/* 
const dev = {
   FIRSTBLOG: "52c98576-0675-476d-bd1d-bd4b031ce8d0", //"ccb16265-5895-4af1-bf76-b500b996fc2f",         //first post list to show by default is housekeeping blog
   ROOTURL: "http://localhost:3000/",                         //for model share
   s3: {
      REGION: "ca-central-1",
      //BUCKET: "surveyalotapi-dev-attachmentsbucket-1jhov9fg41md6" // "surveyalotapi-dev-attachmentsbucket-337qqx0gwuvn"
      // BUCKET: "surveyalot-image-bucket-dev" 
      BUCKET: "surveyalotapp-photos-dev.surveyalot.com"
   },
   MAX_ATTACHMENT_SIZE: 2000000,
   STRIPE_PUBLIC_KEY: "pk_test_jAaNQESBO6anPnJ7i9xC9PHe00jlvnCQ3c"
};
 */
/* */
const prod = {
   FIRSTBLOG: "52c98576-0675-476d-bd1d-bd4b031ce8d0",   //general "ccb16265-5895-4af1-bf76-b500b996fc2f", //housekeeping        //first post list to show by default is housekeeping blog
   ROOTURL: "https://www.takebacktheheat.org/",                         //for model share
   s3: {
      REGION: "ca-central-1",
      //BUCKET: "surveyalotapi-dev-attachmentsbucket-1jhov9fg41md6" // "surveyalotapi-dev-attachmentsbucket-337qqx0gwuvn"
      // BUCKET: "surveyalot-image-bucket-dev" 
      BUCKET: //"surveyalotapp-photos-dev.surveyalot.com"
                "tbth-publicfiles"
   },
   MAX_ATTACHMENT_SIZE: 2000000,
   STRIPE_PUBLIC_KEY: "pk_live_z4Ok5jxHwLaeuJJrWW82VZjl00JYtuvjJr"
};
/* */
const config = prod;  //dev;  //prod

export default config;