//do this direct to backend server, be a service instead?  didn't see how.
//going through here makes it easier to monitor, doesn't give out graphql api endpoint

//send in (public) deviceId, writeKey, cels1 (to start)
// it looks up deviceId and writeKey from Registry, obtains owner, lat and lng (may be overwritten by event data)
// it gets ip from call
// appends DataLog
// sends back success or error (no details?)

import React, { useState, useEffect } from "react";

//import { API, graphqlOperation } from 'aws-amplify';
import { API} from 'aws-amplify';
import { processEvent } from '../graphql/mutations';

export default function AddEvent(props) {

    const [result, setResult] = useState();

    async function callProcessEvent() {
        const payload = {
            deviceId: props.match.params.id,
            writeKey: '123',//props.match.params.writeKey,
            cels1: 42.3 //props.match.params.cels1
 //           ip: request.connection.remoteAddress
        };
        try {
           // console.log("calling processEvent");
            //await API.graphql(graphqlOperation(processEvent, { input: payload }));
           // const processEventData = await API.graphql({ query: processEvent, variables: {input:payload}, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
           await API.graphql({ query: processEvent, variables: {input:payload}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
            //console.log("res:",processEventData);
            setResult("SUCCESS: " + props.match.params.id);
        } catch (err) {
            //console.log(err);
            setResult("FAILED: " + err);
        }
    }

    useEffect(() => {
       // console.log("props:", props);

        if (props.match.params.id === undefined) {
            setResult("missing id");
        } else {
            callProcessEvent();
        }
        // eslint-disable-next-line        
    }, []);

    return (
        <div><p>result:{result}</p>
        </div>
    );
}