import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ChangePassword.css";

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            oldPassword: "",
            isChanging: false,
            confirmPassword: ""
        };
    }

    validateForm() {
        return (
            this.state.oldPassword.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleChangeClick = async event => {
        event.preventDefault();

        this.setState({ isChanging: true });

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            if (currentUser.toLower() === "demo") {
            } else {
                await Auth.changePassword(
                    currentUser,
                    this.state.oldPassword,
                    this.state.password
                );
            }

            this.props.history.push("/why");
        } catch (e) {
            alert(e.message);
            this.setState({ isChanging: false });
        }
    };

    render() {
        return (
            <div className="ChangePassword">
                <form onSubmit={this.handleChangeClick}>
                    <FormGroup controlId="oldPassword">
                        <FormLabel className="ldlabel">Old Password</FormLabel>
                        <FormControl
                            type="password"
                            onChange={this.handleChange}
                            value={this.state.oldPassword}
                        />
                    </FormGroup>
                    <hr />
                    <FormGroup controlId="password">
                        <FormLabel className="ldlabel">New Password</FormLabel>
                        <FormControl
                            type="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="confirmPassword">
                        <FormLabel className="ldlabel">Confirm Password</FormLabel>
                        <FormControl
                            type="password"
                            onChange={this.handleChange}
                            value={this.state.confirmPassword}
                        />
                    </FormGroup>
                    <LoaderButton
                        className="ldloaderbutton"
                        block
                        type="submit"
                        disabled={!this.validateForm()}
                        isLoading={this.state.isChanging}
                    >
                        Change Password
            </LoaderButton>
                    <label className="ldPasswordDescription"> Passwords: minimal length of 8 and have at least 1 each of number, special character, uppercase letter, and lowercase letter.</label>
                </form>
            </div>
        );
    }
}