import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Button, Row, InputGroup, FormControl } from "react-bootstrap";
import { listConfigs } from '../graphql/queries';
import config from '../config';
import { Helmet } from "react-helmet";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { copyToClipboard } from '../common.js';

export default function CalcPanel(props) {

    const [calcpanelList, setCalcpanelList] = useState([]);

    const [configList, setConfigList] = useState([]);
    const [urlData, setUrlData] = useState([]);  //for optional model setting parameter overrides

    const [desc, setDesc] = useState([]);         //description of configuration
    const [ppa, setPpa] = useState([]);          //panels per acre
    const [cpp, setCpp] = useState([]);          //cost per panel
    const [wpp, setWpp] = useState([]);          //watts per panel
    const [epp, setEpp] = useState([]);          //electronics per panel
    const [ipp, setIpp] = useState([]);          //installation per panel (shipping, frame, electronics etc.)
 //   const [hlpp, setHlpp] = useState([]);          //heat loss per panel
 //   const [sh, setSh] = useState([]);          //sunshine hours
    const [tmw, setTmw] = useState([]);          //target MW
    const [cpa, setCpa] = useState([]);          //cost per acre

    const [sred, setSred] = useState([]);      // setup reduction (85%) estimate  should be number 0 to 99.   for bulk discounts etc.
    const [mpy, setMpy] = useState([]);       // estimated maintenance per year
    const [paneff, setPaneff] = useState([]);       //catch efficiency % eg panes due to age, manufacture etc.
    const [dceff, setDceff] = useState([]);       //dispatch conversion effiency % . energy converted, ready to be dispatched.  eg SMD LEDs @ 75%, with 25% heat loss
    const [deff, setDeff] = useState([]);       //dispatch efficiency %.  efficiency of technique used. eg 555nm light 70% makes it to space
    const [dlhpd, setDlhpd] = useState([]);       //daylight hours per day.  avg over year, includes twilight etc. not night (no sun)
    const [dlf, setDlf] = useState([]);       //daylight factor % .   of the daylight hurs, average sunlight.  represents localized cloudiness, bell curve for dawn/dusk cycle etc.
    const [ctpwd, setCtpwd] = useState([]);       //carbon tons (credits) per watt-day

    const [c_akw, setC_akw] = useState([]);      //calced 1 acre KWs
    const [c_mwa, setC_mwa] = useState([]);      //calced 1 MW acres
    const [c_a, setC_a] = useState([]);      //calced acres
    const [c_p, setC_p] = useState([]);      //calced panels
    const [c_pc, setC_pc] = useState([]);      //calced panel cost
    const [c_pic, setC_pic] = useState([]);      //calced panel installation cost
    const [c_ec, setC_ec] = useState([]);      //calced electronics cost
    const [c_lc, setC_lc] = useState([]);      //calced land cost
    const [c_tc, setC_tc] = useState([]);      //calced total cost
    const [c_apmw, setC_apmw] = useState([]);      //calced average cost per MW

    const [c_tcwd, setC_tcwd] = useState([]);     //calced total cost with discount
    const [c_maxw, setC_maxw] = useState([]);       // theoretical max watts (panels totaled)     target * 1,000,000
    const [c_dw, setC_dw] = useState([]);       //  result dispatched watts    calculated: max watts x efficiency % x dispatch conversion efficiency % x dispatch %
    const [c_eres, setC_eres] = useState([]);       //  approx. result %  c_dw / c_maxw   is efficency result so far
    const [c_tdh, setC_tdh] = useState([]);       //  total daily hours (daylight hours x factor)    daylight hours x factor
    const [c_elf, setC_elf] = useState([]);       //  effective load factor   c_eres x (dlhpd/24) x (dlf / 100)
    const [c_whpd, setC_whpd] = useState([]);       //  watt hours per day (Wh/day)  c_dw x c_tdh 
    const [c_wdpd, setC_wdpd] = useState([]);       //  watt days per day   c_whpd / 24
    const [c_wdpy, setC_wdpy] = useState([]);       //  watt days per year        c_wdpd x 365
    const [c_kwhpd, setC_kwhpd] = useState([]);       //  kWH / day    c_whpd / 1000    
    const [c_mwhpd, setC_mwhpd] = useState([]);       //  MWH / day       c_whpd / 1000000
    const [c_mjpd, setC_mjpd] = useState([]);         //  mega joules per day  kWhpd * 3600000 / 1000000
    const [c_gjpy, setC_gjpy] = useState([]);         //  giga joules per year.  mjpd x 365 / 1000
    const [c_mwhpy, setC_mwhpy] = useState([])       ///  MWH / year      c_mwhpd * 365
   // const [c_ctpy, setC_ctpy] = useState([]);       //  carbon tons per year  (old carbon credit, not metric, not co2)  c_wdpd * ctpwd * 365
    const [c_co2emtpy, setC_co2emtpy] = useState([]);       //  co2e metric tonnes per year      c_ctpy * 3.6667 * 0.907185
    const [c_co2emtcpp, setC_co2emtcpp] = useState([]);       //   co2e tonne credits per panel    c_co2emtpy / c_p
    const [c_ncpp, setC_ncpp] = useState([]);       //   net cost per panel  (excel maint)   c_tcwd / c_p
    const [c_co2emtpyp1w, setC_co2emtpyp1w] = useState([]);       //  co2e tonne per year / wpp  co2e tonne credit per panel / watts per panel    c_co2emtcpp / wpp
    const [c_cpco2emt1y, setC_cpco2emt1y] = useState([]);       //   cost per co2e metric tonne 1st year  total cost (excl maint) / co2e metric tonnes per year     c_tcwd / c_co2emtpy  
    const [c_cpco2emtsy, setC_cpco2emtsy] = useState([]);       //   cost per co2e metric tonne subsequent years  total maint cost / co2e metric tonnes per year    mpy / c_co2emtpy
    const [c_cpwd1y, setC_cpwd1y] = useState([]);       //   yearly cost per watt-day 1st year  total cost (exc. maint) / watt days per year     c_tcwd / c_wdpy
    const [c_cpwdsy, setC_cpwdsy] = useState([]);       //   yearly cost per watt-day subsequent years  total maint cost / watt days per year      mpy / c_wdpy


    const rootUrl = config.ROOTURL;

    const [messageLine, setMessageLine] = useState();

    const [reloadModel, setReloadModel] = useState(false);  //happens once, determines url vs settings etc.
    const [recalcModel, setRecalcModel] = useState(false);  //happens whenever a number is changed

    useEffect(() => {
        function doOnLoad() {
            fetchConfigList();
            const query = new URLSearchParams(props.location.search);
            let data = {};
            for (let params of query.entries()) {
                if (params[0].includes("desc")) {
                    data[params[0]] = params[1];     //+        text, we don't use +
                } else {
                    data[params[0]] = +params[1];
                }
            }
            setUrlData(data);
        }
        doOnLoad();
        /* eslint-disable */
    }, [])

    /******************************************************************************************* */
    useEffect(() => {
        function doRecalcModel() {
   //         const _akw = ppa * wpp * ((100 - hlpp) / 100) * (sh / 24) / 1000;
            const _akw = ppa * wpp / 1000;
            setC_akw(_akw);
            const _mwa = 1000000 / (_akw * 1000);
            setC_mwa(_mwa);
            const _a = tmw * _mwa;
            setC_a(_a);
            const _p = _a * ppa;
            setC_p(_p);
            const _pc = cpp * _p;
            setC_pc(_pc);
            const _pic = ipp * _p;
            setC_pic(_pic);
            const _ec = epp * _p;
            setC_ec(_ec);
            const _lc = cpa * _a;
            setC_lc(_lc);
            const _tc = _pc + _pic + _ec + _lc;
            setC_tc(_tc);
            const _apmw = _tc / tmw;
            setC_apmw(_apmw);

            const _tcwd = _tc * (sred / 100);
            setC_tcwd(_tcwd);
            const _maxw = wpp * _p; //tmw * 1000000;
            setC_maxw(_maxw);
            const _dw = _maxw * (paneff / 100) * (dceff / 100) * (deff / 100);
            setC_dw(_dw);
            const _eres = _dw / _maxw * 100;
            setC_eres(_eres);
            const _tdh = dlhpd * (dlf / 100);
            setC_tdh(_tdh);
            const _elf = (_eres/100) * (dlhpd / 24) * (dlf / 100) * 100;
            setC_elf(_elf);
            const _whpd = _dw * _tdh;
            setC_whpd(_whpd);
            const _wdpd = _whpd / 24;
            setC_wdpd(_wdpd);
            const _wdpy = _wdpd * 365;
            setC_wdpy(_wdpy);
            const _kwhpd = _whpd / 1000;
            setC_kwhpd(_kwhpd);
            const _mwhpd = _whpd / 1000000;
            setC_mwhpd(_mwhpd);
            const _mwhpy = _mwhpd * 365;
            setC_mwhpy(_mwhpy);
            const _mjpd = _kwhpd * 3600000 / 1000000;
            setC_mjpd(_mjpd);
            const _gjpy = _mjpd * 365 / 1000;
            setC_gjpy(_gjpy);
            const _ctpy = _wdpd * ctpwd * 365;
  //          setC_ctpy(_ctpy);
            const _co2emtpy = _ctpy * 3.6667 * 0.907185;
            setC_co2emtpy(_co2emtpy);
            const _co2emtcpp = _co2emtpy / _p;
            setC_co2emtcpp(_co2emtcpp);
            const _ncpp = _tcwd / _p;
            setC_ncpp(_ncpp);
            const _co2emtpyp1w = _co2emtcpp / wpp;
            setC_co2emtpyp1w(_co2emtpyp1w);
            const _cpco2emt1y = _tcwd / _co2emtpy;
            setC_cpco2emt1y(_cpco2emt1y);
            const _cpco2emtsy = mpy / _co2emtpy;
            setC_cpco2emtsy(_cpco2emtsy);
            const _cpwd1y = _tcwd / _wdpy;
            setC_cpwd1y(_cpwd1y);
            const _cpwdsy = mpy / _wdpy;
            setC_cpwdsy(_cpwdsy);


            setRecalcModel(false);
        }

        /* xeslint-disable */

        if (recalcModel === true & configList.length > 0) {
            doRecalcModel();
        }
    }, [recalcModel])

    useEffect(() => {
        function doOnReload() {
            // set defaults  see values in config table, these should be moved.
            setSred(85);
            setMpy(240000);
            setPaneff(90);
            setDceff(75);
            setDeff(70);
            setDlhpd(14);
            setDlf(85);
            setCtpwd(0.000157);
        
            //
            if (configList !== undefined && configList.length > 0) {
                const modelSettings = configList.find(obj => obj.configName === "CalcPanel");
                if (modelSettings !== undefined) {      //override defaults


                    if (urlData.desc !== undefined) {
                        setDesc(urlData.desc);
                    } else {
                        if (modelSettings.attribute.desc !== undefined) {
                            setDesc(modelSettings.attribute.desc);
                        }
                    }

                    if (urlData.ppa !== undefined) {
                        setPpa(urlData.ppa);
                    } else {
                        if (modelSettings.attribute.ppa !== undefined) {
                            setPpa(modelSettings.attribute.ppa);
                        }
                    }

                    if (urlData.cpp !== undefined) {
                        setCpp(urlData.cpp);
                    } else {
                        if (modelSettings.attribute.cpp !== undefined) {
                            setCpp(modelSettings.attribute.cpp);
                        }
                    }
                    if (urlData.wpp !== undefined) {
                        setWpp(urlData.wpp);
                    } else {
                        if (modelSettings.attribute.wpp !== undefined) {
                            setWpp(modelSettings.attribute.wpp);
                        }
                    }
                    if (urlData.epp !== undefined) {
                        setEpp(urlData.epp);
                    } else {
                        if (modelSettings.attribute.epp !== undefined) {
                            setEpp(modelSettings.attribute.epp);
                        }
                    }
                    if (urlData.ipp !== undefined) {
                        setIpp(urlData.ipp);
                    } else {
                        if (modelSettings.attribute.ipp !== undefined) {
                            setIpp(modelSettings.attribute.ipp);
                        }
                    }
/*                    if (urlData.hlpp !== undefined) {
                        setHlpp(urlData.hlpp);
                    } else {
                        if (modelSettings.attribute.hlpp !== undefined) {
                            setHlpp(modelSettings.attribute.hlpp);
                        }
                    }
                    if (urlData.sh !== undefined) {
                        setSh(urlData.sh);
                    } else {
                        if (modelSettings.attribute.sh !== undefined) {
                            setSh(modelSettings.attribute.sh);
                        }
                    }
*/
                    if (urlData.tmw !== undefined) {
                        setTmw(urlData.tmw);
                    } else {
                        if (modelSettings.attribute.tmw !== undefined) {
                            setTmw(modelSettings.attribute.tmw);
                        }
                    }
                    if (urlData.cpa !== undefined) {
                        setCpa(urlData.cpa);
                    } else {
                        if (modelSettings.attribute.cpa !== undefined) {
                            setCpa(modelSettings.attribute.cpa);
                        }
                    }

                    if (urlData.Sred !== undefined) {
                        setSred(urlData.Sred);
                    } else {
                        if (modelSettings.attribute.Sred !== undefined) {
                            setSred(modelSettings.attribute.Sred);
                        }
                    }
                    if (urlData.Mpy !== undefined) {
                        setMpy(urlData.mpy);
                    } else {
                        if (modelSettings.attribute.mpy !== undefined) {
                            setMpy(modelSettings.attribute.mpy);
                        }
                    }
                    if (urlData.paneff !== undefined) {
                        setPaneff(urlData.paneff);
                    } else {
                        if (modelSettings.attribute.paneff !== undefined) {
                            setPaneff(modelSettings.attribute.paneff);
                        }
                    }
                    if (urlData.dceff !== undefined) {
                        setDceff(urlData.dceff);
                    } else {
                        if (modelSettings.attribute.dceff !== undefined) {
                            setDceff(modelSettings.attribute.dceff);
                        }
                    }
                    if (urlData.deff !== undefined) {
                        setDeff(urlData.deff);
                    } else {
                        if (modelSettings.attribute.deff !== undefined) {
                            setDeff(modelSettings.attribute.deff);
                        }
                    }
                    if (urlData.dlhpd !== undefined) {
                        setDlhpd(urlData.dlhpd);
                    } else {
                        if (modelSettings.attribute.dlhpd !== undefined) {
                            setDlhpd(modelSettings.attribute.dlhpd);
                        }
                    }
                    if (urlData.dlf !== undefined) {
                        setDlf(urlData.dlf);
                    } else {
                        if (modelSettings.attribute.dlf !== undefined) {
                            setDlf(modelSettings.attribute.dlf);
                        }
                    }
                    if (urlData.ctpwd !== undefined) {
                        setCtpwd(urlData.ctpwd);
                    } else {
                        if (modelSettings.attribute.ctpwd !== undefined) {
                            setCtpwd(modelSettings.attribute.ctpwd);
                        }
                    }
                }
                setRecalcModel(true);
                //  copyToClipboard("hello world");
            }

        }

        if (reloadModel === true && configList.length > 0) {
            doOnReload();
            setReloadModel(false);
        }


    }, [reloadModel])

    async function fetchConfigList() {
        try {
            //todo   just get the calcpanel record, if we're not going to integrate 
            const configListDataA = await API.graphql({ query: listConfigs, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            //const configListDataA = await API.graphql(graphqlOperation(listConfigs));
            const configListA = configListDataA.data.listConfigs.items;

            const cList = configListA.map(obj => ({ ...obj, attribute: JSON.parse(obj.attributes) }));

            const cpList = cList.filter(obj => obj.attribute.Type === "calcpanel");

            setConfigList(cList);
            setCalcpanelList(cpList);    //first load what's in db.  we may need to overwrite with url parameter settings
            setReloadModel(true);

            return { status: true };

        } catch (error) {
            console.log("error on fetching configList", error);
            return { status: false, error: error };
        }
    };

    async function handleShare() {
        //http://localhost:3000/model?sy=2050&ey=2120&t=10000000000000&tDesc=this%20is%20my%20target%20description%20or%20not&hslName=newname&hsl100=5&hslStart=2027&hslSites=42&hslDaily=500&hslLoss=17
        //        var str = rootUrl + "CalcPanel?desc=" + desc + "&ppa=" + ppa + "&cpp=" + cpp + "&wpp=" + wpp + "&epp=" + epp
        //            + "&ipp=" + ipp + "&hlpp=" + hlpp + "&sh=" + sh + "&tmw=" + tmw + "&cpa=" + cpa;
        // NOTE: API_KEY NEEDS TO BE RENEWED EACH YEAR

        const str = new URL(rootUrl + "CalcPanel");
        str.searchParams.append("desc", desc);
        str.searchParams.append("ppa", ppa);
        str.searchParams.append("cpp", cpp);
        str.searchParams.append("wpp", wpp);
        str.searchParams.append("epp", epp);
        str.searchParams.append("ipp", ipp);
    //    str.searchParams.append("hlpp", hlpp);
    //    str.searchParams.append("sh", sh);
        str.searchParams.append("tmw", tmw);
        str.searchParams.append("cpa", cpa);
        str.searchParams.append("sred", sred);
        str.searchParams.append("mpy", mpy);
        str.searchParams.append("paneff", paneff);
        str.searchParams.append("dceff", dceff);
        str.searchParams.append("deff", deff);
        str.searchParams.append("dlhpd", dlhpd);
        str.searchParams.append("dlf", dlf);
        str.searchParams.append("ctpwd", ctpwd);
        
        copyToClipboard(str.href);
        alert("copied to clipboard");
    }

    function handleChange(e) {
        //todo yeah yeah should have used state differently

        var val = (e.target.value).replace(/[,$]/g, '');    //remove all , and $

        switch (e.target.name) {
            case "desc":
                setMessageLine("");
                setDesc(e.target.value);
                break;
            case "ppa":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setPpa(val);
                }
                break;
            case "cpp":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setCpp(val);
                }
                break;
            case "wpp":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setWpp(val);
                }
                break;
            case "epp":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setEpp(val);
                }
                break;
            case "ipp":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setIpp(val);
                }
                break;
  /*          case "hlpp":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    if (val > 100) {
                        setMessageLine("Must be < 100");
                    } else {
                        if (val < 0) {
                            setMessageLine("Must be >= 0");
                        } else {
                            setMessageLine("");
                            setHlpp(val);
                        }
                    }
                }
                break;
            case "sh":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setSh(val);
                }
                break;
 */               
            case "tmw":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setTmw(val);
                }
                break;
            case "cpa":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setCpa(val);
                }
                break;

            case "sred":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setSred(val);
                }
                break;

            case "mpy":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setMpy(val);
                }
                break;

            case "paneff":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setPaneff(val);
                }
                break;

            case "dceff":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setDceff(val);
                }
                break;

            case "deff":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setDeff(val);
                }
                break;

            case "dlhpd":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setDlhpd(val);
                }
                break;

            case "dlf":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setDlf(val);
                }
                break;

            case "ctpwd":
                if (isNaN(val)) {
                    setMessageLine("Must be a number");
                } else {
                    setMessageLine("");
                    setCtpwd(val);
                }
                break;


            default:
                console.log("unhandled update for:", e.target.name);
        }
        setRecalcModel(true);
    }

    function renderShareButton() {
        return (
            <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleShare} disabled={false}
            >
                Share
            </Button>
        )
    }

    function formatNumeric(value, showCurrency, decimals) { //}, ltzdecimals) {
 /*       if (value < 0) {
            var ret = (showCurrency ? '$ ' : '') + Number(value).toLocaleString('en', { style: 'decimal', maximumFractionDigits: decimals, minimumFractionDigits: decimals });
            return ret;
        } else {
           var ret = (showCurrency ? '$ ' : '') + Number(value).toLocaleString('en', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 });
           return ret;
        }
        */
       if (decimals > 0 && value > 0) { 
        console.log("here");
       }
        var ret = (showCurrency ? '$ ' : '') + Number(value).toLocaleString('en', { style: 'decimal', maximumFractionDigits: decimals, minimumFractionDigits: decimals });
        return ret;
 }

    /*
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>carbon (tons) per watt-day</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="carbon (tons) per watt-day"
                                value={formatNumeric(ctpwd, false, 6)}
                                name="ctpwd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>carbon (tons) per year</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="carbon (tons) per year"
                                value={formatNumeric(c_ctpy, false, 0)}
                                name="c_ctpy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>

                    */

    function renderCalcPanel() {
        return (<div className="CalcPanel" >
            <Row className="ldHeaderRow">
                Take Back The Heat - Calc Panels
            </Row>
            <Row className="ldTopRow">
                <div className="ldMessageContainer" >
                    <div className="ldFormMessage">
                        {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                    </div>
                </div>
            </Row>
            <Row className="ldMidRow">
                <div className="ldSmallButton">
                    {renderShareButton()}
                </div>
            </Row>
            <Row className="ldBodyRow">
                <div className="ldSmallButton">
                    <Link to="/post/05956d05-1f5a-4ce0-a654-efb559d1b3d8">Forum Discussion</Link>
                </div>
            </Row>

            {calcpanelList != undefined && calcpanelList.length > 0 ? (

                <div>

                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Description</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as="textarea"
                                className="formDescriptionText"
                                rows={1}
                                placeholder="Description"
                                value={desc || ''}
                                name="desc"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Panels / acre</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Panels / acre"
                                value={formatNumeric(ppa, false, 0)}
                                name="ppa"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Cost / panel $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Cost / panel"
                                value={formatNumeric(cpp, true, 0)}
                                name="cpp"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Watts / panel</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Watts / panel"
                                value={formatNumeric(wpp, false, 0)}
                                name="wpp"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Electronics / panel $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Electronics / panel"
                                value={formatNumeric(epp, true, 0)}
                                name="epp"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>installation $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Installation"
                                value={formatNumeric(ipp, true, 0)}
                                name="ipp"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>1 Acre (KW)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="1 Acre (KW)"
                                value={formatNumeric(c_akw, false, 0)}
                                name="c_akw"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>1 MW (acres)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="1 MW (acres)"
                                value={formatNumeric(c_mwa, false, 0)}
                                name="c_mwa"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Gross Target (MW)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className=" FormHighlight FormTextRight"
                                placeholder="Gross Target (MW)"
                                value={tmw}
                                name="tmw"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Max watts</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Max watts"
                                value={formatNumeric(c_maxw, false, 0)}
                                name="c_maxw"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                     <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Acres</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Acres"
                                value={formatNumeric(c_a, false, 1)}
                                name="c_a"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>


                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Panels (p)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Panels"
                                value={formatNumeric(c_p, false, 0)}
                                name="c_p"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Panel cost $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Panel cost"
                                value={formatNumeric(c_pc, true, 0)}
                                name="c_pc"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Panel installation cost $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Panel installation cost"
                                value={formatNumeric(c_pic, true, 0)}
                                name="c_pic"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Electronics cost / p $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Electronics cost / p"
                                value={formatNumeric(c_ec, true, 0)}
                                name="c_ec"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Acre cost $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="numeric"
                                className="FormAmount"
                                placeholder="Acre cost"
                                value={formatNumeric(cpa, true, 0)}
                                name="cpa"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Land cost $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Land cost"
                                value={formatNumeric(c_lc, true, 0)}
                                name="c_lc"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Total cost $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Total cost"
                                value={formatNumeric(c_tc, true, 0)}
                                name="c_tc"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Avg cost / MW $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Avg $ / MW"
                                value={formatNumeric(c_apmw, true, 0)}
                                name="c_apmw"
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row>
                        -
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Setup reduction (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Setup reduction (%)"
                                value={formatNumeric(sred, false, 0)}
                                name="sred"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Total Cost with Reduction ($)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Total Cost with reduction ($)"
                                value={formatNumeric(c_tcwd, false, 0)}
                                name="c_tcwd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Maintenance / year $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="numeric"
                                className="FormAmount"
                                placeholder="Maintenance / year"
                                value={formatNumeric(mpy, true, 0)}
                                name="mpy"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>


                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Panel 'catch' efficency (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Panel 'catch' efficiency (%)"
                                value={formatNumeric(paneff, false, 0)}
                                name="paneff"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>'Dispatch' conversion efficiency (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="'Dispatch' conversion efficiency (%)"
                                value={formatNumeric(dceff, false, 0)}
                                name="dceff"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>'Dispatch' efficency (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="'Dispatch' efficiency (%)"
                                value={formatNumeric(deff, false, 0)}
                                name="deff"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>

                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Result dispatched watts</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Result dispatched watts"
                                value={formatNumeric(c_dw, false, 0)}
                                name="c_dw"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Approx. result (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Approx. result (%)"
                                value={formatNumeric(c_eres, false, 0)}
                                name="c_eres"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Daylight hours / day</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="daylight hours per day"
                                value={formatNumeric(dlhpd, false, 0)}
                                name="dlhpd"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Daylight factor (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="daylight factor (%)"
                                value={formatNumeric(dlf, false, 0)}
                                name="dlf"
                                onChange={handleChange}
                                disabled={false}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Total daylight hours/d</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="'Total daylight hours/d"
                                value={formatNumeric(c_tdh, false, 2)}
                                name="c_tdh"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Effective load factor (%)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Effective load factor (%)"
                                value={formatNumeric(c_elf, false, 4)}
                                name="c_elf"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Watt hours / day (Wh/day)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Watt hours / day (Wh/day)"
                                value={formatNumeric(c_whpd, false, 2)}
                                name="c_whpd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Watt days / day</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Watt days / day"
                                value={formatNumeric(c_wdpd, false, 2)}
                                name="c_wdpd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Watt days / year</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="Watt days / year"
                                value={formatNumeric(c_wdpy, false, 2)}
                                name="c_wdpy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>kWh / day</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="kWh / day"
                                value={formatNumeric(c_kwhpd, false, 2)}
                                name="c_kwhpd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>MWh / day</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="MWh / day"
                                value={formatNumeric(c_mwhpd, false, 2)}
                                name="c_mwhpd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>MWh / year</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="MWh / year"
                                value={formatNumeric(c_mwhpy, false, 2)}
                                name="c_mwhpy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>MJ / day</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="MJ / day"
                                value={formatNumeric(c_mjpd, false, 2)}
                                name="c_mjpd"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>GJ / year</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="GJ / year"
                                value={formatNumeric(c_gjpy, false, 2)}
                                name="c_gjpy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                        <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>CO2e tonnes / y</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="CO2e tonnes/y"
                                value={formatNumeric(c_co2emtpy, false, 2)}
                                name="c_co2emtpy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>CO2e tonnes / y / p</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="CO2e tonnes/y/p"
                                value={formatNumeric(c_co2emtcpp, false, 2)}
                                name="c_co2emtcpp"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Net cost / p</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Net cost/p"
                                value={formatNumeric(c_ncpp, true, 2)}
                                name="c_ncpp"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>CO2e tonne / y / 1 watt</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormNumber"
                                placeholder="CO2e tonnes /y / 1 watt"
                                value={formatNumeric(c_co2emtpyp1w, false, 6)}
                                name="c_co2emtpyp1w"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                   <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Yearly cost / watt-day (1st year)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Yearly cost / watt-day (1st year)"
                                value={formatNumeric(c_cpwd1y, true, 6)}
                                name="c_cpwd1y"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Yearly cost / watt-day (2+ years)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Yearly cost / watt-day (2+ years)"
                                value={formatNumeric(c_cpwdsy, true, 6)}
                                name="c_cpwdsy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Cost / CO2e tonne (1st year)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Cost / CO2e tonne (1st year)"
                                value={formatNumeric(c_cpco2emt1y, true, 6)}
                                name="c_cpco2emt1y"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="ldBodyRow">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Cost / CO2e tonne (2+ years)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="FormAmount"
                                placeholder="Cost / CO2e tonne (2+ years)"
                                value={formatNumeric(c_cpco2emtsy, true, 6)}
                                name="c_cpco2emtsy"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </InputGroup>
                    </Row>
       


                </div>


            ) : (
                <div>
                    loading data
                </div>
            )}
        </div>

        );
    }

    return <div className="CalcPanel" >
        <Helmet>
            <title>{"TakeBackTheHeat - CalcPanel"}</title>
            <meta name="description" content="takebacktheheat.org panel calculator to estimate panels, acres and costs of implementing solar photovoltaic (PV) panels to reduce the heat energy to address climate change and global warming." />
        </Helmet>
        {renderCalcPanel()}
    </div>;
}
