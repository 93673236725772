import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Button, Row, Card, Accordion } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { listConfigs } from '../graphql/queries';
import Chart from "react-google-charts";
import config from '../config';
import { Helmet } from "react-helmet";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { copyToClipboard } from '../common.js';

export default function Model(props) {

    const [configList, setConfigList] = useState([]);
    const [classList, setClassList] = useState([]);   //a subset of configList, is only classes
    const [urlData, setUrlData] = useState([]);  //for optional model setting parameter overrides

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => { afterSaveCell(oldValue, newValue, row, column) }
    });

    const rootUrl = config.ROOTURL;

    const [messageLine, setMessageLine] = useState();

    const [itemCount, setItemCount] = useState(0);

    const [modelDetail, setModelDetail] = useState([{ "year": 1, "field2": "aaa" }, { "year": 2, "field2": "bbb" }]);
    const [modelChartDetail, setModelChartDetail] = useState([]);  //a copy of some of the columns from modelDetail, for charting
    const [modelChartSummary, setModelChartSummary] = useState([]);  //chart2 data just summary
    const [recalcModel, setRecalcModel] = useState(false);

    const [startYear, setStartYear] = useState(2020);
    const [endYear, setEndYear] = useState(2104);
    const [target, setTarget] = useState(1000000000);
    const [targetDesc, setTargetDesc] = useState("default 1bn watts");

    const [modelTotalWatts, setModelTotalWatts] = useState(0);

    const [modelDetailColumns, setModelDetailColumns] = useState([{ dataField: 'year', text: 'year' }]);

    function compare(a, b) {
        if (a.configName < b.configName) {
            return -1;
        }
        if (a.configName > b.configName) {
            return 1;
        }
        return 0;
    }

    function afterSaveCell(oldValue, newValue, row, column) {
        //    console.log("in function: ", `${oldValue} ${newValue}`);
        //    console.log("=>", column.dataType);

        if (oldValue !== newValue) {
            setRecalcModel(true);
            //        console.log("new value detected");
        }
    }

    //  function ActionFormatter(data) {
    //      return (
    //          <div className="ButtonColumn">
    //              {(props.userDetails.username === data.row.owner) ? (<>
    //                  <Button type="button" variant="outline-danger" className="ActionButton" size="sm" onClick={() => handlexButton(data.row)}>x</Button></>) : (<></>)}
    //          </div>
    //      );
    //  }

    //  function handlexButton(e) {
    //      alert("yup:" + itemCount, e);
    //  }

    //function formatDate(cell, row, rowIndex, colIndex) {
    //}

    //const actionFormatter = (cell, row) => <ActionFormatter row={row} />;

    /*    var modelDetailColumns = [{
            dataField: 'year',
            text: 'year'
        }, {
            dataField: 'field2',
            text: 'field2'
        }];
    */
    const configColumns = [{
        //  dataField: 'action',
        //  isDummyField: true,
        //  csvExport: false,
        //  formatter: actionFormatter,
        //  formatExtraData: props.userDetails.username,
        //  headerStyle: (column, colIndex) => { return { width: '16rem' }; },
        //  text: ''
        //}, {
        dataField: 'configName',
        text: 'Class',    //name
        sort: true,
        type: 'string'
        //    }, {
        //        dataField: 'createdAt',
        //        text: 'Created At'
    }, {
        dataField: 'attribute.YearsTo100',      //{YearsTo100: 10, StartYear: 2022, NumSites: 1000, DailyWattAvg: 8000000, HeatLossPercent: 10}
        text: 'YearsTo100',
        sort: true,
        type: 'number',
        validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
                return {
                    valid: false,
                    message: 'Year to 100 should be numeric'
                };
            }
            return true;
        }

        //    }, {
        //        dataField: 'createdAt',
        //        text: 'Created At'
    }, {
        dataField: 'attribute.StartYear',
        text: 'StartYear',
        sort: true,
        type: 'number',
        validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
                return {
                    valid: false,
                    message: 'Start year should be numeric'
                };
            }
            return true;
        }

    }, {
        dataField: 'attribute.NumSites',
        text: 'NumSites',
        sort: true,
        type: 'number',
        validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
                return {
                    valid: false,
                    message: 'Number of sites should be numeric'
                };
            }
            return true;
        }

    }, {
        dataField: 'attribute.DailyWattAvg',
        text: 'DailyWattAvg',
        sort: true,
        type: 'number',
        validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
                return {
                    valid: false,
                    message: 'Daily Watt Avg should be numeric'
                };
            }
            return true;
        }

    }, {
        dataField: 'attribute.HeatLossPercent',
        text: 'HeatLossPercent',
        sort: true,
        type: 'number',
        validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
                return {
                    valid: false,
                    message: 'Loss should be numeric'
                };
            }
            return true;
        }

    }, {
        dataField: 'configNote',
        text: 'Note',
        sort: true,
        type: 'string'
    }

    ];

    // using this approach results in this routine called twice, first is false then true, when page called with parameters.  this overwrites the parameters.
    //    useEffect(() => {
    //        fetchConfigList();
    //        setModelTotalWatts(0);  //just to keep error message away
    //        /* eslint-disable */
    //    }, [props.isAuthenticated])

    useEffect(() => {
        function doOnLoad() {
            fetchConfigList();
            setModelTotalWatts(0);  //just to keep error message away
            const query = new URLSearchParams(props.location.search);
            let data = {};
            for (let params of query.entries()) {
                if (params[0].includes("Desc") || params[0].includes("Name") || params[0].includes("Note")) {
                    data[params[0]] = params[1];     //+
                } else {
                    data[params[0]] = +params[1];
                }
            }
            setUrlData(data);
        }
        doOnLoad();
        /* eslint-disable */
    }, [])

    /******************************************************************************************* */
    useEffect(() => {
        function doRecalcModel() {
            var newA = [];   // the replacement array of rows
            //  var newC = [];   // a narrow array for charting, newC is the collection of rows
            // var cData = [];  //a narrower array for charting, cData is each row



            var priorR = {};
            for (let yearPointer = startYear; yearPointer <= endYear; yearPointer += 1) {
                let r = { year: yearPointer, newDeltaTotal: 0, yearTotal: 0, yearRunningTotal: 0 };
                //     let YearTotal = 0;
                //     let YearRunningTotal = 0;
                for (let cl = 0; cl < classList.length; cl += 1) {
                    if (yearPointer >= classList[cl].attribute.StartYear) {
                        if (classList[cl].attribute.YearsTo100 + classList[cl].attribute.StartYear > yearPointer) {
                            // r[classList[cl].configName] = (classList[cl].attribute.NumSites / classList[cl].attribute.YearsTo100).toLocaleString("en-US");
                            r[classList[cl].configName] = (classList[cl].attribute.NumSites / classList[cl].attribute.YearsTo100).toFixed(1);
                        } else {
                            r[classList[cl].configName] = 0;
                        }
                    } else {
                        r[classList[cl].configName] = 0;
                    }

                    r[(classList[cl].configName) + "NewDelta"] = r[classList[cl].configName] * classList[cl].attribute.DailyWattAvg * (1 - (classList[cl].attribute.HeatLossPercent / 100));
                    r["newDeltaTotal"] += r[(classList[cl].configName) + "NewDelta"];
                    if (yearPointer > startYear) {
                        r[(classList[cl].configName) + "RunningDailyWTotal"] = r[(classList[cl].configName) + "NewDelta"] + priorR[(classList[cl].configName) + "RunningDailyWTotal"];

                    } else {
                        r[(classList[cl].configName) + "RunningDailyWTotal"] = r[(classList[cl].configName) + "NewDelta"];
                    }
                    //             cData[(classList[cl].configName) + "RunningDailyWTotal"] = r[(classList[cl].configName) + "RunningDailyWTotal"];

                }

                if (yearPointer > startYear) {
                    r["yearTotal"] = priorR["yearTotal"] + r["newDeltaTotal"];
                    r["runningDailyWTotal"] = priorR["runningDailyWTotal"] + r["yearTotal"];
                } else {
                    r["yearTotal"] = r["newDeltaTotal"];
                    r["runningDailyWTotal"] = r["yearTotal"];
                }
                r["runningTotal"] = r["runningDailyWTotal"] * 365;
                r["percentOfTarget"] = (r["runningTotal"] / target * 100).toFixed(4);
                //           cData["runningTotal"] = r["runningTotal"];
                //           cData["percentOfTarget"] = r["percentOfTarget"];
                newA.push(r);
                //             newC.push(cData);

                priorR = { ...r };
            }
            setModelTotalWatts(priorR["runningTotal"]);
            setModelDetail(newA);

            // now populate a table for charting, with narrower list of columns
            //two charts, one with watts daily, other with running total
            var cData = []; // new google.visualization.DataTable();
            var cR = [];
            var cDataSum = [];
            var cRSum = [];
            //    cData.addColumn('number', 'Year');    //string
            //    classList.forEach(function (classRow) {
            //        cData.addColumn('number',classRow.configName + "RunningDailyWTotal"); 
            //    });
            //    cData.addColumn('number', 'runningTotal');
            //    cData.addColumn('number', 'percentOfTotal');

            cR.push({ label: 'year', type: 'string' });
            cRSum.push({ label: 'year', type: 'string' });
            classList.forEach(function (classRow) {
                // cR.push({label: classRow.configName + "RunningDailyWTotal",type:'number'}); 
                cR.push({ label: classRow.configName, type: 'number' });
            });
            cRSum.push({ label: 'running Total', type: 'number' });
            cRSum.push({ label: '% Of Target', type: 'number' });

            cData.push(cR);
            cDataSum.push(cRSum);

            newA.forEach(function (row) {
                var cRow = [];
                var cRowSum = [];
                cRow.push(row.year);
                cRowSum.push(row.year);
                classList.forEach(function (classRow) {
                    cRow.push(row[classRow.configName + "RunningDailyWTotal"]);
                });
                cRowSum.push(row.runningTotal);
                cRowSum.push(row.percentOfTarget);
                //           cData["percentOfTarget"] = r["percentOfTarget"];
                // cData.addRow(cRow);
                cData.push(cRow);
                cDataSum.push(cRowSum);
            });

            //             jsonData.forEach(function (row) {
            //               data.addRow([
            //                 row.Week,
            //                 row.Retail,
            //                 row.Wholesale
            //                ]);
            //            });

            //
            //  setModelChartDetail(newC);
            setModelChartDetail(cData);
            setModelChartSummary(cDataSum);
            setRecalcModel(false);

            //now reset column names for table
            var newModelDetailColumns = [];
            newModelDetailColumns.push({ dataField: 'year', text: 'year' });
            for (let cl = 0; cl < classList.length; cl += 1) {
                newModelDetailColumns.push({ dataField: classList[cl].configName, text: classList[cl].configName });
                newModelDetailColumns.push({ dataField: classList[cl].configName + "NewDelta", text: classList[cl].configName + "NewDelta (daily W)" });
                newModelDetailColumns.push({ dataField: classList[cl].configName + "RunningDailyWTotal", text: classList[cl].configName + "Running Total (daily W)" });
            }
            newModelDetailColumns.push({ dataField: "newDeltaTotal", text: "New Delta Total (daily W)" });
            newModelDetailColumns.push({ dataField: "yearTotal", text: "Year Total (daily W)" });
            newModelDetailColumns.push({ dataField: "runningDailyWTotal", text: "Running Daily Total (daily W)" });
            newModelDetailColumns.push({ dataField: "runningTotal", text: "Running Total (DailyWTotal * 365)" });
            newModelDetailColumns.push({ dataField: "percentOfTarget", text: "Percent of Target (Running Total/Target)" });
            setModelDetailColumns(newModelDetailColumns);
        }

        /* xeslint-disable */

        if (recalcModel === true & configList.length > 0) {
            doRecalcModel();
        }
    }, [recalcModel])

    useEffect(() => {
        function doOnReload() {
            if (configList !== undefined && configList.length > 0) {
                const modelSettings = configList.find(obj => obj.configName === "HeatbackModel");
                if (modelSettings !== undefined) {      //override defaults
                    if (urlData.sy !== undefined) {
                        setStartYear(urlData.sy);
                    } else {
                        if (modelSettings.attribute.StartYear !== undefined) {
                            setStartYear(modelSettings.attribute.StartYear);
                        }
                    }
                    if (urlData.ey !== undefined) {
                        setEndYear(urlData.ey);
                    } else {
                        if (modelSettings.attribute.EndYear !== undefined) {
                            setEndYear(modelSettings.attribute.EndYear);
                        }
                    }
                    if (urlData.t !== undefined) {
                        setTarget(urlData.t);
                    } else {
                        if (modelSettings.attribute.Target !== undefined) {
                            setTarget(modelSettings.attribute.Target);
                        }
                    }
                    if (urlData.tDesc !== undefined) {
                        setTargetDesc(urlData.tDesc);
                    } else {
                        if (modelSettings.attribute.TargetDesc !== undefined) {
                            setTargetDesc(modelSettings.attribute.TargetDesc);
                        }
                    }

                }

                //look for a url parameter of short name + one of Name, 100, Start, Sites, Daily, Loss, Desc to replace that value.

                //var attribs = ["100","Start","Sites","Daily","Loss"];
                var newClassList = [].concat(...classList);

                for (let y = 0; y < newClassList.length; y++) {
                    if (urlData[(newClassList[y].attribute.Short) + "Name"] !== undefined) {
                        newClassList[y].configName = urlData[(newClassList[y].attribute.Short) + "Name"];
                    }
                    if (urlData[(newClassList[y].attribute.Short) + "Note"] !== undefined) {
                        newClassList[y].configNote = urlData[(newClassList[y].attribute.Short) + "Note"];
                    }
                    if (urlData[(newClassList[y].attribute.Short) + "100"] !== undefined) {
                        newClassList[y].attribute.YearsTo100 = urlData[(newClassList[y].attribute.Short) + "100"];
                    }
                    if (urlData[(newClassList[y].attribute.Short) + "Start"] !== undefined) {
                        newClassList[y].attribute.StartYear = urlData[(newClassList[y].attribute.Short) + "Start"];
                    }
                    if (urlData[(newClassList[y].attribute.Short) + "Sites"] !== undefined) {
                        newClassList[y].attribute.NumSites = urlData[(newClassList[y].attribute.Short) + "Sites"];
                    }
                    if (urlData[(newClassList[y].attribute.Short) + "Daily"] !== undefined) {
                        newClassList[y].attribute.DailyWattAvg = urlData[(newClassList[y].attribute.Short) + "Daily"];
                    }
                    if (urlData[(newClassList[y].attribute.Short) + "Loss"] !== undefined) {
                        newClassList[y].attribute.HeatLossPercent = urlData[(newClassList[y].attribute.Short) + "Loss"];
                    }
                }

                setClassList(newClassList);    //replace stored model list with overridden values from url, is temp only for this session
                setRecalcModel(true);
                //  copyToClipboard("hello world");
            }

        }

        doOnReload();

    }, [itemCount])

    async function fetchConfigList() {
        setItemCount(0);

        try {
            const configListDataA = await API.graphql({ query: listConfigs, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });

            //const configListDataA = await API.graphql(graphqlOperation(listConfigs));
            const configListA = configListDataA.data.listConfigs.items;

            const configListB = configListA.map(obj => ({ ...obj, attribute: JSON.parse(obj.attributes) }));
            const cList = [].concat(...configListB).sort(compare);

            setConfigList(cList); //configListA);
            setClassList(cList.filter(obj => obj.attribute.Type === "class"));

            //            if (urlData.t !== undefined) {
            //                setTarget(urlData.t);



            setItemCount(cList.length);
            setMessageLine("");
            return { status: true };

        } catch (error) {
            console.log("error on fetching configList", error);
            return { status: false, error: error };
        }
    };

    async function handleShare() {
        //http://localhost:3000/model?sy=2050&ey=2120&t=10000000000000&tDesc=this%20is%20my%20target%20description%20or%20not&hslName=newname&hsl100=5&hslStart=2027&hslSites=42&hslDaily=500&hslLoss=17
        var str = rootUrl + "model?sy=" + startYear + "&ey=" + endYear + "&t=" + target + "&tDesc=" + targetDesc;

        for (let y = 0; y < classList.length; y++) {
            str += "&" + classList[y].attribute.Short + "Name=" + classList[y].configName;
            str += "&" + classList[y].attribute.Short + "Note=" + classList[y].configNote;
            str += "&" + classList[y].attribute.Short + "100=" + classList[y].attribute.YearsTo100;
            str += "&" + classList[y].attribute.Short + "Start=" + classList[y].attribute.StartYear;
            str += "&" + classList[y].attribute.Short + "Sites=" + classList[y].attribute.NumSites;
            str += "&" + classList[y].attribute.Short + "Daily=" + classList[y].attribute.DailyWattAvg;
            str += "&" + classList[y].attribute.Short + "Loss=" + classList[y].attribute.HeatLossPercent;

        }

        copyToClipboard(str);
        alert("copied to clipboard");
    }

    function renderShareButton() {
        return (
            <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleShare} disabled={false}
            >
                Share
            </Button>
        )
    }

    function renderModel() {
        return (<div className="Model" >
            <Row className="ldHeaderRow">
                Take Back The Heat - Model
            </Row>
            <Row className="ldTopRow">
                <div className="ldMessageContainer" >
                    <div className="ldFormMessage">
                        {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                    </div>
                </div>
            </Row>
            <Row className="ldMidRow">
                <div>
                    Target:{" "}{targetDesc}={" "}{target.toLocaleString('en')}{" (" + target.toExponential() + ")"}
                </div>
            </Row>
            <Row className="ldRow">
                <div>
                    {startYear}{" --> "}{endYear}
                </div>
            </Row>
            <Row className="ldRow">
                <div>
                    {"model total: "}{modelTotalWatts.toLocaleString('en')}{" (" + modelTotalWatts.toExponential() + ")"}
                    {" (" + ((modelTotalWatts / target) * 100).toFixed(4) + "%)"}
                </div>
            </Row>
            <Row className="ldMidRow">
                <div className="ldSmallButton">
                    {renderShareButton()}
                </div>
            </Row>
            <Row className="ldBodyRow">
                <div className="ldSmallButton">
                    <Link to="/post/bfc658b1-c883-413f-ba8a-f475292ff6e1">Forum Discussion</Link>
                </div>
            </Row>
            {modelChartDetail.length > 0 ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    <Chart
                        width={500}
                        height={400}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={modelChartDetail}
                        options={{
                            title: 'Daily Watts',
                            chartArea: { left: "30%", width: '60%', right: "5%" },
                            hAxis: {
                                title: 'Year',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'Watts',
                            },
                            isStacked: true,
                            height: 400,
                            legend: { position: 'top', maxLines: 5, textStyle: { fontSize: 10 } }

                        }}
                        legendToggle
                    />
                    <Chart
                        width={500}
                        height={400}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={modelChartSummary}
                        options={{
                            title: 'Daily Watts',
                            chartArea: { left: "30%", width: '60%', right: "5%" },
                            hAxis: {
                                title: 'Year',
                                minValue: 0,
                            },
                            vAxis: { title: 'Total Watts' },
                            isStacked: true,
                            height: 400,
                            legend: { position: 'top', maxLines: 5, textStyle: { fontSize: 10 } }

                        }}
                        legendToggle
                    />
                </div>
            ) : (
                <div>
                    loading data
                </div>
            )
            }


            <div className="ConfigList">
                {window.innerWidth < 500 ? (
                    <><mark>browser window too small for data table</mark></>
                ) : (
                    <Card className="DataLogCard">
                        <Card.Header>
                            worksheet
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                data={classList}
                                columns={configColumns}
                                // selectRow={selectRow}
                                //rowEvents={rowEvents}
                                cellEdit={cellEdit}
                            >
                            </BootstrapTable>
                        </Card.Body>
                    </Card>
                )}
            </div>

            {
                window.innerWidth < 800 ? (
                    <><mark>browser window too small for detail table</mark></>) : (
                    <Accordion>
                        <Card className="DataLogCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                details...
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <>
                                    <Card.Body>
                                        <div className="ConfigDetailList">
                                            <BootstrapTable
                                                bootstrap4
                                                keyField='year'
                                                data={modelDetail}
                                                columns={modelDetailColumns}
                                            // selectRow={selectRow}
                                            //rowEvents={rowEvents}                    
                                            >
                                            </BootstrapTable>
                                        </div>
                                    </Card.Body>
                                </>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                )
            }


        </div >

        );
    }

    return <div className="Model" >
        <Helmet>
            <title>{"TakeBackTheHeat - Model"}</title>
            <meta name="description" content="A planning tool for understanding the impact and how takebacktheheat.org and related projects can reduce the heat energy to address climate change and global warming." />
        </Helmet>
        {renderModel()}
    </div>;
}
