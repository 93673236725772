import React, { useState, useEffect } from "react";
//import {Storage} from "aws-amplify";
import Select from 'react-select';
import { Col, Row, InputGroup, Accordion, Card } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
//import Chart from "react-google-charts";
import { Helmet } from "react-helmet";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SimpleChart from "../components/SimpleChart";

import { buildStationList } from '../stationData.js';

//import config from '../config';

export default function ShowTemp(props) {

    const [tempData, setTempData] = useState([]);   //temperature data
    const [chartDetail, setChartDetail] = useState([]);
    const [chartDetailMonth, setChartDetailMonth] = useState([]);
    const [stationList, setStationList] = useState([]);
    const [stationOptions, setStationOptions] = useState([]);
    const [measureOptions, setMeasureOptions] = useState([]);
    const [myJson, setMyJson] = useState([]);     //so far don't need to keep this around, doing so for debugging and maybe later use it...
    const [station, setStation] = useState([]);  //"3031320");
    const [stationDetail, setStationDetail] = useState([]);  //instead of using stationList.find(r => r["Climate ID"] = station.value)  multiple times
    const [measure, setMeasure] = useState([]);

    const [monthMinMinValue, setMonthMinMinValue] = useState(); //instead of calculating this 12 times, let's just calc once and store it
    const [monthMinMaxValue, setMonthMinMaxValue] = useState(); //instead of calculating this 12 times, let's just calc once and store it
    const [monthMaxMinValue, setMonthMaxMinValue] = useState(); //instead of calculating this 12 times, let's just calc once and store it
    const [monthMaxMaxValue, setMonthMaxMaxValue] = useState(); //instead of calculating this 12 times, let's just calc once and store it
    const [monthMeanMinValue, setMonthMeanMinValue] = useState(); //instead of calculating this 12 times, let's just calc once and store it
    const [monthMeanMaxValue, setMonthMeanMaxValue] = useState(); //instead of calculating this 12 times, let's just calc once and store it
    const [chartMonthMin, setChartMonthMin] = useState();                   //when changing measure, move above value into min and max to have consistent chart areas for all months
    const [chartMonthMax, setChartMonthMax] = useState();

    //const rootUrl = config.ROOTURL;

    //const stationFile = "https://takebacktheheat.org/StationList.csv"; //"https://nubium.com/publicdownload/StationList.csv";  //new URL(rootUrl + "StationList.csv");  //amazon public folder?

    //const stationFile = "/StationList.json";
        

    function compare(a, b) {
        if (a.dateEpoch < b.dateEpoch) {
            return -1;
        }
        if (a.dateEpoch > b.dateEpoch) {
            return 1;
        }
        return 0;
    }

    function compareStation(a, b) {
        if (a.Name < b.Name) {
            return -1;
        }
        if (a.Name > b.Name) {
            return 1;
        }
        return 0;
    }

    function checkStation(station) {
        return !(station["Station ID"] === undefined);

    }

    const width = window.innerWidth;
    //const height = window.innerHeight;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function chartOptions() {
        const cWidth = width > 800 ? 1000 : 500;
        const cHeight = width > 800 ? 500 : 300;
   //     console.log("width:" + width + " cWidth:" + cWidth);
        return {
            title: 'Temperature',
            chartArea: { left: "10%", width: '100%', height: '55%', right: "10%" },
            hAxis: {
                title: tempData[0].year + " " + tempData[0].month + " - " + tempData[tempData.length - 1].year + " " + tempData[tempData.length - 1].month,
                minValue: tempData[0].yyyymm,
                textPosition: 'none'

            },
            vAxis: {
                title: "C", //tempData[0].units,
            },
            //isStacked: true,
            height: cHeight,
            width: cWidth,
            legend: { position: 'top', maxLines: 5, textStyle: { fontSize: 14 } },
            trendlines: { 0: { color: '#00e600', showR2: true, visibleInLegend: true } }
        }
    }

    function chartMonthOptions(m) {
        return {
            title: 'Temperature - ' + m,
            chartArea: { left: "10%", width: '100%', height: '50%', right: "10%" },
            trendlines: { 0: { color: '#00e600', showR2: true, visibleInLegend: true } },
            hAxis: {
                title: 'Year',
                minValue: tempData[0].year,
                format: '####'
            },
            vAxis: {
                title: "C", //tempData[0].units,
                //minValue: -55, //chartMonthMin,
                //maxValue: 55   //chartMonthMax
                viewWindow: {
                    min: chartMonthMin - 10,
                    max: chartMonthMax + 10
                }
            },
            legend: { position: 'top', maxLines: 5, textStyle: { fontSize: 14 } }
        }
    }

 /*   
    const processCSV = (str, defStation, delim = ',') => {
        const headers = str.slice(0, str.indexOf('\n')).split(delim);
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');

        const newArray = rows.map(row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })

        const blist = newArray.filter(checkStation).sort(compareStation).map((item) => [{ value: item["Climate ID"], label: item["Name"] + " , " + item["Province"] + " : " + item["Climate ID"] + " ( " + item["First Year"] + " -> " + item["Last Year"] + " )" }]);
        const ablist = [].concat(...blist); //.sort(compare);
        setStationList(newArray.sort(compareStation));
        setStationDetail(newArray.find(r => r["Climate ID"] === defStation.value));//needed if stationid passed in as parameter, also for default station

        setStationOptions(ablist);
        /*Last Year:
        '1995'
        Latitude:
        '465700000'
        Latitude (Decimal Degrees):
        '46.95'
        Longitude:
        '-552300000'
        Longitude (Decimal Degrees):
        '-55.38'
        MLY First Year:
        '1957'
        MLY Last Year
        :
        '1995\r'
        Name:
        'WESTBROOK ST LAWRENCE'
        Province:
        'NEWFOUNDLAND'
        Station ID:
        '6759'
        TC ID:
        ''
        WMO ID:
        ''
        */
    //}

    
    const dataColumns = [{
        dataField: 'month',
        text: 'Month',    //name
        sort: true,
        type: 'string'
    }, {
        dataField: 'year',
        text: 'Year',
        sort: true,
        type: 'string',
    }, {
        dataField: 'min_temperature',
        text: 'Min',
        sort: true,
        type: 'number',
    }, {
        dataField: 'max_temperature',
        text: 'Max',
        sort: true,
        type: 'number',
    }, {
        dataField: 'mean_temperature',
        text: 'Mean',
        sort: true,
        type: 'number',
    }, {
        dataField: 'total_precipitation',
        text: 'total_precipitation',
        sort: true,
        type: 'number',
    }, {
        dataField: 'yyyymm',
        text: 'Epoch',
        sort: true,
        type: 'string',
    }, {
        dataField: 'ldate',
        text: 'Local Date',
        sort: true,
        type: 'string',
    }

    ];



    function loadMeasureOptions() {
        const meas = [{ value: "mean", label: "mean" }, { value: "min", label: "min" }, { value: "max", label: "max" }]
        setMeasureOptions(meas);
    }

    function getTempData(climateId) {
        //fetch('3031094-mean.json'
        fetch("https://api.weather.gc.ca/collections/climate-monthly/items?datetime=1800-01/2024-01&CLIMATE_IDENTIFIER=" + climateId + "&sortby=PROVINCE_CODE,CLIMATE_IDENTIFIER,LOCAL_DATE&f=json&limit=100000&startindex=0"
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then(json => {
                setMyJson(json);
                //         setDataFetched(true);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    useEffect(() => {

        function doParseData() {
            var newA = [];
            if (!(myJson.features === undefined)) {
                for (let i = 0; i < myJson.features.length; i += 1) {
                    if (!(myJson.features[i].properties.MAX_TEMPERATURE === null && myJson.features[i].properties.MEAN_TEMPERATURE === null && myJson.features[i].properties.MIN_TEMPERATURE === null)) {
                        let r = {
                            dateEpoch: new Date(myJson.features[i].properties.LOCAL_YEAR, myJson.features[i].properties.LOCAL_MONTH - 1, 15).getTime() / 1000,
                            month: parseInt(myJson.features[i].properties.LOCAL_MONTH), //new Date(myJson.features[i].properties.year__annee, months[myJson.features[i].properties.period__periode], 15),
                            year: myJson.features[i].properties.LOCAL_YEAR,
                            yyyymm: new Date(myJson.features[i].properties.LOCAL_YEAR, myJson.features[i].properties.LOCAL_MONTH - 1, 15).getTime() / 1000,
                            ldate: myJson.features[i].properties.LOCAL_DATE,
                            mean_temperature: myJson.features[i].properties.MEAN_TEMPERATURE,
                            max_temperature: myJson.features[i].properties.MAX_TEMPERATURE,
                            min_temperature: myJson.features[i].properties.MIN_TEMPERATURE,
                            total_precipitation: myJson.features[i].properties.TOTAL_PRECIPITATION
                        }
                        newA.push(r);
                    }
                }
            }
            if (newA.length === 0) { //there was no valid data.  perhaps all percipitation
                newA.push({ dataEpoch: new Date("2099-01-01").getTime() / 1000, month: 1, year: 2099, yyyymm: new Date("2099-01-01").getTime() / 1000, ldate: "2099-01", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-02-01").getTime() / 1000, month: 2, year: 2099, yyyymm: new Date("2099-02-01").getTime() / 1000, ldate: "2099-02", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-03-01").getTime() / 1000, month: 3, year: 2099, yyyymm: new Date("2099-03-01").getTime() / 1000, ldate: "2099-03", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-04-01").getTime() / 1000, month: 4, year: 2099, yyyymm: new Date("2099-04-01").getTime() / 1000, ldate: "2099-04", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-05-01").getTime() / 1000, month: 5, year: 2099, yyyymm: new Date("2099-05-01").getTime() / 1000, ldate: "2099-05", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-06-01").getTime() / 1000, month: 6, year: 2099, yyyymm: new Date("2099-06-01").getTime() / 1000, ldate: "2099-06", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-07-01").getTime() / 1000, month: 7, year: 2099, yyyymm: new Date("2099-07-01").getTime() / 1000, ldate: "2099-07", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-08-01").getTime() / 1000, month: 8, year: 2099, yyyymm: new Date("2099-08-01").getTime() / 1000, ldate: "2099-08", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-09-01").getTime() / 1000, month: 9, year: 2099, yyyymm: new Date("2099-09-01").getTime() / 1000, ldate: "2099-09", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-10-01").getTime() / 1000, month: 10, year: 2099, yyyymm: new Date("2099-10-01").getTime() / 1000, ldate: "2099-10", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-11-01").getTime() / 1000, month: 11, year: 2099, yyyymm: new Date("2099-11-01").getTime() / 1000, ldate: "2099-11", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
                newA.push({ dataEpoch: new Date("2099-12-01").getTime() / 1000, month: 12, year: 2099, yyyymm: new Date("2099-12-01").getTime() / 1000, ldate: "2099-12", mean_temperature: 0, max_temperature: 0, min_temperature: 0, total_precipitation: 0 });
            }
            setTempData(newA);

        };

        doParseData();
    }, [myJson]);



    useEffect(() => {
        function doLoadMonth() {
            loadMonth();
        }
        doLoadMonth();
        /* eslint-disable */
    }, [chartDetail]);

    useEffect(() => {
        function doLoadFull() {
            loadFull();
            if (tempData.length > 0) {
                setMonthMinMinValue(monthMin(tempData, "min"));
                setMonthMinMaxValue(monthMax(tempData, "min"));
                setMonthMaxMinValue(monthMin(tempData, "max"));
                setMonthMaxMaxValue(monthMax(tempData, "max"));
                setMonthMeanMinValue(monthMin(tempData, "mean"));
                setMonthMeanMaxValue(monthMax(tempData, "mean"));

            }
        }
        doLoadFull();
        /* eslint-disable */
    }, [tempData]);

    useEffect(() => {
        function doOnLoad() {
            var defStation;
            if (props.match.params.id === undefined) {
                defStation = { value: "3031094", label: "CALGARY INT'L C S" };
            } else {
                defStation = { value: props.match.params.id, label: props.match.params.id };
            }
            setStation(defStation);
            //fetchStationData(defStation);
            const stationListRaw = buildStationList();
            const blist = stationListRaw.filter(checkStation).sort(compareStation).map((item) => [{ value: item["Climate ID"], label: item["Name"] + " , " + item["Province"] + " : " + item["Climate ID"] + " ( " + item["First Year"] + " -> " + item["Last Year"] + " )" }]);
            const ablist = [].concat(...blist); //.sort(compare);
            setStationList(stationListRaw.sort(compareStation));
            setStationDetail(stationListRaw.find(r => r["Climate ID"] === defStation.value));//needed if stationid passed in as parameter, also for default station

            setStationOptions(ablist);


            loadMeasureOptions();

            setMeasure({ value: "mean", label: "mean" });
        }
        doOnLoad();
        /* eslint-disable */
    }, []);

    useEffect(() => {
        function doOnStation() {
            getTempData(station.value);
        }
        doOnStation();
        /* eslint-disable */
    }, [station]);

    useEffect(() => {
        function doOnMeasure() {
            loadFull();
            loadMonth();
        }
        doOnMeasure();
        /* eslint-disable */
    }, [measure]);

    function loadFull() {   //load data from tempData into table for full detail chart
        var cData = [];
        var cRHeader = [["Date", measure.value, { role: "tooltip", type: "string", p: { html: true } }]];//{ type: 'datetime', label: 'date'  },{ type: 'number', label: 'mean'  }];

        tempData.sort(compare).forEach(function (row) {
            switch (measure.value) {
                case "min":
                    cData.push([row.yyyymm, row.min_temperature, row.year + " " + row.month + ":" + row.min_temperature]);
                    break;
                case "max":
                    cData.push([row.yyyymm, row.max_temperature, row.year + " " + row.month + ":" + row.max_temperature]);
                    break;
                default:
                    cData.push([row.yyyymm, row.mean_temperature, row.year + " " + row.month + ":" + row.mean_temperature]);
            }


        });

        setChartDetail([].concat(cRHeader).concat(cData));
    }

    function loadMonth() {  //load data from tempData into table for month charts
        var cDataMonth = [];
        var cRHeader = [["Date", "Month", measure.value, { role: "tooltip", type: "string", p: { html: true } }]];//{ type: 'datetime', label: 'date'  },{ type: 'number', label: 'mean'  }];

        tempData.sort(compare).forEach(function (row) {
            switch (measure.value) {
                case "min":
                    cDataMonth.push([row.year, row.month, row.min_temperature, row.year + ":" + row.min_temperature]);
                    break;
                case "max":
                    cDataMonth.push([row.year, row.month, row.max_temperature, row.year + ":" + row.max_temperature]);
                    break;
                default:
                    cDataMonth.push([row.year, row.month, row.mean_temperature, row.year + ":" + row.mean_temperature]);
            }
        });

        setChartDetailMonth([].concat(cRHeader).concat(cDataMonth));

        var years;
        var diff;
        var yrate;
        if (cDataMonth.length > 0) {
            years = cDataMonth[cDataMonth.length - 1][0] - cDataMonth[0][0] + 1;
            diff = cDataMonth[cDataMonth.length - 1][2] - cDataMonth[0][2];
            yrate = diff / years;
        } else {
            yrate = 0;
            diff = 0;
            years = 0;
        }
    }

    /******************************************************************************************* */

    function monthMin(arr, meas) {
        if (arr.length > 0) {
            switch (meas) {
                case "min":
                    return arr.reduce((min, p) => p.min_temperature < min ? p.min_temperature : min, arr[0].min_temperature);
                case "max":
                    return arr.reduce((min, p) => p.max_temperature < min ? p.max_temperature : min, arr[0].max_temperature);
                default:
                    return arr.reduce((min, p) => p.mean_temperature < min ? p.mean_temperature : min, arr[0].mean_temperature);
            }
        }
        return -50;
    }

    function monthMax(arr, meas) {
        if (arr.length > 0) {
            switch (meas) {
                case "min":
                    return arr.reduce((max, p) => p.min_temperature > max ? p.min_temperature : max, arr[0].min_temperature);
                case "max":
                    return arr.reduce((max, p) => p.max_temperature > max ? p.max_temperature : max, arr[0].max_temperature);
                default:
                    return arr.reduce((max, p) => p.mean_temperature > max ? p.mean_temperature : max, arr[0].mean_temperature);
            }
        }
        return 50;
    }

    function removeEl(array, remIdx) {
        return array.map(function (arr) {
            return arr.filter(function (el, idx) { return idx !== remIdx });
        });
    };

    function handleStationListChange(event) {     //will become blog list
        setStation({ value: event.value, label: event.label });
        setStationDetail(stationList.find(r => r["Climate ID"] === event.value));
        //reload
    };

    function handleMeasureChange(event) {
        setMeasure({ value: event.value, label: event.label });
        switch (event.label) {
            case "min":
                setChartMonthMin(monthMinMinValue);
                setChartMonthMax(monthMinMaxValue);
                break;
            case "max":
                setChartMonthMin(monthMaxMinValue);
                setChartMonthMax(monthMaxMaxValue);
                break;
            default:
                setChartMonthMin(monthMeanMinValue);
                setChartMonthMax(monthMeanMaxValue);
                break;
        }
    }
//        return (<div className="ShowTemp" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>

    function renderShowTemp() {
        return (<div className="What" >
            <p></p>
             <div className='justify-content-center'><h1>Take Back The Heat - Canadian Historic Temperature</h1></div>
            <p></p>
            <Row className="ldTopRow d-flex justify-content-center" style={{display: 'flex', flexWrap: 'wrap'}}>

                <Col md={12} lg={4} className="justify-content-left" >
                    <InputGroup>
                        <InputGroup.Text>Station</InputGroup.Text>
                        <Select className="selectCategory"
                            value={station || ''}
                            onChange={handleStationListChange}
                            options={stationOptions}
                            placeholder=""
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            
                        />
                    </InputGroup>
                </Col>
                <Col md={12} lg={4} className="justify-content-center">
                    {stationDetail === undefined ? (
                        <div>
                        <h2><font color="red"><i>Unable to load Station List (Try Edge or Firefox)</i></font></h2> </div>)
                    : (
                        <div>
                        <h2>{stationDetail.Name}</h2>
                        <h6>{stationDetail.Province}</h6>
                        <h6>{stationDetail["Climate ID"]}</h6>
                        <h6>{"( " + stationDetail["First Year"] + " -> " + stationDetail["Last Year"] + " )"}</h6> </div>) 
                }
                </Col>
                <Col md={12} lg={4} className="justify-content-right">
                    <InputGroup>
                        <InputGroup.Text>Measure</InputGroup.Text>
                        <Select className="selectCategory"
                            value={measure || ''}
                            onChange={handleMeasureChange}
                            options={measureOptions}
                            placeholder=""
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </InputGroup>
                    <Link to="/post/053e530f-689c-4402-884d-f98529dd9862">Forum Discussion</Link>
                </Col>
            </Row>
            {tempData.length > 0 ? (
                <div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>

                        <SimpleChart
                            chartType="LineChart"
                            data={chartDetail}
                            options={chartOptions()}
                        />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Jan")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 1 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Feb")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 2 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Mar")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 3 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Apr")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 4 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("May")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 5 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Jun")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 6 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Jul")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 7 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Aug")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 8 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Sep")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 9 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Oct")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 10 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"400"} chartType="LineChart" options={chartMonthOptions("Nov")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 11 || v[1] === 'Month')), 1)}
                        />
                        <SimpleChart width={"300"} height={"100"} chartType="LineChart" options={chartMonthOptions("Dec")}
                            data={removeEl(chartDetailMonth.filter(v => (v[1] === 12 || v[1] === 'Month')), 1)}
                        />
                    </div>
                    <h6>Raw data from <a href="https://api.weather.gc.ca">https://api.weather.gc.ca</a>.  Station List from <a href="https://drive.google.com/drive/folders/1WJCDEU34c60IfOnG4rv5EPZ4IhhW9vZH">https://drive.google.com/drive/folders/1WJCDEU34c60IfOnG4rv5EPZ4IhhW9vZH</a></h6>
                    <h6>Rows with all nulls (temperature min,max,mean) are removed. When no data available, 12 2099 rows inserted.</h6>

                </div>
            ) : (
                <div>
                    loading data
                </div>
            )
            }
            {
                window.innerWidth < 800 ? (
                    <><mark>browser window too small for detail table</mark></>) : (
                    <Accordion>
                        <Card className="DataLogCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                details...
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <>
                                    <Card.Body>
                                        <div className="ConfigDetailList">
                                            <BootstrapTable
                                                bootstrap4
                                                keyField='yyyymm'
                                                data={tempData}
                                                columns={dataColumns}
                                            >
                                            </BootstrapTable>
                                        </div>
                                    </Card.Body>
                                </>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                )
            }
        </div>
        );
    }

    return <div className="ShowTemp" >
        <Helmet>
            <title>{"TakeBackTheHeat - Show Canadian Historic Temperature"}</title>
            <meta name="description" content="takebacktheheat.org temperature history by location" />
        </Helmet>
        {renderShowTemp()}
    </div>;
}
