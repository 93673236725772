import React from "react";
import { Route, Switch } from "react-router-dom";

//import { useLocation } from "react-router-dom";
//import analytics from "./analytics";

import Admin from "./containers/Admin";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import About from "./containers/About";
import AddEvent from "./containers/AddEvent";
import Context from "./containers/Context";
import DataLog from "./containers/DataLog";
import Etc from "./containers/Etc";
import CalcPanel from "./containers/CalcPanel";
import ShowTemp from "./containers/ShowTemp";
import News from "./containers/News";
import Products from "./containers/Products";
import Post from "./containers/Post";
import PostEdit from "./containers/PostEdit";
import PostNew from "./containers/PostNew";
import What from "./containers/What";
import Who from "./containers/Who";
import Why from "./containers/Why";
import How from "./containers/How";
import Forum from "./containers/Forum";
import Registry from "./containers/Registry";
import Model from "./containers/Model";
import Roadmap from "./containers/Roadmap";
import Where from "./containers/Where";
import GetInvolved from "./containers/GetInvolved";
import Signup from "./containers/Signup";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";
import NotFound from "./containers/NotFound";
import Video from "./containers/Video";

export default function Routes({ appProps }) {
//    function useGoogleAnalytics() {
//        const location = useLocation();
//        React.useEffect(() => {
//            analytics.init()
//        }, [])

//        React.useEffect(() => {
//            const currentPath = location.pathname + location.search;
//            analytics.sendPageview(currentPath);
//        }, [location])

//    }

//    useGoogleAnalytics();

    return (
    <Switch>
        <AppliedRoute path="/registry/:id/dataLogs" exact component={AddEvent}  appProps={appProps}/>
        <AppliedRoute path="/home"                exact component={Home}  appProps={appProps}/>
        <AppliedRoute path="/where"               exact component={Where} appProps={appProps}/>
        <AppliedRoute path="/registry"            exact component={Registry} appProps={appProps}/>
        <AppliedRoute path="/dataLog/:id"         exact component={DataLog} appProps={appProps}/>
        <AppliedRoute path="/about"               exact component={About} appProps={appProps}/>
        <AppliedRoute path="/index.html"          exact component={Home}  appProps={appProps}/>
        <AppliedRoute path="/"                    exact component={Products}  appProps={appProps}/>
        <AppliedRoute path="/etc"                 exact component={Etc}   appProps={appProps}/>
        <AppliedRoute path="/calcPanel"           exact component={CalcPanel}  appProps={appProps}/>
        <AppliedRoute path="/showTemp"            exact component={ShowTemp}  appProps={appProps}/>
        <AppliedRoute path="/showTemp/:id"        exact component={ShowTemp}  appProps={appProps}/>
        <AppliedRoute path="/news"                exact component={News}  appProps={appProps}/>
        <AppliedRoute path="/products"                exact component={Products}  appProps={appProps}/>
        <AppliedRoute path="/post"                exact component={Post}   appProps={appProps}/>
        <AppliedRoute path="/post/new"            exact component={PostNew}appProps={appProps}/>
        <AppliedRoute path="/post/:id"            exact component={Post}   appProps={appProps}/>
        <AppliedRoute path="/postEdit/:id"        exact component={PostEdit} appProps={appProps}/>
        <AppliedRoute path="/what"                exact component={What}  appProps={appProps}/>
        <AppliedRoute path="/why"                 exact component={Why}   appProps={appProps}/>
        <AppliedRoute path="/who"                 exact component={Who}   appProps={appProps}/>
        <AppliedRoute path="/how"                 exact component={How}   appProps={appProps}/>
        <AppliedRoute path="/context"             exact component={Context}  appProps={appProps}/>
        <AppliedRoute path="/forum"               exact component={Forum} appProps={appProps}/>
        <AppliedRoute path="/forum/:id"           exact component={Forum} appProps={appProps}/>
        <AppliedRoute path="/getInvolved"         exact component={GetInvolved} appProps={appProps}/>
        <AppliedRoute path="/model"               exact component={Model} appProps={appProps}/>
        <AppliedRoute path="/roadmap"             exact component={Roadmap} appProps={appProps}/>
        <AppliedRoute path="/admin"               exact component={Admin}  appProps={appProps}/>
        <UnauthenticatedRoute path="/signup"      exact component={Signup} appProps={appProps}/>
        <UnauthenticatedRoute path="/login"       exact component={Login} appProps={appProps}/>
        <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} appProps={appProps}/>
        <AuthenticatedRoute path="/changePassword" exact component={ChangePassword} appProps={appProps} />
        <AppliedRoute path="/video"               exact component={Video} appProps={appProps} />
        <AppliedRoute path="/:slug"               exact component={Post}   appProps={appProps}/>
        { /* Finally, catch all unmatched routes */} 
        <Route component={NotFound}/>
    </Switch>
    );
}