import React, { useState, useEffect, useRef } from "react";
//import { API, graphqlOperation } from 'aws-amplify';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Button, Card, Row, Col, InputGroup, FormControl, Accordion } from "react-bootstrap";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import Checkbox from '../components/Checkbox';
import { createRegistry, updateRegistry, deleteRegistry, listAllRegistry } from '../graphql/mutations';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

//import { listRegistrys } from '../graphql/queries';

import { buildDeviceTypeOptions, buildRowStateOptions, buildPerPageOptions } from '../data.js';
import { copyToClipboard } from '../common.js';

import { Helmet } from "react-helmet";

export default function Registry(props) {
    const [registryList, setRegistryList] = useState([]);

    const [selectedRegistry, setSelectedRegistry] = useState();

    //    const [formData, setFormData] = useState();       //using a generic onChange was updating the state but the visible form was delayed in getting updated, happens later after doing dropdown etc. not good

    const [formId, setFormId] = useState();
    const [formType, setFormType] = useState();
    const [formDescription, setFormDescription] = useState();
    const [formName, setFormName] = useState();
    const [formRowState, setFormRowState] = useState();
    const [formLat, setFormLat] = useState();
    const [formLng, setFormLng] = useState();
    const [formDailyAvgW, setFormDailyAvgW] = useState();
    const [formUpdateFromDataLog, setFormUpdateFromDataLog] = useState();
    const [formIncludeInDashTotal, setFormIncludeInDashTotal] = useState();
    const [formDataLogReadKey, setFormDataLogReadKey] = useState();
    const [formDataLogWriteKey, setFormDataLogWriteKey] = useState();
    const [formCreatedAt, setFormCreatedAt] = useState();
    const [formUpdatedAt, setFormUpdatedAt] = useState();

    const [isFetching, setIsFetching] = useState(false);

    const [messageLine, setMessageLine] = useState();
    const [isEdited, setIsEdited] = useState(false);
    const [isShowAll, setIsShowAll] = useState(false);         //change to false

    const readKeyRef = useRef(null);
    const writeKeyRef = useRef(null);
    const idRef = useRef(null);

    const { v4: uuidv4 } = require("uuid");

    const deviceTypeOptions = buildDeviceTypeOptions();
    const rowStateOptions = buildRowStateOptions();

    const perPageOptions = buildPerPageOptions();
    const [perPage, setPerPage] = useState(20);       //limit count for downloading data log list

    const [dNextPageTokenList, setDNextPageTokenList] = useState([]);      //an array of next tokens we have collected so far.  if undefined, means no more pages.  so [0] may be undefined, or next group...
    const [dCurrentPage, setDCurrentPage] = useState(0);   //what page of data are we currently looking at?  will only be built up as we walk through data, only supporting prev/next  nothing fancy here
    const [itemCount, setItemCount] = useState(0);



    function ActionFormatter(data) {
        return (
            <div className="ButtonColumn">
                <Button type="button" variant="outline-secondary" className="ActionButton" size="sm" onClick={() => handleDataLogButton(data.row)}>datalog</Button>
                {(props.userDetails.username === data.row.owner) ? (<>
                    <Button type="button" variant="outline-danger" className="ActionButton" size="sm" onClick={() => handleDeleteButton(data.row)}>delete</Button></>) : (<></>)}
            </div>
        );
    }
    const actionFormatter = (cell, row) => <ActionFormatter row={row} />;

    const registryColumns = window.innerWidth > 500 ? [{
        dataField: 'action',
        isDummyField: true,
        csvExport: false,
        formatter: actionFormatter,
        formatExtraData: props.userDetails.username,
        headerStyle: (column, colIndex) => { return { width: '16rem' }; },
        text: ''
    }, {
        dataField: 'deviceType',
        headerStyle: (column, colIndex) => { return { width: '12rem' }; },
        text: 'Type',
        sort: true
    }, {
        dataField: 'deviceName',
        text: 'Name',
        sort: true
        //    }, {
        //        dataField: 'createdAt',
        //        text: 'Created At'
    }, {
        dataField: 'rowState',
        headerStyle: (column, colIndex) => { return { width: '8rem' }; },
        text: 'State',
        sort: true
    }
    ] :
    [{
        dataField: 'action',
        isDummyField: true,
        csvExport: false,
        formatter: actionFormatter,
        formatExtraData: props.userDetails.username,
        headerStyle: (column, colIndex) => { return { width: '16rem' }; },
        text: ''
    }, {
        dataField: 'deviceName',
        text: 'Name',
        sort: true
        //    }, {
        //        dataField: 'createdAt',
        //        text: 'Created At'
    }
    ];

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            alert(row.content);
            //props.history.push("/post/" + row.id);
        }
    }

    const selectRow = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            setSelectedRegistry(row);
            //   setFormData(row);
            setFormId(row.id);
            setFormType({ label: row.deviceType, value: row.deviceType });
            setFormName(row.deviceName);
            setFormDescription(row.deviceDescription);
            setFormRowState({ label: row.rowState, value: row.rowState });
            setFormLat(row.lat);
            setFormLng(row.lng);
            setFormDailyAvgW(row.dailyAverageW);
            setFormUpdateFromDataLog(row.updateFromDataLog === true ? true : false);
            setFormIncludeInDashTotal(row.includeInDashTotal === true ? true : false);
            setFormDataLogReadKey(row.dataLogReadKey);
            setFormDataLogWriteKey(row.dataLogWriteKey);
            setFormCreatedAt(row.createdAt);
            setFormUpdatedAt(row.updatedAt);
            //setDdeviceType({label:row.deviceType,value:row.deviceType});

        }
    };

    function handleDeviceTypeChange(e) {
        setIsEdited(true);
        //       const nFormData = formData;
        //       nFormData.deviceType = e.value;
        //       setFormData(nFormData);
        //       setDdeviceType({label:e.value,value:e.value});
        setFormType({ label: e.value, value: e.value });

    }

    //    function handleDescriptionChange(e) {
    //        setFormDescription(e.target.value);
    //    }

    /*
        function handleChange(event) {   //this doesn't update the form after changes...?
            if (selectedRegistry !== undefined) { //don't edit if they haven't selected one
                setIsEdited(true);
                console.log("in handleChange:", event);
                const nFormData = formData;
                if (event.target.type === 'checkbox') {
                    nFormData[event.target.name] = event.target.checked;
                } else {
                    nFormData[event.target.name] = event.target.value;
                }
                setFormData(nFormData);
            }
        }
    */


    function handleDeviceNameChange(e) {
        setIsEdited(true);
        setFormName(e.target.value);
    }

    function handleRowStateChange(e) {
        setIsEdited(true);
        setFormRowState({ label: e.value, value: e.value });
    }

    function handleDeviceDescriptionChange(e) {
        setIsEdited(true);
        setFormDescription(e.target.value);
    }

    function handleLatChange(e) {
        setIsEdited(true);
        setFormLat(e.target.value);
    }

    function handleLngChange(e) {
        setIsEdited(true);
        setFormLng(e.target.value);
    }

    function handleDailyAvgWChange(e) {
        setIsEdited(true);
        setFormDailyAvgW(e.target.value);
    }

//    function handleUpdateFromDataLogChange(e) {
//        setIsEdited(true);
//        setFormUpdateFromDataLog(e.target.checked);
//    }

    function handleIncludeInDashTotal(e) {
        setIsEdited(true);
        setFormIncludeInDashTotal(e.target.checked);
    }
    /*
        useEffect(() => {
            if (isFetching !== true) {
                setIsFetching(true);
                setDCurrentPage(0);
                fetchRegistryList(0);
                // gets hung up on not having this function within useEffect, but we also call it later to reload.
                // interweb seems divided on how best to code. see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
                // eslint-disable-next-line
                setIsFetching(false);
            }
            // eslint-disable-next-line
        }, []);
    */
    useEffect(() => {
        //reset with new list
        if (isFetching !== true) {  //doesn't work, can still end up in here multiple times...   TODO
            setIsFetching(true);
            setDNextPageTokenList([]);
            setDCurrentPage(0);
            fetchRegistryList(0);
//            console.log("in useEffect isShowAll,perPage");
            setIsFetching(false);
        }
        // eslint-disable-next-line
    }, [isShowAll, perPage]);

    async function fetchRegistryList(page) {
        if (props.isAuthenticated) {
            var dToken;
            if (page === 0) {
                dToken = undefined;
            } else {
                dToken = JSON.stringify(dNextPageTokenList[page - 1]);
            }

            setItemCount(0);

            try {
                //var registryListDataA;
                const registryListDataAJSON = await API.graphql({
                    //                query: (props.isAuthenticated ?
                    //                    listRegistrys
                    //                    : listRegistrysNoKeys)
                    //                ,
                    query: listAllRegistry,
                    variables: {
                        input: {
                            isMine: !isShowAll,
                            sortDirection: 'DESC',
                            limit: perPage,
                            nextToken: dToken
                        }
                    },
                    authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY')
                });

                //            if (props.isAuthenticated) {        
                //                const registryListDataA = await API.graphql(graphqlOperation(listRegistrys));
                //                registryListDataA = await API.graphql(graphqlOperation(listRegistrys));
                //            } else {
                //                registryListDataA = await API.graphql(graphqlOperation(listRegistrysNoKeys));
                //            };

                //            const registryListA = JSON.parse(registryListDataA.data.listAllRegistry.Items); //registryListDataA.data.listAllRegistry.data; //JSON.parse(registryListDataA.data.listAllRegistry).data;   //items;
                //const registryListA = registryListDataA.data.listAllRegistry.Items; //registryListDataA.data.listAllRegistry.data; //JSON.parse(registryListDataA.data.listAllRegistry).data;   //items;

                //const registryListA = JSON.parse(JSON.parse(registryListDataA.data.listAllRegistry).body)
                //                setRegistryList(registryListA.Items);

                //      const registryResult = JSON.parse(registryListDataA.data.listAllRegistry);
                //const registryListA = JSON.parse(registryResult).body;       //this doesn't work...wt?
                //ld  const registryListA = JSON.parse(JSON.parse(registryListDataA.data.listAllRegistry).body);

                const registryListDataA = JSON.parse(registryListDataAJSON.data.listAllRegistry);
                const registryListA = registryListDataA.body.Items;
                setRegistryList(registryListA);
                //ld  const nextToken = JSON.parse(JSON.parse(registryListDataA.data.listAllRegistry).body).LastEvaluatedKey;
                const nextToken = registryListDataA.body.LastEvaluatedKey;

//                console.log("nextToken:", nextToken);
                updateDNextPageTokenList(page, nextToken);

                setItemCount(registryListA.length);
                setMessageLine("");
                return { status: true };

                //            } else {       //if not authenticated then they don't see anything
                //                alert("Please log in to review your devices");
                //            }
            } catch (error) {
                console.log("error on fetching registryList", error);
                return { status: false, error: error };
            }
        }
    };

    function handleDataLogButton(e) {
        props.history.push("/dataLog/" + e.id);
    }

    async function handleDeleteButton(e) {
        let cResult = window.confirm("Delete " + e.deviceName + " ?");
        if (cResult) {
            try {
                const delRegistry = {
                    id: e.id
                };
                await API.graphql({ query: deleteRegistry, variables: { input: delRegistry }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
                await fetchRegistryList(dCurrentPage);
                setMessageLine("Deleted " + e.deviceName);
            } catch (error) {
                alert(error);
            }
        }
    }

    async function saveRegistry() {
        var ret = { result: true };
        const newRegistry = {
            id: formId,
            rowState: formRowState.value,
            deviceType: formType.value,
            deviceName: formName,
            deviceDescription: formDescription,
            lat: formLat,
            lng: formLng,
            dailyAverageW: formDailyAvgW,
            updateFromDataLog: formUpdateFromDataLog,
            includeInDashTotal: formIncludeInDashTotal
        };
        try {
            await API.graphql({ query: updateRegistry, variables: { input: newRegistry }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
            setIsEdited(false);
            await fetchRegistryList(dCurrentPage);
        } catch (error) {
            ret = { result: false, message: error.errors[0].message };
        }

        return ret;
    };


    async function newRegistry() {
        var ret = { result: true };
        const newRegistry = {
            rowState: "g",
            deviceType: "heatback1",
            deviceName: "New",
            deviceDescription: "New",
            dailyAverageW: 0,
            updateFromDataLog: false,
            includeInDashTotal: false,
            dataLogReadKey: uuidv4(),
            dataLogWriteKey: uuidv4()
        };
        try {
            await API.graphql({ query: createRegistry, variables: { input: newRegistry }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
            setIsEdited(false);
            await fetchRegistryList(dCurrentPage);
        } catch (error) {
            ret = { result: false, message: error.errors[0].message };
        }

        return ret;
    };


    function validateForm() {
        let ret = true;
        let msg = "";
        setMessageLine(msg);
        return ret;
    }

    async function handleSave() {
        if (!validateForm()) {
            return;
        }
        const saveResult = await saveRegistry();
        if (saveResult.result === true) {
            setMessageLine("Saved");
        } else {
            setMessageLine("Unable to save: " + saveResult.message);
        }
    }

    async function handleNew() {
        const saveResult = await newRegistry();
        if (saveResult.result === true) {
            setMessageLine("New Device Created");
        } else {
            setMessageLine("Unable to save: " + saveResult.message);
        }
    }

    async function handleReadCopy(e) {
        //    alert(readKeyRef.current.value);
        copyToClipboard(readKeyRef.current.value);
        setMessageLine("copied to clipboard");
    }

    async function handleWriteCopy(e) {
        //   alert(readKeyRef.current.value);
        copyToClipboard(writeKeyRef.current.value);
        setMessageLine("copied to clipboard");
    }


    async function handleIdCopy(e) {
        //   alert(readKeyRef.current.value);
        copyToClipboard(idRef.current.value);
        setMessageLine("copied to clipboard");
    }


    async function handleIsShowAll(e) {
        setIsShowAll(e.target.checked);
    }

    async function handlePrev() {
        const fetchResult = await fetchRegistryList(dCurrentPage - 1);
        if (fetchResult.status === true) {
            setDCurrentPage(dCurrentPage - 1);
        } else {
            console.log("unable to get previous page:" + dCurrentPage - 1);
        }
    }

    async function handleNext() {
        const fetchResult = await fetchRegistryList(dCurrentPage + 1);
        if (fetchResult.status === true) {
            setDCurrentPage(dCurrentPage + 1);
        } else {
            console.log("unable to get next page:" + dCurrentPage + 1);
        }
    }

    async function handlePerPageChange(e) {
        setPerPage(e.value);

    }

    function updateDNextPageTokenList(i, v) {
        var items = dNextPageTokenList;
        items[i] = v;
        setDNextPageTokenList(items);
    }




    function renderNewButton() {
        return props.isAuthenticated ? (
            <Button className="float-right ldButton" middle="xs" onClick={handleNew} disabled={(isEdited)}
            >
                New
            </Button>
        ) : (<></>
        )
    }

    function renderSaveButton() {
        if (selectedRegistry === undefined) {
            return (<></>)
        } else {
            if (selectedRegistry.owner && selectedRegistry.owner === props.userDetails.username) {
                return (
                    <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleSave} disabled={(!isEdited)}
                    >
                        Save
                    </Button>
                )
            } else {
                return (
                    <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleSave} disabled={true}
                    >
                        Save
                    </Button>
                )
            }
        }
    }

    function renderShowAllCheckBox() {
        return (
            <Checkbox
                isSelected={isShowAll || false}
                name="isShowAll"
                onChange={handleIsShowAll}
                className="ldCheckbox"
                disabled={false}
            />
        )
    }

    function renderPrevButton() {
        return (
            <Button className="ldButton" middle="xs" onClick={handlePrev} disabled={dCurrentPage === 0}
            >
                Prev
            </Button>
        )
    }
    function renderNextButton() {
        return (
            <Button className="ldButton" middle="xs" onClick={handleNext} disabled={(dNextPageTokenList[dCurrentPage] === undefined || dNextPageTokenList[dCurrentPage] === null)}
            >
                Next
            </Button>
        )
    }

    function renderPerPage() {
        return (
            <Select className="selectPerPage"
                value={{ name: perPage, label: perPage }}
                name="perPage"
                onChange={handlePerPageChange}
                options={perPageOptions}
                placeholder=""
                isDisabled={false}
            />
        )
    }

    function renderItemCount() {
        return (<div className="ldItemCount">
            ({itemCount})
        </div>
        )
    }

    /*   removed after data avg watts.  decide later if we want to calculate the daily number from log, what happens if it's not there etc.
                        <Col sm={2} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>DataLog Update</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Checkbox
                                isSelected={formUpdateFromDataLog || false}
                                name="updateFromDataLog"
                                onChange={handleUpdateFromDataLogChange}
                                className="FormCheckbox"
                                disabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>

    */

    function renderCardBody() {
        return (
            <Card.Body className="ldCardBody">
                {/*selectedPost.content*/}
                <Row className="ldBodyRow">
                    <Col sm={5} className="ldBodyColLeft">
                        <InputGroup className="ldInputGroup">
                            <InputGroup.Prepend>
                                <InputGroup.Text >Type</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Select className="selectCategory foxrm-control"
                                value={formType || ''}
                                name="deviceType"
                                onChange={handleDeviceTypeChange}
                                options={deviceTypeOptions}
                                placeholder=""
                                isDisabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={2} className="ldBodyColLeft">
                        <InputGroup className="ldInputGroup">
                            <InputGroup.Prepend>
                                <InputGroup.Text >State</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Select className="selectRowState"
                                value={formRowState || ''}
                                name="rowState"
                                onChange={handleRowStateChange}
                                options={rowStateOptions}
                                placeholder=""
                                isDisabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>

                    <Col sm={5} className="ldBodyColRight">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="name"
                                value={formName || ''}
                                name="deviceName"
                                onChange={handleDeviceNameChange}
                                disabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="ldBodyRow">
                    <InputGroup >
                        <InputGroup.Prepend>
                            <InputGroup.Text>Description</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea"
                            className="formDescriptionText"
                            rows={3}
                            placeholder="description"
                            value={formDescription || ''}
                            name="deviceDescription"
                            onChange={handleDeviceDescriptionChange}
                            disabled={selectedRegistry === undefined}
                        />
                    </InputGroup>

                </Row>
                <Row className="ldBodyRow">
                    <Col sm={2} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>lat</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="lat"
                                value={formLat || ''}
                                name="lat"
                                onChange={handleLatChange}
                                disabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={2} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>lng</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="lng"
                                value={formLng || ''}
                                name="lng"
                                onChange={handleLngChange}
                                disabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Daily Avg Watts</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formDailyAvgW || ''}
                                name="dailyAvgW"
                                onChange={handleDailyAvgWChange}
                                disabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={2} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Include</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Checkbox
                                isSelected={formIncludeInDashTotal || false}
                                name="includeInDashTotal"
                                onChange={handleIncludeInDashTotal}
                                className="FormCheckbox"
                                disabled={selectedRegistry === undefined}
                            />
                        </InputGroup>
                    </Col>

                    {props.isAuthenticated ? (<>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Read Key</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                ref={readKeyRef}
                                placeholder="DataLog Read Key"
                                value={formDataLogReadKey || ''}
                                name="dataLogReadKey"
                                disabled={true}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={handleReadCopy}><span aria-label="copy read to clipboard" role="img">📋</span></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Write Key</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                ref={writeKeyRef}
                                placeholder="DataLog Write Key"
                                value={formDataLogWriteKey || ''}
                                name="dataLogWriteKey"
                                disabled={true}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={handleWriteCopy}><span aria-label="copy write to clipboard" role="img">📋</span></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Id</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                ref={idRef}
                                placeholder="Id"
                                value={formId || ''}
                                name="Id"
                                disabled={true}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={handleIdCopy}><span aria-label="copy id to clipboard" role="img">📋</span></Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </>) : (<></>)}

                    <InputGroup >
                        <InputGroup.Prepend>
                            <InputGroup.Text>CreatedAt</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder=""
                            value={formCreatedAt || ''}
                            name="createdAt"
                            disabled={true}
                        />
                    </InputGroup>

                </Row>

            </Card.Body>

        )

    }

    function renderRegistry() {
        //   console.log("in renderRegistry");
        return (

            <div className="Registry" >
                <Row className="ldHeaderRow">
                    Take Back The Heat - Registry
                </Row>
                <Row className="ldMidRow">
                    Devices - Filter [G]lobal? {renderShowAllCheckBox()}
                </Row>
                <Row className="ldTopRow">
                    <div className="ldDataNav">
                        {renderPrevButton()}
                        {renderNextButton()}
                        {renderPerPage()}
                        {renderItemCount()}
                    </div>
                    <div className="ldMessageContainer" >
                        <div className="ldFormMessage">
                            {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                        </div>
                    </div>
                    <div className="ldSmallButton">
                        {renderNewButton()}
                        {renderSaveButton()}
                    </div>
                </Row>
                <Row className="ldBodyRow">
                    <div className="ldSmallButton">
                        <Link to="/post/e90d703a-e9b6-478a-b99d-20de2ffb6d33">Forum Discussion</Link>
                    </div>
                </Row>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            {formName}...
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <>
                                <Card.Body>
                                    {renderCardBody()}
                                </Card.Body>
                                <Card.Footer className="RegistryCardFooter">
                                    {formUpdatedAt}
                                </Card.Footer>

                            </>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <div className="RegistryList">
                    <BootstrapTable
                        bootstrap4
                        keyField='id'
                        data={registryList}
                        columns={registryColumns}
                        selectRow={selectRow}
                        rowEvents={rowEvents}
                    >
                    </BootstrapTable>
                </div>

            </div>

        );
    }

    function renderLander() {
        return (
            <div className="lander">
                <h1>Take Back The Heat</h1>
                <p>Please log in first.</p>
            </div>
        );
    }

    return (
        <div className="Registry" >
            <Helmet>
                <title>{"TakeBackTheHeat - Registry"}</title>
                <meta name="description" content="The solutions in progress registered with takebacktheheat.org, that are addressing global warming and climate change by reducing the heat energy." />
            </Helmet>

            {props.isAuthenticated ? renderRegistry() : renderLander()}
        </div>
    );
}