import React, { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import SimpleMap from "../components/SimpleMap";
import { listMapMarkers } from '../graphql/queries';
import { createMapMarker, updateMapMarker } from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { Helmet } from "react-helmet";

export default function Where(props) {

    const [mapList, setMapList] = useState([]);
    const [markerMoved, setMarkerMoved] = useState(false);
    const [enableMarker, setEnableMarker] = useState(false);
    const [userMarker, setUserMarker] = useState([]);

    async function fetchMapList() {
        try {
            if (props.isAuthenticated) {
                const mapListDataA = await API.graphql(graphqlOperation(listMapMarkers));
                const mapMarkerListA = mapListDataA.data.listMapMarkers.items;
                setMapList(mapMarkerListA);
            } else {
                const mapListData = await API.graphql({
                    query: listMapMarkers,
                    //variables:{input: xx},
                    authMode: 'API_KEY'        //switch from incognito to API_KEY for unauthenticated users
                });
                const mapMarkerList = mapListData.data.listMapMarkers.items;
                setMapList(mapMarkerList);
            }
        } catch (error) {
            console.log("error on fetching mapList", error);
        }
    };

    useEffect(() => {
        fetchMapList();
        //console.log("fetched maplist");
        // gets hung up on not having this function within useEffect, but we also call it later to reload.
        // interweb seems divided on how best to code. see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
        // eslint-disable-next-line
    }, []);

    function handleEnableMarker(event) {
        //console.log("in handleEnableMarker");
        if (props.isAuthenticated) {
            if (enableMarker === true) { //if user was moving visitor flag
                if (markerMoved === true) {
                    saveMarker();
                }
            }
            setEnableMarker(!enableMarker);
        };
    }

    function saveMarker() {
        //   const updateResult = await API.graphql(graphqlOperation(updateMapMarkers));
        if (userMarker.id == null) { //means is new  catches null or undefined
            const mapMarker = { lat: String(userMarker.lat), lng: String(userMarker.lng), markerType: 'visitor' };
            //await

            try {  //does graphql through error?
                //     console.log("trying to create mapmarker");
                API.graphql({ query: createMapMarker, variables: { input: mapMarker }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
                fetchMapList();        //since we need the id for the newly created marker, go get the list again
            } catch (error) {
                //     console.log("error trying to create map marker:", error);
            }

        } else {
            const mapMarkerU = { id: userMarker.id, lat: String(userMarker.lat), lng: String(userMarker.lng), markerType: 'visitor' };

            try {
                //     console.log("trying to update mapmarker");
                API.graphql({ query: updateMapMarker, variables: { input: mapMarkerU }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
            } catch (error) {
                //     console.log("error trying to update map marker:", error);
            }

        }
    }

    function onMapClick(e) {
        if (enableMarker) {
            setMarkerMoved(true);
            var isFound = false;
            //console.log("in onMapClick", e);
            const list = mapList.map((item, j) => {
                if (item.owner === props.userDetails.username && item.markerType === "visitor") {   //and 'visitor'          //dynamodb sets owner to username in backend
                    isFound = true;
                    //           console.log("is found:",item);
                    item.lat = e.lat;
                    item.lng = e.lng;
                    setUserMarker(item);
                    return item;
                } else {
                    return item;
                }
            });
            if (isFound) {
                setMapList(list);
            } else {
                setMapList(mapList.concat({ owner: props.userDetails.username, markerType: "visitor", lat: e.lat, lng: e.lng }));
                //     console.log("is not found, was added:", e.lat,e.lng);
            }
        };
    };

    function renderPinDropButton() {
        return (
            <>
                <Button className="ldBackButton" middle="xs" onClick={handleEnableMarker}>
                    Pin drop is {enableMarker ? "ON" : "OFF"}
                </Button>
            </>
        )
    }

    function renderWhere() {
        return (<div className="Where" >
            <p></p>
            <h1 > Take Back The Heat </h1>
            {props.isAuthenticated ? (enableMarker ? "Turn Off to save " : "Drop a visitor pin ") : "Sign in to leave a visitor marker or a device marker if you are taking back the heat! "}
            {renderPinDropButton()}
            <Row className="ldBodyRow">
                <div className="ldSmallButton">
                    <Link to="/post/cb3b147a-6d87-48f0-976e-8cb03dce2285">Forum Discussion</Link>
                </div>
            </Row>
            <p>
            </p>
            <SimpleMap markers={mapList} onMapClick={onMapClick}></SimpleMap>
        </div>
        );
    }

    return (
        <div className="Where" >
            <Helmet>
                <title>{"TakeBackTheHeat - Where"}</title>
                <meta name="description" content="Where takebacktheheat.org members and solutions exist to address climate change and global warming" />
            </Helmet>

            {renderWhere()}
        </div>
    );
}