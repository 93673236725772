import React, { useEffect } from "react";
import causediagram from "../media/causeandeffect.jpg";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

//height="38" width="60"


export default function Why(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function renderWhy() {
        return (<div className="Why" >
            <p></p>

            <h1 > Take Back The Heat - Why </h1>
            <p>Why does this need to happen?  The current carbon projects that are focused on addressing the GHG levels will not see results for many years, and will be struggling at best to obtain results.
                The heat is the direct problem, it should be addressed directly while the GHG programs spin up.  Atmospheric Heat Reduction projects will suppliment the carbon project efforts of reversing or at least slowing down the damage caused.
                In the diagram below the blue boxes identify what we are addressing, and the red boxes identify some of the damages that are being caused currently.
            </p>
            <p>The book <a href="https://read.amazon.ca/kp/embed?asin=B08JQKQGD5&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_4C8CM9991DAYF9408D6V" target="_blank" rel="noreferrer">Unsettled, by Steven E. Koonin</a> <Link to="/Etc"><sub>[14]</sub></Link>
                has some fascinating detailed information on the modeling process and current activities within the scientific community around climate change, it is worth reading.
            </p>
            <p><b>Take Back The Heat</b> moves forward on the assumption that it is better to be aware of the possible heat issues, and to prepare and do something about them now, instead of assuming the projected problems are overblown and not worth considering.
            </p>
            <p>The slight increase in temperature, if it does happen, may turn out to be beneficial for all of us.  Or not.  The models currently utilized leave a great margin of uncertainty, we just don't know.  Climate modeling technology is very immature at this point. </p>
            <p></p>
            <p>
                <img src={causediagram} alt="Cause and Effect" height="70%" width="90%"></img>
            </p>
            <p className="PageFooter"><Link to="/What">{"<<"}--What</Link><Link to="/How">How--{">>"}</Link></p>
        </div>
        );
    }

    return (
        <div className="Why" >
            <Helmet>
                <title>{"TakeBackTheHeat - Why"}</title>
                <meta name="description" content="Why takebacktheheat.org is addressing climate change and global warming by reducing climate heat energy" />
            </Helmet>
            {renderWhy()}
        </div>
    );
}