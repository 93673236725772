/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input)
  }
`;
export const processContribution = /* GraphQL */ `
  mutation ProcessContribution($input: ProcessOrderInput!) {
    processContribution(input: $input)
  }
`;
export const processEvent = /* GraphQL */ `
  mutation ProcessEvent($input: EventInput!) {
    processEvent(input: $input)
  }
`;
export const listAllRegistry = /* GraphQL */ `
  mutation ListAllRegistry($input: ListRegistryInput!) {
    listAllRegistry(input: $input)
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      sortOrder
      posts {
        items {
          id
          title
          content
          contentHTML
          postBlogId
          topic
          createdAt
          updatedAt
          owner
          groupsAdmin
          imageNames
        }
        nextToken
      }
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      sortOrder
      posts {
        items {
          id
          title
          content
          contentHTML
          postBlogId
          topic
          createdAt
          updatedAt
          owner
          groupsAdmin
          imageNames
        }
        nextToken
      }
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      sortOrder
      posts {
        items {
          id
          title
          content
          contentHTML
          postBlogId
          topic
          createdAt
          updatedAt
          owner
          groupsAdmin
          imageNames
        }
        nextToken
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      content
      contentHTML
      postBlogId
      topic
      createdAt
      updatedAt
      owner
      groupsAdmin
      imageNames
      blog {
        id
        name
        createdAt
        updatedAt
        owner
        sortOrder
        posts {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentPostId
          createdAt
          updatedAt
          owner
          groupsAdmin
        }
        nextToken
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      content
      contentHTML
      postBlogId
      topic
      createdAt
      updatedAt
      owner
      groupsAdmin
      imageNames
      blog {
        id
        name
        createdAt
        updatedAt
        owner
        sortOrder
        posts {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentPostId
          createdAt
          updatedAt
          owner
          groupsAdmin
        }
        nextToken
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      content
      contentHTML
      postBlogId
      topic
      createdAt
      updatedAt
      owner
      groupsAdmin
      imageNames
      blog {
        id
        name
        createdAt
        updatedAt
        owner
        sortOrder
        posts {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentPostId
          createdAt
          updatedAt
          owner
          groupsAdmin
        }
        nextToken
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      commentPostId
      createdAt
      updatedAt
      owner
      groupsAdmin
      post {
        id
        title
        content
        contentHTML
        postBlogId
        topic
        createdAt
        updatedAt
        owner
        groupsAdmin
        imageNames
        blog {
          id
          name
          createdAt
          updatedAt
          owner
          sortOrder
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      commentPostId
      createdAt
      updatedAt
      owner
      groupsAdmin
      post {
        id
        title
        content
        contentHTML
        postBlogId
        topic
        createdAt
        updatedAt
        owner
        groupsAdmin
        imageNames
        blog {
          id
          name
          createdAt
          updatedAt
          owner
          sortOrder
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      commentPostId
      createdAt
      updatedAt
      owner
      groupsAdmin
      post {
        id
        title
        content
        contentHTML
        postBlogId
        topic
        createdAt
        updatedAt
        owner
        groupsAdmin
        imageNames
        blog {
          id
          name
          createdAt
          updatedAt
          owner
          sortOrder
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const createContribution = /* GraphQL */ `
  mutation CreateContribution(
    $input: CreateContributionInput!
    $condition: ModelContributionConditionInput
  ) {
    createContribution(input: $input, condition: $condition) {
      id
      contributorName
      contributorId
      contributionDescription
      contributionAmount
      updatedAt
      createdAt
    }
  }
`;
export const updateContribution = /* GraphQL */ `
  mutation UpdateContribution(
    $input: UpdateContributionInput!
    $condition: ModelContributionConditionInput
  ) {
    updateContribution(input: $input, condition: $condition) {
      id
      contributorName
      contributorId
      contributionDescription
      contributionAmount
      updatedAt
      createdAt
    }
  }
`;
export const deleteContribution = /* GraphQL */ `
  mutation DeleteContribution(
    $input: DeleteContributionInput!
    $condition: ModelContributionConditionInput
  ) {
    deleteContribution(input: $input, condition: $condition) {
      id
      contributorName
      contributorId
      contributionDescription
      contributionAmount
      updatedAt
      createdAt
    }
  }
`;
export const createMapMarker = /* GraphQL */ `
  mutation CreateMapMarker(
    $input: CreateMapMarkerInput!
    $condition: ModelMapMarkerConditionInput
  ) {
    createMapMarker(input: $input, condition: $condition) {
      id
      owner
      lat
      lng
      markerType
      markerRef
      createdAt
      updatedAt
    }
  }
`;
export const updateMapMarker = /* GraphQL */ `
  mutation UpdateMapMarker(
    $input: UpdateMapMarkerInput!
    $condition: ModelMapMarkerConditionInput
  ) {
    updateMapMarker(input: $input, condition: $condition) {
      id
      owner
      lat
      lng
      markerType
      markerRef
      createdAt
      updatedAt
    }
  }
`;
export const deleteMapMarker = /* GraphQL */ `
  mutation DeleteMapMarker(
    $input: DeleteMapMarkerInput!
    $condition: ModelMapMarkerConditionInput
  ) {
    deleteMapMarker(input: $input, condition: $condition) {
      id
      owner
      lat
      lng
      markerType
      markerRef
      createdAt
      updatedAt
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      id
      owner
      configName
      configNote
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      id
      owner
      configName
      configNote
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      id
      owner
      configName
      configNote
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const createRegistry = /* GraphQL */ `
  mutation CreateRegistry(
    $input: CreateRegistryInput!
    $condition: ModelRegistryConditionInput
  ) {
    createRegistry(input: $input, condition: $condition) {
      id
      owner
      rowState
      deviceType
      deviceName
      deviceDescription
      lat
      lng
      dailyAverageW
      includeInDashTotal
      updateFromDataLog
      dataLogReadKey
      dataLogWriteKey
      attributes
      dataLogs {
        items {
          id
          owner
          dataLogRegistryId
          dataType
          note
          lat
          lng
          watts1
          watts2
          watts3
          coldTempC
          hotTempC
          cels1
          cels2
          cels3
          val1
          val2
          val3
          dataJSON
          eventDate
          createdAt
          updatedAt
          TTLExpiry
          ip
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRegistry = /* GraphQL */ `
  mutation UpdateRegistry(
    $input: UpdateRegistryInput!
    $condition: ModelRegistryConditionInput
  ) {
    updateRegistry(input: $input, condition: $condition) {
      id
      owner
      rowState
      deviceType
      deviceName
      deviceDescription
      lat
      lng
      dailyAverageW
      includeInDashTotal
      updateFromDataLog
      dataLogReadKey
      dataLogWriteKey
      attributes
      dataLogs {
        items {
          id
          owner
          dataLogRegistryId
          dataType
          note
          lat
          lng
          watts1
          watts2
          watts3
          coldTempC
          hotTempC
          cels1
          cels2
          cels3
          val1
          val2
          val3
          dataJSON
          eventDate
          createdAt
          updatedAt
          TTLExpiry
          ip
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegistry = /* GraphQL */ `
  mutation DeleteRegistry(
    $input: DeleteRegistryInput!
    $condition: ModelRegistryConditionInput
  ) {
    deleteRegistry(input: $input, condition: $condition) {
      id
      owner
      rowState
      deviceType
      deviceName
      deviceDescription
      lat
      lng
      dailyAverageW
      includeInDashTotal
      updateFromDataLog
      dataLogReadKey
      dataLogWriteKey
      attributes
      dataLogs {
        items {
          id
          owner
          dataLogRegistryId
          dataType
          note
          lat
          lng
          watts1
          watts2
          watts3
          coldTempC
          hotTempC
          cels1
          cels2
          cels3
          val1
          val2
          val3
          dataJSON
          eventDate
          createdAt
          updatedAt
          TTLExpiry
          ip
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDataLog = /* GraphQL */ `
  mutation CreateDataLog(
    $input: CreateDataLogInput!
    $condition: ModelDataLogConditionInput
  ) {
    createDataLog(input: $input, condition: $condition) {
      id
      owner
      dataLogRegistryId
      dataType
      note
      lat
      lng
      watts1
      watts2
      watts3
      coldTempC
      hotTempC
      cels1
      cels2
      cels3
      val1
      val2
      val3
      dataJSON
      eventDate
      createdAt
      updatedAt
      TTLExpiry
      ip
      registry {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateDataLog = /* GraphQL */ `
  mutation UpdateDataLog(
    $input: UpdateDataLogInput!
    $condition: ModelDataLogConditionInput
  ) {
    updateDataLog(input: $input, condition: $condition) {
      id
      owner
      dataLogRegistryId
      dataType
      note
      lat
      lng
      watts1
      watts2
      watts3
      coldTempC
      hotTempC
      cels1
      cels2
      cels3
      val1
      val2
      val3
      dataJSON
      eventDate
      createdAt
      updatedAt
      TTLExpiry
      ip
      registry {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteDataLog = /* GraphQL */ `
  mutation DeleteDataLog(
    $input: DeleteDataLogInput!
    $condition: ModelDataLogConditionInput
  ) {
    deleteDataLog(input: $input, condition: $condition) {
      id
      owner
      dataLogRegistryId
      dataType
      note
      lat
      lng
      watts1
      watts2
      watts3
      coldTempC
      hotTempC
      cels1
      cels2
      cels3
      val1
      val2
      val3
      dataJSON
      eventDate
      createdAt
      updatedAt
      TTLExpiry
      ip
      registry {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createStats = /* GraphQL */ `
  mutation CreateStats(
    $input: CreateStatsInput!
    $condition: ModelStatsConditionInput
  ) {
    createStats(input: $input, condition: $condition) {
      id
      name
      title
      level
      sortOrder
      statsType
      statsDate
      description
      value
      createdAt
      updatedAt
      owner
      isDisabled
    }
  }
`;
export const updateStats = /* GraphQL */ `
  mutation UpdateStats(
    $input: UpdateStatsInput!
    $condition: ModelStatsConditionInput
  ) {
    updateStats(input: $input, condition: $condition) {
      id
      name
      title
      level
      sortOrder
      statsType
      statsDate
      description
      value
      createdAt
      updatedAt
      owner
      isDisabled
    }
  }
`;
export const deleteStats = /* GraphQL */ `
  mutation DeleteStats(
    $input: DeleteStatsInput!
    $condition: ModelStatsConditionInput
  ) {
    deleteStats(input: $input, condition: $condition) {
      id
      name
      title
      level
      sortOrder
      statsType
      statsDate
      description
      value
      createdAt
      updatedAt
      owner
      isDisabled
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      items
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      items
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      items
      createdAt
      updatedAt
      owner
    }
  }
`;
