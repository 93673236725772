import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { API } from 'aws-amplify';
import { blogsOrderBySortOrder, getPost } from '../graphql/queries';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './suneditor.css';

import { buildTopicOptions } from '../data.js';
import DOMPurify from 'dompurify';

import Comments from '../components/Comments';
import { Helmet } from "react-helmet";
export default function Post(props) {

    const blogOwner = "ld"; //this is the owner of the top level blogs, since should all be the same is being used as partition key

    const [categoryOptions, setCategoryOptions] = useState([]);

    const [post, setPost] = useState([]);

    const editorRef = useRef();

    // const RenderHTML = (props) => (<span dangerouslySetInnerHTML={{__html:props.HTML}}></span>)


    //    const toolbarOptions = {
    //        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
    //options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'embedded', 'emoji', 'image'],
    //    };

    const topicOptions = buildTopicOptions();

    const [categoryType, setCategoryType] = useState([]);
    const [topicType, setTopicType] = useState([]);


    const slug = (props.match.params.slug);
    const slugPages = ['usage', 'usage-statement', 'privacy', 'privacy-statement', 'usage-guides', 'usage-guidelines', 'contact', 'cookies'];
    const slugPagesKey = [
        "38d7df99-eab5-457b-893a-0858fa4830fd",
        "38d7df99-eab5-457b-893a-0858fa4830fd",
        "7fa7493a-ab37-48b1-9333-3a89cfa98b79",
        "7fa7493a-ab37-48b1-9333-3a89cfa98b79",
        "ed556838-2cd3-429b-ba88-c7b067b6783d",
        "ed556838-2cd3-429b-ba88-c7b067b6783d",
        "c985549d-b245-44d1-ae6f-71296d1e3f18",
        "c334ff77-0f90-40f6-987f-c58140569285"];
    const slugFound = slug === undefined ? -1 : slugPages.indexOf(slug.toLowerCase());
    const slugId = slugFound === -1 ? 0 : slugPagesKey[slugFound];

    useEffect(() => {
        // onload               pass in either 'new' or a post id
        // if not logged in, return
        fetchBlogList();
        if (props.match.params.id === undefined) {
            if (slugId === 0) {
                fetchPost(slugPagesKey[0]);      //default to usage page
            } else {
                fetchPost(slugId);
            }
        } else {
            fetchPost(props.match.params.id);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        //
        if ((categoryOptions.length > 0) & (post.postBlogId !== undefined)) {
            setCategoryType({ value: post.postBlogId, label: categoryOptions.find(x => x.value === post.postBlogId).label });
        }
    }, [categoryOptions, post]);

    function toDate(dString) {
       return new Date(dString).toLocaleString();
    }

    async function handleBack(event) {
        //  props.history.goBack();
        props.history.push("/forum/" + categoryType.value);
    }


    async function fetchBlogList() {
        try {
            const blogListData = await API.graphql({ query: blogsOrderBySortOrder, variables: { owner: blogOwner }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const blogList = blogListData.data.blogsOrderBySortOrder.items;
            const blist = blogList.map((item) => [{ value: item.id, label: item.name, sortOrder: item.sortOrder }]);
            const ablist = [].concat(...blist); //.sort(compare);
            setCategoryOptions(ablist);
        } catch (error) {
            console.log("error on fetching blogList", error);
        };
    };

    function renderBackButton() {
        return (
            <>
                <Button className="float-left ldButton" middle="xs" onClick={handleBack}>Back</Button>
            </>
        )
    }

    async function fetchPost(postId) {
        try {
            const postData = await API.graphql({ query: getPost, variables: { id: postId }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const dbPost = postData.data.getPost;
            //const ablist = [].concat(...blist); //.sort(compare);
            setPost(dbPost);
            //setPostComments(postComments);
            //setSubjectText(dbPost.title);
            //setCategoryType({value:dbPost.postBlogId,label:categoryOptions.find(x=>x.value===dbPost.postBlogId).label});  //doesn't work here, category still empty
            setTopicType({ value: dbPost.topic, label: topicOptions.find(x => x.value === dbPost.topic).label });
            //x            const content = convertFromRaw(JSON.parse(dbPost.content));
            //x            setEditorState(() => EditorState.createWithContent(content));
            editorRef.current.editor.core.setContents(DOMPurify.sanitize(dbPost.content));
        } catch (error) {
            //            console.log("errors:", error.errors.length);
            //            console.log("first error:", error.errors[0]);
            console.log("error on fetching blogList", error);
        };
    };

    //                                <div className="EditorContentPreview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
    console.log("in render:");
    return (
        <div>
            <Helmet>
                <title>{"TakeBackTheHeat - " + categoryType.label + " - " + topicType.label}</title>
                <meta name="description" content={"Content at takebacktheheat.org focused on reducing the heat energy to address climate change and global warming. " + post.title} />
            </Helmet>

            <p></p>
            <Row>
                <Col>
                    {renderBackButton()}
                </Col>
            </Row>
            <Card>
                <Card.Header>
                    {categoryType.label}{" - "}{topicType.label}
                </Card.Header>
                <Card.Title>
                    {post.title}
                </Card.Title>
                <Card.Body className="ldCardBody">
                    <div className="EditorContent">
                        <SunEditor ref={editorRef}
                            disable={true}
                            enableToolbar={false}
                            autoFocus={true}
                            showToolbar={false}
                            width="100%"
                            height="100%"
                            setOptions={{
                                resizingBar: false,
                                showPathLabel: false,
                                height: '100%',
                                defaultStyle: 'sun-editor-editable',
                            }}
                        />
                    </div>

                </Card.Body>
                <Card.Footer className="ForumPostCardFooter">
                    <Row>
                        <Col>
                            {post.owner}
                        </Col>
                        <Col className="ForumPostCardFooterRight">
                            {toDate(post.updatedAt)}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
            <Comments postId={post.id} isAuthenticated={props.isAuthenticated} username={props.userDetails.username} />
        </div>
    );
}