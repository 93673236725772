import React from "react";
import { Link } from 'react-router-dom';
import { Card, Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default function Etc(props) {
      function renderEtc() {
            return (<div className="Etc" >
                  <p></p>
                  <h1>Take Back The Heat - etc</h1>
                  <p></p>
                  <Accordion>
                        <Card className="EtcCard">
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Notes...
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                    <>
                                          <Card.Body>
                                                <div>
                                                      <ul style={{ listStyleType: "none", textAlign: "left" }}>
                                                            <li><b>[1]</b> Bill Gates, <i>How to Avoid a Climate Disaster </i> (ALFRED A. KNOPF AND ALFRED A. KNOPF CANADA, 2021) <a href="https://www.amazon.ca/dp/0735280444/ref=cm_sw_em_r_mt_dp_9W0EMYYG01M23JPNVRDF">link</a></li>
                                                            <li><b>[2]</b> NASA, <i>The greenhouse effect </i> <a href="https://spaceplace.nasa.gov/greenhouse/en/">link</a></li>
                                                            <li><b>[3]</b> NASA, <i>Net Radiation </i> <a href="https://earthobservatory.nasa.gov/global-maps/CERES_NETFLUX_M">link</a> </li>
                                                            <li><b>[4]</b> NASA, <i>The Water Planet </i><a href="https://www.nasa.gov/multimedia/imagegallery/image_feature_1925.html">link</a> </li>
                                                            <li><b>[5]</b> Ryan J. Kramer, Haozhe He, Brian J. Soden, Lazaros Oreopoulos, Gunnar Myhre, Piers M. Forster, Christopher J. Smith <i>Observational evidence of increasing global radiative forcing <a href="https://doi.org/10.1029/2020GL091585">link</a></i></li>
                                                            <li><b>[6]</b> NASA, <i>What is Earth's Energy Budget?  Five Questions with a Guy Who Knows</i> <a href="https://www.nasa.gov/feature/langley/what-is-earth-s-energy-budget-five-questions-with-a-guy-who-knows">link</a></li>
                                                            <li><b>[7]</b> Jeremy McGilvrey <i>Teacher picture </i>Photo by <a href="https://unsplash.com/@jeremy_mcgilvrey?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jeremy McGilvrey</a> on <a href="https://unsplash.com/s/photos/school?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                                            </li>
                                                            <li><b>[8]</b> Nirmal Rajendharkumar <i>Designer picture </i>Photo by <a href="https://unsplash.com/@neotronimz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nirmal Rajendharkumar</a> on <a href="https://unsplash.com/s/photos/design-board?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                                            </li>
                                                            <li><b>[9]</b> Mauro Paillex, <i>Running uphill picture </i>Photo by <a href="https://unsplash.com/@mauropaillexphotographer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">mauro paillex</a> on <a href="https://unsplash.com/s/photos/progress?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                                            </li>
                                                            <li><b>[10]</b> UN, <i>10 Priorities for 2021 </i><a href="https://sdg.iisd.org/news/un-secretary-general-presents-10-priorities-for-2021/#:~:text=sustainable%20economic%20recovery-,Make%20peace%20with%20nature,the%20greatest%20human%20rights%20challenge">link</a></li>
                                                            <li><b>[11]</b> U.S. Department of Agriculture, <i>Give a Hoot </i><a href="https://www.usda.gov/media/blog/2011/09/16/us-forest-service-symbol-woodsy-owl-turns-40">link</a></li>
                                                            <li><b>[12]</b> PSU, <i>Electromagnetic Spectrum </i><a href="https://www.e-education.psu.edu/natureofgeoinfo/book/export/html/1743">link</a></li>
                                                            <li><b>[13]</b> NASA, <i>Global Climate Change </i> <a href="https://climate.nasa.gov/climate_resources/9/graphic-earths-temperature-record/">link</a></li>
                                                            <li><b>[14]</b> Steven E. Koonin, <i>Unsettled: What Climate Science Tells Us, What It Doesn't, and Why It Matters </i><a href="https://read.amazon.ca/kp/embed?asin=B08JQKQGD5&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_4C8CM9991DAYF9408D6V" target="_blank" rel="noreferrer">link</a></li>
                                                            <li><b>[15]</b> NASA, <i>Galleries - Earth at Night </i> <a href="https://www.nasa.gov/topics/earth/earthday/gall_earth_night.html">link</a></li>
                                                      </ul>
                                                </div>
                                          </Card.Body>
                                    </>
                              </Accordion.Collapse>
                        </Card>
                  </Accordion>
                  <Accordion>
                        <Card className="EtcCard">
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                    Additional Reading...
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                    <>
                                          <Card.Body>
                                                <div>
                                                      <ul style={{ listStyleType: "none", textAlign: "left" }}>
                                                            <li><a href="https://en-roads.climateinteractive.org/" target="_blank" rel="noreferrer">En-ROADS climate simulator</a></li>
                                                            <li><a href="https://www.nasa.gov/feature/goddard/2021/direct-observations-confirm-that-humans-are-throwing-earth-s-energy-budget-off-balance" target="_blank" rel="noreferrer">Direct Observations Confirm that Humans are Throwing Earth’s Energy Budget off Balance</a></li>
                                                            <li><a href="https://read.amazon.ca/kp/embed?asin=B0827TL851&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_35CAMRAWC8QNNJRM3CE5" target="_blank" rel="noreferrer">False Alarm; book; Bjorn Lomborg</a></li>
                                                            <li><a href="https://documentarylovers.com/film/cool-it" target="_blank" rel="noreferrer">Cool it; video; Bjorn Lomborg</a></li>
                                                            <li><a href="https://www.discovermagazine.com/environment/heres-what-real-science-says-about-the-role-of-co2-as-earths-preeminent" target="_blank" rel="noreferrer">Discover Magazine; Here's What Real Science Says About The Role Of CO2 As Earth's Preeminent Climatic Thermostat; Tom Yulsman; Mar 12, 2018</a></li>
                                                            <li><a href="https://climate.nasa.gov/climate_resources/189/graphic-temperature-vs-solar-activity/" target="_blank" rel="noreferrer">Temperature vs Solar Activity; NASA</a></li>
                                                            <li><a href="https://thebestschools.org/special/karoly-happer-dialogue-global-warming/happer-major-statement/" target="_blank" rel="noreferrer">CO2 will be a major benefit to the Earth; William Happer</a></li>
                                                            <li><a href="http://cdn.antarcticglaciers.org/wp-content/uploads/2012/07/Vostok_420ky_4curves_insolation_to_2004.jpg" target="_blank" rel="noreferrer">400,000 years of data; Antarcticglaciers.org</a></li>
                                                      </ul>                                                </div>
                                          </Card.Body>
                                    </>
                              </Accordion.Collapse>
                        </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="2">
                        <Card className="EtcCard">
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                    Tools...
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                    <>
                                          <Card.Body>
                                                <div>
                                                      <p>
                                                            Calculate # of PV panels and related energy generated / converted: (<Link to="/CalcPanel">Calc Panel</Link>)
                                                      </p>
                                                      <p>
                                                            Show canadian temperature history by station: (<Link to="/ShowTemp">Show Temperature</Link>)
                                                      </p>
                                                      <p>
                                                            Simplified online tCO2e/y calculator: (<a href="https://ahrnow.com/calc/calctco2e.html" target="_blank" rel="noreferrer">https://ahrnow.com/calc/calctco2e.html</a>)
                                                      </p>

                                                </div>
                                          </Card.Body>
                                    </>
                              </Accordion.Collapse>
                        </Card>
                  </Accordion>
            </div>
            );
      }

      return <div className="Etc" >
            <Helmet>
                  <title>{"TakeBackTheHeat - Etc"}</title>
                  <meta name="description" content="takebacktheheat.org notes, references, tools and more regarding efforts to reduce the heat energy to address climate change and global warming." />
            </Helmet>
            {renderEtc()} </div>;
}