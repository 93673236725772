const topicOptions = [
   { value: 1, label: "Design" },
   { value: 2, label: "Showcase" },
   { value: 3, label: "For Sale" },
   { value: 4, label: "Information" },
   { value: 5, label: "Other" }];

export function buildTopicOptions () {
   return topicOptions;
}

//anything starting with heatback has the option to be totalled into daily watts total the registry value setting
const deviceTypeOptions = [
   { value: "heatsource", label: "heat source" },
   { value: "heatbackscavenger", label: "heat scavenger" }, 
   { value: "heatbackpanel", label: "heatback panel" }, 
   { value: "heatback", label: "heatback unit" },
   { value: "heatback1", label: "heatback unit with power, 2 temp sensors" }, 
   { value: "heatback2", label: "heatback unit with power sensor" }, 
   { value: "heatback3", label: "heatback unit with power sensor, 1 temp sensor" }, 
   { value: "airqual1", label: "air quality pm10 and pm25 sensors" }, 
   { value: "temp1", label: "temperature sensor (1)" }, 
   { value: "temp2", label: "temperature sensors (2)" }, 
   { value: "organic", label: "organic" },
   { value: "other", label: "other" },
   { value: "photosynthesis", label: "photosynthesis" },
   { value: "pvpanel", label: "pv panel" } ,
   { value: "stirling", label: "stirling" }   
];

const perPageOptions = [
   {value: "2", label: "2" },
   {value: "10", label: "10" },
   {value: "20", label: "20" },
   {value: "50", label: "50" },
   {value: "100", label: "100" },
   {value: "200", label: "200" }
];

//for registry, we show either all that are owned by user, or all with rowState = "g"  for generally available.  this allows a person to set theirs to p, so only they see it.
//note that security is not enforced, data is still accessible just not shown on the list.

const rowStateOptions = [
   {value: "g", label: "g" },
   {value: "p", label: "p" },
   {value: "d", label: "d" }
];

export function buildDeviceTypeOptions () {
   return deviceTypeOptions;
}

export function buildPerPageOptions () {
   return perPageOptions;
}

export function buildRowStateOptions () {
   return rowStateOptions;
}

