import React from 'react';
//https://reactjs.org/tutorial/tutorial.html


class ScoreTile extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         title: this.props.title,
         value: this.props.value,
         style: {background: this.props.color, color: "#000000"},
         onClick: this.props.onClick
      };
   }

   
   render() {
      return (
         <button className="ScoreTile" onClick={this.state.onClick} style={this.state.style} key={this.state.title}>
            <div style={{fontSize:"80%",fontWeight:"500"}}>
            {this.state.title}
            </div>
            <div></div>
            <div style={{fontSize:"140%",fontWeight:"600"}}>
            {this.state.value}
            </div>
         </button>
      );
   }
}

export default ScoreTile;