import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Button, Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { API } from 'aws-amplify';
import { blogsOrderBySortOrder, getPost } from '../graphql/queries';
import { updatePost } from '../graphql/mutations';

import { buildTopicOptions } from '../data.js';
import DOMPurify from 'dompurify';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './suneditor.css';
import {Helmet} from "react-helmet";

export default function PostEdit(props) {

    const blogOwner = "ld"; //this is the owner of the top level blogs, since should all be the same is being used as partition key

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [messageLine, setMessageLine] = useState();
    const [isEdited, setIsEdited] = useState(false);
    const [isEditor, setIsEditor] = useState(false);

    const [post, setPost] = useState([]);

    const editorRef = useRef();

    const topicOptions = buildTopicOptions();

    const [subjectText, setSubjectText] = useState();
    const [categoryType, setCategoryType] = useState([]);
    const [topicType, setTopicType] = useState([]);

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted black',
          color: state.isSelected ? 'red' : 'blue',
          padding: 10,
        }),
      }


    useEffect(() => {
        // onload               pass in either 'new' or a post id
        // if not logged in, return
        if (props.match.params.id === undefined) {
            props.history.push("/forum");
        }
        fetchBlogList();
        fetchPost(props.match.params.id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        //
        if ((categoryOptions.length > 0) & (post.postBlogId !== undefined)) {
            if (props.userDetails.username !== post.owner) { //fudged their way in here, time to leave}
                props.history.push("/forum");
            }
            setCategoryType({ value: post.postBlogId, label: categoryOptions.find(x => x.value === post.postBlogId).label });
        }
        // eslint-disable-next-line
    }, [categoryOptions, post]);

    function handleSubjectChange(e) {
        setSubjectText(e.target.value);
        setIsEdited(true);
    }

    function handleCategoryChange(event) {     //will become blog list
        //        setAccountType(event.value);
        setCategoryType({ value: event.value, label: event.label });
        setIsEdited(true);
    }

    function handleTopicChange(event) {

        setTopicType({ value: event.value, label: event.label });
        setIsEdited(true);
    }

    async function fetchBlogList() {
        try {
            const blogListData = await API.graphql({ query: blogsOrderBySortOrder, variables: { owner: blogOwner }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const blogList = blogListData.data.blogsOrderBySortOrder.items;
            const blist = blogList.map((item) => [{ value: item.id, label: item.name, sortOrder: item.sortOrder }]);
            const ablist = [].concat(...blist); //.sort(compare);
            //const ablistA = [].concat(...blistA).sort(compare).filter(item=> item.label !== "Housekeeping");
            setCategoryOptions(ablist);
        } catch (error) {
            console.log("error on fetching blogList", error);
        };
    };

    async function fetchPost(postId) {
        try {
            const postData = await API.graphql({ query: getPost, variables: { id: postId }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const dbPost = postData.data.getPost;
            // const postComments = dbPost.comments;                     //map((item) => [{ value: item.id, label: item.name, sortOrder: item.sortOrder }]);
            //const ablist = [].concat(...blist); //.sort(compare);
            setPost(dbPost);
            //setPostComments(postComments);
            setSubjectText(dbPost.title);
            //setCategoryType({value:dbPost.postBlogId,label:categoryOptions.find(x=>x.value===dbPost.postBlogId).label});  //doesn't work here, category still empty
            setTopicType({ value: dbPost.topic, label: topicOptions.find(x => x.value === dbPost.topic).label });
            setIsEdited(false);
            setIsEditor((dbPost.owner === props.userDetails.username));
            editorRef.current.editor.core.setContents(DOMPurify.sanitize(dbPost.content));
            //   setEditorState(convertFromRaw(post.content));
            //x            const content = convertFromRaw(JSON.parse(dbPost.content));
            //x            setEditorState(() => EditorState.createWithContent(content));
            //setEditorState(post.content);       //fails
            //    EditorState = post.content;
        } catch (error) {
            //            console.log("errors:", error.errors.length);
            //            console.log("first error:", error.errors[0]);
            console.log("error on fetching blogList", error);
        };
    };

    async function savePost() {
        var ret = { result: true };
        const updatedPost = {
            id: post.id,
            title: subjectText,

            //x            contentHTML: createMarkup(convertedContent).__html,  //convertedContent,
            //x            content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            content: DOMPurify.sanitize(editorRef.current.editor.core.getContents()), //editorRef.current.editor.core.getContents(),
            topic: topicType.value,
            postBlogId: categoryType.value
        };
        try {
            await API.graphql({ query: updatePost, variables: { input: updatedPost }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        } catch (error) {
            ret = { result: false, message: error.errors[0].message };
        }
        return ret;
    };

    function validateForm() {
        let ret = true;
        let msg = "";
        if (categoryType.length === 0) {
            msg = "Please select Category\n";
            ret = false;
        };
        if (topicType.length === 0) {              //should be a number
            msg = msg + "Please select Topic\n";
            ret = false;
        }
        //        if ((convertedContent === undefined) || (convertedContent && (convertedContent.length < 10))) {
        //            msg = msg + "Please enter content\n";
        //            ret = false;
        //        }
        //editorRef.current.editor.core.getContents()
        if (subjectText === undefined) {
            msg = msg + "Please enter subject\n";
            ret = false;
        } else {
            if (subjectText.length < 3) {   //not working on one line above.
                msg = msg + "Please enter subject\n";
                ret = false;
            }
        }
        setMessageLine(msg);
        return ret;
    }

    function handleCancel() {

        if (isEdited) {
            if (window.confirm('Discard changes?')) {
                //props.history.goBack();
                props.history.push("/forum/"+categoryType.value);
            }
        } else {
            //            props.history.goBack();
            props.history.push("/forum/"+categoryType.value);

        }
    }

    async function handleSave() {
        if (isEditor) {
            if (!validateForm()) {
                return;
            }
            const saveResult = await savePost();
            if (saveResult.result === true) {
                setIsEdited(false);
                setMessageLine("Saved");
            } else {
                setMessageLine("Unable to save: " + saveResult.message);
            }
        } else {
            setMessageLine("Unable to save, you are not the editor");
        }
    }

    async function handleEditorChange(e) {
        if (editorRef.current.editor.core.history.stack.length > 2) {    //first two changes are initial state and post content from db, so these don't represent something to save
            setIsEdited(true);
        }
        //editorRef.current.editor.core.setContents( DOMPurify.sanitize(editorRef.current.editor.core.getContents())); //loops
        //setEditorContent(e);
    };

    function renderSaveButton() {
        return (
            <Button className="float-right ldButton" middle="xs" onClick={handleSave} disabled={(!isEdited)}
            >
                Save
            </Button>
        )
    }

    function renderCancelButton() {
        return (
            <>
                {isEdited ? (
                    <Button className="float-left ldButton" middle="xs" onClick={handleCancel}>Cancel</Button>
                ) : (
                    <Button className="float-left ldButton" middle="xs" onClick={handleCancel}>Close</Button>
                )}
            </>
        )
    }

    function renderPostEdit() {
        return (<div className="PostEdit" >
            <p></p>
            <Container className="ldPostContainer" fluid>
                <Row>
                    <Col>
                        {renderCancelButton()}
                    </Col>
                    <Col className="ldFormMessage">
                        {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                    </Col>
                    <Col>
                        {renderSaveButton()}
                    </Col>
                </Row>
                <Row className="ldBodyRowN">
                    <Col sm={7} className="ldBodyColLeft">
                        <InputGroup size="lg" >
                                <InputGroup.Text>Category</InputGroup.Text>
                            <Select className="selectCategory"
                                value={categoryType || ''}
                                onChange={handleCategoryChange}
                                options={categoryOptions}
                                placeholder=""
                                styles={customStyles}                           
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={5} className="ldBodyColRight">
                        <InputGroup size="lg">
                                <InputGroup.Text>Topic</InputGroup.Text>
                            <Select className="selectTopic"
                                value={topicType || ''}
                                onChange={handleTopicChange}
                                options={topicOptions}
                                placeholder=""
                                styles={customStyles}                           
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="ldBodyRow">
                    <InputGroup >
                        <InputGroup.Prepend>
                            <InputGroup.Text>Subject</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea"
                            className="formSubjectText"
                            rows={1}
                            placeholder="subject"
                            value={subjectText || ''}
                            name="subjectText"
                            onChange={handleSubjectChange}
                        />
                    </InputGroup>

                </Row>
                <div className="EditorContent">
                    <SunEditor ref={editorRef}
                        onChange={handleEditorChange}

                        autoFocus={true}
                        showToolbar={true}
                        width="100%"
                        height="100%"
                        setOptions={{
                            height: '100%',
                            buttonList: [['undo', 'redo'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['removeFormat'], ['outdent', 'indent'], ['fullScreen', 'showBlocks'],
                            ['font', 'fontSize', 'fontColor', 'hiliteColor', 'formatBlock', 'align'],
                            ['list', 'paragraphStyle', 'table', 'textStyle'],
                            ['link', 'image'],
                            ['preview']],
                            defaultStyle: 'sun-editor-editable',
                            placeholder: "content..."
                        }}
                    />
                </div>
            </Container>
        </div>
        );
    }

    return (
        <div className="PostEdit" > 
            <Helmet>
                <title>{"TakeBackTheHeat - Edit"}</title>
                <meta name="description" content="Editing content on takebacktheheat.org, which is addressing global warming and climate change by reducing the heat energy." />
            </Helmet>
        { renderPostEdit()}
        </div>
    );
}