    //https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    /**
    * Copy a string to clipboard
    * @param  {String} string         The string to be copied to clipboard
    * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
    */
     export function copyToClipboard(string) {
      let textarea;
      //      let result;

      try {
          textarea = document.createElement('textarea');
          textarea.setAttribute('readonly', true);
          textarea.setAttribute('contenteditable', true);
          textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
          textarea.value = string;

          document.body.appendChild(textarea);

          textarea.focus();
          textarea.select();

          const range = document.createRange();
          range.selectNodeContents(textarea);

          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);

          textarea.setSelectionRange(0, textarea.value.length);
          //result = document.execCommand('copy');
          document.execCommand('copy');
      } catch (err) {
          console.error(err);
          //            result = null;
      } finally {
          document.body.removeChild(textarea);
      }
  }