import React from "react";

export default function Who(props) {

    function renderWho() {
        return ( <div className = "Who" >
            <p></p>
            <h1 > Take Back The Heat </h1>

            <p>
            Take back the heat 
            </p> 
            <p> coming soon... who is involved. (so far just us)
            </p> 
            </div>
        );
    }

    return ( 
        <div className = "Who" > { renderWho() } 
        </div>
    );
}