import React, {useState, useEffect} from "react";
import { API } from 'aws-amplify';
import { Figure, Container, Row } from "react-bootstrap";
import ScoreTile from "../components/ScoreTile";
import worldAtNightImage from "../media/324350main_11_full.jpg";
//import { listStatss } from '../graphql/queries';
//import { statsOrderBySortOrder} from '../graphql/queries';
import {statsLevelBySortOrder} from '../graphql/queries';
import { Link } from 'react-router-dom';

//            <p style={{flexDirection:'col'}}></p>
import { Helmet } from "react-helmet";

export default function Home(props) {

    const [statsList, setStatsList] = useState([]);

    const colors = ['#E15759', '#4E79A7', '#F28E2B', '#76B7B2', '#59A14F', '#EDC948', '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'];


    //************************************************************************************************************************** */
    useEffect(() => {
        fetchStatsList();      
        // eslint-disable-next-line        
    }, []);
    
    async function fetchStatsList() {
        try {
//            const listDataA = await API.graphql({ query: statsOrderBySortOrder, variables: {statsType: 1}, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const listDataA = await API.graphql({ query: statsLevelBySortOrder, variables: {level: 0 
                                                                                           },                                
                                                                              authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
  
           // const listA = listDataA.data.statsOrderBySortOrder.items.filter(item => (item.isDisabled !== true && item.level === 0));
           const listA = listDataA.data.statsLevelBySortOrder.items.filter(item => (item.isDisabled !== true));

            //const sList = listA.map(obj => ({ ...obj, attribute: JSON.parse(obj.attributes) }));

            //const cpList = sList.filter(obj => obj.attribute.Type === "calcpanel");

           setStatsList(listA);  //sList);
          //  setStatsList(listDataA.data.statsOrderBySortOrder.items);
            //setCalcpanelList(cpList);    //first load what's in db.  we may need to overwrite with url parameter settings
            //setReloadModel(true);

            //return { status: true };

        } catch (error) {
            console.log("error on fetching statsList", error);
            //return { status: false, error: error };
        }
    };


    function renderScoreTile(index, title, val, col, onClick) {
        return <ScoreTile key={index} title={title} value={val} color={col} onClick={onClick} />
    }

    function onClick(e) {
        //alert("clicked " + e.target.textContent);    //not reliable, it sometimes give title, others the value.  need to fix scoretile or use diff handlers
        console.log("click e:", e);
    }

    function renderHome() {
        return (<div className="home">
            <p></p>
            <h1 > Take Back The Heat <sup>v1.65</sup> </h1>
            <Figure>
                            <Figure.Image
                                src={worldAtNightImage}
                            />
               <Figure.Caption><Link to="/Etc"><sub>[15]</sub></Link></Figure.Caption>
            </Figure>
            <Container>
            <p>
            <h2>Air Joule conversions since Jan 1 2022</h2>
            </p>
                <Row className="ScoreBoard">
                    {/*renderScoreTile("Technical Solutions", 0, colors[0], onClick)*/}
                    {/*renderScoreTile("Heatback Devices deployed to date", 0, colors[1], onClick)*/}
                    {statsList.map((stat, index) => (
                        renderScoreTile(index,stat.title,stat.value,colors[index],onClick)
                    ))}
                    {/*renderScoreTile("Watt days", statsList[0] ? (statsList[0].value):(99), colors[2], onClick)*/}
                    {/*renderScoreTile("Organic Solutions", 0, colors[3], onClick)*/}
                    {/*renderScoreTile("Artificial Photosynthesis Solutions", 0, colors[4], onClick)*/}
                    {/*renderScoreTile("Heat Scavenger Solutions", 0, colors[5], onClick)*/}
                </Row>
            </Container>
            <p></p>
            <h2 > Mission</h2>
            <p></p>
            <h3>To address global warming directly by converting or diverting a portion of the atmospheric heat energy to something less harmful.</h3>
            <p></p>
            <p>
                Accomplished by identifying and working with the best available technology to provide proof of concept and working prototypes to collaborative partners and external entities; by building conversion units directly; by tracking progress of internal and external projects towards the stated numeric goal; and by encouraging communication within the related solution provider community.
            </p>
            <p></p>
                <h6>Metrics include 'proof of concept' test equipment, client registered equipment, and public subscriptions.  1 Watt-day = 24Wh.</h6>
                <h6>1 Watt-day = 0.000522 Carbon Dioxide metric ton equivalent (CO2E).  1 Megawatt-day (MWd) = 522 CO2E. <Link to ="/post/99116361-3fbc-40b6-a3aa-61c03e40043e"><sub>Link</sub></Link> </h6>
        </div>
        );
    }

    return (
        <div className="Home" >
            <Helmet>
                <title>{"TakeBackTheHeat - Home"}</title>
                <meta name="description" content="takebacktheheat.org progress on reducing the heat energy to address climate change and global warming." />
            </Helmet>
            {renderHome()}
        </div>
    );
}