import React, {useEffect} from "react";
import { Card, Figure, Container, Row, Col } from "react-bootstrap";
//import { Link } from 'react-router-dom';
import ahrrtrImage from "../media/ahrrtr.jpg";
import comingsoonImage from "../media/comingsoon1600x600.jpg";
import solarInIROutImage from "../media/SolarInIROut.png";
import solarInAHROutImage from "../media/SolarInAHROut.png";
import {Helmet} from "react-helmet";

export default function Products(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function renderAHRRTR() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Atmospheric Heat Reduction Air Joules
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={ahrrtrImage}
                                />
                                <Figure.Caption><a href="https://shop.takebacktheheat.org/products/air-joules-base">Air Joules</a></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>
                                
                            </p>
                            <p>
                                <b>Air Joules</b>
                            </p>
                            <p>
                                Available in single or multi packs, each Air Joule package represents your investment in the future of the planet.  
                                The Air Joules base package (172 MJ) is equivalent to removing 2000 watt-days over 1 year, which is equivalent to the anticipated results of removing 1 metric ton of carbon dioxide or equivalent greenhouse gas (CO2E).</p>
                                <p>
                                As each Air Joules base package is equivalent to carbon sequestered by 16.5 tree seedlings grown for 10 years (as per epa.gov), purchase multiple to have the same impact as planting a forest, without leaving your keyboard.</p>
                                <p>
                                We implement and maintain the Atmospheric Heat Reduction equipment in various configurations such as those shown in the concept diagram above, with a portion of each assigned to each Air Joules subscription for each year.
                                </p>
                                <p>
                                You report that amount (by total Joules removed, Watt-days removed, or CO2E removal equivalency) for the year on sustainability reports, web sites etc. to let others know you are actively helping cool the planet.
                                </p>
                            <p>
                                Powered by Shopify, head over to <a href="https://shop.takebacktheheat.org">https://shop.takebacktheheat.org</a> today!
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Dec 24, 2022
                </Card.Footer>

            </Card>

        )
    }
//             <Figure.Caption><a href="https://takebacktheheat/media/comingsoon1600x600.jpg">Concept</a></Figure.Caption>
  

    function renderProducts() {
        return (<div className="products" >
            <p></p>
            <h1 > Take Back The Heat - Products</h1>
            <Figure>
            <Figure.Image
                width={"80%"}
                src={solarInIROutImage}
             />
           </Figure>
           <Figure.Caption><b>Current:</b> Incoming Solar Radiation is partially absorbed and emitted as Infrared radiation, which is 'trapped' by GHGs in turn heating up the planet. </Figure.Caption>
           <p></p>
           <Figure>
            <Figure.Image
                width={"80%"}
                src={solarInAHROutImage}
             />
            <Figure.Caption><b>AHR:</b> Incoming Solar Radiation is partially converted to visible light, emitted out to solar system unchallenged by GHGs (which have no impact on visible light wavelengths).</Figure.Caption> 
           </Figure>
           <p></p>
            <Figure>
            <Figure.Image
                width={"80%"}
                src={comingsoonImage}
             />
            <Figure.Caption><b>Products:</b><p>For Individuals and small organizations, we offer online (below) Air Joules where we build and maintain equipment, you report towards your (unofficial) net zero efforts.</p>
                            <p>For Large organizations and Non Profits, we build large implements on your behalf, you report towards your (unofficial) GHG net zero targets.</p>
                            <p><a href="https://takebacktheheat.org/contact">Contact</a> us today!</p> </Figure.Caption> 
           </Figure>

            <Container>
                {renderAHRRTR()}

            </Container>
        </div>

        );
    }

    return (
        <div className="News" > 
            <Helmet>
                <title>{"TakeBackTheHeat - Products"}</title>
                <meta name="description" content="Products available for purchase through our shopify store." />
            </Helmet>
            {renderProducts()}
        </div>
    );
}