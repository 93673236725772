import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Button, Container, Row, InputGroup, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from 'aws-amplify';
import { blogsOrderBySortOrder, postsOrderByCreatedAt } from '../graphql/queries';
import { deletePost } from '../graphql/mutations';
import BootstrapTable from 'react-bootstrap-table-next';
import DOMPurify from 'dompurify';
import config from '../config';
import { Helmet } from "react-helmet";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './suneditor.css';

//import {topicOptions} from '../data';
import { buildTopicOptions } from '../data.js';

export default function Forum(props) {

    const firstBlog = config.FIRSTBLOG;  //"ccb16265-5895-4af1-bf76-b500b996fc2f";         //first post list to show by default is housekeeping blog
    const blogOwner = "ld"; //this is the owner of the top level blogs, since should all be the same is being used as partition key

    const topicOptions = buildTopicOptions();

    const [categoryOptions, setCategoryOptions] = useState([]);

    const [categoryType, setCategoryType] = useState([]);
    //    const [topicType, setTopicType] = useState([{ value: 1, label: "Design" }]);

    const [postList, setPostList] = useState([]);
    const [selectedPost, setSelectedPost] = useState();

    const editorRef = useRef();

    function ActionFormatter(data) {
        return (
            <div className="ButtonColumn">
                <Button type="button" variant="outline-primary" className="ActionButton" size="sm" onClick={() => handleSelectButton(data.row)}>select</Button>
                {(props.userDetails.username === data.row.owner) ? (<>
                    <Button type="button" variant="outline-secondary" className="ActionButton" size="sm" onClick={() => handleEditButton(data.row)}>edit</Button>
                    <Button type="button" variant="outline-danger" className="ActionButton" size="sm" onClick={() => handleDeleteButton(data.row)}>delete</Button></>) : (<></>)}
            </div>
        );
    }
    const actionFormatter = (cell, row) => <ActionFormatter row={row} />;


    //<Button title="Edit" size="sm" color="info"> <span className="fa fa-pencil" /> </Button>
    const postColumns = [{
        dataField: 'action',
        isDummyField: true,
        csvExport: false,
        formatter: actionFormatter,
        formatExtraData: props.userDetails.username,
        headerStyle: (column, colIndex) => { return { width: '16rem' }; },
        text: ''
    }, {
        dataField: 'title',
        text: 'Title'
    }, {
        dataField: 'topicText',
        text: 'Topic'
    }, {
        dataField: 'createdAt',
        text: 'Created At',
        formatter: (cell) => {
            return (
                <> {cell.substring(0, 10)} </>)
        }
    }, {
        dataField: 'postBlogId',
        hidden: true
    }
    ];

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            //alert(row.content);
            props.history.push("/post/" + row.id);
        }
    }

    const selectRow = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            setSelectedPost(row);
            setCategoryType(categoryOptions.find(obj => obj.value === row.postBlogId));
            const safeContent = DOMPurify.sanitize(row.content);
            if (editorRef.current !== undefined) {
                editorRef.current.editor.core.setContents(safeContent);
            }
        }
    };

    useEffect(() => {

        fetchBlogList();
        if (props.match.params.id === undefined) {
            fetchPostList(firstBlog);
        } else {
            fetchPostList(props.match.params.id);

        }

        // gets hung up on not having this function within useEffect, but we also call it later to reload.
        // interweb seems divided on how best to code. see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
        // eslint-disable-next-line
    }, []);

    function handleSelectButton(e) {
        props.history.push("/post/" + e.id);


    }
    function handleEditButton(e) {
        props.history.push("/postEdit/" + e.id);
    }

    async function handleDeleteButton(e) {
        let cResult = window.confirm("Delete " + e.title + " ?");
        if (cResult) {
            try {
                const delPost = {
                    id: e.id
                };
                await API.graphql({ query: deletePost, variables: { input: delPost }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
                fetchPostList(firstBlog);
            } catch (error) {
                alert(error);
                //console.log("unable to delete post ", e.title);
            }
        }
    }

    function handleCategoryChange(event) {     //will become blog list
        //        setAccountType(event.value);
        //l        if (!isLoading) {
        setCategoryType({ value: event.value, label: event.label });
        fetchPostList(event.value);
        //l        };
    };

    async function fetchBlogList() {
        try {
            //const blogListDataA = await API.graphql(graphqlOperation(listBlogs));
            //            const blogListData = await API.graphql({ query: blogsOrderBySortOrder, variables: { owner: blogOwner, sortDirection: 'ASC' }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const blogListData = await API.graphql({ query: blogsOrderBySortOrder, variables: { owner: blogOwner }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            const blogList = blogListData.data.blogsOrderBySortOrder.items;
            //                setBlogList(blogListA);
            const blist = blogList.map((item) => [{ value: item.id, label: item.name, sortOrder: item.sortOrder }]);
            const ablist = [].concat(...blist); //.sort(compare);
            setCategoryOptions(ablist);
        } catch (error) {
            //console.log("error on fetching blogList", error);
        };
    };

    async function fetchPostList(blogId) {
        if (blogId === undefined) {
        }
        else {
            //l        if (!isLoading) {
            try {
                //            const postListData = await API.graphql({ query: getBlog, variables: { id: blogId }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
                const postListData = await API.graphql({ query: postsOrderByCreatedAt, variables: { postBlogId: blogId }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
                //            if (postListData.data.getBlog.posts.items.length > 0) {
                //                const postList = postListData.data.getBlog.posts.items.map((x) => ({...x,topicText:topicOptions[(x.topic)-1].label}));
                if (postListData.data.postsOrderByCreatedAt.items.length > 0) {
                    const postList = postListData.data.postsOrderByCreatedAt.items.map((x) => ({ ...x, topicText: topicOptions[(x.topic) - 1].label }));

                    setPostList(postList);
                } else {
                    setPostList([]);
                };
            } catch (error) {
                //console.log("error on fetching postList", error);
            };
            //l        };
        };
    };

    function renderNewButton() {
        return (
            <LinkContainer key="new" to="/post/new">
                <Button className="float-right ldButton" middle="xs"
                >
                    New
                </Button>
            </LinkContainer>
        )
    }

    function renderForum() {
        return (<div className="Forum" >
            <p></p>
            <Container className="ForumMenu" fluid>
                <Row className="ldTopRow">
                    <div>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>Category</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Select className="selectCategorySm"
                                value={categoryType || ''}
                                onChange={handleCategoryChange}
                                options={categoryOptions}
                                placeholder=""
                            />
                        </InputGroup>
                    </div>
                    <div>
                        {renderNewButton()}
                    </div>
                </Row>
            </Container>
            <div className="ForumPosts">
                <BootstrapTable
                    keyField='id'
                    data={postList}
                    columns={postColumns}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                >
                </BootstrapTable>
            </div>
            <div>
                {selectedPost === undefined ? (
                    <>
                    </>
                ) : (
                    <Card className="ForumPostCard">
                        <Card.Body className="ldCardBody">
                            {/*selectedPost.content*/}
                            <div className="EditorContent">
                                <SunEditor ref={editorRef}
                                    disable={true}
                                    enableToolbar={false}
                                    autoFocus={true}
                                    showToolbar={false}
                                    width="100%"
                                    height="100%"
                                    setOptions={{
                                        resizingBar: false,
                                        showPathLabel: false,
                                        height: '100%',
                                        defaultStyle: 'sun-editor-editable',
                                    }}
                                />
                            </div>
                        </Card.Body>
                        <Card.Footer className="ForumPostCardFooter">
                            {selectedPost.createdAt}
                        </Card.Footer>
                    </Card>
                )}
            </div>
        </div>
        );
    }

    return (
        <div>
            <Helmet>
                <title>{"TakeBackTheHeat - Forum"}</title>
                <meta name="description" content="takebacktheheat.org discussion site and information consolidation regarding how to reduce heat energy to address climate change and global warming." />
            </Helmet>
            {renderForum()}
        </div>
    );
}