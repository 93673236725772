const stationList = 
 [
      {
        "Name": "ACTIVE PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010066",
        "Station ID": 14,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -123.28,
        "Latitude": 485200000,
        "Longitude": -1231700000,
        "Elevation (m)": 4,
        "First Year": 1984,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1996,
        "MLY First Year": 1984,
        "MLY Last Year": 1996
      },
      {
        "Name": "ALBERT HEAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010235",
        "Station ID": 15,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -123.48,
        "Latitude": 482400000,
        "Longitude": -1232900000,
        "Elevation (m)": 17,
        "First Year": 1971,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1995,
        "MLY First Year": 1971,
        "MLY Last Year": 1995
      },
      {
        "Name": "BAMBERTON OCEAN CEMENT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010595",
        "Station ID": 16,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -123.52,
        "Latitude": 483500000,
        "Longitude": -1233100000,
        "Elevation (m)": 85.3,
        "First Year": 1961,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1980,
        "MLY First Year": 1961,
        "MLY Last Year": 1980
      },
      {
        "Name": "BEAR CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010720",
        "Station ID": 17,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -124,
        "Latitude": 483000000,
        "Longitude": -1240000000,
        "Elevation (m)": 350.5,
        "First Year": 1910,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1971,
        "MLY First Year": 1910,
        "MLY Last Year": 1971
      },
      {
        "Name": "BEAVER LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010774",
        "Station ID": 18,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -123.35,
        "Latitude": 483000000,
        "Longitude": -1232100000,
        "Elevation (m)": 61,
        "First Year": 1894,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 1952,
        "MLY First Year": 1894,
        "MLY Last Year": 1952
      },
      {
        "Name": "BECHER BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010780",
        "Station ID": 19,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -123.63,
        "Latitude": 482000000,
        "Longitude": -1233800000,
        "Elevation (m)": 12.2,
        "First Year": 1956,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1966,
        "MLY First Year": 1956,
        "MLY Last Year": 1966
      },
      {
        "Name": "BRENTWOOD BAY 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010960",
        "Station ID": 20,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 483600000,
        "Longitude": -1232800000,
        "Elevation (m)": 38,
        "First Year": 1987,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1997,
        "MLY First Year": 1987,
        "MLY Last Year": 1997
      },
      {
        "Name": "BRENTWOOD CLARKE ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010961",
        "Station ID": 21,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -123.45,
        "Latitude": 483400000,
        "Longitude": -1232700000,
        "Elevation (m)": 30.5,
        "First Year": 1972,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1980,
        "MLY First Year": 1972,
        "MLY Last Year": 1980
      },
      {
        "Name": "BRENTWOOD W SAANICH RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1010965",
        "Station ID": 22,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 483400000,
        "Longitude": -1232600000,
        "Elevation (m)": 91.4,
        "First Year": 1960,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1970,
        "MLY First Year": 1960,
        "MLY Last Year": 1970
      },
      {
        "Name": "CENTRAL SAANICH VEYANESS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011467",
        "Station ID": 25,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483500000,
        "Longitude": -1232500000,
        "Elevation (m)": 53.3,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "CENTRAL SAANICH ISL VIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10114F6",
        "Station ID": 24,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -123.39,
        "Latitude": 483424030,
        "Longitude": -1232331660,
        "Elevation (m)": 38,
        "First Year": 1970,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2008,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "CENTRAL SAANICH TANNER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10114FF",
        "Station ID": 23,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 483300000,
        "Longitude": -1232400000,
        "Elevation (m)": 88.4,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "CHEMAINUS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011500",
        "Station ID": 26,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.94,
        "Longitude (Decimal Degrees)": -123.74,
        "Latitude": 485606080,
        "Longitude": -1234430000,
        "Elevation (m)": 75,
        "First Year": 1919,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 2022,
        "MLY First Year": 1919,
        "MLY Last Year": 2007
      },
      {
        "Name": "COBBLE HILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011743",
        "Station ID": 27,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -123.6,
        "Latitude": 484100000,
        "Longitude": -1233600000,
        "Elevation (m)": 99,
        "First Year": 1990,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1994,
        "MLY First Year": 1990,
        "MLY Last Year": 1994
      },
      {
        "Name": "COBBLE HILL DELOUME ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011745",
        "Station ID": 28,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.57,
        "Latitude": 483900000,
        "Longitude": -1233400000,
        "Elevation (m)": 61,
        "First Year": 1970,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1981,
        "MLY First Year": 1970,
        "MLY Last Year": 1981
      },
      {
        "Name": "COLE BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011775",
        "Station ID": 29,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 483900000,
        "Longitude": -1232400000,
        "Elevation (m)": 36.6,
        "First Year": 1923,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1942,
        "MLY First Year": 1923,
        "MLY Last Year": 1942
      },
      {
        "Name": "COLWOOD HATLEY DR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011810",
        "Station ID": 30,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.48,
        "Latitude": 482504000,
        "Longitude": -1232850000,
        "Elevation (m)": 75.6,
        "First Year": 1981,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2001,
        "MLY First Year": 1981,
        "MLY Last Year": 2001
      },
      {
        "Name": "COPPER CANYON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011860",
        "Station ID": 31,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -123.83,
        "Latitude": 485000000,
        "Longitude": -1235000000,
        "Elevation (m)": 176.8,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1957,
        "MLY Last Year": 1959
      },
      {
        "Name": "CORDOVA BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011920",
        "Station ID": 32,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 483200000,
        "Longitude": -1232200000,
        "Elevation (m)": 37.2,
        "First Year": 1951,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1970,
        "MLY First Year": 1951,
        "MLY Last Year": 1970
      },
      {
        "Name": "CORDOVA BAY SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1011922",
        "Station ID": 33,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 483100000,
        "Longitude": -1232200000,
        "Elevation (m)": 26,
        "First Year": 1972,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1995,
        "MLY First Year": 1972,
        "MLY Last Year": 1995
      },
      {
        "Name": "COWICHAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012008",
        "Station ID": 34,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.7,
        "Latitude": 482500000,
        "Longitude": -1234200000,
        "Elevation (m)": 51.8,
        "First Year": 1904,
        "Last Year": 1925,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1925,
        "MLY First Year": 1904,
        "MLY Last Year": 1925
      },
      {
        "Name": "COWICHAN BAY CHERRY POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012010",
        "Station ID": 35,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.71,
        "Longitude (Decimal Degrees)": -123.56,
        "Latitude": 484240000,
        "Longitude": -1233324000,
        "Elevation (m)": 1,
        "First Year": 1913,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2001,
        "MLY First Year": 1913,
        "MLY Last Year": 2001
      },
      {
        "Name": "COWICHAN BAY SOUTHEAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012018",
        "Station ID": 36,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -123.6,
        "Latitude": 484400000,
        "Longitude": -1233600000,
        "Elevation (m)": 94,
        "First Year": 1989,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1994,
        "MLY First Year": 1989,
        "MLY Last Year": 1994
      },
      {
        "Name": "COWICHAN LAKE FORESTRY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012040",
        "Station ID": 37,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -124.13,
        "Latitude": 484928000,
        "Longitude": -1240800000,
        "Elevation (m)": 176.8,
        "First Year": 1949,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2018,
        "MLY First Year": 1949,
        "MLY Last Year": 2007
      },
      {
        "Name": "COWICHAN LAKE HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012046",
        "Station ID": 38,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -124.12,
        "Latitude": 485000000,
        "Longitude": -1240700000,
        "Elevation (m)": 152.4,
        "First Year": 1931,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1948,
        "MLY First Year": 1931,
        "MLY Last Year": 1948
      },
      {
        "Name": "COWICHAN LAKE NITINAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012052",
        "Station ID": 39,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -124.48,
        "Latitude": 485600000,
        "Longitude": -1242900000,
        "Elevation (m)": 189,
        "First Year": 1959,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1962,
        "MLY First Year": 1959,
        "MLY Last Year": 1962
      },
      {
        "Name": "LAKE COWICHAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012055",
        "Station ID": 40,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -124.05,
        "Latitude": 484946000,
        "Longitude": -1240308000,
        "Elevation (m)": 171,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "CROFTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012190",
        "Station ID": 41,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -123.67,
        "Latitude": 484900000,
        "Longitude": -1234000000,
        "Elevation (m)": 30.5,
        "First Year": 1921,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1968,
        "MLY First Year": 1921,
        "MLY Last Year": 1968
      },
      {
        "Name": "DISCOVERY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012475",
        "Station ID": 27226,
        "WMO ID": 71031,
        "TC ID": "WDR",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.23,
        "Latitude": 482528590,
        "Longitude": -1231332519,
        "Elevation (m)": 18.93,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2005
      },
      {
        "Name": "DUNCAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012550",
        "Station ID": 42,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -123.72,
        "Latitude": 484700000,
        "Longitude": -1234300000,
        "Elevation (m)": 8.5,
        "First Year": 1926,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1957,
        "MLY First Year": 1926,
        "MLY Last Year": 1957
      },
      {
        "Name": "DUNCAN BCHPA VIT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012562",
        "Station ID": 43,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -123.72,
        "Latitude": 485000000,
        "Longitude": -1234300000,
        "Elevation (m)": 36,
        "First Year": 1972,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "DUNCAN EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012566",
        "Station ID": 44,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -123.68,
        "Latitude": 484700000,
        "Longitude": -1234100000,
        "Elevation (m)": 7.6,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1964
      },
      {
        "Name": "DUNCAN FORESTRY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012570",
        "Station ID": 45,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -123.68,
        "Latitude": 484600000,
        "Longitude": -1234100000,
        "Elevation (m)": 6,
        "First Year": 1958,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1989,
        "MLY First Year": 1958,
        "MLY Last Year": 1989
      },
      {
        "Name": "DUNCAN KELVIN CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012573",
        "Station ID": 46,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 484405000,
        "Longitude": -1234339001,
        "Elevation (m)": 103,
        "First Year": 1987,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2016,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "EAST SOOKE ANDERSON COVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012628",
        "Station ID": 47,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -123.63,
        "Latitude": 482100000,
        "Longitude": -1233800000,
        "Elevation (m)": 36.6,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "ELK LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012655",
        "Station ID": 48,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -123.38,
        "Latitude": 483100000,
        "Longitude": -1232300000,
        "Elevation (m)": 114.3,
        "First Year": 1957,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1978,
        "MLY First Year": 1957,
        "MLY Last Year": 1978
      },
      {
        "Name": "ELK LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012656",
        "Station ID": 49,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -123.38,
        "Latitude": 483200000,
        "Longitude": -1232300000,
        "Elevation (m)": 53,
        "First Year": 1980,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1986,
        "MLY First Year": 1980,
        "MLY Last Year": 1986
      },
      {
        "Name": "ESQUIMALT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012700",
        "Station ID": 50,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 482600000,
        "Longitude": -1232500000,
        "Elevation (m)": 13.7,
        "First Year": 1872,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1950,
        "MLY First Year": 1872,
        "MLY Last Year": 1950
      },
      {
        "Name": "ESQUIMALT LAGOON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012707",
        "Station ID": 51,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 482500000,
        "Longitude": -1232800000,
        "Elevation (m)": 15.2,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "ESQUIMALT HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012710",
        "Station ID": 52,
        "WMO ID": 71798,
        "TC ID": "WPF",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 482555100,
        "Longitude": -1232621600,
        "Elevation (m)": 3,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2005
      },
      {
        "Name": "FINLAYSON ARM RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1012883",
        "Station ID": 53,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 483000000,
        "Longitude": -1233200000,
        "Elevation (m)": 167.6,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "GALIANO ISLAND GCC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013045",
        "Station ID": 56,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -123.35,
        "Latitude": 485400000,
        "Longitude": -1232100000,
        "Elevation (m)": 15.2,
        "First Year": 1956,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1977,
        "MLY First Year": 1956,
        "MLY Last Year": 1977
      },
      {
        "Name": "GANGES MANSELL RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013051",
        "Station ID": 57,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -123.5,
        "Latitude": 485218200,
        "Longitude": -1232953450,
        "Elevation (m)": 45,
        "First Year": 1974,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2014,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "GALIANO ISL BURRILL PT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10130DE",
        "Station ID": 54,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 485200000,
        "Longitude": -1231900000,
        "Elevation (m)": 1.5,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "GALIANO NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10130MN",
        "Station ID": 55,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.99,
        "Longitude (Decimal Degrees)": -123.57,
        "Latitude": 485906030,
        "Longitude": -1233424020,
        "Elevation (m)": 6,
        "First Year": 1975,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2021,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOLDSTREAM LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013240",
        "Station ID": 58,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.55,
        "Latitude": 482700000,
        "Longitude": -1233300000,
        "Elevation (m)": 458.7,
        "First Year": 1894,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 1953,
        "MLY First Year": 1894,
        "MLY Last Year": 1953
      },
      {
        "Name": "GOLDSTREAM MEADOWS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013241",
        "Station ID": 59,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 483700000,
        "Longitude": -1233200000,
        "Elevation (m)": 64,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "HONEYMOON BAY NORTHWEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013519",
        "Station ID": 60,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -124.19,
        "Latitude": 484951000,
        "Longitude": -1241139000,
        "Elevation (m)": 170,
        "First Year": 1989,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2003,
        "MLY First Year": 1989,
        "MLY Last Year": 2003
      },
      {
        "Name": "JAMES ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013720",
        "Station ID": 61,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -123.35,
        "Latitude": 483600000,
        "Longitude": -1232100000,
        "Elevation (m)": 53.6,
        "First Year": 1914,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1978,
        "MLY First Year": 1914,
        "MLY Last Year": 1978
      },
      {
        "Name": "KELP REEFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1013998",
        "Station ID": 10853,
        "WMO ID": null,
        "TC ID": "WZO",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -123.24,
        "Latitude": 483251700,
        "Longitude": -1231413320,
        "Elevation (m)": null,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KUPER ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014430",
        "Station ID": 62,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -123.63,
        "Latitude": 485800000,
        "Longitude": -1233800000,
        "Elevation (m)": null,
        "First Year": 1894,
        "Last Year": 1905,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 1905,
        "MLY First Year": 1894,
        "MLY Last Year": 1905
      },
      {
        "Name": "LAKE COWICHAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014493",
        "Station ID": 63,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -124.05,
        "Latitude": 484900000,
        "Longitude": -1240300000,
        "Elevation (m)": 180,
        "First Year": 1924,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1994,
        "MLY First Year": 1924,
        "MLY Last Year": 1994
      },
      {
        "Name": "LANGFORD LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014530",
        "Station ID": 64,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 482600000,
        "Longitude": -1233200000,
        "Elevation (m)": 75,
        "First Year": 1953,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1983,
        "MLY First Year": 1953,
        "MLY Last Year": 1983
      },
      {
        "Name": "MALAHAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014818",
        "Station ID": 10730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -123.58,
        "Latitude": 483500000,
        "Longitude": -1233500000,
        "Elevation (m)": 275,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": 1991,
        "HLY Last Year": 1992,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MALAHAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014820",
        "Station ID": 65,
        "WMO ID": 71774,
        "TC ID": "WKH",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 483429700,
        "Longitude": -1233147700,
        "Elevation (m)": 365.8,
        "First Year": 1920,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1920,
        "DLY Last Year": 2022,
        "MLY First Year": 1920,
        "MLY Last Year": 2005
      },
      {
        "Name": "MAYNE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014930",
        "Station ID": 66,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 485100000,
        "Longitude": -1232000000,
        "Elevation (m)": 9.1,
        "First Year": 1921,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1942,
        "MLY First Year": 1921,
        "MLY Last Year": 1942
      },
      {
        "Name": "MAYNE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1014931",
        "Station ID": 67,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.84,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 485041000,
        "Longitude": -1231913000,
        "Elevation (m)": 28,
        "First Year": 1970,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2002,
        "MLY First Year": 1970,
        "MLY Last Year": 2002
      },
      {
        "Name": "METCHOSIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015105",
        "Station ID": 68,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -123.56,
        "Latitude": 482227001,
        "Longitude": -1233341002,
        "Elevation (m)": 164,
        "First Year": 1911,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 2022,
        "MLY First Year": 1915,
        "MLY Last Year": 2007
      },
      {
        "Name": "METCHOSIN HAPPY VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015107",
        "Station ID": 69,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 482500000,
        "Longitude": -1233200000,
        "Elevation (m)": 76,
        "First Year": 1970,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1998,
        "MLY First Year": 1970,
        "MLY Last Year": 1998
      },
      {
        "Name": "METCHOSIN MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015109",
        "Station ID": 70,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 482400000,
        "Longitude": -1233200000,
        "Elevation (m)": 91.4,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "METCHOSIN PARRY BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015111",
        "Station ID": 6809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.38,
        "Longitude (Decimal Degrees)": -123.5,
        "Latitude": 482300000,
        "Longitude": -1233000000,
        "Elevation (m)": 3,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "MILL BAY KILMALU",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015134",
        "Station ID": 71,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.55,
        "Latitude": 483900000,
        "Longitude": -1233300000,
        "Elevation (m)": 45.7,
        "First Year": 1972,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1983,
        "MLY First Year": 1972,
        "MLY Last Year": 1983
      },
      {
        "Name": "MILL BAY 1 SOUTHWEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015136",
        "Station ID": 72,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.64,
        "Longitude (Decimal Degrees)": -123.56,
        "Latitude": 483841000,
        "Longitude": -1233327000,
        "Elevation (m)": 100,
        "First Year": 1989,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2006,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "MILLSTREAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015150",
        "Station ID": 73,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.48,
        "Longitude (Decimal Degrees)": -123.55,
        "Latitude": 482900000,
        "Longitude": -1233300000,
        "Elevation (m)": 213.4,
        "First Year": 1926,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1941,
        "MLY First Year": 1926,
        "MLY Last Year": 1941
      },
      {
        "Name": "MILNES LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015160",
        "Station ID": 74,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 482100000,
        "Longitude": -1234400000,
        "Elevation (m)": 38.1,
        "First Year": 1910,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1956,
        "MLY First Year": 1910,
        "MLY Last Year": 1956
      },
      {
        "Name": "MUIR CREEK 1W",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015242",
        "Station ID": 75,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.38,
        "Longitude (Decimal Degrees)": -123.88,
        "Latitude": 482300000,
        "Longitude": -1235300000,
        "Elevation (m)": 30,
        "First Year": 1970,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1986,
        "MLY First Year": 1970,
        "MLY Last Year": 1986
      },
      {
        "Name": "NORTH COWICHAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015628",
        "Station ID": 76,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -123.72,
        "Latitude": 484927000,
        "Longitude": -1234306000,
        "Elevation (m)": 45.7,
        "First Year": 1981,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORTH COWICHAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015630",
        "Station ID": 46728,
        "WMO ID": 71927,
        "TC ID": "VOO",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -123.72,
        "Latitude": 484927010,
        "Longitude": -1234308009,
        "Elevation (m)": 44.8,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH PENDER ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1015638",
        "Station ID": 77,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -123.29,
        "Latitude": 484550040,
        "Longitude": -1231712010,
        "Elevation (m)": 98,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "PENDER ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016120",
        "Station ID": 79,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 484600000,
        "Longitude": -1231800000,
        "Elevation (m)": 26.2,
        "First Year": 1924,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1999,
        "MLY First Year": 1924,
        "MLY Last Year": 1999
      },
      {
        "Name": "PIERS ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016169",
        "Station ID": 80,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 484200000,
        "Longitude": -1232500000,
        "Elevation (m)": 17.1,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "POINT-NO-POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016203",
        "Station ID": 81,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.41,
        "Longitude (Decimal Degrees)": -123.99,
        "Latitude": 482421000,
        "Longitude": -1235929000,
        "Elevation (m)": 25,
        "First Year": 1990,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2005,
        "MLY First Year": 1990,
        "MLY Last Year": 2005
      },
      {
        "Name": "PORT RENFREW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016335",
        "Station ID": 82,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.59,
        "Longitude (Decimal Degrees)": -124.33,
        "Latitude": 483530003,
        "Longitude": -1241935000,
        "Elevation (m)": 10,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "RACE ROCKS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016640",
        "Station ID": 10943,
        "WMO ID": 71778,
        "TC ID": "WQK",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 481752743,
        "Longitude": -1233153186,
        "Elevation (m)": 7.4,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RACE ROCKS LIGHTSTATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016641",
        "Station ID": 83,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 481800000,
        "Longitude": -1233200000,
        "Elevation (m)": 4.9,
        "First Year": 1984,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1997,
        "MLY First Year": 1984,
        "MLY Last Year": 1997
      },
      {
        "Name": "RIVER JORDAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016780",
        "Station ID": 84,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -124.05,
        "Latitude": 482500000,
        "Longitude": -1240300000,
        "Elevation (m)": 3,
        "First Year": 1908,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1972,
        "MLY First Year": 1908,
        "MLY Last Year": 1972
      },
      {
        "Name": "SAANICHTON CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016940",
        "Station ID": 87,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483718000,
        "Longitude": -1232508000,
        "Elevation (m)": 61,
        "First Year": 1914,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2022,
        "MLY First Year": 1914,
        "MLY Last Year": 2007
      },
      {
        "Name": "SAANICH CAMOSUN COLLEGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016941",
        "Station ID": 88,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483000000,
        "Longitude": -1232500000,
        "Elevation (m)": 38.1,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "SAANICH DENSMORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016942",
        "Station ID": 89,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 483000000,
        "Longitude": -1232400000,
        "Elevation (m)": 59,
        "First Year": 1963,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1992,
        "MLY First Year": 1963,
        "MLY Last Year": 1992
      },
      {
        "Name": "SAANICHTON CFIA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016943",
        "Station ID": 54641,
        "WMO ID": 73028,
        "TC ID": "VSA",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483718000,
        "Longitude": -1232508000,
        "Elevation (m)": 61,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SALTAIR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016980",
        "Station ID": 90,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -123.77,
        "Latitude": 485800000,
        "Longitude": -1234600000,
        "Elevation (m)": 15.2,
        "First Year": 1959,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1967,
        "MLY First Year": 1959,
        "MLY Last Year": 1967
      },
      {
        "Name": "SALTSPRING ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016990",
        "Station ID": 91,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -123.5,
        "Latitude": 485100000,
        "Longitude": -1233000000,
        "Elevation (m)": 73.2,
        "First Year": 1893,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1977,
        "MLY First Year": 1893,
        "MLY Last Year": 1977
      },
      {
        "Name": "SALTSPRING IS CUSHEON LK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016992",
        "Station ID": 92,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -123.48,
        "Latitude": 484913000,
        "Longitude": -1232836000,
        "Elevation (m)": 107.9,
        "First Year": 1976,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2001,
        "MLY First Year": 1976,
        "MLY Last Year": 2001
      },
      {
        "Name": "SALTSPRING ST MARY'S L",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016995",
        "Station ID": 93,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.89,
        "Longitude (Decimal Degrees)": -123.55,
        "Latitude": 485317000,
        "Longitude": -1233247000,
        "Elevation (m)": 45.7,
        "First Year": 1975,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "SAANICH DOM ASTRO OBS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10169DK",
        "Station ID": 85,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483100000,
        "Longitude": -1232500000,
        "Elevation (m)": 222.5,
        "First Year": 1916,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1977,
        "MLY First Year": 1916,
        "MLY Last Year": 1977
      },
      {
        "Name": "SAANICH OLDFIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10169MK",
        "Station ID": 86,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483300000,
        "Longitude": -1232500000,
        "Elevation (m)": 69.8,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "SAANICHTON MT NEWTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1016RM0",
        "Station ID": 78,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 483551000,
        "Longitude": -1232538000,
        "Elevation (m)": 42.7,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALTSPRING VESUVIUS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017000",
        "Station ID": 94,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -123.57,
        "Latitude": 485300000,
        "Longitude": -1233400000,
        "Elevation (m)": 7.6,
        "First Year": 1955,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1975,
        "MLY First Year": 1955,
        "MLY Last Year": 1975
      },
      {
        "Name": "SATURNA CAPMON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017098",
        "Station ID": 95,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 484631000,
        "Longitude": -1230740006,
        "Elevation (m)": 178,
        "First Year": 1989,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2017,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "SATURNA CAPMON CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017099",
        "Station ID": 6810,
        "WMO ID": 71914,
        "TC ID": "VTS",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 484630080,
        "Longitude": -1230741080,
        "Elevation (m)": 178,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "SATURNA ISLAND CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017101",
        "Station ID": 96,
        "WMO ID": 71473,
        "TC ID": "WEZ",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -123.04,
        "Latitude": 484702067,
        "Longitude": -1230241082,
        "Elevation (m)": 24.4,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHAWNIGAN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017230",
        "Station ID": 97,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.63,
        "Latitude": 483849010,
        "Longitude": -1233735070,
        "Elevation (m)": 159,
        "First Year": 1911,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 2022,
        "MLY First Year": 1911,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHAWNIGAN LAKE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017233",
        "Station ID": 98,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.63,
        "Latitude": 483900000,
        "Longitude": -1233800000,
        "Elevation (m)": 149.4,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "SHERINGHAM POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017254",
        "Station ID": 6811,
        "WMO ID": 71780,
        "TC ID": "WSP",
        "Latitude (Decimal Degrees)": 48.38,
        "Longitude (Decimal Degrees)": -123.92,
        "Latitude": 482236100,
        "Longitude": -1235515700,
        "Elevation (m)": 6.9,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "SIDNEY DEEP COVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017329",
        "Station ID": 99,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 484100000,
        "Longitude": -1232400000,
        "Elevation (m)": 18.3,
        "First Year": 1967,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1973,
        "MLY First Year": 1967,
        "MLY Last Year": 1973
      },
      {
        "Name": "SOOKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017556",
        "Station ID": 100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 482200000,
        "Longitude": -1234400000,
        "Elevation (m)": 27.4,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "SOOKE GLINZ LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017559",
        "Station ID": 101,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.63,
        "Latitude": 482500000,
        "Longitude": -1233800000,
        "Elevation (m)": 256,
        "First Year": 1983,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1995,
        "MLY First Year": 1983,
        "MLY Last Year": 1995
      },
      {
        "Name": "SOOKE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017560",
        "Station ID": 102,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -123.7,
        "Latitude": 483100000,
        "Longitude": -1234200000,
        "Elevation (m)": 172.8,
        "First Year": 1903,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1966,
        "MLY First Year": 1903,
        "MLY Last Year": 1966
      },
      {
        "Name": "SOOKE LAKE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017563",
        "Station ID": 103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -123.64,
        "Latitude": 483432000,
        "Longitude": -1233824000,
        "Elevation (m)": 231,
        "First Year": 1966,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOOKE OTTER POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017570",
        "Station ID": 104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -123.82,
        "Latitude": 482200000,
        "Longitude": -1234900000,
        "Elevation (m)": 39.6,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "SOOKE SASEENOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017575",
        "Station ID": 105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.38,
        "Longitude (Decimal Degrees)": -123.67,
        "Latitude": 482300000,
        "Longitude": -1234000000,
        "Elevation (m)": 27.4,
        "First Year": 1976,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "SOUTH PENDER ISLAND 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1017610",
        "Station ID": 106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -123.22,
        "Latitude": 484500000,
        "Longitude": -1231300000,
        "Elevation (m)": 91.1,
        "First Year": 1966,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1982
      },
      {
        "Name": "TRIAL ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018238",
        "Station ID": 27290,
        "WMO ID": 71034,
        "TC ID": "WIN",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482342200,
        "Longitude": -1231817670,
        "Elevation (m)": 23,
        "First Year": 1997,
        "Last Year": 1998,
        "HLY First Year": 1997,
        "HLY Last Year": 1998,
        "DLY First Year": 1998,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA AMROTH PLACE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018590",
        "Station ID": 108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.28,
        "Latitude": 482700000,
        "Longitude": -1231700000,
        "Elevation (m)": 18.3,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "VICTORIA ARDMORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018592",
        "Station ID": 109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 483800000,
        "Longitude": -1232800000,
        "Elevation (m)": 24.4,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "VICTORIA BAY STREET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018595",
        "Station ID": 110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 482600000,
        "Longitude": -1232200000,
        "Elevation (m)": 13.7,
        "First Year": 1958,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1961,
        "MLY First Year": 1958,
        "MLY Last Year": 1961
      },
      {
        "Name": "VICTORIA UNIVERSITY CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018598",
        "Station ID": 6812,
        "WMO ID": 71783,
        "TC ID": "WYJ",
        "Latitude (Decimal Degrees)": 48.46,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482725200,
        "Longitude": -1231816600,
        "Elevation (m)": 60.1,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICTORIA CAREY ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018600",
        "Station ID": 111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 482800000,
        "Longitude": -1232800000,
        "Elevation (m)": 19.8,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "VICTORIA FRANCIS PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018605",
        "Station ID": 112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.48,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 482835000,
        "Longitude": -1232635000,
        "Elevation (m)": 55,
        "First Year": 1962,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2017,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICTORIA GONZALES HTS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018610",
        "Station ID": 113,
        "WMO ID": null,
        "TC ID": "WLM",
        "Latitude (Decimal Degrees)": 48.41,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 482447061,
        "Longitude": -1231930017,
        "Elevation (m)": 69.5,
        "First Year": 1898,
        "Last Year": 2004,
        "HLY First Year": 1953,
        "HLY Last Year": 2000,
        "DLY First Year": 1898,
        "DLY Last Year": 2004,
        "MLY First Year": 1898,
        "MLY Last Year": 2004
      },
      {
        "Name": "VICTORIA GONZALES CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018611",
        "Station ID": 114,
        "WMO ID": 71200,
        "TC ID": "WLM",
        "Latitude (Decimal Degrees)": 48.41,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 482447896,
        "Longitude": -1231929192,
        "Elevation (m)": 61.04,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICTORIA GORDON HEAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018614",
        "Station ID": 115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482800000,
        "Longitude": -1231800000,
        "Elevation (m)": 42.7,
        "First Year": 1959,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1983,
        "MLY First Year": 1959,
        "MLY Last Year": 1983
      },
      {
        "Name": "VICTORIA HARBOUR A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018615",
        "Station ID": 10944,
        "WMO ID": null,
        "TC ID": "YWH",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.39,
        "Latitude": 482522000,
        "Longitude": -1232315000,
        "Elevation (m)": null,
        "First Year": 1994,
        "Last Year": 2015,
        "HLY First Year": 1994,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA HIGHLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018616",
        "Station ID": 116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.51,
        "Longitude (Decimal Degrees)": -123.51,
        "Latitude": 483033000,
        "Longitude": -1233030000,
        "Elevation (m)": 190.6,
        "First Year": 1961,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2012,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICTORIA HOLLAND 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018617",
        "Station ID": 117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.48,
        "Longitude (Decimal Degrees)": -123.41,
        "Latitude": 482902000,
        "Longitude": -1232423000,
        "Elevation (m)": 15.2,
        "First Year": 1960,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2002,
        "MLY First Year": 1960,
        "MLY Last Year": 2002
      },
      {
        "Name": "VICTORIA HIGHLANDS NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018618",
        "Station ID": 49468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.54,
        "Longitude (Decimal Degrees)": -123.5,
        "Latitude": 483215005,
        "Longitude": -1233004000,
        "Elevation (m)": 230,
        "First Year": 2011,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2017,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA HARBOUR A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018619",
        "Station ID": 53478,
        "WMO ID": null,
        "TC ID": "YWH",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.39,
        "Latitude": 482522000,
        "Longitude": -1232315000,
        "Elevation (m)": null,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA INT'L A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018620",
        "Station ID": 118,
        "WMO ID": 71799,
        "TC ID": "YYJ",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 483850010,
        "Longitude": -1232533000,
        "Elevation (m)": 19.51,
        "First Year": 1940,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1940,
        "DLY Last Year": 2013,
        "MLY First Year": 1940,
        "MLY Last Year": 2013
      },
      {
        "Name": "VICTORIA INTL A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018621",
        "Station ID": 51337,
        "WMO ID": 71799,
        "TC ID": "YYJ",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 483850000,
        "Longitude": -1232533000,
        "Elevation (m)": 19.5,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA JUBILEE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018629",
        "Station ID": 119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 482600000,
        "Longitude": -1232000000,
        "Elevation (m)": 23.5,
        "First Year": 1968,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1969,
        "MLY First Year": 1968,
        "MLY Last Year": 1969
      },
      {
        "Name": "VICTORIA HARBOUR A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018633",
        "Station ID": 53479,
        "WMO ID": null,
        "TC ID": "YWH",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -123.39,
        "Latitude": 482522000,
        "Longitude": -1232315000,
        "Elevation (m)": null,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA LANSDOWNE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018640",
        "Station ID": 120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 482600000,
        "Longitude": -1231900000,
        "Elevation (m)": 29,
        "First Year": 1954,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1962,
        "MLY First Year": 1954,
        "MLY Last Year": 1962
      },
      {
        "Name": "VICTORIA MARINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018642",
        "Station ID": 121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -123.75,
        "Latitude": 482200000,
        "Longitude": -1234500000,
        "Elevation (m)": 31.7,
        "First Year": 1967,
        "Last Year": 1992,
        "HLY First Year": 1967,
        "HLY Last Year": 1992,
        "DLY First Year": 1967,
        "DLY Last Year": 1992,
        "MLY First Year": 1967,
        "MLY Last Year": 1992
      },
      {
        "Name": "VICTORIA MUSGRAVE STREET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018644",
        "Station ID": 122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482600000,
        "Longitude": -1231800000,
        "Elevation (m)": 15.2,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "VICTORIA NORTH DAIRY RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018648",
        "Station ID": 123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 482700000,
        "Longitude": -1232000000,
        "Elevation (m)": 40.8,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "VICTORIA NORTHRIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018651",
        "Station ID": 124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.49,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 482917000,
        "Longitude": -1232355000,
        "Elevation (m)": 37,
        "First Year": 1990,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1999,
        "MLY First Year": 1990,
        "MLY Last Year": 1999
      },
      {
        "Name": "VICTORIA ROWLEY ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018657",
        "Station ID": 125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482800000,
        "Longitude": -1231800000,
        "Elevation (m)": 13.4,
        "First Year": 1962,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1962,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "VICTORIA SINCLAIR ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018658",
        "Station ID": 126,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482800000,
        "Longitude": -1231800000,
        "Elevation (m)": 53.3,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "VICTORIA TILLICUM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018660",
        "Station ID": 127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.39,
        "Latitude": 482659000,
        "Longitude": -1232330000,
        "Elevation (m)": 22.9,
        "First Year": 1958,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2000,
        "MLY First Year": 1958,
        "MLY Last Year": 2000
      },
      {
        "Name": "VICTORIA TUDOR AVENUE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018666",
        "Station ID": 128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.27,
        "Latitude": 482700000,
        "Longitude": -1231600000,
        "Elevation (m)": 21.6,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "WILLIAM HEAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018935",
        "Station ID": 129,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.34,
        "Longitude (Decimal Degrees)": -123.54,
        "Latitude": 482023000,
        "Longitude": -1233219000,
        "Elevation (m)": 12.2,
        "First Year": 1959,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2018,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICTORIA U VIC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018FF6",
        "Station ID": 107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 482800000,
        "Longitude": -1232000000,
        "Elevation (m)": 68.4,
        "First Year": 1974,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1988,
        "MLY First Year": 1974,
        "MLY Last Year": 1988
      },
      {
        "Name": "VICTORIA HARTLAND CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1018FJ5",
        "Station ID": 11007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -123.46,
        "Latitude": 483201800,
        "Longitude": -1232732900,
        "Elevation (m)": 154.1,
        "First Year": 1994,
        "Last Year": 2012,
        "HLY First Year": 1994,
        "HLY Last Year": 2012,
        "DLY First Year": 1994,
        "DLY Last Year": 2012,
        "MLY First Year": 1994,
        "MLY Last Year": 2005
      },
      {
        "Name": "YOUBOU",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1019010",
        "Station ID": 130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -124.22,
        "Latitude": 485300000,
        "Longitude": -1241300000,
        "Elevation (m)": 173.7,
        "First Year": 1959,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1967,
        "MLY First Year": 1959,
        "MLY Last Year": 1967
      },
      {
        "Name": "YOUBOU SCHOOL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1019014",
        "Station ID": 131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -124.22,
        "Latitude": 485300000,
        "Longitude": -1241300000,
        "Elevation (m)": 189,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "CHEMAINUS BARE POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101AE00",
        "Station ID": 2,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.92,
        "Longitude (Decimal Degrees)": -123.7,
        "Latitude": 485500000,
        "Longitude": -1234200000,
        "Elevation (m)": 3,
        "First Year": 1979,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1979,
        "MLY First Year": 1979,
        "MLY Last Year": 1979
      },
      {
        "Name": "GALIANO SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101C04E",
        "Station ID": 4,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 485200000,
        "Longitude": -1231900000,
        "Elevation (m)": 15.2,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "GALIANO SOUTH 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101C0ME",
        "Station ID": 3,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -123.35,
        "Latitude": 485300000,
        "Longitude": -1232100000,
        "Elevation (m)": 61,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "SAANICHTON MT NEWTON 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101F940",
        "Station ID": 6807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -123.45,
        "Latitude": 483600000,
        "Longitude": -1232700000,
        "Elevation (m)": 68,
        "First Year": 1993,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1995,
        "MLY First Year": 1993,
        "MLY Last Year": 1995
      },
      {
        "Name": "SAANICH OLDFIELD NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101F942",
        "Station ID": 5,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 483300000,
        "Longitude": -1232500000,
        "Elevation (m)": 69.5,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "SATURNA ISLAND CAMPBELL SCIENTIFIC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101G100",
        "Station ID": 7997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 484700000,
        "Longitude": -1230300000,
        "Elevation (m)": 24.4,
        "First Year": 1980,
        "Last Year": 1992,
        "HLY First Year": 1980,
        "HLY Last Year": 1992,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VICTORIA WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101H6FF",
        "Station ID": 9,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.38,
        "Latitude": 482600000,
        "Longitude": -1232300000,
        "Elevation (m)": 15.2,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "VICTORIA WILLOWS PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101HF66",
        "Station ID": 8,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 482600000,
        "Longitude": -1231800000,
        "Elevation (m)": 9.1,
        "First Year": 1970,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1979,
        "MLY First Year": 1970,
        "MLY Last Year": 1979
      },
      {
        "Name": "VICTORIA PHYLLIS STREET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101HFEE",
        "Station ID": 6,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.46,
        "Longitude (Decimal Degrees)": -123.27,
        "Latitude": 482718000,
        "Longitude": -1231601000,
        "Elevation (m)": 7.6,
        "First Year": 1973,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2001,
        "MLY First Year": 1973,
        "MLY Last Year": 2001
      },
      {
        "Name": "VICTORIA PORTAGE INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101HFNE",
        "Station ID": 7,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 482700000,
        "Longitude": -1232600000,
        "Elevation (m)": 6.1,
        "First Year": 1976,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1983,
        "MLY First Year": 1976,
        "MLY Last Year": 1983
      },
      {
        "Name": "CORDOVA BAY CLAREMOUNT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101J9K0",
        "Station ID": 6808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 483100000,
        "Longitude": -1232200000,
        "Elevation (m)": 80,
        "First Year": 1993,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1995,
        "MLY First Year": 1993,
        "MLY Last Year": 1995
      },
      {
        "Name": "VICTORIA PRINCESS AVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101Q655",
        "Station ID": 13,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -123.35,
        "Latitude": 482600000,
        "Longitude": -1232100000,
        "Elevation (m)": 27,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "VICTORIA PROSPECT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101Q6NN",
        "Station ID": 12,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 483100000,
        "Longitude": -1232600000,
        "Elevation (m)": 61,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "VICTORIA SHELBOURNE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101QF57",
        "Station ID": 11,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 482800000,
        "Longitude": -1232000000,
        "Elevation (m)": 49,
        "First Year": 1964,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1991,
        "MLY First Year": 1964,
        "MLY Last Year": 1991
      },
      {
        "Name": "VICTORIA SHORT STREET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "101QFEG",
        "Station ID": 10,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 482700000,
        "Longitude": -1232200000,
        "Elevation (m)": 21.3,
        "First Year": 1961,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1973,
        "MLY First Year": 1961,
        "MLY Last Year": 1973
      },
      {
        "Name": "ALERT BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1020270",
        "Station ID": 137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.58,
        "Longitude (Decimal Degrees)": -126.93,
        "Latitude": 503500000,
        "Longitude": -1265600000,
        "Elevation (m)": 59.4,
        "First Year": 1913,
        "Last Year": 2006,
        "HLY First Year": 1954,
        "HLY Last Year": 1994,
        "DLY First Year": 1913,
        "DLY Last Year": 2006,
        "MLY First Year": 1913,
        "MLY Last Year": 1994
      },
      {
        "Name": "BALLENAS ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1020590",
        "Station ID": 138,
        "WMO ID": 71769,
        "TC ID": "WGB",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -124.16,
        "Latitude": 492101000,
        "Longitude": -1240937000,
        "Elevation (m)": 14.51,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLACK CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1020880",
        "Station ID": 139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -125.13,
        "Latitude": 495046000,
        "Longitude": -1250758000,
        "Elevation (m)": 46,
        "First Year": 1964,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2008,
        "MLY First Year": 1964,
        "MLY Last Year": 2005
      },
      {
        "Name": "BLACK CREEK SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1020881",
        "Station ID": 45507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -125.09,
        "Latitude": 494812000,
        "Longitude": -1250541080,
        "Elevation (m)": 87.8,
        "First Year": 2006,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2016,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLIND CHANNEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1020885",
        "Station ID": 140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -125.5,
        "Latitude": 502500000,
        "Longitude": -1253000000,
        "Elevation (m)": 3,
        "First Year": 1956,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1993,
        "MLY First Year": 1956,
        "MLY Last Year": 1993
      },
      {
        "Name": "BOWSER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1020910",
        "Station ID": 141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -124.67,
        "Latitude": 492700000,
        "Longitude": -1244000000,
        "Elevation (m)": 3,
        "First Year": 1957,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1958,
        "MLY First Year": 1957,
        "MLY Last Year": 1958
      },
      {
        "Name": "NANAIMO HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021052",
        "Station ID": 26822,
        "WMO ID": null,
        "TC ID": "WNA",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -123.93,
        "Latitude": 491000000,
        "Longitude": -1235600000,
        "Elevation (m)": 5,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAMERON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021230",
        "Station ID": 142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -124.58,
        "Latitude": 491700000,
        "Longitude": -1243500000,
        "Elevation (m)": 193,
        "First Year": 1924,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1986,
        "MLY First Year": 1924,
        "MLY Last Year": 1986
      },
      {
        "Name": "CAMPBELL LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021250",
        "Station ID": 143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -125.4,
        "Latitude": 500100000,
        "Longitude": -1252400000,
        "Elevation (m)": 15.2,
        "First Year": 1919,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1933,
        "MLY First Year": 1919,
        "MLY Last Year": 1933
      },
      {
        "Name": "CAMPBELL RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021260",
        "Station ID": 144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -125.3,
        "Latitude": 500100000,
        "Longitude": -1251800000,
        "Elevation (m)": 79.2,
        "First Year": 1936,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1969,
        "MLY First Year": 1936,
        "MLY Last Year": 1969
      },
      {
        "Name": "CAMPBELL RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021261",
        "Station ID": 145,
        "WMO ID": null,
        "TC ID": "YBL",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -125.27,
        "Latitude": 495707000,
        "Longitude": -1251623000,
        "Elevation (m)": 108.8,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": 1979,
        "HLY Last Year": 2013,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMPBELL RIVER BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021262",
        "Station ID": 146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -125.32,
        "Latitude": 500400000,
        "Longitude": -1251900000,
        "Elevation (m)": 128,
        "First Year": 1969,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1982,
        "MLY First Year": 1969,
        "MLY Last Year": 1982
      },
      {
        "Name": "CAMPBELL RIVER BCHPA GEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021263",
        "Station ID": 147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -125.32,
        "Latitude": 500300000,
        "Longitude": -1251900000,
        "Elevation (m)": 30.5,
        "First Year": 1972,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1982,
        "MLY First Year": 1972,
        "MLY Last Year": 1982
      },
      {
        "Name": "CAMPBELL R LAKEVIEW FC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021264",
        "Station ID": 148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -125.55,
        "Latitude": 501200000,
        "Longitude": -1253300000,
        "Elevation (m)": 198.1,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "CAMPBELL RIVER STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021265",
        "Station ID": 149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -125.23,
        "Latitude": 500100000,
        "Longitude": -1251400000,
        "Elevation (m)": 3,
        "First Year": 1982,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1996,
        "MLY First Year": 1982,
        "MLY Last Year": 1996
      },
      {
        "Name": "CAMPBELL RIVER SURFSIDE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021266",
        "Station ID": 150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -125.21,
        "Latitude": 495642000,
        "Longitude": -1251227000,
        "Elevation (m)": 9,
        "First Year": 1981,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2015,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMPBELL RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021267",
        "Station ID": 51317,
        "WMO ID": null,
        "TC ID": "YBL",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -125.27,
        "Latitude": 495707000,
        "Longitude": -1251623000,
        "Elevation (m)": 108.8,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAMPBELL RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021270",
        "Station ID": 52979,
        "WMO ID": null,
        "TC ID": "YBL",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -125.27,
        "Latitude": 495707000,
        "Longitude": -1251623000,
        "Elevation (m)": 108.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE LAZO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021320",
        "Station ID": 151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -124.87,
        "Latitude": 494200000,
        "Longitude": -1245200000,
        "Elevation (m)": 38.1,
        "First Year": 1935,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1962,
        "MLY First Year": 1935,
        "MLY Last Year": 1962
      },
      {
        "Name": "CAPE MUDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021330",
        "Station ID": 152,
        "WMO ID": null,
        "TC ID": "WAD",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -125.2,
        "Latitude": 495954500,
        "Longitude": -1251143700,
        "Elevation (m)": 4.6,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAPE MUDGE CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021332",
        "Station ID": 27382,
        "WMO ID": null,
        "TC ID": "WYA",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -125.2,
        "Latitude": 500000000,
        "Longitude": -1251200000,
        "Elevation (m)": 4,
        "First Year": 1998,
        "Last Year": 1998,
        "HLY First Year": 1998,
        "HLY Last Year": 1998,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHATHAM POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021480",
        "Station ID": 153,
        "WMO ID": null,
        "TC ID": "WFM",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -125.45,
        "Latitude": 501959500,
        "Longitude": -1252644000,
        "Elevation (m)": 22.9,
        "First Year": 1958,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1958,
        "DLY Last Year": 2022,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHROME ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021500",
        "Station ID": 51678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -124.68,
        "Latitude": 492820007,
        "Longitude": -1244059009,
        "Elevation (m)": 10,
        "First Year": 2013,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2013,
        "DLY Last Year": 2020,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHROME ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021616",
        "Station ID": 27289,
        "WMO ID": null,
        "TC ID": "WOG",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -124.68,
        "Latitude": 492800000,
        "Longitude": -1244100000,
        "Elevation (m)": 11.3,
        "First Year": 1997,
        "Last Year": 1998,
        "HLY First Year": 1997,
        "HLY Last Year": 1998,
        "DLY First Year": 1998,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "COAL HARBOUR RUPERT IN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021737",
        "Station ID": 154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -127.5,
        "Latitude": 503600000,
        "Longitude": -1273000000,
        "Elevation (m)": 57.3,
        "First Year": 1968,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1996,
        "MLY First Year": 1968,
        "MLY Last Year": 1996
      },
      {
        "Name": "COMOX A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021830",
        "Station ID": 155,
        "WMO ID": 71893,
        "TC ID": "YQQ",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -124.9,
        "Latitude": 494300000,
        "Longitude": -1245400000,
        "Elevation (m)": 25.6,
        "First Year": 1944,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1944,
        "DLY Last Year": 2022,
        "MLY First Year": 1944,
        "MLY Last Year": 2017
      },
      {
        "Name": "COMOX PCC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021838",
        "Station ID": 156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.69,
        "Longitude (Decimal Degrees)": -125.03,
        "Latitude": 494110000,
        "Longitude": -1250159000,
        "Elevation (m)": 11.9,
        "First Year": 1984,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2005,
        "MLY First Year": 1984,
        "MLY Last Year": 2005
      },
      {
        "Name": "COOMBS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021850",
        "Station ID": 157,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.31,
        "Longitude (Decimal Degrees)": -124.43,
        "Latitude": 491821000,
        "Longitude": -1242545000,
        "Elevation (m)": 98.1,
        "First Year": 1960,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2010,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "CORTES ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021950",
        "Station ID": 158,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -125.03,
        "Latitude": 500500000,
        "Longitude": -1250200000,
        "Elevation (m)": 6.1,
        "First Year": 1947,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1995,
        "MLY First Year": 1947,
        "MLY Last Year": 1995
      },
      {
        "Name": "CORTES ISL SUTIL POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021958",
        "Station ID": 159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -124.98,
        "Latitude": 500100000,
        "Longitude": -1245900000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "CORTES ISLAND TIBER BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021960",
        "Station ID": 160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -124.95,
        "Latitude": 500417000,
        "Longitude": -1245658000,
        "Elevation (m)": 15,
        "First Year": 1960,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2019,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "COURTENAY GRANTHAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021988",
        "Station ID": 161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.76,
        "Longitude (Decimal Degrees)": -125,
        "Latitude": 494547007,
        "Longitude": -1245957003,
        "Elevation (m)": 81,
        "First Year": 1960,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2019,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "COURTENAY PUNTLEDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021989",
        "Station ID": 162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.69,
        "Longitude (Decimal Degrees)": -125.03,
        "Latitude": 494111190,
        "Longitude": -1250157963,
        "Elevation (m)": 40,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "COURTENAY PUNTLEDGE BCHP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021990",
        "Station ID": 163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -125.03,
        "Latitude": 494100000,
        "Longitude": -1250200000,
        "Elevation (m)": 24.4,
        "First Year": 1921,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1964,
        "MLY First Year": 1921,
        "MLY Last Year": 1964
      },
      {
        "Name": "COURTENAY SANDWICK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1021994",
        "Station ID": 164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -124.97,
        "Latitude": 494200000,
        "Longitude": -1245800000,
        "Elevation (m)": 76,
        "First Year": 1989,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1993,
        "MLY First Year": 1989,
        "MLY Last Year": 1993
      },
      {
        "Name": "CUMBERLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022250",
        "Station ID": 165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -125.03,
        "Latitude": 493700000,
        "Longitude": -1250200000,
        "Elevation (m)": 159.4,
        "First Year": 1893,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1977,
        "MLY First Year": 1893,
        "MLY Last Year": 1977
      },
      {
        "Name": "DENMAN ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022430",
        "Station ID": 166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -124.78,
        "Latitude": 493100000,
        "Longitude": -1244700000,
        "Elevation (m)": 13.5,
        "First Year": 1906,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1993,
        "MLY First Year": 1906,
        "MLY Last Year": 1993
      },
      {
        "Name": "DENMAN ISLAND EAST SHORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022433",
        "Station ID": 167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -124.74,
        "Latitude": 493100000,
        "Longitude": -1244431200,
        "Elevation (m)": 13,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "DENMAN ISLAND 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022436",
        "Station ID": 168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -124.8,
        "Latitude": 493300000,
        "Longitude": -1244800000,
        "Elevation (m)": 92,
        "First Year": 1989,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1996,
        "MLY First Year": 1989,
        "MLY Last Year": 1996
      },
      {
        "Name": "DUNCAN BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022560",
        "Station ID": 169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -125.28,
        "Latitude": 500400000,
        "Longitude": -1251700000,
        "Elevation (m)": 11,
        "First Year": 1957,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1994,
        "MLY First Year": 1957,
        "MLY Last Year": 1994
      },
      {
        "Name": "DUNCAN GLENORA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022571",
        "Station ID": 26990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -123.77,
        "Latitude": 484521070,
        "Longitude": -1234555040,
        "Elevation (m)": 84,
        "First Year": 1994,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2010,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "ENTRANCE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022688",
        "Station ID": 170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.8,
        "Latitude": 491200000,
        "Longitude": -1234800000,
        "Elevation (m)": 3.3,
        "First Year": 1914,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1918,
        "MLY First Year": 1914,
        "MLY Last Year": 1918
      },
      {
        "Name": "ENTRANCE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022689",
        "Station ID": 29411,
        "WMO ID": 71772,
        "TC ID": "WEL",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -123.81,
        "Latitude": 491231195,
        "Longitude": -1234838001,
        "Elevation (m)": 7.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2006
      },
      {
        "Name": "FANNY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1022795",
        "Station ID": 29733,
        "WMO ID": 71568,
        "TC ID": "XFA",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -125.99,
        "Latitude": 502712663,
        "Longitude": -1255934425,
        "Elevation (m)": 5.2,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "FRENCH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1023037",
        "Station ID": 171,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -124.37,
        "Latitude": 492100000,
        "Longitude": -1242200000,
        "Elevation (m)": 38.1,
        "First Year": 1892,
        "Last Year": 1903,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1903,
        "MLY First Year": 1892,
        "MLY Last Year": 1903
      },
      {
        "Name": "GABRIOLA ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1023042",
        "Station ID": 172,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 490914000,
        "Longitude": -1234401000,
        "Elevation (m)": 46,
        "First Year": 1967,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2017,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "HERIOT BAY SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1023462",
        "Station ID": 173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -125.2,
        "Latitude": 500557964,
        "Longitude": -1251212190,
        "Elevation (m)": 17,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "HILLIERS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1023471",
        "Station ID": 174,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 491800000,
        "Longitude": -1243000000,
        "Elevation (m)": 150,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "HORNBY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1023590",
        "Station ID": 175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -124.6,
        "Latitude": 493200000,
        "Longitude": -1243600000,
        "Elevation (m)": 6.1,
        "First Year": 1907,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1970,
        "MLY First Year": 1907,
        "MLY Last Year": 1970
      },
      {
        "Name": "KELSEY BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024005",
        "Station ID": 176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -125.95,
        "Latitude": 502300000,
        "Longitude": -1255700000,
        "Elevation (m)": 4.9,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "LADYSMITH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024485",
        "Station ID": 177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -123.65,
        "Latitude": 485700000,
        "Longitude": -1233900000,
        "Elevation (m)": 24.4,
        "First Year": 1913,
        "Last Year": 1923,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1923,
        "MLY First Year": 1913,
        "MLY Last Year": 1923
      },
      {
        "Name": "LASQUETI ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024590",
        "Station ID": 178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -124.33,
        "Latitude": 493000000,
        "Longitude": -1242000000,
        "Elevation (m)": 4.6,
        "First Year": 1950,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1954,
        "MLY First Year": 1950,
        "MLY Last Year": 1954
      },
      {
        "Name": "LAZO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024592",
        "Station ID": 179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -124.9,
        "Latitude": 494400000,
        "Longitude": -1245400000,
        "Elevation (m)": 3.7,
        "First Year": 1914,
        "Last Year": 1927,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1927,
        "MLY First Year": 1914,
        "MLY Last Year": 1927
      },
      {
        "Name": "LITTLE QUALICUM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024637",
        "Station ID": 180,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -124.78,
        "Latitude": 492100000,
        "Longitude": -1244700000,
        "Elevation (m)": 186.5,
        "First Year": 1908,
        "Last Year": 1921,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1921,
        "MLY First Year": 1908,
        "MLY Last Year": 1921
      },
      {
        "Name": "LITTLE QUALICUM HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024638",
        "Station ID": 181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -124.51,
        "Latitude": 492110000,
        "Longitude": -1243044000,
        "Elevation (m)": 30,
        "First Year": 1981,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "LITTLE QUALICUM R ESTY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024639",
        "Station ID": 182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -124.48,
        "Latitude": 492200000,
        "Longitude": -1242900000,
        "Elevation (m)": 1.5,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "LITTLE QUALICUM RIVER GANSKE ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1024642",
        "Station ID": 183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 492200000,
        "Longitude": -1243000000,
        "Elevation (m)": 42,
        "First Year": 1987,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1992,
        "MLY First Year": 1987,
        "MLY Last Year": 1992
      },
      {
        "Name": "MT WASHINGTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025230",
        "Station ID": 46567,
        "WMO ID": null,
        "TC ID": "VOJ",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -125.29,
        "Latitude": 494448100,
        "Longitude": -1251713900,
        "Elevation (m)": 1473.5,
        "First Year": 2007,
        "Last Year": 2010,
        "HLY First Year": 2007,
        "HLY Last Year": 2010,
        "DLY First Year": 2007,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MT WASHINGTON RESORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025234",
        "Station ID": 185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -125.29,
        "Latitude": 494449000,
        "Longitude": -1251727000,
        "Elevation (m)": 1197,
        "First Year": 1979,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1999,
        "MLY First Year": 1980,
        "MLY Last Year": 1999
      },
      {
        "Name": "MT WASHINGTON UPPER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025237",
        "Station ID": 186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -125.29,
        "Latitude": 494500000,
        "Longitude": -1251728000,
        "Elevation (m)": 1450,
        "First Year": 1990,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1999,
        "MLY First Year": 1990,
        "MLY Last Year": 1999
      },
      {
        "Name": "MUD BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025240",
        "Station ID": 187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -124.79,
        "Latitude": 492815000,
        "Longitude": -1244739000,
        "Elevation (m)": 4,
        "First Year": 1971,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2015,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "MYRA CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025254",
        "Station ID": 188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -125.61,
        "Latitude": 493432020,
        "Longitude": -1253621010,
        "Elevation (m)": 353.9,
        "First Year": 1979,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2015,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "NANAIMO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025340",
        "Station ID": 191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -123.97,
        "Latitude": 491100000,
        "Longitude": -1235800000,
        "Elevation (m)": 70.1,
        "First Year": 1892,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1968,
        "MLY First Year": 1892,
        "MLY Last Year": 1968
      },
      {
        "Name": "NANAIMO A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025365",
        "Station ID": 52941,
        "WMO ID": null,
        "TC ID": "YCD",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -123.87,
        "Latitude": 490316000,
        "Longitude": -1235212000,
        "Elevation (m)": 28,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NANAIMO A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025369",
        "Station ID": 51318,
        "WMO ID": null,
        "TC ID": "YCD",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -123.87,
        "Latitude": 490316000,
        "Longitude": -1235212000,
        "Elevation (m)": 28,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NANAIMO A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025370",
        "Station ID": 192,
        "WMO ID": null,
        "TC ID": "YCD",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -123.87,
        "Latitude": 490316000,
        "Longitude": -1235212000,
        "Elevation (m)": 28.04,
        "First Year": 1947,
        "Last Year": 2016,
        "HLY First Year": 1954,
        "HLY Last Year": 2013,
        "DLY First Year": 1947,
        "DLY Last Year": 2016,
        "MLY First Year": 1947,
        "MLY Last Year": 2007
      },
      {
        "Name": "NANAIMO WATER RESERVOIR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025371",
        "Station ID": 193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -123.97,
        "Latitude": 490900000,
        "Longitude": -1235800000,
        "Elevation (m)": 114.3,
        "First Year": 1962,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1967,
        "MLY First Year": 1962,
        "MLY Last Year": 1967
      },
      {
        "Name": "NANOOSE BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025375",
        "Station ID": 194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -124.18,
        "Latitude": 491700000,
        "Longitude": -1241100000,
        "Elevation (m)": 82,
        "First Year": 1912,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1989,
        "MLY First Year": 1912,
        "MLY Last Year": 1989
      },
      {
        "Name": "NANOOSE BAY AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025376",
        "Station ID": 52058,
        "WMO ID": null,
        "TC ID": "VNB",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -124.15,
        "Latitude": 491558030,
        "Longitude": -1240905070,
        "Elevation (m)": 5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NANOOSE BAY SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025377",
        "Station ID": 195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -124.18,
        "Latitude": 491506080,
        "Longitude": -1241038040,
        "Elevation (m)": 111,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2007,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "NANAIMO CITY YARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10253G0",
        "Station ID": 189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.99,
        "Latitude": 491156932,
        "Longitude": -1235916262,
        "Elevation (m)": 114,
        "First Year": 1981,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "NANAIMO CHUB",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10253P0",
        "Station ID": 190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -123.93,
        "Latitude": 491000000,
        "Longitude": -1235600000,
        "Elevation (m)": 21.3,
        "First Year": 1969,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1977,
        "MLY First Year": 1969,
        "MLY Last Year": 1977
      },
      {
        "Name": "OYSTER RIVER UBC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025915",
        "Station ID": 196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -125.13,
        "Latitude": 495300000,
        "Longitude": -1250800000,
        "Elevation (m)": 10.7,
        "First Year": 1967,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2006,
        "MLY First Year": 1967,
        "MLY Last Year": 2006
      },
      {
        "Name": "PARKSVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025970",
        "Station ID": 197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -124.3,
        "Latitude": 491800000,
        "Longitude": -1241800000,
        "Elevation (m)": 82.3,
        "First Year": 1915,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1960,
        "MLY First Year": 1915,
        "MLY Last Year": 1960
      },
      {
        "Name": "PARKSVILLE NORTHWEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025975",
        "Station ID": 198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -124.33,
        "Latitude": 492000000,
        "Longitude": -1242000000,
        "Elevation (m)": 30.5,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "PARKSVILLE SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025977",
        "Station ID": 199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -124.3,
        "Latitude": 492000000,
        "Longitude": -1241800000,
        "Elevation (m)": 0.9,
        "First Year": 1967,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1993,
        "MLY First Year": 1967,
        "MLY Last Year": 1993
      },
      {
        "Name": "NANAIMO DEPARTURE BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1025C70",
        "Station ID": 184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123.95,
        "Latitude": 491300000,
        "Longitude": -1235700000,
        "Elevation (m)": 7.6,
        "First Year": 1913,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1992,
        "MLY First Year": 1913,
        "MLY Last Year": 1992
      },
      {
        "Name": "PINE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026170",
        "Station ID": 200,
        "WMO ID": null,
        "TC ID": "WPI",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -127.73,
        "Latitude": 505833010,
        "Longitude": -1274341040,
        "Elevation (m)": 28,
        "First Year": 1984,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1984,
        "DLY Last Year": 2018,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORT HARDY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026266",
        "Station ID": 201,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -127.48,
        "Latitude": 504000000,
        "Longitude": -1272900000,
        "Elevation (m)": 3,
        "First Year": 1928,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1944,
        "MLY First Year": 1928,
        "MLY Last Year": 1944
      },
      {
        "Name": "PORT HARDY A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026270",
        "Station ID": 202,
        "WMO ID": 71109,
        "TC ID": "YZT",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -127.37,
        "Latitude": 504049000,
        "Longitude": -1272158000,
        "Elevation (m)": 21.6,
        "First Year": 1944,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1944,
        "DLY Last Year": 2013,
        "MLY First Year": 1944,
        "MLY Last Year": 2013
      },
      {
        "Name": "PORT HARDY A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026271",
        "Station ID": 51319,
        "WMO ID": 71109,
        "TC ID": "YZT",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -127.37,
        "Latitude": 504050000,
        "Longitude": -1272200000,
        "Elevation (m)": 21.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PORT HARDY BEAVER HR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026274",
        "Station ID": 203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -127.43,
        "Latitude": 504300000,
        "Longitude": -1272600000,
        "Elevation (m)": 4.6,
        "First Year": 1959,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1975,
        "MLY First Year": 1959,
        "MLY Last Year": 1975
      },
      {
        "Name": "PORT MCNEILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026323",
        "Station ID": 204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.58,
        "Longitude (Decimal Degrees)": -127.08,
        "Latitude": 503500000,
        "Longitude": -1270500000,
        "Elevation (m)": 91.4,
        "First Year": 1974,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1983,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "QUADRA ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026560",
        "Station ID": 205,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -125.18,
        "Latitude": 501100000,
        "Longitude": -1251100000,
        "Elevation (m)": 31.1,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "QUALICUM BEACH AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026562",
        "Station ID": 45627,
        "WMO ID": 71766,
        "TC ID": "VOQ",
        "Latitude (Decimal Degrees)": 49.34,
        "Longitude (Decimal Degrees)": -124.39,
        "Latitude": 492014000,
        "Longitude": -1242338000,
        "Elevation (m)": 58.2,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "QUALICUM BEACH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026563",
        "Station ID": 206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -124.43,
        "Latitude": 492000000,
        "Longitude": -1242600000,
        "Elevation (m)": 9.1,
        "First Year": 1913,
        "Last Year": 1937,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1937,
        "MLY First Year": 1913,
        "MLY Last Year": 1937
      },
      {
        "Name": "QUALICUM BEACH YAMBURY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026564",
        "Station ID": 207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -124.38,
        "Latitude": 492100000,
        "Longitude": -1242300000,
        "Elevation (m)": 3,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "QUALICUM R FISH RESEARCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026565",
        "Station ID": 208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.39,
        "Longitude (Decimal Degrees)": -124.62,
        "Latitude": 492337620,
        "Longitude": -1243702054,
        "Elevation (m)": 7.6,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "QUINSAM RIVER HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1026639",
        "Station ID": 209,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -125.3,
        "Latitude": 500058000,
        "Longitude": -1251814000,
        "Elevation (m)": 45.7,
        "First Year": 1975,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "SANDWICK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027060",
        "Station ID": 210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -125.05,
        "Latitude": 494500000,
        "Longitude": -1250300000,
        "Elevation (m)": 45.7,
        "First Year": 1914,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1943,
        "MLY First Year": 1914,
        "MLY Last Year": 1943
      },
      {
        "Name": "SAVARY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027110",
        "Station ID": 211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -124.85,
        "Latitude": 495600000,
        "Longitude": -1245100000,
        "Elevation (m)": 38,
        "First Year": 1919,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1989,
        "MLY First Year": 1919,
        "MLY Last Year": 1989
      },
      {
        "Name": "SAYWARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027112",
        "Station ID": 212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -126,
        "Latitude": 502200000,
        "Longitude": -1260000000,
        "Elevation (m)": null,
        "First Year": 1920,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1930,
        "MLY First Year": 1920,
        "MLY Last Year": 1930
      },
      {
        "Name": "SAYWARD 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027114",
        "Station ID": 213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.32,
        "Longitude (Decimal Degrees)": -125.93,
        "Latitude": 501929000,
        "Longitude": -1255549000,
        "Elevation (m)": 16,
        "First Year": 1973,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2006,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "SAYWARD BIG TREE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027115",
        "Station ID": 214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 501400000,
        "Longitude": -1254600000,
        "Elevation (m)": 57,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1987,
        "MLY Last Year": 1990
      },
      {
        "Name": "SISTERS ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027403",
        "Station ID": 6813,
        "WMO ID": 71781,
        "TC ID": "WGT",
        "Latitude (Decimal Degrees)": 49.49,
        "Longitude (Decimal Degrees)": -124.43,
        "Latitude": 492911800,
        "Longitude": -1242605800,
        "Elevation (m)": 20,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2004
      },
      {
        "Name": "STRATHCONA DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027775",
        "Station ID": 216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -125.58,
        "Latitude": 500000000,
        "Longitude": -1253500000,
        "Elevation (m)": 201.2,
        "First Year": 1967,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1977,
        "MLY First Year": 1967,
        "MLY Last Year": 1977
      },
      {
        "Name": "STRATHCONA PARK LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10277GN",
        "Station ID": 215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -125.65,
        "Latitude": 495300000,
        "Longitude": -1253900000,
        "Elevation (m)": 228.6,
        "First Year": 1975,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1980,
        "MLY First Year": 1975,
        "MLY Last Year": 1980
      },
      {
        "Name": "SURGE NARROWS READ IS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027870",
        "Station ID": 217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -125.1,
        "Latitude": 501300000,
        "Longitude": -1250600000,
        "Elevation (m)": 76.2,
        "First Year": 1962,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1983,
        "MLY First Year": 1962,
        "MLY Last Year": 1983
      },
      {
        "Name": "SURGE NARROWS RENDEZVOUS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1027871",
        "Station ID": 218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -125.07,
        "Latitude": 501700000,
        "Longitude": -1250400000,
        "Elevation (m)": 9.1,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "VANANDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1028340",
        "Station ID": 220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -124.58,
        "Latitude": 494500000,
        "Longitude": -1243500000,
        "Elevation (m)": 12.2,
        "First Year": 1916,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1952,
        "MLY First Year": 1916,
        "MLY Last Year": 1952
      },
      {
        "Name": "UPPER CAMPBELL LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1028L3M",
        "Station ID": 219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -125.63,
        "Latitude": 495400000,
        "Longitude": -1253800000,
        "Elevation (m)": 548.6,
        "First Year": 1980,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1998,
        "MLY First Year": 1980,
        "MLY Last Year": 1998
      },
      {
        "Name": "ENTRANCE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "102BFHH",
        "Station ID": 132,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -123.81,
        "Latitude": 491232080,
        "Longitude": -1234832010,
        "Elevation (m)": 7,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "NANAIMO NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "102E3G0",
        "Station ID": 134,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123.98,
        "Latitude": 491300000,
        "Longitude": -1235900000,
        "Elevation (m)": 98,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "NANAIMO RIVER CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "102ECG0",
        "Station ID": 133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 490500000,
        "Longitude": -1240500000,
        "Elevation (m)": 204.2,
        "First Year": 1962,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1968,
        "MLY First Year": 1962,
        "MLY Last Year": 1968
      },
      {
        "Name": "COURTENAY MEADOWBROOK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "102JR88",
        "Station ID": 135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -124.98,
        "Latitude": 494301050,
        "Longitude": -1245837001,
        "Elevation (m)": 35,
        "First Year": 1987,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2010,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "STUART CHANNEL BOAT HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "102PG75",
        "Station ID": 136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.09,
        "Longitude (Decimal Degrees)": -123.8,
        "Latitude": 490527000,
        "Longitude": -1234758000,
        "Elevation (m)": 14,
        "First Year": 1987,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2006,
        "MLY First Year": 1987,
        "MLY Last Year": 2006
      },
      {
        "Name": "ALBERNI BEAVER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030180",
        "Station ID": 221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -124.93,
        "Latitude": 492200000,
        "Longitude": -1245600000,
        "Elevation (m)": 91.4,
        "First Year": 1894,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 1959,
        "MLY First Year": 1894,
        "MLY Last Year": 1959
      },
      {
        "Name": "ALBERNI CITY RESERVOIR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030185",
        "Station ID": 222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -124.78,
        "Latitude": 491600000,
        "Longitude": -1244700000,
        "Elevation (m)": 64.6,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "ALBERNI LUPSI CUPSI",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030210",
        "Station ID": 223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -124.82,
        "Latitude": 491500000,
        "Longitude": -1244900000,
        "Elevation (m)": 9.1,
        "First Year": 1948,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1974,
        "MLY First Year": 1948,
        "MLY Last Year": 1974
      },
      {
        "Name": "ALBERNI MCCOY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030220",
        "Station ID": 224,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -124.88,
        "Latitude": 491700000,
        "Longitude": -1245300000,
        "Elevation (m)": 42.7,
        "First Year": 1958,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1973,
        "MLY First Year": 1958,
        "MLY Last Year": 1973
      },
      {
        "Name": "ALBERNI ROBERTSON CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030230",
        "Station ID": 225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.34,
        "Longitude (Decimal Degrees)": -124.98,
        "Latitude": 492014010,
        "Longitude": -1245855090,
        "Elevation (m)": 73.75,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "AMPHITRITE POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030425",
        "Station ID": 226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.92,
        "Longitude (Decimal Degrees)": -125.53,
        "Latitude": 485500000,
        "Longitude": -1253200000,
        "Elevation (m)": 10.7,
        "First Year": 1958,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1980,
        "MLY First Year": 1958,
        "MLY Last Year": 1980
      },
      {
        "Name": "AMPHITRITE POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030426",
        "Station ID": 227,
        "WMO ID": null,
        "TC ID": "WAN",
        "Latitude (Decimal Degrees)": 48.92,
        "Longitude (Decimal Degrees)": -125.54,
        "Latitude": 485516007,
        "Longitude": -1253228003,
        "Elevation (m)": 26.5,
        "First Year": 1980,
        "Last Year": 2010,
        "HLY First Year": 1980,
        "HLY Last Year": 2001,
        "DLY First Year": 1980,
        "DLY Last Year": 2010,
        "MLY First Year": 1980,
        "MLY Last Year": 1999
      },
      {
        "Name": "ASH RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030550",
        "Station ID": 228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -125.08,
        "Latitude": 492800000,
        "Longitude": -1250500000,
        "Elevation (m)": 304.8,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "ASH RIVER GENERATING STN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030552",
        "Station ID": 229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -125.15,
        "Latitude": 492200000,
        "Longitude": -1250900000,
        "Elevation (m)": 91.4,
        "First Year": 1959,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1969,
        "MLY First Year": 1959,
        "MLY Last Year": 1969
      },
      {
        "Name": "BAMFIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030600",
        "Station ID": 230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -125.1,
        "Latitude": 485000000,
        "Longitude": -1250600000,
        "Elevation (m)": 21.3,
        "First Year": 1903,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1956,
        "MLY First Year": 1903,
        "MLY Last Year": 1956
      },
      {
        "Name": "BAMFIELD EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030605",
        "Station ID": 231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -125.12,
        "Latitude": 485000000,
        "Longitude": -1250700000,
        "Elevation (m)": 4,
        "First Year": 1959,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2001,
        "MLY First Year": 1959,
        "MLY Last Year": 2001
      },
      {
        "Name": "BAMFIELD WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030610",
        "Station ID": 232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -125.15,
        "Latitude": 485000000,
        "Longitude": -1250900000,
        "Elevation (m)": 3,
        "First Year": 1955,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1959,
        "MLY First Year": 1955,
        "MLY Last Year": 1959
      },
      {
        "Name": "BENSON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1030850",
        "Station ID": 233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -127.23,
        "Latitude": 502200000,
        "Longitude": -1271400000,
        "Elevation (m)": 144.8,
        "First Year": 1959,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1972,
        "MLY First Year": 1959,
        "MLY Last Year": 1972
      },
      {
        "Name": "BULL HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031110",
        "Station ID": 234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -127.95,
        "Latitude": 505500000,
        "Longitude": -1275700000,
        "Elevation (m)": 13.7,
        "First Year": 1921,
        "Last Year": 1988,
        "HLY First Year": 1953,
        "HLY Last Year": 1988,
        "DLY First Year": 1921,
        "DLY Last Year": 1988,
        "MLY First Year": 1921,
        "MLY Last Year": 1988
      },
      {
        "Name": "CAPE BEALE LIGHT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031316",
        "Station ID": 235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.79,
        "Longitude (Decimal Degrees)": -125.22,
        "Latitude": 484710000,
        "Longitude": -1251258000,
        "Elevation (m)": 25.9,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAPE SCOTT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031352",
        "Station ID": 236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -128.45,
        "Latitude": 504800000,
        "Longitude": -1282700000,
        "Elevation (m)": 6.1,
        "First Year": 1897,
        "Last Year": 1909,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1909,
        "MLY First Year": 1897,
        "MLY Last Year": 1908
      },
      {
        "Name": "CAPE SCOTT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031353",
        "Station ID": 237,
        "WMO ID": null,
        "TC ID": "WES",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -128.43,
        "Latitude": 504656040,
        "Longitude": -1282538020,
        "Elevation (m)": 71.6,
        "First Year": 1965,
        "Last Year": 2016,
        "HLY First Year": 1966,
        "HLY Last Year": 2001,
        "DLY First Year": 1965,
        "DLY Last Year": 2016,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARMANAH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031400",
        "Station ID": 238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -124.78,
        "Latitude": 483800000,
        "Longitude": -1244700000,
        "Elevation (m)": 39.6,
        "First Year": 1891,
        "Last Year": 1902,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1902,
        "MLY First Year": 1891,
        "MLY Last Year": 1902
      },
      {
        "Name": "CARMANAH POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031402",
        "Station ID": 239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.61,
        "Longitude (Decimal Degrees)": -124.75,
        "Latitude": 483642000,
        "Longitude": -1244505000,
        "Elevation (m)": 38.1,
        "First Year": 1968,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2020,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARNATION CREEK CDF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031413",
        "Station ID": 240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -125,
        "Latitude": 485400000,
        "Longitude": -1250000000,
        "Elevation (m)": 61,
        "First Year": 1971,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1990,
        "MLY First Year": 1971,
        "MLY Last Year": 1990
      },
      {
        "Name": "CLAYOQUOT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031650",
        "Station ID": 241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -125.9,
        "Latitude": 490900000,
        "Longitude": -1255400000,
        "Elevation (m)": 22.9,
        "First Year": 1896,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1959,
        "MLY First Year": 1896,
        "MLY Last Year": 1958
      },
      {
        "Name": "CONUMA RIVER HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1031844",
        "Station ID": 242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -126.42,
        "Latitude": 494801020,
        "Longitude": -1262505070,
        "Elevation (m)": 12,
        "First Year": 1989,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2018,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELK LAKE JEUNE LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1032660",
        "Station ID": 243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -127.3,
        "Latitude": 502200000,
        "Longitude": -1271800000,
        "Elevation (m)": 109.7,
        "First Year": 1924,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1938,
        "MLY First Year": 1924,
        "MLY Last Year": 1938
      },
      {
        "Name": "ESTEVAN POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1032730",
        "Station ID": 244,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -126.54,
        "Latitude": 492259914,
        "Longitude": -1263235193,
        "Elevation (m)": 5.8,
        "First Year": 1908,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 1979,
        "DLY First Year": 1908,
        "DLY Last Year": 2022,
        "MLY First Year": 1908,
        "MLY Last Year": 2007
      },
      {
        "Name": "ESTEVAN POINT CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1032731",
        "Station ID": 8040,
        "WMO ID": 71894,
        "TC ID": "WEB",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -126.54,
        "Latitude": 492259914,
        "Longitude": -1263235193,
        "Elevation (m)": 5.8,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOLD RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033230",
        "Station ID": 245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -126.17,
        "Latitude": 494000000,
        "Longitude": -1261000000,
        "Elevation (m)": 3,
        "First Year": 1958,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1965,
        "MLY First Year": 1958,
        "MLY Last Year": 1965
      },
      {
        "Name": "GOLD RIVER TOWNSITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033232",
        "Station ID": 246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -126.06,
        "Latitude": 494644002,
        "Longitude": -1260318000,
        "Elevation (m)": 119,
        "First Year": 1966,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2016,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "GREAT CENTRAL LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033292",
        "Station ID": 8041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -125.15,
        "Latitude": 492100000,
        "Longitude": -1250900000,
        "Elevation (m)": 502.9,
        "First Year": 1957,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1957,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HENDERSON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033455",
        "Station ID": 247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -125.13,
        "Latitude": 490800000,
        "Longitude": -1250800000,
        "Elevation (m)": 3.7,
        "First Year": 1922,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1936,
        "MLY First Year": 1922,
        "MLY Last Year": 1936
      },
      {
        "Name": "HENDERSON LAKE HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033456",
        "Station ID": 27330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -125.14,
        "Latitude": 490800000,
        "Longitude": -1250831000,
        "Elevation (m)": 2,
        "First Year": 1996,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2007,
        "MLY First Year": 1996,
        "MLY Last Year": 2003
      },
      {
        "Name": "HERBERT INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033460",
        "Station ID": 248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -125.9,
        "Latitude": 492400000,
        "Longitude": -1255400000,
        "Elevation (m)": 4.6,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "HOLBERG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033479",
        "Station ID": 249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -128,
        "Latitude": 503900000,
        "Longitude": -1280000000,
        "Elevation (m)": 2.4,
        "First Year": 1909,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 1939,
        "MLY First Year": 1909,
        "MLY Last Year": 1939
      },
      {
        "Name": "HOLBERG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033480",
        "Station ID": 250,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -128,
        "Latitude": 503900000,
        "Longitude": -1280000000,
        "Elevation (m)": 579,
        "First Year": 1956,
        "Last Year": 1993,
        "HLY First Year": 1956,
        "HLY Last Year": 1969,
        "DLY First Year": 1958,
        "DLY Last Year": 1993,
        "MLY First Year": 1958,
        "MLY Last Year": 1992
      },
      {
        "Name": "HOLBERG CCR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033481",
        "Station ID": 10998,
        "WMO ID": 71562,
        "TC ID": "WXH",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -128.12,
        "Latitude": 503800000,
        "Longitude": -1280700000,
        "Elevation (m)": 568,
        "First Year": 1994,
        "Last Year": 2010,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 2010,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOLBERG FIRE DEPT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033483",
        "Station ID": 251,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -127.98,
        "Latitude": 503900000,
        "Longitude": -1275900000,
        "Elevation (m)": 45.7,
        "First Year": 1967,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1991,
        "MLY First Year": 1967,
        "MLY Last Year": 1991
      },
      {
        "Name": "HOLBERG PUMP HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1033485",
        "Station ID": 252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -128,
        "Latitude": 503900000,
        "Longitude": -1280000000,
        "Elevation (m)": 41.8,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1967
      },
      {
        "Name": "KENNEDY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034070",
        "Station ID": 253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -125.63,
        "Latitude": 490800000,
        "Longitude": -1253800000,
        "Elevation (m)": 10.7,
        "First Year": 1924,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1936,
        "MLY First Year": 1924,
        "MLY Last Year": 1936
      },
      {
        "Name": "KILDONAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034170",
        "Station ID": 254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -125,
        "Latitude": 490000000,
        "Longitude": -1250000000,
        "Elevation (m)": 3,
        "First Year": 1937,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1976,
        "MLY First Year": 1937,
        "MLY Last Year": 1976
      },
      {
        "Name": "KILDONAN FATTY BASIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034175",
        "Station ID": 255,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -125.02,
        "Latitude": 485900000,
        "Longitude": -1250100000,
        "Elevation (m)": 4.6,
        "First Year": 1967,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1971,
        "MLY First Year": 1967,
        "MLY Last Year": 1971
      },
      {
        "Name": "KYUQUOT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034440",
        "Station ID": 256,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -127.37,
        "Latitude": 500200000,
        "Longitude": -1272200000,
        "Elevation (m)": 3,
        "First Year": 1933,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1959,
        "MLY First Year": 1933,
        "MLY Last Year": 1959
      },
      {
        "Name": "LENNARD ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034600",
        "Station ID": 257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -125.92,
        "Latitude": 490638000,
        "Longitude": -1255524000,
        "Elevation (m)": 10.6,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "LOWRY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034725",
        "Station ID": 258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -125.13,
        "Latitude": 492600000,
        "Longitude": -1250800000,
        "Elevation (m)": 304.8,
        "First Year": 1957,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "LAWN POINT (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1034NR0",
        "Station ID": 8043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -127.97,
        "Latitude": 502000000,
        "Longitude": -1275800000,
        "Elevation (m)": 3.6,
        "First Year": 1980,
        "Last Year": 1986,
        "HLY First Year": 1980,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NITINAT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1035610",
        "Station ID": 259,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -124.75,
        "Latitude": 484500000,
        "Longitude": -1244500000,
        "Elevation (m)": 7.6,
        "First Year": 1924,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1952,
        "MLY First Year": 1924,
        "MLY Last Year": 1952
      },
      {
        "Name": "NITINAT RIVER HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1035612",
        "Station ID": 260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.86,
        "Longitude (Decimal Degrees)": -124.66,
        "Latitude": 485134000,
        "Longitude": -1243919000,
        "Elevation (m)": 14.9,
        "First Year": 1981,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "NOOTKA LIGHTSTATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1035614",
        "Station ID": 261,
        "WMO ID": null,
        "TC ID": "WCV",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -126.62,
        "Latitude": 493600000,
        "Longitude": -1263700000,
        "Elevation (m)": 15.8,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "PACHENA POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1035940",
        "Station ID": 262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.72,
        "Longitude (Decimal Degrees)": -125.1,
        "Latitude": 484322000,
        "Longitude": -1250550000,
        "Elevation (m)": 37,
        "First Year": 1924,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 1958,
        "DLY First Year": 1924,
        "DLY Last Year": 2022,
        "MLY First Year": 1924,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORT ALBERNI",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036205",
        "Station ID": 263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -124.8,
        "Latitude": 491400000,
        "Longitude": -1244800000,
        "Elevation (m)": 59.4,
        "First Year": 1917,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1960,
        "MLY First Year": 1917,
        "MLY Last Year": 1960
      },
      {
        "Name": "PORT ALBERNI A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036206",
        "Station ID": 264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -124.83,
        "Latitude": 491500000,
        "Longitude": -1245000000,
        "Elevation (m)": 2.4,
        "First Year": 1969,
        "Last Year": 1996,
        "HLY First Year": 1969,
        "HLY Last Year": 1995,
        "DLY First Year": 1969,
        "DLY Last Year": 1996,
        "MLY First Year": 1969,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORT ALBERNI CHINA CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036207",
        "Station ID": 265,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -124.78,
        "Latitude": 491400000,
        "Longitude": -1244700000,
        "Elevation (m)": 70.1,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "PORT ALBERNI COX LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036208",
        "Station ID": 266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -124.75,
        "Latitude": 491206002,
        "Longitude": -1244506005,
        "Elevation (m)": 100,
        "First Year": 1987,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2020,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORT ALBERNI REDFORD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036210",
        "Station ID": 267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -124.8,
        "Latitude": 491500000,
        "Longitude": -1244800000,
        "Elevation (m)": 21.3,
        "First Year": 1959,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1971,
        "MLY First Year": 1959,
        "MLY Last Year": 1971
      },
      {
        "Name": "PORT ALBERNI SHUHUM CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036218",
        "Station ID": 268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -124.93,
        "Latitude": 491800000,
        "Longitude": -1245600000,
        "Elevation (m)": 70,
        "First Year": 1987,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2000,
        "MLY First Year": 1987,
        "MLY Last Year": 2000
      },
      {
        "Name": "PORT ALICE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036240",
        "Station ID": 269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.39,
        "Longitude (Decimal Degrees)": -127.46,
        "Latitude": 502309010,
        "Longitude": -1272719030,
        "Elevation (m)": 21,
        "First Year": 1924,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2015,
        "MLY First Year": 1924,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORT ALICE TOWNSITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036243",
        "Station ID": 270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -127.48,
        "Latitude": 502600000,
        "Longitude": -1272900000,
        "Elevation (m)": 102,
        "First Year": 1987,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1991,
        "MLY First Year": 1987,
        "MLY Last Year": 1991
      },
      {
        "Name": "QUATSINO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036570",
        "Station ID": 271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -127.65,
        "Latitude": 503201010,
        "Longitude": -1273912009,
        "Elevation (m)": 3.42,
        "First Year": 1895,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 2022,
        "MLY First Year": 1895,
        "MLY Last Year": 2007
      },
      {
        "Name": "QUATSINO LIGHTSTATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036572",
        "Station ID": 272,
        "WMO ID": null,
        "TC ID": "WIF",
        "Latitude (Decimal Degrees)": 50.44,
        "Longitude (Decimal Degrees)": -128.03,
        "Latitude": 502628000,
        "Longitude": -1280157000,
        "Elevation (m)": 21,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "RUMBLE BEACH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036907",
        "Station ID": 273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -127.48,
        "Latitude": 502600000,
        "Longitude": -1272900000,
        "Elevation (m)": 106.7,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "PORT ALBERNI (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1036B06",
        "Station ID": 8045,
        "WMO ID": 71475,
        "TC ID": "WQC",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -124.93,
        "Latitude": 491859700,
        "Longitude": -1245536600,
        "Elevation (m)": 76.2,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "SARTINE ISLAND (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1037090",
        "Station ID": 8261,
        "WMO ID": 71478,
        "TC ID": "WFG",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -128.91,
        "Latitude": 504916000,
        "Longitude": -1285429000,
        "Elevation (m)": 103.45,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOLANDER ISLAND (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1037553",
        "Station ID": 8260,
        "WMO ID": 71479,
        "TC ID": "WRU",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -127.94,
        "Latitude": 500641436,
        "Longitude": -1275623999,
        "Elevation (m)": 94.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPRING ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1037650",
        "Station ID": 274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -127.42,
        "Latitude": 500000000,
        "Longitude": -1272500000,
        "Elevation (m)": 11.3,
        "First Year": 1949,
        "Last Year": 1979,
        "HLY First Year": 1953,
        "HLY Last Year": 1979,
        "DLY First Year": 1949,
        "DLY Last Year": 1979,
        "MLY First Year": 1949,
        "MLY Last Year": 1979
      },
      {
        "Name": "TAHSIS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1037890",
        "Station ID": 275,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -126.65,
        "Latitude": 495500000,
        "Longitude": -1263900000,
        "Elevation (m)": 4.6,
        "First Year": 1952,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1988,
        "MLY First Year": 1952,
        "MLY Last Year": 1988
      },
      {
        "Name": "TAHSIS VILLAGE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1037899",
        "Station ID": 276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -126.65,
        "Latitude": 495555050,
        "Longitude": -1263914000,
        "Elevation (m)": 9,
        "First Year": 1989,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2022,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "TOFINO A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038204",
        "Station ID": 52198,
        "WMO ID": null,
        "TC ID": "YAZ",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 490456000,
        "Longitude": -1254621000,
        "Elevation (m)": 24.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TOFINO A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038205",
        "Station ID": 277,
        "WMO ID": null,
        "TC ID": "YAZ",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 490456000,
        "Longitude": -1254621020,
        "Elevation (m)": 24.45,
        "First Year": 1942,
        "Last Year": 2022,
        "HLY First Year": 1960,
        "HLY Last Year": 2014,
        "DLY First Year": 1942,
        "DLY Last Year": 2022,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "TOFINO CATFACE MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038206",
        "Station ID": 278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -125.97,
        "Latitude": 491600000,
        "Longitude": -1255800000,
        "Elevation (m)": 740.7,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "TOFINO VILLAGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038207",
        "Station ID": 279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -125.91,
        "Latitude": 490913000,
        "Longitude": -1255424000,
        "Elevation (m)": 4.6,
        "First Year": 1962,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1999,
        "MLY First Year": 1962,
        "MLY Last Year": 1999
      },
      {
        "Name": "TOFINO A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038210",
        "Station ID": 52960,
        "WMO ID": null,
        "TC ID": "YAZ",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 490456000,
        "Longitude": -1254621000,
        "Elevation (m)": 24.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TRIANGLE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038240",
        "Station ID": 280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -129.08,
        "Latitude": 505200000,
        "Longitude": -1290500000,
        "Elevation (m)": 207.3,
        "First Year": 1910,
        "Last Year": 1921,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1921,
        "MLY First Year": 1910,
        "MLY Last Year": 1921
      },
      {
        "Name": "UCLUELET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038330",
        "Station ID": 281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -125.53,
        "Latitude": 485800000,
        "Longitude": -1253200000,
        "Elevation (m)": 5.5,
        "First Year": 1914,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1948,
        "MLY First Year": 1914,
        "MLY Last Year": 1948
      },
      {
        "Name": "UCLUELET BRYNNOR MINES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038331",
        "Station ID": 282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -125.43,
        "Latitude": 490300000,
        "Longitude": -1252600000,
        "Elevation (m)": 91.4,
        "First Year": 1964,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1968,
        "MLY First Year": 1964,
        "MLY Last Year": 1968
      },
      {
        "Name": "UCLUELET KENNEDY CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038332",
        "Station ID": 283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -125.53,
        "Latitude": 485643020,
        "Longitude": -1253138050,
        "Elevation (m)": 40,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINTER HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1038964",
        "Station ID": 284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -128.03,
        "Latitude": 503100000,
        "Longitude": -1280200000,
        "Elevation (m)": 4.6,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "ZEBALLOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1039030",
        "Station ID": 285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -126.85,
        "Latitude": 495900000,
        "Longitude": -1265100000,
        "Elevation (m)": 7,
        "First Year": 1955,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1993,
        "MLY First Year": 1955,
        "MLY Last Year": 1993
      },
      {
        "Name": "ZEBALLOS MURAUDE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1039035",
        "Station ID": 48888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -126.78,
        "Latitude": 500310600,
        "Longitude": -1264643300,
        "Elevation (m)": 103,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ZEBALLOS IRON MINES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1039040",
        "Station ID": 286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -126.82,
        "Latitude": 500200000,
        "Longitude": -1264900000,
        "Elevation (m)": 91.4,
        "First Year": 1963,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1968,
        "MLY First Year": 1963,
        "MLY Last Year": 1968
      },
      {
        "Name": "NITINAT LAKE (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "103EFJ0",
        "Station ID": 10184,
        "WMO ID": null,
        "TC ID": "WAC",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -124.83,
        "Latitude": 484000000,
        "Longitude": -1245000000,
        "Elevation (m)": 36.4,
        "First Year": 1992,
        "Last Year": 2001,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1992,
        "DLY Last Year": 2001,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALTA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040390",
        "Station ID": 291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500900000,
        "Longitude": -1225700000,
        "Elevation (m)": 667.5,
        "First Year": 1950,
        "Last Year": 1976,
        "HLY First Year": 1953,
        "HLY Last Year": 1976,
        "DLY First Year": 1950,
        "DLY Last Year": 1976,
        "MLY First Year": 1950,
        "MLY Last Year": 1976
      },
      {
        "Name": "ALTA LAKE NORTHAIR MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040400",
        "Station ID": 292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 500700000,
        "Longitude": -1230600000,
        "Elevation (m)": 1005.8,
        "First Year": 1975,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1987,
        "MLY First Year": 1975,
        "MLY Last Year": 1987
      },
      {
        "Name": "ALTA LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040420",
        "Station ID": 293,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -122.98,
        "Latitude": 500900000,
        "Longitude": -1225900000,
        "Elevation (m)": 640.1,
        "First Year": 1931,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1969,
        "MLY First Year": 1931,
        "MLY Last Year": 1969
      },
      {
        "Name": "ANVIL ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040445",
        "Station ID": 294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 493100000,
        "Longitude": -1231800000,
        "Elevation (m)": 30.5,
        "First Year": 1956,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1963,
        "MLY First Year": 1956,
        "MLY Last Year": 1963
      },
      {
        "Name": "BOWEN ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040908",
        "Station ID": 296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 492100000,
        "Longitude": -1232500000,
        "Elevation (m)": 22.9,
        "First Year": 1959,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1965,
        "MLY First Year": 1959,
        "MLY Last Year": 1965
      },
      {
        "Name": "BOWEN ISL ARBUTUS BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040909",
        "Station ID": 297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.38,
        "Latitude": 492000000,
        "Longitude": -1232300000,
        "Elevation (m)": 21,
        "First Year": 1961,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1998,
        "MLY First Year": 1961,
        "MLY Last Year": 1998
      },
      {
        "Name": "BOWEN ISLAND BOWEN BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "104090R",
        "Station ID": 295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 492153440,
        "Longitude": -1232513036,
        "Elevation (m)": 36.6,
        "First Year": 1966,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2014,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOWEN ISLAND CATES BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040911",
        "Station ID": 298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.41,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 492443000,
        "Longitude": -1231854000,
        "Elevation (m)": 27,
        "First Year": 1987,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2000,
        "MLY First Year": 1987,
        "MLY Last Year": 2000
      },
      {
        "Name": "BOWEN ISLAND JOSEPHINE RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "104091A",
        "Station ID": 6815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -123.38,
        "Latitude": 492200000,
        "Longitude": -1232300000,
        "Elevation (m)": 160,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "BOWEN ISLAND SUNSET PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "104091B",
        "Station ID": 6816,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 492135668,
        "Longitude": -1232406008,
        "Elevation (m)": 185,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOWEN ISLAND MILLERS LDG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040R09",
        "Station ID": 290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.39,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 492331000,
        "Longitude": -1231941000,
        "Elevation (m)": 91.1,
        "First Year": 1972,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2001,
        "MLY First Year": 1972,
        "MLY Last Year": 2001
      },
      {
        "Name": "BOWEN ISLAND KILLARNEY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1040R1J",
        "Station ID": 6814,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 492400000,
        "Longitude": -1232200000,
        "Elevation (m)": 50,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "BRITANNIA BEACH FURRY CR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1041050",
        "Station ID": 299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -123.22,
        "Latitude": 493502000,
        "Longitude": -1231325000,
        "Elevation (m)": 9,
        "First Year": 1913,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2000,
        "MLY First Year": 1913,
        "MLY Last Year": 2000
      },
      {
        "Name": "BUCCANEER BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1041100",
        "Station ID": 300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -123.97,
        "Latitude": 492900000,
        "Longitude": -1235800000,
        "Elevation (m)": 1.5,
        "First Year": 1927,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1948,
        "MLY First Year": 1927,
        "MLY Last Year": 1948
      },
      {
        "Name": "CAPILANO INTAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1041380",
        "Station ID": 301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 492400000,
        "Longitude": -1230900000,
        "Elevation (m)": 146.3,
        "First Year": 1924,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1955,
        "MLY First Year": 1924,
        "MLY Last Year": 1955
      },
      {
        "Name": "CHEEKYE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1041490",
        "Station ID": 302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 494800000,
        "Longitude": -1230900000,
        "Elevation (m)": 48.8,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "CLOWHOM FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1041710",
        "Station ID": 303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -123.53,
        "Latitude": 494300000,
        "Longitude": -1233200000,
        "Elevation (m)": 22.9,
        "First Year": 1932,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 1990,
        "MLY First Year": 1932,
        "MLY Last Year": 1990
      },
      {
        "Name": "DAISY LAKE DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1042255",
        "Station ID": 304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 495900000,
        "Longitude": -1230800000,
        "Elevation (m)": 381,
        "First Year": 1968,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1983,
        "MLY First Year": 1968,
        "MLY Last Year": 1983
      },
      {
        "Name": "DESERTED BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1042470",
        "Station ID": 305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 500600000,
        "Longitude": -1234400000,
        "Elevation (m)": 6.1,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "GAMBIER HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043048",
        "Station ID": 306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.44,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 492635000,
        "Longitude": -1232557000,
        "Elevation (m)": 53.3,
        "First Year": 1962,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2003,
        "MLY First Year": 1962,
        "MLY Last Year": 2003
      },
      {
        "Name": "GARIBALDI",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043060",
        "Station ID": 307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 495900000,
        "Longitude": -1230800000,
        "Elevation (m)": 381,
        "First Year": 1921,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1980,
        "MLY First Year": 1921,
        "MLY Last Year": 1980
      },
      {
        "Name": "GIBSONS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043150",
        "Station ID": 308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.51,
        "Latitude": 492348950,
        "Longitude": -1233048650,
        "Elevation (m)": 62,
        "First Year": 1949,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2006,
        "MLY First Year": 1949,
        "MLY Last Year": 2006
      },
      {
        "Name": "GIBSONS GOWER POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043152",
        "Station ID": 309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.39,
        "Longitude (Decimal Degrees)": -123.54,
        "Latitude": 492308090,
        "Longitude": -1233229000,
        "Elevation (m)": 34,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRIEF POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043304",
        "Station ID": 27449,
        "WMO ID": null,
        "TC ID": "WKA",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -124.53,
        "Latitude": 494816600,
        "Longitude": -1243130800,
        "Elevation (m)": 10,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALFMOON BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043320",
        "Station ID": 311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -123.92,
        "Latitude": 493100000,
        "Longitude": -1235500000,
        "Elevation (m)": 10.7,
        "First Year": 1961,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1964
      },
      {
        "Name": "HALFMOON BAY MIDDLE PT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043321",
        "Station ID": 312,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -124,
        "Latitude": 493500000,
        "Longitude": -1240000000,
        "Elevation (m)": 91.4,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "HALFMOON BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10433B0",
        "Station ID": 310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -123.9,
        "Latitude": 493100000,
        "Longitude": -1235400000,
        "Elevation (m)": 61,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "HOLLYBURN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043504",
        "Station ID": 313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 491900000,
        "Longitude": -1231000000,
        "Elevation (m)": 45.7,
        "First Year": 1926,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1952,
        "MLY First Year": 1926,
        "MLY Last Year": 1952
      },
      {
        "Name": "HOPKINS LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043582",
        "Station ID": 314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -123.48,
        "Latitude": 492800000,
        "Longitude": -1232900000,
        "Elevation (m)": 7.6,
        "First Year": 1969,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1992,
        "MLY First Year": 1969,
        "MLY Last Year": 1992
      },
      {
        "Name": "JERVIS INLET VANC BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1043722",
        "Station ID": 315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -123.87,
        "Latitude": 495500000,
        "Longitude": -1235200000,
        "Elevation (m)": 2.1,
        "First Year": 1984,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1993,
        "MLY First Year": 1984,
        "MLY Last Year": 1993
      },
      {
        "Name": "LOIS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1044680",
        "Station ID": 316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -124.15,
        "Latitude": 495200000,
        "Longitude": -1240900000,
        "Elevation (m)": 167.6,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "LOIS RIVER DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1044710",
        "Station ID": 317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -124.32,
        "Latitude": 494743000,
        "Longitude": -1241905000,
        "Elevation (m)": 157,
        "First Year": 1931,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 2007,
        "MLY First Year": 1931,
        "MLY Last Year": 2007
      },
      {
        "Name": "LUND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1044732",
        "Station ID": 318,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -124.77,
        "Latitude": 495900000,
        "Longitude": -1244600000,
        "Elevation (m)": 13.7,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "MALIBU JERVIS INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1044840",
        "Station ID": 319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -123.85,
        "Latitude": 500954060,
        "Longitude": -1235113009,
        "Elevation (m)": 18,
        "First Year": 1974,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2012,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "MERRY ISLAND LIGHTSTATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1045100",
        "Station ID": 320,
        "WMO ID": null,
        "TC ID": "WMR",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -123.91,
        "Latitude": 492803030,
        "Longitude": -1235445040,
        "Elevation (m)": 7.2,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": 1954,
        "HLY Last Year": 2001,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "MERRY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1045101",
        "Station ID": 27288,
        "WMO ID": 71210,
        "TC ID": "WMY",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -123.92,
        "Latitude": 492800000,
        "Longitude": -1235500000,
        "Elevation (m)": 20,
        "First Year": 1997,
        "Last Year": 1998,
        "HLY First Year": 1997,
        "HLY Last Year": 1998,
        "DLY First Year": 1998,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NELSON ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1045440",
        "Station ID": 321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 494200000,
        "Longitude": -1240500000,
        "Elevation (m)": 15.2,
        "First Year": 1964,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1970,
        "MLY First Year": 1965,
        "MLY Last Year": 1970
      },
      {
        "Name": "PARADISE VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1045960",
        "Station ID": 322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 494900000,
        "Longitude": -1230900000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "HOWE SOUND - PAM ROCKS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10459NN",
        "Station ID": 6817,
        "WMO ID": 71211,
        "TC ID": "WAS",
        "Latitude (Decimal Degrees)": 49.49,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 492916009,
        "Longitude": -1231758030,
        "Elevation (m)": 7.14,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "PENDER HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046115",
        "Station ID": 323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -124.03,
        "Latitude": 493804000,
        "Longitude": -1240156000,
        "Elevation (m)": 64,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORT MELLON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046330",
        "Station ID": 324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -123.48,
        "Latitude": 493100000,
        "Longitude": -1232900000,
        "Elevation (m)": 7.6,
        "First Year": 1942,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1989,
        "MLY First Year": 1942,
        "MLY Last Year": 1989
      },
      {
        "Name": "PORT MELLON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046332",
        "Station ID": 45267,
        "WMO ID": 71605,
        "TC ID": "VOM",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -123.5,
        "Latitude": 493134700,
        "Longitude": -1232946500,
        "Elevation (m)": 125.1,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "POWELL LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046360",
        "Station ID": 325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -124.47,
        "Latitude": 500600000,
        "Longitude": -1242800000,
        "Elevation (m)": 76.2,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "POWELL RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046390",
        "Station ID": 326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -124.55,
        "Latitude": 495234000,
        "Longitude": -1243315000,
        "Elevation (m)": 51.8,
        "First Year": 1924,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2007,
        "MLY First Year": 1924,
        "MLY Last Year": 2007
      },
      {
        "Name": "POWELL RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046391",
        "Station ID": 327,
        "WMO ID": null,
        "TC ID": "YPW",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 495003010,
        "Longitude": -1243001000,
        "Elevation (m)": 129.51,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": 1982,
        "HLY Last Year": 2013,
        "DLY First Year": 1954,
        "DLY Last Year": 2022,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "POWELL RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046392",
        "Station ID": 45807,
        "WMO ID": 71720,
        "TC ID": "VOP",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 495004400,
        "Longitude": -1242948500,
        "Elevation (m)": 125,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POWELL RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046395",
        "Station ID": 51438,
        "WMO ID": null,
        "TC ID": "YPW",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 495003000,
        "Longitude": -1243001000,
        "Elevation (m)": 129.5,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POWELL RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046396",
        "Station ID": 52018,
        "WMO ID": null,
        "TC ID": "YPW",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 495003000,
        "Longitude": -1243001000,
        "Elevation (m)": 129.5,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POWELL RIVER GRIEF POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046400",
        "Station ID": 328,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -124.52,
        "Latitude": 494800000,
        "Longitude": -1243100000,
        "Elevation (m)": 31,
        "First Year": 1960,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1993,
        "MLY First Year": 1960,
        "MLY Last Year": 1993
      },
      {
        "Name": "POWELL RIVER WESTVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046410",
        "Station ID": 329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -124.52,
        "Latitude": 495000000,
        "Longitude": -1243100000,
        "Elevation (m)": 54.9,
        "First Year": 1960,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1986,
        "MLY First Year": 1960,
        "MLY Last Year": 1986
      },
      {
        "Name": "ROBERTS CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046791",
        "Station ID": 330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.68,
        "Latitude": 492400000,
        "Longitude": -1234100000,
        "Elevation (m)": 3.7,
        "First Year": 1924,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1942,
        "MLY First Year": 1924,
        "MLY Last Year": 1942
      },
      {
        "Name": "ROBERTS CREEK EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1046795",
        "Station ID": 331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -123.62,
        "Latitude": 492600000,
        "Longitude": -1233700000,
        "Elevation (m)": 143.3,
        "First Year": 1956,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1960,
        "MLY First Year": 1956,
        "MLY Last Year": 1960
      },
      {
        "Name": "SECHELT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047170",
        "Station ID": 333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -123.77,
        "Latitude": 492800000,
        "Longitude": -1234600000,
        "Elevation (m)": 22.9,
        "First Year": 1927,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1968,
        "MLY First Year": 1927,
        "MLY Last Year": 1968
      },
      {
        "Name": "SECHELT AUT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047172",
        "Station ID": 45788,
        "WMO ID": 71638,
        "TC ID": "VOU",
        "Latitude (Decimal Degrees)": 49.46,
        "Longitude (Decimal Degrees)": -123.72,
        "Latitude": 492728788,
        "Longitude": -1234254945,
        "Elevation (m)": 86.2,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SECHELT SANDY HOOK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047176",
        "Station ID": 334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -123.77,
        "Latitude": 493200000,
        "Longitude": -1234600000,
        "Elevation (m)": 125,
        "First Year": 1987,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1987,
        "MLY First Year": 1987,
        "MLY Last Year": 1987
      },
      {
        "Name": "SECHELT 5WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047179",
        "Station ID": 335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -123.81,
        "Latitude": 492827000,
        "Longitude": -1234823000,
        "Elevation (m)": 61,
        "First Year": 1989,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2005,
        "MLY First Year": 1989,
        "MLY Last Year": 2005
      },
      {
        "Name": "SQUAMISH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047660",
        "Station ID": 337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 494200000,
        "Longitude": -1230800000,
        "Elevation (m)": 31.1,
        "First Year": 1959,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1996,
        "MLY First Year": 1959,
        "MLY Last Year": 1996
      },
      {
        "Name": "SQUAMISH FMC CHEMICALS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047662",
        "Station ID": 338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 494100000,
        "Longitude": -1231000000,
        "Elevation (m)": 3,
        "First Year": 1968,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1983,
        "MLY First Year": 1969,
        "MLY Last Year": 1983
      },
      {
        "Name": "SQUAMISH ST DAVIDS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047669",
        "Station ID": 339,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -123,
        "Latitude": 494500000,
        "Longitude": -1230000000,
        "Elevation (m)": 21.3,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "SQUAMISH STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047670",
        "Station ID": 340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 494400000,
        "Longitude": -1230900000,
        "Elevation (m)": 6.1,
        "First Year": 1981,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1996,
        "MLY First Year": 1981,
        "MLY Last Year": 1996
      },
      {
        "Name": "SQUAMISH STP CENTRAL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047671",
        "Station ID": 341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -123.16,
        "Latitude": 494154000,
        "Longitude": -1230937000,
        "Elevation (m)": 3.9,
        "First Year": 1986,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2005,
        "MLY First Year": 1986,
        "MLY Last Year": 2005
      },
      {
        "Name": "SQUAMISH UPPER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047672",
        "Station ID": 342,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -123.28,
        "Latitude": 495345000,
        "Longitude": -1231653000,
        "Elevation (m)": 46,
        "First Year": 1979,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2010,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "SQUAMISH 10 MILE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047673",
        "Station ID": 343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -123.2,
        "Latitude": 494900000,
        "Longitude": -1231200000,
        "Elevation (m)": 30,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "SQUAMISH AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10476F0",
        "Station ID": 336,
        "WMO ID": 71207,
        "TC ID": "WSK",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -123.16,
        "Latitude": 494659550,
        "Longitude": -1230940300,
        "Elevation (m)": 53.7,
        "First Year": 1982,
        "Last Year": 2022,
        "HLY First Year": 1982,
        "HLY Last Year": 2022,
        "DLY First Year": 1982,
        "DLY Last Year": 2022,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "STILLWATER POWER HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047770",
        "Station ID": 344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -124.32,
        "Latitude": 494600000,
        "Longitude": -1241900000,
        "Elevation (m)": 7,
        "First Year": 1931,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 2007,
        "MLY First Year": 1931,
        "MLY Last Year": 2007
      },
      {
        "Name": "SQUAMISH GARIBALDI",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047F6B",
        "Station ID": 332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 494400000,
        "Longitude": -1230900000,
        "Elevation (m)": 10,
        "First Year": 1975,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1996,
        "MLY First Year": 1975,
        "MLY Last Year": 1996
      },
      {
        "Name": "SQUAMISH AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1047FF0",
        "Station ID": 10816,
        "WMO ID": null,
        "TC ID": "YSE",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -123.16,
        "Latitude": 494654000,
        "Longitude": -1230943000,
        "Elevation (m)": 52.1,
        "First Year": 2010,
        "Last Year": 2010,
        "HLY First Year": 2010,
        "HLY Last Year": 2010,
        "DLY First Year": 2010,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TEXADA ISLAND A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048140",
        "Station ID": 345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -124.52,
        "Latitude": 494200000,
        "Longitude": -1243100000,
        "Elevation (m)": 96.9,
        "First Year": 1960,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1993,
        "MLY First Year": 1960,
        "MLY Last Year": 1993
      },
      {
        "Name": "TUNNEL CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048310",
        "Station ID": 346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 493700000,
        "Longitude": -1230800000,
        "Elevation (m)": 670.6,
        "First Year": 1924,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1974,
        "MLY First Year": 1924,
        "MLY Last Year": 1974
      },
      {
        "Name": "WEST REDONDA ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048816",
        "Station ID": 347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -124.82,
        "Latitude": 501500000,
        "Longitude": -1244900000,
        "Elevation (m)": 4.9,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "WHISTLER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048898",
        "Station ID": 348,
        "WMO ID": null,
        "TC ID": "WAE",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500744001,
        "Longitude": -1225717400,
        "Elevation (m)": 657.8,
        "First Year": 1976,
        "Last Year": 2022,
        "HLY First Year": 1983,
        "HLY Last Year": 2014,
        "DLY First Year": 1976,
        "DLY Last Year": 2022,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHISTLER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048899",
        "Station ID": 52178,
        "WMO ID": null,
        "TC ID": "WAE",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500744000,
        "Longitude": -1225717000,
        "Elevation (m)": 657.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WOODFIBRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048974",
        "Station ID": 349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -123.89,
        "Latitude": 493452000,
        "Longitude": -1235320000,
        "Elevation (m)": 3.5,
        "First Year": 1960,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2006,
        "MLY First Year": 1960,
        "MLY Last Year": 2006
      },
      {
        "Name": "WOODFIBRE HENRIETTA LK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1048975",
        "Station ID": 350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -123.32,
        "Latitude": 494100000,
        "Longitude": -1231900000,
        "Elevation (m)": 869,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "SQUAMISH ASHLU CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "104G660",
        "Station ID": 287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -123.42,
        "Latitude": 495700000,
        "Longitude": -1232500000,
        "Elevation (m)": 495,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HARDY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "104LC3N",
        "Station ID": 288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -124.18,
        "Latitude": 494400000,
        "Longitude": -1241100000,
        "Elevation (m)": 26,
        "First Year": 1987,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1993,
        "MLY First Year": 1987,
        "MLY Last Year": 1993
      },
      {
        "Name": "SQUAMISH PARADISE VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "104PF64",
        "Station ID": 289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 495000000,
        "Longitude": -1230900000,
        "Elevation (m)": 61,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "CAPE ST JAMES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1051350",
        "Station ID": 352,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.93,
        "Longitude (Decimal Degrees)": -131.02,
        "Latitude": 515600000,
        "Longitude": -1310100000,
        "Elevation (m)": 92,
        "First Year": 1925,
        "Last Year": 1998,
        "HLY First Year": 1953,
        "HLY Last Year": 1992,
        "DLY First Year": 1925,
        "DLY Last Year": 1998,
        "MLY First Year": 1925,
        "MLY Last Year": 1998
      },
      {
        "Name": "CAPE ST JAMES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1051351",
        "Station ID": 10901,
        "WMO ID": 71107,
        "TC ID": "WZV",
        "Latitude (Decimal Degrees)": 51.94,
        "Longitude (Decimal Degrees)": -131.02,
        "Latitude": 515609000,
        "Longitude": -1310057000,
        "Elevation (m)": 87.8,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "CINOLA MINE SITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1051630",
        "Station ID": 353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -132.13,
        "Latitude": 533000000,
        "Longitude": -1320800000,
        "Elevation (m)": 75.9,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "DEAD TREE POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1052310",
        "Station ID": 354,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -131.93,
        "Latitude": 532200000,
        "Longitude": -1315600000,
        "Elevation (m)": 14.3,
        "First Year": 1939,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1958,
        "MLY First Year": 1939,
        "MLY Last Year": 1958
      },
      {
        "Name": "EDEN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1052639",
        "Station ID": 355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -132.73,
        "Latitude": 535200000,
        "Longitude": -1324400000,
        "Elevation (m)": 42.7,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "HOSKINS ISLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1053608",
        "Station ID": 6818,
        "WMO ID": null,
        "TC ID": "WZK",
        "Latitude (Decimal Degrees)": 52.53,
        "Longitude (Decimal Degrees)": -131.55,
        "Latitude": 523200000,
        "Longitude": -1313300000,
        "Elevation (m)": 8,
        "First Year": 1992,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": 1992,
        "DLY Last Year": 1995,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "IKEDA BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1053645",
        "Station ID": 356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -131.12,
        "Latitude": 521700000,
        "Longitude": -1310700000,
        "Elevation (m)": 1.5,
        "First Year": 1908,
        "Last Year": 1920,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1920,
        "MLY First Year": 1908,
        "MLY Last Year": 1920
      },
      {
        "Name": "KINDAKUN ROCKS (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054222",
        "Station ID": 8062,
        "WMO ID": 71472,
        "TC ID": "WQS",
        "Latitude (Decimal Degrees)": 53.32,
        "Longitude (Decimal Degrees)": -132.77,
        "Latitude": 531856088,
        "Longitude": -1324619199,
        "Elevation (m)": 14.7,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "LANGARA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054500",
        "Station ID": 358,
        "WMO ID": 71899,
        "TC ID": "WLA",
        "Latitude (Decimal Degrees)": 54.26,
        "Longitude (Decimal Degrees)": -133.06,
        "Latitude": 541518648,
        "Longitude": -1330328799,
        "Elevation (m)": 42.69,
        "First Year": 1936,
        "Last Year": 2022,
        "HLY First Year": 1954,
        "HLY Last Year": 2001,
        "DLY First Year": 1936,
        "DLY Last Year": 2022,
        "MLY First Year": 1936,
        "MLY Last Year": 2007
      },
      {
        "Name": "LANGARA ISLAND RCS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054503",
        "Station ID": 6819,
        "WMO ID": 71903,
        "TC ID": "WJU",
        "Latitude (Decimal Degrees)": 54.26,
        "Longitude (Decimal Degrees)": -133.06,
        "Latitude": 541519397,
        "Longitude": -1330330557,
        "Elevation (m)": 46.9,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "MASSET AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054920",
        "Station ID": 360,
        "WMO ID": null,
        "TC ID": "ZMT",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -132.12,
        "Latitude": 540121400,
        "Longitude": -1320702900,
        "Elevation (m)": 5.8,
        "First Year": 1897,
        "Last Year": 2014,
        "HLY First Year": 2005,
        "HLY Last Year": 2014,
        "DLY First Year": 1897,
        "DLY Last Year": 2008,
        "MLY First Year": 1897,
        "MLY Last Year": 2007
      },
      {
        "Name": "MASSET A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054921",
        "Station ID": 52339,
        "WMO ID": null,
        "TC ID": "ZMT",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -132.13,
        "Latitude": 540138000,
        "Longitude": -1320730000,
        "Elevation (m)": 5.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MASSET A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054925",
        "Station ID": 361,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -132.17,
        "Latitude": 540000000,
        "Longitude": -1321000000,
        "Elevation (m)": 12.8,
        "First Year": 1944,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 2016,
        "MLY First Year": 1944,
        "MLY Last Year": 1949
      },
      {
        "Name": "MASSET INDIAN DAY SCHOOL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054926",
        "Station ID": 362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -132.18,
        "Latitude": 540200000,
        "Longitude": -1321100000,
        "Elevation (m)": 12.2,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "MASSET A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054929",
        "Station ID": 52940,
        "WMO ID": null,
        "TC ID": "ZMT",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -132.13,
        "Latitude": 540138000,
        "Longitude": -1320730000,
        "Elevation (m)": 5.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MASSET CFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10549BN",
        "Station ID": 359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -132.07,
        "Latitude": 540200000,
        "Longitude": -1320400000,
        "Elevation (m)": 12.2,
        "First Year": 1971,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1981,
        "MLY First Year": 1971,
        "MLY Last Year": 1981
      },
      {
        "Name": "MASSET TOW HILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1054RKF",
        "Station ID": 357,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -131.97,
        "Latitude": 540200000,
        "Longitude": -1315800000,
        "Elevation (m)": 2.1,
        "First Year": 1981,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1986,
        "MLY First Year": 1981,
        "MLY Last Year": 1986
      },
      {
        "Name": "MORESBY ISLAND MITCHELL INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10551R8",
        "Station ID": 6820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.93,
        "Longitude (Decimal Degrees)": -132.13,
        "Latitude": 525605000,
        "Longitude": -1320744000,
        "Elevation (m)": 3,
        "First Year": 1991,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2007,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "PALLANT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1055950",
        "Station ID": 363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.05,
        "Longitude (Decimal Degrees)": -132.05,
        "Latitude": 530300000,
        "Longitude": -1320300000,
        "Elevation (m)": 20,
        "First Year": 1980,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1998,
        "MLY First Year": 1980,
        "MLY Last Year": 1998
      },
      {
        "Name": "PORT CLEMENTS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1056250",
        "Station ID": 364,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.68,
        "Longitude (Decimal Degrees)": -132.18,
        "Latitude": 534100000,
        "Longitude": -1321100000,
        "Elevation (m)": 16,
        "First Year": 1967,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1988,
        "MLY First Year": 1967,
        "MLY Last Year": 1988
      },
      {
        "Name": "QUEEN CHARLOTTE CITY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1056590",
        "Station ID": 365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -132.08,
        "Latitude": 531500000,
        "Longitude": -1320500000,
        "Elevation (m)": 12,
        "First Year": 1914,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1948,
        "MLY First Year": 1914,
        "MLY Last Year": 1948
      },
      {
        "Name": "RENNELL SOUND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1056742",
        "Station ID": 366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.3,
        "Longitude (Decimal Degrees)": -132.42,
        "Latitude": 531800000,
        "Longitude": -1322500000,
        "Elevation (m)": 3,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "ROSE SPIT (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1056869",
        "Station ID": 8064,
        "WMO ID": 71477,
        "TC ID": "WRO",
        "Latitude (Decimal Degrees)": 54.16,
        "Longitude (Decimal Degrees)": -131.66,
        "Latitude": 540932904,
        "Longitude": -1313940773,
        "Elevation (m)": 6.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2005
      },
      {
        "Name": "SANDSPIT A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1057050",
        "Station ID": 367,
        "WMO ID": null,
        "TC ID": "YZP",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -131.81,
        "Latitude": 531514000,
        "Longitude": -1314847000,
        "Elevation (m)": 6.4,
        "First Year": 1945,
        "Last Year": 2017,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1945,
        "DLY Last Year": 2017,
        "MLY First Year": 1945,
        "MLY Last Year": 2007
      },
      {
        "Name": "SANDSPIT AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1057051",
        "Station ID": 50108,
        "WMO ID": 71101,
        "TC ID": "VZP",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -131.81,
        "Latitude": 531458002,
        "Longitude": -1314847257,
        "Elevation (m)": 6.5,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANDSPIT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1057052",
        "Station ID": 48648,
        "WMO ID": 71111,
        "TC ID": "YZP",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -131.81,
        "Latitude": 531515000,
        "Longitude": -1314850000,
        "Elevation (m)": 6.4,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANDSPIT AWOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1057055",
        "Station ID": 30955,
        "WMO ID": null,
        "TC ID": "CZP",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -131.81,
        "Latitude": 531515000,
        "Longitude": -1314850000,
        "Elevation (m)": 6.4,
        "First Year": 1999,
        "Last Year": 2013,
        "HLY First Year": 1999,
        "HLY Last Year": 2013,
        "DLY First Year": 2002,
        "DLY Last Year": 2013,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "SCUDDER POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1057133",
        "Station ID": 6821,
        "WMO ID": null,
        "TC ID": "WZD",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -131.23,
        "Latitude": 522700000,
        "Longitude": -1311400000,
        "Elevation (m)": 5,
        "First Year": 1992,
        "Last Year": 2009,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": 1992,
        "DLY Last Year": 2009,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "SEWELL INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1057192",
        "Station ID": 368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -131.98,
        "Latitude": 525300000,
        "Longitude": -1315900000,
        "Elevation (m)": 15,
        "First Year": 1973,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1990,
        "MLY First Year": 1973,
        "MLY Last Year": 1990
      },
      {
        "Name": "TASU SOUND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1058003",
        "Station ID": 369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -132.05,
        "Latitude": 524600000,
        "Longitude": -1320300000,
        "Elevation (m)": 15.2,
        "First Year": 1963,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1984,
        "MLY First Year": 1963,
        "MLY Last Year": 1984
      },
      {
        "Name": "TLELL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1058190",
        "Station ID": 370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -131.95,
        "Latitude": 533000000,
        "Longitude": -1315700000,
        "Elevation (m)": 4.6,
        "First Year": 1950,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1999,
        "MLY First Year": 1950,
        "MLY Last Year": 1999
      },
      {
        "Name": "SEWALL MASSET INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "105PA91",
        "Station ID": 351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.76,
        "Longitude (Decimal Degrees)": -132.3,
        "Latitude": 534549200,
        "Longitude": -1321757000,
        "Elevation (m)": 3,
        "First Year": 1974,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2017,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "ADDENBROKE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060080",
        "Station ID": 374,
        "WMO ID": null,
        "TC ID": "WCZ",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -127.86,
        "Latitude": 513612700,
        "Longitude": -1275149900,
        "Elevation (m)": 21.3,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALICE ARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060330",
        "Station ID": 375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.68,
        "Longitude (Decimal Degrees)": -129.5,
        "Latitude": 554100000,
        "Longitude": -1293000000,
        "Elevation (m)": 314.2,
        "First Year": 1948,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1964,
        "MLY First Year": 1948,
        "MLY Last Year": 1964
      },
      {
        "Name": "ALICE ARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060331",
        "Station ID": 376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.47,
        "Longitude (Decimal Degrees)": -129.47,
        "Latitude": 552800000,
        "Longitude": -1292800000,
        "Elevation (m)": 1.5,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "ANYOX",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060446",
        "Station ID": 377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.42,
        "Longitude (Decimal Degrees)": -129.82,
        "Latitude": 552500000,
        "Longitude": -1294900000,
        "Elevation (m)": 112.8,
        "First Year": 1916,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1935,
        "MLY First Year": 1916,
        "MLY Last Year": 1935
      },
      {
        "Name": "BELLA BELLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060810",
        "Station ID": 378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -128.15,
        "Latitude": 521000000,
        "Longitude": -1280900000,
        "Elevation (m)": 12.2,
        "First Year": 1933,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1977,
        "MLY First Year": 1933,
        "MLY Last Year": 1977
      },
      {
        "Name": "BELLA BELLA A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060811",
        "Station ID": 44766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.19,
        "Longitude (Decimal Degrees)": -128.16,
        "Latitude": 521106000,
        "Longitude": -1280924000,
        "Elevation (m)": 43,
        "First Year": 2006,
        "Last Year": 2011,
        "HLY First Year": 2007,
        "HLY Last Year": 2011,
        "DLY First Year": 2006,
        "DLY Last Year": 2011,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "BELLA BELLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060815",
        "Station ID": 49388,
        "WMO ID": 71582,
        "TC ID": "BBC",
        "Latitude (Decimal Degrees)": 52.19,
        "Longitude (Decimal Degrees)": -128.16,
        "Latitude": 521106000,
        "Longitude": -1280924000,
        "Elevation (m)": 43,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BELLA BELLA SHEARWATER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060820",
        "Station ID": 379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -128.08,
        "Latitude": 520900000,
        "Longitude": -1280500000,
        "Elevation (m)": 12.2,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1964
      },
      {
        "Name": "BELLA COOLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060840",
        "Station ID": 380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -126.69,
        "Latitude": 522214000,
        "Longitude": -1264106000,
        "Elevation (m)": 18.3,
        "First Year": 1895,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 2002,
        "MLY First Year": 1895,
        "MLY Last Year": 2002
      },
      {
        "Name": "BELLA COOLA A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060841",
        "Station ID": 381,
        "WMO ID": 71206,
        "TC ID": "YBD",
        "Latitude (Decimal Degrees)": 52.39,
        "Longitude (Decimal Degrees)": -126.6,
        "Latitude": 522315000,
        "Longitude": -1263545000,
        "Elevation (m)": 35.7,
        "First Year": 1983,
        "Last Year": 2017,
        "HLY First Year": 1983,
        "HLY Last Year": 2013,
        "DLY First Year": 1983,
        "DLY Last Year": 2017,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "BELLA COOLA BC HYDRO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060842",
        "Station ID": 382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -126.82,
        "Latitude": 522200000,
        "Longitude": -1264900000,
        "Elevation (m)": 13.7,
        "First Year": 1961,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1985,
        "MLY First Year": 1961,
        "MLY Last Year": 1985
      },
      {
        "Name": "BELLA COOLA AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060844",
        "Station ID": 54338,
        "WMO ID": 71533,
        "TC ID": "VBD",
        "Latitude (Decimal Degrees)": 52.39,
        "Longitude (Decimal Degrees)": -126.59,
        "Latitude": 522319000,
        "Longitude": -1263513000,
        "Elevation (m)": 36.89,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BELLA COOLA A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060845",
        "Station ID": 51419,
        "WMO ID": null,
        "TC ID": "YBD",
        "Latitude (Decimal Degrees)": 52.39,
        "Longitude (Decimal Degrees)": -126.6,
        "Latitude": 522315000,
        "Longitude": -1263545000,
        "Elevation (m)": 35.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BELLA COOLA A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060848",
        "Station ID": 52978,
        "WMO ID": null,
        "TC ID": "YBD",
        "Latitude (Decimal Degrees)": 52.39,
        "Longitude (Decimal Degrees)": -126.6,
        "Latitude": 522315000,
        "Longitude": -1263545000,
        "Elevation (m)": 35.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BOAT BLUFF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060901",
        "Station ID": 383,
        "WMO ID": null,
        "TC ID": "WZM",
        "Latitude (Decimal Degrees)": 52.64,
        "Longitude (Decimal Degrees)": -128.52,
        "Latitude": 523835050,
        "Longitude": -1283126070,
        "Elevation (m)": 10.7,
        "First Year": 1974,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1974,
        "DLY Last Year": 2022,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "BONILLA ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060902",
        "Station ID": 384,
        "WMO ID": null,
        "TC ID": "WVB",
        "Latitude (Decimal Degrees)": 53.49,
        "Longitude (Decimal Degrees)": -130.64,
        "Latitude": 532933900,
        "Longitude": -1303815700,
        "Elevation (m)": 16.2,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "BONILLA ISLAND (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1060R0K",
        "Station ID": 8265,
        "WMO ID": 71484,
        "TC ID": "WWL",
        "Latitude (Decimal Degrees)": 53.49,
        "Longitude (Decimal Degrees)": -130.64,
        "Latitude": 532934259,
        "Longitude": -1303820400,
        "Elevation (m)": 12.52,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "BUTEDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1061210",
        "Station ID": 385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.15,
        "Longitude (Decimal Degrees)": -128.7,
        "Latitude": 530900000,
        "Longitude": -1284200000,
        "Elevation (m)": 15.2,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "CATHEDRAL POINT (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1061458",
        "Station ID": 8264,
        "WMO ID": 71482,
        "TC ID": "WME",
        "Latitude (Decimal Degrees)": 52.19,
        "Longitude (Decimal Degrees)": -127.47,
        "Latitude": 521114830,
        "Longitude": -1272816180,
        "Elevation (m)": 26.4,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "CUMSHEWA ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062251",
        "Station ID": 6822,
        "WMO ID": 71771,
        "TC ID": "WZL",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -131.6,
        "Latitude": 530149476,
        "Longitude": -1313605599,
        "Elevation (m)": 12.57,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAWSONS LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062295",
        "Station ID": 386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -127.58,
        "Latitude": 513500000,
        "Longitude": -1273500000,
        "Elevation (m)": 34,
        "First Year": 1973,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1988,
        "MLY First Year": 1973,
        "MLY Last Year": 1988
      },
      {
        "Name": "DRYAD POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062544",
        "Station ID": 387,
        "WMO ID": null,
        "TC ID": "WAU",
        "Latitude (Decimal Degrees)": 52.19,
        "Longitude (Decimal Degrees)": -128.11,
        "Latitude": 521106002,
        "Longitude": -1280644008,
        "Elevation (m)": 3.5,
        "First Year": 1977,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1977,
        "DLY Last Year": 2022,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "EAST CRACROFT ISL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062593",
        "Station ID": 388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -126.32,
        "Latitude": 503600000,
        "Longitude": -1261900000,
        "Elevation (m)": 14,
        "First Year": 1985,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1995,
        "MLY First Year": 1985,
        "MLY Last Year": 1995
      },
      {
        "Name": "EGG ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062646",
        "Station ID": 389,
        "WMO ID": null,
        "TC ID": "WEM",
        "Latitude (Decimal Degrees)": 51.25,
        "Longitude (Decimal Degrees)": -127.84,
        "Latitude": 511450000,
        "Longitude": -1275007300,
        "Elevation (m)": 14,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": 1969,
        "HLY Last Year": 2001,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "ETHELDA BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062745",
        "Station ID": 390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.05,
        "Longitude (Decimal Degrees)": -129.68,
        "Latitude": 530300000,
        "Longitude": -1294100000,
        "Elevation (m)": 9.6,
        "First Year": 1957,
        "Last Year": 1994,
        "HLY First Year": 1957,
        "HLY Last Year": 1994,
        "DLY First Year": 1957,
        "DLY Last Year": 1991,
        "MLY First Year": 1957,
        "MLY Last Year": 1991
      },
      {
        "Name": "FALLS RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062790",
        "Station ID": 391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -129.73,
        "Latitude": 535900000,
        "Longitude": -1294400000,
        "Elevation (m)": 18,
        "First Year": 1931,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1992,
        "MLY First Year": 1931,
        "MLY Last Year": 1992
      },
      {
        "Name": "FIRVALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1062884",
        "Station ID": 6941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -126.3,
        "Latitude": 522700000,
        "Longitude": -1261800000,
        "Elevation (m)": 121.9,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "GENESEE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063086",
        "Station ID": 392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -126.75,
        "Latitude": 514000000,
        "Longitude": -1264500000,
        "Elevation (m)": 13.7,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "GREEN ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063298",
        "Station ID": 393,
        "WMO ID": null,
        "TC ID": "WNJ",
        "Latitude (Decimal Degrees)": 54.57,
        "Longitude (Decimal Degrees)": -130.71,
        "Latitude": 543407020,
        "Longitude": -1304229020,
        "Elevation (m)": 11.9,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": 1970,
        "HLY Last Year": 2001,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "GREY ISLET (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063303",
        "Station ID": 8258,
        "WMO ID": 71476,
        "TC ID": "WEK",
        "Latitude (Decimal Degrees)": 54.58,
        "Longitude (Decimal Degrees)": -130.7,
        "Latitude": 543449152,
        "Longitude": -1304152080,
        "Elevation (m)": 9.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "HARTLEY BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063339",
        "Station ID": 394,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -129.25,
        "Latitude": 532500000,
        "Longitude": -1291500000,
        "Elevation (m)": 2,
        "First Year": 1973,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1998,
        "MLY First Year": 1973,
        "MLY Last Year": 1998
      },
      {
        "Name": "HERBERT ISLAND (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063461",
        "Station ID": 8259,
        "WMO ID": 71485,
        "TC ID": "WLP",
        "Latitude (Decimal Degrees)": 50.94,
        "Longitude (Decimal Degrees)": -127.63,
        "Latitude": 505623140,
        "Longitude": -1273755380,
        "Elevation (m)": 13.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOLLAND ROCK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063496",
        "Station ID": 6823,
        "WMO ID": 71219,
        "TC ID": "WHL",
        "Latitude (Decimal Degrees)": 54.17,
        "Longitude (Decimal Degrees)": -130.36,
        "Latitude": 541020042,
        "Longitude": -1302139060,
        "Elevation (m)": 5.5,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 1997
      },
      {
        "Name": "IVORY ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1063690",
        "Station ID": 395,
        "WMO ID": null,
        "TC ID": "WJG",
        "Latitude (Decimal Degrees)": 52.27,
        "Longitude (Decimal Degrees)": -128.41,
        "Latitude": 521611040,
        "Longitude": -1282424030,
        "Elevation (m)": 9.8,
        "First Year": 1948,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1948,
        "DLY Last Year": 2022,
        "MLY First Year": 1948,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEMANO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064020",
        "Station ID": 396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.56,
        "Longitude (Decimal Degrees)": -127.94,
        "Latitude": 533347000,
        "Longitude": -1275634000,
        "Elevation (m)": 66,
        "First Year": 1951,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2022,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "KILDALA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064138",
        "Station ID": 397,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -128.48,
        "Latitude": 535000000,
        "Longitude": -1282900000,
        "Elevation (m)": 30.5,
        "First Year": 1966,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2000,
        "MLY First Year": 1966,
        "MLY Last Year": 2000
      },
      {
        "Name": "KINGCOME INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064227",
        "Station ID": 398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -126.2,
        "Latitude": 505700000,
        "Longitude": -1261200000,
        "Elevation (m)": 1.5,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "KITIMAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064288",
        "Station ID": 399,
        "WMO ID": null,
        "TC ID": "WKI",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -128.68,
        "Latitude": 540300000,
        "Longitude": -1284100000,
        "Elevation (m)": 12.8,
        "First Year": 1979,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": 1979,
        "DLY Last Year": 1994,
        "MLY First Year": 1979,
        "MLY Last Year": 1994
      },
      {
        "Name": "KITIMAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064289",
        "Station ID": 400,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -128.7,
        "Latitude": 540000000,
        "Longitude": -1284200000,
        "Elevation (m)": 16.8,
        "First Year": 1951,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1966,
        "MLY First Year": 1951,
        "MLY Last Year": 1966
      },
      {
        "Name": "KITIMAT MISSION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064290",
        "Station ID": 401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -128.65,
        "Latitude": 535900000,
        "Longitude": -1283900000,
        "Elevation (m)": 6.1,
        "First Year": 1902,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1948,
        "MLY First Year": 1902,
        "MLY Last Year": 1948
      },
      {
        "Name": "KITIMAT TOWNSITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064320",
        "Station ID": 402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -128.63,
        "Latitude": 540313000,
        "Longitude": -1283803000,
        "Elevation (m)": 98,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2022,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "KITIMAT 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064321",
        "Station ID": 403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.01,
        "Longitude (Decimal Degrees)": -128.71,
        "Latitude": 540035000,
        "Longitude": -1284218000,
        "Elevation (m)": 16.8,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "KITIMAT 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064322",
        "Station ID": 404,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.06,
        "Longitude (Decimal Degrees)": -128.64,
        "Latitude": 540353000,
        "Longitude": -1283827000,
        "Elevation (m)": 137,
        "First Year": 1988,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2013,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "KITIMAT FOREST AVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064324",
        "Station ID": 54978,
        "WMO ID": 73007,
        "TC ID": "VKC",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -128.63,
        "Latitude": 540313025,
        "Longitude": -1283801087,
        "Elevation (m)": 98,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KITSAULT MINESITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064329",
        "Station ID": 405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.43,
        "Longitude (Decimal Degrees)": -129.47,
        "Latitude": 552600000,
        "Longitude": -1292800000,
        "Elevation (m)": 620.9,
        "First Year": 1968,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1969,
        "MLY First Year": 1968,
        "MLY Last Year": 1969
      },
      {
        "Name": "KNIGHT INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064375",
        "Station ID": 406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -125.58,
        "Latitude": 510600000,
        "Longitude": -1253500000,
        "Elevation (m)": 9.1,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "LAKELSE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064497",
        "Station ID": 407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -128.52,
        "Latitude": 542345000,
        "Longitude": -1283125000,
        "Elevation (m)": 76,
        "First Year": 1967,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1999,
        "MLY First Year": 1967,
        "MLY Last Year": 1999
      },
      {
        "Name": "LAWYER ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064591",
        "Station ID": 408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -130.35,
        "Latitude": 540700000,
        "Longitude": -1302100000,
        "Elevation (m)": 6.1,
        "First Year": 1978,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1988,
        "MLY First Year": 1978,
        "MLY Last Year": 1988
      },
      {
        "Name": "LUCY ISLAND LIGHTSTATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1064728",
        "Station ID": 6824,
        "WMO ID": 71220,
        "TC ID": "WLC",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -130.61,
        "Latitude": 541745312,
        "Longitude": -1303632400,
        "Elevation (m)": 2,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2000
      },
      {
        "Name": "MCINNES ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065010",
        "Station ID": 409,
        "WMO ID": 71897,
        "TC ID": "WMS",
        "Latitude (Decimal Degrees)": 52.26,
        "Longitude (Decimal Degrees)": -128.72,
        "Latitude": 521542000,
        "Longitude": -1284310000,
        "Elevation (m)": 25.9,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": 1955,
        "HLY Last Year": 2001,
        "DLY First Year": 1954,
        "DLY Last Year": 2022,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILL BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065130",
        "Station ID": 410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55,
        "Longitude (Decimal Degrees)": -129.75,
        "Latitude": 550000000,
        "Longitude": -1294500000,
        "Elevation (m)": 3,
        "First Year": 1915,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1959,
        "MLY First Year": 1915,
        "MLY Last Year": 1959
      },
      {
        "Name": "MINSTREL ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065170",
        "Station ID": 411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -126.32,
        "Latitude": 503700000,
        "Longitude": -1261900000,
        "Elevation (m)": 3,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "NAAS HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065275",
        "Station ID": 412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.93,
        "Longitude (Decimal Degrees)": -129.93,
        "Latitude": 545600000,
        "Longitude": -1295600000,
        "Elevation (m)": 6.1,
        "First Year": 1900,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 1929,
        "MLY First Year": 1900,
        "MLY Last Year": 1929
      },
      {
        "Name": "NAMU",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065310",
        "Station ID": 413,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -127.87,
        "Latitude": 515200000,
        "Longitude": -1275200000,
        "Elevation (m)": 2,
        "First Year": 1931,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1985,
        "MLY First Year": 1931,
        "MLY Last Year": 1985
      },
      {
        "Name": "OCEAN FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065670",
        "Station ID": 414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -127.68,
        "Latitude": 522100000,
        "Longitude": -1274100000,
        "Elevation (m)": 4.9,
        "First Year": 1924,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1986,
        "MLY First Year": 1924,
        "MLY Last Year": 1986
      },
      {
        "Name": "PACIFIC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1065945",
        "Station ID": 415,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -128.27,
        "Latitude": 544600000,
        "Longitude": -1281600000,
        "Elevation (m)": 121.9,
        "First Year": 1977,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1982,
        "MLY First Year": 1977,
        "MLY Last Year": 1982
      },
      {
        "Name": "POINTER ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066202",
        "Station ID": 418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -127.95,
        "Latitude": 520400000,
        "Longitude": -1275700000,
        "Elevation (m)": 6.7,
        "First Year": 1977,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1989,
        "MLY First Year": 1977,
        "MLY Last Year": 1989
      },
      {
        "Name": "PORT SIMPSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066336",
        "Station ID": 419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.57,
        "Longitude (Decimal Degrees)": -130.43,
        "Latitude": 543400000,
        "Longitude": -1302600000,
        "Elevation (m)": 7.9,
        "First Year": 1886,
        "Last Year": 1910,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1910,
        "MLY First Year": 1886,
        "MLY Last Year": 1910
      },
      {
        "Name": "PREMIER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066420",
        "Station ID": 420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.05,
        "Longitude (Decimal Degrees)": -130.02,
        "Latitude": 560300000,
        "Longitude": -1300100000,
        "Elevation (m)": 410,
        "First Year": 1926,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1996,
        "MLY First Year": 1926,
        "MLY Last Year": 1996
      },
      {
        "Name": "PRINCE RUPERT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066480",
        "Station ID": 421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -130.38,
        "Latitude": 541700000,
        "Longitude": -1302300000,
        "Elevation (m)": 51.8,
        "First Year": 1908,
        "Last Year": 1962,
        "HLY First Year": 1953,
        "HLY Last Year": 1962,
        "DLY First Year": 1908,
        "DLY Last Year": 1962,
        "MLY First Year": 1908,
        "MLY Last Year": 1962
      },
      {
        "Name": "PRINCE RUPERT A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066481",
        "Station ID": 422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.29,
        "Longitude (Decimal Degrees)": -130.44,
        "Latitude": 541733000,
        "Longitude": -1302641000,
        "Elevation (m)": 35.4,
        "First Year": 1961,
        "Last Year": 2006,
        "HLY First Year": 1961,
        "HLY Last Year": 2005,
        "DLY First Year": 1962,
        "DLY Last Year": 2006,
        "MLY First Year": 1962,
        "MLY Last Year": 2006
      },
      {
        "Name": "PRINCE RUPERT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066482",
        "Station ID": 48693,
        "WMO ID": 71022,
        "TC ID": "YPR",
        "Latitude (Decimal Degrees)": 54.29,
        "Longitude (Decimal Degrees)": -130.44,
        "Latitude": 541710000,
        "Longitude": -1302641000,
        "Elevation (m)": 35.4,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE RUPERT AWOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066483",
        "Station ID": 27991,
        "WMO ID": 71898,
        "TC ID": "VPT",
        "Latitude (Decimal Degrees)": 54.29,
        "Longitude (Decimal Degrees)": -130.44,
        "Latitude": 541710000,
        "Longitude": -1302641000,
        "Elevation (m)": 35.4,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": 2004,
        "HLY Last Year": 2012,
        "DLY First Year": 2005,
        "DLY Last Year": 2012,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRINCE RUPERT FERN PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066484",
        "Station ID": 423,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.32,
        "Longitude (Decimal Degrees)": -130.27,
        "Latitude": 541900000,
        "Longitude": -1301600000,
        "Elevation (m)": 44.2,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "PRINCE RUPERT MONT CIRC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066488",
        "Station ID": 424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.32,
        "Longitude (Decimal Degrees)": -130.29,
        "Latitude": 541913000,
        "Longitude": -1301724000,
        "Elevation (m)": 60,
        "First Year": 1959,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2022,
        "MLY First Year": 1959,
        "MLY Last Year": 2004
      },
      {
        "Name": "PRINCE RUPERT R PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066492",
        "Station ID": 425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -130.33,
        "Latitude": 541800000,
        "Longitude": -1302000000,
        "Elevation (m)": 90.8,
        "First Year": 1959,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1997,
        "MLY First Year": 1959,
        "MLY Last Year": 1997
      },
      {
        "Name": "PRINCE RUPERT SHAWATLANS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066493",
        "Station ID": 426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.33,
        "Longitude (Decimal Degrees)": -130.25,
        "Latitude": 542000000,
        "Longitude": -1301500000,
        "Elevation (m)": 11,
        "First Year": 1966,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1999,
        "MLY First Year": 1966,
        "MLY Last Year": 1999
      },
      {
        "Name": "RIVERS INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066787",
        "Station ID": 427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -127.35,
        "Latitude": 514000000,
        "Longitude": -1272100000,
        "Elevation (m)": 6.1,
        "First Year": 1893,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1943,
        "MLY First Year": 1893,
        "MLY Last Year": 1943
      },
      {
        "Name": "RIVERS INLET GOOD HOPE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066788",
        "Station ID": 428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -127.5,
        "Latitude": 513600000,
        "Longitude": -1273000000,
        "Elevation (m)": 3,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "ROSSWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066885",
        "Station ID": 429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.78,
        "Longitude (Decimal Degrees)": -128.77,
        "Latitude": 544700000,
        "Longitude": -1284600000,
        "Elevation (m)": 146.3,
        "First Year": 1927,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1946,
        "MLY First Year": 1927,
        "MLY Last Year": 1946
      },
      {
        "Name": "ROY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066900",
        "Station ID": 430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -125.53,
        "Latitude": 503000000,
        "Longitude": -1253200000,
        "Elevation (m)": 10.4,
        "First Year": 1931,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1955,
        "MLY First Year": 1931,
        "MLY Last Year": 1955
      },
      {
        "Name": "PHILLIPS ARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066JFQ",
        "Station ID": 416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -125.37,
        "Latitude": 503300000,
        "Longitude": -1252200000,
        "Elevation (m)": 1.5,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "PORT EDWARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1066K57",
        "Station ID": 417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.2,
        "Longitude (Decimal Degrees)": -130.23,
        "Latitude": 541200000,
        "Longitude": -1301400000,
        "Elevation (m)": 2,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "SALVUS CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067005",
        "Station ID": 431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -129.37,
        "Latitude": 541800000,
        "Longitude": -1292200000,
        "Elevation (m)": 15.2,
        "First Year": 1975,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1988,
        "MLY First Year": 1975,
        "MLY Last Year": 1988
      },
      {
        "Name": "SHAWL BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067225",
        "Station ID": 432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.85,
        "Longitude (Decimal Degrees)": -126.57,
        "Latitude": 505100000,
        "Longitude": -1263400000,
        "Elevation (m)": null,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "STEWART",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067740",
        "Station ID": 433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.95,
        "Longitude (Decimal Degrees)": -129.98,
        "Latitude": 555700000,
        "Longitude": -1295900000,
        "Elevation (m)": 4.6,
        "First Year": 1910,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1967,
        "MLY First Year": 1910,
        "MLY Last Year": 1967
      },
      {
        "Name": "STEWART A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067741",
        "Station ID": 53422,
        "WMO ID": null,
        "TC ID": "ZST",
        "Latitude (Decimal Degrees)": 55.93,
        "Longitude (Decimal Degrees)": -129.98,
        "Latitude": 555600000,
        "Longitude": -1295900000,
        "Elevation (m)": 7.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STEWART A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067742",
        "Station ID": 434,
        "WMO ID": null,
        "TC ID": "ZST",
        "Latitude (Decimal Degrees)": 55.94,
        "Longitude (Decimal Degrees)": -129.99,
        "Latitude": 555610000,
        "Longitude": -1295906000,
        "Elevation (m)": 7.3,
        "First Year": 1974,
        "Last Year": 2016,
        "HLY First Year": 1974,
        "HLY Last Year": 2015,
        "DLY First Year": 1974,
        "DLY Last Year": 2016,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "STEWART BCHPA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067745",
        "Station ID": 435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.95,
        "Longitude (Decimal Degrees)": -129.98,
        "Latitude": 555700000,
        "Longitude": -1295900000,
        "Elevation (m)": 12.2,
        "First Year": 1967,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1976,
        "MLY First Year": 1967,
        "MLY Last Year": 1976
      },
      {
        "Name": "STEWART A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067748",
        "Station ID": 53438,
        "WMO ID": null,
        "TC ID": "ZST",
        "Latitude (Decimal Degrees)": 55.93,
        "Longitude (Decimal Degrees)": -129.98,
        "Latitude": 555600000,
        "Longitude": -1295900000,
        "Elevation (m)": 7.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STUIE TWEEDSMUIR LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067776",
        "Station ID": 436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -126.07,
        "Latitude": 522205000,
        "Longitude": -1260402000,
        "Elevation (m)": 162,
        "First Year": 1932,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 2013,
        "MLY First Year": 1932,
        "MLY Last Year": 2007
      },
      {
        "Name": "SURF INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067868",
        "Station ID": 437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -129.03,
        "Latitude": 522800000,
        "Longitude": -1290200000,
        "Elevation (m)": null,
        "First Year": 1916,
        "Last Year": 1926,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1926,
        "MLY First Year": 1916,
        "MLY Last Year": 1926
      },
      {
        "Name": "SWANSON BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067880",
        "Station ID": 438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -128.5,
        "Latitude": 530200000,
        "Longitude": -1283000000,
        "Elevation (m)": 4.6,
        "First Year": 1917,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1942,
        "MLY First Year": 1917,
        "MLY Last Year": 1942
      },
      {
        "Name": "SWANSON BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1067881",
        "Station ID": 439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -126.88,
        "Latitude": 503700000,
        "Longitude": -1265300000,
        "Elevation (m)": null,
        "First Year": 1907,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1913,
        "MLY First Year": 1907,
        "MLY Last Year": 1913
      },
      {
        "Name": "TERRACE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068100",
        "Station ID": 440,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.52,
        "Longitude (Decimal Degrees)": -128.58,
        "Latitude": 543100000,
        "Longitude": -1283500000,
        "Elevation (m)": null,
        "First Year": 1912,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1953,
        "MLY First Year": 1912,
        "MLY Last Year": 1953
      },
      {
        "Name": "TERRACE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068130",
        "Station ID": 441,
        "WMO ID": 71951,
        "TC ID": "YXT",
        "Latitude (Decimal Degrees)": 54.47,
        "Longitude (Decimal Degrees)": -128.58,
        "Latitude": 542759000,
        "Longitude": -1283439000,
        "Elevation (m)": 217.3,
        "First Year": 1953,
        "Last Year": 2013,
        "HLY First Year": 1955,
        "HLY Last Year": 2013,
        "DLY First Year": 1953,
        "DLY Last Year": 2013,
        "MLY First Year": 1953,
        "MLY Last Year": 2013
      },
      {
        "Name": "TERRACE PCC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068131",
        "Station ID": 442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.5,
        "Longitude (Decimal Degrees)": -128.62,
        "Latitude": 543007000,
        "Longitude": -1283729000,
        "Elevation (m)": 67,
        "First Year": 1968,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2022,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "TERRACE SKEENA BRIDGE CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068133",
        "Station ID": 10750,
        "WMO ID": null,
        "TC ID": "WSX",
        "Latitude (Decimal Degrees)": 54.52,
        "Longitude (Decimal Degrees)": -128.57,
        "Latitude": 543100000,
        "Longitude": -1283400000,
        "Elevation (m)": 210,
        "First Year": 1999,
        "Last Year": 2002,
        "HLY First Year": 1999,
        "HLY Last Year": 2002,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TERRACE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068134",
        "Station ID": 51037,
        "WMO ID": 71951,
        "TC ID": "YXT",
        "Latitude (Decimal Degrees)": 54.47,
        "Longitude (Decimal Degrees)": -128.58,
        "Latitude": 542807000,
        "Longitude": -1283442000,
        "Elevation (m)": 217.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THOMPSON SOUND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068159",
        "Station ID": 443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -126,
        "Latitude": 504800000,
        "Longitude": -1260000000,
        "Elevation (m)": 12.2,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "THURSTON BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068170",
        "Station ID": 444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -125.42,
        "Latitude": 502500000,
        "Longitude": -1252500000,
        "Elevation (m)": 1.5,
        "First Year": 1923,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1938,
        "MLY First Year": 1923,
        "MLY Last Year": 1938
      },
      {
        "Name": "TOBA INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068201",
        "Station ID": 445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -124.27,
        "Latitude": 503100000,
        "Longitude": -1241600000,
        "Elevation (m)": 27.4,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "TRIPLE ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068250",
        "Station ID": 446,
        "WMO ID": null,
        "TC ID": "WTI",
        "Latitude (Decimal Degrees)": 54.29,
        "Longitude (Decimal Degrees)": -130.88,
        "Latitude": 541740800,
        "Longitude": -1305249100,
        "Elevation (m)": 20.7,
        "First Year": 1953,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2001,
        "DLY First Year": 1989,
        "DLY Last Year": 2022,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "WANNOCK RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1068677",
        "Station ID": 447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -127.25,
        "Latitude": 514100000,
        "Longitude": -1271500000,
        "Elevation (m)": 3,
        "First Year": 1974,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1987,
        "MLY First Year": 1974,
        "MLY Last Year": 1987
      },
      {
        "Name": "FORWARD HARBOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "106C0C5",
        "Station ID": 371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -125.7,
        "Latitude": 502900000,
        "Longitude": -1254200000,
        "Elevation (m)": 7.6,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1975,
        "MLY Last Year": 1978
      },
      {
        "Name": "HELMCKEN ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "106CDL0",
        "Station ID": 9818,
        "WMO ID": null,
        "TC ID": "WXM",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -125.87,
        "Latitude": 502400000,
        "Longitude": -1255200000,
        "Elevation (m)": 18.9,
        "First Year": 1994,
        "Last Year": 2000,
        "HLY First Year": 1994,
        "HLY Last Year": 2000,
        "DLY First Year": 1994,
        "DLY Last Year": 2000,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KITIMAT HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "106D289",
        "Station ID": 27121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.04,
        "Longitude (Decimal Degrees)": -128.68,
        "Latitude": 540237000,
        "Longitude": -1284056000,
        "Elevation (m)": 11,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "TERRACE GOSSAN CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "106H130",
        "Station ID": 372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.58,
        "Longitude (Decimal Degrees)": -128.43,
        "Latitude": 543500000,
        "Longitude": -1282600000,
        "Elevation (m)": 107,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "NANAKWA SHOALS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "106NLPJ",
        "Station ID": 9817,
        "WMO ID": 46181,
        "TC ID": "WCB",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -128.83,
        "Latitude": 535000000,
        "Longitude": -1284954000,
        "Elevation (m)": null,
        "First Year": 1986,
        "Last Year": 1986,
        "HLY First Year": 1986,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WADHAMS CDF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "106Q669",
        "Station ID": 373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -127.5,
        "Latitude": 513000000,
        "Longitude": -1273000000,
        "Elevation (m)": 6.1,
        "First Year": 1967,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1973,
        "MLY First Year": 1967,
        "MLY Last Year": 1973
      },
      {
        "Name": "AIYANSH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1070150",
        "Station ID": 452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.23,
        "Longitude (Decimal Degrees)": -129.02,
        "Latitude": 551400000,
        "Longitude": -1290100000,
        "Elevation (m)": 228.6,
        "First Year": 1924,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1971,
        "MLY First Year": 1924,
        "MLY Last Year": 1971
      },
      {
        "Name": "AIYANSH 2SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1070154",
        "Station ID": 453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.18,
        "Longitude (Decimal Degrees)": -129.05,
        "Latitude": 551100000,
        "Longitude": -1290300000,
        "Elevation (m)": 213.4,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "BABINE LAKE FISHERIES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1070571",
        "Station ID": 454,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.07,
        "Longitude (Decimal Degrees)": -126.53,
        "Latitude": 550400000,
        "Longitude": -1263200000,
        "Elevation (m)": 713.2,
        "First Year": 1912,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1936,
        "MLY First Year": 1912,
        "MLY Last Year": 1936
      },
      {
        "Name": "BABINE LAKE HALIFAX BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1070572",
        "Station ID": 455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.05,
        "Longitude (Decimal Degrees)": -126.48,
        "Latitude": 550300000,
        "Longitude": -1262900000,
        "Elevation (m)": 711.7,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "BABINE LAKE PINKUT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1070573",
        "Station ID": 456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.45,
        "Longitude (Decimal Degrees)": -125.46,
        "Latitude": 542647000,
        "Longitude": -1252735000,
        "Elevation (m)": 713.2,
        "First Year": 1968,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2012,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "BIG OLIVER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1070873",
        "Station ID": 457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.83,
        "Longitude (Decimal Degrees)": -128.3,
        "Latitude": 545000000,
        "Longitude": -1281800000,
        "Elevation (m)": 121.9,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "BRUCEJACK LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1071092",
        "Station ID": 458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.47,
        "Longitude (Decimal Degrees)": -130.17,
        "Latitude": 562800000,
        "Longitude": -1301000000,
        "Elevation (m)": 1372,
        "First Year": 1988,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "COLLINS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1071785",
        "Station ID": 459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -127.17,
        "Latitude": 540700000,
        "Longitude": -1271000000,
        "Elevation (m)": 836.7,
        "First Year": 1958,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "CRANBERRY RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1072075",
        "Station ID": 460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.5,
        "Longitude (Decimal Degrees)": -128.23,
        "Latitude": 553000000,
        "Longitude": -1281400000,
        "Elevation (m)": 609.6,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "EQUITY SILVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1072692",
        "Station ID": 461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.2,
        "Longitude (Decimal Degrees)": -126.28,
        "Latitude": 541155000,
        "Longitude": -1261635700,
        "Elevation (m)": 1280,
        "First Year": 1981,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2021,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT BABINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1072902",
        "Station ID": 462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.32,
        "Longitude (Decimal Degrees)": -126.62,
        "Latitude": 551900000,
        "Longitude": -1263700000,
        "Elevation (m)": 716.3,
        "First Year": 1944,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1975,
        "MLY First Year": 1944,
        "MLY Last Year": 1975
      },
      {
        "Name": "FULTON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073040",
        "Station ID": 464,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.8,
        "Longitude (Decimal Degrees)": -126.3,
        "Latitude": 544800000,
        "Longitude": -1261800000,
        "Elevation (m)": 734.6,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "GRANISLE COPPER LTD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073278",
        "Station ID": 465,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.95,
        "Longitude (Decimal Degrees)": -126.17,
        "Latitude": 545700000,
        "Longitude": -1261000000,
        "Elevation (m)": 705.6,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "HAZELTON TEMLEHAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073347",
        "Station ID": 466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.2,
        "Longitude (Decimal Degrees)": -127.73,
        "Latitude": 551200000,
        "Longitude": -1274400000,
        "Elevation (m)": 121.9,
        "First Year": 1973,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1997,
        "MLY First Year": 1973,
        "MLY Last Year": 1997
      },
      {
        "Name": "HAZELTON 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073349",
        "Station ID": 467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.27,
        "Longitude (Decimal Degrees)": -127.63,
        "Latitude": 551600000,
        "Longitude": -1273800000,
        "Elevation (m)": 295,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1991,
        "MLY First Year": 1988,
        "MLY Last Year": 1991
      },
      {
        "Name": "HAZELTON TRIPLE CREEK RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "107335J",
        "Station ID": 468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.27,
        "Longitude (Decimal Degrees)": -127.52,
        "Latitude": 551600000,
        "Longitude": -1273100000,
        "Elevation (m)": 365.8,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "HOUSTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073612",
        "Station ID": 469,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.38,
        "Longitude (Decimal Degrees)": -126.72,
        "Latitude": 542300000,
        "Longitude": -1264300000,
        "Elevation (m)": 586.7,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "HOUSTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073615",
        "Station ID": 470,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -126.67,
        "Latitude": 542347000,
        "Longitude": -1264004000,
        "Elevation (m)": 610,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOUSTON GOOSLY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073616",
        "Station ID": 471,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.15,
        "Longitude (Decimal Degrees)": -126.37,
        "Latitude": 540900000,
        "Longitude": -1262200000,
        "Elevation (m)": 952.5,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "HAZELTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1073L4F",
        "Station ID": 463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.27,
        "Longitude (Decimal Degrees)": -127.62,
        "Latitude": 551600000,
        "Longitude": -1273700000,
        "Elevation (m)": 320,
        "First Year": 1981,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1991,
        "MLY First Year": 1981,
        "MLY Last Year": 1991
      },
      {
        "Name": "KISPIOX",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1074276",
        "Station ID": 472,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.47,
        "Longitude (Decimal Degrees)": -127.77,
        "Latitude": 552800000,
        "Longitude": -1274600000,
        "Elevation (m)": 304.8,
        "First Year": 1976,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1980,
        "MLY First Year": 1976,
        "MLY Last Year": 1980
      },
      {
        "Name": "KITSAULT MILLSITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1074328",
        "Station ID": 473,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.42,
        "Longitude (Decimal Degrees)": -128.42,
        "Latitude": 552500000,
        "Longitude": -1282500000,
        "Elevation (m)": 533.4,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "KITSAULT MINESITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1074330",
        "Station ID": 474,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.5,
        "Longitude (Decimal Degrees)": -129,
        "Latitude": 553000000,
        "Longitude": -1290000000,
        "Elevation (m)": 652.3,
        "First Year": 1969,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1972
      },
      {
        "Name": "KITSAULT TOWNSITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1074331",
        "Station ID": 475,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.45,
        "Longitude (Decimal Degrees)": -128.48,
        "Latitude": 552700000,
        "Longitude": -1282900000,
        "Elevation (m)": 38.1,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "KITWANGA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1074336",
        "Station ID": 476,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.12,
        "Longitude (Decimal Degrees)": -128.05,
        "Latitude": 550700000,
        "Longitude": -1280300000,
        "Elevation (m)": 192.9,
        "First Year": 1973,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1979,
        "MLY First Year": 1973,
        "MLY Last Year": 1979
      },
      {
        "Name": "MACLURE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1074800",
        "Station ID": 477,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -127.07,
        "Latitude": 544400000,
        "Longitude": -1270400000,
        "Elevation (m)": 573,
        "First Year": 1924,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1963,
        "MLY First Year": 1924,
        "MLY Last Year": 1963
      },
      {
        "Name": "MORICE RIVER FDR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075199",
        "Station ID": 478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.2,
        "Longitude (Decimal Degrees)": -126.9,
        "Latitude": 541200000,
        "Longitude": -1265400000,
        "Elevation (m)": 701,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "MORICE RIVER HOUSTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075200",
        "Station ID": 479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.32,
        "Longitude (Decimal Degrees)": -126.8,
        "Latitude": 541900000,
        "Longitude": -1264800000,
        "Elevation (m)": 693.4,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "MORICETOWN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075202",
        "Station ID": 480,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.05,
        "Longitude (Decimal Degrees)": -127.3,
        "Latitude": 550300000,
        "Longitude": -1271800000,
        "Elevation (m)": 390.1,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "MURDER CREEK 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075252",
        "Station ID": 27648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.49,
        "Longitude (Decimal Degrees)": -127.83,
        "Latitude": 552929000,
        "Longitude": -1275002000,
        "Elevation (m)": 368,
        "First Year": 1998,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2004,
        "MLY First Year": 1998,
        "MLY Last Year": 2004
      },
      {
        "Name": "MURDER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075253",
        "Station ID": 481,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.52,
        "Longitude (Decimal Degrees)": -127.8,
        "Latitude": 553100000,
        "Longitude": -1274800000,
        "Elevation (m)": 245,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "NASS CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075384",
        "Station ID": 482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.24,
        "Longitude (Decimal Degrees)": -129.03,
        "Latitude": 551415000,
        "Longitude": -1290147004,
        "Elevation (m)": 195,
        "First Year": 1973,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2017,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "NASS RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075385",
        "Station ID": 483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.42,
        "Longitude (Decimal Degrees)": -129.02,
        "Latitude": 552500000,
        "Longitude": -1290100000,
        "Elevation (m)": 1585,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "NEW HAZELTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1075520",
        "Station ID": 484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.23,
        "Longitude (Decimal Degrees)": -127.6,
        "Latitude": 551400000,
        "Longitude": -1273600000,
        "Elevation (m)": 313.9,
        "First Year": 1914,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1976,
        "MLY First Year": 1914,
        "MLY Last Year": 1976
      },
      {
        "Name": "QUICK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1076638",
        "Station ID": 485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.62,
        "Longitude (Decimal Degrees)": -126.9,
        "Latitude": 543700000,
        "Longitude": -1265400000,
        "Elevation (m)": 533.4,
        "First Year": 1962,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1995,
        "MLY First Year": 1962,
        "MLY Last Year": 1995
      },
      {
        "Name": "ROSSWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1076886",
        "Station ID": 486,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.85,
        "Longitude (Decimal Degrees)": -128.8,
        "Latitude": 545100000,
        "Longitude": -1284800000,
        "Elevation (m)": 182.9,
        "First Year": 1973,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1993,
        "MLY First Year": 1973,
        "MLY Last Year": 1993
      },
      {
        "Name": "SMITHERS A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1077498",
        "Station ID": 50130,
        "WMO ID": null,
        "TC ID": "YYD",
        "Latitude (Decimal Degrees)": 54.83,
        "Longitude (Decimal Degrees)": -127.18,
        "Latitude": 544931000,
        "Longitude": -1271058000,
        "Elevation (m)": 523,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SMITHERS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1077499",
        "Station ID": 48628,
        "WMO ID": 71950,
        "TC ID": "YYD",
        "Latitude (Decimal Degrees)": 54.83,
        "Longitude (Decimal Degrees)": -127.18,
        "Latitude": 544931000,
        "Longitude": -1271058000,
        "Elevation (m)": 523,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SMITHERS A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1077500",
        "Station ID": 487,
        "WMO ID": null,
        "TC ID": "YYD",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -127.18,
        "Latitude": 544929000,
        "Longitude": -1271058000,
        "Elevation (m)": 521.8,
        "First Year": 1942,
        "Last Year": 2018,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1942,
        "DLY Last Year": 2018,
        "MLY First Year": 1942,
        "MLY Last Year": 2010
      },
      {
        "Name": "SMITHERS AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1077501",
        "Station ID": 51658,
        "WMO ID": 71268,
        "TC ID": "VYD",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -127.19,
        "Latitude": 544927000,
        "Longitude": -1271122000,
        "Elevation (m)": 517.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SMITHERS CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1077505",
        "Station ID": 488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -127.1,
        "Latitude": 544400000,
        "Longitude": -1270600000,
        "Elevation (m)": 515.1,
        "First Year": 1938,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1968,
        "MLY First Year": 1938,
        "MLY Last Year": 1968
      },
      {
        "Name": "SMITHERS 4E",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1077508",
        "Station ID": 489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.78,
        "Longitude (Decimal Degrees)": -127.08,
        "Latitude": 544700000,
        "Longitude": -1270500000,
        "Elevation (m)": 577.6,
        "First Year": 1969,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1975,
        "MLY First Year": 1969,
        "MLY Last Year": 1975
      },
      {
        "Name": "TELKWA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078070",
        "Station ID": 491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.65,
        "Longitude (Decimal Degrees)": -126.83,
        "Latitude": 543900000,
        "Longitude": -1265000000,
        "Elevation (m)": 682.8,
        "First Year": 1922,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1968,
        "MLY First Year": 1922,
        "MLY Last Year": 1968
      },
      {
        "Name": "TELKWA COAL MINE RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078072",
        "Station ID": 492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.65,
        "Longitude (Decimal Degrees)": -127.12,
        "Latitude": 543900000,
        "Longitude": -1270700000,
        "Elevation (m)": 670,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "TELKWA MACLURE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078074",
        "Station ID": 493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -127.02,
        "Latitude": 544400000,
        "Longitude": -1270100000,
        "Elevation (m)": 640.1,
        "First Year": 1964,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1974,
        "MLY First Year": 1964,
        "MLY Last Year": 1974
      },
      {
        "Name": "TELKWA ROUND LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078080",
        "Station ID": 494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.67,
        "Longitude (Decimal Degrees)": -126.92,
        "Latitude": 544000000,
        "Longitude": -1265500000,
        "Elevation (m)": 579.1,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "TELKWA WOODMERE RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078086",
        "Station ID": 495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -126.98,
        "Latitude": 544100000,
        "Longitude": -1265900000,
        "Elevation (m)": 854,
        "First Year": 1982,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1984,
        "MLY First Year": 1982,
        "MLY Last Year": 1984
      },
      {
        "Name": "TOPLEY LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078209",
        "Station ID": 496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -126.16,
        "Latitude": 544857000,
        "Longitude": -1260947000,
        "Elevation (m)": 722,
        "First Year": 1962,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2017,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "UNUK RIVER ESKAY CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1078L3D",
        "Station ID": 490,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.65,
        "Longitude (Decimal Degrees)": -130.45,
        "Latitude": 563909000,
        "Longitude": -1302646000,
        "Elevation (m)": 887,
        "First Year": 1989,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2010,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "CEDARVALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "107ADFE",
        "Station ID": 448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.02,
        "Longitude (Decimal Degrees)": -128.32,
        "Latitude": 550100000,
        "Longitude": -1281900000,
        "Elevation (m)": 152,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "SUSKWA VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "107G879",
        "Station ID": 450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.29,
        "Longitude (Decimal Degrees)": -127.17,
        "Latitude": 551719000,
        "Longitude": -1271014000,
        "Elevation (m)": 534,
        "First Year": 1982,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2022,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUSTUT MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "107GHG9",
        "Station ID": 449,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.62,
        "Longitude (Decimal Degrees)": -126.68,
        "Latitude": 563700000,
        "Longitude": -1264100000,
        "Elevation (m)": 1798.3,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "SMITHERS KATHLYN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "107P505",
        "Station ID": 451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -127.22,
        "Latitude": 544900000,
        "Longitude": -1271300000,
        "Elevation (m)": 546,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "ALEXIS CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080283",
        "Station ID": 499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -123.62,
        "Latitude": 520600000,
        "Longitude": -1233700000,
        "Elevation (m)": 853.4,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "ALEXIS CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080284",
        "Station ID": 500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -123.28,
        "Latitude": 520500000,
        "Longitude": -1231700000,
        "Elevation (m)": 800.1,
        "First Year": 1973,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1995,
        "MLY First Year": 1973,
        "MLY Last Year": 1995
      },
      {
        "Name": "ALEXIS CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080285",
        "Station ID": 501,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -123.33,
        "Latitude": 520800000,
        "Longitude": -1232000000,
        "Elevation (m)": 723.9,
        "First Year": 1919,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1963,
        "MLY First Year": 1919,
        "MLY Last Year": 1963
      },
      {
        "Name": "ALEXIS CREEK TAUTRI CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080289",
        "Station ID": 502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.55,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 523300000,
        "Longitude": -1231100000,
        "Elevation (m)": 1219.2,
        "First Year": 1961,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1983,
        "MLY First Year": 1961,
        "MLY Last Year": 1983
      },
      {
        "Name": "ATNARKO PARKS CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080562",
        "Station ID": 506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -125.92,
        "Latitude": 522500000,
        "Longitude": -1255500000,
        "Elevation (m)": 289.9,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "BIG CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080870",
        "Station ID": 507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 514002005,
        "Longitude": -1230423005,
        "Elevation (m)": 1175,
        "First Year": 1893,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1998,
        "MLY First Year": 1893,
        "MLY Last Year": 1998
      },
      {
        "Name": "BIG CREEK (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10808G0",
        "Station ID": 10811,
        "WMO ID": 71760,
        "TC ID": "WBG",
        "Latitude (Decimal Degrees)": 51.26,
        "Longitude (Decimal Degrees)": -123.09,
        "Latitude": 511537000,
        "Longitude": -1230533000,
        "Elevation (m)": 1670,
        "First Year": 1994,
        "Last Year": 2011,
        "HLY First Year": 1994,
        "HLY Last Year": 2003,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BRALORNE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080930",
        "Station ID": 508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -122.82,
        "Latitude": 504700000,
        "Longitude": -1224900000,
        "Elevation (m)": 1015,
        "First Year": 1924,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1963,
        "MLY First Year": 1924,
        "MLY Last Year": 1963
      },
      {
        "Name": "BLACKDOME MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1080QQ0",
        "Station ID": 498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -122.5,
        "Latitude": 511900000,
        "Longitude": -1223000000,
        "Elevation (m)": 1898,
        "First Year": 1986,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1991,
        "MLY First Year": 1986,
        "MLY Last Year": 1991
      },
      {
        "Name": "CHARLIE CREEK RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1081469",
        "Station ID": 510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -124.47,
        "Latitude": 514500000,
        "Longitude": -1242800000,
        "Elevation (m)": 1158.2,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "CHILKO LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1081515",
        "Station ID": 511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.62,
        "Longitude (Decimal Degrees)": -124.15,
        "Latitude": 513700000,
        "Longitude": -1240900000,
        "Elevation (m)": 1172,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "COLLEYMOUNT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1081788",
        "Station ID": 512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -126.13,
        "Latitude": 540100000,
        "Longitude": -1260800000,
        "Elevation (m)": 731.5,
        "First Year": 1981,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1996,
        "MLY First Year": 1981,
        "MLY Last Year": 1996
      },
      {
        "Name": "CHARLOTTE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1081D6R",
        "Station ID": 509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -125.28,
        "Latitude": 520900000,
        "Longitude": -1251700000,
        "Elevation (m)": 1174.1,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "ELKINS CREEK RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1082654",
        "Station ID": 513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -123.78,
        "Latitude": 513000000,
        "Longitude": -1234700000,
        "Elevation (m)": 1225.3,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "EUCHINIKO TITETOWN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1082748",
        "Station ID": 514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -123.82,
        "Latitude": 532000000,
        "Longitude": -1234900000,
        "Elevation (m)": 795.5,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "GILLIES CROSSING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1083157",
        "Station ID": 515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -123.6,
        "Latitude": 531400000,
        "Longitude": -1233600000,
        "Elevation (m)": 838.2,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "GRASSY PLAINS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1083285",
        "Station ID": 516,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -125.92,
        "Latitude": 535500000,
        "Longitude": -1255500000,
        "Elevation (m)": 868.7,
        "First Year": 1957,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1976,
        "MLY First Year": 1957,
        "MLY Last Year": 1976
      },
      {
        "Name": "HAVEN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1083344",
        "Station ID": 517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -127.02,
        "Latitude": 531700000,
        "Longitude": -1270100000,
        "Elevation (m)": 1036.3,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "KEMANO KILDALA PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1084050",
        "Station ID": 518,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -128.1,
        "Latitude": 534300000,
        "Longitude": -1280600000,
        "Elevation (m)": 1609.3,
        "First Year": 1952,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1959,
        "MLY First Year": 1953,
        "MLY Last Year": 1959
      },
      {
        "Name": "KLEENA KLEENE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1084349",
        "Station ID": 519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.05,
        "Longitude (Decimal Degrees)": -124.9,
        "Latitude": 520300000,
        "Longitude": -1245400000,
        "Elevation (m)": 823,
        "First Year": 1915,
        "Last Year": 1920,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1920,
        "MLY First Year": 1915,
        "MLY Last Year": 1920
      },
      {
        "Name": "KLEENA KLEENE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1084350",
        "Station ID": 520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -124.93,
        "Latitude": 515900000,
        "Longitude": -1245600000,
        "Elevation (m)": 899.2,
        "First Year": 1942,
        "Last Year": 1968,
        "HLY First Year": 1953,
        "HLY Last Year": 1968,
        "DLY First Year": 1942,
        "DLY Last Year": 1968,
        "MLY First Year": 1942,
        "MLY Last Year": 1968
      },
      {
        "Name": "KLEENA KLEENE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1084351",
        "Station ID": 521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -124.99,
        "Latitude": 515840000,
        "Longitude": -1245925000,
        "Elevation (m)": 914.1,
        "First Year": 1980,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2006,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "LAJOIE DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1084490",
        "Station ID": 522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -122.87,
        "Latitude": 505000000,
        "Longitude": -1225200000,
        "Elevation (m)": 685.8,
        "First Year": 1963,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1982,
        "MLY First Year": 1963,
        "MLY Last Year": 1982
      },
      {
        "Name": "LUNCH LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1084731",
        "Station ID": 523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.82,
        "Longitude (Decimal Degrees)": -124.47,
        "Latitude": 514928000,
        "Longitude": -1242759500,
        "Elevation (m)": 1017,
        "First Year": 1980,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2009,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "MEAGER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085064",
        "Station ID": 524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -123.4,
        "Latitude": 503800000,
        "Longitude": -1232400000,
        "Elevation (m)": 430.1,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "MOSLEY CREEK SAND CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085208",
        "Station ID": 525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -125,
        "Latitude": 513600000,
        "Longitude": -1250000000,
        "Elevation (m)": 759.9,
        "First Year": 1980,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1995,
        "MLY First Year": 1980,
        "MLY Last Year": 1995
      },
      {
        "Name": "NADINA RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085279",
        "Station ID": 526,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.17,
        "Longitude (Decimal Degrees)": -126.67,
        "Latitude": 541000000,
        "Longitude": -1264000000,
        "Elevation (m)": 807.7,
        "First Year": 1931,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1932,
        "MLY First Year": 1931,
        "MLY Last Year": 1932
      },
      {
        "Name": "NADINA RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085280",
        "Station ID": 527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -126.38,
        "Latitude": 535900000,
        "Longitude": -1262300000,
        "Elevation (m)": 731.5,
        "First Year": 1934,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1962,
        "MLY First Year": 1934,
        "MLY Last Year": 1962
      },
      {
        "Name": "NADINA RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085281",
        "Station ID": 528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.9,
        "Longitude (Decimal Degrees)": -126.95,
        "Latitude": 535400000,
        "Longitude": -1265700000,
        "Elevation (m)": 914.1,
        "First Year": 1981,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1988,
        "MLY First Year": 1981,
        "MLY Last Year": 1988
      },
      {
        "Name": "NANIKA RIVER HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085373",
        "Station ID": 529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.1,
        "Longitude (Decimal Degrees)": -127.45,
        "Latitude": 540600000,
        "Longitude": -1272700000,
        "Elevation (m)": 807.7,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "NAZKO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085414",
        "Station ID": 530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -123.62,
        "Latitude": 525500000,
        "Longitude": -1233700000,
        "Elevation (m)": 899.2,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "NECHAKO RIVER (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085415",
        "Station ID": 10709,
        "WMO ID": 71763,
        "TC ID": "WNX",
        "Latitude (Decimal Degrees)": 53.68,
        "Longitude (Decimal Degrees)": -124.83,
        "Latitude": 534100000,
        "Longitude": -1245000000,
        "Elevation (m)": 715,
        "First Year": 1994,
        "Last Year": 2002,
        "HLY First Year": 1994,
        "HLY Last Year": 2002,
        "DLY First Year": 1995,
        "DLY Last Year": 2002,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NEMAIA VALLEY CHILKO LK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085450",
        "Station ID": 531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -124.07,
        "Latitude": 512500000,
        "Longitude": -1240400000,
        "Elevation (m)": 1216.2,
        "First Year": 1974,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1979,
        "MLY First Year": 1974,
        "MLY Last Year": 1979
      },
      {
        "Name": "NIMPO LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085597",
        "Station ID": 532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -125.13,
        "Latitude": 522100000,
        "Longitude": -1250800000,
        "Elevation (m)": 1085.1,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "NIMPO LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085598",
        "Station ID": 533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -125.15,
        "Latitude": 522000000,
        "Longitude": -1250900000,
        "Elevation (m)": 1129.9,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "NORA LEE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085615",
        "Station ID": 534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -126.43,
        "Latitude": 535900000,
        "Longitude": -1262600000,
        "Elevation (m)": 792.5,
        "First Year": 1977,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1979,
        "MLY First Year": 1977,
        "MLY Last Year": 1979
      },
      {
        "Name": "OOTSA L SKINS L SPILLWAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085835",
        "Station ID": 535,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.77,
        "Longitude (Decimal Degrees)": -126,
        "Latitude": 534619800,
        "Longitude": -1255947600,
        "Elevation (m)": 861.1,
        "First Year": 1956,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2017,
        "MLY First Year": 1956,
        "MLY Last Year": 2007
      },
      {
        "Name": "OOTSA LAKESKINS LAKE CLIMATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1085836",
        "Station ID": 43963,
        "WMO ID": 71679,
        "TC ID": "VSL",
        "Latitude (Decimal Degrees)": 53.77,
        "Longitude (Decimal Degrees)": -126,
        "Latitude": 534618800,
        "Longitude": -1255948190,
        "Elevation (m)": 861,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEMBERTON AIRPORT (WIND)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086081",
        "Station ID": 46747,
        "WMO ID": null,
        "TC ID": "WPN",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -122.74,
        "Latitude": 501808000,
        "Longitude": -1224417000,
        "Elevation (m)": 203,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEMBERTON AIRPORT CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086082",
        "Station ID": 536,
        "WMO ID": 71777,
        "TC ID": "WGP",
        "Latitude (Decimal Degrees)": 50.31,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 501820326,
        "Longitude": -1224402720,
        "Elevation (m)": 204.31,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": 1988,
        "HLY Last Year": 2022,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2006
      },
      {
        "Name": "PEMBERTON BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086083",
        "Station ID": 537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.32,
        "Longitude (Decimal Degrees)": -122.82,
        "Latitude": 501900000,
        "Longitude": -1224900000,
        "Elevation (m)": 218.2,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1969,
        "MLY Last Year": 1984
      },
      {
        "Name": "PEMBERTON AIRPORT CWS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086085",
        "Station ID": 48308,
        "WMO ID": null,
        "TC ID": "YPS",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -122.74,
        "Latitude": 501809000,
        "Longitude": -1224416000,
        "Elevation (m)": 204.3,
        "First Year": 2010,
        "Last Year": 2010,
        "HLY First Year": 2010,
        "HLY Last Year": 2010,
        "DLY First Year": 2010,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEMBERTON MEADOWS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086090",
        "Station ID": 538,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 502700000,
        "Longitude": -1225600000,
        "Elevation (m)": 222.5,
        "First Year": 1912,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1971,
        "MLY First Year": 1912,
        "MLY Last Year": 1971
      },
      {
        "Name": "PEMBERTON MEADOWS 4NW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086093",
        "Station ID": 539,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 502800000,
        "Longitude": -1225600000,
        "Elevation (m)": 243.8,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "PEMBERTON WOLVERINE CR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086098",
        "Station ID": 540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -122.98,
        "Latitude": 503000000,
        "Longitude": -1225900000,
        "Elevation (m)": 271,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "PUNTCHESAKUT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086550",
        "Station ID": 541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.99,
        "Longitude (Decimal Degrees)": -122.96,
        "Latitude": 525930000,
        "Longitude": -1225719000,
        "Elevation (m)": 915,
        "First Year": 1973,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2008,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "PUNTZI MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086555",
        "Station ID": 542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -124.2,
        "Latitude": 521000000,
        "Longitude": -1241200000,
        "Elevation (m)": 1371.6,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "PUNTZI MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086556",
        "Station ID": 543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 520700000,
        "Longitude": -1240500000,
        "Elevation (m)": 909.5,
        "First Year": 1968,
        "Last Year": 1979,
        "HLY First Year": 1968,
        "HLY Last Year": 1979,
        "DLY First Year": 1968,
        "DLY Last Year": 1979,
        "MLY First Year": 1968,
        "MLY Last Year": 1979
      },
      {
        "Name": "PUNTZI MOUNTAIN (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086558",
        "Station ID": 8092,
        "WMO ID": 71050,
        "TC ID": "WPU",
        "Latitude (Decimal Degrees)": 52.11,
        "Longitude (Decimal Degrees)": -124.14,
        "Latitude": 520652000,
        "Longitude": -1240808000,
        "Elevation (m)": 909.8,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "QUESNEL BAR HL RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086633",
        "Station ID": 544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 525000000,
        "Longitude": -1230500000,
        "Elevation (m)": 944.9,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "REDSTONE STUART RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1086695",
        "Station ID": 545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -123.7,
        "Latitude": 520900000,
        "Longitude": -1234200000,
        "Elevation (m)": 841.9,
        "First Year": 1980,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1991,
        "MLY First Year": 1980,
        "MLY Last Year": 1991
      },
      {
        "Name": "SOUTHBANK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1087600",
        "Station ID": 546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 540200000,
        "Longitude": -1254600000,
        "Elevation (m)": 730,
        "First Year": 1962,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1976,
        "MLY First Year": 1962,
        "MLY Last Year": 1976
      },
      {
        "Name": "TAHTSA LAKE WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1087950",
        "Station ID": 547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -127.7,
        "Latitude": 533700000,
        "Longitude": -1274200000,
        "Elevation (m)": 862.6,
        "First Year": 1951,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2000,
        "MLY First Year": 1951,
        "MLY Last Year": 2000
      },
      {
        "Name": "TAKYSIE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1087974",
        "Station ID": 548,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -125.87,
        "Latitude": 535221000,
        "Longitude": -1255213000,
        "Elevation (m)": 884,
        "First Year": 1987,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2007,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "TASEKO RIVER VICK CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1087998",
        "Station ID": 549,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -123.68,
        "Latitude": 513600000,
        "Longitude": -1234100000,
        "Elevation (m)": 1239.9,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "TATELKUZ LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088007",
        "Station ID": 550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.3,
        "Longitude (Decimal Degrees)": -124.73,
        "Latitude": 531800000,
        "Longitude": -1244400000,
        "Elevation (m)": 914.4,
        "First Year": 1970,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "TATLA LAKE BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088009",
        "Station ID": 551,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -124.6,
        "Latitude": 515400000,
        "Longitude": -1243600000,
        "Elevation (m)": 944.9,
        "First Year": 1973,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1984,
        "MLY First Year": 1973,
        "MLY Last Year": 1984
      },
      {
        "Name": "TATLAYOKO LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088010",
        "Station ID": 552,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -124.41,
        "Latitude": 514029000,
        "Longitude": -1242418000,
        "Elevation (m)": 870,
        "First Year": 1928,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 2005,
        "MLY First Year": 1928,
        "MLY Last Year": 2005
      },
      {
        "Name": "TATLAYOKO LAKE RCS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088015",
        "Station ID": 30494,
        "WMO ID": 71028,
        "TC ID": "XTL",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -124.4,
        "Latitude": 514028400,
        "Longitude": -1242411300,
        "Elevation (m)": 875,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "TSUNIAH LAKE LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088293",
        "Station ID": 553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -124.17,
        "Latitude": 513200000,
        "Longitude": -1241000000,
        "Elevation (m)": 1222.2,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "TYAUGHTON LAKE RESORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088326",
        "Station ID": 554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 505600000,
        "Longitude": -1224700000,
        "Elevation (m)": 1021.1,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "WISTARIA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1088970",
        "Station ID": 555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -126.21,
        "Latitude": 534930000,
        "Longitude": -1261252000,
        "Elevation (m)": 862.6,
        "First Year": 1926,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 2004,
        "MLY First Year": 1926,
        "MLY Last Year": 2004
      },
      {
        "Name": "HOUSTON MCBRIDE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "108CFAF",
        "Station ID": 497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.07,
        "Longitude (Decimal Degrees)": -127.37,
        "Latitude": 540400000,
        "Longitude": -1272200000,
        "Elevation (m)": 792.5,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "ALEZA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090300",
        "Station ID": 565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -122.07,
        "Latitude": 540700000,
        "Longitude": -1220400000,
        "Elevation (m)": 624.8,
        "First Year": 1952,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1980,
        "MLY First Year": 1952,
        "MLY Last Year": 1980
      },
      {
        "Name": "ALKALI LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090335",
        "Station ID": 566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -122.28,
        "Latitude": 514700000,
        "Longitude": -1221700000,
        "Elevation (m)": null,
        "First Year": 1910,
        "Last Year": 1922,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1922,
        "MLY First Year": 1910,
        "MLY Last Year": 1922
      },
      {
        "Name": "BALDY HUGHES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090586",
        "Station ID": 567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 533700000,
        "Longitude": -1225700000,
        "Elevation (m)": 1128.1,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "BARKERVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090660",
        "Station ID": 568,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.07,
        "Longitude (Decimal Degrees)": -121.51,
        "Latitude": 530409000,
        "Longitude": -1213053000,
        "Elevation (m)": 1283,
        "First Year": 1888,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 2022,
        "MLY First Year": 1888,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOSS MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090904",
        "Station ID": 569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -120.88,
        "Latitude": 520600000,
        "Longitude": -1205300000,
        "Elevation (m)": 1531.6,
        "First Year": 1967,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1984,
        "MLY First Year": 1967,
        "MLY Last Year": 1984
      },
      {
        "Name": "BRIDGE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090989",
        "Station ID": 570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.85,
        "Longitude (Decimal Degrees)": -122.43,
        "Latitude": 505100000,
        "Longitude": -1222600000,
        "Elevation (m)": 609.6,
        "First Year": 1924,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1929,
        "MLY First Year": 1924,
        "MLY Last Year": 1929
      },
      {
        "Name": "BRIDGE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090990",
        "Station ID": 571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -122.22,
        "Latitude": 504400000,
        "Longitude": -1221300000,
        "Elevation (m)": 253,
        "First Year": 1935,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1953,
        "MLY First Year": 1935,
        "MLY Last Year": 1953
      },
      {
        "Name": "BOWRON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1090R0R",
        "Station ID": 564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -121.45,
        "Latitude": 531700000,
        "Longitude": -1212700000,
        "Elevation (m)": 944.9,
        "First Year": 1974,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1990,
        "MLY First Year": 1974,
        "MLY Last Year": 1990
      },
      {
        "Name": "BURNS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091169",
        "Station ID": 572,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.23,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 541400000,
        "Longitude": -1254600000,
        "Elevation (m)": 704.1,
        "First Year": 1969,
        "Last Year": 1990,
        "HLY First Year": 1969,
        "HLY Last Year": 1990,
        "DLY First Year": 1969,
        "DLY Last Year": 1990,
        "MLY First Year": 1969,
        "MLY Last Year": 1990
      },
      {
        "Name": "BURNS LAKE DECKER LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091170",
        "Station ID": 573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.25,
        "Longitude (Decimal Degrees)": -125.8,
        "Latitude": 541500000,
        "Longitude": -1254800000,
        "Elevation (m)": 707.1,
        "First Year": 1949,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1973,
        "MLY First Year": 1949,
        "MLY Last Year": 1973
      },
      {
        "Name": "BURNS LAKE DECKER LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091174",
        "Station ID": 574,
        "WMO ID": 71952,
        "TC ID": "WPZ",
        "Latitude (Decimal Degrees)": 54.38,
        "Longitude (Decimal Degrees)": -125.96,
        "Latitude": 542259400,
        "Longitude": -1255731200,
        "Elevation (m)": 713.2,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": 1990,
        "HLY Last Year": 2022,
        "DLY First Year": 1990,
        "DLY Last Year": 2022,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "BURNS LAKE 10 SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091175",
        "Station ID": 6825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.15,
        "Longitude (Decimal Degrees)": -125.73,
        "Latitude": 540900000,
        "Longitude": -1254400000,
        "Elevation (m)": 783,
        "First Year": 1992,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1994,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "CAMILLE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091235",
        "Station ID": 575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -121.97,
        "Latitude": 522500000,
        "Longitude": -1215800000,
        "Elevation (m)": 857,
        "First Year": 1980,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1997,
        "MLY First Year": 1980,
        "MLY Last Year": 1997
      },
      {
        "Name": "CANIM LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091305",
        "Station ID": 576,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -120.78,
        "Latitude": 515400000,
        "Longitude": -1204700000,
        "Elevation (m)": 944.9,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "CHIEF LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1091512",
        "Station ID": 577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.1,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 540600000,
        "Longitude": -1230400000,
        "Elevation (m)": 792.5,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "CRESCENT SPUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092120",
        "Station ID": 26989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -120.7,
        "Latitude": 533600000,
        "Longitude": -1204200000,
        "Elevation (m)": 686,
        "First Year": 1994,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2016,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "DOG CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092489",
        "Station ID": 578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -122.25,
        "Latitude": 513500000,
        "Longitude": -1221500000,
        "Elevation (m)": 655.3,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "DOG CREEK A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092490",
        "Station ID": 579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -122.25,
        "Latitude": 513800000,
        "Longitude": -1221500000,
        "Elevation (m)": 1027.2,
        "First Year": 1944,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1960,
        "MLY First Year": 1944,
        "MLY Last Year": 1960
      },
      {
        "Name": "DOME CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092519",
        "Station ID": 580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.75,
        "Longitude (Decimal Degrees)": -121.17,
        "Latitude": 534500000,
        "Longitude": -1211000000,
        "Elevation (m)": 670.6,
        "First Year": 1917,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1951,
        "MLY First Year": 1918,
        "MLY Last Year": 1951
      },
      {
        "Name": "DOME CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092520",
        "Station ID": 581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.73,
        "Longitude (Decimal Degrees)": -120.98,
        "Latitude": 534400000,
        "Longitude": -1205900000,
        "Elevation (m)": 647.7,
        "First Year": 1970,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1995,
        "MLY First Year": 1970,
        "MLY Last Year": 1995
      },
      {
        "Name": "DUNSTER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092578",
        "Station ID": 582,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.13,
        "Longitude (Decimal Degrees)": -119.85,
        "Latitude": 530800000,
        "Longitude": -1195100000,
        "Elevation (m)": 725.4,
        "First Year": 1974,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1995,
        "MLY First Year": 1974,
        "MLY Last Year": 1995
      },
      {
        "Name": "DUNSTER 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092579",
        "Station ID": 27008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.17,
        "Longitude (Decimal Degrees)": -119.83,
        "Latitude": 531000000,
        "Longitude": -1195000000,
        "Elevation (m)": 833,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1997,
        "MLY First Year": 1994,
        "MLY Last Year": 1997
      },
      {
        "Name": "ENDAKO MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092676",
        "Station ID": 583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -125.1,
        "Latitude": 540200000,
        "Longitude": -1250600000,
        "Elevation (m)": 984.5,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "ENDAKO SAVORY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092678",
        "Station ID": 584,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.1,
        "Longitude (Decimal Degrees)": -125.17,
        "Latitude": 540600000,
        "Longitude": -1251000000,
        "Elevation (m)": 688.8,
        "First Year": 1961,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1967,
        "MLY First Year": 1961,
        "MLY Last Year": 1967
      },
      {
        "Name": "ENGEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092685",
        "Station ID": 585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -124.22,
        "Latitude": 540200000,
        "Longitude": -1241300000,
        "Elevation (m)": 705.9,
        "First Year": 1957,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1970,
        "MLY First Year": 1957,
        "MLY Last Year": 1970
      },
      {
        "Name": "FORT FRASER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092904",
        "Station ID": 586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.1,
        "Longitude (Decimal Degrees)": -124.55,
        "Latitude": 540600000,
        "Longitude": -1243300000,
        "Elevation (m)": 701,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "FORT FRASER 13S",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092905",
        "Station ID": 587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -124.58,
        "Latitude": 535300000,
        "Longitude": -1243500000,
        "Elevation (m)": 701,
        "First Year": 1970,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1993,
        "MLY First Year": 1970,
        "MLY Last Year": 1993
      },
      {
        "Name": "FORT ST JAMES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092970",
        "Station ID": 588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.46,
        "Longitude (Decimal Degrees)": -124.29,
        "Latitude": 542719009,
        "Longitude": -1241708002,
        "Elevation (m)": 691,
        "First Year": 1895,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 2019,
        "MLY First Year": 1895,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT ST JAMES A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092972",
        "Station ID": 589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.42,
        "Longitude (Decimal Degrees)": -124.25,
        "Latitude": 542500000,
        "Longitude": -1241500000,
        "Elevation (m)": 716.3,
        "First Year": 1979,
        "Last Year": 1991,
        "HLY First Year": 1979,
        "HLY Last Year": 1991,
        "DLY First Year": 1979,
        "DLY Last Year": 1991,
        "MLY First Year": 1979,
        "MLY Last Year": 1991
      },
      {
        "Name": "FORT ST JAMES AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092975",
        "Station ID": 51538,
        "WMO ID": 71933,
        "TC ID": "VFS",
        "Latitude (Decimal Degrees)": 54.46,
        "Longitude (Decimal Degrees)": -124.29,
        "Latitude": 542719057,
        "Longitude": -1241708006,
        "Elevation (m)": 688,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT ST JAMES GOLF CLUB",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1092977",
        "Station ID": 55363,
        "WMO ID": 73047,
        "TC ID": "VFJ",
        "Latitude (Decimal Degrees)": 54.46,
        "Longitude (Decimal Degrees)": -124.29,
        "Latitude": 542734038,
        "Longitude": -1241733007,
        "Elevation (m)": 712,
        "First Year": 2022,
        "Last Year": 2022,
        "HLY First Year": 2022,
        "HLY Last Year": 2022,
        "DLY First Year": 2022,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GANG RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093052",
        "Station ID": 590,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.55,
        "Longitude (Decimal Degrees)": -122.33,
        "Latitude": 513300000,
        "Longitude": -1222000000,
        "Elevation (m)": 658,
        "First Year": 1973,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "GISCOME",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093165",
        "Station ID": 591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -122.27,
        "Latitude": 540700000,
        "Longitude": -1221600000,
        "Elevation (m)": 598.9,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "HIXON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093474",
        "Station ID": 592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -122.7,
        "Latitude": 533153001,
        "Longitude": -1224158006,
        "Elevation (m)": 551,
        "First Year": 1970,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2017,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIXON BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093475",
        "Station ID": 593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -122.58,
        "Latitude": 532500000,
        "Longitude": -1223500000,
        "Elevation (m)": 586.7,
        "First Year": 1973,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1983,
        "MLY First Year": 1973,
        "MLY Last Year": 1983
      },
      {
        "Name": "HORSEFLY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093598",
        "Station ID": 594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -121.42,
        "Latitude": 522000000,
        "Longitude": -1212500000,
        "Elevation (m)": 787.9,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "HORSEFLY BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093599",
        "Station ID": 595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -121.42,
        "Latitude": 522000000,
        "Longitude": -1212500000,
        "Elevation (m)": 784.9,
        "First Year": 1970,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1984,
        "MLY First Year": 1970,
        "MLY Last Year": 1984
      },
      {
        "Name": "HORSEFLY LAKE GRUHS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093600",
        "Station ID": 596,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -121.36,
        "Latitude": 522145000,
        "Longitude": -1212151000,
        "Elevation (m)": 777,
        "First Year": 1950,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2016,
        "MLY First Year": 1950,
        "MLY Last Year": 2007
      },
      {
        "Name": "ISLE PIERRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093680",
        "Station ID": 597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -123.23,
        "Latitude": 535800000,
        "Longitude": -1231400000,
        "Elevation (m)": 624.8,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "JESMOND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093730",
        "Station ID": 598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -121.97,
        "Latitude": 511600000,
        "Longitude": -1215800000,
        "Elevation (m)": 1158.2,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "KALDER LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093777",
        "Station ID": 599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.97,
        "Longitude (Decimal Degrees)": -124.25,
        "Latitude": 545800000,
        "Longitude": -1241500000,
        "Elevation (m)": 969.3,
        "First Year": 1973,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1988,
        "MLY First Year": 1973,
        "MLY Last Year": 1988
      },
      {
        "Name": "KEITHLEY CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1093920",
        "Station ID": 600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -121.4,
        "Latitude": 524500000,
        "Longitude": -1212400000,
        "Elevation (m)": 807.7,
        "First Year": 1962,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1962,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "KERSLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094125",
        "Station ID": 601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -122.37,
        "Latitude": 524900000,
        "Longitude": -1222200000,
        "Elevation (m)": 670.6,
        "First Year": 1962,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1992,
        "MLY First Year": 1962,
        "MLY Last Year": 1992
      },
      {
        "Name": "KERSLEY 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094127",
        "Station ID": 602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.81,
        "Longitude (Decimal Degrees)": -122.41,
        "Latitude": 524851900,
        "Longitude": -1222446500,
        "Elevation (m)": 570,
        "First Year": 1985,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2010,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAC LA HACHE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094455",
        "Station ID": 603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.82,
        "Longitude (Decimal Degrees)": -121.47,
        "Latitude": 514900000,
        "Longitude": -1212800000,
        "Elevation (m)": 819,
        "First Year": 1962,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1990,
        "MLY First Year": 1962,
        "MLY Last Year": 1990
      },
      {
        "Name": "LAC LA HACHE DINGWALL CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094456",
        "Station ID": 604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.88,
        "Longitude (Decimal Degrees)": -121.73,
        "Latitude": 515300000,
        "Longitude": -1214400000,
        "Elevation (m)": 906,
        "First Year": 1989,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1994,
        "MLY First Year": 1989,
        "MLY Last Year": 1994
      },
      {
        "Name": "LAC LA HACHE 11SW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094457",
        "Station ID": 605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -121.55,
        "Latitude": 514300000,
        "Longitude": -1213300000,
        "Elevation (m)": 990,
        "First Year": 1987,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1993,
        "MLY First Year": 1987,
        "MLY Last Year": 1993
      },
      {
        "Name": "LEO CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094602",
        "Station ID": 606,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.08,
        "Longitude (Decimal Degrees)": -125.58,
        "Latitude": 550500000,
        "Longitude": -1253500000,
        "Elevation (m)": 698,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "LIKELY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094616",
        "Station ID": 607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.6,
        "Longitude (Decimal Degrees)": -121.53,
        "Latitude": 523600000,
        "Longitude": -1213200000,
        "Elevation (m)": 723.9,
        "First Year": 1974,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1993,
        "MLY First Year": 1974,
        "MLY Last Year": 1993
      },
      {
        "Name": "LORSTA WILLIAMS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094719",
        "Station ID": 608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -122.35,
        "Latitude": 515800000,
        "Longitude": -1222100000,
        "Elevation (m)": 926.6,
        "First Year": 1977,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1984,
        "MLY First Year": 1977,
        "MLY Last Year": 1984
      },
      {
        "Name": "MACKAY RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094785",
        "Station ID": 609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -120.72,
        "Latitude": 522300000,
        "Longitude": -1204300000,
        "Elevation (m)": 1219.2,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MAPES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094897",
        "Station ID": 610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -123.88,
        "Latitude": 535300000,
        "Longitude": -1235300000,
        "Elevation (m)": 784.9,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "MCBRIDE ELDER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094948",
        "Station ID": 611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -120.35,
        "Latitude": 532524200,
        "Longitude": -1202109600,
        "Elevation (m)": 733,
        "First Year": 1985,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2009,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "MCBRIDE 4SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094950",
        "Station ID": 612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.27,
        "Longitude (Decimal Degrees)": -120.15,
        "Latitude": 531600000,
        "Longitude": -1200900000,
        "Elevation (m)": 722.4,
        "First Year": 1922,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1995,
        "MLY First Year": 1922,
        "MLY Last Year": 1995
      },
      {
        "Name": "MCBRIDE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094955",
        "Station ID": 613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -120.25,
        "Latitude": 532200000,
        "Longitude": -1201500000,
        "Elevation (m)": 771.1,
        "First Year": 1973,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1992,
        "MLY First Year": 1973,
        "MLY Last Year": 1992
      },
      {
        "Name": "MCBRIDE S & W RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1094962",
        "Station ID": 614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.27,
        "Longitude (Decimal Degrees)": -120.13,
        "Latitude": 531600000,
        "Longitude": -1200800000,
        "Elevation (m)": 716,
        "First Year": 1980,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1985,
        "MLY First Year": 1980,
        "MLY Last Year": 1985
      },
      {
        "Name": "MCGREGOR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095000",
        "Station ID": 615,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -121.83,
        "Latitude": 540500000,
        "Longitude": -1215000000,
        "Elevation (m)": 609.6,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "MCLEESE LAKE FRASERVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095015",
        "Station ID": 616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -122.37,
        "Latitude": 522247050,
        "Longitude": -1222225010,
        "Elevation (m)": 563.9,
        "First Year": 1980,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2016,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "MCLEESE LAKE GRANITE MT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095018",
        "Station ID": 617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.53,
        "Longitude (Decimal Degrees)": -122.29,
        "Latitude": 523150009,
        "Longitude": -1221709008,
        "Elevation (m)": 1133,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOFFAT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095195",
        "Station ID": 618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -121.48,
        "Latitude": 521400000,
        "Longitude": -1212900000,
        "Elevation (m)": 945,
        "First Year": 1975,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1986,
        "MLY First Year": 1975,
        "MLY Last Year": 1986
      },
      {
        "Name": "MOUNT ROBSON RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10952B9",
        "Station ID": 619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -119.23,
        "Latitude": 530100000,
        "Longitude": -1191400000,
        "Elevation (m)": 868.7,
        "First Year": 1975,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1992,
        "MLY First Year": 1975,
        "MLY Last Year": 1992
      },
      {
        "Name": "NAVER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095410",
        "Station ID": 620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 531700000,
        "Longitude": -1222900000,
        "Elevation (m)": 667.5,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "NAVER CREEK AHBAU",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095412",
        "Station ID": 621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -122.33,
        "Latitude": 531500000,
        "Longitude": -1222000000,
        "Elevation (m)": 896.1,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "NORCO COAL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095617",
        "Station ID": 622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -121.92,
        "Latitude": 534900000,
        "Longitude": -1215500000,
        "Elevation (m)": 742.2,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "OCHILTREE MIOCENE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095676",
        "Station ID": 623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.26,
        "Longitude (Decimal Degrees)": -121.72,
        "Latitude": 521537000,
        "Longitude": -1214326000,
        "Elevation (m)": 1027,
        "First Year": 1963,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2003,
        "MLY First Year": 1963,
        "MLY Last Year": 2003
      },
      {
        "Name": "OLD SUMMIT RD NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095740",
        "Station ID": 624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -122.65,
        "Latitude": 540700000,
        "Longitude": -1223900000,
        "Elevation (m)": 685.8,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "100 MILE HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095790",
        "Station ID": 625,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -121.27,
        "Latitude": 513900000,
        "Longitude": -1211600000,
        "Elevation (m)": 929.6,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1957,
        "MLY Last Year": 1959
      },
      {
        "Name": "108 MILE HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095796",
        "Station ID": 626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -121.33,
        "Latitude": 514500000,
        "Longitude": -1212000000,
        "Elevation (m)": 957.1,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "150 MILE HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095820",
        "Station ID": 627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -121.93,
        "Latitude": 520700000,
        "Longitude": -1215600000,
        "Elevation (m)": 737.6,
        "First Year": 1947,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1963,
        "MLY First Year": 1947,
        "MLY Last Year": 1963
      },
      {
        "Name": "150 MILE HOUSE 7N",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1095821",
        "Station ID": 628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.22,
        "Longitude (Decimal Degrees)": -122,
        "Latitude": 521300000,
        "Longitude": -1220000000,
        "Elevation (m)": 883.9,
        "First Year": 1969,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1975,
        "MLY First Year": 1969,
        "MLY Last Year": 1975
      },
      {
        "Name": "PRINCE GEORGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096435",
        "Station ID": 629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 535500000,
        "Longitude": -1224400000,
        "Elevation (m)": 569.1,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "PRINCE GEORGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096436",
        "Station ID": 630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -122.8,
        "Latitude": 535000000,
        "Longitude": -1224800000,
        "Elevation (m)": 570,
        "First Year": 1912,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1945,
        "MLY First Year": 1912,
        "MLY Last Year": 1945
      },
      {
        "Name": "PRINCE GEORGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096439",
        "Station ID": 48370,
        "WMO ID": 71896,
        "TC ID": "YXS",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -122.68,
        "Latitude": 535303000,
        "Longitude": -1224039000,
        "Elevation (m)": 691,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE GEORGE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096450",
        "Station ID": 631,
        "WMO ID": 71896,
        "TC ID": "YXS",
        "Latitude (Decimal Degrees)": 53.89,
        "Longitude (Decimal Degrees)": -122.68,
        "Latitude": 535327000,
        "Longitude": -1224044000,
        "Elevation (m)": 691.3,
        "First Year": 1942,
        "Last Year": 2009,
        "HLY First Year": 1953,
        "HLY Last Year": 2009,
        "DLY First Year": 1942,
        "DLY Last Year": 2009,
        "MLY First Year": 1942,
        "MLY Last Year": 2009
      },
      {
        "Name": "PRINCE GEORGE AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096453",
        "Station ID": 48248,
        "WMO ID": 71302,
        "TC ID": "VXS",
        "Latitude (Decimal Degrees)": 53.89,
        "Longitude (Decimal Degrees)": -122.67,
        "Latitude": 535320000,
        "Longitude": -1224019002,
        "Elevation (m)": 680,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE GEORGE MASSEY AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096454",
        "Station ID": 50169,
        "WMO ID": null,
        "TC ID": "VPX",
        "Latitude (Decimal Degrees)": 53.9,
        "Longitude (Decimal Degrees)": -122.79,
        "Latitude": 535359009,
        "Longitude": -1224727001,
        "Elevation (m)": 601,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE GEORGE WEST LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096455",
        "Station ID": 632,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.78,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 534632001,
        "Longitude": -1225108000,
        "Elevation (m)": 699,
        "First Year": 1970,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2011,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRINCE GEORGE 15NW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096458",
        "Station ID": 633,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -122.74,
        "Latitude": 540311000,
        "Longitude": -1224409000,
        "Elevation (m)": 762,
        "First Year": 1984,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2004,
        "MLY First Year": 1984,
        "MLY Last Year": 2004
      },
      {
        "Name": "PR GEORGE FOREMAN FLAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096460",
        "Station ID": 634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.93,
        "Longitude (Decimal Degrees)": -122.68,
        "Latitude": 535600000,
        "Longitude": -1224100000,
        "Elevation (m)": 579.1,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "PRINCE GEORGE MIWORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096465",
        "Station ID": 635,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 535800000,
        "Longitude": -1225600000,
        "Elevation (m)": 610,
        "First Year": 1984,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2002,
        "MLY First Year": 1984,
        "MLY Last Year": 2002
      },
      {
        "Name": "PRINCE GEORGE STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096468",
        "Station ID": 636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -122.77,
        "Latitude": 535248000,
        "Longitude": -1224603000,
        "Elevation (m)": 579,
        "First Year": 1975,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRINCE GEORGE WESTWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096470",
        "Station ID": 637,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.9,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 535400000,
        "Longitude": -1224700000,
        "Elevation (m)": 591.3,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "QUESNEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096600",
        "Station ID": 638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.98,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 525900000,
        "Longitude": -1222900000,
        "Elevation (m)": 487.7,
        "First Year": 1892,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1970,
        "MLY First Year": 1893,
        "MLY Last Year": 1970
      },
      {
        "Name": "QUESNEL AUT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096601",
        "Station ID": 639,
        "WMO ID": null,
        "TC ID": "WQZ",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 530200000,
        "Longitude": -1223100000,
        "Elevation (m)": 545.3,
        "First Year": 1990,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": 1990,
        "DLY Last Year": 1994,
        "MLY First Year": 1990,
        "MLY Last Year": 1994
      },
      {
        "Name": "QUESNEL AWOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096625",
        "Station ID": 30967,
        "WMO ID": 71103,
        "TC ID": "CQZ",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -122.51,
        "Latitude": 530134000,
        "Longitude": -1223036000,
        "Elevation (m)": 545,
        "First Year": 2005,
        "Last Year": 2011,
        "HLY First Year": 2005,
        "HLY Last Year": 2011,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "QUESNEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096629",
        "Station ID": 48688,
        "WMO ID": 71192,
        "TC ID": "YQZ",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -122.51,
        "Latitude": 530134000,
        "Longitude": -1223037000,
        "Elevation (m)": 545,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QUESNEL A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096630",
        "Station ID": 640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -122.51,
        "Latitude": 530134000,
        "Longitude": -1223036000,
        "Elevation (m)": 545,
        "First Year": 1946,
        "Last Year": 2007,
        "HLY First Year": 1953,
        "HLY Last Year": 2006,
        "DLY First Year": 1946,
        "DLY Last Year": 2007,
        "MLY First Year": 1946,
        "MLY Last Year": 2007
      },
      {
        "Name": "QUESNEL AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096631",
        "Station ID": 48108,
        "WMO ID": 71779,
        "TC ID": "VQZ",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -122.51,
        "Latitude": 530136008,
        "Longitude": -1223023007,
        "Elevation (m)": 545.3,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QUESNEL MOOSE HEIGHTS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096635",
        "Station ID": 641,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.07,
        "Longitude (Decimal Degrees)": -122.5,
        "Latitude": 530400000,
        "Longitude": -1223000000,
        "Elevation (m)": 711.7,
        "First Year": 1962,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1984,
        "MLY First Year": 1962,
        "MLY Last Year": 1983
      },
      {
        "Name": "QUESNEL NAZKO ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096636",
        "Station ID": 642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -122.67,
        "Latitude": 530100000,
        "Longitude": -1224000000,
        "Elevation (m)": 762,
        "First Year": 1968,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1974,
        "MLY First Year": 1968,
        "MLY Last Year": 1974
      },
      {
        "Name": "RED PASS JUNCTION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096660",
        "Station ID": 643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53,
        "Longitude (Decimal Degrees)": -119,
        "Latitude": 530000000,
        "Longitude": -1190000000,
        "Elevation (m)": 1059.2,
        "First Year": 1931,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1969,
        "MLY First Year": 1931,
        "MLY Last Year": 1969
      },
      {
        "Name": "RED PASS MT ROBSON PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096665",
        "Station ID": 644,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.98,
        "Longitude (Decimal Degrees)": -119,
        "Latitude": 525900000,
        "Longitude": -1190000000,
        "Elevation (m)": 1066.8,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "REDSTONE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096691",
        "Station ID": 645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -123.65,
        "Latitude": 520600000,
        "Longitude": -1233900000,
        "Elevation (m)": 808.9,
        "First Year": 1957,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1957,
        "MLY First Year": 1957,
        "MLY Last Year": 1957
      },
      {
        "Name": "RISKE CREEK FTP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096775",
        "Station ID": 646,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.02,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 520100000,
        "Longitude": -1223100000,
        "Elevation (m)": 1005.8,
        "First Year": 1965,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1970,
        "MLY First Year": 1965,
        "MLY Last Year": 1970
      },
      {
        "Name": "SALMON VAL ERICKSON RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1096952",
        "Station ID": 647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -122.65,
        "Latitude": 540800000,
        "Longitude": -1223900000,
        "Elevation (m)": 693.4,
        "First Year": 1970,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "SINCLAIR MILLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1097370",
        "Station ID": 648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -121.67,
        "Latitude": 540100000,
        "Longitude": -1214000000,
        "Elevation (m)": 655.3,
        "First Year": 1960,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1965,
        "MLY First Year": 1960,
        "MLY Last Year": 1965
      },
      {
        "Name": "SODA CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1097550",
        "Station ID": 649,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -122.28,
        "Latitude": 522000000,
        "Longitude": -1221700000,
        "Elevation (m)": null,
        "First Year": 1879,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1933,
        "MLY First Year": 1879,
        "MLY Last Year": 1933
      },
      {
        "Name": "SPOKIN LAKE 4E",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1097646",
        "Station ID": 650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -121.69,
        "Latitude": 521101014,
        "Longitude": -1214110039,
        "Elevation (m)": 1033,
        "First Year": 1983,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2022,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "STEWART CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1097750",
        "Station ID": 651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.2,
        "Longitude (Decimal Degrees)": -121.6,
        "Latitude": 531200000,
        "Longitude": -1213600000,
        "Elevation (m)": 1207,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "TACHIE 1SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1097888",
        "Station ID": 652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.63,
        "Longitude (Decimal Degrees)": -124.7,
        "Latitude": 543800000,
        "Longitude": -1244200000,
        "Elevation (m)": 682.8,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "TAKLA LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1097970",
        "Station ID": 653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.47,
        "Longitude (Decimal Degrees)": -125.97,
        "Latitude": 552800000,
        "Longitude": -1255800000,
        "Elevation (m)": 854,
        "First Year": 1962,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1991,
        "MLY First Year": 1962,
        "MLY Last Year": 1991
      },
      {
        "Name": "TETE JAUNE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098138",
        "Station ID": 656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 525500000,
        "Longitude": -1192300000,
        "Elevation (m)": 776.9,
        "First Year": 1981,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1985,
        "MLY First Year": 1981,
        "MLY Last Year": 1985
      },
      {
        "Name": "TWAN CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098318",
        "Station ID": 657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -122.62,
        "Latitude": 522759000,
        "Longitude": -1223703000,
        "Elevation (m)": 914.4,
        "First Year": 1977,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2005,
        "MLY First Year": 1977,
        "MLY Last Year": 2005
      },
      {
        "Name": "TYEE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098328",
        "Station ID": 658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -122.08,
        "Latitude": 522300000,
        "Longitude": -1220500000,
        "Elevation (m)": 911,
        "First Year": 1988,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1998,
        "MLY First Year": 1988,
        "MLY Last Year": 1998
      },
      {
        "Name": "UPPER FRASER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098335",
        "Station ID": 659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -121.95,
        "Latitude": 540700000,
        "Longitude": -1215700000,
        "Elevation (m)": 612.6,
        "First Year": 1959,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "VANDERHOOF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098490",
        "Station ID": 661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -124,
        "Latitude": 540300000,
        "Longitude": -1240000000,
        "Elevation (m)": 637.9,
        "First Year": 1916,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1966,
        "MLY First Year": 1916,
        "MLY Last Year": 1966
      },
      {
        "Name": "VANDERHOOF 2NE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098492",
        "Station ID": 662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -124,
        "Latitude": 540200000,
        "Longitude": -1240000000,
        "Elevation (m)": 676.7,
        "First Year": 1969,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1976,
        "MLY First Year": 1969,
        "MLY Last Year": 1976
      },
      {
        "Name": "VANDERHOOF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "10984R0",
        "Station ID": 660,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -124.13,
        "Latitude": 540300000,
        "Longitude": -1240800000,
        "Elevation (m)": 673.6,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "WILLIAMS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098939",
        "Station ID": 663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -122.17,
        "Latitude": 520800000,
        "Longitude": -1221000000,
        "Elevation (m)": 614,
        "First Year": 1936,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1987,
        "MLY First Year": 1939,
        "MLY Last Year": 1987
      },
      {
        "Name": "WILLIAMS LAKE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098940",
        "Station ID": 664,
        "WMO ID": 71104,
        "TC ID": "YWL",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -122.05,
        "Latitude": 521059000,
        "Longitude": -1220315000,
        "Elevation (m)": 939.7,
        "First Year": 1960,
        "Last Year": 2012,
        "HLY First Year": 1961,
        "HLY Last Year": 2012,
        "DLY First Year": 1960,
        "DLY Last Year": 2012,
        "MLY First Year": 1961,
        "MLY Last Year": 2012
      },
      {
        "Name": "WILLIAMS LAKE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098941",
        "Station ID": 50820,
        "WMO ID": 71104,
        "TC ID": "YWL",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -122.05,
        "Latitude": 521100000,
        "Longitude": -1220316000,
        "Elevation (m)": 939.7,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WILLIAMS LAKE ESLER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098942",
        "Station ID": 665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -122.18,
        "Latitude": 520600000,
        "Longitude": -1221100000,
        "Elevation (m)": 930,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "WILLIAMS LAKE GLENDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098943",
        "Station ID": 666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -122.17,
        "Latitude": 520900000,
        "Longitude": -1221000000,
        "Elevation (m)": 588.3,
        "First Year": 1965,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1979,
        "MLY First Year": 1965,
        "MLY Last Year": 1979
      },
      {
        "Name": "WILLIAMS LAKE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098946",
        "Station ID": 667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -122.15,
        "Latitude": 520741000,
        "Longitude": -1220856000,
        "Elevation (m)": 585.2,
        "First Year": 1980,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2002,
        "MLY First Year": 1980,
        "MLY Last Year": 2002
      },
      {
        "Name": "WILLOW RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098954",
        "Station ID": 668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.06,
        "Longitude (Decimal Degrees)": -122.47,
        "Latitude": 540323000,
        "Longitude": -1222809000,
        "Elevation (m)": 586,
        "First Year": 1988,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1999,
        "MLY First Year": 1988,
        "MLY Last Year": 1999
      },
      {
        "Name": "WILLOW RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098955",
        "Station ID": 669,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.73,
        "Longitude (Decimal Degrees)": -122.32,
        "Latitude": 534400000,
        "Longitude": -1221900000,
        "Elevation (m)": 801.3,
        "First Year": 1955,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1956,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WOODPECKER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098977",
        "Station ID": 670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -122.65,
        "Latitude": 533100000,
        "Longitude": -1223900000,
        "Elevation (m)": 609.6,
        "First Year": 1934,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1945,
        "MLY First Year": 1934,
        "MLY Last Year": 1945
      },
      {
        "Name": "VANDERHOOF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098D90",
        "Station ID": 655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -124.02,
        "Latitude": 540200000,
        "Longitude": -1240100000,
        "Elevation (m)": 638,
        "First Year": 1980,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2021,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "VANDERHOOF BRAESIDE RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1098DR0",
        "Station ID": 654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -124.27,
        "Latitude": 540500000,
        "Longitude": -1241600000,
        "Elevation (m)": 682.8,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "CASTLE ROCK 6 NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109A4NG",
        "Station ID": 5017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.54,
        "Longitude (Decimal Degrees)": -122.49,
        "Latitude": 523229000,
        "Longitude": -1222919000,
        "Elevation (m)": 541,
        "First Year": 1991,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2014,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "CATFISH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109AMEP",
        "Station ID": 556,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -120.8,
        "Latitude": 533800000,
        "Longitude": -1204800000,
        "Elevation (m)": 648,
        "First Year": 1986,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1987,
        "MLY First Year": 1986,
        "MLY Last Year": 1987
      },
      {
        "Name": "FRASER LAKE NORTH SHORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109C0L6",
        "Station ID": 558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -124.75,
        "Latitude": 540700000,
        "Longitude": -1244500000,
        "Elevation (m)": 666,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "FRASER LAKE NORTH SHORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109C0LF",
        "Station ID": 557,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -124.85,
        "Latitude": 540442000,
        "Longitude": -1245050000,
        "Elevation (m)": 674,
        "First Year": 1969,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2010,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "HORSEFLY RIVER (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109CN9G",
        "Station ID": 10891,
        "WMO ID": null,
        "TC ID": "WFR",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -121,
        "Latitude": 520017000,
        "Longitude": -1210003000,
        "Elevation (m)": 846,
        "First Year": 1994,
        "Last Year": 2001,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1995,
        "DLY Last Year": 2001,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "108 MILE HOUSE ABEL LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109E7R6",
        "Station ID": 559,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.62,
        "Longitude (Decimal Degrees)": -121.26,
        "Latitude": 513724031,
        "Longitude": -1211546069,
        "Elevation (m)": 1005,
        "First Year": 1987,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2020,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "STRATHNAVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109G775",
        "Station ID": 560,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -122.58,
        "Latitude": 532200000,
        "Longitude": -1223500000,
        "Elevation (m)": 655,
        "First Year": 1983,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1985,
        "MLY First Year": 1983,
        "MLY Last Year": 1985
      },
      {
        "Name": "WILLIAMS LAKE ESLER 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109H9M2",
        "Station ID": 561,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -122.18,
        "Latitude": 520600000,
        "Longitude": -1221100000,
        "Elevation (m)": 917,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "WILLIAMS LAKE FROST CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109Q9MK",
        "Station ID": 6942,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.03,
        "Longitude (Decimal Degrees)": -122.13,
        "Latitude": 520200000,
        "Longitude": -1220800000,
        "Elevation (m)": 822.9,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "TETE JAUNE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109QJ3G",
        "Station ID": 562,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53,
        "Longitude (Decimal Degrees)": -119.54,
        "Latitude": 530009000,
        "Longitude": -1193206000,
        "Elevation (m)": 746,
        "First Year": 1989,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2006,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "WINEGLASS RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "109QR57",
        "Station ID": 563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.85,
        "Longitude (Decimal Degrees)": -122.65,
        "Latitude": 515100000,
        "Longitude": -1223900000,
        "Elevation (m)": 487.7,
        "First Year": 1973,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1993,
        "MLY First Year": 1973,
        "MLY Last Year": 1993
      },
      {
        "Name": "CALLAGHAN VALLEY (SKI JUMP TOP)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100001",
        "Station ID": 45487,
        "WMO ID": null,
        "TC ID": "VOW",
        "Latitude (Decimal Degrees)": 50.14,
        "Longitude (Decimal Degrees)": -123.11,
        "Latitude": 500815000,
        "Longitude": -1230636060,
        "Elevation (m)": 936,
        "First Year": 2006,
        "Last Year": 2010,
        "HLY First Year": 2006,
        "HLY Last Year": 2010,
        "DLY First Year": 2006,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CYPRESS BOWL FREESTYLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100004",
        "Station ID": 45490,
        "WMO ID": 71004,
        "TC ID": "VOZ",
        "Latitude (Decimal Degrees)": 49.39,
        "Longitude (Decimal Degrees)": -123.21,
        "Latitude": 492332400,
        "Longitude": -1231227270,
        "Elevation (m)": 969.1,
        "First Year": 2007,
        "Last Year": 2010,
        "HLY First Year": 2007,
        "HLY Last Year": 2010,
        "DLY First Year": 2007,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ABBOTSFORD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100015",
        "Station ID": 701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -122.33,
        "Latitude": 490100000,
        "Longitude": -1222000000,
        "Elevation (m)": null,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "ABBOTSFORD A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100030",
        "Station ID": 702,
        "WMO ID": 71108,
        "TC ID": "YXX",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.36,
        "Latitude": 490131000,
        "Longitude": -1222136000,
        "Elevation (m)": 59.13,
        "First Year": 1944,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1944,
        "DLY Last Year": 2012,
        "MLY First Year": 1944,
        "MLY Last Year": 2012
      },
      {
        "Name": "ABBOTSFORD A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100031",
        "Station ID": 50308,
        "WMO ID": 71108,
        "TC ID": "YXX",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.36,
        "Latitude": 490131000,
        "Longitude": -1222136000,
        "Elevation (m)": 59.1,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ABBOTSFORD A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100032",
        "Station ID": 54238,
        "WMO ID": null,
        "TC ID": "YXX",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.36,
        "Latitude": 490131000,
        "Longitude": -1222136000,
        "Elevation (m)": 59.1,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ABBOTSFORD EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100035",
        "Station ID": 703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.25,
        "Latitude": 490200000,
        "Longitude": -1221500000,
        "Elevation (m)": 7.6,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "ABBOTSFORD KING ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100037",
        "Station ID": 704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.3,
        "Latitude": 490200000,
        "Longitude": -1221800000,
        "Elevation (m)": 61,
        "First Year": 1968,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1973,
        "MLY First Year": 1968,
        "MLY Last Year": 1973
      },
      {
        "Name": "ABBOTSFORD UPPER SUMAS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100040",
        "Station ID": 705,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -122.27,
        "Latitude": 490300000,
        "Longitude": -1221600000,
        "Elevation (m)": 9.1,
        "First Year": 1935,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1946,
        "MLY First Year": 1935,
        "MLY Last Year": 1946
      },
      {
        "Name": "AGASSIZ RCS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100119",
        "Station ID": 706,
        "WMO ID": 71113,
        "TC ID": "WZA",
        "Latitude (Decimal Degrees)": 49.24,
        "Longitude (Decimal Degrees)": -121.76,
        "Latitude": 491435000,
        "Longitude": -1214537000,
        "Elevation (m)": 19.3,
        "First Year": 1988,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1988,
        "DLY Last Year": 2022,
        "MLY First Year": 1988,
        "MLY Last Year": 2006
      },
      {
        "Name": "AGASSIZ CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100120",
        "Station ID": 707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.24,
        "Longitude (Decimal Degrees)": -121.76,
        "Latitude": 491435000,
        "Longitude": -1214537000,
        "Elevation (m)": 15,
        "First Year": 1889,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 2022,
        "MLY First Year": 1889,
        "MLY Last Year": 2007
      },
      {
        "Name": "AGASSIZ MOUNTAIN INSTITUTE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100130",
        "Station ID": 708,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -121.83,
        "Latitude": 491600000,
        "Longitude": -1215000000,
        "Elevation (m)": 30,
        "First Year": 1986,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1995,
        "MLY First Year": 1986,
        "MLY Last Year": 1995
      },
      {
        "Name": "ALDERGROVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100240",
        "Station ID": 709,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 490700000,
        "Longitude": -1222900000,
        "Elevation (m)": 76.2,
        "First Year": 1953,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1980,
        "MLY First Year": 1953,
        "MLY Last Year": 1980
      },
      {
        "Name": "ALDERGROVE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100241",
        "Station ID": 710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 490200000,
        "Longitude": -1222900000,
        "Elevation (m)": 60,
        "First Year": 1980,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1989,
        "MLY First Year": 1980,
        "MLY Last Year": 1989
      },
      {
        "Name": "ALDERGROVE 48 AVENUE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100245",
        "Station ID": 711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 490500000,
        "Longitude": -1223100000,
        "Elevation (m)": 85,
        "First Year": 1987,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1991,
        "MLY First Year": 1987,
        "MLY Last Year": 1991
      },
      {
        "Name": "ALOUETTE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100360",
        "Station ID": 712,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 491700000,
        "Longitude": -1222900000,
        "Elevation (m)": 117.3,
        "First Year": 1924,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1982,
        "MLY First Year": 1924,
        "MLY Last Year": 1982
      },
      {
        "Name": "ALOUETTE POWER HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100370",
        "Station ID": 713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -122.32,
        "Latitude": 492200000,
        "Longitude": -1221900000,
        "Elevation (m)": 91.4,
        "First Year": 1935,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1949,
        "MLY First Year": 1935,
        "MLY Last Year": 1949
      },
      {
        "Name": "ANNACIS ISLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100440",
        "Station ID": 714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 491100000,
        "Longitude": -1225600000,
        "Elevation (m)": 4.6,
        "First Year": 1954,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1962,
        "MLY First Year": 1954,
        "MLY Last Year": 1962
      },
      {
        "Name": "WHISTLER - NESTERS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100875",
        "Station ID": 43443,
        "WMO ID": 71687,
        "TC ID": "VOC",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500744200,
        "Longitude": -1225716600,
        "Elevation (m)": 659,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLACKCOMB MOUNTAIN 1860",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100879",
        "Station ID": 715,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 500600000,
        "Longitude": -1225500000,
        "Elevation (m)": 1860.9,
        "First Year": 1980,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1990
      },
      {
        "Name": "BLACKCOMB BASE SLIDING CENTER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100881",
        "Station ID": 45787,
        "WMO ID": 71756,
        "TC ID": "VOI",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -122.94,
        "Latitude": 500608900,
        "Longitude": -1225610900,
        "Elevation (m)": 937,
        "First Year": 2007,
        "Last Year": 2018,
        "HLY First Year": 2007,
        "HLY Last Year": 2018,
        "DLY First Year": 2007,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BLACKCOMB BASE SLIDING CENTRE BOTTOM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100882",
        "Station ID": 47807,
        "WMO ID": null,
        "TC ID": "VON",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -122.94,
        "Latitude": 500621007,
        "Longitude": -1225632001,
        "Elevation (m)": 816.6,
        "First Year": 2008,
        "Last Year": 2010,
        "HLY First Year": 2008,
        "HLY Last Year": 2010,
        "DLY First Year": 2008,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHISTLER MOUNTAIN TIMING FLATS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100883",
        "Station ID": 47867,
        "WMO ID": null,
        "TC ID": "VOT",
        "Latitude (Decimal Degrees)": 50.09,
        "Longitude (Decimal Degrees)": -122.98,
        "Latitude": 500530000,
        "Longitude": -1225849300,
        "Elevation (m)": 804.9,
        "First Year": 2009,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": 2009,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALDERGROVE CENTRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100KM0",
        "Station ID": 699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -122.47,
        "Latitude": 490300000,
        "Longitude": -1222800000,
        "Elevation (m)": 60,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "BLACKCOMB MOUNTAIN 1540",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1100Q7Q",
        "Station ID": 700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 500600000,
        "Longitude": -1225500000,
        "Elevation (m)": 1540.2,
        "First Year": 1983,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1994,
        "MLY First Year": 1983,
        "MLY Last Year": 1994
      },
      {
        "Name": "BUNTZEN BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101138",
        "Station ID": 720,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -122.86,
        "Latitude": 492305000,
        "Longitude": -1225146000,
        "Elevation (m)": 10,
        "First Year": 1971,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2007,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "BUNTZEN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101140",
        "Station ID": 721,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -122.87,
        "Latitude": 492300000,
        "Longitude": -1225200000,
        "Elevation (m)": 10,
        "First Year": 1924,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1983,
        "MLY First Year": 1924,
        "MLY Last Year": 1983
      },
      {
        "Name": "BURNABY BC INST TECHNOL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101142",
        "Station ID": 722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -123,
        "Latitude": 491500000,
        "Longitude": -1230000000,
        "Elevation (m)": 30.5,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "BURNABY BRANTFORD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101144",
        "Station ID": 723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -122.97,
        "Latitude": 491400000,
        "Longitude": -1225800000,
        "Elevation (m)": 125,
        "First Year": 1959,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1970,
        "MLY First Year": 1960,
        "MLY Last Year": 1970
      },
      {
        "Name": "BURNABY CAPITOL HILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101146",
        "Station ID": 724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.29,
        "Longitude (Decimal Degrees)": -122.99,
        "Latitude": 491710000,
        "Longitude": -1225912000,
        "Elevation (m)": 182.9,
        "First Year": 1960,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2001,
        "MLY First Year": 1960,
        "MLY Last Year": 2001
      },
      {
        "Name": "BURNABY CURTIS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101147",
        "Station ID": 725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.97,
        "Latitude": 491700000,
        "Longitude": -1225800000,
        "Elevation (m)": 99.1,
        "First Year": 1968,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1973,
        "MLY First Year": 1968,
        "MLY Last Year": 1973
      },
      {
        "Name": "BURNABY EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101150",
        "Station ID": 726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 491400000,
        "Longitude": -1225600000,
        "Elevation (m)": 114.3,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "BURNABY EDMONDS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101151",
        "Station ID": 727,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 491300000,
        "Longitude": -1225700000,
        "Elevation (m)": 99.1,
        "First Year": 1959,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "BURNABY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101153",
        "Station ID": 728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 491600000,
        "Longitude": -1225700000,
        "Elevation (m)": 65.5,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "BURNABY LAKE WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101154",
        "Station ID": 729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.98,
        "Latitude": 491600000,
        "Longitude": -1225900000,
        "Elevation (m)": 33.5,
        "First Year": 1964,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1964,
        "MLY First Year": 1964,
        "MLY Last Year": 1964
      },
      {
        "Name": "BURNABY MTN TERMINAL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101155",
        "Station ID": 730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 491600000,
        "Longitude": -1225600000,
        "Elevation (m)": 137.2,
        "First Year": 1958,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1996,
        "MLY First Year": 1958,
        "MLY Last Year": 1996
      },
      {
        "Name": "BURNABY SIMON FRASER U",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101158",
        "Station ID": 731,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 491642200,
        "Longitude": -1225504960,
        "Elevation (m)": 365.8,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURNABY SUNCREST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101160",
        "Station ID": 732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123.02,
        "Latitude": 491300000,
        "Longitude": -1230100000,
        "Elevation (m)": 54.9,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "BURNABY VANCOUVER HTS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101166",
        "Station ID": 733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -123.02,
        "Latitude": 491800000,
        "Longitude": -1230100000,
        "Elevation (m)": 82.3,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "BURNABY CARIBOO DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11011MF",
        "Station ID": 718,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 491500000,
        "Longitude": -1225500000,
        "Elevation (m)": 12.8,
        "First Year": 1979,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1987,
        "MLY First Year": 1979,
        "MLY Last Year": 1987
      },
      {
        "Name": "BURNABY EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11011N0",
        "Station ID": 719,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.75,
        "Latitude": 491300000,
        "Longitude": -1224500000,
        "Elevation (m)": 120.1,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "BURQUITLAM VANCOUVER GOLF COURSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101200",
        "Station ID": 734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.88,
        "Latitude": 491506000,
        "Longitude": -1225237000,
        "Elevation (m)": 122,
        "First Year": 1926,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 2005,
        "MLY First Year": 1926,
        "MLY Last Year": 2005
      },
      {
        "Name": "CALLAGHAN VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101300",
        "Station ID": 43500,
        "WMO ID": 71688,
        "TC ID": "VOD",
        "Latitude (Decimal Degrees)": 50.14,
        "Longitude (Decimal Degrees)": -123.11,
        "Latitude": 500838059,
        "Longitude": -1230638010,
        "Elevation (m)": 884,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALLAGHAN VALLEY(BIATHALON)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101310",
        "Station ID": 45707,
        "WMO ID": null,
        "TC ID": "VOY",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 500850002,
        "Longitude": -1230656002,
        "Elevation (m)": 870,
        "First Year": 2007,
        "Last Year": 2010,
        "HLY First Year": 2007,
        "HLY Last Year": 2010,
        "DLY First Year": 2007,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALLAGHAN VALLEY BIATHLON HIGH LEVEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101313",
        "Station ID": 47835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.01,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 500032000,
        "Longitude": -1230707009,
        "Elevation (m)": 882.7,
        "First Year": 2009,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": 2009,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALLAGHAN VALLEY CROSS COUNTRY HIGH LEVEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101316",
        "Station ID": 47834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.14,
        "Longitude (Decimal Degrees)": -123.11,
        "Latitude": 500834008,
        "Longitude": -1230623002,
        "Elevation (m)": 922,
        "First Year": 2009,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALLAGHAN VALLEY LOW LEVEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101318",
        "Station ID": 47833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.14,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 500823002,
        "Longitude": -1230701003,
        "Elevation (m)": 838,
        "First Year": 2009,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": 2009,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALLAGHAN VALLEY(SKI JUMP BOTTOM)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101320",
        "Station ID": 46967,
        "WMO ID": null,
        "TC ID": "VOX",
        "Latitude (Decimal Degrees)": 50.14,
        "Longitude (Decimal Degrees)": -123.11,
        "Latitude": 500812600,
        "Longitude": -1230647300,
        "Elevation (m)": 860,
        "First Year": 2008,
        "Last Year": 2010,
        "HLY First Year": 2008,
        "HLY Last Year": 2010,
        "DLY First Year": 2008,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHILLIWACK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101530",
        "Station ID": 735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -121.92,
        "Latitude": 491019770,
        "Longitude": -1215528770,
        "Elevation (m)": 11,
        "First Year": 1879,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 2014,
        "MLY First Year": 1879,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHILLIWACK CITY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101540",
        "Station ID": 736,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -121.95,
        "Latitude": 491100000,
        "Longitude": -1215700000,
        "Elevation (m)": 6.1,
        "First Year": 1960,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1985,
        "MLY First Year": 1960,
        "MLY Last Year": 1985
      },
      {
        "Name": "CHILLIWACK GIBSON ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101545",
        "Station ID": 737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -121.87,
        "Latitude": 491000000,
        "Longitude": -1215200000,
        "Elevation (m)": 12,
        "First Year": 1961,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1986,
        "MLY First Year": 1961,
        "MLY Last Year": 1986
      },
      {
        "Name": "CHILLIWACK MARBLEHILL CK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101561",
        "Station ID": 738,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -121.83,
        "Latitude": 490800000,
        "Longitude": -1215000000,
        "Elevation (m)": 213.4,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "CHILLIWACK R CENTRE CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101564",
        "Station ID": 739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -121.55,
        "Latitude": 490600000,
        "Longitude": -1213300000,
        "Elevation (m)": 487.7,
        "First Year": 1961,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1976,
        "MLY First Year": 1961,
        "MLY Last Year": 1976
      },
      {
        "Name": "CHILLIWACK R FOLEY CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101565",
        "Station ID": 740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -121.63,
        "Latitude": 490600000,
        "Longitude": -1213800000,
        "Elevation (m)": 369.1,
        "First Year": 1966,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1984,
        "MLY First Year": 1966,
        "MLY Last Year": 1984
      },
      {
        "Name": "CHILLIWACK R TAMAHI CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101567",
        "Station ID": 742,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -121.83,
        "Latitude": 490400000,
        "Longitude": -1215000000,
        "Elevation (m)": 137.2,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "CHILLIWACK WESTVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101577",
        "Station ID": 743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -121.97,
        "Latitude": 491000000,
        "Longitude": -1215800000,
        "Elevation (m)": 6.1,
        "First Year": 1981,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1994
      },
      {
        "Name": "CHILLIWACK YALE RD EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101580",
        "Station ID": 744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -121.9,
        "Latitude": 491100000,
        "Longitude": -1215400000,
        "Elevation (m)": 7.6,
        "First Year": 1969,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1977,
        "MLY First Year": 1969,
        "MLY Last Year": 1977
      },
      {
        "Name": "CLOVERDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101680",
        "Station ID": 745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 490600000,
        "Longitude": -1224400000,
        "Elevation (m)": 3,
        "First Year": 1953,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1962,
        "MLY First Year": 1953,
        "MLY Last Year": 1962
      },
      {
        "Name": "CLOVERDALE CENTRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101707",
        "Station ID": 746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 490600000,
        "Longitude": -1224400000,
        "Elevation (m)": 3,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "CLOVERDALE EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101708",
        "Station ID": 747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -122.7,
        "Latitude": 490632950,
        "Longitude": -1224151350,
        "Elevation (m)": 50,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLOVERVALLEY FRYS CORNER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101709",
        "Station ID": 748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 490800000,
        "Longitude": -1224400000,
        "Elevation (m)": 9.1,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "COQUITLAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101888",
        "Station ID": 749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491600000,
        "Longitude": -1225100000,
        "Elevation (m)": 7.9,
        "First Year": 1901,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1901,
        "DLY Last Year": 1933,
        "MLY First Year": 1901,
        "MLY Last Year": 1933
      },
      {
        "Name": "COQUITLAM COMO LAKE AVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101889",
        "Station ID": 750,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.87,
        "Latitude": 491610020,
        "Longitude": -1225156010,
        "Elevation (m)": 160,
        "First Year": 1972,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2016,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "COQUITLAM LAFARGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110188H",
        "Station ID": 27007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.77,
        "Latitude": 491700000,
        "Longitude": -1224600000,
        "Elevation (m)": 34,
        "First Year": 1994,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1996,
        "MLY First Year": 1994,
        "MLY Last Year": 1996
      },
      {
        "Name": "COQUITLAM LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101890",
        "Station ID": 751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -122.8,
        "Latitude": 492200000,
        "Longitude": -1224800000,
        "Elevation (m)": 160.9,
        "First Year": 1924,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1982,
        "MLY First Year": 1924,
        "MLY Last Year": 1982
      },
      {
        "Name": "COQUITLAM MUNDY PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101891",
        "Station ID": 752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.83,
        "Latitude": 491500000,
        "Longitude": -1225000000,
        "Elevation (m)": 101,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1989,
        "MLY First Year": 1988,
        "MLY Last Year": 1989
      },
      {
        "Name": "CHILLIWACK R HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101N65",
        "Station ID": 716,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -121.7,
        "Latitude": 490448008,
        "Longitude": -1214215002,
        "Elevation (m)": 225,
        "First Year": 1961,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2016,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "COQUITLAM MUNDY PARK 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101Q9A",
        "Station ID": 717,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.26,
        "Longitude (Decimal Degrees)": -122.84,
        "Latitude": 491533000,
        "Longitude": -1225006000,
        "Elevation (m)": 150,
        "First Year": 1989,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2003,
        "MLY First Year": 1989,
        "MLY Last Year": 2003
      },
      {
        "Name": "COQUITLAM MUNDY PARK 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1101QRJ",
        "Station ID": 6829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.84,
        "Latitude": 491513000,
        "Longitude": -1225023000,
        "Elevation (m)": 144.4,
        "First Year": 1992,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2003,
        "MLY First Year": 1992,
        "MLY Last Year": 2003
      },
      {
        "Name": "CULTUS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102220",
        "Station ID": 754,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -121.98,
        "Latitude": 490500000,
        "Longitude": -1215900000,
        "Elevation (m)": 45.7,
        "First Year": 1931,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1994,
        "MLY First Year": 1931,
        "MLY Last Year": 1994
      },
      {
        "Name": "CULTUS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102221",
        "Station ID": 50368,
        "WMO ID": null,
        "TC ID": "VCL",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -121.98,
        "Latitude": 490444000,
        "Longitude": -1215843000,
        "Elevation (m)": 45.7,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CULTUS LAKE LINDELL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102222",
        "Station ID": 755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.02,
        "Latitude": 490200000,
        "Longitude": -1220100000,
        "Elevation (m)": 61,
        "First Year": 1981,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1988,
        "MLY First Year": 1981,
        "MLY Last Year": 1988
      },
      {
        "Name": "CYPRESS BOWL 1425",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102252",
        "Station ID": 28013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.41,
        "Longitude (Decimal Degrees)": -123.2,
        "Latitude": 492436000,
        "Longitude": -1231143000,
        "Elevation (m)": 1425,
        "First Year": 1999,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2004,
        "MLY First Year": 1999,
        "MLY Last Year": 2004
      },
      {
        "Name": "CYPRESS BOWL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102253",
        "Station ID": 756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.2,
        "Latitude": 492346000,
        "Longitude": -1231216000,
        "Elevation (m)": 930,
        "First Year": 1984,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2004,
        "MLY First Year": 1984,
        "MLY Last Year": 2004
      },
      {
        "Name": "CYPRESS BOWL UPPER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102254",
        "Station ID": 757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.19,
        "Latitude": 492416700,
        "Longitude": -1231127000,
        "Elevation (m)": 1210,
        "First Year": 1985,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2004,
        "MLY First Year": 1985,
        "MLY Last Year": 2004
      },
      {
        "Name": "CYPRESS BOWL NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102255",
        "Station ID": 44023,
        "WMO ID": null,
        "TC ID": "VOE",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.21,
        "Latitude": 492407015,
        "Longitude": -1231227027,
        "Elevation (m)": 953,
        "First Year": 2005,
        "Last Year": 2010,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "CYPRESS BOWL SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102256",
        "Station ID": 44024,
        "WMO ID": null,
        "TC ID": "VOG",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -123.19,
        "Latitude": 492243400,
        "Longitude": -1231138300,
        "Elevation (m)": 885.5,
        "First Year": 2005,
        "Last Year": 2010,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "CYPRESS BOWL GRANDSTAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102257",
        "Station ID": 48488,
        "WMO ID": null,
        "TC ID": "VWG",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -123.21,
        "Latitude": 492344006,
        "Longitude": -1231222008,
        "Elevation (m)": 968,
        "First Year": 2010,
        "Last Year": 2010,
        "HLY First Year": 2010,
        "HLY Last Year": 2010,
        "DLY First Year": 2010,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DELTA ANNIEVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102410",
        "Station ID": 758,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 490900000,
        "Longitude": -1225500000,
        "Elevation (m)": 85.3,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "DELTA BEACH GROVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102412",
        "Station ID": 759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 490200000,
        "Longitude": -1230400000,
        "Elevation (m)": 3,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "DELTA BURNS BOG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102415",
        "Station ID": 49088,
        "WMO ID": 71042,
        "TC ID": "VBB",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -123,
        "Latitude": 490733053,
        "Longitude": -1230008085,
        "Elevation (m)": 3.11,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DELTA LADNER EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102416",
        "Station ID": 760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 490500000,
        "Longitude": -1230400000,
        "Elevation (m)": 1.5,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "DELTA LADNER SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102417",
        "Station ID": 761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 490400000,
        "Longitude": -1230500000,
        "Elevation (m)": 2,
        "First Year": 1971,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1989,
        "MLY First Year": 1971,
        "MLY Last Year": 1989
      },
      {
        "Name": "DELTA OLIVER SLOUGH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102419",
        "Station ID": 762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 490500000,
        "Longitude": -1225500000,
        "Elevation (m)": 1.8,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "DELTA PEBBLE HILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102420",
        "Station ID": 763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 490000000,
        "Longitude": -1230500000,
        "Elevation (m)": 51,
        "First Year": 1961,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1996,
        "MLY First Year": 1961,
        "MLY Last Year": 1996
      },
      {
        "Name": "DELTA SUNSHINE HILLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102422",
        "Station ID": 764,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 490700000,
        "Longitude": -1225500000,
        "Elevation (m)": 64,
        "First Year": 1987,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1997,
        "MLY First Year": 1987,
        "MLY Last Year": 1997
      },
      {
        "Name": "DELTA TSAWWASSEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102424",
        "Station ID": 765,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 490100000,
        "Longitude": -1230600000,
        "Elevation (m)": 53.3,
        "First Year": 1959,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1969,
        "MLY First Year": 1959,
        "MLY Last Year": 1969
      },
      {
        "Name": "DELTA TSAWWASSEN BEACH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102425",
        "Station ID": 766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.01,
        "Longitude (Decimal Degrees)": -123.09,
        "Latitude": 490039400,
        "Longitude": -1230536000,
        "Elevation (m)": 2.4,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEROCHE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102465",
        "Station ID": 767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.07,
        "Latitude": 491100000,
        "Longitude": -1220400000,
        "Elevation (m)": 61,
        "First Year": 1984,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1990,
        "MLY First Year": 1984,
        "MLY Last Year": 1990
      },
      {
        "Name": "ELBOW LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102650",
        "Station ID": 768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -121.97,
        "Latitude": 491700000,
        "Longitude": -1215800000,
        "Elevation (m)": 218,
        "First Year": 1980,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1989,
        "MLY First Year": 1981,
        "MLY Last Year": 1989
      },
      {
        "Name": "ESSONDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102725",
        "Station ID": 769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.82,
        "Latitude": 491500000,
        "Longitude": -1224900000,
        "Elevation (m)": 53.3,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "FORT LANGLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102910",
        "Station ID": 770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.62,
        "Latitude": 491200000,
        "Longitude": -1223700000,
        "Elevation (m)": 6.1,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1956
      },
      {
        "Name": "FORT LANGLEY TELEGRAPH TRAIL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102912",
        "Station ID": 43723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.14,
        "Longitude (Decimal Degrees)": -122.55,
        "Latitude": 490840800,
        "Longitude": -1223303600,
        "Elevation (m)": 76,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDENBANK SARDIS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1102F3H",
        "Station ID": 753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -121.97,
        "Latitude": 490800000,
        "Longitude": -1215800000,
        "Elevation (m)": 10,
        "First Year": 1977,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1997,
        "MLY First Year": 1977,
        "MLY Last Year": 1997
      },
      {
        "Name": "HANEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103322",
        "Station ID": 771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.6,
        "Latitude": 491300000,
        "Longitude": -1223600000,
        "Elevation (m)": 25.9,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "HANEY CORRL INSTN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103324",
        "Station ID": 772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 491500000,
        "Longitude": -1223100000,
        "Elevation (m)": 141.7,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "HANEY EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103326",
        "Station ID": 773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.56,
        "Latitude": 491215800,
        "Longitude": -1223329800,
        "Elevation (m)": 30.5,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "HANEY 532",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103327",
        "Station ID": 774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.62,
        "Latitude": 491500000,
        "Longitude": -1223700000,
        "Elevation (m)": 3,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "HANEY PINE RIDGE CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103329",
        "Station ID": 775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 491600000,
        "Longitude": -1223100000,
        "Elevation (m)": 150,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HANEY UBC RF ADMIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103332",
        "Station ID": 776,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.26,
        "Longitude (Decimal Degrees)": -122.57,
        "Latitude": 491552100,
        "Longitude": -1223423400,
        "Elevation (m)": 147,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "HANEY UBC RF LOON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103333",
        "Station ID": 777,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -122.58,
        "Latitude": 491800000,
        "Longitude": -1223500000,
        "Elevation (m)": 353.6,
        "First Year": 1962,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1972,
        "MLY First Year": 1962,
        "MLY Last Year": 1972
      },
      {
        "Name": "HANEY UBC RF SPUR 17",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103334",
        "Station ID": 778,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -122.55,
        "Latitude": 491800000,
        "Longitude": -1223300000,
        "Elevation (m)": 373.4,
        "First Year": 1961,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1972,
        "MLY First Year": 1961,
        "MLY Last Year": 1972
      },
      {
        "Name": "HATZIC LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103342",
        "Station ID": 779,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -122.25,
        "Latitude": 491000000,
        "Longitude": -1221500000,
        "Elevation (m)": 25,
        "First Year": 1959,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2000,
        "MLY First Year": 1959,
        "MLY Last Year": 2000
      },
      {
        "Name": "HEMLOCK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103440",
        "Station ID": 780,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -121.93,
        "Latitude": 492300000,
        "Longitude": -1215600000,
        "Elevation (m)": 976.9,
        "First Year": 1982,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1985,
        "MLY First Year": 1983,
        "MLY Last Year": 1985
      },
      {
        "Name": "HOLLYBURN RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103510",
        "Station ID": 781,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 492300000,
        "Longitude": -1231100000,
        "Elevation (m)": 930,
        "First Year": 1954,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1995,
        "MLY First Year": 1954,
        "MLY Last Year": 1995
      },
      {
        "Name": "HOWE SOUND STRACHAN CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110361F",
        "Station ID": 27006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -123.23,
        "Latitude": 492514000,
        "Longitude": -1231404000,
        "Elevation (m)": 20,
        "First Year": 1994,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2006,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "HUNTINGDON METER STATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103635",
        "Station ID": 782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -122.22,
        "Latitude": 490000000,
        "Longitude": -1221300000,
        "Elevation (m)": 7.6,
        "First Year": 1961,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1968,
        "MLY First Year": 1961,
        "MLY Last Year": 1968
      },
      {
        "Name": "HUNTINGDON VYE ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103636",
        "Station ID": 783,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.2,
        "Latitude": 490200000,
        "Longitude": -1221200000,
        "Elevation (m)": 25,
        "First Year": 1971,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1992,
        "MLY First Year": 1972,
        "MLY Last Year": 1992
      },
      {
        "Name": "INDIAN ARM CENTRAL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103647",
        "Station ID": 784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -122.88,
        "Latitude": 492300000,
        "Longitude": -1225300000,
        "Elevation (m)": 1,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "INDIAN ARM WOODLANDS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103648",
        "Station ID": 785,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 492000000,
        "Longitude": -1225500000,
        "Elevation (m)": 15,
        "First Year": 1987,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2001,
        "MLY First Year": 1987,
        "MLY Last Year": 2001
      },
      {
        "Name": "IOCO REFINERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1103660",
        "Station ID": 786,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -122.88,
        "Latitude": 491800000,
        "Longitude": -1225300000,
        "Elevation (m)": 53.3,
        "First Year": 1916,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1995,
        "MLY First Year": 1916,
        "MLY Last Year": 1995
      },
      {
        "Name": "KENSINGTON PRAIRIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104080",
        "Station ID": 788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 490400000,
        "Longitude": -1224700000,
        "Elevation (m)": 27.4,
        "First Year": 1953,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1978,
        "MLY First Year": 1953,
        "MLY Last Year": 1978
      },
      {
        "Name": "LADNER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104468",
        "Station ID": 789,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 490500000,
        "Longitude": -1230400000,
        "Elevation (m)": 9.1,
        "First Year": 1878,
        "Last Year": 1934,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1934,
        "MLY First Year": 1878,
        "MLY Last Year": 1934
      },
      {
        "Name": "LADNER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104470",
        "Station ID": 790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -123.02,
        "Latitude": 490500000,
        "Longitude": -1230100000,
        "Elevation (m)": 1.2,
        "First Year": 1952,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1971,
        "MLY First Year": 1952,
        "MLY Last Year": 1971
      },
      {
        "Name": "LADNER BCHPA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104473",
        "Station ID": 791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 490500000,
        "Longitude": -1230300000,
        "Elevation (m)": 1.5,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1975,
        "MLY Last Year": 1978
      },
      {
        "Name": "LADNER MONITOR STN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104477",
        "Station ID": 792,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 490400000,
        "Longitude": -1230700000,
        "Elevation (m)": null,
        "First Year": 1960,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1971,
        "MLY First Year": 1960,
        "MLY Last Year": 1971
      },
      {
        "Name": "LADNER PORT GUICHON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104484",
        "Station ID": 793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 490500000,
        "Longitude": -1230600000,
        "Elevation (m)": null,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "LAIDLAW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104488",
        "Station ID": 794,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -121.58,
        "Latitude": 492123000,
        "Longitude": -1213446070,
        "Elevation (m)": 37,
        "First Year": 1977,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2017,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "LANGLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104550",
        "Station ID": 795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.53,
        "Latitude": 491100000,
        "Longitude": -1223200000,
        "Elevation (m)": null,
        "First Year": 1878,
        "Last Year": 1900,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1900,
        "MLY First Year": 1878,
        "MLY Last Year": 1900
      },
      {
        "Name": "LANGLEY CAMPBELL R PK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104553",
        "Station ID": 796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.67,
        "Latitude": 490200000,
        "Longitude": -1224000000,
        "Elevation (m)": 56,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1987,
        "MLY Last Year": 1996
      },
      {
        "Name": "LANGLEY CENTRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104554",
        "Station ID": 797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -122.62,
        "Latitude": 490500000,
        "Longitude": -1223700000,
        "Elevation (m)": 59.4,
        "First Year": 1988,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1994,
        "MLY First Year": 1988,
        "MLY Last Year": 1994
      },
      {
        "Name": "LANGLEY LOCHIEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104555",
        "Station ID": 798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -122.58,
        "Latitude": 490300000,
        "Longitude": -1223500000,
        "Elevation (m)": 100.9,
        "First Year": 1957,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1986,
        "MLY First Year": 1957,
        "MLY Last Year": 1986
      },
      {
        "Name": "LANGLEY PRAIRIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104560",
        "Station ID": 799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -122.65,
        "Latitude": 490900000,
        "Longitude": -1223900000,
        "Elevation (m)": 86.9,
        "First Year": 1953,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1983,
        "MLY First Year": 1953,
        "MLY Last Year": 1983
      },
      {
        "Name": "LANGLEY RIVER ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104562",
        "Station ID": 800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -122.53,
        "Latitude": 491000000,
        "Longitude": -1223200000,
        "Elevation (m)": 15,
        "First Year": 1987,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1999,
        "MLY First Year": 1987,
        "MLY Last Year": 1999
      },
      {
        "Name": "LANGLEY WELLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104565",
        "Station ID": 801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -122.67,
        "Latitude": 490400000,
        "Longitude": -1224000000,
        "Elevation (m)": 45.7,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "LANGLEY 96 AVENUE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104569",
        "Station ID": 802,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.6,
        "Latitude": 491100000,
        "Longitude": -1223600000,
        "Elevation (m)": 13,
        "First Year": 1987,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1987,
        "MLY First Year": 1987,
        "MLY Last Year": 1987
      },
      {
        "Name": "LANGLEY SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110456B",
        "Station ID": 26820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.55,
        "Latitude": 490200000,
        "Longitude": -1223300000,
        "Elevation (m)": 61,
        "First Year": 1995,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 1996,
        "MLY First Year": 1995,
        "MLY Last Year": 1996
      },
      {
        "Name": "LIONS BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104634",
        "Station ID": 803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -123.24,
        "Latitude": 492712000,
        "Longitude": -1231419000,
        "Elevation (m)": 52,
        "First Year": 1983,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2004,
        "MLY First Year": 1983,
        "MLY Last Year": 2004
      },
      {
        "Name": "MAPLE RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104902",
        "Station ID": 804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -122.62,
        "Latitude": 491400000,
        "Longitude": -1223700000,
        "Elevation (m)": 23,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "MAPLE RIDGE FERGUSON AVENUE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104910",
        "Station ID": 53278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 491234005,
        "Longitude": -1222851001,
        "Elevation (m)": 150,
        "First Year": 2015,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2015,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MATSQUI",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104927",
        "Station ID": 805,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -122.3,
        "Latitude": 490600000,
        "Longitude": -1221800000,
        "Elevation (m)": 5,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "MATSQUI ROSS ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104928",
        "Station ID": 806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -122.4,
        "Latitude": 490400000,
        "Longitude": -1222400000,
        "Elevation (m)": 120,
        "First Year": 1987,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1991,
        "MLY First Year": 1987,
        "MLY Last Year": 1991
      },
      {
        "Name": "MAPLE RIDGE KANAKA CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1104R02",
        "Station ID": 787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -122.51,
        "Latitude": 491240000,
        "Longitude": -1223024000,
        "Elevation (m)": 76,
        "First Year": 1986,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2018,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILNER AIC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105155",
        "Station ID": 808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -122.62,
        "Latitude": 490800000,
        "Longitude": -1223700000,
        "Elevation (m)": 7.6,
        "First Year": 1967,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1979,
        "MLY First Year": 1967,
        "MLY Last Year": 1979
      },
      {
        "Name": "MISSION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105190",
        "Station ID": 809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -122.33,
        "Latitude": 490800000,
        "Longitude": -1222000000,
        "Elevation (m)": 60,
        "First Year": 1953,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1992,
        "MLY First Year": 1953,
        "MLY Last Year": 1992
      },
      {
        "Name": "MISSION WEST ABBEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105192",
        "Station ID": 810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -122.27,
        "Latitude": 490909002,
        "Longitude": -1221614001,
        "Elevation (m)": 197,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "RICHMOND OPERATIONS CENTRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105210",
        "Station ID": 48448,
        "WMO ID": 71112,
        "TC ID": "VMO",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 491054840,
        "Longitude": -1230441160,
        "Elevation (m)": 16,
        "First Year": 2010,
        "Last Year": 2018,
        "HLY First Year": 2010,
        "HLY Last Year": 2018,
        "DLY First Year": 2010,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOUNT SEYMOUR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105230",
        "Station ID": 811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 492600000,
        "Longitude": -1225700000,
        "Elevation (m)": 823,
        "First Year": 1958,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1968,
        "MLY First Year": 1958,
        "MLY Last Year": 1968
      },
      {
        "Name": "NEW WESTMINSTER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105550",
        "Station ID": 812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 491300000,
        "Longitude": -1225600000,
        "Elevation (m)": 118.9,
        "First Year": 1874,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1966,
        "MLY First Year": 1874,
        "MLY Last Year": 1966
      },
      {
        "Name": "NEW WESTMINSTER BC PEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105553",
        "Station ID": 813,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.9,
        "Latitude": 491300000,
        "Longitude": -1225400000,
        "Elevation (m)": 18.3,
        "First Year": 1960,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1980,
        "MLY First Year": 1960,
        "MLY Last Year": 1980
      },
      {
        "Name": "NEW WESTM QUEENS PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105560",
        "Station ID": 814,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.92,
        "Latitude": 491300000,
        "Longitude": -1225500000,
        "Elevation (m)": 100.6,
        "First Year": 1958,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "NEW WESTMINSTER WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105570",
        "Station ID": 815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.93,
        "Latitude": 491300000,
        "Longitude": -1225600000,
        "Elevation (m)": 83.8,
        "First Year": 1960,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1971,
        "MLY First Year": 1960,
        "MLY Last Year": 1971
      },
      {
        "Name": "NORTH BURNABY UNION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105627",
        "Station ID": 817,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 491700000,
        "Longitude": -1225700000,
        "Elevation (m)": 121.9,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "NORTH NICOMEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105630",
        "Station ID": 818,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.03,
        "Latitude": 491200000,
        "Longitude": -1220200000,
        "Elevation (m)": 18,
        "First Year": 1897,
        "Last Year": 1928,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1928,
        "MLY First Year": 1897,
        "MLY Last Year": 1928
      },
      {
        "Name": "N VANCOUVER CANYON HTS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105652",
        "Station ID": 819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 492200000,
        "Longitude": -1230600000,
        "Elevation (m)": 208.8,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "N VANCOUVER CAPILANO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105655",
        "Station ID": 820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 492100000,
        "Longitude": -1230700000,
        "Elevation (m)": 93,
        "First Year": 1955,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1990,
        "MLY First Year": 1955,
        "MLY Last Year": 1990
      },
      {
        "Name": "NORTH VANCOUVER CITY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105656",
        "Station ID": 821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 491900000,
        "Longitude": -1230500000,
        "Elevation (m)": 88.4,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "N VANCOUVER GLENAIRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105657",
        "Station ID": 822,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 492000000,
        "Longitude": -1230700000,
        "Elevation (m)": 15.2,
        "First Year": 1964,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "N VANC GROUSE MTN RESORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105658",
        "Station ID": 823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 492252002,
        "Longitude": -1230442004,
        "Elevation (m)": 1103,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORTH VANCOUVER HOLYROOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105659",
        "Station ID": 824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 492100000,
        "Longitude": -1230500000,
        "Elevation (m)": 182.9,
        "First Year": 1960,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1968,
        "MLY First Year": 1960,
        "MLY Last Year": 1968
      },
      {
        "Name": "N VANCOUVER LYNN CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105660",
        "Station ID": 825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 492200000,
        "Longitude": -1230200000,
        "Elevation (m)": 190.5,
        "First Year": 1964,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1983,
        "MLY First Year": 1964,
        "MLY Last Year": 1983
      },
      {
        "Name": "N VANCOUVER LYNN VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105661",
        "Station ID": 826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 492100000,
        "Longitude": -1230200000,
        "Elevation (m)": 152.4,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1964
      },
      {
        "Name": "N VANCOUVER MOSQUITO CR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105663",
        "Station ID": 827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 492100000,
        "Longitude": -1230500000,
        "Elevation (m)": 344.4,
        "First Year": 1954,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1960,
        "MLY First Year": 1954,
        "MLY Last Year": 1960
      },
      {
        "Name": "NORTH VANCOUVER NORGATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105664",
        "Station ID": 828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 491900000,
        "Longitude": -1230700000,
        "Elevation (m)": 4.6,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "N VANCOUVER N LONSDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105665",
        "Station ID": 829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 492106000,
        "Longitude": -1230405000,
        "Elevation (m)": 308,
        "First Year": 1960,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2004,
        "MLY First Year": 1960,
        "MLY Last Year": 2004
      },
      {
        "Name": "N VANCOUVER 2ND NARROWS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105666",
        "Station ID": 830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -123.01,
        "Latitude": 491752600,
        "Longitude": -1230053700,
        "Elevation (m)": 4,
        "First Year": 1957,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2008,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "N VANCOUVER UPR LONSDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105667",
        "Station ID": 831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 492100000,
        "Longitude": -1230400000,
        "Elevation (m)": 350.5,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "N VANCOUVER UPPER LYNN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105668",
        "Station ID": 832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 492100000,
        "Longitude": -1230200000,
        "Elevation (m)": 176.8,
        "First Year": 1960,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1980,
        "MLY First Year": 1960,
        "MLY Last Year": 1980
      },
      {
        "Name": "N VANCOUVER WHARVES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105669",
        "Station ID": 833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.31,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 491853220,
        "Longitude": -1230655000,
        "Elevation (m)": 7,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "N VANCOUVER DOLLARTON 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1105F5F",
        "Station ID": 807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 491900000,
        "Longitude": -1225700000,
        "Elevation (m)": 109.7,
        "First Year": 1990,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1993,
        "MLY First Year": 1990,
        "MLY Last Year": 1993
      },
      {
        "Name": "PITT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106174",
        "Station ID": 839,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 492600000,
        "Longitude": -1223100000,
        "Elevation (m)": 7.6,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "PITT MEADOWS A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106177",
        "Station ID": 840,
        "WMO ID": null,
        "TC ID": "YPK",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.71,
        "Latitude": 491258000,
        "Longitude": -1224246000,
        "Elevation (m)": 3.35,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "PITT MEADOWS CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106178",
        "Station ID": 6830,
        "WMO ID": 71775,
        "TC ID": "WMM",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -122.69,
        "Latitude": 491229964,
        "Longitude": -1224124076,
        "Elevation (m)": 5,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "PITT MEADOWS LOUGHEED",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106179",
        "Station ID": 841,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -122.68,
        "Latitude": 491400000,
        "Longitude": -1224100000,
        "Elevation (m)": 6.1,
        "First Year": 1960,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1969,
        "MLY First Year": 1960,
        "MLY Last Year": 1969
      },
      {
        "Name": "PITT POLDER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106180",
        "Station ID": 842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.63,
        "Latitude": 491623600,
        "Longitude": -1223750200,
        "Elevation (m)": 5,
        "First Year": 1951,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2010,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "PITT RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106190",
        "Station ID": 843,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 491500000,
        "Longitude": -1224400000,
        "Elevation (m)": 5.5,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1957,
        "MLY Last Year": 1959
      },
      {
        "Name": "POINT ATKINSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106200",
        "Station ID": 844,
        "WMO ID": 71037,
        "TC ID": "WSB",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.26,
        "Latitude": 491949300,
        "Longitude": -1231553000,
        "Elevation (m)": 14,
        "First Year": 1968,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1968,
        "DLY Last Year": 2022,
        "MLY First Year": 1968,
        "MLY Last Year": 2001
      },
      {
        "Name": "PORT COQUITLAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106255",
        "Station ID": 845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 491600000,
        "Longitude": -1224700000,
        "Elevation (m)": 12,
        "First Year": 1958,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1990,
        "MLY First Year": 1958,
        "MLY Last Year": 1990
      },
      {
        "Name": "PORT COQUITLAM CITY YARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106256",
        "Station ID": 846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 491600000,
        "Longitude": -1224700000,
        "Elevation (m)": 6.7,
        "First Year": 1971,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1990,
        "MLY First Year": 1971,
        "MLY Last Year": 1990
      },
      {
        "Name": "PRT COQUITLAM PRAIRIE RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106257",
        "Station ID": 847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.73,
        "Latitude": 491600000,
        "Longitude": -1224400000,
        "Elevation (m)": 2.7,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "PORT KELLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106300",
        "Station ID": 849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -122.7,
        "Latitude": 490900000,
        "Longitude": -1224200000,
        "Elevation (m)": 9.1,
        "First Year": 1953,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1965,
        "MLY First Year": 1953,
        "MLY Last Year": 1965
      },
      {
        "Name": "PORT MOODY CENTRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11063L2",
        "Station ID": 848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491700000,
        "Longitude": -1225100000,
        "Elevation (m)": 23,
        "First Year": 1970,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1989,
        "MLY First Year": 1970,
        "MLY Last Year": 1989
      },
      {
        "Name": "REIDVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106720",
        "Station ID": 850,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 490700000,
        "Longitude": -1225100000,
        "Elevation (m)": 99.1,
        "First Year": 1953,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1958,
        "MLY First Year": 1953,
        "MLY Last Year": 1958
      },
      {
        "Name": "RICHMOND CBC TRANSMITTER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106762",
        "Station ID": 851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 490900000,
        "Longitude": -1230700000,
        "Elevation (m)": 1.5,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "RICHMOND BROADMOOR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106763",
        "Station ID": 852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 490800000,
        "Longitude": -1230800000,
        "Elevation (m)": 1,
        "First Year": 1986,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1993,
        "MLY First Year": 1986,
        "MLY Last Year": 1993
      },
      {
        "Name": "RICHMOND DALLYN 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106764",
        "Station ID": 853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -123.09,
        "Latitude": 491043000,
        "Longitude": -1230513000,
        "Elevation (m)": 1.8,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "RICHMOND EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106765",
        "Station ID": 854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.97,
        "Latitude": 491100000,
        "Longitude": -1225800000,
        "Elevation (m)": 0.6,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "RICHMOND MCLENNAN ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106766",
        "Station ID": 855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 491200000,
        "Longitude": -1230600000,
        "Elevation (m)": 1.8,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "RICHMOND MINLER ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106767",
        "Station ID": 856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 490900000,
        "Longitude": -1230900000,
        "Elevation (m)": 1.2,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "RICHMOND NELSON RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106768",
        "Station ID": 857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -123.02,
        "Latitude": 491000000,
        "Longitude": -1230100000,
        "Elevation (m)": 1.8,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "RICHMOND RIVER ROAD EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106770",
        "Station ID": 858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 491200000,
        "Longitude": -1230200000,
        "Elevation (m)": 2.1,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "ROSEDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106865",
        "Station ID": 859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -121.8,
        "Latitude": 491100000,
        "Longitude": -1214800000,
        "Elevation (m)": 10.7,
        "First Year": 1967,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1988,
        "MLY First Year": 1967,
        "MLY Last Year": 1988
      },
      {
        "Name": "RUSKIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106910",
        "Station ID": 860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -122.42,
        "Latitude": 491000000,
        "Longitude": -1222500000,
        "Elevation (m)": 30.5,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "PORT MOODY GLENAYRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106CL2",
        "Station ID": 834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.88,
        "Latitude": 491645000,
        "Longitude": -1225253000,
        "Elevation (m)": 129.5,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "RICHMOND BROADMOOR 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106GF3",
        "Station ID": 835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 490900000,
        "Longitude": -1230700000,
        "Elevation (m)": 2,
        "First Year": 1990,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1993,
        "MLY First Year": 1990,
        "MLY Last Year": 1993
      },
      {
        "Name": "PORT MOODY MERIDIAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106L3K",
        "Station ID": 836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -122.8,
        "Latitude": 491800000,
        "Longitude": -1224800000,
        "Elevation (m)": 325.5,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "RICHMOND GEAL RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106P6N",
        "Station ID": 838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 490900000,
        "Longitude": -1231000000,
        "Elevation (m)": 1.2,
        "First Year": 1976,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1988,
        "MLY First Year": 1976,
        "MLY Last Year": 1988
      },
      {
        "Name": "RICHMOND NATURE PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1106PF7",
        "Station ID": 837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -123.09,
        "Latitude": 491015000,
        "Longitude": -1230535000,
        "Elevation (m)": 3,
        "First Year": 1977,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2022,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "SANDHEADS CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107010",
        "Station ID": 6831,
        "WMO ID": 71209,
        "TC ID": "WVF",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -123.3,
        "Latitude": 490621225,
        "Longitude": -1231812123,
        "Elevation (m)": 11,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 1999
      },
      {
        "Name": "SARDIS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107080",
        "Station ID": 864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -121.89,
        "Latitude": 490455000,
        "Longitude": -1215341000,
        "Elevation (m)": 106.7,
        "First Year": 1954,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2010,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "SARDIS 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107083",
        "Station ID": 6832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -121.95,
        "Latitude": 490700000,
        "Longitude": -1215700000,
        "Elevation (m)": 24,
        "First Year": 1991,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1994,
        "MLY First Year": 1991,
        "MLY Last Year": 1994
      },
      {
        "Name": "SEYMOUR CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107195",
        "Station ID": 865,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 492500000,
        "Longitude": -1225700000,
        "Elevation (m)": 177.7,
        "First Year": 1924,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1947,
        "MLY First Year": 1924,
        "MLY Last Year": 1947
      },
      {
        "Name": "SEYMOUR FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107200",
        "Station ID": 866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.44,
        "Longitude (Decimal Degrees)": -122.97,
        "Latitude": 492625000,
        "Longitude": -1225816000,
        "Elevation (m)": 244,
        "First Year": 1927,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 2003,
        "MLY First Year": 1927,
        "MLY Last Year": 2003
      },
      {
        "Name": "STAVE FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107680",
        "Station ID": 867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -122.37,
        "Latitude": 491400000,
        "Longitude": -1222200000,
        "Elevation (m)": 110,
        "First Year": 1909,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 2004,
        "MLY First Year": 1909,
        "MLY Last Year": 2004
      },
      {
        "Name": "STAVE LAKE SEVENTYNINE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107689",
        "Station ID": 868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.33,
        "Latitude": 491700000,
        "Longitude": -1222000000,
        "Elevation (m)": 106,
        "First Year": 1987,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1995,
        "MLY First Year": 1987,
        "MLY Last Year": 1995
      },
      {
        "Name": "STEVESTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107710",
        "Station ID": 869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -123.19,
        "Latitude": 490750000,
        "Longitude": -1231119000,
        "Elevation (m)": null,
        "First Year": 1896,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 2004,
        "MLY First Year": 1896,
        "MLY Last Year": 2004
      },
      {
        "Name": "SUMAS CANAL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107785",
        "Station ID": 870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -122.11,
        "Latitude": 490648008,
        "Longitude": -1220635004,
        "Elevation (m)": 9,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUMAS PRAIRIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107788",
        "Station ID": 871,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -122.13,
        "Latitude": 490300000,
        "Longitude": -1220800000,
        "Elevation (m)": 3,
        "First Year": 1969,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1976,
        "MLY First Year": 1970,
        "MLY Last Year": 1976
      },
      {
        "Name": "SUMAS PRAIRIE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107790",
        "Station ID": 872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -122.15,
        "Latitude": 490300000,
        "Longitude": -1220900000,
        "Elevation (m)": 10.1,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "SURREY KWANTLEN PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107873",
        "Station ID": 874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.19,
        "Longitude (Decimal Degrees)": -122.86,
        "Latitude": 491132000,
        "Longitude": -1225135050,
        "Elevation (m)": 78,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "SURREY MUNICIPAL HALL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107876",
        "Station ID": 875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -122.83,
        "Latitude": 490624050,
        "Longitude": -1224939040,
        "Elevation (m)": 76,
        "First Year": 1962,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2006,
        "MLY First Year": 1962,
        "MLY Last Year": 2006
      },
      {
        "Name": "SURREY NEWTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107878",
        "Station ID": 876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 490800000,
        "Longitude": -1225050000,
        "Elevation (m)": 73.2,
        "First Year": 1960,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2000,
        "MLY First Year": 1960,
        "MLY Last Year": 2000
      },
      {
        "Name": "SURREY SUNNYSIDE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107879",
        "Station ID": 877,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -122.77,
        "Latitude": 490300000,
        "Longitude": -1224600000,
        "Elevation (m)": 95.1,
        "First Year": 1960,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1983,
        "MLY First Year": 1960,
        "MLY Last Year": 1983
      },
      {
        "Name": "SURREY BRIDGEVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11078GK",
        "Station ID": 873,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.87,
        "Latitude": 491300000,
        "Longitude": -1225200000,
        "Elevation (m)": 9.1,
        "First Year": 1971,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1971,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "SURREY TYNEHEAD B",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107H79",
        "Station ID": 11002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 491219000,
        "Longitude": -1224635000,
        "Elevation (m)": 77.1,
        "First Year": 1994,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2005,
        "MLY First Year": 1994,
        "MLY Last Year": 2005
      },
      {
        "Name": "SURREY 99A AVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107H7H",
        "Station ID": 861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491100000,
        "Longitude": -1225100000,
        "Elevation (m)": 96,
        "First Year": 1974,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1993,
        "MLY First Year": 1974,
        "MLY Last Year": 1993
      },
      {
        "Name": "SURREY WHITE ROCK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107H7R",
        "Station ID": 862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.77,
        "Latitude": 490200000,
        "Longitude": -1224600000,
        "Elevation (m)": 54.9,
        "First Year": 1980,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1987,
        "MLY First Year": 1980,
        "MLY Last Year": 1987
      },
      {
        "Name": "SURREY 104 AVE 164 ST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1107QP8",
        "Station ID": 863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.77,
        "Latitude": 491200000,
        "Longitude": -1224600000,
        "Elevation (m)": 65.5,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "TSAWWASSEN FERRY AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108291",
        "Station ID": 50228,
        "WMO ID": null,
        "TC ID": "VTF",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 490014080,
        "Longitude": -1230800040,
        "Elevation (m)": 7.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VANCOUVER SEA ISLAND CCG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108380",
        "Station ID": 51357,
        "WMO ID": 71608,
        "TC ID": "VVR",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -123.19,
        "Latitude": 491057000,
        "Longitude": -1231114050,
        "Elevation (m)": 2.11,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VANCOUVER INTL A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108395",
        "Station ID": 51442,
        "WMO ID": 71892,
        "TC ID": "YVR",
        "Latitude (Decimal Degrees)": 49.19,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 491141000,
        "Longitude": -1231102000,
        "Elevation (m)": 4.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VANCOUVER CEDAR COTTAGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108410",
        "Station ID": 880,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491500000,
        "Longitude": -1230300000,
        "Elevation (m)": 79.2,
        "First Year": 1972,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "VANCOUVER CENTRAL PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108414",
        "Station ID": 881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.02,
        "Latitude": 491400000,
        "Longitude": -1230100000,
        "Elevation (m)": 121.9,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "VANCOUVER INT'L HWOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108425",
        "Station ID": 52218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.19,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 491141000,
        "Longitude": -1231102000,
        "Elevation (m)": 4.3,
        "First Year": 2014,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2014,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VANCOUVER CITY HALL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108430",
        "Station ID": 882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 491700000,
        "Longitude": -1230700000,
        "Elevation (m)": 86.3,
        "First Year": 1924,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1987,
        "MLY First Year": 1924,
        "MLY Last Year": 1987
      },
      {
        "Name": "VANCOUVER HILLCREST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108433",
        "Station ID": 48328,
        "WMO ID": null,
        "TC ID": "VWC",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 491400648,
        "Longitude": -1230600376,
        "Elevation (m)": 83.9,
        "First Year": 2009,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": 2009,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VANCOUVER DUNBAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108435",
        "Station ID": 883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 491400000,
        "Longitude": -1231100000,
        "Elevation (m)": 61,
        "First Year": 1955,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1974,
        "MLY First Year": 1955,
        "MLY Last Year": 1974
      },
      {
        "Name": "VANCOUVER DUNBAR SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108436",
        "Station ID": 884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 491500000,
        "Longitude": -1231100000,
        "Elevation (m)": 61,
        "First Year": 1966,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1982
      },
      {
        "Name": "VANCOUVER EXHIB PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108440",
        "Station ID": 885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491700000,
        "Longitude": -1230300000,
        "Elevation (m)": 35.1,
        "First Year": 1955,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1964,
        "MLY First Year": 1955,
        "MLY Last Year": 1964
      },
      {
        "Name": "VANCOUVER FAIRVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108442",
        "Station ID": 886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 491600000,
        "Longitude": -1230800000,
        "Elevation (m)": 35.1,
        "First Year": 1957,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1958,
        "MLY First Year": 1957,
        "MLY Last Year": 1958
      },
      {
        "Name": "VANCOUVER FRASERVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108445",
        "Station ID": 887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491200000,
        "Longitude": -1230300000,
        "Elevation (m)": 85.3,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "VANCOUVER HARBOUR CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108446",
        "Station ID": 888,
        "WMO ID": 71201,
        "TC ID": "WHC",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 491743270,
        "Longitude": -1230718730,
        "Elevation (m)": 2.5,
        "First Year": 1925,
        "Last Year": 2022,
        "HLY First Year": 1976,
        "HLY Last Year": 2022,
        "DLY First Year": 1925,
        "DLY Last Year": 2022,
        "MLY First Year": 1925,
        "MLY Last Year": 2007
      },
      {
        "Name": "VANCOUVER INT'L A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108447",
        "Station ID": 889,
        "WMO ID": null,
        "TC ID": "YVR",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 491142000,
        "Longitude": -1231055000,
        "Elevation (m)": 4.3,
        "First Year": 1937,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1937,
        "DLY Last Year": 2013,
        "MLY First Year": 1937,
        "MLY Last Year": 2013
      },
      {
        "Name": "VANCOUVER KERRISDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108448",
        "Station ID": 890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 491400000,
        "Longitude": -1231000000,
        "Elevation (m)": 45.7,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "VANCOUVER KERRISDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108449",
        "Station ID": 891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 491400000,
        "Longitude": -1230800000,
        "Elevation (m)": 88.4,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "VANCOUVER KINGCREST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108450",
        "Station ID": 892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 491500000,
        "Longitude": -1230500000,
        "Elevation (m)": 65.5,
        "First Year": 1955,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1961,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "VANCOUVER KITSILANO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108453",
        "Station ID": 893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 491600000,
        "Longitude": -1231000000,
        "Elevation (m)": 12,
        "First Year": 1956,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1990,
        "MLY First Year": 1956,
        "MLY Last Year": 1990
      },
      {
        "Name": "VANCOUVER MARPOLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108455",
        "Station ID": 894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 491200000,
        "Longitude": -1230800000,
        "Elevation (m)": 30.5,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "VANCOUVER MT PLEASANT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108460",
        "Station ID": 895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 491600000,
        "Longitude": -1230600000,
        "Elevation (m)": 48.8,
        "First Year": 1955,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1956,
        "MLY First Year": 1955,
        "MLY Last Year": 1956
      },
      {
        "Name": "VANCOUVER OAK 53",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108462",
        "Station ID": 896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 491300000,
        "Longitude": -1230800000,
        "Elevation (m)": 82.3,
        "First Year": 1970,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "VANCOUVER OAKRIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108463",
        "Station ID": 897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 491405000,
        "Longitude": -1230712000,
        "Elevation (m)": 76,
        "First Year": 1960,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2008,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "VANCOUVER PMO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108465",
        "Station ID": 898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 491700000,
        "Longitude": -1230700000,
        "Elevation (m)": 59.4,
        "First Year": 1898,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 1979,
        "MLY First Year": 1898,
        "MLY Last Year": 1979
      },
      {
        "Name": "VANCOUVER QUILCHENA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108470",
        "Station ID": 899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 491400000,
        "Longitude": -1230900000,
        "Elevation (m)": 83.8,
        "First Year": 1955,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1961,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "VANCOUVER RUPERT ST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108471",
        "Station ID": 900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 491400000,
        "Longitude": -1230200000,
        "Elevation (m)": 91.1,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "VANCOUVER SOUTHLANDS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108473",
        "Station ID": 901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 491339000,
        "Longitude": -1231042000,
        "Elevation (m)": 8,
        "First Year": 1960,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2004,
        "MLY First Year": 1960,
        "MLY Last Year": 2004
      },
      {
        "Name": "VANCOUVER SOUTH FRASER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108475",
        "Station ID": 902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 491300000,
        "Longitude": -1230400000,
        "Elevation (m)": 64,
        "First Year": 1955,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1972,
        "MLY First Year": 1955,
        "MLY Last Year": 1972
      },
      {
        "Name": "VANCOUVER TRAFALGAR PK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108482",
        "Station ID": 27093,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 491600000,
        "Longitude": -1230900000,
        "Elevation (m)": 42,
        "First Year": 1995,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 1999,
        "MLY First Year": 1995,
        "MLY Last Year": 1999
      },
      {
        "Name": "VANCOUVER UBC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108487",
        "Station ID": 903,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -123.25,
        "Latitude": 491500000,
        "Longitude": -1231500000,
        "Elevation (m)": 76,
        "First Year": 1957,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1995,
        "MLY First Year": 1957,
        "MLY Last Year": 1995
      },
      {
        "Name": "VANCOUVER WEST PT GREY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108488",
        "Station ID": 904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.22,
        "Latitude": 491600000,
        "Longitude": -1231300000,
        "Elevation (m)": 61,
        "First Year": 1963,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1966
      },
      {
        "Name": "VANCOUVER WPG BYNG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108489",
        "Station ID": 905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.2,
        "Latitude": 491600000,
        "Longitude": -1231200000,
        "Elevation (m)": 76.2,
        "First Year": 1968,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1970,
        "MLY First Year": 1968,
        "MLY Last Year": 1970
      },
      {
        "Name": "VEDDER SOUTH LICKMAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108530",
        "Station ID": 906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -122,
        "Latitude": 490600000,
        "Longitude": -1220000000,
        "Elevation (m)": 15.2,
        "First Year": 1964,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1968,
        "MLY First Year": 1964,
        "MLY Last Year": 1968
      },
      {
        "Name": "WEST VANCOUVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108820",
        "Station ID": 907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 492000000,
        "Longitude": -1231000000,
        "Elevation (m)": 28.7,
        "First Year": 1953,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1961,
        "MLY First Year": 1953,
        "MLY Last Year": 1961
      },
      {
        "Name": "W VANC BALLANTREE PLACE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108823",
        "Station ID": 908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 492200000,
        "Longitude": -1230800000,
        "Elevation (m)": 289.6,
        "First Year": 1972,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1988,
        "MLY First Year": 1972,
        "MLY Last Year": 1988
      },
      {
        "Name": "WEST VANCOUVER AUT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108824",
        "Station ID": 6833,
        "WMO ID": 71784,
        "TC ID": "WWA",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.19,
        "Latitude": 492049350,
        "Longitude": -1231135910,
        "Elevation (m)": 170.21,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "W VANC CAPILANO GCC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108825",
        "Station ID": 909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 492100000,
        "Longitude": -1230700000,
        "Elevation (m)": 200.9,
        "First Year": 1976,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1998,
        "MLY First Year": 1976,
        "MLY Last Year": 1998
      },
      {
        "Name": "W VANCOUVER COPPER COVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108826",
        "Station ID": 910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -123.28,
        "Latitude": 492300000,
        "Longitude": -1231700000,
        "Elevation (m)": 45.7,
        "First Year": 1959,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1972,
        "MLY First Year": 1960,
        "MLY Last Year": 1972
      },
      {
        "Name": "W VANCOUVER CYPRESS PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108828",
        "Station ID": 911,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.25,
        "Latitude": 492100000,
        "Longitude": -1231500000,
        "Elevation (m)": 155,
        "First Year": 1957,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1994,
        "MLY First Year": 1957,
        "MLY Last Year": 1994
      },
      {
        "Name": "W VANCOUVER DUNDARAVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108829",
        "Station ID": 912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 492000000,
        "Longitude": -1231100000,
        "Elevation (m)": 2,
        "First Year": 1971,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1986,
        "MLY First Year": 1971,
        "MLY Last Year": 1986
      },
      {
        "Name": "WEST VANCOUVER KEW BEACH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108831",
        "Station ID": 913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.25,
        "Latitude": 492100000,
        "Longitude": -1231500000,
        "Elevation (m)": 64,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "WEST VANCOUVER MACBETH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108835",
        "Station ID": 914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 492000000,
        "Longitude": -1230700000,
        "Elevation (m)": 54.9,
        "First Year": 1955,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1973,
        "MLY First Year": 1955,
        "MLY Last Year": 1973
      },
      {
        "Name": "WEST VANCOUVER MATHERS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108837",
        "Station ID": 915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.18,
        "Latitude": 492000000,
        "Longitude": -1231100000,
        "Elevation (m)": 45.7,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "W VANCOUVER MILLSTREAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108840",
        "Station ID": 916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 492200000,
        "Longitude": -1230800000,
        "Elevation (m)": 381,
        "First Year": 1961,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1992,
        "MLY First Year": 1961,
        "MLY Last Year": 1992
      },
      {
        "Name": "WEST VANCOUVER OTTAWA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108844",
        "Station ID": 917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 492000000,
        "Longitude": -1231000000,
        "Elevation (m)": 121.9,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "W VANCOUVER PALMERSTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108846",
        "Station ID": 918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 492000000,
        "Longitude": -1230900000,
        "Elevation (m)": 121.9,
        "First Year": 1961,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1972,
        "MLY First Year": 1961,
        "MLY Last Year": 1972
      },
      {
        "Name": "WEST VANCOUVER RENA CRES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108848",
        "Station ID": 919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 492000000,
        "Longitude": -1230900000,
        "Elevation (m)": 146.3,
        "First Year": 1962,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1975,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "WHALLEY BALL PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108885",
        "Station ID": 920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491200000,
        "Longitude": -1225100000,
        "Elevation (m)": 80.8,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "WHALLEY BOLIVAR CRESCENT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108886",
        "Station ID": 921,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491200000,
        "Longitude": -1225100000,
        "Elevation (m)": 61,
        "First Year": 1959,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "WHALLEY FOREST NURSERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108890",
        "Station ID": 922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.83,
        "Latitude": 491100000,
        "Longitude": -1225000000,
        "Elevation (m)": 83.8,
        "First Year": 1958,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1994,
        "MLY First Year": 1958,
        "MLY Last Year": 1994
      },
      {
        "Name": "WHALLEY HILTON ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108892",
        "Station ID": 923,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491200000,
        "Longitude": -1225100000,
        "Elevation (m)": 91.4,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "WHISTLER ROUNDHOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108906",
        "Station ID": 924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500404000,
        "Longitude": -1225650000,
        "Elevation (m)": 1835,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITE ROCK CAMPBELL SCIENTIFIC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108910",
        "Station ID": 925,
        "WMO ID": 71785,
        "TC ID": "WWK",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 490105000,
        "Longitude": -1224702000,
        "Elevation (m)": 13,
        "First Year": 1929,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1929,
        "DLY Last Year": 2022,
        "MLY First Year": 1929,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITE ROCK BREARLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108911",
        "Station ID": 926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -122.82,
        "Latitude": 490200000,
        "Longitude": -1224900000,
        "Elevation (m)": 76.2,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "WHITE ROCK OCEAN PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108913",
        "Station ID": 927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.04,
        "Longitude (Decimal Degrees)": -122.88,
        "Latitude": 490232000,
        "Longitude": -1225235000,
        "Elevation (m)": 45.7,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2007,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITE ROCK STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108914",
        "Station ID": 928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 490107000,
        "Longitude": -1224702000,
        "Elevation (m)": 13,
        "First Year": 1964,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2002,
        "MLY First Year": 1964,
        "MLY Last Year": 2002
      },
      {
        "Name": "WHONNOCK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108920",
        "Station ID": 929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 491248000,
        "Longitude": -1222830000,
        "Elevation (m)": 162,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1986,
        "MLY First Year": 1985,
        "MLY Last Year": 1986
      },
      {
        "Name": "WHONNOCK NO 5 RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108923",
        "Station ID": 930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.47,
        "Latitude": 491100000,
        "Longitude": -1222800000,
        "Elevation (m)": 61,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "WHONNOCK THORN HILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108925",
        "Station ID": 931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.5,
        "Latitude": 491100000,
        "Longitude": -1223000000,
        "Elevation (m)": 213.4,
        "First Year": 1957,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1969,
        "MLY First Year": 1957,
        "MLY Last Year": 1969
      },
      {
        "Name": "WHONNOCK 269 ST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108927",
        "Station ID": 932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 491100000,
        "Longitude": -1222900000,
        "Elevation (m)": 61,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "WHISTLER MOUNTAIN HIGH LEVEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108987",
        "Station ID": 43660,
        "WMO ID": null,
        "TC ID": "VOA",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500437034,
        "Longitude": -1225646050,
        "Elevation (m)": 1639.97,
        "First Year": 2005,
        "Last Year": 2010,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHISTLER MOUNTAIN LOW LEVEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108988",
        "Station ID": 43680,
        "WMO ID": null,
        "TC ID": "VOB",
        "Latitude (Decimal Degrees)": 50.09,
        "Longitude (Decimal Degrees)": -122.98,
        "Latitude": 500516053,
        "Longitude": -1225832031,
        "Elevation (m)": 933.13,
        "First Year": 2005,
        "Last Year": 2010,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHISTLER MOUNTAIN HIGH LEVEL REMOTE WIND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108989",
        "Station ID": 44463,
        "WMO ID": null,
        "TC ID": "VOH",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 500427030,
        "Longitude": -1225649000,
        "Elevation (m)": 1690,
        "First Year": 2005,
        "Last Year": 2010,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHISTLER MOUNTAIN MID-STATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108990",
        "Station ID": 46307,
        "WMO ID": null,
        "TC ID": "VOL",
        "Latitude (Decimal Degrees)": 50.09,
        "Longitude (Decimal Degrees)": -122.96,
        "Latitude": 500506000,
        "Longitude": -1225751000,
        "Elevation (m)": 1320,
        "First Year": 2007,
        "Last Year": 2010,
        "HLY First Year": 2007,
        "HLY Last Year": 2010,
        "DLY First Year": 2007,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "W VANCOUVER CRESTWELL RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108H2G",
        "Station ID": 878,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.15,
        "Latitude": 492100000,
        "Longitude": -1230900000,
        "Elevation (m)": 304.8,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "VANCOUVER WALES ST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1108M87",
        "Station ID": 879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491300000,
        "Longitude": -1230300000,
        "Elevation (m)": 91.1,
        "First Year": 1982,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1986,
        "MLY First Year": 1982,
        "MLY Last Year": 1986
      },
      {
        "Name": "YARROW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1109004",
        "Station ID": 933,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -122.07,
        "Latitude": 490400000,
        "Longitude": -1220400000,
        "Elevation (m)": 99.1,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1964
      },
      {
        "Name": "YARROW SUMAS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1109006",
        "Station ID": 934,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -122.12,
        "Latitude": 490500000,
        "Longitude": -1220700000,
        "Elevation (m)": 9.1,
        "First Year": 1961,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1968,
        "MLY First Year": 1961,
        "MLY Last Year": 1968
      },
      {
        "Name": "BURNABY METROTOWN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110A1ND",
        "Station ID": 671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -123,
        "Latitude": 491300000,
        "Longitude": -1230000000,
        "Elevation (m)": 125,
        "First Year": 1987,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1998,
        "MLY First Year": 1987,
        "MLY Last Year": 1998
      },
      {
        "Name": "COQUITLAM NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110A8R1",
        "Station ID": 6826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 491600000,
        "Longitude": -1225100000,
        "Elevation (m)": 51,
        "First Year": 1991,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1996,
        "MLY First Year": 1991,
        "MLY Last Year": 1996
      },
      {
        "Name": "N VANCOUVER W KEITH RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110B001",
        "Station ID": 27356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 491908000,
        "Longitude": -1230444000,
        "Elevation (m)": 64,
        "First Year": 1997,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2002,
        "MLY First Year": 1997,
        "MLY Last Year": 2002
      },
      {
        "Name": "HANEY UBC RF MARC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110CCCC",
        "Station ID": 672,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.58,
        "Latitude": 491700000,
        "Longitude": -1223500000,
        "Elevation (m)": 114.3,
        "First Year": 1945,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1972,
        "MLY First Year": 1945,
        "MLY Last Year": 1972
      },
      {
        "Name": "LIONS BAY 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110D634",
        "Station ID": 673,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -123.23,
        "Latitude": 492700000,
        "Longitude": -1231400000,
        "Elevation (m)": 40,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1989,
        "MLY First Year": 1988,
        "MLY Last Year": 1989
      },
      {
        "Name": "MAPLE RIDGE STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110D90K",
        "Station ID": 674,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.63,
        "Latitude": 491300000,
        "Longitude": -1223800000,
        "Elevation (m)": 6.1,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "MISSION HARTLEY RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EA90",
        "Station ID": 675,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.23,
        "Latitude": 491500000,
        "Longitude": -1221400000,
        "Elevation (m)": 137.2,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "N VANCOUVER CLEVELAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EF56",
        "Station ID": 680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -123.11,
        "Latitude": 492135000,
        "Longitude": -1230626000,
        "Elevation (m)": 157,
        "First Year": 1968,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2003,
        "MLY First Year": 1968,
        "MLY Last Year": 2003
      },
      {
        "Name": "N VANC GRAND BLVD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EF57",
        "Station ID": 681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491900000,
        "Longitude": -1230300000,
        "Elevation (m)": 111.3,
        "First Year": 1969,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1997,
        "MLY First Year": 1970,
        "MLY Last Year": 1997
      },
      {
        "Name": "NORTH VANC LONSDALE PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EFE9",
        "Station ID": 677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -123.07,
        "Latitude": 492000000,
        "Longitude": -1230400000,
        "Elevation (m)": 135.6,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "N VANCOUVER CLOVERLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EFEF",
        "Station ID": 676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491900000,
        "Longitude": -1230300000,
        "Elevation (m)": 79.2,
        "First Year": 1968,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1984,
        "MLY First Year": 1968,
        "MLY Last Year": 1984
      },
      {
        "Name": "N VANCOUVER SEYMOUR BLVD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EFFF",
        "Station ID": 678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123.02,
        "Latitude": 491900000,
        "Longitude": -1230100000,
        "Elevation (m)": 8.5,
        "First Year": 1968,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1982,
        "MLY First Year": 1968,
        "MLY Last Year": 1982
      },
      {
        "Name": "N VANCOUVER CAPILANO HIGHLANDS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110EFNN",
        "Station ID": 679,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 492100000,
        "Longitude": -1230700000,
        "Elevation (m)": 130,
        "First Year": 1989,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1991,
        "MLY First Year": 1989,
        "MLY Last Year": 1991
      },
      {
        "Name": "PITT MEADOWS STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110FAG9",
        "Station ID": 682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.68,
        "Latitude": 491300000,
        "Longitude": -1224100000,
        "Elevation (m)": 4.9,
        "First Year": 1974,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1993,
        "MLY First Year": 1974,
        "MLY Last Year": 1993
      },
      {
        "Name": "PORT COQUITLAM BURKE MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110FBEE",
        "Station ID": 6827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.75,
        "Latitude": 491700000,
        "Longitude": -1224500000,
        "Elevation (m)": 60,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "PITT MEADOWS ALOUETTE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110FJPP",
        "Station ID": 683,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -122.67,
        "Latitude": 491700000,
        "Longitude": -1224000000,
        "Elevation (m)": 6,
        "First Year": 1986,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1992,
        "MLY First Year": 1986,
        "MLY Last Year": 1992
      },
      {
        "Name": "PORLIER PASS LIGHT STATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110FK0M",
        "Station ID": 685,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -123.58,
        "Latitude": 490100000,
        "Longitude": -1233500000,
        "Elevation (m)": 3.3,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1987,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORT COQUITLAM MARY HILL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110FKNF",
        "Station ID": 684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 491500000,
        "Longitude": -1224700000,
        "Elevation (m)": 63,
        "First Year": 1988,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "SURREY PANORAMA RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110G8G8",
        "Station ID": 687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 490600000,
        "Longitude": -1225100000,
        "Elevation (m)": 50,
        "First Year": 1987,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1992,
        "MLY First Year": 1987,
        "MLY Last Year": 1992
      },
      {
        "Name": "SURREY GUILDFORD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110GQPB",
        "Station ID": 686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.19,
        "Longitude (Decimal Degrees)": -122.83,
        "Latitude": 491115000,
        "Longitude": -1224951000,
        "Elevation (m)": 80,
        "First Year": 1988,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2004,
        "MLY First Year": 1988,
        "MLY Last Year": 2004
      },
      {
        "Name": "VANCOUVER JERICHO BEACH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110H447",
        "Station ID": 691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 491600000,
        "Longitude": -1231000000,
        "Elevation (m)": 6.7,
        "First Year": 1927,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1936,
        "MLY First Year": 1927,
        "MLY Last Year": 1936
      },
      {
        "Name": "VANCOUVER WEST 10TH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110H488",
        "Station ID": 692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 491600000,
        "Longitude": -1231000000,
        "Elevation (m)": 54.9,
        "First Year": 1975,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1984,
        "MLY First Year": 1975,
        "MLY Last Year": 1984
      },
      {
        "Name": "VANCOUVER GRANDVIEW E",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110HD45",
        "Station ID": 689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 491600000,
        "Longitude": -1230200000,
        "Elevation (m)": 53.3,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "VANCOUVER GRANDVIEW N",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110HD4N",
        "Station ID": 688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -123.05,
        "Latitude": 491700000,
        "Longitude": -1230300000,
        "Elevation (m)": 45.7,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "VANCOUVER KITSILANO BEACH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110HM5C",
        "Station ID": 690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 491600000,
        "Longitude": -1231000000,
        "Elevation (m)": 12,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "INDIAN ARM FARRER COVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110L6M7",
        "Station ID": 6828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -122.88,
        "Latitude": 492000000,
        "Longitude": -1225300000,
        "Elevation (m)": 20,
        "First Year": 1993,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": 1993,
        "MLY Last Year": 1993
      },
      {
        "Name": "MAPLE RIDGE WHONNOCK LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110MR02",
        "Station ID": 693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 491300000,
        "Longitude": -1222900000,
        "Elevation (m)": 172,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "N VAN SEYMOUR HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110N666",
        "Station ID": 698,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.44,
        "Longitude (Decimal Degrees)": -122.97,
        "Latitude": 492615066,
        "Longitude": -1225801007,
        "Elevation (m)": 177,
        "First Year": 1981,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2020,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "N VANCOUVER REDONDA DR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110N6F5",
        "Station ID": 697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 492200000,
        "Longitude": -1230500000,
        "Elevation (m)": 228.6,
        "First Year": 1973,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1995,
        "MLY First Year": 1973,
        "MLY Last Year": 1995
      },
      {
        "Name": "N VANC SONORA DR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110N6FF",
        "Station ID": 696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -123.1,
        "Latitude": 492145000,
        "Longitude": -1230551000,
        "Elevation (m)": 182.9,
        "First Year": 1976,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2011,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORTH VANCOUVER BLUE RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110NFEQ",
        "Station ID": 694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -123,
        "Latitude": 491900000,
        "Longitude": -1230000000,
        "Elevation (m)": 71.9,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1989,
        "MLY First Year": 1988,
        "MLY Last Year": 1989
      },
      {
        "Name": "NORTH VANCOUVER DOLLARTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110NFNF",
        "Station ID": 695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 491900000,
        "Longitude": -1225700000,
        "Elevation (m)": 52,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1991,
        "MLY First Year": 1988,
        "MLY Last Year": 1991
      },
      {
        "Name": "SURREY FLEETWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "110S001",
        "Station ID": 27198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -122.79,
        "Latitude": 490902000,
        "Longitude": -1224724000,
        "Elevation (m)": 67,
        "First Year": 1996,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2001,
        "MLY First Year": 1996,
        "MLY Last Year": 2001
      },
      {
        "Name": "ALLISON PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1110350",
        "Station ID": 938,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -120.83,
        "Latitude": 490800000,
        "Longitude": -1205000000,
        "Elevation (m)": 1341.1,
        "First Year": 1958,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1972,
        "MLY First Year": 1958,
        "MLY Last Year": 1972
      },
      {
        "Name": "ALLISON PASS DOH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1110352",
        "Station ID": 939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -120.87,
        "Latitude": 490700000,
        "Longitude": -1205200000,
        "Elevation (m)": 1339.9,
        "First Year": 1974,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1989,
        "MLY First Year": 1974,
        "MLY Last Year": 1989
      },
      {
        "Name": "BOSTON BAR ALPINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "111090D",
        "Station ID": 940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -121.45,
        "Latitude": 495500000,
        "Longitude": -1212700000,
        "Elevation (m)": 213,
        "First Year": 1985,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1985,
        "MLY First Year": 1985,
        "MLY Last Year": 1985
      },
      {
        "Name": "BOSTON BAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "111090M",
        "Station ID": 941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -121.43,
        "Latitude": 495200000,
        "Longitude": -1212600000,
        "Elevation (m)": 199.9,
        "First Year": 1974,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1989,
        "MLY First Year": 1974,
        "MLY Last Year": 1989
      },
      {
        "Name": "BIRKENHEAD LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1110Q78",
        "Station ID": 936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -122.65,
        "Latitude": 503400000,
        "Longitude": -1223900000,
        "Elevation (m)": 715.1,
        "First Year": 1983,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1985,
        "MLY First Year": 1983,
        "MLY Last Year": 1985
      },
      {
        "Name": "BOSTON BAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1110R04",
        "Station ID": 937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -121.45,
        "Latitude": 495200000,
        "Longitude": -1212700000,
        "Elevation (m)": 163.1,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "EAST LILLOOET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1112625",
        "Station ID": 942,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -121.92,
        "Latitude": 504200000,
        "Longitude": -1215500000,
        "Elevation (m)": 269.7,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "GATES STATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113072",
        "Station ID": 943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 503100000,
        "Longitude": -1223100000,
        "Elevation (m)": 390.2,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "HELLS GATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113420",
        "Station ID": 944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -121.45,
        "Latitude": 494700000,
        "Longitude": -1212700000,
        "Elevation (m)": 121.9,
        "First Year": 1951,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1987,
        "MLY First Year": 1951,
        "MLY Last Year": 1987
      },
      {
        "Name": "HOPE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113539",
        "Station ID": 945,
        "WMO ID": 71114,
        "TC ID": "YHE",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -121.48,
        "Latitude": 492200000,
        "Longitude": -1212900000,
        "Elevation (m)": 39,
        "First Year": 1990,
        "Last Year": 2005,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1990,
        "DLY Last Year": 2005,
        "MLY First Year": 1990,
        "MLY Last Year": 2004
      },
      {
        "Name": "HOPE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113540",
        "Station ID": 946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 492206000,
        "Longitude": -1212953000,
        "Elevation (m)": 39,
        "First Year": 1934,
        "Last Year": 1995,
        "HLY First Year": 1953,
        "HLY Last Year": 1995,
        "DLY First Year": 1934,
        "DLY Last Year": 1995,
        "MLY First Year": 1934,
        "MLY Last Year": 1995
      },
      {
        "Name": "HOPE (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113541",
        "Station ID": 8152,
        "WMO ID": 71114,
        "TC ID": "ZHE",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 492206000,
        "Longitude": -1212953000,
        "Elevation (m)": 39,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": 2004,
        "HLY Last Year": 2012,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOPE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113542",
        "Station ID": 49588,
        "WMO ID": 71187,
        "TC ID": "YHE",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 492206000,
        "Longitude": -1212953000,
        "Elevation (m)": 39,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOPE AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113543",
        "Station ID": 50517,
        "WMO ID": 71114,
        "TC ID": "VPE",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -121.49,
        "Latitude": 492211400,
        "Longitude": -1212936500,
        "Elevation (m)": 39,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOPE DOG MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113545",
        "Station ID": 947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -121.55,
        "Latitude": 492500000,
        "Longitude": -1213300000,
        "Elevation (m)": 1475.2,
        "First Year": 1958,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "HOPE KAWKAWA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113550",
        "Station ID": 948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -121.4,
        "Latitude": 492300000,
        "Longitude": -1212400000,
        "Elevation (m)": 152.4,
        "First Year": 1955,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1977,
        "MLY First Year": 1955,
        "MLY Last Year": 1977
      },
      {
        "Name": "HOPE LITTLE MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113570",
        "Station ID": 949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -121.42,
        "Latitude": 492300000,
        "Longitude": -1212500000,
        "Elevation (m)": 176.8,
        "First Year": 1910,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1954,
        "MLY First Year": 1910,
        "MLY Last Year": 1954
      },
      {
        "Name": "HOPE SLIDE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113580",
        "Station ID": 950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -121.22,
        "Latitude": 491600000,
        "Longitude": -1211300000,
        "Elevation (m)": 701,
        "First Year": 1967,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1974,
        "MLY First Year": 1967,
        "MLY Last Year": 1974
      },
      {
        "Name": "HOPE SLIDE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113581",
        "Station ID": 951,
        "WMO ID": null,
        "TC ID": "WKV",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -121.23,
        "Latitude": 491600000,
        "Longitude": -1211400000,
        "Elevation (m)": 685,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": 1969,
        "HLY Last Year": 2014,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOPE SLIDE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1113582",
        "Station ID": 52160,
        "WMO ID": null,
        "TC ID": "WKV",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 491630000,
        "Longitude": -1211411000,
        "Elevation (m)": 687.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KWOTLENEMO LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114435",
        "Station ID": 952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -121.8,
        "Latitude": 504000000,
        "Longitude": -1214800000,
        "Elevation (m)": 919,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "LADNER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114474",
        "Station ID": 953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -121.25,
        "Latitude": 493000000,
        "Longitude": -1211500000,
        "Elevation (m)": 807.1,
        "First Year": 1982,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1992,
        "MLY First Year": 1982,
        "MLY Last Year": 1992
      },
      {
        "Name": "LILLOOET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114619",
        "Station ID": 27388,
        "WMO ID": 71999,
        "TC ID": "WKF",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -121.93,
        "Latitude": 504101420,
        "Longitude": -1215602945,
        "Elevation (m)": 239.5,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2005
      },
      {
        "Name": "LILLOOET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114620",
        "Station ID": 955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -121.93,
        "Latitude": 504200000,
        "Longitude": -1215600000,
        "Elevation (m)": 289.6,
        "First Year": 1878,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1970,
        "MLY First Year": 1878,
        "MLY Last Year": 1970
      },
      {
        "Name": "LILLOOET CEDAR FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114621",
        "Station ID": 956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -121.87,
        "Latitude": 503600000,
        "Longitude": -1215200000,
        "Elevation (m)": 554.7,
        "First Year": 1962,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1989,
        "MLY First Year": 1962,
        "MLY Last Year": 1989
      },
      {
        "Name": "LILLOOET FOUNTAINVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114622",
        "Station ID": 957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -121.77,
        "Latitude": 503200000,
        "Longitude": -1214600000,
        "Elevation (m)": 300,
        "First Year": 1987,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1994,
        "MLY First Year": 1987,
        "MLY Last Year": 1994
      },
      {
        "Name": "LILLOOET HEIGHTS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114623",
        "Station ID": 958,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -121.93,
        "Latitude": 504200000,
        "Longitude": -1215600000,
        "Elevation (m)": 298.7,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "LILLOOET RUSSELL ST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114626",
        "Station ID": 959,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -121.93,
        "Latitude": 504200000,
        "Longitude": -1215600000,
        "Elevation (m)": 243.8,
        "First Year": 1959,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1983,
        "MLY First Year": 1959,
        "MLY Last Year": 1983
      },
      {
        "Name": "LILLOOET SETON BCHPA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114627",
        "Station ID": 960,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -121.92,
        "Latitude": 504024000,
        "Longitude": -1215527000,
        "Elevation (m)": 198.1,
        "First Year": 1971,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2001,
        "MLY First Year": 1971,
        "MLY Last Year": 2001
      },
      {
        "Name": "LILLOOET A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11146K0",
        "Station ID": 954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -121.88,
        "Latitude": 504000000,
        "Longitude": -1215300000,
        "Elevation (m)": 402,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "LYTTON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114738",
        "Station ID": 51598,
        "WMO ID": 71812,
        "TC ID": "WLY",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -121.58,
        "Latitude": 501328000,
        "Longitude": -1213455000,
        "Elevation (m)": 224.9,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LYTTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114739",
        "Station ID": 961,
        "WMO ID": null,
        "TC ID": "WLY",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -121.58,
        "Latitude": 501328000,
        "Longitude": -1213455000,
        "Elevation (m)": 225,
        "First Year": 1990,
        "Last Year": 2013,
        "HLY First Year": 1994,
        "HLY Last Year": 2013,
        "DLY First Year": 1990,
        "DLY Last Year": 2013,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "LYTTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114740",
        "Station ID": 962,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -121.57,
        "Latitude": 501400000,
        "Longitude": -1213400000,
        "Elevation (m)": 175,
        "First Year": 1944,
        "Last Year": 1970,
        "HLY First Year": 1953,
        "HLY Last Year": 1970,
        "DLY First Year": 1944,
        "DLY Last Year": 1969,
        "MLY First Year": 1944,
        "MLY Last Year": 1970
      },
      {
        "Name": "LYTTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114741",
        "Station ID": 963,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -121.58,
        "Latitude": 501400000,
        "Longitude": -1213500000,
        "Elevation (m)": 229,
        "First Year": 1970,
        "Last Year": 1991,
        "HLY First Year": 1970,
        "HLY Last Year": 1991,
        "DLY First Year": 1970,
        "DLY Last Year": 1991,
        "MLY First Year": 1970,
        "MLY Last Year": 1991
      },
      {
        "Name": "LYTTON BOTANIE VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114742",
        "Station ID": 964,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -121.55,
        "Latitude": 501940000,
        "Longitude": -1213256000,
        "Elevation (m)": 730,
        "First Year": 1989,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2006,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "LYTTON STEIN MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114743",
        "Station ID": 965,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -121.68,
        "Latitude": 502400000,
        "Longitude": -1214100000,
        "Elevation (m)": 385,
        "First Year": 1988,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1995,
        "MLY First Year": 1988,
        "MLY Last Year": 1995
      },
      {
        "Name": "LYTTON 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114745",
        "Station ID": 966,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.26,
        "Longitude (Decimal Degrees)": -121.61,
        "Latitude": 501525000,
        "Longitude": -1213628000,
        "Elevation (m)": 174,
        "First Year": 1917,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 2015,
        "MLY First Year": 1917,
        "MLY Last Year": 2007
      },
      {
        "Name": "LYTTON RCS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114746",
        "Station ID": 44927,
        "WMO ID": 71765,
        "TC ID": "VLY",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -121.58,
        "Latitude": 501328000,
        "Longitude": -1213455000,
        "Elevation (m)": 225,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "MCGILLIVARY FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1114992",
        "Station ID": 967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -122.43,
        "Latitude": 503700000,
        "Longitude": -1222600000,
        "Elevation (m)": 419,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "MOHA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1115196",
        "Station ID": 968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -122.27,
        "Latitude": 505300000,
        "Longitude": -1221600000,
        "Elevation (m)": 548.6,
        "First Year": 1924,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1947,
        "MLY First Year": 1924,
        "MLY Last Year": 1947
      },
      {
        "Name": "PAVILION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1116060",
        "Station ID": 969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -121.83,
        "Latitude": 505200000,
        "Longitude": -1215000000,
        "Elevation (m)": 1066.8,
        "First Year": 1953,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1955,
        "MLY First Year": 1953,
        "MLY Last Year": 1955
      },
      {
        "Name": "PAVILION BLUE RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1116062",
        "Station ID": 970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -121.88,
        "Latitude": 505300000,
        "Longitude": -1215300000,
        "Elevation (m)": 602,
        "First Year": 1987,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1995,
        "MLY First Year": 1987,
        "MLY Last Year": 1995
      },
      {
        "Name": "PEMBERTON HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1116084",
        "Station ID": 971,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -122.55,
        "Latitude": 502000000,
        "Longitude": -1223300000,
        "Elevation (m)": 256,
        "First Year": 1908,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1936,
        "MLY First Year": 1908,
        "MLY Last Year": 1936
      },
      {
        "Name": "PORCUPINE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1116204",
        "Station ID": 972,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -121.8,
        "Latitude": 510200000,
        "Longitude": -1214800000,
        "Elevation (m)": 1190,
        "First Year": 1974,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1995,
        "MLY First Year": 1974,
        "MLY Last Year": 1995
      },
      {
        "Name": "SHALALTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1117215",
        "Station ID": 973,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -122.24,
        "Latitude": 504342000,
        "Longitude": -1221426000,
        "Elevation (m)": 243.8,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "SKAGIT RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1117410",
        "Station ID": 974,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -121.17,
        "Latitude": 490500000,
        "Longitude": -1211000000,
        "Elevation (m)": 515.1,
        "First Year": 1936,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1955,
        "MLY First Year": 1936,
        "MLY Last Year": 1955
      },
      {
        "Name": "TREASURE MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1118235",
        "Station ID": 975,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -121.05,
        "Latitude": 492500000,
        "Longitude": -1210300000,
        "Elevation (m)": 1430,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1990,
        "MLY Last Year": 1990
      },
      {
        "Name": "YALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1119002",
        "Station ID": 976,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -121.43,
        "Latitude": 493400000,
        "Longitude": -1212600000,
        "Elevation (m)": 76,
        "First Year": 1984,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1994,
        "MLY First Year": 1984,
        "MLY Last Year": 1994
      },
      {
        "Name": "EDGE HILLS BUTCHER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "111KFLR",
        "Station ID": 935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -121.94,
        "Latitude": 510414080,
        "Longitude": -1215609050,
        "Elevation (m)": 580,
        "First Year": 1988,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2002,
        "MLY First Year": 1988,
        "MLY Last Year": 2002
      },
      {
        "Name": "ADRA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120090",
        "Station ID": 983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -119.58,
        "Latitude": 494400000,
        "Longitude": -1193500000,
        "Elevation (m)": 1002.5,
        "First Year": 1928,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1953,
        "MLY First Year": 1928,
        "MLY Last Year": 1953
      },
      {
        "Name": "APEX MTN LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120447",
        "Station ID": 984,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -119.92,
        "Latitude": 492400000,
        "Longitude": -1195500000,
        "Elevation (m)": 1889.8,
        "First Year": 1965,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1971,
        "MLY First Year": 1965,
        "MLY Last Year": 1971
      },
      {
        "Name": "ARMSTRONG GRANDVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120475",
        "Station ID": 985,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -119.28,
        "Latitude": 502400000,
        "Longitude": -1191700000,
        "Elevation (m)": 502.9,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "ARMSTRONG OTTER LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120486",
        "Station ID": 6943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -119.25,
        "Latitude": 502400000,
        "Longitude": -1191500000,
        "Elevation (m)": 342.4,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "BANKEIR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120630",
        "Station ID": 986,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -120.23,
        "Latitude": 494300000,
        "Longitude": -1201400000,
        "Elevation (m)": 1097.3,
        "First Year": 1954,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1959,
        "MLY First Year": 1954,
        "MLY Last Year": 1959
      },
      {
        "Name": "BANKIER CHAIN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120633",
        "Station ID": 987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -120.28,
        "Latitude": 494200000,
        "Longitude": -1201700000,
        "Elevation (m)": 1020,
        "First Year": 1974,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1991,
        "MLY First Year": 1974,
        "MLY Last Year": 1991
      },
      {
        "Name": "BLAKEBURN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120882",
        "Station ID": 988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -120.73,
        "Latitude": 492900000,
        "Longitude": -1204400000,
        "Elevation (m)": 1485,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ARAWANA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1120M47",
        "Station ID": 982,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -119.57,
        "Latitude": 493500000,
        "Longitude": -1193400000,
        "Elevation (m)": 518.2,
        "First Year": 1970,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1976,
        "MLY First Year": 1970,
        "MLY Last Year": 1976
      },
      {
        "Name": "BROOKMERE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1121090",
        "Station ID": 989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -120.87,
        "Latitude": 494900000,
        "Longitude": -1205200000,
        "Elevation (m)": 972,
        "First Year": 1986,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1994,
        "MLY First Year": 1986,
        "MLY Last Year": 1994
      },
      {
        "Name": "CAWSTON SIMILKAMEEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1121459",
        "Station ID": 990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -119.77,
        "Latitude": 491100000,
        "Longitude": -1194600000,
        "Elevation (m)": 398,
        "First Year": 1979,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1992,
        "MLY First Year": 1979,
        "MLY Last Year": 1992
      },
      {
        "Name": "CHUTE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1121620",
        "Station ID": 991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -119.53,
        "Latitude": 494100000,
        "Longitude": -1193200000,
        "Elevation (m)": 1193.6,
        "First Year": 1928,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1962,
        "MLY First Year": 1928,
        "MLY Last Year": 1962
      },
      {
        "Name": "DOUGLAS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1122541",
        "Station ID": 992,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.16,
        "Longitude (Decimal Degrees)": -120.2,
        "Latitude": 500953400,
        "Longitude": -1201158800,
        "Elevation (m)": 808,
        "First Year": 1979,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2007,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "GLENEMMA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123195",
        "Station ID": 993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -119.32,
        "Latitude": 502200000,
        "Longitude": -1191900000,
        "Elevation (m)": null,
        "First Year": 1914,
        "Last Year": 1925,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1925,
        "MLY First Year": 1914,
        "MLY Last Year": 1925
      },
      {
        "Name": "GRANITE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123280",
        "Station ID": 994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -120.73,
        "Latitude": 492800000,
        "Longitude": -1204400000,
        "Elevation (m)": 1036.9,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "HEDLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123360",
        "Station ID": 995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -120.08,
        "Latitude": 492126000,
        "Longitude": -1200436000,
        "Elevation (m)": 517,
        "First Year": 1904,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 2005,
        "MLY First Year": 1904,
        "MLY Last Year": 2005
      },
      {
        "Name": "HEDLEY N",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123370",
        "Station ID": 46027,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -120.07,
        "Latitude": 492149400,
        "Longitude": -1200408200,
        "Elevation (m)": 542,
        "First Year": 2007,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2017,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HEDLEY NP MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123390",
        "Station ID": 996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -120.02,
        "Latitude": 492210020,
        "Longitude": -1200118070,
        "Elevation (m)": 1651,
        "First Year": 1904,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 2015,
        "MLY First Year": 1904,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGHLAND VALLEY BCCL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123468",
        "Station ID": 997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -121,
        "Latitude": 503000000,
        "Longitude": -1210000000,
        "Elevation (m)": 1470.1,
        "First Year": 1966,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1989,
        "MLY First Year": 1966,
        "MLY Last Year": 1989
      },
      {
        "Name": "HIGHLAND VALLEY LORNEX",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123469",
        "Station ID": 998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -121.02,
        "Latitude": 502800000,
        "Longitude": -1210100000,
        "Elevation (m)": 1268,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "JELLICOE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123721",
        "Station ID": 27122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -120.33,
        "Latitude": 494022000,
        "Longitude": -1201958000,
        "Elevation (m)": 929,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "JOE RICH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123750",
        "Station ID": 999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.86,
        "Longitude (Decimal Degrees)": -119.13,
        "Latitude": 495126000,
        "Longitude": -1190734000,
        "Elevation (m)": 874.8,
        "First Year": 1928,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 2008,
        "MLY First Year": 1928,
        "MLY Last Year": 2007
      },
      {
        "Name": "KELOWNA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123930",
        "Station ID": 1000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -119.47,
        "Latitude": 495400000,
        "Longitude": -1192800000,
        "Elevation (m)": 353.6,
        "First Year": 1899,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1962,
        "MLY First Year": 1899,
        "MLY Last Year": 1962
      },
      {
        "Name": "KELOWNA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123939",
        "Station ID": 48369,
        "WMO ID": 71203,
        "TC ID": "YLW",
        "Latitude (Decimal Degrees)": 49.96,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 495726000,
        "Longitude": -1192240000,
        "Elevation (m)": 433.1,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KELOWNA AWOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123965",
        "Station ID": 30954,
        "WMO ID": null,
        "TC ID": "VLW",
        "Latitude (Decimal Degrees)": 49.96,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 495722000,
        "Longitude": -1192240000,
        "Elevation (m)": 429.5,
        "First Year": 2004,
        "Last Year": 2010,
        "HLY First Year": 2004,
        "HLY Last Year": 2009,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "KELOWNA A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123970",
        "Station ID": 1001,
        "WMO ID": 71203,
        "TC ID": "YLW",
        "Latitude (Decimal Degrees)": 49.96,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 495722000,
        "Longitude": -1192240000,
        "Elevation (m)": 429.5,
        "First Year": 1959,
        "Last Year": 2005,
        "HLY First Year": 1959,
        "HLY Last Year": 2005,
        "DLY First Year": 1968,
        "DLY Last Year": 2005,
        "MLY First Year": 1968,
        "MLY Last Year": 2005
      },
      {
        "Name": "KELOWNA BANKHEAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123972",
        "Station ID": 1002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -119.3,
        "Latitude": 495400000,
        "Longitude": -1191800000,
        "Elevation (m)": null,
        "First Year": 1914,
        "Last Year": 1931,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1931,
        "MLY First Year": 1914,
        "MLY Last Year": 1931
      },
      {
        "Name": "KELOWNA BOWES STREET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123975",
        "Station ID": 1003,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -119.47,
        "Latitude": 495300000,
        "Longitude": -1192800000,
        "Elevation (m)": 350.5,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1961,
        "MLY Last Year": 1969
      },
      {
        "Name": "KELOWNA BYLANDS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123977",
        "Station ID": 26991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -119.58,
        "Latitude": 495200000,
        "Longitude": -1193500000,
        "Elevation (m)": 480,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1995,
        "MLY First Year": 1994,
        "MLY Last Year": 1995
      },
      {
        "Name": "KELOWNA CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123980",
        "Station ID": 1004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -119.42,
        "Latitude": 495200000,
        "Longitude": -1192500000,
        "Elevation (m)": 484.6,
        "First Year": 1950,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1970,
        "MLY First Year": 1950,
        "MLY Last Year": 1970
      },
      {
        "Name": "KELOWNA CEDARCREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123981",
        "Station ID": 26993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -119.54,
        "Latitude": 494721600,
        "Longitude": -1193229400,
        "Elevation (m)": 375,
        "First Year": 1994,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2008,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "KELOWNA DAV-SPIERS ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123982",
        "Station ID": 1005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -119.45,
        "Latitude": 495104000,
        "Longitude": -1192642000,
        "Elevation (m)": 375,
        "First Year": 1978,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2004,
        "MLY First Year": 1978,
        "MLY Last Year": 2004
      },
      {
        "Name": "KELOWNA EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123984",
        "Station ID": 1006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.86,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 495134200,
        "Longitude": -1192348000,
        "Elevation (m)": 491,
        "First Year": 1980,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2000,
        "MLY First Year": 1980,
        "MLY Last Year": 2000
      },
      {
        "Name": "KELOWNA LAKEVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123990",
        "Station ID": 1007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.86,
        "Longitude (Decimal Degrees)": -119.55,
        "Latitude": 495149200,
        "Longitude": -1193310200,
        "Elevation (m)": 525,
        "First Year": 1952,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2000,
        "MLY First Year": 1952,
        "MLY Last Year": 2000
      },
      {
        "Name": "KELOWNA PC BURNETTS NURSERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123992",
        "Station ID": 1008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -119.48,
        "Latitude": 495227000,
        "Longitude": -1192900600,
        "Elevation (m)": 349.9,
        "First Year": 1969,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2003,
        "MLY First Year": 1969,
        "MLY Last Year": 2003
      },
      {
        "Name": "KELOWNA QUAILS GATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123993",
        "Station ID": 26932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.84,
        "Longitude (Decimal Degrees)": -119.57,
        "Latitude": 495032000,
        "Longitude": -1193422000,
        "Elevation (m)": 417,
        "First Year": 1995,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2014,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "KELOWNA UBCO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1123996",
        "Station ID": 51117,
        "WMO ID": 71644,
        "TC ID": "VKU",
        "Latitude (Decimal Degrees)": 49.94,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 495626700,
        "Longitude": -1192400760,
        "Elevation (m)": 456,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KELOWNA MWS0",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11239R0",
        "Station ID": 10976,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 495700000,
        "Longitude": -1192400000,
        "Elevation (m)": 456,
        "First Year": 1994,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2017,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEREMEOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124110",
        "Station ID": 1009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -119.82,
        "Latitude": 491200000,
        "Longitude": -1194900000,
        "Elevation (m)": 415,
        "First Year": 1891,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1995,
        "MLY First Year": 1891,
        "MLY Last Year": 1995
      },
      {
        "Name": "KEREMEOS 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124112",
        "Station ID": 1010,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -119.82,
        "Latitude": 491237200,
        "Longitude": -1194916800,
        "Elevation (m)": 434.9,
        "First Year": 1924,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2000,
        "MLY First Year": 1924,
        "MLY Last Year": 2000
      },
      {
        "Name": "KEREMEOS WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124118",
        "Station ID": 1011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -119.83,
        "Latitude": 491300000,
        "Longitude": -1195000000,
        "Elevation (m)": 416.1,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "KIRTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124260",
        "Station ID": 1012,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -119.95,
        "Latitude": 494000000,
        "Longitude": -1195700000,
        "Elevation (m)": 883.9,
        "First Year": 1928,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1962,
        "MLY First Year": 1928,
        "MLY Last Year": 1962
      },
      {
        "Name": "LAC LE JEUNE WALLOPER LK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124460",
        "Station ID": 1013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -120.53,
        "Latitude": 502900000,
        "Longitude": -1203200000,
        "Elevation (m)": 1305,
        "First Year": 1957,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1988,
        "MLY First Year": 1957,
        "MLY Last Year": 1988
      },
      {
        "Name": "LOGAN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124668",
        "Station ID": 1014,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -120.82,
        "Latitude": 503000000,
        "Longitude": -1204900000,
        "Elevation (m)": 1101,
        "First Year": 1971,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2005,
        "MLY First Year": 1971,
        "MLY Last Year": 2005
      },
      {
        "Name": "MAMIT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124860",
        "Station ID": 1015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -120.8,
        "Latitude": 502300000,
        "Longitude": -1204800000,
        "Elevation (m)": 1005.8,
        "First Year": 1924,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1965,
        "MLY First Year": 1924,
        "MLY Last Year": 1965
      },
      {
        "Name": "MANNING PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124890",
        "Station ID": 1016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -120.78,
        "Latitude": 490400000,
        "Longitude": -1204700000,
        "Elevation (m)": 1197.9,
        "First Year": 1952,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1961,
        "MLY First Year": 1952,
        "MLY Last Year": 1961
      },
      {
        "Name": "MCCULLOCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1124980",
        "Station ID": 1017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -119.2,
        "Latitude": 494800000,
        "Longitude": -1191200000,
        "Elevation (m)": 1249.7,
        "First Year": 1923,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1996,
        "MLY First Year": 1923,
        "MLY Last Year": 1996
      },
      {
        "Name": "MEADOWGREEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125060",
        "Station ID": 1019,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -120.68,
        "Latitude": 502800000,
        "Longitude": -1204100000,
        "Elevation (m)": 1207,
        "First Year": 1980,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1986,
        "MLY First Year": 1980,
        "MLY Last Year": 1986
      },
      {
        "Name": "MERRITT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125070",
        "Station ID": 1020,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -120.8,
        "Latitude": 500700000,
        "Longitude": -1204800000,
        "Elevation (m)": 585.2,
        "First Year": 1918,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1966,
        "MLY First Year": 1918,
        "MLY Last Year": 1966
      },
      {
        "Name": "MERRITT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125073",
        "Station ID": 49408,
        "WMO ID": 71557,
        "TC ID": "VME",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -120.78,
        "Latitude": 500645007,
        "Longitude": -1204641000,
        "Elevation (m)": 591,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MERRITT CRAIGMONT MINES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125075",
        "Station ID": 1021,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -120.88,
        "Latitude": 501200000,
        "Longitude": -1205300000,
        "Elevation (m)": 731.5,
        "First Year": 1962,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1976,
        "MLY First Year": 1962,
        "MLY Last Year": 1976
      },
      {
        "Name": "MERRITT STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125079",
        "Station ID": 1022,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -120.8,
        "Latitude": 500651004,
        "Longitude": -1204803005,
        "Elevation (m)": 609,
        "First Year": 1968,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2021,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "MT BALDY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125221",
        "Station ID": 1023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -119.23,
        "Latitude": 490900000,
        "Longitude": -1191400000,
        "Elevation (m)": 1722.1,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "MT KOBAU OBSERVATORY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125223",
        "Station ID": 1024,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -119.68,
        "Latitude": 490700000,
        "Longitude": -1194100000,
        "Elevation (m)": 1862.3,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "NARAMATA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125380",
        "Station ID": 1025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -119.6,
        "Latitude": 493700000,
        "Longitude": -1193600000,
        "Elevation (m)": 365.8,
        "First Year": 1924,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1936,
        "MLY First Year": 1924,
        "MLY Last Year": 1936
      },
      {
        "Name": "NARAMATA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125381",
        "Station ID": 1026,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -119.57,
        "Latitude": 493302400,
        "Longitude": -1193415600,
        "Elevation (m)": 411,
        "First Year": 1971,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2004,
        "MLY First Year": 1971,
        "MLY Last Year": 2004
      },
      {
        "Name": "NICKLEN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125570",
        "Station ID": 1027,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -119,
        "Latitude": 500800000,
        "Longitude": -1190000000,
        "Elevation (m)": 1317,
        "First Year": 1990,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1997,
        "MLY First Year": 1990,
        "MLY Last Year": 1997
      },
      {
        "Name": "NICOLA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125585",
        "Station ID": 1028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -120.65,
        "Latitude": 500900000,
        "Longitude": -1203900000,
        "Elevation (m)": 646.2,
        "First Year": 1878,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1918,
        "MLY First Year": 1878,
        "MLY Last Year": 1918
      },
      {
        "Name": "NICOLA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125586",
        "Station ID": 1029,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -120.45,
        "Latitude": 501500000,
        "Longitude": -1202700000,
        "Elevation (m)": 633.1,
        "First Year": 1979,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1985,
        "MLY First Year": 1979,
        "MLY Last Year": 1985
      },
      {
        "Name": "NICOLA LAKE W END",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125590",
        "Station ID": 1030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -120.63,
        "Latitude": 501000000,
        "Longitude": -1203800000,
        "Elevation (m)": 642,
        "First Year": 1984,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1992,
        "MLY First Year": 1984,
        "MLY Last Year": 1992
      },
      {
        "Name": "OKANAGAN CENTRE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125700",
        "Station ID": 1032,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.06,
        "Longitude (Decimal Degrees)": -119.45,
        "Latitude": 500322100,
        "Longitude": -1192642400,
        "Elevation (m)": 370,
        "First Year": 1925,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 2022,
        "MLY First Year": 1925,
        "MLY Last Year": 2007
      },
      {
        "Name": "OKANAGAN FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125701",
        "Station ID": 1033,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -119.53,
        "Latitude": 492100000,
        "Longitude": -1193200000,
        "Elevation (m)": 502.9,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "OKANAGAN MISSION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125703",
        "Station ID": 1034,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -119.52,
        "Latitude": 494800000,
        "Longitude": -1193100000,
        "Elevation (m)": 408.4,
        "First Year": 1970,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1985,
        "MLY First Year": 1970,
        "MLY Last Year": 1985
      },
      {
        "Name": "OKANAGAN MISSION TOZER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125707",
        "Station ID": 1035,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -119.48,
        "Latitude": 494900000,
        "Longitude": -1192900000,
        "Elevation (m)": 359,
        "First Year": 1984,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1992,
        "MLY First Year": 1984,
        "MLY Last Year": 1992
      },
      {
        "Name": "OK FALLS MCLEAN CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125712",
        "Station ID": 1036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -119.53,
        "Latitude": 492100000,
        "Longitude": -1193200000,
        "Elevation (m)": 455.1,
        "First Year": 1982,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1991,
        "MLY First Year": 1982,
        "MLY Last Year": 1991
      },
      {
        "Name": "OLIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125760",
        "Station ID": 1037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -119.56,
        "Latitude": 490957000,
        "Longitude": -1193351000,
        "Elevation (m)": 315.2,
        "First Year": 1938,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 2008,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLIVER EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125765",
        "Station ID": 1038,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -119.53,
        "Latitude": 491100000,
        "Longitude": -1193200000,
        "Elevation (m)": 335.3,
        "First Year": 1962,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1967,
        "MLY First Year": 1962,
        "MLY Last Year": 1967
      },
      {
        "Name": "OLIVER STP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125766",
        "Station ID": 1039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -119.54,
        "Latitude": 491044500,
        "Longitude": -1193240900,
        "Elevation (m)": 297.2,
        "First Year": 1924,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2022,
        "MLY First Year": 1924,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLIVER 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125779",
        "Station ID": 6836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -119.55,
        "Latitude": 491100000,
        "Longitude": -1193300000,
        "Elevation (m)": 280,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "OLIVER MONASHEE VINEYARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11257FN",
        "Station ID": 1031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -119.55,
        "Latitude": 490700000,
        "Longitude": -1193300000,
        "Elevation (m)": 374.9,
        "First Year": 1978,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1978,
        "MLY First Year": 1978,
        "MLY Last Year": 1978
      },
      {
        "Name": "OSOYOOS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125850",
        "Station ID": 1040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -119.43,
        "Latitude": 490200000,
        "Longitude": -1192600000,
        "Elevation (m)": 297,
        "First Year": 1954,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1991,
        "MLY First Year": 1954,
        "MLY Last Year": 1991
      },
      {
        "Name": "OSOYOOS CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125852",
        "Station ID": 1041,
        "WMO ID": 71215,
        "TC ID": "WYY",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -119.44,
        "Latitude": 490141850,
        "Longitude": -1192627570,
        "Elevation (m)": 282.9,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1990,
        "DLY Last Year": 2022,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "OSOYOOS EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125855",
        "Station ID": 1042,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -119.43,
        "Latitude": 490100000,
        "Longitude": -1192600000,
        "Elevation (m)": 329.2,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "OSOYOOS WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125865",
        "Station ID": 1043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -119.44,
        "Latitude": 490155000,
        "Longitude": -1192634000,
        "Elevation (m)": 297.2,
        "First Year": 1967,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2009,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "OSPREY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125880",
        "Station ID": 1044,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 494200000,
        "Longitude": -1201100000,
        "Elevation (m)": 1099.1,
        "First Year": 1928,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1952,
        "MLY First Year": 1928,
        "MLY Last Year": 1952
      },
      {
        "Name": "OYAMA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125907",
        "Station ID": 1045,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -119.37,
        "Latitude": 500651000,
        "Longitude": -1192200700,
        "Elevation (m)": 440,
        "First Year": 1965,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1997,
        "MLY First Year": 1965,
        "MLY Last Year": 1997
      },
      {
        "Name": "OKANAGAN FALLS 2S",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1125G0A",
        "Station ID": 1018,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -119.55,
        "Latitude": 491900000,
        "Longitude": -1193300000,
        "Elevation (m)": 335.3,
        "First Year": 1970,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1982,
        "MLY First Year": 1970,
        "MLY Last Year": 1982
      },
      {
        "Name": "PEACHLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126070",
        "Station ID": 1046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -119.72,
        "Latitude": 494700000,
        "Longitude": -1194300000,
        "Elevation (m)": 345,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEACHLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126071",
        "Station ID": 1047,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -119.71,
        "Latitude": 494658010,
        "Longitude": -1194252050,
        "Elevation (m)": 351,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "PEACHLAND BRENDA MINES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126077",
        "Station ID": 1048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -120,
        "Latitude": 495200000,
        "Longitude": -1200000000,
        "Elevation (m)": 1520,
        "First Year": 1968,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1993,
        "MLY First Year": 1968,
        "MLY Last Year": 1993
      },
      {
        "Name": "PEACHLAND GREATA RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126078",
        "Station ID": 26994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -119.74,
        "Latitude": 494201200,
        "Longitude": -1194428200,
        "Elevation (m)": 398,
        "First Year": 1994,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2008,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEACHLAND MINOS ROCK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126079",
        "Station ID": 1049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -119.75,
        "Latitude": 494600000,
        "Longitude": -1194500000,
        "Elevation (m)": 609.9,
        "First Year": 1982,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1982,
        "MLY First Year": 1982,
        "MLY Last Year": 1982
      },
      {
        "Name": "PEACHLAND TREPANIER CR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126080",
        "Station ID": 1050,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -119.83,
        "Latitude": 494700000,
        "Longitude": -1195000000,
        "Elevation (m)": 548.6,
        "First Year": 1924,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1943,
        "MLY First Year": 1924,
        "MLY Last Year": 1943
      },
      {
        "Name": "PENNASK LAKE LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126135",
        "Station ID": 1051,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -120.15,
        "Latitude": 500000000,
        "Longitude": -1200900000,
        "Elevation (m)": 1420.4,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "PENTICTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126145",
        "Station ID": 1052,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -119.58,
        "Latitude": 492900000,
        "Longitude": -1193500000,
        "Elevation (m)": 347.5,
        "First Year": 1907,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1965,
        "MLY First Year": 1907,
        "MLY Last Year": 1965
      },
      {
        "Name": "PENTICTON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126146",
        "Station ID": 50269,
        "WMO ID": 71889,
        "TC ID": "YYF",
        "Latitude (Decimal Degrees)": 49.46,
        "Longitude (Decimal Degrees)": -119.6,
        "Latitude": 492745000,
        "Longitude": -1193608000,
        "Elevation (m)": 344.4,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PENTICTON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126150",
        "Station ID": 1053,
        "WMO ID": 71889,
        "TC ID": "YYF",
        "Latitude (Decimal Degrees)": 49.46,
        "Longitude (Decimal Degrees)": -119.6,
        "Latitude": 492747000,
        "Longitude": -1193608000,
        "Elevation (m)": 344.42,
        "First Year": 1941,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1941,
        "DLY Last Year": 2012,
        "MLY First Year": 1941,
        "MLY Last Year": 2012
      },
      {
        "Name": "PENTICTON SEWAGE PLANT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126160",
        "Station ID": 1054,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -119.6,
        "Latitude": 493000000,
        "Longitude": -1193600000,
        "Elevation (m)": 344.1,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1969,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "PRINCETON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126505",
        "Station ID": 1055,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -120.5,
        "Latitude": 492600000,
        "Longitude": -1203000000,
        "Elevation (m)": 632.5,
        "First Year": 1893,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1942,
        "MLY First Year": 1893,
        "MLY Last Year": 1942
      },
      {
        "Name": "PRINCETON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126510",
        "Station ID": 1056,
        "WMO ID": null,
        "TC ID": "YDC",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -120.51,
        "Latitude": 492804000,
        "Longitude": -1203045000,
        "Elevation (m)": 701.65,
        "First Year": 1936,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1936,
        "DLY Last Year": 2022,
        "MLY First Year": 1937,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRINCETON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126511",
        "Station ID": 51439,
        "WMO ID": null,
        "TC ID": "YDC",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -120.51,
        "Latitude": 492804000,
        "Longitude": -1203045000,
        "Elevation (m)": 701.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCETON 8NE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126514",
        "Station ID": 1057,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -120.43,
        "Latitude": 493200000,
        "Longitude": -1202600000,
        "Elevation (m)": 975.4,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "PRINCETON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126518",
        "Station ID": 52958,
        "WMO ID": null,
        "TC ID": "YDC",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -120.51,
        "Latitude": 492804000,
        "Longitude": -1203045000,
        "Elevation (m)": 701.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RUTLAND MISSION CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1126915",
        "Station ID": 1058,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -119.45,
        "Latitude": 495800000,
        "Longitude": -1192700000,
        "Elevation (m)": 914.4,
        "First Year": 1926,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1947,
        "MLY First Year": 1926,
        "MLY Last Year": 1947
      },
      {
        "Name": "SIMILKAMEEN COPPER MTN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1127358",
        "Station ID": 1059,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -120.52,
        "Latitude": 492000000,
        "Longitude": -1203100000,
        "Elevation (m)": 1229,
        "First Year": 1980,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1995,
        "MLY First Year": 1980,
        "MLY Last Year": 1995
      },
      {
        "Name": "SIMILKAMEEN MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1127360",
        "Station ID": 1060,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -120.55,
        "Latitude": 492100000,
        "Longitude": -1203300000,
        "Elevation (m)": 940,
        "First Year": 1974,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1996,
        "MLY First Year": 1974,
        "MLY Last Year": 1996
      },
      {
        "Name": "SUMMERLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1127798",
        "Station ID": 1061,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -119.67,
        "Latitude": 493600000,
        "Longitude": -1194000000,
        "Elevation (m)": 335.3,
        "First Year": 1907,
        "Last Year": 1923,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1923,
        "MLY First Year": 1907,
        "MLY Last Year": 1923
      },
      {
        "Name": "SUMMERLAND CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1127800",
        "Station ID": 1062,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -119.65,
        "Latitude": 493400000,
        "Longitude": -1193900000,
        "Elevation (m)": 454.2,
        "First Year": 1916,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1995,
        "MLY First Year": 1916,
        "MLY Last Year": 1995
      },
      {
        "Name": "SUMMERLAND CDA EL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1127830",
        "Station ID": 1063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -119.63,
        "Latitude": 493400000,
        "Longitude": -1193800000,
        "Elevation (m)": 345.9,
        "First Year": 1949,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1964,
        "MLY First Year": 1949,
        "MLY Last Year": 1964
      },
      {
        "Name": "VERNON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128550",
        "Station ID": 1064,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -119.27,
        "Latitude": 501500000,
        "Longitude": -1191600000,
        "Elevation (m)": 421.5,
        "First Year": 1893,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1960,
        "MLY First Year": 1893,
        "MLY Last Year": 1960
      },
      {
        "Name": "VERNON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128551",
        "Station ID": 1065,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -119.28,
        "Latitude": 501400000,
        "Longitude": -1191700000,
        "Elevation (m)": 556,
        "First Year": 1971,
        "Last Year": 1995,
        "HLY First Year": 1971,
        "HLY Last Year": 1995,
        "DLY First Year": 1971,
        "DLY Last Year": 1994,
        "MLY First Year": 1971,
        "MLY Last Year": 1994
      },
      {
        "Name": "VERNON BELLA VISTA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128553",
        "Station ID": 1066,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.26,
        "Longitude (Decimal Degrees)": -119.31,
        "Latitude": 501551300,
        "Longitude": -1191836300,
        "Elevation (m)": 427,
        "First Year": 1984,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2017,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "VERNON BX",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128554",
        "Station ID": 1067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -119.22,
        "Latitude": 501700000,
        "Longitude": -1191300000,
        "Elevation (m)": 518.2,
        "First Year": 1960,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "VERNON COLDSTREAM RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128580",
        "Station ID": 1068,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -119.2,
        "Latitude": 501300000,
        "Longitude": -1191200000,
        "Elevation (m)": 482.2,
        "First Year": 1900,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 1997,
        "MLY First Year": 1900,
        "MLY Last Year": 1997
      },
      {
        "Name": "VERNON CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128581",
        "Station ID": 6837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -119.19,
        "Latitude": 501323900,
        "Longitude": -1191136800,
        "Elevation (m)": 482,
        "First Year": 1991,
        "Last Year": 2008,
        "HLY First Year": 1994,
        "HLY Last Year": 2008,
        "DLY First Year": 1991,
        "DLY Last Year": 2008,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "VERNON AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128582",
        "Station ID": 46987,
        "WMO ID": 71115,
        "TC ID": "WJV",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -119.19,
        "Latitude": 501323900,
        "Longitude": -1191136700,
        "Elevation (m)": 482,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VERNON NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128583",
        "Station ID": 1069,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.34,
        "Longitude (Decimal Degrees)": -119.27,
        "Latitude": 502039600,
        "Longitude": -1191617000,
        "Elevation (m)": 538,
        "First Year": 1990,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2020,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "VERNON SILVER STAR LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128584",
        "Station ID": 1070,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.36,
        "Longitude (Decimal Degrees)": -119.06,
        "Latitude": 502132500,
        "Longitude": -1190322300,
        "Elevation (m)": 1585.6,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "VERNON SOUTHWEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128585",
        "Station ID": 1071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -119.37,
        "Latitude": 501400000,
        "Longitude": -1192200000,
        "Elevation (m)": 370,
        "First Year": 1966,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1991,
        "MLY First Year": 1966,
        "MLY Last Year": 1991
      },
      {
        "Name": "VERNON SWAN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128586",
        "Station ID": 26996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -119.27,
        "Latitude": 501800000,
        "Longitude": -1191600000,
        "Elevation (m)": 490,
        "First Year": 1994,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2007,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "WESTBANK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128758",
        "Station ID": 1072,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -119.63,
        "Latitude": 495000000,
        "Longitude": -1193800000,
        "Elevation (m)": 520.9,
        "First Year": 1981,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1996,
        "MLY First Year": 1982,
        "MLY Last Year": 1996
      },
      {
        "Name": "WESTBANK IRRIGATION",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128759",
        "Station ID": 1073,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -119.63,
        "Latitude": 495000000,
        "Longitude": -1193800000,
        "Elevation (m)": 420.9,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "WINFIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128958",
        "Station ID": 1074,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.04,
        "Longitude (Decimal Degrees)": -119.42,
        "Latitude": 500211600,
        "Longitude": -1192458600,
        "Elevation (m)": 506,
        "First Year": 1971,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2016,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINFIELD WOOD LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128959",
        "Station ID": 1075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 500300000,
        "Longitude": -1192300000,
        "Elevation (m)": 457.2,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "WOODS LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1128983",
        "Station ID": 1076,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -119.45,
        "Latitude": 500300000,
        "Longitude": -1192700000,
        "Elevation (m)": 405.4,
        "First Year": 1915,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1939,
        "MLY First Year": 1915,
        "MLY Last Year": 1939
      },
      {
        "Name": "KELOWNA N GLENMORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112C991",
        "Station ID": 977,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -119.43,
        "Latitude": 495600000,
        "Longitude": -1192600000,
        "Elevation (m)": 451,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "OKANAGAN CENTRE CAMP RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112E700",
        "Station ID": 978,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -119.43,
        "Latitude": 500300000,
        "Longitude": -1192600000,
        "Elevation (m)": 445,
        "First Year": 1984,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1988,
        "MLY First Year": 1984,
        "MLY Last Year": 1988
      },
      {
        "Name": "PRINCETON CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112FN0M",
        "Station ID": 6834,
        "WMO ID": 71032,
        "TC ID": "WPR",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -120.51,
        "Latitude": 492754000,
        "Longitude": -1203036000,
        "Elevation (m)": 696.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "SUMMERLAND CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112G8L1",
        "Station ID": 979,
        "WMO ID": 71768,
        "TC ID": "WUS",
        "Latitude (Decimal Degrees)": 49.56,
        "Longitude (Decimal Degrees)": -119.65,
        "Latitude": 493345200,
        "Longitude": -1193855300,
        "Elevation (m)": 454.2,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1990,
        "DLY Last Year": 2022,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "VERNON SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112HEHD",
        "Station ID": 980,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -119.27,
        "Latitude": 501500000,
        "Longitude": -1191600000,
        "Elevation (m)": 381,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "ELKHART LODGE HIGHWAY 97C",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112K653",
        "Station ID": 6835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -120.35,
        "Latitude": 495600000,
        "Longitude": -1202100000,
        "Elevation (m)": 1615,
        "First Year": 1992,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1995,
        "MLY First Year": 1992,
        "MLY Last Year": 1995
      },
      {
        "Name": "OLIVER ROCKCLIFF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "112N765",
        "Station ID": 981,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -119.57,
        "Latitude": 491000000,
        "Longitude": -1193400000,
        "Elevation (m)": 332,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "BEAVERDELL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1130770",
        "Station ID": 1077,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -119.11,
        "Latitude": 492521600,
        "Longitude": -1190618000,
        "Elevation (m)": 780,
        "First Year": 1925,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 2000,
        "MLY First Year": 1925,
        "MLY Last Year": 2000
      },
      {
        "Name": "BEAVERDELL NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1130771",
        "Station ID": 1078,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -119.05,
        "Latitude": 492842000,
        "Longitude": -1190249200,
        "Elevation (m)": 838.2,
        "First Year": 1975,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2006,
        "MLY First Year": 1975,
        "MLY Last Year": 2006
      },
      {
        "Name": "BIG WHITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1130874",
        "Station ID": 1079,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -118.93,
        "Latitude": 494400000,
        "Longitude": -1185600000,
        "Elevation (m)": 1841,
        "First Year": 1981,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1999,
        "MLY First Year": 1981,
        "MLY Last Year": 1999
      },
      {
        "Name": "BIG WHITE MTN LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1130875",
        "Station ID": 1080,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -118.93,
        "Latitude": 494400000,
        "Longitude": -1185600000,
        "Elevation (m)": 1844,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "BRIDESVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1130975",
        "Station ID": 1081,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -119.17,
        "Latitude": 490300000,
        "Longitude": -1191000000,
        "Elevation (m)": 1187,
        "First Year": 1957,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1994,
        "MLY First Year": 1957,
        "MLY Last Year": 1994
      },
      {
        "Name": "CARMI",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1131410",
        "Station ID": 1082,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -119.08,
        "Latitude": 493000000,
        "Longitude": -1190500000,
        "Elevation (m)": 1244.8,
        "First Year": 1924,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1969,
        "MLY First Year": 1924,
        "MLY Last Year": 1969
      },
      {
        "Name": "CHRISTIAN VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1131615",
        "Station ID": 1083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -118.75,
        "Latitude": 493900000,
        "Longitude": -1184500000,
        "Elevation (m)": 804.7,
        "First Year": 1972,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1985,
        "MLY First Year": 1972,
        "MLY Last Year": 1985
      },
      {
        "Name": "GRAND FORKS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1133270",
        "Station ID": 1084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -118.47,
        "Latitude": 490134200,
        "Longitude": -1182756400,
        "Elevation (m)": 531.9,
        "First Year": 1941,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 2008,
        "MLY First Year": 1941,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRAND FORKS A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1133271",
        "Station ID": 1085,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -118.45,
        "Latitude": 490200000,
        "Longitude": -1182700000,
        "Elevation (m)": 530.4,
        "First Year": 1936,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1940,
        "MLY First Year": 1936,
        "MLY Last Year": 1940
      },
      {
        "Name": "GRAND FORKS HARDY MTN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1133273",
        "Station ID": 50579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.04,
        "Longitude (Decimal Degrees)": -118.49,
        "Latitude": 490214032,
        "Longitude": -1182929077,
        "Elevation (m)": 623,
        "First Year": 2012,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2012,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRAND FORKS PHOENIX",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1133274",
        "Station ID": 1086,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -118.58,
        "Latitude": 490600000,
        "Longitude": -1183500000,
        "Elevation (m)": 1414.3,
        "First Year": 1970,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "GRAND FORKS RAYFIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1133275",
        "Station ID": 1087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -118.47,
        "Latitude": 490100000,
        "Longitude": -1182800000,
        "Elevation (m)": 523,
        "First Year": 1909,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 1990,
        "MLY First Year": 1909,
        "MLY Last Year": 1990
      },
      {
        "Name": "GREENWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1133300",
        "Station ID": 1088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -118.68,
        "Latitude": 490600000,
        "Longitude": -1184100000,
        "Elevation (m)": 762.8,
        "First Year": 1911,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1990,
        "MLY First Year": 1911,
        "MLY Last Year": 1990
      },
      {
        "Name": "KETTLE VALLEY BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1134130",
        "Station ID": 1089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -118.93,
        "Latitude": 490400000,
        "Longitude": -1185600000,
        "Elevation (m)": 594.7,
        "First Year": 1972,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1980,
        "MLY First Year": 1972,
        "MLY Last Year": 1980
      },
      {
        "Name": "LYNCH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1134735",
        "Station ID": 1090,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -118.43,
        "Latitude": 491500000,
        "Longitude": -1182600000,
        "Elevation (m)": 548.6,
        "First Year": 1924,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1948,
        "MLY First Year": 1924,
        "MLY Last Year": 1948
      },
      {
        "Name": "MIDWAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1135126",
        "Station ID": 1091,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -118.77,
        "Latitude": 490013264,
        "Longitude": -1184618507,
        "Elevation (m)": 571,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROCK CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1136812",
        "Station ID": 1092,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -119.02,
        "Latitude": 490400000,
        "Longitude": -1190100000,
        "Elevation (m)": 609.6,
        "First Year": 1912,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1947,
        "MLY First Year": 1912,
        "MLY Last Year": 1947
      },
      {
        "Name": "ROCK CREEK MT BALDY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1136813",
        "Station ID": 1093,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -119.16,
        "Latitude": 490700000,
        "Longitude": -1190927000,
        "Elevation (m)": 1174,
        "First Year": 1988,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2000,
        "MLY First Year": 1988,
        "MLY Last Year": 2000
      },
      {
        "Name": "ROCK CREEK WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1136815",
        "Station ID": 1094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -119.07,
        "Latitude": 490300000,
        "Longitude": -1190400000,
        "Elevation (m)": 975.4,
        "First Year": 1963,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1967
      },
      {
        "Name": "ARGENTA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1140448",
        "Station ID": 1099,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -116.93,
        "Latitude": 501000000,
        "Longitude": -1165600000,
        "Elevation (m)": 548.6,
        "First Year": 1957,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1974,
        "MLY First Year": 1957,
        "MLY Last Year": 1974
      },
      {
        "Name": "BILLINGS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1140876",
        "Station ID": 1100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -118.23,
        "Latitude": 490100600,
        "Longitude": -1181342300,
        "Elevation (m)": 519,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "BIRCHBANK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1140877",
        "Station ID": 1101,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -117.72,
        "Latitude": 491000000,
        "Longitude": -1174300000,
        "Elevation (m)": 469.4,
        "First Year": 1924,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1945,
        "MLY First Year": 1924,
        "MLY Last Year": 1945
      },
      {
        "Name": "BONNINGTON FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1140903",
        "Station ID": 1102,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -117.48,
        "Latitude": 492700000,
        "Longitude": -1172900000,
        "Elevation (m)": 457.2,
        "First Year": 1924,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1939,
        "MLY First Year": 1924,
        "MLY Last Year": 1939
      },
      {
        "Name": "BOSWELL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1140905",
        "Station ID": 1103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -116.75,
        "Latitude": 492900000,
        "Longitude": -1164500000,
        "Elevation (m)": 573,
        "First Year": 1911,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1940,
        "MLY First Year": 1911,
        "MLY Last Year": 1940
      },
      {
        "Name": "CASTLEGAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141450",
        "Station ID": 1104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -117.67,
        "Latitude": 492000000,
        "Longitude": -1174000000,
        "Elevation (m)": 434.3,
        "First Year": 1916,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1963,
        "MLY First Year": 1916,
        "MLY Last Year": 1963
      },
      {
        "Name": "CASTLEGAR A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141455",
        "Station ID": 1105,
        "WMO ID": null,
        "TC ID": "YCG",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -117.63,
        "Latitude": 491747000,
        "Longitude": -1173757000,
        "Elevation (m)": 495.6,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": 1954,
        "HLY Last Year": 2013,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "CASTLEGAR A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141456",
        "Station ID": 51458,
        "WMO ID": null,
        "TC ID": "YCG",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -117.63,
        "Latitude": 491746000,
        "Longitude": -1173757000,
        "Elevation (m)": 495.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CASTLEGAR BCHPA DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141457",
        "Station ID": 1106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.34,
        "Longitude (Decimal Degrees)": -117.77,
        "Latitude": 492036220,
        "Longitude": -1174627040,
        "Elevation (m)": 435,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "CASTLEGAR A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141460",
        "Station ID": 52938,
        "WMO ID": null,
        "TC ID": "YCG",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -117.63,
        "Latitude": 491746000,
        "Longitude": -1173757000,
        "Elevation (m)": 495.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CASTLEGAR ROBSON PUMPHOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141463",
        "Station ID": 30434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.34,
        "Longitude (Decimal Degrees)": -117.73,
        "Latitude": 492028000,
        "Longitude": -1174405000,
        "Elevation (m)": 435,
        "First Year": 1998,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2003,
        "MLY First Year": 1998,
        "MLY Last Year": 2003
      },
      {
        "Name": "COLUMBIA GARDENS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141800",
        "Station ID": 1107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -117.6,
        "Latitude": 490300000,
        "Longitude": -1173600000,
        "Elevation (m)": 432.8,
        "First Year": 1937,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1970,
        "MLY First Year": 1937,
        "MLY Last Year": 1970
      },
      {
        "Name": "COLUMBIA GARDENS NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1141803",
        "Station ID": 1108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -117.6,
        "Latitude": 490400000,
        "Longitude": -1173600000,
        "Elevation (m)": 487.7,
        "First Year": 1970,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1976,
        "MLY First Year": 1970,
        "MLY Last Year": 1976
      },
      {
        "Name": "CRAWFORD BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142112",
        "Station ID": 1109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -116.83,
        "Latitude": 494100000,
        "Longitude": -1165000000,
        "Elevation (m)": 594.4,
        "First Year": 1907,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1969,
        "MLY First Year": 1907,
        "MLY Last Year": 1969
      },
      {
        "Name": "CRESCENT VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142130",
        "Station ID": 1110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -117.57,
        "Latitude": 492700000,
        "Longitude": -1173400000,
        "Elevation (m)": 609.6,
        "First Year": 1939,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1965,
        "MLY First Year": 1939,
        "MLY Last Year": 1965
      },
      {
        "Name": "CRESTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142160",
        "Station ID": 1111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -116.52,
        "Latitude": 490549400,
        "Longitude": -1163104200,
        "Elevation (m)": 610.4,
        "First Year": 1912,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 2017,
        "MLY First Year": 1912,
        "MLY Last Year": 2007
      },
      {
        "Name": "CRESTON SOUTHEAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142161",
        "Station ID": 1112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 490400000,
        "Longitude": -1162900000,
        "Elevation (m)": 715,
        "First Year": 1990,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1994,
        "MLY First Year": 1990,
        "MLY Last Year": 1994
      },
      {
        "Name": "CRESTON WPCC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142164",
        "Station ID": 1113,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -116.53,
        "Latitude": 490544000,
        "Longitude": -1163146000,
        "Elevation (m)": 538,
        "First Year": 1980,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2006,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "DEER PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142400",
        "Station ID": 1114,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -118.05,
        "Latitude": 492500000,
        "Longitude": -1180300000,
        "Elevation (m)": 485,
        "First Year": 1924,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1995,
        "MLY First Year": 1924,
        "MLY Last Year": 1995
      },
      {
        "Name": "DUNCAN LAKE DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142574",
        "Station ID": 1115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.24,
        "Longitude (Decimal Degrees)": -116.97,
        "Latitude": 501419200,
        "Longitude": -1165817500,
        "Elevation (m)": 548.6,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDGEWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142640",
        "Station ID": 1116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -118.13,
        "Latitude": 495000000,
        "Longitude": -1180800000,
        "Elevation (m)": 427.9,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1958,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "FAUQUIER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142820",
        "Station ID": 1117,
        "WMO ID": null,
        "TC ID": "WFS",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -118.07,
        "Latitude": 495219000,
        "Longitude": -1180403000,
        "Elevation (m)": 490,
        "First Year": 1913,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2017,
        "MLY First Year": 1913,
        "MLY Last Year": 2007
      },
      {
        "Name": "FAUQUIER 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142826",
        "Station ID": 1118,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -118.07,
        "Latitude": 495200000,
        "Longitude": -1180400000,
        "Elevation (m)": 470,
        "First Year": 1990,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1992,
        "MLY First Year": 1990,
        "MLY Last Year": 1992
      },
      {
        "Name": "FERGUSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1142845",
        "Station ID": 1119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -117.48,
        "Latitude": 504000000,
        "Longitude": -1172900000,
        "Elevation (m)": null,
        "First Year": 1908,
        "Last Year": 1922,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1922,
        "MLY First Year": 1908,
        "MLY Last Year": 1922
      },
      {
        "Name": "GENELLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1143085",
        "Station ID": 1120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -117.68,
        "Latitude": 491300000,
        "Longitude": -1174100000,
        "Elevation (m)": 472.4,
        "First Year": 1962,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1962,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "GERRARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1143120",
        "Station ID": 1121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -117.3,
        "Latitude": 503000000,
        "Longitude": -1171800000,
        "Elevation (m)": 715.7,
        "First Year": 1935,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1946,
        "MLY First Year": 1935,
        "MLY Last Year": 1946
      },
      {
        "Name": "HARROP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1143338",
        "Station ID": 1122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -117.05,
        "Latitude": 493600000,
        "Longitude": -1170300000,
        "Elevation (m)": 538,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "HOWSER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1143617",
        "Station ID": 1123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -117,
        "Latitude": 501800000,
        "Longitude": -1170000000,
        "Elevation (m)": 571.2,
        "First Year": 1912,
        "Last Year": 1927,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1927,
        "MLY First Year": 1912,
        "MLY Last Year": 1927
      },
      {
        "Name": "KASLO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1143900",
        "Station ID": 1124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -116.92,
        "Latitude": 495445700,
        "Longitude": -1165514300,
        "Elevation (m)": 600,
        "First Year": 1894,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 2018,
        "MLY First Year": 1894,
        "MLY Last Year": 2007
      },
      {
        "Name": "KINGSGATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1144230",
        "Station ID": 1125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -116.18,
        "Latitude": 490000000,
        "Longitude": -1161100000,
        "Elevation (m)": 819.9,
        "First Year": 1924,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1962,
        "MLY First Year": 1924,
        "MLY Last Year": 1962
      },
      {
        "Name": "KOOTENAY BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1144388",
        "Station ID": 1126,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -116.87,
        "Latitude": 494000000,
        "Longitude": -1165200000,
        "Elevation (m)": 548.6,
        "First Year": 1974,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1981,
        "MLY First Year": 1974,
        "MLY Last Year": 1980
      },
      {
        "Name": "KOOTENAY PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1144413",
        "Station ID": 1127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -117.03,
        "Latitude": 490400000,
        "Longitude": -1170200000,
        "Elevation (m)": 1773.9,
        "First Year": 1974,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1989,
        "MLY First Year": 1974,
        "MLY Last Year": 1989
      },
      {
        "Name": "LARDEAU",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1144580",
        "Station ID": 1128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -116.97,
        "Latitude": 501200000,
        "Longitude": -1165800000,
        "Elevation (m)": 550.2,
        "First Year": 1961,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1980,
        "MLY First Year": 1961,
        "MLY Last Year": 1980
      },
      {
        "Name": "LARDEAU CREEK GALENA LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1144582",
        "Station ID": 1129,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.66,
        "Longitude (Decimal Degrees)": -117.39,
        "Latitude": 503946056,
        "Longitude": -1172338058,
        "Elevation (m)": 1097,
        "First Year": 1989,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2008,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "LISTER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1144635",
        "Station ID": 1130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -116.46,
        "Latitude": 490155100,
        "Longitude": -1162727800,
        "Elevation (m)": 660,
        "First Year": 1956,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2017,
        "MLY First Year": 1956,
        "MLY Last Year": 2007
      },
      {
        "Name": "NAKUSP CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145297",
        "Station ID": 6840,
        "WMO ID": 71216,
        "TC ID": "WNP",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -117.82,
        "Latitude": 501609930,
        "Longitude": -1174901540,
        "Elevation (m)": 512.1,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "NAKUSP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145300",
        "Station ID": 1131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 501500000,
        "Longitude": -1174800000,
        "Elevation (m)": 457.2,
        "First Year": 1912,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1995,
        "MLY First Year": 1912,
        "MLY Last Year": 1995
      },
      {
        "Name": "NAKUSP GLENBANK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145303",
        "Station ID": 1132,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -117.78,
        "Latitude": 501600000,
        "Longitude": -1174700000,
        "Elevation (m)": 585,
        "First Year": 1985,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1991,
        "MLY First Year": 1985,
        "MLY Last Year": 1991
      },
      {
        "Name": "NEEDLES WHATSHAN BCHPA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145419",
        "Station ID": 1133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -118.08,
        "Latitude": 495400000,
        "Longitude": -1180500000,
        "Elevation (m)": 463.3,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "NEEDLES WHATSHAN PH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145420",
        "Station ID": 1134,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -118.07,
        "Latitude": 495500000,
        "Longitude": -1180400000,
        "Elevation (m)": 433.1,
        "First Year": 1955,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1969,
        "MLY First Year": 1955,
        "MLY Last Year": 1969
      },
      {
        "Name": "NELSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145430",
        "Station ID": 1135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -117.35,
        "Latitude": 492900000,
        "Longitude": -1172100000,
        "Elevation (m)": 539.5,
        "First Year": 1904,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1955,
        "MLY First Year": 1904,
        "MLY Last Year": 1955
      },
      {
        "Name": "NELSON CITY HALL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145433",
        "Station ID": 1136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -117.35,
        "Latitude": 492900000,
        "Longitude": -1172100000,
        "Elevation (m)": 551.7,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "NELSON NE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145442",
        "Station ID": 1137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.59,
        "Longitude (Decimal Degrees)": -117.21,
        "Latitude": 493510000,
        "Longitude": -1171223000,
        "Elevation (m)": 570,
        "First Year": 1983,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2022,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "NELSON NOTRE DAME U",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145443",
        "Station ID": 1138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -117.27,
        "Latitude": 493000000,
        "Longitude": -1171600000,
        "Elevation (m)": 627.9,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "NELSON 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145444",
        "Station ID": 1139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -117.28,
        "Latitude": 493100000,
        "Longitude": -1171700000,
        "Elevation (m)": 552,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "NELSON 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145445",
        "Station ID": 1140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -117.28,
        "Latitude": 493000000,
        "Longitude": -1171700000,
        "Elevation (m)": 603.5,
        "First Year": 1955,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1982,
        "MLY First Year": 1955,
        "MLY Last Year": 1982
      },
      {
        "Name": "NELSON VOCATIONAL SCHOOL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145446",
        "Station ID": 1141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -117.3,
        "Latitude": 492900000,
        "Longitude": -1171800000,
        "Elevation (m)": 678.5,
        "First Year": 1972,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1978,
        "MLY First Year": 1972,
        "MLY Last Year": 1978
      },
      {
        "Name": "NEW DENVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145460",
        "Station ID": 1142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -117.37,
        "Latitude": 495945006,
        "Longitude": -1172213026,
        "Elevation (m)": 568,
        "First Year": 1924,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2022,
        "MLY First Year": 1924,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLD GLORY MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145730",
        "Station ID": 1143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -117.92,
        "Latitude": 490900000,
        "Longitude": -1175500000,
        "Elevation (m)": 2347,
        "First Year": 1944,
        "Last Year": 1967,
        "HLY First Year": 1953,
        "HLY Last Year": 1967,
        "DLY First Year": 1944,
        "DLY Last Year": 1967,
        "MLY First Year": 1945,
        "MLY Last Year": 1967
      },
      {
        "Name": "NELSON CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1145M29",
        "Station ID": 6839,
        "WMO ID": 71776,
        "TC ID": "WNM",
        "Latitude (Decimal Degrees)": 49.49,
        "Longitude (Decimal Degrees)": -117.31,
        "Latitude": 492929000,
        "Longitude": -1171819000,
        "Elevation (m)": 534.9,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2006
      },
      {
        "Name": "PATERSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1146000",
        "Station ID": 1144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -117.82,
        "Latitude": 490000000,
        "Longitude": -1174900000,
        "Elevation (m)": 673,
        "First Year": 1954,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1963,
        "MLY First Year": 1954,
        "MLY Last Year": 1963
      },
      {
        "Name": "PERRY SIDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1146168",
        "Station ID": 1145,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -117.48,
        "Latitude": 494000000,
        "Longitude": -1172900000,
        "Elevation (m)": 518.2,
        "First Year": 1913,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1939,
        "MLY First Year": 1913,
        "MLY Last Year": 1939
      },
      {
        "Name": "ROBSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1146810",
        "Station ID": 1146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 492000000,
        "Longitude": -1174200000,
        "Elevation (m)": 442,
        "First Year": 1946,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1980,
        "MLY First Year": 1946,
        "MLY Last Year": 1980
      },
      {
        "Name": "ROSSLAND CITY YARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1146870",
        "Station ID": 1147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 490439000,
        "Longitude": -1174807200,
        "Elevation (m)": 1039,
        "First Year": 1900,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 2000,
        "MLY First Year": 1900,
        "MLY Last Year": 2000
      },
      {
        "Name": "ROSSLAND MACLEAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1146874",
        "Station ID": 1148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 490500000,
        "Longitude": -1174800000,
        "Elevation (m)": 1085.1,
        "First Year": 1963,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1990,
        "MLY First Year": 1963,
        "MLY Last Year": 1990
      },
      {
        "Name": "SALMO BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1146944",
        "Station ID": 1149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -117.3,
        "Latitude": 491100000,
        "Longitude": -1171800000,
        "Elevation (m)": 684.9,
        "First Year": 1972,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1980,
        "MLY First Year": 1972,
        "MLY Last Year": 1980
      },
      {
        "Name": "SANDON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1147020",
        "Station ID": 1150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -117.22,
        "Latitude": 495800000,
        "Longitude": -1171300000,
        "Elevation (m)": 1082,
        "First Year": 1941,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1964,
        "MLY First Year": 1941,
        "MLY Last Year": 1964
      },
      {
        "Name": "SHELTER BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1147250",
        "Station ID": 1151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -117.93,
        "Latitude": 503700000,
        "Longitude": -1175600000,
        "Elevation (m)": 457.2,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "SIRDAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1147400",
        "Station ID": 1152,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -116.62,
        "Latitude": 491500000,
        "Longitude": -1163700000,
        "Elevation (m)": 566,
        "First Year": 1958,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "SOUTH SLOCAN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1147620",
        "Station ID": 1153,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.46,
        "Longitude (Decimal Degrees)": -117.52,
        "Latitude": 492736500,
        "Longitude": -1173122400,
        "Elevation (m)": 457.2,
        "First Year": 1940,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 2008,
        "MLY First Year": 1940,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOUTH SLOCAN 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1147622",
        "Station ID": 1154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -117.52,
        "Latitude": 492800000,
        "Longitude": -1173100000,
        "Elevation (m)": 518.2,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "TARRYS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1147995",
        "Station ID": 1155,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -117.57,
        "Latitude": 492200000,
        "Longitude": -1173400000,
        "Elevation (m)": 470,
        "First Year": 1924,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1943,
        "MLY First Year": 1924,
        "MLY Last Year": 1943
      },
      {
        "Name": "TRAIL SUNNINGDALE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148212",
        "Station ID": 1156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -117.73,
        "Latitude": 490800000,
        "Longitude": -1174400000,
        "Elevation (m)": 432.8,
        "First Year": 1959,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1970,
        "MLY First Year": 1959,
        "MLY Last Year": 1970
      },
      {
        "Name": "TROUT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148262",
        "Station ID": 1157,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -117.5,
        "Latitude": 502000000,
        "Longitude": -1173000000,
        "Elevation (m)": 717.8,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "WANETA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148670",
        "Station ID": 1158,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -117.58,
        "Latitude": 490100000,
        "Longitude": -1173500000,
        "Elevation (m)": 557.8,
        "First Year": 1913,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1977,
        "MLY First Year": 1913,
        "MLY Last Year": 1977
      },
      {
        "Name": "WARFIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148700",
        "Station ID": 1159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -117.75,
        "Latitude": 490600000,
        "Longitude": -1174500000,
        "Elevation (m)": 605.6,
        "First Year": 1928,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 2002,
        "MLY First Year": 1928,
        "MLY Last Year": 2002
      },
      {
        "Name": "WARFIELD RCS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148705",
        "Station ID": 31067,
        "WMO ID": 71401,
        "TC ID": "XWF",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -117.74,
        "Latitude": 490643000,
        "Longitude": -1174420000,
        "Elevation (m)": 566.9,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "WEST CRESTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148790",
        "Station ID": 1160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -116.58,
        "Latitude": 490500000,
        "Longitude": -1163500000,
        "Elevation (m)": 548.6,
        "First Year": 1949,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1962,
        "MLY First Year": 1949,
        "MLY Last Year": 1962
      },
      {
        "Name": "WILMER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1148957",
        "Station ID": 1161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -116.23,
        "Latitude": 503400000,
        "Longitude": -1161400000,
        "Elevation (m)": 1005.8,
        "First Year": 1909,
        "Last Year": 1926,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 1926,
        "MLY First Year": 1909,
        "MLY Last Year": 1926
      },
      {
        "Name": "WYNNDEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1149000",
        "Station ID": 1162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -116.58,
        "Latitude": 491000000,
        "Longitude": -1163500000,
        "Elevation (m)": 597.4,
        "First Year": 1949,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1969,
        "MLY First Year": 1949,
        "MLY Last Year": 1969
      },
      {
        "Name": "WYNNDEL NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1149001",
        "Station ID": 1163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -116.58,
        "Latitude": 491200000,
        "Longitude": -1163500000,
        "Elevation (m)": 579.1,
        "First Year": 1968,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1977,
        "MLY First Year": 1968,
        "MLY Last Year": 1977
      },
      {
        "Name": "CRESTON CAMPBELL SCIENTIFIC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "114B1F0",
        "Station ID": 6838,
        "WMO ID": 71770,
        "TC ID": "WJR",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -116.5,
        "Latitude": 490454082,
        "Longitude": -1163002449,
        "Elevation (m)": 640.7,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "NELSON RIXEN CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "114EMDM",
        "Station ID": 1095,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.51,
        "Longitude (Decimal Degrees)": -117.4,
        "Latitude": 493044400,
        "Longitude": -1172355700,
        "Elevation (m)": 760,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2022,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "PASSMORE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "114Q3C9",
        "Station ID": 1096,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.54,
        "Longitude (Decimal Degrees)": -117.65,
        "Latitude": 493219000,
        "Longitude": -1173852000,
        "Elevation (m)": 503,
        "First Year": 1987,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2004,
        "MLY First Year": 1987,
        "MLY Last Year": 2004
      },
      {
        "Name": "YMIR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "114R006",
        "Station ID": 1098,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -117.2,
        "Latitude": 491600000,
        "Longitude": -1171200000,
        "Elevation (m)": 731.5,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "YAHK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "114R00A",
        "Station ID": 1097,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -116.1,
        "Latitude": 490500000,
        "Longitude": -1160600000,
        "Elevation (m)": 862.6,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "ABERFELDIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1150060",
        "Station ID": 1166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -115.37,
        "Latitude": 493000000,
        "Longitude": -1152200000,
        "Elevation (m)": 804.7,
        "First Year": 1950,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1982,
        "MLY First Year": 1950,
        "MLY Last Year": 1982
      },
      {
        "Name": "BAYNES LAKE KOOTENAY RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1150690",
        "Station ID": 1167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -115.22,
        "Latitude": 491333700,
        "Longitude": -1151313800,
        "Elevation (m)": 800,
        "First Year": 1986,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2017,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "CANAL FLATS RANGER STN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1151288",
        "Station ID": 1168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -115.82,
        "Latitude": 500900000,
        "Longitude": -1154900000,
        "Elevation (m)": 816.9,
        "First Year": 1962,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1982,
        "MLY First Year": 1962,
        "MLY Last Year": 1982
      },
      {
        "Name": "CANAL FLATS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1151290",
        "Station ID": 1169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -115.82,
        "Latitude": 500900000,
        "Longitude": -1154900000,
        "Elevation (m)": 808.6,
        "First Year": 1924,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1948,
        "MLY First Year": 1924,
        "MLY Last Year": 1948
      },
      {
        "Name": "CORBIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1151915",
        "Station ID": 1170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -114.65,
        "Latitude": 493100000,
        "Longitude": -1143900000,
        "Elevation (m)": 1572.2,
        "First Year": 1977,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1993,
        "MLY First Year": 1977,
        "MLY Last Year": 1993
      },
      {
        "Name": "CRANBROOK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152090",
        "Station ID": 1172,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 493000000,
        "Longitude": -1154700000,
        "Elevation (m)": 918.7,
        "First Year": 1901,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1901,
        "DLY Last Year": 1939,
        "MLY First Year": 1901,
        "MLY Last Year": 1939
      },
      {
        "Name": "CRANBROOK A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152100",
        "Station ID": 1173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -115.77,
        "Latitude": 493200000,
        "Longitude": -1154600000,
        "Elevation (m)": 918.4,
        "First Year": 1938,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1968,
        "MLY First Year": 1938,
        "MLY Last Year": 1968
      },
      {
        "Name": "CRANBROOK A - HWOS CLIMATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152101",
        "Station ID": 50957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.61,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 493644000,
        "Longitude": -1154655000,
        "Elevation (m)": 918,
        "First Year": 2012,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2012,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CRANBROOK A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152102",
        "Station ID": 1174,
        "WMO ID": 71880,
        "TC ID": "YXC",
        "Latitude (Decimal Degrees)": 49.61,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 493644000,
        "Longitude": -1154655000,
        "Elevation (m)": 940,
        "First Year": 1968,
        "Last Year": 2012,
        "HLY First Year": 1968,
        "HLY Last Year": 2012,
        "DLY First Year": 1968,
        "DLY Last Year": 2012,
        "MLY First Year": 1968,
        "MLY Last Year": 2012
      },
      {
        "Name": "CRANBROOK MAYOOK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152103",
        "Station ID": 1175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -115.52,
        "Latitude": 492700000,
        "Longitude": -1153100000,
        "Elevation (m)": 854,
        "First Year": 1974,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1986,
        "MLY First Year": 1974,
        "MLY Last Year": 1986
      },
      {
        "Name": "CRANBROOK MT BAKER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152104",
        "Station ID": 1176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -115.73,
        "Latitude": 493000000,
        "Longitude": -1154400000,
        "Elevation (m)": 1005.8,
        "First Year": 1972,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1977,
        "MLY First Year": 1972,
        "MLY Last Year": 1977
      },
      {
        "Name": "CRANBROOK A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152105",
        "Station ID": 50818,
        "WMO ID": 71880,
        "TC ID": "YXC",
        "Latitude (Decimal Degrees)": 49.61,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 493644000,
        "Longitude": -1154655000,
        "Elevation (m)": 940,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CRANBROOK AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152106",
        "Station ID": 51818,
        "WMO ID": 71660,
        "TC ID": "VXC",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -115.79,
        "Latitude": 493703428,
        "Longitude": -1154720576,
        "Elevation (m)": 926.69,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CRANBROOK SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152109",
        "Station ID": 1177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -115.75,
        "Latitude": 492834000,
        "Longitude": -1154454000,
        "Elevation (m)": 1082,
        "First Year": 1984,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2002,
        "MLY First Year": 1984,
        "MLY Last Year": 2002
      },
      {
        "Name": "ELKFORD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152653",
        "Station ID": 1178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 500100000,
        "Longitude": -1145500000,
        "Elevation (m)": 1370,
        "First Year": 1972,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1993,
        "MLY First Year": 1972,
        "MLY Last Year": 1993
      },
      {
        "Name": "ELKO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152670",
        "Station ID": 1179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 491800000,
        "Longitude": -1150700000,
        "Elevation (m)": 931,
        "First Year": 1923,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1995,
        "MLY First Year": 1923,
        "MLY Last Year": 1995
      },
      {
        "Name": "FERNIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152850",
        "Station ID": 1180,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.49,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 492919400,
        "Longitude": -1150423900,
        "Elevation (m)": 1001,
        "First Year": 1913,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2022,
        "MLY First Year": 1913,
        "MLY Last Year": 2007
      },
      {
        "Name": "FERNIE SNOW VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152851",
        "Station ID": 1181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -115.1,
        "Latitude": 492800000,
        "Longitude": -1150600000,
        "Elevation (m)": 1371.6,
        "First Year": 1975,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "FERNIE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152852",
        "Station ID": 1182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 493000000,
        "Longitude": -1150400000,
        "Elevation (m)": 1009.8,
        "First Year": 1924,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1935,
        "MLY First Year": 1924,
        "MLY Last Year": 1935
      },
      {
        "Name": "FORDING RIVER CLODE CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152898",
        "Station ID": 1183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -114.82,
        "Latitude": 501200000,
        "Longitude": -1144900000,
        "Elevation (m)": 2100,
        "First Year": 1976,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1987,
        "MLY First Year": 1976,
        "MLY Last Year": 1987
      },
      {
        "Name": "FORDING RIVER COMINCO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152899",
        "Station ID": 1184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -114.86,
        "Latitude": 500855000,
        "Longitude": -1145118000,
        "Elevation (m)": 1585,
        "First Year": 1970,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2017,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "CRANBROOK CITY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1152J02",
        "Station ID": 1171,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.51,
        "Longitude (Decimal Degrees)": -115.76,
        "Latitude": 493033000,
        "Longitude": -1154539000,
        "Elevation (m)": 926,
        "First Year": 1979,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2003,
        "MLY First Year": 1979,
        "MLY Last Year": 2003
      },
      {
        "Name": "FORT STEELE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1153033",
        "Station ID": 1185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -115.62,
        "Latitude": 493800000,
        "Longitude": -1153700000,
        "Elevation (m)": 915,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "FT STEELE DANDY CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1153034",
        "Station ID": 1186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -115.46,
        "Latitude": 493126100,
        "Longitude": -1152738100,
        "Elevation (m)": 856,
        "First Year": 1968,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2022,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRASMERE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1153282",
        "Station ID": 1187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 490500000,
        "Longitude": -1150400000,
        "Elevation (m)": 868.7,
        "First Year": 1962,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1993,
        "MLY First Year": 1962,
        "MLY Last Year": 1993
      },
      {
        "Name": "HANSON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1153335",
        "Station ID": 1188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -115.73,
        "Latitude": 494700000,
        "Longitude": -1154400000,
        "Elevation (m)": 774.2,
        "First Year": 1976,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "INVERMERE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1153655",
        "Station ID": 1189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -116.03,
        "Latitude": 503000000,
        "Longitude": -1160200000,
        "Elevation (m)": 810,
        "First Year": 1912,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1993,
        "MLY First Year": 1913,
        "MLY Last Year": 1993
      },
      {
        "Name": "INVERMERE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1153656",
        "Station ID": 1190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 502800000,
        "Longitude": -1160300000,
        "Elevation (m)": 807.7,
        "First Year": 1912,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1930,
        "MLY First Year": 1912,
        "MLY Last Year": 1930
      },
      {
        "Name": "KIMBERLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154195",
        "Station ID": 1191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -115.98,
        "Latitude": 494100000,
        "Longitude": -1155900000,
        "Elevation (m)": 1176.5,
        "First Year": 1960,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1974,
        "MLY First Year": 1960,
        "MLY Last Year": 1974
      },
      {
        "Name": "KIMBERLEY A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154200",
        "Station ID": 1192,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 494400000,
        "Longitude": -1154700000,
        "Elevation (m)": 914.4,
        "First Year": 1943,
        "Last Year": 1976,
        "HLY First Year": 1953,
        "HLY Last Year": 1969,
        "DLY First Year": 1943,
        "DLY Last Year": 1976,
        "MLY First Year": 1943,
        "MLY Last Year": 1976
      },
      {
        "Name": "KIMBERLEY CHAPMAN CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154201",
        "Station ID": 1193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -115.98,
        "Latitude": 494100000,
        "Longitude": -1155900000,
        "Elevation (m)": 1097.3,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "KIMBERLEY COMINCO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154202",
        "Station ID": 6841,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -115.96,
        "Latitude": 493956400,
        "Longitude": -1155731800,
        "Elevation (m)": 1100,
        "First Year": 1992,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2002,
        "MLY First Year": 1992,
        "MLY Last Year": 2002
      },
      {
        "Name": "KIMBERLEY PCC",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154203",
        "Station ID": 1194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -115.96,
        "Latitude": 493753700,
        "Longitude": -1155744200,
        "Elevation (m)": 889,
        "First Year": 1975,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "KOOTENAY NAT PARK ADMIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154395",
        "Station ID": 1195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -116.07,
        "Latitude": 503700000,
        "Longitude": -1160400000,
        "Elevation (m)": 1001.6,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "KOOTENAY NP KTNY CRSG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154400",
        "Station ID": 1196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 505300000,
        "Longitude": -1160304000,
        "Elevation (m)": 1170.4,
        "First Year": 1965,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2002,
        "MLY First Year": 1965,
        "MLY Last Year": 2002
      },
      {
        "Name": "KTNY NP MARBLE CANYON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154402",
        "Station ID": 1197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -116.12,
        "Latitude": 511100000,
        "Longitude": -1160700000,
        "Elevation (m)": 1484.4,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "KOOTENAY NAT PARK RADIUM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154405",
        "Station ID": 1198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -116.02,
        "Latitude": 503900000,
        "Longitude": -1160100000,
        "Elevation (m)": 1088.1,
        "First Year": 1925,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1968,
        "MLY First Year": 1925,
        "MLY Last Year": 1968
      },
      {
        "Name": "KOOTENAY NP WEST GATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154410",
        "Station ID": 1199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -116.06,
        "Latitude": 503750900,
        "Longitude": -1160341000,
        "Elevation (m)": 935,
        "First Year": 1968,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2022,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARYSVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1154909",
        "Station ID": 1200,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.64,
        "Longitude (Decimal Degrees)": -115.96,
        "Latitude": 493813600,
        "Longitude": -1155725400,
        "Elevation (m)": 960,
        "First Year": 1972,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2009,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOYIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1155236",
        "Station ID": 1201,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -115.83,
        "Latitude": 491700000,
        "Longitude": -1155000000,
        "Elevation (m)": 944.9,
        "First Year": 1972,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1978,
        "MLY First Year": 1972,
        "MLY Last Year": 1978
      },
      {
        "Name": "NATAL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1155401",
        "Station ID": 1202,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 494200000,
        "Longitude": -1145500000,
        "Elevation (m)": 1143,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "NATAL HARMER RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1155402",
        "Station ID": 1203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -114.83,
        "Latitude": 494600000,
        "Longitude": -1145000000,
        "Elevation (m)": 1889.8,
        "First Year": 1971,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1991,
        "MLY First Year": 1971,
        "MLY Last Year": 1991
      },
      {
        "Name": "NATAL KAISER RESOURCES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1155403",
        "Station ID": 1204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 494500000,
        "Longitude": -1145300000,
        "Elevation (m)": 1127.8,
        "First Year": 1969,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1980,
        "MLY First Year": 1969,
        "MLY Last Year": 1980
      },
      {
        "Name": "NEWGATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1155490",
        "Station ID": 1205,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -115.15,
        "Latitude": 490100000,
        "Longitude": -1150900000,
        "Elevation (m)": 708.7,
        "First Year": 1914,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1960,
        "MLY First Year": 1914,
        "MLY Last Year": 1960
      },
      {
        "Name": "SINCLAIR PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1157380",
        "Station ID": 1206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -115.97,
        "Latitude": 504000000,
        "Longitude": -1155800000,
        "Elevation (m)": 1170.4,
        "First Year": 1935,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1955,
        "MLY First Year": 1935,
        "MLY Last Year": 1955
      },
      {
        "Name": "SPARWOOD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1157630",
        "Station ID": 1207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 494443000,
        "Longitude": -1145258000,
        "Elevation (m)": 1137.7,
        "First Year": 1980,
        "Last Year": 2020,
        "HLY First Year": 1980,
        "HLY Last Year": 2014,
        "DLY First Year": 1980,
        "DLY Last Year": 2020,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPARWOOD CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1157631",
        "Station ID": 6842,
        "WMO ID": 71782,
        "TC ID": "WGW",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 494442000,
        "Longitude": -1145302040,
        "Elevation (m)": 1136.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "SPARWOOD A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1157632",
        "Station ID": 52278,
        "WMO ID": null,
        "TC ID": "WSW",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 494443000,
        "Longitude": -1145258000,
        "Elevation (m)": 1137.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SPARWOOD A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1157635",
        "Station ID": 52959,
        "WMO ID": null,
        "TC ID": "WSW",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 495000000,
        "Longitude": -1145300000,
        "Elevation (m)": 1158.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TOBACCO PLAINS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1158200",
        "Station ID": 1208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -115.08,
        "Latitude": 490100000,
        "Longitude": -1150500000,
        "Elevation (m)": 701,
        "First Year": 1896,
        "Last Year": 1911,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1911,
        "MLY First Year": 1896,
        "MLY Last Year": 1911
      },
      {
        "Name": "WARDNER KTNY HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1158692",
        "Station ID": 1209,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -115.46,
        "Latitude": 492823400,
        "Longitude": -1152734300,
        "Elevation (m)": 760,
        "First Year": 1971,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2021,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "WASA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1158730",
        "Station ID": 1210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.81,
        "Longitude (Decimal Degrees)": -115.63,
        "Latitude": 494826300,
        "Longitude": -1153750800,
        "Elevation (m)": 970,
        "First Year": 1924,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2017,
        "MLY First Year": 1924,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITESWAN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1158917",
        "Station ID": 1211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -115.42,
        "Latitude": 501000000,
        "Longitude": -1152500000,
        "Elevation (m)": 1138.4,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "WYCLIFFE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1158990",
        "Station ID": 1212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -115.88,
        "Latitude": 493500000,
        "Longitude": -1155300000,
        "Elevation (m)": 914.4,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1967
      },
      {
        "Name": "KIMBERLEY MEADOWBROOK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "115D202",
        "Station ID": 1164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -115.9,
        "Latitude": 494200000,
        "Longitude": -1155400000,
        "Elevation (m)": 1064,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "WINDERMERE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "115H9NP",
        "Station ID": 1165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -115.98,
        "Latitude": 502800000,
        "Longitude": -1155900000,
        "Elevation (m)": 802,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "WINDERMERE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "115HR57",
        "Station ID": 6944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -115.95,
        "Latitude": 502800000,
        "Longitude": -1155700000,
        "Elevation (m)": 884,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "ADAMS LAKE SKWAAM BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160075",
        "Station ID": 1225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -119.8,
        "Latitude": 510500000,
        "Longitude": -1194800000,
        "Elevation (m)": 457.2,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "ARMSTRONG",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160450",
        "Station ID": 1226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -119.18,
        "Latitude": 502700000,
        "Longitude": -1191100000,
        "Elevation (m)": 359,
        "First Year": 1912,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1992,
        "MLY First Year": 1912,
        "MLY Last Year": 1992
      },
      {
        "Name": "ARMSTRONG HULLCAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160483",
        "Station ID": 1227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -119.22,
        "Latitude": 503000000,
        "Longitude": -1191300000,
        "Elevation (m)": 505,
        "First Year": 1971,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1998,
        "MLY First Year": 1971,
        "MLY Last Year": 1998
      },
      {
        "Name": "ARMSTRONG NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160485",
        "Station ID": 1228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -119.13,
        "Latitude": 502800000,
        "Longitude": -1190800000,
        "Elevation (m)": 373,
        "First Year": 1972,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1993,
        "MLY First Year": 1972,
        "MLY Last Year": 1993
      },
      {
        "Name": "ASHCROFT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160510",
        "Station ID": 1229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -121.28,
        "Latitude": 504300000,
        "Longitude": -1211700000,
        "Elevation (m)": 304.8,
        "First Year": 1912,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1970,
        "MLY First Year": 1912,
        "MLY Last Year": 1969
      },
      {
        "Name": "ASHCROFT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160511",
        "Station ID": 1230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -121.27,
        "Latitude": 504400000,
        "Longitude": -1211600000,
        "Elevation (m)": 331,
        "First Year": 1973,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1989,
        "MLY First Year": 1973,
        "MLY Last Year": 1989
      },
      {
        "Name": "ASHCROFT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160515",
        "Station ID": 48708,
        "WMO ID": 71681,
        "TC ID": "VAS",
        "Latitude (Decimal Degrees)": 50.71,
        "Longitude (Decimal Degrees)": -121.28,
        "Latitude": 504230005,
        "Longitude": -1211653001,
        "Elevation (m)": 327,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ASHCROFT NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160520",
        "Station ID": 1231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -121.27,
        "Latitude": 504400000,
        "Longitude": -1211600000,
        "Elevation (m)": 320,
        "First Year": 1980,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1986,
        "MLY First Year": 1980,
        "MLY Last Year": 1986
      },
      {
        "Name": "ASHCROFT M",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160540",
        "Station ID": 1232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -121.33,
        "Latitude": 504300000,
        "Longitude": -1212000000,
        "Elevation (m)": 487.7,
        "First Year": 1944,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1972,
        "MLY First Year": 1944,
        "MLY Last Year": 1972
      },
      {
        "Name": "ASHCROFT VENABLES VLY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160546",
        "Station ID": 1233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -121.33,
        "Latitude": 503300000,
        "Longitude": -1212000000,
        "Elevation (m)": 640,
        "First Year": 1984,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1987,
        "MLY First Year": 1984,
        "MLY Last Year": 1987
      },
      {
        "Name": "AVOLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160565",
        "Station ID": 1234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -119.33,
        "Latitude": 514700000,
        "Longitude": -1192000000,
        "Elevation (m)": 609.6,
        "First Year": 1974,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1982,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "BARRIERE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160670",
        "Station ID": 1235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -120.12,
        "Latitude": 511100000,
        "Longitude": -1200700000,
        "Elevation (m)": 415,
        "First Year": 1955,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1996,
        "MLY First Year": 1955,
        "MLY Last Year": 1996
      },
      {
        "Name": "BARRIERE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160673",
        "Station ID": 1236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -120.15,
        "Latitude": 511400000,
        "Longitude": -1200900000,
        "Elevation (m)": 388.6,
        "First Year": 1970,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1984,
        "MLY First Year": 1970,
        "MLY Last Year": 1984
      },
      {
        "Name": "BLUE RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160898",
        "Station ID": 53423,
        "WMO ID": null,
        "TC ID": "YCP",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -119.29,
        "Latitude": 520729000,
        "Longitude": -1191734000,
        "Elevation (m)": 690.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BLUE RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160899",
        "Station ID": 1237,
        "WMO ID": null,
        "TC ID": "YCP",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -119.29,
        "Latitude": 520744500,
        "Longitude": -1191722300,
        "Elevation (m)": 690.4,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": 1969,
        "HLY Last Year": 2015,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLUE RIVER NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160900",
        "Station ID": 1238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -119.28,
        "Latitude": 520900000,
        "Longitude": -1191700000,
        "Elevation (m)": 688.8,
        "First Year": 1929,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1985,
        "MLY First Year": 1929,
        "MLY Last Year": 1985
      },
      {
        "Name": "BLUE RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160902",
        "Station ID": 53424,
        "WMO ID": null,
        "TC ID": "YCP",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -119.29,
        "Latitude": 520729000,
        "Longitude": -1191734000,
        "Elevation (m)": 690.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BRIDGE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160985",
        "Station ID": 1239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -120.73,
        "Latitude": 512800000,
        "Longitude": -1204400000,
        "Elevation (m)": 1157,
        "First Year": 1975,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1995,
        "MLY First Year": 1975,
        "MLY Last Year": 1995
      },
      {
        "Name": "BRIDGE LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160986",
        "Station ID": 1240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -120.79,
        "Latitude": 513013800,
        "Longitude": -1204736600,
        "Elevation (m)": 1155.2,
        "First Year": 1980,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2010,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLUE RIVER CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1160H99",
        "Station ID": 8214,
        "WMO ID": 71883,
        "TC ID": "WSV",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -119.29,
        "Latitude": 520744400,
        "Longitude": -1191723700,
        "Elevation (m)": 682.8,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "BUFFALO LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161104",
        "Station ID": 1243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.71,
        "Longitude (Decimal Degrees)": -121.08,
        "Latitude": 514231079,
        "Longitude": -1210457072,
        "Elevation (m)": 989,
        "First Year": 1962,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2016,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "CACHE CREEK 16 MILE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161215",
        "Station ID": 1244,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -121.42,
        "Latitude": 505454020,
        "Longitude": -1212455030,
        "Elevation (m)": 515.1,
        "First Year": 1982,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1998,
        "MLY First Year": 1982,
        "MLY Last Year": 1998
      },
      {
        "Name": "CANIM LAKE EAGLE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161306",
        "Station ID": 1245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -120.87,
        "Latitude": 515200000,
        "Longitude": -1205200000,
        "Elevation (m)": 780,
        "First Year": 1987,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1991,
        "MLY First Year": 1987,
        "MLY Last Year": 1991
      },
      {
        "Name": "CANOE POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161312",
        "Station ID": 1246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -119.07,
        "Latitude": 504800000,
        "Longitude": -1190400000,
        "Elevation (m)": 353.3,
        "First Year": 1910,
        "Last Year": 1920,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1920,
        "MLY First Year": 1910,
        "MLY Last Year": 1920
      },
      {
        "Name": "CELISTA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116146F",
        "Station ID": 1247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.96,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 505720000,
        "Longitude": -1192246000,
        "Elevation (m)": 515,
        "First Year": 1986,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2004,
        "MLY First Year": 1986,
        "MLY Last Year": 2004
      },
      {
        "Name": "CHASE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161470",
        "Station ID": 1248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -119.69,
        "Latitude": 504908000,
        "Longitude": -1194111000,
        "Elevation (m)": 359,
        "First Year": 1952,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2001,
        "MLY First Year": 1952,
        "MLY Last Year": 2001
      },
      {
        "Name": "CHERRYVILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161506",
        "Station ID": 1249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -118.58,
        "Latitude": 501400000,
        "Longitude": -1183500000,
        "Elevation (m)": 664.5,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "CHINOOK COVE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161590",
        "Station ID": 1250,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.25,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 511500000,
        "Longitude": -1201100000,
        "Elevation (m)": 403.6,
        "First Year": 1913,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1955,
        "MLY First Year": 1914,
        "MLY Last Year": 1955
      },
      {
        "Name": "CHINOOK COVE POSBY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161592",
        "Station ID": 1251,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.26,
        "Longitude (Decimal Degrees)": -120.2,
        "Latitude": 511544000,
        "Longitude": -1201214000,
        "Elevation (m)": 716,
        "First Year": 1989,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2007,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLEARWATER AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161650",
        "Station ID": 52398,
        "WMO ID": 71645,
        "TC ID": "VCW",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -120.08,
        "Latitude": 513909300,
        "Longitude": -1200456400,
        "Elevation (m)": 438,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLEARWATER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161655",
        "Station ID": 1252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -120.03,
        "Latitude": 513900000,
        "Longitude": -1200200000,
        "Elevation (m)": 466,
        "First Year": 1976,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1992,
        "MLY First Year": 1976,
        "MLY Last Year": 1992
      },
      {
        "Name": "CLEARWATER SUNSHINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161656",
        "Station ID": 1253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -120.1,
        "Latitude": 513900000,
        "Longitude": -1200600000,
        "Elevation (m)": 451.1,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "CLINTON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161660",
        "Station ID": 1254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -121.57,
        "Latitude": 510500000,
        "Longitude": -1213400000,
        "Elevation (m)": 891.5,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "CLINTON (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161661",
        "Station ID": 1255,
        "WMO ID": null,
        "TC ID": "ZCL",
        "Latitude (Decimal Degrees)": 51.14,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 510841000,
        "Longitude": -1213017000,
        "Elevation (m)": 1056.7,
        "First Year": 1972,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2018,
        "DLY First Year": 1973,
        "DLY Last Year": 2015,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLINTON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161662",
        "Station ID": 51558,
        "WMO ID": 71717,
        "TC ID": "YIN",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -121.68,
        "Latitude": 511559000,
        "Longitude": -1214105000,
        "Elevation (m)": 1126.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLINTON RCS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161663",
        "Station ID": 53638,
        "WMO ID": 71474,
        "TC ID": "NCN",
        "Latitude (Decimal Degrees)": 51.14,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 510840074,
        "Longitude": -1213017208,
        "Elevation (m)": 1059,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLEARWATER AXEL CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161F55",
        "Station ID": 1242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -120.12,
        "Latitude": 513100000,
        "Longitude": -1200700000,
        "Elevation (m)": 716,
        "First Year": 1984,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1996,
        "MLY First Year": 1984,
        "MLY Last Year": 1996
      },
      {
        "Name": "CLEARWATER BLACKPOOL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1161FN5",
        "Station ID": 1241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.62,
        "Longitude (Decimal Degrees)": -120.12,
        "Latitude": 513700000,
        "Longitude": -1200700000,
        "Elevation (m)": 420.9,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "CRISS CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162177",
        "Station ID": 1256,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -120.73,
        "Latitude": 510147000,
        "Longitude": -1204342000,
        "Elevation (m)": 1122,
        "First Year": 1988,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2020,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "DARFIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162265",
        "Station ID": 1257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.29,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 511730300,
        "Longitude": -1201057100,
        "Elevation (m)": 466,
        "First Year": 1956,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2018,
        "MLY First Year": 1956,
        "MLY Last Year": 2007
      },
      {
        "Name": "EAGLE BAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162580",
        "Station ID": 1258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -119.22,
        "Latitude": 505600000,
        "Longitude": -1191300000,
        "Elevation (m)": 411.5,
        "First Year": 1924,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1995,
        "MLY First Year": 1924,
        "MLY Last Year": 1995
      },
      {
        "Name": "ENDERBY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162680",
        "Station ID": 1259,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -119.15,
        "Latitude": 503300000,
        "Longitude": -1190900000,
        "Elevation (m)": 354,
        "First Year": 1893,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1997,
        "MLY First Year": 1893,
        "MLY Last Year": 1997
      },
      {
        "Name": "ENDERBY ASHTON CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162682",
        "Station ID": 1260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -118.92,
        "Latitude": 503300000,
        "Longitude": -1185500000,
        "Elevation (m)": 350.5,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "ENDERBY VALECAIRN FARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162683",
        "Station ID": 1261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -119.17,
        "Latitude": 503200000,
        "Longitude": -1191000000,
        "Elevation (m)": 335.3,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "FALKLAND SALMON VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162784",
        "Station ID": 1262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 502800000,
        "Longitude": -1192400000,
        "Elevation (m)": 485,
        "First Year": 1959,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1986,
        "MLY First Year": 1959,
        "MLY Last Year": 1986
      },
      {
        "Name": "FALKLAND SPANISH LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1162785",
        "Station ID": 1263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -119.5,
        "Latitude": 503000000,
        "Longitude": -1193000000,
        "Elevation (m)": 823,
        "First Year": 1974,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1984,
        "MLY First Year": 1975,
        "MLY Last Year": 1984
      },
      {
        "Name": "GRIFFIN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163306",
        "Station ID": 1264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -118.53,
        "Latitude": 505800000,
        "Longitude": -1183200000,
        "Elevation (m)": 434.3,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "HAT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163340",
        "Station ID": 1265,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.75,
        "Longitude (Decimal Degrees)": -121.58,
        "Latitude": 504500000,
        "Longitude": -1213500000,
        "Elevation (m)": 922.9,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "HEFFLEY CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163400",
        "Station ID": 1266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 505500000,
        "Longitude": -1201100000,
        "Elevation (m)": 682.8,
        "First Year": 1953,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1995,
        "MLY First Year": 1953,
        "MLY Last Year": 1995
      },
      {
        "Name": "HEMP CREEK CLEARWATER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163450",
        "Station ID": 1267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.92,
        "Longitude (Decimal Degrees)": -120.05,
        "Latitude": 515500000,
        "Longitude": -1200300000,
        "Elevation (m)": 640.1,
        "First Year": 1953,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1963,
        "MLY First Year": 1953,
        "MLY Last Year": 1963
      },
      {
        "Name": "HIGHLAND VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163465",
        "Station ID": 1268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -120.98,
        "Latitude": 502900000,
        "Longitude": -1205900000,
        "Elevation (m)": 1524,
        "First Year": 1957,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1958,
        "MLY First Year": 1957,
        "MLY Last Year": 1958
      },
      {
        "Name": "HIGHLAND VALLEY 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163466",
        "Station ID": 1269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -120.98,
        "Latitude": 503100000,
        "Longitude": -1205900000,
        "Elevation (m)": 1402.1,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "HIGHLAND VALLEY 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163467",
        "Station ID": 1270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -120.98,
        "Latitude": 503100000,
        "Longitude": -1205900000,
        "Elevation (m)": 1402.1,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "HOOPATATKWA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163530",
        "Station ID": 1271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -120.48,
        "Latitude": 511200000,
        "Longitude": -1202900000,
        "Elevation (m)": 1402.1,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "HORSE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163595",
        "Station ID": 1272,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -121.2,
        "Latitude": 513600000,
        "Longitude": -1211200000,
        "Elevation (m)": 994,
        "First Year": 1983,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1994,
        "MLY First Year": 1983,
        "MLY Last Year": 1994
      },
      {
        "Name": "HORSE LAKE FAWN CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163596",
        "Station ID": 1273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -121.07,
        "Latitude": 513400000,
        "Longitude": -1210400000,
        "Elevation (m)": 1061,
        "First Year": 1987,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1993,
        "MLY First Year": 1987,
        "MLY Last Year": 1993
      },
      {
        "Name": "KAMLOOPS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163779",
        "Station ID": 1274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -120.33,
        "Latitude": 504000000,
        "Longitude": -1202000000,
        "Elevation (m)": 378.9,
        "First Year": 1878,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1982,
        "MLY First Year": 1878,
        "MLY Last Year": 1982
      },
      {
        "Name": "KAMLOOPS A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163780",
        "Station ID": 1275,
        "WMO ID": 71887,
        "TC ID": "YKA",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -120.44,
        "Latitude": 504208000,
        "Longitude": -1202631000,
        "Elevation (m)": 345.3,
        "First Year": 1951,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1951,
        "DLY Last Year": 2013,
        "MLY First Year": 1951,
        "MLY Last Year": 2013
      },
      {
        "Name": "KAMLOOPS A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163781",
        "Station ID": 51423,
        "WMO ID": 71887,
        "TC ID": "YKA",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -120.45,
        "Latitude": 504209000,
        "Longitude": -1202655000,
        "Elevation (m)": 345.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KAMLOOPS AFTON MINES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163790",
        "Station ID": 1276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -120.5,
        "Latitude": 504000000,
        "Longitude": -1203000000,
        "Elevation (m)": 701,
        "First Year": 1977,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1993,
        "MLY First Year": 1977,
        "MLY Last Year": 1993
      },
      {
        "Name": "KAMLOOPS CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163810",
        "Station ID": 1277,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -120.45,
        "Latitude": 504300000,
        "Longitude": -1202700000,
        "Elevation (m)": 345,
        "First Year": 1949,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1977,
        "MLY First Year": 1949,
        "MLY Last Year": 1977
      },
      {
        "Name": "KAMLOOPS CHERRY CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163814",
        "Station ID": 1278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -120.58,
        "Latitude": 504100000,
        "Longitude": -1203500000,
        "Elevation (m)": 556.3,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "KAMLOOPS CHERRY CREEK 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163815",
        "Station ID": 1279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -120.58,
        "Latitude": 503900000,
        "Longitude": -1203500000,
        "Elevation (m)": 701,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "KAMLOOPS GLENEAGLES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163817",
        "Station ID": 1280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -120.37,
        "Latitude": 504000000,
        "Longitude": -1202200000,
        "Elevation (m)": 601,
        "First Year": 1986,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1988,
        "MLY First Year": 1986,
        "MLY Last Year": 1988
      },
      {
        "Name": "KAMLOOPS KIMBERLY CRESCENT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163826",
        "Station ID": 1281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -120.37,
        "Latitude": 504300000,
        "Longitude": -1202200000,
        "Elevation (m)": 345,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "KAMLOOPS MISSION FLATS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163840",
        "Station ID": 1282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -120.35,
        "Latitude": 504100000,
        "Longitude": -1202100000,
        "Elevation (m)": 345.3,
        "First Year": 1939,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1957,
        "MLY First Year": 1939,
        "MLY Last Year": 1957
      },
      {
        "Name": "KAMLOOPS AUT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163842",
        "Station ID": 42203,
        "WMO ID": 71741,
        "TC ID": "ZKA",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -120.44,
        "Latitude": 504208000,
        "Longitude": -1202631000,
        "Elevation (m)": 345,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "KAMLOOPS TUNSTALL CRES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163873",
        "Station ID": 1283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -120.32,
        "Latitude": 504000000,
        "Longitude": -1201900000,
        "Elevation (m)": 442,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "KAMLOOPS VALLEYVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1163875",
        "Station ID": 1284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -120.25,
        "Latitude": 504030000,
        "Longitude": -1201510000,
        "Elevation (m)": 347,
        "First Year": 1962,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2005,
        "MLY First Year": 1962,
        "MLY Last Year": 2005
      },
      {
        "Name": "KNOUFF LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164380",
        "Station ID": 1288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -120.13,
        "Latitude": 505800000,
        "Longitude": -1200800000,
        "Elevation (m)": 1143,
        "First Year": 1924,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1951,
        "MLY First Year": 1924,
        "MLY Last Year": 1951
      },
      {
        "Name": "KNOUFF LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164381",
        "Station ID": 1289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -120.13,
        "Latitude": 505700000,
        "Longitude": -1200800000,
        "Elevation (m)": 1112.5,
        "First Year": 1952,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1952,
        "MLY First Year": 1952,
        "MLY Last Year": 1952
      },
      {
        "Name": "KNUTSFORD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164384",
        "Station ID": 1290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -120.32,
        "Latitude": 503700000,
        "Longitude": -1201900000,
        "Elevation (m)": 786,
        "First Year": 1986,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1988,
        "MLY First Year": 1986,
        "MLY Last Year": 1988
      },
      {
        "Name": "LAC DES ROCHES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164445",
        "Station ID": 1291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -120.62,
        "Latitude": 513000000,
        "Longitude": -1203700000,
        "Elevation (m)": 1143,
        "First Year": 1974,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1979,
        "MLY First Year": 1974,
        "MLY Last Year": 1979
      },
      {
        "Name": "LITTLE FORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164636",
        "Station ID": 1293,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -120.2,
        "Latitude": 512700000,
        "Longitude": -1201200000,
        "Elevation (m)": 457.2,
        "First Year": 1973,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1984,
        "MLY First Year": 1973,
        "MLY Last Year": 1984
      },
      {
        "Name": "LITTLE FORT 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11646L6",
        "Station ID": 1292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 512200000,
        "Longitude": -1201100000,
        "Elevation (m)": 390,
        "First Year": 1986,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1990,
        "MLY First Year": 1986,
        "MLY Last Year": 1990
      },
      {
        "Name": "LONE BUTTE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164715",
        "Station ID": 1294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -120.83,
        "Latitude": 513900000,
        "Longitude": -1205000000,
        "Elevation (m)": 1173.5,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "LOON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164717",
        "Station ID": 1295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -121.33,
        "Latitude": 510400000,
        "Longitude": -1212000000,
        "Elevation (m)": 838,
        "First Year": 1968,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1989,
        "MLY First Year": 1968,
        "MLY Last Year": 1989
      },
      {
        "Name": "LOUIS CRK MEL-BAR RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164720",
        "Station ID": 1296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -120.03,
        "Latitude": 510200000,
        "Longitude": -1200200000,
        "Elevation (m)": 624.8,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "LUMBY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164729",
        "Station ID": 1297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.24,
        "Longitude (Decimal Degrees)": -118.96,
        "Latitude": 501410200,
        "Longitude": -1185720400,
        "Elevation (m)": 500,
        "First Year": 1959,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2010,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "LUMBY SIGALET RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164730",
        "Station ID": 1298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -118.77,
        "Latitude": 502200000,
        "Longitude": -1184600000,
        "Elevation (m)": 559.9,
        "First Year": 1970,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1999,
        "MLY First Year": 1970,
        "MLY Last Year": 1999
      },
      {
        "Name": "MABEL LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164770",
        "Station ID": 1299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -118.78,
        "Latitude": 502300000,
        "Longitude": -1184700000,
        "Elevation (m)": 399.3,
        "First Year": 1924,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1975,
        "MLY First Year": 1924,
        "MLY Last Year": 1975
      },
      {
        "Name": "MALAKWA EAGLE R HATCHERY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164830",
        "Station ID": 1300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51,
        "Longitude (Decimal Degrees)": -118.65,
        "Latitude": 510000000,
        "Longitude": -1183900000,
        "Elevation (m)": 404,
        "First Year": 1924,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1994,
        "MLY First Year": 1924,
        "MLY Last Year": 1994
      },
      {
        "Name": "MARA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164903",
        "Station ID": 1301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -119.03,
        "Latitude": 504300000,
        "Longitude": -1190200000,
        "Elevation (m)": 353.6,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "LONE BUTTE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164G1E",
        "Station ID": 1286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.55,
        "Longitude (Decimal Degrees)": -121.2,
        "Latitude": 513300000,
        "Longitude": -1211200000,
        "Elevation (m)": 1145.1,
        "First Year": 1981,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1991,
        "MLY First Year": 1981,
        "MLY Last Year": 1991
      },
      {
        "Name": "LONE BUTTE 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164GA5",
        "Station ID": 1285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -121.15,
        "Latitude": 513400000,
        "Longitude": -1210900000,
        "Elevation (m)": 1082,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "KNUTSFORD 2S",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1164LH5",
        "Station ID": 1287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -120.32,
        "Latitude": 503600000,
        "Longitude": -1201900000,
        "Elevation (m)": 899,
        "First Year": 1986,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1997,
        "MLY First Year": 1986,
        "MLY Last Year": 1997
      },
      {
        "Name": "MCLURE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165030",
        "Station ID": 1302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -120.22,
        "Latitude": 510248000,
        "Longitude": -1201318000,
        "Elevation (m)": 381,
        "First Year": 1967,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2009,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "MONTE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165197",
        "Station ID": 1303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -119.85,
        "Latitude": 503100000,
        "Longitude": -1195100000,
        "Elevation (m)": 683.4,
        "First Year": 1961,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1968,
        "MLY First Year": 1961,
        "MLY Last Year": 1968
      },
      {
        "Name": "MONTE LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165198",
        "Station ID": 1304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -119.85,
        "Latitude": 503100000,
        "Longitude": -1195100000,
        "Elevation (m)": 701,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "MOUL CREEK CLEARWATER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165210",
        "Station ID": 1305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -120.02,
        "Latitude": 515200000,
        "Longitude": -1200100000,
        "Elevation (m)": 713.2,
        "First Year": 1963,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1967,
        "MLY First Year": 1963,
        "MLY Last Year": 1967
      },
      {
        "Name": "MT LOLO ADMIN KAMLOOPS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165224",
        "Station ID": 1306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 504700000,
        "Longitude": -1201100000,
        "Elevation (m)": 872.9,
        "First Year": 1977,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1988,
        "MLY First Year": 1977,
        "MLY Last Year": 1988
      },
      {
        "Name": "MT LOLO KAMLOOPS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165225",
        "Station ID": 1307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -120.13,
        "Latitude": 504800000,
        "Longitude": -1200800000,
        "Elevation (m)": 1737.4,
        "First Year": 1964,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1977,
        "MLY First Year": 1964,
        "MLY Last Year": 1977
      },
      {
        "Name": "100 MILE HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165791",
        "Station ID": 1308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -121.3,
        "Latitude": 513849020,
        "Longitude": -1211809060,
        "Elevation (m)": 1059.2,
        "First Year": 1970,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1999,
        "MLY First Year": 1970,
        "MLY Last Year": 1999
      },
      {
        "Name": "100 MILE HOUSE 6NE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1165793",
        "Station ID": 1309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -121.22,
        "Latitude": 514053000,
        "Longitude": -1211301000,
        "Elevation (m)": 928,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRITCHARD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166527",
        "Station ID": 1312,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -119.82,
        "Latitude": 504100000,
        "Longitude": -1194900000,
        "Elevation (m)": 381,
        "First Year": 1986,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1997,
        "MLY First Year": 1986,
        "MLY Last Year": 1997
      },
      {
        "Name": "RED LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166658",
        "Station ID": 1313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.94,
        "Longitude (Decimal Degrees)": -120.8,
        "Latitude": 505606000,
        "Longitude": -1204800000,
        "Elevation (m)": 1162,
        "First Year": 1974,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2021,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166659",
        "Station ID": 1314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -120.77,
        "Latitude": 505400000,
        "Longitude": -1204600000,
        "Elevation (m)": 990,
        "First Year": 1979,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1985,
        "MLY First Year": 1979,
        "MLY Last Year": 1985
      },
      {
        "Name": "RICHLAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166760",
        "Station ID": 1315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -118.5,
        "Latitude": 501300000,
        "Longitude": -1183000000,
        "Elevation (m)": 716.3,
        "First Year": 1962,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1975,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "RUTH LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166912",
        "Station ID": 1316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.84,
        "Longitude (Decimal Degrees)": -120.98,
        "Latitude": 515041000,
        "Longitude": -1205845000,
        "Elevation (m)": 795,
        "First Year": 1987,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2013,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALMON ARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166945",
        "Station ID": 1317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -119.25,
        "Latitude": 504200000,
        "Longitude": -1191500000,
        "Elevation (m)": 506,
        "First Year": 1911,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1982,
        "MLY First Year": 1911,
        "MLY Last Year": 1982
      },
      {
        "Name": "SALMON ARM 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166946",
        "Station ID": 1318,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -119.28,
        "Latitude": 504200000,
        "Longitude": -1191700000,
        "Elevation (m)": 396.2,
        "First Year": 1950,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1986,
        "MLY First Year": 1950,
        "MLY Last Year": 1986
      },
      {
        "Name": "SALMON ARM 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166947",
        "Station ID": 1319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -119.35,
        "Latitude": 504100000,
        "Longitude": -1192100000,
        "Elevation (m)": 363,
        "First Year": 1893,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1996,
        "MLY First Year": 1893,
        "MLY Last Year": 1996
      },
      {
        "Name": "PINANTAN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166JFR",
        "Station ID": 1310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -119.95,
        "Latitude": 504258000,
        "Longitude": -1195642000,
        "Elevation (m)": 960,
        "First Year": 1986,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2006,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "SALMON ARM A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1166R45",
        "Station ID": 1311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.69,
        "Longitude (Decimal Degrees)": -119.23,
        "Latitude": 504108008,
        "Longitude": -1191401009,
        "Elevation (m)": 527.3,
        "First Year": 1982,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2017,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "SAMATOSUM MOUNTAIN MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167008",
        "Station ID": 1320,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -119.82,
        "Latitude": 511000000,
        "Longitude": -1194900000,
        "Elevation (m)": 1145,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "SCOTCH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167150",
        "Station ID": 43063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -119.46,
        "Latitude": 505459004,
        "Longitude": -1192733005,
        "Elevation (m)": 354,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "70 MILE HOUSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167191",
        "Station ID": 1321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -121.4,
        "Latitude": 511800000,
        "Longitude": -1212400000,
        "Elevation (m)": 1079.9,
        "First Year": 1974,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1987,
        "MLY First Year": 1974,
        "MLY Last Year": 1987
      },
      {
        "Name": "SEYMOUR ARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167193",
        "Station ID": 1322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.25,
        "Longitude (Decimal Degrees)": -118.95,
        "Latitude": 511500000,
        "Longitude": -1185700000,
        "Elevation (m)": 351.1,
        "First Year": 1973,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1996,
        "MLY First Year": 1973,
        "MLY Last Year": 1996
      },
      {
        "Name": "SEYMOUR ARM NINE MILE LIGHT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167194",
        "Station ID": 1323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -119.03,
        "Latitude": 510400000,
        "Longitude": -1190200000,
        "Elevation (m)": 351,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "SHUSWAP FALLS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167260",
        "Station ID": 1324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -118.82,
        "Latitude": 501800000,
        "Longitude": -1184900000,
        "Elevation (m)": 426.7,
        "First Year": 1930,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1972,
        "MLY First Year": 1930,
        "MLY Last Year": 1972
      },
      {
        "Name": "SICAMOUS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167275",
        "Station ID": 1325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -119,
        "Latitude": 504800000,
        "Longitude": -1190000000,
        "Elevation (m)": 426.7,
        "First Year": 1954,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1979,
        "MLY First Year": 1954,
        "MLY Last Year": 1979
      },
      {
        "Name": "SICAMOUS 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167276",
        "Station ID": 1326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.85,
        "Longitude (Decimal Degrees)": -118.98,
        "Latitude": 505100000,
        "Longitude": -1185900000,
        "Elevation (m)": 355,
        "First Year": 1979,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1994,
        "MLY First Year": 1980,
        "MLY Last Year": 1994
      },
      {
        "Name": "SILVER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167337",
        "Station ID": 1327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -119.35,
        "Latitude": 503242700,
        "Longitude": -1192100100,
        "Elevation (m)": 419,
        "First Year": 1989,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2021,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "SORRENTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167590",
        "Station ID": 1328,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -119.48,
        "Latitude": 505300000,
        "Longitude": -1192900000,
        "Elevation (m)": 390.1,
        "First Year": 1924,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1979,
        "MLY First Year": 1924,
        "MLY Last Year": 1979
      },
      {
        "Name": "SORRENTO EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167592",
        "Station ID": 1329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 505300000,
        "Longitude": -1192400000,
        "Elevation (m)": 487.7,
        "First Year": 1969,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1990,
        "MLY First Year": 1969,
        "MLY Last Year": 1990
      },
      {
        "Name": "SPENCES BRIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167635",
        "Station ID": 1330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 502500000,
        "Longitude": -1213000000,
        "Elevation (m)": 231.6,
        "First Year": 1873,
        "Last Year": 1909,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1873,
        "DLY Last Year": 1909,
        "MLY First Year": 1873,
        "MLY Last Year": 1909
      },
      {
        "Name": "SPENCES BRIDGE NICOLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167637",
        "Station ID": 1331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -121.31,
        "Latitude": 502518600,
        "Longitude": -1211852800,
        "Elevation (m)": 235,
        "First Year": 1980,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2002,
        "MLY First Year": 1980,
        "MLY Last Year": 2002
      },
      {
        "Name": "SUGAR LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167778",
        "Station ID": 1332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -118.53,
        "Latitude": 502000000,
        "Longitude": -1183200000,
        "Elevation (m)": 609.6,
        "First Year": 1957,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1964,
        "MLY First Year": 1957,
        "MLY Last Year": 1964
      },
      {
        "Name": "TAPPEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167980",
        "Station ID": 1333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.75,
        "Longitude (Decimal Degrees)": -119.33,
        "Latitude": 504500000,
        "Longitude": -1192000000,
        "Elevation (m)": 442,
        "First Year": 1913,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1961,
        "MLY First Year": 1913,
        "MLY Last Year": 1961
      },
      {
        "Name": "TAPPEN FORD ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167982",
        "Station ID": 1334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -119.3,
        "Latitude": 504800000,
        "Longitude": -1191800000,
        "Elevation (m)": 566.9,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "TAPPEN NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167984",
        "Station ID": 1335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -119.3,
        "Latitude": 504800000,
        "Longitude": -1191800000,
        "Elevation (m)": 487.7,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "TAPPEN TULARI FARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1167986",
        "Station ID": 1336,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -119.3,
        "Latitude": 504000000,
        "Longitude": -1191800000,
        "Elevation (m)": 381,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "SUN PEAKS MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168204",
        "Station ID": 1338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -119.91,
        "Latitude": 505410844,
        "Longitude": -1195440813,
        "Elevation (m)": 1847.42,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "TRANQUILLE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168220",
        "Station ID": 1339,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -120.52,
        "Latitude": 504300000,
        "Longitude": -1203100000,
        "Elevation (m)": 359.7,
        "First Year": 1908,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1953,
        "MLY First Year": 1908,
        "MLY Last Year": 1953
      },
      {
        "Name": "TURTLE VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168300",
        "Station ID": 48928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.84,
        "Longitude (Decimal Degrees)": -119.56,
        "Latitude": 505006000,
        "Longitude": -1193323070,
        "Elevation (m)": 638,
        "First Year": 2010,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2010,
        "DLY Last Year": 2011,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VAVENBY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168520",
        "Station ID": 1340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -119.78,
        "Latitude": 513434000,
        "Longitude": -1194641000,
        "Elevation (m)": 445,
        "First Year": 1913,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2022,
        "MLY First Year": 1913,
        "MLY Last Year": 2007
      },
      {
        "Name": "VENABLES VLY BHUMI FARM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168532",
        "Station ID": 46547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -121.38,
        "Latitude": 503548000,
        "Longitude": -1212234000,
        "Elevation (m)": 756,
        "First Year": 2007,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2008,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VINSULLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168667",
        "Station ID": 1341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -120.25,
        "Latitude": 505600000,
        "Longitude": -1201500000,
        "Elevation (m)": 356.6,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "WATCH LAKE EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168736",
        "Station ID": 1342,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -121.07,
        "Latitude": 512800000,
        "Longitude": -1210400000,
        "Elevation (m)": 1080,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "WATCH LAKE NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168739",
        "Station ID": 1343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -121.12,
        "Latitude": 512800000,
        "Longitude": -1210700000,
        "Elevation (m)": 1069,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1987,
        "MLY Last Year": 1996
      },
      {
        "Name": "WESTWOLD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168880",
        "Station ID": 1344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -119.75,
        "Latitude": 502808008,
        "Longitude": -1194502003,
        "Elevation (m)": 609,
        "First Year": 1921,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 2013,
        "MLY First Year": 1921,
        "MLY Last Year": 2007
      },
      {
        "Name": "THIMBLE RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1168A5H",
        "Station ID": 1337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -118.58,
        "Latitude": 501400000,
        "Longitude": -1183500000,
        "Elevation (m)": 424.9,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1975,
        "MLY Last Year": 1978
      },
      {
        "Name": "CLEARWATER CANDLE CRK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116A655",
        "Station ID": 1213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -120,
        "Latitude": 514100000,
        "Longitude": -1200000000,
        "Elevation (m)": 701,
        "First Year": 1985,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1988,
        "MLY First Year": 1985,
        "MLY Last Year": 1988
      },
      {
        "Name": "KAMLOOPS PRATT ROAD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116C8P0",
        "Station ID": 1216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -120.17,
        "Latitude": 503746600,
        "Longitude": -1201019200,
        "Elevation (m)": 729,
        "First Year": 1986,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2022,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "GREEN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116CK98",
        "Station ID": 6945,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -121.23,
        "Latitude": 512500000,
        "Longitude": -1211400000,
        "Elevation (m)": 1070,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "HORSE LAKE LONE BUTE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116CNR6",
        "Station ID": 1214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -121.15,
        "Latitude": 513400000,
        "Longitude": -1210900000,
        "Elevation (m)": 1070,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "KAMLOOPS DALLAS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116CQAK",
        "Station ID": 1215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -120.17,
        "Latitude": 504000000,
        "Longitude": -1201000000,
        "Elevation (m)": 346,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "SALMON ARM CS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116FRMN",
        "Station ID": 6843,
        "WMO ID": 71218,
        "TC ID": "WSL",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -119.29,
        "Latitude": 504210800,
        "Longitude": -1191726440,
        "Elevation (m)": 350.5,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "VIDETTE LAKE SHARPE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116HFF6",
        "Station ID": 1217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.33,
        "Longitude (Decimal Degrees)": -120.84,
        "Latitude": 511935400,
        "Longitude": -1205021000,
        "Elevation (m)": 1083,
        "First Year": 1987,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2009,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLEARWATER SPAHATS CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116JF55",
        "Station ID": 1218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -120,
        "Latitude": 514400000,
        "Longitude": -1200000000,
        "Elevation (m)": 869,
        "First Year": 1990,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1995,
        "MLY First Year": 1990,
        "MLY Last Year": 1995
      },
      {
        "Name": "KAMLOOPS RAYLEIGH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116L87J",
        "Station ID": 1220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -120.29,
        "Latitude": 504905100,
        "Longitude": -1201740200,
        "Elevation (m)": 357,
        "First Year": 1986,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2002,
        "MLY First Year": 1986,
        "MLY Last Year": 2002
      },
      {
        "Name": "KAMLOOPS RIDGEVIEW TERRACE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116LQPJ",
        "Station ID": 1219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -120.38,
        "Latitude": 504200000,
        "Longitude": -1202300000,
        "Elevation (m)": 342,
        "First Year": 1986,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1990,
        "MLY First Year": 1986,
        "MLY Last Year": 1990
      },
      {
        "Name": "108 MILE HOUSE WATSON LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116N7R6",
        "Station ID": 1224,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -121.33,
        "Latitude": 514200000,
        "Longitude": -1212000000,
        "Elevation (m)": 890,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "MONTE LAKE PAXTON VALLEY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116NJ97",
        "Station ID": 1223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -119.8,
        "Latitude": 503200000,
        "Longitude": -1194800000,
        "Elevation (m)": 844.9,
        "First Year": 1972,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1990,
        "MLY First Year": 1972,
        "MLY Last Year": 1990
      },
      {
        "Name": "MONTE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116NJ9F",
        "Station ID": 1222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -119.95,
        "Latitude": 503911000,
        "Longitude": -1195714000,
        "Elevation (m)": 351,
        "First Year": 1986,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2007,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "MONTE CREEK WEST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116NJRF",
        "Station ID": 1221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -120.02,
        "Latitude": 503900000,
        "Longitude": -1200100000,
        "Elevation (m)": 363,
        "First Year": 1986,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1996,
        "MLY First Year": 1986,
        "MLY Last Year": 1996
      },
      {
        "Name": "SUN PEAKS LOWER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116Q20D",
        "Station ID": 11006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.89,
        "Longitude (Decimal Degrees)": -119.88,
        "Latitude": 505313451,
        "Longitude": -1195259137,
        "Elevation (m)": 1310.57,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "WALHACHIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116QF69",
        "Station ID": 26997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.77,
        "Longitude (Decimal Degrees)": -120.97,
        "Latitude": 504600000,
        "Longitude": -1205800000,
        "Elevation (m)": 450,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1997,
        "MLY First Year": 1994,
        "MLY Last Year": 1997
      },
      {
        "Name": "SUN PEAKS UPPER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "116QK0M",
        "Station ID": 11005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -119.92,
        "Latitude": 505407500,
        "Longitude": -1195506000,
        "Elevation (m)": 2055,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALBREDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1170237",
        "Station ID": 1351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.67,
        "Longitude (Decimal Degrees)": -119.2,
        "Latitude": 524000000,
        "Longitude": -1191200000,
        "Elevation (m)": 873.3,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "BOBBIE BURNS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1170R01",
        "Station ID": 1350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -116.93,
        "Latitude": 505658000,
        "Longitude": -1165546000,
        "Elevation (m)": 1370,
        "First Year": 1981,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2001,
        "MLY First Year": 1981,
        "MLY Last Year": 2000
      },
      {
        "Name": "BRISCO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1171020",
        "Station ID": 1352,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -116.26,
        "Latitude": 504914000,
        "Longitude": -1161529000,
        "Elevation (m)": 823,
        "First Year": 1924,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 2004,
        "MLY First Year": 1924,
        "MLY Last Year": 2004
      },
      {
        "Name": "BUGABOO CREEK LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1171105",
        "Station ID": 1353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.75,
        "Longitude (Decimal Degrees)": -116.71,
        "Latitude": 504516900,
        "Longitude": -1164223900,
        "Elevation (m)": 1529,
        "First Year": 1972,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2006,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "CANOE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1171313",
        "Station ID": 1354,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.73,
        "Longitude (Decimal Degrees)": -119.38,
        "Latitude": 524400000,
        "Longitude": -1192300000,
        "Elevation (m)": 953.1,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "CARIBOO LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1171393",
        "Station ID": 1355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.72,
        "Longitude (Decimal Degrees)": -119.47,
        "Latitude": 524310091,
        "Longitude": -1192818297,
        "Elevation (m)": 1095.8,
        "First Year": 1975,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2016,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "DONALD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1172525",
        "Station ID": 1356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -117.15,
        "Latitude": 512900000,
        "Longitude": -1170900000,
        "Elevation (m)": 783.3,
        "First Year": 1891,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1972,
        "MLY First Year": 1891,
        "MLY Last Year": 1972
      },
      {
        "Name": "DOWNIE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1172542",
        "Station ID": 8227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -117.83,
        "Latitude": 510900000,
        "Longitude": -1175000000,
        "Elevation (m)": 754.4,
        "First Year": 1953,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1955,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DOWNIE SLIDE BCHPA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1172543",
        "Station ID": 1357,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -118.52,
        "Latitude": 513000000,
        "Longitude": -1183100000,
        "Elevation (m)": 975.4,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "FIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1172870",
        "Station ID": 1358,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 512300000,
        "Longitude": -1162900000,
        "Elevation (m)": 1238.7,
        "First Year": 1917,
        "Last Year": 1937,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1937,
        "MLY First Year": 1917,
        "MLY Last Year": 1937
      },
      {
        "Name": "FLAT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1172895",
        "Station ID": 1359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -117.62,
        "Latitude": 511400000,
        "Longitude": -1173700000,
        "Elevation (m)": 944.9,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLACIER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173180",
        "Station ID": 1361,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -117.48,
        "Latitude": 511400000,
        "Longitude": -1172900000,
        "Elevation (m)": 1247.9,
        "First Year": 1892,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1957,
        "MLY First Year": 1892,
        "MLY Last Year": 1957
      },
      {
        "Name": "GLACIER AVALANCHE RS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173190",
        "Station ID": 1362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -117.5,
        "Latitude": 511600000,
        "Longitude": -1173000000,
        "Elevation (m)": 1176.5,
        "First Year": 1957,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1965,
        "MLY First Year": 1957,
        "MLY Last Year": 1965
      },
      {
        "Name": "GLACIER NP ROGERS PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173191",
        "Station ID": 1363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -117.52,
        "Latitude": 511806060,
        "Longitude": -1173100000,
        "Elevation (m)": 1330,
        "First Year": 1965,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2014,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOLDEN A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173209",
        "Station ID": 52439,
        "WMO ID": null,
        "TC ID": "YGE",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -116.98,
        "Latitude": 511757000,
        "Longitude": -1165856000,
        "Elevation (m)": 784.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOLDEN A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173210",
        "Station ID": 1364,
        "WMO ID": 71173,
        "TC ID": "YGE",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -116.98,
        "Latitude": 511757000,
        "Longitude": -1165856000,
        "Elevation (m)": 784.9,
        "First Year": 1902,
        "Last Year": 2022,
        "HLY First Year": 1991,
        "HLY Last Year": 2014,
        "DLY First Year": 1902,
        "DLY Last Year": 2022,
        "MLY First Year": 1902,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOLDEN A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173214",
        "Station ID": 52980,
        "WMO ID": null,
        "TC ID": "YGE",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -116.98,
        "Latitude": 511757000,
        "Longitude": -1165856000,
        "Elevation (m)": 784.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOLDEN AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173220",
        "Station ID": 54318,
        "WMO ID": 71905,
        "TC ID": "VGE",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -116.98,
        "Latitude": 511800800,
        "Longitude": -1165903600,
        "Elevation (m)": 785,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOLDSTREAM RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173242",
        "Station ID": 1365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -118.42,
        "Latitude": 513747000,
        "Longitude": -1182529000,
        "Elevation (m)": 700.1,
        "First Year": 1982,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2018,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOLDSTREAM RIVER STITT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173243",
        "Station ID": 1366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -118.17,
        "Latitude": 513757000,
        "Longitude": -1181006000,
        "Elevation (m)": 963,
        "First Year": 1990,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2008,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "GOLDSTREAM RIVER BREWSTER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1173BDB",
        "Station ID": 1360,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.64,
        "Longitude (Decimal Degrees)": -118.49,
        "Latitude": 513823000,
        "Longitude": -1182920000,
        "Elevation (m)": 710,
        "First Year": 1990,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2008,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "MICA DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1175122",
        "Station ID": 1367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.05,
        "Longitude (Decimal Degrees)": -118.59,
        "Latitude": 520311000,
        "Longitude": -1183507000,
        "Elevation (m)": 579.1,
        "First Year": 1951,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2022,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "PARSON COLUMBIA MINES",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1175982",
        "Station ID": 1368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -116.98,
        "Latitude": 505700000,
        "Longitude": -1165900000,
        "Elevation (m)": 1722.1,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "REVELSTOKE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176745",
        "Station ID": 49492,
        "WMO ID": 71685,
        "TC ID": "YRV",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -118.18,
        "Latitude": 505800000,
        "Longitude": -1181100000,
        "Elevation (m)": 444.7,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "REVELSTOKE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176749",
        "Station ID": 1370,
        "WMO ID": null,
        "TC ID": "ZRV",
        "Latitude (Decimal Degrees)": 50.96,
        "Longitude (Decimal Degrees)": -118.18,
        "Latitude": 505740000,
        "Longitude": -1181100000,
        "Elevation (m)": 444.7,
        "First Year": 1990,
        "Last Year": 2014,
        "HLY First Year": 1994,
        "HLY Last Year": 2014,
        "DLY First Year": 1990,
        "DLY Last Year": 2013,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "REVELSTOKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176750",
        "Station ID": 1371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51,
        "Longitude (Decimal Degrees)": -118.2,
        "Latitude": 510000000,
        "Longitude": -1181200000,
        "Elevation (m)": 456.3,
        "First Year": 1898,
        "Last Year": 1969,
        "HLY First Year": 1953,
        "HLY Last Year": 1969,
        "DLY First Year": 1898,
        "DLY Last Year": 1969,
        "MLY First Year": 1898,
        "MLY Last Year": 1969
      },
      {
        "Name": "REVELSTOKE AIRPORT RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176751",
        "Station ID": 1372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -118.17,
        "Latitude": 505712000,
        "Longitude": -1180959000,
        "Elevation (m)": 450,
        "First Year": 1969,
        "Last Year": 1999,
        "HLY First Year": 1969,
        "HLY Last Year": 1999,
        "DLY First Year": 1969,
        "DLY Last Year": 1999,
        "MLY First Year": 1969,
        "MLY Last Year": 1999
      },
      {
        "Name": "REVELSTOKE MT COPELAND",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176753",
        "Station ID": 1373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -118.38,
        "Latitude": 511200000,
        "Longitude": -1182300000,
        "Elevation (m)": 1847.1,
        "First Year": 1969,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1973,
        "MLY First Year": 1969,
        "MLY Last Year": 1973
      },
      {
        "Name": "REVELSTOKE COLUMBIA PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176754",
        "Station ID": 46147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.01,
        "Longitude (Decimal Degrees)": -118.22,
        "Latitude": 510042000,
        "Longitude": -1181328000,
        "Elevation (m)": 446,
        "First Year": 2007,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "REVELSTOKE AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176755",
        "Station ID": 49548,
        "WMO ID": 71882,
        "TC ID": "VRA",
        "Latitude (Decimal Degrees)": 50.96,
        "Longitude (Decimal Degrees)": -118.18,
        "Latitude": 505729600,
        "Longitude": -1181034600,
        "Elevation (m)": 444.7,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROGERS PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176825",
        "Station ID": 1374,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -117.52,
        "Latitude": 511800000,
        "Longitude": -1173100000,
        "Elevation (m)": 1316.7,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "REVELSTOKE CARNES CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1176GEB",
        "Station ID": 1369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -118.13,
        "Latitude": 511700000,
        "Longitude": -1180800000,
        "Elevation (m)": 960,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "SIDMOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1177290",
        "Station ID": 1375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -118.02,
        "Latitude": 504700000,
        "Longitude": -1180100000,
        "Elevation (m)": 429.8,
        "First Year": 1947,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1961,
        "MLY First Year": 1948,
        "MLY Last Year": 1961
      },
      {
        "Name": "SPILLIMACHEEN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1177640",
        "Station ID": 1376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -116.4,
        "Latitude": 505500000,
        "Longitude": -1162400000,
        "Elevation (m)": 818.4,
        "First Year": 1958,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1980,
        "MLY First Year": 1958,
        "MLY Last Year": 1980
      },
      {
        "Name": "STONEY CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1177773",
        "Station ID": 1377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.35,
        "Longitude (Decimal Degrees)": -117.47,
        "Latitude": 512100000,
        "Longitude": -1172800000,
        "Elevation (m)": 1097.3,
        "First Year": 1957,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1957,
        "MLY First Year": 1957,
        "MLY Last Year": 1957
      },
      {
        "Name": "VALEMOUNT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1178339",
        "Station ID": 1380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -119.25,
        "Latitude": 524900000,
        "Longitude": -1191500000,
        "Elevation (m)": 797.1,
        "First Year": 1914,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1975,
        "MLY First Year": 1914,
        "MLY Last Year": 1975
      },
      {
        "Name": "VALEMOUNT EAST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11783L9",
        "Station ID": 1379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -119.25,
        "Latitude": 524948000,
        "Longitude": -1191515000,
        "Elevation (m)": 808,
        "First Year": 1970,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2004,
        "MLY First Year": 1970,
        "MLY Last Year": 2004
      },
      {
        "Name": "WILDCAT CR. MISTAYA LODGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1178931",
        "Station ID": 1381,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -116.64,
        "Latitude": 514159000,
        "Longitude": -1163811000,
        "Elevation (m)": 2057,
        "First Year": 1990,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2003,
        "MLY First Year": 1990,
        "MLY Last Year": 2003
      },
      {
        "Name": "VALEMOUNT NORTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1178CL9",
        "Station ID": 1378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -119.25,
        "Latitude": 525100000,
        "Longitude": -1191500000,
        "Elevation (m)": 891.5,
        "First Year": 1971,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1989,
        "MLY First Year": 1971,
        "MLY Last Year": 1989
      },
      {
        "Name": "YOHO NAT PARK FIELD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1179007",
        "Station ID": 1383,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.35,
        "Longitude (Decimal Degrees)": -116.5,
        "Latitude": 512100000,
        "Longitude": -1163000000,
        "Elevation (m)": 1243,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "YOHO NAT PARK HECTOR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1179008",
        "Station ID": 1384,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -116.33,
        "Latitude": 512600000,
        "Longitude": -1162000000,
        "Elevation (m)": 1639.8,
        "First Year": 1965,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1972,
        "MLY First Year": 1965,
        "MLY Last Year": 1972
      },
      {
        "Name": "YOHO NAT PARK OTTERTAIL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1179009",
        "Station ID": 1385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.4,
        "Longitude (Decimal Degrees)": -116.55,
        "Latitude": 512400000,
        "Longitude": -1163300000,
        "Elevation (m)": 1158.2,
        "First Year": 1965,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1971,
        "MLY First Year": 1965,
        "MLY Last Year": 1971
      },
      {
        "Name": "YOHO NP WAPTA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117900R",
        "Station ID": 1382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -116.33,
        "Latitude": 512700000,
        "Longitude": -1162000000,
        "Elevation (m)": 1645.9,
        "First Year": 1974,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1993,
        "MLY First Year": 1974,
        "MLY Last Year": 1993
      },
      {
        "Name": "YOHO PARK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11790J1",
        "Station ID": 6844,
        "WMO ID": 71786,
        "TC ID": "WYL",
        "Latitude (Decimal Degrees)": 51.44,
        "Longitude (Decimal Degrees)": -116.34,
        "Latitude": 512634400,
        "Longitude": -1162040400,
        "Elevation (m)": 1602,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "GLACIER NP MT FIDELITY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117CA90",
        "Station ID": 1345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.24,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 511417500,
        "Longitude": -1174205300,
        "Elevation (m)": 1890,
        "First Year": 1969,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2014,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "VALEMOUNT SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117HCL9",
        "Station ID": 26995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -119.3,
        "Latitude": 524500000,
        "Longitude": -1191800000,
        "Elevation (m)": 930,
        "First Year": 1994,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1996,
        "MLY First Year": 1994,
        "MLY Last Year": 1996
      },
      {
        "Name": "YOHO NP WEST GATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117R009",
        "Station ID": 1349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -116.67,
        "Latitude": 511400000,
        "Longitude": -1164000000,
        "Elevation (m)": 1143,
        "First Year": 1975,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1979,
        "MLY First Year": 1975,
        "MLY Last Year": 1979
      },
      {
        "Name": "YOHO NAT PARK BOULDER CR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117R00F",
        "Station ID": 1346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -116.53,
        "Latitude": 512300000,
        "Longitude": -1163200000,
        "Elevation (m)": 1219.2,
        "First Year": 1971,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1993,
        "MLY First Year": 1971,
        "MLY Last Year": 1993
      },
      {
        "Name": "YOHO NP EMERALD LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117R00G",
        "Station ID": 27094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -116.54,
        "Latitude": 512600000,
        "Longitude": -1163227900,
        "Elevation (m)": 1280,
        "First Year": 1995,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2017,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "YOHO NP OHARA LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117R00H",
        "Station ID": 1347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.36,
        "Longitude (Decimal Degrees)": -116.34,
        "Latitude": 512122032,
        "Longitude": -1162013002,
        "Elevation (m)": 2024.6,
        "First Year": 1987,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2021,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "YOHO NAT PARK LEANCHOIL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "117R00Q",
        "Station ID": 1348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -116.57,
        "Latitude": 511400000,
        "Longitude": -1163400000,
        "Elevation (m)": 1140,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "ARRAS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180490",
        "Station ID": 1391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.73,
        "Longitude (Decimal Degrees)": -120.53,
        "Latitude": 554400000,
        "Longitude": -1203200000,
        "Elevation (m)": 676.7,
        "First Year": 1970,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1988,
        "MLY First Year": 1970,
        "MLY Last Year": 1988
      },
      {
        "Name": "BAKER MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180580",
        "Station ID": 1392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.2,
        "Longitude (Decimal Degrees)": -126.95,
        "Latitude": 571200000,
        "Longitude": -1265700000,
        "Elevation (m)": 1645.9,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1983
      },
      {
        "Name": "BALDONNEL",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180585",
        "Station ID": 1393,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -120.68,
        "Latitude": 561400000,
        "Longitude": -1204100000,
        "Elevation (m)": 685.8,
        "First Year": 1927,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1993,
        "MLY First Year": 1927,
        "MLY Last Year": 1993
      },
      {
        "Name": "BEATTON RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180749",
        "Station ID": 1394,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.22,
        "Longitude (Decimal Degrees)": -121.47,
        "Latitude": 571300000,
        "Longitude": -1212800000,
        "Elevation (m)": 807.7,
        "First Year": 1977,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1979,
        "MLY First Year": 1977,
        "MLY Last Year": 1979
      },
      {
        "Name": "BEATTON RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180750",
        "Station ID": 1395,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.38,
        "Longitude (Decimal Degrees)": -121.38,
        "Latitude": 572300000,
        "Longitude": -1212300000,
        "Elevation (m)": 839.7,
        "First Year": 1944,
        "Last Year": 1967,
        "HLY First Year": 1953,
        "HLY Last Year": 1967,
        "DLY First Year": 1944,
        "DLY Last Year": 1967,
        "MLY First Year": 1944,
        "MLY Last Year": 1967
      },
      {
        "Name": "BLACKWATER CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180881",
        "Station ID": 1396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.53,
        "Longitude (Decimal Degrees)": -123.65,
        "Latitude": 553200000,
        "Longitude": -1233900000,
        "Elevation (m)": 732,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "BOUNDARY LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1180R07",
        "Station ID": 1390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.33,
        "Longitude (Decimal Degrees)": -120.1,
        "Latitude": 562000000,
        "Longitude": -1200600000,
        "Elevation (m)": 711.1,
        "First Year": 1982,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1987,
        "MLY First Year": 1982,
        "MLY Last Year": 1987
      },
      {
        "Name": "BULLMOOSE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181120",
        "Station ID": 1397,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.23,
        "Longitude (Decimal Degrees)": -121.38,
        "Latitude": 551400000,
        "Longitude": -1212300000,
        "Elevation (m)": 1102,
        "First Year": 1982,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2003,
        "MLY First Year": 1983,
        "MLY Last Year": 2003
      },
      {
        "Name": "CECIL LAKE CDA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181465",
        "Station ID": 1399,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.33,
        "Longitude (Decimal Degrees)": -120.28,
        "Latitude": 562000000,
        "Longitude": -1201700000,
        "Elevation (m)": 772.7,
        "First Year": 1961,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1980,
        "MLY First Year": 1961,
        "MLY Last Year": 1980
      },
      {
        "Name": "CHARLIE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11814FR",
        "Station ID": 1398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.3,
        "Longitude (Decimal Degrees)": -121.02,
        "Latitude": 561751600,
        "Longitude": -1210059400,
        "Elevation (m)": 753,
        "First Year": 1988,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2017,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHETWYND A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181507",
        "Station ID": 51420,
        "WMO ID": null,
        "TC ID": "YCQ",
        "Latitude (Decimal Degrees)": 55.69,
        "Longitude (Decimal Degrees)": -121.63,
        "Latitude": 554114000,
        "Longitude": -1213736000,
        "Elevation (m)": 609.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHETWYND A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181508",
        "Station ID": 1400,
        "WMO ID": null,
        "TC ID": "YCQ",
        "Latitude (Decimal Degrees)": 55.69,
        "Longitude (Decimal Degrees)": -121.63,
        "Latitude": 554114000,
        "Longitude": -1213736000,
        "Elevation (m)": 609.6,
        "First Year": 1982,
        "Last Year": 2022,
        "HLY First Year": 1982,
        "HLY Last Year": 2014,
        "DLY First Year": 1982,
        "DLY Last Year": 2022,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHETWYND BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181509",
        "Station ID": 1401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.7,
        "Longitude (Decimal Degrees)": -121.62,
        "Latitude": 554200000,
        "Longitude": -1213700000,
        "Elevation (m)": 659.9,
        "First Year": 1970,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1982,
        "MLY First Year": 1970,
        "MLY Last Year": 1982
      },
      {
        "Name": "CHETWYND SUKUNKA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181510",
        "Station ID": 1402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.23,
        "Longitude (Decimal Degrees)": -121.65,
        "Latitude": 551400000,
        "Longitude": -1213900000,
        "Elevation (m)": 749.8,
        "First Year": 1971,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "CHETWYND A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1181513",
        "Station ID": 52939,
        "WMO ID": null,
        "TC ID": "YCQ",
        "Latitude (Decimal Degrees)": 55.69,
        "Longitude (Decimal Degrees)": -121.63,
        "Latitude": 554114000,
        "Longitude": -1213736000,
        "Elevation (m)": 609.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAWSON CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182280",
        "Station ID": 1403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.75,
        "Longitude (Decimal Degrees)": -120.22,
        "Latitude": 554500000,
        "Longitude": -1201300000,
        "Elevation (m)": 670.6,
        "First Year": 1950,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1963,
        "MLY First Year": 1950,
        "MLY Last Year": 1963
      },
      {
        "Name": "DAWSON CREEK A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182285",
        "Station ID": 1404,
        "WMO ID": 71471,
        "TC ID": "YDQ",
        "Latitude (Decimal Degrees)": 55.74,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 554430000,
        "Longitude": -1201055000,
        "Elevation (m)": 654.7,
        "First Year": 1964,
        "Last Year": 2009,
        "HLY First Year": 1964,
        "HLY Last Year": 2009,
        "DLY First Year": 1968,
        "DLY Last Year": 2009,
        "MLY First Year": 1968,
        "MLY Last Year": 2006
      },
      {
        "Name": "DAWSON CREEK LWIS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182287",
        "Station ID": 44789,
        "WMO ID": null,
        "TC ID": "VDQ",
        "Latitude (Decimal Degrees)": 55.74,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 554430000,
        "Longitude": -1201055000,
        "Elevation (m)": 654.7,
        "First Year": 2006,
        "Last Year": 2010,
        "HLY First Year": 2006,
        "HLY Last Year": 2010,
        "DLY First Year": 2006,
        "DLY Last Year": 2009,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAWSON CREEK A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182289",
        "Station ID": 48208,
        "WMO ID": 71471,
        "TC ID": "YDQ",
        "Latitude (Decimal Degrees)": 55.74,
        "Longitude (Decimal Degrees)": -120.18,
        "Latitude": 554432000,
        "Longitude": -1201059000,
        "Elevation (m)": 654.7,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAWSON CREEK RADIO BCN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182290",
        "Station ID": 1405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.78,
        "Longitude (Decimal Degrees)": -120.2,
        "Latitude": 554700000,
        "Longitude": -1201200000,
        "Elevation (m)": 781.2,
        "First Year": 1957,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1964,
        "MLY First Year": 1957,
        "MLY Last Year": 1963
      },
      {
        "Name": "DENISON PLANT SITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182427",
        "Station ID": 1406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55,
        "Longitude (Decimal Degrees)": -121.03,
        "Latitude": 550000000,
        "Longitude": -1210200000,
        "Elevation (m)": 854,
        "First Year": 1982,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1998,
        "MLY First Year": 1982,
        "MLY Last Year": 1998
      },
      {
        "Name": "DENISON SHERIFF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182428",
        "Station ID": 1407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.07,
        "Longitude (Decimal Degrees)": -121.12,
        "Latitude": 550400000,
        "Longitude": -1210700000,
        "Elevation (m)": 1096.1,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "FARRELL CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182810",
        "Station ID": 1408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.18,
        "Longitude (Decimal Degrees)": -121.57,
        "Latitude": 561100000,
        "Longitude": -1213400000,
        "Elevation (m)": 503.8,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "FINLAY FORKS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182880",
        "Station ID": 1409,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56,
        "Longitude (Decimal Degrees)": -123.9,
        "Latitude": 560000000,
        "Longitude": -1235400000,
        "Elevation (m)": 579.1,
        "First Year": 1945,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1951,
        "MLY First Year": 1945,
        "MLY Last Year": 1951
      },
      {
        "Name": "FINLAY FORKS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182881",
        "Station ID": 1410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.9,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 555400000,
        "Longitude": -1234400000,
        "Elevation (m)": 762,
        "First Year": 1981,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1989,
        "MLY First Year": 1981,
        "MLY Last Year": 1989
      },
      {
        "Name": "FORT ST JOHN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1182998",
        "Station ID": 1411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -120.83,
        "Latitude": 561500000,
        "Longitude": -1205000000,
        "Elevation (m)": 673.6,
        "First Year": 1910,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1945,
        "MLY First Year": 1910,
        "MLY Last Year": 1945
      },
      {
        "Name": "FORT ST JOHN A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183000",
        "Station ID": 1413,
        "WMO ID": 71943,
        "TC ID": "YXJ",
        "Latitude (Decimal Degrees)": 56.24,
        "Longitude (Decimal Degrees)": -120.74,
        "Latitude": 561417000,
        "Longitude": -1204425000,
        "Elevation (m)": 694.9,
        "First Year": 1942,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1942,
        "DLY Last Year": 2012,
        "MLY First Year": 1942,
        "MLY Last Year": 2012
      },
      {
        "Name": "FORT ST. JOHN A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183001",
        "Station ID": 50837,
        "WMO ID": 71943,
        "TC ID": "YXJ",
        "Latitude (Decimal Degrees)": 56.24,
        "Longitude (Decimal Degrees)": -120.74,
        "Latitude": 561418000,
        "Longitude": -1204425000,
        "Elevation (m)": 694.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT ST JOHN AIRPORT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183002",
        "Station ID": 55198,
        "WMO ID": 73090,
        "TC ID": "VSJ",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -120.75,
        "Latitude": 561451007,
        "Longitude": -1204459008,
        "Elevation (m)": 695,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT ST JOHN- HWOS CLIMATE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183007",
        "Station ID": 50997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.24,
        "Longitude (Decimal Degrees)": -120.74,
        "Latitude": 561417000,
        "Longitude": -1204425000,
        "Elevation (m)": 694.9,
        "First Year": 2012,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2012,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT ST JOHN PINEVIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183032",
        "Station ID": 1414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.32,
        "Longitude (Decimal Degrees)": -120.82,
        "Latitude": 561900000,
        "Longitude": -1204900000,
        "Elevation (m)": 731.5,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "GERMANSEN LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183090",
        "Station ID": 1415,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.79,
        "Longitude (Decimal Degrees)": -124.7,
        "Latitude": 554707900,
        "Longitude": -1244205200,
        "Elevation (m)": 766,
        "First Year": 1951,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 1979,
        "DLY First Year": 1951,
        "DLY Last Year": 2013,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUDSON HOPE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183628",
        "Station ID": 1416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.08,
        "Longitude (Decimal Degrees)": -121.92,
        "Latitude": 560500000,
        "Longitude": -1215500000,
        "Elevation (m)": 489.5,
        "First Year": 1935,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1944,
        "MLY First Year": 1935,
        "MLY Last Year": 1944
      },
      {
        "Name": "HUDSON HOPE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183629",
        "Station ID": 1417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.03,
        "Longitude (Decimal Degrees)": -121.9,
        "Latitude": 560200000,
        "Longitude": -1215400000,
        "Elevation (m)": 480,
        "First Year": 1916,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 2003,
        "MLY First Year": 1916,
        "MLY Last Year": 2003
      },
      {
        "Name": "HUDSON HOPE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183630",
        "Station ID": 1418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.03,
        "Longitude (Decimal Degrees)": -121.91,
        "Latitude": 560132003,
        "Longitude": -1215442001,
        "Elevation (m)": 491,
        "First Year": 1953,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1955,
        "MLY First Year": 1953,
        "MLY Last Year": 1955
      },
      {
        "Name": "HUDSON HOPE LYNX CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183631",
        "Station ID": 1419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -121.87,
        "Latitude": 560700000,
        "Longitude": -1215200000,
        "Elevation (m)": 615.7,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "INGENIKA POINT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183651",
        "Station ID": 1420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.8,
        "Longitude (Decimal Degrees)": -124.9,
        "Latitude": 564800000,
        "Longitude": -1245400000,
        "Elevation (m)": 679.7,
        "First Year": 1972,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1984,
        "MLY First Year": 1972,
        "MLY Last Year": 1984
      },
      {
        "Name": "HUDSON HOPE BCHPA DAM",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1183FL0",
        "Station ID": 1412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.02,
        "Longitude (Decimal Degrees)": -122.2,
        "Latitude": 560100000,
        "Longitude": -1221200000,
        "Elevation (m)": 678.2,
        "First Year": 1963,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1989,
        "MLY First Year": 1963,
        "MLY Last Year": 1989
      },
      {
        "Name": "LAPRISE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184575",
        "Station ID": 26821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.43,
        "Longitude (Decimal Degrees)": -121.98,
        "Latitude": 572600000,
        "Longitude": -1215900000,
        "Elevation (m)": 781,
        "First Year": 1995,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 1997,
        "MLY First Year": 1995,
        "MLY Last Year": 1997
      },
      {
        "Name": "LONE PRAIRIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184716",
        "Station ID": 1422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.57,
        "Longitude (Decimal Degrees)": -121.23,
        "Latitude": 553400000,
        "Longitude": -1211400000,
        "Elevation (m)": 750.1,
        "First Year": 1980,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1985,
        "MLY First Year": 1980,
        "MLY Last Year": 1985
      },
      {
        "Name": "MACKENZIE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184790",
        "Station ID": 1423,
        "WMO ID": null,
        "TC ID": "VMK",
        "Latitude (Decimal Degrees)": 55.31,
        "Longitude (Decimal Degrees)": -123.14,
        "Latitude": 551819000,
        "Longitude": -1230817000,
        "Elevation (m)": 690.1,
        "First Year": 1971,
        "Last Year": 2015,
        "HLY First Year": 1971,
        "HLY Last Year": 2015,
        "DLY First Year": 1971,
        "DLY Last Year": 2013,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "MACKENZIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184791",
        "Station ID": 48870,
        "WMO ID": 71290,
        "TC ID": "YZY",
        "Latitude (Decimal Degrees)": 55.3,
        "Longitude (Decimal Degrees)": -123.13,
        "Latitude": 551758000,
        "Longitude": -1230800000,
        "Elevation (m)": 690.1,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MACKENZIE BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184792",
        "Station ID": 1424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.32,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 551900000,
        "Longitude": -1230500000,
        "Elevation (m)": 721.2,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "MACKENZIE AIRPORT AUTO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184793",
        "Station ID": 51517,
        "WMO ID": 71944,
        "TC ID": "VZY",
        "Latitude (Decimal Degrees)": 55.31,
        "Longitude (Decimal Degrees)": -123.14,
        "Latitude": 551819041,
        "Longitude": -1230816088,
        "Elevation (m)": 694,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MACKENZIE TOWNSITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184795",
        "Station ID": 1425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -123.08,
        "Latitude": 551938000,
        "Longitude": -1230443000,
        "Elevation (m)": 762,
        "First Year": 1966,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2004,
        "MLY First Year": 1967,
        "MLY Last Year": 2004
      },
      {
        "Name": "LONE PRAIRIE SOUTH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1184GJ6",
        "Station ID": 1421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.55,
        "Longitude (Decimal Degrees)": -121.3,
        "Latitude": 553300000,
        "Longitude": -1211800000,
        "Elevation (m)": 854,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "MCLEOD LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1185020",
        "Station ID": 1426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.03,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 550200000,
        "Longitude": -1230200000,
        "Elevation (m)": 704.1,
        "First Year": 1963,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1971,
        "MLY First Year": 1963,
        "MLY Last Year": 1971
      },
      {
        "Name": "MESILINKA CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1185103",
        "Station ID": 26917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -124.5,
        "Latitude": 560700000,
        "Longitude": -1243000000,
        "Elevation (m)": 803,
        "First Year": 1995,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 1996,
        "MLY First Year": 1995,
        "MLY Last Year": 1995
      },
      {
        "Name": "MORFEE LAKES MILL SITE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11851RQ",
        "Station ID": 1427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.3,
        "Longitude (Decimal Degrees)": -123.17,
        "Latitude": 551800000,
        "Longitude": -1231000000,
        "Elevation (m)": 694.9,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "NORTH PINE CDA EPF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1185640",
        "Station ID": 1428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.43,
        "Longitude (Decimal Degrees)": -120.78,
        "Latitude": 562600000,
        "Longitude": -1204700000,
        "Elevation (m)": 710.2,
        "First Year": 1953,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1963,
        "MLY First Year": 1953,
        "MLY Last Year": 1963
      },
      {
        "Name": "PINE PASS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186171",
        "Station ID": 1431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.35,
        "Longitude (Decimal Degrees)": -122.6,
        "Latitude": 552100000,
        "Longitude": -1223600000,
        "Elevation (m)": 944.9,
        "First Year": 1962,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1981,
        "MLY First Year": 1962,
        "MLY Last Year": 1981
      },
      {
        "Name": "PINK MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186172",
        "Station ID": 1432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57,
        "Longitude (Decimal Degrees)": -122.37,
        "Latitude": 570000000,
        "Longitude": -1222200000,
        "Elevation (m)": 1204,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "PINK MOUNTAIN 3",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186173",
        "Station ID": 54818,
        "WMO ID": null,
        "TC ID": "WNO",
        "Latitude (Decimal Degrees)": 56.94,
        "Longitude (Decimal Degrees)": -122.72,
        "Latitude": 565607000,
        "Longitude": -1224301000,
        "Elevation (m)": 960.12,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POUCE COUPE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186350",
        "Station ID": 1433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.72,
        "Longitude (Decimal Degrees)": -120.13,
        "Latitude": 554300000,
        "Longitude": -1200800000,
        "Elevation (m)": 609.6,
        "First Year": 1926,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1939,
        "MLY First Year": 1926,
        "MLY Last Year": 1939
      },
      {
        "Name": "POUCE COUPE MTN VIEW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186352",
        "Station ID": 1434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.77,
        "Longitude (Decimal Degrees)": -120.1,
        "Latitude": 554600000,
        "Longitude": -1200600000,
        "Elevation (m)": 640.1,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "PRESPATOU",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186428",
        "Station ID": 1435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.92,
        "Longitude (Decimal Degrees)": -121.03,
        "Latitude": 565500000,
        "Longitude": -1210200000,
        "Elevation (m)": 684.9,
        "First Year": 1982,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1989,
        "MLY First Year": 1982,
        "MLY Last Year": 1989
      },
      {
        "Name": "PROGRESS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186540",
        "Station ID": 1436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.83,
        "Longitude (Decimal Degrees)": -120.17,
        "Latitude": 555000000,
        "Longitude": -1201000000,
        "Elevation (m)": 701,
        "First Year": 1942,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1946,
        "MLY First Year": 1942,
        "MLY Last Year": 1946
      },
      {
        "Name": "ROLLA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186839",
        "Station ID": 1437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.92,
        "Longitude (Decimal Degrees)": -120.07,
        "Latitude": 555500000,
        "Longitude": -1200400000,
        "Elevation (m)": 637,
        "First Year": 1971,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1990,
        "MLY First Year": 1971,
        "MLY Last Year": 1988
      },
      {
        "Name": "ROLLA GOLATA CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186842",
        "Station ID": 1438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.13,
        "Longitude (Decimal Degrees)": -120.13,
        "Latitude": 560800000,
        "Longitude": -1200800000,
        "Elevation (m)": 609.6,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1964
      },
      {
        "Name": "ROSE PRAIRIE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186868",
        "Station ID": 1439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.65,
        "Longitude (Decimal Degrees)": -120.77,
        "Latitude": 563900000,
        "Longitude": -1204600000,
        "Elevation (m)": 670.6,
        "First Year": 1973,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1988,
        "MLY First Year": 1973,
        "MLY Last Year": 1988
      },
      {
        "Name": "PINE PASS MT LEMORAY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186A71",
        "Station ID": 1429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.54,
        "Longitude (Decimal Degrees)": -122.48,
        "Latitude": 553221000,
        "Longitude": -1222859000,
        "Elevation (m)": 679.7,
        "First Year": 1974,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2004,
        "MLY First Year": 1974,
        "MLY Last Year": 2004
      },
      {
        "Name": "PINK MOUNTAIN 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1186J7K",
        "Station ID": 1430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.03,
        "Longitude (Decimal Degrees)": -122.52,
        "Latitude": 570200000,
        "Longitude": -1223100000,
        "Elevation (m)": 1144,
        "First Year": 1982,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1990,
        "MLY First Year": 1982,
        "MLY Last Year": 1990
      },
      {
        "Name": "SIKANNI CHIEF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1187335",
        "Station ID": 1440,
        "WMO ID": null,
        "TC ID": "WKS",
        "Latitude (Decimal Degrees)": 57.25,
        "Longitude (Decimal Degrees)": -122.72,
        "Latitude": 571501008,
        "Longitude": -1224305001,
        "Elevation (m)": 937,
        "First Year": 1990,
        "Last Year": 2016,
        "HLY First Year": 1994,
        "HLY Last Year": 2014,
        "DLY First Year": 1990,
        "DLY Last Year": 2016,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "SIKANNI CHIEF",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1187336",
        "Station ID": 52078,
        "WMO ID": null,
        "TC ID": "WKS",
        "Latitude (Decimal Degrees)": 57.25,
        "Longitude (Decimal Degrees)": -122.72,
        "Latitude": 571501000,
        "Longitude": -1224305000,
        "Elevation (m)": 937,
        "First Year": 2014,
        "Last Year": 2018,
        "HLY First Year": 2014,
        "HLY Last Year": 2018,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUMMIT LAKE BCFS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1187861",
        "Station ID": 1441,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -122.63,
        "Latitude": 541700000,
        "Longitude": -1223800000,
        "Elevation (m)": 716.3,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "SWEETWATER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1187885",
        "Station ID": 1442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.87,
        "Longitude (Decimal Degrees)": -120.5,
        "Latitude": 555200000,
        "Longitude": -1203000000,
        "Elevation (m)": 792.5,
        "First Year": 1932,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 1945,
        "MLY First Year": 1932,
        "MLY Last Year": 1945
      },
      {
        "Name": "TAYLOR FLATS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1188020",
        "Station ID": 1443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.17,
        "Longitude (Decimal Degrees)": -120.69,
        "Latitude": 561000003,
        "Longitude": -1204116000,
        "Elevation (m)": 517,
        "First Year": 1960,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2015,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "TAYLOR 3SE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1188026",
        "Station ID": 1444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -120.63,
        "Latitude": 560700000,
        "Longitude": -1203800000,
        "Elevation (m)": 442.9,
        "First Year": 1983,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1989,
        "MLY First Year": 1983,
        "MLY Last Year": 1989
      },
      {
        "Name": "TUMBLER RIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1188297",
        "Station ID": 1445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.13,
        "Longitude (Decimal Degrees)": -121.25,
        "Latitude": 550759000,
        "Longitude": -1211500000,
        "Elevation (m)": 824,
        "First Year": 1985,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2003,
        "MLY First Year": 1985,
        "MLY Last Year": 2003
      },
      {
        "Name": "WARE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1188696",
        "Station ID": 1446,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.43,
        "Longitude (Decimal Degrees)": -125.63,
        "Latitude": 572600000,
        "Longitude": -1253800000,
        "Elevation (m)": 777.2,
        "First Year": 1966,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1987,
        "MLY First Year": 1966,
        "MLY Last Year": 1987
      },
      {
        "Name": "WOLVERINE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1188971",
        "Station ID": 1447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.07,
        "Longitude (Decimal Degrees)": -121.27,
        "Latitude": 550400000,
        "Longitude": -1211600000,
        "Elevation (m)": 854,
        "First Year": 1981,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1984,
        "MLY First Year": 1981,
        "MLY Last Year": 1984
      },
      {
        "Name": "WONOWON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1188973",
        "Station ID": 1448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.73,
        "Longitude (Decimal Degrees)": -121.8,
        "Latitude": 564400000,
        "Longitude": -1214800000,
        "Elevation (m)": 914.4,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "FINLAY FORKS 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "118BHQ1",
        "Station ID": 1386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.98,
        "Longitude (Decimal Degrees)": -123.73,
        "Latitude": 555900000,
        "Longitude": -1234400000,
        "Elevation (m)": 1128,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "HALFWAY RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "118CCB1",
        "Station ID": 1387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.73,
        "Longitude (Decimal Degrees)": -122.58,
        "Latitude": 564400000,
        "Longitude": -1223500000,
        "Elevation (m)": 670.6,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "FORT ST JOHN 8NW",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "118L0LK",
        "Station ID": 1388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -121.03,
        "Latitude": 561511000,
        "Longitude": -1210202000,
        "Elevation (m)": 808,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2007,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUDSON HOPE BRENOT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "118L6C0",
        "Station ID": 1389,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.08,
        "Longitude (Decimal Degrees)": -121.97,
        "Latitude": 560448000,
        "Longitude": -1215819000,
        "Elevation (m)": 690,
        "First Year": 1987,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1998,
        "MLY First Year": 1987,
        "MLY Last Year": 1998
      },
      {
        "Name": "BUCKINGHORSE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1191101",
        "Station ID": 1450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.38,
        "Longitude (Decimal Degrees)": -122.87,
        "Latitude": 572300000,
        "Longitude": -1225200000,
        "Elevation (m)": 1097.3,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "CASSIAR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1191440",
        "Station ID": 1451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.28,
        "Longitude (Decimal Degrees)": -129.83,
        "Latitude": 591700000,
        "Longitude": -1295000000,
        "Elevation (m)": 1077.5,
        "First Year": 1954,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1996,
        "MLY First Year": 1954,
        "MLY Last Year": 1996
      },
      {
        "Name": "CASSIAR MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1191442",
        "Station ID": 1452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.33,
        "Longitude (Decimal Degrees)": -129.82,
        "Latitude": 592000000,
        "Longitude": -1294900000,
        "Elevation (m)": 1853.2,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "CHURCHILL COPPER ONE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1191618",
        "Station ID": 1453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.45,
        "Longitude (Decimal Degrees)": -125.4,
        "Latitude": 582700000,
        "Longitude": -1252400000,
        "Elevation (m)": 1592.6,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "COAL RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1191740",
        "Station ID": 6846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.67,
        "Longitude (Decimal Degrees)": -127.15,
        "Latitude": 594000000,
        "Longitude": -1270900000,
        "Elevation (m)": 517,
        "First Year": 1992,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1997,
        "MLY First Year": 1992,
        "MLY Last Year": 1997
      },
      {
        "Name": "DEASE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192340",
        "Station ID": 1454,
        "WMO ID": 71958,
        "TC ID": "WDL",
        "Latitude (Decimal Degrees)": 58.43,
        "Longitude (Decimal Degrees)": -130.01,
        "Latitude": 582542006,
        "Longitude": -1300038004,
        "Elevation (m)": 806.6,
        "First Year": 1944,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1944,
        "DLY Last Year": 2011,
        "MLY First Year": 1944,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEASE LAKE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192341",
        "Station ID": 49628,
        "WMO ID": 71686,
        "TC ID": "YDL",
        "Latitude (Decimal Degrees)": 58.42,
        "Longitude (Decimal Degrees)": -130.03,
        "Latitude": 582520000,
        "Longitude": -1300153000,
        "Elevation (m)": 802.8,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEASE LAKE LWIS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192345",
        "Station ID": 30909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.42,
        "Longitude (Decimal Degrees)": -130.03,
        "Latitude": 582520000,
        "Longitude": -1300156000,
        "Elevation (m)": 792.5,
        "First Year": 2000,
        "Last Year": 2011,
        "HLY First Year": 2000,
        "HLY Last Year": 2011,
        "DLY First Year": 2002,
        "DLY Last Year": 2004,
        "MLY First Year": 2002,
        "MLY Last Year": 2004
      },
      {
        "Name": "FORT NELSON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192940",
        "Station ID": 1455,
        "WMO ID": 71945,
        "TC ID": "YYE",
        "Latitude (Decimal Degrees)": 58.84,
        "Longitude (Decimal Degrees)": -122.6,
        "Latitude": 585011000,
        "Longitude": -1223550000,
        "Elevation (m)": 381.9,
        "First Year": 1937,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1937,
        "DLY Last Year": 2012,
        "MLY First Year": 1937,
        "MLY Last Year": 2012
      },
      {
        "Name": "FORT NELSON ALPINE RD",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192942",
        "Station ID": 1456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.82,
        "Longitude (Decimal Degrees)": -122.7,
        "Latitude": 584900000,
        "Longitude": -1224200000,
        "Elevation (m)": 419.1,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "FT NELSON CHURCHILL MINE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192943",
        "Station ID": 1457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.48,
        "Longitude (Decimal Degrees)": -125.4,
        "Latitude": 582900000,
        "Longitude": -1252400000,
        "Elevation (m)": 1528.6,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "FORT NELSON GARDINER CR",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192945",
        "Station ID": 1458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.82,
        "Longitude (Decimal Degrees)": -122.7,
        "Latitude": 584900000,
        "Longitude": -1224200000,
        "Elevation (m)": 419.4,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "FORT NELSON A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192946",
        "Station ID": 50819,
        "WMO ID": 71945,
        "TC ID": "YYE",
        "Latitude (Decimal Degrees)": 58.84,
        "Longitude (Decimal Degrees)": -122.6,
        "Latitude": 585011000,
        "Longitude": -1223549000,
        "Elevation (m)": 381.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT NELSON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192948",
        "Station ID": 54098,
        "WMO ID": 71594,
        "TC ID": "VFN",
        "Latitude (Decimal Degrees)": 58.84,
        "Longitude (Decimal Degrees)": -122.57,
        "Latitude": 585029006,
        "Longitude": -1223427002,
        "Elevation (m)": 380.2,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT NELSON UA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1192950",
        "Station ID": 8248,
        "WMO ID": 71945,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.84,
        "Longitude (Decimal Degrees)": -122.57,
        "Latitude": 585029000,
        "Longitude": -1223422070,
        "Elevation (m)": 378.3,
        "First Year": 1999,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2017,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOOD HOPE LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1193245",
        "Station ID": 1459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.3,
        "Longitude (Decimal Degrees)": -129.28,
        "Latitude": 591800000,
        "Longitude": -1291700000,
        "Elevation (m)": 769.6,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "LIARD RIVER (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1194605",
        "Station ID": 1460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.45,
        "Longitude (Decimal Degrees)": -126.15,
        "Latitude": 592700000,
        "Longitude": -1260900000,
        "Elevation (m)": 464.2,
        "First Year": 1972,
        "Last Year": 1981,
        "HLY First Year": 1977,
        "HLY Last Year": 1981,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "LOWER POST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1194722",
        "Station ID": 1461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.93,
        "Longitude (Decimal Degrees)": -128.5,
        "Latitude": 595600000,
        "Longitude": -1283000000,
        "Elevation (m)": 583.1,
        "First Year": 1960,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1976,
        "MLY First Year": 1960,
        "MLY Last Year": 1976
      },
      {
        "Name": "MIDWAY PROJECT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195127",
        "Station ID": 1468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.93,
        "Longitude (Decimal Degrees)": -130.33,
        "Latitude": 595600000,
        "Longitude": -1302000000,
        "Elevation (m)": 1150,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1986,
        "MLY First Year": 1985,
        "MLY Last Year": 1986
      },
      {
        "Name": "MILE 295 ALASKA HWY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "119512R",
        "Station ID": 1467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.78,
        "Longitude (Decimal Degrees)": -122.67,
        "Latitude": 584700000,
        "Longitude": -1224000000,
        "Elevation (m)": 380,
        "First Year": 1984,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1991,
        "MLY First Year": 1984,
        "MLY Last Year": 1991
      },
      {
        "Name": "MINAKER RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195165",
        "Station ID": 1469,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.85,
        "Longitude (Decimal Degrees)": -123.03,
        "Latitude": 575100000,
        "Longitude": -1230200000,
        "Elevation (m)": 625.3,
        "First Year": 1984,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1993,
        "MLY First Year": 1984,
        "MLY Last Year": 1993
      },
      {
        "Name": "MILE 306 ALASKA HWY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11951B9",
        "Station ID": 1465,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.85,
        "Longitude (Decimal Degrees)": -122.87,
        "Latitude": 585100000,
        "Longitude": -1225200000,
        "Elevation (m)": 451.1,
        "First Year": 1984,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1992,
        "MLY First Year": 1984,
        "MLY Last Year": 1992
      },
      {
        "Name": "MILE 318 ALASKA HWY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "11951K9",
        "Station ID": 1466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.9,
        "Longitude (Decimal Degrees)": -123.12,
        "Latitude": 585400000,
        "Longitude": -1230700000,
        "Elevation (m)": 520.9,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "MUNCHO LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195250",
        "Station ID": 1470,
        "WMO ID": 71280,
        "TC ID": "WWQ",
        "Latitude (Decimal Degrees)": 58.93,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 585548004,
        "Longitude": -1254600003,
        "Elevation (m)": 836.5,
        "First Year": 1970,
        "Last Year": 2014,
        "HLY First Year": 1994,
        "HLY Last Year": 2014,
        "DLY First Year": 1970,
        "DLY Last Year": 2014,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "MUNCHO LAKE A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195251",
        "Station ID": 52158,
        "WMO ID": null,
        "TC ID": "WWQ",
        "Latitude (Decimal Degrees)": 58.93,
        "Longitude (Decimal Degrees)": -125.77,
        "Latitude": 585548000,
        "Longitude": -1254600000,
        "Elevation (m)": 836.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TETSA RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195800",
        "Station ID": 52159,
        "WMO ID": null,
        "TC ID": "WTH",
        "Latitude (Decimal Degrees)": 58.65,
        "Longitude (Decimal Degrees)": -124.24,
        "Latitude": 583911000,
        "Longitude": -1241409000,
        "Elevation (m)": 810,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MILE 351 ALASKA HWY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195A29",
        "Station ID": 1463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.68,
        "Longitude (Decimal Degrees)": -123.72,
        "Latitude": 584100000,
        "Longitude": -1234300000,
        "Elevation (m)": 846.1,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "MILE 371 ALASKA HWY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195ABR",
        "Station ID": 1462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.65,
        "Longitude (Decimal Degrees)": -124.13,
        "Latitude": 583900000,
        "Longitude": -1240800000,
        "Elevation (m)": 758,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "TETSA RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1195J29",
        "Station ID": 1464,
        "WMO ID": null,
        "TC ID": "WTH",
        "Latitude (Decimal Degrees)": 58.65,
        "Longitude (Decimal Degrees)": -124.24,
        "Latitude": 583911056,
        "Longitude": -1241409052,
        "Elevation (m)": 810,
        "First Year": 1982,
        "Last Year": 2017,
        "HLY First Year": 1994,
        "HLY Last Year": 2014,
        "DLY First Year": 1982,
        "DLY Last Year": 2017,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "PROPHET RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1196543",
        "Station ID": 1471,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.22,
        "Longitude (Decimal Degrees)": -122.78,
        "Latitude": 581300000,
        "Longitude": -1224700000,
        "Elevation (m)": 457.2,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "SIERRA",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1197331",
        "Station ID": 1472,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.83,
        "Longitude (Decimal Degrees)": -121.42,
        "Latitude": 585000000,
        "Longitude": -1212500000,
        "Elevation (m)": 566.3,
        "First Year": 1974,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1981,
        "MLY First Year": 1974,
        "MLY Last Year": 1981
      },
      {
        "Name": "SMITH RIVER A",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1197530",
        "Station ID": 1473,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.9,
        "Longitude (Decimal Degrees)": -126.43,
        "Latitude": 595400000,
        "Longitude": -1262600000,
        "Elevation (m)": 673,
        "First Year": 1944,
        "Last Year": 1969,
        "HLY First Year": 1953,
        "HLY Last Year": 1969,
        "DLY First Year": 1944,
        "DLY Last Year": 1969,
        "MLY First Year": 1944,
        "MLY Last Year": 1969
      },
      {
        "Name": "STEAMBOAT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1197704",
        "Station ID": 6847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.68,
        "Longitude (Decimal Degrees)": -123.77,
        "Latitude": 584100000,
        "Longitude": -1234600000,
        "Elevation (m)": 1063.8,
        "First Year": 1993,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": 1993,
        "DLY Last Year": 1995,
        "MLY First Year": 1993,
        "MLY Last Year": 1995
      },
      {
        "Name": "SUMMIT LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1197860",
        "Station ID": 1474,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.65,
        "Longitude (Decimal Degrees)": -124.65,
        "Latitude": 583900000,
        "Longitude": -1243900000,
        "Elevation (m)": 1280.5,
        "First Year": 1976,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1991,
        "MLY First Year": 1976,
        "MLY Last Year": 1991
      },
      {
        "Name": "TOAD RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1198194",
        "Station ID": 1475,
        "WMO ID": null,
        "TC ID": "WBP",
        "Latitude (Decimal Degrees)": 58.85,
        "Longitude (Decimal Degrees)": -125.23,
        "Latitude": 585100000,
        "Longitude": -1251400000,
        "Elevation (m)": 731.5,
        "First Year": 1982,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1995,
        "MLY First Year": 1982,
        "MLY Last Year": 1995
      },
      {
        "Name": "TOAD RIVER BONANZA CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1198196",
        "Station ID": 1476,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.55,
        "Longitude (Decimal Degrees)": -125.48,
        "Latitude": 583300000,
        "Longitude": -1252900000,
        "Elevation (m)": 1428,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "TRUTCH MILE 200",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1198281",
        "Station ID": 1477,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.73,
        "Longitude (Decimal Degrees)": -122.95,
        "Latitude": 574400000,
        "Longitude": -1225700000,
        "Elevation (m)": 868.7,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "TRUTCH MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1198282",
        "Station ID": 1478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.38,
        "Longitude (Decimal Degrees)": -122.98,
        "Latitude": 572300000,
        "Longitude": -1225900000,
        "Elevation (m)": 1066.8,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "YOYO",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1199020",
        "Station ID": 1479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.88,
        "Longitude (Decimal Degrees)": -121.5,
        "Latitude": 585300000,
        "Longitude": -1213000000,
        "Elevation (m)": 579.1,
        "First Year": 1983,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "DEASE LAKE (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "119BLM0",
        "Station ID": 6845,
        "WMO ID": 71222,
        "TC ID": "WKX",
        "Latitude (Decimal Degrees)": 58.43,
        "Longitude (Decimal Degrees)": -130.03,
        "Latitude": 582533959,
        "Longitude": -1300130000,
        "Elevation (m)": 800.67,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "LOWER LIARD BRIDGE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "119M720",
        "Station ID": 1449,
        "WMO ID": null,
        "TC ID": "WBX",
        "Latitude (Decimal Degrees)": 59.42,
        "Longitude (Decimal Degrees)": -126.1,
        "Latitude": 592500000,
        "Longitude": -1260600000,
        "Elevation (m)": 434.6,
        "First Year": 1976,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1998,
        "MLY First Year": 1976,
        "MLY Last Year": 1998
      },
      {
        "Name": "ATLIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1200560",
        "Station ID": 1485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.57,
        "Longitude (Decimal Degrees)": -133.7,
        "Latitude": 593400000,
        "Longitude": -1334200000,
        "Elevation (m)": 673.6,
        "First Year": 1899,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 2021,
        "MLY First Year": 1899,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEARSKIN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1200730",
        "Station ID": 1486,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.22,
        "Longitude (Decimal Degrees)": -132.27,
        "Latitude": 581300000,
        "Longitude": -1321600000,
        "Elevation (m)": 958,
        "First Year": 1983,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1990,
        "MLY First Year": 1983,
        "MLY Last Year": 1989
      },
      {
        "Name": "BENNETT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1200847",
        "Station ID": 1487,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.85,
        "Longitude (Decimal Degrees)": -134.98,
        "Latitude": 595100000,
        "Longitude": -1345900000,
        "Elevation (m)": 662,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "BOB QUINN LAKE 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "120090J",
        "Station ID": 6947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.97,
        "Longitude (Decimal Degrees)": -130.25,
        "Latitude": 565800000,
        "Longitude": -1301500000,
        "Elevation (m)": 609.6,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "BOB QUINN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1200R0A",
        "Station ID": 1483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.98,
        "Longitude (Decimal Degrees)": -130.25,
        "Latitude": 565900000,
        "Longitude": -1301500000,
        "Elevation (m)": 579.1,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "BOB QUINN AGS",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1200R0J",
        "Station ID": 1484,
        "WMO ID": null,
        "TC ID": "BW4",
        "Latitude (Decimal Degrees)": 56.97,
        "Longitude (Decimal Degrees)": -130.25,
        "Latitude": 565800400,
        "Longitude": -1301500000,
        "Elevation (m)": 610,
        "First Year": 1977,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1994,
        "MLY First Year": 1977,
        "MLY Last Year": 1994
      },
      {
        "Name": "BRONSON CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1201086",
        "Station ID": 1488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.67,
        "Longitude (Decimal Degrees)": -131.1,
        "Latitude": 564000000,
        "Longitude": -1310600000,
        "Elevation (m)": 107,
        "First Year": 1989,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1999,
        "MLY First Year": 1989,
        "MLY Last Year": 1999
      },
      {
        "Name": "COLD FISH LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1201770",
        "Station ID": 1489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.67,
        "Longitude (Decimal Degrees)": -128.83,
        "Latitude": 574000000,
        "Longitude": -1285000000,
        "Elevation (m)": 1202.7,
        "First Year": 1955,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1962,
        "MLY First Year": 1955,
        "MLY Last Year": 1962
      },
      {
        "Name": "EDDONTENAJON",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1202638",
        "Station ID": 1490,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.83,
        "Longitude (Decimal Degrees)": -129.98,
        "Latitude": 575000000,
        "Longitude": -1295900000,
        "Elevation (m)": 883.9,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "GALORE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1203046",
        "Station ID": 1491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.12,
        "Longitude (Decimal Degrees)": -131.45,
        "Latitude": 570700000,
        "Longitude": -1312700000,
        "Elevation (m)": 789.4,
        "First Year": 1966,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1973,
        "MLY First Year": 1966,
        "MLY Last Year": 1973
      },
      {
        "Name": "GRAHAM INLET",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1203255",
        "Station ID": 1492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.6,
        "Longitude (Decimal Degrees)": -134.18,
        "Latitude": 593600000,
        "Longitude": -1341100000,
        "Elevation (m)": 659.9,
        "First Year": 1973,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2011,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "HAINES APPS NO 2",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1203315",
        "Station ID": 1494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.52,
        "Longitude (Decimal Degrees)": -136.47,
        "Latitude": 593100000,
        "Longitude": -1362800000,
        "Elevation (m)": 399.3,
        "First Year": 1956,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1974,
        "MLY First Year": 1956,
        "MLY Last Year": 1974
      },
      {
        "Name": "HATIN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "12033M0",
        "Station ID": 1493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.65,
        "Longitude (Decimal Degrees)": -131.72,
        "Latitude": 583900000,
        "Longitude": -1314300000,
        "Elevation (m)": 944.9,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "HYLAND POST",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1203640",
        "Station ID": 1495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.65,
        "Longitude (Decimal Degrees)": -128.17,
        "Latitude": 573900000,
        "Longitude": -1281000000,
        "Elevation (m)": 1066.8,
        "First Year": 1964,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1972,
        "MLY First Year": 1964,
        "MLY Last Year": 1972
      },
      {
        "Name": "ISKUT",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1203670",
        "Station ID": 1496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.82,
        "Longitude (Decimal Degrees)": -129.97,
        "Latitude": 574900000,
        "Longitude": -1295800000,
        "Elevation (m)": 883.9,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ISKUT RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1203672",
        "Station ID": 1497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.87,
        "Longitude (Decimal Degrees)": -130.02,
        "Latitude": 575200000,
        "Longitude": -1300100000,
        "Elevation (m)": 854,
        "First Year": 1976,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1994,
        "MLY First Year": 1976,
        "MLY Last Year": 1994
      },
      {
        "Name": "KINASKAN LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1204215",
        "Station ID": 1498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.53,
        "Longitude (Decimal Degrees)": -130.2,
        "Latitude": 573200000,
        "Longitude": -1301200000,
        "Elevation (m)": 815.3,
        "First Year": 1966,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1977,
        "MLY First Year": 1966,
        "MLY Last Year": 1977
      },
      {
        "Name": "KUTCHO CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1204432",
        "Station ID": 1499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.28,
        "Longitude (Decimal Degrees)": -128.5,
        "Latitude": 581700000,
        "Longitude": -1283000000,
        "Elevation (m)": 1240,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "LINDEMAN CITY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1204632",
        "Station ID": 1500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.78,
        "Longitude (Decimal Degrees)": -135.08,
        "Latitude": 594700000,
        "Longitude": -1350500000,
        "Elevation (m)": 667.5,
        "First Year": 1968,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1981,
        "MLY First Year": 1969,
        "MLY Last Year": 1981
      },
      {
        "Name": "MCBRIDE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1204958",
        "Station ID": 1501,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.97,
        "Longitude (Decimal Degrees)": -129.27,
        "Latitude": 575800000,
        "Longitude": -1291600000,
        "Elevation (m)": 914.4,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1975,
        "MLY Last Year": 1978
      },
      {
        "Name": "MULE CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1205248",
        "Station ID": 1502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.78,
        "Longitude (Decimal Degrees)": -136.6,
        "Latitude": 594700000,
        "Longitude": -1363600000,
        "Elevation (m)": 883.9,
        "First Year": 1970,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1986,
        "MLY First Year": 1970,
        "MLY Last Year": 1986
      },
      {
        "Name": "NAKONAKE RIVER",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1205295",
        "Station ID": 1503,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59,
        "Longitude (Decimal Degrees)": -133.48,
        "Latitude": 590000000,
        "Longitude": -1332900000,
        "Elevation (m)": 365.8,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "PLEASANT CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1206197",
        "Station ID": 1504,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.45,
        "Longitude (Decimal Degrees)": -136.37,
        "Latitude": 592700000,
        "Longitude": -1362200000,
        "Elevation (m)": 274.3,
        "First Year": 1974,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2022,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORTERS LANDING",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1206258",
        "Station ID": 1505,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.8,
        "Longitude (Decimal Degrees)": -133.1,
        "Latitude": 584800000,
        "Longitude": -1330600000,
        "Elevation (m)": 731.5,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "SCHAFT CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1207126",
        "Station ID": 1506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.35,
        "Longitude (Decimal Degrees)": -131,
        "Latitude": 572100000,
        "Longitude": -1310000000,
        "Elevation (m)": 914.4,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "TELEGRAPH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1208040",
        "Station ID": 1507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.9,
        "Longitude (Decimal Degrees)": -131.17,
        "Latitude": 575400000,
        "Longitude": -1311000000,
        "Elevation (m)": 182.9,
        "First Year": 1942,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1979,
        "MLY First Year": 1942,
        "MLY Last Year": 1979
      },
      {
        "Name": "TELEGRAPH CREEK",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1208041",
        "Station ID": 1508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.9,
        "Longitude (Decimal Degrees)": -131.33,
        "Latitude": 575400000,
        "Longitude": -1312000000,
        "Elevation (m)": 249.9,
        "First Year": 1979,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2000,
        "MLY First Year": 1979,
        "MLY Last Year": 2000
      },
      {
        "Name": "TODAGIN RANCH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1208202",
        "Station ID": 1509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.6,
        "Longitude (Decimal Degrees)": -130.07,
        "Latitude": 573600000,
        "Longitude": -1300400000,
        "Elevation (m)": 899,
        "First Year": 1973,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1992,
        "MLY First Year": 1973,
        "MLY Last Year": 1992
      },
      {
        "Name": "TULSEQUAH",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "1208295",
        "Station ID": 1510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.7,
        "Longitude (Decimal Degrees)": -133.63,
        "Latitude": 584200000,
        "Longitude": -1333800000,
        "Elevation (m)": null,
        "First Year": 1964,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1966,
        "MLY First Year": 1964,
        "MLY Last Year": 1966
      },
      {
        "Name": "FRASER CAMP",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "120C036",
        "Station ID": 1481,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.72,
        "Longitude (Decimal Degrees)": -135.05,
        "Latitude": 594259000,
        "Longitude": -1350242000,
        "Elevation (m)": 869,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "ISKUT RIVER (AUT)",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "120C6PK",
        "Station ID": 10806,
        "WMO ID": 71762,
        "TC ID": "WIO",
        "Latitude (Decimal Degrees)": 56.73,
        "Longitude (Decimal Degrees)": -131.67,
        "Latitude": 564400000,
        "Longitude": -1314000000,
        "Elevation (m)": 15,
        "First Year": 1994,
        "Last Year": 2002,
        "HLY First Year": 1994,
        "HLY Last Year": 2002,
        "DLY First Year": 1995,
        "DLY Last Year": 2002,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "JOHNNY MOUNTAIN",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "120CPNA",
        "Station ID": 1480,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.63,
        "Longitude (Decimal Degrees)": -131.08,
        "Latitude": 563800000,
        "Longitude": -1310500000,
        "Elevation (m)": 1075,
        "First Year": 1988,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1992,
        "MLY First Year": 1988,
        "MLY Last Year": 1992
      },
      {
        "Name": "LOST LAKE",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "120DP19",
        "Station ID": 6946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.72,
        "Longitude (Decimal Degrees)": -131.22,
        "Latitude": 564300000,
        "Longitude": -1311300000,
        "Elevation (m)": 90,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "WINDY CRAGGY",
        "Province": "BRITISH COLUMBIA",
        "Climate ID": "120HRNP",
        "Station ID": 1482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.62,
        "Longitude (Decimal Degrees)": -137.72,
        "Latitude": 593700000,
        "Longitude": -1374300000,
        "Elevation (m)": 667,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1987,
        "MLY Last Year": 1990
      },
      {
        "Name": "AISHIHIK A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100100",
        "Station ID": 1515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.65,
        "Longitude (Decimal Degrees)": -137.48,
        "Latitude": 613900000,
        "Longitude": -1372900000,
        "Elevation (m)": 966.2,
        "First Year": 1943,
        "Last Year": 1966,
        "HLY First Year": 1953,
        "HLY Last Year": 1966,
        "DLY First Year": 1943,
        "DLY Last Year": 1966,
        "MLY First Year": 1943,
        "MLY Last Year": 1966
      },
      {
        "Name": "ANNIE LAKE ROBINSON",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100115",
        "Station ID": 1516,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.47,
        "Longitude (Decimal Degrees)": -134.83,
        "Latitude": 602800000,
        "Longitude": -1345000000,
        "Elevation (m)": 820,
        "First Year": 1976,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2006,
        "MLY First Year": 1976,
        "MLY Last Year": 2006
      },
      {
        "Name": "ANVIL",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100120",
        "Station ID": 1517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.37,
        "Longitude (Decimal Degrees)": -133.38,
        "Latitude": 622200000,
        "Longitude": -1332300000,
        "Elevation (m)": 1158.2,
        "First Year": 1967,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1991,
        "MLY First Year": 1967,
        "MLY Last Year": 1991
      },
      {
        "Name": "BABBAGE RIVER (AUT)",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100143",
        "Station ID": 10187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.82,
        "Longitude (Decimal Degrees)": -138.75,
        "Latitude": 684900000,
        "Longitude": -1384500000,
        "Elevation (m)": 182.9,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": 1988,
        "HLY Last Year": 1989,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAVER CREEK A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100155",
        "Station ID": 53179,
        "WMO ID": null,
        "TC ID": "YXQ",
        "Latitude (Decimal Degrees)": 62.41,
        "Longitude (Decimal Degrees)": -140.87,
        "Latitude": 622437000,
        "Longitude": -1405208000,
        "Elevation (m)": 649.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAVER CREEK A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100159",
        "Station ID": 53178,
        "WMO ID": null,
        "TC ID": "YXQ",
        "Latitude (Decimal Degrees)": 62.41,
        "Longitude (Decimal Degrees)": -140.87,
        "Latitude": 622437000,
        "Longitude": -1405208000,
        "Elevation (m)": 649.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAVER CREEK A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100160",
        "Station ID": 1518,
        "WMO ID": null,
        "TC ID": "YXQ",
        "Latitude (Decimal Degrees)": 62.41,
        "Longitude (Decimal Degrees)": -140.87,
        "Latitude": 622437000,
        "Longitude": -1405203000,
        "Elevation (m)": 649,
        "First Year": 1968,
        "Last Year": 2015,
        "HLY First Year": 1968,
        "HLY Last Year": 2014,
        "DLY First Year": 1968,
        "DLY Last Year": 2015,
        "MLY First Year": 1968,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEAVER CREEK YTG",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100161",
        "Station ID": 6848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.37,
        "Longitude (Decimal Degrees)": -140.87,
        "Latitude": 622200000,
        "Longitude": -1405200000,
        "Elevation (m)": 663,
        "First Year": 1993,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2008,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLANCHARD RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100163",
        "Station ID": 1519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60,
        "Longitude (Decimal Degrees)": -136.77,
        "Latitude": 600000000,
        "Longitude": -1364600000,
        "Elevation (m)": 836,
        "First Year": 1986,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2012,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "BONANZA CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100164",
        "Station ID": 1520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.97,
        "Longitude (Decimal Degrees)": -139.35,
        "Latitude": 635800000,
        "Longitude": -1392100000,
        "Elevation (m)": 396.2,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "BOUNDARY",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100165",
        "Station ID": 1521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.23,
        "Longitude (Decimal Degrees)": -140.35,
        "Latitude": 641400000,
        "Longitude": -1402100000,
        "Elevation (m)": 1036.3,
        "First Year": 1967,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1978,
        "MLY First Year": 1967,
        "MLY Last Year": 1978
      },
      {
        "Name": "BRAEBURN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100167",
        "Station ID": 1522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.47,
        "Longitude (Decimal Degrees)": -135.78,
        "Latitude": 612800000,
        "Longitude": -1354700000,
        "Elevation (m)": 716.3,
        "First Year": 1974,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1995,
        "MLY First Year": 1974,
        "MLY Last Year": 1995
      },
      {
        "Name": "BRANDT PEAK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100168",
        "Station ID": 1523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.05,
        "Longitude (Decimal Degrees)": -140.93,
        "Latitude": 630300000,
        "Longitude": -1405600000,
        "Elevation (m)": 1127.8,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "BRYN MYRDDIN FARM",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100174",
        "Station ID": 1524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.87,
        "Longitude (Decimal Degrees)": -135.38,
        "Latitude": 605200000,
        "Longitude": -1352300000,
        "Elevation (m)": 686,
        "First Year": 1988,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1996,
        "MLY First Year": 1988,
        "MLY Last Year": 1996
      },
      {
        "Name": "BURWASH",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100179",
        "Station ID": 10192,
        "WMO ID": null,
        "TC ID": "WDB",
        "Latitude (Decimal Degrees)": 61.37,
        "Longitude (Decimal Degrees)": -139.03,
        "Latitude": 612200000,
        "Longitude": -1390200000,
        "Elevation (m)": 806.8,
        "First Year": 1993,
        "Last Year": 2004,
        "HLY First Year": 1994,
        "HLY Last Year": 2004,
        "DLY First Year": 1993,
        "DLY Last Year": 2004,
        "MLY First Year": 1999,
        "MLY Last Year": 2000
      },
      {
        "Name": "BURWASH A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100180",
        "Station ID": 50270,
        "WMO ID": null,
        "TC ID": "YDB",
        "Latitude (Decimal Degrees)": 61.37,
        "Longitude (Decimal Degrees)": -139.04,
        "Latitude": 612214000,
        "Longitude": -1390224000,
        "Elevation (m)": 806.2,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BURWASH A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100181",
        "Station ID": 49650,
        "WMO ID": 71001,
        "TC ID": "YDB",
        "Latitude (Decimal Degrees)": 61.37,
        "Longitude (Decimal Degrees)": -139.04,
        "Latitude": 612214000,
        "Longitude": -1390224000,
        "Elevation (m)": 805.3,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BURWASH A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100182",
        "Station ID": 1525,
        "WMO ID": null,
        "TC ID": "YDB",
        "Latitude (Decimal Degrees)": 61.37,
        "Longitude (Decimal Degrees)": -139.05,
        "Latitude": 612200000,
        "Longitude": -1390300000,
        "Elevation (m)": 806.2,
        "First Year": 1966,
        "Last Year": 2015,
        "HLY First Year": 1966,
        "HLY Last Year": 2012,
        "DLY First Year": 1966,
        "DLY Last Year": 2015,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURWASH AIRPORT AUTO",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100184",
        "Station ID": 51718,
        "WMO ID": 71195,
        "TC ID": "VBR",
        "Latitude (Decimal Degrees)": 61.37,
        "Longitude (Decimal Degrees)": -139.02,
        "Latitude": 612200000,
        "Longitude": -1390100000,
        "Elevation (m)": 807,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BURWASH AWOS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100185",
        "Station ID": 31087,
        "WMO ID": null,
        "TC ID": "CBH",
        "Latitude (Decimal Degrees)": 61.37,
        "Longitude (Decimal Degrees)": -139.03,
        "Latitude": 612212030,
        "Longitude": -1390156042,
        "Elevation (m)": 805.3,
        "First Year": 2008,
        "Last Year": 2011,
        "HLY First Year": 2008,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARCROSS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100200",
        "Station ID": 1526,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -134.7,
        "Latitude": 601027600,
        "Longitude": -1344152110,
        "Elevation (m)": 660,
        "First Year": 1907,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 2008,
        "MLY First Year": 1907,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARMACKS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100300",
        "Station ID": 1527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.1,
        "Longitude (Decimal Degrees)": -136.3,
        "Latitude": 620600000,
        "Longitude": -1361800000,
        "Elevation (m)": 524.9,
        "First Year": 1963,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2008,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARMACKS CS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100301",
        "Station ID": 27950,
        "WMO ID": 71039,
        "TC ID": "XCK",
        "Latitude (Decimal Degrees)": 62.12,
        "Longitude (Decimal Degrees)": -136.19,
        "Latitude": 620654000,
        "Longitude": -1361131100,
        "Elevation (m)": 542.9,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARMACKS TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100302",
        "Station ID": 1528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.18,
        "Longitude (Decimal Degrees)": -136.48,
        "Latitude": 621100000,
        "Longitude": -1362900000,
        "Elevation (m)": 1234.4,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "CASINO CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100310",
        "Station ID": 1529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.73,
        "Longitude (Decimal Degrees)": -138.83,
        "Latitude": 624400000,
        "Longitude": -1385000000,
        "Elevation (m)": 1100,
        "First Year": 1969,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1995,
        "MLY First Year": 1969,
        "MLY Last Year": 1995
      },
      {
        "Name": "CASINO MTN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100315",
        "Station ID": 1530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.73,
        "Longitude (Decimal Degrees)": -138.83,
        "Latitude": 624400000,
        "Longitude": -1385000000,
        "Elevation (m)": 1005,
        "First Year": 1985,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1985,
        "MLY First Year": 1985,
        "MLY Last Year": 1985
      },
      {
        "Name": "CLINTON CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100365",
        "Station ID": 1531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.42,
        "Longitude (Decimal Degrees)": -140.67,
        "Latitude": 642500000,
        "Longitude": -1404000000,
        "Elevation (m)": 335.3,
        "First Year": 1964,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1966,
        "MLY First Year": 1964,
        "MLY Last Year": 1966
      },
      {
        "Name": "CLINTON CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100366",
        "Station ID": 1532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.47,
        "Longitude (Decimal Degrees)": -140.73,
        "Latitude": 642800000,
        "Longitude": -1404400000,
        "Elevation (m)": 576.1,
        "First Year": 1966,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1978,
        "MLY First Year": 1966,
        "MLY Last Year": 1978
      },
      {
        "Name": "DAWSON",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100398",
        "Station ID": 1533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.07,
        "Longitude (Decimal Degrees)": -139.33,
        "Latitude": 640400000,
        "Longitude": -1392000000,
        "Elevation (m)": null,
        "First Year": 1898,
        "Last Year": 1901,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 1901,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAWSON",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100400",
        "Station ID": 1534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.05,
        "Longitude (Decimal Degrees)": -139.43,
        "Latitude": 640300000,
        "Longitude": -1392600000,
        "Elevation (m)": 320,
        "First Year": 1897,
        "Last Year": 1979,
        "HLY First Year": 1953,
        "HLY Last Year": 1976,
        "DLY First Year": 1897,
        "DLY Last Year": 1979,
        "MLY First Year": 1897,
        "MLY Last Year": 1979
      },
      {
        "Name": "DAWSON CITY A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100401",
        "Station ID": 51421,
        "WMO ID": null,
        "TC ID": "YDA",
        "Latitude (Decimal Degrees)": 64.04,
        "Longitude (Decimal Degrees)": -139.13,
        "Latitude": 640232000,
        "Longitude": -1390749000,
        "Elevation (m)": 370.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAWSON A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100402",
        "Station ID": 1535,
        "WMO ID": null,
        "TC ID": "YDA",
        "Latitude (Decimal Degrees)": 64.04,
        "Longitude (Decimal Degrees)": -139.13,
        "Latitude": 640235000,
        "Longitude": -1390740000,
        "Elevation (m)": 370.33,
        "First Year": 1976,
        "Last Year": 2015,
        "HLY First Year": 1976,
        "HLY Last Year": 2013,
        "DLY First Year": 1976,
        "DLY Last Year": 2015,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAWSON TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100405",
        "Station ID": 1536,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.07,
        "Longitude (Decimal Degrees)": -139.33,
        "Latitude": 640400000,
        "Longitude": -1392000000,
        "Elevation (m)": 1066.8,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "DAWSON CITY A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100407",
        "Station ID": 53022,
        "WMO ID": null,
        "TC ID": "YDA",
        "Latitude (Decimal Degrees)": 64.04,
        "Longitude (Decimal Degrees)": -139.13,
        "Latitude": 640232000,
        "Longitude": -1390749000,
        "Elevation (m)": 370.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEMPSTER 177",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100412",
        "Station ID": 1537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.88,
        "Longitude (Decimal Degrees)": -137.55,
        "Latitude": 655300000,
        "Longitude": -1373300000,
        "Elevation (m)": 722.4,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "DEMPSTER 179",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100413",
        "Station ID": 1538,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.92,
        "Longitude (Decimal Degrees)": -137.52,
        "Latitude": 655500000,
        "Longitude": -1373100000,
        "Elevation (m)": 685.8,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "DEMPSTER 203",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100415",
        "Station ID": 1539,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.12,
        "Longitude (Decimal Degrees)": -137.25,
        "Latitude": 660700000,
        "Longitude": -1371500000,
        "Elevation (m)": 615.7,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "DESTRUCTION BAY",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100418",
        "Station ID": 1540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.25,
        "Longitude (Decimal Degrees)": -138.78,
        "Latitude": 611500000,
        "Longitude": -1384700000,
        "Elevation (m)": 807.7,
        "First Year": 1975,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1984,
        "MLY First Year": 1975,
        "MLY Last Year": 1984
      },
      {
        "Name": "DEZADEASH",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100430",
        "Station ID": 1541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.37,
        "Longitude (Decimal Degrees)": -137.05,
        "Latitude": 602200000,
        "Longitude": -1370300000,
        "Elevation (m)": 723.9,
        "First Year": 1974,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1986,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "DRURY CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100460",
        "Station ID": 1542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.2,
        "Longitude (Decimal Degrees)": -134.39,
        "Latitude": 621207000,
        "Longitude": -1342324000,
        "Elevation (m)": 609,
        "First Year": 1970,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2009,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "EAGLE PLAINS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100468",
        "Station ID": 1543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.37,
        "Longitude (Decimal Degrees)": -136.72,
        "Latitude": 662210005,
        "Longitude": -1364303580,
        "Elevation (m)": 620,
        "First Year": 1979,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2008,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "EAGLE RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100470",
        "Station ID": 1544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.45,
        "Longitude (Decimal Degrees)": -136.72,
        "Latitude": 662700000,
        "Longitude": -1364300000,
        "Elevation (m)": 324.6,
        "First Year": 1971,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1977,
        "MLY First Year": 1971,
        "MLY Last Year": 1977
      },
      {
        "Name": "ELSA",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100500",
        "Station ID": 1545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.92,
        "Longitude (Decimal Degrees)": -135.48,
        "Latitude": 635500000,
        "Longitude": -1352900000,
        "Elevation (m)": 813.8,
        "First Year": 1948,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1989,
        "MLY First Year": 1948,
        "MLY Last Year": 1989
      },
      {
        "Name": "FARO",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100515",
        "Station ID": 1546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.35,
        "Longitude (Decimal Degrees)": -133.4,
        "Latitude": 622100000,
        "Longitude": -1332400000,
        "Elevation (m)": 1074.4,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "FARO",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100516",
        "Station ID": 1547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.23,
        "Longitude (Decimal Degrees)": -133.35,
        "Latitude": 621400000,
        "Longitude": -1332100000,
        "Elevation (m)": 694.3,
        "First Year": 1971,
        "Last Year": 1977,
        "HLY First Year": 1972,
        "HLY Last Year": 1977,
        "DLY First Year": 1971,
        "DLY Last Year": 1977,
        "MLY First Year": 1971,
        "MLY Last Year": 1977
      },
      {
        "Name": "FARO A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100517",
        "Station ID": 1548,
        "WMO ID": null,
        "TC ID": "ZFA",
        "Latitude (Decimal Degrees)": 62.21,
        "Longitude (Decimal Degrees)": -133.37,
        "Latitude": 621225000,
        "Longitude": -1332224000,
        "Elevation (m)": 716.3,
        "First Year": 1977,
        "Last Year": 2015,
        "HLY First Year": 1987,
        "HLY Last Year": 2013,
        "DLY First Year": 1977,
        "DLY Last Year": 2015,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "FARO (AUT)",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100518",
        "Station ID": 8964,
        "WMO ID": 71949,
        "TC ID": "WUM",
        "Latitude (Decimal Degrees)": 62.21,
        "Longitude (Decimal Degrees)": -133.38,
        "Latitude": 621227000,
        "Longitude": -1332233000,
        "Elevation (m)": 716.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "FARO A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100519",
        "Station ID": 51887,
        "WMO ID": null,
        "TC ID": "ZFA",
        "Latitude (Decimal Degrees)": 62.21,
        "Longitude (Decimal Degrees)": -133.37,
        "Latitude": 621225000,
        "Longitude": -1332224000,
        "Elevation (m)": 716.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FARO A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100527",
        "Station ID": 53025,
        "WMO ID": null,
        "TC ID": "ZFA",
        "Latitude (Decimal Degrees)": 62.21,
        "Longitude (Decimal Degrees)": -133.37,
        "Latitude": 621225000,
        "Longitude": -1332224000,
        "Elevation (m)": 716.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FISH LAKE ROAD",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100535",
        "Station ID": 1549,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.72,
        "Longitude (Decimal Degrees)": -135.2,
        "Latitude": 604300000,
        "Longitude": -1351200000,
        "Elevation (m)": 975,
        "First Year": 1988,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "FORTY MILE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100605",
        "Station ID": 1551,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.38,
        "Longitude (Decimal Degrees)": -140.6,
        "Latitude": 642300000,
        "Longitude": -1403600000,
        "Elevation (m)": 335.3,
        "First Year": 1978,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1984,
        "MLY First Year": 1978,
        "MLY Last Year": 1984
      },
      {
        "Name": "FRANCES LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100611",
        "Station ID": 1552,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.33,
        "Longitude (Decimal Degrees)": -129.33,
        "Latitude": 612000000,
        "Longitude": -1292000000,
        "Elevation (m)": 780.3,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "GOLDEN HORN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100615",
        "Station ID": 1553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.62,
        "Longitude (Decimal Degrees)": -134.82,
        "Latitude": 603700000,
        "Longitude": -1344900000,
        "Elevation (m)": 701,
        "First Year": 1989,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1994,
        "MLY First Year": 1989,
        "MLY Last Year": 1994
      },
      {
        "Name": "HAECKEL HILL TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100620",
        "Station ID": 1554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.75,
        "Longitude (Decimal Degrees)": -135.22,
        "Latitude": 604500000,
        "Longitude": -1351300000,
        "Elevation (m)": 1432.6,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "HAINES APPS #4",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100627",
        "Station ID": 1555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.82,
        "Longitude (Decimal Degrees)": -137.73,
        "Latitude": 604900000,
        "Longitude": -1374400000,
        "Elevation (m)": 845.8,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "HAINES JUNCTION",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100630",
        "Station ID": 1556,
        "WMO ID": 71505,
        "TC ID": "WHT",
        "Latitude (Decimal Degrees)": 60.77,
        "Longitude (Decimal Degrees)": -137.58,
        "Latitude": 604621008,
        "Longitude": -1373449004,
        "Elevation (m)": 595.3,
        "First Year": 1944,
        "Last Year": 2022,
        "HLY First Year": 1960,
        "HLY Last Year": 2022,
        "DLY First Year": 1944,
        "DLY Last Year": 2022,
        "MLY First Year": 1944,
        "MLY Last Year": 2006
      },
      {
        "Name": "HAINES JUNCTION YTG",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100631",
        "Station ID": 1557,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.75,
        "Longitude (Decimal Degrees)": -137.51,
        "Latitude": 604458360,
        "Longitude": -1373018900,
        "Elevation (m)": 596,
        "First Year": 1985,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2008,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "HART RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100632",
        "Station ID": 1558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.65,
        "Longitude (Decimal Degrees)": -136.78,
        "Latitude": 643900000,
        "Longitude": -1364700000,
        "Elevation (m)": 914.4,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "HERSCHEL ISLAND",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100635",
        "Station ID": 1559,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.5,
        "Longitude (Decimal Degrees)": -139.25,
        "Latitude": 693000000,
        "Longitude": -1391500000,
        "Elevation (m)": 4.6,
        "First Year": 1896,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1918,
        "MLY First Year": 1899,
        "MLY Last Year": 1905
      },
      {
        "Name": "HERSCHEL ISLAND",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100636",
        "Station ID": 1560,
        "WMO ID": 71501,
        "TC ID": "WJN",
        "Latitude (Decimal Degrees)": 69.57,
        "Longitude (Decimal Degrees)": -138.91,
        "Latitude": 693405500,
        "Longitude": -1385448200,
        "Elevation (m)": 1.2,
        "First Year": 1974,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1974,
        "DLY Last Year": 2022,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOWARDS PASS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100638",
        "Station ID": 1561,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.47,
        "Longitude (Decimal Degrees)": -129.2,
        "Latitude": 622800000,
        "Longitude": -1291200000,
        "Elevation (m)": 1563.6,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "HUDSON BAY MINE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100639",
        "Station ID": 1562,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.18,
        "Longitude (Decimal Degrees)": -130.17,
        "Latitude": 631100000,
        "Longitude": -1301000000,
        "Elevation (m)": 1493.5,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "IRON CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100650",
        "Station ID": 1563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.02,
        "Longitude (Decimal Degrees)": -127.85,
        "Latitude": 600100000,
        "Longitude": -1275100000,
        "Elevation (m)": 868.7,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "IVVAVIK NAT. PARK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100660",
        "Station ID": 26869,
        "WMO ID": 71978,
        "TC ID": "WOI",
        "Latitude (Decimal Degrees)": 69.16,
        "Longitude (Decimal Degrees)": -140.15,
        "Latitude": 690952000,
        "Longitude": -1400900000,
        "Elevation (m)": 243.8,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "JOHNSONS CROSSING",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100670",
        "Station ID": 1564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.48,
        "Longitude (Decimal Degrees)": -133.3,
        "Latitude": 602900000,
        "Longitude": -1331800000,
        "Elevation (m)": 690.4,
        "First Year": 1963,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1995,
        "MLY First Year": 1963,
        "MLY Last Year": 1995
      },
      {
        "Name": "KENO HILL",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100677",
        "Station ID": 1565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.93,
        "Longitude (Decimal Degrees)": -135.2,
        "Latitude": 635600000,
        "Longitude": -1351200000,
        "Elevation (m)": 1472.8,
        "First Year": 1974,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1982,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "KING POINT (AUT)",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100678",
        "Station ID": 8994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.08,
        "Longitude (Decimal Degrees)": -138.02,
        "Latitude": 690500000,
        "Longitude": -1380100000,
        "Elevation (m)": 61,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": 1985,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KLONDIKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100679",
        "Station ID": 1566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.45,
        "Longitude (Decimal Degrees)": -138.22,
        "Latitude": 642714000,
        "Longitude": -1381256000,
        "Elevation (m)": 972.6,
        "First Year": 1966,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2010,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "KLUANE LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100680",
        "Station ID": 1567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.02,
        "Longitude (Decimal Degrees)": -138.4,
        "Latitude": 610100000,
        "Longitude": -1382400000,
        "Elevation (m)": 786.4,
        "First Year": 1946,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1983,
        "MLY First Year": 1970,
        "MLY Last Year": 1983
      },
      {
        "Name": "KOMAKUK BEACH",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100682",
        "Station ID": 10822,
        "WMO ID": 71046,
        "TC ID": "WKM",
        "Latitude (Decimal Degrees)": 69.61,
        "Longitude (Decimal Degrees)": -140.2,
        "Latitude": 693643000,
        "Longitude": -1401149000,
        "Elevation (m)": 13.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KOMAKUK BEACH A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100685",
        "Station ID": 1568,
        "WMO ID": 71969,
        "TC ID": "YAJ",
        "Latitude (Decimal Degrees)": 69.58,
        "Longitude (Decimal Degrees)": -140.18,
        "Latitude": 693500000,
        "Longitude": -1401100000,
        "Elevation (m)": 7.3,
        "First Year": 1958,
        "Last Year": 1993,
        "HLY First Year": 1973,
        "HLY Last Year": 1993,
        "DLY First Year": 1958,
        "DLY Last Year": 1993,
        "MLY First Year": 1958,
        "MLY Last Year": 1993
      },
      {
        "Name": "LITTLE GOLD CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100690",
        "Station ID": 1569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.08,
        "Longitude (Decimal Degrees)": -141,
        "Latitude": 640500000,
        "Longitude": -1410000000,
        "Elevation (m)": 1257.3,
        "First Year": 1974,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1999,
        "MLY First Year": 1974,
        "MLY Last Year": 1999
      },
      {
        "Name": "MACMILLAN PASS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100693",
        "Station ID": 27339,
        "WMO ID": 71990,
        "TC ID": "WNV",
        "Latitude (Decimal Degrees)": 63.24,
        "Longitude (Decimal Degrees)": -130.04,
        "Latitude": 631437026,
        "Longitude": -1300214028,
        "Elevation (m)": 1379,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "MACMILLAN PASS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100695",
        "Station ID": 1570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.17,
        "Longitude (Decimal Degrees)": -130.2,
        "Latitude": 631000000,
        "Longitude": -1301200000,
        "Elevation (m)": 1408.8,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "MACTUNG",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100696",
        "Station ID": 1571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.28,
        "Longitude (Decimal Degrees)": -130.18,
        "Latitude": 631700000,
        "Longitude": -1301100000,
        "Elevation (m)": 1889.8,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "MARGARET LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100697",
        "Station ID": 27296,
        "WMO ID": 71977,
        "TC ID": "WZR",
        "Latitude (Decimal Degrees)": 68.8,
        "Longitude (Decimal Degrees)": -140.85,
        "Latitude": 684802000,
        "Longitude": -1405051000,
        "Elevation (m)": 568,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "MARSH LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100698",
        "Station ID": 26998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.43,
        "Longitude (Decimal Degrees)": -134.25,
        "Latitude": 602600000,
        "Longitude": -1341500000,
        "Elevation (m)": 655,
        "First Year": 1994,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2002,
        "MLY First Year": 1994,
        "MLY Last Year": 2002
      },
      {
        "Name": "MACPASS AIRSTRIP",
        "Province": "YUKON TERRITORY",
        "Climate ID": "21006RN",
        "Station ID": 1550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.18,
        "Longitude (Decimal Degrees)": -130.18,
        "Latitude": 631100000,
        "Longitude": -1301100000,
        "Elevation (m)": 1219.2,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "MAYO A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100700",
        "Station ID": 1572,
        "WMO ID": 71965,
        "TC ID": "YMA",
        "Latitude (Decimal Degrees)": 63.62,
        "Longitude (Decimal Degrees)": -135.87,
        "Latitude": 633700000,
        "Longitude": -1355200000,
        "Elevation (m)": 503.8,
        "First Year": 1924,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1924,
        "DLY Last Year": 2013,
        "MLY First Year": 1925,
        "MLY Last Year": 2013
      },
      {
        "Name": "MAYO A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100701",
        "Station ID": 51426,
        "WMO ID": 71965,
        "TC ID": "YMA",
        "Latitude (Decimal Degrees)": 63.62,
        "Longitude (Decimal Degrees)": -135.87,
        "Latitude": 633659000,
        "Longitude": -1355206000,
        "Elevation (m)": 503.8,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MAYO A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100702",
        "Station ID": 54178,
        "WMO ID": null,
        "TC ID": "YMA",
        "Latitude (Decimal Degrees)": 63.62,
        "Longitude (Decimal Degrees)": -135.87,
        "Latitude": 633700000,
        "Longitude": -1355208000,
        "Elevation (m)": 503.8,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MAYO ROAD",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100709",
        "Station ID": 1573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.88,
        "Longitude (Decimal Degrees)": -135.18,
        "Latitude": 605235000,
        "Longitude": -1351058000,
        "Elevation (m)": 655,
        "First Year": 1983,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2017,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "MCQUESTEN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100719",
        "Station ID": 1574,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.59,
        "Longitude (Decimal Degrees)": -137.52,
        "Latitude": 633539000,
        "Longitude": -1373121000,
        "Elevation (m)": 458.2,
        "First Year": 1986,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2014,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "MCQUESTEN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100720",
        "Station ID": 1575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.55,
        "Longitude (Decimal Degrees)": -137.4,
        "Latitude": 633300000,
        "Longitude": -1372400000,
        "Elevation (m)": 426.7,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "MINTO",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100744",
        "Station ID": 1576,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.63,
        "Longitude (Decimal Degrees)": -137.25,
        "Latitude": 623800000,
        "Longitude": -1371500000,
        "Elevation (m)": 914.4,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "MOOSE CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100746",
        "Station ID": 1577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.52,
        "Longitude (Decimal Degrees)": -137.02,
        "Latitude": 633100000,
        "Longitude": -1370100000,
        "Elevation (m)": 460.2,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "MORLEY RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100750",
        "Station ID": 1578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.02,
        "Longitude (Decimal Degrees)": -132.17,
        "Latitude": 600100000,
        "Longitude": -1321000000,
        "Elevation (m)": 823,
        "First Year": 1984,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1989,
        "MLY First Year": 1984,
        "MLY Last Year": 1989
      },
      {
        "Name": "MOUNTAIN VIEW LODGE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100755",
        "Station ID": 1579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.63,
        "Longitude (Decimal Degrees)": -139.67,
        "Latitude": 613800000,
        "Longitude": -1394000000,
        "Elevation (m)": 845.8,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "NEW IMPERIAL",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100765",
        "Station ID": 1580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.63,
        "Longitude (Decimal Degrees)": -135.08,
        "Latitude": 603800000,
        "Longitude": -1350500000,
        "Elevation (m)": 800.1,
        "First Year": 1968,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1969,
        "MLY First Year": 1968,
        "MLY Last Year": 1969
      },
      {
        "Name": "OGILVIE RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100794",
        "Station ID": 1581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.36,
        "Longitude (Decimal Degrees)": -138.31,
        "Latitude": 652137000,
        "Longitude": -1381819000,
        "Elevation (m)": 597.4,
        "First Year": 1971,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2008,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLD CROW A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100800",
        "Station ID": 1582,
        "WMO ID": null,
        "TC ID": "YOC",
        "Latitude (Decimal Degrees)": 67.57,
        "Longitude (Decimal Degrees)": -139.84,
        "Latitude": 673414000,
        "Longitude": -1395021000,
        "Elevation (m)": 250.24,
        "First Year": 1951,
        "Last Year": 2015,
        "HLY First Year": 1975,
        "HLY Last Year": 2013,
        "DLY First Year": 1951,
        "DLY Last Year": 2015,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLD CROW A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100801",
        "Station ID": 51437,
        "WMO ID": null,
        "TC ID": "YOC",
        "Latitude (Decimal Degrees)": 67.57,
        "Longitude (Decimal Degrees)": -139.84,
        "Latitude": 673412000,
        "Longitude": -1395024000,
        "Elevation (m)": 250.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OLD CROW RCS",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100805",
        "Station ID": 26894,
        "WMO ID": 71044,
        "TC ID": "ZOC",
        "Latitude (Decimal Degrees)": 67.57,
        "Longitude (Decimal Degrees)": -139.84,
        "Latitude": 673414000,
        "Longitude": -1395021000,
        "Elevation (m)": 251.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLD CROW A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100807",
        "Station ID": 53023,
        "WMO ID": null,
        "TC ID": "YOC",
        "Latitude (Decimal Degrees)": 67.57,
        "Longitude (Decimal Degrees)": -139.84,
        "Latitude": 673412000,
        "Longitude": -1395024000,
        "Elevation (m)": 250.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OTTER FALLS NCPC",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100840",
        "Station ID": 1583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.03,
        "Longitude (Decimal Degrees)": -137.05,
        "Latitude": 610200000,
        "Longitude": -1370300000,
        "Elevation (m)": 829.7,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "PAINT MOUNTAIN TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100850",
        "Station ID": 1584,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.83,
        "Longitude (Decimal Degrees)": -137.5,
        "Latitude": 605000000,
        "Longitude": -1373000000,
        "Elevation (m)": 1386.8,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "PARKIN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100860",
        "Station ID": 1585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.23,
        "Longitude (Decimal Degrees)": -137.28,
        "Latitude": 661400000,
        "Longitude": -1371700000,
        "Elevation (m)": 533.4,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "PELLY RANCH",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100880",
        "Station ID": 1586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.83,
        "Longitude (Decimal Degrees)": -137.31,
        "Latitude": 624948009,
        "Longitude": -1371839005,
        "Elevation (m)": 445,
        "First Year": 1898,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 2017,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "PELLY RANCH FORT SELKIRK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100881",
        "Station ID": 54601,
        "WMO ID": 73013,
        "TC ID": "CPY",
        "Latitude (Decimal Degrees)": 62.82,
        "Longitude (Decimal Degrees)": -137.37,
        "Latitude": 624900000,
        "Longitude": -1372200000,
        "Elevation (m)": 454.2,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PORTER CREEK WAHL",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100907",
        "Station ID": 1587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.77,
        "Longitude (Decimal Degrees)": -135.12,
        "Latitude": 604600000,
        "Longitude": -1350700000,
        "Elevation (m)": 713,
        "First Year": 1989,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2005,
        "MLY First Year": 1989,
        "MLY Last Year": 2005
      },
      {
        "Name": "QUIET LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100910",
        "Station ID": 1588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.15,
        "Longitude (Decimal Degrees)": -133.07,
        "Latitude": 610900000,
        "Longitude": -1330400000,
        "Elevation (m)": 820,
        "First Year": 1966,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1992,
        "MLY First Year": 1966,
        "MLY Last Year": 1992
      },
      {
        "Name": "QUILL CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100914",
        "Station ID": 1589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.45,
        "Longitude (Decimal Degrees)": -139.25,
        "Latitude": 612700000,
        "Longitude": -1391500000,
        "Elevation (m)": 831,
        "First Year": 1983,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1985,
        "MLY First Year": 1983,
        "MLY Last Year": 1985
      },
      {
        "Name": "RAMPART HOUSE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100920",
        "Station ID": 1590,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": null,
        "Longitude (Decimal Degrees)": null,
        "Latitude": null,
        "Longitude": null,
        "Elevation (m)": null,
        "First Year": 1918,
        "Last Year": 1919,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1919,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RANCHERIA",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100932",
        "Station ID": 1591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.08,
        "Longitude (Decimal Degrees)": -130.6,
        "Latitude": 600500000,
        "Longitude": -1303600000,
        "Elevation (m)": 865.6,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "ROCK RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100935",
        "Station ID": 8979,
        "WMO ID": 71506,
        "TC ID": "WRR",
        "Latitude (Decimal Degrees)": 66.98,
        "Longitude (Decimal Degrees)": -136.22,
        "Latitude": 665852000,
        "Longitude": -1361305000,
        "Elevation (m)": 731,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROSS RIVER A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100940",
        "Station ID": 1592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.97,
        "Longitude (Decimal Degrees)": -132.43,
        "Latitude": 615800000,
        "Longitude": -1322600000,
        "Elevation (m)": 705.3,
        "First Year": 1961,
        "Last Year": 1994,
        "HLY First Year": 1987,
        "HLY Last Year": 1994,
        "DLY First Year": 1961,
        "DLY Last Year": 1994,
        "MLY First Year": 1964,
        "MLY Last Year": 1994
      },
      {
        "Name": "ROSS RIVER YTG",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100941",
        "Station ID": 6849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.98,
        "Longitude (Decimal Degrees)": -132.45,
        "Latitude": 615900000,
        "Longitude": -1322700000,
        "Elevation (m)": 698,
        "First Year": 1993,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2008,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "RUSSELL CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100942",
        "Station ID": 1593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.03,
        "Longitude (Decimal Degrees)": -133.38,
        "Latitude": 630200000,
        "Longitude": -1332300000,
        "Elevation (m)": 686,
        "First Year": 1989,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1993,
        "MLY First Year": 1989,
        "MLY Last Year": 1993
      },
      {
        "Name": "SAM LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100945",
        "Station ID": 1594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.42,
        "Longitude (Decimal Degrees)": -138.62,
        "Latitude": 682500000,
        "Longitude": -1383700000,
        "Elevation (m)": 457.2,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "SATAH RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100946",
        "Station ID": 26867,
        "WMO ID": null,
        "TC ID": "WNO",
        "Latitude (Decimal Degrees)": 66.87,
        "Longitude (Decimal Degrees)": -134.2,
        "Latitude": 665200000,
        "Longitude": -1341200000,
        "Elevation (m)": 86,
        "First Year": 1995,
        "Last Year": 2001,
        "HLY First Year": 1995,
        "HLY Last Year": 2001,
        "DLY First Year": 1995,
        "DLY Last Year": 2001,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SHELDON LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100948",
        "Station ID": 1595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.62,
        "Longitude (Decimal Degrees)": -131.28,
        "Latitude": 623700000,
        "Longitude": -1311700000,
        "Elevation (m)": 883.9,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "SHINGLE POINT A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100950",
        "Station ID": 1596,
        "WMO ID": 71968,
        "TC ID": "YUA",
        "Latitude (Decimal Degrees)": 68.95,
        "Longitude (Decimal Degrees)": -137.22,
        "Latitude": 685700000,
        "Longitude": -1371300000,
        "Elevation (m)": 49.4,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1973,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "SIMPSON TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100960",
        "Station ID": 1597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.4,
        "Longitude (Decimal Degrees)": -129.47,
        "Latitude": 612400000,
        "Longitude": -1292800000,
        "Elevation (m)": 1676.4,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOUR LAKE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100FCG",
        "Station ID": 1511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.18,
        "Longitude (Decimal Degrees)": -129.13,
        "Latitude": 611054000,
        "Longitude": -1290754000,
        "Elevation (m)": 890,
        "First Year": 1982,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2017,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "KETZA RIVER MINE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100FPP",
        "Station ID": 1512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.52,
        "Longitude (Decimal Degrees)": -132.27,
        "Latitude": 613100000,
        "Longitude": -1321600000,
        "Elevation (m)": 1380,
        "First Year": 1985,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1995,
        "MLY First Year": 1986,
        "MLY Last Year": 1995
      },
      {
        "Name": "MACPHERSON",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100FRN",
        "Station ID": 1513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.82,
        "Longitude (Decimal Degrees)": -135.22,
        "Latitude": 604900000,
        "Longitude": -1351300000,
        "Elevation (m)": 686.1,
        "First Year": 1984,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1988,
        "MLY First Year": 1984,
        "MLY Last Year": 1988
      },
      {
        "Name": "DAWSON",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100LRP",
        "Station ID": 10194,
        "WMO ID": 71966,
        "TC ID": "WON",
        "Latitude (Decimal Degrees)": 64.06,
        "Longitude (Decimal Degrees)": -139.13,
        "Latitude": 640339076,
        "Longitude": -1390736000,
        "Elevation (m)": 370,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "MIDWAY LODGE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2100PLF",
        "Station ID": 1514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.53,
        "Longitude (Decimal Degrees)": -136.75,
        "Latitude": 623200000,
        "Longitude": -1364500000,
        "Elevation (m)": 610,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "SNAG A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101000",
        "Station ID": 1598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.37,
        "Longitude (Decimal Degrees)": -140.4,
        "Latitude": 622200000,
        "Longitude": -1402400000,
        "Elevation (m)": 586.7,
        "First Year": 1943,
        "Last Year": 1966,
        "HLY First Year": 1953,
        "HLY Last Year": 1966,
        "DLY First Year": 1943,
        "DLY Last Year": 1966,
        "MLY First Year": 1943,
        "MLY Last Year": 1966
      },
      {
        "Name": "STEWART CROSSING",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101030",
        "Station ID": 1599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.38,
        "Longitude (Decimal Degrees)": -136.68,
        "Latitude": 632300000,
        "Longitude": -1364100000,
        "Elevation (m)": 480.1,
        "First Year": 1963,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2008,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "STEWART CROSSING TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101031",
        "Station ID": 1600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.42,
        "Longitude (Decimal Degrees)": -136.68,
        "Latitude": 632500000,
        "Longitude": -1364100000,
        "Elevation (m)": 1249.7,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STEWART RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101033",
        "Station ID": 1601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.32,
        "Longitude (Decimal Degrees)": -139.43,
        "Latitude": 631900000,
        "Longitude": -1392600000,
        "Elevation (m)": 358.1,
        "First Year": 1976,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1993,
        "MLY First Year": 1976,
        "MLY Last Year": 1993
      },
      {
        "Name": "STOKES POINT",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101050",
        "Station ID": 1602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.35,
        "Longitude (Decimal Degrees)": -138.77,
        "Latitude": 692049000,
        "Longitude": -1384613000,
        "Elevation (m)": 34.7,
        "First Year": 1959,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1959,
        "DLY Last Year": 1993,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "SUNNYDALE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101062",
        "Station ID": 1603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.05,
        "Longitude (Decimal Degrees)": -139.47,
        "Latitude": 640300000,
        "Longitude": -1392800000,
        "Elevation (m)": 335,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "SWEDE CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101070",
        "Station ID": 1604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.1,
        "Longitude (Decimal Degrees)": -139.75,
        "Latitude": 640600000,
        "Longitude": -1394500000,
        "Elevation (m)": 320,
        "First Year": 1918,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1929,
        "MLY First Year": 1918,
        "MLY Last Year": 1929
      },
      {
        "Name": "SWIFT RIVER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101081",
        "Station ID": 1605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60,
        "Longitude (Decimal Degrees)": -131.18,
        "Latitude": 600000000,
        "Longitude": -1311100000,
        "Elevation (m)": 891.2,
        "First Year": 1966,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2008,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "TAGISH",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101089",
        "Station ID": 1606,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.3,
        "Longitude (Decimal Degrees)": -134.27,
        "Latitude": 601800000,
        "Longitude": -1341600000,
        "Elevation (m)": 658.4,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "TAGISH TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101093",
        "Station ID": 1607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.27,
        "Longitude (Decimal Degrees)": -134.18,
        "Latitude": 601600000,
        "Longitude": -1341100000,
        "Elevation (m)": 1219.2,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "TAKHINI RIVER RANCH",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101095",
        "Station ID": 1608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.95,
        "Longitude (Decimal Degrees)": -135.57,
        "Latitude": 605645000,
        "Longitude": -1353423000,
        "Elevation (m)": 671,
        "First Year": 1980,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2021,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "TESLIN",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101099",
        "Station ID": 1609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -132.75,
        "Latitude": 601000000,
        "Longitude": -1324500000,
        "Elevation (m)": 705,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "TESLIN A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101100",
        "Station ID": 1610,
        "WMO ID": null,
        "TC ID": "YZW",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -132.74,
        "Latitude": 601026900,
        "Longitude": -1324409200,
        "Elevation (m)": 705,
        "First Year": 1943,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1943,
        "DLY Last Year": 2014,
        "MLY First Year": 1943,
        "MLY Last Year": 2006
      },
      {
        "Name": "TESLIN A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101101",
        "Station ID": 52321,
        "WMO ID": null,
        "TC ID": "YZW",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -132.74,
        "Latitude": 601023000,
        "Longitude": -1324430000,
        "Elevation (m)": 705,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TESLIN (AUT)",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101102",
        "Station ID": 8985,
        "WMO ID": 71045,
        "TC ID": "WZW",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -132.73,
        "Latitude": 601000000,
        "Longitude": -1324400000,
        "Elevation (m)": 705,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "TESLIN A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101106",
        "Station ID": 53027,
        "WMO ID": null,
        "TC ID": "YZW",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -132.74,
        "Latitude": 601023000,
        "Longitude": -1324430000,
        "Elevation (m)": 705,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TOM CREEK TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101125",
        "Station ID": 1611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.28,
        "Longitude (Decimal Degrees)": -128.98,
        "Latitude": 601700000,
        "Longitude": -1285900000,
        "Elevation (m)": 944.9,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "TRANSPORT TOWER",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101130",
        "Station ID": 1612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.17,
        "Longitude (Decimal Degrees)": -130,
        "Latitude": 601000000,
        "Longitude": -1300000000,
        "Elevation (m)": 1432.6,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "TUCHITUA",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101135",
        "Station ID": 1613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.93,
        "Longitude (Decimal Degrees)": -129.22,
        "Latitude": 605600000,
        "Longitude": -1291300000,
        "Elevation (m)": 723.9,
        "First Year": 1967,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2014,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "TWO PETE CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101138",
        "Station ID": 1614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.63,
        "Longitude (Decimal Degrees)": -133.7,
        "Latitude": 623800000,
        "Longitude": -1334200000,
        "Elevation (m)": 960.1,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "WATSON LAKE A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101200",
        "Station ID": 1615,
        "WMO ID": 71953,
        "TC ID": "YQH",
        "Latitude (Decimal Degrees)": 60.12,
        "Longitude (Decimal Degrees)": -128.82,
        "Latitude": 600659400,
        "Longitude": -1284920400,
        "Elevation (m)": 687.35,
        "First Year": 1938,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1938,
        "DLY Last Year": 2014,
        "MLY First Year": 1938,
        "MLY Last Year": 2014
      },
      {
        "Name": "WATSON LAKE A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101201",
        "Station ID": 53339,
        "WMO ID": null,
        "TC ID": "YQH",
        "Latitude (Decimal Degrees)": 60.12,
        "Longitude (Decimal Degrees)": -128.82,
        "Latitude": 600659000,
        "Longitude": -1284921000,
        "Elevation (m)": 687.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WATSON LAKE A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101202",
        "Station ID": 54198,
        "WMO ID": null,
        "TC ID": "YQH",
        "Latitude (Decimal Degrees)": 60.12,
        "Longitude (Decimal Degrees)": -128.82,
        "Latitude": 600659000,
        "Longitude": -1284921000,
        "Elevation (m)": 687.3,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WATSON LAKE (AUT)",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101204",
        "Station ID": 32293,
        "WMO ID": 71199,
        "TC ID": "XQH",
        "Latitude (Decimal Degrees)": 60.12,
        "Longitude (Decimal Degrees)": -128.83,
        "Latitude": 600657020,
        "Longitude": -1284955020,
        "Elevation (m)": 683.3,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WATSON LAKE YTG",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101222",
        "Station ID": 10983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.07,
        "Longitude (Decimal Degrees)": -128.72,
        "Latitude": 600400000,
        "Longitude": -1284300000,
        "Elevation (m)": 701,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1995,
        "MLY First Year": 1994,
        "MLY Last Year": 1995
      },
      {
        "Name": "WHITEHORSE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101290",
        "Station ID": 1616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.72,
        "Longitude (Decimal Degrees)": -135.05,
        "Latitude": 604300000,
        "Longitude": -1350300000,
        "Elevation (m)": 636.1,
        "First Year": 1900,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 1960,
        "MLY First Year": 1904,
        "MLY Last Year": 1942
      },
      {
        "Name": "WHITEHORSE A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101300",
        "Station ID": 1617,
        "WMO ID": 71964,
        "TC ID": "YXY",
        "Latitude (Decimal Degrees)": 60.71,
        "Longitude (Decimal Degrees)": -135.07,
        "Latitude": 604234200,
        "Longitude": -1350407800,
        "Elevation (m)": 706.2,
        "First Year": 1942,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1942,
        "DLY Last Year": 2012,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITEHORSE A",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101303",
        "Station ID": 50842,
        "WMO ID": 71964,
        "TC ID": "YXY",
        "Latitude (Decimal Degrees)": 60.71,
        "Longitude (Decimal Degrees)": -135.07,
        "Latitude": 604234000,
        "Longitude": -1350402000,
        "Elevation (m)": 706.2,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHITEHORSE AUTO",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101310",
        "Station ID": 48168,
        "WMO ID": 71773,
        "TC ID": "VXY",
        "Latitude (Decimal Degrees)": 60.73,
        "Longitude (Decimal Degrees)": -135.1,
        "Latitude": 604359000,
        "Longitude": -1350552000,
        "Elevation (m)": 707,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHITEHORSE RIVERDALE",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101400",
        "Station ID": 1618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.71,
        "Longitude (Decimal Degrees)": -135.03,
        "Latitude": 604236000,
        "Longitude": -1350138000,
        "Elevation (m)": 640.08,
        "First Year": 1959,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2012,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITEHORSE WSO",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101415",
        "Station ID": 26988,
        "WMO ID": null,
        "TC ID": "WXY",
        "Latitude (Decimal Degrees)": 60.73,
        "Longitude (Decimal Degrees)": -135.08,
        "Latitude": 604400000,
        "Longitude": -1350500000,
        "Elevation (m)": 706.9,
        "First Year": 1996,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1998,
        "MLY First Year": 1996,
        "MLY Last Year": 1998
      },
      {
        "Name": "WOLF CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101600",
        "Station ID": 1619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.58,
        "Longitude (Decimal Degrees)": -134.95,
        "Latitude": 603500000,
        "Longitude": -1345700000,
        "Elevation (m)": 746.8,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1973
      },
      {
        "Name": "WOLF CREEK",
        "Province": "YUKON TERRITORY",
        "Climate ID": "2101601",
        "Station ID": 1620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.5,
        "Longitude (Decimal Degrees)": -134.95,
        "Latitude": 603000000,
        "Longitude": -1345700000,
        "Elevation (m)": 762,
        "First Year": 1985,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1989,
        "MLY First Year": 1985,
        "MLY Last Year": 1989
      },
      {
        "Name": "AKLAVIK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200100",
        "Station ID": 1623,
        "WMO ID": null,
        "TC ID": "YKD",
        "Latitude (Decimal Degrees)": 68.22,
        "Longitude (Decimal Degrees)": -135.01,
        "Latitude": 681324000,
        "Longitude": -1350021000,
        "Elevation (m)": 6.4,
        "First Year": 1926,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1926,
        "DLY Last Year": 2014,
        "MLY First Year": 1926,
        "MLY Last Year": 2007
      },
      {
        "Name": "AKLAVIK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200101",
        "Station ID": 51397,
        "WMO ID": null,
        "TC ID": "YKD",
        "Latitude (Decimal Degrees)": 68.22,
        "Longitude (Decimal Degrees)": -135.01,
        "Latitude": 681324000,
        "Longitude": -1350020000,
        "Elevation (m)": 6.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AKLAVIK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200105",
        "Station ID": 52962,
        "WMO ID": null,
        "TC ID": "YKD",
        "Latitude (Decimal Degrees)": 68.22,
        "Longitude (Decimal Degrees)": -135.01,
        "Latitude": 681324000,
        "Longitude": -1350020000,
        "Elevation (m)": 6.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AKLAVIK RADIOSONDE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200200",
        "Station ID": 1624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.23,
        "Longitude (Decimal Degrees)": -135,
        "Latitude": 681400000,
        "Longitude": -1350000000,
        "Elevation (m)": 9.1,
        "First Year": 1953,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1960,
        "MLY First Year": 1953,
        "MLY Last Year": 1960
      },
      {
        "Name": "ANGUS TOWER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200310",
        "Station ID": 1625,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.35,
        "Longitude (Decimal Degrees)": -114.28,
        "Latitude": 602100000,
        "Longitude": -1141700000,
        "Elevation (m)": 246.9,
        "First Year": 1968,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1990,
        "MLY First Year": 1968,
        "MLY Last Year": 1990
      },
      {
        "Name": "ARCTIC RED RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200412",
        "Station ID": 1626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.45,
        "Longitude (Decimal Degrees)": -133.73,
        "Latitude": 672700000,
        "Longitude": -1334400000,
        "Elevation (m)": 30.5,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "ATKINSON POINT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200430",
        "Station ID": 1627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.93,
        "Longitude (Decimal Degrees)": -131.4,
        "Latitude": 695600000,
        "Longitude": -1312400000,
        "Elevation (m)": 3,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "BEAR TOWER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200554",
        "Station ID": 1629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.52,
        "Longitude (Decimal Degrees)": -113.23,
        "Latitude": 603100000,
        "Longitude": -1131400000,
        "Elevation (m)": 213.4,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "CADILLAC MINE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200597",
        "Station ID": 1631,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.53,
        "Longitude (Decimal Degrees)": -124.75,
        "Latitude": 613200000,
        "Longitude": -1244500000,
        "Elevation (m)": 868.1,
        "First Year": 1970,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1982,
        "MLY First Year": 1970,
        "MLY Last Year": 1982
      },
      {
        "Name": "CAMERON HILLS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200606",
        "Station ID": 1632,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.3,
        "Longitude (Decimal Degrees)": -117.05,
        "Latitude": 601800000,
        "Longitude": -1170300000,
        "Elevation (m)": 841.2,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "CAMSELL RIVER (AUT)",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200617",
        "Station ID": 8932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.62,
        "Longitude (Decimal Degrees)": -118.12,
        "Latitude": 653700000,
        "Longitude": -1180700000,
        "Elevation (m)": 230.4,
        "First Year": 1983,
        "Last Year": 1990,
        "HLY First Year": 1983,
        "HLY Last Year": 1990,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE BATHURST",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200627",
        "Station ID": 10075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 70.55,
        "Longitude (Decimal Degrees)": -127.95,
        "Latitude": 703300000,
        "Longitude": -1275700000,
        "Elevation (m)": 17,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": 1986,
        "HLY Last Year": 1989,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE PARRY A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200675",
        "Station ID": 1633,
        "WMO ID": 71948,
        "TC ID": "ZCP",
        "Latitude (Decimal Degrees)": 70.17,
        "Longitude (Decimal Degrees)": -124.72,
        "Latitude": 701000000,
        "Longitude": -1244300000,
        "Elevation (m)": 86.6,
        "First Year": 1956,
        "Last Year": 2022,
        "HLY First Year": 1956,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLIFTON POINT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200730",
        "Station ID": 1636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.2,
        "Longitude (Decimal Degrees)": -118.63,
        "Latitude": 691200000,
        "Longitude": -1183800000,
        "Elevation (m)": 32.6,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "CLUT LAKE (AUT)",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200777",
        "Station ID": 10896,
        "WMO ID": null,
        "TC ID": "WCX",
        "Latitude (Decimal Degrees)": 65.6,
        "Longitude (Decimal Degrees)": -117.77,
        "Latitude": 653600000,
        "Longitude": -1174600000,
        "Elevation (m)": 185,
        "First Year": 1994,
        "Last Year": 2010,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1994,
        "DLY Last Year": 2010,
        "MLY First Year": 1998,
        "MLY Last Year": 2000
      },
      {
        "Name": "COLOMAC MINE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200822",
        "Station ID": 1638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.42,
        "Longitude (Decimal Degrees)": -115.1,
        "Latitude": 642500000,
        "Longitude": -1150600000,
        "Elevation (m)": 327.1,
        "First Year": 1989,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1991,
        "MLY First Year": 1989,
        "MLY Last Year": 1991
      },
      {
        "Name": "COLVILLE LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200824",
        "Station ID": 10867,
        "WMO ID": 71055,
        "TC ID": "WFX",
        "Latitude (Decimal Degrees)": 67.02,
        "Longitude (Decimal Degrees)": -126.12,
        "Latitude": 670117040,
        "Longitude": -1260723070,
        "Elevation (m)": 271,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "COLVILLE LAKE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2200827",
        "Station ID": 52899,
        "WMO ID": null,
        "TC ID": "YVL",
        "Latitude (Decimal Degrees)": 67.02,
        "Longitude (Decimal Degrees)": -126.13,
        "Latitude": 670116000,
        "Longitude": -1260743000,
        "Elevation (m)": 274,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DELINE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201020",
        "Station ID": 52778,
        "WMO ID": null,
        "TC ID": "YWJ",
        "Latitude (Decimal Degrees)": 65.21,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 651240000,
        "Longitude": -1232611000,
        "Elevation (m)": 214.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DELINE CS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201022",
        "Station ID": 27749,
        "WMO ID": 71503,
        "TC ID": "XDE",
        "Latitude (Decimal Degrees)": 65.21,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 651231090,
        "Longitude": -1232600070,
        "Elevation (m)": 212.8,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "DELINE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201023",
        "Station ID": 52964,
        "WMO ID": null,
        "TC ID": "YWJ",
        "Latitude (Decimal Degrees)": 65.21,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 651240000,
        "Longitude": -1232611000,
        "Elevation (m)": 214.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEADMEN VALLEY",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "22010H0",
        "Station ID": 31187,
        "WMO ID": 71021,
        "TC ID": "XDK",
        "Latitude (Decimal Degrees)": 61.26,
        "Longitude (Decimal Degrees)": -124.47,
        "Latitude": 611524000,
        "Longitude": -1242806000,
        "Elevation (m)": 283,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "DELINE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "22010KA",
        "Station ID": 6850,
        "WMO ID": null,
        "TC ID": "YWJ",
        "Latitude (Decimal Degrees)": 65.21,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 651240000,
        "Longitude": -1232611000,
        "Elevation (m)": 214.27,
        "First Year": 1991,
        "Last Year": 2014,
        "HLY First Year": 1991,
        "HLY Last Year": 2014,
        "DLY First Year": 1991,
        "DLY Last Year": 2014,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "DIAVIK DIAMOND MINE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201100",
        "Station ID": 54678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.49,
        "Longitude (Decimal Degrees)": -110.35,
        "Latitude": 642934108,
        "Longitude": -1102051860,
        "Elevation (m)": 447,
        "First Year": 2020,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2020,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FLAT RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201345",
        "Station ID": 1642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.97,
        "Longitude (Decimal Degrees)": -128.2,
        "Latitude": 615800000,
        "Longitude": -1281200000,
        "Elevation (m)": 1115.6,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "FORT FRANKLIN",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201380",
        "Station ID": 1643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.18,
        "Longitude (Decimal Degrees)": -123.43,
        "Latitude": 651100000,
        "Longitude": -1232600000,
        "Elevation (m)": 159.4,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "FORT GOOD HOPE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201400",
        "Station ID": 1644,
        "WMO ID": null,
        "TC ID": "YGH",
        "Latitude (Decimal Degrees)": 66.24,
        "Longitude (Decimal Degrees)": -128.65,
        "Latitude": 661427000,
        "Longitude": -1283903000,
        "Elevation (m)": 81.38,
        "First Year": 1944,
        "Last Year": 2015,
        "HLY First Year": 1965,
        "HLY Last Year": 2015,
        "DLY First Year": 1944,
        "DLY Last Year": 2015,
        "MLY First Year": 1944,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT GOOD HOPE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201401",
        "Station ID": 52602,
        "WMO ID": null,
        "TC ID": "YGH",
        "Latitude (Decimal Degrees)": 66.24,
        "Longitude (Decimal Degrees)": -128.65,
        "Latitude": 661426000,
        "Longitude": -1283851000,
        "Elevation (m)": 81.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT GOOD HOPE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201406",
        "Station ID": 53580,
        "WMO ID": null,
        "TC ID": "YGH",
        "Latitude (Decimal Degrees)": 66.24,
        "Longitude (Decimal Degrees)": -128.65,
        "Latitude": 661426000,
        "Longitude": -1283851000,
        "Elevation (m)": 81.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT GOOD HOPE CS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201450",
        "Station ID": 27549,
        "WMO ID": 71491,
        "TC ID": "ZGH",
        "Latitude (Decimal Degrees)": 66.24,
        "Longitude (Decimal Degrees)": -128.64,
        "Latitude": 661432044,
        "Longitude": -1283839081,
        "Elevation (m)": 81.7,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT GOOD HOPE 2",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201500",
        "Station ID": 1645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.25,
        "Longitude (Decimal Degrees)": -128.63,
        "Latitude": 661500000,
        "Longitude": -1283800000,
        "Elevation (m)": 41.8,
        "First Year": 1897,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1966,
        "MLY First Year": 1897,
        "MLY Last Year": 1966
      },
      {
        "Name": "FORT LIARD A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201575",
        "Station ID": 1646,
        "WMO ID": null,
        "TC ID": "YJF",
        "Latitude (Decimal Degrees)": 60.24,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 601406000,
        "Longitude": -1232801000,
        "Elevation (m)": 215.8,
        "First Year": 1973,
        "Last Year": 2014,
        "HLY First Year": 1973,
        "HLY Last Year": 2014,
        "DLY First Year": 1973,
        "DLY Last Year": 2014,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT LIARD A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201576",
        "Station ID": 52779,
        "WMO ID": null,
        "TC ID": "YJF",
        "Latitude (Decimal Degrees)": 60.24,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 601408000,
        "Longitude": -1232812000,
        "Elevation (m)": 215.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT LIARD",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201579",
        "Station ID": 10687,
        "WMO ID": 71497,
        "TC ID": "WJL",
        "Latitude (Decimal Degrees)": 60.23,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 601400000,
        "Longitude": -1232800000,
        "Elevation (m)": 213.4,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT LIARD A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201581",
        "Station ID": 52965,
        "WMO ID": null,
        "TC ID": "YJF",
        "Latitude (Decimal Degrees)": 60.24,
        "Longitude (Decimal Degrees)": -123.47,
        "Latitude": 601408000,
        "Longitude": -1232812000,
        "Elevation (m)": 215.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT MCPHERSON A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201599",
        "Station ID": 51882,
        "WMO ID": null,
        "TC ID": "ZFM",
        "Latitude (Decimal Degrees)": 67.41,
        "Longitude (Decimal Degrees)": -134.86,
        "Latitude": 672425000,
        "Longitude": -1345135000,
        "Elevation (m)": 35.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT MCPHERSON",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201600",
        "Station ID": 1647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.43,
        "Longitude (Decimal Degrees)": -134.88,
        "Latitude": 672600000,
        "Longitude": -1345300000,
        "Elevation (m)": 30.5,
        "First Year": 1892,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1977,
        "MLY First Year": 1892,
        "MLY Last Year": 1977
      },
      {
        "Name": "FORT MCPHERSON A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201601",
        "Station ID": 1648,
        "WMO ID": null,
        "TC ID": "ZFM",
        "Latitude (Decimal Degrees)": 67.41,
        "Longitude (Decimal Degrees)": -134.86,
        "Latitude": 672428000,
        "Longitude": -1345137000,
        "Elevation (m)": 35.4,
        "First Year": 1981,
        "Last Year": 2014,
        "HLY First Year": 1988,
        "HLY Last Year": 2014,
        "DLY First Year": 1981,
        "DLY Last Year": 2014,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT MCPHERSON NWLFS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201602",
        "Station ID": 1649,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.45,
        "Longitude (Decimal Degrees)": -134.83,
        "Latitude": 692700000,
        "Longitude": -1345000000,
        "Elevation (m)": 30.5,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "FORT MCPHERSON A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201606",
        "Station ID": 52963,
        "WMO ID": null,
        "TC ID": "ZFM",
        "Latitude (Decimal Degrees)": 67.41,
        "Longitude (Decimal Degrees)": -134.86,
        "Latitude": 672425000,
        "Longitude": -1345135000,
        "Elevation (m)": 35.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TULITA A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201700",
        "Station ID": 1650,
        "WMO ID": null,
        "TC ID": "ZFN",
        "Latitude (Decimal Degrees)": 64.91,
        "Longitude (Decimal Degrees)": -125.57,
        "Latitude": 645431000,
        "Longitude": -1253406000,
        "Elevation (m)": 100.3,
        "First Year": 1903,
        "Last Year": 2014,
        "HLY First Year": 1987,
        "HLY Last Year": 2014,
        "DLY First Year": 1903,
        "DLY Last Year": 2014,
        "MLY First Year": 1903,
        "MLY Last Year": 2007
      },
      {
        "Name": "TULITA A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201701",
        "Station ID": 52798,
        "WMO ID": null,
        "TC ID": "ZFN",
        "Latitude (Decimal Degrees)": 64.91,
        "Longitude (Decimal Degrees)": -125.57,
        "Latitude": 645435000,
        "Longitude": -1253410000,
        "Elevation (m)": 100.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TULITA A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201705",
        "Station ID": 52967,
        "WMO ID": null,
        "TC ID": "ZFN",
        "Latitude (Decimal Degrees)": 64.91,
        "Longitude (Decimal Degrees)": -125.57,
        "Latitude": 645435000,
        "Longitude": -1253410000,
        "Elevation (m)": 100.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT PROVIDENCE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201799",
        "Station ID": 10902,
        "WMO ID": 71087,
        "TC ID": "WID",
        "Latitude (Decimal Degrees)": 61.32,
        "Longitude (Decimal Degrees)": -117.6,
        "Latitude": 611901000,
        "Longitude": -1173607000,
        "Elevation (m)": 161.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT PROVIDENCE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201800",
        "Station ID": 1651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.33,
        "Longitude (Decimal Degrees)": -117.67,
        "Latitude": 612000000,
        "Longitude": -1174000000,
        "Elevation (m)": 159.4,
        "First Year": 1943,
        "Last Year": 1982,
        "HLY First Year": 1953,
        "HLY Last Year": 1979,
        "DLY First Year": 1943,
        "DLY Last Year": 1982,
        "MLY First Year": 1943,
        "MLY Last Year": 1982
      },
      {
        "Name": "FORT RELIANCE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201900",
        "Station ID": 1652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.72,
        "Longitude (Decimal Degrees)": -109.17,
        "Latitude": 624300000,
        "Longitude": -1091000000,
        "Elevation (m)": 165.8,
        "First Year": 1948,
        "Last Year": 1992,
        "HLY First Year": 1953,
        "HLY Last Year": 1991,
        "DLY First Year": 1948,
        "DLY Last Year": 1992,
        "MLY First Year": 1948,
        "MLY Last Year": 1991
      },
      {
        "Name": "FORT RELIANCE (AUT)",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201903",
        "Station ID": 8935,
        "WMO ID": 71160,
        "TC ID": "WFZ",
        "Latitude (Decimal Degrees)": 62.71,
        "Longitude (Decimal Degrees)": -109.17,
        "Latitude": 624241000,
        "Longitude": -1091006000,
        "Elevation (m)": 167.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT RESOLUTION",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2201998",
        "Station ID": 1653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.17,
        "Longitude (Decimal Degrees)": -113.67,
        "Latitude": 611000000,
        "Longitude": -1134000000,
        "Elevation (m)": 158.5,
        "First Year": 1911,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1936,
        "MLY First Year": 1911,
        "MLY Last Year": 1936
      },
      {
        "Name": "FORT RESOLUTION A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202000",
        "Station ID": 1654,
        "WMO ID": null,
        "TC ID": "YFR",
        "Latitude (Decimal Degrees)": 61.18,
        "Longitude (Decimal Degrees)": -113.69,
        "Latitude": 611051000,
        "Longitude": -1134123000,
        "Elevation (m)": 160.3,
        "First Year": 1930,
        "Last Year": 2014,
        "HLY First Year": 1954,
        "HLY Last Year": 2014,
        "DLY First Year": 1930,
        "DLY Last Year": 2014,
        "MLY First Year": 1930,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT RESOLUTION A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202001",
        "Station ID": 53324,
        "WMO ID": null,
        "TC ID": "YFR",
        "Latitude (Decimal Degrees)": 61.18,
        "Longitude (Decimal Degrees)": -113.69,
        "Latitude": 611051000,
        "Longitude": -1134123000,
        "Elevation (m)": 160.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT RESOLUTION A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202010",
        "Station ID": 53322,
        "WMO ID": null,
        "TC ID": "YFR",
        "Latitude (Decimal Degrees)": 61.18,
        "Longitude (Decimal Degrees)": -113.69,
        "Latitude": 611051000,
        "Longitude": -1134123000,
        "Elevation (m)": 160.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT SIMPSON",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202100",
        "Station ID": 1655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.87,
        "Longitude (Decimal Degrees)": -121.35,
        "Latitude": 615200000,
        "Longitude": -1212100000,
        "Elevation (m)": 131.7,
        "First Year": 1895,
        "Last Year": 1963,
        "HLY First Year": 1953,
        "HLY Last Year": 1963,
        "DLY First Year": 1895,
        "DLY Last Year": 1963,
        "MLY First Year": 1895,
        "MLY Last Year": 1963
      },
      {
        "Name": "FORT SIMPSON A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202101",
        "Station ID": 1656,
        "WMO ID": 71946,
        "TC ID": "YFS",
        "Latitude (Decimal Degrees)": 61.76,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 614537000,
        "Longitude": -1211412000,
        "Elevation (m)": 169.2,
        "First Year": 1963,
        "Last Year": 2014,
        "HLY First Year": 1963,
        "HLY Last Year": 2014,
        "DLY First Year": 1963,
        "DLY Last Year": 2014,
        "MLY First Year": 1963,
        "MLY Last Year": 2010
      },
      {
        "Name": "FORT SIMPSON CLIMATE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202102",
        "Station ID": 41944,
        "WMO ID": 71365,
        "TC ID": "ZFS",
        "Latitude (Decimal Degrees)": 61.76,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 614537000,
        "Longitude": -1211412000,
        "Elevation (m)": 168,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT SIMPSON A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202103",
        "Station ID": 52780,
        "WMO ID": 71946,
        "TC ID": "YFS",
        "Latitude (Decimal Degrees)": 61.76,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 614537000,
        "Longitude": -1211412000,
        "Elevation (m)": 169.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT SIMPSON A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202104",
        "Station ID": 54159,
        "WMO ID": null,
        "TC ID": "YFS",
        "Latitude (Decimal Degrees)": 61.76,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 614537000,
        "Longitude": -1211412000,
        "Elevation (m)": 169.5,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT SIMPSON CDA",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202110",
        "Station ID": 1657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.87,
        "Longitude (Decimal Degrees)": -121.35,
        "Latitude": 615200000,
        "Longitude": -1212100000,
        "Elevation (m)": 131.1,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1961,
        "MLY Last Year": 1969
      },
      {
        "Name": "FORT SIMPSON",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202115",
        "Station ID": 27609,
        "WMO ID": null,
        "TC ID": "XFS",
        "Latitude (Decimal Degrees)": 61.76,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 614537000,
        "Longitude": -1211412000,
        "Elevation (m)": 169.2,
        "First Year": 1998,
        "Last Year": 2010,
        "HLY First Year": 1998,
        "HLY Last Year": 2000,
        "DLY First Year": 1998,
        "DLY Last Year": 2010,
        "MLY First Year": 1998,
        "MLY Last Year": 2000
      },
      {
        "Name": "FORT SMITH",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202196",
        "Station ID": 1658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60,
        "Longitude (Decimal Degrees)": -111.93,
        "Latitude": 600000000,
        "Longitude": -1115600000,
        "Elevation (m)": 182.9,
        "First Year": 1913,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1929,
        "MLY First Year": 1913,
        "MLY Last Year": 1929
      },
      {
        "Name": "FORT SMITH",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202198",
        "Station ID": 1659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60,
        "Longitude (Decimal Degrees)": -111.87,
        "Latitude": 600000000,
        "Longitude": -1115200000,
        "Elevation (m)": 207.3,
        "First Year": 1928,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1946,
        "MLY First Year": 1928,
        "MLY Last Year": 1946
      },
      {
        "Name": "FORT SMITH A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202200",
        "Station ID": 1660,
        "WMO ID": 71934,
        "TC ID": "YSM",
        "Latitude (Decimal Degrees)": 60.02,
        "Longitude (Decimal Degrees)": -111.96,
        "Latitude": 600113000,
        "Longitude": -1115743000,
        "Elevation (m)": 205.13,
        "First Year": 1943,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1943,
        "DLY Last Year": 2014,
        "MLY First Year": 1943,
        "MLY Last Year": 2014
      },
      {
        "Name": "FORT SMITH A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202201",
        "Station ID": 53119,
        "WMO ID": 71934,
        "TC ID": "YSM",
        "Latitude (Decimal Degrees)": 60.02,
        "Longitude (Decimal Degrees)": -111.96,
        "Latitude": 600113000,
        "Longitude": -1115743000,
        "Elevation (m)": 205.1,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT SMITH CLIMATE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202202",
        "Station ID": 41884,
        "WMO ID": 71362,
        "TC ID": "ZSM",
        "Latitude (Decimal Degrees)": 60.03,
        "Longitude (Decimal Degrees)": -111.93,
        "Latitude": 600134000,
        "Longitude": -1115546000,
        "Elevation (m)": 203,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT SMITH A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202203",
        "Station ID": 54218,
        "WMO ID": null,
        "TC ID": "YSM",
        "Latitude (Decimal Degrees)": 60.02,
        "Longitude (Decimal Degrees)": -111.96,
        "Latitude": 600113000,
        "Longitude": -1115743000,
        "Elevation (m)": 205.1,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FRANK CHANNEL",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202250",
        "Station ID": 1661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.78,
        "Longitude (Decimal Degrees)": -115.95,
        "Latitude": 624700000,
        "Longitude": -1155700000,
        "Elevation (m)": 160,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "HALDANE RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202349",
        "Station ID": 26868,
        "WMO ID": null,
        "TC ID": "WNW",
        "Latitude (Decimal Degrees)": 67.07,
        "Longitude (Decimal Degrees)": -121.1,
        "Latitude": 670400000,
        "Longitude": -1210600000,
        "Elevation (m)": 242.5,
        "First Year": 1995,
        "Last Year": 1999,
        "HLY First Year": 1995,
        "HLY Last Year": 1999,
        "DLY First Year": 1995,
        "DLY Last Year": 1999,
        "MLY First Year": 1998,
        "MLY Last Year": 1998
      },
      {
        "Name": "HANBURY RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202351",
        "Station ID": 10897,
        "WMO ID": 71963,
        "TC ID": "WQY",
        "Latitude (Decimal Degrees)": 63.6,
        "Longitude (Decimal Degrees)": -105.13,
        "Latitude": 633600000,
        "Longitude": -1050800000,
        "Elevation (m)": 317.4,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "HART LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202360",
        "Station ID": 1662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.85,
        "Longitude (Decimal Degrees)": -116.63,
        "Latitude": 605100000,
        "Longitude": -1163800000,
        "Elevation (m)": 252.4,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "HAY RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202398",
        "Station ID": 1663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.85,
        "Longitude (Decimal Degrees)": -115.95,
        "Latitude": 605100000,
        "Longitude": -1155700000,
        "Elevation (m)": 161.2,
        "First Year": 1893,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1943,
        "MLY First Year": 1894,
        "MLY Last Year": 1943
      },
      {
        "Name": "HAY RIVER A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202400",
        "Station ID": 1664,
        "WMO ID": 71935,
        "TC ID": "YHY",
        "Latitude (Decimal Degrees)": 60.84,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 605023000,
        "Longitude": -1154658000,
        "Elevation (m)": 164.9,
        "First Year": 1943,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1943,
        "DLY Last Year": 2014,
        "MLY First Year": 1943,
        "MLY Last Year": 2014
      },
      {
        "Name": "HAY RIVER A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202401",
        "Station ID": 52600,
        "WMO ID": 71935,
        "TC ID": "YHY",
        "Latitude (Decimal Degrees)": 60.84,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 605023000,
        "Longitude": -1154658000,
        "Elevation (m)": 164.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAY RIVER CLIMATE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202402",
        "Station ID": 41885,
        "WMO ID": 71361,
        "TC ID": "ZHY",
        "Latitude (Decimal Degrees)": 60.84,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 605020000,
        "Longitude": -1154636000,
        "Elevation (m)": 164,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "HAY RIVER A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202403",
        "Station ID": 54140,
        "WMO ID": null,
        "TC ID": "YHY",
        "Latitude (Decimal Degrees)": 60.84,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 605023000,
        "Longitude": -1154658000,
        "Elevation (m)": 164.9,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAY RIVER PARADISE GDNS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202405",
        "Station ID": 1665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.65,
        "Longitude (Decimal Degrees)": -116,
        "Latitude": 603900000,
        "Longitude": -1160000000,
        "Elevation (m)": 213.4,
        "First Year": 1962,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2006,
        "MLY First Year": 1962,
        "MLY Last Year": 2006
      },
      {
        "Name": "HORN PLATEAU",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202516",
        "Station ID": 1666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.13,
        "Longitude (Decimal Degrees)": -118.12,
        "Latitude": 620800000,
        "Longitude": -1180700000,
        "Elevation (m)": 746.8,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "HORN RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202518",
        "Station ID": 1667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.5,
        "Longitude (Decimal Degrees)": -118.03,
        "Latitude": 613000000,
        "Longitude": -1180200000,
        "Elevation (m)": 152.4,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "HORTON RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202520",
        "Station ID": 1668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 70.03,
        "Longitude (Decimal Degrees)": -126.96,
        "Latitude": 700157000,
        "Longitude": -1265738000,
        "Elevation (m)": 164.2,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "INDIN RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202552",
        "Station ID": 10757,
        "WMO ID": null,
        "TC ID": "WIE",
        "Latitude (Decimal Degrees)": 64.39,
        "Longitude (Decimal Degrees)": -115.02,
        "Latitude": 642320000,
        "Longitude": -1150116000,
        "Elevation (m)": 304.7,
        "First Year": 1994,
        "Last Year": 2004,
        "HLY First Year": 1994,
        "HLY Last Year": 2004,
        "DLY First Year": 1994,
        "DLY Last Year": 2002,
        "MLY First Year": 1996,
        "MLY Last Year": 2002
      },
      {
        "Name": "INNER WHALEBACKS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202560",
        "Station ID": 10209,
        "WMO ID": 71162,
        "TC ID": "WBJ",
        "Latitude (Decimal Degrees)": 61.92,
        "Longitude (Decimal Degrees)": -113.73,
        "Latitude": 615513030,
        "Longitude": -1134335040,
        "Elevation (m)": 165.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "INUVIK CR10",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202569",
        "Station ID": 27222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.3,
        "Longitude (Decimal Degrees)": -133.48,
        "Latitude": 681800000,
        "Longitude": -1332900000,
        "Elevation (m)": 68,
        "First Year": 1999,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 1999,
        "MLY First Year": 1999,
        "MLY Last Year": 1999
      },
      {
        "Name": "INUVIK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202570",
        "Station ID": 1669,
        "WMO ID": null,
        "TC ID": "YEV",
        "Latitude (Decimal Degrees)": 68.3,
        "Longitude (Decimal Degrees)": -133.48,
        "Latitude": 681815000,
        "Longitude": -1332858000,
        "Elevation (m)": 67.7,
        "First Year": 1957,
        "Last Year": 2013,
        "HLY First Year": 1958,
        "HLY Last Year": 2013,
        "DLY First Year": 1957,
        "DLY Last Year": 2006,
        "MLY First Year": 1957,
        "MLY Last Year": 2006
      },
      {
        "Name": "INUVIK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202571",
        "Station ID": 51477,
        "WMO ID": null,
        "TC ID": "YEV",
        "Latitude (Decimal Degrees)": 68.3,
        "Longitude (Decimal Degrees)": -133.48,
        "Latitude": 681814000,
        "Longitude": -1332859000,
        "Elevation (m)": 67.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INUVIK AWOS A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202575",
        "Station ID": 31470,
        "WMO ID": 71957,
        "TC ID": "YEV",
        "Latitude (Decimal Degrees)": 68.3,
        "Longitude (Decimal Degrees)": -133.48,
        "Latitude": 681800000,
        "Longitude": -1332900000,
        "Elevation (m)": 67.67,
        "First Year": 2006,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": 2006,
        "DLY Last Year": 2013,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "INUVIK CLIMATE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202578",
        "Station ID": 41883,
        "WMO ID": 71364,
        "TC ID": "ZEV",
        "Latitude (Decimal Degrees)": 68.32,
        "Longitude (Decimal Degrees)": -133.52,
        "Latitude": 681900000,
        "Longitude": -1333100000,
        "Elevation (m)": 103,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "INUVIK CDA",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202580",
        "Station ID": 1670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.3,
        "Longitude (Decimal Degrees)": -133.48,
        "Latitude": 681800000,
        "Longitude": -1332900000,
        "Elevation (m)": 67.1,
        "First Year": 1961,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1961,
        "MLY First Year": 1961,
        "MLY Last Year": 1961
      },
      {
        "Name": "INUVIK UA",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202582",
        "Station ID": 8938,
        "WMO ID": 71957,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.32,
        "Longitude (Decimal Degrees)": -133.52,
        "Latitude": 681900000,
        "Longitude": -1333100000,
        "Elevation (m)": 103.2,
        "First Year": 1995,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2007,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "JEAN MARIE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202640",
        "Station ID": 27794,
        "WMO ID": null,
        "TC ID": "XJM",
        "Latitude (Decimal Degrees)": 61.35,
        "Longitude (Decimal Degrees)": -120.78,
        "Latitude": 612100000,
        "Longitude": -1204700000,
        "Elevation (m)": 227.27,
        "First Year": 1999,
        "Last Year": 2000,
        "HLY First Year": 1999,
        "HLY Last Year": 2000,
        "DLY First Year": 1999,
        "DLY Last Year": 2000,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KEATS POINT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202663",
        "Station ID": 10825,
        "WMO ID": 71058,
        "TC ID": "WKP",
        "Latitude (Decimal Degrees)": 69.67,
        "Longitude (Decimal Degrees)": -121.67,
        "Latitude": 694022000,
        "Longitude": -1214021000,
        "Elevation (m)": 330.2,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "WHATI",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202678",
        "Station ID": 1674,
        "WMO ID": 71163,
        "TC ID": "WMT",
        "Latitude (Decimal Degrees)": 63.13,
        "Longitude (Decimal Degrees)": -117.24,
        "Latitude": 630801070,
        "Longitude": -1171441050,
        "Elevation (m)": 271.3,
        "First Year": 1974,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1974,
        "DLY Last Year": 2022,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "LIARD TOWER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202683",
        "Station ID": 1676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.3,
        "Longitude (Decimal Degrees)": -123.52,
        "Latitude": 601800000,
        "Longitude": -1233100000,
        "Elevation (m)": 827.5,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "LUTSELK'E A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202687",
        "Station ID": 53510,
        "WMO ID": null,
        "TC ID": "YLK",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -110.68,
        "Latitude": 622506000,
        "Longitude": -1104056000,
        "Elevation (m)": 178.6,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LUTSELK'E A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202690",
        "Station ID": 53508,
        "WMO ID": null,
        "TC ID": "YLK",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -110.68,
        "Latitude": 622506000,
        "Longitude": -1104056000,
        "Elevation (m)": 178.6,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LYNX LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "22026Q5",
        "Station ID": 1672,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.47,
        "Longitude (Decimal Degrees)": -106.88,
        "Latitude": 622800000,
        "Longitude": -1065300000,
        "Elevation (m)": 1183,
        "First Year": 1990,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1993,
        "MLY First Year": 1990,
        "MLY Last Year": 1993
      },
      {
        "Name": "NAHANNI BUTTE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202723",
        "Station ID": 1677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.08,
        "Longitude (Decimal Degrees)": -123.38,
        "Latitude": 610500000,
        "Longitude": -1232300000,
        "Elevation (m)": 1371.6,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "NAHANNI VALLEY",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202727",
        "Station ID": 1678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.05,
        "Longitude (Decimal Degrees)": -123.37,
        "Latitude": 610300000,
        "Longitude": -1232200000,
        "Elevation (m)": 213.4,
        "First Year": 1974,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1988,
        "MLY First Year": 1974,
        "MLY Last Year": 1988
      },
      {
        "Name": "NICHOLSON PENINSULA",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202750",
        "Station ID": 1679,
        "WMO ID": null,
        "TC ID": "WRP",
        "Latitude (Decimal Degrees)": 69.93,
        "Longitude (Decimal Degrees)": -128.97,
        "Latitude": 695600000,
        "Longitude": -1285800000,
        "Elevation (m)": 88.7,
        "First Year": 1957,
        "Last Year": 2010,
        "HLY First Year": 1973,
        "HLY Last Year": 1993,
        "DLY First Year": 1957,
        "DLY Last Year": 2010,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "NICHOLSON PENINSULA 2",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202751",
        "Station ID": 54758,
        "WMO ID": 73230,
        "TC ID": "BAR",
        "Latitude (Decimal Degrees)": 69.92,
        "Longitude (Decimal Degrees)": -128.97,
        "Latitude": 695529000,
        "Longitude": -1285827000,
        "Elevation (m)": 87.9,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NICHOLSON PENINSULA 1",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202754",
        "Station ID": 10846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.92,
        "Longitude (Decimal Degrees)": -128.97,
        "Latitude": 695529000,
        "Longitude": -1285827000,
        "Elevation (m)": 90.7,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORMAN WELLS A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202800",
        "Station ID": 1680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.28,
        "Longitude (Decimal Degrees)": -126.8,
        "Latitude": 651657000,
        "Longitude": -1264801000,
        "Elevation (m)": 72.54,
        "First Year": 1943,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1943,
        "DLY Last Year": 2012,
        "MLY First Year": 1943,
        "MLY Last Year": 2012
      },
      {
        "Name": "NORMAN WELLS A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202801",
        "Station ID": 50717,
        "WMO ID": 71043,
        "TC ID": "YVQ",
        "Latitude (Decimal Degrees)": 65.28,
        "Longitude (Decimal Degrees)": -126.8,
        "Latitude": 651653000,
        "Longitude": -1264755000,
        "Elevation (m)": 72.5,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORMAN WELLS SITE 1",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202805",
        "Station ID": 1681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.28,
        "Longitude (Decimal Degrees)": -126.83,
        "Latitude": 651700000,
        "Longitude": -1265000000,
        "Elevation (m)": 57.9,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "NORMAN WELLS SITE 2",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202806",
        "Station ID": 1682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.28,
        "Longitude (Decimal Degrees)": -126.83,
        "Latitude": 651700000,
        "Longitude": -1265000000,
        "Elevation (m)": 63.4,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "NORMAN WELLS SITE 3",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202807",
        "Station ID": 1683,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.27,
        "Longitude (Decimal Degrees)": -126.7,
        "Latitude": 651600000,
        "Longitude": -1264200000,
        "Elevation (m)": 61,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "NORMAN WELLS SITE 4",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202808",
        "Station ID": 1684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.27,
        "Longitude (Decimal Degrees)": -126.7,
        "Latitude": 651600000,
        "Longitude": -1264200000,
        "Elevation (m)": 61,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "NORMAN WELLS CLIMATE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2202810",
        "Station ID": 43004,
        "WMO ID": 71480,
        "TC ID": "NVQ",
        "Latitude (Decimal Degrees)": 65.29,
        "Longitude (Decimal Degrees)": -126.75,
        "Latitude": 651715090,
        "Longitude": -1264512084,
        "Elevation (m)": 93.6,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "PAULATUK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203055",
        "Station ID": 53420,
        "WMO ID": null,
        "TC ID": "YPC",
        "Latitude (Decimal Degrees)": 69.36,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 692138000,
        "Longitude": -1240433000,
        "Elevation (m)": 4.6,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PAULATUK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203056",
        "Station ID": 53418,
        "WMO ID": null,
        "TC ID": "YPC",
        "Latitude (Decimal Degrees)": 69.36,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 692138000,
        "Longitude": -1240433000,
        "Elevation (m)": 4.6,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PAULATUK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203057",
        "Station ID": 1685,
        "WMO ID": null,
        "TC ID": "YPC",
        "Latitude (Decimal Degrees)": 69.36,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 692140000,
        "Longitude": -1240431000,
        "Elevation (m)": 4.57,
        "First Year": 1985,
        "Last Year": 2015,
        "HLY First Year": 1987,
        "HLY Last Year": 2015,
        "DLY First Year": 1985,
        "DLY Last Year": 2014,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "PAULATUK",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203058",
        "Station ID": 26986,
        "WMO ID": 71984,
        "TC ID": "ZPK",
        "Latitude (Decimal Degrees)": 69.36,
        "Longitude (Decimal Degrees)": -124.08,
        "Latitude": 692128004,
        "Longitude": -1240457005,
        "Elevation (m)": 6.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEARCE POINT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203080",
        "Station ID": 1686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.82,
        "Longitude (Decimal Degrees)": -122.7,
        "Latitude": 694900000,
        "Longitude": -1224200000,
        "Elevation (m)": 48.8,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "PELLY ISLAND",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203095",
        "Station ID": 10091,
        "WMO ID": 71502,
        "TC ID": "WND",
        "Latitude (Decimal Degrees)": 69.63,
        "Longitude (Decimal Degrees)": -135.44,
        "Latitude": 693757000,
        "Longitude": -1352638000,
        "Elevation (m)": 17,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "PINE POINT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203100",
        "Station ID": 1687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.83,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 605000000,
        "Longitude": -1142800000,
        "Elevation (m)": 237.7,
        "First Year": 1953,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1965,
        "MLY First Year": 1953,
        "MLY Last Year": 1965
      },
      {
        "Name": "PINE POINT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203101",
        "Station ID": 1688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.87,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 605200000,
        "Longitude": -1142200000,
        "Elevation (m)": 224,
        "First Year": 1975,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1988,
        "MLY First Year": 1975,
        "MLY Last Year": 1988
      },
      {
        "Name": "PORTER LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203283",
        "Station ID": 1689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.7,
        "Longitude (Decimal Degrees)": -108,
        "Latitude": 614200000,
        "Longitude": -1080000000,
        "Elevation (m)": 364.8,
        "First Year": 1975,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "PORT RADIUM",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203300",
        "Station ID": 1690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.08,
        "Longitude (Decimal Degrees)": -118.03,
        "Latitude": 660500000,
        "Longitude": -1180200000,
        "Elevation (m)": 191.4,
        "First Year": 1937,
        "Last Year": 1974,
        "HLY First Year": 1953,
        "HLY Last Year": 1974,
        "DLY First Year": 1937,
        "DLY Last Year": 1974,
        "MLY First Year": 1937,
        "MLY Last Year": 1974
      },
      {
        "Name": "PORT RADIUM (AUT)",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203305",
        "Station ID": 8944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.08,
        "Longitude (Decimal Degrees)": -117.93,
        "Latitude": 660500000,
        "Longitude": -1175600000,
        "Elevation (m)": 438,
        "First Year": 1977,
        "Last Year": 1983,
        "HLY First Year": 1977,
        "HLY Last Year": 1983,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POWDER LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203315",
        "Station ID": 10931,
        "WMO ID": null,
        "TC ID": "WMP",
        "Latitude (Decimal Degrees)": 61.97,
        "Longitude (Decimal Degrees)": -108.62,
        "Latitude": 615800000,
        "Longitude": -1083700000,
        "Elevation (m)": 485,
        "First Year": 1994,
        "Last Year": 2003,
        "HLY First Year": 1994,
        "HLY Last Year": 2003,
        "DLY First Year": 1994,
        "DLY Last Year": 2003,
        "MLY First Year": 1996,
        "MLY Last Year": 2003
      },
      {
        "Name": "QAVVIK LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203341",
        "Station ID": 27889,
        "WMO ID": 71583,
        "TC ID": "XQA",
        "Latitude (Decimal Degrees)": 68.25,
        "Longitude (Decimal Degrees)": -122.1,
        "Latitude": 681516000,
        "Longitude": -1220610000,
        "Elevation (m)": 548,
        "First Year": 1999,
        "Last Year": 2011,
        "HLY First Year": 2000,
        "HLY Last Year": 2011,
        "DLY First Year": 1999,
        "DLY Last Year": 2011,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "RABBIT KETTLE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203342",
        "Station ID": 26895,
        "WMO ID": 71980,
        "TC ID": "WQF",
        "Latitude (Decimal Degrees)": 61.96,
        "Longitude (Decimal Degrees)": -127.21,
        "Latitude": 615747000,
        "Longitude": -1271233000,
        "Elevation (m)": 618,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "GAMETI",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203359",
        "Station ID": 10926,
        "WMO ID": 71165,
        "TC ID": "WYH",
        "Latitude (Decimal Degrees)": 64.11,
        "Longitude (Decimal Degrees)": -117.33,
        "Latitude": 640642078,
        "Longitude": -1171940086,
        "Elevation (m)": 221,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "RAE LAKES",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203360",
        "Station ID": 1691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.12,
        "Longitude (Decimal Degrees)": -117.35,
        "Latitude": 640700000,
        "Longitude": -1172100000,
        "Elevation (m)": 213.4,
        "First Year": 1974,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2000,
        "MLY First Year": 1974,
        "MLY Last Year": 2000
      },
      {
        "Name": "GAMETI AIRPORT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203361",
        "Station ID": 27798,
        "WMO ID": null,
        "TC ID": "YRA",
        "Latitude (Decimal Degrees)": 64.12,
        "Longitude (Decimal Degrees)": -117.31,
        "Latitude": 640700000,
        "Longitude": -1171834000,
        "Elevation (m)": 220.4,
        "First Year": 2000,
        "Last Year": 2014,
        "HLY First Year": 2000,
        "HLY Last Year": 2013,
        "DLY First Year": 2000,
        "DLY Last Year": 2014,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "GAMETI A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203362",
        "Station ID": 51886,
        "WMO ID": null,
        "TC ID": "YRA",
        "Latitude (Decimal Degrees)": 64.12,
        "Longitude (Decimal Degrees)": -117.31,
        "Latitude": 640658000,
        "Longitude": -1171835000,
        "Elevation (m)": 220.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GAMETI A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203365",
        "Station ID": 52966,
        "WMO ID": null,
        "TC ID": "YRA",
        "Latitude (Decimal Degrees)": 64.12,
        "Longitude (Decimal Degrees)": -117.31,
        "Latitude": 640658000,
        "Longitude": -1171835000,
        "Elevation (m)": 220.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RAMPART RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203390",
        "Station ID": 26833,
        "WMO ID": null,
        "TC ID": "WMU",
        "Latitude (Decimal Degrees)": 65.95,
        "Longitude (Decimal Degrees)": -130.47,
        "Latitude": 655700000,
        "Longitude": -1302800000,
        "Elevation (m)": 154.5,
        "First Year": 1994,
        "Last Year": 2003,
        "HLY First Year": 1994,
        "HLY Last Year": 2002,
        "DLY First Year": 1994,
        "DLY Last Year": 2003,
        "MLY First Year": 1996,
        "MLY Last Year": 2002
      },
      {
        "Name": "ST CHARLES CREEK",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203656",
        "Station ID": 26896,
        "WMO ID": null,
        "TC ID": "WMV",
        "Latitude (Decimal Degrees)": 64.78,
        "Longitude (Decimal Degrees)": -124.22,
        "Latitude": 644700000,
        "Longitude": -1241300000,
        "Elevation (m)": 341,
        "First Year": 1995,
        "Last Year": 2003,
        "HLY First Year": 1995,
        "HLY Last Year": 2003,
        "DLY First Year": 1995,
        "DLY Last Year": 2003,
        "MLY First Year": 1998,
        "MLY Last Year": 2002
      },
      {
        "Name": "SANS SAULT RAPIDS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203675",
        "Station ID": 1693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.67,
        "Longitude (Decimal Degrees)": -128.82,
        "Latitude": 654000000,
        "Longitude": -1284900000,
        "Elevation (m)": 63.4,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "SNARE RAPIDS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203700",
        "Station ID": 1694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.52,
        "Longitude (Decimal Degrees)": -116,
        "Latitude": 633100000,
        "Longitude": -1160000000,
        "Elevation (m)": 241,
        "First Year": 1947,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 2000,
        "MLY First Year": 1947,
        "MLY Last Year": 2000
      },
      {
        "Name": "SNOWDRIFT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203750",
        "Station ID": 1695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.4,
        "Longitude (Decimal Degrees)": -110.73,
        "Latitude": 622400000,
        "Longitude": -1104400000,
        "Elevation (m)": 176.8,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": 1973,
        "HLY Last Year": 1974,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "TSICHU RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203891",
        "Station ID": 1696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.3,
        "Longitude (Decimal Degrees)": -129.82,
        "Latitude": 631800000,
        "Longitude": -1294900000,
        "Elevation (m)": 1264.9,
        "First Year": 1974,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1982,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "TSU LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203892",
        "Station ID": 1697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.68,
        "Longitude (Decimal Degrees)": -111.87,
        "Latitude": 604100000,
        "Longitude": -1115200000,
        "Elevation (m)": 213.4,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "TUKTOYAKTUK",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203900",
        "Station ID": 1698,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.47,
        "Longitude (Decimal Degrees)": -132.98,
        "Latitude": 692800000,
        "Longitude": -1325900000,
        "Elevation (m)": null,
        "First Year": 1948,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1956,
        "MLY First Year": 1948,
        "MLY Last Year": 1956
      },
      {
        "Name": "TUKTOYAKTUK",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203910",
        "Station ID": 1699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.45,
        "Longitude (Decimal Degrees)": -133,
        "Latitude": 692700000,
        "Longitude": -1330000000,
        "Elevation (m)": 18.3,
        "First Year": 1957,
        "Last Year": 1993,
        "HLY First Year": 1958,
        "HLY Last Year": 1993,
        "DLY First Year": 1957,
        "DLY Last Year": 1993,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "TUKTOYAKTUK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203911",
        "Station ID": 53582,
        "WMO ID": null,
        "TC ID": "YUB",
        "Latitude (Decimal Degrees)": 69.43,
        "Longitude (Decimal Degrees)": -133.03,
        "Latitude": 692600000,
        "Longitude": -1330135000,
        "Elevation (m)": 4.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TUKTOYAKTUK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203912",
        "Station ID": 1700,
        "WMO ID": null,
        "TC ID": "YUB",
        "Latitude (Decimal Degrees)": 69.43,
        "Longitude (Decimal Degrees)": -133.03,
        "Latitude": 692600000,
        "Longitude": -1330135000,
        "Elevation (m)": 4.27,
        "First Year": 1970,
        "Last Year": 2015,
        "HLY First Year": 1970,
        "HLY Last Year": 2015,
        "DLY First Year": 1970,
        "DLY Last Year": 2015,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "TUKTOYAKTUK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203913",
        "Station ID": 53581,
        "WMO ID": null,
        "TC ID": "YUB",
        "Latitude (Decimal Degrees)": 69.43,
        "Longitude (Decimal Degrees)": -133.03,
        "Latitude": 692600000,
        "Longitude": -1330135000,
        "Elevation (m)": 4.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TUKTOYAKTUK",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203914",
        "Station ID": 26987,
        "WMO ID": 71985,
        "TC ID": "ZUB",
        "Latitude (Decimal Degrees)": 69.43,
        "Longitude (Decimal Degrees)": -133.02,
        "Latitude": 692600000,
        "Longitude": -1330100000,
        "Elevation (m)": 4.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2006
      },
      {
        "Name": "TUKTUT NOGAIT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203918",
        "Station ID": 27626,
        "WMO ID": 71492,
        "TC ID": "XTN",
        "Latitude (Decimal Degrees)": 69.2,
        "Longitude (Decimal Degrees)": -122.36,
        "Latitude": 691156000,
        "Longitude": -1222133000,
        "Elevation (m)": 551.6,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "TUNDRA MINE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203920",
        "Station ID": 1701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 64.03,
        "Longitude (Decimal Degrees)": -111.17,
        "Latitude": 640200000,
        "Longitude": -1111000000,
        "Elevation (m)": 470.9,
        "First Year": 1963,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1967,
        "MLY First Year": 1963,
        "MLY Last Year": 1967
      },
      {
        "Name": "TUNGSTEN",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203922",
        "Station ID": 1702,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.95,
        "Longitude (Decimal Degrees)": -128.25,
        "Latitude": 615700000,
        "Longitude": -1281500000,
        "Elevation (m)": 1143,
        "First Year": 1966,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1990,
        "MLY First Year": 1966,
        "MLY Last Year": 1990
      },
      {
        "Name": "TUNUNUK",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203925",
        "Station ID": 1703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.02,
        "Longitude (Decimal Degrees)": -134.68,
        "Latitude": 690100000,
        "Longitude": -1344100000,
        "Elevation (m)": 48.8,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "TWIN GORGES",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203933",
        "Station ID": 1704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.25,
        "Longitude (Decimal Degrees)": -111.8,
        "Latitude": 601500000,
        "Longitude": -1114800000,
        "Elevation (m)": 237.7,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "VIRGINIA FALLS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203943",
        "Station ID": 8947,
        "WMO ID": null,
        "TC ID": "WFV",
        "Latitude (Decimal Degrees)": 61.63,
        "Longitude (Decimal Degrees)": -125.8,
        "Latitude": 613800000,
        "Longitude": -1254800000,
        "Elevation (m)": 609.6,
        "First Year": 1994,
        "Last Year": 2009,
        "HLY First Year": 1994,
        "HLY Last Year": 2004,
        "DLY First Year": 1994,
        "DLY Last Year": 2009,
        "MLY First Year": 1998,
        "MLY Last Year": 2002
      },
      {
        "Name": "WEKWEETI A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2203980",
        "Station ID": 50721,
        "WMO ID": null,
        "TC ID": "YWE",
        "Latitude (Decimal Degrees)": 64.19,
        "Longitude (Decimal Degrees)": -114.08,
        "Latitude": 641127000,
        "Longitude": -1140436000,
        "Elevation (m)": 368.2,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WRIGLEY A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204000",
        "Station ID": 1705,
        "WMO ID": null,
        "TC ID": "YWY",
        "Latitude (Decimal Degrees)": 63.21,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 631234000,
        "Longitude": -1232612000,
        "Elevation (m)": 149.7,
        "First Year": 1943,
        "Last Year": 2015,
        "HLY First Year": 1957,
        "HLY Last Year": 2015,
        "DLY First Year": 1943,
        "DLY Last Year": 2015,
        "MLY First Year": 1943,
        "MLY Last Year": 2007
      },
      {
        "Name": "WRIGLEY A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204001",
        "Station ID": 53600,
        "WMO ID": null,
        "TC ID": "YWY",
        "Latitude (Decimal Degrees)": 63.21,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 631235000,
        "Longitude": -1232612000,
        "Elevation (m)": 149.7,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WRIGLEY A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204005",
        "Station ID": 53601,
        "WMO ID": null,
        "TC ID": "YWY",
        "Latitude (Decimal Degrees)": 63.21,
        "Longitude (Decimal Degrees)": -123.44,
        "Latitude": 631235000,
        "Longitude": -1232612000,
        "Elevation (m)": 149.7,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YELLOWKNIFE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204100",
        "Station ID": 1706,
        "WMO ID": 71936,
        "TC ID": "YZF",
        "Latitude (Decimal Degrees)": 62.46,
        "Longitude (Decimal Degrees)": -114.44,
        "Latitude": 622746000,
        "Longitude": -1142625000,
        "Elevation (m)": 205.7,
        "First Year": 1942,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1942,
        "DLY Last Year": 2013,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "YELLOWKNIFE A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204101",
        "Station ID": 51058,
        "WMO ID": 71936,
        "TC ID": "YZF",
        "Latitude (Decimal Degrees)": 62.46,
        "Longitude (Decimal Degrees)": -114.44,
        "Latitude": 622747000,
        "Longitude": -1142625000,
        "Elevation (m)": 205.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YELLOWKNIFE AIRPORT",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204108",
        "Station ID": 55358,
        "WMO ID": 73044,
        "TC ID": "NYE",
        "Latitude (Decimal Degrees)": 62.47,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 622828000,
        "Longitude": -1142657000,
        "Elevation (m)": 205,
        "First Year": 2022,
        "Last Year": 2022,
        "HLY First Year": 2022,
        "HLY Last Year": 2022,
        "DLY First Year": 2022,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YELLOWKNIFE-HENDERSON",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204110",
        "Station ID": 45467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.45,
        "Longitude (Decimal Degrees)": -114.38,
        "Latitude": 622700000,
        "Longitude": -1142300000,
        "Elevation (m)": 200,
        "First Year": 2006,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2021,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "YELLOWKNIFE CS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204155",
        "Station ID": 27338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.47,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 622800000,
        "Longitude": -1142700000,
        "Elevation (m)": 210,
        "First Year": 1997,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2004,
        "MLY First Year": 1997,
        "MLY Last Year": 2004
      },
      {
        "Name": "YELLOWKNIFE HYDRO",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204200",
        "Station ID": 1707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.67,
        "Longitude (Decimal Degrees)": -114.25,
        "Latitude": 624000000,
        "Longitude": -1141500000,
        "Elevation (m)": 159.4,
        "First Year": 1943,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 2000,
        "MLY First Year": 1943,
        "MLY Last Year": 2000
      },
      {
        "Name": "YOHIN",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2204300",
        "Station ID": 1635,
        "WMO ID": 71020,
        "TC ID": "XYH",
        "Latitude (Decimal Degrees)": 61.24,
        "Longitude (Decimal Degrees)": -123.74,
        "Latitude": 611431000,
        "Longitude": -1234430000,
        "Elevation (m)": 204,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1973,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "LIVERPOOL BAY",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220B68C",
        "Station ID": 10823,
        "WMO ID": 71960,
        "TC ID": "WLI",
        "Latitude (Decimal Degrees)": 69.6,
        "Longitude (Decimal Degrees)": -130.91,
        "Latitude": 693615000,
        "Longitude": -1305438000,
        "Elevation (m)": 101.9,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "LITTLE CHICAGO",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220B6Q3",
        "Station ID": 10760,
        "WMO ID": 71164,
        "TC ID": "WLG",
        "Latitude (Decimal Degrees)": 67.18,
        "Longitude (Decimal Degrees)": -130.23,
        "Latitude": 671045000,
        "Longitude": -1301341000,
        "Elevation (m)": 62.5,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "LITTLE DOCTOR LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220BFH3",
        "Station ID": 1621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.9,
        "Longitude (Decimal Degrees)": -123.25,
        "Latitude": 615400000,
        "Longitude": -1231500000,
        "Elevation (m)": 215.8,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "TUKTOYAKTUK 1",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220C9JK",
        "Station ID": 10845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.44,
        "Longitude (Decimal Degrees)": -133,
        "Latitude": 692637000,
        "Longitude": -1325957000,
        "Elevation (m)": 17.3,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAMBAA K'E",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220CQHR",
        "Station ID": 10880,
        "WMO ID": 71166,
        "TC ID": "WTE",
        "Latitude (Decimal Degrees)": 60.44,
        "Longitude (Decimal Degrees)": -121.24,
        "Latitude": 602627000,
        "Longitude": -1211420000,
        "Elevation (m)": 498,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARIAN LAKE TOWER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220K68F",
        "Station ID": 1622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.07,
        "Longitude (Decimal Degrees)": -116.88,
        "Latitude": 630400000,
        "Longitude": -1165300000,
        "Elevation (m)": 294.7,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "GREAT SLAVE LAKE BUOY",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220KLML",
        "Station ID": 10886,
        "WMO ID": 45141,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.18,
        "Longitude (Decimal Degrees)": -115.32,
        "Latitude": 611038000,
        "Longitude": -1151924000,
        "Elevation (m)": 156.3,
        "First Year": 1997,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2001,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LUTSELK'E A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220L001",
        "Station ID": 27797,
        "WMO ID": null,
        "TC ID": "YLK",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -110.68,
        "Latitude": 622507000,
        "Longitude": -1104056000,
        "Elevation (m)": 178.6,
        "First Year": 1999,
        "Last Year": 2016,
        "HLY First Year": 1999,
        "HLY Last Year": 2015,
        "DLY First Year": 1999,
        "DLY Last Year": 2016,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "LUTSELK'E CS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220L002",
        "Station ID": 29451,
        "WMO ID": 71529,
        "TC ID": "XLU",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -110.69,
        "Latitude": 622456030,
        "Longitude": -1104125070,
        "Elevation (m)": 178.9,
        "First Year": 1999,
        "Last Year": 2017,
        "HLY First Year": 1999,
        "HLY Last Year": 2017,
        "DLY First Year": 2000,
        "DLY Last Year": 2017,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRAY LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220LBQK",
        "Station ID": 10929,
        "WMO ID": null,
        "TC ID": "WJP",
        "Latitude (Decimal Degrees)": 61.92,
        "Longitude (Decimal Degrees)": -108.43,
        "Latitude": 615500000,
        "Longitude": -1082600000,
        "Elevation (m)": 451.1,
        "First Year": 1994,
        "Last Year": 2001,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1994,
        "DLY Last Year": 2001,
        "MLY First Year": 1996,
        "MLY Last Year": 2000
      },
      {
        "Name": "STORM HILLS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220LQJJ",
        "Station ID": 10824,
        "WMO ID": 71052,
        "TC ID": "WVH",
        "Latitude (Decimal Degrees)": 68.91,
        "Longitude (Decimal Degrees)": -133.94,
        "Latitude": 685439000,
        "Longitude": -1335630000,
        "Elevation (m)": 261.4,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2000
      },
      {
        "Name": "EKATI A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220N001",
        "Station ID": 27240,
        "WMO ID": null,
        "TC ID": "YOA",
        "Latitude (Decimal Degrees)": 64.7,
        "Longitude (Decimal Degrees)": -110.61,
        "Latitude": 644156000,
        "Longitude": -1103653000,
        "Elevation (m)": 468.2,
        "First Year": 1997,
        "Last Year": 2016,
        "HLY First Year": 1997,
        "HLY Last Year": 2016,
        "DLY First Year": 1998,
        "DLY Last Year": 2016,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "LINDBURG LANDING",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220N003",
        "Station ID": 27608,
        "WMO ID": 71682,
        "TC ID": "XLL",
        "Latitude (Decimal Degrees)": 61.13,
        "Longitude (Decimal Degrees)": -122.85,
        "Latitude": 610730000,
        "Longitude": -1225104000,
        "Elevation (m)": 183,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "LOWER CARP LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220N004",
        "Station ID": 27610,
        "WMO ID": 71680,
        "TC ID": "XLC",
        "Latitude (Decimal Degrees)": 63.6,
        "Longitude (Decimal Degrees)": -113.86,
        "Latitude": 633610080,
        "Longitude": -1135145040,
        "Elevation (m)": 373.4,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1992,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "TRAIL VALLEY",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "220N005",
        "Station ID": 27620,
        "WMO ID": 71683,
        "TC ID": "XTV",
        "Latitude (Decimal Degrees)": 68.75,
        "Longitude (Decimal Degrees)": -133.5,
        "Latitude": 684446800,
        "Longitude": -1333006400,
        "Elevation (m)": 85,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "ARVIAT AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2300426",
        "Station ID": 47427,
        "WMO ID": null,
        "TC ID": "YEK",
        "Latitude (Decimal Degrees)": 61.1,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 610600000,
        "Longitude": -940400000,
        "Elevation (m)": 10.4,
        "First Year": 2008,
        "Last Year": 2012,
        "HLY First Year": 2008,
        "HLY Last Year": 2012,
        "DLY First Year": 2008,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARVIAT A",
        "Province": "NUNAVUT",
        "Climate ID": "2300427",
        "Station ID": 50557,
        "WMO ID": 71174,
        "TC ID": "YEK",
        "Latitude (Decimal Degrees)": 61.09,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 610538000,
        "Longitude": -940418000,
        "Elevation (m)": 10.4,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARVIAT A",
        "Province": "NUNAVUT",
        "Climate ID": "2300428",
        "Station ID": 50718,
        "WMO ID": null,
        "TC ID": "YEK",
        "Latitude (Decimal Degrees)": 61.09,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 610538000,
        "Longitude": -940418000,
        "Elevation (m)": 10.4,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAKER LAKE A",
        "Province": "NUNAVUT",
        "Climate ID": "2300500",
        "Station ID": 1709,
        "WMO ID": 71926,
        "TC ID": "YBK",
        "Latitude (Decimal Degrees)": 64.3,
        "Longitude (Decimal Degrees)": -96.08,
        "Latitude": 641756000,
        "Longitude": -960440000,
        "Elevation (m)": 18.6,
        "First Year": 1946,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1946,
        "DLY Last Year": 2013,
        "MLY First Year": 1946,
        "MLY Last Year": 2013
      },
      {
        "Name": "BAKER LAKE CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2300501",
        "Station ID": 42583,
        "WMO ID": 71356,
        "TC ID": "XBL",
        "Latitude (Decimal Degrees)": 64.32,
        "Longitude (Decimal Degrees)": -96,
        "Latitude": 641900000,
        "Longitude": -960000000,
        "Elevation (m)": 50.8,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "BAKER LAKE A",
        "Province": "NUNAVUT",
        "Climate ID": "2300503",
        "Station ID": 51417,
        "WMO ID": 71926,
        "TC ID": "YBK",
        "Latitude (Decimal Degrees)": 64.3,
        "Longitude (Decimal Degrees)": -96.08,
        "Latitude": 641756000,
        "Longitude": -960440000,
        "Elevation (m)": 18.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAKER LAKE A",
        "Province": "NUNAVUT",
        "Climate ID": "2300504",
        "Station ID": 54138,
        "WMO ID": null,
        "TC ID": "YBK",
        "Latitude (Decimal Degrees)": 64.3,
        "Longitude (Decimal Degrees)": -96.08,
        "Latitude": 641756000,
        "Longitude": -960440000,
        "Elevation (m)": 18.6,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BATHURST INLET",
        "Province": "NUNAVUT",
        "Climate ID": "2300550",
        "Station ID": 1628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.83,
        "Longitude (Decimal Degrees)": -108.02,
        "Latitude": 665000000,
        "Longitude": -1080100000,
        "Elevation (m)": 13.4,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "BATHURST INLET",
        "Province": "NUNAVUT",
        "Climate ID": "2300551",
        "Station ID": 50657,
        "WMO ID": 71874,
        "TC ID": "NBI",
        "Latitude (Decimal Degrees)": 66.84,
        "Longitude (Decimal Degrees)": -108.01,
        "Latitude": 665015050,
        "Longitude": -1080053030,
        "Elevation (m)": 5,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BERNARD HARBOUR",
        "Province": "NUNAVUT",
        "Climate ID": "2300555",
        "Station ID": 1630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -114.83,
        "Latitude": 684700000,
        "Longitude": -1145000000,
        "Elevation (m)": 64.6,
        "First Year": 1959,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1959,
        "DLY Last Year": 1993,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "BERNARD HARBOUR",
        "Province": "NUNAVUT",
        "Climate ID": "2300556",
        "Station ID": 54778,
        "WMO ID": 73231,
        "TC ID": "PIN",
        "Latitude (Decimal Degrees)": 68.76,
        "Longitude (Decimal Degrees)": -114.94,
        "Latitude": 684519000,
        "Longitude": -1145623000,
        "Elevation (m)": 61.5,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE PEEL",
        "Province": "NUNAVUT",
        "Climate ID": "2300680",
        "Station ID": 1634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.05,
        "Longitude (Decimal Degrees)": -107.32,
        "Latitude": 690300000,
        "Longitude": -1071900000,
        "Elevation (m)": 58.5,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "CHESTERFIELD",
        "Province": "NUNAVUT",
        "Climate ID": "2300700",
        "Station ID": 1710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.33,
        "Longitude (Decimal Degrees)": -90.72,
        "Latitude": 632000000,
        "Longitude": -904300000,
        "Elevation (m)": 6.4,
        "First Year": 1930,
        "Last Year": 1984,
        "HLY First Year": 1953,
        "HLY Last Year": 1984,
        "DLY First Year": 1930,
        "DLY Last Year": 1981,
        "MLY First Year": 1930,
        "MLY Last Year": 1981
      },
      {
        "Name": "CHESTERFIELD INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2300701",
        "Station ID": 51998,
        "WMO ID": null,
        "TC ID": "YCS",
        "Latitude (Decimal Degrees)": 63.35,
        "Longitude (Decimal Degrees)": -90.73,
        "Latitude": 632050000,
        "Longitude": -904352000,
        "Elevation (m)": 9.8,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHESTERFIELD INLET",
        "Province": "NUNAVUT",
        "Climate ID": "2300705",
        "Station ID": 1711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.75,
        "Longitude (Decimal Degrees)": -91.83,
        "Latitude": 634500000,
        "Longitude": -915000000,
        "Elevation (m)": 14.6,
        "First Year": 1921,
        "Last Year": 1931,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1931,
        "MLY First Year": 1922,
        "MLY Last Year": 1931
      },
      {
        "Name": "CHESTERFIELD INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2300707",
        "Station ID": 1712,
        "WMO ID": null,
        "TC ID": "YCS",
        "Latitude (Decimal Degrees)": 63.35,
        "Longitude (Decimal Degrees)": -90.73,
        "Latitude": 632049000,
        "Longitude": -904352000,
        "Elevation (m)": 9.8,
        "First Year": 1985,
        "Last Year": 2014,
        "HLY First Year": 1985,
        "HLY Last Year": 2013,
        "DLY First Year": 1985,
        "DLY Last Year": 2014,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHESTERFIELD INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2300710",
        "Station ID": 52968,
        "WMO ID": null,
        "TC ID": "YCS",
        "Latitude (Decimal Degrees)": 63.35,
        "Longitude (Decimal Degrees)": -90.73,
        "Latitude": 632050000,
        "Longitude": -904352000,
        "Elevation (m)": 9.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLINTON POINT",
        "Province": "NUNAVUT",
        "Climate ID": "2300750",
        "Station ID": 1637,
        "WMO ID": 71053,
        "TC ID": "WXK",
        "Latitude (Decimal Degrees)": 69.58,
        "Longitude (Decimal Degrees)": -120.8,
        "Latitude": 693500000,
        "Longitude": -1204800000,
        "Elevation (m)": 100.6,
        "First Year": 1957,
        "Last Year": 1993,
        "HLY First Year": 1958,
        "HLY Last Year": 1993,
        "DLY First Year": 1957,
        "DLY Last Year": 1993,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "CONTWOYTO LAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2300850",
        "Station ID": 1639,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.48,
        "Longitude (Decimal Degrees)": -110.37,
        "Latitude": 652900000,
        "Longitude": -1102200000,
        "Elevation (m)": 451.4,
        "First Year": 1956,
        "Last Year": 1981,
        "HLY First Year": 1956,
        "HLY Last Year": 1981,
        "DLY First Year": 1959,
        "DLY Last Year": 1981,
        "MLY First Year": 1959,
        "MLY Last Year": 1981
      },
      {
        "Name": "COPPERMINE",
        "Province": "NUNAVUT",
        "Climate ID": "2300900",
        "Station ID": 1640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.83,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 675000000,
        "Longitude": -1150700000,
        "Elevation (m)": 9.1,
        "First Year": 1930,
        "Last Year": 1977,
        "HLY First Year": 1953,
        "HLY Last Year": 1977,
        "DLY First Year": 1930,
        "DLY Last Year": 1977,
        "MLY First Year": 1930,
        "MLY Last Year": 1977
      },
      {
        "Name": "KUGLUKTUK A",
        "Province": "NUNAVUT",
        "Climate ID": "2300902",
        "Station ID": 1641,
        "WMO ID": 71938,
        "TC ID": "YCO",
        "Latitude (Decimal Degrees)": 67.82,
        "Longitude (Decimal Degrees)": -115.14,
        "Latitude": 674900000,
        "Longitude": -1150838000,
        "Elevation (m)": 22.6,
        "First Year": 1977,
        "Last Year": 2014,
        "HLY First Year": 1978,
        "HLY Last Year": 2014,
        "DLY First Year": 1977,
        "DLY Last Year": 2014,
        "MLY First Year": 1977,
        "MLY Last Year": 2014
      },
      {
        "Name": "KUGLUKTUK A",
        "Province": "NUNAVUT",
        "Climate ID": "2300903",
        "Station ID": 53335,
        "WMO ID": 71938,
        "TC ID": "YCO",
        "Latitude (Decimal Degrees)": 67.82,
        "Longitude (Decimal Degrees)": -115.14,
        "Latitude": 674900000,
        "Longitude": -1150838000,
        "Elevation (m)": 22.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KUGLUKTUK CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2300904",
        "Station ID": 43003,
        "WMO ID": 71332,
        "TC ID": "NCO",
        "Latitude (Decimal Degrees)": 67.82,
        "Longitude (Decimal Degrees)": -115.14,
        "Latitude": 674902004,
        "Longitude": -1150807000,
        "Elevation (m)": 22.6,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "KUGLUKTUK A",
        "Province": "NUNAVUT",
        "Climate ID": "2300905",
        "Station ID": 54158,
        "WMO ID": null,
        "TC ID": "YCO",
        "Latitude (Decimal Degrees)": 67.82,
        "Longitude (Decimal Degrees)": -115.14,
        "Latitude": 674900000,
        "Longitude": -1150838000,
        "Elevation (m)": 22.6,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARVIAT A",
        "Province": "NUNAVUT",
        "Climate ID": "2300MKF",
        "Station ID": 1708,
        "WMO ID": null,
        "TC ID": "YEK",
        "Latitude (Decimal Degrees)": 61.1,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 610600000,
        "Longitude": -940400000,
        "Elevation (m)": 10.4,
        "First Year": 1973,
        "Last Year": 2016,
        "HLY First Year": 1984,
        "HLY Last Year": 2012,
        "DLY First Year": 1973,
        "DLY Last Year": 2016,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "BACK RIVER (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2300MQM",
        "Station ID": 10723,
        "WMO ID": null,
        "TC ID": "WXV",
        "Latitude (Decimal Degrees)": 66.09,
        "Longitude (Decimal Degrees)": -96.51,
        "Latitude": 660510014,
        "Longitude": -963039000,
        "Elevation (m)": 61,
        "First Year": 1994,
        "Last Year": 2004,
        "HLY First Year": 1994,
        "HLY Last Year": 2004,
        "DLY First Year": 1995,
        "DLY Last Year": 2004,
        "MLY First Year": 1999,
        "MLY Last Year": 2003
      },
      {
        "Name": "CORAL HARBOUR A",
        "Province": "NUNAVUT",
        "Climate ID": "2301000",
        "Station ID": 1713,
        "WMO ID": 71915,
        "TC ID": "YZS",
        "Latitude (Decimal Degrees)": 64.19,
        "Longitude (Decimal Degrees)": -83.36,
        "Latitude": 641136000,
        "Longitude": -832134000,
        "Elevation (m)": 62.2,
        "First Year": 1933,
        "Last Year": 2015,
        "HLY First Year": 1953,
        "HLY Last Year": 2015,
        "DLY First Year": 1933,
        "DLY Last Year": 2015,
        "MLY First Year": 1933,
        "MLY Last Year": 2015
      },
      {
        "Name": "CORAL HARBOUR A",
        "Province": "NUNAVUT",
        "Climate ID": "2301002",
        "Station ID": 53421,
        "WMO ID": 71915,
        "TC ID": "YZS",
        "Latitude (Decimal Degrees)": 64.19,
        "Longitude (Decimal Degrees)": -83.36,
        "Latitude": 641136000,
        "Longitude": -832134000,
        "Elevation (m)": 62.2,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CORAL HARBOUR A",
        "Province": "NUNAVUT",
        "Climate ID": "2301004",
        "Station ID": 54220,
        "WMO ID": null,
        "TC ID": "YZS",
        "Latitude (Decimal Degrees)": 64.19,
        "Longitude (Decimal Degrees)": -83.36,
        "Latitude": 641136000,
        "Longitude": -832134000,
        "Elevation (m)": 62.2,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CORAL HARBOUR RCS",
        "Province": "NUNAVUT",
        "Climate ID": "2301010",
        "Station ID": 47347,
        "WMO ID": 71134,
        "TC ID": "NZS",
        "Latitude (Decimal Degrees)": 64.18,
        "Longitude (Decimal Degrees)": -83.35,
        "Latitude": 641100000,
        "Longitude": -832100000,
        "Elevation (m)": 57,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ELLICE RIVER (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2301094",
        "Station ID": 10895,
        "WMO ID": null,
        "TC ID": "WEV",
        "Latitude (Decimal Degrees)": 67.72,
        "Longitude (Decimal Degrees)": -104.47,
        "Latitude": 674300000,
        "Longitude": -1042800000,
        "Elevation (m)": 42,
        "First Year": 1994,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2003,
        "DLY First Year": 1994,
        "DLY Last Year": 2007,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "ENNADAI LAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2301100",
        "Station ID": 1714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.13,
        "Longitude (Decimal Degrees)": -100.9,
        "Latitude": 610800000,
        "Longitude": -1005400000,
        "Elevation (m)": 324.6,
        "First Year": 1949,
        "Last Year": 1979,
        "HLY First Year": 1953,
        "HLY Last Year": 1979,
        "DLY First Year": 1949,
        "DLY Last Year": 1979,
        "MLY First Year": 1949,
        "MLY Last Year": 1979
      },
      {
        "Name": "ENNADAI LAKE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2301102",
        "Station ID": 7341,
        "WMO ID": 71923,
        "TC ID": "WJC",
        "Latitude (Decimal Degrees)": 61.13,
        "Longitude (Decimal Degrees)": -100.88,
        "Latitude": 610755000,
        "Longitude": -1005302000,
        "Elevation (m)": 353.1,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARVIAT CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2301153",
        "Station ID": 41576,
        "WMO ID": 71322,
        "TC ID": "XAR",
        "Latitude (Decimal Degrees)": 61.1,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 610600000,
        "Longitude": -940400000,
        "Elevation (m)": 9.8,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROWN RIVER",
        "Province": "NUNAVUT",
        "Climate ID": "2301331",
        "Station ID": 10971,
        "WMO ID": null,
        "TC ID": "WBR",
        "Latitude (Decimal Degrees)": 66.03,
        "Longitude (Decimal Degrees)": -91.83,
        "Latitude": 660200000,
        "Longitude": -915000000,
        "Elevation (m)": 150,
        "First Year": 1994,
        "Last Year": 2004,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1994,
        "DLY Last Year": 2004,
        "MLY First Year": 1996,
        "MLY Last Year": 2001
      },
      {
        "Name": "GJOA HAVEN A",
        "Province": "NUNAVUT",
        "Climate ID": "2302335",
        "Station ID": 1715,
        "WMO ID": null,
        "TC ID": "YHK",
        "Latitude (Decimal Degrees)": 68.64,
        "Longitude (Decimal Degrees)": -95.85,
        "Latitude": 683808000,
        "Longitude": -955101000,
        "Elevation (m)": 46.9,
        "First Year": 1984,
        "Last Year": 2014,
        "HLY First Year": 1984,
        "HLY Last Year": 2013,
        "DLY First Year": 1984,
        "DLY Last Year": 2014,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "GJOA HAVEN A",
        "Province": "NUNAVUT",
        "Climate ID": "2302336",
        "Station ID": 51082,
        "WMO ID": null,
        "TC ID": "YHK",
        "Latitude (Decimal Degrees)": 68.64,
        "Longitude (Decimal Degrees)": -95.85,
        "Latitude": 683808000,
        "Longitude": -955101000,
        "Elevation (m)": 46.9,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GJOA HAVEN A",
        "Province": "NUNAVUT",
        "Climate ID": "2302337",
        "Station ID": 51079,
        "WMO ID": 71597,
        "TC ID": "YHK",
        "Latitude (Decimal Degrees)": 68.64,
        "Longitude (Decimal Degrees)": -95.85,
        "Latitude": 683808000,
        "Longitude": -955101000,
        "Elevation (m)": 46.9,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GJOA HAVEN AWOS A",
        "Province": "NUNAVUT",
        "Climate ID": "2302338",
        "Station ID": 31268,
        "WMO ID": null,
        "TC ID": "YHK",
        "Latitude (Decimal Degrees)": 68.64,
        "Longitude (Decimal Degrees)": -95.85,
        "Latitude": 683808000,
        "Longitude": -955101000,
        "Elevation (m)": 46.94,
        "First Year": 2008,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GJOA HAVEN CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2302340",
        "Station ID": 41943,
        "WMO ID": 71363,
        "TC ID": "ZHK",
        "Latitude (Decimal Degrees)": 68.64,
        "Longitude (Decimal Degrees)": -95.85,
        "Latitude": 683808000,
        "Longitude": -955101000,
        "Elevation (m)": 42.3,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "HARDING RIVER",
        "Province": "NUNAVUT",
        "Climate ID": "2302353",
        "Station ID": 10827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.84,
        "Longitude (Decimal Degrees)": -117,
        "Latitude": 685007000,
        "Longitude": -1165958000,
        "Elevation (m)": 89.7,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAT ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2302370",
        "Station ID": 1716,
        "WMO ID": 71084,
        "TC ID": "WIL",
        "Latitude (Decimal Degrees)": 68.32,
        "Longitude (Decimal Degrees)": -100.09,
        "Latitude": 681905000,
        "Longitude": -1000513000,
        "Elevation (m)": 36.1,
        "First Year": 1959,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1959,
        "DLY Last Year": 2022,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUDSON BAY SITE 1",
        "Province": "NUNAVUT",
        "Climate ID": "2302530",
        "Station ID": 7344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.5,
        "Longitude (Decimal Degrees)": -87.17,
        "Latitude": 583000000,
        "Longitude": -871000000,
        "Elevation (m)": 21,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": 1974,
        "HLY Last Year": 1974,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HUDSON BAY SITE 2",
        "Province": "NUNAVUT",
        "Climate ID": "2302531",
        "Station ID": 7345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.5,
        "Longitude (Decimal Degrees)": -86.78,
        "Latitude": 583000000,
        "Longitude": -864700000,
        "Elevation (m)": 21,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": 1974,
        "HLY Last Year": 1974,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HUDSON BAY SITE 3",
        "Province": "NUNAVUT",
        "Climate ID": "2302532",
        "Station ID": 7346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.02,
        "Longitude (Decimal Degrees)": -84.3,
        "Latitude": 580100000,
        "Longitude": -841800000,
        "Elevation (m)": 21,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": 1974,
        "HLY Last Year": 1974,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "JENNY LIND ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2302649",
        "Station ID": 10829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.75,
        "Longitude (Decimal Degrees)": -101.85,
        "Latitude": 684458000,
        "Longitude": -1015110000,
        "Elevation (m)": 78.5,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "JENNY LIND ISLAND A",
        "Province": "NUNAVUT",
        "Climate ID": "2302650",
        "Station ID": 1717,
        "WMO ID": 71071,
        "TC ID": "YUQ",
        "Latitude (Decimal Degrees)": 68.65,
        "Longitude (Decimal Degrees)": -101.73,
        "Latitude": 683900000,
        "Longitude": -1014400000,
        "Elevation (m)": 18.3,
        "First Year": 1957,
        "Last Year": 1992,
        "HLY First Year": 1973,
        "HLY Last Year": 1992,
        "DLY First Year": 1957,
        "DLY Last Year": 1992,
        "MLY First Year": 1957,
        "MLY Last Year": 1992
      },
      {
        "Name": "LADY FRANKLIN POINT A",
        "Province": "NUNAVUT",
        "Climate ID": "2302680",
        "Station ID": 1675,
        "WMO ID": 71937,
        "TC ID": "YUJ",
        "Latitude (Decimal Degrees)": 68.5,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 683000000,
        "Longitude": -1131300000,
        "Elevation (m)": 15.9,
        "First Year": 1957,
        "Last Year": 2000,
        "HLY First Year": 1973,
        "HLY Last Year": 2000,
        "DLY First Year": 1957,
        "DLY Last Year": 2000,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "LUPIN (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2302685",
        "Station ID": 8941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 65.77,
        "Longitude (Decimal Degrees)": -111.23,
        "Latitude": 654600000,
        "Longitude": -1111400000,
        "Elevation (m)": 499.6,
        "First Year": 1993,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": 1993,
        "DLY Last Year": 1995,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LUPIN A",
        "Province": "NUNAVUT",
        "Climate ID": "23026HN",
        "Station ID": 1671,
        "WMO ID": null,
        "TC ID": "YWO",
        "Latitude (Decimal Degrees)": 65.76,
        "Longitude (Decimal Degrees)": -111.25,
        "Latitude": 654533000,
        "Longitude": -1111500000,
        "Elevation (m)": 490.1,
        "First Year": 1982,
        "Last Year": 2006,
        "HLY First Year": 1982,
        "HLY Last Year": 2006,
        "DLY First Year": 1982,
        "DLY Last Year": 2006,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "PELLY BAY",
        "Province": "NUNAVUT",
        "Climate ID": "2303090",
        "Station ID": 1718,
        "WMO ID": null,
        "TC ID": "WKE",
        "Latitude (Decimal Degrees)": 68.43,
        "Longitude (Decimal Degrees)": -89.72,
        "Latitude": 682600000,
        "Longitude": -894300000,
        "Elevation (m)": 325.8,
        "First Year": 1955,
        "Last Year": 1992,
        "HLY First Year": 1955,
        "HLY Last Year": 1992,
        "DLY First Year": 1957,
        "DLY Last Year": 1992,
        "MLY First Year": 1957,
        "MLY Last Year": 1992
      },
      {
        "Name": "KUGAARUK A",
        "Province": "NUNAVUT",
        "Climate ID": "2303091",
        "Station ID": 53515,
        "WMO ID": null,
        "TC ID": "YBB",
        "Latitude (Decimal Degrees)": 68.54,
        "Longitude (Decimal Degrees)": -89.81,
        "Latitude": 683209000,
        "Longitude": -894819000,
        "Elevation (m)": 15.5,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KUGAARUK A",
        "Province": "NUNAVUT",
        "Climate ID": "2303092",
        "Station ID": 1719,
        "WMO ID": null,
        "TC ID": "YBB",
        "Latitude (Decimal Degrees)": 68.54,
        "Longitude (Decimal Degrees)": -89.8,
        "Latitude": 683226000,
        "Longitude": -894750000,
        "Elevation (m)": 15.54,
        "First Year": 1984,
        "Last Year": 2015,
        "HLY First Year": 1984,
        "HLY Last Year": 2015,
        "DLY First Year": 1984,
        "DLY Last Year": 2012,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "PELLY BAY",
        "Province": "NUNAVUT",
        "Climate ID": "2303093",
        "Station ID": 10847,
        "WMO ID": 71918,
        "TC ID": "WRF",
        "Latitude (Decimal Degrees)": 68.44,
        "Longitude (Decimal Degrees)": -89.73,
        "Latitude": 682613000,
        "Longitude": -894333000,
        "Elevation (m)": 324.45,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "KUGAARUK CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2303094",
        "Station ID": 43006,
        "WMO ID": 71407,
        "TC ID": "NBB",
        "Latitude (Decimal Degrees)": 68.54,
        "Longitude (Decimal Degrees)": -89.8,
        "Latitude": 683215060,
        "Longitude": -894747020,
        "Elevation (m)": 15.8,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "KUGAARUK A",
        "Province": "NUNAVUT",
        "Climate ID": "2303095",
        "Station ID": 53518,
        "WMO ID": null,
        "TC ID": "YBB",
        "Latitude (Decimal Degrees)": 68.54,
        "Longitude (Decimal Degrees)": -89.81,
        "Latitude": 683209000,
        "Longitude": -894819000,
        "Elevation (m)": 15.5,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RANKIN INLET",
        "Province": "NUNAVUT",
        "Climate ID": "2303400",
        "Station ID": 1720,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.8,
        "Longitude (Decimal Degrees)": -92.07,
        "Latitude": 624800000,
        "Longitude": -920400000,
        "Elevation (m)": 30.5,
        "First Year": 1954,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1962,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "RANKIN INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2303401",
        "Station ID": 1721,
        "WMO ID": 71083,
        "TC ID": "YRT",
        "Latitude (Decimal Degrees)": 62.82,
        "Longitude (Decimal Degrees)": -92.12,
        "Latitude": 624900000,
        "Longitude": -920700000,
        "Elevation (m)": 32.31,
        "First Year": 1981,
        "Last Year": 2013,
        "HLY First Year": 1981,
        "HLY Last Year": 2013,
        "DLY First Year": 1981,
        "DLY Last Year": 2013,
        "MLY First Year": 1981,
        "MLY Last Year": 2013
      },
      {
        "Name": "RANKIN INLET ARTC",
        "Province": "NUNAVUT",
        "Climate ID": "2303403",
        "Station ID": 1722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.82,
        "Longitude (Decimal Degrees)": -92.08,
        "Latitude": 624900000,
        "Longitude": -920500000,
        "Elevation (m)": 13.7,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "RANKIN INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2303405",
        "Station ID": 51277,
        "WMO ID": 71083,
        "TC ID": "YRT",
        "Latitude (Decimal Degrees)": 62.81,
        "Longitude (Decimal Degrees)": -92.11,
        "Latitude": 624838000,
        "Longitude": -920653000,
        "Elevation (m)": 32.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROBERTSON LAKE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2303610",
        "Station ID": 7337,
        "WMO ID": 71490,
        "TC ID": "WTD",
        "Latitude (Decimal Degrees)": 65.1,
        "Longitude (Decimal Degrees)": -102.43,
        "Latitude": 650559000,
        "Longitude": -1022546000,
        "Elevation (m)": 243.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1992,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROSS POINT",
        "Province": "NUNAVUT",
        "Climate ID": "2303616",
        "Station ID": 1692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.58,
        "Longitude (Decimal Degrees)": -111.1,
        "Latitude": 683500000,
        "Longitude": -1110600000,
        "Elevation (m)": 148.7,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "SANIKILUAQ A",
        "Province": "NUNAVUT",
        "Climate ID": "2303660",
        "Station ID": 53336,
        "WMO ID": null,
        "TC ID": "YSK",
        "Latitude (Decimal Degrees)": 56.54,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 563213000,
        "Longitude": -791500000,
        "Elevation (m)": 33.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANIKILUAQ A",
        "Province": "NUNAVUT",
        "Climate ID": "2303666",
        "Station ID": 53337,
        "WMO ID": null,
        "TC ID": "YSK",
        "Latitude (Decimal Degrees)": 56.54,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 563213000,
        "Longitude": -791500000,
        "Elevation (m)": 33.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANIKILUAQ A",
        "Province": "NUNAVUT",
        "Climate ID": "2303667",
        "Station ID": 8274,
        "WMO ID": null,
        "TC ID": "YSK",
        "Latitude (Decimal Degrees)": 56.54,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 563209000,
        "Longitude": -791449000,
        "Elevation (m)": 33.53,
        "First Year": 1988,
        "Last Year": 2014,
        "HLY First Year": 1988,
        "HLY Last Year": 2014,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANIKILUAQ",
        "Province": "NUNAVUT",
        "Climate ID": "2303668",
        "Station ID": 52658,
        "WMO ID": 71643,
        "TC ID": "NSQ",
        "Latitude (Decimal Degrees)": 56.54,
        "Longitude (Decimal Degrees)": -79.26,
        "Latitude": 563214460,
        "Longitude": -791537074,
        "Elevation (m)": 34,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SHEPHERD BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2303685",
        "Station ID": 1723,
        "WMO ID": 71911,
        "TC ID": "YUS",
        "Latitude (Decimal Degrees)": 68.82,
        "Longitude (Decimal Degrees)": -93.43,
        "Latitude": 684900000,
        "Longitude": -932600000,
        "Elevation (m)": 42.7,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1973,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2006
      },
      {
        "Name": "SIMPSON LAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2303693",
        "Station ID": 10833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.6,
        "Longitude (Decimal Degrees)": -91.95,
        "Latitude": 683600000,
        "Longitude": -915700000,
        "Elevation (m)": 282.5,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIMPSON LAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2303694",
        "Station ID": 1724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.6,
        "Longitude (Decimal Degrees)": -92.02,
        "Latitude": 683600000,
        "Longitude": -920100000,
        "Elevation (m)": 371.2,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "STURT POINT",
        "Province": "NUNAVUT",
        "Climate ID": "2303830",
        "Station ID": 1725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -103.35,
        "Latitude": 684700000,
        "Longitude": -1032100000,
        "Elevation (m)": 19.5,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "STURT POINT NORTH",
        "Province": "NUNAVUT",
        "Climate ID": "2303831",
        "Station ID": 10832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.97,
        "Longitude (Decimal Degrees)": -103.77,
        "Latitude": 685800000,
        "Longitude": -1034600000,
        "Elevation (m)": 106,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHALE COVE",
        "Province": "NUNAVUT",
        "Climate ID": "2303985",
        "Station ID": 1726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.18,
        "Longitude (Decimal Degrees)": -92.6,
        "Latitude": 621100000,
        "Longitude": -923600000,
        "Elevation (m)": 6.1,
        "First Year": 1974,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1984,
        "MLY First Year": 1974,
        "MLY Last Year": 1984
      },
      {
        "Name": "WHALE COVE A",
        "Province": "NUNAVUT",
        "Climate ID": "2303986",
        "Station ID": 1727,
        "WMO ID": null,
        "TC ID": "YXN",
        "Latitude (Decimal Degrees)": 62.24,
        "Longitude (Decimal Degrees)": -92.6,
        "Latitude": 621424000,
        "Longitude": -923553000,
        "Elevation (m)": 12.19,
        "First Year": 1984,
        "Last Year": 2014,
        "HLY First Year": 1984,
        "HLY Last Year": 2013,
        "DLY First Year": 1985,
        "DLY Last Year": 2014,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHALE COVE A",
        "Province": "NUNAVUT",
        "Climate ID": "2303987",
        "Station ID": 51443,
        "WMO ID": null,
        "TC ID": "YXN",
        "Latitude (Decimal Degrees)": 62.24,
        "Longitude (Decimal Degrees)": -92.6,
        "Latitude": 621424000,
        "Longitude": -923553000,
        "Elevation (m)": 12.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHALE COVE A",
        "Province": "NUNAVUT",
        "Climate ID": "2303990",
        "Station ID": 52984,
        "WMO ID": null,
        "TC ID": "YXN",
        "Latitude (Decimal Degrees)": 62.24,
        "Longitude (Decimal Degrees)": -92.6,
        "Latitude": 621424000,
        "Longitude": -923553000,
        "Elevation (m)": 12.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YATHKYED LAKE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2304058",
        "Station ID": 10803,
        "WMO ID": null,
        "TC ID": "WJY",
        "Latitude (Decimal Degrees)": 62.71,
        "Longitude (Decimal Degrees)": -98.29,
        "Latitude": 624222000,
        "Longitude": -981718000,
        "Elevation (m)": 148.2,
        "First Year": 1993,
        "Last Year": 2004,
        "HLY First Year": 1993,
        "HLY Last Year": 2004,
        "DLY First Year": 1993,
        "DLY Last Year": 2004,
        "MLY First Year": 1998,
        "MLY Last Year": 2004
      },
      {
        "Name": "MALLOCH DOME (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "230BF86",
        "Station ID": 7338,
        "WMO ID": null,
        "TC ID": "WCN",
        "Latitude (Decimal Degrees)": 78.22,
        "Longitude (Decimal Degrees)": -101.05,
        "Latitude": 781300000,
        "Longitude": -1010300000,
        "Elevation (m)": 12,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": 1981,
        "HLY Last Year": 1983,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CROKER RIVER",
        "Province": "NUNAVUT",
        "Climate ID": "230J01Q",
        "Station ID": 10826,
        "WMO ID": 71059,
        "TC ID": "WXR",
        "Latitude (Decimal Degrees)": 69.28,
        "Longitude (Decimal Degrees)": -119.22,
        "Latitude": 691632000,
        "Longitude": -1191308000,
        "Elevation (m)": 69.4,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "DUBAWNT LAKE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "230J048",
        "Station ID": 10802,
        "WMO ID": null,
        "TC ID": "WJZ",
        "Latitude (Decimal Degrees)": 63.23,
        "Longitude (Decimal Degrees)": -101.76,
        "Latitude": 631336000,
        "Longitude": -1014544000,
        "Elevation (m)": 237.4,
        "First Year": 1993,
        "Last Year": 2005,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1993,
        "DLY Last Year": 2005,
        "MLY First Year": 1998,
        "MLY Last Year": 2005
      },
      {
        "Name": "GJOA HAVEN",
        "Province": "NUNAVUT",
        "Climate ID": "230KLLM",
        "Station ID": 10830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.66,
        "Longitude (Decimal Degrees)": -95.87,
        "Latitude": 683941000,
        "Longitude": -955211000,
        "Elevation (m)": 51.1,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LUPIN CS",
        "Province": "NUNAVUT",
        "Climate ID": "230N002",
        "Station ID": 27376,
        "WMO ID": 71470,
        "TC ID": "WIJ",
        "Latitude (Decimal Degrees)": 65.76,
        "Longitude (Decimal Degrees)": -111.25,
        "Latitude": 654519050,
        "Longitude": -1111445030,
        "Elevation (m)": 488,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALERT",
        "Province": "NUNAVUT",
        "Climate ID": "2400300",
        "Station ID": 1731,
        "WMO ID": null,
        "TC ID": "WLT",
        "Latitude (Decimal Degrees)": 82.52,
        "Longitude (Decimal Degrees)": -62.28,
        "Latitude": 823104000,
        "Longitude": -621650000,
        "Elevation (m)": 30.48,
        "First Year": 1950,
        "Last Year": 2006,
        "HLY First Year": 1953,
        "HLY Last Year": 2006,
        "DLY First Year": 1950,
        "DLY Last Year": 2006,
        "MLY First Year": 1950,
        "MLY Last Year": 2006
      },
      {
        "Name": "ALERT A",
        "Province": "NUNAVUT",
        "Climate ID": "2400302",
        "Station ID": 7559,
        "WMO ID": null,
        "TC ID": "YLT",
        "Latitude (Decimal Degrees)": 82.52,
        "Longitude (Decimal Degrees)": -62.28,
        "Latitude": 823100000,
        "Longitude": -621700000,
        "Elevation (m)": 30.5,
        "First Year": 1988,
        "Last Year": 2022,
        "HLY First Year": 1988,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALERT BASE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2400304",
        "Station ID": 7350,
        "WMO ID": null,
        "TC ID": "WML",
        "Latitude (Decimal Degrees)": 82.5,
        "Longitude (Decimal Degrees)": -62.37,
        "Latitude": 823000000,
        "Longitude": -622200000,
        "Elevation (m)": 92,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": 1983,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALERT CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2400305",
        "Station ID": 42463,
        "WMO ID": 71355,
        "TC ID": "ZLT",
        "Latitude (Decimal Degrees)": 82.5,
        "Longitude (Decimal Degrees)": -62.33,
        "Latitude": 823000000,
        "Longitude": -622000000,
        "Elevation (m)": 65.4,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALERT UA",
        "Province": "NUNAVUT",
        "Climate ID": "2400306",
        "Station ID": 10744,
        "WMO ID": 71082,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 82.5,
        "Longitude (Decimal Degrees)": -62.33,
        "Latitude": 823000000,
        "Longitude": -622000000,
        "Elevation (m)": 65.4,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARCTIC BAY",
        "Province": "NUNAVUT",
        "Climate ID": "2400399",
        "Station ID": 48973,
        "WMO ID": null,
        "TC ID": "YAB",
        "Latitude (Decimal Degrees)": 73.01,
        "Longitude (Decimal Degrees)": -85.05,
        "Latitude": 730023000,
        "Longitude": -850250000,
        "Elevation (m)": 21.95,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARCTIC BAY",
        "Province": "NUNAVUT",
        "Climate ID": "2400400",
        "Station ID": 1732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 73.03,
        "Longitude (Decimal Degrees)": -85.15,
        "Latitude": 730200000,
        "Longitude": -850900000,
        "Elevation (m)": 11.3,
        "First Year": 1937,
        "Last Year": 1976,
        "HLY First Year": 1953,
        "HLY Last Year": 1976,
        "DLY First Year": 1937,
        "DLY Last Year": 1976,
        "MLY First Year": 1937,
        "MLY Last Year": 1976
      },
      {
        "Name": "ARCTIC BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2400401",
        "Station ID": 50497,
        "WMO ID": null,
        "TC ID": "YAB",
        "Latitude (Decimal Degrees)": 73.01,
        "Longitude (Decimal Degrees)": -85.05,
        "Latitude": 730023000,
        "Longitude": -850250000,
        "Elevation (m)": 21.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARCTIC BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2400402",
        "Station ID": 49328,
        "WMO ID": null,
        "TC ID": "YAB",
        "Latitude (Decimal Degrees)": 73.01,
        "Longitude (Decimal Degrees)": -85.05,
        "Latitude": 730023000,
        "Longitude": -850250000,
        "Elevation (m)": 21.95,
        "First Year": 2011,
        "Last Year": 2012,
        "HLY First Year": 2011,
        "HLY Last Year": 2012,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARCTIC BAY CS",
        "Province": "NUNAVUT",
        "Climate ID": "2400404",
        "Station ID": 29517,
        "WMO ID": 71592,
        "TC ID": "XAT",
        "Latitude (Decimal Degrees)": 72.99,
        "Longitude (Decimal Degrees)": -85.01,
        "Latitude": 725934000,
        "Longitude": -850044000,
        "Elevation (m)": 10,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "BATHURST ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2400552",
        "Station ID": 1733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 75.72,
        "Longitude (Decimal Degrees)": -98.42,
        "Latitude": 754300000,
        "Longitude": -982500000,
        "Elevation (m)": 3,
        "First Year": 1971,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1979,
        "MLY First Year": 1971,
        "MLY Last Year": 1979
      },
      {
        "Name": "BLACK TOP RIDGE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2400559",
        "Station ID": 7351,
        "WMO ID": null,
        "TC ID": "WQN",
        "Latitude (Decimal Degrees)": 80,
        "Longitude (Decimal Degrees)": -85.42,
        "Latitude": 800000000,
        "Longitude": -852500000,
        "Elevation (m)": 619,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": 1983,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BRAY ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2400560",
        "Station ID": 1734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.22,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 691326000,
        "Longitude": -771449000,
        "Elevation (m)": 17.7,
        "First Year": 1959,
        "Last Year": 1995,
        "HLY First Year": 1995,
        "HLY Last Year": 1995,
        "DLY First Year": 1959,
        "DLY Last Year": 1993,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "BRAY ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2400561",
        "Station ID": 54779,
        "WMO ID": 73232,
        "TC ID": "FOX",
        "Latitude (Decimal Degrees)": 69.22,
        "Longitude (Decimal Degrees)": -77.23,
        "Latitude": 691326000,
        "Longitude": -771349000,
        "Elevation (m)": 15.2,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BREVOORT ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2400565",
        "Station ID": 1735,
        "WMO ID": 71097,
        "TC ID": "WOB",
        "Latitude (Decimal Degrees)": 63.34,
        "Longitude (Decimal Degrees)": -64.15,
        "Latitude": 632023000,
        "Longitude": -640845000,
        "Elevation (m)": 376,
        "First Year": 1959,
        "Last Year": 2022,
        "HLY First Year": 1960,
        "HLY Last Year": 2022,
        "DLY First Year": 1959,
        "DLY Last Year": 2022,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROUGHTON ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2400570",
        "Station ID": 1736,
        "WMO ID": 71096,
        "TC ID": "WVD",
        "Latitude (Decimal Degrees)": 67.54,
        "Longitude (Decimal Degrees)": -63.79,
        "Latitude": 673208000,
        "Longitude": -634720000,
        "Elevation (m)": 584.4,
        "First Year": 1956,
        "Last Year": 2022,
        "HLY First Year": 1956,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "QIKIQTARJUAQ AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2400571",
        "Station ID": 47428,
        "WMO ID": 71338,
        "TC ID": "YVM",
        "Latitude (Decimal Degrees)": 67.55,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 673245000,
        "Longitude": -640153000,
        "Elevation (m)": 5.5,
        "First Year": 2008,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": 2008,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QIKIQTARJUAQ A",
        "Province": "NUNAVUT",
        "Climate ID": "2400572",
        "Station ID": 8271,
        "WMO ID": 71338,
        "TC ID": "YVM",
        "Latitude (Decimal Degrees)": 67.55,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 673245000,
        "Longitude": -640153000,
        "Elevation (m)": 5.5,
        "First Year": 1988,
        "Last Year": 2016,
        "HLY First Year": 1988,
        "HLY Last Year": 2013,
        "DLY First Year": 1994,
        "DLY Last Year": 2016,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "QIKIQTARJUAQ CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2400573",
        "Station ID": 42803,
        "WMO ID": 71357,
        "TC ID": "ZVM",
        "Latitude (Decimal Degrees)": 67.55,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 673250700,
        "Longitude": -640200400,
        "Elevation (m)": 6.4,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROUGHTON VILLAGE",
        "Province": "NUNAVUT",
        "Climate ID": "2400574",
        "Station ID": 1737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.55,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 673300000,
        "Longitude": -640200000,
        "Elevation (m)": 9.1,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "QIKIQTARJUAQ A",
        "Province": "NUNAVUT",
        "Climate ID": "2400576",
        "Station ID": 51177,
        "WMO ID": null,
        "TC ID": "YVM",
        "Latitude (Decimal Degrees)": 67.55,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 673248000,
        "Longitude": -640154000,
        "Elevation (m)": 4.9,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QIKIQTARJUAQ A",
        "Province": "NUNAVUT",
        "Climate ID": "2400577",
        "Station ID": 51218,
        "WMO ID": null,
        "TC ID": "YVM",
        "Latitude (Decimal Degrees)": 67.55,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 673248000,
        "Longitude": -640154000,
        "Elevation (m)": 5.5,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BYRON BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2400595",
        "Station ID": 1785,
        "WMO ID": 71929,
        "TC ID": "YUK",
        "Latitude (Decimal Degrees)": 68.75,
        "Longitude (Decimal Degrees)": -109.07,
        "Latitude": 684500000,
        "Longitude": -1090400000,
        "Elevation (m)": 92.1,
        "First Year": 1955,
        "Last Year": 1994,
        "HLY First Year": 1955,
        "HLY Last Year": 1994,
        "DLY First Year": 1957,
        "DLY Last Year": 1993,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "CAMBRIDGE BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2400600",
        "Station ID": 1786,
        "WMO ID": 71925,
        "TC ID": "YCB",
        "Latitude (Decimal Degrees)": 69.11,
        "Longitude (Decimal Degrees)": -105.14,
        "Latitude": 690629000,
        "Longitude": -1050818000,
        "Elevation (m)": 31.09,
        "First Year": 1929,
        "Last Year": 2015,
        "HLY First Year": 1953,
        "HLY Last Year": 2015,
        "DLY First Year": 1929,
        "DLY Last Year": 2015,
        "MLY First Year": 1929,
        "MLY Last Year": 2015
      },
      {
        "Name": "CAMBRIDGE BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2400601",
        "Station ID": 53512,
        "WMO ID": 71925,
        "TC ID": "YCB",
        "Latitude (Decimal Degrees)": 69.11,
        "Longitude (Decimal Degrees)": -105.14,
        "Latitude": 690629000,
        "Longitude": -1050814000,
        "Elevation (m)": 31.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAMBRIDGE BAY GSN",
        "Province": "NUNAVUT",
        "Climate ID": "2400602",
        "Station ID": 32233,
        "WMO ID": 71288,
        "TC ID": "XCM",
        "Latitude (Decimal Degrees)": 69.11,
        "Longitude (Decimal Degrees)": -105.14,
        "Latitude": 690629000,
        "Longitude": -1050818000,
        "Elevation (m)": 18.69,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMBRIDGE BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2400603",
        "Station ID": 54139,
        "WMO ID": null,
        "TC ID": "YCB",
        "Latitude (Decimal Degrees)": 69.11,
        "Longitude (Decimal Degrees)": -105.14,
        "Latitude": 690629000,
        "Longitude": -1050814000,
        "Elevation (m)": 31.1,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINNGAIT AIRPORT",
        "Province": "NUNAVUT",
        "Climate ID": "2400633",
        "Station ID": 50288,
        "WMO ID": null,
        "TC ID": "YTE",
        "Latitude (Decimal Degrees)": 64.23,
        "Longitude (Decimal Degrees)": -79.53,
        "Latitude": 641349000,
        "Longitude": -793130000,
        "Elevation (m)": 48.2,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE DORSET AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2400634",
        "Station ID": 47447,
        "WMO ID": 71910,
        "TC ID": "YTE",
        "Latitude (Decimal Degrees)": 64.23,
        "Longitude (Decimal Degrees)": -76.53,
        "Latitude": 641348000,
        "Longitude": -763136000,
        "Elevation (m)": 48.2,
        "First Year": 2008,
        "Last Year": 2012,
        "HLY First Year": 2008,
        "HLY Last Year": 2012,
        "DLY First Year": 2008,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE DORSET A",
        "Province": "NUNAVUT",
        "Climate ID": "2400635",
        "Station ID": 1738,
        "WMO ID": 71910,
        "TC ID": "YTE",
        "Latitude (Decimal Degrees)": 64.23,
        "Longitude (Decimal Degrees)": -76.53,
        "Latitude": 641349000,
        "Longitude": -763130000,
        "Elevation (m)": 48.2,
        "First Year": 1963,
        "Last Year": 2014,
        "HLY First Year": 1970,
        "HLY Last Year": 2012,
        "DLY First Year": 1963,
        "DLY Last Year": 2014,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "KINNGAIT",
        "Province": "NUNAVUT",
        "Climate ID": "2400636",
        "Station ID": 43163,
        "WMO ID": 71575,
        "TC ID": "NCD",
        "Latitude (Decimal Degrees)": 64.23,
        "Longitude (Decimal Degrees)": -76.53,
        "Latitude": 641350700,
        "Longitude": -763149600,
        "Elevation (m)": 50,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "KINNGAIT AIRPORT",
        "Province": "NUNAVUT",
        "Climate ID": "2400637",
        "Station ID": 50328,
        "WMO ID": 71366,
        "TC ID": "YTE",
        "Latitude (Decimal Degrees)": 64.23,
        "Longitude (Decimal Degrees)": -76.53,
        "Latitude": 641349000,
        "Longitude": -763130000,
        "Elevation (m)": 48.2,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE DYER",
        "Province": "NUNAVUT",
        "Climate ID": "2400650",
        "Station ID": 1739,
        "WMO ID": 71094,
        "TC ID": "WFD",
        "Latitude (Decimal Degrees)": 66.65,
        "Longitude (Decimal Degrees)": -61.38,
        "Latitude": 663900000,
        "Longitude": -612300000,
        "Elevation (m)": 725.1,
        "First Year": 1955,
        "Last Year": 2022,
        "HLY First Year": 1955,
        "HLY Last Year": 2022,
        "DLY First Year": 1958,
        "DLY Last Year": 2022,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "CAPE DYER A",
        "Province": "NUNAVUT",
        "Climate ID": "2400654",
        "Station ID": 1740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.58,
        "Longitude (Decimal Degrees)": -61.62,
        "Latitude": 663500000,
        "Longitude": -613700000,
        "Elevation (m)": 392.6,
        "First Year": 1959,
        "Last Year": 1994,
        "HLY First Year": 1959,
        "HLY Last Year": 1994,
        "DLY First Year": 1959,
        "DLY Last Year": 1993,
        "MLY First Year": 1959,
        "MLY Last Year": 1993
      },
      {
        "Name": "CAPE HERSCHEL",
        "Province": "NUNAVUT",
        "Climate ID": "2400658",
        "Station ID": 1741,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 78.62,
        "Longitude (Decimal Degrees)": -74.7,
        "Latitude": 783700000,
        "Longitude": -744200000,
        "Elevation (m)": 60,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": 1973,
        "HLY Last Year": 1974,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "CAPE HOOPER",
        "Province": "NUNAVUT",
        "Climate ID": "2400660",
        "Station ID": 1742,
        "WMO ID": 71093,
        "TC ID": "WUP",
        "Latitude (Decimal Degrees)": 68.47,
        "Longitude (Decimal Degrees)": -66.8,
        "Latitude": 682821000,
        "Longitude": -664755000,
        "Elevation (m)": 390.1,
        "First Year": 1956,
        "Last Year": 2022,
        "HLY First Year": 1956,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAPE LIVERPOOL",
        "Province": "NUNAVUT",
        "Climate ID": "2400675",
        "Station ID": 51618,
        "WMO ID": null,
        "TC ID": "NPL",
        "Latitude (Decimal Degrees)": 73.67,
        "Longitude (Decimal Degrees)": -78.29,
        "Latitude": 734005030,
        "Longitude": -781736070,
        "Elevation (m)": 2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE PEEL WEST",
        "Province": "NUNAVUT",
        "Climate ID": "2400697",
        "Station ID": 10828,
        "WMO ID": 71064,
        "TC ID": "WPX",
        "Latitude (Decimal Degrees)": 69.04,
        "Longitude (Decimal Degrees)": -107.82,
        "Latitude": 690209000,
        "Longitude": -1074923000,
        "Elevation (m)": 165.3,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE MCLAUGHLIN",
        "Province": "NUNAVUT",
        "Climate ID": "24006FL",
        "Station ID": 10834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.66,
        "Longitude (Decimal Degrees)": -85.59,
        "Latitude": 683953000,
        "Longitude": -853529000,
        "Elevation (m)": 383.2,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLYDE A",
        "Province": "NUNAVUT",
        "Climate ID": "2400800",
        "Station ID": 1743,
        "WMO ID": 71090,
        "TC ID": "YCY",
        "Latitude (Decimal Degrees)": 70.49,
        "Longitude (Decimal Degrees)": -68.52,
        "Latitude": 702910000,
        "Longitude": -683100000,
        "Elevation (m)": 26.5,
        "First Year": 1933,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1933,
        "DLY Last Year": 2008,
        "MLY First Year": 1933,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLYDE AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2400801",
        "Station ID": 47387,
        "WMO ID": 71090,
        "TC ID": "YCY",
        "Latitude (Decimal Degrees)": 70.49,
        "Longitude (Decimal Degrees)": -68.52,
        "Latitude": 702910000,
        "Longitude": -683100000,
        "Elevation (m)": 26.5,
        "First Year": 2008,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": 2008,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLYDE RIVER CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2400802",
        "Station ID": 42244,
        "WMO ID": 71358,
        "TC ID": "ZCY",
        "Latitude (Decimal Degrees)": 70.48,
        "Longitude (Decimal Degrees)": -68.52,
        "Latitude": 702900000,
        "Longitude": -683100000,
        "Elevation (m)": 26.5,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLYDE RIVER A",
        "Province": "NUNAVUT",
        "Climate ID": "2400803",
        "Station ID": 51078,
        "WMO ID": null,
        "TC ID": "YCY",
        "Latitude (Decimal Degrees)": 70.49,
        "Longitude (Decimal Degrees)": -68.52,
        "Latitude": 702909000,
        "Longitude": -683101000,
        "Elevation (m)": 26.5,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLYDE RIVER A",
        "Province": "NUNAVUT",
        "Climate ID": "2400804",
        "Station ID": 51077,
        "WMO ID": 71090,
        "TC ID": "YCY",
        "Latitude (Decimal Degrees)": 70.49,
        "Longitude (Decimal Degrees)": -68.52,
        "Latitude": 702909000,
        "Longitude": -683101000,
        "Elevation (m)": 26.5,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "COBURG ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2400815",
        "Station ID": 1744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 75.92,
        "Longitude (Decimal Degrees)": -79,
        "Latitude": 755500000,
        "Longitude": -790000000,
        "Elevation (m)": 3,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": 1972,
        "HLY Last Year": 1974,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "CAPE MERCY",
        "Province": "NUNAVUT",
        "Climate ID": "2400F63",
        "Station ID": 10839,
        "WMO ID": 71975,
        "TC ID": "WYM",
        "Latitude (Decimal Degrees)": 64.96,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 645728000,
        "Longitude": -633440000,
        "Elevation (m)": 582.6,
        "First Year": 1994,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2018,
        "DLY First Year": 1994,
        "DLY Last Year": 2018,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEVON ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2401025",
        "Station ID": 1745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 74.67,
        "Longitude (Decimal Degrees)": -84.67,
        "Latitude": 744000000,
        "Longitude": -844000000,
        "Elevation (m)": null,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEWAR LAKES",
        "Province": "NUNAVUT",
        "Climate ID": "2401030",
        "Station ID": 1746,
        "WMO ID": 71092,
        "TC ID": "WUW",
        "Latitude (Decimal Degrees)": 68.65,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 683900000,
        "Longitude": -711000000,
        "Elevation (m)": 526.7,
        "First Year": 1956,
        "Last Year": 2022,
        "HLY First Year": 1956,
        "HLY Last Year": 2022,
        "DLY First Year": 1958,
        "DLY Last Year": 2022,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "DUNDAS HARBOUR",
        "Province": "NUNAVUT",
        "Climate ID": "2401050",
        "Station ID": 1747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 74.53,
        "Longitude (Decimal Degrees)": -82.38,
        "Latitude": 743200000,
        "Longitude": -822300000,
        "Elevation (m)": 4.6,
        "First Year": 1930,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1950,
        "MLY First Year": 1930,
        "MLY Last Year": 1950
      },
      {
        "Name": "DURBAN ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2401060",
        "Station ID": 1748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.1,
        "Longitude (Decimal Degrees)": -62.15,
        "Latitude": 670600000,
        "Longitude": -620900000,
        "Elevation (m)": 664.5,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "EAST DRAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2401070",
        "Station ID": 8951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 76.42,
        "Longitude (Decimal Degrees)": -107.82,
        "Latitude": 762500000,
        "Longitude": -1074900000,
        "Elevation (m)": null,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": 1974,
        "HLY Last Year": 1975,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDINBURGH ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2401087",
        "Station ID": 10831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.49,
        "Longitude (Decimal Degrees)": -110.88,
        "Latitude": 682910000,
        "Longitude": -1105254000,
        "Elevation (m)": 193.8,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EKALUGAD FIORD",
        "Province": "NUNAVUT",
        "Climate ID": "2401090",
        "Station ID": 1749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.72,
        "Longitude (Decimal Degrees)": -68.55,
        "Latitude": 684300000,
        "Longitude": -683300000,
        "Elevation (m)": 723.9,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "EUREKA CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2401199",
        "Station ID": 50737,
        "WMO ID": 71613,
        "TC ID": "NEK",
        "Latitude (Decimal Degrees)": 79.99,
        "Longitude (Decimal Degrees)": -85.93,
        "Latitude": 795921000,
        "Longitude": -855602000,
        "Elevation (m)": 10,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EUREKA A",
        "Province": "NUNAVUT",
        "Climate ID": "2401200",
        "Station ID": 1750,
        "WMO ID": 71917,
        "TC ID": "WEU",
        "Latitude (Decimal Degrees)": 79.98,
        "Longitude (Decimal Degrees)": -85.93,
        "Latitude": 795900000,
        "Longitude": -855600000,
        "Elevation (m)": 10.4,
        "First Year": 1947,
        "Last Year": 2016,
        "HLY First Year": 1953,
        "HLY Last Year": 2016,
        "DLY First Year": 1947,
        "DLY Last Year": 2016,
        "MLY First Year": 1947,
        "MLY Last Year": 2016
      },
      {
        "Name": "EUREKA A",
        "Province": "NUNAVUT",
        "Climate ID": "2401203",
        "Station ID": 53598,
        "WMO ID": 71917,
        "TC ID": "WEU",
        "Latitude (Decimal Degrees)": 79.99,
        "Longitude (Decimal Degrees)": -85.81,
        "Latitude": 795940000,
        "Longitude": -854843000,
        "Elevation (m)": 82.9,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EUREKA A",
        "Province": "NUNAVUT",
        "Climate ID": "2401208",
        "Station ID": 53599,
        "WMO ID": null,
        "TC ID": "WEU",
        "Latitude (Decimal Degrees)": 79.99,
        "Longitude (Decimal Degrees)": -85.81,
        "Latitude": 795940000,
        "Longitude": -854843000,
        "Elevation (m)": 82.9,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT ROSS",
        "Province": "NUNAVUT",
        "Climate ID": "2402050",
        "Station ID": 1751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 71.92,
        "Longitude (Decimal Degrees)": -94.25,
        "Latitude": 715500000,
        "Longitude": -941500000,
        "Elevation (m)": 15.2,
        "First Year": 1937,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1948,
        "MLY First Year": 1938,
        "MLY Last Year": 1948
      },
      {
        "Name": "FORT ROSS",
        "Province": "NUNAVUT",
        "Climate ID": "2402051",
        "Station ID": 50658,
        "WMO ID": 71629,
        "TC ID": "NFR",
        "Latitude (Decimal Degrees)": 72.02,
        "Longitude (Decimal Degrees)": -94.2,
        "Latitude": 720104060,
        "Longitude": -941152020,
        "Elevation (m)": 10,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GATESHEAD ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2402332",
        "Station ID": 49828,
        "WMO ID": 71141,
        "TC ID": "NGH",
        "Latitude (Decimal Degrees)": 70.63,
        "Longitude (Decimal Degrees)": -100.27,
        "Latitude": 703758000,
        "Longitude": -1001602000,
        "Elevation (m)": 20,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRISE FIORD A",
        "Province": "NUNAVUT",
        "Climate ID": "2402338",
        "Station ID": 53513,
        "WMO ID": null,
        "TC ID": "WGZ",
        "Latitude (Decimal Degrees)": 76.43,
        "Longitude (Decimal Degrees)": -82.91,
        "Latitude": 762533000,
        "Longitude": -825429000,
        "Elevation (m)": 41.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLADMAN POINT",
        "Province": "NUNAVUT",
        "Climate ID": "2402339",
        "Station ID": 10904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.68,
        "Longitude (Decimal Degrees)": -97.81,
        "Latitude": 684049000,
        "Longitude": -974841000,
        "Elevation (m)": null,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLADMAN POINT A",
        "Province": "NUNAVUT",
        "Climate ID": "2402340",
        "Station ID": 1752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.67,
        "Longitude (Decimal Degrees)": -97.8,
        "Latitude": 684000000,
        "Longitude": -974800000,
        "Elevation (m)": 14,
        "First Year": 1955,
        "Last Year": 1992,
        "HLY First Year": 1955,
        "HLY Last Year": 1992,
        "DLY First Year": 1957,
        "DLY Last Year": 1992,
        "MLY First Year": 1957,
        "MLY Last Year": 1992
      },
      {
        "Name": "GRANT (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2402343",
        "Station ID": 7352,
        "WMO ID": null,
        "TC ID": "WWM",
        "Latitude (Decimal Degrees)": 82.5,
        "Longitude (Decimal Degrees)": -65.08,
        "Latitude": 823000000,
        "Longitude": -650500000,
        "Elevation (m)": 1242.1,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": 1983,
        "HLY Last Year": 1984,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRISE FIORD",
        "Province": "NUNAVUT",
        "Climate ID": "2402345",
        "Station ID": 1753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 76.42,
        "Longitude (Decimal Degrees)": -82.95,
        "Latitude": 762500000,
        "Longitude": -825700000,
        "Elevation (m)": 1.5,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "GRISE FIORD",
        "Province": "NUNAVUT",
        "Climate ID": "2402346",
        "Station ID": 1754,
        "WMO ID": null,
        "TC ID": "WGZ",
        "Latitude (Decimal Degrees)": 76.42,
        "Longitude (Decimal Degrees)": -82.9,
        "Latitude": 762500000,
        "Longitude": -825400000,
        "Elevation (m)": 42.67,
        "First Year": 1984,
        "Last Year": 2015,
        "HLY First Year": 1984,
        "HLY Last Year": 2015,
        "DLY First Year": 1984,
        "DLY Last Year": 2015,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "HALL BEACH A",
        "Province": "NUNAVUT",
        "Climate ID": "2402350",
        "Station ID": 1755,
        "WMO ID": 71081,
        "TC ID": "YUX",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -81.24,
        "Latitude": 684633000,
        "Longitude": -811433000,
        "Elevation (m)": 9.1,
        "First Year": 1956,
        "Last Year": 2014,
        "HLY First Year": 1956,
        "HLY Last Year": 2012,
        "DLY First Year": 1957,
        "DLY Last Year": 2014,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRISE FIORD CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2402351",
        "Station ID": 46568,
        "WMO ID": 71971,
        "TC ID": "NGC",
        "Latitude (Decimal Degrees)": 76.42,
        "Longitude (Decimal Degrees)": -82.9,
        "Latitude": 762522040,
        "Longitude": -825408020,
        "Elevation (m)": 44.5,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALL BEACH AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2402352",
        "Station ID": 47467,
        "WMO ID": 71081,
        "TC ID": "YUX",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -81.24,
        "Latitude": 684634000,
        "Longitude": -811437000,
        "Elevation (m)": 9.1,
        "First Year": 2008,
        "Last Year": 2012,
        "HLY First Year": 2008,
        "HLY Last Year": 2012,
        "DLY First Year": 2008,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANIRAJAK",
        "Province": "NUNAVUT",
        "Climate ID": "2402353",
        "Station ID": 42483,
        "WMO ID": 71320,
        "TC ID": "XUX",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -81.24,
        "Latitude": 684634000,
        "Longitude": -811437000,
        "Elevation (m)": 8.2,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "SANIRAJAK AIRPORT",
        "Province": "NUNAVUT",
        "Climate ID": "2402354",
        "Station ID": 50498,
        "WMO ID": 71293,
        "TC ID": "YUX",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -81.24,
        "Latitude": 684633000,
        "Longitude": -811433000,
        "Elevation (m)": 9.1,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANIRAJAK AIRPORT",
        "Province": "NUNAVUT",
        "Climate ID": "2402356",
        "Station ID": 50499,
        "WMO ID": null,
        "TC ID": "YUX",
        "Latitude (Decimal Degrees)": 68.78,
        "Longitude (Decimal Degrees)": -81.24,
        "Latitude": 684633000,
        "Longitude": -811433000,
        "Elevation (m)": 9.1,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRISE FIORD A",
        "Province": "NUNAVUT",
        "Climate ID": "2402360",
        "Station ID": 53514,
        "WMO ID": null,
        "TC ID": "WGZ",
        "Latitude (Decimal Degrees)": 76.43,
        "Longitude (Decimal Degrees)": -82.91,
        "Latitude": 762533000,
        "Longitude": -825429000,
        "Elevation (m)": 41.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IDA (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2402536",
        "Station ID": 7353,
        "WMO ID": null,
        "TC ID": "WGS",
        "Latitude (Decimal Degrees)": 81.58,
        "Longitude (Decimal Degrees)": -69.38,
        "Latitude": 813500000,
        "Longitude": -692300000,
        "Elevation (m)": 839.1,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": 1983,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IGLOOLIK",
        "Province": "NUNAVUT",
        "Climate ID": "2402540",
        "Station ID": 1756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.38,
        "Longitude (Decimal Degrees)": -81.8,
        "Latitude": 692300000,
        "Longitude": -814800000,
        "Elevation (m)": 21.3,
        "First Year": 1977,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2003,
        "MLY First Year": 1977,
        "MLY Last Year": 2003
      },
      {
        "Name": "IGLOOLIK A",
        "Province": "NUNAVUT",
        "Climate ID": "2402541",
        "Station ID": 53334,
        "WMO ID": null,
        "TC ID": "YGT",
        "Latitude (Decimal Degrees)": 69.36,
        "Longitude (Decimal Degrees)": -81.82,
        "Latitude": 692153000,
        "Longitude": -814859000,
        "Elevation (m)": 52.7,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IGLOOLIK A",
        "Province": "NUNAVUT",
        "Climate ID": "2402543",
        "Station ID": 1757,
        "WMO ID": null,
        "TC ID": "YGT",
        "Latitude (Decimal Degrees)": 69.37,
        "Longitude (Decimal Degrees)": -81.82,
        "Latitude": 692200000,
        "Longitude": -814900000,
        "Elevation (m)": 52.74,
        "First Year": 1984,
        "Last Year": 2015,
        "HLY First Year": 1984,
        "HLY Last Year": 2015,
        "DLY First Year": 1984,
        "DLY Last Year": 2015,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "IGLOOLIK A",
        "Province": "NUNAVUT",
        "Climate ID": "2402546",
        "Station ID": 53333,
        "WMO ID": null,
        "TC ID": "YGT",
        "Latitude (Decimal Degrees)": 69.36,
        "Longitude (Decimal Degrees)": -81.82,
        "Latitude": 692153000,
        "Longitude": -814859000,
        "Elevation (m)": 52.7,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IQALUIT A",
        "Province": "NUNAVUT",
        "Climate ID": "2402590",
        "Station ID": 1758,
        "WMO ID": 71909,
        "TC ID": "YFB",
        "Latitude (Decimal Degrees)": 63.75,
        "Longitude (Decimal Degrees)": -68.55,
        "Latitude": 634500000,
        "Longitude": -683300000,
        "Elevation (m)": 33.5,
        "First Year": 1946,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1946,
        "DLY Last Year": 2008,
        "MLY First Year": 1946,
        "MLY Last Year": 2007
      },
      {
        "Name": "IQALUIT AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2402591",
        "Station ID": 47388,
        "WMO ID": 71909,
        "TC ID": "YFB",
        "Latitude (Decimal Degrees)": 63.75,
        "Longitude (Decimal Degrees)": -68.55,
        "Latitude": 634500000,
        "Longitude": -683300000,
        "Elevation (m)": 33.5,
        "First Year": 2008,
        "Last Year": 2015,
        "HLY First Year": 2008,
        "HLY Last Year": 2015,
        "DLY First Year": 2008,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IQALUIT CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2402592",
        "Station ID": 42503,
        "WMO ID": 71321,
        "TC ID": "XFB",
        "Latitude (Decimal Degrees)": 63.75,
        "Longitude (Decimal Degrees)": -68.54,
        "Latitude": 634450000,
        "Longitude": -683240000,
        "Elevation (m)": 33.5,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "IQALUIT UA",
        "Province": "NUNAVUT",
        "Climate ID": "2402594",
        "Station ID": 10082,
        "WMO ID": 71909,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.75,
        "Longitude (Decimal Degrees)": -68.55,
        "Latitude": 634500000,
        "Longitude": -683300000,
        "Elevation (m)": 21.9,
        "First Year": 1997,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2016,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "IQALUIT A",
        "Province": "NUNAVUT",
        "Climate ID": "2402596",
        "Station ID": 52079,
        "WMO ID": null,
        "TC ID": "YFB",
        "Latitude (Decimal Degrees)": 63.76,
        "Longitude (Decimal Degrees)": -68.56,
        "Latitude": 634524000,
        "Longitude": -683322000,
        "Elevation (m)": 33.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ISACHSEN",
        "Province": "NUNAVUT",
        "Climate ID": "2402600",
        "Station ID": 1790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 78.78,
        "Longitude (Decimal Degrees)": -103.53,
        "Latitude": 784700000,
        "Longitude": -1033200000,
        "Elevation (m)": 25.3,
        "First Year": 1948,
        "Last Year": 1978,
        "HLY First Year": 1953,
        "HLY Last Year": 1978,
        "DLY First Year": 1948,
        "DLY Last Year": 1978,
        "MLY First Year": 1948,
        "MLY Last Year": 1978
      },
      {
        "Name": "ISACHSEN (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2402604",
        "Station ID": 10253,
        "WMO ID": 71074,
        "TC ID": "WIC",
        "Latitude (Decimal Degrees)": 78.79,
        "Longitude (Decimal Degrees)": -103.55,
        "Latitude": 784732004,
        "Longitude": -1033316008,
        "Elevation (m)": 58,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEITH BAY",
        "Province": "NUNAVUT",
        "Climate ID": "2402665",
        "Station ID": 1759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.4,
        "Longitude (Decimal Degrees)": -88.22,
        "Latitude": 682400000,
        "Longitude": -881300000,
        "Elevation (m)": 80.8,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "KILLINEK",
        "Province": "NUNAVUT",
        "Climate ID": "2402667",
        "Station ID": 1760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.42,
        "Longitude (Decimal Degrees)": -64.85,
        "Latitude": 602500000,
        "Longitude": -645100000,
        "Elevation (m)": 9.4,
        "First Year": 1975,
        "Last Year": 1985,
        "HLY First Year": 1975,
        "HLY Last Year": 1985,
        "DLY First Year": 1975,
        "DLY Last Year": 1985,
        "MLY First Year": 1975,
        "MLY Last Year": 1984
      },
      {
        "Name": "KIMMIRUT A",
        "Province": "NUNAVUT",
        "Climate ID": "2402671",
        "Station ID": 51424,
        "WMO ID": null,
        "TC ID": "YLC",
        "Latitude (Decimal Degrees)": 62.85,
        "Longitude (Decimal Degrees)": -69.88,
        "Latitude": 625053000,
        "Longitude": -695238000,
        "Elevation (m)": 51.5,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KIMMIRUT A",
        "Province": "NUNAVUT",
        "Climate ID": "2402673",
        "Station ID": 53517,
        "WMO ID": null,
        "TC ID": "YLC",
        "Latitude (Decimal Degrees)": 62.85,
        "Longitude (Decimal Degrees)": -69.88,
        "Latitude": 625053000,
        "Longitude": -695238000,
        "Elevation (m)": 51.5,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KIVITOO",
        "Province": "NUNAVUT",
        "Climate ID": "2402675",
        "Station ID": 1761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.97,
        "Longitude (Decimal Degrees)": -64.92,
        "Latitude": 675800000,
        "Longitude": -645500000,
        "Elevation (m)": 442,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "LAKE HARBOUR",
        "Province": "NUNAVUT",
        "Climate ID": "2402681",
        "Station ID": 1762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.83,
        "Longitude (Decimal Degrees)": -69.92,
        "Latitude": 625000000,
        "Longitude": -695500000,
        "Elevation (m)": 16.5,
        "First Year": 1913,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1946,
        "MLY First Year": 1913,
        "MLY Last Year": 1946
      },
      {
        "Name": "LAKE HAZEN",
        "Province": "NUNAVUT",
        "Climate ID": "2402682",
        "Station ID": 1763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 81.82,
        "Longitude (Decimal Degrees)": -71.3,
        "Latitude": 814900000,
        "Longitude": -711800000,
        "Elevation (m)": 159.1,
        "First Year": 1957,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1963,
        "MLY First Year": 1963,
        "MLY Last Year": 1963
      },
      {
        "Name": "LONGSTAFF BLUFF",
        "Province": "NUNAVUT",
        "Climate ID": "2402684",
        "Station ID": 1764,
        "WMO ID": 71091,
        "TC ID": "WLX",
        "Latitude (Decimal Degrees)": 68.9,
        "Longitude (Decimal Degrees)": -75.14,
        "Latitude": 685355000,
        "Longitude": -750827000,
        "Elevation (m)": 160.8,
        "First Year": 1955,
        "Last Year": 2022,
        "HLY First Year": 1955,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "MACKAR INLET",
        "Province": "NUNAVUT",
        "Climate ID": "2402686",
        "Station ID": 1765,
        "WMO ID": 71080,
        "TC ID": "WUU",
        "Latitude (Decimal Degrees)": 68.3,
        "Longitude (Decimal Degrees)": -85.67,
        "Latitude": 681800000,
        "Longitude": -854000000,
        "Elevation (m)": 394.7,
        "First Year": 1956,
        "Last Year": 1992,
        "HLY First Year": 1956,
        "HLY Last Year": 1992,
        "DLY First Year": 1957,
        "DLY Last Year": 1992,
        "MLY First Year": 1957,
        "MLY Last Year": 1992
      },
      {
        "Name": "MARY RIVER",
        "Province": "NUNAVUT",
        "Climate ID": "2402687",
        "Station ID": 1766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 71.32,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 711900000,
        "Longitude": -792000000,
        "Elevation (m)": 243.8,
        "First Year": 1958,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1958,
        "MLY Last Year": 1965
      },
      {
        "Name": "MATHESON POINT",
        "Province": "NUNAVUT",
        "Climate ID": "2402688",
        "Station ID": 1767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.83,
        "Longitude (Decimal Degrees)": -95.32,
        "Latitude": 685000000,
        "Longitude": -951900000,
        "Elevation (m)": 70.1,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "MEIGHEN ISLAND ICE CAP",
        "Province": "NUNAVUT",
        "Climate ID": "2402689",
        "Station ID": 1768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 79.97,
        "Longitude (Decimal Degrees)": -99.15,
        "Latitude": 795800000,
        "Longitude": -990900000,
        "Elevation (m)": 240.8,
        "First Year": 1960,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "MT ARTHUR (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2402710",
        "Station ID": 7354,
        "WMO ID": null,
        "TC ID": "WNG",
        "Latitude (Decimal Degrees)": 81.33,
        "Longitude (Decimal Degrees)": -74.77,
        "Latitude": 812000000,
        "Longitude": -744600000,
        "Elevation (m)": 1335.9,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": 1983,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NADLUARDJUK LAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2402718",
        "Station ID": 10851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.62,
        "Longitude (Decimal Degrees)": -73.21,
        "Latitude": 683711000,
        "Longitude": -731241000,
        "Elevation (m)": 138.8,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NANISIVIK A",
        "Province": "NUNAVUT",
        "Climate ID": "2402730",
        "Station ID": 1769,
        "WMO ID": null,
        "TC ID": "YSR",
        "Latitude (Decimal Degrees)": 72.98,
        "Longitude (Decimal Degrees)": -84.62,
        "Latitude": 725900000,
        "Longitude": -843700000,
        "Elevation (m)": 641.9,
        "First Year": 1976,
        "Last Year": 2011,
        "HLY First Year": 1977,
        "HLY Last Year": 2011,
        "DLY First Year": 1976,
        "DLY Last Year": 2010,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "NANISIVIK AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2402731",
        "Station ID": 47468,
        "WMO ID": null,
        "TC ID": "YSR",
        "Latitude (Decimal Degrees)": 72.98,
        "Longitude (Decimal Degrees)": -84.62,
        "Latitude": 725900000,
        "Longitude": -843700000,
        "Elevation (m)": 641.9,
        "First Year": 2008,
        "Last Year": 2011,
        "HLY First Year": 2008,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NOTTINGHAM ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2402900",
        "Station ID": 1770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 63.12,
        "Longitude (Decimal Degrees)": -77.93,
        "Latitude": 630700000,
        "Longitude": -775600000,
        "Elevation (m)": 16.5,
        "First Year": 1927,
        "Last Year": 1970,
        "HLY First Year": 1953,
        "HLY Last Year": 1970,
        "DLY First Year": 1927,
        "DLY Last Year": 1970,
        "MLY First Year": 1927,
        "MLY Last Year": 1970
      },
      {
        "Name": "KILLINEK (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2402F67",
        "Station ID": 8995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.42,
        "Longitude (Decimal Degrees)": -64.85,
        "Latitude": 602500000,
        "Longitude": -645100000,
        "Elevation (m)": 31.7,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": 1985,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LONGSTAFF BLUFF A",
        "Province": "NUNAVUT",
        "Climate ID": "2402F84",
        "Station ID": 8950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.93,
        "Longitude (Decimal Degrees)": -75.28,
        "Latitude": 685600000,
        "Longitude": -751700000,
        "Elevation (m)": 14.3,
        "First Year": 1983,
        "Last Year": 1991,
        "HLY First Year": 1983,
        "HLY Last Year": 1991,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PADLOPING ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403000",
        "Station ID": 1771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 67.03,
        "Longitude (Decimal Degrees)": -62.73,
        "Latitude": 670200000,
        "Longitude": -624400000,
        "Elevation (m)": 39.6,
        "First Year": 1941,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1956,
        "MLY First Year": 1942,
        "MLY Last Year": 1956
      },
      {
        "Name": "PANGNIRTUNG",
        "Province": "NUNAVUT",
        "Climate ID": "2403049",
        "Station ID": 10721,
        "WMO ID": 71826,
        "TC ID": "WXP",
        "Latitude (Decimal Degrees)": 66.14,
        "Longitude (Decimal Degrees)": -65.71,
        "Latitude": 660836300,
        "Longitude": -654240500,
        "Elevation (m)": 22.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "PANGNIRTUNG 1925 CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2403050",
        "Station ID": 1772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 66.13,
        "Longitude (Decimal Degrees)": -65.73,
        "Latitude": 660800000,
        "Longitude": -654400000,
        "Elevation (m)": 15.2,
        "First Year": 1925,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1983,
        "MLY First Year": 1926,
        "MLY Last Year": 1983
      },
      {
        "Name": "PANGNIRTUNG A",
        "Province": "NUNAVUT",
        "Climate ID": "2403051",
        "Station ID": 51158,
        "WMO ID": null,
        "TC ID": "YXP",
        "Latitude (Decimal Degrees)": 66.15,
        "Longitude (Decimal Degrees)": -65.71,
        "Latitude": 660842000,
        "Longitude": -654249000,
        "Elevation (m)": 24.1,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PANGNIRTUNG AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2403052",
        "Station ID": 47487,
        "WMO ID": null,
        "TC ID": "YXP",
        "Latitude (Decimal Degrees)": 66.13,
        "Longitude (Decimal Degrees)": -65.7,
        "Latitude": 660800000,
        "Longitude": -654200000,
        "Elevation (m)": 24.1,
        "First Year": 2008,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PANGNIRTUNG A",
        "Province": "NUNAVUT",
        "Climate ID": "2403053",
        "Station ID": 8273,
        "WMO ID": null,
        "TC ID": "YXP",
        "Latitude (Decimal Degrees)": 66.15,
        "Longitude (Decimal Degrees)": -65.71,
        "Latitude": 660842000,
        "Longitude": -654249000,
        "Elevation (m)": 24.1,
        "First Year": 1987,
        "Last Year": 2014,
        "HLY First Year": 1987,
        "HLY Last Year": 2013,
        "DLY First Year": 1995,
        "DLY Last Year": 2014,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "PANGNIRTUNG A",
        "Province": "NUNAVUT",
        "Climate ID": "2403054",
        "Station ID": 51217,
        "WMO ID": null,
        "TC ID": "YXP",
        "Latitude (Decimal Degrees)": 66.15,
        "Longitude (Decimal Degrees)": -65.71,
        "Latitude": 660842000,
        "Longitude": -654249000,
        "Elevation (m)": 24,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POLARIS MINE LITTLE CORNWALLIS ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403160",
        "Station ID": 6852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 75.38,
        "Longitude (Decimal Degrees)": -96.92,
        "Latitude": 752300000,
        "Longitude": -965500000,
        "Elevation (m)": 60.9,
        "First Year": 1991,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1996,
        "MLY First Year": 1991,
        "MLY Last Year": 1996
      },
      {
        "Name": "POND INLET",
        "Province": "NUNAVUT",
        "Climate ID": "2403200",
        "Station ID": 1773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 72.68,
        "Longitude (Decimal Degrees)": -77.98,
        "Latitude": 724100000,
        "Longitude": -775900000,
        "Elevation (m)": 4,
        "First Year": 1922,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1965,
        "MLY First Year": 1923,
        "MLY Last Year": 1965
      },
      {
        "Name": "POND INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2403201",
        "Station ID": 1774,
        "WMO ID": 71095,
        "TC ID": "YIO",
        "Latitude (Decimal Degrees)": 72.69,
        "Longitude (Decimal Degrees)": -77.97,
        "Latitude": 724122000,
        "Longitude": -775808000,
        "Elevation (m)": 61.6,
        "First Year": 1975,
        "Last Year": 2014,
        "HLY First Year": 1975,
        "HLY Last Year": 2013,
        "DLY First Year": 1975,
        "DLY Last Year": 2014,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "POND INLET AWOS",
        "Province": "NUNAVUT",
        "Climate ID": "2403202",
        "Station ID": 47488,
        "WMO ID": 71095,
        "TC ID": "YIO",
        "Latitude (Decimal Degrees)": 72.69,
        "Longitude (Decimal Degrees)": -77.97,
        "Latitude": 724122000,
        "Longitude": -775808000,
        "Elevation (m)": 61.6,
        "First Year": 2008,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": 2008,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POND INLET CLIMATE",
        "Province": "NUNAVUT",
        "Climate ID": "2403204",
        "Station ID": 43223,
        "WMO ID": 71576,
        "TC ID": "NDT",
        "Latitude (Decimal Degrees)": 72.69,
        "Longitude (Decimal Degrees)": -77.96,
        "Latitude": 724136000,
        "Longitude": -775727000,
        "Elevation (m)": 64.66,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "POND INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2403205",
        "Station ID": 51081,
        "WMO ID": null,
        "TC ID": "YIO",
        "Latitude (Decimal Degrees)": 72.69,
        "Longitude (Decimal Degrees)": -77.97,
        "Latitude": 724122000,
        "Longitude": -775808000,
        "Elevation (m)": 61.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POND INLET A",
        "Province": "NUNAVUT",
        "Climate ID": "2403206",
        "Station ID": 51080,
        "WMO ID": 71095,
        "TC ID": "YIO",
        "Latitude (Decimal Degrees)": 72.69,
        "Longitude (Decimal Degrees)": -77.97,
        "Latitude": 724122000,
        "Longitude": -775808000,
        "Elevation (m)": 61.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "REA POINT",
        "Province": "NUNAVUT",
        "Climate ID": "2403450",
        "Station ID": 1793,
        "WMO ID": 71983,
        "TC ID": "ZRP",
        "Latitude (Decimal Degrees)": 75.38,
        "Longitude (Decimal Degrees)": -105.72,
        "Latitude": 752236009,
        "Longitude": -1054254002,
        "Elevation (m)": 15,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": 1969,
        "HLY Last Year": 2022,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "REPULSE BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2403490",
        "Station ID": 1775,
        "WMO ID": null,
        "TC ID": "YUT",
        "Latitude (Decimal Degrees)": 66.52,
        "Longitude (Decimal Degrees)": -86.22,
        "Latitude": 663117000,
        "Longitude": -861329000,
        "Elevation (m)": 22.9,
        "First Year": 1973,
        "Last Year": 2014,
        "HLY First Year": 1984,
        "HLY Last Year": 2013,
        "DLY First Year": 1973,
        "DLY Last Year": 2014,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "NAUJAAT A",
        "Province": "NUNAVUT",
        "Climate ID": "2403491",
        "Station ID": 52001,
        "WMO ID": null,
        "TC ID": "YUT",
        "Latitude (Decimal Degrees)": 66.52,
        "Longitude (Decimal Degrees)": -86.22,
        "Latitude": 663114000,
        "Longitude": -861329000,
        "Elevation (m)": 22.9,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NAUJAAT A",
        "Province": "NUNAVUT",
        "Climate ID": "2403495",
        "Station ID": 52983,
        "WMO ID": null,
        "TC ID": "YUT",
        "Latitude (Decimal Degrees)": 66.52,
        "Longitude (Decimal Degrees)": -86.22,
        "Latitude": 663114000,
        "Longitude": -861329000,
        "Elevation (m)": 22.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RESOLUTE BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2403497",
        "Station ID": 54199,
        "WMO ID": null,
        "TC ID": "YRB",
        "Latitude (Decimal Degrees)": 74.72,
        "Longitude (Decimal Degrees)": -94.97,
        "Latitude": 744301000,
        "Longitude": -945810000,
        "Elevation (m)": 67.7,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RESOLUTE BAY A",
        "Province": "NUNAVUT",
        "Climate ID": "2403498",
        "Station ID": 53060,
        "WMO ID": 71924,
        "TC ID": "YRB",
        "Latitude (Decimal Degrees)": 74.72,
        "Longitude (Decimal Degrees)": -94.97,
        "Latitude": 744301000,
        "Longitude": -945810000,
        "Elevation (m)": 67.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RESOLUTE CARS",
        "Province": "NUNAVUT",
        "Climate ID": "2403500",
        "Station ID": 1776,
        "WMO ID": 71924,
        "TC ID": "YRB",
        "Latitude (Decimal Degrees)": 74.72,
        "Longitude (Decimal Degrees)": -94.97,
        "Latitude": 744301000,
        "Longitude": -945810000,
        "Elevation (m)": 67.68,
        "First Year": 1947,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1947,
        "DLY Last Year": 2014,
        "MLY First Year": 1947,
        "MLY Last Year": 2014
      },
      {
        "Name": "RESOLUTE CS",
        "Province": "NUNAVUT",
        "Climate ID": "2403505",
        "Station ID": 32150,
        "WMO ID": 71018,
        "TC ID": "XRB",
        "Latitude (Decimal Degrees)": 74.72,
        "Longitude (Decimal Degrees)": -94.98,
        "Latitude": 744257005,
        "Longitude": -945859007,
        "Elevation (m)": 30,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "RESOLUTION ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403600",
        "Station ID": 1777,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.3,
        "Longitude (Decimal Degrees)": -64.88,
        "Latitude": 611800000,
        "Longitude": -645300000,
        "Elevation (m)": 38.7,
        "First Year": 1929,
        "Last Year": 1961,
        "HLY First Year": 1953,
        "HLY Last Year": 1961,
        "DLY First Year": 1929,
        "DLY Last Year": 1961,
        "MLY First Year": 1929,
        "MLY Last Year": 1961
      },
      {
        "Name": "RESOLUTION ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403602",
        "Station ID": 1778,
        "WMO ID": 71972,
        "TC ID": "WRH",
        "Latitude (Decimal Degrees)": 61.58,
        "Longitude (Decimal Degrees)": -64.65,
        "Latitude": 613500000,
        "Longitude": -643900000,
        "Elevation (m)": 368.5,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": 1962,
        "HLY Last Year": 2022,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "ROWLEY ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403625",
        "Station ID": 1779,
        "WMO ID": 71088,
        "TC ID": "WRX",
        "Latitude (Decimal Degrees)": 69.08,
        "Longitude (Decimal Degrees)": -79.03,
        "Latitude": 690500000,
        "Longitude": -790200000,
        "Elevation (m)": 41,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "SARCPA LAKE",
        "Province": "NUNAVUT",
        "Climate ID": "2403680",
        "Station ID": 1780,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.55,
        "Longitude (Decimal Degrees)": -83.32,
        "Latitude": 683300000,
        "Longitude": -831900000,
        "Elevation (m)": 271.3,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "SKULL POINT (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2403697",
        "Station ID": 7357,
        "WMO ID": null,
        "TC ID": "WJE",
        "Latitude (Decimal Degrees)": 80,
        "Longitude (Decimal Degrees)": -86.43,
        "Latitude": 800000000,
        "Longitude": -862600000,
        "Elevation (m)": 36.9,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": 1983,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STEFANSSON ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403756",
        "Station ID": 32148,
        "WMO ID": 71017,
        "TC ID": "XSE",
        "Latitude (Decimal Degrees)": 73.77,
        "Longitude (Decimal Degrees)": -105.3,
        "Latitude": 734556500,
        "Longitude": -1051744000,
        "Elevation (m)": 11,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPENCE BAY",
        "Province": "NUNAVUT",
        "Climate ID": "2403800",
        "Station ID": 1781,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.53,
        "Longitude (Decimal Degrees)": -93.52,
        "Latitude": 693200000,
        "Longitude": -933100000,
        "Elevation (m)": 12.2,
        "First Year": 1951,
        "Last Year": 1976,
        "HLY First Year": 1953,
        "HLY Last Year": 1963,
        "DLY First Year": 1951,
        "DLY Last Year": 1976,
        "MLY First Year": 1951,
        "MLY Last Year": 1976
      },
      {
        "Name": "STRATHCONA SOUND",
        "Province": "NUNAVUT",
        "Climate ID": "2403820",
        "Station ID": 1782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 73.03,
        "Longitude (Decimal Degrees)": -84.55,
        "Latitude": 730200000,
        "Longitude": -843300000,
        "Elevation (m)": 357.8,
        "First Year": 1975,
        "Last Year": 1975,
        "HLY First Year": 1975,
        "HLY Last Year": 1975,
        "DLY First Year": 1975,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "SVARTEVAEG",
        "Province": "NUNAVUT",
        "Climate ID": "2403833",
        "Station ID": 50659,
        "WMO ID": 71872,
        "TC ID": "NSG",
        "Latitude (Decimal Degrees)": 81.16,
        "Longitude (Decimal Degrees)": -91.82,
        "Latitude": 810939000,
        "Longitude": -914859060,
        "Elevation (m)": 72,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TALOYOAK A",
        "Province": "NUNAVUT",
        "Climate ID": "2403854",
        "Station ID": 1783,
        "WMO ID": 71580,
        "TC ID": "YYH",
        "Latitude (Decimal Degrees)": 69.55,
        "Longitude (Decimal Degrees)": -93.58,
        "Latitude": 693300000,
        "Longitude": -933500000,
        "Elevation (m)": 27.4,
        "First Year": 1984,
        "Last Year": 2015,
        "HLY First Year": 1984,
        "HLY Last Year": 2015,
        "DLY First Year": 1984,
        "DLY Last Year": 2015,
        "MLY First Year": 1984,
        "MLY Last Year": 2015
      },
      {
        "Name": "TALOYOAK A",
        "Province": "NUNAVUT",
        "Climate ID": "2403855",
        "Station ID": 53340,
        "WMO ID": 71580,
        "TC ID": "YYH",
        "Latitude (Decimal Degrees)": 69.55,
        "Longitude (Decimal Degrees)": -93.58,
        "Latitude": 693248000,
        "Longitude": -933437000,
        "Elevation (m)": 27.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": 2015,
        "MLY Last Year": 2015
      },
      {
        "Name": "TALOYOAK A",
        "Province": "NUNAVUT",
        "Climate ID": "2403856",
        "Station ID": 54219,
        "WMO ID": null,
        "TC ID": "YYH",
        "Latitude (Decimal Degrees)": 69.55,
        "Longitude (Decimal Degrees)": -93.58,
        "Latitude": 693248000,
        "Longitude": -933437000,
        "Elevation (m)": 27.4,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WAGER BAY (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2403945",
        "Station ID": 31167,
        "WMO ID": 71049,
        "TC ID": "XWB",
        "Latitude (Decimal Degrees)": 65.88,
        "Longitude (Decimal Degrees)": -89.45,
        "Latitude": 655245000,
        "Longitude": -892645000,
        "Elevation (m)": 18,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "WEST BAFFIN ISLAND",
        "Province": "NUNAVUT",
        "Climate ID": "2403948",
        "Station ID": 1784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.62,
        "Longitude (Decimal Degrees)": -73.2,
        "Latitude": 683700000,
        "Longitude": -731200000,
        "Elevation (m)": 155.4,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "YANKEE (AUT)",
        "Province": "NUNAVUT",
        "Climate ID": "2404030",
        "Station ID": 7359,
        "WMO ID": null,
        "TC ID": "WPT",
        "Latitude (Decimal Degrees)": 80.7,
        "Longitude (Decimal Degrees)": -81,
        "Latitude": 804200000,
        "Longitude": -810000000,
        "Elevation (m)": 1052.8,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": 1983,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAILOR RIVER",
        "Province": "NUNAVUT",
        "Climate ID": "240B6Q0",
        "Station ID": 10835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 69.11,
        "Longitude (Decimal Degrees)": -83.54,
        "Latitude": 690639000,
        "Longitude": -833223000,
        "Elevation (m)": 335.2,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MEIGHEN ISLAND NORTH",
        "Province": "NUNAVUT",
        "Climate ID": "240BFH9",
        "Station ID": 1729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 80.07,
        "Longitude (Decimal Degrees)": -99.3,
        "Latitude": 800400000,
        "Longitude": -991800000,
        "Elevation (m)": 78.3,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "MEIGHEN ISLAND NORTH ICE",
        "Province": "NUNAVUT",
        "Climate ID": "240BFHR",
        "Station ID": 1728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 80.03,
        "Longitude (Decimal Degrees)": -99.23,
        "Latitude": 800200000,
        "Longitude": -991400000,
        "Elevation (m)": 150.9,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "MEIGHEN ISLAND WEST",
        "Province": "NUNAVUT",
        "Climate ID": "240BFQ9",
        "Station ID": 1730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 79.97,
        "Longitude (Decimal Degrees)": -99.5,
        "Latitude": 795800000,
        "Longitude": -993000000,
        "Elevation (m)": 100.6,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGOK FIORD",
        "Province": "NUNAVUT",
        "Climate ID": "240KFNP",
        "Station ID": 10838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 68.65,
        "Longitude (Decimal Degrees)": -69.13,
        "Latitude": 683900000,
        "Longitude": -690800000,
        "Elevation (m)": 914.4,
        "First Year": 1994,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KIMMIRUT A",
        "Province": "NUNAVUT",
        "Climate ID": "240KFQJ",
        "Station ID": 8272,
        "WMO ID": null,
        "TC ID": "YLC",
        "Latitude (Decimal Degrees)": 62.85,
        "Longitude (Decimal Degrees)": -69.88,
        "Latitude": 625100000,
        "Longitude": -695300000,
        "Elevation (m)": 51.5,
        "First Year": 1987,
        "Last Year": 2015,
        "HLY First Year": 1987,
        "HLY Last Year": 2015,
        "DLY First Year": 1994,
        "DLY Last Year": 2004,
        "MLY First Year": 1994,
        "MLY Last Year": 2004
      },
      {
        "Name": "NANGMAGVIK LAKE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2500440",
        "Station ID": 27118,
        "WMO ID": 71974,
        "TC ID": "WSQ",
        "Latitude (Decimal Degrees)": 74.14,
        "Longitude (Decimal Degrees)": -119.99,
        "Latitude": 740820000,
        "Longitude": -1195920000,
        "Elevation (m)": 32,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAPE NOREM",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2500667",
        "Station ID": 1787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 77.47,
        "Longitude (Decimal Degrees)": -110.47,
        "Latitude": 772800000,
        "Longitude": -1102800000,
        "Elevation (m)": 13.7,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "CAPE PROVIDENCE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2501419",
        "Station ID": 53298,
        "WMO ID": null,
        "TC ID": "NPV",
        "Latitude (Decimal Degrees)": 74.46,
        "Longitude (Decimal Degrees)": -112.16,
        "Latitude": 742723800,
        "Longitude": -1120938000,
        "Elevation (m)": 12,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOLMAN",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502500",
        "Station ID": 1788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 70.73,
        "Longitude (Decimal Degrees)": -117.78,
        "Latitude": 704400000,
        "Longitude": -1174700000,
        "Elevation (m)": 9.1,
        "First Year": 1941,
        "Last Year": 1969,
        "HLY First Year": 1953,
        "HLY Last Year": 1967,
        "DLY First Year": 1941,
        "DLY Last Year": 1969,
        "MLY First Year": 1941,
        "MLY Last Year": 1969
      },
      {
        "Name": "ULUKHAKTOK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502501",
        "Station ID": 1789,
        "WMO ID": null,
        "TC ID": "YHI",
        "Latitude (Decimal Degrees)": 70.76,
        "Longitude (Decimal Degrees)": -117.81,
        "Latitude": 704546000,
        "Longitude": -1174822000,
        "Elevation (m)": 35.97,
        "First Year": 1979,
        "Last Year": 2014,
        "HLY First Year": 1987,
        "HLY Last Year": 2014,
        "DLY First Year": 1979,
        "DLY Last Year": 2010,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "ULUKHAKTOK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502502",
        "Station ID": 53332,
        "WMO ID": null,
        "TC ID": "YHI",
        "Latitude (Decimal Degrees)": 70.76,
        "Longitude (Decimal Degrees)": -117.81,
        "Latitude": 704546000,
        "Longitude": -1174822000,
        "Elevation (m)": 36,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ULUKHAKTOK A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502503",
        "Station ID": 53329,
        "WMO ID": null,
        "TC ID": "YHI",
        "Latitude (Decimal Degrees)": 70.76,
        "Longitude (Decimal Degrees)": -117.81,
        "Latitude": 704546000,
        "Longitude": -1174822000,
        "Elevation (m)": 36,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOLMAN CS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502505",
        "Station ID": 29715,
        "WMO ID": 71029,
        "TC ID": "XHI",
        "Latitude (Decimal Degrees)": 70.76,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 704541002,
        "Longitude": -1174800002,
        "Elevation (m)": 29.9,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "JOHNSON POINT A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502657",
        "Station ID": 1791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 72.77,
        "Longitude (Decimal Degrees)": -118.5,
        "Latitude": 724600000,
        "Longitude": -1183000000,
        "Elevation (m)": 9.1,
        "First Year": 1972,
        "Last Year": 1976,
        "HLY First Year": 1972,
        "HLY Last Year": 1976,
        "DLY First Year": 1972,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "MOULD BAY A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502700",
        "Station ID": 1792,
        "WMO ID": null,
        "TC ID": "YMD",
        "Latitude (Decimal Degrees)": 76.23,
        "Longitude (Decimal Degrees)": -119.33,
        "Latitude": 761400000,
        "Longitude": -1192000000,
        "Elevation (m)": 12.2,
        "First Year": 1948,
        "Last Year": 1997,
        "HLY First Year": 1953,
        "HLY Last Year": 1997,
        "DLY First Year": 1948,
        "DLY Last Year": 1997,
        "MLY First Year": 1948,
        "MLY Last Year": 1997
      },
      {
        "Name": "MOULD BAY CAMP",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2502G00",
        "Station ID": 10908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 76.23,
        "Longitude (Decimal Degrees)": -119.32,
        "Latitude": 761400000,
        "Longitude": -1191900000,
        "Elevation (m)": 12.2,
        "First Year": 1993,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": 1993,
        "DLY Last Year": 1997,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SACHS HARBOUR CLIMATE",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2503648",
        "Station ID": 10076,
        "WMO ID": 71467,
        "TC ID": "WSY",
        "Latitude (Decimal Degrees)": 71.99,
        "Longitude (Decimal Degrees)": -125.25,
        "Latitude": 715933000,
        "Longitude": -1251515000,
        "Elevation (m)": 87.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "SACHS HARBOUR A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2503649",
        "Station ID": 53326,
        "WMO ID": null,
        "TC ID": "YSY",
        "Latitude (Decimal Degrees)": 71.99,
        "Longitude (Decimal Degrees)": -125.24,
        "Latitude": 715937000,
        "Longitude": -1251429000,
        "Elevation (m)": 86.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SACHS HARBOUR A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2503650",
        "Station ID": 1794,
        "WMO ID": null,
        "TC ID": "YSY",
        "Latitude (Decimal Degrees)": 72,
        "Longitude (Decimal Degrees)": -125.27,
        "Latitude": 720000000,
        "Longitude": -1251600000,
        "Elevation (m)": 86.25,
        "First Year": 1955,
        "Last Year": 2015,
        "HLY First Year": 1955,
        "HLY Last Year": 2015,
        "DLY First Year": 1955,
        "DLY Last Year": 2013,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "SACHS HARBOUR A",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2503651",
        "Station ID": 53328,
        "WMO ID": null,
        "TC ID": "YSY",
        "Latitude (Decimal Degrees)": 71.99,
        "Longitude (Decimal Degrees)": -125.24,
        "Latitude": 715937000,
        "Longitude": -1251429000,
        "Elevation (m)": 86.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THOMSEN RIVER",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "2503886",
        "Station ID": 10598,
        "WMO ID": 71916,
        "TC ID": "WYF",
        "Latitude (Decimal Degrees)": 73.23,
        "Longitude (Decimal Degrees)": -119.54,
        "Latitude": 731351000,
        "Longitude": -1193218000,
        "Elevation (m)": 41.5,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOULD BAY CS",
        "Province": "NORTHWEST TERRITORIES",
        "Climate ID": "250M001",
        "Station ID": 27324,
        "WMO ID": 71989,
        "TC ID": "WAY",
        "Latitude (Decimal Degrees)": 76.24,
        "Longitude (Decimal Degrees)": -119.35,
        "Latitude": 761415060,
        "Longitude": -1192050040,
        "Elevation (m)": 1.5,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "ABEE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3010010",
        "Station ID": 32232,
        "WMO ID": 71285,
        "TC ID": "XAF",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -112.97,
        "Latitude": 541700000,
        "Longitude": -1125800000,
        "Elevation (m)": 664,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": 1990,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALBERT HALL AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3010060",
        "Station ID": 46127,
        "WMO ID": 71005,
        "TC ID": "PAH",
        "Latitude (Decimal Degrees)": 53.11,
        "Longitude (Decimal Degrees)": -111.18,
        "Latitude": 530648004,
        "Longitude": -1111052006,
        "Elevation (m)": 658,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALDER FLATS LO",
        "Province": "ALBERTA",
        "Climate ID": "3010080",
        "Station ID": 1807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -114.82,
        "Latitude": 524800000,
        "Longitude": -1144900000,
        "Elevation (m)": 1075.9,
        "First Year": 1952,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1984,
        "MLY First Year": 1952,
        "MLY Last Year": 1984
      },
      {
        "Name": "ALLIANCE",
        "Province": "ALBERTA",
        "Climate ID": "3010160",
        "Station ID": 1808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.43,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 522600000,
        "Longitude": -1114700000,
        "Elevation (m)": 715.7,
        "First Year": 1916,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1982,
        "MLY First Year": 1916,
        "MLY Last Year": 1982
      },
      {
        "Name": "ALLIANCE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3010162",
        "Station ID": 46327,
        "WMO ID": 71006,
        "TC ID": "PAA",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 521855007,
        "Longitude": -1114643006,
        "Elevation (m)": 737,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALLIANCE GREENVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3010164",
        "Station ID": 1809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -111.8,
        "Latitude": 522300000,
        "Longitude": -1114800000,
        "Elevation (m)": 731.5,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "ALLIANCE SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3010175",
        "Station ID": 1810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 522500000,
        "Longitude": -1114700000,
        "Elevation (m)": 713.2,
        "First Year": 1982,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2000,
        "MLY First Year": 1982,
        "MLY Last Year": 2000
      },
      {
        "Name": "AMISK",
        "Province": "ALBERTA",
        "Climate ID": "3010232",
        "Station ID": 1811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.68,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 524100000,
        "Longitude": -1110700000,
        "Elevation (m)": 701,
        "First Year": 1967,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1974,
        "MLY First Year": 1967,
        "MLY Last Year": 1974
      },
      {
        "Name": "ANDREW",
        "Province": "ALBERTA",
        "Climate ID": "3010234",
        "Station ID": 1812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -112.23,
        "Latitude": 540100000,
        "Longitude": -1121400000,
        "Elevation (m)": 610,
        "First Year": 1973,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2017,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "ANDREW AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3010237",
        "Station ID": 32253,
        "WMO ID": 71286,
        "TC ID": "XAG",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -112.28,
        "Latitude": 535501000,
        "Longitude": -1121645000,
        "Elevation (m)": 625,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARMENA",
        "Province": "ALBERTA",
        "Climate ID": "3010301",
        "Station ID": 1813,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.13,
        "Longitude (Decimal Degrees)": -112.95,
        "Latitude": 530800000,
        "Longitude": -1125700000,
        "Elevation (m)": 746.8,
        "First Year": 1969,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1972
      },
      {
        "Name": "AURORA LO",
        "Province": "ALBERTA",
        "Climate ID": "3010410",
        "Station ID": 1814,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.65,
        "Longitude (Decimal Degrees)": -115.72,
        "Latitude": 523900000,
        "Longitude": -1154300000,
        "Elevation (m)": 1341.1,
        "First Year": 1968,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2011,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "BASELINE LO",
        "Province": "ALBERTA",
        "Climate ID": "3010534",
        "Station ID": 1815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -115.42,
        "Latitude": 520800000,
        "Longitude": -1152500000,
        "Elevation (m)": 1897.1,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "BASHAW",
        "Province": "ALBERTA",
        "Climate ID": "3010535",
        "Station ID": 1816,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.68,
        "Longitude (Decimal Degrees)": -112.87,
        "Latitude": 524100000,
        "Longitude": -1125200000,
        "Elevation (m)": 792.5,
        "First Year": 1906,
        "Last Year": 1922,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1922,
        "MLY First Year": 1906,
        "MLY Last Year": 1922
      },
      {
        "Name": "BATTLE HEADWATERS",
        "Province": "ALBERTA",
        "Climate ID": "3010540",
        "Station ID": 42164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.93,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 525550000,
        "Longitude": -1141142000,
        "Elevation (m)": 654,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAVER HILLS WEST",
        "Province": "ALBERTA",
        "Climate ID": "3010558",
        "Station ID": 1817,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -112.53,
        "Latitude": 533200000,
        "Longitude": -1123200000,
        "Elevation (m)": 769.6,
        "First Year": 1897,
        "Last Year": 1908,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1908,
        "MLY First Year": 1897,
        "MLY Last Year": 1908
      },
      {
        "Name": "BELLIS",
        "Province": "ALBERTA",
        "Climate ID": "3010615",
        "Station ID": 1818,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.18,
        "Longitude (Decimal Degrees)": -112.17,
        "Latitude": 541100000,
        "Longitude": -1121000000,
        "Elevation (m)": 685.8,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1961,
        "MLY Last Year": 1969
      },
      {
        "Name": "BELLIS",
        "Province": "ALBERTA",
        "Climate ID": "3010616",
        "Station ID": 1819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -112.15,
        "Latitude": 540800000,
        "Longitude": -1120900000,
        "Elevation (m)": 660.6,
        "First Year": 1979,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1992,
        "MLY First Year": 1979,
        "MLY Last Year": 1992
      },
      {
        "Name": "BELLSHILL AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3010650",
        "Station ID": 46128,
        "WMO ID": 71010,
        "TC ID": "PBL",
        "Latitude (Decimal Degrees)": 52.58,
        "Longitude (Decimal Degrees)": -111.47,
        "Latitude": 523456007,
        "Longitude": -1112755007,
        "Elevation (m)": 721,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BIRCH LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3010698",
        "Station ID": 1820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.38,
        "Longitude (Decimal Degrees)": -111.77,
        "Latitude": 532300000,
        "Longitude": -1114600000,
        "Elevation (m)": 689,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1988
      },
      {
        "Name": "BITTERN LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3010704",
        "Station ID": 1821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.07,
        "Longitude (Decimal Degrees)": -113,
        "Latitude": 530400000,
        "Longitude": -1130000000,
        "Elevation (m)": 745.2,
        "First Year": 1907,
        "Last Year": 1926,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1926,
        "MLY First Year": 1907,
        "MLY Last Year": 1926
      },
      {
        "Name": "BLACKSTONE LO",
        "Province": "ALBERTA",
        "Climate ID": "3010712",
        "Station ID": 1822,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.78,
        "Longitude (Decimal Degrees)": -116.35,
        "Latitude": 524700000,
        "Longitude": -1162100000,
        "Elevation (m)": 1569.7,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "BODO AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3010730",
        "Station ID": 32149,
        "WMO ID": 71214,
        "TC ID": "XBA",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -110.1,
        "Latitude": 520727005,
        "Longitude": -1100605009,
        "Elevation (m)": 687,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRAZEAU LO",
        "Province": "ALBERTA",
        "Climate ID": "3010800",
        "Station ID": 1823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -115.42,
        "Latitude": 530100000,
        "Longitude": -1152500000,
        "Elevation (m)": 1088.4,
        "First Year": 1940,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 2011,
        "MLY First Year": 1940,
        "MLY Last Year": 2007
      },
      {
        "Name": "BREMNER",
        "Province": "ALBERTA",
        "Climate ID": "3010810",
        "Station ID": 1824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 533500000,
        "Longitude": -1131700000,
        "Elevation (m)": 678.2,
        "First Year": 1962,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1972,
        "MLY First Year": 1962,
        "MLY Last Year": 1972
      },
      {
        "Name": "BRETON",
        "Province": "ALBERTA",
        "Climate ID": "3010815",
        "Station ID": 1825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.17,
        "Longitude (Decimal Degrees)": -114.48,
        "Latitude": 531000000,
        "Longitude": -1142900000,
        "Elevation (m)": 843,
        "First Year": 1961,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2021,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRETON PLOTS",
        "Province": "ALBERTA",
        "Climate ID": "3010816",
        "Station ID": 10928,
        "WMO ID": 71233,
        "TC ID": "WOV",
        "Latitude (Decimal Degrees)": 53.09,
        "Longitude (Decimal Degrees)": -114.44,
        "Latitude": 530522000,
        "Longitude": -1142627000,
        "Elevation (m)": 851.5,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRETON TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3010817",
        "Station ID": 1826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.12,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 530700000,
        "Longitude": -1142800000,
        "Elevation (m)": 837.3,
        "First Year": 1977,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1989,
        "MLY First Year": 1977,
        "MLY Last Year": 1989
      },
      {
        "Name": "BRIARFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3010827",
        "Station ID": 1827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.73,
        "Longitude (Decimal Degrees)": -111.05,
        "Latitude": 534400000,
        "Longitude": -1110300000,
        "Elevation (m)": 633,
        "First Year": 1987,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1995,
        "MLY First Year": 1987,
        "MLY Last Year": 1995
      },
      {
        "Name": "BRIGHTVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3010830",
        "Station ID": 1828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.98,
        "Longitude (Decimal Degrees)": -113.72,
        "Latitude": 525900000,
        "Longitude": -1134300000,
        "Elevation (m)": 815.3,
        "First Year": 1961,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2012,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROWNFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3010890",
        "Station ID": 1829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -111.47,
        "Latitude": 521909024,
        "Longitude": -1112810074,
        "Elevation (m)": 755.3,
        "First Year": 1958,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2009,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRUDERHEIM",
        "Province": "ALBERTA",
        "Climate ID": "3010900",
        "Station ID": 1830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.78,
        "Longitude (Decimal Degrees)": -112.93,
        "Latitude": 534700000,
        "Longitude": -1125600000,
        "Elevation (m)": 640.1,
        "First Year": 1899,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1962,
        "MLY First Year": 1899,
        "MLY Last Year": 1962
      },
      {
        "Name": "BUCK MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "3010920",
        "Station ID": 1831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -114.7,
        "Latitude": 530100000,
        "Longitude": -1144200000,
        "Elevation (m)": 1060.7,
        "First Year": 1939,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1951,
        "MLY First Year": 1939,
        "MLY Last Year": 1951
      },
      {
        "Name": "BUSBY",
        "Province": "ALBERTA",
        "Climate ID": "3010978",
        "Station ID": 6854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -113.89,
        "Latitude": 535948600,
        "Longitude": -1135319600,
        "Elevation (m)": 701,
        "First Year": 1993,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2015,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "BUSBY AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3010979",
        "Station ID": 47068,
        "WMO ID": 71284,
        "TC ID": "PBB",
        "Latitude (Decimal Degrees)": 53.93,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 535551005,
        "Longitude": -1135518001,
        "Elevation (m)": 714,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CADOGAN",
        "Province": "ALBERTA",
        "Climate ID": "3010990",
        "Station ID": 1832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.27,
        "Longitude (Decimal Degrees)": -110.58,
        "Latitude": 521600000,
        "Longitude": -1103500000,
        "Elevation (m)": 670.6,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "CADOGAN",
        "Province": "ALBERTA",
        "Climate ID": "3010991",
        "Station ID": 1833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -110.53,
        "Latitude": 521500000,
        "Longitude": -1103200000,
        "Elevation (m)": 665.3,
        "First Year": 1980,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2002,
        "MLY First Year": 1980,
        "MLY Last Year": 2002
      },
      {
        "Name": "CADOGAN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3010992",
        "Station ID": 46470,
        "WMO ID": 71012,
        "TC ID": "PCA",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -110.51,
        "Latitude": 522003007,
        "Longitude": -1103036002,
        "Elevation (m)": 690,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BUCK MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "3010RK0",
        "Station ID": 1806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -114.7,
        "Latitude": 530200000,
        "Longitude": -1144200000,
        "Elevation (m)": 914.4,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "CALMAR",
        "Province": "ALBERTA",
        "Climate ID": "3011120",
        "Station ID": 1835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.29,
        "Longitude (Decimal Degrees)": -113.86,
        "Latitude": 531723007,
        "Longitude": -1135147008,
        "Elevation (m)": 720,
        "First Year": 1914,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2018,
        "MLY First Year": 1914,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALMAR TOWNSITE",
        "Province": "ALBERTA",
        "Climate ID": "3011136",
        "Station ID": 1836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -113.8,
        "Latitude": 531400000,
        "Longitude": -1134800000,
        "Elevation (m)": 670.6,
        "First Year": 1952,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1959,
        "MLY First Year": 1952,
        "MLY Last Year": 1959
      },
      {
        "Name": "CALMAR WEST",
        "Province": "ALBERTA",
        "Climate ID": "3011138",
        "Station ID": 1837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 531700000,
        "Longitude": -1135200000,
        "Elevation (m)": 725,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "CAMROSE",
        "Province": "ALBERTA",
        "Climate ID": "3011239",
        "Station ID": 1838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 530200000,
        "Longitude": -1124800000,
        "Elevation (m)": 675.1,
        "First Year": 1928,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1941,
        "MLY First Year": 1928,
        "MLY Last Year": 1941
      },
      {
        "Name": "CAMROSE",
        "Province": "ALBERTA",
        "Climate ID": "3011240",
        "Station ID": 1839,
        "WMO ID": 71254,
        "TC ID": "WRV",
        "Latitude (Decimal Degrees)": 53.05,
        "Longitude (Decimal Degrees)": -112.82,
        "Latitude": 530300000,
        "Longitude": -1124900000,
        "Elevation (m)": 739,
        "First Year": 1946,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1946,
        "DLY Last Year": 2022,
        "MLY First Year": 1946,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMROSE 2",
        "Province": "ALBERTA",
        "Climate ID": "3011241",
        "Station ID": 1840,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 525700000,
        "Longitude": -1124800000,
        "Elevation (m)": 738,
        "First Year": 1947,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 2016,
        "MLY First Year": 1947,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARNWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3011350",
        "Station ID": 30055,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -114.65,
        "Latitude": 531403767,
        "Longitude": -1143915883,
        "Elevation (m)": 793.2,
        "First Year": 2000,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2016,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "CASTOR",
        "Province": "ALBERTA",
        "Climate ID": "3011440",
        "Station ID": 1841,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.22,
        "Longitude (Decimal Degrees)": -111.92,
        "Latitude": 521300000,
        "Longitude": -1115500000,
        "Elevation (m)": 769.6,
        "First Year": 1951,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1963,
        "MLY First Year": 1951,
        "MLY Last Year": 1963
      },
      {
        "Name": "CASTOR",
        "Province": "ALBERTA",
        "Climate ID": "3011441",
        "Station ID": 1842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -111.9,
        "Latitude": 520800000,
        "Longitude": -1115400000,
        "Elevation (m)": 807.7,
        "First Year": 1969,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1992,
        "MLY First Year": 1969,
        "MLY Last Year": 1992
      },
      {
        "Name": "CHAILEY",
        "Province": "ALBERTA",
        "Climate ID": "3011474",
        "Station ID": 1843,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -111.25,
        "Latitude": 533300000,
        "Longitude": -1111500000,
        "Elevation (m)": 670,
        "First Year": 1980,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2009,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHAUVIN",
        "Province": "ALBERTA",
        "Climate ID": "3011479",
        "Station ID": 1844,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.72,
        "Longitude (Decimal Degrees)": -110.17,
        "Latitude": 524300000,
        "Longitude": -1101000000,
        "Elevation (m)": 640.1,
        "First Year": 1980,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1990,
        "MLY First Year": 1980,
        "MLY Last Year": 1990
      },
      {
        "Name": "CHAUVIN EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3011480",
        "Station ID": 1845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.7,
        "Longitude (Decimal Degrees)": -110.13,
        "Latitude": 524200000,
        "Longitude": -1100800000,
        "Elevation (m)": 627.9,
        "First Year": 1951,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1956,
        "MLY First Year": 1951,
        "MLY Last Year": 1956
      },
      {
        "Name": "CHEDDERVILLE CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3011520",
        "Station ID": 1846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -114.87,
        "Latitude": 521200000,
        "Longitude": -1145200000,
        "Elevation (m)": 1036.3,
        "First Year": 1954,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1977,
        "MLY First Year": 1954,
        "MLY Last Year": 1977
      },
      {
        "Name": "CHIPMAN",
        "Province": "ALBERTA",
        "Climate ID": "3011560",
        "Station ID": 1847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.7,
        "Longitude (Decimal Degrees)": -112.58,
        "Latitude": 534200000,
        "Longitude": -1123500000,
        "Elevation (m)": 669.3,
        "First Year": 1954,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1960,
        "MLY First Year": 1954,
        "MLY Last Year": 1960
      },
      {
        "Name": "CLEARWATER",
        "Province": "ALBERTA",
        "Climate ID": "3011663",
        "Station ID": 1848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -115.25,
        "Latitude": 515900000,
        "Longitude": -1151500000,
        "Elevation (m)": 1280,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "COMPEER",
        "Province": "ALBERTA",
        "Climate ID": "3011760",
        "Station ID": 1849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -110.03,
        "Latitude": 515800000,
        "Longitude": -1100200000,
        "Elevation (m)": 701,
        "First Year": 1949,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1971,
        "MLY First Year": 1949,
        "MLY Last Year": 1971
      },
      {
        "Name": "CONSORT AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3011815",
        "Station ID": 32230,
        "WMO ID": 71223,
        "TC ID": "XCS",
        "Latitude (Decimal Degrees)": 51.94,
        "Longitude (Decimal Degrees)": -110.71,
        "Latitude": 515613008,
        "Longitude": -1104247003,
        "Elevation (m)": 772,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "CONSORT CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3011820",
        "Station ID": 1850,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.03,
        "Longitude (Decimal Degrees)": -110.8,
        "Latitude": 520200000,
        "Longitude": -1104800000,
        "Elevation (m)": 769.6,
        "First Year": 1956,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1975,
        "MLY First Year": 1956,
        "MLY Last Year": 1975
      },
      {
        "Name": "CONSORT WADES",
        "Province": "ALBERTA",
        "Climate ID": "3011840",
        "Station ID": 1851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.85,
        "Longitude (Decimal Degrees)": -110.77,
        "Latitude": 515100000,
        "Longitude": -1104600000,
        "Elevation (m)": 752.9,
        "First Year": 1953,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1971,
        "MLY First Year": 1953,
        "MLY Last Year": 1971
      },
      {
        "Name": "CONTRACOSTA LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3011848",
        "Station ID": 1852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 514600000,
        "Longitude": -1113300000,
        "Elevation (m)": 791.9,
        "First Year": 1983,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2001,
        "MLY First Year": 1983,
        "MLY Last Year": 2001
      },
      {
        "Name": "COOKING LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3011854",
        "Station ID": 1853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.43,
        "Longitude (Decimal Degrees)": -113.12,
        "Latitude": 532600000,
        "Longitude": -1130700000,
        "Elevation (m)": 742.5,
        "First Year": 1985,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1994,
        "MLY First Year": 1985,
        "MLY Last Year": 1994
      },
      {
        "Name": "CORONATION A",
        "Province": "ALBERTA",
        "Climate ID": "3011880",
        "Station ID": 1854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 520400000,
        "Longitude": -1112700000,
        "Elevation (m)": 791,
        "First Year": 1944,
        "Last Year": 1994,
        "HLY First Year": 1953,
        "HLY Last Year": 1994,
        "DLY First Year": 1944,
        "DLY Last Year": 1994,
        "MLY First Year": 1944,
        "MLY Last Year": 1994
      },
      {
        "Name": "CORONATION (AUT)",
        "Province": "ALBERTA",
        "Climate ID": "3011885",
        "Station ID": 8691,
        "WMO ID": null,
        "TC ID": "WCT",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 520500000,
        "Longitude": -1112700000,
        "Elevation (m)": 790.7,
        "First Year": 1992,
        "Last Year": 2003,
        "HLY First Year": 1994,
        "HLY Last Year": 2003,
        "DLY First Year": 1992,
        "DLY Last Year": 2003,
        "MLY First Year": 1996,
        "MLY Last Year": 2003
      },
      {
        "Name": "CORONATION CLIMATE",
        "Province": "ALBERTA",
        "Climate ID": "3011887",
        "Station ID": 27212,
        "WMO ID": 71318,
        "TC ID": "WCT",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 520427000,
        "Longitude": -1112658000,
        "Elevation (m)": 791,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "CORONATION",
        "Province": "ALBERTA",
        "Climate ID": "3011890",
        "Station ID": 1855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 520700000,
        "Longitude": -1112700000,
        "Elevation (m)": 788.5,
        "First Year": 1912,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1943,
        "MLY First Year": 1912,
        "MLY Last Year": 1943
      },
      {
        "Name": "CRESTOMERE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3011892",
        "Station ID": 47069,
        "WMO ID": 71380,
        "TC ID": "PCC",
        "Latitude (Decimal Degrees)": 52.73,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 524358005,
        "Longitude": -1135410007,
        "Elevation (m)": 855,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAKOTA",
        "Province": "ALBERTA",
        "Climate ID": "3011952",
        "Station ID": 1856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.73,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 524400000,
        "Longitude": -1135500000,
        "Elevation (m)": 862.6,
        "First Year": 1963,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1978,
        "MLY First Year": 1963,
        "MLY Last Year": 1978
      },
      {
        "Name": "DAKOTA WEST",
        "Province": "ALBERTA",
        "Climate ID": "3011953",
        "Station ID": 1857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -113.95,
        "Latitude": 524500000,
        "Longitude": -1135700000,
        "Elevation (m)": 865.2,
        "First Year": 1963,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2016,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "DARWELL",
        "Province": "ALBERTA",
        "Climate ID": "3011RED",
        "Station ID": 1834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -114.58,
        "Latitude": 533600000,
        "Longitude": -1143500000,
        "Elevation (m)": 780,
        "First Year": 1987,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1992,
        "MLY First Year": 1987,
        "MLY Last Year": 1992
      },
      {
        "Name": "DELBURNE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012050",
        "Station ID": 46730,
        "WMO ID": 71014,
        "TC ID": "PDA",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 521058004,
        "Longitude": -1131032006,
        "Elevation (m)": 889,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEWBERRY",
        "Province": "ALBERTA",
        "Climate ID": "3012072",
        "Station ID": 1859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.65,
        "Longitude (Decimal Degrees)": -110.6,
        "Latitude": 533900000,
        "Longitude": -1103600000,
        "Elevation (m)": 615.7,
        "First Year": 1987,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2006,
        "MLY First Year": 1987,
        "MLY Last Year": 2006
      },
      {
        "Name": "DEWBERRY AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012073",
        "Station ID": 46731,
        "WMO ID": 71359,
        "TC ID": "PDB",
        "Latitude (Decimal Degrees)": 53.66,
        "Longitude (Decimal Degrees)": -110.59,
        "Latitude": 533933008,
        "Longitude": -1103515006,
        "Elevation (m)": 616,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "DODDS ROUND HILL",
        "Province": "ALBERTA",
        "Climate ID": "3012091",
        "Station ID": 1860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.17,
        "Longitude (Decimal Degrees)": -112.37,
        "Latitude": 531000000,
        "Longitude": -1122200000,
        "Elevation (m)": 705.6,
        "First Year": 1975,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "DONALDA SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3012095",
        "Station ID": 30433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -112.35,
        "Latitude": 523100000,
        "Longitude": -1122104000,
        "Elevation (m)": 741.5,
        "First Year": 2000,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2016,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "DRAYTON VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3012115",
        "Station ID": 1861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.2,
        "Longitude (Decimal Degrees)": -114.98,
        "Latitude": 531200000,
        "Longitude": -1145900000,
        "Elevation (m)": 853.4,
        "First Year": 1961,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1978,
        "MLY First Year": 1961,
        "MLY Last Year": 1978
      },
      {
        "Name": "DRAYTON VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3012116",
        "Station ID": 30056,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.22,
        "Longitude (Decimal Degrees)": -114.95,
        "Latitude": 531303430,
        "Longitude": -1145715267,
        "Elevation (m)": 883.08,
        "First Year": 2000,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2005,
        "MLY First Year": 2000,
        "MLY Last Year": 2005
      },
      {
        "Name": "EDBERG",
        "Province": "ALBERTA",
        "Climate ID": "3012190",
        "Station ID": 1862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.73,
        "Longitude (Decimal Degrees)": -112.78,
        "Latitude": 524400000,
        "Longitude": -1124700000,
        "Elevation (m)": 784.9,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "EDGERTON AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012192",
        "Station ID": 46129,
        "WMO ID": 71360,
        "TC ID": "PEA",
        "Latitude (Decimal Degrees)": 52.78,
        "Longitude (Decimal Degrees)": -110.43,
        "Latitude": 524640001,
        "Longitude": -1102558002,
        "Elevation (m)": 649,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDMONTON",
        "Province": "ALBERTA",
        "Climate ID": "3012195",
        "Station ID": 1863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -113.5,
        "Latitude": 533300000,
        "Longitude": -1133000000,
        "Elevation (m)": 657.8,
        "First Year": 1880,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1943,
        "MLY First Year": 1880,
        "MLY Last Year": 1943
      },
      {
        "Name": "EDMONTON CITY CENTRE AWOS",
        "Province": "ALBERTA",
        "Climate ID": "3012202",
        "Station ID": 31427,
        "WMO ID": 71879,
        "TC ID": "YXD",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 533419000,
        "Longitude": -1133110000,
        "Elevation (m)": 671.4,
        "First Year": 2004,
        "Last Year": 2015,
        "HLY First Year": 2004,
        "HLY Last Year": 2015,
        "DLY First Year": 2005,
        "DLY Last Year": 2015,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON CALDER",
        "Province": "ALBERTA",
        "Climate ID": "3012203",
        "Station ID": 1864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 533400000,
        "Longitude": -1133100000,
        "Elevation (m)": 670.6,
        "First Year": 1975,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1977,
        "MLY First Year": 1975,
        "MLY Last Year": 1977
      },
      {
        "Name": "EDMONTON INT'L A",
        "Province": "ALBERTA",
        "Climate ID": "3012205",
        "Station ID": 1865,
        "WMO ID": 71123,
        "TC ID": "YEG",
        "Latitude (Decimal Degrees)": 53.32,
        "Longitude (Decimal Degrees)": -113.58,
        "Latitude": 531900000,
        "Longitude": -1133500000,
        "Elevation (m)": 723.3,
        "First Year": 1959,
        "Last Year": 2012,
        "HLY First Year": 1961,
        "HLY Last Year": 2012,
        "DLY First Year": 1959,
        "DLY Last Year": 2012,
        "MLY First Year": 1959,
        "MLY Last Year": 2012
      },
      {
        "Name": "EDMONTON INTERNATIONAL CS",
        "Province": "ALBERTA",
        "Climate ID": "3012206",
        "Station ID": 27793,
        "WMO ID": 71155,
        "TC ID": "XEG",
        "Latitude (Decimal Degrees)": 53.31,
        "Longitude (Decimal Degrees)": -113.61,
        "Latitude": 531824002,
        "Longitude": -1133621009,
        "Elevation (m)": 715,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON LYNNWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3012207",
        "Station ID": 1866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 533200000,
        "Longitude": -1133400000,
        "Elevation (m)": 670.6,
        "First Year": 1971,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1976,
        "MLY First Year": 1971,
        "MLY Last Year": 1976
      },
      {
        "Name": "EDMONTON CITY CENTRE A",
        "Province": "ALBERTA",
        "Climate ID": "3012208",
        "Station ID": 1867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 533424000,
        "Longitude": -1133106000,
        "Elevation (m)": 670.6,
        "First Year": 1937,
        "Last Year": 2005,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1937,
        "DLY Last Year": 2005,
        "MLY First Year": 1937,
        "MLY Last Year": 2005
      },
      {
        "Name": "EDMONTON BLATCHFORD",
        "Province": "ALBERTA",
        "Climate ID": "3012209",
        "Station ID": 27214,
        "WMO ID": 71157,
        "TC ID": "XEC",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 533423008,
        "Longitude": -1133100010,
        "Elevation (m)": 671,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON NAMAO A",
        "Province": "ALBERTA",
        "Climate ID": "3012210",
        "Station ID": 1868,
        "WMO ID": 71121,
        "TC ID": "YED",
        "Latitude (Decimal Degrees)": 53.67,
        "Longitude (Decimal Degrees)": -113.47,
        "Latitude": 534000000,
        "Longitude": -1132800000,
        "Elevation (m)": 687.9,
        "First Year": 1955,
        "Last Year": 2022,
        "HLY First Year": 1955,
        "HLY Last Year": 2022,
        "DLY First Year": 1955,
        "DLY Last Year": 2009,
        "MLY First Year": 1955,
        "MLY Last Year": 1996
      },
      {
        "Name": "EDMONTON NAMAO AWOS A",
        "Province": "ALBERTA",
        "Climate ID": "3012212",
        "Station ID": 30907,
        "WMO ID": 71121,
        "TC ID": "YED",
        "Latitude (Decimal Degrees)": 53.67,
        "Longitude (Decimal Degrees)": -113.47,
        "Latitude": 534000000,
        "Longitude": -1132800000,
        "Elevation (m)": 687.93,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON PLEASANTVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3012215",
        "Station ID": 1869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.48,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 532900000,
        "Longitude": -1133100000,
        "Elevation (m)": 670.6,
        "First Year": 1977,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1979,
        "MLY First Year": 1977,
        "MLY Last Year": 1979
      },
      {
        "Name": "EDMONTON INTL A",
        "Province": "ALBERTA",
        "Climate ID": "3012216",
        "Station ID": 50149,
        "WMO ID": 71123,
        "TC ID": "YEG",
        "Latitude (Decimal Degrees)": 53.31,
        "Longitude (Decimal Degrees)": -113.58,
        "Latitude": 531836000,
        "Longitude": -1133446000,
        "Elevation (m)": 723.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDMONTON SOUTH CAMPUS",
        "Province": "ALBERTA",
        "Climate ID": "3012220",
        "Station ID": 53718,
        "WMO ID": null,
        "TC ID": "NES",
        "Latitude (Decimal Degrees)": 53.49,
        "Longitude (Decimal Degrees)": -113.54,
        "Latitude": 532924050,
        "Longitude": -1133216000,
        "Elevation (m)": 670,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDMONTON VILLENEUVE A",
        "Province": "ALBERTA",
        "Climate ID": "3012228",
        "Station ID": 1871,
        "WMO ID": null,
        "TC ID": "ZVL",
        "Latitude (Decimal Degrees)": 53.67,
        "Longitude (Decimal Degrees)": -113.85,
        "Latitude": 534003000,
        "Longitude": -1135116000,
        "Elevation (m)": 687.63,
        "First Year": 1984,
        "Last Year": 1991,
        "HLY First Year": 1984,
        "HLY Last Year": 1991,
        "DLY First Year": 1984,
        "DLY Last Year": 1991,
        "MLY First Year": 1984,
        "MLY Last Year": 1991
      },
      {
        "Name": "EDMONTON VILLENEUVE A",
        "Province": "ALBERTA",
        "Climate ID": "3012229",
        "Station ID": 51758,
        "WMO ID": 71664,
        "TC ID": "ZVL",
        "Latitude (Decimal Degrees)": 53.67,
        "Longitude (Decimal Degrees)": -113.86,
        "Latitude": 534006000,
        "Longitude": -1135120000,
        "Elevation (m)": 687.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDMONTON STONY PLAIN",
        "Province": "ALBERTA",
        "Climate ID": "301222F",
        "Station ID": 1870,
        "WMO ID": 71119,
        "TC ID": "WSE",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -114.11,
        "Latitude": 533251006,
        "Longitude": -1140630090,
        "Elevation (m)": 766.3,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2019,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2017
      },
      {
        "Name": "EDMONTON WOODBEND",
        "Province": "ALBERTA",
        "Climate ID": "3012230",
        "Station ID": 1872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -113.75,
        "Latitude": 532500000,
        "Longitude": -1134500000,
        "Elevation (m)": 670.6,
        "First Year": 1973,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2015,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON CLOVERBAR",
        "Province": "ALBERTA",
        "Climate ID": "3012232",
        "Station ID": 43148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.59,
        "Longitude (Decimal Degrees)": -113.36,
        "Latitude": 533512007,
        "Longitude": -1132149000,
        "Elevation (m)": 653.8,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2006,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "EDMONTON GARRISON",
        "Province": "ALBERTA",
        "Climate ID": "3012233",
        "Station ID": 43147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.66,
        "Longitude (Decimal Degrees)": -113.36,
        "Latitude": 533935000,
        "Longitude": -1132149000,
        "Elevation (m)": 683.4,
        "First Year": 2004,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2009,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON SMITH WATER TREATMENT",
        "Province": "ALBERTA",
        "Climate ID": "3012234",
        "Station ID": 43150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.47,
        "Longitude (Decimal Degrees)": -113.62,
        "Latitude": 532816000,
        "Longitude": -1133710000,
        "Elevation (m)": 638.5,
        "First Year": 2004,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2009,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON AB RESEARCH PARK",
        "Province": "ALBERTA",
        "Climate ID": "3012235",
        "Station ID": 43149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -113.48,
        "Latitude": 532644000,
        "Longitude": -1132839000,
        "Elevation (m)": 694.2,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "EDMONTON EER",
        "Province": "ALBERTA",
        "Climate ID": "3012240",
        "Station ID": 54718,
        "WMO ID": null,
        "TC ID": "NEE",
        "Latitude (Decimal Degrees)": 40,
        "Longitude (Decimal Degrees)": -50,
        "Latitude": 400000000,
        "Longitude": -500000000,
        "Elevation (m)": null,
        "First Year": 2016,
        "Last Year": 2017,
        "HLY First Year": 2016,
        "HLY Last Year": 2017,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ELK ISLAND NAT PARK",
        "Province": "ALBERTA",
        "Climate ID": "3012275",
        "Station ID": 1873,
        "WMO ID": 71238,
        "TC ID": "WFE",
        "Latitude (Decimal Degrees)": 53.68,
        "Longitude (Decimal Degrees)": -112.87,
        "Latitude": 534058000,
        "Longitude": -1125205000,
        "Elevation (m)": 716.2,
        "First Year": 1981,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1981,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELK ISLAND PARK",
        "Province": "ALBERTA",
        "Climate ID": "3012277",
        "Station ID": 1874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -112.9,
        "Latitude": 533100000,
        "Longitude": -1125400000,
        "Elevation (m)": 723.9,
        "First Year": 1966,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "ELK ISLAND SIDING",
        "Province": "ALBERTA",
        "Climate ID": "3012278",
        "Station ID": 1875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.78,
        "Longitude (Decimal Degrees)": -112.98,
        "Latitude": 534700000,
        "Longitude": -1125900000,
        "Elevation (m)": 650.4,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "ELK POINT",
        "Province": "ALBERTA",
        "Climate ID": "3012280",
        "Station ID": 1876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -111.07,
        "Latitude": 535300000,
        "Longitude": -1110400000,
        "Elevation (m)": 605,
        "First Year": 1911,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1997,
        "MLY First Year": 1912,
        "MLY Last Year": 1997
      },
      {
        "Name": "ELK RIVER ER RS",
        "Province": "ALBERTA",
        "Climate ID": "3012281",
        "Station ID": 1877,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -115.65,
        "Latitude": 525700000,
        "Longitude": -1153900000,
        "Elevation (m)": 944.9,
        "First Year": 1978,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1978,
        "MLY First Year": 1978,
        "MLY Last Year": 1978
      },
      {
        "Name": "ELK RIVER AFS",
        "Province": "ALBERTA",
        "Climate ID": "3012282",
        "Station ID": 27260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.97,
        "Longitude (Decimal Degrees)": -115.87,
        "Latitude": 525800000,
        "Longitude": -1155200000,
        "Elevation (m)": 1036,
        "First Year": 1996,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2011,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELLERSLIE",
        "Province": "ALBERTA",
        "Climate ID": "3012295",
        "Station ID": 1878,
        "WMO ID": null,
        "TC ID": "WHE",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -113.55,
        "Latitude": 532500000,
        "Longitude": -1133300000,
        "Elevation (m)": 693.7,
        "First Year": 1964,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1986,
        "MLY First Year": 1964,
        "MLY Last Year": 1986
      },
      {
        "Name": "ELNORA SALEM",
        "Province": "ALBERTA",
        "Climate ID": "3012330",
        "Station ID": 1879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.03,
        "Longitude (Decimal Degrees)": -112.13,
        "Latitude": 520200000,
        "Longitude": -1120800000,
        "Elevation (m)": 826,
        "First Year": 1988,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2001,
        "MLY First Year": 1988,
        "MLY Last Year": 2001
      },
      {
        "Name": "ENDIANG",
        "Province": "ALBERTA",
        "Climate ID": "3012425",
        "Station ID": 1880,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.92,
        "Longitude (Decimal Degrees)": -112.08,
        "Latitude": 515500000,
        "Longitude": -1120500000,
        "Elevation (m)": 853.4,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "FABYAN",
        "Province": "ALBERTA",
        "Climate ID": "3012515",
        "Station ID": 1881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.97,
        "Longitude (Decimal Degrees)": -111,
        "Latitude": 525800000,
        "Longitude": -1110000000,
        "Elevation (m)": 698,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "FERINTOSH AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012590",
        "Station ID": 46733,
        "WMO ID": 71673,
        "TC ID": "PFA",
        "Latitude (Decimal Degrees)": 52.74,
        "Longitude (Decimal Degrees)": -112.86,
        "Latitude": 524437009,
        "Longitude": -1125128009,
        "Elevation (m)": 795,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "FIVE LAKES",
        "Province": "ALBERTA",
        "Climate ID": "3012620",
        "Station ID": 1882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.95,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 535700000,
        "Longitude": -1141300000,
        "Elevation (m)": 721.8,
        "First Year": 1911,
        "Last Year": 1934,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1934,
        "MLY First Year": 1911,
        "MLY Last Year": 1933
      },
      {
        "Name": "FLEET AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012635",
        "Station ID": 51502,
        "WMO ID": null,
        "TC ID": "PFE",
        "Latitude (Decimal Degrees)": 52.04,
        "Longitude (Decimal Degrees)": -111.81,
        "Latitude": 520227008,
        "Longitude": -1114847007,
        "Elevation (m)": 823,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORESTBURG",
        "Province": "ALBERTA",
        "Climate ID": "3012651",
        "Station ID": 1883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -112.13,
        "Latitude": 522800000,
        "Longitude": -1120800000,
        "Elevation (m)": 690.4,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "FORESTBURG PLANT SITE",
        "Province": "ALBERTA",
        "Climate ID": "3012652",
        "Station ID": 1884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 522802933,
        "Longitude": -1120712933,
        "Elevation (m)": 662.9,
        "First Year": 1967,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2020,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORESTBURG AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012653",
        "Station ID": 46130,
        "WMO ID": 71674,
        "TC ID": "PFO",
        "Latitude (Decimal Degrees)": 52.55,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 523254005,
        "Longitude": -1120724003,
        "Elevation (m)": 721,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT EDMONTON",
        "Province": "ALBERTA",
        "Climate ID": "3012659",
        "Station ID": 1885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 533100000,
        "Longitude": -1133400000,
        "Elevation (m)": 627.9,
        "First Year": 1976,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1986,
        "MLY First Year": 1976,
        "MLY Last Year": 1986
      },
      {
        "Name": "FORT SASKATCHEWAN",
        "Province": "ALBERTA",
        "Climate ID": "3012710",
        "Station ID": 1886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 534300000,
        "Longitude": -1131100000,
        "Elevation (m)": 620,
        "First Year": 1958,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2022,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "FRISCO",
        "Province": "ALBERTA",
        "Climate ID": "3012740",
        "Station ID": 1887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -114.93,
        "Latitude": 522700000,
        "Longitude": -1145600000,
        "Elevation (m)": 960.1,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "FROG LAKE RS",
        "Province": "ALBERTA",
        "Climate ID": "3012742",
        "Station ID": 1888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.77,
        "Longitude (Decimal Degrees)": -110.42,
        "Latitude": 534600000,
        "Longitude": -1102500000,
        "Elevation (m)": 579.1,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "GADSBY",
        "Province": "ALBERTA",
        "Climate ID": "3012760",
        "Station ID": 1889,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -112.35,
        "Latitude": 521800000,
        "Longitude": -1122100000,
        "Elevation (m)": 808.3,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1956
      },
      {
        "Name": "GAINFORD",
        "Province": "ALBERTA",
        "Climate ID": "3012762",
        "Station ID": 1890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -114.72,
        "Latitude": 533500000,
        "Longitude": -1144300000,
        "Elevation (m)": 728.5,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "GENESEE",
        "Province": "ALBERTA",
        "Climate ID": "3012774",
        "Station ID": 1891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 532100000,
        "Longitude": -1142700000,
        "Elevation (m)": 701,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "GILT EDGE NORTH AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012790",
        "Station ID": 46131,
        "WMO ID": 71791,
        "TC ID": "PGE",
        "Latitude (Decimal Degrees)": 53.07,
        "Longitude (Decimal Degrees)": -110.62,
        "Latitude": 530415007,
        "Longitude": -1103715009,
        "Elevation (m)": 647,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST. FRANCIS",
        "Province": "ALBERTA",
        "Climate ID": "3012799",
        "Station ID": 53998,
        "WMO ID": null,
        "TC ID": "PFR",
        "Latitude (Decimal Degrees)": 53.29,
        "Longitude (Decimal Degrees)": -114.32,
        "Latitude": 531741030,
        "Longitude": -1141912040,
        "Elevation (m)": 809,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLADSTONE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012810",
        "Station ID": 51501,
        "WMO ID": null,
        "TC ID": "PGT",
        "Latitude (Decimal Degrees)": 52.99,
        "Longitude (Decimal Degrees)": -112.28,
        "Latitude": 525914093,
        "Longitude": -1121641095,
        "Elevation (m)": 704,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLENEVIS",
        "Province": "ALBERTA",
        "Climate ID": "3012817",
        "Station ID": 1892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.8,
        "Longitude (Decimal Degrees)": -114.52,
        "Latitude": 534800000,
        "Longitude": -1143100000,
        "Elevation (m)": 731.5,
        "First Year": 1966,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1987,
        "MLY First Year": 1966,
        "MLY Last Year": 1987
      },
      {
        "Name": "GLENEVIS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012818",
        "Station ID": 47074,
        "WMO ID": 71500,
        "TC ID": "PGC",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -114.54,
        "Latitude": 534957006,
        "Longitude": -1143220005,
        "Elevation (m)": 670,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOODRIDGE RS",
        "Province": "ALBERTA",
        "Climate ID": "3012860",
        "Station ID": 1893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.38,
        "Longitude (Decimal Degrees)": -111.37,
        "Latitude": 542300000,
        "Longitude": -1112200000,
        "Elevation (m)": 609.6,
        "First Year": 1977,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1987,
        "MLY First Year": 1977,
        "MLY Last Year": 1987
      },
      {
        "Name": "GOOSEBERRY LAKE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012871",
        "Station ID": 51578,
        "WMO ID": null,
        "TC ID": "PGB",
        "Latitude (Decimal Degrees)": 52.11,
        "Longitude (Decimal Degrees)": -110.8,
        "Latitude": 520647000,
        "Longitude": -1104809000,
        "Elevation (m)": 740,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOOSEBERRY LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3012872",
        "Station ID": 1894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -110.7,
        "Latitude": 520700000,
        "Longitude": -1104200000,
        "Elevation (m)": 731.5,
        "First Year": 1983,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1983,
        "MLY First Year": 1983,
        "MLY Last Year": 1983
      },
      {
        "Name": "GWYNNE",
        "Province": "ALBERTA",
        "Climate ID": "3012990",
        "Station ID": 1895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 525700000,
        "Longitude": -1131100000,
        "Elevation (m)": 768.1,
        "First Year": 1975,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2005,
        "MLY First Year": 1975,
        "MLY Last Year": 2005
      },
      {
        "Name": "HACKETT",
        "Province": "ALBERTA",
        "Climate ID": "3012992",
        "Station ID": 1896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -112.62,
        "Latitude": 521000000,
        "Longitude": -1123700000,
        "Elevation (m)": 852,
        "First Year": 1984,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2007,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "HALKIRK",
        "Province": "ALBERTA",
        "Climate ID": "3012998",
        "Station ID": 1897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -112.17,
        "Latitude": 521700000,
        "Longitude": -1121000000,
        "Elevation (m)": 823,
        "First Year": 1907,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1929,
        "MLY First Year": 1907,
        "MLY Last Year": 1929
      },
      {
        "Name": "HALKIRK AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3012999",
        "Station ID": 46787,
        "WMO ID": 71792,
        "TC ID": "PHA",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -112.16,
        "Latitude": 520659008,
        "Longitude": -1120951001,
        "Elevation (m)": 843,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "GIBBONS",
        "Province": "ALBERTA",
        "Climate ID": "3012P82",
        "Station ID": 1858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.81,
        "Longitude (Decimal Degrees)": -113.17,
        "Latitude": 534830000,
        "Longitude": -1131020000,
        "Elevation (m)": 647.1,
        "First Year": 1983,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2002,
        "MLY First Year": 1983,
        "MLY Last Year": 2002
      },
      {
        "Name": "HARDISTY",
        "Province": "ALBERTA",
        "Climate ID": "3013040",
        "Station ID": 1900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.68,
        "Longitude (Decimal Degrees)": -111.3,
        "Latitude": 524100000,
        "Longitude": -1111800000,
        "Elevation (m)": 625.4,
        "First Year": 1914,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1959,
        "MLY First Year": 1914,
        "MLY Last Year": 1959
      },
      {
        "Name": "HASTINGS LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3013060",
        "Station ID": 1901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.38,
        "Longitude (Decimal Degrees)": -112.88,
        "Latitude": 532300000,
        "Longitude": -1125300000,
        "Elevation (m)": 762,
        "First Year": 1977,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1987,
        "MLY First Year": 1977,
        "MLY Last Year": 1987
      },
      {
        "Name": "HEISLER 10S",
        "Province": "ALBERTA",
        "Climate ID": "3013128",
        "Station ID": 1902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -112.25,
        "Latitude": 523100000,
        "Longitude": -1121500000,
        "Elevation (m)": 731.5,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "HEMARUKA",
        "Province": "ALBERTA",
        "Climate ID": "3013132",
        "Station ID": 1903,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -111.08,
        "Latitude": 514700000,
        "Longitude": -1110500000,
        "Elevation (m)": 763.5,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "HEMARUKA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3013140",
        "Station ID": 46907,
        "WMO ID": 71793,
        "TC ID": "PHE",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -111.21,
        "Latitude": 514647007,
        "Longitude": -1111243004,
        "Elevation (m)": 780,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGHVALE",
        "Province": "ALBERTA",
        "Climate ID": "3013247",
        "Station ID": 1904,
        "WMO ID": 71241,
        "TC ID": "WHA",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 532700000,
        "Longitude": -1142800000,
        "Elevation (m)": 746.8,
        "First Year": 1977,
        "Last Year": 2006,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": 1977,
        "DLY Last Year": 2006,
        "MLY First Year": 1977,
        "MLY Last Year": 2006
      },
      {
        "Name": "HILLIARD",
        "Province": "ALBERTA",
        "Climate ID": "3013270",
        "Station ID": 1905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -112.47,
        "Latitude": 533700000,
        "Longitude": -1122800000,
        "Elevation (m)": 686.1,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "HOLDEN AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3013340",
        "Station ID": 32395,
        "WMO ID": 71227,
        "TC ID": "XHD",
        "Latitude (Decimal Degrees)": 53.19,
        "Longitude (Decimal Degrees)": -112.25,
        "Latitude": 531106009,
        "Longitude": -1121446004,
        "Elevation (m)": 688,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOLDEN",
        "Province": "ALBERTA",
        "Climate ID": "3013344",
        "Station ID": 1906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.12,
        "Longitude (Decimal Degrees)": -112.18,
        "Latitude": 530700000,
        "Longitude": -1121100000,
        "Elevation (m)": 701,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "HORSESHOE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3013345",
        "Station ID": 1907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -110.73,
        "Latitude": 522100000,
        "Longitude": -1104400000,
        "Elevation (m)": 658.4,
        "First Year": 1969,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1982,
        "MLY First Year": 1969,
        "MLY Last Year": 1982
      },
      {
        "Name": "HUGHENDEN",
        "Province": "ALBERTA",
        "Climate ID": "3013360",
        "Station ID": 1908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -110.97,
        "Latitude": 523100000,
        "Longitude": -1105800000,
        "Elevation (m)": 694,
        "First Year": 1934,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1972,
        "MLY First Year": 1934,
        "MLY Last Year": 1972
      },
      {
        "Name": "HUGHENDEN",
        "Province": "ALBERTA",
        "Climate ID": "3013361",
        "Station ID": 1909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -110.97,
        "Latitude": 523100000,
        "Longitude": -1105800000,
        "Elevation (m)": 694,
        "First Year": 1972,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1985,
        "MLY First Year": 1972,
        "MLY Last Year": 1985
      },
      {
        "Name": "HUGHENDEN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3013362",
        "Station ID": 46908,
        "WMO ID": 71794,
        "TC ID": "PHU",
        "Latitude (Decimal Degrees)": 52.58,
        "Longitude (Decimal Degrees)": -110.78,
        "Latitude": 523441007,
        "Longitude": -1104701004,
        "Elevation (m)": 703,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUGHENDEN SW",
        "Province": "ALBERTA",
        "Climate ID": "3013363",
        "Station ID": 42283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -111.08,
        "Latitude": 522800000,
        "Longitude": -1110500000,
        "Elevation (m)": 746.8,
        "First Year": 2003,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2009,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "IRMA WEST",
        "Province": "ALBERTA",
        "Climate ID": "3013467",
        "Station ID": 1910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -111.38,
        "Latitude": 525300000,
        "Longitude": -1112300000,
        "Elevation (m)": 678,
        "First Year": 1985,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1987,
        "MLY First Year": 1985,
        "MLY Last Year": 1987
      },
      {
        "Name": "JASPER PLACE SCHOOL",
        "Province": "ALBERTA",
        "Climate ID": "3013530",
        "Station ID": 1911,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -113.6,
        "Latitude": 533100000,
        "Longitude": -1133600000,
        "Elevation (m)": 687.6,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "KILLAM AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3013640",
        "Station ID": 32313,
        "WMO ID": 71228,
        "TC ID": "XKM",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -111.87,
        "Latitude": 525051000,
        "Longitude": -1115219000,
        "Elevation (m)": 674.5,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "KESSLER",
        "Province": "ALBERTA",
        "Climate ID": "3013643",
        "Station ID": 1912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -111.02,
        "Latitude": 521700000,
        "Longitude": -1110100000,
        "Elevation (m)": 716.3,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "KESSLER AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3013644",
        "Station ID": 46909,
        "WMO ID": 71796,
        "TC ID": "PKA",
        "Latitude (Decimal Degrees)": 52.29,
        "Longitude (Decimal Degrees)": -111.11,
        "Latitude": 521725000,
        "Longitude": -1110647006,
        "Elevation (m)": 720,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "KINSELLA RANCH",
        "Province": "ALBERTA",
        "Climate ID": "3013645",
        "Station ID": 1913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53,
        "Longitude (Decimal Degrees)": -111.52,
        "Latitude": 530000000,
        "Longitude": -1113100000,
        "Elevation (m)": 705,
        "First Year": 1962,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2003,
        "MLY First Year": 1962,
        "MLY Last Year": 2003
      },
      {
        "Name": "KINSELLA RESEARC STATION",
        "Province": "ALBERTA",
        "Climate ID": "3013647",
        "Station ID": 49049,
        "WMO ID": null,
        "TC ID": "PKR",
        "Latitude (Decimal Degrees)": 53.05,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 530249000,
        "Longitude": -1113251000,
        "Elevation (m)": 701,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KITSCOTY",
        "Province": "ALBERTA",
        "Climate ID": "3013650",
        "Station ID": 1914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -110.33,
        "Latitude": 532100000,
        "Longitude": -1102000000,
        "Elevation (m)": 670,
        "First Year": 1958,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1974,
        "MLY First Year": 1958,
        "MLY Last Year": 1974
      },
      {
        "Name": "KITSCOTY AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3013660",
        "Station ID": 46808,
        "WMO ID": 71797,
        "TC ID": "PKI",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -110.42,
        "Latitude": 532112005,
        "Longitude": -1102458002,
        "Elevation (m)": 664,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAVOY",
        "Province": "ALBERTA",
        "Climate ID": "3013770",
        "Station ID": 1915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -111.87,
        "Latitude": 533200000,
        "Longitude": -1115200000,
        "Elevation (m)": 670,
        "First Year": 1958,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2008,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAVOY SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3013771",
        "Station ID": 1916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.38,
        "Longitude (Decimal Degrees)": -111.8,
        "Latitude": 532300000,
        "Longitude": -1114800000,
        "Elevation (m)": 684.5,
        "First Year": 1988,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1993,
        "MLY First Year": 1988,
        "MLY Last Year": 1993
      },
      {
        "Name": "LEDUC",
        "Province": "ALBERTA",
        "Climate ID": "3013780",
        "Station ID": 1917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.15,
        "Longitude (Decimal Degrees)": -113.68,
        "Latitude": 530900000,
        "Longitude": -1134100000,
        "Elevation (m)": 762,
        "First Year": 1984,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "LEGAL AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3013790",
        "Station ID": 47088,
        "WMO ID": 71544,
        "TC ID": "PLF",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -113.47,
        "Latitude": 540011001,
        "Longitude": -1132828001,
        "Elevation (m)": 680,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LINDBERGH",
        "Province": "ALBERTA",
        "Climate ID": "3013920",
        "Station ID": 1918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -110.65,
        "Latitude": 535300000,
        "Longitude": -1103900000,
        "Elevation (m)": 563.9,
        "First Year": 1954,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1958,
        "MLY First Year": 1954,
        "MLY Last Year": 1958
      },
      {
        "Name": "LINDBERGH AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3013921",
        "Station ID": 43560,
        "WMO ID": 71567,
        "TC ID": "NLB",
        "Latitude (Decimal Degrees)": 53.94,
        "Longitude (Decimal Degrees)": -110.58,
        "Latitude": 535634000,
        "Longitude": -1103444000,
        "Elevation (m)": 630,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "LLOYDMINSTER A",
        "Province": "ALBERTA",
        "Climate ID": "3013958",
        "Station ID": 50311,
        "WMO ID": null,
        "TC ID": "YLL",
        "Latitude (Decimal Degrees)": 53.31,
        "Longitude (Decimal Degrees)": -110.07,
        "Latitude": 531838000,
        "Longitude": -1100427000,
        "Elevation (m)": 668.7,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LLOYDMINSTER",
        "Province": "ALBERTA",
        "Climate ID": "3013959",
        "Station ID": 48550,
        "WMO ID": 71871,
        "TC ID": "YLL",
        "Latitude (Decimal Degrees)": 53.31,
        "Longitude (Decimal Degrees)": -110.07,
        "Latitude": 531833000,
        "Longitude": -1100421000,
        "Elevation (m)": 668.7,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LLOYDMINSTER",
        "Province": "ALBERTA",
        "Climate ID": "3013960",
        "Station ID": 1919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -110,
        "Latitude": 531700000,
        "Longitude": -1100000000,
        "Elevation (m)": 646.2,
        "First Year": 1913,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1970,
        "MLY First Year": 1913,
        "MLY Last Year": 1970
      },
      {
        "Name": "LLOYDMINSTER A",
        "Province": "ALBERTA",
        "Climate ID": "3013961",
        "Station ID": 1920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.31,
        "Longitude (Decimal Degrees)": -110.07,
        "Latitude": 531833000,
        "Longitude": -1100421000,
        "Elevation (m)": 668.43,
        "First Year": 1982,
        "Last Year": 2022,
        "HLY First Year": 1982,
        "HLY Last Year": 2012,
        "DLY First Year": 1982,
        "DLY Last Year": 2022,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "LLOYDMINSTER NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3013963",
        "Station ID": 1921,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -110,
        "Latitude": 531700000,
        "Longitude": -1100000000,
        "Elevation (m)": 649.2,
        "First Year": 1967,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1976,
        "MLY First Year": 1967,
        "MLY Last Year": 1976
      },
      {
        "Name": "LLOYDMINSTER SEFTON PARK",
        "Province": "ALBERTA",
        "Climate ID": "3013965",
        "Station ID": 1922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.08,
        "Longitude (Decimal Degrees)": -110.17,
        "Latitude": 530500000,
        "Longitude": -1101000000,
        "Elevation (m)": 682.8,
        "First Year": 1961,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1973,
        "MLY First Year": 1961,
        "MLY Last Year": 1973
      },
      {
        "Name": "LONG LAKE RS",
        "Province": "ALBERTA",
        "Climate ID": "3013983",
        "Station ID": 1923,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.43,
        "Longitude (Decimal Degrees)": -112.75,
        "Latitude": 542600000,
        "Longitude": -1124500000,
        "Elevation (m)": 640.1,
        "First Year": 1975,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1999,
        "MLY First Year": 1975,
        "MLY Last Year": 1999
      },
      {
        "Name": "LOUGHEED",
        "Province": "ALBERTA",
        "Climate ID": "3013990",
        "Station ID": 1924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -111.43,
        "Latitude": 524600000,
        "Longitude": -1112600000,
        "Elevation (m)": 662.9,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "LOUGHEED SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3013993",
        "Station ID": 1925,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.72,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 524300000,
        "Longitude": -1113300000,
        "Elevation (m)": 701,
        "First Year": 1981,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1986,
        "MLY First Year": 1981,
        "MLY Last Year": 1986
      },
      {
        "Name": "HOLDEN SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3013C44",
        "Station ID": 1898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.08,
        "Longitude (Decimal Degrees)": -112.27,
        "Latitude": 530500000,
        "Longitude": -1121600000,
        "Elevation (m)": 708.7,
        "First Year": 1981,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2018,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAMONT",
        "Province": "ALBERTA",
        "Climate ID": "3013PFD",
        "Station ID": 1899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.77,
        "Longitude (Decimal Degrees)": -112.85,
        "Latitude": 534600000,
        "Longitude": -1125100000,
        "Elevation (m)": 710.2,
        "First Year": 1980,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1993,
        "MLY First Year": 1980,
        "MLY Last Year": 1993
      },
      {
        "Name": "MA-ME-O",
        "Province": "ALBERTA",
        "Climate ID": "3014178",
        "Station ID": 1926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.97,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 525800000,
        "Longitude": -1135400000,
        "Elevation (m)": 853.4,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "MANNVILLE SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3014188",
        "Station ID": 1927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.1,
        "Longitude (Decimal Degrees)": -111.35,
        "Latitude": 530600000,
        "Longitude": -1112100000,
        "Elevation (m)": 701,
        "First Year": 1973,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1984,
        "MLY First Year": 1973,
        "MLY Last Year": 1984
      },
      {
        "Name": "MANNVILLE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3014195",
        "Station ID": 46767,
        "WMO ID": 71047,
        "TC ID": "PMA",
        "Latitude (Decimal Degrees)": 53.46,
        "Longitude (Decimal Degrees)": -111.26,
        "Latitude": 532720006,
        "Longitude": -1111519009,
        "Elevation (m)": 656,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARWAYNE",
        "Province": "ALBERTA",
        "Climate ID": "3014225",
        "Station ID": 1928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -110.23,
        "Latitude": 533600000,
        "Longitude": -1101400000,
        "Elevation (m)": 594.4,
        "First Year": 1961,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1980,
        "MLY First Year": 1961,
        "MLY Last Year": 1980
      },
      {
        "Name": "MATCHAYAW LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3014235",
        "Station ID": 1929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 534300000,
        "Longitude": -1140600000,
        "Elevation (m)": 685.8,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "METISKOW CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3014520",
        "Station ID": 1930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -110.58,
        "Latitude": 522400000,
        "Longitude": -1103500000,
        "Elevation (m)": 685.8,
        "First Year": 1951,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1978,
        "MLY First Year": 1951,
        "MLY Last Year": 1978
      },
      {
        "Name": "MINBURN GRAZING RESERVE",
        "Province": "ALBERTA",
        "Climate ID": "3014556",
        "Station ID": 1931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.18,
        "Longitude (Decimal Degrees)": -111.27,
        "Latitude": 531100000,
        "Longitude": -1111600000,
        "Elevation (m)": 701,
        "First Year": 1967,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1973,
        "MLY First Year": 1967,
        "MLY Last Year": 1973
      },
      {
        "Name": "MINISTIK LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3014560",
        "Station ID": 1932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.43,
        "Longitude (Decimal Degrees)": -116.68,
        "Latitude": 532600000,
        "Longitude": -1164100000,
        "Elevation (m)": 698,
        "First Year": 1940,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1951,
        "MLY First Year": 1940,
        "MLY Last Year": 1951
      },
      {
        "Name": "MINISTIK RESEARCH",
        "Province": "ALBERTA",
        "Climate ID": "3014563",
        "Station ID": 1933,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -112.97,
        "Latitude": 532100000,
        "Longitude": -1125800000,
        "Elevation (m)": 769.8,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "MINISTIK SANCTUARY",
        "Province": "ALBERTA",
        "Climate ID": "3014565",
        "Station ID": 1934,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -113.03,
        "Latitude": 532200000,
        "Longitude": -1130200000,
        "Elevation (m)": 762,
        "First Year": 1961,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1970,
        "MLY First Year": 1961,
        "MLY Last Year": 1970
      },
      {
        "Name": "MONITOR",
        "Province": "ALBERTA",
        "Climate ID": "3014600",
        "Station ID": 1935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.02,
        "Longitude (Decimal Degrees)": -110.55,
        "Latitude": 520100000,
        "Longitude": -1103300000,
        "Elevation (m)": 731.5,
        "First Year": 1983,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2022,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "MUNDARE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3014665",
        "Station ID": 41785,
        "WMO ID": 71354,
        "TC ID": "ZMU",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -112.3,
        "Latitude": 533400000,
        "Longitude": -1121800000,
        "Elevation (m)": 683,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "MYRNAM AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3014690",
        "Station ID": 46811,
        "WMO ID": 71065,
        "TC ID": "PMN",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -111.11,
        "Latitude": 534312008,
        "Longitude": -1110652005,
        "Elevation (m)": 580,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "NACO",
        "Province": "ALBERTA",
        "Climate ID": "3014760",
        "Station ID": 1936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -110.8,
        "Latitude": 514300000,
        "Longitude": -1104800000,
        "Elevation (m)": 731.5,
        "First Year": 1930,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1965,
        "MLY First Year": 1930,
        "MLY Last Year": 1965
      },
      {
        "Name": "NEUTRAL VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3014783",
        "Station ID": 1937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -110.88,
        "Latitude": 520800000,
        "Longitude": -1105300000,
        "Elevation (m)": 758,
        "First Year": 1989,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1995,
        "MLY First Year": 1989,
        "MLY Last Year": 1995
      },
      {
        "Name": "NEW BRIGDEN",
        "Province": "ALBERTA",
        "Climate ID": "3014785",
        "Station ID": 1938,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -110.5,
        "Latitude": 514300000,
        "Longitude": -1103000000,
        "Elevation (m)": 731.5,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "NEW BRIGDEN EAST",
        "Province": "ALBERTA",
        "Climate ID": "3014788",
        "Station ID": 1939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -110.27,
        "Latitude": 514100000,
        "Longitude": -1101600000,
        "Elevation (m)": 695,
        "First Year": 1984,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2003,
        "MLY First Year": 1984,
        "MLY Last Year": 2003
      },
      {
        "Name": "NEW SAREPTA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3014795",
        "Station ID": 46911,
        "WMO ID": 71067,
        "TC ID": "PNS",
        "Latitude (Decimal Degrees)": 53.26,
        "Longitude (Decimal Degrees)": -113.17,
        "Latitude": 531544009,
        "Longitude": -1130954006,
        "Elevation (m)": 765,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "NEWBROOK",
        "Province": "ALBERTA",
        "Climate ID": "3014800",
        "Station ID": 1940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.32,
        "Longitude (Decimal Degrees)": -112.95,
        "Latitude": 541900000,
        "Longitude": -1125700000,
        "Elevation (m)": 670.6,
        "First Year": 1954,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1979,
        "MLY First Year": 1954,
        "MLY Last Year": 1979
      },
      {
        "Name": "NEWBROOK NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3014803",
        "Station ID": 1941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.42,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 542500000,
        "Longitude": -1124800000,
        "Elevation (m)": 701,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "NORTH COOKING LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3014870",
        "Station ID": 1942,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.43,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 532600000,
        "Longitude": -1131100000,
        "Elevation (m)": 740.7,
        "First Year": 1918,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1930,
        "MLY First Year": 1918,
        "MLY Last Year": 1930
      },
      {
        "Name": "O CHIESE LO",
        "Province": "ALBERTA",
        "Climate ID": "3014912",
        "Station ID": 1943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.78,
        "Longitude (Decimal Degrees)": -115.33,
        "Latitude": 524700000,
        "Longitude": -1152000000,
        "Elevation (m)": 1112.5,
        "First Year": 1959,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1975,
        "MLY First Year": 1959,
        "MLY Last Year": 1975
      },
      {
        "Name": "OLIVER AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3014921",
        "Station ID": 41783,
        "WMO ID": 71351,
        "TC ID": "ZOL",
        "Latitude (Decimal Degrees)": 53.65,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 533900000,
        "Longitude": -1132100000,
        "Elevation (m)": 665,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLIVER TREE NURSERY",
        "Province": "ALBERTA",
        "Climate ID": "3014922",
        "Station ID": 1944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.65,
        "Longitude (Decimal Degrees)": -113.37,
        "Latitude": 533900000,
        "Longitude": -1132200000,
        "Elevation (m)": 647.7,
        "First Year": 1972,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2005,
        "MLY First Year": 1972,
        "MLY Last Year": 2005
      },
      {
        "Name": "ONOWAY",
        "Province": "ALBERTA",
        "Climate ID": "3014926",
        "Station ID": 1945,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.7,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 534200000,
        "Longitude": -1141200000,
        "Elevation (m)": 711.7,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "PAKAN",
        "Province": "ALBERTA",
        "Climate ID": "3014975",
        "Station ID": 1946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -112.52,
        "Latitude": 540000000,
        "Longitude": -1123100000,
        "Elevation (m)": null,
        "First Year": 1902,
        "Last Year": 1912,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1912,
        "MLY First Year": 1902,
        "MLY Last Year": 1912
      },
      {
        "Name": "PARADISE VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3014995",
        "Station ID": 1947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.12,
        "Longitude (Decimal Degrees)": -110.35,
        "Latitude": 530700000,
        "Longitude": -1102100000,
        "Elevation (m)": 658,
        "First Year": 1961,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2003,
        "MLY First Year": 1961,
        "MLY Last Year": 2003
      },
      {
        "Name": "PARADISE VALLEY WEST",
        "Province": "ALBERTA",
        "Climate ID": "3014996",
        "Station ID": 1948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -110.43,
        "Latitude": 530100000,
        "Longitude": -1102600000,
        "Elevation (m)": 655.3,
        "First Year": 1971,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1982,
        "MLY First Year": 1971,
        "MLY Last Year": 1982
      },
      {
        "Name": "PEMUKAN",
        "Province": "ALBERTA",
        "Climate ID": "3015165",
        "Station ID": 1950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -110.45,
        "Latitude": 515900000,
        "Longitude": -1102700000,
        "Elevation (m)": 680.3,
        "First Year": 1926,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1949,
        "MLY First Year": 1926,
        "MLY Last Year": 1949
      },
      {
        "Name": "PONOKA",
        "Province": "ALBERTA",
        "Climate ID": "3015280",
        "Station ID": 1951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.72,
        "Longitude (Decimal Degrees)": -113.77,
        "Latitude": 524300000,
        "Longitude": -1134600000,
        "Elevation (m)": 856.5,
        "First Year": 1906,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1968,
        "MLY First Year": 1906,
        "MLY Last Year": 1968
      },
      {
        "Name": "PONOKA SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3015283",
        "Station ID": 1952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.65,
        "Longitude (Decimal Degrees)": -113.62,
        "Latitude": 523900000,
        "Longitude": -1133700000,
        "Elevation (m)": 813.8,
        "First Year": 1980,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1988,
        "MLY First Year": 1980,
        "MLY Last Year": 1988
      },
      {
        "Name": "POPLAR GLEN",
        "Province": "ALBERTA",
        "Climate ID": "3015287",
        "Station ID": 1953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -110.9,
        "Latitude": 525200000,
        "Longitude": -1105400000,
        "Elevation (m)": 685.8,
        "First Year": 1990,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2008,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRAIRIE CREEK RS",
        "Province": "ALBERTA",
        "Climate ID": "3015295",
        "Station ID": 1954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -115.3,
        "Latitude": 521500000,
        "Longitude": -1151800000,
        "Elevation (m)": 1173.5,
        "First Year": 1967,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1984,
        "MLY First Year": 1967,
        "MLY Last Year": 1984
      },
      {
        "Name": "PROVOST",
        "Province": "ALBERTA",
        "Climate ID": "3015311",
        "Station ID": 1955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -110.25,
        "Latitude": 522200000,
        "Longitude": -1101500000,
        "Elevation (m)": 666.6,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "RADWAY",
        "Province": "ALBERTA",
        "Climate ID": "3015345",
        "Station ID": 1956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -112.9,
        "Latitude": 540500000,
        "Longitude": -1125400000,
        "Elevation (m)": 632.5,
        "First Year": 1922,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1951,
        "MLY First Year": 1922,
        "MLY Last Year": 1951
      },
      {
        "Name": "RADWAY AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015346",
        "Station ID": 47089,
        "WMO ID": 71545,
        "TC ID": "PRW",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -112.88,
        "Latitude": 535942002,
        "Longitude": -1125245001,
        "Elevation (m)": 625,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RANFURLY",
        "Province": "ALBERTA",
        "Climate ID": "3015400",
        "Station ID": 1957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -111.65,
        "Latitude": 532700000,
        "Longitude": -1113900000,
        "Elevation (m)": 685.8,
        "First Year": 1905,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1991,
        "MLY First Year": 1905,
        "MLY Last Year": 1991
      },
      {
        "Name": "RANFURLY AUTO",
        "Province": "ALBERTA",
        "Climate ID": "3015401",
        "Station ID": 51898,
        "WMO ID": 71637,
        "TC ID": "PNX",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -111.73,
        "Latitude": 532529090,
        "Longitude": -1114332060,
        "Elevation (m)": 687,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RANFURLY 2NW",
        "Province": "ALBERTA",
        "Climate ID": "3015405",
        "Station ID": 1958,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -111.73,
        "Latitude": 532500000,
        "Longitude": -1114400000,
        "Elevation (m)": 673,
        "First Year": 1980,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2014,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "RIBSTONE SOUTH AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015480",
        "Station ID": 46812,
        "WMO ID": 71089,
        "TC ID": "PRS",
        "Latitude (Decimal Degrees)": 52.59,
        "Longitude (Decimal Degrees)": -110.34,
        "Latitude": 523513003,
        "Longitude": -1102034005,
        "Elevation (m)": 644,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "RICINUS",
        "Province": "ALBERTA",
        "Climate ID": "3015493",
        "Station ID": 1959,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -115,
        "Latitude": 520500000,
        "Longitude": -1150000000,
        "Elevation (m)": 1149.1,
        "First Year": 1983,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1987,
        "MLY First Year": 1983,
        "MLY Last Year": 1987
      },
      {
        "Name": "RIVERCOURSE",
        "Province": "ALBERTA",
        "Climate ID": "3015500",
        "Station ID": 1960,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.97,
        "Longitude (Decimal Degrees)": -110.02,
        "Latitude": 525800000,
        "Longitude": -1100100000,
        "Elevation (m)": 624.8,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "RIVERCOURSE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015501",
        "Station ID": 46132,
        "WMO ID": 71102,
        "TC ID": "PRA",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -110.1,
        "Latitude": 530108003,
        "Longitude": -1100607003,
        "Elevation (m)": 669,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROCKY MTN HOUSE",
        "Province": "ALBERTA",
        "Climate ID": "3015520",
        "Station ID": 1961,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 522300000,
        "Longitude": -1145500000,
        "Elevation (m)": 1015,
        "First Year": 1945,
        "Last Year": 1978,
        "HLY First Year": 1953,
        "HLY Last Year": 1978,
        "DLY First Year": 1945,
        "DLY Last Year": 1978,
        "MLY First Year": 1945,
        "MLY Last Year": 1978
      },
      {
        "Name": "ROCKY MTN HOUSE A",
        "Province": "ALBERTA",
        "Climate ID": "3015522",
        "Station ID": 1962,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.43,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 522600000,
        "Longitude": -1145500000,
        "Elevation (m)": 988.2,
        "First Year": 1978,
        "Last Year": 1994,
        "HLY First Year": 1978,
        "HLY Last Year": 1994,
        "DLY First Year": 1978,
        "DLY Last Year": 1994,
        "MLY First Year": 1978,
        "MLY Last Year": 1994
      },
      {
        "Name": "ROCKY MTN HOUSE (AUT)",
        "Province": "ALBERTA",
        "Climate ID": "3015523",
        "Station ID": 8712,
        "WMO ID": 71928,
        "TC ID": "WRM",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -114.91,
        "Latitude": 522517006,
        "Longitude": -1145444003,
        "Elevation (m)": 988.2,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROCKY MTN HOUSE CR10",
        "Province": "ALBERTA",
        "Climate ID": "3015524",
        "Station ID": 27208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.43,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 522600000,
        "Longitude": -1145500000,
        "Elevation (m)": 988,
        "First Year": 1996,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2002,
        "MLY First Year": 1996,
        "MLY Last Year": 2002
      },
      {
        "Name": "ROCKY MOUNTAIN HOUSE",
        "Province": "ALBERTA",
        "Climate ID": "3015530",
        "Station ID": 1963,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 522300000,
        "Longitude": -1145500000,
        "Elevation (m)": 969.3,
        "First Year": 1915,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1946,
        "MLY First Year": 1915,
        "MLY Last Year": 1946
      },
      {
        "Name": "ROCKY RAPIDS",
        "Province": "ALBERTA",
        "Climate ID": "3015535",
        "Station ID": 1964,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.32,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 531900000,
        "Longitude": -1145500000,
        "Elevation (m)": 792.5,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "ROLLYVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3015550",
        "Station ID": 32190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.3,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 531748000,
        "Longitude": -1131800000,
        "Elevation (m)": 740.7,
        "First Year": 2002,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2004,
        "MLY First Year": 2002,
        "MLY Last Year": 2004
      },
      {
        "Name": "ROSALIND AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015600",
        "Station ID": 46847,
        "WMO ID": 71202,
        "TC ID": "PRL",
        "Latitude (Decimal Degrees)": 52.79,
        "Longitude (Decimal Degrees)": -112.42,
        "Latitude": 524732170,
        "Longitude": -1122520530,
        "Elevation (m)": 712,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "RYLEY",
        "Province": "ALBERTA",
        "Climate ID": "3015640",
        "Station ID": 1966,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -112.45,
        "Latitude": 531500000,
        "Longitude": -1122700000,
        "Elevation (m)": 682.8,
        "First Year": 1951,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1954,
        "MLY First Year": 1951,
        "MLY Last Year": 1954
      },
      {
        "Name": "ST ALBERT",
        "Province": "ALBERTA",
        "Climate ID": "3015670",
        "Station ID": 27332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -113.62,
        "Latitude": 533700000,
        "Longitude": -1133700000,
        "Elevation (m)": 680.3,
        "First Year": 1997,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 1998,
        "MLY First Year": 1997,
        "MLY Last Year": 1998
      },
      {
        "Name": "ST LINA",
        "Province": "ALBERTA",
        "Climate ID": "3015672",
        "Station ID": 1967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 541800000,
        "Longitude": -1112700000,
        "Elevation (m)": 632.5,
        "First Year": 1970,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2009,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST. LINA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015673",
        "Station ID": 47127,
        "WMO ID": 71548,
        "TC ID": "PSL",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 541656001,
        "Longitude": -1112711005,
        "Elevation (m)": 619,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST. PAUL AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015676",
        "Station ID": 32457,
        "WMO ID": 71275,
        "TC ID": "XSP",
        "Latitude (Decimal Degrees)": 54.01,
        "Longitude (Decimal Degrees)": -111.27,
        "Latitude": 540040000,
        "Longitude": -1111619000,
        "Elevation (m)": 649,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST PAUL EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3015680",
        "Station ID": 1968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -111.28,
        "Latitude": 540000000,
        "Longitude": -1111700000,
        "Elevation (m)": 586.7,
        "First Year": 1952,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1955,
        "MLY First Year": 1952,
        "MLY Last Year": 1955
      },
      {
        "Name": "ST VINCENT",
        "Province": "ALBERTA",
        "Climate ID": "3015685",
        "Station ID": 1969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.15,
        "Longitude (Decimal Degrees)": -111.27,
        "Latitude": 540900000,
        "Longitude": -1111600000,
        "Elevation (m)": 640.1,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "ST VINCENT SOUTHEAST",
        "Province": "ALBERTA",
        "Climate ID": "30156H6",
        "Station ID": 1965,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -111.22,
        "Latitude": 540700000,
        "Longitude": -1111300000,
        "Elevation (m)": 640,
        "First Year": 1985,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1987,
        "MLY First Year": 1985,
        "MLY Last Year": 1987
      },
      {
        "Name": "SEDALIA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015797",
        "Station ID": 46887,
        "WMO ID": 71399,
        "TC ID": "PSA",
        "Latitude (Decimal Degrees)": 51.59,
        "Longitude (Decimal Degrees)": -110.75,
        "Latitude": 513525008,
        "Longitude": -1104517002,
        "Elevation (m)": 755,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "SEDALIA",
        "Province": "ALBERTA",
        "Climate ID": "3015798",
        "Station ID": 1970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -110.55,
        "Latitude": 514000000,
        "Longitude": -1103300000,
        "Elevation (m)": 762,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "SEDALIA EAST",
        "Province": "ALBERTA",
        "Climate ID": "3015799",
        "Station ID": 1971,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -110.55,
        "Latitude": 513900000,
        "Longitude": -1103300000,
        "Elevation (m)": 762,
        "First Year": 1974,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1997,
        "MLY First Year": 1974,
        "MLY Last Year": 1996
      },
      {
        "Name": "SEDGEWICK",
        "Province": "ALBERTA",
        "Climate ID": "3015800",
        "Station ID": 1973,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -111.7,
        "Latitude": 525300000,
        "Longitude": -1114200000,
        "Elevation (m)": 685.8,
        "First Year": 1913,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1977,
        "MLY First Year": 1913,
        "MLY Last Year": 1977
      },
      {
        "Name": "SEDGEWICK TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3015808",
        "Station ID": 1974,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.78,
        "Longitude (Decimal Degrees)": -111.7,
        "Latitude": 524700000,
        "Longitude": -1114200000,
        "Elevation (m)": 662.9,
        "First Year": 1987,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2016,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHALKA LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3015879",
        "Station ID": 1975,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.8,
        "Longitude (Decimal Degrees)": -111.9,
        "Latitude": 534800000,
        "Longitude": -1115400000,
        "Elevation (m)": 670.6,
        "First Year": 1972,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1980,
        "MLY First Year": 1972,
        "MLY Last Year": 1980
      },
      {
        "Name": "SHALKA LAKE NE",
        "Province": "ALBERTA",
        "Climate ID": "30158GR",
        "Station ID": 1972,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -111.9,
        "Latitude": 535200000,
        "Longitude": -1115400000,
        "Elevation (m)": 655,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "SHONTS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3015900",
        "Station ID": 46848,
        "WMO ID": 71405,
        "TC ID": "PSH",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -112.54,
        "Latitude": 531957001,
        "Longitude": -1123222004,
        "Elevation (m)": 679,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "SION",
        "Province": "ALBERTA",
        "Climate ID": "3015960",
        "Station ID": 1976,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -114.12,
        "Latitude": 535300000,
        "Longitude": -1140700000,
        "Elevation (m)": 701,
        "First Year": 1906,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 2004,
        "MLY First Year": 1906,
        "MLY Last Year": 2004
      },
      {
        "Name": "PINE RIDGE C3",
        "Province": "ALBERTA",
        "Climate ID": "3015K2K",
        "Station ID": 1949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -112.27,
        "Latitude": 540500000,
        "Longitude": -1121600000,
        "Elevation (m)": 579.1,
        "First Year": 1979,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1982,
        "MLY First Year": 1979,
        "MLY Last Year": 1982
      },
      {
        "Name": "SMOKY LAKE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3016022",
        "Station ID": 32456,
        "WMO ID": 71274,
        "TC ID": "XSL",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -112.5,
        "Latitude": 541639000,
        "Longitude": -1122957000,
        "Elevation (m)": 680,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": 1990,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOUNDING CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3016052",
        "Station ID": 1978,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 520400000,
        "Longitude": -1115100000,
        "Elevation (m)": 813.8,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1983,
        "MLY First Year": 1981,
        "MLY Last Year": 1983
      },
      {
        "Name": "SPONDIN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3016070",
        "Station ID": 46913,
        "WMO ID": 71413,
        "TC ID": "PSP",
        "Latitude (Decimal Degrees)": 51.82,
        "Longitude (Decimal Degrees)": -111.68,
        "Latitude": 514857009,
        "Longitude": -1114057004,
        "Elevation (m)": 804,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPRUCE GROVE",
        "Province": "ALBERTA",
        "Climate ID": "3016083",
        "Station ID": 1979,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 533300000,
        "Longitude": -1135400000,
        "Elevation (m)": 693.4,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "STETTLER NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3016119",
        "Station ID": 1980,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -112.72,
        "Latitude": 522000000,
        "Longitude": -1124300000,
        "Elevation (m)": 821,
        "First Year": 1977,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2001,
        "MLY First Year": 1977,
        "MLY Last Year": 2001
      },
      {
        "Name": "STETTLER",
        "Province": "ALBERTA",
        "Climate ID": "3016120",
        "Station ID": 1981,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -112.7,
        "Latitude": 521800000,
        "Longitude": -1124200000,
        "Elevation (m)": 823,
        "First Year": 1918,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1977,
        "MLY First Year": 1918,
        "MLY Last Year": 1977
      },
      {
        "Name": "STETTLER AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3016124",
        "Station ID": 32458,
        "WMO ID": 71289,
        "TC ID": "XAK",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -112.6,
        "Latitude": 522049000,
        "Longitude": -1123544000,
        "Elevation (m)": 795,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "STRACHAN RS",
        "Province": "ALBERTA",
        "Climate ID": "3016180",
        "Station ID": 1982,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -115.13,
        "Latitude": 521500000,
        "Longitude": -1150800000,
        "Elevation (m)": 1112.5,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "STRY",
        "Province": "ALBERTA",
        "Climate ID": "3016225",
        "Station ID": 1983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -111.82,
        "Latitude": 540300000,
        "Longitude": -1114900000,
        "Elevation (m)": 655.3,
        "First Year": 1918,
        "Last Year": 1931,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1931,
        "MLY First Year": 1918,
        "MLY Last Year": 1931
      },
      {
        "Name": "SULLIVAN LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3016253",
        "Station ID": 1984,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.05,
        "Longitude (Decimal Degrees)": -111.95,
        "Latitude": 520300000,
        "Longitude": -1115700000,
        "Elevation (m)": 810.7,
        "First Year": 1971,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2007,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "SYLVAN HEIGHTS",
        "Province": "ALBERTA",
        "Climate ID": "3016328",
        "Station ID": 1985,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 525100000,
        "Longitude": -1135400000,
        "Elevation (m)": 823,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "TELFORDVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3016370",
        "Station ID": 1986,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.27,
        "Longitude (Decimal Degrees)": -114.13,
        "Latitude": 531600000,
        "Longitude": -1140800000,
        "Elevation (m)": 685.8,
        "First Year": 1923,
        "Last Year": 1937,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1937,
        "MLY First Year": 1923,
        "MLY Last Year": 1937
      },
      {
        "Name": "THORHILD",
        "Province": "ALBERTA",
        "Climate ID": "3016400",
        "Station ID": 1987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.15,
        "Longitude (Decimal Degrees)": -113.12,
        "Latitude": 540900000,
        "Longitude": -1130700000,
        "Elevation (m)": 647.7,
        "First Year": 1952,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1960,
        "MLY First Year": 1952,
        "MLY Last Year": 1960
      },
      {
        "Name": "THORHILD VILLAGE",
        "Province": "ALBERTA",
        "Climate ID": "3016405",
        "Station ID": 1988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.17,
        "Longitude (Decimal Degrees)": -113.12,
        "Latitude": 541000000,
        "Longitude": -1130700000,
        "Elevation (m)": 648.9,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "THORSBY",
        "Province": "ALBERTA",
        "Climate ID": "3016440",
        "Station ID": 1989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.22,
        "Longitude (Decimal Degrees)": -114.05,
        "Latitude": 531300000,
        "Longitude": -1140300000,
        "Elevation (m)": 743.7,
        "First Year": 1932,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 1968,
        "MLY First Year": 1932,
        "MLY Last Year": 1968
      },
      {
        "Name": "THORSBY AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3016441",
        "Station ID": 46850,
        "WMO ID": 71504,
        "TC ID": "PTH",
        "Latitude (Decimal Degrees)": 53.22,
        "Longitude (Decimal Degrees)": -113.89,
        "Latitude": 531302007,
        "Longitude": -1135341005,
        "Elevation (m)": 754,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "TOFIELD NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3016494",
        "Station ID": 1990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -112.75,
        "Latitude": 533300000,
        "Longitude": -1124500000,
        "Elevation (m)": 701,
        "First Year": 1974,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2014,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "TOMAHAWK AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3016505",
        "Station ID": 43581,
        "WMO ID": 71566,
        "TC ID": "NTK",
        "Latitude (Decimal Degrees)": 53.44,
        "Longitude (Decimal Degrees)": -114.72,
        "Latitude": 532622000,
        "Longitude": -1144306000,
        "Elevation (m)": 814,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "TULLIBY LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3016590",
        "Station ID": 1991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.67,
        "Longitude (Decimal Degrees)": -110.13,
        "Latitude": 534000000,
        "Longitude": -1100800000,
        "Elevation (m)": 574.4,
        "First Year": 1970,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1995,
        "MLY First Year": 1970,
        "MLY Last Year": 1995
      },
      {
        "Name": "TULLIBY LAKE EAST",
        "Province": "ALBERTA",
        "Climate ID": "3016591",
        "Station ID": 1992,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.65,
        "Longitude (Decimal Degrees)": -110.07,
        "Latitude": 533900000,
        "Longitude": -1100400000,
        "Elevation (m)": 583.7,
        "First Year": 1990,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2005,
        "MLY First Year": 1990,
        "MLY Last Year": 2005
      },
      {
        "Name": "TULLIBY LAKE NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3016592",
        "Station ID": 1993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -110.15,
        "Latitude": 534300000,
        "Longitude": -1100900000,
        "Elevation (m)": 594.4,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "TULLIBY LAKE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3016593",
        "Station ID": 46851,
        "WMO ID": 71857,
        "TC ID": "PTU",
        "Latitude (Decimal Degrees)": 53.66,
        "Longitude (Decimal Degrees)": -110.08,
        "Latitude": 533951009,
        "Longitude": -1100451005,
        "Elevation (m)": 596,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "TWO HILLS",
        "Province": "ALBERTA",
        "Climate ID": "3016648",
        "Station ID": 1994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -111.75,
        "Latitude": 534300000,
        "Longitude": -1114500000,
        "Elevation (m)": 670.6,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "UNCAS",
        "Province": "ALBERTA",
        "Climate ID": "3016650",
        "Station ID": 27018,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -113.03,
        "Latitude": 533000000,
        "Longitude": -1130200000,
        "Elevation (m)": 750,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "TWO HILLS AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3016655",
        "Station ID": 32459,
        "WMO ID": 71276,
        "TC ID": "XTH",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -111.68,
        "Latitude": 533731000,
        "Longitude": -1114042000,
        "Elevation (m)": 678,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "VEGREVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3016760",
        "Station ID": 1995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.48,
        "Longitude (Decimal Degrees)": -112.05,
        "Latitude": 532900000,
        "Longitude": -1120300000,
        "Elevation (m)": 634.6,
        "First Year": 1918,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1970,
        "MLY First Year": 1919,
        "MLY Last Year": 1970
      },
      {
        "Name": "VEGREVILLE CDA",
        "Province": "ALBERTA",
        "Climate ID": "3016761",
        "Station ID": 1996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.48,
        "Longitude (Decimal Degrees)": -112.03,
        "Latitude": 532900000,
        "Longitude": -1120200000,
        "Elevation (m)": 635.8,
        "First Year": 1956,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1994,
        "MLY First Year": 1956,
        "MLY Last Year": 1994
      },
      {
        "Name": "VERMILION",
        "Province": "ALBERTA",
        "Climate ID": "3016790",
        "Station ID": 1997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -110.83,
        "Latitude": 532200000,
        "Longitude": -1105000000,
        "Elevation (m)": 618.4,
        "First Year": 1906,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1940,
        "MLY First Year": 1906,
        "MLY Last Year": 1940
      },
      {
        "Name": "VERMILION A",
        "Province": "ALBERTA",
        "Climate ID": "3016800",
        "Station ID": 1998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -110.83,
        "Latitude": 532100000,
        "Longitude": -1105000000,
        "Elevation (m)": 618.7,
        "First Year": 1945,
        "Last Year": 1982,
        "HLY First Year": 1953,
        "HLY Last Year": 1982,
        "DLY First Year": 1945,
        "DLY Last Year": 1982,
        "MLY First Year": 1945,
        "MLY Last Year": 1982
      },
      {
        "Name": "VERMILION AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3016802",
        "Station ID": 32461,
        "WMO ID": 71278,
        "TC ID": "XVM",
        "Latitude (Decimal Degrees)": 53.34,
        "Longitude (Decimal Degrees)": -110.88,
        "Latitude": 532037700,
        "Longitude": -1105254700,
        "Elevation (m)": 623,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "VERMILION RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3016807",
        "Station ID": 1999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.15,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 530900000,
        "Longitude": -1120700000,
        "Elevation (m)": 685.8,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "VERMILION TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3016808",
        "Station ID": 41735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.36,
        "Longitude (Decimal Degrees)": -110.84,
        "Latitude": 532124000,
        "Longitude": -1105012000,
        "Elevation (m)": 686,
        "First Year": 2003,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2003,
        "MLY First Year": 2003,
        "MLY Last Year": 2003
      },
      {
        "Name": "VERMILION UNION",
        "Province": "ALBERTA",
        "Climate ID": "3016810",
        "Station ID": 2000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -110.68,
        "Latitude": 531700000,
        "Longitude": -1104100000,
        "Elevation (m)": 655.3,
        "First Year": 1961,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1972,
        "MLY First Year": 1961,
        "MLY Last Year": 1972
      },
      {
        "Name": "VETERAN",
        "Province": "ALBERTA",
        "Climate ID": "3016817",
        "Station ID": 2001,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -111,
        "Latitude": 520000000,
        "Longitude": -1110000000,
        "Elevation (m)": 784.6,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2007,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "VIKING",
        "Province": "ALBERTA",
        "Climate ID": "3016840",
        "Station ID": 2002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.1,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 530600000,
        "Longitude": -1114700000,
        "Elevation (m)": 691,
        "First Year": 1922,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1997,
        "MLY First Year": 1922,
        "MLY Last Year": 1997
      },
      {
        "Name": "VIKING AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3016841",
        "Station ID": 46852,
        "WMO ID": 71888,
        "TC ID": "PVA",
        "Latitude (Decimal Degrees)": 53.18,
        "Longitude (Decimal Degrees)": -111.73,
        "Latitude": 531056000,
        "Longitude": -1114354001,
        "Elevation (m)": 711,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "VIKING NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3016843",
        "Station ID": 2003,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.22,
        "Longitude (Decimal Degrees)": -111.72,
        "Latitude": 531300000,
        "Longitude": -1114300000,
        "Elevation (m)": 701,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "VIKING NORTH 2",
        "Province": "ALBERTA",
        "Climate ID": "3016844",
        "Station ID": 27463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -111.77,
        "Latitude": 531700000,
        "Longitude": -1114600000,
        "Elevation (m)": 675.8,
        "First Year": 1997,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 1998,
        "MLY First Year": 1997,
        "MLY Last Year": 1998
      },
      {
        "Name": "VIKING NORTH 3",
        "Province": "ALBERTA",
        "Climate ID": "3016845",
        "Station ID": 27715,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -111.77,
        "Latitude": 531700000,
        "Longitude": -1114600000,
        "Elevation (m)": 675.8,
        "First Year": 1998,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2016,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "VILNA",
        "Province": "ALBERTA",
        "Climate ID": "3016850",
        "Station ID": 2004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -112.02,
        "Latitude": 535800000,
        "Longitude": -1120100000,
        "Elevation (m)": 640.1,
        "First Year": 1958,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1969,
        "MLY First Year": 1958,
        "MLY Last Year": 1969
      },
      {
        "Name": "VILNA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3016852",
        "Station ID": 47110,
        "WMO ID": 71761,
        "TC ID": "PVI",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -111.92,
        "Latitude": 540748001,
        "Longitude": -1115517004,
        "Elevation (m)": 645,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VIOLET GROVE CS",
        "Province": "ALBERTA",
        "Climate ID": "3016860",
        "Station ID": 30643,
        "WMO ID": 71062,
        "TC ID": "XVG",
        "Latitude (Decimal Degrees)": 53.14,
        "Longitude (Decimal Degrees)": -115.13,
        "Latitude": 530837009,
        "Longitude": -1150735000,
        "Elevation (m)": 914,
        "First Year": 2000,
        "Last Year": 2021,
        "HLY First Year": 2000,
        "HLY Last Year": 2021,
        "DLY First Year": 2000,
        "DLY Last Year": 2021,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "WABAMUN",
        "Province": "ALBERTA",
        "Climate ID": "3016900",
        "Station ID": 2005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 533400000,
        "Longitude": -1142800000,
        "Elevation (m)": 728.5,
        "First Year": 1906,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1940,
        "MLY First Year": 1906,
        "MLY Last Year": 1940
      },
      {
        "Name": "WAINWRIGHT SNOW",
        "Province": "ALBERTA",
        "Climate ID": "3016928",
        "Station ID": 43151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -110.88,
        "Latitude": 524937000,
        "Longitude": -1105243000,
        "Elevation (m)": 686.2,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2007,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "WAINWRIGHT",
        "Province": "ALBERTA",
        "Climate ID": "3016930",
        "Station ID": 2006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -110.9,
        "Latitude": 525300000,
        "Longitude": -1105400000,
        "Elevation (m)": 685.8,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "WAINWRIGHT",
        "Province": "ALBERTA",
        "Climate ID": "3016931",
        "Station ID": 2007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -110.87,
        "Latitude": 524900000,
        "Longitude": -1105200000,
        "Elevation (m)": 681.2,
        "First Year": 1966,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1970,
        "MLY First Year": 1966,
        "MLY Last Year": 1970
      },
      {
        "Name": "WAINWRIGHT HEATH",
        "Province": "ALBERTA",
        "Climate ID": "3016932",
        "Station ID": 2008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -110.83,
        "Latitude": 525000000,
        "Longitude": -1105000000,
        "Elevation (m)": 687.3,
        "First Year": 1973,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1990,
        "MLY First Year": 1973,
        "MLY Last Year": 1990
      },
      {
        "Name": "WARWICK",
        "Province": "ALBERTA",
        "Climate ID": "3016954",
        "Station ID": 2009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.65,
        "Longitude (Decimal Degrees)": -111.98,
        "Latitude": 533900000,
        "Longitude": -1115900000,
        "Elevation (m)": 609.6,
        "First Year": 1961,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1988,
        "MLY First Year": 1961,
        "MLY Last Year": 1988
      },
      {
        "Name": "WASKATENAU",
        "Province": "ALBERTA",
        "Climate ID": "3016958",
        "Station ID": 2010,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.1,
        "Longitude (Decimal Degrees)": -112.78,
        "Latitude": 540600000,
        "Longitude": -1124700000,
        "Elevation (m)": 637,
        "First Year": 1969,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1973,
        "MLY First Year": 1969,
        "MLY Last Year": 1973
      },
      {
        "Name": "WASTINA HEMARUKA",
        "Province": "ALBERTA",
        "Climate ID": "3016960",
        "Station ID": 2011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 514200000,
        "Longitude": -1110700000,
        "Elevation (m)": 792.5,
        "First Year": 1913,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1977,
        "MLY First Year": 1913,
        "MLY Last Year": 1977
      },
      {
        "Name": "WASTINA YOUNGSTOWN",
        "Province": "ALBERTA",
        "Climate ID": "3016965",
        "Station ID": 2012,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 514000000,
        "Longitude": -1110700000,
        "Elevation (m)": 762,
        "First Year": 1977,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "VEGREVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3016GF0",
        "Station ID": 1977,
        "WMO ID": 71458,
        "TC ID": "WVI",
        "Latitude (Decimal Degrees)": 53.51,
        "Longitude (Decimal Degrees)": -112.1,
        "Latitude": 533019003,
        "Longitude": -1120552004,
        "Elevation (m)": 639.3,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "WESTEROSE",
        "Province": "ALBERTA",
        "Climate ID": "3017257",
        "Station ID": 2013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -113.98,
        "Latitude": 525700000,
        "Longitude": -1135900000,
        "Elevation (m)": 853.4,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "WESTEROSE 2",
        "Province": "ALBERTA",
        "Climate ID": "3017258",
        "Station ID": 2014,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -114.02,
        "Latitude": 525700000,
        "Longitude": -1140100000,
        "Elevation (m)": 877.8,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "WETASKIWIN",
        "Province": "ALBERTA",
        "Climate ID": "3017280",
        "Station ID": 2015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.97,
        "Longitude (Decimal Degrees)": -113.33,
        "Latitude": 525800000,
        "Longitude": -1132000000,
        "Elevation (m)": 755.9,
        "First Year": 1902,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1975,
        "MLY First Year": 1902,
        "MLY Last Year": 1975
      },
      {
        "Name": "WETASKIWIN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3017282",
        "Station ID": 46853,
        "WMO ID": 71895,
        "TC ID": "PWT",
        "Latitude (Decimal Degrees)": 52.98,
        "Longitude (Decimal Degrees)": -113.44,
        "Latitude": 525849007,
        "Longitude": -1132639003,
        "Elevation (m)": 774,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "WETASKIWIN SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3017286",
        "Station ID": 2016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.63,
        "Longitude (Decimal Degrees)": -113.38,
        "Latitude": 523800000,
        "Longitude": -1132300000,
        "Elevation (m)": 762,
        "First Year": 1985,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2008,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3017595",
        "Station ID": 2017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -114.58,
        "Latitude": 525700000,
        "Longitude": -1143500000,
        "Elevation (m)": 910,
        "First Year": 1970,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2006,
        "MLY First Year": 1970,
        "MLY Last Year": 2006
      },
      {
        "Name": "WINTERBURN KOZAK",
        "Province": "ALBERTA",
        "Climate ID": "3017606",
        "Station ID": 2018,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -113.81,
        "Latitude": 533135000,
        "Longitude": -1134840000,
        "Elevation (m)": 700.5,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "WINTERBURN LAKESHORE A",
        "Province": "ALBERTA",
        "Climate ID": "3017608",
        "Station ID": 2019,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -113.72,
        "Latitude": 533500000,
        "Longitude": -1134300000,
        "Elevation (m)": 670.6,
        "First Year": 1964,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1964,
        "MLY First Year": 1964,
        "MLY Last Year": 1964
      },
      {
        "Name": "WOSTOK",
        "Province": "ALBERTA",
        "Climate ID": "3017665",
        "Station ID": 2020,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -112.48,
        "Latitude": 534900000,
        "Longitude": -1122900000,
        "Elevation (m)": 652.3,
        "First Year": 1989,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1999,
        "MLY First Year": 1989,
        "MLY Last Year": 1999
      },
      {
        "Name": "YEOFORD",
        "Province": "ALBERTA",
        "Climate ID": "3017685",
        "Station ID": 2021,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -114.32,
        "Latitude": 530100000,
        "Longitude": -1141900000,
        "Elevation (m)": 868.7,
        "First Year": 1963,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1969,
        "MLY First Year": 1963,
        "MLY Last Year": 1969
      },
      {
        "Name": "YOUNGSTOWN",
        "Province": "ALBERTA",
        "Climate ID": "3017700",
        "Station ID": 2022,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -111.4,
        "Latitude": 512500000,
        "Longitude": -1112400000,
        "Elevation (m)": 775.1,
        "First Year": 1918,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1955,
        "MLY First Year": 1918,
        "MLY Last Year": 1955
      },
      {
        "Name": "YOUNGSTOWN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3017705",
        "Station ID": 49949,
        "WMO ID": null,
        "TC ID": "PYT",
        "Latitude (Decimal Degrees)": 51.49,
        "Longitude (Decimal Degrees)": -111.22,
        "Latitude": 512926004,
        "Longitude": -1111325006,
        "Elevation (m)": 800,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDMONTON STONY PLAIN CS",
        "Province": "ALBERTA",
        "Climate ID": "301A001",
        "Station ID": 27492,
        "WMO ID": 71127,
        "TC ID": "XPS",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -114.11,
        "Latitude": 533250080,
        "Longitude": -1140630070,
        "Elevation (m)": 766.3,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDMONTON TIEBEKE ESTATES",
        "Province": "ALBERTA",
        "Climate ID": "301A005",
        "Station ID": 27909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -113.08,
        "Latitude": 532100000,
        "Longitude": -1130500000,
        "Elevation (m)": 775.8,
        "First Year": 1999,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2001,
        "MLY First Year": 1999,
        "MLY Last Year": 2001
      },
      {
        "Name": "ESTHER PRATT",
        "Province": "ALBERTA",
        "Climate ID": "301A011",
        "Station ID": 29946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -110.27,
        "Latitude": 514000000,
        "Longitude": -1101600000,
        "Elevation (m)": 692,
        "First Year": 2000,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2003,
        "MLY First Year": 2000,
        "MLY Last Year": 2003
      },
      {
        "Name": "DAYSLAND",
        "Province": "ALBERTA",
        "Climate ID": "301AR54",
        "Station ID": 1795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -112.28,
        "Latitude": 525200000,
        "Longitude": -1121700000,
        "Elevation (m)": 688.8,
        "First Year": 1908,
        "Last Year": 1922,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1922,
        "MLY First Year": 1908,
        "MLY Last Year": 1922
      },
      {
        "Name": "ESTHER 1",
        "Province": "ALBERTA",
        "Climate ID": "301B460",
        "Station ID": 10225,
        "WMO ID": 71240,
        "TC ID": "WGY",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -110.21,
        "Latitude": 514011070,
        "Longitude": -1101222090,
        "Elevation (m)": 707,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "FLEET",
        "Province": "ALBERTA",
        "Climate ID": "301B6L0",
        "Station ID": 1797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -111.73,
        "Latitude": 520900000,
        "Longitude": -1114400000,
        "Elevation (m)": 838.2,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1987,
        "MLY Last Year": 1990
      },
      {
        "Name": "GOLDEN VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "301B8LR",
        "Station ID": 1798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.2,
        "Longitude (Decimal Degrees)": -110.15,
        "Latitude": 531200000,
        "Longitude": -1100900000,
        "Elevation (m)": 640,
        "First Year": 1987,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1998,
        "MLY First Year": 1987,
        "MLY Last Year": 1998
      },
      {
        "Name": "EDMONTON CORONATION",
        "Province": "ALBERTA",
        "Climate ID": "301BK03",
        "Station ID": 1796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 533400000,
        "Longitude": -1133400000,
        "Elevation (m)": 670.6,
        "First Year": 1978,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1979,
        "MLY First Year": 1978,
        "MLY Last Year": 1979
      },
      {
        "Name": "HORBURG",
        "Province": "ALBERTA",
        "Climate ID": "301C3D4",
        "Station ID": 1799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -115.2,
        "Latitude": 522400000,
        "Longitude": -1151200000,
        "Elevation (m)": 1036,
        "First Year": 1980,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2009,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINE RIDGE GEN",
        "Province": "ALBERTA",
        "Climate ID": "301E2B2",
        "Station ID": 1800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -112.25,
        "Latitude": 540500000,
        "Longitude": -1121500000,
        "Elevation (m)": 585.2,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "UNIVERSITY OF ALBERTA METABOLIC CENTRE",
        "Province": "ALBERTA",
        "Climate ID": "301FFNJ",
        "Station ID": 1801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -113.53,
        "Latitude": 533100000,
        "Longitude": -1133200000,
        "Elevation (m)": 668.4,
        "First Year": 1986,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2019,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "WAINWRIGHT A",
        "Province": "ALBERTA",
        "Climate ID": "301FRLJ",
        "Station ID": 10712,
        "WMO ID": null,
        "TC ID": "YWV",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -110.88,
        "Latitude": 524800000,
        "Longitude": -1105300000,
        "Elevation (m)": 662,
        "First Year": 1995,
        "Last Year": 1995,
        "HLY First Year": 1995,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WESTLOCK LITKE",
        "Province": "ALBERTA",
        "Climate ID": "301GK5H",
        "Station ID": 6853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.15,
        "Longitude (Decimal Degrees)": -113.85,
        "Latitude": 540900000,
        "Longitude": -1135100000,
        "Elevation (m)": 652.3,
        "First Year": 1993,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2008,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "FALLIS",
        "Province": "ALBERTA",
        "Climate ID": "301KF03",
        "Station ID": 1802,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 533300000,
        "Longitude": -1143800000,
        "Elevation (m)": 732,
        "First Year": 1987,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1991,
        "MLY First Year": 1987,
        "MLY Last Year": 1991
      },
      {
        "Name": "ELLSCOTT",
        "Province": "ALBERTA",
        "Climate ID": "301KKRR",
        "Station ID": 1803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.48,
        "Longitude (Decimal Degrees)": -112.95,
        "Latitude": 542900000,
        "Longitude": -1125700000,
        "Elevation (m)": 664.4,
        "First Year": 1986,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1995,
        "MLY First Year": 1986,
        "MLY Last Year": 1995
      },
      {
        "Name": "REDWATER",
        "Province": "ALBERTA",
        "Climate ID": "301N49A",
        "Station ID": 1805,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 540000000,
        "Longitude": -1131100000,
        "Elevation (m)": 617.2,
        "First Year": 1990,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2008,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED WILLOW",
        "Province": "ALBERTA",
        "Climate ID": "301NDR1",
        "Station ID": 1804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -112.57,
        "Latitude": 522500000,
        "Longitude": -1123400000,
        "Elevation (m)": 783.3,
        "First Year": 1989,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2002,
        "MLY First Year": 1989,
        "MLY Last Year": 2002
      },
      {
        "Name": "REDWATER SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "301R001",
        "Station ID": 27484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -113.12,
        "Latitude": 535000000,
        "Longitude": -1130700000,
        "Elevation (m)": 627.3,
        "First Year": 1997,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2000,
        "MLY First Year": 1997,
        "MLY Last Year": 2000
      },
      {
        "Name": "WAINWRIGHT CFB AIRFIELD 21",
        "Province": "ALBERTA",
        "Climate ID": "301S001",
        "Station ID": 26888,
        "WMO ID": 71118,
        "TC ID": "ZWN",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -111.1,
        "Latitude": 524946006,
        "Longitude": -1110544007,
        "Elevation (m)": 686.25,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMP WAINWRIGHT AIRFIELD 21",
        "Province": "ALBERTA",
        "Climate ID": "301S111",
        "Station ID": 52358,
        "WMO ID": null,
        "TC ID": "FP7",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -111.1,
        "Latitude": 525000000,
        "Longitude": -1110600000,
        "Elevation (m)": 689,
        "First Year": 2015,
        "Last Year": 2015,
        "HLY First Year": 2015,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PIBROCH AGCM",
        "Province": "ALBERTA",
        "Climate ID": "301X001",
        "Station ID": 55338,
        "WMO ID": null,
        "TC ID": "PX1",
        "Latitude (Decimal Degrees)": 54.31,
        "Longitude (Decimal Degrees)": -113.82,
        "Latitude": 541829088,
        "Longitude": -1134914007,
        "Elevation (m)": 621,
        "First Year": 2022,
        "Last Year": 2022,
        "HLY First Year": 2022,
        "HLY Last Year": 2022,
        "DLY First Year": 2022,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ACADIA VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3020018",
        "Station ID": 2030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -110.23,
        "Latitude": 511100000,
        "Longitude": -1101400000,
        "Elevation (m)": 732,
        "First Year": 1980,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1991,
        "MLY First Year": 1980,
        "MLY Last Year": 1991
      },
      {
        "Name": "ACADIA VALLEY EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3020020",
        "Station ID": 2031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -110.28,
        "Latitude": 510500000,
        "Longitude": -1101700000,
        "Elevation (m)": 712.9,
        "First Year": 1954,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1962,
        "MLY First Year": 1954,
        "MLY Last Year": 1962
      },
      {
        "Name": "ACADIA VALLEY CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3020022",
        "Station ID": 2032,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -110.13,
        "Latitude": 510400000,
        "Longitude": -1100800000,
        "Elevation (m)": null,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "ACADIA VALLEY MACTAVISH",
        "Province": "ALBERTA",
        "Climate ID": "3020025",
        "Station ID": 2033,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -110.17,
        "Latitude": 511146010,
        "Longitude": -1101008010,
        "Elevation (m)": 743.7,
        "First Year": 1988,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2005,
        "MLY First Year": 1988,
        "MLY Last Year": 2005
      },
      {
        "Name": "ACADIA VALLEY VANDYNE",
        "Province": "ALBERTA",
        "Climate ID": "3020031",
        "Station ID": 2034,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -110.3,
        "Latitude": 510200000,
        "Longitude": -1101800000,
        "Elevation (m)": 708.6,
        "First Year": 1988,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1998,
        "MLY First Year": 1988,
        "MLY Last Year": 1998
      },
      {
        "Name": "ACADIA VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3020035",
        "Station ID": 47748,
        "WMO ID": 71048,
        "TC ID": "PAC",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -110.32,
        "Latitude": 510358090,
        "Longitude": -1101901090,
        "Elevation (m)": 735,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ACME CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3020040",
        "Station ID": 2035,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -113.42,
        "Latitude": 513000000,
        "Longitude": -1132500000,
        "Elevation (m)": 905,
        "First Year": 1953,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1982,
        "MLY First Year": 1953,
        "MLY Last Year": 1982
      },
      {
        "Name": "ALIX",
        "Province": "ALBERTA",
        "Climate ID": "3020120",
        "Station ID": 2036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -113.17,
        "Latitude": 522300000,
        "Longitude": -1131000000,
        "Elevation (m)": 787.9,
        "First Year": 1905,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1951,
        "MLY First Year": 1905,
        "MLY Last Year": 1951
      },
      {
        "Name": "ALIX",
        "Province": "ALBERTA",
        "Climate ID": "3020121",
        "Station ID": 2037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 522200000,
        "Longitude": -1131800000,
        "Elevation (m)": 853.4,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "ALSASK",
        "Province": "ALBERTA",
        "Climate ID": "3020220",
        "Station ID": 2038,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -110.03,
        "Latitude": 512300000,
        "Longitude": -1100200000,
        "Elevation (m)": 701,
        "First Year": 1957,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1965,
        "MLY First Year": 1957,
        "MLY Last Year": 1965
      },
      {
        "Name": "ANTLER HILL",
        "Province": "ALBERTA",
        "Climate ID": "3020250",
        "Station ID": 2039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.05,
        "Longitude (Decimal Degrees)": -113.85,
        "Latitude": 520300000,
        "Longitude": -1135100000,
        "Elevation (m)": 944.9,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ATLEE",
        "Province": "ALBERTA",
        "Climate ID": "3020401",
        "Station ID": 2040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -110.95,
        "Latitude": 504900000,
        "Longitude": -1105700000,
        "Elevation (m)": 792.5,
        "First Year": 1982,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1995,
        "MLY First Year": 1982,
        "MLY Last Year": 1995
      },
      {
        "Name": "ATLEE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3020405",
        "Station ID": 47787,
        "WMO ID": 71986,
        "TC ID": "PAT",
        "Latitude (Decimal Degrees)": 50.81,
        "Longitude (Decimal Degrees)": -111.01,
        "Latitude": 504836070,
        "Longitude": -1110020030,
        "Elevation (m)": 795,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BASHAW",
        "Province": "ALBERTA",
        "Climate ID": "302053E",
        "Station ID": 2041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.6,
        "Longitude (Decimal Degrees)": -112.98,
        "Latitude": 523600000,
        "Longitude": -1125900000,
        "Elevation (m)": 800.1,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "BEARBERRY",
        "Province": "ALBERTA",
        "Climate ID": "3020550",
        "Station ID": 2042,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -114.87,
        "Latitude": 515400000,
        "Longitude": -1145200000,
        "Elevation (m)": 1158.2,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "BEISEKER VILLAGE",
        "Province": "ALBERTA",
        "Climate ID": "3020608",
        "Station ID": 2043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -113.53,
        "Latitude": 512300000,
        "Longitude": -1133200000,
        "Elevation (m)": 916,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "BEISEKER AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3020610",
        "Station ID": 46468,
        "WMO ID": 71009,
        "TC ID": "PBE",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -113.36,
        "Latitude": 512243007,
        "Longitude": -1132126001,
        "Elevation (m)": 896,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BENALTO",
        "Province": "ALBERTA",
        "Climate ID": "3020620",
        "Station ID": 2044,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -114.27,
        "Latitude": 521800000,
        "Longitude": -1141600000,
        "Elevation (m)": 963.5,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "BERRY CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3020636",
        "Station ID": 2045,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -111.03,
        "Latitude": 510100000,
        "Longitude": -1110200000,
        "Elevation (m)": 732,
        "First Year": 1982,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1990,
        "MLY First Year": 1982,
        "MLY Last Year": 1990
      },
      {
        "Name": "BIG STONE LOCKHART",
        "Province": "ALBERTA",
        "Climate ID": "3020670",
        "Station ID": 31327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 511200000,
        "Longitude": -1110700000,
        "Elevation (m)": 789,
        "First Year": 2002,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2004,
        "MLY First Year": 2002,
        "MLY Last Year": 2004
      },
      {
        "Name": "BIG STONE",
        "Province": "ALBERTA",
        "Climate ID": "3020676",
        "Station ID": 2046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -111.2,
        "Latitude": 511000000,
        "Longitude": -1111200000,
        "Elevation (m)": 773.9,
        "First Year": 1982,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2000,
        "MLY First Year": 1982,
        "MLY Last Year": 2000
      },
      {
        "Name": "BIG VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3020677",
        "Station ID": 2047,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.03,
        "Longitude (Decimal Degrees)": -112.75,
        "Latitude": 520200000,
        "Longitude": -1124500000,
        "Elevation (m)": 829.1,
        "First Year": 1970,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1986,
        "MLY First Year": 1970,
        "MLY Last Year": 1986
      },
      {
        "Name": "BIG VALLEY WEST",
        "Province": "ALBERTA",
        "Climate ID": "3020678",
        "Station ID": 2048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.03,
        "Longitude (Decimal Degrees)": -112.97,
        "Latitude": 520200000,
        "Longitude": -1125800000,
        "Elevation (m)": 792.5,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1983
      },
      {
        "Name": "BIG VALLEY AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3020679",
        "Station ID": 46469,
        "WMO ID": 71011,
        "TC ID": "PBV",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 515952006,
        "Longitude": -1124809006,
        "Elevation (m)": 870,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BINDLOSS",
        "Province": "ALBERTA",
        "Climate ID": "3020680",
        "Station ID": 2049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -110.17,
        "Latitude": 504100000,
        "Longitude": -1101000000,
        "Elevation (m)": 640.1,
        "First Year": 1948,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1959,
        "MLY First Year": 1948,
        "MLY Last Year": 1959
      },
      {
        "Name": "BINDLOSS EAST",
        "Province": "ALBERTA",
        "Climate ID": "3020690",
        "Station ID": 2050,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.89,
        "Longitude (Decimal Degrees)": -110.2,
        "Latitude": 505327010,
        "Longitude": -1101155050,
        "Elevation (m)": 636,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "BINDLOSS EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3020696",
        "Station ID": 2051,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -110.18,
        "Latitude": 505200000,
        "Longitude": -1101100000,
        "Elevation (m)": 641.6,
        "First Year": 1954,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "BISMARK",
        "Province": "ALBERTA",
        "Climate ID": "3020702",
        "Station ID": 2052,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.73,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 524400000,
        "Longitude": -1135200000,
        "Elevation (m)": null,
        "First Year": 1906,
        "Last Year": 1916,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1916,
        "MLY First Year": 1906,
        "MLY Last Year": 1916
      },
      {
        "Name": "BLINDMAN",
        "Province": "ALBERTA",
        "Climate ID": "3020715",
        "Station ID": 2053,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -114.48,
        "Latitude": 524600000,
        "Longitude": -1142900000,
        "Elevation (m)": 960.1,
        "First Year": 1963,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1992,
        "MLY First Year": 1963,
        "MLY Last Year": 1992
      },
      {
        "Name": "BLOOD INDIAN CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3020717",
        "Station ID": 2054,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -111.07,
        "Latitude": 505500000,
        "Longitude": -1110400000,
        "Elevation (m)": 640.1,
        "First Year": 1982,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1993,
        "MLY First Year": 1982,
        "MLY Last Year": 1993
      },
      {
        "Name": "BLUFFTON",
        "Province": "ALBERTA",
        "Climate ID": "3020730",
        "Station ID": 2055,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -114.28,
        "Latitude": 524500000,
        "Longitude": -1141700000,
        "Elevation (m)": 908.3,
        "First Year": 1963,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1984,
        "MLY First Year": 1964,
        "MLY Last Year": 1984
      },
      {
        "Name": "BOWDEN",
        "Province": "ALBERTA",
        "Climate ID": "3020760",
        "Station ID": 2056,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -114.02,
        "Latitude": 515900000,
        "Longitude": -1140100000,
        "Elevation (m)": 914.7,
        "First Year": 1957,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1985,
        "MLY First Year": 1957,
        "MLY Last Year": 1985
      },
      {
        "Name": "BROOKS ONE TREE",
        "Province": "ALBERTA",
        "Climate ID": "3020864",
        "Station ID": 2057,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 503800000,
        "Longitude": -1114700000,
        "Elevation (m)": 737,
        "First Year": 1960,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1991,
        "MLY First Year": 1960,
        "MLY Last Year": 1991
      },
      {
        "Name": "BUFFALO AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3020950",
        "Station ID": 50920,
        "WMO ID": null,
        "TC ID": "PXB",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -110.69,
        "Latitude": 504753159,
        "Longitude": -1104108088,
        "Elevation (m)": 723,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BUFFALO",
        "Province": "ALBERTA",
        "Climate ID": "3020952",
        "Station ID": 2058,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -110.93,
        "Latitude": 505600000,
        "Longitude": -1105600000,
        "Elevation (m)": 624.8,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "BULLPOUND CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3020973",
        "Station ID": 2059,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -111.88,
        "Latitude": 511700000,
        "Longitude": -1115300000,
        "Elevation (m)": 793,
        "First Year": 1982,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1992,
        "MLY First Year": 1982,
        "MLY Last Year": 1992
      },
      {
        "Name": "BURNSTICK LO",
        "Province": "ALBERTA",
        "Climate ID": "3020975",
        "Station ID": 2060,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 515800000,
        "Longitude": -1144700000,
        "Elevation (m)": 1219.2,
        "First Year": 1962,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1992,
        "MLY First Year": 1962,
        "MLY Last Year": 1992
      },
      {
        "Name": "ARNESON",
        "Province": "ALBERTA",
        "Climate ID": "3020L01",
        "Station ID": 2029,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -110.05,
        "Latitude": 511400000,
        "Longitude": -1100300000,
        "Elevation (m)": 754.4,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "CABIN LAKE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3021000",
        "Station ID": 49948,
        "WMO ID": null,
        "TC ID": "PCL",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -111.22,
        "Latitude": 510125005,
        "Longitude": -1111317009,
        "Elevation (m)": 734,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALENDULA SIBBALD",
        "Province": "ALBERTA",
        "Climate ID": "3021010",
        "Station ID": 2061,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -110.05,
        "Latitude": 512900000,
        "Longitude": -1100300000,
        "Elevation (m)": 718.7,
        "First Year": 1926,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1936,
        "MLY First Year": 1926,
        "MLY Last Year": 1936
      },
      {
        "Name": "CAPPON",
        "Province": "ALBERTA",
        "Climate ID": "3021200",
        "Station ID": 53099,
        "WMO ID": null,
        "TC ID": "PCN",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -110.8,
        "Latitude": 510401010,
        "Longitude": -1104748080,
        "Elevation (m)": 758,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPPON SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3021290",
        "Station ID": 2062,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -110.65,
        "Latitude": 510300000,
        "Longitude": -1103900000,
        "Elevation (m)": 737.6,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "CARBON",
        "Province": "ALBERTA",
        "Climate ID": "3021309",
        "Station ID": 2063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -113.15,
        "Latitude": 512900000,
        "Longitude": -1130900000,
        "Elevation (m)": 777.2,
        "First Year": 1925,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1982,
        "MLY First Year": 1925,
        "MLY Last Year": 1982
      },
      {
        "Name": "CARIBOU MTN LO",
        "Province": "ALBERTA",
        "Climate ID": "3021330",
        "Station ID": 2064,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -114.57,
        "Latitude": 513100000,
        "Longitude": -1143400000,
        "Elevation (m)": 914.4,
        "First Year": 1975,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "CARSTAIRS WEST",
        "Province": "ALBERTA",
        "Climate ID": "3021368",
        "Station ID": 2065,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -114.28,
        "Latitude": 513400000,
        "Longitude": -1141700000,
        "Elevation (m)": 1090,
        "First Year": 1984,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1994,
        "MLY First Year": 1984,
        "MLY Last Year": 1994
      },
      {
        "Name": "CEREAL",
        "Province": "ALBERTA",
        "Climate ID": "3021460",
        "Station ID": 2066,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -110.8,
        "Latitude": 512900000,
        "Longitude": -1104800000,
        "Elevation (m)": 746.8,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "CEREAL AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3021463",
        "Station ID": 50938,
        "WMO ID": null,
        "TC ID": "PCE",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -110.82,
        "Latitude": 511632879,
        "Longitude": -1104927839,
        "Elevation (m)": 753,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CESSFORD",
        "Province": "ALBERTA",
        "Climate ID": "3021465",
        "Station ID": 2067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 510100000,
        "Longitude": -1113300000,
        "Elevation (m)": 701,
        "First Year": 1982,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1998,
        "MLY First Year": 1982,
        "MLY Last Year": 1998
      },
      {
        "Name": "COALCAMP CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3021667",
        "Station ID": 8723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -114.93,
        "Latitude": 514400000,
        "Longitude": -1145600000,
        "Elevation (m)": 1379.5,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "CRAIGMYLE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3021912",
        "Station ID": 46471,
        "WMO ID": 71013,
        "TC ID": "PCM",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -112.25,
        "Latitude": 514630001,
        "Longitude": -1121508004,
        "Elevation (m)": 845,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "CRAIGMYLE",
        "Province": "ALBERTA",
        "Climate ID": "3021940",
        "Station ID": 2068,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -112.28,
        "Latitude": 514600000,
        "Longitude": -1121700000,
        "Elevation (m)": 845.8,
        "First Year": 1954,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2018,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "CREMONA",
        "Province": "ALBERTA",
        "Climate ID": "3021943",
        "Station ID": 2069,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.55,
        "Longitude (Decimal Degrees)": -114.48,
        "Latitude": 513300000,
        "Longitude": -1142900000,
        "Elevation (m)": 1168.9,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CROSSFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3021950",
        "Station ID": 2070,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -114.17,
        "Latitude": 512500000,
        "Longitude": -1141000000,
        "Elevation (m)": 1127.8,
        "First Year": 1961,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1981,
        "MLY First Year": 1961,
        "MLY Last Year": 1981
      },
      {
        "Name": "DELIA",
        "Province": "ALBERTA",
        "Climate ID": "3022060",
        "Station ID": 2071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -112.42,
        "Latitude": 513500000,
        "Longitude": -1122500000,
        "Elevation (m)": 906.2,
        "First Year": 1911,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1933,
        "MLY First Year": 1911,
        "MLY Last Year": 1933
      },
      {
        "Name": "DICKSON DAM",
        "Province": "ALBERTA",
        "Climate ID": "3022078",
        "Station ID": 2072,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.05,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 520300000,
        "Longitude": -1141300000,
        "Elevation (m)": 980,
        "First Year": 1983,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2007,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "DIDSBURY",
        "Province": "ALBERTA",
        "Climate ID": "3022083",
        "Station ID": 2073,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -114.13,
        "Latitude": 514000000,
        "Longitude": -1140800000,
        "Elevation (m)": 1005.8,
        "First Year": 1899,
        "Last Year": 1919,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1919,
        "MLY First Year": 1899,
        "MLY Last Year": 1919
      },
      {
        "Name": "DIDSBURY ELKTON",
        "Province": "ALBERTA",
        "Climate ID": "3022084",
        "Station ID": 2074,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -114.42,
        "Latitude": 513800000,
        "Longitude": -1142500000,
        "Elevation (m)": 1066.8,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "DOG POUND",
        "Province": "ALBERTA",
        "Climate ID": "3022092",
        "Station ID": 2075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 512700000,
        "Longitude": -1142700000,
        "Elevation (m)": 1158.2,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "DOWLING LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3022108",
        "Station ID": 2076,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -111.92,
        "Latitude": 514500000,
        "Longitude": -1115500000,
        "Elevation (m)": 853.1,
        "First Year": 1983,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1983,
        "MLY First Year": 1983,
        "MLY Last Year": 1983
      },
      {
        "Name": "DRUMHELLER",
        "Province": "ALBERTA",
        "Climate ID": "3022120",
        "Station ID": 2077,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -112.72,
        "Latitude": 512800000,
        "Longitude": -1124300000,
        "Elevation (m)": 687.3,
        "First Year": 1923,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1968,
        "MLY First Year": 1923,
        "MLY Last Year": 1968
      },
      {
        "Name": "DRUMHELLER ANDREW",
        "Province": "ALBERTA",
        "Climate ID": "3022136",
        "Station ID": 2078,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -112.87,
        "Latitude": 512800000,
        "Longitude": -1125200000,
        "Elevation (m)": 719.3,
        "First Year": 1954,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2008,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "DRUMHELLER INSTITUTION",
        "Province": "ALBERTA",
        "Climate ID": "3022137",
        "Station ID": 2079,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -112.68,
        "Latitude": 512500000,
        "Longitude": -1124100000,
        "Elevation (m)": 813.8,
        "First Year": 1969,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1975,
        "MLY First Year": 1969,
        "MLY Last Year": 1975
      },
      {
        "Name": "DRUMHELLER CITY",
        "Province": "ALBERTA",
        "Climate ID": "3022139",
        "Station ID": 2080,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -112.7,
        "Latitude": 512700000,
        "Longitude": -1124200000,
        "Elevation (m)": 688.2,
        "First Year": 1974,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1990,
        "MLY First Year": 1974,
        "MLY Last Year": 1990
      },
      {
        "Name": "DUCHESS",
        "Province": "ALBERTA",
        "Climate ID": "3022143",
        "Station ID": 2081,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -111.9,
        "Latitude": 504400000,
        "Longitude": -1115400000,
        "Elevation (m)": 755.3,
        "First Year": 1963,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1979,
        "MLY First Year": 1963,
        "MLY Last Year": 1979
      },
      {
        "Name": "EAST BERRY CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3022154",
        "Station ID": 2082,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -111.35,
        "Latitude": 511400000,
        "Longitude": -1112100000,
        "Elevation (m)": 791.9,
        "First Year": 1982,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1989,
        "MLY First Year": 1982,
        "MLY Last Year": 1989
      },
      {
        "Name": "ECKVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3022158",
        "Station ID": 2083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 522300000,
        "Longitude": -1142200000,
        "Elevation (m)": 914.4,
        "First Year": 1907,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1947,
        "MLY First Year": 1908,
        "MLY Last Year": 1947
      },
      {
        "Name": "ECKVILLE SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3022159",
        "Station ID": 2084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -114.39,
        "Latitude": 521705002,
        "Longitude": -1142327003,
        "Elevation (m)": 960.1,
        "First Year": 1970,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2011,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "DRUMHELLER EAST",
        "Province": "ALBERTA",
        "Climate ID": "30221LG",
        "Station ID": 10708,
        "WMO ID": 71237,
        "TC ID": "WDZ",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -112.68,
        "Latitude": 512643000,
        "Longitude": -1124036000,
        "Elevation (m)": 678.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELNORA",
        "Province": "ALBERTA",
        "Climate ID": "3022325",
        "Station ID": 2085,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.96,
        "Longitude (Decimal Degrees)": -113.19,
        "Latitude": 515750060,
        "Longitude": -1131141050,
        "Elevation (m)": 929.6,
        "First Year": 1961,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2003,
        "MLY First Year": 1961,
        "MLY Last Year": 2003
      },
      {
        "Name": "EMPRESS",
        "Province": "ALBERTA",
        "Climate ID": "3022400",
        "Station ID": 2086,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.96,
        "Longitude (Decimal Degrees)": -110.01,
        "Latitude": 505721002,
        "Longitude": -1100022080,
        "Elevation (m)": 612,
        "First Year": 1914,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2006,
        "MLY First Year": 1914,
        "MLY Last Year": 2006
      },
      {
        "Name": "EMPRESS DOT",
        "Province": "ALBERTA",
        "Climate ID": "3022402",
        "Station ID": 2087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -110.02,
        "Latitude": 505700000,
        "Longitude": -1100100000,
        "Elevation (m)": 621.8,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "ESTHER",
        "Province": "ALBERTA",
        "Climate ID": "3022460",
        "Station ID": 2088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -110.23,
        "Latitude": 513600000,
        "Longitude": -1101400000,
        "Elevation (m)": 746.8,
        "First Year": 1980,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2000,
        "MLY First Year": 1980,
        "MLY Last Year": 2000
      },
      {
        "Name": "EVARTS",
        "Province": "ALBERTA",
        "Climate ID": "3022485",
        "Station ID": 2089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 521500000,
        "Longitude": -1141300000,
        "Elevation (m)": 941.8,
        "First Year": 1963,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1972,
        "MLY First Year": 1963,
        "MLY Last Year": 1972
      },
      {
        "Name": "EXCEL",
        "Province": "ALBERTA",
        "Climate ID": "3022500",
        "Station ID": 2090,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -110.57,
        "Latitude": 513000000,
        "Longitude": -1103400000,
        "Elevation (m)": 793.7,
        "First Year": 1955,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1967,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "FINNEGAN",
        "Province": "ALBERTA",
        "Climate ID": "3022616",
        "Station ID": 2091,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -111.92,
        "Latitude": 511100000,
        "Longitude": -1115500000,
        "Elevation (m)": 746.8,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "FINNEGAN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3022650",
        "Station ID": 52459,
        "WMO ID": null,
        "TC ID": "PFF",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -112.1,
        "Latitude": 510311484,
        "Longitude": -1120550531,
        "Elevation (m)": 748,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GEM",
        "Province": "ALBERTA",
        "Climate ID": "3022772",
        "Station ID": 2092,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -112.19,
        "Latitude": 505659070,
        "Longitude": -1121120080,
        "Elevation (m)": 763.7,
        "First Year": 1989,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2009,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "GHOST PINE CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3022778",
        "Station ID": 2093,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -113.08,
        "Latitude": 513900000,
        "Longitude": -1130500000,
        "Elevation (m)": 823,
        "First Year": 1979,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1993,
        "MLY First Year": 1979,
        "MLY Last Year": 1993
      },
      {
        "Name": "GULL LAKE GOLF COURSE",
        "Province": "ALBERTA",
        "Climate ID": "3022981",
        "Station ID": 27801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.6,
        "Longitude (Decimal Degrees)": -114.07,
        "Latitude": 523547000,
        "Longitude": -1140417000,
        "Elevation (m)": 926.6,
        "First Year": 1999,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2000,
        "MLY First Year": 1999,
        "MLY Last Year": 2000
      },
      {
        "Name": "HAND HILLS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3022990",
        "Station ID": 50939,
        "WMO ID": null,
        "TC ID": "PXH",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -112.14,
        "Latitude": 512709000,
        "Longitude": -1120806719,
        "Elevation (m)": 941,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HANNA",
        "Province": "ALBERTA",
        "Climate ID": "3023000",
        "Station ID": 2095,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 513500000,
        "Longitude": -1115100000,
        "Elevation (m)": 823,
        "First Year": 1921,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1985,
        "MLY First Year": 1921,
        "MLY Last Year": 1985
      },
      {
        "Name": "HARMATTAN",
        "Province": "ALBERTA",
        "Climate ID": "3023055",
        "Station ID": 2096,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -114.38,
        "Latitude": 514500000,
        "Longitude": -1142300000,
        "Elevation (m)": 1066.8,
        "First Year": 1908,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1939,
        "MLY First Year": 1908,
        "MLY Last Year": 1939
      },
      {
        "Name": "HESPERO AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3023200",
        "Station ID": 47076,
        "WMO ID": 71540,
        "TC ID": "PHR",
        "Latitude (Decimal Degrees)": 52.31,
        "Longitude (Decimal Degrees)": -114.49,
        "Latitude": 521836008,
        "Longitude": -1142909002,
        "Elevation (m)": 965,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HILLSDOWN",
        "Province": "ALBERTA",
        "Climate ID": "3023280",
        "Station ID": 2097,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 521000000,
        "Longitude": -1133100000,
        "Elevation (m)": 896.1,
        "First Year": 1904,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1960,
        "MLY First Year": 1904,
        "MLY Last Year": 1960
      },
      {
        "Name": "HUSSAR",
        "Province": "ALBERTA",
        "Climate ID": "3023400",
        "Station ID": 2098,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -112.58,
        "Latitude": 511100000,
        "Longitude": -1123500000,
        "Elevation (m)": 937.3,
        "First Year": 1947,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1961,
        "MLY First Year": 1947,
        "MLY Last Year": 1961
      },
      {
        "Name": "HUSSAR AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3023402",
        "Station ID": 31409,
        "WMO ID": 71344,
        "TC ID": "XHR",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -112.5,
        "Latitude": 511100000,
        "Longitude": -1123000000,
        "Elevation (m)": 971.21,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUTTON EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3023420",
        "Station ID": 2099,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -111.83,
        "Latitude": 510100000,
        "Longitude": -1115000000,
        "Elevation (m)": 762,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "HUXLEY",
        "Province": "ALBERTA",
        "Climate ID": "3023422",
        "Station ID": 2100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.91,
        "Longitude (Decimal Degrees)": -113.43,
        "Latitude": 515438080,
        "Longitude": -1132559080,
        "Elevation (m)": 914.4,
        "First Year": 1966,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2003,
        "MLY First Year": 1966,
        "MLY Last Year": 2003
      },
      {
        "Name": "HUXLEY EAST",
        "Province": "ALBERTA",
        "Climate ID": "3023423",
        "Station ID": 2101,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.95,
        "Longitude (Decimal Degrees)": -112.95,
        "Latitude": 515700000,
        "Longitude": -1125700000,
        "Elevation (m)": 868.7,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "IDDESLEIGH",
        "Province": "ALBERTA",
        "Climate ID": "3023430",
        "Station ID": 2102,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.74,
        "Longitude (Decimal Degrees)": -111.32,
        "Latitude": 504423050,
        "Longitude": -1111915070,
        "Elevation (m)": 773,
        "First Year": 1982,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2008,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "JENNER",
        "Province": "ALBERTA",
        "Climate ID": "3023560",
        "Station ID": 2103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -111.2,
        "Latitude": 504320020,
        "Longitude": -1111145070,
        "Elevation (m)": 755,
        "First Year": 1915,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 2008,
        "MLY First Year": 1915,
        "MLY Last Year": 2007
      },
      {
        "Name": "KIRRIEMUIR AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3023660",
        "Station ID": 51500,
        "WMO ID": null,
        "TC ID": "PZK",
        "Latitude (Decimal Degrees)": 51.91,
        "Longitude (Decimal Degrees)": -110.36,
        "Latitude": 515447081,
        "Longitude": -1102132094,
        "Elevation (m)": 711,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KNEEHILL",
        "Province": "ALBERTA",
        "Climate ID": "3023665",
        "Station ID": 2104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.92,
        "Longitude (Decimal Degrees)": -113.78,
        "Latitude": 515500000,
        "Longitude": -1134700000,
        "Elevation (m)": 853.4,
        "First Year": 1896,
        "Last Year": 1909,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1909,
        "MLY First Year": 1896,
        "MLY Last Year": 1908
      },
      {
        "Name": "LACOMBE CDA",
        "Province": "ALBERTA",
        "Climate ID": "3023720",
        "Station ID": 2106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -113.75,
        "Latitude": 522800000,
        "Longitude": -1134500000,
        "Elevation (m)": 847.3,
        "First Year": 1907,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1996,
        "MLY First Year": 1908,
        "MLY Last Year": 1996
      },
      {
        "Name": "LACOMBE CDA 2",
        "Province": "ALBERTA",
        "Climate ID": "3023722",
        "Station ID": 10906,
        "WMO ID": 71242,
        "TC ID": "WQQ",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -113.76,
        "Latitude": 522656006,
        "Longitude": -1134521005,
        "Elevation (m)": 860,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "LEEDALE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3023740",
        "Station ID": 43561,
        "WMO ID": 71565,
        "TC ID": "NLD",
        "Latitude (Decimal Degrees)": 52.55,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 523311000,
        "Longitude": -1142821000,
        "Elevation (m)": 947,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAMERTON",
        "Province": "ALBERTA",
        "Climate ID": "30237FM",
        "Station ID": 2105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.53,
        "Longitude (Decimal Degrees)": -113.12,
        "Latitude": 523200000,
        "Longitude": -1130700000,
        "Elevation (m)": 838.2,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "LESLIEVILLE CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3023800",
        "Station ID": 2107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -114.68,
        "Latitude": 522400000,
        "Longitude": -1144100000,
        "Elevation (m)": 958.3,
        "First Year": 1951,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1965,
        "MLY First Year": 1951,
        "MLY Last Year": 1964
      },
      {
        "Name": "LINDEN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3023850",
        "Station ID": 46910,
        "WMO ID": 71035,
        "TC ID": "PLA",
        "Latitude (Decimal Degrees)": 51.62,
        "Longitude (Decimal Degrees)": -113.66,
        "Latitude": 513708009,
        "Longitude": -1133920009,
        "Elevation (m)": 914,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "LODGE CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3023900",
        "Station ID": 52458,
        "WMO ID": null,
        "TC ID": "PLZ",
        "Latitude (Decimal Degrees)": 49.46,
        "Longitude (Decimal Degrees)": -110.34,
        "Latitude": 492727756,
        "Longitude": -1102028320,
        "Elevation (m)": 1068,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LOBSTICK AUBUCHON",
        "Province": "ALBERTA",
        "Climate ID": "3023971",
        "Station ID": 2108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -114.73,
        "Latitude": 523100000,
        "Longitude": -1144400000,
        "Elevation (m)": 1066.8,
        "First Year": 1989,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2006,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "LONE PINE CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3023982",
        "Station ID": 2109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -113.83,
        "Latitude": 513500000,
        "Longitude": -1135000000,
        "Elevation (m)": 987.6,
        "First Year": 1976,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "HOMESTEAD CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3023L4M",
        "Station ID": 2094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.22,
        "Longitude (Decimal Degrees)": -112.1,
        "Latitude": 511300000,
        "Longitude": -1120600000,
        "Elevation (m)": 823,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "LOUSANA",
        "Province": "ALBERTA",
        "Climate ID": "3024000",
        "Station ID": 2111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -113.08,
        "Latitude": 520500000,
        "Longitude": -1130500000,
        "Elevation (m)": 899.2,
        "First Year": 1952,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1975,
        "MLY First Year": 1952,
        "MLY Last Year": 1975
      },
      {
        "Name": "MADDEN",
        "Province": "ALBERTA",
        "Climate ID": "3024150",
        "Station ID": 2112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -114.32,
        "Latitude": 513015070,
        "Longitude": -1141926060,
        "Elevation (m)": 1138,
        "First Year": 1962,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2012,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARKERVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3024210",
        "Station ID": 2113,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -114.13,
        "Latitude": 520700000,
        "Longitude": -1140800000,
        "Elevation (m)": 914.4,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "MIRROR FLETCHER",
        "Province": "ALBERTA",
        "Climate ID": "3024572",
        "Station ID": 2115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -113.05,
        "Latitude": 522700000,
        "Longitude": -1130300000,
        "Elevation (m)": 795,
        "First Year": 1982,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1996,
        "MLY First Year": 1982,
        "MLY Last Year": 1996
      },
      {
        "Name": "MICHICHI CREEK",
        "Province": "ALBERTA",
        "Climate ID": "30245KL",
        "Station ID": 2114,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -112.6,
        "Latitude": 513600000,
        "Longitude": -1123600000,
        "Elevation (m)": 838.2,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "MORNINGSIDE",
        "Province": "ALBERTA",
        "Climate ID": "3024664",
        "Station ID": 2116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.57,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 523400000,
        "Longitude": -1133400000,
        "Elevation (m)": 899.2,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "MORRIN",
        "Province": "ALBERTA",
        "Climate ID": "3024665",
        "Station ID": 2117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -112.6,
        "Latitude": 513900000,
        "Longitude": -1123600000,
        "Elevation (m)": 853.4,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1965
      },
      {
        "Name": "MORRIN AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3024668",
        "Station ID": 32433,
        "WMO ID": 71257,
        "TC ID": "XMO",
        "Latitude (Decimal Degrees)": 51.66,
        "Longitude (Decimal Degrees)": -112.68,
        "Latitude": 513937000,
        "Longitude": -1124030000,
        "Elevation (m)": 836,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": 1991,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOUND",
        "Province": "ALBERTA",
        "Climate ID": "3024680",
        "Station ID": 2118,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -114.52,
        "Latitude": 514100000,
        "Longitude": -1143100000,
        "Elevation (m)": null,
        "First Year": 1952,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1954,
        "MLY First Year": 1952,
        "MLY Last Year": 1954
      },
      {
        "Name": "OAKLAND LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3024906",
        "Station ID": 2119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -111.95,
        "Latitude": 512300000,
        "Longitude": -1115700000,
        "Elevation (m)": 844.9,
        "First Year": 1983,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1988,
        "MLY First Year": 1983,
        "MLY Last Year": 1988
      },
      {
        "Name": "OLDS",
        "Province": "ALBERTA",
        "Climate ID": "3024920",
        "Station ID": 2120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 514700000,
        "Longitude": -1140600000,
        "Elevation (m)": 1040.3,
        "First Year": 1914,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2015,
        "MLY First Year": 1914,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLDS AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3024925",
        "Station ID": 32453,
        "WMO ID": 71258,
        "TC ID": "XOL",
        "Latitude (Decimal Degrees)": 51.76,
        "Longitude (Decimal Degrees)": -114.08,
        "Latitude": 514531000,
        "Longitude": -1140505000,
        "Elevation (m)": 1046,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "OYEN",
        "Province": "ALBERTA",
        "Climate ID": "3024960",
        "Station ID": 2121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -110.48,
        "Latitude": 512700000,
        "Longitude": -1102900000,
        "Elevation (m)": 777.2,
        "First Year": 1912,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1959,
        "MLY First Year": 1912,
        "MLY Last Year": 1959
      },
      {
        "Name": "OYEN CAPPON",
        "Province": "ALBERTA",
        "Climate ID": "3024961",
        "Station ID": 2122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -110.52,
        "Latitude": 511000000,
        "Longitude": -1103100000,
        "Elevation (m)": 792.5,
        "First Year": 1974,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2013,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "OYEN CARA",
        "Province": "ALBERTA",
        "Climate ID": "3024962",
        "Station ID": 2123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.35,
        "Longitude (Decimal Degrees)": -110.47,
        "Latitude": 512110040,
        "Longitude": -1102818010,
        "Elevation (m)": 769.6,
        "First Year": 1988,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2014,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "OYEN AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3024965",
        "Station ID": 31410,
        "WMO ID": 71342,
        "TC ID": "XOY",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -110.35,
        "Latitude": 512300000,
        "Longitude": -1102100000,
        "Elevation (m)": 766.67,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "PANDORA EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3024980",
        "Station ID": 2124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -111.73,
        "Latitude": 510100000,
        "Longitude": -1114400000,
        "Elevation (m)": 762,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "OYEN AIRPORT",
        "Province": "ALBERTA",
        "Climate ID": "3024R60",
        "Station ID": 2110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.33,
        "Longitude (Decimal Degrees)": -110.48,
        "Latitude": 512000000,
        "Longitude": -1102900000,
        "Elevation (m)": 761.4,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "PATRICIA",
        "Province": "ALBERTA",
        "Climate ID": "3025010",
        "Station ID": 2126,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -111.67,
        "Latitude": 504000000,
        "Longitude": -1114000000,
        "Elevation (m)": 732.4,
        "First Year": 1921,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1935,
        "MLY First Year": 1921,
        "MLY Last Year": 1935
      },
      {
        "Name": "PATRICIA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3025013",
        "Station ID": 50923,
        "WMO ID": null,
        "TC ID": "PPT",
        "Latitude (Decimal Degrees)": 50.74,
        "Longitude (Decimal Degrees)": -111.62,
        "Latitude": 504424072,
        "Longitude": -1113715599,
        "Elevation (m)": 726,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PERBECK",
        "Province": "ALBERTA",
        "Climate ID": "3025170",
        "Station ID": 2127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -113.08,
        "Latitude": 515800000,
        "Longitude": -1130500000,
        "Elevation (m)": 868.7,
        "First Year": 1913,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1938,
        "MLY First Year": 1913,
        "MLY Last Year": 1938
      },
      {
        "Name": "PINE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3025222",
        "Station ID": 2128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -113.5,
        "Latitude": 521000000,
        "Longitude": -1133000000,
        "Elevation (m)": 944.9,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "POLLOCKVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3025240",
        "Station ID": 2129,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -111.6,
        "Latitude": 510500000,
        "Longitude": -1113600000,
        "Elevation (m)": 746.8,
        "First Year": 1952,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1983,
        "MLY First Year": 1952,
        "MLY Last Year": 1983
      },
      {
        "Name": "POLLOCKVILLE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3025243",
        "Station ID": 32454,
        "WMO ID": 71259,
        "TC ID": "XPL",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -111.71,
        "Latitude": 510731000,
        "Longitude": -1114219000,
        "Elevation (m)": 750,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRENTISS",
        "Province": "ALBERTA",
        "Climate ID": "3025296",
        "Station ID": 2130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -113.67,
        "Latitude": 522200000,
        "Longitude": -1134000000,
        "Elevation (m)": 883.9,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "PRENTISS",
        "Province": "ALBERTA",
        "Climate ID": "3025297",
        "Station ID": 41784,
        "WMO ID": 71353,
        "TC ID": "ZPS",
        "Latitude (Decimal Degrees)": 52.43,
        "Longitude (Decimal Degrees)": -113.6,
        "Latitude": 522600000,
        "Longitude": -1133600000,
        "Elevation (m)": 965,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED DEER",
        "Province": "ALBERTA",
        "Climate ID": "3025440",
        "Station ID": 2132,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -113.82,
        "Latitude": 521700000,
        "Longitude": -1134900000,
        "Elevation (m)": 859.5,
        "First Year": 1904,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1974,
        "MLY First Year": 1904,
        "MLY Last Year": 1974
      },
      {
        "Name": "RED DEER",
        "Province": "ALBERTA",
        "Climate ID": "3025441",
        "Station ID": 2133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.31,
        "Longitude (Decimal Degrees)": -113.79,
        "Latitude": 521839007,
        "Longitude": -1134732002,
        "Elevation (m)": 846.7,
        "First Year": 1974,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2013,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED DEER CCOC",
        "Province": "ALBERTA",
        "Climate ID": "3025445",
        "Station ID": 45927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -113.83,
        "Latitude": 521448000,
        "Longitude": -1134940000,
        "Elevation (m)": 878,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED DEER A",
        "Province": "ALBERTA",
        "Climate ID": "3025480",
        "Station ID": 2134,
        "WMO ID": 71878,
        "TC ID": "YQF",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -113.89,
        "Latitude": 521043000,
        "Longitude": -1135335000,
        "Elevation (m)": 904.6,
        "First Year": 1938,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1938,
        "DLY Last Year": 2014,
        "MLY First Year": 1938,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED DEER REGIONAL A",
        "Province": "ALBERTA",
        "Climate ID": "3025481",
        "Station ID": 51440,
        "WMO ID": null,
        "TC ID": "YQF",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -113.89,
        "Latitude": 521056000,
        "Longitude": -1135340000,
        "Elevation (m)": 904.6,
        "First Year": 2014,
        "Last Year": 2021,
        "HLY First Year": 2014,
        "HLY Last Year": 2021,
        "DLY First Year": 2014,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RED DEER OVERLAP",
        "Province": "ALBERTA",
        "Climate ID": "3025483",
        "Station ID": 54478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -112.11,
        "Latitude": 521055092,
        "Longitude": -1120620016,
        "Elevation (m)": 904.6,
        "First Year": 2018,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2018,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RED DEER REGIONAL A",
        "Province": "ALBERTA",
        "Climate ID": "3025484",
        "Station ID": 55018,
        "WMO ID": 71878,
        "TC ID": "YQF",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -113.89,
        "Latitude": 521056000,
        "Longitude": -1135340000,
        "Elevation (m)": 904.6,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIMBEY",
        "Province": "ALBERTA",
        "Climate ID": "3025494",
        "Station ID": 2135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.63,
        "Longitude (Decimal Degrees)": -114.23,
        "Latitude": 523800000,
        "Longitude": -1141400000,
        "Elevation (m)": 917,
        "First Year": 1957,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1991,
        "MLY First Year": 1957,
        "MLY Last Year": 1991
      },
      {
        "Name": "REDLAND",
        "Province": "ALBERTA",
        "Climate ID": "30254RJ",
        "Station ID": 2131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -113.07,
        "Latitude": 512200000,
        "Longitude": -1130400000,
        "Elevation (m)": 853.4,
        "First Year": 1966,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "ROSEBUD",
        "Province": "ALBERTA",
        "Climate ID": "3025543",
        "Station ID": 2136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -113,
        "Latitude": 512300000,
        "Longitude": -1130000000,
        "Elevation (m)": 887,
        "First Year": 1966,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1974,
        "MLY First Year": 1966,
        "MLY Last Year": 1974
      },
      {
        "Name": "ST. ALBERT RESEARCH STATION",
        "Province": "ALBERTA",
        "Climate ID": "3025750",
        "Station ID": 49050,
        "WMO ID": null,
        "TC ID": "PSR",
        "Latitude (Decimal Degrees)": 53.69,
        "Longitude (Decimal Degrees)": -113.62,
        "Latitude": 534131000,
        "Longitude": -1133711000,
        "Elevation (m)": 687,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SCAPA",
        "Province": "ALBERTA",
        "Climate ID": "3025762",
        "Station ID": 2137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -112.02,
        "Latitude": 515200000,
        "Longitude": -1120100000,
        "Elevation (m)": 816.8,
        "First Year": 1982,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1999,
        "MLY First Year": 1982,
        "MLY Last Year": 1999
      },
      {
        "Name": "SCHULER",
        "Province": "ALBERTA",
        "Climate ID": "3025764",
        "Station ID": 2138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.31,
        "Longitude (Decimal Degrees)": -110.09,
        "Latitude": 501833050,
        "Longitude": -1100510000,
        "Elevation (m)": 803,
        "First Year": 1980,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2005,
        "MLY First Year": 1980,
        "MLY Last Year": 2005
      },
      {
        "Name": "SCOLLARD",
        "Province": "ALBERTA",
        "Climate ID": "3025766",
        "Station ID": 2139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.93,
        "Longitude (Decimal Degrees)": -112.87,
        "Latitude": 515600000,
        "Longitude": -1125200000,
        "Elevation (m)": 853.4,
        "First Year": 1969,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1998,
        "MLY First Year": 1969,
        "MLY Last Year": 1998
      },
      {
        "Name": "SCHULER AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3025768",
        "Station ID": 32455,
        "WMO ID": 71269,
        "TC ID": "XSC",
        "Latitude (Decimal Degrees)": 50.31,
        "Longitude (Decimal Degrees)": -110.09,
        "Latitude": 501825000,
        "Longitude": -1100526000,
        "Elevation (m)": 800,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "SCOTFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3025770",
        "Station ID": 2140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -111.36,
        "Latitude": 513500080,
        "Longitude": -1112149002,
        "Elevation (m)": 762,
        "First Year": 1961,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2007,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHEERNESS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3025890",
        "Station ID": 50937,
        "WMO ID": null,
        "TC ID": "PXS",
        "Latitude (Decimal Degrees)": 51.46,
        "Longitude (Decimal Degrees)": -111.65,
        "Latitude": 512746008,
        "Longitude": -1113847039,
        "Elevation (m)": 852,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIBBALD",
        "Province": "ALBERTA",
        "Climate ID": "3025920",
        "Station ID": 2141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -110.25,
        "Latitude": 512830090,
        "Longitude": -1101453010,
        "Elevation (m)": 725,
        "First Year": 1953,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 2006,
        "MLY First Year": 1953,
        "MLY Last Year": 2006
      },
      {
        "Name": "SIBBALD 2",
        "Province": "ALBERTA",
        "Climate ID": "3025921",
        "Station ID": 2142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -110.15,
        "Latitude": 512500000,
        "Longitude": -1100900000,
        "Elevation (m)": 731.5,
        "First Year": 1955,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1968,
        "MLY First Year": 1955,
        "MLY Last Year": 1968
      },
      {
        "Name": "SIBBALD EAST",
        "Province": "ALBERTA",
        "Climate ID": "3025925",
        "Station ID": 2143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -110.13,
        "Latitude": 512600000,
        "Longitude": -1100800000,
        "Elevation (m)": 731.5,
        "First Year": 1980,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1989,
        "MLY First Year": 1980,
        "MLY Last Year": 1989
      },
      {
        "Name": "SIBBALD WESTGARD",
        "Province": "ALBERTA",
        "Climate ID": "3025928",
        "Station ID": 2144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -110.15,
        "Latitude": 512529000,
        "Longitude": -1100844090,
        "Elevation (m)": 723.9,
        "First Year": 1988,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2018,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "SKYTREX",
        "Province": "ALBERTA",
        "Climate ID": "3025984",
        "Station ID": 29887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.59,
        "Longitude (Decimal Degrees)": -112.57,
        "Latitude": 513533048,
        "Longitude": -1123401074,
        "Elevation (m)": 866.15,
        "First Year": 2000,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2003,
        "MLY First Year": 2000,
        "MLY Last Year": 2003
      },
      {
        "Name": "PINE LAKE NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3025K22",
        "Station ID": 2125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -113.48,
        "Latitude": 521200000,
        "Longitude": -1132900000,
        "Elevation (m)": 899.2,
        "First Year": 1981,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1992,
        "MLY First Year": 1981,
        "MLY Last Year": 1992
      },
      {
        "Name": "SLEEPY HOLLOW",
        "Province": "ALBERTA",
        "Climate ID": "3026009",
        "Station ID": 2145,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.49,
        "Longitude (Decimal Degrees)": -112.96,
        "Latitude": 512931007,
        "Longitude": -1125733002,
        "Elevation (m)": 811,
        "First Year": 1988,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2014,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPRINGDALE",
        "Province": "ALBERTA",
        "Climate ID": "3026080",
        "Station ID": 2146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -114.3,
        "Latitude": 524800000,
        "Longitude": -1141800000,
        "Elevation (m)": 914.4,
        "First Year": 1913,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1956,
        "MLY First Year": 1913,
        "MLY Last Year": 1956
      },
      {
        "Name": "STANMORE EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3026090",
        "Station ID": 2147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -111.47,
        "Latitude": 513500000,
        "Longitude": -1112800000,
        "Elevation (m)": 786.4,
        "First Year": 1955,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1955,
        "MLY First Year": 1955,
        "MLY Last Year": 1955
      },
      {
        "Name": "STAUFFER",
        "Province": "ALBERTA",
        "Climate ID": "3026095",
        "Station ID": 2148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -114.57,
        "Latitude": 520800000,
        "Longitude": -1143400000,
        "Elevation (m)": 975.4,
        "First Year": 1963,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1964,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "SUNDRE",
        "Province": "ALBERTA",
        "Climate ID": "3026258",
        "Station ID": 2149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 514500000,
        "Longitude": -1144700000,
        "Elevation (m)": 1219,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "SUNDRE GARRINGTON",
        "Province": "ALBERTA",
        "Climate ID": "3026259",
        "Station ID": 2150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 515800000,
        "Longitude": -1142700000,
        "Elevation (m)": 990.6,
        "First Year": 1985,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2008,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUNDRE RS",
        "Province": "ALBERTA",
        "Climate ID": "3026260",
        "Station ID": 2151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 514600000,
        "Longitude": -1143800000,
        "Elevation (m)": 1127.8,
        "First Year": 1962,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1985,
        "MLY First Year": 1962,
        "MLY Last Year": 1985
      },
      {
        "Name": "SUNNYNOOK",
        "Province": "ALBERTA",
        "Climate ID": "3026263",
        "Station ID": 2152,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -111.65,
        "Latitude": 511400000,
        "Longitude": -1113900000,
        "Elevation (m)": 738.8,
        "First Year": 1982,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1984,
        "MLY First Year": 1982,
        "MLY Last Year": 1984
      },
      {
        "Name": "SUNNYNOOK AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3026264",
        "Station ID": 53100,
        "WMO ID": null,
        "TC ID": "PNU",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -111.39,
        "Latitude": 511803010,
        "Longitude": -1112317030,
        "Elevation (m)": null,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUNSET",
        "Province": "ALBERTA",
        "Climate ID": "3026265",
        "Station ID": 2153,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -114.52,
        "Latitude": 525100000,
        "Longitude": -1143100000,
        "Elevation (m)": 975.4,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "SWALWELL",
        "Province": "ALBERTA",
        "Climate ID": "3026275",
        "Station ID": 2154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 513500000,
        "Longitude": -1132100000,
        "Elevation (m)": 908.3,
        "First Year": 1961,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1971,
        "MLY First Year": 1961,
        "MLY Last Year": 1971
      },
      {
        "Name": "SYLVAN LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3026330",
        "Station ID": 2155,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -114.17,
        "Latitude": 522100000,
        "Longitude": -1141000000,
        "Elevation (m)": 920.5,
        "First Year": 1962,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1993,
        "MLY First Year": 1962,
        "MLY Last Year": 1993
      },
      {
        "Name": "THREE HILLS",
        "Province": "ALBERTA",
        "Climate ID": "3026479",
        "Station ID": 10907,
        "WMO ID": 71249,
        "TC ID": "WHI",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -113.21,
        "Latitude": 514603082,
        "Longitude": -1131220095,
        "Elevation (m)": 857,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "THREE HILLS",
        "Province": "ALBERTA",
        "Climate ID": "3026480",
        "Station ID": 2156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 513900000,
        "Longitude": -1131800000,
        "Elevation (m)": 841.2,
        "First Year": 1921,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1978,
        "MLY First Year": 1921,
        "MLY Last Year": 1978
      },
      {
        "Name": "THREE HILLS EAST",
        "Province": "ALBERTA",
        "Climate ID": "3026484",
        "Station ID": 2157,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -113.23,
        "Latitude": 514300000,
        "Longitude": -1131400000,
        "Elevation (m)": 853.4,
        "First Year": 1981,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1988,
        "MLY First Year": 1981,
        "MLY Last Year": 1988
      },
      {
        "Name": "THREE HILLS RILLING IHD",
        "Province": "ALBERTA",
        "Climate ID": "3026486",
        "Station ID": 2158,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -113.53,
        "Latitude": 514300000,
        "Longitude": -1133200000,
        "Elevation (m)": 975.4,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "THREE HILLS TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3026487",
        "Station ID": 2159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -113.27,
        "Latitude": 514200000,
        "Longitude": -1131600000,
        "Elevation (m)": 914.4,
        "First Year": 1969,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1977,
        "MLY First Year": 1969,
        "MLY Last Year": 1977
      },
      {
        "Name": "TROCHU 2",
        "Province": "ALBERTA",
        "Climate ID": "3026521",
        "Station ID": 2160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.83,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 515000000,
        "Longitude": -1131300000,
        "Elevation (m)": 870.5,
        "First Year": 1951,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1975,
        "MLY First Year": 1951,
        "MLY Last Year": 1975
      },
      {
        "Name": "TROCHU EQUITY",
        "Province": "ALBERTA",
        "Climate ID": "3026530",
        "Station ID": 2161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -113.2,
        "Latitude": 514700000,
        "Longitude": -1131200000,
        "Elevation (m)": 854.3,
        "First Year": 1954,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1989,
        "MLY First Year": 1954,
        "MLY Last Year": 1989
      },
      {
        "Name": "TROCHU TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3026540",
        "Station ID": 2162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.83,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 515000000,
        "Longitude": -1131300000,
        "Elevation (m)": 876.3,
        "First Year": 1980,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2003,
        "MLY First Year": 1980,
        "MLY Last Year": 2003
      },
      {
        "Name": "TROCHU EAST",
        "Province": "ALBERTA",
        "Climate ID": "3026543",
        "Station ID": 32273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.82,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 514927060,
        "Longitude": -1131254040,
        "Elevation (m)": 853,
        "First Year": 2002,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "TROCHU WIMBORNE",
        "Province": "ALBERTA",
        "Climate ID": "3026550",
        "Station ID": 2163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.85,
        "Longitude (Decimal Degrees)": -113.45,
        "Latitude": 515100000,
        "Longitude": -1132700000,
        "Elevation (m)": 891.5,
        "First Year": 1950,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1963,
        "MLY First Year": 1950,
        "MLY Last Year": 1962
      },
      {
        "Name": "TWO BAR LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3026646",
        "Station ID": 2164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -112.55,
        "Latitude": 511700000,
        "Longitude": -1123300000,
        "Elevation (m)": 876.3,
        "First Year": 1981,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1991,
        "MLY First Year": 1981,
        "MLY Last Year": 1991
      },
      {
        "Name": "VERGER",
        "Province": "ALBERTA",
        "Climate ID": "3026800",
        "Station ID": 53078,
        "WMO ID": null,
        "TC ID": "PVR",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -111.96,
        "Latitude": 505342076,
        "Longitude": -1115718022,
        "Elevation (m)": 723,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUNDRE SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3026K61",
        "Station ID": 8743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 514745000,
        "Longitude": -1143805000,
        "Elevation (m)": 1090,
        "First Year": 2004,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2004,
        "MLY First Year": 2004,
        "MLY Last Year": 2004
      },
      {
        "Name": "SUNDRE A",
        "Province": "ALBERTA",
        "Climate ID": "3026KNQ",
        "Station ID": 10105,
        "WMO ID": 71248,
        "TC ID": "WAV",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -114.68,
        "Latitude": 514641000,
        "Longitude": -1144057000,
        "Elevation (m)": 1114.4,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "WESTWARD HO",
        "Province": "ALBERTA",
        "Climate ID": "3027260",
        "Station ID": 2165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.83,
        "Longitude (Decimal Degrees)": -114.52,
        "Latitude": 515000000,
        "Longitude": -1143100000,
        "Elevation (m)": 1066.8,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "WIMBORNE GAS PLANT",
        "Province": "ALBERTA",
        "Climate ID": "3027580",
        "Station ID": 2166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 515400000,
        "Longitude": -1133400000,
        "Elevation (m)": 960.1,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "WIMBORNE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3027582",
        "Station ID": 46729,
        "WMO ID": 71904,
        "TC ID": "PWB",
        "Latitude (Decimal Degrees)": 51.94,
        "Longitude (Decimal Degrees)": -113.59,
        "Latitude": 515609009,
        "Longitude": -1133527002,
        "Elevation (m)": 940,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOADLEY",
        "Province": "ALBERTA",
        "Climate ID": "302CCDB",
        "Station ID": 2023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 525100000,
        "Longitude": -1141200000,
        "Elevation (m)": 914.4,
        "First Year": 1966,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1983,
        "MLY First Year": 1966,
        "MLY Last Year": 1983
      },
      {
        "Name": "OLDS CLARKE",
        "Province": "ALBERTA",
        "Climate ID": "302D920",
        "Station ID": 2024,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 514400000,
        "Longitude": -1140600000,
        "Elevation (m)": 1042.7,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "THREE HILLS SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "302F4HF",
        "Station ID": 2025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -113.31,
        "Latitude": 514056080,
        "Longitude": -1131847040,
        "Elevation (m)": 846,
        "First Year": 1981,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2008,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEYSTONE LO",
        "Province": "ALBERTA",
        "Climate ID": "302L643",
        "Station ID": 2026,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 512200000,
        "Longitude": -1144700000,
        "Elevation (m)": 1585,
        "First Year": 1975,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1992,
        "MLY First Year": 1975,
        "MLY Last Year": 1992
      },
      {
        "Name": "OLDS ERICKSON",
        "Province": "ALBERTA",
        "Climate ID": "302M920",
        "Station ID": 2027,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 514700000,
        "Longitude": -1141200000,
        "Elevation (m)": 1014,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1991,
        "MLY First Year": 1988,
        "MLY Last Year": 1991
      },
      {
        "Name": "SHEERNESS CARAGANA",
        "Province": "ALBERTA",
        "Climate ID": "302NH8L",
        "Station ID": 2028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -111.63,
        "Latitude": 512700000,
        "Longitude": -1113800000,
        "Elevation (m)": 815.3,
        "First Year": 1988,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "AIRDRIE",
        "Province": "ALBERTA",
        "Climate ID": "3030060",
        "Station ID": 2181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -113.88,
        "Latitude": 511900000,
        "Longitude": -1135300000,
        "Elevation (m)": 1082,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "ARDENVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3030200",
        "Station ID": 53098,
        "WMO ID": null,
        "TC ID": "PAV",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -113.43,
        "Latitude": 493139040,
        "Longitude": -1132602090,
        "Elevation (m)": 1126,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARMADA EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3030300",
        "Station ID": 2182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -112.68,
        "Latitude": 502400000,
        "Longitude": -1124100000,
        "Elevation (m)": 911.4,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "ARROWWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3030302",
        "Station ID": 2183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -113.15,
        "Latitude": 504400000,
        "Longitude": -1130900000,
        "Elevation (m)": 936.3,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "AZURE",
        "Province": "ALBERTA",
        "Climate ID": "3030450",
        "Station ID": 47727,
        "WMO ID": 71981,
        "TC ID": "PAZ",
        "Latitude (Decimal Degrees)": 50.51,
        "Longitude (Decimal Degrees)": -114.01,
        "Latitude": 503043000,
        "Longitude": -1140047020,
        "Elevation (m)": 1143,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BARNWELL AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3030525",
        "Station ID": 31408,
        "WMO ID": 71346,
        "TC ID": "XBW",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -112.3,
        "Latitude": 494800000,
        "Longitude": -1121800000,
        "Elevation (m)": 824.24,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "BARONS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3030529",
        "Station ID": 46447,
        "WMO ID": 71007,
        "TC ID": "PBA",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 500133008,
        "Longitude": -1131328009,
        "Elevation (m)": 967,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BARONS EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3030530",
        "Station ID": 2184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -113.17,
        "Latitude": 500200000,
        "Longitude": -1131000000,
        "Elevation (m)": 961,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "BARONS EXP ST 2",
        "Province": "ALBERTA",
        "Climate ID": "3030531",
        "Station ID": 2185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -112.92,
        "Latitude": 500000000,
        "Longitude": -1125500000,
        "Elevation (m)": 960.1,
        "First Year": 1956,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1960,
        "MLY First Year": 1956,
        "MLY Last Year": 1959
      },
      {
        "Name": "BASSANO DAM",
        "Province": "ALBERTA",
        "Climate ID": "3030537",
        "Station ID": 2186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -112.47,
        "Latitude": 504700000,
        "Longitude": -1122800000,
        "Elevation (m)": 800.1,
        "First Year": 1914,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1935,
        "MLY First Year": 1914,
        "MLY Last Year": 1935
      },
      {
        "Name": "BASSANO GEM",
        "Province": "ALBERTA",
        "Climate ID": "3030538",
        "Station ID": 2187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -112.2,
        "Latitude": 505400000,
        "Longitude": -1121200000,
        "Elevation (m)": 751,
        "First Year": 1916,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1935,
        "MLY First Year": 1916,
        "MLY Last Year": 1935
      },
      {
        "Name": "BASSANO AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3030540",
        "Station ID": 46467,
        "WMO ID": 71008,
        "TC ID": "PBS",
        "Latitude (Decimal Degrees)": 50.89,
        "Longitude (Decimal Degrees)": -112.47,
        "Latitude": 505333005,
        "Longitude": -1122754009,
        "Elevation (m)": 807,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAUVAIS PARK",
        "Province": "ALBERTA",
        "Climate ID": "3030555",
        "Station ID": 8753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 492500000,
        "Longitude": -1140600000,
        "Elevation (m)": 1524,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "BIG COULEE",
        "Province": "ALBERTA",
        "Climate ID": "3030665",
        "Station ID": 2188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -113.8,
        "Latitude": 494500000,
        "Longitude": -1134800000,
        "Elevation (m)": 1325,
        "First Year": 1980,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1985,
        "MLY First Year": 1980,
        "MLY Last Year": 1985
      },
      {
        "Name": "BLACK DIAMOND",
        "Province": "ALBERTA",
        "Climate ID": "3030680",
        "Station ID": 47747,
        "WMO ID": 71982,
        "TC ID": "PBD",
        "Latitude (Decimal Degrees)": 50.71,
        "Longitude (Decimal Degrees)": -114.15,
        "Latitude": 504224008,
        "Longitude": -1140906002,
        "Elevation (m)": 1156,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BLACKIE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3030690",
        "Station ID": 50917,
        "WMO ID": null,
        "TC ID": "PXC",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -113.64,
        "Latitude": 503244084,
        "Longitude": -1133825000,
        "Elevation (m)": 1019,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BLOOD TRIBE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3030720",
        "Station ID": 42703,
        "WMO ID": 71517,
        "TC ID": "PBT",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -113.05,
        "Latitude": 493400000,
        "Longitude": -1130300000,
        "Elevation (m)": 980,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOW ISLAND",
        "Province": "ALBERTA",
        "Climate ID": "3030768",
        "Station ID": 10915,
        "WMO ID": 71231,
        "TC ID": "WXL",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 494403070,
        "Longitude": -1112701000,
        "Elevation (m)": 816.6,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOW ISLAND",
        "Province": "ALBERTA",
        "Climate ID": "3030769",
        "Station ID": 8755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -111.38,
        "Latitude": 495200000,
        "Longitude": -1112300000,
        "Elevation (m)": 798.9,
        "First Year": 2003,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2004,
        "MLY First Year": 2003,
        "MLY Last Year": 2004
      },
      {
        "Name": "BOW ISLAND EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3030770",
        "Station ID": 2189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -111.37,
        "Latitude": 495200000,
        "Longitude": -1112200000,
        "Elevation (m)": 798.6,
        "First Year": 1955,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1959,
        "MLY First Year": 1955,
        "MLY Last Year": 1959
      },
      {
        "Name": "BOW ISLAND IRRIGATION AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3030772",
        "Station ID": 42723,
        "WMO ID": 71519,
        "TC ID": "PXL",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -111.37,
        "Latitude": 495205052,
        "Longitude": -1112158068,
        "Elevation (m)": 796,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "BOW ISLAND RIVERS DEV",
        "Province": "ALBERTA",
        "Climate ID": "3030773",
        "Station ID": 2190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -111.37,
        "Latitude": 495200000,
        "Longitude": -1112200000,
        "Elevation (m)": 798.9,
        "First Year": 1958,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1976,
        "MLY First Year": 1958,
        "MLY Last Year": 1976
      },
      {
        "Name": "BOWNESS",
        "Province": "ALBERTA",
        "Climate ID": "3030775",
        "Station ID": 2191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.12,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 510700000,
        "Longitude": -1141300000,
        "Elevation (m)": 1036.3,
        "First Year": 1959,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1959,
        "MLY Last Year": 1959
      },
      {
        "Name": "BRAGG CREEK WEST",
        "Province": "ALBERTA",
        "Climate ID": "3030796",
        "Station ID": 2192,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -114.62,
        "Latitude": 505800000,
        "Longitude": -1143700000,
        "Elevation (m)": 1371.9,
        "First Year": 1975,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1985,
        "MLY First Year": 1975,
        "MLY Last Year": 1985
      },
      {
        "Name": "BRANT",
        "Province": "ALBERTA",
        "Climate ID": "3030798",
        "Station ID": 2193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -113.5,
        "Latitude": 502700000,
        "Longitude": -1133000000,
        "Elevation (m)": 990.6,
        "First Year": 1983,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1989,
        "MLY First Year": 1983,
        "MLY Last Year": 1989
      },
      {
        "Name": "BROCKET AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3030830",
        "Station ID": 31407,
        "WMO ID": 71341,
        "TC ID": "XBR",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -113.82,
        "Latitude": 493700000,
        "Longitude": -1134900000,
        "Elevation (m)": 1072.73,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROOKS",
        "Province": "ALBERTA",
        "Climate ID": "3030838",
        "Station ID": 2194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.58,
        "Longitude (Decimal Degrees)": -111.9,
        "Latitude": 503500000,
        "Longitude": -1115400000,
        "Elevation (m)": 755.3,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": 1966,
        "HLY Last Year": 1979,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "BROOKS 1",
        "Province": "ALBERTA",
        "Climate ID": "3030840",
        "Station ID": 2195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -111.9,
        "Latitude": 503400000,
        "Longitude": -1115400000,
        "Elevation (m)": 758,
        "First Year": 1912,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1968,
        "MLY First Year": 1912,
        "MLY Last Year": 1968
      },
      {
        "Name": "BROOKS AHRC",
        "Province": "ALBERTA",
        "Climate ID": "3030856",
        "Station ID": 2196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 503300000,
        "Longitude": -1115100000,
        "Elevation (m)": 758,
        "First Year": 1915,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1988,
        "MLY First Year": 1915,
        "MLY Last Year": 1988
      },
      {
        "Name": "BROOKS NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3030862",
        "Station ID": 2197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -111.88,
        "Latitude": 503700000,
        "Longitude": -1115300000,
        "Elevation (m)": 759,
        "First Year": 1970,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2002,
        "MLY First Year": 1970,
        "MLY Last Year": 2002
      },
      {
        "Name": "BULL SPRINGS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3030879",
        "Station ID": 50921,
        "WMO ID": null,
        "TC ID": "PBU",
        "Latitude (Decimal Degrees)": 50.34,
        "Longitude (Decimal Degrees)": -110.48,
        "Latitude": 502022092,
        "Longitude": -1102902759,
        "Elevation (m)": 780,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BATTLE CREEK LO",
        "Province": "ALBERTA",
        "Climate ID": "3.03E+42",
        "Station ID": 2175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -109.98,
        "Latitude": 493900000,
        "Longitude": -1095900000,
        "Elevation (m)": 1310.6,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "BLACKIE",
        "Province": "ALBERTA",
        "Climate ID": "3030GAA",
        "Station ID": 2176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -113.6,
        "Latitude": 504400000,
        "Longitude": -1133600000,
        "Elevation (m)": 1066.8,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "BLACKIE NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3030GJ1",
        "Station ID": 2178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.77,
        "Longitude (Decimal Degrees)": -113.67,
        "Latitude": 504600000,
        "Longitude": -1134000000,
        "Elevation (m)": 1066.8,
        "First Year": 1972,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1977,
        "MLY First Year": 1972,
        "MLY Last Year": 1977
      },
      {
        "Name": "BLACKIE 2W",
        "Province": "ALBERTA",
        "Climate ID": "3030GJJ",
        "Station ID": 2177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -113.68,
        "Latitude": 503600000,
        "Longitude": -1134100000,
        "Elevation (m)": 1024.1,
        "First Year": 1972,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1982,
        "MLY First Year": 1972,
        "MLY Last Year": 1982
      },
      {
        "Name": "BLACKIE NW",
        "Province": "ALBERTA",
        "Climate ID": "3030PAJ",
        "Station ID": 2179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.77,
        "Longitude (Decimal Degrees)": -113.77,
        "Latitude": 504600000,
        "Longitude": -1134600000,
        "Elevation (m)": 1066.8,
        "First Year": 1977,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1977,
        "MLY First Year": 1977,
        "MLY Last Year": 1977
      },
      {
        "Name": "BROOKS",
        "Province": "ALBERTA",
        "Climate ID": "3030QLP",
        "Station ID": 2180,
        "WMO ID": 71457,
        "TC ID": "WBO",
        "Latitude (Decimal Degrees)": 50.56,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 503319070,
        "Longitude": -1115056030,
        "Elevation (m)": 747,
        "First Year": 1988,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1988,
        "DLY Last Year": 2022,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALDWELL",
        "Province": "ALBERTA",
        "Climate ID": "3031000",
        "Station ID": 2201,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -113.63,
        "Latitude": 491000000,
        "Longitude": -1133800000,
        "Elevation (m)": 1286,
        "First Year": 1910,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1990,
        "MLY First Year": 1910,
        "MLY Last Year": 1990
      },
      {
        "Name": "CALGARY ROSSCARROCK",
        "Province": "ALBERTA",
        "Climate ID": "3031075",
        "Station ID": 45847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.04,
        "Longitude (Decimal Degrees)": -114.15,
        "Latitude": 510217000,
        "Longitude": -1140906000,
        "Elevation (m)": 1148,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALGARY MARLBOROUGH",
        "Province": "ALBERTA",
        "Climate ID": "3031076",
        "Station ID": 45867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.06,
        "Longitude (Decimal Degrees)": -113.99,
        "Latitude": 510345000,
        "Longitude": -1135915000,
        "Elevation (m)": 1088,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALGARY NOSE HILL",
        "Province": "ALBERTA",
        "Climate ID": "3031077",
        "Station ID": 45887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.09,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 510530000,
        "Longitude": -1140542000,
        "Elevation (m)": 1138,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALGARY MIDNAPORE",
        "Province": "ALBERTA",
        "Climate ID": "3031078",
        "Station ID": 45907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.91,
        "Longitude (Decimal Degrees)": -114.06,
        "Latitude": 505424000,
        "Longitude": -1140336000,
        "Elevation (m)": 1045,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALGARY BEARSPAW",
        "Province": "ALBERTA",
        "Climate ID": "3031083",
        "Station ID": 2202,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -114.33,
        "Latitude": 510600000,
        "Longitude": -1142000000,
        "Elevation (m)": 1188.7,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "CALGARY BELLEVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3031084",
        "Station ID": 2203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -114.15,
        "Latitude": 510600000,
        "Longitude": -1140900000,
        "Elevation (m)": 1112.5,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "CALGARY GLENMORE DAM",
        "Province": "ALBERTA",
        "Climate ID": "3031090",
        "Station ID": 2204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 510100000,
        "Longitude": -1140600000,
        "Elevation (m)": 1066.8,
        "First Year": 1956,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1979,
        "MLY First Year": 1956,
        "MLY Last Year": 1979
      },
      {
        "Name": "CALGARY INTL A",
        "Province": "ALBERTA",
        "Climate ID": "3031092",
        "Station ID": 50430,
        "WMO ID": 71877,
        "TC ID": "YYC",
        "Latitude (Decimal Degrees)": 51.12,
        "Longitude (Decimal Degrees)": -114.01,
        "Latitude": 510721000,
        "Longitude": -1140048000,
        "Elevation (m)": 1099.1,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALGARY INT'L A",
        "Province": "ALBERTA",
        "Climate ID": "3031093",
        "Station ID": 2205,
        "WMO ID": 71877,
        "TC ID": "YYC",
        "Latitude (Decimal Degrees)": 51.11,
        "Longitude (Decimal Degrees)": -114.02,
        "Latitude": 510650000,
        "Longitude": -1140113000,
        "Elevation (m)": 1084.1,
        "First Year": 1881,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1881,
        "DLY Last Year": 2012,
        "MLY First Year": 1881,
        "MLY Last Year": 2012
      },
      {
        "Name": "CALGARY INT'L CS",
        "Province": "ALBERTA",
        "Climate ID": "3031094",
        "Station ID": 27211,
        "WMO ID": 71393,
        "TC ID": "PCI",
        "Latitude (Decimal Degrees)": 51.11,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 510634010,
        "Longitude": -1140001070,
        "Elevation (m)": 1081.9,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALGARY POPLAR GARDENS",
        "Province": "ALBERTA",
        "Climate ID": "3031102",
        "Station ID": 2206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -114.18,
        "Latitude": 510200000,
        "Longitude": -1141100000,
        "Elevation (m)": 1207.2,
        "First Year": 1987,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2004,
        "MLY First Year": 1987,
        "MLY Last Year": 2004
      },
      {
        "Name": "CALGARY SIMONS VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3031105",
        "Station ID": 2207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.22,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 511300000,
        "Longitude": -1141200000,
        "Elevation (m)": 1112.5,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "CALGARY SPRINGBANK",
        "Province": "ALBERTA",
        "Climate ID": "3031107",
        "Station ID": 2208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -114.28,
        "Latitude": 510400000,
        "Longitude": -1141700000,
        "Elevation (m)": 1188.7,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "CALGARY SPRINGBANK",
        "Province": "ALBERTA",
        "Climate ID": "3031108",
        "Station ID": 2209,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -114.43,
        "Latitude": 510600000,
        "Longitude": -1142600000,
        "Elevation (m)": 1249.7,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "CALGARY SPRINGBANK A",
        "Province": "ALBERTA",
        "Climate ID": "3031109",
        "Station ID": 52200,
        "WMO ID": null,
        "TC ID": "YBW",
        "Latitude (Decimal Degrees)": 51.11,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 510619000,
        "Longitude": -1142217000,
        "Elevation (m)": 1200.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAMERON COULEE",
        "Province": "ALBERTA",
        "Climate ID": "3031151",
        "Station ID": 2210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 503900000,
        "Longitude": -1140200000,
        "Elevation (m)": 1122,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "CARDSTON",
        "Province": "ALBERTA",
        "Climate ID": "3031318",
        "Station ID": 8763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 491200000,
        "Longitude": -1131800000,
        "Elevation (m)": null,
        "First Year": 1996,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1997,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARDSTON",
        "Province": "ALBERTA",
        "Climate ID": "3031320",
        "Station ID": 2211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -113.24,
        "Latitude": 490754010,
        "Longitude": -1131431030,
        "Elevation (m)": 1193,
        "First Year": 1918,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 2005,
        "MLY First Year": 1918,
        "MLY Last Year": 2005
      },
      {
        "Name": "CARDSTON",
        "Province": "ALBERTA",
        "Climate ID": "3031322",
        "Station ID": 26971,
        "WMO ID": 71153,
        "TC ID": "WFJ",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -113.29,
        "Latitude": 491202070,
        "Longitude": -1131709020,
        "Elevation (m)": 1136,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARDSTON EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3031328",
        "Station ID": 2212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 491200000,
        "Longitude": -1131800000,
        "Elevation (m)": 1166.2,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "CARMANGAY",
        "Province": "ALBERTA",
        "Climate ID": "3031338",
        "Station ID": 2213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -112.78,
        "Latitude": 500900000,
        "Longitude": -1124700000,
        "Elevation (m)": 944.9,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "CARMANGAY EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3031340",
        "Station ID": 2214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -113.1,
        "Latitude": 500800000,
        "Longitude": -1130600000,
        "Elevation (m)": 938.8,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "CARMANGAY EXP ST 2",
        "Province": "ALBERTA",
        "Climate ID": "3031341",
        "Station ID": 2215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -113.02,
        "Latitude": 501300000,
        "Longitude": -1130100000,
        "Elevation (m)": 938.8,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "CARMANGAY EXP ST 3",
        "Province": "ALBERTA",
        "Climate ID": "3031342",
        "Station ID": 2216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -113.33,
        "Latitude": 500700000,
        "Longitude": -1132000000,
        "Elevation (m)": 938.8,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "CARMANGAY PLAINFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3031345",
        "Station ID": 2217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -112.88,
        "Latitude": 501300000,
        "Longitude": -1125300000,
        "Elevation (m)": 914.4,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "CARMANGAY VILLAGE",
        "Province": "ALBERTA",
        "Climate ID": "3031348",
        "Station ID": 2218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -113.11,
        "Latitude": 500728000,
        "Longitude": -1130642000,
        "Elevation (m)": 939.4,
        "First Year": 1965,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2008,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARWAY",
        "Province": "ALBERTA",
        "Climate ID": "3031400",
        "Station ID": 2219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -113.38,
        "Latitude": 485959010,
        "Longitude": -1132234000,
        "Elevation (m)": 1354,
        "First Year": 1914,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2011,
        "MLY First Year": 1914,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARWAY",
        "Province": "ALBERTA",
        "Climate ID": "3031402",
        "Station ID": 49968,
        "WMO ID": 71488,
        "TC ID": "PCY",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -113.38,
        "Latitude": 490002000,
        "Longitude": -1132234000,
        "Elevation (m)": 1354,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAYLEY",
        "Province": "ALBERTA",
        "Climate ID": "3031450",
        "Station ID": 2220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 502900000,
        "Longitude": -1135200000,
        "Elevation (m)": 1071.4,
        "First Year": 1969,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1972
      },
      {
        "Name": "CHAIN LAKES RS",
        "Province": "ALBERTA",
        "Climate ID": "3031476",
        "Station ID": 2221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 501200000,
        "Longitude": -1141200000,
        "Elevation (m)": 1371.6,
        "First Year": 1972,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "CHAMPION AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3031480",
        "Station ID": 32254,
        "WMO ID": 71217,
        "TC ID": "XCP",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 501655001,
        "Longitude": -1132101000,
        "Elevation (m)": 1006,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1992,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHESTERMERE LAKE SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3031524",
        "Station ID": 2222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -113.78,
        "Latitude": 510200000,
        "Longitude": -1134700000,
        "Elevation (m)": 1021.1,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "CLARESHOLM",
        "Province": "ALBERTA",
        "Climate ID": "3031640",
        "Station ID": 2224,
        "WMO ID": 71234,
        "TC ID": "WDK",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -113.64,
        "Latitude": 500013070,
        "Longitude": -1133819090,
        "Elevation (m)": 1009,
        "First Year": 1951,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1951,
        "DLY Last Year": 2022,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLARESHOLM EXP FARM",
        "Province": "ALBERTA",
        "Climate ID": "3031652",
        "Station ID": 2225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -113.58,
        "Latitude": 500200000,
        "Longitude": -1133500000,
        "Elevation (m)": 1033.3,
        "First Year": 1914,
        "Last Year": 1931,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1931,
        "MLY First Year": 1914,
        "MLY Last Year": 1931
      },
      {
        "Name": "CLARESHOLM EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3031656",
        "Station ID": 2226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -113.63,
        "Latitude": 500100000,
        "Longitude": -1133800000,
        "Elevation (m)": 1033.3,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1954,
        "MLY Last Year": 1961
      },
      {
        "Name": "CLARESHOLM WATERWORKS",
        "Province": "ALBERTA",
        "Climate ID": "3031658",
        "Station ID": 2227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -113.71,
        "Latitude": 500108060,
        "Longitude": -1134238010,
        "Elevation (m)": 1008,
        "First Year": 1959,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2008,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "COALDALE",
        "Province": "ALBERTA",
        "Climate ID": "3031668",
        "Station ID": 2228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -112.63,
        "Latitude": 494400000,
        "Longitude": -1123800000,
        "Elevation (m)": 862,
        "First Year": 1957,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1966,
        "MLY First Year": 1957,
        "MLY Last Year": 1966
      },
      {
        "Name": "COALDALE EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3031670",
        "Station ID": 2229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -112.63,
        "Latitude": 494300000,
        "Longitude": -1123800000,
        "Elevation (m)": 862,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "COCHRANE",
        "Province": "ALBERTA",
        "Climate ID": "3031676",
        "Station ID": 2230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -114.43,
        "Latitude": 511149010,
        "Longitude": -1142604090,
        "Elevation (m)": 1332,
        "First Year": 1961,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2004,
        "MLY First Year": 1961,
        "MLY Last Year": 2004
      },
      {
        "Name": "COCHRANE BIGHILL CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3031678",
        "Station ID": 42443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.24,
        "Longitude (Decimal Degrees)": -114.42,
        "Latitude": 511433070,
        "Longitude": -1142505080,
        "Elevation (m)": 1329,
        "First Year": 2003,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2009,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLARESHOLM LAING",
        "Province": "ALBERTA",
        "Climate ID": "30316NF",
        "Station ID": 2223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -113.37,
        "Latitude": 500300000,
        "Longitude": -1132200000,
        "Elevation (m)": 975.4,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "CONNELLY CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3031805",
        "Station ID": 2231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 493658030,
        "Longitude": -1141254080,
        "Elevation (m)": 1249,
        "First Year": 1978,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2011,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "COP UPPER",
        "Province": "ALBERTA",
        "Climate ID": "3031875",
        "Station ID": 9010,
        "WMO ID": 71235,
        "TC ID": "WDU",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 510434020,
        "Longitude": -1141320090,
        "Elevation (m)": 1235,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "COWLEY",
        "Province": "ALBERTA",
        "Climate ID": "3031918",
        "Station ID": 2232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -114.12,
        "Latitude": 494100000,
        "Longitude": -1140700000,
        "Elevation (m)": 1188.7,
        "First Year": 1961,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1975,
        "MLY First Year": 1961,
        "MLY Last Year": 1975
      },
      {
        "Name": "COWLEY A",
        "Province": "ALBERTA",
        "Climate ID": "3031920",
        "Station ID": 2233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -114.08,
        "Latitude": 493800000,
        "Longitude": -1140500000,
        "Elevation (m)": 1182,
        "First Year": 1939,
        "Last Year": 1960,
        "HLY First Year": 1953,
        "HLY Last Year": 1960,
        "DLY First Year": 1939,
        "DLY Last Year": 1960,
        "MLY First Year": 1939,
        "MLY Last Year": 1960
      },
      {
        "Name": "COWLEY CREEBANK RANCH",
        "Province": "ALBERTA",
        "Climate ID": "3031924",
        "Station ID": 2234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -114.08,
        "Latitude": 493400000,
        "Longitude": -1140500000,
        "Elevation (m)": 1171,
        "First Year": 1912,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1941,
        "MLY First Year": 1912,
        "MLY Last Year": 1941
      },
      {
        "Name": "COWLEY OLIN CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3031926",
        "Station ID": 2235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -114.07,
        "Latitude": 494200000,
        "Longitude": -1140400000,
        "Elevation (m)": 1234.4,
        "First Year": 1961,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2001,
        "MLY First Year": 1961,
        "MLY Last Year": 2001
      },
      {
        "Name": "COWLEY TANNER",
        "Province": "ALBERTA",
        "Climate ID": "3031927",
        "Station ID": 31089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -114.04,
        "Latitude": 493850070,
        "Longitude": -1140224080,
        "Elevation (m)": 1259,
        "First Year": 2001,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2008,
        "MLY First Year": 2001,
        "MLY Last Year": 2005
      },
      {
        "Name": "CLARESHOLM MEADOW CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3031F5F",
        "Station ID": 2200,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.94,
        "Longitude (Decimal Degrees)": -113.74,
        "Latitude": 495615080,
        "Longitude": -1134415070,
        "Elevation (m)": 1035,
        "First Year": 1912,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 2005,
        "MLY First Year": 1912,
        "MLY Last Year": 2005
      },
      {
        "Name": "CLARESHOLM NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3031FN6",
        "Station ID": 2198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -113.58,
        "Latitude": 500200000,
        "Longitude": -1133500000,
        "Elevation (m)": 1036.3,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "COCHRANE CATHCART",
        "Province": "ALBERTA",
        "Climate ID": "3031FPF",
        "Station ID": 2199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 511200000,
        "Longitude": -1142200000,
        "Elevation (m)": 1310.6,
        "First Year": 1990,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1995,
        "MLY First Year": 1990,
        "MLY Last Year": 1995
      },
      {
        "Name": "DE WINTON",
        "Province": "ALBERTA",
        "Climate ID": "3032074",
        "Station ID": 2236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 504800000,
        "Longitude": -1140200000,
        "Elevation (m)": 1097.3,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "ENCHANT",
        "Province": "ALBERTA",
        "Climate ID": "3032420",
        "Station ID": 2237,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.32,
        "Longitude (Decimal Degrees)": -112.38,
        "Latitude": 501900000,
        "Longitude": -1122300000,
        "Elevation (m)": 808.9,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "ENCHANT AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3032422",
        "Station ID": 42724,
        "WMO ID": 71518,
        "TC ID": "PEH",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -112.43,
        "Latitude": 501100000,
        "Longitude": -1122600000,
        "Elevation (m)": 801,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "ETZIKOM AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3032450",
        "Station ID": 46732,
        "WMO ID": 71409,
        "TC ID": "PET",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -111.05,
        "Latitude": 493310002,
        "Longitude": -1110315008,
        "Elevation (m)": 845,
        "First Year": 2007,
        "Last Year": 2021,
        "HLY First Year": 2008,
        "HLY Last Year": 2021,
        "DLY First Year": 2007,
        "DLY Last Year": 2021,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "FINCASTLE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3032550",
        "Station ID": 42727,
        "WMO ID": 71535,
        "TC ID": "PFI",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -112.03,
        "Latitude": 494800000,
        "Longitude": -1120200000,
        "Elevation (m)": 833,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "FIVE MILE CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3032624",
        "Station ID": 2239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -113.84,
        "Latitude": 494609050,
        "Longitude": -1135017090,
        "Elevation (m)": 1295,
        "First Year": 1985,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2008,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "FOREMOST",
        "Province": "ALBERTA",
        "Climate ID": "3032640",
        "Station ID": 2240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 492837040,
        "Longitude": -1112647060,
        "Elevation (m)": 892,
        "First Year": 1915,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 2005,
        "MLY First Year": 1915,
        "MLY Last Year": 2005
      },
      {
        "Name": "FOREMOST AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3032642",
        "Station ID": 32257,
        "WMO ID": 71225,
        "TC ID": "XFM",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -111.49,
        "Latitude": 492900001,
        "Longitude": -1112910000,
        "Elevation (m)": 881,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "FOREMOST EAST",
        "Province": "ALBERTA",
        "Climate ID": "3032645",
        "Station ID": 2241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -111.28,
        "Latitude": 492700000,
        "Longitude": -1111700000,
        "Elevation (m)": 914.4,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "FOREMOST EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3032650",
        "Station ID": 2242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 492900000,
        "Longitude": -1112700000,
        "Elevation (m)": 890,
        "First Year": 1954,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1960,
        "MLY First Year": 1954,
        "MLY Last Year": 1960
      },
      {
        "Name": "FORT MACLEOD",
        "Province": "ALBERTA",
        "Climate ID": "3032680",
        "Station ID": 2243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -113.4,
        "Latitude": 494300000,
        "Longitude": -1132400000,
        "Elevation (m)": 949.8,
        "First Year": 1876,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1876,
        "DLY Last Year": 1988,
        "MLY First Year": 1877,
        "MLY Last Year": 1988
      },
      {
        "Name": "FORT MACLEOD AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3032683",
        "Station ID": 46734,
        "WMO ID": 71789,
        "TC ID": "PFM",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -113.38,
        "Latitude": 494709000,
        "Longitude": -1132246009,
        "Elevation (m)": 978,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT MACLEOD EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3032685",
        "Station ID": 2244,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -113.4,
        "Latitude": 494300000,
        "Longitude": -1132400000,
        "Elevation (m)": 953.4,
        "First Year": 1955,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1962,
        "MLY First Year": 1955,
        "MLY Last Year": 1962
      },
      {
        "Name": "FORT MACLEOD EXP ST 2",
        "Province": "ALBERTA",
        "Climate ID": "3032686",
        "Station ID": 2245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -113.48,
        "Latitude": 494600000,
        "Longitude": -1132900000,
        "Elevation (m)": 953.4,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "FORT MACLEOD STAND OFF",
        "Province": "ALBERTA",
        "Climate ID": "3032690",
        "Station ID": 2246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -113.33,
        "Latitude": 493200000,
        "Longitude": -1132000000,
        "Elevation (m)": 990.6,
        "First Year": 1961,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1975,
        "MLY First Year": 1961,
        "MLY Last Year": 1975
      },
      {
        "Name": "FORT MACLEOD NORTH",
        "Province": "ALBERTA",
        "Climate ID": "30326QF",
        "Station ID": 2238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 495200000,
        "Longitude": -1131700000,
        "Elevation (m)": 953,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "GLEICHEN",
        "Province": "ALBERTA",
        "Climate ID": "3032800",
        "Station ID": 2247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -113.05,
        "Latitude": 505300000,
        "Longitude": -1130300000,
        "Elevation (m)": 905.3,
        "First Year": 1903,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 2006,
        "MLY First Year": 1903,
        "MLY Last Year": 2006
      },
      {
        "Name": "GLEICHEN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3032801",
        "Station ID": 47073,
        "WMO ID": 71498,
        "TC ID": "PGI",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -112.94,
        "Latitude": 505604004,
        "Longitude": -1125626009,
        "Elevation (m)": 907,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLENWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3032818",
        "Station ID": 45748,
        "WMO ID": 71885,
        "TC ID": "PCR",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 492004000,
        "Longitude": -1133128000,
        "Elevation (m)": 1074,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "GLENWOODVILLE EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3032820",
        "Station ID": 2248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 492200000,
        "Longitude": -1133100000,
        "Elevation (m)": 1066.8,
        "First Year": 1926,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1963,
        "MLY First Year": 1926,
        "MLY Last Year": 1963
      },
      {
        "Name": "GRANUM JUMBO VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3032924",
        "Station ID": 2249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 494700000,
        "Longitude": -1131800000,
        "Elevation (m)": 944.9,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "GRASSY LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3032927",
        "Station ID": 45647,
        "WMO ID": 71788,
        "TC ID": "PGL",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -111.73,
        "Latitude": 495225000,
        "Longitude": -1114358000,
        "Elevation (m)": 783,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRASSY LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3032928",
        "Station ID": 2250,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -111.3,
        "Latitude": 495000000,
        "Longitude": -1111800000,
        "Elevation (m)": 808.3,
        "First Year": 1906,
        "Last Year": 1916,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1916,
        "MLY First Year": 1906,
        "MLY Last Year": 1916
      },
      {
        "Name": "GRASSY LAKE RIVERS DEV",
        "Province": "ALBERTA",
        "Climate ID": "3032930",
        "Station ID": 2251,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -111.7,
        "Latitude": 494300000,
        "Longitude": -1114200000,
        "Elevation (m)": 830,
        "First Year": 1958,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1966,
        "MLY First Year": 1958,
        "MLY Last Year": 1966
      },
      {
        "Name": "EAGLE LAKE PARK",
        "Province": "ALBERTA",
        "Climate ID": "3032AM9",
        "Station ID": 6856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.99,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 505920000,
        "Longitude": -1131750000,
        "Elevation (m)": 923.6,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "HAYS",
        "Province": "ALBERTA",
        "Climate ID": "3033080",
        "Station ID": 2252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -111.8,
        "Latitude": 500600000,
        "Longitude": -1114800000,
        "Elevation (m)": 775.7,
        "First Year": 1952,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1970,
        "MLY First Year": 1952,
        "MLY Last Year": 1970
      },
      {
        "Name": "HERRONTON",
        "Province": "ALBERTA",
        "Climate ID": "3033137",
        "Station ID": 2253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -113.45,
        "Latitude": 503700000,
        "Longitude": -1132700000,
        "Elevation (m)": 1000.4,
        "First Year": 1965,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1974,
        "MLY First Year": 1965,
        "MLY Last Year": 1974
      },
      {
        "Name": "HERRONTON EAST",
        "Province": "ALBERTA",
        "Climate ID": "3033138",
        "Station ID": 2254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 503559080,
        "Longitude": -1132052030,
        "Elevation (m)": 1056,
        "First Year": 1965,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2005,
        "MLY First Year": 1965,
        "MLY Last Year": 2005
      },
      {
        "Name": "HIGH RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3033240",
        "Station ID": 2255,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -114.17,
        "Latitude": 502900000,
        "Longitude": -1141000000,
        "Elevation (m)": 1219.2,
        "First Year": 1902,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 2006,
        "MLY First Year": 1902,
        "MLY Last Year": 2006
      },
      {
        "Name": "HIGH RIVER RADIO",
        "Province": "ALBERTA",
        "Climate ID": "3033242",
        "Station ID": 2256,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.58,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 503500000,
        "Longitude": -1135400000,
        "Elevation (m)": 1039.1,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "HIGH RIVER TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3033243",
        "Station ID": 2257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 503400000,
        "Longitude": -1135200000,
        "Elevation (m)": 1036.3,
        "First Year": 1970,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1989,
        "MLY First Year": 1970,
        "MLY Last Year": 1989
      },
      {
        "Name": "HILL SPRING",
        "Province": "ALBERTA",
        "Climate ID": "3033281",
        "Station ID": 2258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.29,
        "Longitude (Decimal Degrees)": -113.63,
        "Latitude": 491729070,
        "Longitude": -1133736060,
        "Elevation (m)": 1183,
        "First Year": 1990,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2011,
        "MLY First Year": 1990,
        "MLY Last Year": 1995
      },
      {
        "Name": "IRON SPRINGS AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3033498",
        "Station ID": 42728,
        "WMO ID": 71528,
        "TC ID": "PIR",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -112.73,
        "Latitude": 495400000,
        "Longitude": -1124400000,
        "Elevation (m)": 858,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "IRON SPRINGS EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3033500",
        "Station ID": 2259,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -112.6,
        "Latitude": 495600000,
        "Longitude": -1123600000,
        "Elevation (m)": 837.3,
        "First Year": 1955,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2005,
        "MLY First Year": 1955,
        "MLY Last Year": 2005
      },
      {
        "Name": "PRIDDIS OBSERVATORY",
        "Province": "ALBERTA",
        "Climate ID": "3033505",
        "Station ID": 48468,
        "WMO ID": null,
        "TC ID": "PRD",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -114.29,
        "Latitude": 505209000,
        "Longitude": -1141730000,
        "Elevation (m)": 1267,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IRVINE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3033550",
        "Station ID": 46807,
        "WMO ID": 71795,
        "TC ID": "PIA",
        "Latitude (Decimal Degrees)": 49.99,
        "Longitude (Decimal Degrees)": -110.26,
        "Latitude": 495921008,
        "Longitude": -1101541007,
        "Elevation (m)": 795,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "JUMPING POUND",
        "Province": "ALBERTA",
        "Climate ID": "3033572",
        "Station ID": 2260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -114.55,
        "Latitude": 510500000,
        "Longitude": -1143300000,
        "Elevation (m)": null,
        "First Year": 1905,
        "Last Year": 1916,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1916,
        "MLY First Year": 1905,
        "MLY Last Year": 1916
      },
      {
        "Name": "KEOMA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3033620",
        "Station ID": 50940,
        "WMO ID": null,
        "TC ID": "PKO",
        "Latitude (Decimal Degrees)": 51.19,
        "Longitude (Decimal Degrees)": -113.63,
        "Latitude": 511126016,
        "Longitude": -1133750016,
        "Elevation (m)": 945,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KYISKAP CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3033675",
        "Station ID": 2261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -113.72,
        "Latitude": 495400000,
        "Longitude": -1134300000,
        "Elevation (m)": 1066.8,
        "First Year": 1972,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1978,
        "MLY First Year": 1972,
        "MLY Last Year": 1978
      },
      {
        "Name": "LATHOM AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3033770",
        "Station ID": 51499,
        "WMO ID": null,
        "TC ID": "PZL",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -112.3,
        "Latitude": 503601044,
        "Longitude": -1121756052,
        "Elevation (m)": 810,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LETHBRIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3033875",
        "Station ID": 49268,
        "WMO ID": 71267,
        "TC ID": "YQL",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 493749000,
        "Longitude": -1124756000,
        "Elevation (m)": 929,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LETHBRIDGE AGTECH AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3033877",
        "Station ID": 51377,
        "WMO ID": null,
        "TC ID": "PXZ",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -112.76,
        "Latitude": 493850000,
        "Longitude": -1124545000,
        "Elevation (m)": 918,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LETHBRIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3033879",
        "Station ID": 2262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -112.85,
        "Latitude": 494200000,
        "Longitude": -1125100000,
        "Elevation (m)": 902.5,
        "First Year": 1886,
        "Last Year": 1921,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1921,
        "MLY First Year": 1886,
        "MLY Last Year": 1921
      },
      {
        "Name": "LETHBRIDGE A",
        "Province": "ALBERTA",
        "Climate ID": "3033880",
        "Station ID": 2263,
        "WMO ID": null,
        "TC ID": "YQL",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 493749000,
        "Longitude": -1124759000,
        "Elevation (m)": 928.7,
        "First Year": 1938,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1938,
        "DLY Last Year": 2008,
        "MLY First Year": 1938,
        "MLY Last Year": 2007
      },
      {
        "Name": "LETHBRIDGE A",
        "Province": "ALBERTA",
        "Climate ID": "3033881",
        "Station ID": 50128,
        "WMO ID": null,
        "TC ID": "YQL",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 493749000,
        "Longitude": -1124759000,
        "Elevation (m)": 929,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LETHBRIDGE A CR10",
        "Province": "ALBERTA",
        "Climate ID": "3033882",
        "Station ID": 27217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 493800000,
        "Longitude": -1124800000,
        "Elevation (m)": 929,
        "First Year": 1996,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2007,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "LETHBRIDGE AWOS A",
        "Province": "ALBERTA",
        "Climate ID": "3033884",
        "Station ID": 31447,
        "WMO ID": null,
        "TC ID": "YQL",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 493800000,
        "Longitude": -1124800000,
        "Elevation (m)": 929.03,
        "First Year": 2006,
        "Last Year": 2012,
        "HLY First Year": 2006,
        "HLY Last Year": 2012,
        "DLY First Year": 2006,
        "DLY Last Year": 2011,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LETHBRIDGE BEGAMY",
        "Province": "ALBERTA",
        "Climate ID": "3033885",
        "Station ID": 2264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -112.62,
        "Latitude": 494400000,
        "Longitude": -1123700000,
        "Elevation (m)": 883.9,
        "First Year": 1966,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1971,
        "MLY First Year": 1966,
        "MLY Last Year": 1971
      },
      {
        "Name": "LETHBRIDGE CDA",
        "Province": "ALBERTA",
        "Climate ID": "3033890",
        "Station ID": 2265,
        "WMO ID": 71243,
        "TC ID": "WQL",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -112.77,
        "Latitude": 494142000,
        "Longitude": -1124603000,
        "Elevation (m)": 910,
        "First Year": 1908,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1908,
        "DLY Last Year": 2022,
        "MLY First Year": 1908,
        "MLY Last Year": 2007
      },
      {
        "Name": "LETHBRIDGE CDA 2",
        "Province": "ALBERTA",
        "Climate ID": "3033892",
        "Station ID": 46867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -112.78,
        "Latitude": 494200000,
        "Longitude": -1124700000,
        "Elevation (m)": 921.1,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "LETHBRIDGE COUNTY",
        "Province": "ALBERTA",
        "Climate ID": "3033895",
        "Station ID": 2266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -112.48,
        "Latitude": 494300000,
        "Longitude": -1122900000,
        "Elevation (m)": 868.7,
        "First Year": 1966,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1971,
        "MLY First Year": 1966,
        "MLY Last Year": 1971
      },
      {
        "Name": "LETHBRIDGE DEMO FARM AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3033897",
        "Station ID": 42726,
        "WMO ID": 71509,
        "TC ID": "PQL",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -112.75,
        "Latitude": 494100000,
        "Longitude": -1124500000,
        "Elevation (m)": 903,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "LETHBRIDGE CCOC",
        "Province": "ALBERTA",
        "Climate ID": "3033898",
        "Station ID": 45947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -112.78,
        "Latitude": 494343000,
        "Longitude": -1124657000,
        "Elevation (m)": 913,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "LLOYD LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3033956",
        "Station ID": 2267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -114.13,
        "Latitude": 505200000,
        "Longitude": -1140800000,
        "Elevation (m)": 1127.8,
        "First Year": 1981,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1992,
        "MLY First Year": 1981,
        "MLY Last Year": 1992
      },
      {
        "Name": "LOMOND",
        "Province": "ALBERTA",
        "Climate ID": "3033979",
        "Station ID": 2268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -112.38,
        "Latitude": 502400000,
        "Longitude": -1122300000,
        "Elevation (m)": 807.7,
        "First Year": 1971,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1971,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "LOMOND EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3033980",
        "Station ID": 2269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.35,
        "Longitude (Decimal Degrees)": -112.68,
        "Latitude": 502100000,
        "Longitude": -1124100000,
        "Elevation (m)": 871.1,
        "First Year": 1954,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1959,
        "MLY First Year": 1954,
        "MLY Last Year": 1959
      },
      {
        "Name": "MAGRATH",
        "Province": "ALBERTA",
        "Climate ID": "3034160",
        "Station ID": 2270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -112.87,
        "Latitude": 492500000,
        "Longitude": -1125200000,
        "Elevation (m)": 978.4,
        "First Year": 1905,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1968,
        "MLY First Year": 1905,
        "MLY Last Year": 1968
      },
      {
        "Name": "MAGRATH 2",
        "Province": "ALBERTA",
        "Climate ID": "3034162",
        "Station ID": 2271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -112.93,
        "Latitude": 492700000,
        "Longitude": -1125600000,
        "Elevation (m)": 990.6,
        "First Year": 1958,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1966,
        "MLY First Year": 1958,
        "MLY Last Year": 1966
      },
      {
        "Name": "MAJORVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3034166",
        "Station ID": 2272,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -112.65,
        "Latitude": 504200000,
        "Longitude": -1123900000,
        "Elevation (m)": 914.4,
        "First Year": 1962,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1974,
        "MLY First Year": 1962,
        "MLY Last Year": 1974
      },
      {
        "Name": "MEDICINE HAT",
        "Province": "ALBERTA",
        "Climate ID": "3034479",
        "Station ID": 48949,
        "WMO ID": 71247,
        "TC ID": "YXH",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -110.72,
        "Latitude": 500108000,
        "Longitude": -1104315000,
        "Elevation (m)": 716.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MEDICINE HAT A",
        "Province": "ALBERTA",
        "Climate ID": "3034480",
        "Station ID": 2273,
        "WMO ID": null,
        "TC ID": "YXH",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -110.72,
        "Latitude": 500108000,
        "Longitude": -1104315000,
        "Elevation (m)": 716.9,
        "First Year": 1883,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1883,
        "DLY Last Year": 2008,
        "MLY First Year": 1883,
        "MLY Last Year": 2007
      },
      {
        "Name": "MEDICINE HAT A",
        "Province": "ALBERTA",
        "Climate ID": "3034481",
        "Station ID": 50129,
        "WMO ID": null,
        "TC ID": "YXH",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -110.72,
        "Latitude": 500108000,
        "Longitude": -1104315000,
        "Elevation (m)": 716.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MEDICINE HAT AWOS",
        "Province": "ALBERTA",
        "Climate ID": "3034483",
        "Station ID": 44746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -110.72,
        "Latitude": 500108100,
        "Longitude": -1104315000,
        "Elevation (m)": 716.9,
        "First Year": 2006,
        "Last Year": 2010,
        "HLY First Year": 2006,
        "HLY Last Year": 2010,
        "DLY First Year": 2008,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MEDICINE HAT RCS",
        "Province": "ALBERTA",
        "Climate ID": "3034485",
        "Station ID": 30347,
        "WMO ID": 71026,
        "TC ID": "XMW",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -110.72,
        "Latitude": 500130400,
        "Longitude": -1104302100,
        "Elevation (m)": 715,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "MEDICINE HAT CCOC",
        "Province": "ALBERTA",
        "Climate ID": "3034488",
        "Station ID": 45948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.04,
        "Longitude (Decimal Degrees)": -110.67,
        "Latitude": 500238000,
        "Longitude": -1104011000,
        "Elevation (m)": 661,
        "First Year": 2004,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "MEDICINE LODGE LO",
        "Province": "ALBERTA",
        "Climate ID": "3034489",
        "Station ID": 2274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -110.32,
        "Latitude": 493800000,
        "Longitude": -1101900000,
        "Elevation (m)": 1402.1,
        "First Year": 1974,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2007,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILLARVILLE",
        "Province": "ALBERTA",
        "Climate ID": "3034552",
        "Station ID": 2275,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 504300000,
        "Longitude": -1142200000,
        "Elevation (m)": 1249.7,
        "First Year": 1971,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1993,
        "MLY First Year": 1971,
        "MLY Last Year": 1993
      },
      {
        "Name": "MOSSLEIGH AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3034577",
        "Station ID": 46810,
        "WMO ID": 71057,
        "TC ID": "PML",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 504021005,
        "Longitude": -1132055005,
        "Elevation (m)": 965,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "MONARCH",
        "Province": "ALBERTA",
        "Climate ID": "3034596",
        "Station ID": 2276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.81,
        "Longitude (Decimal Degrees)": -113.16,
        "Latitude": 494852020,
        "Longitude": -1130939080,
        "Elevation (m)": 947,
        "First Year": 1980,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2012,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOSSLEIGH",
        "Province": "ALBERTA",
        "Climate ID": "3034675",
        "Station ID": 2277,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.66,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 503934010,
        "Longitude": -1131653010,
        "Elevation (m)": 1066.8,
        "First Year": 1965,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2004,
        "MLY First Year": 1965,
        "MLY Last Year": 2004
      },
      {
        "Name": "MOSSLEIGH BURNAY",
        "Province": "ALBERTA",
        "Climate ID": "3034676",
        "Station ID": 2278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -113.3,
        "Latitude": 504302070,
        "Longitude": -1131807010,
        "Elevation (m)": 944.9,
        "First Year": 1966,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2003,
        "MLY First Year": 1967,
        "MLY Last Year": 2003
      },
      {
        "Name": "MOUNTAIN VIEW",
        "Province": "ALBERTA",
        "Climate ID": "3034720",
        "Station ID": 2279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -113.63,
        "Latitude": 490737040,
        "Longitude": -1133748060,
        "Elevation (m)": 1339,
        "First Year": 1912,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 2006,
        "MLY First Year": 1912,
        "MLY Last Year": 2006
      },
      {
        "Name": "MOUNTAIN VIEW VILLAGE",
        "Province": "ALBERTA",
        "Climate ID": "3034725",
        "Station ID": 27199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -113.59,
        "Latitude": 490755000,
        "Longitude": -1133538010,
        "Elevation (m)": 1308,
        "First Year": 1996,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2015,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "NIER AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3034795",
        "Station ID": 43580,
        "WMO ID": 71495,
        "TC ID": "NNR",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -114.1,
        "Latitude": 512210000,
        "Longitude": -1140557000,
        "Elevation (m)": 1145,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "NOBLEFORD",
        "Province": "ALBERTA",
        "Climate ID": "3034818",
        "Station ID": 2280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -113.05,
        "Latitude": 495200000,
        "Longitude": -1130300000,
        "Elevation (m)": 975.4,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "NOBLEFORD EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3034820",
        "Station ID": 2281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -113.07,
        "Latitude": 495300000,
        "Longitude": -1130400000,
        "Elevation (m)": 983.3,
        "First Year": 1954,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1963,
        "MLY First Year": 1954,
        "MLY Last Year": 1963
      },
      {
        "Name": "OKOTOKS",
        "Province": "ALBERTA",
        "Climate ID": "3034915",
        "Station ID": 2282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -113.98,
        "Latitude": 504300000,
        "Longitude": -1135900000,
        "Elevation (m)": 1051,
        "First Year": 1906,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1932,
        "MLY First Year": 1906,
        "MLY Last Year": 1932
      },
      {
        "Name": "OKOTOKS EAST",
        "Province": "ALBERTA",
        "Climate ID": "3034916",
        "Station ID": 2283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 504400000,
        "Longitude": -1135500000,
        "Elevation (m)": 1066.8,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "OWENDALE",
        "Province": "ALBERTA",
        "Climate ID": "3034950",
        "Station ID": 2284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -113.03,
        "Latitude": 490800000,
        "Longitude": -1130200000,
        "Elevation (m)": 1249.7,
        "First Year": 1958,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1963,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "PICTURE BUTTE",
        "Province": "ALBERTA",
        "Climate ID": "3035173",
        "Station ID": 2285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -112.78,
        "Latitude": 495300000,
        "Longitude": -1124700000,
        "Elevation (m)": 899.2,
        "First Year": 1965,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1978,
        "MLY First Year": 1965,
        "MLY Last Year": 1978
      },
      {
        "Name": "PINCHER CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3035198",
        "Station ID": 49368,
        "WMO ID": 71085,
        "TC ID": "ZPC",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 493114000,
        "Longitude": -1135950000,
        "Elevation (m)": 1189.6,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PINCHER CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3035201",
        "Station ID": 2286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -113.95,
        "Latitude": 493000000,
        "Longitude": -1135700000,
        "Elevation (m)": 1155.2,
        "First Year": 1960,
        "Last Year": 1979,
        "HLY First Year": 1960,
        "HLY Last Year": 1979,
        "DLY First Year": 1960,
        "DLY Last Year": 1979,
        "MLY First Year": 1960,
        "MLY Last Year": 1979
      },
      {
        "Name": "PINCHER CREEK A",
        "Province": "ALBERTA",
        "Climate ID": "3035202",
        "Station ID": 2287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 493100000,
        "Longitude": -1140000000,
        "Elevation (m)": 1189.9,
        "First Year": 1979,
        "Last Year": 1994,
        "HLY First Year": 1979,
        "HLY Last Year": 1994,
        "DLY First Year": 1979,
        "DLY Last Year": 1994,
        "MLY First Year": 1979,
        "MLY Last Year": 1994
      },
      {
        "Name": "PINCHER CREEK (AUT)",
        "Province": "ALBERTA",
        "Climate ID": "3035206",
        "Station ID": 8791,
        "WMO ID": null,
        "TC ID": "PPX",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 493114000,
        "Longitude": -1135950000,
        "Elevation (m)": 1189.63,
        "First Year": 1992,
        "Last Year": 2012,
        "HLY First Year": 1994,
        "HLY Last Year": 2012,
        "DLY First Year": 1992,
        "DLY Last Year": 2011,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINCHER CREEK CLIMATE",
        "Province": "ALBERTA",
        "Climate ID": "3035208",
        "Station ID": 27218,
        "WMO ID": 71736,
        "TC ID": "PCK",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 493118003,
        "Longitude": -1140017001,
        "Elevation (m)": 1190,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINCHER CREEK EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3035216",
        "Station ID": 2288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -113.93,
        "Latitude": 492900000,
        "Longitude": -1135600000,
        "Elevation (m)": 1149.1,
        "First Year": 1954,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1962,
        "MLY First Year": 1954,
        "MLY Last Year": 1962
      },
      {
        "Name": "PINCHER CREEK KERR",
        "Province": "ALBERTA",
        "Climate ID": "3035218",
        "Station ID": 2289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -113.93,
        "Latitude": 492300000,
        "Longitude": -1135600000,
        "Elevation (m)": 1333.5,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "PINCHER CRK SPRINGRIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3035219",
        "Station ID": 2290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -113.65,
        "Latitude": 492700000,
        "Longitude": -1133900000,
        "Elevation (m)": 1158.2,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "PINCHER CREEK TOWN",
        "Province": "ALBERTA",
        "Climate ID": "3035220",
        "Station ID": 2291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -113.97,
        "Latitude": 493100000,
        "Longitude": -1135800000,
        "Elevation (m)": 1145.4,
        "First Year": 1893,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1963,
        "MLY First Year": 1893,
        "MLY Last Year": 1963
      },
      {
        "Name": "PINCHER CREEK WEST",
        "Province": "ALBERTA",
        "Climate ID": "3035221",
        "Station ID": 2292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 493100000,
        "Longitude": -1140200000,
        "Elevation (m)": 1146,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "PINE COULEE RESERVOIR",
        "Province": "ALBERTA",
        "Climate ID": "3035224",
        "Station ID": 27825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -113.75,
        "Latitude": 500719000,
        "Longitude": -1134453000,
        "Elevation (m)": 1050,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINE COULEE FP",
        "Province": "ALBERTA",
        "Climate ID": "3035228",
        "Station ID": 27826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -113.75,
        "Latitude": 500705000,
        "Longitude": -1134505000,
        "Elevation (m)": 1040,
        "First Year": 1999,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2005,
        "MLY First Year": 1999,
        "MLY Last Year": 2005
      },
      {
        "Name": "POKAPPINI",
        "Province": "ALBERTA",
        "Climate ID": "3035233",
        "Station ID": 2293,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -113.63,
        "Latitude": 502500000,
        "Longitude": -1133800000,
        "Elevation (m)": 743.7,
        "First Year": 1921,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1935,
        "MLY First Year": 1921,
        "MLY Last Year": 1935
      },
      {
        "Name": "PRIDDIS",
        "Province": "ALBERTA",
        "Climate ID": "3035301",
        "Station ID": 2294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 505300000,
        "Longitude": -1142700000,
        "Elevation (m)": 1371.6,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "QUEENSTOWN",
        "Province": "ALBERTA",
        "Climate ID": "3035340",
        "Station ID": 2295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.61,
        "Longitude (Decimal Degrees)": -112.98,
        "Latitude": 503623070,
        "Longitude": -1125900010,
        "Elevation (m)": 940,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "QUEENSTOWN",
        "Province": "ALBERTA",
        "Climate ID": "3035342",
        "Station ID": 47767,
        "WMO ID": 71073,
        "TC ID": "PQU",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 503552010,
        "Longitude": -1124812050,
        "Elevation (m)": 941,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RAINIER",
        "Province": "ALBERTA",
        "Climate ID": "3035350",
        "Station ID": 2296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -112.03,
        "Latitude": 502200000,
        "Longitude": -1120200000,
        "Elevation (m)": 763.5,
        "First Year": 1963,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1980,
        "MLY First Year": 1963,
        "MLY Last Year": 1980
      },
      {
        "Name": "RAINIER AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3035355",
        "Station ID": 53738,
        "WMO ID": null,
        "TC ID": "PRE",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 502200001,
        "Longitude": -1120700000,
        "Elevation (m)": 765,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RALSTON",
        "Province": "ALBERTA",
        "Climate ID": "3035360",
        "Station ID": 2297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -111.17,
        "Latitude": 501500000,
        "Longitude": -1111000000,
        "Elevation (m)": 769.6,
        "First Year": 1954,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1976,
        "MLY First Year": 1954,
        "MLY Last Year": 1976
      },
      {
        "Name": "RALSTON AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3035362",
        "Station ID": 50918,
        "WMO ID": null,
        "TC ID": "PXX",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -111.21,
        "Latitude": 500556759,
        "Longitude": -1111247088,
        "Elevation (m)": 770,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RAYMOND",
        "Province": "ALBERTA",
        "Climate ID": "3035420",
        "Station ID": 2298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -112.7,
        "Latitude": 492700000,
        "Longitude": -1124200000,
        "Elevation (m)": 957.1,
        "First Year": 1910,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1976,
        "MLY First Year": 1910,
        "MLY Last Year": 1976
      },
      {
        "Name": "RAYMOND AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3035422",
        "Station ID": 42729,
        "WMO ID": 71536,
        "TC ID": "PRY",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -112.68,
        "Latitude": 492900000,
        "Longitude": -1124100000,
        "Elevation (m)": 937,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "REDCLIFF",
        "Province": "ALBERTA",
        "Climate ID": "3035434",
        "Station ID": 2299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -110.77,
        "Latitude": 500400000,
        "Longitude": -1104600000,
        "Elevation (m)": 740.7,
        "First Year": 1976,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1984,
        "MLY First Year": 1976,
        "MLY Last Year": 1984
      },
      {
        "Name": "ROLLING HILLS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3035530",
        "Station ID": 46827,
        "WMO ID": 71105,
        "TC ID": "PRH",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -111.7,
        "Latitude": 501554007,
        "Longitude": -1114204000,
        "Elevation (m)": 645,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "RONALANE",
        "Province": "ALBERTA",
        "Climate ID": "3035540",
        "Station ID": 2300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -111.57,
        "Latitude": 500500000,
        "Longitude": -1113400000,
        "Elevation (m)": 716.3,
        "First Year": 1913,
        "Last Year": 1924,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1924,
        "MLY First Year": 1913,
        "MLY Last Year": 1924
      },
      {
        "Name": "ROSEMARY AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3035650",
        "Station ID": 42731,
        "WMO ID": 71537,
        "TC ID": "PRO",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -112.05,
        "Latitude": 505000000,
        "Longitude": -1120300000,
        "Elevation (m)": 714,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "SEVEN PERSONS",
        "Province": "ALBERTA",
        "Climate ID": "3035840",
        "Station ID": 2301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -110.9,
        "Latitude": 495000000,
        "Longitude": -1105400000,
        "Elevation (m)": 755.9,
        "First Year": 1911,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 2007,
        "MLY First Year": 1911,
        "MLY Last Year": 2007
      },
      {
        "Name": "SEVEN PERSONS AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3035845",
        "Station ID": 42730,
        "WMO ID": 71525,
        "TC ID": "PSV",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -110.92,
        "Latitude": 495500000,
        "Longitude": -1105500000,
        "Elevation (m)": 766,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SEVEN PERSONS RIVERS DEV",
        "Province": "ALBERTA",
        "Climate ID": "3035850",
        "Station ID": 2302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -110.9,
        "Latitude": 495200000,
        "Longitude": -1105400000,
        "Elevation (m)": 758.6,
        "First Year": 1958,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1963,
        "MLY First Year": 1958,
        "MLY Last Year": 1963
      },
      {
        "Name": "SHAUGHNESSY",
        "Province": "ALBERTA",
        "Climate ID": "3035880",
        "Station ID": 2303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -112.82,
        "Latitude": 495000000,
        "Longitude": -1124900000,
        "Elevation (m)": null,
        "First Year": 1952,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1956,
        "MLY First Year": 1952,
        "MLY Last Year": 1956
      },
      {
        "Name": "SOCIAL PLAINS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3036040",
        "Station ID": 50922,
        "WMO ID": null,
        "TC ID": "PPN",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -110.21,
        "Latitude": 504355199,
        "Longitude": -1101252919,
        "Elevation (m)": 741,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CALGARY SPRINGBANK A",
        "Province": "ALBERTA",
        "Climate ID": "3036077",
        "Station ID": 50623,
        "WMO ID": 71860,
        "TC ID": "YBW",
        "Latitude (Decimal Degrees)": 51.11,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 510619000,
        "Longitude": -1142217000,
        "Elevation (m)": 1200.9,
        "First Year": 2012,
        "Last Year": 2014,
        "HLY First Year": 2012,
        "HLY Last Year": 2014,
        "DLY First Year": 2012,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SPRINGBANK A",
        "Province": "ALBERTA",
        "Climate ID": "3036078",
        "Station ID": 2304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -114.38,
        "Latitude": 510600000,
        "Longitude": -1142300000,
        "Elevation (m)": 1200.9,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "SPY HILL",
        "Province": "ALBERTA",
        "Climate ID": "3036084",
        "Station ID": 2305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -114.22,
        "Latitude": 511200000,
        "Longitude": -1141300000,
        "Elevation (m)": 1210,
        "First Year": 1990,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1992,
        "MLY First Year": 1990,
        "MLY Last Year": 1992
      },
      {
        "Name": "STANDARD",
        "Province": "ALBERTA",
        "Climate ID": "3036085",
        "Station ID": 2306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -112.92,
        "Latitude": 510900000,
        "Longitude": -1125500000,
        "Elevation (m)": 914.4,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "STANDARD AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3036086",
        "Station ID": 46849,
        "WMO ID": 71440,
        "TC ID": "PSD",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -112.98,
        "Latitude": 511342005,
        "Longitude": -1125855006,
        "Elevation (m)": 901,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "STAVELY AAFC",
        "Province": "ALBERTA",
        "Climate ID": "3036099",
        "Station ID": 27200,
        "WMO ID": 71555,
        "TC ID": "WXN",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -113.88,
        "Latitude": 501100000,
        "Longitude": -1135300000,
        "Elevation (m)": 1363.6,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "STAVELY CDA",
        "Province": "ALBERTA",
        "Climate ID": "3036100",
        "Station ID": 2307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 501000000,
        "Longitude": -1135200000,
        "Elevation (m)": 1097.3,
        "First Year": 1955,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1962,
        "MLY First Year": 1955,
        "MLY Last Year": 1962
      },
      {
        "Name": "STAVELY EXP ST 2",
        "Province": "ALBERTA",
        "Climate ID": "3036102",
        "Station ID": 2308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -113.65,
        "Latitude": 501000000,
        "Longitude": -1133900000,
        "Elevation (m)": 1043,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "STRATHMORE",
        "Province": "ALBERTA",
        "Climate ID": "3036200",
        "Station ID": 2309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -113.42,
        "Latitude": 510300000,
        "Longitude": -1132500000,
        "Elevation (m)": 963.2,
        "First Year": 1912,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 2004,
        "MLY First Year": 1912,
        "MLY Last Year": 2003
      },
      {
        "Name": "STRATHMORE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3036205",
        "Station ID": 42725,
        "WMO ID": 71526,
        "TC ID": "PST",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 510200000,
        "Longitude": -1131700000,
        "Elevation (m)": 967,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "STRATHMORE EAST",
        "Province": "ALBERTA",
        "Climate ID": "3036208",
        "Station ID": 2310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 510600000,
        "Longitude": -1131300000,
        "Elevation (m)": 929.6,
        "First Year": 1970,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1989,
        "MLY First Year": 1970,
        "MLY Last Year": 1989
      },
      {
        "Name": "SUFFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3036235",
        "Station ID": 2311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -111.15,
        "Latitude": 501300000,
        "Longitude": -1110900000,
        "Elevation (m)": 748.3,
        "First Year": 1913,
        "Last Year": 1924,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1924,
        "MLY First Year": 1913,
        "MLY Last Year": 1924
      },
      {
        "Name": "SUFFIELD A",
        "Province": "ALBERTA",
        "Climate ID": "3036240",
        "Station ID": 2312,
        "WMO ID": 71128,
        "TC ID": "YSD",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -111.18,
        "Latitude": 501600000,
        "Longitude": -1111100000,
        "Elevation (m)": 769.6,
        "First Year": 1950,
        "Last Year": 2020,
        "HLY First Year": 1953,
        "HLY Last Year": 2020,
        "DLY First Year": 1950,
        "DLY Last Year": 2008,
        "MLY First Year": 1950,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUFFIELD HAMLET",
        "Province": "ALBERTA",
        "Climate ID": "3036241",
        "Station ID": 2313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -111.17,
        "Latitude": 501300000,
        "Longitude": -1111000000,
        "Elevation (m)": 751.3,
        "First Year": 1980,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1991,
        "MLY First Year": 1980,
        "MLY Last Year": 1991
      },
      {
        "Name": "SUFFIELD SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3036242",
        "Station ID": 2314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -111.17,
        "Latitude": 500400000,
        "Longitude": -1111000000,
        "Elevation (m)": 678,
        "First Year": 1985,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1989,
        "MLY First Year": 1985,
        "MLY Last Year": 1989
      },
      {
        "Name": "TABER",
        "Province": "ALBERTA",
        "Climate ID": "3036360",
        "Station ID": 2315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 494731020,
        "Longitude": -1120728080,
        "Elevation (m)": 811,
        "First Year": 1907,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 2022,
        "MLY First Year": 1907,
        "MLY Last Year": 2007
      },
      {
        "Name": "TIDE LAKE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3036485",
        "Station ID": 50919,
        "WMO ID": null,
        "TC ID": "PTE",
        "Latitude (Decimal Degrees)": 50.46,
        "Longitude (Decimal Degrees)": -111.32,
        "Latitude": 502720052,
        "Longitude": -1111917039,
        "Elevation (m)": 766,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TRAVERS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3036500",
        "Station ID": 46914,
        "WMO ID": 71848,
        "TC ID": "PTR",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -112.86,
        "Latitude": 501813008,
        "Longitude": -1125145007,
        "Elevation (m)": 955,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "TURIN",
        "Province": "ALBERTA",
        "Climate ID": "3036618",
        "Station ID": 2316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -112.45,
        "Latitude": 495800000,
        "Longitude": -1122700000,
        "Elevation (m)": 823,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "TURIN EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3036620",
        "Station ID": 2317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -112.43,
        "Latitude": 500000000,
        "Longitude": -1122600000,
        "Elevation (m)": 835.2,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "TURNER VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3036640",
        "Station ID": 2318,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -114.35,
        "Latitude": 504300000,
        "Longitude": -1142100000,
        "Elevation (m)": 1237.5,
        "First Year": 1949,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1975,
        "MLY First Year": 1949,
        "MLY Last Year": 1975
      },
      {
        "Name": "TURNER VALLEY RS",
        "Province": "ALBERTA",
        "Climate ID": "3036641",
        "Station ID": 2319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -114.28,
        "Latitude": 504000000,
        "Longitude": -1141700000,
        "Elevation (m)": 1219.2,
        "First Year": 1972,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1982,
        "MLY First Year": 1972,
        "MLY Last Year": 1982
      },
      {
        "Name": "UNIVERSITY OF CALGARY",
        "Province": "ALBERTA",
        "Climate ID": "3036652",
        "Station ID": 2320,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -114.13,
        "Latitude": 510500000,
        "Longitude": -1140800000,
        "Elevation (m)": 1112.2,
        "First Year": 1964,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1990,
        "MLY First Year": 1964,
        "MLY Last Year": 1990
      },
      {
        "Name": "VAUXHALL",
        "Province": "ALBERTA",
        "Climate ID": "3036680",
        "Station ID": 2321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -112.1,
        "Latitude": 500400000,
        "Longitude": -1120600000,
        "Elevation (m)": 778.8,
        "First Year": 1913,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1957,
        "MLY First Year": 1913,
        "MLY Last Year": 1957
      },
      {
        "Name": "VAUXHALL CDA",
        "Province": "ALBERTA",
        "Climate ID": "3036681",
        "Station ID": 2322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -112.13,
        "Latitude": 500300000,
        "Longitude": -1120800000,
        "Elevation (m)": 778.8,
        "First Year": 1953,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1991,
        "MLY First Year": 1953,
        "MLY Last Year": 1991
      },
      {
        "Name": "VAUXHALL CDA CS",
        "Province": "ALBERTA",
        "Climate ID": "3036682",
        "Station ID": 10889,
        "WMO ID": 71251,
        "TC ID": "WVO",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -112.13,
        "Latitude": 500300000,
        "Longitude": -1120800000,
        "Elevation (m)": 779,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "VAUXHALL NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3036690",
        "Station ID": 2323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 501057080,
        "Longitude": -1120719040,
        "Elevation (m)": 760,
        "First Year": 1982,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2009,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "VINERIPE GREENHOUSE",
        "Province": "ALBERTA",
        "Climate ID": "3036856",
        "Station ID": 6857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -114.5,
        "Latitude": 505000000,
        "Longitude": -1143000000,
        "Elevation (m)": 1322.8,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": 1992,
        "MLY Last Year": 1993
      },
      {
        "Name": "VULCAN",
        "Province": "ALBERTA",
        "Climate ID": "3036880",
        "Station ID": 2324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -113.07,
        "Latitude": 503200000,
        "Longitude": -1130400000,
        "Elevation (m)": 990.6,
        "First Year": 1921,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1968,
        "MLY First Year": 1921,
        "MLY Last Year": 1968
      },
      {
        "Name": "VULCAN",
        "Province": "ALBERTA",
        "Climate ID": "3036881",
        "Station ID": 2325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -113.25,
        "Latitude": 502400000,
        "Longitude": -1131500000,
        "Elevation (m)": 1048.8,
        "First Year": 1974,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2000,
        "MLY First Year": 1974,
        "MLY Last Year": 2000
      },
      {
        "Name": "VULCAN SMITH",
        "Province": "ALBERTA",
        "Climate ID": "3036884",
        "Station ID": 6858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -113.05,
        "Latitude": 503000000,
        "Longitude": -1130300000,
        "Elevation (m)": 966,
        "First Year": 1992,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1994,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "WHITLA",
        "Province": "ALBERTA",
        "Climate ID": "3037520",
        "Station ID": 2326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -111.05,
        "Latitude": 495200000,
        "Longitude": -1110300000,
        "Elevation (m)": 837.6,
        "First Year": 1917,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1968,
        "MLY First Year": 1917,
        "MLY Last Year": 1968
      },
      {
        "Name": "WILDCAT HILLS",
        "Province": "ALBERTA",
        "Climate ID": "3037550",
        "Station ID": 43363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -114.72,
        "Latitude": 511609000,
        "Longitude": -1144314070,
        "Elevation (m)": 1268,
        "First Year": 2005,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2016,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIFRED",
        "Province": "ALBERTA",
        "Climate ID": "3037600",
        "Station ID": 2327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -111.2,
        "Latitude": 495400000,
        "Longitude": -1111200000,
        "Elevation (m)": 830.6,
        "First Year": 1928,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1967,
        "MLY First Year": 1928,
        "MLY Last Year": 1967
      },
      {
        "Name": "WOODHOUSE",
        "Province": "ALBERTA",
        "Climate ID": "3037650",
        "Station ID": 2328,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -113.37,
        "Latitude": 495700000,
        "Longitude": -1132200000,
        "Elevation (m)": 975.4,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1983,
        "MLY First Year": 1981,
        "MLY Last Year": 1983
      },
      {
        "Name": "WRENTHAM AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3037675",
        "Station ID": 31412,
        "WMO ID": 71343,
        "TC ID": "XWM",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -112.12,
        "Latitude": 493000000,
        "Longitude": -1120700000,
        "Elevation (m)": 943.94,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALGARY ELBOW VIEW",
        "Province": "ALBERTA",
        "Climate ID": "303A0Q6",
        "Station ID": 2168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 510100000,
        "Longitude": -1141200000,
        "Elevation (m)": 1127.8,
        "First Year": 1966,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1987,
        "MLY First Year": 1966,
        "MLY Last Year": 1987
      },
      {
        "Name": "COCHRANE VE6CQC",
        "Province": "ALBERTA",
        "Climate ID": "303AFGF",
        "Station ID": 2167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.24,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 511417020,
        "Longitude": -1142652060,
        "Elevation (m)": 1303,
        "First Year": 1990,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2003,
        "MLY First Year": 1990,
        "MLY Last Year": 2003
      },
      {
        "Name": "DUCKWORTH FARM",
        "Province": "ALBERTA",
        "Climate ID": "303BJDL",
        "Station ID": 6948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -113.88,
        "Latitude": 510200000,
        "Longitude": -1135300000,
        "Elevation (m)": 1050.3,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "KEOMA",
        "Province": "ALBERTA",
        "Climate ID": "303C642",
        "Station ID": 2169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.24,
        "Longitude (Decimal Degrees)": -113.64,
        "Latitude": 511436020,
        "Longitude": -1133822080,
        "Elevation (m)": 948,
        "First Year": 1985,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2006,
        "MLY First Year": 1985,
        "MLY Last Year": 2006
      },
      {
        "Name": "OKOTOKS BIG ROCK",
        "Province": "ALBERTA",
        "Climate ID": "303D9JN",
        "Station ID": 2170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -114.12,
        "Latitude": 504200000,
        "Longitude": -1140700000,
        "Elevation (m)": 1136.9,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "SPRINGBANK A",
        "Province": "ALBERTA",
        "Climate ID": "303F0PP",
        "Station ID": 2171,
        "WMO ID": 71860,
        "TC ID": "YBW",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 510611000,
        "Longitude": -1142228000,
        "Elevation (m)": 1200.9,
        "First Year": 1984,
        "Last Year": 2012,
        "HLY First Year": 1984,
        "HLY Last Year": 2012,
        "DLY First Year": 1984,
        "DLY Last Year": 2012,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "TURNER VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "303FFLR",
        "Station ID": 6855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -114.33,
        "Latitude": 503800000,
        "Longitude": -1142000000,
        "Elevation (m)": 1310.6,
        "First Year": 1991,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2000,
        "MLY First Year": 1991,
        "MLY Last Year": 2000
      },
      {
        "Name": "HIGH RIVER TOWERS",
        "Province": "ALBERTA",
        "Climate ID": "303L24K",
        "Station ID": 2172,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.58,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 503500000,
        "Longitude": -1135200000,
        "Elevation (m)": 1036,
        "First Year": 1988,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1993,
        "MLY First Year": 1988,
        "MLY Last Year": 1993
      },
      {
        "Name": "OKOTOKS",
        "Province": "ALBERTA",
        "Climate ID": "303M9JM",
        "Station ID": 2173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -113.96,
        "Latitude": 504345010,
        "Longitude": -1135739090,
        "Elevation (m)": 1081,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2022,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "PICTURE BUTTE WEST",
        "Province": "ALBERTA",
        "Climate ID": "303N1G3",
        "Station ID": 2174,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -112.79,
        "Latitude": 495217090,
        "Longitude": -1124718010,
        "Elevation (m)": 901,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "CROSS CONSERVATION AREA",
        "Province": "ALBERTA",
        "Climate ID": "303S001",
        "Station ID": 27015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.79,
        "Longitude (Decimal Degrees)": -114.21,
        "Latitude": 504730000,
        "Longitude": -1141250000,
        "Elevation (m)": 1264,
        "First Year": 1996,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1996,
        "MLY First Year": 1996,
        "MLY Last Year": 1996
      },
      {
        "Name": "ADEN",
        "Province": "ALBERTA",
        "Climate ID": "3040049",
        "Station ID": 2331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -111.32,
        "Latitude": 490300000,
        "Longitude": -1111900000,
        "Elevation (m)": 1036.3,
        "First Year": 1962,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2000,
        "MLY First Year": 1962,
        "MLY Last Year": 2000
      },
      {
        "Name": "ALTAWAN",
        "Province": "ALBERTA",
        "Climate ID": "3040223",
        "Station ID": 2332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -110.01,
        "Latitude": 491443007,
        "Longitude": -1100049004,
        "Elevation (m)": 944.9,
        "First Year": 1965,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2007,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "BONNIE VIEW",
        "Province": "ALBERTA",
        "Climate ID": "3040735",
        "Station ID": 2333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -112.63,
        "Latitude": 491900000,
        "Longitude": -1123800000,
        "Elevation (m)": 1250,
        "First Year": 1977,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1981,
        "MLY First Year": 1977,
        "MLY Last Year": 1981
      },
      {
        "Name": "COUTTS",
        "Province": "ALBERTA",
        "Climate ID": "3041908",
        "Station ID": 2334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -111.95,
        "Latitude": 490000000,
        "Longitude": -1115700000,
        "Elevation (m)": 1036.3,
        "First Year": 1899,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1973,
        "MLY First Year": 1899,
        "MLY Last Year": 1973
      },
      {
        "Name": "COUTTS EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3041910",
        "Station ID": 2335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 490200000,
        "Longitude": -1115100000,
        "Elevation (m)": 1057,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "DEL BONITA",
        "Province": "ALBERTA",
        "Climate ID": "3042040",
        "Station ID": 2336,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -112.8,
        "Latitude": 490200000,
        "Longitude": -1124800000,
        "Elevation (m)": 1158.2,
        "First Year": 1950,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1956,
        "MLY First Year": 1950,
        "MLY Last Year": 1956
      },
      {
        "Name": "DEL BONITA",
        "Province": "ALBERTA",
        "Climate ID": "3042041",
        "Station ID": 2337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -112.82,
        "Latitude": 490800000,
        "Longitude": -1124900000,
        "Elevation (m)": 1293,
        "First Year": 1985,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1990,
        "MLY First Year": 1985,
        "MLY Last Year": 1990
      },
      {
        "Name": "DEL BONITA AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3042045",
        "Station ID": 32256,
        "WMO ID": 71224,
        "TC ID": "XDB",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -112.82,
        "Latitude": 490300000,
        "Longitude": -1124900000,
        "Elevation (m)": 1310,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1992,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEL BONITA EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3042056",
        "Station ID": 2338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -112.88,
        "Latitude": 490500000,
        "Longitude": -1125300000,
        "Elevation (m)": 1158.2,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "EAGLE BUTTE",
        "Province": "ALBERTA",
        "Climate ID": "3042148",
        "Station ID": 2339,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -110.37,
        "Latitude": 493453010,
        "Longitude": -1102215030,
        "Elevation (m)": 1163,
        "First Year": 1966,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2012,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "GLASSFORD",
        "Province": "ALBERTA",
        "Climate ID": "3042793",
        "Station ID": 2340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -110.7,
        "Latitude": 492200000,
        "Longitude": -1104200000,
        "Elevation (m)": 833.6,
        "First Year": 1925,
        "Last Year": 1937,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1937,
        "MLY First Year": 1925,
        "MLY Last Year": 1937
      },
      {
        "Name": "GROTON",
        "Province": "ALBERTA",
        "Climate ID": "3042960",
        "Station ID": 2341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -111.33,
        "Latitude": 491200000,
        "Longitude": -1112000000,
        "Elevation (m)": 914.4,
        "First Year": 1927,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1963,
        "MLY First Year": 1927,
        "MLY Last Year": 1963
      },
      {
        "Name": "KNAPPEN EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3043660",
        "Station ID": 2342,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 490400000,
        "Longitude": -1113300000,
        "Elevation (m)": 975.4,
        "First Year": 1955,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1962,
        "MLY First Year": 1955,
        "MLY Last Year": 1962
      },
      {
        "Name": "KNIGHT LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3043667",
        "Station ID": 2343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -112.73,
        "Latitude": 491300000,
        "Longitude": -1124400000,
        "Elevation (m)": 1219,
        "First Year": 1981,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1995,
        "MLY First Year": 1981,
        "MLY Last Year": 1995
      },
      {
        "Name": "LUCKY STRIKE",
        "Province": "ALBERTA",
        "Climate ID": "3044070",
        "Station ID": 2344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -111.57,
        "Latitude": 491400000,
        "Longitude": -1113400000,
        "Elevation (m)": 896.1,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "MANYBERRIES CDA",
        "Province": "ALBERTA",
        "Climate ID": "3044200",
        "Station ID": 2345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -110.47,
        "Latitude": 490700000,
        "Longitude": -1102800000,
        "Elevation (m)": 934.2,
        "First Year": 1928,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1993,
        "MLY First Year": 1928,
        "MLY Last Year": 1993
      },
      {
        "Name": "MANYBERRIES AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3044203",
        "Station ID": 46809,
        "WMO ID": 71051,
        "TC ID": "PMB",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -110.68,
        "Latitude": 492150000,
        "Longitude": -1104041005,
        "Elevation (m)": 927,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "MANYBERRIES CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3044204",
        "Station ID": 2346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -110.55,
        "Latitude": 492900000,
        "Longitude": -1103300000,
        "Elevation (m)": 1036.3,
        "First Year": 1966,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1970,
        "MLY First Year": 1966,
        "MLY Last Year": 1970
      },
      {
        "Name": "MASINASIN",
        "Province": "ALBERTA",
        "Climate ID": "3044230",
        "Station ID": 2347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -111.65,
        "Latitude": 490736060,
        "Longitude": -1113915020,
        "Elevation (m)": 953,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "MASINASIN AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3044232",
        "Station ID": 31411,
        "WMO ID": 71345,
        "TC ID": "XMN",
        "Latitude (Decimal Degrees)": 49.14,
        "Longitude (Decimal Degrees)": -111.65,
        "Latitude": 490812044,
        "Longitude": -1113906084,
        "Elevation (m)": 947.9,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILK RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3044533",
        "Station ID": 8804,
        "WMO ID": 71244,
        "TC ID": "WRY",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -112.05,
        "Latitude": 490800000,
        "Longitude": -1120300000,
        "Elevation (m)": 1050,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILK RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3044534",
        "Station ID": 2348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -111.53,
        "Latitude": 491000000,
        "Longitude": -1113200000,
        "Elevation (m)": 975.4,
        "First Year": 1959,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1980,
        "MLY First Year": 1959,
        "MLY Last Year": 1980
      },
      {
        "Name": "MILK RIVER EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3044540",
        "Station ID": 2349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -112.05,
        "Latitude": 490500000,
        "Longitude": -1120300000,
        "Elevation (m)": 1045.5,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "MILK RIVER RIDGE RESERVOIR",
        "Province": "ALBERTA",
        "Climate ID": "3044545",
        "Station ID": 45767,
        "WMO ID": 71015,
        "TC ID": "PRR",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -112.54,
        "Latitude": 491702000,
        "Longitude": -1123218000,
        "Elevation (m)": 1305,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILK R VERDIGRIS L",
        "Province": "ALBERTA",
        "Climate ID": "3044550",
        "Station ID": 2350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -112.03,
        "Latitude": 491900000,
        "Longitude": -1120200000,
        "Elevation (m)": 956.2,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "MILK R VERDIGRIS L",
        "Province": "ALBERTA",
        "Climate ID": "3044551",
        "Station ID": 2351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -112.03,
        "Latitude": 491200000,
        "Longitude": -1120200000,
        "Elevation (m)": 1036.3,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "ONEFOUR CDA",
        "Province": "ALBERTA",
        "Climate ID": "3044923",
        "Station ID": 10725,
        "WMO ID": 71116,
        "TC ID": "WOE",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -110.47,
        "Latitude": 490700000,
        "Longitude": -1102800000,
        "Elevation (m)": 935,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ORION",
        "Province": "ALBERTA",
        "Climate ID": "3044930",
        "Station ID": 32333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.49,
        "Longitude (Decimal Degrees)": -110.82,
        "Latitude": 492941030,
        "Longitude": -1104927090,
        "Elevation (m)": 944,
        "First Year": 2002,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "PAKOWKI LAKE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3045000",
        "Station ID": 46912,
        "WMO ID": 71070,
        "TC ID": "PPL",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -111.13,
        "Latitude": 491329006,
        "Longitude": -1110734006,
        "Elevation (m)": 915,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINHORN AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3045100",
        "Station ID": 51498,
        "WMO ID": null,
        "TC ID": "PZN",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -111.03,
        "Latitude": 490144022,
        "Longitude": -1110130061,
        "Elevation (m)": 1061,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POTHOLE MCINTYRE",
        "Province": "ALBERTA",
        "Climate ID": "3045293",
        "Station ID": 2352,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -112.85,
        "Latitude": 491300000,
        "Longitude": -1125100000,
        "Elevation (m)": 1158.2,
        "First Year": 1977,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1984,
        "MLY First Year": 1977,
        "MLY Last Year": 1984
      },
      {
        "Name": "THELMA CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3046376",
        "Station ID": 2353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -110.33,
        "Latitude": 492700000,
        "Longitude": -1102000000,
        "Elevation (m)": 1066.8,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "WARNER",
        "Province": "ALBERTA",
        "Climate ID": "3046950",
        "Station ID": 2354,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -112.2,
        "Latitude": 491700000,
        "Longitude": -1121200000,
        "Elevation (m)": 1010.1,
        "First Year": 1955,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1966,
        "MLY First Year": 1955,
        "MLY Last Year": 1966
      },
      {
        "Name": "WARNER AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3046951",
        "Station ID": 51497,
        "WMO ID": null,
        "TC ID": "PZW",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -112.16,
        "Latitude": 491658051,
        "Longitude": -1120937066,
        "Elevation (m)": 1004,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WARNER EAST",
        "Province": "ALBERTA",
        "Climate ID": "3046952",
        "Station ID": 2355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 491600000,
        "Longitude": -1114700000,
        "Elevation (m)": 944.9,
        "First Year": 1963,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1967,
        "MLY First Year": 1963,
        "MLY Last Year": 1967
      },
      {
        "Name": "WARNER WEST",
        "Province": "ALBERTA",
        "Climate ID": "3046953",
        "Station ID": 2356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.29,
        "Longitude (Decimal Degrees)": -112.41,
        "Latitude": 491708030,
        "Longitude": -1122435070,
        "Elevation (m)": 1109,
        "First Year": 1978,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2004,
        "MLY First Year": 1978,
        "MLY Last Year": 2004
      },
      {
        "Name": "WHISKEY GAP",
        "Province": "ALBERTA",
        "Climate ID": "3047365",
        "Station ID": 2357,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -112.95,
        "Latitude": 490100000,
        "Longitude": -1125700000,
        "Elevation (m)": 1310.6,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "WRITING ON STONE PARK",
        "Province": "ALBERTA",
        "Climate ID": "3047670",
        "Station ID": 2358,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -111.62,
        "Latitude": 490500000,
        "Longitude": -1113700000,
        "Elevation (m)": 899.2,
        "First Year": 1981,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1988,
        "MLY First Year": 1981,
        "MLY Last Year": 1988
      },
      {
        "Name": "KETCHUM CREEK",
        "Province": "ALBERTA",
        "Climate ID": "304CFDC",
        "Station ID": 2329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 491600000,
        "Longitude": -1113300000,
        "Elevation (m)": 701,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "FLORANN",
        "Province": "ALBERTA",
        "Climate ID": "304KFL0",
        "Station ID": 2330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -111.4,
        "Latitude": 491600000,
        "Longitude": -1112400000,
        "Elevation (m)": 951,
        "First Year": 1986,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1990,
        "MLY First Year": 1986,
        "MLY Last Year": 1990
      },
      {
        "Name": "AKAMINA PASS",
        "Province": "ALBERTA",
        "Climate ID": "30500FR",
        "Station ID": 9604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -114.05,
        "Latitude": 490136000,
        "Longitude": -1140304000,
        "Elevation (m)": 1800,
        "First Year": 2002,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2007,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "ANTHRACITE",
        "Province": "ALBERTA",
        "Climate ID": "3050240",
        "Station ID": 2362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -115.5,
        "Latitude": 511200000,
        "Longitude": -1153000000,
        "Elevation (m)": 1386.8,
        "First Year": 1929,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1962,
        "MLY First Year": 1931,
        "MLY Last Year": 1962
      },
      {
        "Name": "BALDY LO",
        "Province": "ALBERTA",
        "Climate ID": "3050490",
        "Station ID": 2363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.55,
        "Longitude (Decimal Degrees)": -116.12,
        "Latitude": 523300000,
        "Longitude": -1160700000,
        "Elevation (m)": 2083.3,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "BANFF CS",
        "Province": "ALBERTA",
        "Climate ID": "3050519",
        "Station ID": 27378,
        "WMO ID": 71122,
        "TC ID": "WZG",
        "Latitude (Decimal Degrees)": 51.19,
        "Longitude (Decimal Degrees)": -115.55,
        "Latitude": 511136090,
        "Longitude": -1153308050,
        "Elevation (m)": 1396.9,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "BANFF",
        "Province": "ALBERTA",
        "Climate ID": "3050520",
        "Station ID": 2364,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -115.57,
        "Latitude": 511100000,
        "Longitude": -1153400000,
        "Elevation (m)": 1383.7,
        "First Year": 1887,
        "Last Year": 1995,
        "HLY First Year": 1953,
        "HLY Last Year": 1995,
        "DLY First Year": 1887,
        "DLY Last Year": 1995,
        "MLY First Year": 1887,
        "MLY Last Year": 1995
      },
      {
        "Name": "BANFF (AUT)",
        "Province": "ALBERTA",
        "Climate ID": "3050521",
        "Station ID": 8811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -115.57,
        "Latitude": 511100000,
        "Longitude": -1153400000,
        "Elevation (m)": 1396.9,
        "First Year": 1992,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": 1992,
        "DLY Last Year": 1997,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BANFF CR10",
        "Province": "ALBERTA",
        "Climate ID": "3050522",
        "Station ID": 27210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -115.55,
        "Latitude": 511200000,
        "Longitude": -1153300000,
        "Elevation (m)": 1397,
        "First Year": 1996,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1997,
        "MLY First Year": 1996,
        "MLY Last Year": 1997
      },
      {
        "Name": "BANFF SPRINGS",
        "Province": "ALBERTA",
        "Climate ID": "3050526",
        "Station ID": 31198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -115.55,
        "Latitude": 511000000,
        "Longitude": -1153300000,
        "Elevation (m)": 1370,
        "First Year": 2001,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2007,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "BARRIER LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3050533",
        "Station ID": 27295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -115.08,
        "Latitude": 510300000,
        "Longitude": -1150442000,
        "Elevation (m)": 2020.5,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAVER MINES",
        "Province": "ALBERTA",
        "Climate ID": "3050600",
        "Station ID": 2366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -114.18,
        "Latitude": 492802020,
        "Longitude": -1141037040,
        "Elevation (m)": 1257,
        "First Year": 1912,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 2012,
        "MLY First Year": 1912,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAVER MINES",
        "Province": "ALBERTA",
        "Climate ID": "3050602",
        "Station ID": 50028,
        "WMO ID": 71304,
        "TC ID": "PBM",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -114.18,
        "Latitude": 492804500,
        "Longitude": -1141030000,
        "Elevation (m)": 1257,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BIGHORN DAM",
        "Province": "ALBERTA",
        "Climate ID": "30506GN",
        "Station ID": 2365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -116.33,
        "Latitude": 521900000,
        "Longitude": -1162000000,
        "Elevation (m)": 1341,
        "First Year": 1969,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2010,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLUEHILL LO",
        "Province": "ALBERTA",
        "Climate ID": "3050725",
        "Station ID": 2368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -115.22,
        "Latitude": 514200000,
        "Longitude": -1151300000,
        "Elevation (m)": 1950.7,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOB CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3050731",
        "Station ID": 2369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -114.25,
        "Latitude": 495300000,
        "Longitude": -1141500000,
        "Elevation (m)": 1371.6,
        "First Year": 1971,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1983,
        "MLY First Year": 1971,
        "MLY Last Year": 1983
      },
      {
        "Name": "BOW VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3050778",
        "Station ID": 10222,
        "WMO ID": 71232,
        "TC ID": "WXA",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 510500000,
        "Longitude": -1150400000,
        "Elevation (m)": 1297.5,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOW VALLEY PROV PARK",
        "Province": "ALBERTA",
        "Climate ID": "3050779",
        "Station ID": 2370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 510500000,
        "Longitude": -1150400000,
        "Elevation (m)": 1318,
        "First Year": 1967,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1990,
        "MLY First Year": 1967,
        "MLY Last Year": 1990
      },
      {
        "Name": "BLAIRMORE HQTS RS",
        "Province": "ALBERTA",
        "Climate ID": "30507AC",
        "Station ID": 2367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -114.43,
        "Latitude": 493700000,
        "Longitude": -1142600000,
        "Elevation (m)": 1310.6,
        "First Year": 1971,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1999,
        "MLY First Year": 1971,
        "MLY Last Year": 1999
      },
      {
        "Name": "BULLHORN COULEE RESERVOIR",
        "Province": "ALBERTA",
        "Climate ID": "3050960",
        "Station ID": 45747,
        "WMO ID": 71839,
        "TC ID": "PBR",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -113.36,
        "Latitude": 491743000,
        "Longitude": -1132131000,
        "Elevation (m)": 1115,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BULLHORN HEADWATERS",
        "Province": "ALBERTA",
        "Climate ID": "3050965",
        "Station ID": 45749,
        "WMO ID": 71016,
        "TC ID": "PBH",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -113.55,
        "Latitude": 490909000,
        "Longitude": -1133302000,
        "Elevation (m)": 1326,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURNS CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3050974",
        "Station ID": 8924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 503700000,
        "Longitude": -1145300000,
        "Elevation (m)": 1875,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURNT TIMBER LO",
        "Province": "ALBERTA",
        "Climate ID": "3050978",
        "Station ID": 2371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -115.38,
        "Latitude": 513000000,
        "Longitude": -1152300000,
        "Elevation (m)": 2316.5,
        "First Year": 1975,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1986,
        "MLY First Year": 1975,
        "MLY Last Year": 1986
      },
      {
        "Name": "(AE) BOW SUMMIT",
        "Province": "ALBERTA",
        "Climate ID": "3050PPF",
        "Station ID": 10700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 514200000,
        "Longitude": -1162800000,
        "Elevation (m)": 2080,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMERON FALLS",
        "Province": "ALBERTA",
        "Climate ID": "3051165",
        "Station ID": 2373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 490300000,
        "Longitude": -1135500000,
        "Elevation (m)": 1310.6,
        "First Year": 1975,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1995,
        "MLY First Year": 1975,
        "MLY Last Year": 1995
      },
      {
        "Name": "CANMORE",
        "Province": "ALBERTA",
        "Climate ID": "3051260",
        "Station ID": 2374,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -115.35,
        "Latitude": 510500000,
        "Longitude": -1152100000,
        "Elevation (m)": 1309.1,
        "First Year": 1958,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "CARBONDALE LO",
        "Province": "ALBERTA",
        "Climate ID": "3051310",
        "Station ID": 2375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 492600000,
        "Longitude": -1142200000,
        "Elevation (m)": 1798.3,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "CASTLE MOUNTAIN VILLAGE",
        "Province": "ALBERTA",
        "Climate ID": "3051424",
        "Station ID": 2376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -115.92,
        "Latitude": 511600000,
        "Longitude": -1155500000,
        "Elevation (m)": 1524,
        "First Year": 1983,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2001,
        "MLY First Year": 1983,
        "MLY Last Year": 2001
      },
      {
        "Name": "CASTLE",
        "Province": "ALBERTA",
        "Climate ID": "3051430",
        "Station ID": 2377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -114.33,
        "Latitude": 492400000,
        "Longitude": -1142000000,
        "Elevation (m)": 1360,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHAPEL ROCK",
        "Province": "ALBERTA",
        "Climate ID": "3051478",
        "Station ID": 10689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -114.28,
        "Latitude": 494324000,
        "Longitude": -1141646000,
        "Elevation (m)": 1510,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHUNGO LO",
        "Province": "ALBERTA",
        "Climate ID": "3051585",
        "Station ID": 2378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.68,
        "Longitude (Decimal Degrees)": -116.7,
        "Latitude": 524100000,
        "Longitude": -1164200000,
        "Elevation (m)": 2304.3,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1964
      },
      {
        "Name": "CLINE LO",
        "Province": "ALBERTA",
        "Climate ID": "3051665",
        "Station ID": 2380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -116.68,
        "Latitude": 521000000,
        "Longitude": -1164100000,
        "Elevation (m)": 1828.8,
        "First Year": 1960,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1965,
        "MLY First Year": 1960,
        "MLY Last Year": 1965
      },
      {
        "Name": "CLINE LO",
        "Province": "ALBERTA",
        "Climate ID": "30516F5",
        "Station ID": 2379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -116.4,
        "Latitude": 521041000,
        "Longitude": -1162400040,
        "Elevation (m)": 2050,
        "First Year": 1975,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2011,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "COLEMAN",
        "Province": "ALBERTA",
        "Climate ID": "3051720",
        "Station ID": 2381,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -114.58,
        "Latitude": 493800000,
        "Longitude": -1143500000,
        "Elevation (m)": 1341.1,
        "First Year": 1912,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1997,
        "MLY First Year": 1912,
        "MLY Last Year": 1997
      },
      {
        "Name": "COLEMAN RS",
        "Province": "ALBERTA",
        "Climate ID": "3051725",
        "Station ID": 2382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -114.5,
        "Latitude": 493900000,
        "Longitude": -1143000000,
        "Elevation (m)": 1341.1,
        "First Year": 1958,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1971,
        "MLY First Year": 1958,
        "MLY Last Year": 1971
      },
      {
        "Name": "COLUMBIA ICEFIELD",
        "Province": "ALBERTA",
        "Climate ID": "3051740",
        "Station ID": 2383,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -117.17,
        "Latitude": 521400000,
        "Longitude": -1171000000,
        "Elevation (m)": 1981.2,
        "First Year": 1961,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1994,
        "MLY First Year": 1961,
        "MLY Last Year": 1994
      },
      {
        "Name": "COMPRESSION RIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3051766",
        "Station ID": 8914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 505400000,
        "Longitude": -1145500000,
        "Elevation (m)": 1798.3,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "COWLEY TODD CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3051928",
        "Station ID": 2384,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -114.27,
        "Latitude": 494600000,
        "Longitude": -1141600000,
        "Elevation (m)": 1980,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "COX HILL",
        "Province": "ALBERTA",
        "Climate ID": "3051932",
        "Station ID": 8724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -114.95,
        "Latitude": 505900000,
        "Longitude": -1145700000,
        "Elevation (m)": 1674.9,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CROWSNEST CREEK",
        "Province": "ALBERTA",
        "Climate ID": "30519N0",
        "Station ID": 10690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.61,
        "Longitude (Decimal Degrees)": -114.68,
        "Latitude": 493620000,
        "Longitude": -1144040000,
        "Elevation (m)": 1387,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLARESHOLM LYNDON CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3051F56",
        "Station ID": 2372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 500300000,
        "Longitude": -1135500000,
        "Elevation (m)": 1120.1,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "CROWSNEST",
        "Province": "ALBERTA",
        "Climate ID": "3051R4R",
        "Station ID": 10890,
        "WMO ID": 71236,
        "TC ID": "WRT",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -114.48,
        "Latitude": 493739090,
        "Longitude": -1142855020,
        "Elevation (m)": 1303,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "CUTHEAD LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3051R51",
        "Station ID": 8819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -115.77,
        "Latitude": 512700000,
        "Longitude": -1154600000,
        "Elevation (m)": 2179.9,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEVONA",
        "Province": "ALBERTA",
        "Climate ID": "3052069",
        "Station ID": 27713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.17,
        "Longitude (Decimal Degrees)": -118.03,
        "Latitude": 531019000,
        "Longitude": -1180144000,
        "Elevation (m)": 1403.6,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "EISENHOWER JUNCTION",
        "Province": "ALBERTA",
        "Climate ID": "3052255",
        "Station ID": 2385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -115.9,
        "Latitude": 511700000,
        "Longitude": -1155400000,
        "Elevation (m)": 1424.9,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "ELBOW RS",
        "Province": "ALBERTA",
        "Climate ID": "3052270",
        "Station ID": 2386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -114.7,
        "Latitude": 505400000,
        "Longitude": -1144200000,
        "Elevation (m)": 1399.9,
        "First Year": 1958,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2006,
        "MLY First Year": 1958,
        "MLY Last Year": 2006
      },
      {
        "Name": "EXSHAW",
        "Province": "ALBERTA",
        "Climate ID": "3052508",
        "Station ID": 2387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -115.2,
        "Latitude": 510400000,
        "Longitude": -1151200000,
        "Elevation (m)": 1298.4,
        "First Year": 1928,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1945,
        "MLY First Year": 1928,
        "MLY Last Year": 1945
      },
      {
        "Name": "FALLEN TIMBER",
        "Province": "ALBERTA",
        "Climate ID": "3052602",
        "Station ID": 8825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -115.1,
        "Latitude": 513200000,
        "Longitude": -1150600000,
        "Elevation (m)": 1554.5,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "FALSE NICHOLAS",
        "Province": "ALBERTA",
        "Climate ID": "3052603",
        "Station ID": 27650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -116.5,
        "Latitude": 513800000,
        "Longitude": -1163000000,
        "Elevation (m)": 2926.1,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "FORGET ME NOT LO",
        "Province": "ALBERTA",
        "Climate ID": "3052653",
        "Station ID": 2388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.75,
        "Longitude (Decimal Degrees)": -114.75,
        "Latitude": 504500000,
        "Longitude": -1144500000,
        "Elevation (m)": 2316.5,
        "First Year": 1962,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1975,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "FORGET ME NOT MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "30526EL",
        "Station ID": 8824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -114.7,
        "Latitude": 504400000,
        "Longitude": -1144200000,
        "Elevation (m)": 1738.6,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORTRESS MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "3052705",
        "Station ID": 2389,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.85,
        "Longitude (Decimal Degrees)": -115.25,
        "Latitude": 505100000,
        "Longitude": -1151500000,
        "Elevation (m)": 2042.2,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "FURMAN CLARESHOLM",
        "Province": "ALBERTA",
        "Climate ID": "3052745",
        "Station ID": 2390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 495800000,
        "Longitude": -1140000000,
        "Elevation (m)": 1295.4,
        "First Year": 1958,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1965,
        "MLY First Year": 1958,
        "MLY Last Year": 1965
      },
      {
        "Name": "GHOST RS",
        "Province": "ALBERTA",
        "Climate ID": "3052780",
        "Station ID": 2391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -114.93,
        "Latitude": 511800000,
        "Longitude": -1145600000,
        "Elevation (m)": 1417,
        "First Year": 1958,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1996,
        "MLY First Year": 1958,
        "MLY Last Year": 1996
      },
      {
        "Name": "GOAT'S EYE",
        "Province": "ALBERTA",
        "Climate ID": "3052838",
        "Station ID": 27716,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51,
        "Longitude (Decimal Degrees)": -115.5,
        "Latitude": 510000000,
        "Longitude": -1153000000,
        "Elevation (m)": 2200,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 2001,
        "MLY Last Year": 2002
      },
      {
        "Name": "GRAVE FLATS LO",
        "Province": "ALBERTA",
        "Climate ID": "3052940",
        "Station ID": 2392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -117,
        "Latitude": 525100000,
        "Longitude": -1170000000,
        "Elevation (m)": 2073.9,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "HAILSTONE BUTTE LO",
        "Province": "ALBERTA",
        "Climate ID": "3052995",
        "Station ID": 2393,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 501000000,
        "Longitude": -1142700000,
        "Elevation (m)": 2372.9,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "EVANS THOMAS",
        "Province": "ALBERTA",
        "Climate ID": "3052D82",
        "Station ID": 8919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -115.05,
        "Latitude": 504700000,
        "Longitude": -1150300000,
        "Elevation (m)": 2164.1,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "GHOST DIVERSION",
        "Province": "ALBERTA",
        "Climate ID": "3052PP6",
        "Station ID": 8920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -115.13,
        "Latitude": 511800000,
        "Longitude": -1150800000,
        "Elevation (m)": 1600.2,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGHWOOD HOUSE",
        "Province": "ALBERTA",
        "Climate ID": "3053249",
        "Station ID": 2394,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 502300000,
        "Longitude": -1143800000,
        "Elevation (m)": 1493.5,
        "First Year": 1977,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1977,
        "MLY First Year": 1977,
        "MLY Last Year": 1977
      },
      {
        "Name": "HIGHWOOD AU (B1)",
        "Province": "ALBERTA",
        "Climate ID": "3053250",
        "Station ID": 2395,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 503304000,
        "Longitude": -1142214000,
        "Elevation (m)": 1580,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "IRONSTONE LO",
        "Province": "ALBERTA",
        "Climate ID": "3053505",
        "Station ID": 2396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 493400000,
        "Longitude": -1142800000,
        "Elevation (m)": 2072.6,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "JAMES RIVER HEADWATERS",
        "Province": "ALBERTA",
        "Climate ID": "3053512",
        "Station ID": 8830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -115.32,
        "Latitude": 514600000,
        "Longitude": -1151900000,
        "Elevation (m)": 1463,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "JASPER",
        "Province": "ALBERTA",
        "Climate ID": "3053519",
        "Station ID": 2397,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -118.15,
        "Latitude": 525300000,
        "Longitude": -1180900000,
        "Elevation (m)": 1057.4,
        "First Year": 1914,
        "Last Year": 1931,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1931,
        "MLY First Year": 1916,
        "MLY Last Year": 1931
      },
      {
        "Name": "JASPER",
        "Province": "ALBERTA",
        "Climate ID": "3053520",
        "Station ID": 2398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -118.07,
        "Latitude": 525300000,
        "Longitude": -1180400000,
        "Elevation (m)": 1062.2,
        "First Year": 1926,
        "Last Year": 1998,
        "HLY First Year": 1953,
        "HLY Last Year": 1996,
        "DLY First Year": 1926,
        "DLY Last Year": 1998,
        "MLY First Year": 1926,
        "MLY Last Year": 1996
      },
      {
        "Name": "JASPER WARDEN",
        "Province": "ALBERTA",
        "Climate ID": "3053536",
        "Station ID": 10223,
        "WMO ID": 71486,
        "TC ID": "WJW",
        "Latitude (Decimal Degrees)": 52.93,
        "Longitude (Decimal Degrees)": -118.03,
        "Latitude": 525535000,
        "Longitude": -1180147000,
        "Elevation (m)": 1020,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "JASPER WEST GATE",
        "Province": "ALBERTA",
        "Climate ID": "3053538",
        "Station ID": 2399,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -118.38,
        "Latitude": 525200000,
        "Longitude": -1182300000,
        "Elevation (m)": 1127.8,
        "First Year": 1974,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2000,
        "MLY First Year": 1974,
        "MLY Last Year": 2000
      },
      {
        "Name": "JOB CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3053552",
        "Station ID": 27655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -116.78,
        "Latitude": 522100000,
        "Longitude": -1164700000,
        "Elevation (m)": 2005,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "JUNCTION LO",
        "Province": "ALBERTA",
        "Climate ID": "3053575",
        "Station ID": 2400,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -114.68,
        "Latitude": 503300000,
        "Longitude": -1144100000,
        "Elevation (m)": 2238.8,
        "First Year": 1963,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2011,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "KAKWA NOTUS",
        "Province": "ALBERTA",
        "Climate ID": "3053587",
        "Station ID": 2401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.2,
        "Longitude (Decimal Degrees)": -119.88,
        "Latitude": 541200000,
        "Longitude": -1195300000,
        "Elevation (m)": 1347.2,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "KANANASKIS",
        "Province": "ALBERTA",
        "Climate ID": "3053600",
        "Station ID": 2402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -115.03,
        "Latitude": 510139080,
        "Longitude": -1150205060,
        "Elevation (m)": 1391.1,
        "First Year": 1939,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 2022,
        "MLY First Year": 1939,
        "MLY Last Year": 2007
      },
      {
        "Name": "KANANASKIS BOUNDARY RS",
        "Province": "ALBERTA",
        "Climate ID": "3053602",
        "Station ID": 2403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 505900000,
        "Longitude": -1150700000,
        "Elevation (m)": 1463,
        "First Year": 1962,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1986,
        "MLY First Year": 1962,
        "MLY Last Year": 1986
      },
      {
        "Name": "KANANASKIS POCATERRA",
        "Province": "ALBERTA",
        "Climate ID": "3053604",
        "Station ID": 2404,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.71,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 504245020,
        "Longitude": -1150712060,
        "Elevation (m)": 1610,
        "First Year": 1976,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2018,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "KANANASKIS LO",
        "Province": "ALBERTA",
        "Climate ID": "3053605",
        "Station ID": 2405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 503700000,
        "Longitude": -1150400000,
        "Elevation (m)": 2072.6,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "KISKA LO",
        "Province": "ALBERTA",
        "Climate ID": "3053649",
        "Station ID": 2406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -116.18,
        "Latitude": 521800000,
        "Longitude": -1161100000,
        "Elevation (m)": 2072.6,
        "First Year": 1966,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1984,
        "MLY First Year": 1966,
        "MLY Last Year": 1984
      },
      {
        "Name": "KOOTENAY PLAINS",
        "Province": "ALBERTA",
        "Climate ID": "3053668",
        "Station ID": 2407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 521100000,
        "Longitude": -1162800000,
        "Elevation (m)": 1359.4,
        "First Year": 1980,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2007,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "KOOTENAY PLAINS R4",
        "Province": "ALBERTA",
        "Climate ID": "3053669",
        "Station ID": 27739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -116.42,
        "Latitude": 520400000,
        "Longitude": -1162500000,
        "Elevation (m)": 1294,
        "First Year": 1998,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2003,
        "MLY First Year": 1998,
        "MLY Last Year": 2001
      },
      {
        "Name": "KOOTENAY PLAINS RS",
        "Province": "ALBERTA",
        "Climate ID": "3053670",
        "Station ID": 2408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 521100000,
        "Longitude": -1162900000,
        "Elevation (m)": 1219.2,
        "First Year": 1977,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1980,
        "MLY First Year": 1977,
        "MLY Last Year": 1980
      },
      {
        "Name": "LAKE LOUISE",
        "Province": "ALBERTA",
        "Climate ID": "3053760",
        "Station ID": 2409,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -116.22,
        "Latitude": 512600000,
        "Longitude": -1161300000,
        "Elevation (m)": 1524,
        "First Year": 1915,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 2007,
        "MLY First Year": 1915,
        "MLY Last Year": 2007
      },
      {
        "Name": "LIMESTONE RIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3053908",
        "Station ID": 8834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -115.38,
        "Latitude": 515400000,
        "Longitude": -1152300000,
        "Elevation (m)": 1950.7,
        "First Year": 1998,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2005,
        "MLY First Year": 1998,
        "MLY Last Year": 2005
      },
      {
        "Name": "LIVINGSTONE",
        "Province": "ALBERTA",
        "Climate ID": "3053925",
        "Station ID": 2410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -114.38,
        "Latitude": 495300000,
        "Longitude": -1142300000,
        "Elevation (m)": 1417,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "LIVINGSTONE LO",
        "Province": "ALBERTA",
        "Climate ID": "3053926",
        "Station ID": 2411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -114.35,
        "Latitude": 495400000,
        "Longitude": -1142100000,
        "Elevation (m)": 2169.9,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "LONGVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3053985",
        "Station ID": 2412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 502200000,
        "Longitude": -1143800000,
        "Elevation (m)": 1219.2,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "LOST CREEK SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3053988",
        "Station ID": 8837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -114.7,
        "Latitude": 501000000,
        "Longitude": -1144200000,
        "Elevation (m)": 2129.9,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "LUNDBRECK",
        "Province": "ALBERTA",
        "Climate ID": "3054080",
        "Station ID": 2413,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -114.13,
        "Latitude": 495500000,
        "Longitude": -1140800000,
        "Elevation (m)": 1194.2,
        "First Year": 1911,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1958,
        "MLY First Year": 1911,
        "MLY Last Year": 1958
      },
      {
        "Name": "LYNDON",
        "Province": "ALBERTA",
        "Climate ID": "3054120",
        "Station ID": 2414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -113.88,
        "Latitude": 500100000,
        "Longitude": -1135300000,
        "Elevation (m)": 1249.7,
        "First Year": 1910,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1966,
        "MLY First Year": 1910,
        "MLY Last Year": 1966
      },
      {
        "Name": "MARMOT TRIPLE",
        "Province": "ALBERTA",
        "Climate ID": "3054215",
        "Station ID": 27656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.7,
        "Longitude (Decimal Degrees)": -118.12,
        "Latitude": 524200000,
        "Longitude": -1180700000,
        "Elevation (m)": 2290,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARMOT BASIN",
        "Province": "ALBERTA",
        "Climate ID": "305421B",
        "Station ID": 8917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.68,
        "Longitude (Decimal Degrees)": -118.1,
        "Latitude": 524100000,
        "Longitude": -1180600000,
        "Elevation (m)": 2072,
        "First Year": 1996,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2007,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAYCROFT",
        "Province": "ALBERTA",
        "Climate ID": "3054250",
        "Station ID": 2415,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -114.25,
        "Latitude": 495000000,
        "Longitude": -1141500000,
        "Elevation (m)": 1371.6,
        "First Year": 1911,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1939,
        "MLY First Year": 1911,
        "MLY Last Year": 1939
      },
      {
        "Name": "MEDICINE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3054487",
        "Station ID": 27651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 524800000,
        "Longitude": -1174800000,
        "Elevation (m)": 2200,
        "First Year": 1998,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOCKINGBIRD LO",
        "Province": "ALBERTA",
        "Climate ID": "3054592",
        "Station ID": 2416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -115.08,
        "Latitude": 512600000,
        "Longitude": -1150500000,
        "Elevation (m)": 1905,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOOSE MTN LO",
        "Province": "ALBERTA",
        "Climate ID": "3054655",
        "Station ID": 2417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -114.83,
        "Latitude": 505500000,
        "Longitude": -1145000000,
        "Elevation (m)": 2433.8,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOUNTAIN AIRE LODGE",
        "Province": "ALBERTA",
        "Climate ID": "3054700",
        "Station ID": 2418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -115.28,
        "Latitude": 513900000,
        "Longitude": -1151700000,
        "Elevation (m)": 1371.6,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "MOUNTAIN VIEW BIRDSEYE",
        "Province": "ALBERTA",
        "Climate ID": "3054722",
        "Station ID": 2419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -113.73,
        "Latitude": 490700000,
        "Longitude": -1134400000,
        "Elevation (m)": 1408.2,
        "First Year": 1916,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1981,
        "MLY First Year": 1916,
        "MLY Last Year": 1981
      },
      {
        "Name": "MOUNT EISENHOWER",
        "Province": "ALBERTA",
        "Climate ID": "3054730",
        "Station ID": 2420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.25,
        "Longitude (Decimal Degrees)": -115.95,
        "Latitude": 511500000,
        "Longitude": -1155700000,
        "Elevation (m)": 1424.9,
        "First Year": 1960,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1971,
        "MLY First Year": 1960,
        "MLY Last Year": 1971
      },
      {
        "Name": "MOUNT KIDD LO",
        "Province": "ALBERTA",
        "Climate ID": "3054732",
        "Station ID": 2421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -115.17,
        "Latitude": 505500000,
        "Longitude": -1151000000,
        "Elevation (m)": 2141,
        "First Year": 1987,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1992,
        "MLY First Year": 1987,
        "MLY Last Year": 1992
      },
      {
        "Name": "MOUNT ODLUM",
        "Province": "ALBERTA",
        "Climate ID": "3054733",
        "Station ID": 9015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -114.9,
        "Latitude": 502900000,
        "Longitude": -1145400000,
        "Elevation (m)": 2060,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "MT RUNDLE EAST",
        "Province": "ALBERTA",
        "Climate ID": "3054735",
        "Station ID": 46928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.12,
        "Longitude (Decimal Degrees)": -115.47,
        "Latitude": 510700000,
        "Longitude": -1152800000,
        "Elevation (m)": 2750,
        "First Year": 2007,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORDEGG",
        "Province": "ALBERTA",
        "Climate ID": "3054840",
        "Station ID": 2422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -116.08,
        "Latitude": 522800000,
        "Longitude": -1160500000,
        "Elevation (m)": 1402.1,
        "First Year": 1915,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1955,
        "MLY First Year": 1915,
        "MLY Last Year": 1955
      },
      {
        "Name": "NORDEGG CS",
        "Province": "ALBERTA",
        "Climate ID": "3054843",
        "Station ID": 30543,
        "WMO ID": 71060,
        "TC ID": "XND",
        "Latitude (Decimal Degrees)": 52.49,
        "Longitude (Decimal Degrees)": -116.04,
        "Latitude": 522931090,
        "Longitude": -1160224000,
        "Elevation (m)": 1332,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORDEGG RS",
        "Province": "ALBERTA",
        "Climate ID": "3054845",
        "Station ID": 2423,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.5,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 523000000,
        "Longitude": -1160300000,
        "Elevation (m)": 1320.1,
        "First Year": 1958,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2006,
        "MLY First Year": 1958,
        "MLY Last Year": 2006
      },
      {
        "Name": "PARKER RIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3054997",
        "Station ID": 2424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -117.12,
        "Latitude": 521200000,
        "Longitude": -1170700000,
        "Elevation (m)": 1981.2,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "PARKER RIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3054998",
        "Station ID": 2425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -117.13,
        "Latitude": 521200000,
        "Longitude": -1170800000,
        "Elevation (m)": 2023,
        "First Year": 1978,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2007,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "PASQUE",
        "Province": "ALBERTA",
        "Climate ID": "3054R99",
        "Station ID": 8838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -114.62,
        "Latitude": 500600000,
        "Longitude": -1143700000,
        "Elevation (m)": 1767.8,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "PECTEN",
        "Province": "ALBERTA",
        "Climate ID": "3055090",
        "Station ID": 2426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 491800000,
        "Longitude": -1140200000,
        "Elevation (m)": 1432.6,
        "First Year": 1979,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1979,
        "MLY First Year": 1979,
        "MLY Last Year": 1979
      },
      {
        "Name": "PEKISKO",
        "Province": "ALBERTA",
        "Climate ID": "3055119",
        "Station ID": 10665,
        "WMO ID": 71669,
        "TC ID": "NPK",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -114.42,
        "Latitude": 502206007,
        "Longitude": -1142456001,
        "Elevation (m)": 1415,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEKISKO",
        "Province": "ALBERTA",
        "Climate ID": "3055120",
        "Station ID": 2427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -114.42,
        "Latitude": 502200000,
        "Longitude": -1142500000,
        "Elevation (m)": 1439,
        "First Year": 1905,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1992,
        "MLY First Year": 1905,
        "MLY Last Year": 1992
      },
      {
        "Name": "PIGEON LO",
        "Province": "ALBERTA",
        "Climate ID": "3055175",
        "Station ID": 2428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 510300000,
        "Longitude": -1150400000,
        "Elevation (m)": 1828.8,
        "First Year": 1960,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1996,
        "MLY First Year": 1960,
        "MLY Last Year": 1996
      },
      {
        "Name": "POLL HAVEN AFS",
        "Province": "ALBERTA",
        "Climate ID": "3055237",
        "Station ID": 27030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -113.6,
        "Latitude": 490141000,
        "Longitude": -1133607000,
        "Elevation (m)": 1615,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORCUPINE LO",
        "Province": "ALBERTA",
        "Climate ID": "3055289",
        "Station ID": 2429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -114.02,
        "Latitude": 495400000,
        "Longitude": -1140100000,
        "Elevation (m)": 1783.1,
        "First Year": 1971,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2011,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORCUPINE RS",
        "Province": "ALBERTA",
        "Climate ID": "3055291",
        "Station ID": 2430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -114.12,
        "Latitude": 495800000,
        "Longitude": -1140700000,
        "Elevation (m)": 1424.9,
        "First Year": 1968,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1971,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "RACEHORSE CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3055343",
        "Station ID": 8853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 494900000,
        "Longitude": -1143800000,
        "Elevation (m)": 1920.2,
        "First Year": 1999,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2005,
        "MLY First Year": 1999,
        "MLY Last Year": 2005
      },
      {
        "Name": "RAM FALLS",
        "Province": "ALBERTA",
        "Climate ID": "3055379",
        "Station ID": 2431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -115.85,
        "Latitude": 520500000,
        "Longitude": -1155100000,
        "Elevation (m)": 1641,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "RASPBERRY LO",
        "Province": "ALBERTA",
        "Climate ID": "3055410",
        "Station ID": 2432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -114.52,
        "Latitude": 501800000,
        "Longitude": -1143100000,
        "Elevation (m)": 2362.2,
        "First Year": 1963,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2011,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED DEER RS",
        "Province": "ALBERTA",
        "Climate ID": "3055485",
        "Station ID": 2433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -115.22,
        "Latitude": 514000000,
        "Longitude": -1151300000,
        "Elevation (m)": 1450.8,
        "First Year": 1958,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1972,
        "MLY First Year": 1958,
        "MLY Last Year": 1972
      },
      {
        "Name": "SASKATCHEWAN R CROSSING",
        "Province": "ALBERTA",
        "Climate ID": "3055752",
        "Station ID": 2434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -116.58,
        "Latitude": 515200000,
        "Longitude": -1163500000,
        "Elevation (m)": null,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "SASK RIVER CROSSING 2",
        "Province": "ALBERTA",
        "Climate ID": "3055754",
        "Station ID": 8855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -116.72,
        "Latitude": 515800000,
        "Longitude": -1164300000,
        "Elevation (m)": 1392.3,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "SCALP CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3055761",
        "Station ID": 8856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -115.65,
        "Latitude": 514800000,
        "Longitude": -1153900000,
        "Elevation (m)": 2042.2,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "SCOTCH CAMP",
        "Province": "ALBERTA",
        "Climate ID": "3055768",
        "Station ID": 8857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -115.8,
        "Latitude": 514000000,
        "Longitude": -1154800000,
        "Elevation (m)": 1737.4,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHEEP RS",
        "Province": "ALBERTA",
        "Climate ID": "3055883",
        "Station ID": 2435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -114.65,
        "Latitude": 503900000,
        "Longitude": -1143900000,
        "Elevation (m)": 1493.5,
        "First Year": 1963,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1976,
        "MLY First Year": 1963,
        "MLY Last Year": 1975
      },
      {
        "Name": "SHUNDA RS",
        "Province": "ALBERTA",
        "Climate ID": "3055890",
        "Station ID": 2436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.48,
        "Longitude (Decimal Degrees)": -115.77,
        "Latitude": 522900000,
        "Longitude": -1154600000,
        "Elevation (m)": 1432.6,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1967
      },
      {
        "Name": "SKOKI",
        "Province": "ALBERTA",
        "Climate ID": "3055976",
        "Station ID": 8858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 513200000,
        "Longitude": -1160300000,
        "Elevation (m)": 2040,
        "First Year": 1998,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2005,
        "MLY First Year": 1998,
        "MLY Last Year": 2005
      },
      {
        "Name": "SKYLINE RS",
        "Province": "ALBERTA",
        "Climate ID": "3055980",
        "Station ID": 2437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -113.97,
        "Latitude": 495300000,
        "Longitude": -1135800000,
        "Elevation (m)": 1661.2,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1967
      },
      {
        "Name": "SHEEP RIVER 2",
        "Province": "ALBERTA",
        "Climate ID": "3055H8C",
        "Station ID": 8851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -114.62,
        "Latitude": 503900000,
        "Longitude": -1143700000,
        "Elevation (m)": 1524,
        "First Year": 1998,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2006,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOUTH ESK",
        "Province": "ALBERTA",
        "Climate ID": "3056055",
        "Station ID": 42163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.67,
        "Longitude (Decimal Degrees)": -117.21,
        "Latitude": 524018000,
        "Longitude": -1171228000,
        "Elevation (m)": 2186,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPIONKOP CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3056067",
        "Station ID": 8859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -114.08,
        "Latitude": 491300000,
        "Longitude": -1140500000,
        "Elevation (m)": 1860.5,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST. MARY RESERVOIR",
        "Province": "ALBERTA",
        "Climate ID": "3056100",
        "Station ID": 45727,
        "WMO ID": 71837,
        "TC ID": "PMR",
        "Latitude (Decimal Degrees)": 49.36,
        "Longitude (Decimal Degrees)": -113.11,
        "Latitude": 492141000,
        "Longitude": -1130636000,
        "Elevation (m)": 1128,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "STREETER",
        "Province": "ALBERTA",
        "Climate ID": "3056212",
        "Station ID": 8915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -114.07,
        "Latitude": 500700000,
        "Longitude": -1140400000,
        "Elevation (m)": 1402.1,
        "First Year": 1998,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2006,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "WAIPAROUS",
        "Province": "ALBERTA",
        "Climate ID": "3056213",
        "Station ID": 31168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -114.84,
        "Latitude": 511658070,
        "Longitude": -1145041040,
        "Elevation (m)": 1283,
        "First Year": 2001,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2004,
        "MLY First Year": 2001,
        "MLY Last Year": 2004
      },
      {
        "Name": "WATERTON PARK GATE",
        "Province": "ALBERTA",
        "Climate ID": "3056214",
        "Station ID": 26850,
        "WMO ID": 71154,
        "TC ID": "WGM",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -113.81,
        "Latitude": 490752080,
        "Longitude": -1134831010,
        "Elevation (m)": 1289,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUGARLOAF LO",
        "Province": "ALBERTA",
        "Climate ID": "3056250",
        "Station ID": 2438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -114.53,
        "Latitude": 495700000,
        "Longitude": -1143200000,
        "Elevation (m)": 2514,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUNSHINE CS",
        "Province": "ALBERTA",
        "Climate ID": "3056267",
        "Station ID": 27455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 510400000,
        "Longitude": -1154700000,
        "Elevation (m)": 2186.9,
        "First Year": 1997,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2007,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUNWAPTA",
        "Province": "ALBERTA",
        "Climate ID": "3056272",
        "Station ID": 2439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -117.45,
        "Latitude": 522700000,
        "Longitude": -1172700000,
        "Elevation (m)": 1554.5,
        "First Year": 1979,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2004,
        "MLY First Year": 1979,
        "MLY Last Year": 2004
      },
      {
        "Name": "SUNWAPTA",
        "Province": "ALBERTA",
        "Climate ID": "3056273",
        "Station ID": 30310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.45,
        "Longitude (Decimal Degrees)": -117.44,
        "Latitude": 522708000,
        "Longitude": -1172638000,
        "Elevation (m)": 1554.5,
        "First Year": 2001,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "THREE ISLE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3056489",
        "Station ID": 8866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -115.27,
        "Latitude": 503900000,
        "Longitude": -1151600000,
        "Elevation (m)": 2225,
        "First Year": 1998,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORRENS LO",
        "Province": "ALBERTA",
        "Climate ID": "3056510",
        "Station ID": 2440,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -119.67,
        "Latitude": 541800040,
        "Longitude": -1194000056,
        "Elevation (m)": 1815,
        "First Year": 1963,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2011,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "TROUT CREEK WEST",
        "Province": "ALBERTA",
        "Climate ID": "3056560",
        "Station ID": 2441,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -113.97,
        "Latitude": 495700000,
        "Longitude": -1135800000,
        "Elevation (m)": 1264.9,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "UPR PARKER RIDGE",
        "Province": "ALBERTA",
        "Climate ID": "3056657",
        "Station ID": 27652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -117.08,
        "Latitude": 521100000,
        "Longitude": -1170500000,
        "Elevation (m)": 2316.5,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UPPER SASKATCHEWAN RS",
        "Province": "ALBERTA",
        "Climate ID": "3056660",
        "Station ID": 2442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -116.45,
        "Latitude": 521100000,
        "Longitude": -1162700000,
        "Elevation (m)": 1298.4,
        "First Year": 1959,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1965,
        "MLY First Year": 1959,
        "MLY Last Year": 1965
      },
      {
        "Name": "TURTLE MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "30566M2",
        "Station ID": 10200,
        "WMO ID": null,
        "TC ID": "WQA",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -114.42,
        "Latitude": 493500000,
        "Longitude": -1142500000,
        "Elevation (m)": 2164,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WATERTON DAM",
        "Province": "ALBERTA",
        "Climate ID": "3056984",
        "Station ID": 27657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -113.68,
        "Latitude": 491912060,
        "Longitude": -1134104000,
        "Elevation (m)": 1189,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "WATERTON LAKES BAKER",
        "Province": "ALBERTA",
        "Climate ID": "3056993",
        "Station ID": 2443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 490300000,
        "Longitude": -1135400000,
        "Elevation (m)": 1280.8,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "TEMPLE",
        "Province": "ALBERTA",
        "Climate ID": "3056LPA",
        "Station ID": 9436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -116.15,
        "Latitude": 512600000,
        "Longitude": -1160900000,
        "Elevation (m)": 1891,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2007,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "WATERTON LAKES BELLY R",
        "Province": "ALBERTA",
        "Climate ID": "3057000",
        "Station ID": 2444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -113.7,
        "Latitude": 490300000,
        "Longitude": -1134200000,
        "Elevation (m)": 1371.6,
        "First Year": 1905,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1967,
        "MLY First Year": 1940,
        "MLY Last Year": 1967
      },
      {
        "Name": "WATERTON LAKES CAMERON L",
        "Province": "ALBERTA",
        "Climate ID": "3057040",
        "Station ID": 2445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 490200000,
        "Longitude": -1140200000,
        "Elevation (m)": 1691.6,
        "First Year": 1940,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1955,
        "MLY First Year": 1940,
        "MLY Last Year": 1955
      },
      {
        "Name": "WATERTON LAKES CEDAR CAB",
        "Province": "ALBERTA",
        "Climate ID": "3057060",
        "Station ID": 2446,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 490500000,
        "Longitude": -1135200000,
        "Elevation (m)": 1280.2,
        "First Year": 1940,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1949,
        "MLY First Year": 1940,
        "MLY Last Year": 1949
      },
      {
        "Name": "WATERTON LAKES PASS CRK",
        "Province": "ALBERTA",
        "Climate ID": "3057080",
        "Station ID": 2447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -113.93,
        "Latitude": 490600000,
        "Longitude": -1135600000,
        "Elevation (m)": 1371.6,
        "First Year": 1944,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1955,
        "MLY First Year": 1944,
        "MLY Last Year": 1955
      },
      {
        "Name": "WATERTON LAKES RED ROCK",
        "Province": "ALBERTA",
        "Climate ID": "3057120",
        "Station ID": 2448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -114.02,
        "Latitude": 490800000,
        "Longitude": -1140100000,
        "Elevation (m)": 1497.5,
        "First Year": 1940,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1967,
        "MLY First Year": 1940,
        "MLY Last Year": 1967
      },
      {
        "Name": "WATERTON PARK HQ",
        "Province": "ALBERTA",
        "Climate ID": "3057240",
        "Station ID": 2449,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 490300000,
        "Longitude": -1135500000,
        "Elevation (m)": 1280.2,
        "First Year": 1944,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1973,
        "MLY First Year": 1944,
        "MLY Last Year": 1973
      },
      {
        "Name": "WATERTON RED ROCK",
        "Province": "ALBERTA",
        "Climate ID": "3057242",
        "Station ID": 8872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 490800000,
        "Longitude": -1140200000,
        "Elevation (m)": 1524,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "WATERTON RIVER CABIN",
        "Province": "ALBERTA",
        "Climate ID": "3057243",
        "Station ID": 2450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -113.83,
        "Latitude": 490700000,
        "Longitude": -1135000000,
        "Elevation (m)": 1281,
        "First Year": 1949,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1995,
        "MLY First Year": 1949,
        "MLY Last Year": 1995
      },
      {
        "Name": "WHISTLER LO",
        "Province": "ALBERTA",
        "Climate ID": "3057368",
        "Station ID": 2451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -114.3,
        "Latitude": 492000000,
        "Longitude": -1141800000,
        "Elevation (m)": 2162.9,
        "First Year": 1967,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1975,
        "MLY First Year": 1967,
        "MLY Last Year": 1975
      },
      {
        "Name": "WHITECOURT A",
        "Province": "ALBERTA",
        "Climate ID": "3057376",
        "Station ID": 52982,
        "WMO ID": null,
        "TC ID": "YZU",
        "Latitude (Decimal Degrees)": 54.14,
        "Longitude (Decimal Degrees)": -115.79,
        "Latitude": 540838000,
        "Longitude": -1154712000,
        "Elevation (m)": 782.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WILLOW CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3057570",
        "Station ID": 2452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -114.35,
        "Latitude": 501500000,
        "Longitude": -1142100000,
        "Elevation (m)": 1478,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "YA HA TINDA  AFS",
        "Province": "ALBERTA",
        "Climate ID": "3057673",
        "Station ID": 27029,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -115.36,
        "Latitude": 513917000,
        "Longitude": -1152145000,
        "Elevation (m)": 1479,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "WESTCASTLE",
        "Province": "ALBERTA",
        "Climate ID": "3057K55",
        "Station ID": 8918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 491700000,
        "Longitude": -1142200000,
        "Elevation (m)": 1524,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOW SUMMIT (PC)",
        "Province": "ALBERTA",
        "Climate ID": "305A002",
        "Station ID": 27653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 514300000,
        "Longitude": -1162900000,
        "Elevation (m)": 2011.7,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "LOOKOUT",
        "Province": "ALBERTA",
        "Climate ID": "305A003",
        "Station ID": 27654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -115.75,
        "Latitude": 510400000,
        "Longitude": -1154500000,
        "Elevation (m)": 2621,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 2002,
        "MLY Last Year": 2002
      },
      {
        "Name": "DAVID THOMPSON RESORT",
        "Province": "ALBERTA",
        "Climate ID": "305A9N4",
        "Station ID": 2359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 521100000,
        "Longitude": -1162800000,
        "Elevation (m)": 1359.4,
        "First Year": 1975,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1975,
        "MLY First Year": 1975,
        "MLY Last Year": 1975
      },
      {
        "Name": "GARDINER CREEK",
        "Province": "ALBERTA",
        "Climate ID": "305B769",
        "Station ID": 8916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -114.52,
        "Latitude": 492200000,
        "Longitude": -1143100000,
        "Elevation (m)": 1919.9,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "THREE ISLE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "305FMQQ",
        "Station ID": 9610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -115.28,
        "Latitude": 503753000,
        "Longitude": -1151646000,
        "Elevation (m)": 2240,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICARY CREEK",
        "Province": "ALBERTA",
        "Climate ID": "305FQKP",
        "Station ID": 9608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -114.47,
        "Latitude": 494811000,
        "Longitude": -1142823000,
        "Elevation (m)": 1606,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLARESHOLM TROUT CREEK",
        "Province": "ALBERTA",
        "Climate ID": "305J65G",
        "Station ID": 2360,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 495800000,
        "Longitude": -1135200000,
        "Elevation (m)": 1164.3,
        "First Year": 1966,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1976,
        "MLY First Year": 1966,
        "MLY Last Year": 1976
      },
      {
        "Name": "LIVINGSTONE AIRFIELD",
        "Province": "ALBERTA",
        "Climate ID": "305L9K4",
        "Station ID": 10697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -114.43,
        "Latitude": 500300000,
        "Longitude": -1142540000,
        "Elevation (m)": 1615,
        "First Year": 1998,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2005,
        "MLY First Year": 1998,
        "MLY Last Year": 2005
      },
      {
        "Name": "LITTLE ELBOW SUMMIT",
        "Province": "ALBERTA",
        "Climate ID": "305LRKB",
        "Station ID": 9609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.71,
        "Longitude (Decimal Degrees)": -114.99,
        "Latitude": 504219000,
        "Longitude": -1145921000,
        "Elevation (m)": 2120,
        "First Year": 1998,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2006,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "NAKISKA RIDGETOP",
        "Province": "ALBERTA",
        "Climate ID": "305MGFF",
        "Station ID": 10104,
        "WMO ID": 71245,
        "TC ID": "WNR",
        "Latitude (Decimal Degrees)": 50.94,
        "Longitude (Decimal Degrees)": -115.19,
        "Latitude": 505633000,
        "Longitude": -1151125000,
        "Elevation (m)": 2543,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2006
      },
      {
        "Name": "MIETTE HOT SPRINGS",
        "Province": "ALBERTA",
        "Climate ID": "305MNKM",
        "Station ID": 2361,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.13,
        "Longitude (Decimal Degrees)": -117.77,
        "Latitude": 530800000,
        "Longitude": -1174600000,
        "Elevation (m)": 1463.4,
        "First Year": 1986,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1995,
        "MLY First Year": 1986,
        "MLY Last Year": 1995
      },
      {
        "Name": "PELLETIER CREEK",
        "Province": "ALBERTA",
        "Climate ID": "305NJ61",
        "Station ID": 10691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -114.48,
        "Latitude": 494025000,
        "Longitude": -1142853000,
        "Elevation (m)": 1646,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "WILLOUGHBY RIDGE",
        "Province": "ALBERTA",
        "Climate ID": "305PE6R",
        "Station ID": 10693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -114.5,
        "Latitude": 493245000,
        "Longitude": -1142948000,
        "Elevation (m)": 1783,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "ADAMS CREEK LO",
        "Province": "ALBERTA",
        "Climate ID": "3060045",
        "Station ID": 2460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -118.57,
        "Latitude": 534300000,
        "Longitude": -1183400000,
        "Elevation (m)": 2209.8,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALGAR LO",
        "Province": "ALBERTA",
        "Climate ID": "3060110",
        "Station ID": 2461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -111.78,
        "Latitude": 560700000,
        "Longitude": -1114700000,
        "Elevation (m)": 780.3,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "ANSELL LO",
        "Province": "ALBERTA",
        "Climate ID": "3060235",
        "Station ID": 2462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -116.5,
        "Latitude": 533300000,
        "Longitude": -1163000000,
        "Elevation (m)": 968,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "ANSELMO",
        "Province": "ALBERTA",
        "Climate ID": "3060237",
        "Station ID": 2463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -115.42,
        "Latitude": 535800000,
        "Longitude": -1152500000,
        "Elevation (m)": 853.4,
        "First Year": 1990,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2003,
        "MLY First Year": 1990,
        "MLY Last Year": 2003
      },
      {
        "Name": "ANZAC",
        "Province": "ALBERTA",
        "Climate ID": "3060280",
        "Station ID": 2464,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.45,
        "Longitude (Decimal Degrees)": -111.05,
        "Latitude": 562700000,
        "Longitude": -1110300000,
        "Elevation (m)": 487.7,
        "First Year": 1950,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1957,
        "MLY First Year": 1950,
        "MLY Last Year": 1957
      },
      {
        "Name": "ANZAC",
        "Province": "ALBERTA",
        "Climate ID": "3060281",
        "Station ID": 2465,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.45,
        "Longitude (Decimal Degrees)": -111.03,
        "Latitude": 562700000,
        "Longitude": -1110200000,
        "Elevation (m)": 495.3,
        "First Year": 1972,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1979,
        "MLY First Year": 1972,
        "MLY Last Year": 1979
      },
      {
        "Name": "ATHABASCA",
        "Province": "ALBERTA",
        "Climate ID": "3060320",
        "Station ID": 2466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.72,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 544300000,
        "Longitude": -1131700000,
        "Elevation (m)": 515,
        "First Year": 1918,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1967,
        "MLY First Year": 1918,
        "MLY Last Year": 1967
      },
      {
        "Name": "ATHABASCA 2",
        "Province": "ALBERTA",
        "Climate ID": "3060321",
        "Station ID": 2467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -113.54,
        "Latitude": 544918007,
        "Longitude": -1133223007,
        "Elevation (m)": 626.3,
        "First Year": 1952,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2010,
        "MLY First Year": 1952,
        "MLY Last Year": 2007
      },
      {
        "Name": "ATHABASCA 3",
        "Province": "ALBERTA",
        "Climate ID": "3060322",
        "Station ID": 2468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.72,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 544300000,
        "Longitude": -1131700000,
        "Elevation (m)": 526.7,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "ATHABASCA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3060330",
        "Station ID": 47047,
        "WMO ID": 71271,
        "TC ID": "PAF",
        "Latitude (Decimal Degrees)": 54.63,
        "Longitude (Decimal Degrees)": -113.38,
        "Latitude": 543805003,
        "Longitude": -1132255008,
        "Elevation (m)": 635,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ATHABASCA EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3060337",
        "Station ID": 2469,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.7,
        "Longitude (Decimal Degrees)": -113.38,
        "Latitude": 544200000,
        "Longitude": -1132300000,
        "Elevation (m)": 579.1,
        "First Year": 1952,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1957,
        "MLY First Year": 1952,
        "MLY Last Year": 1957
      },
      {
        "Name": "ATHABASCA LANDING",
        "Province": "ALBERTA",
        "Climate ID": "3060352",
        "Station ID": 2470,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.72,
        "Longitude (Decimal Degrees)": -113.28,
        "Latitude": 544300000,
        "Longitude": -1131700000,
        "Elevation (m)": 502.9,
        "First Year": 1900,
        "Last Year": 1927,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 1927,
        "MLY First Year": 1900,
        "MLY Last Year": 1927
      },
      {
        "Name": "ATHABASCA LO",
        "Province": "ALBERTA",
        "Climate ID": "3060353",
        "Station ID": 2471,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -117.78,
        "Latitude": 532500000,
        "Longitude": -1174700000,
        "Elevation (m)": 1630.7,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "ATMORE",
        "Province": "ALBERTA",
        "Climate ID": "3060403",
        "Station ID": 2472,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -112.55,
        "Latitude": 544900000,
        "Longitude": -1123300000,
        "Elevation (m)": 609.6,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ATMORE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3060406",
        "Station ID": 32231,
        "WMO ID": 71287,
        "TC ID": "XAJ",
        "Latitude (Decimal Degrees)": 54.78,
        "Longitude (Decimal Degrees)": -112.83,
        "Latitude": 544654000,
        "Longitude": -1124930000,
        "Elevation (m)": 574,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1992,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "BAIRD LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3060472",
        "Station ID": 2473,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.35,
        "Longitude (Decimal Degrees)": -114.43,
        "Latitude": 542100000,
        "Longitude": -1142600000,
        "Elevation (m)": 670.6,
        "First Year": 1981,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1997,
        "MLY First Year": 1981,
        "MLY Last Year": 1997
      },
      {
        "Name": "BALM",
        "Province": "ALBERTA",
        "Climate ID": "3060495",
        "Station ID": 2474,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -115.25,
        "Latitude": 535500000,
        "Longitude": -1151500000,
        "Elevation (m)": 777.2,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "BANANA BELT",
        "Province": "ALBERTA",
        "Climate ID": "3060514",
        "Station ID": 2475,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.2,
        "Longitude (Decimal Degrees)": -116.42,
        "Latitude": 551200000,
        "Longitude": -1162500000,
        "Elevation (m)": 670.6,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "BAPTISTE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3060523",
        "Station ID": 2476,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -113.53,
        "Latitude": 544400000,
        "Longitude": -1133200000,
        "Elevation (m)": 575,
        "First Year": 1971,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2000,
        "MLY First Year": 1971,
        "MLY Last Year": 2000
      },
      {
        "Name": "BARRHEAD",
        "Province": "ALBERTA",
        "Climate ID": "3060532",
        "Station ID": 2477,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -114.37,
        "Latitude": 540700000,
        "Longitude": -1142200000,
        "Elevation (m)": 644,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "BARRHEAD CS",
        "Province": "ALBERTA",
        "Climate ID": "3060535",
        "Station ID": 30641,
        "WMO ID": 71061,
        "TC ID": "XBD",
        "Latitude (Decimal Degrees)": 54.09,
        "Longitude (Decimal Degrees)": -114.45,
        "Latitude": 540541000,
        "Longitude": -1142651000,
        "Elevation (m)": 648,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAVER LAKE RS",
        "Province": "ALBERTA",
        "Climate ID": "3060559",
        "Station ID": 2478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.75,
        "Longitude (Decimal Degrees)": -112.95,
        "Latitude": 544500000,
        "Longitude": -1125700000,
        "Elevation (m)": 563.9,
        "First Year": 1980,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1995,
        "MLY First Year": 1980,
        "MLY Last Year": 1995
      },
      {
        "Name": "BERLAND LO",
        "Province": "ALBERTA",
        "Climate ID": "3060630",
        "Station ID": 2479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.08,
        "Longitude (Decimal Degrees)": -117.4,
        "Latitude": 540500000,
        "Longitude": -1172400000,
        "Elevation (m)": 1231,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "BERNY",
        "Province": "ALBERTA",
        "Climate ID": "3060633",
        "Station ID": 2480,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.75,
        "Longitude (Decimal Degrees)": -112.35,
        "Latitude": 544500000,
        "Longitude": -1122100000,
        "Elevation (m)": 579.1,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "BIRCH MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3060700",
        "Station ID": 2481,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.72,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 574300000,
        "Longitude": -1115100000,
        "Elevation (m)": 853.4,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "BITUMONT LO",
        "Province": "ALBERTA",
        "Climate ID": "3060705",
        "Station ID": 2482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.37,
        "Longitude (Decimal Degrees)": -111.53,
        "Latitude": 572200000,
        "Longitude": -1113200000,
        "Elevation (m)": 348.7,
        "First Year": 1962,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2008,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOVINE CREEK AFS",
        "Province": "ALBERTA",
        "Climate ID": "3060756",
        "Station ID": 27037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.11,
        "Longitude (Decimal Degrees)": -112.55,
        "Latitude": 560628000,
        "Longitude": -1123258000,
        "Elevation (m)": 518,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOYLE",
        "Province": "ALBERTA",
        "Climate ID": "3060780",
        "Station ID": 2483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.57,
        "Longitude (Decimal Degrees)": -112.87,
        "Latitude": 543400000,
        "Longitude": -1125200000,
        "Elevation (m)": 670.6,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "BRIGHTWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3060832",
        "Station ID": 2484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -114.93,
        "Latitude": 533000000,
        "Longitude": -1145600000,
        "Elevation (m)": 792.5,
        "First Year": 1965,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1973,
        "MLY First Year": 1965,
        "MLY Last Year": 1973
      },
      {
        "Name": "BRULE BLACK CAT",
        "Province": "ALBERTA",
        "Climate ID": "3060903",
        "Station ID": 2485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -117.87,
        "Latitude": 532100000,
        "Longitude": -1175200000,
        "Elevation (m)": 1036.3,
        "First Year": 1985,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2022,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "BUCKTON LO",
        "Province": "ALBERTA",
        "Climate ID": "3060922",
        "Station ID": 2486,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.87,
        "Longitude (Decimal Degrees)": -112.1,
        "Latitude": 575200000,
        "Longitude": -1120600000,
        "Elevation (m)": 792.5,
        "First Year": 1965,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2010,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "ATHABASCA 1",
        "Province": "ALBERTA",
        "Climate ID": "3060L20",
        "Station ID": 2459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.72,
        "Longitude (Decimal Degrees)": -113.29,
        "Latitude": 544320003,
        "Longitude": -1131717007,
        "Elevation (m)": 514.7,
        "First Year": 1986,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2020,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "CALLING LAKE RS",
        "Province": "ALBERTA",
        "Climate ID": "3061117",
        "Station ID": 2489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.25,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 551500000,
        "Longitude": -1131100000,
        "Elevation (m)": 598,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMPSIE",
        "Province": "ALBERTA",
        "Climate ID": "3061200",
        "Station ID": 2490,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -114.68,
        "Latitude": 540756002,
        "Longitude": -1144040004,
        "Elevation (m)": 670.6,
        "First Year": 1910,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 2013,
        "MLY First Year": 1910,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMPSIE AUTO",
        "Province": "ALBERTA",
        "Climate ID": "3061201",
        "Station ID": 51899,
        "WMO ID": 71636,
        "TC ID": "PZC",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -114.68,
        "Latitude": 540750041,
        "Longitude": -1144039055,
        "Elevation (m)": 647,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARROT CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3061350",
        "Station ID": 53918,
        "WMO ID": null,
        "TC ID": "PCF",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -115.89,
        "Latitude": 533723030,
        "Longitude": -1155337070,
        "Elevation (m)": 861,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARROT CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3061355",
        "Station ID": 2491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -115.8,
        "Latitude": 533700000,
        "Longitude": -1154800000,
        "Elevation (m)": 853.1,
        "First Year": 1980,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1987,
        "MLY First Year": 1980,
        "MLY Last Year": 1987
      },
      {
        "Name": "CARROT CREEK LO",
        "Province": "ALBERTA",
        "Climate ID": "3061360",
        "Station ID": 2492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -115.87,
        "Latitude": 532700000,
        "Longitude": -1155200000,
        "Elevation (m)": 1043.9,
        "First Year": 1939,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 2011,
        "MLY First Year": 1939,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHISHOLM LO",
        "Province": "ALBERTA",
        "Climate ID": "3061570",
        "Station ID": 2493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.93,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 545600000,
        "Longitude": -1140200000,
        "Elevation (m)": 676.7,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHISHOLM MILLS",
        "Province": "ALBERTA",
        "Climate ID": "3061571",
        "Station ID": 2494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.92,
        "Longitude (Decimal Degrees)": -114.2,
        "Latitude": 545500000,
        "Longitude": -1141200000,
        "Elevation (m)": 579.1,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "CHRISTINA LO",
        "Province": "ALBERTA",
        "Climate ID": "3061580",
        "Station ID": 2495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.58,
        "Longitude (Decimal Degrees)": -111.85,
        "Latitude": 553500000,
        "Longitude": -1115100000,
        "Elevation (m)": 823,
        "First Year": 1966,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2002,
        "MLY First Year": 1967,
        "MLY Last Year": 2002
      },
      {
        "Name": "CHRISTINA LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3061600",
        "Station ID": 46927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.63,
        "Longitude (Decimal Degrees)": -110.83,
        "Latitude": 553800000,
        "Longitude": -1105000000,
        "Elevation (m)": 370,
        "First Year": 2007,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2007,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "COALSPUR",
        "Province": "ALBERTA",
        "Climate ID": "3061674",
        "Station ID": 2496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.18,
        "Longitude (Decimal Degrees)": -117,
        "Latitude": 531100000,
        "Longitude": -1170000000,
        "Elevation (m)": 1173.5,
        "First Year": 1913,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1938,
        "MLY First Year": 1913,
        "MLY Last Year": 1938
      },
      {
        "Name": "COAL VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "3061675",
        "Station ID": 2497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.08,
        "Longitude (Decimal Degrees)": -116.82,
        "Latitude": 530500000,
        "Longitude": -1164900000,
        "Elevation (m)": 1405.7,
        "First Year": 1980,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1986,
        "MLY First Year": 1980,
        "MLY Last Year": 1986
      },
      {
        "Name": "COLD CREEK RS",
        "Province": "ALBERTA",
        "Climate ID": "3061679",
        "Station ID": 2498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -115.58,
        "Latitude": 533700000,
        "Longitude": -1153500000,
        "Elevation (m)": 792.5,
        "First Year": 1962,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1990,
        "MLY First Year": 1962,
        "MLY Last Year": 1990
      },
      {
        "Name": "COLINTON",
        "Province": "ALBERTA",
        "Climate ID": "3061728",
        "Station ID": 6859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.63,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 543800000,
        "Longitude": -1131300000,
        "Elevation (m)": 602.3,
        "First Year": 1992,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2005,
        "MLY First Year": 1992,
        "MLY Last Year": 2005
      },
      {
        "Name": "CONKLIN",
        "Province": "ALBERTA",
        "Climate ID": "3061795",
        "Station ID": 2499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.68,
        "Longitude (Decimal Degrees)": -111.1,
        "Latitude": 554100000,
        "Longitude": -1110600000,
        "Elevation (m)": 541,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "CONKLIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3061800",
        "Station ID": 2500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.62,
        "Longitude (Decimal Degrees)": -111.18,
        "Latitude": 553700000,
        "Longitude": -1111100000,
        "Elevation (m)": 670.6,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "COWPAR LO",
        "Province": "ALBERTA",
        "Climate ID": "3061930",
        "Station ID": 2502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.83,
        "Longitude (Decimal Degrees)": -110.38,
        "Latitude": 555000000,
        "Longitude": -1102300000,
        "Elevation (m)": 563.3,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAPP AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3061975",
        "Station ID": 31414,
        "WMO ID": 71340,
        "TC ID": "XDP",
        "Latitude (Decimal Degrees)": 54.32,
        "Longitude (Decimal Degrees)": -113.95,
        "Latitude": 541900000,
        "Longitude": -1135700000,
        "Elevation (m)": 614.2,
        "First Year": 1990,
        "Last Year": 2021,
        "HLY First Year": 1990,
        "HLY Last Year": 2021,
        "DLY First Year": 2001,
        "DLY Last Year": 2021,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "CROSS LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3.06E+04",
        "Station ID": 2501,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.63,
        "Longitude (Decimal Degrees)": -113.91,
        "Latitude": 543758009,
        "Longitude": -1135449005,
        "Elevation (m)": 655.3,
        "First Year": 1973,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2008,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "CROSS LAKE RS",
        "Province": "ALBERTA",
        "Climate ID": "3061R50",
        "Station ID": 2487,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.67,
        "Longitude (Decimal Degrees)": -113.82,
        "Latitude": 544000000,
        "Longitude": -1134900000,
        "Elevation (m)": 670.6,
        "First Year": 1978,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1986,
        "MLY First Year": 1978,
        "MLY Last Year": 1986
      },
      {
        "Name": "DAPP",
        "Province": "ALBERTA",
        "Climate ID": "3061R54",
        "Station ID": 2488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.35,
        "Longitude (Decimal Degrees)": -113.92,
        "Latitude": 542100000,
        "Longitude": -1135500000,
        "Elevation (m)": 614.2,
        "First Year": 1969,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "DEER MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3062020",
        "Station ID": 2503,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.92,
        "Longitude (Decimal Degrees)": -115.15,
        "Latitude": 545500000,
        "Longitude": -1150900000,
        "Elevation (m)": 1121.7,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "DUNKIRK L3",
        "Province": "ALBERTA",
        "Climate ID": "3062146",
        "Station ID": 27740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.77,
        "Longitude (Decimal Degrees)": -112.48,
        "Latitude": 564600000,
        "Longitude": -1122900000,
        "Elevation (m)": 488,
        "First Year": 2003,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2004,
        "MLY First Year": 2003,
        "MLY Last Year": 2003
      },
      {
        "Name": "EAGLE LO",
        "Province": "ALBERTA",
        "Climate ID": "3062150",
        "Station ID": 2504,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.47,
        "Longitude (Decimal Degrees)": -116.42,
        "Latitude": 542800000,
        "Longitude": -1162500000,
        "Elevation (m)": 1042.4,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDSON",
        "Province": "ALBERTA",
        "Climate ID": "3062239",
        "Station ID": 48948,
        "WMO ID": 71167,
        "TC ID": "YET",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 533444000,
        "Longitude": -1162754000,
        "Elevation (m)": 926.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDSON",
        "Province": "ALBERTA",
        "Climate ID": "3062240",
        "Station ID": 2505,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -116.42,
        "Latitude": 533500000,
        "Longitude": -1162500000,
        "Elevation (m)": 922.6,
        "First Year": 1914,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1960,
        "MLY First Year": 1914,
        "MLY Last Year": 1960
      },
      {
        "Name": "EDSON",
        "Province": "ALBERTA",
        "Climate ID": "3062241",
        "Station ID": 2506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -116.42,
        "Latitude": 533500000,
        "Longitude": -1162500000,
        "Elevation (m)": 924.5,
        "First Year": 1960,
        "Last Year": 1970,
        "HLY First Year": 1960,
        "HLY Last Year": 1970,
        "DLY First Year": 1960,
        "DLY Last Year": 1970,
        "MLY First Year": 1960,
        "MLY Last Year": 1970
      },
      {
        "Name": "EDSON A",
        "Province": "ALBERTA",
        "Climate ID": "3062242",
        "Station ID": 10793,
        "WMO ID": 71881,
        "TC ID": "YET",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 533542000,
        "Longitude": -1162851000,
        "Elevation (m)": 925.4,
        "First Year": 1992,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": 1992,
        "DLY Last Year": 2007,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDSON A",
        "Province": "ALBERTA",
        "Climate ID": "3062244",
        "Station ID": 2507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 533500000,
        "Longitude": -1162800000,
        "Elevation (m)": 927.2,
        "First Year": 1970,
        "Last Year": 1999,
        "HLY First Year": 1970,
        "HLY Last Year": 1999,
        "DLY First Year": 1970,
        "DLY Last Year": 1998,
        "MLY First Year": 1970,
        "MLY Last Year": 1998
      },
      {
        "Name": "EDSON AWOS A",
        "Province": "ALBERTA",
        "Climate ID": "3062245",
        "Station ID": 31588,
        "WMO ID": 71881,
        "TC ID": "PDS",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 533444000,
        "Longitude": -1162754000,
        "Elevation (m)": 926.9,
        "First Year": 2006,
        "Last Year": 2011,
        "HLY First Year": 2006,
        "HLY Last Year": 2011,
        "DLY First Year": 2006,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EDSON CLIMATE",
        "Province": "ALBERTA",
        "Climate ID": "3062246",
        "Station ID": 27213,
        "WMO ID": 71156,
        "TC ID": "ZZJ",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -116.45,
        "Latitude": 533449007,
        "Longitude": -1162712007,
        "Elevation (m)": 927,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELLS LO",
        "Province": "ALBERTA",
        "Climate ID": "3062300",
        "Station ID": 2508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.18,
        "Longitude (Decimal Degrees)": -112.33,
        "Latitude": 571100000,
        "Longitude": -1122000000,
        "Elevation (m)": 573,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "EMBARRAS A",
        "Province": "ALBERTA",
        "Climate ID": "3062360",
        "Station ID": 2509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.2,
        "Longitude (Decimal Degrees)": -111.38,
        "Latitude": 581200000,
        "Longitude": -1112300000,
        "Elevation (m)": 236.2,
        "First Year": 1943,
        "Last Year": 1962,
        "HLY First Year": 1953,
        "HLY Last Year": 1962,
        "DLY First Year": 1943,
        "DLY Last Year": 1962,
        "MLY First Year": 1943,
        "MLY Last Year": 1962
      },
      {
        "Name": "ENILDA-BERG",
        "Province": "ALBERTA",
        "Climate ID": "3062427",
        "Station ID": 27333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.42,
        "Longitude (Decimal Degrees)": -116.3,
        "Latitude": 552500000,
        "Longitude": -1161800000,
        "Elevation (m)": 590.6,
        "First Year": 1997,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2005,
        "MLY First Year": 1997,
        "MLY Last Year": 2005
      },
      {
        "Name": "ENILDA LO",
        "Province": "ALBERTA",
        "Climate ID": "3062430",
        "Station ID": 2510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.32,
        "Longitude (Decimal Degrees)": -116.2,
        "Latitude": 551900000,
        "Longitude": -1161200000,
        "Elevation (m)": 731.5,
        "First Year": 1970,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2011,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "ENTRANCE",
        "Province": "ALBERTA",
        "Climate ID": "3062440",
        "Station ID": 2511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 532200000,
        "Longitude": -1174200000,
        "Elevation (m)": 990.6,
        "First Year": 1917,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 2006,
        "MLY First Year": 1917,
        "MLY Last Year": 2006
      },
      {
        "Name": "ENTRANCE RS",
        "Province": "ALBERTA",
        "Climate ID": "3062445",
        "Station ID": 2512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -117.72,
        "Latitude": 532200000,
        "Longitude": -1174300000,
        "Elevation (m)": 983,
        "First Year": 1968,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1971,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "ENTWISTLE",
        "Province": "ALBERTA",
        "Climate ID": "3062451",
        "Station ID": 2513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -114.98,
        "Latitude": 533600000,
        "Longitude": -1145900000,
        "Elevation (m)": 780.3,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "EVANSBURG AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3062475",
        "Station ID": 47072,
        "WMO ID": 71481,
        "TC ID": "PEB",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 533423007,
        "Longitude": -1150725001,
        "Elevation (m)": 819,
        "First Year": 2008,
        "Last Year": 2016,
        "HLY First Year": 2008,
        "HLY Last Year": 2015,
        "DLY First Year": 2008,
        "DLY Last Year": 2016,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EVANSBURG2 AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3062476",
        "Station ID": 54358,
        "WMO ID": 71481,
        "TC ID": "PNB",
        "Latitude (Decimal Degrees)": 53.61,
        "Longitude (Decimal Degrees)": -115.06,
        "Latitude": 533650000,
        "Longitude": -1150344089,
        "Elevation (m)": 785,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EVANSBURG CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3062480",
        "Station ID": 2514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -115,
        "Latitude": 533800000,
        "Longitude": -1150000000,
        "Elevation (m)": 792.5,
        "First Year": 1954,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1954,
        "MLY Last Year": 1964
      },
      {
        "Name": "FLATTOP LO",
        "Province": "ALBERTA",
        "Climate ID": "3062630",
        "Station ID": 2515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.15,
        "Longitude (Decimal Degrees)": -114.8,
        "Latitude": 550900000,
        "Longitude": -1144800000,
        "Elevation (m)": 1030.2,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT ASSINIBOINE RS",
        "Province": "ALBERTA",
        "Climate ID": "3062654",
        "Station ID": 2516,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.35,
        "Longitude (Decimal Degrees)": -114.8,
        "Latitude": 542100000,
        "Longitude": -1144800000,
        "Elevation (m)": 609.6,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1965
      },
      {
        "Name": "FORT ASSINIBOINE RS",
        "Province": "ALBERTA",
        "Climate ID": "3062655",
        "Station ID": 2517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.37,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 542200000,
        "Longitude": -1145500000,
        "Elevation (m)": 670,
        "First Year": 1983,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2008,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT ASSINIBOINE 2",
        "Province": "ALBERTA",
        "Climate ID": "3062656",
        "Station ID": 27331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.35,
        "Longitude (Decimal Degrees)": -114.83,
        "Latitude": 542100000,
        "Longitude": -1145000000,
        "Elevation (m)": 627.3,
        "First Year": 1997,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2008,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT ASSINIBOINE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3062660",
        "Station ID": 47128,
        "WMO ID": 71483,
        "TC ID": "PFB",
        "Latitude (Decimal Degrees)": 54.41,
        "Longitude (Decimal Degrees)": -114.77,
        "Latitude": 542437005,
        "Longitude": -1144609005,
        "Elevation (m)": 676,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT MACKAY RS",
        "Province": "ALBERTA",
        "Climate ID": "3062675",
        "Station ID": 2518,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.18,
        "Longitude (Decimal Degrees)": -111.62,
        "Latitude": 571100000,
        "Longitude": -1113700000,
        "Elevation (m)": 234.7,
        "First Year": 1964,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1994,
        "MLY First Year": 1964,
        "MLY Last Year": 1994
      },
      {
        "Name": "FORT MCMURRAY A",
        "Province": "ALBERTA",
        "Climate ID": "3062693",
        "Station ID": 2519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.65,
        "Longitude (Decimal Degrees)": -111.22,
        "Latitude": 563900000,
        "Longitude": -1111300000,
        "Elevation (m)": 369.1,
        "First Year": 1944,
        "Last Year": 2008,
        "HLY First Year": 1953,
        "HLY Last Year": 2008,
        "DLY First Year": 1944,
        "DLY Last Year": 2008,
        "MLY First Year": 1944,
        "MLY Last Year": 2007
      },
      {
        "Name": "FT MCMURRAY",
        "Province": "ALBERTA",
        "Climate ID": "3062695",
        "Station ID": 2520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.73,
        "Longitude (Decimal Degrees)": -111.38,
        "Latitude": 564400000,
        "Longitude": -1112300000,
        "Elevation (m)": 252.7,
        "First Year": 1908,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1944,
        "MLY First Year": 1908,
        "MLY Last Year": 1944
      },
      {
        "Name": "FORT MCMURRAY CS",
        "Province": "ALBERTA",
        "Climate ID": "3062696",
        "Station ID": 27216,
        "WMO ID": 71585,
        "TC ID": "XMM",
        "Latitude (Decimal Degrees)": 56.65,
        "Longitude (Decimal Degrees)": -111.21,
        "Latitude": 563904000,
        "Longitude": -1111248000,
        "Elevation (m)": 368.8,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT MCMURRAY A",
        "Province": "ALBERTA",
        "Climate ID": "3062697",
        "Station ID": 49490,
        "WMO ID": 71689,
        "TC ID": "YMM",
        "Latitude (Decimal Degrees)": 56.65,
        "Longitude (Decimal Degrees)": -111.22,
        "Latitude": 563912000,
        "Longitude": -1111324000,
        "Elevation (m)": 369.1,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT MCMURRAY AWOS A",
        "Province": "ALBERTA",
        "Climate ID": "3062700",
        "Station ID": 31288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.65,
        "Longitude (Decimal Degrees)": -111.22,
        "Latitude": 563912000,
        "Longitude": -1111324000,
        "Elevation (m)": 369.1,
        "First Year": 2008,
        "Last Year": 2011,
        "HLY First Year": 2008,
        "HLY Last Year": 2011,
        "DLY First Year": 2008,
        "DLY Last Year": 2011,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOOSE MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3062880",
        "Station ID": 2521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.75,
        "Longitude (Decimal Degrees)": -116.07,
        "Latitude": 544500000,
        "Longitude": -1160400000,
        "Elevation (m)": 1402.1,
        "First Year": 1945,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 2011,
        "MLY First Year": 1945,
        "MLY Last Year": 2007
      },
      {
        "Name": "GORDON LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3062889",
        "Station ID": 2522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.62,
        "Longitude (Decimal Degrees)": -110.5,
        "Latitude": 563703000,
        "Longitude": -1103000000,
        "Elevation (m)": 515,
        "First Year": 1964,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2011,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRANDE LO",
        "Province": "ALBERTA",
        "Climate ID": "3062905",
        "Station ID": 2523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.3,
        "Longitude (Decimal Degrees)": -112.22,
        "Latitude": 561800000,
        "Longitude": -1121300000,
        "Elevation (m)": 533.4,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "GROSMONT",
        "Province": "ALBERTA",
        "Climate ID": "3062957",
        "Station ID": 2524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.87,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 545200000,
        "Longitude": -1133400000,
        "Elevation (m)": 640.1,
        "First Year": 1964,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1975,
        "MLY First Year": 1964,
        "MLY Last Year": 1975
      },
      {
        "Name": "GROUARD",
        "Province": "ALBERTA",
        "Climate ID": "3062965",
        "Station ID": 2525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.58,
        "Longitude (Decimal Degrees)": -116.15,
        "Latitude": 553500000,
        "Longitude": -1160900000,
        "Elevation (m)": 579.1,
        "First Year": 1909,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 1945,
        "MLY First Year": 1909,
        "MLY Last Year": 1945
      },
      {
        "Name": "GROUARD",
        "Province": "ALBERTA",
        "Climate ID": "3062966",
        "Station ID": 2526,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.57,
        "Longitude (Decimal Degrees)": -116.15,
        "Latitude": 553400000,
        "Longitude": -1160900000,
        "Elevation (m)": 609.6,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "GROUND ZERO AFS",
        "Province": "ALBERTA",
        "Climate ID": "3062968",
        "Station ID": 27038,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.02,
        "Longitude (Decimal Degrees)": -110.62,
        "Latitude": 550102000,
        "Longitude": -1103708000,
        "Elevation (m)": 737,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "HAY RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3063075",
        "Station ID": 2527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 533700000,
        "Longitude": -1174200000,
        "Elevation (m)": 1158.2,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "HEART LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3063120",
        "Station ID": 2528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55,
        "Longitude (Decimal Degrees)": -111.33,
        "Latitude": 550000000,
        "Longitude": -1112000000,
        "Elevation (m)": 887,
        "First Year": 1947,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 2011,
        "MLY First Year": 1947,
        "MLY Last Year": 2007
      },
      {
        "Name": "HELDAR",
        "Province": "ALBERTA",
        "Climate ID": "3063130",
        "Station ID": 2529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -115,
        "Latitude": 540100000,
        "Longitude": -1150000000,
        "Elevation (m)": 701,
        "First Year": 1923,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1944,
        "MLY First Year": 1923,
        "MLY Last Year": 1944
      },
      {
        "Name": "HENDRICKSON CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3063135",
        "Station ID": 26870,
        "WMO ID": 71979,
        "TC ID": "WPA",
        "Latitude (Decimal Degrees)": 53.8,
        "Longitude (Decimal Degrees)": -118.45,
        "Latitude": 534742001,
        "Longitude": -1182648005,
        "Elevation (m)": 1448,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGH PRAIRIE",
        "Province": "ALBERTA",
        "Climate ID": "3063160",
        "Station ID": 2530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.43,
        "Longitude (Decimal Degrees)": -116.52,
        "Latitude": 552600000,
        "Longitude": -1163100000,
        "Elevation (m)": 594.4,
        "First Year": 1926,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1977,
        "MLY First Year": 1926,
        "MLY Last Year": 1977
      },
      {
        "Name": "HIGH PRAIRIE A",
        "Province": "ALBERTA",
        "Climate ID": "3063162",
        "Station ID": 2531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.4,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 552400000,
        "Longitude": -1162900000,
        "Elevation (m)": 601.7,
        "First Year": 1980,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1995,
        "MLY First Year": 1980,
        "MLY Last Year": 1995
      },
      {
        "Name": "HIGH PRAIRIE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3063165",
        "Station ID": 32274,
        "WMO ID": 71226,
        "TC ID": "XHP",
        "Latitude (Decimal Degrees)": 55.4,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 552345001,
        "Longitude": -1162846008,
        "Elevation (m)": 601.7,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGH PRAIRIE CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3063176",
        "Station ID": 2532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.43,
        "Longitude (Decimal Degrees)": -116.47,
        "Latitude": 552600000,
        "Longitude": -1162800000,
        "Elevation (m)": 599.8,
        "First Year": 1953,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1962,
        "MLY First Year": 1953,
        "MLY Last Year": 1962
      },
      {
        "Name": "HIGH PRAIRIE NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3063178",
        "Station ID": 44266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.49,
        "Longitude (Decimal Degrees)": -116.46,
        "Latitude": 552924000,
        "Longitude": -1162729001,
        "Elevation (m)": 591,
        "First Year": 2005,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2011,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGH PRAIRIE RS",
        "Province": "ALBERTA",
        "Climate ID": "3063180",
        "Station ID": 2533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.43,
        "Longitude (Decimal Degrees)": -116.5,
        "Latitude": 552600000,
        "Longitude": -1163000000,
        "Elevation (m)": 596.8,
        "First Year": 1968,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1970,
        "MLY First Year": 1968,
        "MLY Last Year": 1970
      },
      {
        "Name": "HIGHWAY",
        "Province": "ALBERTA",
        "Climate ID": "3063248",
        "Station ID": 2534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -115.53,
        "Latitude": 535500000,
        "Longitude": -1153200000,
        "Elevation (m)": 823,
        "First Year": 1969,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1999,
        "MLY First Year": 1969,
        "MLY Last Year": 1999
      },
      {
        "Name": "HINTON",
        "Province": "ALBERTA",
        "Climate ID": "3063340",
        "Station ID": 2535,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -117.57,
        "Latitude": 532500000,
        "Longitude": -1173400000,
        "Elevation (m)": 1013.5,
        "First Year": 1956,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1976,
        "MLY First Year": 1956,
        "MLY Last Year": 1976
      },
      {
        "Name": "HINTON FTS",
        "Province": "ALBERTA",
        "Climate ID": "3063342",
        "Station ID": 2536,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.4,
        "Longitude (Decimal Degrees)": -117.58,
        "Latitude": 532400000,
        "Longitude": -1173500000,
        "Elevation (m)": 1021.1,
        "First Year": 1964,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1980,
        "MLY First Year": 1964,
        "MLY Last Year": 1980
      },
      {
        "Name": "HOUSE MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3063347",
        "Station ID": 2537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.03,
        "Longitude (Decimal Degrees)": -115.62,
        "Latitude": 550200000,
        "Longitude": -1153700000,
        "Elevation (m)": 1152.1,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUCKLEBERRY LO",
        "Province": "ALBERTA",
        "Climate ID": "3063354",
        "Station ID": 2538,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -118.18,
        "Latitude": 535900000,
        "Longitude": -1181100000,
        "Elevation (m)": 1428.9,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "IMPERIAL LO",
        "Province": "ALBERTA",
        "Climate ID": "3063433",
        "Station ID": 2539,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.47,
        "Longitude (Decimal Degrees)": -115.57,
        "Latitude": 542800000,
        "Longitude": -1153400000,
        "Elevation (m)": 1188.7,
        "First Year": 1975,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2011,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "JARVIE",
        "Province": "ALBERTA",
        "Climate ID": "3063516",
        "Station ID": 2540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.47,
        "Longitude (Decimal Degrees)": -113.98,
        "Latitude": 542800000,
        "Longitude": -1135900000,
        "Elevation (m)": 612.6,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "JARVIE HAMLET",
        "Province": "ALBERTA",
        "Climate ID": "3063517",
        "Station ID": 2541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.45,
        "Longitude (Decimal Degrees)": -113.98,
        "Latitude": 542700000,
        "Longitude": -1135900000,
        "Elevation (m)": 613,
        "First Year": 1980,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1992,
        "MLY First Year": 1980,
        "MLY Last Year": 1992
      },
      {
        "Name": "JARVIS LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3063518",
        "Station ID": 27016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -117.82,
        "Latitude": 532700000,
        "Longitude": -1174900000,
        "Elevation (m)": 1158,
        "First Year": 1995,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2001,
        "MLY First Year": 1995,
        "MLY Last Year": 2001
      },
      {
        "Name": "JASPER EAST GATE",
        "Province": "ALBERTA",
        "Climate ID": "3063523",
        "Station ID": 2542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -117.82,
        "Latitude": 531400000,
        "Longitude": -1174900000,
        "Elevation (m)": 1002.8,
        "First Year": 1971,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2008,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "JASPER-HINTON",
        "Province": "ALBERTA",
        "Climate ID": "3063526",
        "Station ID": 2543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.32,
        "Longitude (Decimal Degrees)": -117.75,
        "Latitude": 531900000,
        "Longitude": -1174500000,
        "Elevation (m)": 1227.1,
        "First Year": 1972,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2007,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "JOHNSON LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3063563",
        "Station ID": 2544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.58,
        "Longitude (Decimal Degrees)": -110.33,
        "Latitude": 573500000,
        "Longitude": -1102000000,
        "Elevation (m)": 548.6,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "ISLAND LAKE SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3063600",
        "Station ID": 53358,
        "WMO ID": 71654,
        "TC ID": "PII",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -113.54,
        "Latitude": 544915059,
        "Longitude": -1133236096,
        "Elevation (m)": 618.7,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KAYBOB 3",
        "Province": "ALBERTA",
        "Climate ID": "3063620",
        "Station ID": 2545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.11,
        "Longitude (Decimal Degrees)": -116.63,
        "Latitude": 540650000,
        "Longitude": -1163752002,
        "Elevation (m)": 1002.8,
        "First Year": 1973,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2011,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEANE LO",
        "Province": "ALBERTA",
        "Climate ID": "3063630",
        "Station ID": 2546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.32,
        "Longitude (Decimal Degrees)": -110.28,
        "Latitude": 581900000,
        "Longitude": -1101700000,
        "Elevation (m)": 457.2,
        "First Year": 1961,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2004,
        "MLY First Year": 1961,
        "MLY Last Year": 2003
      },
      {
        "Name": "KINIKINIK",
        "Province": "ALBERTA",
        "Climate ID": "3063640",
        "Station ID": 53999,
        "WMO ID": null,
        "TC ID": "PKK",
        "Latitude (Decimal Degrees)": 54.56,
        "Longitude (Decimal Degrees)": -112.98,
        "Latitude": 543347070,
        "Longitude": -1125852020,
        "Elevation (m)": 629,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINUSO RS",
        "Province": "ALBERTA",
        "Climate ID": "3063646",
        "Station ID": 2547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -115.42,
        "Latitude": 552000000,
        "Longitude": -1152500000,
        "Elevation (m)": 589.8,
        "First Year": 1927,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1996,
        "MLY First Year": 1927,
        "MLY Last Year": 1996
      },
      {
        "Name": "LAC LA BICHE",
        "Province": "ALBERTA",
        "Climate ID": "3063680",
        "Station ID": 2548,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -111.97,
        "Latitude": 544600000,
        "Longitude": -1115800000,
        "Elevation (m)": 559.3,
        "First Year": 1944,
        "Last Year": 1959,
        "HLY First Year": 1953,
        "HLY Last Year": 1958,
        "DLY First Year": 1944,
        "DLY Last Year": 1959,
        "MLY First Year": 1944,
        "MLY Last Year": 1959
      },
      {
        "Name": "LAC LA BICHE A",
        "Province": "ALBERTA",
        "Climate ID": "3063684",
        "Station ID": 2549,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -112.02,
        "Latitude": 544600000,
        "Longitude": -1120100000,
        "Elevation (m)": 565.1,
        "First Year": 1972,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1981,
        "MLY First Year": 1972,
        "MLY Last Year": 1981
      },
      {
        "Name": "LAC LA BICHE (AUT)",
        "Province": "ALBERTA",
        "Climate ID": "3063685",
        "Station ID": 2550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -112.02,
        "Latitude": 544600000,
        "Longitude": -1120100000,
        "Elevation (m)": 567,
        "First Year": 1958,
        "Last Year": 2004,
        "HLY First Year": 1958,
        "HLY Last Year": 2004,
        "DLY First Year": 1958,
        "DLY Last Year": 2003,
        "MLY First Year": 1958,
        "MLY Last Year": 2003
      },
      {
        "Name": "LAC LA BICHE CLIMATE",
        "Province": "ALBERTA",
        "Climate ID": "3063686",
        "Station ID": 30726,
        "WMO ID": 71316,
        "TC ID": "WLB",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -112.02,
        "Latitude": 544608000,
        "Longitude": -1120125000,
        "Elevation (m)": 567,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAC LA BICHE RS",
        "Province": "ALBERTA",
        "Climate ID": "3063690",
        "Station ID": 2551,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -112.97,
        "Latitude": 544600000,
        "Longitude": -1125800000,
        "Elevation (m)": 562.4,
        "First Year": 1972,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1978,
        "MLY First Year": 1972,
        "MLY Last Year": 1978
      },
      {
        "Name": "LINARIA",
        "Province": "ALBERTA",
        "Climate ID": "3063910",
        "Station ID": 2552,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -114.17,
        "Latitude": 541800000,
        "Longitude": -1141000000,
        "Elevation (m)": 640.1,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "LIVOCK LO",
        "Province": "ALBERTA",
        "Climate ID": "3063930",
        "Station ID": 2553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.47,
        "Longitude (Decimal Degrees)": -113.18,
        "Latitude": 562800000,
        "Longitude": -1131100000,
        "Elevation (m)": 579.1,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "LODGEPOLE RS",
        "Province": "ALBERTA",
        "Climate ID": "3063977",
        "Station ID": 2554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.12,
        "Longitude (Decimal Degrees)": -115.32,
        "Latitude": 530700000,
        "Longitude": -1151900000,
        "Elevation (m)": 914.4,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "LONE PINE",
        "Province": "ALBERTA",
        "Climate ID": "3063981",
        "Station ID": 2555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 541700000,
        "Longitude": -1150700000,
        "Elevation (m)": 731.5,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "LITTLE PADDLE HEADWATERS",
        "Province": "ALBERTA",
        "Climate ID": "3063R2L",
        "Station ID": 8884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -115.5,
        "Latitude": 540100000,
        "Longitude": -1153000000,
        "Elevation (m)": 885.1,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "LOVETT LO",
        "Province": "ALBERTA",
        "Climate ID": "3064040",
        "Station ID": 2556,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.08,
        "Longitude (Decimal Degrees)": -116.68,
        "Latitude": 530500000,
        "Longitude": -1164100000,
        "Elevation (m)": 1444.8,
        "First Year": 1939,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 2011,
        "MLY First Year": 1939,
        "MLY Last Year": 2007
      },
      {
        "Name": "LUNNFORD",
        "Province": "ALBERTA",
        "Climate ID": "3064085",
        "Station ID": 2557,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.07,
        "Longitude (Decimal Degrees)": -114.32,
        "Latitude": 540400000,
        "Longitude": -1141900000,
        "Elevation (m)": 640.1,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "MAGNOLIA",
        "Province": "ALBERTA",
        "Climate ID": "3064157",
        "Station ID": 2558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -114.88,
        "Latitude": 533500000,
        "Longitude": -1145300000,
        "Elevation (m)": 755,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "MANOLA",
        "Province": "ALBERTA",
        "Climate ID": "3064190",
        "Station ID": 2559,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.12,
        "Longitude (Decimal Degrees)": -114.25,
        "Latitude": 540700000,
        "Longitude": -1141500000,
        "Elevation (m)": 640.1,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "MARIANA LAKES",
        "Province": "ALBERTA",
        "Climate ID": "3064209",
        "Station ID": 2560,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.95,
        "Longitude (Decimal Degrees)": -112.03,
        "Latitude": 555700000,
        "Longitude": -1120200000,
        "Elevation (m)": 692.5,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "MARTEN HILLS AFS",
        "Province": "ALBERTA",
        "Climate ID": "3064219",
        "Station ID": 27262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.54,
        "Longitude (Decimal Degrees)": -114.56,
        "Latitude": 553235000,
        "Longitude": -1143325000,
        "Elevation (m)": 1000,
        "First Year": 1996,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2011,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARTEN MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3064220",
        "Station ID": 2561,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.5,
        "Longitude (Decimal Degrees)": -114.7,
        "Latitude": 553000000,
        "Longitude": -1144200000,
        "Elevation (m)": 1021.1,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAYBERNE LO",
        "Province": "ALBERTA",
        "Climate ID": "3064240",
        "Station ID": 2562,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -116.67,
        "Latitude": 535200000,
        "Longitude": -1164000000,
        "Elevation (m)": 1490.5,
        "First Year": 1939,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 2011,
        "MLY First Year": 1939,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAYERTHORPE",
        "Province": "ALBERTA",
        "Climate ID": "3064282",
        "Station ID": 2563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 535800000,
        "Longitude": -1150700000,
        "Elevation (m)": 713.2,
        "First Year": 1977,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1983,
        "MLY First Year": 1977,
        "MLY Last Year": 1983
      },
      {
        "Name": "MAYERTHORPE 2",
        "Province": "ALBERTA",
        "Climate ID": "3064284",
        "Station ID": 8888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.85,
        "Longitude (Decimal Degrees)": -115.35,
        "Latitude": 535100000,
        "Longitude": -1152100000,
        "Elevation (m)": 731.5,
        "First Year": 2004,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2005,
        "MLY First Year": 2004,
        "MLY Last Year": 2005
      },
      {
        "Name": "MAY LO",
        "Province": "ALBERTA",
        "Climate ID": "3064300",
        "Station ID": 2564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.62,
        "Longitude (Decimal Degrees)": -112.35,
        "Latitude": 553700000,
        "Longitude": -1122100000,
        "Elevation (m)": 896.1,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "MEANOOK",
        "Province": "ALBERTA",
        "Climate ID": "3064400",
        "Station ID": 2565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.62,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 543700000,
        "Longitude": -1132100000,
        "Elevation (m)": 684,
        "First Year": 1917,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1976,
        "MLY First Year": 1917,
        "MLY Last Year": 1976
      },
      {
        "Name": "MEDICINE LODGE RS",
        "Province": "ALBERTA",
        "Climate ID": "3064490",
        "Station ID": 2566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -117,
        "Latitude": 533300000,
        "Longitude": -1170000000,
        "Elevation (m)": 944.9,
        "First Year": 1962,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1972,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "MERIDIAN LO",
        "Province": "ALBERTA",
        "Climate ID": "3064515",
        "Station ID": 2567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.55,
        "Longitude (Decimal Degrees)": -114.18,
        "Latitude": 553300000,
        "Longitude": -1141100000,
        "Elevation (m)": 1005.8,
        "First Year": 1964,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2011,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILDRED LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3064528",
        "Station ID": 10978,
        "WMO ID": 71255,
        "TC ID": "WMX",
        "Latitude (Decimal Degrees)": 57.04,
        "Longitude (Decimal Degrees)": -111.56,
        "Latitude": 570228000,
        "Longitude": -1113332000,
        "Elevation (m)": 310,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "MILDRED LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3064531",
        "Station ID": 2568,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.08,
        "Longitude (Decimal Degrees)": -111.58,
        "Latitude": 570500000,
        "Longitude": -1113500000,
        "Elevation (m)": 310.3,
        "First Year": 1973,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1996,
        "MLY First Year": 1973,
        "MLY Last Year": 1996
      },
      {
        "Name": "MILDRED LAKE SYNCRUDE",
        "Province": "ALBERTA",
        "Climate ID": "3064532",
        "Station ID": 29693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.03,
        "Longitude (Decimal Degrees)": -111.57,
        "Latitude": 570200000,
        "Longitude": -1113400000,
        "Elevation (m)": 310,
        "First Year": 1994,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2003,
        "MLY First Year": 1994,
        "MLY Last Year": 2003
      },
      {
        "Name": "MIRROR LANDING RS",
        "Province": "ALBERTA",
        "Climate ID": "3064580",
        "Station ID": 2569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.18,
        "Longitude (Decimal Degrees)": -114.05,
        "Latitude": 551100000,
        "Longitude": -1140300000,
        "Elevation (m)": 579.1,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "MOBERLY LO",
        "Province": "ALBERTA",
        "Climate ID": "3064587",
        "Station ID": 2570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -118.03,
        "Latitude": 533300000,
        "Longitude": -1180200000,
        "Elevation (m)": 1647.1,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOON LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3064640",
        "Station ID": 2571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.47,
        "Longitude (Decimal Degrees)": -114.98,
        "Latitude": 532800000,
        "Longitude": -1145900000,
        "Elevation (m)": 762,
        "First Year": 1952,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1967,
        "MLY First Year": 1952,
        "MLY Last Year": 1967
      },
      {
        "Name": "MOOSE WALLOW",
        "Province": "ALBERTA",
        "Climate ID": "3064657",
        "Station ID": 2572,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.23,
        "Longitude (Decimal Degrees)": -115.02,
        "Latitude": 541400000,
        "Longitude": -1150100000,
        "Elevation (m)": 633.1,
        "First Year": 1980,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2007,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "MUSKEG LO",
        "Province": "ALBERTA",
        "Climate ID": "3064740",
        "Station ID": 2573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.13,
        "Longitude (Decimal Degrees)": -110.9,
        "Latitude": 570800000,
        "Longitude": -1105400000,
        "Elevation (m)": 652.3,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "NARROW LAKES LO",
        "Province": "ALBERTA",
        "Climate ID": "3064776",
        "Station ID": 2574,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.57,
        "Longitude (Decimal Degrees)": -113.68,
        "Latitude": 543400000,
        "Longitude": -1134100000,
        "Elevation (m)": 731.5,
        "First Year": 1972,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1977,
        "MLY First Year": 1972,
        "MLY Last Year": 1977
      },
      {
        "Name": "NITON JUNCTION CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3064810",
        "Station ID": 2575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -115.7,
        "Latitude": 533600000,
        "Longitude": -1154200000,
        "Elevation (m)": 838.2,
        "First Year": 1967,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1974,
        "MLY First Year": 1967,
        "MLY Last Year": 1974
      },
      {
        "Name": "OBED",
        "Province": "ALBERTA",
        "Climate ID": "3064908",
        "Station ID": 2576,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -117.22,
        "Latitude": 533300000,
        "Longitude": -1171300000,
        "Elevation (m)": 1088.1,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "OBED LO",
        "Province": "ALBERTA",
        "Climate ID": "3064910",
        "Station ID": 2577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -117.5,
        "Latitude": 533400000,
        "Longitude": -1173000000,
        "Elevation (m)": 1585,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "PADDLE RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3064964",
        "Station ID": 2578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -114.75,
        "Latitude": 540200000,
        "Longitude": -1144500000,
        "Elevation (m)": 655.3,
        "First Year": 1978,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2006,
        "MLY First Year": 1978,
        "MLY Last Year": 2006
      },
      {
        "Name": "PADDLE RIVER 764",
        "Province": "ALBERTA",
        "Climate ID": "3064966",
        "Station ID": 27828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -114.67,
        "Latitude": 540254000,
        "Longitude": -1144012000,
        "Elevation (m)": 660,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 2003,
        "MLY Last Year": 2005
      },
      {
        "Name": "PASS CREEK LO",
        "Province": "ALBERTA",
        "Climate ID": "3065000",
        "Station ID": 2580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.23,
        "Longitude (Decimal Degrees)": -116.83,
        "Latitude": 541400000,
        "Longitude": -1165000000,
        "Elevation (m)": 1135.4,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEAVINE",
        "Province": "ALBERTA",
        "Climate ID": "3065080",
        "Station ID": 2581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.07,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 540400000,
        "Longitude": -1145500000,
        "Elevation (m)": 694.6,
        "First Year": 1944,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1969,
        "MLY First Year": 1944,
        "MLY Last Year": 1969
      },
      {
        "Name": "PELICAN MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3065160",
        "Station ID": 2582,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.62,
        "Longitude (Decimal Degrees)": -113.57,
        "Latitude": 553700000,
        "Longitude": -1133400000,
        "Elevation (m)": 914.4,
        "First Year": 1952,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1976,
        "MLY First Year": 1952,
        "MLY Last Year": 1976
      },
      {
        "Name": "PIBROCH",
        "Province": "ALBERTA",
        "Climate ID": "3065172",
        "Station ID": 2583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.27,
        "Longitude (Decimal Degrees)": -113.97,
        "Latitude": 541600000,
        "Longitude": -1135800000,
        "Elevation (m)": 609.6,
        "First Year": 1969,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1973,
        "MLY First Year": 1969,
        "MLY Last Year": 1973
      },
      {
        "Name": "PIMPLE LO",
        "Province": "ALBERTA",
        "Climate ID": "3065180",
        "Station ID": 2584,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.5,
        "Longitude (Decimal Degrees)": -115.47,
        "Latitude": 543000000,
        "Longitude": -1152800000,
        "Elevation (m)": 1103.1,
        "First Year": 1956,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1975,
        "MLY First Year": 1956,
        "MLY Last Year": 1975
      },
      {
        "Name": "PLEASANT VIEW",
        "Province": "ALBERTA",
        "Climate ID": "3065200",
        "Station ID": 54000,
        "WMO ID": null,
        "TC ID": "PPV",
        "Latitude (Decimal Degrees)": 54.96,
        "Longitude (Decimal Degrees)": -113.32,
        "Latitude": 545750020,
        "Longitude": -1131914080,
        "Elevation (m)": 649,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRIMROSE LAKE DND",
        "Province": "ALBERTA",
        "Climate ID": "3065304",
        "Station ID": 8893,
        "WMO ID": null,
        "TC ID": "WIQ",
        "Latitude (Decimal Degrees)": 54.75,
        "Longitude (Decimal Degrees)": -110.05,
        "Latitude": 544500000,
        "Longitude": -1100300000,
        "Elevation (m)": 702,
        "First Year": 1994,
        "Last Year": 2013,
        "HLY First Year": 1994,
        "HLY Last Year": 2013,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRIMROSE LO",
        "Province": "ALBERTA",
        "Climate ID": "3065305",
        "Station ID": 2585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.75,
        "Longitude (Decimal Degrees)": -110.05,
        "Latitude": 544500000,
        "Longitude": -1100300000,
        "Elevation (m)": 678.2,
        "First Year": 1967,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1992,
        "MLY First Year": 1967,
        "MLY Last Year": 1992
      },
      {
        "Name": "RICH LAKE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3065475",
        "Station ID": 31413,
        "WMO ID": 71349,
        "TC ID": "XRL",
        "Latitude (Decimal Degrees)": 54.5,
        "Longitude (Decimal Degrees)": -111.7,
        "Latitude": 543000000,
        "Longitude": -1114200000,
        "Elevation (m)": 585,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "RICHARDSON LO",
        "Province": "ALBERTA",
        "Climate ID": "3065492",
        "Station ID": 2586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.92,
        "Longitude (Decimal Degrees)": -110.97,
        "Latitude": 575500000,
        "Longitude": -1105800000,
        "Elevation (m)": 304.8,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROBB RS",
        "Province": "ALBERTA",
        "Climate ID": "3065505",
        "Station ID": 2587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -116.97,
        "Latitude": 531400000,
        "Longitude": -1165800000,
        "Elevation (m)": 1130.2,
        "First Year": 1965,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1996,
        "MLY First Year": 1965,
        "MLY Last Year": 1996
      },
      {
        "Name": "ROCHESTER",
        "Province": "ALBERTA",
        "Climate ID": "3065510",
        "Station ID": 2588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -113.53,
        "Latitude": 542400000,
        "Longitude": -1133200000,
        "Elevation (m)": 647.7,
        "First Year": 1956,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1969,
        "MLY First Year": 1956,
        "MLY Last Year": 1968
      },
      {
        "Name": "ROCHESTER NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3065512",
        "Station ID": 2589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.45,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 542700000,
        "Longitude": -1133100000,
        "Elevation (m)": 655.3,
        "First Year": 1970,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1981,
        "MLY First Year": 1970,
        "MLY Last Year": 1981
      },
      {
        "Name": "ROCK ISLAND LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3065515",
        "Station ID": 2590,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -113.47,
        "Latitude": 552000000,
        "Longitude": -1132800000,
        "Elevation (m)": 716.3,
        "First Year": 1977,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2011,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "RONAN",
        "Province": "ALBERTA",
        "Climate ID": "3065541",
        "Station ID": 2591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.88,
        "Longitude (Decimal Degrees)": -115.25,
        "Latitude": 535300000,
        "Longitude": -1151500000,
        "Elevation (m)": 731.5,
        "First Year": 1969,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2005,
        "MLY First Year": 1969,
        "MLY Last Year": 2005
      },
      {
        "Name": "ROUND HILL LO",
        "Province": "ALBERTA",
        "Climate ID": "3065560",
        "Station ID": 2592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.3,
        "Longitude (Decimal Degrees)": -111.98,
        "Latitude": 551800000,
        "Longitude": -1115900000,
        "Elevation (m)": 749.8,
        "First Year": 1951,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2011,
        "MLY First Year": 1952,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALTEAUX",
        "Province": "ALBERTA",
        "Climate ID": "3065693",
        "Station ID": 2593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.87,
        "Longitude (Decimal Degrees)": -114.77,
        "Latitude": 545200000,
        "Longitude": -1144600000,
        "Elevation (m)": 700,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALT PRAIRIE LO",
        "Province": "ALBERTA",
        "Climate ID": "3065700",
        "Station ID": 2594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.67,
        "Longitude (Decimal Degrees)": -115.83,
        "Latitude": 554000000,
        "Longitude": -1155000000,
        "Elevation (m)": 716.3,
        "First Year": 1955,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2011,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALT PRAIRIE RS",
        "Province": "ALBERTA",
        "Climate ID": "3065705",
        "Station ID": 2595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.63,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 553800000,
        "Longitude": -1160300000,
        "Elevation (m)": 609.6,
        "First Year": 1966,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1972,
        "MLY First Year": 1966,
        "MLY Last Year": 1972
      },
      {
        "Name": "SAND RIVER LO",
        "Province": "ALBERTA",
        "Climate ID": "3065710",
        "Station ID": 2596,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.65,
        "Longitude (Decimal Degrees)": -110.98,
        "Latitude": 543900000,
        "Longitude": -1105900000,
        "Elevation (m)": 731.5,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "ISPAS",
        "Province": "ALBERTA",
        "Climate ID": "3065712",
        "Station ID": 53198,
        "WMO ID": null,
        "TC ID": "PLY",
        "Latitude (Decimal Degrees)": 53.85,
        "Longitude (Decimal Degrees)": -111.84,
        "Latitude": 535105000,
        "Longitude": -1115007020,
        "Elevation (m)": 655,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANDY LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3065715",
        "Station ID": 2597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.83,
        "Longitude (Decimal Degrees)": -113.67,
        "Latitude": 555000000,
        "Longitude": -1134000000,
        "Elevation (m)": 594.4,
        "First Year": 1977,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2011,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "SANGUDO",
        "Province": "ALBERTA",
        "Climate ID": "3065720",
        "Station ID": 2598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.9,
        "Longitude (Decimal Degrees)": -114.87,
        "Latitude": 535400000,
        "Longitude": -1145200000,
        "Elevation (m)": 701,
        "First Year": 1953,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1966,
        "MLY First Year": 1953,
        "MLY Last Year": 1966
      },
      {
        "Name": "SANGUDO ROYDALE",
        "Province": "ALBERTA",
        "Climate ID": "3065723",
        "Station ID": 2599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.95,
        "Longitude (Decimal Degrees)": -114.95,
        "Latitude": 535700000,
        "Longitude": -1145700000,
        "Elevation (m)": 658.4,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "SANGUDO SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3065725",
        "Station ID": 2600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.8,
        "Longitude (Decimal Degrees)": -114.87,
        "Latitude": 534800000,
        "Longitude": -1145200000,
        "Elevation (m)": 716.3,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "SCHWARTZ CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3065765",
        "Station ID": 2601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.43,
        "Longitude (Decimal Degrees)": -116.52,
        "Latitude": 532600000,
        "Longitude": -1163100000,
        "Elevation (m)": 958,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "SEBA LANGLEY",
        "Province": "ALBERTA",
        "Climate ID": "3065792",
        "Station ID": 2602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.47,
        "Longitude (Decimal Degrees)": -114.87,
        "Latitude": 532800000,
        "Longitude": -1145200000,
        "Elevation (m)": 823,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "SHELL HARTLEY RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3065884",
        "Station ID": 2603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.27,
        "Longitude (Decimal Degrees)": -111.47,
        "Latitude": 571600000,
        "Longitude": -1112800000,
        "Elevation (m)": 737.6,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "SHINING BANK",
        "Province": "ALBERTA",
        "Climate ID": "3065885",
        "Station ID": 2604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.85,
        "Longitude (Decimal Degrees)": -115.97,
        "Latitude": 535100000,
        "Longitude": -1155800000,
        "Elevation (m)": 829.1,
        "First Year": 1968,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2011,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHINING BANK RS",
        "Province": "ALBERTA",
        "Climate ID": "3065886",
        "Station ID": 2605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.81,
        "Longitude (Decimal Degrees)": -115.94,
        "Latitude": 534836000,
        "Longitude": -1155624000,
        "Elevation (m)": 914.4,
        "First Year": 1967,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2009,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHOAL CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3065887",
        "Station ID": 2606,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.38,
        "Longitude (Decimal Degrees)": -115.02,
        "Latitude": 542300000,
        "Longitude": -1150100000,
        "Elevation (m)": 640,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "SLAVE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3065995",
        "Station ID": 49369,
        "WMO ID": 71369,
        "TC ID": "YZH",
        "Latitude (Decimal Degrees)": 55.29,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 551735000,
        "Longitude": -1144638000,
        "Elevation (m)": 582.8,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SLAVE LAKE RCS",
        "Province": "ALBERTA",
        "Climate ID": "3065997",
        "Station ID": 50777,
        "WMO ID": 71693,
        "TC ID": "PZH",
        "Latitude (Decimal Degrees)": 55.29,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 551735000,
        "Longitude": -1144638000,
        "Elevation (m)": 582.8,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SLAVE LAKE CR10",
        "Province": "ALBERTA",
        "Climate ID": "3065998",
        "Station ID": 27219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.3,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 551747080,
        "Longitude": -1144702090,
        "Elevation (m)": 583,
        "First Year": 1996,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2007,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "SLAVE LAKE A",
        "Province": "ALBERTA",
        "Climate ID": "3065999",
        "Station ID": 10864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.28,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 551700000,
        "Longitude": -1144700000,
        "Elevation (m)": 582.8,
        "First Year": 1992,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": 1992,
        "DLY Last Year": 2007,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINEDALE",
        "Province": "ALBERTA",
        "Climate ID": "3065B2A",
        "Station ID": 2579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -116.1,
        "Latitude": 533400000,
        "Longitude": -1160600000,
        "Elevation (m)": 944.9,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1983
      },
      {
        "Name": "SLAVE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3066000",
        "Station ID": 2607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.28,
        "Longitude (Decimal Degrees)": -114.77,
        "Latitude": 551700000,
        "Longitude": -1144600000,
        "Elevation (m)": 585.2,
        "First Year": 1922,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1962,
        "MLY First Year": 1922,
        "MLY Last Year": 1962
      },
      {
        "Name": "SLAVE LAKE A",
        "Province": "ALBERTA",
        "Climate ID": "3066001",
        "Station ID": 2608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.3,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 551800000,
        "Longitude": -1144700000,
        "Elevation (m)": 580.6,
        "First Year": 1970,
        "Last Year": 1999,
        "HLY First Year": 1971,
        "HLY Last Year": 1999,
        "DLY First Year": 1970,
        "DLY Last Year": 1997,
        "MLY First Year": 1970,
        "MLY Last Year": 1997
      },
      {
        "Name": "SLAVE LAKE AWOS A",
        "Province": "ALBERTA",
        "Climate ID": "3066002",
        "Station ID": 31528,
        "WMO ID": null,
        "TC ID": "PLV",
        "Latitude (Decimal Degrees)": 55.29,
        "Longitude (Decimal Degrees)": -114.78,
        "Latitude": 551735000,
        "Longitude": -1144638000,
        "Elevation (m)": 582.78,
        "First Year": 2006,
        "Last Year": 2014,
        "HLY First Year": 2006,
        "HLY Last Year": 2014,
        "DLY First Year": 2006,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SLAVE LAKE HQTRS",
        "Province": "ALBERTA",
        "Climate ID": "3066003",
        "Station ID": 2609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.28,
        "Longitude (Decimal Degrees)": -114.63,
        "Latitude": 551700000,
        "Longitude": -1143800000,
        "Elevation (m)": 585.8,
        "First Year": 1965,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1970,
        "MLY First Year": 1965,
        "MLY Last Year": 1970
      },
      {
        "Name": "SMITH",
        "Province": "ALBERTA",
        "Climate ID": "3066017",
        "Station ID": 2610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.17,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 551000000,
        "Longitude": -1140000000,
        "Elevation (m)": null,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "SMITH RS",
        "Province": "ALBERTA",
        "Climate ID": "3066018",
        "Station ID": 2611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.17,
        "Longitude (Decimal Degrees)": -114.03,
        "Latitude": 551000000,
        "Longitude": -1140200000,
        "Elevation (m)": 564.5,
        "First Year": 1965,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1989,
        "MLY First Year": 1965,
        "MLY Last Year": 1989
      },
      {
        "Name": "STONEY MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "3066155",
        "Station ID": 2612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.38,
        "Longitude (Decimal Degrees)": -111.27,
        "Latitude": 562300000,
        "Longitude": -1111600000,
        "Elevation (m)": 779.7,
        "First Year": 1958,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1963,
        "MLY First Year": 1958,
        "MLY Last Year": 1963
      },
      {
        "Name": "STONEY MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3066160",
        "Station ID": 2613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.38,
        "Longitude (Decimal Degrees)": -111.23,
        "Latitude": 562300000,
        "Longitude": -1111400000,
        "Elevation (m)": 762,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "SWAN DIVE LO",
        "Province": "ALBERTA",
        "Climate ID": "3066280",
        "Station ID": 2614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -115.22,
        "Latitude": 544400000,
        "Longitude": -1151300000,
        "Elevation (m)": 1272.2,
        "First Year": 1952,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2011,
        "MLY First Year": 1952,
        "MLY Last Year": 2007
      },
      {
        "Name": "SWAN HILLS",
        "Province": "ALBERTA",
        "Climate ID": "3066296",
        "Station ID": 2615,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -115.48,
        "Latitude": 544400000,
        "Longitude": -1152900000,
        "Elevation (m)": 1264.9,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "SWAN HILLS",
        "Province": "ALBERTA",
        "Climate ID": "3066297",
        "Station ID": 2616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.67,
        "Longitude (Decimal Degrees)": -115.42,
        "Latitude": 544000000,
        "Longitude": -1152500000,
        "Elevation (m)": 1058.6,
        "First Year": 1985,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1995,
        "MLY First Year": 1985,
        "MLY Last Year": 1995
      },
      {
        "Name": "SWAN HILLS RS",
        "Province": "ALBERTA",
        "Climate ID": "3066298",
        "Station ID": 2617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.73,
        "Longitude (Decimal Degrees)": -115.4,
        "Latitude": 544400000,
        "Longitude": -1152400000,
        "Elevation (m)": 1127.8,
        "First Year": 1965,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1972,
        "MLY First Year": 1965,
        "MLY Last Year": 1972
      },
      {
        "Name": "SWAN HILLS RS",
        "Province": "ALBERTA",
        "Climate ID": "3066299",
        "Station ID": 2618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.72,
        "Longitude (Decimal Degrees)": -116.43,
        "Latitude": 544300000,
        "Longitude": -1162600000,
        "Elevation (m)": 1127.8,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "SWITZER",
        "Province": "ALBERTA",
        "Climate ID": "3066325",
        "Station ID": 27970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.47,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 532800000,
        "Longitude": -1174800000,
        "Elevation (m)": 1261,
        "First Year": 1999,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2001,
        "MLY First Year": 1999,
        "MLY Last Year": 2001
      },
      {
        "Name": "TAR ISLAND",
        "Province": "ALBERTA",
        "Climate ID": "3066364",
        "Station ID": 2619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.98,
        "Longitude (Decimal Degrees)": -111.45,
        "Latitude": 565900000,
        "Longitude": -1112700000,
        "Elevation (m)": 239.9,
        "First Year": 1970,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1984,
        "MLY First Year": 1970,
        "MLY Last Year": 1984
      },
      {
        "Name": "TAWATINAW AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3066367",
        "Station ID": 47108,
        "WMO ID": 71549,
        "TC ID": "PTA",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -113.52,
        "Latitude": 541759009,
        "Longitude": -1133113001,
        "Elevation (m)": 611,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THICKWOOD LO",
        "Province": "ALBERTA",
        "Climate ID": "3066380",
        "Station ID": 2620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.88,
        "Longitude (Decimal Degrees)": -111.65,
        "Latitude": 565300000,
        "Longitude": -1113900000,
        "Elevation (m)": 603.5,
        "First Year": 1957,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1992,
        "MLY First Year": 1957,
        "MLY Last Year": 1992
      },
      {
        "Name": "TOM HILL LO",
        "Province": "ALBERTA",
        "Climate ID": "3066495",
        "Station ID": 2621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.93,
        "Longitude (Decimal Degrees)": -116.33,
        "Latitude": 535600000,
        "Longitude": -1162000000,
        "Elevation (m)": 1295.4,
        "First Year": 1966,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "TONY LO",
        "Province": "ALBERTA",
        "Climate ID": "3066500",
        "Station ID": 2622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.38,
        "Longitude (Decimal Degrees)": -117.4,
        "Latitude": 542300000,
        "Longitude": -1172400000,
        "Elevation (m)": 1036.3,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "VEGA",
        "Province": "ALBERTA",
        "Climate ID": "3066705",
        "Station ID": 2623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -114.38,
        "Latitude": 542400000,
        "Longitude": -1142300000,
        "Elevation (m)": 670.6,
        "First Year": 1970,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1982,
        "MLY First Year": 1970,
        "MLY Last Year": 1982
      },
      {
        "Name": "VEGA LO",
        "Province": "ALBERTA",
        "Climate ID": "3066710",
        "Station ID": 2624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.43,
        "Longitude (Decimal Degrees)": -114.43,
        "Latitude": 542600000,
        "Longitude": -1142600000,
        "Elevation (m)": 708.7,
        "First Year": 1969,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2011,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "VENICE",
        "Province": "ALBERTA",
        "Climate ID": "3066770",
        "Station ID": 2626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.67,
        "Longitude (Decimal Degrees)": -112.07,
        "Latitude": 544000000,
        "Longitude": -1120400000,
        "Elevation (m)": 576,
        "First Year": 1981,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2008,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "WAGNER",
        "Province": "ALBERTA",
        "Climate ID": "3066920",
        "Station ID": 2627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.35,
        "Longitude (Decimal Degrees)": -114.98,
        "Latitude": 552100000,
        "Longitude": -1145900000,
        "Elevation (m)": 583.7,
        "First Year": 1944,
        "Last Year": 1971,
        "HLY First Year": 1953,
        "HLY Last Year": 1971,
        "DLY First Year": 1944,
        "DLY Last Year": 1971,
        "MLY First Year": 1944,
        "MLY Last Year": 1971
      },
      {
        "Name": "WANDERING RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3066935",
        "Station ID": 2628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.2,
        "Longitude (Decimal Degrees)": -112.5,
        "Latitude": 551200000,
        "Longitude": -1123000000,
        "Elevation (m)": 563.9,
        "First Year": 1962,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2008,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "TWIN LAKES",
        "Province": "ALBERTA",
        "Climate ID": "3066FME",
        "Station ID": 8895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.07,
        "Longitude (Decimal Degrees)": -114.8,
        "Latitude": 540400000,
        "Longitude": -1144800000,
        "Elevation (m)": 655.3,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "WEST PADDLE",
        "Province": "ALBERTA",
        "Climate ID": "3067259",
        "Station ID": 2630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -115.35,
        "Latitude": 540000000,
        "Longitude": -1152100000,
        "Elevation (m)": 762,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "WHITECOURT",
        "Province": "ALBERTA",
        "Climate ID": "3067370",
        "Station ID": 2631,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -115.67,
        "Latitude": 540800000,
        "Longitude": -1154000000,
        "Elevation (m)": 740.7,
        "First Year": 1942,
        "Last Year": 1978,
        "HLY First Year": 1953,
        "HLY Last Year": 1978,
        "DLY First Year": 1942,
        "DLY Last Year": 1978,
        "MLY First Year": 1942,
        "MLY Last Year": 1978
      },
      {
        "Name": "WHITECOURT",
        "Province": "ALBERTA",
        "Climate ID": "3067371",
        "Station ID": 27658,
        "WMO ID": null,
        "TC ID": "XZU",
        "Latitude (Decimal Degrees)": 54.14,
        "Longitude (Decimal Degrees)": -115.78,
        "Latitude": 540823008,
        "Longitude": -1154702000,
        "Elevation (m)": 784.7,
        "First Year": 1998,
        "Last Year": 2016,
        "HLY First Year": 1999,
        "HLY Last Year": 2016,
        "DLY First Year": 1998,
        "DLY Last Year": 2016,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITECOURT A",
        "Province": "ALBERTA",
        "Climate ID": "3067372",
        "Station ID": 2632,
        "WMO ID": null,
        "TC ID": "YZU",
        "Latitude (Decimal Degrees)": 54.14,
        "Longitude (Decimal Degrees)": -115.79,
        "Latitude": 540838000,
        "Longitude": -1154712000,
        "Elevation (m)": 782.4,
        "First Year": 1978,
        "Last Year": 2019,
        "HLY First Year": 1978,
        "HLY Last Year": 2014,
        "DLY First Year": 1978,
        "DLY Last Year": 2019,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITECOURT A",
        "Province": "ALBERTA",
        "Climate ID": "3067373",
        "Station ID": 51444,
        "WMO ID": 71930,
        "TC ID": "YZU",
        "Latitude (Decimal Degrees)": 54.14,
        "Longitude (Decimal Degrees)": -115.79,
        "Latitude": 540838000,
        "Longitude": -1154712000,
        "Elevation (m)": 782.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHITECOURT FOREST HQ",
        "Province": "ALBERTA",
        "Climate ID": "3067388",
        "Station ID": 2633,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -115.68,
        "Latitude": 540800000,
        "Longitude": -1154100000,
        "Elevation (m)": 740.7,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "WHITECOURT LO",
        "Province": "ALBERTA",
        "Climate ID": "3067392",
        "Station ID": 2634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -115.72,
        "Latitude": 540200000,
        "Longitude": -1154300000,
        "Elevation (m)": 1200.9,
        "First Year": 1939,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 2011,
        "MLY First Year": 1939,
        "MLY Last Year": 2007
      },
      {
        "Name": "WILDHAY",
        "Province": "ALBERTA",
        "Climate ID": "3067542",
        "Station ID": 2635,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -117.55,
        "Latitude": 535200000,
        "Longitude": -1173300000,
        "Elevation (m)": 1064,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "WILDWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3067560",
        "Station ID": 2636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -115.22,
        "Latitude": 533600000,
        "Longitude": -1151300000,
        "Elevation (m)": 799.5,
        "First Year": 1955,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1959,
        "MLY First Year": 1955,
        "MLY Last Year": 1959
      },
      {
        "Name": "WILDWOOD NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3067561",
        "Station ID": 2637,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.73,
        "Longitude (Decimal Degrees)": -115.23,
        "Latitude": 534400000,
        "Longitude": -1151400000,
        "Elevation (m)": 853.4,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "WILDWOOD SOUTH",
        "Province": "ALBERTA",
        "Climate ID": "3067562",
        "Station ID": 2638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -115.25,
        "Latitude": 533400000,
        "Longitude": -1151500000,
        "Elevation (m)": 823,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "WINDFALL AFS",
        "Province": "ALBERTA",
        "Climate ID": "3067586",
        "Station ID": 27263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.19,
        "Longitude (Decimal Degrees)": -116.25,
        "Latitude": 541116000,
        "Longitude": -1161459000,
        "Elevation (m)": 808,
        "First Year": 1996,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2011,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINEFRED LO",
        "Province": "ALBERTA",
        "Climate ID": "3067590",
        "Station ID": 2639,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -110.2,
        "Latitude": 552000000,
        "Longitude": -1101200000,
        "Elevation (m)": 743.7,
        "First Year": 1957,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2008,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "WOLF LO",
        "Province": "ALBERTA",
        "Climate ID": "3067620",
        "Station ID": 2640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.15,
        "Longitude (Decimal Degrees)": -115.9,
        "Latitude": 530900000,
        "Longitude": -1155400000,
        "Elevation (m)": 1098.5,
        "First Year": 1956,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1975,
        "MLY First Year": 1956,
        "MLY Last Year": 1975
      },
      {
        "Name": "YELLOWHEAD LO",
        "Province": "ALBERTA",
        "Climate ID": "3067680",
        "Station ID": 2641,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -117.15,
        "Latitude": 531400000,
        "Longitude": -1170900000,
        "Elevation (m)": 1463,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "WILDWOOD NEWBERY",
        "Province": "ALBERTA",
        "Climate ID": "3067NF0",
        "Station ID": 2629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -115.32,
        "Latitude": 533400000,
        "Longitude": -1151900000,
        "Elevation (m)": 853.4,
        "First Year": 1980,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1998,
        "MLY First Year": 1980,
        "MLY Last Year": 1998
      },
      {
        "Name": "PRAIRIE RIVER",
        "Province": "ALBERTA",
        "Climate ID": "306A008",
        "Station ID": 27845,
        "WMO ID": 71593,
        "TC ID": "XPV",
        "Latitude (Decimal Degrees)": 58.63,
        "Longitude (Decimal Degrees)": -111.68,
        "Latitude": 583738020,
        "Longitude": -1114046020,
        "Elevation (m)": 211.8,
        "First Year": 1999,
        "Last Year": 2009,
        "HLY First Year": 1999,
        "HLY Last Year": 2009,
        "DLY First Year": 1999,
        "DLY Last Year": 2009,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "HINTON VALLEY",
        "Province": "ALBERTA",
        "Climate ID": "306A009",
        "Station ID": 29926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.4,
        "Longitude (Decimal Degrees)": -117.54,
        "Latitude": 532413716,
        "Longitude": -1173215433,
        "Elevation (m)": 1010.77,
        "First Year": 2000,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2021,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "CYNTHIA",
        "Province": "ALBERTA",
        "Climate ID": "306A9E1",
        "Station ID": 2453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -115.38,
        "Latitude": 531500000,
        "Longitude": -1152300000,
        "Elevation (m)": 914.4,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "FREEMAN RIVER",
        "Province": "ALBERTA",
        "Climate ID": "306BGCQ",
        "Station ID": 2454,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.55,
        "Longitude (Decimal Degrees)": -115.3,
        "Latitude": 543300000,
        "Longitude": -1151800000,
        "Elevation (m)": 821,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOPE LAKE",
        "Province": "ALBERTA",
        "Climate ID": "306C344",
        "Station ID": 2456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -114.8,
        "Latitude": 535500000,
        "Longitude": -1144800000,
        "Elevation (m)": 670.6,
        "First Year": 1981,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1988,
        "MLY First Year": 1981,
        "MLY Last Year": 1988
      },
      {
        "Name": "HINTON BLUE LAKE CENTRE",
        "Province": "ALBERTA",
        "Climate ID": "306CC41",
        "Station ID": 2455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.48,
        "Longitude (Decimal Degrees)": -117.8,
        "Latitude": 532900000,
        "Longitude": -1174800000,
        "Elevation (m)": 1158.2,
        "First Year": 1983,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1993,
        "MLY First Year": 1983,
        "MLY Last Year": 1993
      },
      {
        "Name": "PRIMROSE LAKE 2",
        "Province": "ALBERTA",
        "Climate ID": "3.06E+306",
        "Station ID": 10807,
        "WMO ID": null,
        "TC ID": "WOU",
        "Latitude (Decimal Degrees)": 54.79,
        "Longitude (Decimal Degrees)": -110.06,
        "Latitude": 544720000,
        "Longitude": -1100335000,
        "Elevation (m)": 605.9,
        "First Year": 1992,
        "Last Year": 2000,
        "HLY First Year": 1994,
        "HLY Last Year": 2000,
        "DLY First Year": 1992,
        "DLY Last Year": 2000,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WILLOW CREEK 1",
        "Province": "ALBERTA",
        "Climate ID": "306GE70",
        "Station ID": 10930,
        "WMO ID": 71252,
        "TC ID": "WWO",
        "Latitude (Decimal Degrees)": 53.39,
        "Longitude (Decimal Degrees)": -118.35,
        "Latitude": 532308000,
        "Longitude": -1182051000,
        "Elevation (m)": 1370,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ETA LAKE",
        "Province": "ALBERTA",
        "Climate ID": "306KD60",
        "Station ID": 2457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.2,
        "Longitude (Decimal Degrees)": -115.88,
        "Latitude": 531200000,
        "Longitude": -1155300000,
        "Elevation (m)": 927,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT ASSINIBOINE",
        "Province": "ALBERTA",
        "Climate ID": "306KFE3",
        "Station ID": 2458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.37,
        "Longitude (Decimal Degrees)": -114.92,
        "Latitude": 542200000,
        "Longitude": -1145500000,
        "Elevation (m)": 670.6,
        "First Year": 1969,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1997,
        "MLY First Year": 1969,
        "MLY Last Year": 1997
      },
      {
        "Name": "ADAIR LO",
        "Province": "ALBERTA",
        "Climate ID": "3070043",
        "Station ID": 2649,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.17,
        "Longitude (Decimal Degrees)": -118.33,
        "Latitude": 591000000,
        "Longitude": -1182000000,
        "Elevation (m)": 365.8,
        "First Year": 1968,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2011,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "AMBER LO",
        "Province": "ALBERTA",
        "Climate ID": "3070230",
        "Station ID": 2650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.18,
        "Longitude (Decimal Degrees)": -119.45,
        "Latitude": 591100000,
        "Longitude": -1192700000,
        "Elevation (m)": 579.1,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "ASSUMPTION",
        "Province": "ALBERTA",
        "Climate ID": "3070310",
        "Station ID": 2651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.7,
        "Longitude (Decimal Degrees)": -118.68,
        "Latitude": 584200000,
        "Longitude": -1184100000,
        "Elevation (m)": 304.8,
        "First Year": 1961,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1970,
        "MLY First Year": 1961,
        "MLY Last Year": 1970
      },
      {
        "Name": "ASSUMPTION",
        "Province": "ALBERTA",
        "Climate ID": "3070311",
        "Station ID": 2652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.7,
        "Longitude (Decimal Degrees)": -118.72,
        "Latitude": 584200000,
        "Longitude": -1184300000,
        "Elevation (m)": 335.3,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "ATIKAMEG",
        "Province": "ALBERTA",
        "Climate ID": "3070400",
        "Station ID": 2653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.9,
        "Longitude (Decimal Degrees)": -115.65,
        "Latitude": 555400000,
        "Longitude": -1153900000,
        "Elevation (m)": 650.7,
        "First Year": 1959,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1959,
        "MLY Last Year": 1959
      },
      {
        "Name": "BALD MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3070480",
        "Station ID": 2654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -118.92,
        "Latitude": 544900000,
        "Longitude": -1185500000,
        "Elevation (m)": 938.8,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "BALLATER",
        "Province": "ALBERTA",
        "Climate ID": "3070493",
        "Station ID": 2655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.58,
        "Longitude (Decimal Degrees)": -117.21,
        "Latitude": 553441003,
        "Longitude": -1171234002,
        "Elevation (m)": 563.8,
        "First Year": 1990,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2014,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "BALLATER AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3070495",
        "Station ID": 49048,
        "WMO ID": null,
        "TC ID": "PBC",
        "Latitude (Decimal Degrees)": 55.58,
        "Longitude (Decimal Degrees)": -117.24,
        "Latitude": 553437000,
        "Longitude": -1171408000,
        "Elevation (m)": 560,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BATTLE RIVER LO",
        "Province": "ALBERTA",
        "Climate ID": "3070540",
        "Station ID": 2657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.48,
        "Longitude (Decimal Degrees)": -117.65,
        "Latitude": 572900000,
        "Longitude": -1173900000,
        "Elevation (m)": 731.5,
        "First Year": 1955,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2011,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAVERLODGE CDA",
        "Province": "ALBERTA",
        "Climate ID": "3070560",
        "Station ID": 2658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.2,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 551200000,
        "Longitude": -1192400000,
        "Elevation (m)": 744.9,
        "First Year": 1913,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2007,
        "MLY First Year": 1913,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEAVERLODGE REDLOW",
        "Province": "ALBERTA",
        "Climate ID": "3070565",
        "Station ID": 2659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 552000000,
        "Longitude": -1192400000,
        "Elevation (m)": 426.7,
        "First Year": 1912,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1930,
        "MLY First Year": 1912,
        "MLY Last Year": 1930
      },
      {
        "Name": "BEAVERLODGE CDA",
        "Province": "ALBERTA",
        "Climate ID": "3.07E+13",
        "Station ID": 10935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.2,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 551200000,
        "Longitude": -1192400000,
        "Elevation (m)": 745,
        "First Year": 1996,
        "Last Year": 2002,
        "HLY First Year": 1996,
        "HLY Last Year": 2002,
        "DLY First Year": 1996,
        "DLY Last Year": 2002,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BASSET LO",
        "Province": "ALBERTA",
        "Climate ID": "30705LQ",
        "Station ID": 2656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.2,
        "Longitude (Decimal Degrees)": -118.22,
        "Latitude": 581200000,
        "Longitude": -1181300000,
        "Elevation (m)": 755.3,
        "First Year": 1966,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEAVERLODGE RCS",
        "Province": "ALBERTA",
        "Climate ID": "3070600",
        "Station ID": 30669,
        "WMO ID": 71230,
        "TC ID": "WBM",
        "Latitude (Decimal Degrees)": 55.2,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 551148002,
        "Longitude": -1192347090,
        "Elevation (m)": 745,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "BERWYN",
        "Province": "ALBERTA",
        "Climate ID": "3070640",
        "Station ID": 2660,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.15,
        "Longitude (Decimal Degrees)": -117.75,
        "Latitude": 560900000,
        "Longitude": -1174500000,
        "Elevation (m)": 654.7,
        "First Year": 1921,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1958,
        "MLY First Year": 1921,
        "MLY Last Year": 1958
      },
      {
        "Name": "BERWYN CDA",
        "Province": "ALBERTA",
        "Climate ID": "3070642",
        "Station ID": 2661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.15,
        "Longitude (Decimal Degrees)": -117.68,
        "Latitude": 560900000,
        "Longitude": -1174100000,
        "Elevation (m)": 626.4,
        "First Year": 1975,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1993,
        "MLY First Year": 1975,
        "MLY Last Year": 1993
      },
      {
        "Name": "BIRCH MOUNTAIN TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3070701",
        "Station ID": 2662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.1,
        "Longitude (Decimal Degrees)": -112.15,
        "Latitude": 580600000,
        "Longitude": -1120900000,
        "Elevation (m)": 731,
        "First Year": 1976,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1990,
        "MLY First Year": 1976,
        "MLY Last Year": 1990
      },
      {
        "Name": "BISON LO",
        "Province": "ALBERTA",
        "Climate ID": "3070703",
        "Station ID": 2663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.12,
        "Longitude (Decimal Degrees)": -116.5,
        "Latitude": 570700000,
        "Longitude": -1163000000,
        "Elevation (m)": 731.5,
        "First Year": 1963,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2011,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "BLUEBERRY CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3070718",
        "Station ID": 6861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.92,
        "Longitude (Decimal Degrees)": -119,
        "Latitude": 555500000,
        "Longitude": -1190002000,
        "Elevation (m)": 621.8,
        "First Year": 1993,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1996,
        "MLY First Year": 1993,
        "MLY Last Year": 1996
      },
      {
        "Name": "BLUEBERRY MTN CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3070720",
        "Station ID": 2664,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.93,
        "Longitude (Decimal Degrees)": -119.13,
        "Latitude": 555600000,
        "Longitude": -1190800000,
        "Elevation (m)": 731.5,
        "First Year": 1955,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1972,
        "MLY First Year": 1955,
        "MLY Last Year": 1972
      },
      {
        "Name": "BROWNVALE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3070800",
        "Station ID": 47067,
        "WMO ID": 71283,
        "TC ID": "PBN",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -117.89,
        "Latitude": 560704000,
        "Longitude": -1175311008,
        "Elevation (m)": 748,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BUFFALO HEAD PRAIRIE",
        "Province": "ALBERTA",
        "Climate ID": "3070960",
        "Station ID": 2665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.05,
        "Longitude (Decimal Degrees)": -116.27,
        "Latitude": 580300000,
        "Longitude": -1161600000,
        "Elevation (m)": 335.3,
        "First Year": 1932,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 1959,
        "MLY First Year": 1932,
        "MLY Last Year": 1959
      },
      {
        "Name": "BUFFALO LO",
        "Province": "ALBERTA",
        "Climate ID": "3070970",
        "Station ID": 2666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.95,
        "Longitude (Decimal Degrees)": -116.22,
        "Latitude": 575700000,
        "Longitude": -1161300000,
        "Elevation (m)": 792.5,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "CADOTTE LO",
        "Province": "ALBERTA",
        "Climate ID": "3070995",
        "Station ID": 2667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.3,
        "Longitude (Decimal Degrees)": -116.43,
        "Latitude": 561800000,
        "Longitude": -1162600000,
        "Elevation (m)": 731.5,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "BASNETT",
        "Province": "ALBERTA",
        "Climate ID": "3070NLN",
        "Station ID": 2648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.37,
        "Longitude (Decimal Degrees)": -119.82,
        "Latitude": 572200000,
        "Longitude": -1194900000,
        "Elevation (m)": 760,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMBRIA LO",
        "Province": "ALBERTA",
        "Climate ID": "3071150",
        "Station ID": 2668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.25,
        "Longitude (Decimal Degrees)": -110.8,
        "Latitude": 591500000,
        "Longitude": -1104800000,
        "Elevation (m)": 327,
        "First Year": 1987,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2011,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARIBOU MTN LO",
        "Province": "ALBERTA",
        "Climate ID": "3071330",
        "Station ID": 2669,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.52,
        "Longitude (Decimal Degrees)": -114.57,
        "Latitude": 593100000,
        "Longitude": -1143400000,
        "Elevation (m)": 914.4,
        "First Year": 1976,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1990,
        "MLY First Year": 1976,
        "MLY Last Year": 1988
      },
      {
        "Name": "CARLSON'S LANDING LO",
        "Province": "ALBERTA",
        "Climate ID": "3071331",
        "Station ID": 2670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.97,
        "Longitude (Decimal Degrees)": -111.8,
        "Latitude": 585800000,
        "Longitude": -1114800000,
        "Elevation (m)": 243.8,
        "First Year": 1975,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1990,
        "MLY First Year": 1975,
        "MLY Last Year": 1990
      },
      {
        "Name": "CARLSON TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3071332",
        "Station ID": 2671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.92,
        "Longitude (Decimal Degrees)": -111.8,
        "Latitude": 585500000,
        "Longitude": -1114800000,
        "Elevation (m)": 213.4,
        "First Year": 1968,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1974,
        "MLY First Year": 1968,
        "MLY Last Year": 1974
      },
      {
        "Name": "CHERRY MOUNTAIN TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3071521",
        "Station ID": 2672,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.38,
        "Longitude (Decimal Degrees)": -112.38,
        "Latitude": 592300000,
        "Longitude": -1122300000,
        "Elevation (m)": 305,
        "First Year": 1968,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1981,
        "MLY First Year": 1968,
        "MLY Last Year": 1981
      },
      {
        "Name": "CHINCHAGA LO",
        "Province": "ALBERTA",
        "Climate ID": "3071550",
        "Station ID": 2673,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.12,
        "Longitude (Decimal Degrees)": -118.33,
        "Latitude": 570700000,
        "Longitude": -1182000000,
        "Elevation (m)": 762,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHIPEWYAN LAKES LO",
        "Province": "ALBERTA",
        "Climate ID": "3071555",
        "Station ID": 2674,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57,
        "Longitude (Decimal Degrees)": -113.42,
        "Latitude": 570000000,
        "Longitude": -1132500000,
        "Elevation (m)": 563.9,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLEAR HILLS LO",
        "Province": "ALBERTA",
        "Climate ID": "3071660",
        "Station ID": 2675,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.6,
        "Longitude (Decimal Degrees)": -119.42,
        "Latitude": 563600000,
        "Longitude": -1192500000,
        "Elevation (m)": 932.7,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLEAR HILLS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3071661",
        "Station ID": 51838,
        "WMO ID": null,
        "TC ID": "PCS",
        "Latitude (Decimal Degrees)": 56.53,
        "Longitude (Decimal Degrees)": -117.93,
        "Latitude": 563144058,
        "Longitude": -1175600068,
        "Elevation (m)": 672,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CODESA LO",
        "Province": "ALBERTA",
        "Climate ID": "3071677",
        "Station ID": 2676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.67,
        "Longitude (Decimal Degrees)": -118.08,
        "Latitude": 554000000,
        "Longitude": -1180500000,
        "Elevation (m)": 792.5,
        "First Year": 1960,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1992,
        "MLY First Year": 1960,
        "MLY Last Year": 1992
      },
      {
        "Name": "COPTON LO",
        "Province": "ALBERTA",
        "Climate ID": "3071860",
        "Station ID": 2677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.18,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 541100000,
        "Longitude": -1192400000,
        "Elevation (m)": 1855.6,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEADWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3071955",
        "Station ID": 2678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.73,
        "Longitude (Decimal Degrees)": -117.47,
        "Latitude": 564400000,
        "Longitude": -1172800000,
        "Elevation (m)": 518.2,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "DEADWOOD AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3071957",
        "Station ID": 51839,
        "WMO ID": null,
        "TC ID": "PDO",
        "Latitude (Decimal Degrees)": 56.72,
        "Longitude (Decimal Degrees)": -117.54,
        "Latitude": 564255042,
        "Longitude": -1173236060,
        "Elevation (m)": 551,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEADWOOD EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3071960",
        "Station ID": 2679,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.73,
        "Longitude (Decimal Degrees)": -117.45,
        "Latitude": 564400000,
        "Longitude": -1172700000,
        "Elevation (m)": null,
        "First Year": 1953,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1955,
        "MLY First Year": 1953,
        "MLY Last Year": 1955
      },
      {
        "Name": "DEADWOOD LO",
        "Province": "ALBERTA",
        "Climate ID": "3071965",
        "Station ID": 2680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.63,
        "Longitude (Decimal Degrees)": -117.35,
        "Latitude": 563800000,
        "Longitude": -1172100000,
        "Elevation (m)": 609.6,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEADWOOD REINWOOD",
        "Province": "ALBERTA",
        "Climate ID": "3071966",
        "Station ID": 2681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.58,
        "Longitude (Decimal Degrees)": -117.3,
        "Latitude": 563500000,
        "Longitude": -1171800000,
        "Elevation (m)": 502.9,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "DEBOLT GOODWIN",
        "Province": "ALBERTA",
        "Climate ID": "3072001",
        "Station ID": 31207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.26,
        "Longitude (Decimal Degrees)": -118.18,
        "Latitude": 551533005,
        "Longitude": -1181044006,
        "Elevation (m)": 639.29,
        "First Year": 2001,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2013,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEBOLT RS",
        "Province": "ALBERTA",
        "Climate ID": "3072002",
        "Station ID": 2682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.93,
        "Longitude (Decimal Degrees)": -118.07,
        "Latitude": 545600000,
        "Longitude": -1180400000,
        "Elevation (m)": 609.6,
        "First Year": 1962,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1984,
        "MLY First Year": 1962,
        "MLY Last Year": 1984
      },
      {
        "Name": "DEMMITT CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3072063",
        "Station ID": 2683,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.45,
        "Longitude (Decimal Degrees)": -119.93,
        "Latitude": 552700000,
        "Longitude": -1195600000,
        "Elevation (m)": 792.5,
        "First Year": 1969,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1978,
        "MLY First Year": 1969,
        "MLY Last Year": 1978
      },
      {
        "Name": "DOIG LO",
        "Province": "ALBERTA",
        "Climate ID": "3072093",
        "Station ID": 2684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.97,
        "Longitude (Decimal Degrees)": -119.55,
        "Latitude": 565800000,
        "Longitude": -1193300000,
        "Elevation (m)": 1219.2,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "DONNELLY",
        "Province": "ALBERTA",
        "Climate ID": "3072096",
        "Station ID": 2685,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.73,
        "Longitude (Decimal Degrees)": -117.1,
        "Latitude": 554343007,
        "Longitude": -1170606008,
        "Elevation (m)": 602,
        "First Year": 1982,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2008,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "DOUCETTE LO",
        "Province": "ALBERTA",
        "Climate ID": "3072100",
        "Station ID": 2686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.82,
        "Longitude (Decimal Degrees)": -114.3,
        "Latitude": 554900000,
        "Longitude": -1141800000,
        "Elevation (m)": 609.6,
        "First Year": 1956,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1997,
        "MLY First Year": 1956,
        "MLY Last Year": 1997
      },
      {
        "Name": "DUNVEGAN",
        "Province": "ALBERTA",
        "Climate ID": "3072145",
        "Station ID": 2687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.93,
        "Longitude (Decimal Degrees)": -118.58,
        "Latitude": 555600000,
        "Longitude": -1183500000,
        "Elevation (m)": 397.8,
        "First Year": 1880,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1943,
        "MLY First Year": 1880,
        "MLY Last Year": 1943
      },
      {
        "Name": "EAGLESHAM",
        "Province": "ALBERTA",
        "Climate ID": "3072149",
        "Station ID": 2688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.77,
        "Longitude (Decimal Degrees)": -117.75,
        "Latitude": 554600000,
        "Longitude": -1174500000,
        "Elevation (m)": 548.6,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "EAGLESHAM AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3072151",
        "Station ID": 47071,
        "WMO ID": 71461,
        "TC ID": "PEL",
        "Latitude (Decimal Degrees)": 55.81,
        "Longitude (Decimal Degrees)": -117.89,
        "Latitude": 554829002,
        "Longitude": -1175312000,
        "Elevation (m)": 563,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ECONOMY LO",
        "Province": "ALBERTA",
        "Climate ID": "3072160",
        "Station ID": 2689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.78,
        "Longitude (Decimal Degrees)": -118.23,
        "Latitude": 544700000,
        "Longitude": -1181400000,
        "Elevation (m)": 800.1,
        "First Year": 1953,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 2011,
        "MLY First Year": 1953,
        "MLY Last Year": 2007
      },
      {
        "Name": "EDRA LO",
        "Province": "ALBERTA",
        "Climate ID": "3072235",
        "Station ID": 2690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.85,
        "Longitude (Decimal Degrees)": -113.25,
        "Latitude": 575100000,
        "Longitude": -1131500000,
        "Elevation (m)": 609.6,
        "First Year": 1966,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "EGG ISLAND",
        "Province": "ALBERTA",
        "Climate ID": "3072246",
        "Station ID": 31027,
        "WMO ID": 71404,
        "TC ID": "XEL",
        "Latitude (Decimal Degrees)": 58.98,
        "Longitude (Decimal Degrees)": -110.44,
        "Latitude": 585853070,
        "Longitude": -1102622062,
        "Elevation (m)": 214.9,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELMWORTH",
        "Province": "ALBERTA",
        "Climate ID": "3072320",
        "Station ID": 2691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.07,
        "Longitude (Decimal Degrees)": -119.67,
        "Latitude": 550400000,
        "Longitude": -1194000000,
        "Elevation (m)": 746.8,
        "First Year": 1926,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1953,
        "MLY First Year": 1926,
        "MLY Last Year": 1953
      },
      {
        "Name": "ELMWORTH CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3072321",
        "Station ID": 2692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.05,
        "Longitude (Decimal Degrees)": -119.57,
        "Latitude": 550300000,
        "Longitude": -1193400000,
        "Elevation (m)": 701,
        "First Year": 1967,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1972,
        "MLY First Year": 1967,
        "MLY Last Year": 1972
      },
      {
        "Name": "ELMWORTH CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3072322",
        "Station ID": 2693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.1,
        "Longitude (Decimal Degrees)": -119.75,
        "Latitude": 550600000,
        "Longitude": -1194500000,
        "Elevation (m)": 754.4,
        "First Year": 1975,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2008,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "EUREKA RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3072475",
        "Station ID": 2694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.48,
        "Longitude (Decimal Degrees)": -118.73,
        "Latitude": 562900000,
        "Longitude": -1184400000,
        "Elevation (m)": 664.5,
        "First Year": 1962,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2008,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "FAIRVIEW",
        "Province": "ALBERTA",
        "Climate ID": "3072520",
        "Station ID": 2695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.07,
        "Longitude (Decimal Degrees)": -118.38,
        "Latitude": 560400000,
        "Longitude": -1182300000,
        "Elevation (m)": 670,
        "First Year": 1931,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1991,
        "MLY First Year": 1931,
        "MLY Last Year": 1991
      },
      {
        "Name": "FAIRVIEW 2",
        "Province": "ALBERTA",
        "Climate ID": "3072521",
        "Station ID": 2696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.97,
        "Longitude (Decimal Degrees)": -118.5,
        "Latitude": 555800000,
        "Longitude": -1183000000,
        "Elevation (m)": 609.6,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "FAIRVIEW AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3072525",
        "Station ID": 31469,
        "WMO ID": 71347,
        "TC ID": "XFR",
        "Latitude (Decimal Degrees)": 56.08,
        "Longitude (Decimal Degrees)": -118.44,
        "Latitude": 560453006,
        "Longitude": -1182622005,
        "Elevation (m)": 654.55,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "FAIRVIEW FSA",
        "Province": "ALBERTA",
        "Climate ID": "3072536",
        "Station ID": 2697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.07,
        "Longitude (Decimal Degrees)": -118.38,
        "Latitude": 560400000,
        "Longitude": -1182300000,
        "Elevation (m)": 646.2,
        "First Year": 1956,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1980,
        "MLY First Year": 1956,
        "MLY Last Year": 1980
      },
      {
        "Name": "FAIRVIEW THREE FOX FARM",
        "Province": "ALBERTA",
        "Climate ID": "3072539",
        "Station ID": 6862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.08,
        "Longitude (Decimal Degrees)": -118.53,
        "Latitude": 560500000,
        "Longitude": -1183200000,
        "Elevation (m)": 603.5,
        "First Year": 1993,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1999,
        "MLY First Year": 1993,
        "MLY Last Year": 1999
      },
      {
        "Name": "FALHER",
        "Province": "ALBERTA",
        "Climate ID": "3072600",
        "Station ID": 2698,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.73,
        "Longitude (Decimal Degrees)": -117.2,
        "Latitude": 554400000,
        "Longitude": -1171200000,
        "Elevation (m)": 582.2,
        "First Year": 1948,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1979,
        "MLY First Year": 1948,
        "MLY Last Year": 1979
      },
      {
        "Name": "FISH CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3072618",
        "Station ID": 2699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.47,
        "Longitude (Decimal Degrees)": -117.4,
        "Latitude": 552800000,
        "Longitude": -1172400000,
        "Elevation (m)": 586.7,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "FOGGY LO",
        "Province": "ALBERTA",
        "Climate ID": "3072635",
        "Station ID": 2700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.68,
        "Longitude (Decimal Degrees)": -114.98,
        "Latitude": 584100000,
        "Longitude": -1145900000,
        "Elevation (m)": 883.9,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "FONTAS LO",
        "Province": "ALBERTA",
        "Climate ID": "3072637",
        "Station ID": 2701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.78,
        "Longitude (Decimal Degrees)": -119.5,
        "Latitude": 574700000,
        "Longitude": -1193000000,
        "Elevation (m)": 1036.3,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "FOOTNER LAKE HQ",
        "Province": "ALBERTA",
        "Climate ID": "3072638",
        "Station ID": 2702,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.62,
        "Longitude (Decimal Degrees)": -117.15,
        "Latitude": 583700000,
        "Longitude": -1170900000,
        "Elevation (m)": 320,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "FORT CHIPEWYAN",
        "Province": "ALBERTA",
        "Climate ID": "3072655",
        "Station ID": 48975,
        "WMO ID": 71305,
        "TC ID": "YPY",
        "Latitude (Decimal Degrees)": 58.77,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 584602000,
        "Longitude": -1110702000,
        "Elevation (m)": 238,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT CHIPEWYAN",
        "Province": "ALBERTA",
        "Climate ID": "3072657",
        "Station ID": 2703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.72,
        "Longitude (Decimal Degrees)": -111.15,
        "Latitude": 584300000,
        "Longitude": -1110900000,
        "Elevation (m)": 227.7,
        "First Year": 1883,
        "Last Year": 1967,
        "HLY First Year": 1962,
        "HLY Last Year": 1967,
        "DLY First Year": 1883,
        "DLY Last Year": 1967,
        "MLY First Year": 1883,
        "MLY Last Year": 1967
      },
      {
        "Name": "FORT CHIPEWYAN A",
        "Province": "ALBERTA",
        "Climate ID": "3072658",
        "Station ID": 2704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.77,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 584600000,
        "Longitude": -1110700000,
        "Elevation (m)": 232,
        "First Year": 1967,
        "Last Year": 2007,
        "HLY First Year": 1967,
        "HLY Last Year": 2006,
        "DLY First Year": 1967,
        "DLY Last Year": 2007,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT CHIPEWYAN RCS",
        "Province": "ALBERTA",
        "Climate ID": "3072659",
        "Station ID": 50757,
        "WMO ID": 71056,
        "TC ID": "PCH",
        "Latitude (Decimal Degrees)": 58.77,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 584602000,
        "Longitude": -1110702000,
        "Elevation (m)": 238,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT CHIPEWYAN AWOS A",
        "Province": "ALBERTA",
        "Climate ID": "3072662",
        "Station ID": 31608,
        "WMO ID": null,
        "TC ID": "PCP",
        "Latitude (Decimal Degrees)": 58.77,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 584602000,
        "Longitude": -1110702000,
        "Elevation (m)": 238,
        "First Year": 2006,
        "Last Year": 2016,
        "HLY First Year": 2006,
        "HLY Last Year": 2016,
        "DLY First Year": 2006,
        "DLY Last Year": 2011,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT VERMILION",
        "Province": "ALBERTA",
        "Climate ID": "3072719",
        "Station ID": 2705,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.45,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 582700000,
        "Longitude": -1160300000,
        "Elevation (m)": null,
        "First Year": 1888,
        "Last Year": 1917,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1917,
        "MLY First Year": 1888,
        "MLY Last Year": 1917
      },
      {
        "Name": "FORT VERMILION CDA",
        "Province": "ALBERTA",
        "Climate ID": "3072720",
        "Station ID": 2706,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.38,
        "Longitude (Decimal Degrees)": -116.03,
        "Latitude": 582300000,
        "Longitude": -1160200000,
        "Elevation (m)": 278.9,
        "First Year": 1908,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1985,
        "MLY First Year": 1908,
        "MLY Last Year": 1985
      },
      {
        "Name": "FORT VERMILION",
        "Province": "ALBERTA",
        "Climate ID": "3072723",
        "Station ID": 30495,
        "WMO ID": 71024,
        "TC ID": "XFV",
        "Latitude (Decimal Degrees)": 58.38,
        "Longitude (Decimal Degrees)": -116.04,
        "Latitude": 582256300,
        "Longitude": -1160224600,
        "Elevation (m)": 289,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT VERMILION",
        "Province": "ALBERTA",
        "Climate ID": "3072730",
        "Station ID": 2707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.38,
        "Longitude (Decimal Degrees)": -116.05,
        "Latitude": 582300000,
        "Longitude": -1160300000,
        "Elevation (m)": 282.9,
        "First Year": 1955,
        "Last Year": 1968,
        "HLY First Year": 1955,
        "HLY Last Year": 1968,
        "DLY First Year": 1963,
        "DLY Last Year": 1963,
        "MLY First Year": 1963,
        "MLY Last Year": 1963
      },
      {
        "Name": "FORT VERMILION RS",
        "Province": "ALBERTA",
        "Climate ID": "3072732",
        "Station ID": 2708,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.4,
        "Longitude (Decimal Degrees)": -116.03,
        "Latitude": 582400000,
        "Longitude": -1160200000,
        "Elevation (m)": 246.9,
        "First Year": 1962,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1989,
        "MLY First Year": 1962,
        "MLY Last Year": 1989
      },
      {
        "Name": "FOX CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3072734",
        "Station ID": 2709,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -116.8,
        "Latitude": 542400000,
        "Longitude": -1164800000,
        "Elevation (m)": 841.2,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "FOX CREEK RS",
        "Province": "ALBERTA",
        "Climate ID": "3072735",
        "Station ID": 2710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -116.93,
        "Latitude": 542400000,
        "Longitude": -1165600000,
        "Elevation (m)": 841.2,
        "First Year": 1966,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1999,
        "MLY First Year": 1966,
        "MLY Last Year": 1999
      },
      {
        "Name": "GARDEN RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3072768",
        "Station ID": 2711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.72,
        "Longitude (Decimal Degrees)": -113.9,
        "Latitude": 584300000,
        "Longitude": -1135400000,
        "Elevation (m)": 243.8,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "GIFT LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3072783",
        "Station ID": 2712,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.9,
        "Longitude (Decimal Degrees)": -115.75,
        "Latitude": 555400000,
        "Longitude": -1154500000,
        "Elevation (m)": 678,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "GOODFARE CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3072840",
        "Station ID": 2714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.27,
        "Longitude (Decimal Degrees)": -119.77,
        "Latitude": 551600000,
        "Longitude": -1194600000,
        "Elevation (m)": 762,
        "First Year": 1929,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1978,
        "MLY First Year": 1929,
        "MLY Last Year": 1978
      },
      {
        "Name": "GRANDE CACHE",
        "Province": "ALBERTA",
        "Climate ID": "307288R",
        "Station ID": 2715,
        "WMO ID": null,
        "TC ID": "WAM",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -118.87,
        "Latitude": 535500000,
        "Longitude": -1185200000,
        "Elevation (m)": 1255.2,
        "First Year": 1985,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2005,
        "MLY First Year": 1985,
        "MLY Last Year": 2005
      },
      {
        "Name": "GRANDE CACHE S.T.P.",
        "Province": "ALBERTA",
        "Climate ID": "3072895",
        "Station ID": 43783,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -119.16,
        "Latitude": 535203050,
        "Longitude": -1190928050,
        "Elevation (m)": 1200,
        "First Year": 2005,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2008,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRANDE CACHE MILNER",
        "Province": "ALBERTA",
        "Climate ID": "30728QR",
        "Station ID": 2713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -119.1,
        "Latitude": 540000000,
        "Longitude": -1190600000,
        "Elevation (m)": 1066.8,
        "First Year": 1976,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1981,
        "MLY First Year": 1976,
        "MLY Last Year": 1981
      },
      {
        "Name": "GRANDE CACHE RS",
        "Province": "ALBERTA",
        "Climate ID": "3072900",
        "Station ID": 2716,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.9,
        "Longitude (Decimal Degrees)": -119.1,
        "Latitude": 535400000,
        "Longitude": -1190600000,
        "Elevation (m)": 1249.7,
        "First Year": 1972,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1994,
        "MLY First Year": 1972,
        "MLY Last Year": 1994
      },
      {
        "Name": "GRANDE PRAIRIE",
        "Province": "ALBERTA",
        "Climate ID": "3072910",
        "Station ID": 2717,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.17,
        "Longitude (Decimal Degrees)": -118.8,
        "Latitude": 551000000,
        "Longitude": -1184800000,
        "Elevation (m)": 652.3,
        "First Year": 1922,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1936,
        "MLY First Year": 1922,
        "MLY Last Year": 1936
      },
      {
        "Name": "GRANDE PRAIRIE CR21X",
        "Province": "ALBERTA",
        "Climate ID": "3072915",
        "Station ID": 27215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.18,
        "Longitude (Decimal Degrees)": -118.88,
        "Latitude": 551100000,
        "Longitude": -1185300000,
        "Elevation (m)": 669,
        "First Year": 1996,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2004,
        "MLY First Year": 1996,
        "MLY Last Year": 2004
      },
      {
        "Name": "GRANDE PRAIRIE A",
        "Province": "ALBERTA",
        "Climate ID": "3072920",
        "Station ID": 2718,
        "WMO ID": 71940,
        "TC ID": "YQU",
        "Latitude (Decimal Degrees)": 55.18,
        "Longitude (Decimal Degrees)": -118.89,
        "Latitude": 551047000,
        "Longitude": -1185306000,
        "Elevation (m)": 669,
        "First Year": 1942,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1942,
        "DLY Last Year": 2013,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRANDE PRAIRIE A",
        "Province": "ALBERTA",
        "Climate ID": "3072921",
        "Station ID": 51422,
        "WMO ID": 71940,
        "TC ID": "YQU",
        "Latitude (Decimal Degrees)": 55.18,
        "Longitude (Decimal Degrees)": -118.88,
        "Latitude": 551048000,
        "Longitude": -1185305000,
        "Elevation (m)": 669,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GROVEDALE RS",
        "Province": "ALBERTA",
        "Climate ID": "3072970",
        "Station ID": 2719,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.02,
        "Longitude (Decimal Degrees)": -118.8,
        "Latitude": 550100000,
        "Longitude": -1184800000,
        "Elevation (m)": 701,
        "First Year": 1978,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2009,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "GUY",
        "Province": "ALBERTA",
        "Climate ID": "3072985",
        "Station ID": 2720,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.55,
        "Longitude (Decimal Degrees)": -117.1,
        "Latitude": 553300000,
        "Longitude": -1170600000,
        "Elevation (m)": 594.1,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "HAWK HILLS LO",
        "Province": "ALBERTA",
        "Climate ID": "3073070",
        "Station ID": 2722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.65,
        "Longitude (Decimal Degrees)": -117.42,
        "Latitude": 573900000,
        "Longitude": -1172500000,
        "Elevation (m)": 609.6,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "HAWK HILLS AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3073071",
        "Station ID": 47075,
        "WMO ID": 71507,
        "TC ID": "PHW",
        "Latitude (Decimal Degrees)": 57.26,
        "Longitude (Decimal Degrees)": -117.3,
        "Latitude": 571553001,
        "Longitude": -1171751009,
        "Elevation (m)": 455,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAY CAMP TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3073072",
        "Station ID": 2723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.88,
        "Longitude (Decimal Degrees)": -111.48,
        "Latitude": 595300000,
        "Longitude": -1112900000,
        "Elevation (m)": 243.8,
        "First Year": 1968,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1990,
        "MLY First Year": 1968,
        "MLY Last Year": 1990
      },
      {
        "Name": "HAY LAKES RS",
        "Province": "ALBERTA",
        "Climate ID": "3073073",
        "Station ID": 2724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.73,
        "Longitude (Decimal Degrees)": -118.68,
        "Latitude": 584400000,
        "Longitude": -1184100000,
        "Elevation (m)": 317,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "HIGH LEVEL A",
        "Province": "ALBERTA",
        "Climate ID": "3073140",
        "Station ID": 49928,
        "WMO ID": 71066,
        "TC ID": "YOJ",
        "Latitude (Decimal Degrees)": 58.62,
        "Longitude (Decimal Degrees)": -117.16,
        "Latitude": 583718000,
        "Longitude": -1170953000,
        "Elevation (m)": 338.02,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HIGH LEVEL A",
        "Province": "ALBERTA",
        "Climate ID": "3073141",
        "Station ID": 50622,
        "WMO ID": null,
        "TC ID": "YOJ",
        "Latitude (Decimal Degrees)": 58.62,
        "Longitude (Decimal Degrees)": -117.16,
        "Latitude": 583718000,
        "Longitude": -1170953000,
        "Elevation (m)": 338,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HIGH LEVEL A",
        "Province": "ALBERTA",
        "Climate ID": "3073145",
        "Station ID": 2725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.52,
        "Longitude (Decimal Degrees)": -117.1,
        "Latitude": 583100000,
        "Longitude": -1170600000,
        "Elevation (m)": 326.7,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": 1967,
        "HLY Last Year": 1970,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "HIGH LEVEL A",
        "Province": "ALBERTA",
        "Climate ID": "3073146",
        "Station ID": 2726,
        "WMO ID": null,
        "TC ID": "YOJ",
        "Latitude (Decimal Degrees)": 58.62,
        "Longitude (Decimal Degrees)": -117.16,
        "Latitude": 583717000,
        "Longitude": -1170953000,
        "Elevation (m)": 338.02,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": 1970,
        "HLY Last Year": 2012,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "HIGH LEVEL",
        "Province": "ALBERTA",
        "Climate ID": "3073148",
        "Station ID": 55118,
        "WMO ID": 73085,
        "TC ID": "PHJ",
        "Latitude (Decimal Degrees)": 58.63,
        "Longitude (Decimal Degrees)": -117.16,
        "Latitude": 583753030,
        "Longitude": -1170950015,
        "Elevation (m)": 339,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HIGH LEVEL RS",
        "Province": "ALBERTA",
        "Climate ID": "3073150",
        "Station ID": 2727,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.52,
        "Longitude (Decimal Degrees)": -117.1,
        "Latitude": 583100000,
        "Longitude": -1170600000,
        "Elevation (m)": 324,
        "First Year": 1962,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1979,
        "MLY First Year": 1962,
        "MLY Last Year": 1979
      },
      {
        "Name": "HIGH PRAIRIE WEST",
        "Province": "ALBERTA",
        "Climate ID": "3073190",
        "Station ID": 2728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.37,
        "Longitude (Decimal Degrees)": -116.87,
        "Latitude": 552200000,
        "Longitude": -1165200000,
        "Elevation (m)": 594.4,
        "First Year": 1970,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1982,
        "MLY First Year": 1970,
        "MLY Last Year": 1982
      },
      {
        "Name": "HIGH ROCK TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3073245",
        "Station ID": 2729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.83,
        "Longitude (Decimal Degrees)": -111.55,
        "Latitude": 585000000,
        "Longitude": -1113300000,
        "Elevation (m)": 211.8,
        "First Year": 1968,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1974,
        "MLY First Year": 1968,
        "MLY Last Year": 1974
      },
      {
        "Name": "HINES CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3073315",
        "Station ID": 8905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.42,
        "Longitude (Decimal Degrees)": -118.6,
        "Latitude": 562500000,
        "Longitude": -1183600000,
        "Elevation (m)": 705.6,
        "First Year": 1963,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1964,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HINES CREEK EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3073320",
        "Station ID": 2730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.18,
        "Longitude (Decimal Degrees)": -118.53,
        "Latitude": 561100000,
        "Longitude": -1183200000,
        "Elevation (m)": 655.3,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1958,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "HINES CREEK RS",
        "Province": "ALBERTA",
        "Climate ID": "3073323",
        "Station ID": 2731,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -118.62,
        "Latitude": 561500000,
        "Longitude": -1183700000,
        "Elevation (m)": 661.4,
        "First Year": 1966,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2009,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOTCHKISS",
        "Province": "ALBERTA",
        "Climate ID": "3073345",
        "Station ID": 27607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.02,
        "Longitude (Decimal Degrees)": -117.6,
        "Latitude": 570100000,
        "Longitude": -1173600000,
        "Elevation (m)": 453.2,
        "First Year": 1998,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2003,
        "MLY First Year": 1998,
        "MLY Last Year": 2003
      },
      {
        "Name": "HOTCHKISS LO",
        "Province": "ALBERTA",
        "Climate ID": "3073346",
        "Station ID": 2732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.33,
        "Longitude (Decimal Degrees)": -118.95,
        "Latitude": 572000000,
        "Longitude": -1185700000,
        "Elevation (m)": 944.9,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "HYTHE",
        "Province": "ALBERTA",
        "Climate ID": "3073425",
        "Station ID": 2733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.3,
        "Longitude (Decimal Degrees)": -119.58,
        "Latitude": 551800000,
        "Longitude": -1193500000,
        "Elevation (m)": 734.6,
        "First Year": 1962,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1968,
        "MLY First Year": 1962,
        "MLY Last Year": 1968
      },
      {
        "Name": "JACKPINE AFS",
        "Province": "ALBERTA",
        "Climate ID": "3073511",
        "Station ID": 27040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.88,
        "Longitude (Decimal Degrees)": -116.56,
        "Latitude": 565251000,
        "Longitude": -1163333000,
        "Elevation (m)": 663,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "JEAN COTE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3073535",
        "Station ID": 47077,
        "WMO ID": 71541,
        "TC ID": "PJT",
        "Latitude (Decimal Degrees)": 55.91,
        "Longitude (Decimal Degrees)": -117.12,
        "Latitude": 555446006,
        "Longitude": -1170711006,
        "Elevation (m)": 638,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "JEAN D'OR RS",
        "Province": "ALBERTA",
        "Climate ID": "3073545",
        "Station ID": 2734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.43,
        "Longitude (Decimal Degrees)": -115.05,
        "Latitude": 582600000,
        "Longitude": -1150300000,
        "Elevation (m)": 274.3,
        "First Year": 1969,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1973,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "JEAN LO",
        "Province": "ALBERTA",
        "Climate ID": "3073550",
        "Station ID": 2735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.52,
        "Longitude (Decimal Degrees)": -113.75,
        "Latitude": 573100000,
        "Longitude": -1134500000,
        "Elevation (m)": 762,
        "First Year": 1963,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2011,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "KAKWA",
        "Province": "ALBERTA",
        "Climate ID": "3073584",
        "Station ID": 2736,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.2,
        "Longitude (Decimal Degrees)": -118.9,
        "Latitude": 541200000,
        "Longitude": -1185400000,
        "Elevation (m)": 1246,
        "First Year": 1990,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2011,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "KAKWA LO",
        "Province": "ALBERTA",
        "Climate ID": "3073585",
        "Station ID": 2737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.43,
        "Longitude (Decimal Degrees)": -118.97,
        "Latitude": 542600000,
        "Longitude": -1185800000,
        "Elevation (m)": 1213.1,
        "First Year": 1956,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2011,
        "MLY First Year": 1956,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEG RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3073640",
        "Station ID": 2738,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.8,
        "Longitude (Decimal Degrees)": -117.87,
        "Latitude": 574800000,
        "Longitude": -1175200000,
        "Elevation (m)": 427.3,
        "First Year": 1935,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1980,
        "MLY First Year": 1935,
        "MLY Last Year": 1979
      },
      {
        "Name": "KEG RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3073641",
        "Station ID": 2739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.75,
        "Longitude (Decimal Degrees)": -117.62,
        "Latitude": 574500000,
        "Longitude": -1173700000,
        "Elevation (m)": 405.4,
        "First Year": 1965,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2009,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEG LO",
        "Province": "ALBERTA",
        "Climate ID": "3073642",
        "Station ID": 2740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.65,
        "Longitude (Decimal Degrees)": -118.35,
        "Latitude": 573900000,
        "Longitude": -1182100000,
        "Elevation (m)": 950,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEG RIVER LARSEN",
        "Province": "ALBERTA",
        "Climate ID": "3073643",
        "Station ID": 41757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.73,
        "Longitude (Decimal Degrees)": -117.01,
        "Latitude": 574400000,
        "Longitude": -1170038000,
        "Elevation (m)": 402,
        "First Year": 2003,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2006,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "KEG RIVER AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3073644",
        "Station ID": 51840,
        "WMO ID": null,
        "TC ID": "PKG",
        "Latitude (Decimal Degrees)": 57.79,
        "Longitude (Decimal Degrees)": -117.68,
        "Latitude": 574715001,
        "Longitude": -1174036000,
        "Elevation (m)": 408,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KLEWI TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3073655",
        "Station ID": 2741,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.92,
        "Longitude (Decimal Degrees)": -113.93,
        "Latitude": 595500000,
        "Longitude": -1135600000,
        "Elevation (m)": 304.8,
        "First Year": 1978,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1990,
        "MLY First Year": 1978,
        "MLY Last Year": 1990
      },
      {
        "Name": "KSITUAN",
        "Province": "ALBERTA",
        "Climate ID": "3073673",
        "Station ID": 2742,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.9,
        "Longitude (Decimal Degrees)": -119.07,
        "Latitude": 555400000,
        "Longitude": -1190400000,
        "Elevation (m)": 640.1,
        "First Year": 1980,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1993,
        "MLY First Year": 1980,
        "MLY Last Year": 1993
      },
      {
        "Name": "LAC CARDINAL",
        "Province": "ALBERTA",
        "Climate ID": "3073677",
        "Station ID": 2743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.27,
        "Longitude (Decimal Degrees)": -117.77,
        "Latitude": 561600000,
        "Longitude": -1174600000,
        "Elevation (m)": 649.2,
        "First Year": 1989,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1996,
        "MLY First Year": 1989,
        "MLY Last Year": 1996
      },
      {
        "Name": "LA CRETE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3073730",
        "Station ID": 47147,
        "WMO ID": 71542,
        "TC ID": "PLC",
        "Latitude (Decimal Degrees)": 58.17,
        "Longitude (Decimal Degrees)": -116.34,
        "Latitude": 581019008,
        "Longitude": -1162034002,
        "Elevation (m)": 323,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA CRETE RS",
        "Province": "ALBERTA",
        "Climate ID": "3073735",
        "Station ID": 2744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.18,
        "Longitude (Decimal Degrees)": -116.4,
        "Latitude": 581100000,
        "Longitude": -1162400000,
        "Elevation (m)": 327.4,
        "First Year": 1969,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1985,
        "MLY First Year": 1969,
        "MLY Last Year": 1985
      },
      {
        "Name": "LA GLACE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3073740",
        "Station ID": 47087,
        "WMO ID": 71543,
        "TC ID": "PLG",
        "Latitude (Decimal Degrees)": 55.42,
        "Longitude (Decimal Degrees)": -119.25,
        "Latitude": 552519001,
        "Longitude": -1191517002,
        "Elevation (m)": 760,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAMBERT CREEK LO",
        "Province": "ALBERTA",
        "Climate ID": "3073764",
        "Station ID": 2745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.05,
        "Longitude (Decimal Degrees)": -114.15,
        "Latitude": 580300000,
        "Longitude": -1140900000,
        "Elevation (m)": 304.8,
        "First Year": 1969,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2011,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "LEGEND LO",
        "Province": "ALBERTA",
        "Climate ID": "3073792",
        "Station ID": 2746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.45,
        "Longitude (Decimal Degrees)": -112.88,
        "Latitude": 572700000,
        "Longitude": -1125300000,
        "Elevation (m)": 911.4,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "LITTLE RED RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3073924",
        "Station ID": 2747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.4,
        "Longitude (Decimal Degrees)": -114.75,
        "Latitude": 582400000,
        "Longitude": -1144500000,
        "Elevation (m)": 289.6,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "LOON LAKE AFS",
        "Province": "ALBERTA",
        "Climate ID": "3073986",
        "Station ID": 27039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.13,
        "Longitude (Decimal Degrees)": -115.07,
        "Latitude": 570759000,
        "Longitude": -1150400000,
        "Elevation (m)": 472,
        "First Year": 1995,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 1995,
        "MLY First Year": 1995,
        "MLY Last Year": 1995
      },
      {
        "Name": "KIMIWAN LO",
        "Province": "ALBERTA",
        "Climate ID": "3073F4M",
        "Station ID": 2721,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56,
        "Longitude (Decimal Degrees)": -116.6,
        "Latitude": 560000000,
        "Longitude": -1163600000,
        "Elevation (m)": 770,
        "First Year": 1976,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2011,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "MANNING",
        "Province": "ALBERTA",
        "Climate ID": "3074180",
        "Station ID": 2748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.88,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 565300000,
        "Longitude": -1174200000,
        "Elevation (m)": 480.1,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "MANNING",
        "Province": "ALBERTA",
        "Climate ID": "3074181",
        "Station ID": 2749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.9,
        "Longitude (Decimal Degrees)": -117.63,
        "Latitude": 565400000,
        "Longitude": -1173800000,
        "Elevation (m)": 487.7,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "MANNING",
        "Province": "ALBERTA",
        "Climate ID": "3074182",
        "Station ID": 2750,
        "WMO ID": null,
        "TC ID": "WAI",
        "Latitude (Decimal Degrees)": 56.95,
        "Longitude (Decimal Degrees)": -117.64,
        "Latitude": 565703000,
        "Longitude": -1173839000,
        "Elevation (m)": 491.3,
        "First Year": 1985,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2019,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "MANNING RS",
        "Province": "ALBERTA",
        "Climate ID": "3074183",
        "Station ID": 2751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.92,
        "Longitude (Decimal Degrees)": -117.6,
        "Latitude": 565500000,
        "Longitude": -1173600000,
        "Elevation (m)": 460.2,
        "First Year": 1962,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1979,
        "MLY First Year": 1962,
        "MLY Last Year": 1979
      },
      {
        "Name": "MANNING",
        "Province": "ALBERTA",
        "Climate ID": "3074184",
        "Station ID": 27334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.95,
        "Longitude (Decimal Degrees)": -117.63,
        "Latitude": 565700000,
        "Longitude": -1173800000,
        "Elevation (m)": 483.3,
        "First Year": 1998,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2002,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MANNING AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3074190",
        "Station ID": 32394,
        "WMO ID": 71229,
        "TC ID": "XMG",
        "Latitude (Decimal Degrees)": 56.97,
        "Longitude (Decimal Degrees)": -117.45,
        "Latitude": 565826000,
        "Longitude": -1172703002,
        "Elevation (m)": 457,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "MCLENNAN CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3074320",
        "Station ID": 2752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.72,
        "Longitude (Decimal Degrees)": -116.95,
        "Latitude": 554300000,
        "Longitude": -1165700000,
        "Elevation (m)": 623,
        "First Year": 1951,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1978,
        "MLY First Year": 1951,
        "MLY Last Year": 1978
      },
      {
        "Name": "MCLENNAN RS",
        "Province": "ALBERTA",
        "Climate ID": "3074322",
        "Station ID": 2753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.72,
        "Longitude (Decimal Degrees)": -116.95,
        "Latitude": 554300000,
        "Longitude": -1165700000,
        "Elevation (m)": 627,
        "First Year": 1962,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1979,
        "MLY First Year": 1962,
        "MLY Last Year": 1979
      },
      {
        "Name": "MEANDER RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3074390",
        "Station ID": 2754,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.12,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 590700000,
        "Longitude": -1174200000,
        "Elevation (m)": 320,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "MEEKWAP",
        "Province": "ALBERTA",
        "Climate ID": "3074494",
        "Station ID": 2755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.62,
        "Longitude (Decimal Degrees)": -116.67,
        "Latitude": 543700000,
        "Longitude": -1164000000,
        "Elevation (m)": 836,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "MISTANUSK CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3074582",
        "Station ID": 27036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.59,
        "Longitude (Decimal Degrees)": -120.01,
        "Latitude": 543534000,
        "Longitude": -1200033000,
        "Elevation (m)": 1112,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "MUSKEG RS",
        "Province": "ALBERTA",
        "Climate ID": "3074743",
        "Station ID": 2756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -118.65,
        "Latitude": 535500000,
        "Longitude": -1183900000,
        "Elevation (m)": 1226.8,
        "First Year": 1962,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1970,
        "MLY First Year": 1962,
        "MLY Last Year": 1970
      },
      {
        "Name": "MUSKWA LO",
        "Province": "ALBERTA",
        "Climate ID": "3074750",
        "Station ID": 2757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.08,
        "Longitude (Decimal Degrees)": -114.65,
        "Latitude": 560500000,
        "Longitude": -1143900000,
        "Elevation (m)": 624.8,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "NAYLOR HILLS LO",
        "Province": "ALBERTA",
        "Climate ID": "3074780",
        "Station ID": 2758,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.67,
        "Longitude (Decimal Degrees)": -117.72,
        "Latitude": 574000000,
        "Longitude": -1174300000,
        "Elevation (m)": 731.5,
        "First Year": 1955,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1975,
        "MLY First Year": 1955,
        "MLY Last Year": 1975
      },
      {
        "Name": "NINA LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3074808",
        "Station ID": 2759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.32,
        "Longitude (Decimal Degrees)": -117.2,
        "Latitude": 571900000,
        "Longitude": -1171200000,
        "Elevation (m)": 453.2,
        "First Year": 1980,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1998,
        "MLY First Year": 1980,
        "MLY Last Year": 1998
      },
      {
        "Name": "NORTH STAR",
        "Province": "ALBERTA",
        "Climate ID": "3074874",
        "Station ID": 2760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.83,
        "Longitude (Decimal Degrees)": -117.57,
        "Latitude": 565000000,
        "Longitude": -1173400000,
        "Elevation (m)": 485,
        "First Year": 1973,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1989,
        "MLY First Year": 1973,
        "MLY Last Year": 1989
      },
      {
        "Name": "NOSE MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3074880",
        "Station ID": 2761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.55,
        "Longitude (Decimal Degrees)": -119.58,
        "Latitude": 543300000,
        "Longitude": -1193500000,
        "Elevation (m)": 1574.3,
        "First Year": 1949,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2011,
        "MLY First Year": 1949,
        "MLY Last Year": 2007
      },
      {
        "Name": "NOTIKEWIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3074900",
        "Station ID": 2762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.87,
        "Longitude (Decimal Degrees)": -118.58,
        "Latitude": 565200000,
        "Longitude": -1183500000,
        "Elevation (m)": 762,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "NOTIKEWIN EAST",
        "Province": "ALBERTA",
        "Climate ID": "3074902",
        "Station ID": 2763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.02,
        "Longitude (Decimal Degrees)": -117.57,
        "Latitude": 570100000,
        "Longitude": -1173400000,
        "Elevation (m)": 464.8,
        "First Year": 1962,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1994,
        "MLY First Year": 1962,
        "MLY Last Year": 1994
      },
      {
        "Name": "CLEARDALE AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3074935",
        "Station ID": 32255,
        "WMO ID": 71221,
        "TC ID": "XCD",
        "Latitude (Decimal Degrees)": 56.31,
        "Longitude (Decimal Degrees)": -119.75,
        "Latitude": 561852009,
        "Longitude": -1194442000,
        "Elevation (m)": 630,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": 1990,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2007
      },
      {
        "Name": "OTTER LAKES LO",
        "Province": "ALBERTA",
        "Climate ID": "3074940",
        "Station ID": 2764,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.7,
        "Longitude (Decimal Degrees)": -115.77,
        "Latitude": 564200000,
        "Longitude": -1154600000,
        "Elevation (m)": 731.5,
        "First Year": 1962,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2011,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "PANNY LO",
        "Province": "ALBERTA",
        "Climate ID": "3074983",
        "Station ID": 2765,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.18,
        "Longitude (Decimal Degrees)": -114.62,
        "Latitude": 571100000,
        "Longitude": -1143700000,
        "Elevation (m)": 691.9,
        "First Year": 1966,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "PARSON LAKE TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3074999",
        "Station ID": 2766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.88,
        "Longitude (Decimal Degrees)": -112.48,
        "Latitude": 595300000,
        "Longitude": -1122900000,
        "Elevation (m)": 237.7,
        "First Year": 1968,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1990,
        "MLY First Year": 1968,
        "MLY Last Year": 1990
      },
      {
        "Name": "PEACE POINT TOWER",
        "Province": "ALBERTA",
        "Climate ID": "3075020",
        "Station ID": 2768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.2,
        "Longitude (Decimal Degrees)": -112.42,
        "Latitude": 591200000,
        "Longitude": -1122500000,
        "Elevation (m)": 237.7,
        "First Year": 1968,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1973,
        "MLY First Year": 1968,
        "MLY Last Year": 1973
      },
      {
        "Name": "PEACE RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3075038",
        "Station ID": 2769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -117.28,
        "Latitude": 561500000,
        "Longitude": -1171700000,
        "Elevation (m)": 326.1,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEACE RIVER A",
        "Province": "ALBERTA",
        "Climate ID": "3075040",
        "Station ID": 2770,
        "WMO ID": 71068,
        "TC ID": "YPE",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -117.45,
        "Latitude": 561337000,
        "Longitude": -1172650000,
        "Elevation (m)": 570.9,
        "First Year": 1944,
        "Last Year": 2014,
        "HLY First Year": 1955,
        "HLY Last Year": 2014,
        "DLY First Year": 1944,
        "DLY Last Year": 2014,
        "MLY First Year": 1944,
        "MLY Last Year": 2007
      },
      {
        "Name": "PEACE RIVER A",
        "Province": "ALBERTA",
        "Climate ID": "3075041",
        "Station ID": 52258,
        "WMO ID": 71068,
        "TC ID": "YPE",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -117.45,
        "Latitude": 561338000,
        "Longitude": -1172654000,
        "Elevation (m)": 570.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROMA",
        "Province": "ALBERTA",
        "Climate ID": "3075043",
        "Station ID": 55098,
        "WMO ID": 73104,
        "TC ID": "PRV",
        "Latitude (Decimal Degrees)": 56.19,
        "Longitude (Decimal Degrees)": -117.45,
        "Latitude": 561135096,
        "Longitude": -1172707005,
        "Elevation (m)": 559.53,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEACE RIVER CKYL",
        "Province": "ALBERTA",
        "Climate ID": "3075045",
        "Station ID": 2771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -117.28,
        "Latitude": 561400000,
        "Longitude": -1171700000,
        "Elevation (m)": 336.5,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEACE RIVER CROSSING",
        "Province": "ALBERTA",
        "Climate ID": "3075047",
        "Station ID": 2772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -117.25,
        "Latitude": 561500000,
        "Longitude": -1171500000,
        "Elevation (m)": 373.4,
        "First Year": 1907,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1935,
        "MLY First Year": 1907,
        "MLY Last Year": 1935
      },
      {
        "Name": "PEACE RIVER FOREST HQ",
        "Province": "ALBERTA",
        "Climate ID": "3075048",
        "Station ID": 2773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.22,
        "Longitude (Decimal Degrees)": -117.27,
        "Latitude": 561300000,
        "Longitude": -1171600000,
        "Elevation (m)": 372.5,
        "First Year": 1968,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1969,
        "MLY First Year": 1968,
        "MLY Last Year": 1969
      },
      {
        "Name": "PEACE RIVER WRB",
        "Province": "ALBERTA",
        "Climate ID": "3075050",
        "Station ID": 2774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -117.28,
        "Latitude": 561400000,
        "Longitude": -1171700000,
        "Elevation (m)": 317,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "PEACE R SHAFTSBURY",
        "Province": "ALBERTA",
        "Climate ID": "3075055",
        "Station ID": 2775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.15,
        "Longitude (Decimal Degrees)": -117.43,
        "Latitude": 560900000,
        "Longitude": -1172600000,
        "Elevation (m)": 344.4,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "PEERLESS LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3075100",
        "Station ID": 2777,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.63,
        "Longitude (Decimal Degrees)": -114.6,
        "Latitude": 563800000,
        "Longitude": -1143600000,
        "Elevation (m)": 694.9,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "PEORIA AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3075160",
        "Station ID": 31468,
        "WMO ID": 71348,
        "TC ID": "XPA",
        "Latitude (Decimal Degrees)": 55.62,
        "Longitude (Decimal Degrees)": -118.29,
        "Latitude": 553716005,
        "Longitude": -1181735001,
        "Elevation (m)": 621.21,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "PETITOT LO",
        "Province": "ALBERTA",
        "Climate ID": "3075171",
        "Station ID": 2778,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.53,
        "Longitude (Decimal Degrees)": -119.62,
        "Latitude": 593200000,
        "Longitude": -1193700000,
        "Elevation (m)": 777.2,
        "First Year": 1974,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2011,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "PICADELLY",
        "Province": "ALBERTA",
        "Climate ID": "3075172",
        "Station ID": 2779,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.65,
        "Longitude (Decimal Degrees)": -114.53,
        "Latitude": 573900000,
        "Longitude": -1143200000,
        "Elevation (m)": 456,
        "First Year": 1989,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2011,
        "MLY First Year": 1989,
        "MLY Last Year": 2007
      },
      {
        "Name": "PENANCE TOWER",
        "Province": "ALBERTA",
        "Climate ID": "30751F7",
        "Station ID": 2776,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.38,
        "Longitude (Decimal Degrees)": -113.15,
        "Latitude": 582300000,
        "Longitude": -1130900000,
        "Elevation (m)": 290,
        "First Year": 1986,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1990,
        "MLY First Year": 1986,
        "MLY Last Year": 1990
      },
      {
        "Name": "PINE RS",
        "Province": "ALBERTA",
        "Climate ID": "3075223",
        "Station ID": 2780,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.48,
        "Longitude (Decimal Degrees)": -116.98,
        "Latitude": 542900000,
        "Longitude": -1165900000,
        "Elevation (m)": 762,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "PINTO LO",
        "Province": "ALBERTA",
        "Climate ID": "3075225",
        "Station ID": 2781,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.78,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 544700000,
        "Longitude": -1192400000,
        "Elevation (m)": 1066.8,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "PONDEROSA",
        "Province": "ALBERTA",
        "Climate ID": "3075270",
        "Station ID": 27031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.21,
        "Longitude (Decimal Degrees)": -117.06,
        "Latitude": 591218000,
        "Longitude": -1170333000,
        "Elevation (m)": 395,
        "First Year": 1995,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2010,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "PONTON LO",
        "Province": "ALBERTA",
        "Climate ID": "3075285",
        "Station ID": 2782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.93,
        "Longitude (Decimal Degrees)": -116.22,
        "Latitude": 585600000,
        "Longitude": -1161300000,
        "Elevation (m)": 883.9,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "PUSKWASKAU LO",
        "Province": "ALBERTA",
        "Climate ID": "3075320",
        "Station ID": 2783,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.22,
        "Longitude (Decimal Degrees)": -117.5,
        "Latitude": 551300000,
        "Longitude": -1173000000,
        "Elevation (m)": 972.3,
        "First Year": 1944,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 2011,
        "MLY First Year": 1944,
        "MLY Last Year": 2007
      },
      {
        "Name": "RAINBOW LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3075348",
        "Station ID": 2784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.48,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 582900000,
        "Longitude": -1192400000,
        "Elevation (m)": 530.4,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "RAINBOW RS",
        "Province": "ALBERTA",
        "Climate ID": "3075349",
        "Station ID": 2785,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.5,
        "Longitude (Decimal Degrees)": -119.42,
        "Latitude": 583000000,
        "Longitude": -1192500000,
        "Elevation (m)": 536.4,
        "First Year": 1970,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1995,
        "MLY First Year": 1970,
        "MLY Last Year": 1995
      },
      {
        "Name": "RED EARTH",
        "Province": "ALBERTA",
        "Climate ID": "3075488",
        "Station ID": 10183,
        "WMO ID": 71246,
        "TC ID": "WRD",
        "Latitude (Decimal Degrees)": 56.55,
        "Longitude (Decimal Degrees)": -115.28,
        "Latitude": 563307070,
        "Longitude": -1151640040,
        "Elevation (m)": 546,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED EARTH",
        "Province": "ALBERTA",
        "Climate ID": "3075489",
        "Station ID": 2787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.55,
        "Longitude (Decimal Degrees)": -115.37,
        "Latitude": 563300000,
        "Longitude": -1152200000,
        "Elevation (m)": 518.2,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "RED EARTH LO",
        "Province": "ALBERTA",
        "Climate ID": "3075491",
        "Station ID": 2788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.67,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 564000000,
        "Longitude": -1150700000,
        "Elevation (m)": 609.6,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "RED EARTH RS",
        "Province": "ALBERTA",
        "Climate ID": "30754R1",
        "Station ID": 2786,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.63,
        "Longitude (Decimal Degrees)": -115.12,
        "Latitude": 563800000,
        "Longitude": -1150700000,
        "Elevation (m)": 518.2,
        "First Year": 1983,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1990,
        "MLY First Year": 1983,
        "MLY Last Year": 1990
      },
      {
        "Name": "ROCKY LANE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3075550",
        "Station ID": 51841,
        "WMO ID": null,
        "TC ID": "PKY",
        "Latitude (Decimal Degrees)": 58.45,
        "Longitude (Decimal Degrees)": -116.48,
        "Latitude": 582706057,
        "Longitude": -1162850015,
        "Elevation (m)": 301,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RYCROFT",
        "Province": "ALBERTA",
        "Climate ID": "3075600",
        "Station ID": 2789,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.75,
        "Longitude (Decimal Degrees)": -118.78,
        "Latitude": 554500000,
        "Longitude": -1184700000,
        "Elevation (m)": 609.6,
        "First Year": 1931,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1978,
        "MLY First Year": 1931,
        "MLY Last Year": 1977
      },
      {
        "Name": "RYCROFT AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3075601",
        "Station ID": 47090,
        "WMO ID": 71546,
        "TC ID": "PRF",
        "Latitude (Decimal Degrees)": 55.78,
        "Longitude (Decimal Degrees)": -118.67,
        "Latitude": 554636005,
        "Longitude": -1184005003,
        "Elevation (m)": 575,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SADDLE HILLS LO",
        "Province": "ALBERTA",
        "Climate ID": "3075688",
        "Station ID": 2790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.62,
        "Longitude (Decimal Degrees)": -119.72,
        "Latitude": 553700000,
        "Longitude": -1194300000,
        "Elevation (m)": 960.1,
        "First Year": 1965,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2007,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "SASKATOON MOUNTAIN",
        "Province": "ALBERTA",
        "Climate ID": "3075760",
        "Station ID": 2791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.25,
        "Longitude (Decimal Degrees)": -119.35,
        "Latitude": 551500000,
        "Longitude": -1192100000,
        "Elevation (m)": 792.5,
        "First Year": 1921,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1951,
        "MLY First Year": 1921,
        "MLY Last Year": 1951
      },
      {
        "Name": "SAVANNA AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3075770",
        "Station ID": 47107,
        "WMO ID": 71547,
        "TC ID": "PSW",
        "Latitude (Decimal Degrees)": 56.08,
        "Longitude (Decimal Degrees)": -119.34,
        "Latitude": 560434002,
        "Longitude": -1192041008,
        "Elevation (m)": 703,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SEAFORTH LO",
        "Province": "ALBERTA",
        "Climate ID": "3075790",
        "Station ID": 2792,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.23,
        "Longitude (Decimal Degrees)": -113.35,
        "Latitude": 571400000,
        "Longitude": -1132100000,
        "Elevation (m)": 823,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1961,
        "MLY Last Year": 1969
      },
      {
        "Name": "SEXSMITH WIEBE",
        "Province": "ALBERTA",
        "Climate ID": "3075858",
        "Station ID": 6863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.45,
        "Longitude (Decimal Degrees)": -118.64,
        "Latitude": 552714001,
        "Longitude": -1183823008,
        "Elevation (m)": 765,
        "First Year": 1993,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2010,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "SIMONETTE",
        "Province": "ALBERTA",
        "Climate ID": "3075937",
        "Station ID": 2793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.42,
        "Longitude (Decimal Degrees)": -117.74,
        "Latitude": 542529008,
        "Longitude": -1174428003,
        "Elevation (m)": 883.9,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "SIMONETTE LO",
        "Province": "ALBERTA",
        "Climate ID": "3075940",
        "Station ID": 2794,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.23,
        "Longitude (Decimal Degrees)": -118.42,
        "Latitude": 541400000,
        "Longitude": -1182500000,
        "Elevation (m)": 1274.1,
        "First Year": 1956,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2011,
        "MLY First Year": 1956,
        "MLY Last Year": 2007
      },
      {
        "Name": "RAINBOW LO",
        "Province": "ALBERTA",
        "Climate ID": "3075L48",
        "Station ID": 2767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.35,
        "Longitude (Decimal Degrees)": -119.7,
        "Latitude": 582100000,
        "Longitude": -1194200000,
        "Elevation (m)": 579.1,
        "First Year": 1970,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2011,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "SMOKY LO",
        "Province": "ALBERTA",
        "Climate ID": "3076020",
        "Station ID": 2795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -118.3,
        "Latitude": 542400000,
        "Longitude": -1181800000,
        "Elevation (m)": 1158.2,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "SMOKY RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3076025",
        "Station ID": 2796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -119.13,
        "Latitude": 535900000,
        "Longitude": -1190800000,
        "Elevation (m)": 1219.2,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "SNUFF MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3076040",
        "Station ID": 2797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -117.53,
        "Latitude": 544100000,
        "Longitude": -1173200000,
        "Elevation (m)": 969.3,
        "First Year": 1953,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 2011,
        "MLY First Year": 1953,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOUSA CREEK (AUT)",
        "Province": "ALBERTA",
        "Climate ID": "3076056",
        "Station ID": 10733,
        "WMO ID": null,
        "TC ID": "WSH",
        "Latitude (Decimal Degrees)": 58.59,
        "Longitude (Decimal Degrees)": -118.49,
        "Latitude": 583529000,
        "Longitude": -1182935000,
        "Elevation (m)": 373.3,
        "First Year": 1994,
        "Last Year": 2002,
        "HLY First Year": 1994,
        "HLY Last Year": 2002,
        "DLY First Year": 1994,
        "DLY Last Year": 2002,
        "MLY First Year": 1996,
        "MLY Last Year": 2001
      },
      {
        "Name": "SOUTH WAPITI RS",
        "Province": "ALBERTA",
        "Climate ID": "3076060",
        "Station ID": 2798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.93,
        "Longitude (Decimal Degrees)": -119.2,
        "Latitude": 545600000,
        "Longitude": -1191200000,
        "Elevation (m)": 762,
        "First Year": 1962,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1981,
        "MLY First Year": 1962,
        "MLY Last Year": 1981
      },
      {
        "Name": "SPIRIT RIVER AUTO STATION",
        "Province": "ALBERTA",
        "Climate ID": "3076069",
        "Station ID": 29633,
        "WMO ID": 71571,
        "TC ID": "XSR",
        "Latitude (Decimal Degrees)": 55.69,
        "Longitude (Decimal Degrees)": -119.23,
        "Latitude": 554141008,
        "Longitude": -1191349004,
        "Elevation (m)": 1015.28,
        "First Year": 1999,
        "Last Year": 2018,
        "HLY First Year": 1999,
        "HLY Last Year": 2018,
        "DLY First Year": 1999,
        "DLY Last Year": 2018,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPIRIT RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3076071",
        "Station ID": 2799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.78,
        "Longitude (Decimal Degrees)": -118.83,
        "Latitude": 554700000,
        "Longitude": -1185000000,
        "Elevation (m)": 629.7,
        "First Year": 1966,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1986,
        "MLY First Year": 1966,
        "MLY Last Year": 1986
      },
      {
        "Name": "STEEN LO",
        "Province": "ALBERTA",
        "Climate ID": "3076108",
        "Station ID": 2800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.63,
        "Longitude (Decimal Degrees)": -117.78,
        "Latitude": 593800000,
        "Longitude": -1174700000,
        "Elevation (m)": 707.1,
        "First Year": 1964,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2011,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "STEEN RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3076110",
        "Station ID": 2801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.63,
        "Longitude (Decimal Degrees)": -117.17,
        "Latitude": 593800000,
        "Longitude": -1171000000,
        "Elevation (m)": 296.9,
        "First Year": 1962,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1997,
        "MLY First Year": 1962,
        "MLY Last Year": 1997
      },
      {
        "Name": "STEEPHILL CREEK",
        "Province": "ALBERTA",
        "Climate ID": "3076115",
        "Station ID": 2802,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.02,
        "Longitude (Decimal Degrees)": -116.58,
        "Latitude": 580100000,
        "Longitude": -1163500000,
        "Elevation (m)": 365.8,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1983
      },
      {
        "Name": "SWEATHOUSE LO",
        "Province": "ALBERTA",
        "Climate ID": "3076320",
        "Station ID": 2803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.92,
        "Longitude (Decimal Degrees)": -116.75,
        "Latitude": 545500000,
        "Longitude": -1164500000,
        "Elevation (m)": 853.4,
        "First Year": 1953,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 2011,
        "MLY First Year": 1953,
        "MLY Last Year": 2007
      },
      {
        "Name": "SWEETGRASS LANDING",
        "Province": "ALBERTA",
        "Climate ID": "3076330",
        "Station ID": 52478,
        "WMO ID": null,
        "TC ID": "PSQ",
        "Latitude (Decimal Degrees)": 58.85,
        "Longitude (Decimal Degrees)": -112,
        "Latitude": 585110400,
        "Longitude": -1115949200,
        "Elevation (m)": 212,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TALBOT LAKE LO",
        "Province": "ALBERTA",
        "Climate ID": "3076362",
        "Station ID": 2804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.33,
        "Longitude (Decimal Degrees)": -115.67,
        "Latitude": 572000000,
        "Longitude": -1154000000,
        "Elevation (m)": 883.9,
        "First Year": 1964,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2011,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "TANGENT",
        "Province": "ALBERTA",
        "Climate ID": "3076363",
        "Station ID": 2805,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.85,
        "Longitude (Decimal Degrees)": -117.68,
        "Latitude": 555100000,
        "Longitude": -1174100000,
        "Elevation (m)": 548.6,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "TEEPEE LO",
        "Province": "ALBERTA",
        "Climate ID": "3076365",
        "Station ID": 2806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.47,
        "Longitude (Decimal Degrees)": -114.12,
        "Latitude": 562800000,
        "Longitude": -1140700000,
        "Elevation (m)": 762,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "TEEPEE CREEK AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3076366",
        "Station ID": 47109,
        "WMO ID": 71690,
        "TC ID": "PTC",
        "Latitude (Decimal Degrees)": 55.35,
        "Longitude (Decimal Degrees)": -118.41,
        "Latitude": 552108007,
        "Longitude": -1182427004,
        "Elevation (m)": 670,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THREE CREEKS RS",
        "Province": "ALBERTA",
        "Climate ID": "3076470",
        "Station ID": 2807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.37,
        "Longitude (Decimal Degrees)": -117,
        "Latitude": 562200000,
        "Longitude": -1170000000,
        "Elevation (m)": 609.6,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "TOMPKINS LANDING AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3076530",
        "Station ID": 51858,
        "WMO ID": null,
        "TC ID": "NTL",
        "Latitude (Decimal Degrees)": 58.02,
        "Longitude (Decimal Degrees)": -116.85,
        "Latitude": 580114082,
        "Longitude": -1165052068,
        "Elevation (m)": 343,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TROUT LAKE",
        "Province": "ALBERTA",
        "Climate ID": "3076564",
        "Station ID": 2808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.5,
        "Longitude (Decimal Degrees)": -114.55,
        "Latitude": 563000000,
        "Longitude": -1143300000,
        "Elevation (m)": 649.2,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "TROUT MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3076566",
        "Station ID": 2809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.8,
        "Longitude (Decimal Degrees)": -114.42,
        "Latitude": 564800000,
        "Longitude": -1142500000,
        "Elevation (m)": 762,
        "First Year": 1960,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2011,
        "MLY First Year": 1960,
        "MLY Last Year": 2007
      },
      {
        "Name": "UNOCAL KAKWA",
        "Province": "ALBERTA",
        "Climate ID": "3076653",
        "Station ID": 6864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.44,
        "Longitude (Decimal Degrees)": -118.74,
        "Latitude": 542620000,
        "Longitude": -1184430000,
        "Elevation (m)": 1085.4,
        "First Year": 1993,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": 1993,
        "MLY Last Year": 1993
      },
      {
        "Name": "UPPER HAY RIVER RS",
        "Province": "ALBERTA",
        "Climate ID": "3076655",
        "Station ID": 2810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.12,
        "Longitude (Decimal Degrees)": -117.7,
        "Latitude": 590700000,
        "Longitude": -1174200000,
        "Elevation (m)": 320,
        "First Year": 1965,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1972,
        "MLY First Year": 1965,
        "MLY Last Year": 1972
      },
      {
        "Name": "VALLEYVIEW RS",
        "Province": "ALBERTA",
        "Climate ID": "3076675",
        "Station ID": 2811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.07,
        "Longitude (Decimal Degrees)": -117.27,
        "Latitude": 550400000,
        "Longitude": -1171600000,
        "Elevation (m)": 762,
        "First Year": 1962,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2009,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "VALLEYVIEW AGDM",
        "Province": "ALBERTA",
        "Climate ID": "3076680",
        "Station ID": 32460,
        "WMO ID": 71277,
        "TC ID": "XVW",
        "Latitude (Decimal Degrees)": 55.1,
        "Longitude (Decimal Degrees)": -117.2,
        "Latitude": 550553000,
        "Longitude": -1171157000,
        "Elevation (m)": 698,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2007
      },
      {
        "Name": "WABASCA",
        "Province": "ALBERTA",
        "Climate ID": "3076905",
        "Station ID": 2812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.03,
        "Longitude (Decimal Degrees)": -113.83,
        "Latitude": 560200000,
        "Longitude": -1135000000,
        "Elevation (m)": 524.3,
        "First Year": 1915,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1947,
        "MLY First Year": 1915,
        "MLY Last Year": 1947
      },
      {
        "Name": "WABASCA RS",
        "Province": "ALBERTA",
        "Climate ID": "3076908",
        "Station ID": 2813,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.97,
        "Longitude (Decimal Degrees)": -113.83,
        "Latitude": 555800000,
        "Longitude": -1135000000,
        "Elevation (m)": 544.7,
        "First Year": 1962,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2009,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "WADLIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3076913",
        "Station ID": 2814,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.78,
        "Longitude (Decimal Degrees)": -115.43,
        "Latitude": 574700000,
        "Longitude": -1152600000,
        "Elevation (m)": 792.5,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "WANHAM CDA",
        "Province": "ALBERTA",
        "Climate ID": "3076940",
        "Station ID": 2815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.78,
        "Longitude (Decimal Degrees)": -118.38,
        "Latitude": 554700000,
        "Longitude": -1182300000,
        "Elevation (m)": 560.5,
        "First Year": 1955,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1996,
        "MLY First Year": 1955,
        "MLY Last Year": 1996
      },
      {
        "Name": "WATINO",
        "Province": "ALBERTA",
        "Climate ID": "3077245",
        "Station ID": 2816,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.72,
        "Longitude (Decimal Degrees)": -117.62,
        "Latitude": 554300000,
        "Longitude": -1173700000,
        "Elevation (m)": 384,
        "First Year": 1962,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1974
      },
      {
        "Name": "WATINO",
        "Province": "ALBERTA",
        "Climate ID": "3077246",
        "Station ID": 2817,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.72,
        "Longitude (Decimal Degrees)": -117.63,
        "Latitude": 554300000,
        "Longitude": -1173800000,
        "Elevation (m)": 393.2,
        "First Year": 1974,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1997,
        "MLY First Year": 1974,
        "MLY Last Year": 1997
      },
      {
        "Name": "WATT MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3077250",
        "Station ID": 2818,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.65,
        "Longitude (Decimal Degrees)": -117.57,
        "Latitude": 583900000,
        "Longitude": -1173400000,
        "Elevation (m)": 701,
        "First Year": 1957,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2011,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITEFISH LO",
        "Province": "ALBERTA",
        "Climate ID": "3077420",
        "Station ID": 2819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.18,
        "Longitude (Decimal Degrees)": -115.47,
        "Latitude": 561100000,
        "Longitude": -1152800000,
        "Elevation (m)": 609.6,
        "First Year": 1958,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2011,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITELAW",
        "Province": "ALBERTA",
        "Climate ID": "3077430",
        "Station ID": 2820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -118.08,
        "Latitude": 560700000,
        "Longitude": -1180500000,
        "Elevation (m)": 670.6,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "WHITE MOUNTAIN LO",
        "Province": "ALBERTA",
        "Climate ID": "3077440",
        "Station ID": 2821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.7,
        "Longitude (Decimal Degrees)": -119.23,
        "Latitude": 554200000,
        "Longitude": -1191400000,
        "Elevation (m)": 1092.7,
        "First Year": 1955,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2011,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITEMUD GRAZING",
        "Province": "ALBERTA",
        "Climate ID": "3077478",
        "Station ID": 2822,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.57,
        "Longitude (Decimal Degrees)": -118,
        "Latitude": 563400000,
        "Longitude": -1180000000,
        "Elevation (m)": 685.8,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "WHITEMUD LO",
        "Province": "ALBERTA",
        "Climate ID": "3077480",
        "Station ID": 2823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.43,
        "Longitude (Decimal Degrees)": -118.02,
        "Latitude": 562600000,
        "Longitude": -1180100000,
        "Elevation (m)": 853.4,
        "First Year": 1954,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2011,
        "MLY First Year": 1954,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITESAND AFS",
        "Province": "ALBERTA",
        "Climate ID": "3077498",
        "Station ID": 27261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.43,
        "Longitude (Decimal Degrees)": -114.9,
        "Latitude": 592600000,
        "Longitude": -1145400000,
        "Elevation (m)": 962,
        "First Year": 1996,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2011,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "WHITESAND LO",
        "Province": "ALBERTA",
        "Climate ID": "3077500",
        "Station ID": 2824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.43,
        "Longitude (Decimal Degrees)": -114.9,
        "Latitude": 592600000,
        "Longitude": -1145400000,
        "Elevation (m)": 944.9,
        "First Year": 1968,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1996,
        "MLY First Year": 1968,
        "MLY Last Year": 1996
      },
      {
        "Name": "WOKING CDA",
        "Province": "ALBERTA",
        "Climate ID": "3077615",
        "Station ID": 2826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.62,
        "Longitude (Decimal Degrees)": -118.95,
        "Latitude": 553700000,
        "Longitude": -1185700000,
        "Elevation (m)": 726.9,
        "First Year": 1981,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1995,
        "MLY First Year": 1981,
        "MLY Last Year": 1995
      },
      {
        "Name": "WORSLEY",
        "Province": "ALBERTA",
        "Climate ID": "3077660",
        "Station ID": 2827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.5,
        "Longitude (Decimal Degrees)": -119.12,
        "Latitude": 563000000,
        "Longitude": -1190700000,
        "Elevation (m)": null,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1957,
        "MLY Last Year": 1957
      },
      {
        "Name": "WORSLEY-KOZACK",
        "Province": "ALBERTA",
        "Climate ID": "3077661",
        "Station ID": 6865,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.53,
        "Longitude (Decimal Degrees)": -119,
        "Latitude": 563200000,
        "Longitude": -1190001000,
        "Elevation (m)": 649.2,
        "First Year": 1993,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2011,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "WORSLEY RS",
        "Province": "ALBERTA",
        "Climate ID": "3077662",
        "Station ID": 2828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.5,
        "Longitude (Decimal Degrees)": -119.13,
        "Latitude": 563000000,
        "Longitude": -1190800000,
        "Elevation (m)": 640.1,
        "First Year": 1962,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1985,
        "MLY First Year": 1962,
        "MLY Last Year": 1985
      },
      {
        "Name": "YATES LO",
        "Province": "ALBERTA",
        "Climate ID": "3077678",
        "Station ID": 2829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.9,
        "Longitude (Decimal Degrees)": -116.33,
        "Latitude": 595400000,
        "Longitude": -1162000000,
        "Elevation (m)": 304.8,
        "First Year": 1969,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2011,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "ZAMA LO",
        "Province": "ALBERTA",
        "Climate ID": "3078000",
        "Station ID": 2830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.58,
        "Longitude (Decimal Degrees)": -119.17,
        "Latitude": 583500000,
        "Longitude": -1191000000,
        "Elevation (m)": 609.6,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "BISTCHO LAKE (F5)",
        "Province": "ALBERTA",
        "Climate ID": "307A010",
        "Station ID": 27827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.92,
        "Longitude (Decimal Degrees)": -118.95,
        "Latitude": 595500000,
        "Longitude": -1185700000,
        "Elevation (m)": 576,
        "First Year": 1999,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2004,
        "MLY First Year": 1999,
        "MLY Last Year": 2001
      },
      {
        "Name": "DAVIDSON LAKE TOWER",
        "Province": "ALBERTA",
        "Climate ID": "307A954",
        "Station ID": 2642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.88,
        "Longitude (Decimal Degrees)": -113.22,
        "Latitude": 585300000,
        "Longitude": -1131300000,
        "Elevation (m)": 253,
        "First Year": 1968,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1990,
        "MLY First Year": 1968,
        "MLY Last Year": 1990
      },
      {
        "Name": "GRANDE CACHE AUTO",
        "Province": "ALBERTA",
        "Climate ID": "307C001",
        "Station ID": 29573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -118.87,
        "Latitude": 535500000,
        "Longitude": -1185200000,
        "Elevation (m)": 1255,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2007,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "JOHN WAYNE PONDEROSA",
        "Province": "ALBERTA",
        "Climate ID": "307CE64",
        "Station ID": 2643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.2,
        "Longitude (Decimal Degrees)": -117.52,
        "Latitude": 591200000,
        "Longitude": -1173100000,
        "Elevation (m)": 395,
        "First Year": 1989,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1994,
        "MLY First Year": 1989,
        "MLY Last Year": 1994
      },
      {
        "Name": "LITTLE BUFFALO TOWER",
        "Province": "ALBERTA",
        "Climate ID": "307CR22",
        "Station ID": 2644,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.7,
        "Longitude (Decimal Degrees)": -113.03,
        "Latitude": 594200000,
        "Longitude": -1130200000,
        "Elevation (m)": 277,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "SPRING CREEK MOOSE",
        "Province": "ALBERTA",
        "Climate ID": "307F0G9",
        "Station ID": 8900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.95,
        "Longitude (Decimal Degrees)": -117.73,
        "Latitude": 545700000,
        "Longitude": -1174400000,
        "Elevation (m)": 719.3,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLEARDALE",
        "Province": "ALBERTA",
        "Climate ID": "307JF5R",
        "Station ID": 2645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.33,
        "Longitude (Decimal Degrees)": -119.48,
        "Latitude": 562000000,
        "Longitude": -1192900000,
        "Elevation (m)": 643.1,
        "First Year": 1963,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2005,
        "MLY First Year": 1963,
        "MLY Last Year": 2005
      },
      {
        "Name": "CROWE TOWER",
        "Province": "ALBERTA",
        "Climate ID": "307JRN0",
        "Station ID": 2646,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.27,
        "Longitude (Decimal Degrees)": -114,
        "Latitude": 591600000,
        "Longitude": -1140000000,
        "Elevation (m)": 914,
        "First Year": 1984,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1990,
        "MLY First Year": 1984,
        "MLY Last Year": 1990
      },
      {
        "Name": "FOX CREEK JUNCTION",
        "Province": "ALBERTA",
        "Climate ID": "307K73D",
        "Station ID": 6860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -116.82,
        "Latitude": 542342009,
        "Longitude": -1164911009,
        "Elevation (m)": 829,
        "First Year": 1991,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2012,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "FORT CHIPEWYAN",
        "Province": "ALBERTA",
        "Climate ID": "307KFNF",
        "Station ID": 10655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.77,
        "Longitude (Decimal Degrees)": -111.12,
        "Latitude": 584600000,
        "Longitude": -1110700000,
        "Elevation (m)": 231.6,
        "First Year": 1989,
        "Last Year": 1993,
        "HLY First Year": 1989,
        "HLY Last Year": 1993,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GARDEN RIVER",
        "Province": "ALBERTA",
        "Climate ID": "307KPFP",
        "Station ID": 10932,
        "WMO ID": 71253,
        "TC ID": "WGF",
        "Latitude (Decimal Degrees)": 58.71,
        "Longitude (Decimal Degrees)": -113.87,
        "Latitude": 584239000,
        "Longitude": -1135159300,
        "Elevation (m)": 240.8,
        "First Year": 1993,
        "Last Year": 2020,
        "HLY First Year": 1994,
        "HLY Last Year": 2020,
        "DLY First Year": 1993,
        "DLY Last Year": 2020,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "REES TOWER",
        "Province": "ALBERTA",
        "Climate ID": "307NM9J",
        "Station ID": 2647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.67,
        "Longitude (Decimal Degrees)": -115.2,
        "Latitude": 594000000,
        "Longitude": -1151200000,
        "Elevation (m)": 945,
        "First Year": 1986,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1990,
        "MLY First Year": 1986,
        "MLY Last Year": 1990
      },
      {
        "Name": "SNEDDON CREEK RS",
        "Province": "ALBERTA",
        "Climate ID": "307S002",
        "Station ID": 27041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.2,
        "Longitude (Decimal Degrees)": -119.4,
        "Latitude": 561200000,
        "Longitude": -1192400000,
        "Elevation (m)": 648,
        "First Year": 1995,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2011,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "BONNYVILLE EXP ST",
        "Province": "ALBERTA",
        "Climate ID": "3080740",
        "Station ID": 2831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -110.63,
        "Latitude": 541700000,
        "Longitude": -1103800000,
        "Elevation (m)": 548.6,
        "First Year": 1952,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1954,
        "MLY First Year": 1952,
        "MLY Last Year": 1954
      },
      {
        "Name": "COLD LAKE A",
        "Province": "ALBERTA",
        "Climate ID": "3081680",
        "Station ID": 2832,
        "WMO ID": 71120,
        "TC ID": "YOD",
        "Latitude (Decimal Degrees)": 54.42,
        "Longitude (Decimal Degrees)": -110.28,
        "Latitude": 542500000,
        "Longitude": -1101700000,
        "Elevation (m)": 541,
        "First Year": 1952,
        "Last Year": 2022,
        "HLY First Year": 1954,
        "HLY Last Year": 2022,
        "DLY First Year": 1952,
        "DLY Last Year": 2022,
        "MLY First Year": 1953,
        "MLY Last Year": 2017
      },
      {
        "Name": "COLD LAKE SNOW",
        "Province": "ALBERTA",
        "Climate ID": "3081682",
        "Station ID": 43164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.41,
        "Longitude (Decimal Degrees)": -110.26,
        "Latitude": 542453000,
        "Longitude": -1101550000,
        "Elevation (m)": 546.2,
        "First Year": 2004,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2010,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "DUPRE AGCM",
        "Province": "ALBERTA",
        "Climate ID": "3081690",
        "Station ID": 47070,
        "WMO ID": 71414,
        "TC ID": "PDU",
        "Latitude (Decimal Degrees)": 54.37,
        "Longitude (Decimal Degrees)": -110.87,
        "Latitude": 542201004,
        "Longitude": -1105204003,
        "Elevation (m)": 556,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT KENT CDA EPF",
        "Province": "ALBERTA",
        "Climate ID": "3082660",
        "Station ID": 2833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.3,
        "Longitude (Decimal Degrees)": -110.62,
        "Latitude": 541800000,
        "Longitude": -1103700000,
        "Elevation (m)": 548.6,
        "First Year": 1954,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1965,
        "MLY First Year": 1954,
        "MLY Last Year": 1965
      },
      {
        "Name": "GLENDON",
        "Province": "ALBERTA",
        "Climate ID": "30828JF",
        "Station ID": 2834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.25,
        "Longitude (Decimal Degrees)": -111.15,
        "Latitude": 541500000,
        "Longitude": -1110900000,
        "Elevation (m)": 588.3,
        "First Year": 1936,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1936,
        "MLY Last Year": 1947
      },
      {
        "Name": "IRON RIVER",
        "Province": "ALBERTA",
        "Climate ID": "3083480",
        "Station ID": 2835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.42,
        "Longitude (Decimal Degrees)": -111,
        "Latitude": 542500000,
        "Longitude": -1110000000,
        "Elevation (m)": 548.6,
        "First Year": 1925,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1975,
        "MLY First Year": 1925,
        "MLY Last Year": 1975
      },
      {
        "Name": "IRON RIVER NORTH",
        "Province": "ALBERTA",
        "Climate ID": "3083482",
        "Station ID": 2836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.5,
        "Longitude (Decimal Degrees)": -110.87,
        "Latitude": 543000000,
        "Longitude": -1105200000,
        "Elevation (m)": 579.1,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "LA COREY RS",
        "Province": "ALBERTA",
        "Climate ID": "3083725",
        "Station ID": 2837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.42,
        "Longitude (Decimal Degrees)": -110.77,
        "Latitude": 542500000,
        "Longitude": -1104600000,
        "Elevation (m)": 579.1,
        "First Year": 1962,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1976,
        "MLY First Year": 1962,
        "MLY Last Year": 1976
      },
      {
        "Name": "ALAMEDA CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010080",
        "Station ID": 2839,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -102.28,
        "Latitude": 491500000,
        "Longitude": -1021700000,
        "Elevation (m)": 580,
        "First Year": 1894,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 1965,
        "MLY First Year": 1894,
        "MLY Last Year": 1965
      },
      {
        "Name": "ALIDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010100",
        "Station ID": 2840,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -101.8,
        "Latitude": 492700000,
        "Longitude": -1014800000,
        "Elevation (m)": 585.2,
        "First Year": 1989,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2004,
        "MLY First Year": 1989,
        "MLY Last Year": 2004
      },
      {
        "Name": "AMULET",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010150",
        "Station ID": 2841,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -104.73,
        "Latitude": 493700000,
        "Longitude": -1044400000,
        "Elevation (m)": 727.6,
        "First Year": 1970,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2002,
        "MLY First Year": 1970,
        "MLY Last Year": 2002
      },
      {
        "Name": "ARCOLA CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010240",
        "Station ID": 2842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -102.53,
        "Latitude": 493800000,
        "Longitude": -1023200000,
        "Elevation (m)": 603.5,
        "First Year": 1952,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1993,
        "MLY First Year": 1952,
        "MLY Last Year": 1993
      },
      {
        "Name": "ATWATER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010310",
        "Station ID": 2843,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -102.22,
        "Latitude": 504900000,
        "Longitude": -1021300000,
        "Elevation (m)": 536.1,
        "First Year": 1977,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2008,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "AVONLEA EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010320",
        "Station ID": 2844,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -105.05,
        "Latitude": 500100000,
        "Longitude": -1050300000,
        "Elevation (m)": 601.1,
        "First Year": 1951,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1955,
        "MLY First Year": 1951,
        "MLY Last Year": 1955
      },
      {
        "Name": "AYLESBURY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010358",
        "Station ID": 2845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -105.7,
        "Latitude": 505600000,
        "Longitude": -1054200000,
        "Elevation (m)": 579.1,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "AYLESBURY CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010360",
        "Station ID": 2846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51,
        "Longitude (Decimal Degrees)": -105.67,
        "Latitude": 510000000,
        "Longitude": -1054000000,
        "Elevation (m)": 576.1,
        "First Year": 1951,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1962,
        "MLY First Year": 1951,
        "MLY Last Year": 1962
      },
      {
        "Name": "BALCARRES",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010395",
        "Station ID": 2847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -103.62,
        "Latitude": 504700000,
        "Longitude": -1033700000,
        "Elevation (m)": 576.1,
        "First Year": 1961,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2010,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "BANGOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010400",
        "Station ID": 2848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -102.28,
        "Latitude": 505400000,
        "Longitude": -1021700000,
        "Elevation (m)": 526.4,
        "First Year": 1951,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2005,
        "MLY First Year": 1951,
        "MLY Last Year": 2005
      },
      {
        "Name": "BECHARD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010540",
        "Station ID": 2849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -104.22,
        "Latitude": 500300000,
        "Longitude": -1041300000,
        "Elevation (m)": 577.6,
        "First Year": 1974,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1982,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "BELLE PLAINE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010568",
        "Station ID": 2850,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -105.2,
        "Latitude": 502700000,
        "Longitude": -1051200000,
        "Elevation (m)": 582.2,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "BENSON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010570",
        "Station ID": 2851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -102.98,
        "Latitude": 492700000,
        "Longitude": -1025900000,
        "Elevation (m)": 588,
        "First Year": 1973,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2006,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "BRATT'S LAKE CLIMATE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010811",
        "Station ID": 44543,
        "WMO ID": 71569,
        "TC ID": "XBK",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -104.71,
        "Latitude": 501202001,
        "Longitude": -1044236000,
        "Elevation (m)": 580,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRIERCREST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010840",
        "Station ID": 2852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -105.27,
        "Latitude": 500900000,
        "Longitude": -1051600000,
        "Elevation (m)": 594.1,
        "First Year": 1952,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1992,
        "MLY First Year": 1952,
        "MLY Last Year": 1992
      },
      {
        "Name": "BRIERCREST 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010842",
        "Station ID": 2853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -105.35,
        "Latitude": 500500000,
        "Longitude": -1052100000,
        "Elevation (m)": 629.1,
        "First Year": 1983,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1993,
        "MLY First Year": 1983,
        "MLY Last Year": 1993
      },
      {
        "Name": "BROADVIEW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010878",
        "Station ID": 2854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -102.55,
        "Latitude": 502300000,
        "Longitude": -1023300000,
        "Elevation (m)": 597.4,
        "First Year": 1904,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1938,
        "MLY First Year": 1904,
        "MLY Last Year": 1938
      },
      {
        "Name": "BROADVIEW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010879",
        "Station ID": 2855,
        "WMO ID": 71861,
        "TC ID": "WIK",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -102.57,
        "Latitude": 502205000,
        "Longitude": -1023415000,
        "Elevation (m)": 599.8,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": 1965,
        "HLY Last Year": 2022,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROADVIEW A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010880",
        "Station ID": 2856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -102.53,
        "Latitude": 501500000,
        "Longitude": -1023200000,
        "Elevation (m)": 619.7,
        "First Year": 1938,
        "Last Year": 1965,
        "HLY First Year": 1953,
        "HLY Last Year": 1965,
        "DLY First Year": 1938,
        "DLY Last Year": 1965,
        "MLY First Year": 1938,
        "MLY Last Year": 1965
      },
      {
        "Name": "BUCHANAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010960",
        "Station ID": 2857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -102.73,
        "Latitude": 514300000,
        "Longitude": -1024400000,
        "Elevation (m)": 503.2,
        "First Year": 1954,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1958,
        "MLY First Year": 1954,
        "MLY Last Year": 1958
      },
      {
        "Name": "BUCHANAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010961",
        "Station ID": 2858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -102.83,
        "Latitude": 514300000,
        "Longitude": -1025000000,
        "Elevation (m)": 502.9,
        "First Year": 1965,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1970,
        "MLY First Year": 1965,
        "MLY Last Year": 1970
      },
      {
        "Name": "BUFFALO POUND LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4010984",
        "Station ID": 2859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -105.38,
        "Latitude": 503300000,
        "Longitude": -1052300000,
        "Elevation (m)": 588,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "CANDIAC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011084",
        "Station ID": 2860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -103.25,
        "Latitude": 501300000,
        "Longitude": -1031500000,
        "Elevation (m)": 684.9,
        "First Year": 1983,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1983,
        "MLY First Year": 1983,
        "MLY Last Year": 1983
      },
      {
        "Name": "CANNINGTON MANOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011090",
        "Station ID": 2861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -102.03,
        "Latitude": 494300000,
        "Longitude": -1020200000,
        "Elevation (m)": 629.1,
        "First Year": 1895,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1918,
        "MLY First Year": 1895,
        "MLY Last Year": 1918
      },
      {
        "Name": "CANORA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011095",
        "Station ID": 2862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -102.38,
        "Latitude": 513800000,
        "Longitude": -1022300000,
        "Elevation (m)": 481.9,
        "First Year": 1965,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1984,
        "MLY First Year": 1965,
        "MLY Last Year": 1984
      },
      {
        "Name": "CARDROSS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011120",
        "Station ID": 2863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -105.65,
        "Latitude": 494900000,
        "Longitude": -1053900000,
        "Elevation (m)": 701,
        "First Year": 1953,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1982,
        "MLY First Year": 1953,
        "MLY Last Year": 1982
      },
      {
        "Name": "CARLYLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011160",
        "Station ID": 2864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -102.27,
        "Latitude": 493800000,
        "Longitude": -1021600000,
        "Elevation (m)": 630.9,
        "First Year": 1922,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1996,
        "MLY First Year": 1922,
        "MLY Last Year": 1996
      },
      {
        "Name": "CARNDUFF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011250",
        "Station ID": 2865,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -101.75,
        "Latitude": 491300000,
        "Longitude": -1014500000,
        "Elevation (m)": 515.1,
        "First Year": 1962,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2007,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011280",
        "Station ID": 2866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -105.87,
        "Latitude": 502700000,
        "Longitude": -1055200000,
        "Elevation (m)": 579.1,
        "First Year": 1915,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1984,
        "MLY First Year": 1915,
        "MLY Last Year": 1984
      },
      {
        "Name": "CEYLON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011440",
        "Station ID": 2867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 492800000,
        "Longitude": -1043600000,
        "Elevation (m)": 712.9,
        "First Year": 1922,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1978,
        "MLY First Year": 1922,
        "MLY Last Year": 1978
      },
      {
        "Name": "CEYLON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011441",
        "Station ID": 2868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -104.65,
        "Latitude": 492300000,
        "Longitude": -1043900000,
        "Elevation (m)": 753,
        "First Year": 1978,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2002,
        "MLY First Year": 1978,
        "MLY Last Year": 2002
      },
      {
        "Name": "CLAYBANK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011580",
        "Station ID": 2869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -105.22,
        "Latitude": 500300000,
        "Longitude": -1051300000,
        "Elevation (m)": 643.4,
        "First Year": 1957,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1976,
        "MLY First Year": 1957,
        "MLY Last Year": 1976
      },
      {
        "Name": "CONDIE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011640",
        "Station ID": 2870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -104.77,
        "Latitude": 503300000,
        "Longitude": -1044600000,
        "Elevation (m)": 571.5,
        "First Year": 1954,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1963,
        "MLY First Year": 1954,
        "MLY Last Year": 1963
      },
      {
        "Name": "CORNING",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011843",
        "Station ID": 2871,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -102.97,
        "Latitude": 495700000,
        "Longitude": -1025800000,
        "Elevation (m)": 675.1,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "COTE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011846",
        "Station ID": 2872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -101.78,
        "Latitude": 513100000,
        "Longitude": -1014700000,
        "Elevation (m)": 449.6,
        "First Year": 1970,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2006,
        "MLY First Year": 1970,
        "MLY Last Year": 2006
      },
      {
        "Name": "CREELMAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011880",
        "Station ID": 2873,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -103.25,
        "Latitude": 494800000,
        "Longitude": -1031500000,
        "Elevation (m)": 624.8,
        "First Year": 1954,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1968,
        "MLY First Year": 1954,
        "MLY Last Year": 1968
      },
      {
        "Name": "CRESCENT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011888",
        "Station ID": 2874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -102.42,
        "Latitude": 505900000,
        "Longitude": -1022500000,
        "Elevation (m)": 505.4,
        "First Year": 1899,
        "Last Year": 1917,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1917,
        "MLY First Year": 1899,
        "MLY Last Year": 1917
      },
      {
        "Name": "CUPAR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4011980",
        "Station ID": 2875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.85,
        "Longitude (Decimal Degrees)": -104.27,
        "Latitude": 505100000,
        "Longitude": -1041600000,
        "Elevation (m)": 556.3,
        "First Year": 1955,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2018,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAFOE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012039",
        "Station ID": 2876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -104.47,
        "Latitude": 513900000,
        "Longitude": -1042800000,
        "Elevation (m)": 563.9,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "DAFOE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012040",
        "Station ID": 2877,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.93,
        "Longitude (Decimal Degrees)": -104.57,
        "Latitude": 515600000,
        "Longitude": -1043400000,
        "Elevation (m)": 539.8,
        "First Year": 1944,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1964,
        "MLY First Year": 1944,
        "MLY Last Year": 1964
      },
      {
        "Name": "DAHINDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012050",
        "Station ID": 2878,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -105.03,
        "Latitude": 494400000,
        "Longitude": -1050200000,
        "Elevation (m)": 716.3,
        "First Year": 1973,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2012,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAVIDSON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012120",
        "Station ID": 2879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -105.98,
        "Latitude": 511600000,
        "Longitude": -1055900000,
        "Elevation (m)": 618.7,
        "First Year": 1922,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2005,
        "MLY First Year": 1922,
        "MLY Last Year": 2005
      },
      {
        "Name": "DAVIDSON 17NORTHEAST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012125",
        "Station ID": 6957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -105.8,
        "Latitude": 512200000,
        "Longitude": -1054800000,
        "Elevation (m)": 625.8,
        "First Year": 1992,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1992,
        "MLY First Year": 1992,
        "MLY Last Year": 1992
      },
      {
        "Name": "DAVIDSON 30 NE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012130",
        "Station ID": 27116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -105.8,
        "Latitude": 512500000,
        "Longitude": -1054800000,
        "Elevation (m)": 640.1,
        "First Year": 2005,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2008,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAVIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012160",
        "Station ID": 2880,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -104.1,
        "Latitude": 502300000,
        "Longitude": -1040600000,
        "Elevation (m)": 662,
        "First Year": 1951,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1969,
        "MLY First Year": 1951,
        "MLY Last Year": 1969
      },
      {
        "Name": "DAVIN 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012161",
        "Station ID": 2881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -104.18,
        "Latitude": 502200000,
        "Longitude": -1041100000,
        "Elevation (m)": 662,
        "First Year": 1951,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1967,
        "MLY First Year": 1951,
        "MLY Last Year": 1967
      },
      {
        "Name": "DAVIN 3",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012162",
        "Station ID": 2882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -104.18,
        "Latitude": 502400000,
        "Longitude": -1041100000,
        "Elevation (m)": 640.1,
        "First Year": 1954,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1986,
        "MLY First Year": 1954,
        "MLY Last Year": 1986
      },
      {
        "Name": "DAVIN 4",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012163",
        "Station ID": 2883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -104.2,
        "Latitude": 502200000,
        "Longitude": -1041200000,
        "Elevation (m)": 662,
        "First Year": 1954,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1978,
        "MLY First Year": 1954,
        "MLY Last Year": 1978
      },
      {
        "Name": "DAVIN 5",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012164",
        "Station ID": 2884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -104.17,
        "Latitude": 502200000,
        "Longitude": -1041000000,
        "Elevation (m)": 655.3,
        "First Year": 1954,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1993,
        "MLY First Year": 1954,
        "MLY Last Year": 1993
      },
      {
        "Name": "DAVIN 6",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012165",
        "Station ID": 2885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -104.15,
        "Latitude": 502200000,
        "Longitude": -1040900000,
        "Elevation (m)": 648,
        "First Year": 1954,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1993,
        "MLY First Year": 1954,
        "MLY Last Year": 1993
      },
      {
        "Name": "DAVIN 7",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012166",
        "Station ID": 2886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -104.17,
        "Latitude": 502300000,
        "Longitude": -1041000000,
        "Elevation (m)": 647.7,
        "First Year": 1954,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1980,
        "MLY First Year": 1954,
        "MLY Last Year": 1980
      },
      {
        "Name": "DILKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012200",
        "Station ID": 2887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -105.17,
        "Latitude": 505200000,
        "Longitude": -1051000000,
        "Elevation (m)": 521.2,
        "First Year": 1934,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1996,
        "MLY First Year": 1934,
        "MLY Last Year": 1996
      },
      {
        "Name": "DIRT HILLS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012215",
        "Station ID": 2888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -105.2,
        "Latitude": 500400000,
        "Longitude": -1051200000,
        "Elevation (m)": null,
        "First Year": 1898,
        "Last Year": 1911,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 1911,
        "MLY First Year": 1898,
        "MLY Last Year": 1911
      },
      {
        "Name": "DUFF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012270",
        "Station ID": 2889,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -103.08,
        "Latitude": 505200000,
        "Longitude": -1030500000,
        "Elevation (m)": 584.6,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "DUVAL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012300",
        "Station ID": 2890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -104.87,
        "Latitude": 511100000,
        "Longitude": -1045200000,
        "Elevation (m)": 584,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "DYSART",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012309",
        "Station ID": 2891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.12,
        "Longitude (Decimal Degrees)": -103.98,
        "Latitude": 510700000,
        "Longitude": -1035900000,
        "Elevation (m)": 640.1,
        "First Year": 1971,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1978,
        "MLY First Year": 1971,
        "MLY Last Year": 1978
      },
      {
        "Name": "EDGELEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012340",
        "Station ID": 2892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -104.03,
        "Latitude": 503600000,
        "Longitude": -1040200000,
        "Elevation (m)": 640.1,
        "First Year": 1958,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1975,
        "MLY First Year": 1958,
        "MLY Last Year": 1975
      },
      {
        "Name": "ELMORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012364",
        "Station ID": 2893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -101.63,
        "Latitude": 490000000,
        "Longitude": -1013800000,
        "Elevation (m)": 495.3,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "ESK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012381",
        "Station ID": 2894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -104.85,
        "Latitude": 514800000,
        "Longitude": -1045100000,
        "Elevation (m)": 541.9,
        "First Year": 1984,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2014,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "ESTEVAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012390",
        "Station ID": 2895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -103.07,
        "Latitude": 491200000,
        "Longitude": -1030400000,
        "Elevation (m)": 566.3,
        "First Year": 1899,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1944,
        "MLY First Year": 1899,
        "MLY Last Year": 1944
      },
      {
        "Name": "ESTEVAN A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012400",
        "Station ID": 2896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -102.97,
        "Latitude": 491300000,
        "Longitude": -1025800000,
        "Elevation (m)": 580.3,
        "First Year": 1944,
        "Last Year": 2015,
        "HLY First Year": 1953,
        "HLY Last Year": 2015,
        "DLY First Year": 1944,
        "DLY Last Year": 2015,
        "MLY First Year": 1944,
        "MLY Last Year": 2015
      },
      {
        "Name": "ESTEVAN A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012401",
        "Station ID": 53520,
        "WMO ID": null,
        "TC ID": "YEN",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -102.97,
        "Latitude": 491237000,
        "Longitude": -1025757000,
        "Elevation (m)": 580.3,
        "First Year": 2015,
        "Last Year": 2018,
        "HLY First Year": 2015,
        "HLY Last Year": 2018,
        "DLY First Year": 2015,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ESTEVAN A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012403",
        "Station ID": 54261,
        "WMO ID": 71862,
        "TC ID": "YEN",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -102.97,
        "Latitude": 491237000,
        "Longitude": -1025757000,
        "Elevation (m)": 580.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ESTEVAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012410",
        "Station ID": 49728,
        "WMO ID": 71559,
        "TC ID": "PJM",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -102.97,
        "Latitude": 491300000,
        "Longitude": -1025800000,
        "Elevation (m)": 580.6,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ESTLIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012425",
        "Station ID": 2897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -104.53,
        "Latitude": 501500000,
        "Longitude": -1043200000,
        "Elevation (m)": 579.1,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "FARRERDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012480",
        "Station ID": 2898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -105.85,
        "Latitude": 513000000,
        "Longitude": -1055100000,
        "Elevation (m)": 524.3,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1955,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "FENWOOD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012483",
        "Station ID": 2899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -103.07,
        "Latitude": 510900000,
        "Longitude": -1030400000,
        "Elevation (m)": 624.8,
        "First Year": 1973,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2006,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "FERTILE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012485",
        "Station ID": 2900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.34,
        "Longitude (Decimal Degrees)": -101.45,
        "Latitude": 492022090,
        "Longitude": -1012653020,
        "Elevation (m)": 510.5,
        "First Year": 1969,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2012,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "FILLMORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012493",
        "Station ID": 2901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -103.43,
        "Latitude": 495300000,
        "Longitude": -1032600000,
        "Elevation (m)": 619,
        "First Year": 1973,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1987,
        "MLY First Year": 1973,
        "MLY Last Year": 1987
      },
      {
        "Name": "FLEMING CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012520",
        "Station ID": 2902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -101.53,
        "Latitude": 500700000,
        "Longitude": -1013200000,
        "Elevation (m)": 541,
        "First Year": 1951,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1976,
        "MLY First Year": 1951,
        "MLY Last Year": 1976
      },
      {
        "Name": "FLEMING SOUTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012525",
        "Station ID": 2903,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -101.58,
        "Latitude": 500200000,
        "Longitude": -1013500000,
        "Elevation (m)": 571.5,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "FOAM LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012560",
        "Station ID": 2904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -103.53,
        "Latitude": 513800000,
        "Longitude": -1033200000,
        "Elevation (m)": 558.4,
        "First Year": 1923,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1974,
        "MLY First Year": 1923,
        "MLY Last Year": 1974
      },
      {
        "Name": "FOAM LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012561",
        "Station ID": 2905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -103.55,
        "Latitude": 514300000,
        "Longitude": -1033300000,
        "Elevation (m)": 544.1,
        "First Year": 1975,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1989,
        "MLY First Year": 1975,
        "MLY Last Year": 1989
      },
      {
        "Name": "FORT QU'APPELLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012600",
        "Station ID": 2906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -103.8,
        "Latitude": 504700000,
        "Longitude": -1034800000,
        "Elevation (m)": 485.5,
        "First Year": 1911,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1973,
        "MLY First Year": 1911,
        "MLY Last Year": 1973
      },
      {
        "Name": "FRANCIS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012720",
        "Station ID": 2907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -103.92,
        "Latitude": 500700000,
        "Longitude": -1035500000,
        "Elevation (m)": 602.6,
        "First Year": 1922,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1982,
        "MLY First Year": 1922,
        "MLY Last Year": 1982
      },
      {
        "Name": "GAINSBOROUGH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012790",
        "Station ID": 2908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -101.53,
        "Latitude": 491800000,
        "Longitude": -1013200000,
        "Elevation (m)": 518.2,
        "First Year": 1973,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1993,
        "MLY First Year": 1973,
        "MLY Last Year": 1993
      },
      {
        "Name": "GLASNEVIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012885",
        "Station ID": 2909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -105.03,
        "Latitude": 492900000,
        "Longitude": -1050200000,
        "Elevation (m)": 746.8,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "GLEN MORAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012930",
        "Station ID": 2910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.77,
        "Longitude (Decimal Degrees)": -104.93,
        "Latitude": 504600000,
        "Longitude": -1045600000,
        "Elevation (m)": 525.8,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "GOOD SPIRIT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012943",
        "Station ID": 2911,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -102.67,
        "Latitude": 513000000,
        "Longitude": -1024000000,
        "Elevation (m)": 484,
        "First Year": 1965,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2010,
        "MLY First Year": 1965,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRAYSON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4012988",
        "Station ID": 2912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -102.63,
        "Latitude": 504200000,
        "Longitude": -1023800000,
        "Elevation (m)": 541,
        "First Year": 1985,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2004,
        "MLY First Year": 1985,
        "MLY Last Year": 2004
      },
      {
        "Name": "GRENFELL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013030",
        "Station ID": 2913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -102.93,
        "Latitude": 502500000,
        "Longitude": -1025600000,
        "Elevation (m)": 598.6,
        "First Year": 1883,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1982,
        "MLY First Year": 1883,
        "MLY Last Year": 1982
      },
      {
        "Name": "GUERNSEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013038",
        "Station ID": 2914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -105.28,
        "Latitude": 514700000,
        "Longitude": -1051700000,
        "Elevation (m)": 526.1,
        "First Year": 1973,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1993,
        "MLY First Year": 1973,
        "MLY Last Year": 1993
      },
      {
        "Name": "GUERNSEY CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013040",
        "Station ID": 2915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.82,
        "Longitude (Decimal Degrees)": -105.23,
        "Latitude": 514900000,
        "Longitude": -1051400000,
        "Elevation (m)": 540.7,
        "First Year": 1951,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1965,
        "MLY First Year": 1951,
        "MLY Last Year": 1965
      },
      {
        "Name": "HANDSWORTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013098",
        "Station ID": 2916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -102.87,
        "Latitude": 495000000,
        "Longitude": -1025200000,
        "Elevation (m)": 677.9,
        "First Year": 1976,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2008,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "HARDY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013108",
        "Station ID": 2917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -104.75,
        "Latitude": 492000000,
        "Longitude": -1044500000,
        "Elevation (m)": 712.9,
        "First Year": 1959,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1962,
        "MLY First Year": 1959,
        "MLY Last Year": 1962
      },
      {
        "Name": "HEWARD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013221",
        "Station ID": 2918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -103.15,
        "Latitude": 494500000,
        "Longitude": -1030900000,
        "Elevation (m)": 624.8,
        "First Year": 1971,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1985,
        "MLY First Year": 1971,
        "MLY Last Year": 1985
      },
      {
        "Name": "HIRSCH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013251",
        "Station ID": 2919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -102.6,
        "Latitude": 491100000,
        "Longitude": -1023600000,
        "Elevation (m)": 573,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "HOLDFAST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013270",
        "Station ID": 2920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51,
        "Longitude (Decimal Degrees)": -105.52,
        "Latitude": 510000000,
        "Longitude": -1053100000,
        "Elevation (m)": 544.1,
        "First Year": 1961,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2011,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUBBARD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013280",
        "Station ID": 2921,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -103.33,
        "Latitude": 510600000,
        "Longitude": -1032000000,
        "Elevation (m)": 662.9,
        "First Year": 1908,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1971,
        "MLY First Year": 1908,
        "MLY Last Year": 1971
      },
      {
        "Name": "HUMBOLDT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013400",
        "Station ID": 2922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -105.1,
        "Latitude": 521200000,
        "Longitude": -1050600000,
        "Elevation (m)": 566.9,
        "First Year": 1879,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1974,
        "MLY First Year": 1880,
        "MLY Last Year": 1974
      },
      {
        "Name": "HUMBOLDT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013401",
        "Station ID": 2923,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.27,
        "Longitude (Decimal Degrees)": -105.12,
        "Latitude": 521600000,
        "Longitude": -1050700000,
        "Elevation (m)": 548.6,
        "First Year": 1974,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2005,
        "MLY First Year": 1974,
        "MLY Last Year": 2005
      },
      {
        "Name": "IMPERIAL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013440",
        "Station ID": 2924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -105.53,
        "Latitude": 512200000,
        "Longitude": -1053200000,
        "Elevation (m)": 556.3,
        "First Year": 1911,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1966,
        "MLY First Year": 1911,
        "MLY Last Year": 1966
      },
      {
        "Name": "INDIAN HEAD CDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013480",
        "Station ID": 2925,
        "WMO ID": 71515,
        "TC ID": "WBD",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -103.65,
        "Latitude": 503300000,
        "Longitude": -1033900000,
        "Elevation (m)": 579.1,
        "First Year": 1885,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1885,
        "DLY Last Year": 2022,
        "MLY First Year": 1885,
        "MLY Last Year": 2007
      },
      {
        "Name": "INDIAN HEAD PFRA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013490",
        "Station ID": 2926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -103.68,
        "Latitude": 503000000,
        "Longitude": -1034100000,
        "Elevation (m)": 604.1,
        "First Year": 1960,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1996,
        "MLY First Year": 1960,
        "MLY Last Year": 1996
      },
      {
        "Name": "INSINGER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013517",
        "Station ID": 2927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -102.98,
        "Latitude": 513200000,
        "Longitude": -1025900000,
        "Elevation (m)": 521.2,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "INSINGER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013518",
        "Station ID": 2928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -103.08,
        "Latitude": 513200000,
        "Longitude": -1030500000,
        "Elevation (m)": 538.9,
        "First Year": 1979,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1987,
        "MLY First Year": 1979,
        "MLY Last Year": 1987
      },
      {
        "Name": "KAMSACK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013640",
        "Station ID": 2929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -101.9,
        "Latitude": 513400000,
        "Longitude": -1015400000,
        "Elevation (m)": 440.4,
        "First Year": 1907,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1969,
        "MLY First Year": 1908,
        "MLY Last Year": 1969
      },
      {
        "Name": "KELLIHER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013660",
        "Station ID": 2930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.26,
        "Longitude (Decimal Degrees)": -103.75,
        "Latitude": 511526700,
        "Longitude": -1034510900,
        "Elevation (m)": 675.6,
        "First Year": 1951,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2022,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "KENASTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013740",
        "Station ID": 2931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -106.27,
        "Latitude": 513000000,
        "Longitude": -1061600000,
        "Elevation (m)": 616,
        "First Year": 1984,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1991,
        "MLY First Year": 1984,
        "MLY Last Year": 1991
      },
      {
        "Name": "KHEDIVE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4013770",
        "Station ID": 2932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -104.5,
        "Latitude": 493600000,
        "Longitude": -1043000000,
        "Elevation (m)": 650.7,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1961,
        "MLY Last Year": 1969
      },
      {
        "Name": "KIPLING",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014040",
        "Station ID": 2933,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -102.73,
        "Latitude": 501200000,
        "Longitude": -1024400000,
        "Elevation (m)": 671.2,
        "First Year": 1949,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2022,
        "MLY First Year": 1949,
        "MLY Last Year": 2007
      },
      {
        "Name": "KISBEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014050",
        "Station ID": 2934,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -102.63,
        "Latitude": 493300000,
        "Longitude": -1023800000,
        "Elevation (m)": 609.6,
        "First Year": 1959,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1977,
        "MLY First Year": 1959,
        "MLY Last Year": 1977
      },
      {
        "Name": "KIPLING SOUTH 21",
        "Province": "SASKATCHEWAN",
        "Climate ID": "40140D3",
        "Station ID": 6949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -102.67,
        "Latitude": 495500000,
        "Longitude": -1024000000,
        "Elevation (m)": 746.8,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "KRISTNES",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014100",
        "Station ID": 2935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -103.63,
        "Latitude": 514400000,
        "Longitude": -1033800000,
        "Elevation (m)": 531.9,
        "First Year": 1956,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1975,
        "MLY First Year": 1956,
        "MLY Last Year": 1975
      },
      {
        "Name": "KRONAU",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014105",
        "Station ID": 2936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -104.3,
        "Latitude": 502000000,
        "Longitude": -1041800000,
        "Elevation (m)": 594.4,
        "First Year": 1961,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1973,
        "MLY First Year": 1961,
        "MLY Last Year": 1973
      },
      {
        "Name": "KUROKI",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014115",
        "Station ID": 2937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -103.45,
        "Latitude": 520000000,
        "Longitude": -1032700000,
        "Elevation (m)": 585.2,
        "First Year": 1958,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1999,
        "MLY First Year": 1958,
        "MLY Last Year": 1999
      },
      {
        "Name": "LAJORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014136",
        "Station ID": 2938,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -104.15,
        "Latitude": 501400000,
        "Longitude": -1040900000,
        "Elevation (m)": 594.4,
        "First Year": 1966,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1970,
        "MLY First Year": 1966,
        "MLY Last Year": 1970
      },
      {
        "Name": "LAKE ALMA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014138",
        "Station ID": 2939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -104.25,
        "Latitude": 490400000,
        "Longitude": -1041500000,
        "Elevation (m)": 723.9,
        "First Year": 1973,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2006,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "LAMPMAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014142",
        "Station ID": 2940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -102.62,
        "Latitude": 492700000,
        "Longitude": -1023700000,
        "Elevation (m)": 594.4,
        "First Year": 1971,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1978,
        "MLY First Year": 1971,
        "MLY Last Year": 1978
      },
      {
        "Name": "LANGBANK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014143",
        "Station ID": 47287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -102.39,
        "Latitude": 500911000,
        "Longitude": -1022338000,
        "Elevation (m)": 622,
        "First Year": 2008,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2008,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LANGENBURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014145",
        "Station ID": 2941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -101.72,
        "Latitude": 505400000,
        "Longitude": -1014300000,
        "Elevation (m)": 516.6,
        "First Year": 1959,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2020,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAST MOUNTAIN CS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014156",
        "Station ID": 2942,
        "WMO ID": 71556,
        "TC ID": "WXG",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -105.25,
        "Latitude": 512500000,
        "Longitude": -1051500000,
        "Elevation (m)": 497,
        "First Year": 1975,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "LEBRET",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014280",
        "Station ID": 2943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -103.73,
        "Latitude": 504800000,
        "Longitude": -1034400000,
        "Elevation (m)": 548.6,
        "First Year": 1953,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1955,
        "MLY First Year": 1953,
        "MLY Last Year": 1955
      },
      {
        "Name": "LEMBERG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014299",
        "Station ID": 2944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -103.25,
        "Latitude": 503600000,
        "Longitude": -1031500000,
        "Elevation (m)": 587,
        "First Year": 1985,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1995,
        "MLY First Year": 1985,
        "MLY Last Year": 1995
      },
      {
        "Name": "LEMBERG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014300",
        "Station ID": 2945,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -103.32,
        "Latitude": 504300000,
        "Longitude": -1031900000,
        "Elevation (m)": 617.5,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "LEROSS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014320",
        "Station ID": 2946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -103.87,
        "Latitude": 511800000,
        "Longitude": -1035200000,
        "Elevation (m)": 676.4,
        "First Year": 1940,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1961,
        "MLY First Year": 1940,
        "MLY Last Year": 1961
      },
      {
        "Name": "LEROY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014322",
        "Station ID": 2947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -104.63,
        "Latitude": 520000000,
        "Longitude": -1043800000,
        "Elevation (m)": 535.4,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "LESTOCK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014325",
        "Station ID": 2948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -103.95,
        "Latitude": 511700000,
        "Longitude": -1035700000,
        "Elevation (m)": 676.4,
        "First Year": 1920,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1939,
        "MLY First Year": 1920,
        "MLY Last Year": 1939
      },
      {
        "Name": "LIBERTY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014360",
        "Station ID": 2949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -105.33,
        "Latitude": 510800000,
        "Longitude": -1052000000,
        "Elevation (m)": 495.3,
        "First Year": 1952,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1976,
        "MLY First Year": 1952,
        "MLY Last Year": 1976
      },
      {
        "Name": "LIPTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014480",
        "Station ID": 2950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.85,
        "Longitude (Decimal Degrees)": -103.77,
        "Latitude": 505100000,
        "Longitude": -1034600000,
        "Elevation (m)": 602,
        "First Year": 1947,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 2000,
        "MLY First Year": 1947,
        "MLY Last Year": 2000
      },
      {
        "Name": "LIPTON 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014481",
        "Station ID": 2951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -103.89,
        "Latitude": 510908008,
        "Longitude": -1035322001,
        "Elevation (m)": 640,
        "First Year": 1979,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2022,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "LORLIE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014620",
        "Station ID": 2952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -103.28,
        "Latitude": 505600000,
        "Longitude": -1031700000,
        "Elevation (m)": 579.1,
        "First Year": 1958,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1961,
        "MLY First Year": 1958,
        "MLY Last Year": 1961
      },
      {
        "Name": "LUMSDEN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014720",
        "Station ID": 2953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -104.87,
        "Latitude": 503900000,
        "Longitude": -1045200000,
        "Elevation (m)": 496.8,
        "First Year": 1922,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1994,
        "MLY First Year": 1922,
        "MLY Last Year": 1994
      },
      {
        "Name": "LUMSDEN 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014721",
        "Station ID": 2954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -104.97,
        "Latitude": 503600000,
        "Longitude": -1045800000,
        "Elevation (m)": 563.9,
        "First Year": 1953,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1969,
        "MLY First Year": 1953,
        "MLY Last Year": 1969
      },
      {
        "Name": "LUMSDEN 3",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014722",
        "Station ID": 2955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -104.93,
        "Latitude": 503600000,
        "Longitude": -1045600000,
        "Elevation (m)": 559.3,
        "First Year": 1968,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1996,
        "MLY First Year": 1968,
        "MLY Last Year": 1996
      },
      {
        "Name": "MACOUN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014870",
        "Station ID": 2956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -103.23,
        "Latitude": 491400000,
        "Longitude": -1031400000,
        "Elevation (m)": 571.2,
        "First Year": 1971,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2017,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "MANOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014913",
        "Station ID": 2957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -102.1,
        "Latitude": 493700000,
        "Longitude": -1020600000,
        "Elevation (m)": 632.5,
        "First Year": 1990,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2004,
        "MLY First Year": 1990,
        "MLY Last Year": 2004
      },
      {
        "Name": "MANOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4014914",
        "Station ID": 2958,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -102.1,
        "Latitude": 494100000,
        "Longitude": -1020600000,
        "Elevation (m)": 655.3,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "MARGO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015020",
        "Station ID": 2959,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.83,
        "Longitude (Decimal Degrees)": -103.33,
        "Latitude": 515000000,
        "Longitude": -1032000000,
        "Elevation (m)": 544.1,
        "First Year": 1958,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2008,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARQUIS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015040",
        "Station ID": 2960,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -105.7,
        "Latitude": 503700000,
        "Longitude": -1054200000,
        "Elevation (m)": 598.6,
        "First Year": 1931,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1936,
        "MLY First Year": 1931,
        "MLY Last Year": 1936
      },
      {
        "Name": "MARYFIELD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015045",
        "Station ID": 2961,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -101.52,
        "Latitude": 495000000,
        "Longitude": -1013100000,
        "Elevation (m)": 576.1,
        "First Year": 1970,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2005,
        "MLY First Year": 1970,
        "MLY Last Year": 2005
      },
      {
        "Name": "MAXIM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015048",
        "Station ID": 2962,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -103.97,
        "Latitude": 491900000,
        "Longitude": -1035800000,
        "Elevation (m)": 612.6,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "MELVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015100",
        "Station ID": 2963,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -102.8,
        "Latitude": 505700000,
        "Longitude": -1024800000,
        "Elevation (m)": 557.8,
        "First Year": 1956,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1980,
        "MLY First Year": 1956,
        "MLY Last Year": 1980
      },
      {
        "Name": "MIDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015159",
        "Station ID": 6867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -103.3,
        "Latitude": 492300000,
        "Longitude": -1031800000,
        "Elevation (m)": 586.7,
        "First Year": 1992,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2014,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "MIDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015160",
        "Station ID": 2964,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -103.4,
        "Latitude": 492400000,
        "Longitude": -1032400000,
        "Elevation (m)": 581.6,
        "First Year": 1922,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1991,
        "MLY First Year": 1922,
        "MLY Last Year": 1991
      },
      {
        "Name": "MONTMARTRE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015258",
        "Station ID": 2965,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -103.47,
        "Latitude": 501500000,
        "Longitude": -1032800000,
        "Elevation (m)": 678.2,
        "First Year": 1986,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2001,
        "MLY First Year": 1986,
        "MLY Last Year": 2001
      },
      {
        "Name": "MONTMARTRE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015259",
        "Station ID": 2966,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -103.55,
        "Latitude": 500700000,
        "Longitude": -1033300000,
        "Elevation (m)": 658.4,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "MOOSE JAW A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015320",
        "Station ID": 2967,
        "WMO ID": 71864,
        "TC ID": "YMJ",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -105.57,
        "Latitude": 502000000,
        "Longitude": -1053400000,
        "Elevation (m)": 576.7,
        "First Year": 1943,
        "Last Year": 2022,
        "HLY First Year": 1954,
        "HLY Last Year": 2022,
        "DLY First Year": 1943,
        "DLY Last Year": 1999,
        "MLY First Year": 1943,
        "MLY Last Year": 1998
      },
      {
        "Name": "MOOSE JAW CS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015322",
        "Station ID": 27476,
        "WMO ID": 71539,
        "TC ID": "ZMJ",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -105.54,
        "Latitude": 501954050,
        "Longitude": -1053215030,
        "Elevation (m)": 577,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOOSE JAW CHAB",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015325",
        "Station ID": 2968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -105.7,
        "Latitude": 502300000,
        "Longitude": -1054200000,
        "Elevation (m)": 598.9,
        "First Year": 1884,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1954,
        "MLY First Year": 1884,
        "MLY Last Year": 1954
      },
      {
        "Name": "MOOSE JAW SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015328",
        "Station ID": 46067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.34,
        "Longitude (Decimal Degrees)": -105.55,
        "Latitude": 502029000,
        "Longitude": -1053303000,
        "Elevation (m)": 569,
        "First Year": 2004,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2015,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOOSE MOUNTAIN PARK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015341",
        "Station ID": 2969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -102.28,
        "Latitude": 495000000,
        "Longitude": -1021700000,
        "Elevation (m)": 769.6,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "MOOSE MOUNTAIN RESERVOIR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015344",
        "Station ID": 2970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -103.02,
        "Latitude": 495300000,
        "Longitude": -1030100000,
        "Elevation (m)": 618.7,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "MOOSOMIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015360",
        "Station ID": 2971,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -101.67,
        "Latitude": 500800000,
        "Longitude": -1014000000,
        "Elevation (m)": 576.1,
        "First Year": 1900,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 2000,
        "MLY First Year": 1900,
        "MLY Last Year": 2000
      },
      {
        "Name": "MORTLACH EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015400",
        "Station ID": 2972,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -106.05,
        "Latitude": 502700000,
        "Longitude": -1060300000,
        "Elevation (m)": 605.9,
        "First Year": 1954,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1960,
        "MLY First Year": 1954,
        "MLY Last Year": 1960
      },
      {
        "Name": "MUENSTER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015440",
        "Station ID": 2973,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -105,
        "Latitude": 522000000,
        "Longitude": -1050000000,
        "Elevation (m)": 580,
        "First Year": 1904,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 2022,
        "MLY First Year": 1904,
        "MLY Last Year": 2007
      },
      {
        "Name": "NEUDORF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015489",
        "Station ID": 2974,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -103.52,
        "Latitude": 504300000,
        "Longitude": -1033100000,
        "Elevation (m)": 605.6,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "NOKOMIS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015560",
        "Station ID": 2975,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -105,
        "Latitude": 513100000,
        "Longitude": -1050000000,
        "Elevation (m)": 524.1,
        "First Year": 1922,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2008,
        "MLY First Year": 1922,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORQUAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015580",
        "Station ID": 27550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -102.07,
        "Latitude": 515200000,
        "Longitude": -1020400000,
        "Elevation (m)": 496,
        "First Year": 1997,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2008,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORTHGATE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015612",
        "Station ID": 2976,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -102.27,
        "Latitude": 490000000,
        "Longitude": -1021600000,
        "Elevation (m)": 560.8,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "ODESSA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015648",
        "Station ID": 2977,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -103.68,
        "Latitude": 502000000,
        "Longitude": -1034100000,
        "Elevation (m)": 658.1,
        "First Year": 1971,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1986,
        "MLY First Year": 1971,
        "MLY Last Year": 1986
      },
      {
        "Name": "ORMISTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015680",
        "Station ID": 2978,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -105.37,
        "Latitude": 494300000,
        "Longitude": -1052200000,
        "Elevation (m)": 685.8,
        "First Year": 1951,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1998,
        "MLY First Year": 1951,
        "MLY Last Year": 1998
      },
      {
        "Name": "OSAGE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015695",
        "Station ID": 2979,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -103.6,
        "Latitude": 495500000,
        "Longitude": -1033600000,
        "Elevation (m)": 603.5,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "OUNGRE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015716",
        "Station ID": 2980,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -103.75,
        "Latitude": 490900000,
        "Longitude": -1034500000,
        "Elevation (m)": 604,
        "First Year": 1984,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1994,
        "MLY First Year": 1984,
        "MLY Last Year": 1994
      },
      {
        "Name": "OXBOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015800",
        "Station ID": 2981,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -102.12,
        "Latitude": 491900000,
        "Longitude": -1020700000,
        "Elevation (m)": 576,
        "First Year": 1949,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2022,
        "MLY First Year": 1949,
        "MLY Last Year": 2007
      },
      {
        "Name": "PANGMAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015863",
        "Station ID": 2982,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -104.55,
        "Latitude": 493900000,
        "Longitude": -1043300000,
        "Elevation (m)": 649.2,
        "First Year": 1990,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1993,
        "MLY First Year": 1990,
        "MLY Last Year": 1993
      },
      {
        "Name": "PARKBEG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015867",
        "Station ID": 2983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -106.07,
        "Latitude": 502800000,
        "Longitude": -1060400000,
        "Elevation (m)": 609.6,
        "First Year": 1981,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2001,
        "MLY First Year": 1981,
        "MLY Last Year": 2001
      },
      {
        "Name": "PARKERVIEW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015870",
        "Station ID": 2984,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.4,
        "Longitude (Decimal Degrees)": -103.27,
        "Latitude": 512411700,
        "Longitude": -1031555100,
        "Elevation (m)": 637,
        "First Year": 1979,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2022,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "PARKERVIEW 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015875",
        "Station ID": 6868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -103.4,
        "Latitude": 512600000,
        "Longitude": -1032400000,
        "Elevation (m)": 649,
        "First Year": 1993,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1995,
        "MLY First Year": 1993,
        "MLY Last Year": 1995
      },
      {
        "Name": "PASQUA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015920",
        "Station ID": 2985,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -105.35,
        "Latitude": 502000000,
        "Longitude": -1052100000,
        "Elevation (m)": 570.6,
        "First Year": 1954,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1968,
        "MLY First Year": 1954,
        "MLY Last Year": 1968
      },
      {
        "Name": "PASWEGIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4015960",
        "Station ID": 2986,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -103.92,
        "Latitude": 515900000,
        "Longitude": -1035500000,
        "Elevation (m)": 532.8,
        "First Year": 1921,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 2003,
        "MLY First Year": 1921,
        "MLY Last Year": 2003
      },
      {
        "Name": "PENSE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016080",
        "Station ID": 2987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -105,
        "Latitude": 502500000,
        "Longitude": -1050000000,
        "Elevation (m)": 576.7,
        "First Year": 1894,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1894,
        "DLY Last Year": 1959,
        "MLY First Year": 1894,
        "MLY Last Year": 1959
      },
      {
        "Name": "PENZANCE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016081",
        "Station ID": 2988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -105.43,
        "Latitude": 510500000,
        "Longitude": -1052600000,
        "Elevation (m)": 502.9,
        "First Year": 1976,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1980,
        "MLY First Year": 1976,
        "MLY Last Year": 1980
      },
      {
        "Name": "PREECEVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016185",
        "Station ID": 2989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.95,
        "Longitude (Decimal Degrees)": -102.65,
        "Latitude": 515700000,
        "Longitude": -1023900000,
        "Elevation (m)": 518.2,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "PREECEVILLE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016187",
        "Station ID": 6869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.82,
        "Longitude (Decimal Degrees)": -102.72,
        "Latitude": 514900000,
        "Longitude": -1024300000,
        "Elevation (m)": 521.2,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": 1992,
        "MLY Last Year": 1993
      },
      {
        "Name": "PUNNICHY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016300",
        "Station ID": 2990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -104.3,
        "Latitude": 511700000,
        "Longitude": -1041800000,
        "Elevation (m)": 644,
        "First Year": 1957,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1957,
        "MLY First Year": 1957,
        "MLY Last Year": 1957
      },
      {
        "Name": "QU'APPELLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016320",
        "Station ID": 2991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -103.88,
        "Latitude": 503100000,
        "Longitude": -1035300000,
        "Elevation (m)": 649.8,
        "First Year": 1877,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1877,
        "DLY Last Year": 1968,
        "MLY First Year": 1877,
        "MLY Last Year": 1968
      },
      {
        "Name": "QU'APPELLE 1",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016322",
        "Station ID": 2992,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -103.98,
        "Latitude": 503400000,
        "Longitude": -1035900000,
        "Elevation (m)": 662.9,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "QUILL LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016330",
        "Station ID": 2993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -104.23,
        "Latitude": 520400000,
        "Longitude": -1041400000,
        "Elevation (m)": 487.7,
        "First Year": 1911,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1943,
        "MLY First Year": 1911,
        "MLY Last Year": 1943
      },
      {
        "Name": "QUINTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016335",
        "Station ID": 2994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.35,
        "Longitude (Decimal Degrees)": -104.42,
        "Latitude": 512100000,
        "Longitude": -1042500000,
        "Elevation (m)": 630,
        "First Year": 1971,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2008,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "RADVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016400",
        "Station ID": 2995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -104.28,
        "Latitude": 493000000,
        "Longitude": -1041700000,
        "Elevation (m)": 633,
        "First Year": 1951,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1996,
        "MLY First Year": 1951,
        "MLY Last Year": 1996
      },
      {
        "Name": "RADVILLE MURRAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016402",
        "Station ID": 2996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -104.28,
        "Latitude": 492800000,
        "Longitude": -1041700000,
        "Elevation (m)": 701,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "RADVILLE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016403",
        "Station ID": 2997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -104.4,
        "Latitude": 492400000,
        "Longitude": -1042400000,
        "Elevation (m)": 696.2,
        "First Year": 1971,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1980,
        "MLY First Year": 1971,
        "MLY Last Year": 1980
      },
      {
        "Name": "RAYMORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016450",
        "Station ID": 2998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -104.58,
        "Latitude": 513400000,
        "Longitude": -1043500000,
        "Elevation (m)": 563.9,
        "First Year": 1963,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1990,
        "MLY First Year": 1963,
        "MLY Last Year": 1990
      },
      {
        "Name": "REDVERS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016520",
        "Station ID": 2999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -101.7,
        "Latitude": 493500000,
        "Longitude": -1014200000,
        "Elevation (m)": 593.8,
        "First Year": 1950,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1973,
        "MLY First Year": 1950,
        "MLY Last Year": 1973
      },
      {
        "Name": "REDVERS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016521",
        "Station ID": 3000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -101.72,
        "Latitude": 493600000,
        "Longitude": -1014300000,
        "Elevation (m)": 595.9,
        "First Year": 1974,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2008,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "REDVERS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016522",
        "Station ID": 3001,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -101.7,
        "Latitude": 493200000,
        "Longitude": -1014200000,
        "Elevation (m)": 587,
        "First Year": 1978,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1982,
        "MLY First Year": 1978,
        "MLY Last Year": 1982
      },
      {
        "Name": "REGINA INT'L A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016560",
        "Station ID": 3002,
        "WMO ID": 71863,
        "TC ID": "YQR",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502600000,
        "Longitude": -1044000000,
        "Elevation (m)": 577.6,
        "First Year": 1883,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1883,
        "DLY Last Year": 2013,
        "MLY First Year": 1883,
        "MLY Last Year": 2007
      },
      {
        "Name": "REGINA CO-OP UPGRADER SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016562",
        "Station ID": 46587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.49,
        "Longitude (Decimal Degrees)": -104.59,
        "Latitude": 502908000,
        "Longitude": -1043508000,
        "Elevation (m)": 585,
        "First Year": 2004,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2013,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "REGINA SIAST SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016563",
        "Station ID": 46588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.41,
        "Longitude (Decimal Degrees)": -104.58,
        "Latitude": 502428000,
        "Longitude": -1043456000,
        "Elevation (m)": 574,
        "First Year": 2004,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2015,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "REGINA RCMP SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016565",
        "Station ID": 46607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502702000,
        "Longitude": -1044000000,
        "Elevation (m)": 570,
        "First Year": 2004,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2016,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "REGINA INTL A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016566",
        "Station ID": 51441,
        "WMO ID": null,
        "TC ID": "YQR",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502556000,
        "Longitude": -1043958000,
        "Elevation (m)": 577.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "REGINA BUSCH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016570",
        "Station ID": 42263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502555000,
        "Longitude": -1043957000,
        "Elevation (m)": 577.29,
        "First Year": 2003,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2004,
        "MLY First Year": 2003,
        "MLY Last Year": 2004
      },
      {
        "Name": "REGINA CATHEDRAL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016590",
        "Station ID": 3003,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -104.65,
        "Latitude": 502700000,
        "Longitude": -1043900000,
        "Elevation (m)": 579.1,
        "First Year": 1959,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1959,
        "MLY Last Year": 1959
      },
      {
        "Name": "REGINA CDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016640",
        "Station ID": 3004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -104.57,
        "Latitude": 502400000,
        "Longitude": -1043400000,
        "Elevation (m)": 573,
        "First Year": 1932,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 1993,
        "MLY First Year": 1932,
        "MLY Last Year": 1993
      },
      {
        "Name": "REGINA CITY GREENHOUSES",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016641",
        "Station ID": 3005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 502800000,
        "Longitude": -1043600000,
        "Elevation (m)": 579.1,
        "First Year": 1962,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1985,
        "MLY First Year": 1962,
        "MLY Last Year": 1985
      },
      {
        "Name": "REGINA EXP FARM SUBSTN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016643",
        "Station ID": 3006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -104.58,
        "Latitude": 502300000,
        "Longitude": -1043500000,
        "Elevation (m)": 579.1,
        "First Year": 1959,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1962,
        "MLY First Year": 1959,
        "MLY Last Year": 1962
      },
      {
        "Name": "REGINA GILMOUR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016651",
        "Station ID": 3007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -104.83,
        "Latitude": 503400000,
        "Longitude": -1045000000,
        "Elevation (m)": 556.3,
        "First Year": 1970,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2016,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "REGINA HILLSDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016660",
        "Station ID": 3008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 502500000,
        "Longitude": -1043600000,
        "Elevation (m)": 573,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "REGINA KING",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016673",
        "Station ID": 3009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -104.5,
        "Latitude": 503100000,
        "Longitude": -1043000000,
        "Elevation (m)": 602,
        "First Year": 1967,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1980,
        "MLY First Year": 1967,
        "MLY Last Year": 1980
      },
      {
        "Name": "REGINA LEGISLATIVE GRNDS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016680",
        "Station ID": 3010,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.63,
        "Latitude": 502600000,
        "Longitude": -1043800000,
        "Elevation (m)": 579.1,
        "First Year": 1952,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1968,
        "MLY First Year": 1952,
        "MLY Last Year": 1968
      },
      {
        "Name": "REGINA MEMORIAL GARDENS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016690",
        "Station ID": 3011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.52,
        "Latitude": 502600000,
        "Longitude": -1043100000,
        "Elevation (m)": 590.1,
        "First Year": 1959,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1973,
        "MLY First Year": 1959,
        "MLY Last Year": 1973
      },
      {
        "Name": "REGINA NORMANDY HEIGHTS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016695",
        "Station ID": 3012,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.63,
        "Latitude": 502600000,
        "Longitude": -1043800000,
        "Elevation (m)": 573,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "REGINA RCS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016699",
        "Station ID": 28011,
        "WMO ID": 71514,
        "TC ID": "WDJ",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502600000,
        "Longitude": -1044000000,
        "Elevation (m)": 577.3,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "REGINA SOUTH HILLSDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016725",
        "Station ID": 3013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 502500000,
        "Longitude": -1043600000,
        "Elevation (m)": 573,
        "First Year": 1960,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1973,
        "MLY First Year": 1960,
        "MLY Last Year": 1973
      },
      {
        "Name": "REGINA TRANSCONA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016730",
        "Station ID": 3014,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502800000,
        "Longitude": -1044000000,
        "Elevation (m)": 579.1,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "REGINA WATERWORKS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016760",
        "Station ID": 3015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -104.48,
        "Latitude": 503200000,
        "Longitude": -1042900000,
        "Elevation (m)": 602,
        "First Year": 1952,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1967,
        "MLY First Year": 1952,
        "MLY Last Year": 1967
      },
      {
        "Name": "REGINA WHITMORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016780",
        "Station ID": 3016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 502400000,
        "Longitude": -1043600000,
        "Elevation (m)": 574.5,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "ROCANVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016842",
        "Station ID": 3017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -101.55,
        "Latitude": 502800000,
        "Longitude": -1013300000,
        "Elevation (m)": 478.5,
        "First Year": 1968,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2014,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROWANS RAVINE PARK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016955",
        "Station ID": 3018,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": null,
        "Longitude (Decimal Degrees)": null,
        "Latitude": null,
        "Longitude": null,
        "Elevation (m)": null,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1965
      },
      {
        "Name": "ROWATT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4016960",
        "Station ID": 3019,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 501300000,
        "Longitude": -1044000000,
        "Elevation (m)": 586.7,
        "First Year": 1952,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1967,
        "MLY First Year": 1952,
        "MLY Last Year": 1967
      },
      {
        "Name": "SALTCOATS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017095",
        "Station ID": 6870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -102.27,
        "Latitude": 505800000,
        "Longitude": -1021600000,
        "Elevation (m)": 522.7,
        "First Year": 1991,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2012,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "SEDLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017280",
        "Station ID": 3020,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -104.18,
        "Latitude": 501000000,
        "Longitude": -1041100000,
        "Elevation (m)": 597.4,
        "First Year": 1937,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1961,
        "MLY First Year": 1937,
        "MLY Last Year": 1961
      },
      {
        "Name": "SEDLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017281",
        "Station ID": 3021,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -104.1,
        "Latitude": 500700000,
        "Longitude": -1040600000,
        "Elevation (m)": 590.1,
        "First Year": 1982,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1998,
        "MLY First Year": 1982,
        "MLY Last Year": 1998
      },
      {
        "Name": "SEMANS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017320",
        "Station ID": 3022,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -104.73,
        "Latitude": 512500000,
        "Longitude": -1044400000,
        "Elevation (m)": 562.4,
        "First Year": 1922,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1994,
        "MLY First Year": 1922,
        "MLY Last Year": 1994
      },
      {
        "Name": "SERATH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017370",
        "Station ID": 3023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -104.4,
        "Latitude": 511000000,
        "Longitude": -1042400000,
        "Elevation (m)": 622.1,
        "First Year": 1980,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2000,
        "MLY First Year": 1980,
        "MLY Last Year": 2000
      },
      {
        "Name": "SINTALUTA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017515",
        "Station ID": 3024,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -103.38,
        "Latitude": 502900000,
        "Longitude": -1032300000,
        "Elevation (m)": 607.5,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "SPALDING",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017704",
        "Station ID": 3025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -104.37,
        "Latitude": 522200000,
        "Longitude": -1042200000,
        "Elevation (m)": 578.1,
        "First Year": 1990,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1997,
        "MLY First Year": 1990,
        "MLY Last Year": 1997
      },
      {
        "Name": "SPY HILL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017734",
        "Station ID": 3026,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -101.55,
        "Latitude": 503700000,
        "Longitude": -1013300000,
        "Elevation (m)": 487.7,
        "First Year": 1971,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1977,
        "MLY First Year": 1971,
        "MLY Last Year": 1977
      },
      {
        "Name": "STOCKHOLM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017780",
        "Station ID": 3027,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -102.38,
        "Latitude": 503900000,
        "Longitude": -1022300000,
        "Elevation (m)": 541,
        "First Year": 1979,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1983,
        "MLY First Year": 1979,
        "MLY Last Year": 1983
      },
      {
        "Name": "STORNOWAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017797",
        "Station ID": 3028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -102.03,
        "Latitude": 511800000,
        "Longitude": -1020200000,
        "Elevation (m)": 525.8,
        "First Year": 1984,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1989,
        "MLY First Year": 1984,
        "MLY Last Year": 1989
      },
      {
        "Name": "STRASBOURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017800",
        "Station ID": 3029,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -104.95,
        "Latitude": 510400000,
        "Longitude": -1045700000,
        "Elevation (m)": 544.1,
        "First Year": 1910,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1986,
        "MLY First Year": 1910,
        "MLY Last Year": 1986
      },
      {
        "Name": "STRASBOURG EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017816",
        "Station ID": 3030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -104.98,
        "Latitude": 510500000,
        "Longitude": -1045900000,
        "Elevation (m)": 548.3,
        "First Year": 1951,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1960,
        "MLY First Year": 1951,
        "MLY Last Year": 1960
      },
      {
        "Name": "SUMMERBERRY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4017910",
        "Station ID": 3031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -103.07,
        "Latitude": 502900000,
        "Longitude": -1030400000,
        "Elevation (m)": 573,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "THEODORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018074",
        "Station ID": 3032,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -102.97,
        "Latitude": 513000000,
        "Longitude": -1025800000,
        "Elevation (m)": 526.4,
        "First Year": 1970,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1997,
        "MLY First Year": 1970,
        "MLY Last Year": 1997
      },
      {
        "Name": "TOGO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018098",
        "Station ID": 3033,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.4,
        "Longitude (Decimal Degrees)": -101.58,
        "Latitude": 512400000,
        "Longitude": -1013500000,
        "Elevation (m)": 559,
        "First Year": 1970,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1991,
        "MLY First Year": 1970,
        "MLY Last Year": 1991
      },
      {
        "Name": "TORQUAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018105",
        "Station ID": 3034,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -103.5,
        "Latitude": 490500000,
        "Longitude": -1033000000,
        "Elevation (m)": 586.7,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "TREGARVA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018117",
        "Station ID": 3035,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 503900000,
        "Longitude": -1044000000,
        "Elevation (m)": 594.4,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "TRIBUNE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018120",
        "Station ID": 3036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -103.83,
        "Latitude": 491500000,
        "Longitude": -1035000000,
        "Elevation (m)": 607.2,
        "First Year": 1937,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1962,
        "MLY First Year": 1937,
        "MLY Last Year": 1962
      },
      {
        "Name": "TUGASKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018160",
        "Station ID": 3037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -106.3,
        "Latitude": 505300000,
        "Longitude": -1061800000,
        "Elevation (m)": 607.2,
        "First Year": 1922,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2009,
        "MLY First Year": 1922,
        "MLY Last Year": 2007
      },
      {
        "Name": "TUGASKE CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018161",
        "Station ID": 3038,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -106.3,
        "Latitude": 505400000,
        "Longitude": -1061800000,
        "Elevation (m)": 605.3,
        "First Year": 1961,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1972,
        "MLY First Year": 1961,
        "MLY Last Year": 1972
      },
      {
        "Name": "TYVAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018285",
        "Station ID": 3039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -103.68,
        "Latitude": 500000000,
        "Longitude": -1034100000,
        "Elevation (m)": 607,
        "First Year": 1985,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1985,
        "MLY First Year": 1985,
        "MLY Last Year": 1985
      },
      {
        "Name": "VEREGIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018445",
        "Station ID": 3040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -102.15,
        "Latitude": 513800000,
        "Longitude": -1020900000,
        "Elevation (m)": 473,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "WALDRON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018496",
        "Station ID": 3041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -102.47,
        "Latitude": 505200000,
        "Longitude": -1022800000,
        "Elevation (m)": 530,
        "First Year": 1985,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1996,
        "MLY First Year": 1985,
        "MLY Last Year": 1996
      },
      {
        "Name": "WAPELLA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018506",
        "Station ID": 3042,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -102.13,
        "Latitude": 501100000,
        "Longitude": -1020800000,
        "Elevation (m)": 602,
        "First Year": 1971,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1982,
        "MLY First Year": 1971,
        "MLY Last Year": 1982
      },
      {
        "Name": "WAPELLA NEWFINLAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018508",
        "Station ID": 3043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -101.93,
        "Latitude": 502700000,
        "Longitude": -1015600000,
        "Elevation (m)": 574.9,
        "First Year": 1979,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2006,
        "MLY First Year": 1979,
        "MLY Last Year": 2006
      },
      {
        "Name": "WAPELLA PIPESTONE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018510",
        "Station ID": 3044,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -102.05,
        "Latitude": 500800000,
        "Longitude": -1020300000,
        "Elevation (m)": 609,
        "First Year": 1982,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1995,
        "MLY First Year": 1982,
        "MLY Last Year": 1995
      },
      {
        "Name": "WAPELLA ST PAULS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018511",
        "Station ID": 27231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -101.98,
        "Latitude": 501200000,
        "Longitude": -1015900000,
        "Elevation (m)": 586.4,
        "First Year": 2002,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2003,
        "MLY First Year": 2002,
        "MLY Last Year": 2003
      },
      {
        "Name": "WARMLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018513",
        "Station ID": 3045,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -102.67,
        "Latitude": 494900000,
        "Longitude": -1024000000,
        "Elevation (m)": 780.3,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "WATROUS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018640",
        "Station ID": 3046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -105.47,
        "Latitude": 514000000,
        "Longitude": -1052800000,
        "Elevation (m)": 541,
        "First Year": 1953,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 2008,
        "MLY First Year": 1953,
        "MLY Last Year": 2007
      },
      {
        "Name": "WATROUS EAST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018642",
        "Station ID": 10785,
        "WMO ID": 71511,
        "TC ID": "WIW",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -105.4,
        "Latitude": 514000000,
        "Longitude": -1052400000,
        "Elevation (m)": 525.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "WATROUS 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018645",
        "Station ID": 3047,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -105.47,
        "Latitude": 514000000,
        "Longitude": -1052800000,
        "Elevation (m)": 543.5,
        "First Year": 1963,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1966
      },
      {
        "Name": "WAWOTA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018678",
        "Station ID": 3048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -101.97,
        "Latitude": 495600000,
        "Longitude": -1015800000,
        "Elevation (m)": 643.1,
        "First Year": 1977,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1993,
        "MLY First Year": 1977,
        "MLY Last Year": 1993
      },
      {
        "Name": "WAWOTA EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018680",
        "Station ID": 3049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -102.03,
        "Latitude": 495500000,
        "Longitude": -1020200000,
        "Elevation (m)": 662.3,
        "First Year": 1954,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "WEYBURN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018760",
        "Station ID": 3050,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -103.83,
        "Latitude": 493900000,
        "Longitude": -1035000000,
        "Elevation (m)": 569.7,
        "First Year": 1900,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 2012,
        "MLY First Year": 1900,
        "MLY Last Year": 2007
      },
      {
        "Name": "WEYBURN 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018762",
        "Station ID": 3051,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -103.85,
        "Latitude": 494000000,
        "Longitude": -1035100000,
        "Elevation (m)": 570,
        "First Year": 1975,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1993,
        "MLY First Year": 1975,
        "MLY Last Year": 1993
      },
      {
        "Name": "WHITEWOOD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018880",
        "Station ID": 3052,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.35,
        "Longitude (Decimal Degrees)": -102.27,
        "Latitude": 502100000,
        "Longitude": -1021600000,
        "Elevation (m)": 598.3,
        "First Year": 1902,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 2008,
        "MLY First Year": 1902,
        "MLY Last Year": 2007
      },
      {
        "Name": "WILCOX",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018920",
        "Station ID": 3053,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -104.33,
        "Latitude": 501000000,
        "Longitude": -1042000000,
        "Elevation (m)": 585.2,
        "First Year": 1952,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1993,
        "MLY First Year": 1952,
        "MLY Last Year": 1993
      },
      {
        "Name": "WILLMAR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4018960",
        "Station ID": 3054,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -102.5,
        "Latitude": 492500000,
        "Longitude": -1023000000,
        "Elevation (m)": 594.4,
        "First Year": 1948,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2007,
        "MLY First Year": 1948,
        "MLY Last Year": 2007
      },
      {
        "Name": "WISHART",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019007",
        "Station ID": 3055,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -104.12,
        "Latitude": 513400000,
        "Longitude": -1040700000,
        "Elevation (m)": 647.7,
        "First Year": 1966,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1991,
        "MLY First Year": 1966,
        "MLY Last Year": 1991
      },
      {
        "Name": "WOLSELEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019022",
        "Station ID": 3056,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -103.15,
        "Latitude": 501800000,
        "Longitude": -1030900000,
        "Elevation (m)": 670.6,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "WOLSELEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019023",
        "Station ID": 3057,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -103.28,
        "Latitude": 502600000,
        "Longitude": -1031700000,
        "Elevation (m)": 594.1,
        "First Year": 1984,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1999,
        "MLY First Year": 1984,
        "MLY Last Year": 1999
      },
      {
        "Name": "WYNYARD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019035",
        "Station ID": 3058,
        "WMO ID": null,
        "TC ID": "WYO",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -104.2,
        "Latitude": 514600000,
        "Longitude": -1041200000,
        "Elevation (m)": 560.8,
        "First Year": 1939,
        "Last Year": 2005,
        "HLY First Year": 1964,
        "HLY Last Year": 1991,
        "DLY First Year": 1939,
        "DLY Last Year": 2005,
        "MLY First Year": 1939,
        "MLY Last Year": 2005
      },
      {
        "Name": "YARBO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019039",
        "Station ID": 3059,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -101.9,
        "Latitude": 504300000,
        "Longitude": -1015400000,
        "Elevation (m)": 511.1,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "YELLOW GRASS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019040",
        "Station ID": 3060,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -104.18,
        "Latitude": 494900000,
        "Longitude": -1041100000,
        "Elevation (m)": 579.7,
        "First Year": 1911,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 2017,
        "MLY First Year": 1911,
        "MLY Last Year": 2007
      },
      {
        "Name": "YELLOW GRASS NORTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019041",
        "Station ID": 54518,
        "WMO ID": 73001,
        "TC ID": "PYS",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -104.18,
        "Latitude": 494900000,
        "Longitude": -1041100000,
        "Elevation (m)": 578,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YORKTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019070",
        "Station ID": 3061,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -102.52,
        "Latitude": 511100000,
        "Longitude": -1023100000,
        "Elevation (m)": 497.7,
        "First Year": 1884,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1945,
        "MLY First Year": 1884,
        "MLY Last Year": 1945
      },
      {
        "Name": "YORKTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019073",
        "Station ID": 49808,
        "WMO ID": 71886,
        "TC ID": "PRJ",
        "Latitude (Decimal Degrees)": 51.26,
        "Longitude (Decimal Degrees)": -102.46,
        "Latitude": 511553000,
        "Longitude": -1022752000,
        "Elevation (m)": 498.4,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YORKTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019075",
        "Station ID": 48977,
        "WMO ID": 71292,
        "TC ID": "YQV",
        "Latitude (Decimal Degrees)": 51.26,
        "Longitude (Decimal Degrees)": -102.46,
        "Latitude": 511553000,
        "Longitude": -1022742000,
        "Elevation (m)": 498.3,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YORKTON A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019080",
        "Station ID": 3062,
        "WMO ID": 71138,
        "TC ID": "YQV",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -102.47,
        "Latitude": 511600000,
        "Longitude": -1022800000,
        "Elevation (m)": 498.3,
        "First Year": 1941,
        "Last Year": 2005,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1941,
        "DLY Last Year": 2005,
        "MLY First Year": 1941,
        "MLY Last Year": 2005
      },
      {
        "Name": "TONKIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019082",
        "Station ID": 26999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -102.23,
        "Latitude": 511200000,
        "Longitude": -1021400000,
        "Elevation (m)": 526.7,
        "First Year": 1994,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2016,
        "MLY First Year": 1994,
        "MLY Last Year": 2007
      },
      {
        "Name": "YORKTON AWOS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019083",
        "Station ID": 30366,
        "WMO ID": null,
        "TC ID": "ZQV",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -102.47,
        "Latitude": 511600000,
        "Longitude": -1022800000,
        "Elevation (m)": 498.3,
        "First Year": 2005,
        "Last Year": 2005,
        "HLY First Year": 2005,
        "HLY Last Year": 2005,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YORKTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019085",
        "Station ID": 44203,
        "WMO ID": 71138,
        "TC ID": "YQV",
        "Latitude (Decimal Degrees)": 51.26,
        "Longitude (Decimal Degrees)": -102.46,
        "Latitude": 511553000,
        "Longitude": -1022742000,
        "Elevation (m)": 498.3,
        "First Year": 2005,
        "Last Year": 2011,
        "HLY First Year": 2005,
        "HLY Last Year": 2011,
        "DLY First Year": 2005,
        "DLY Last Year": 2011,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "YORKTON CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019090",
        "Station ID": 3063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -102.42,
        "Latitude": 510800000,
        "Longitude": -1022500000,
        "Elevation (m)": 503.8,
        "First Year": 1951,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1972,
        "MLY First Year": 1951,
        "MLY Last Year": 1972
      },
      {
        "Name": "YORKTON RCMP SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019095",
        "Station ID": 46609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.21,
        "Longitude (Decimal Degrees)": -102.46,
        "Latitude": 511252000,
        "Longitude": -1022750000,
        "Elevation (m)": 503,
        "First Year": 2004,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2013,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "WYNYARD (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "40190LN",
        "Station ID": 10660,
        "WMO ID": 71865,
        "TC ID": "WOY",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -104.2,
        "Latitude": 514600000,
        "Longitude": -1041200000,
        "Elevation (m)": 560.1,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "ZEHNER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4019200",
        "Station ID": 3064,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -104.4,
        "Latitude": 503800000,
        "Longitude": -1042400000,
        "Elevation (m)": 682.8,
        "First Year": 1961,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1999,
        "MLY First Year": 1961,
        "MLY Last Year": 1999
      },
      {
        "Name": "REGINA UNIVERSITY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "401FFDB",
        "Station ID": 2838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -104.58,
        "Latitude": 502500000,
        "Longitude": -1043500000,
        "Elevation (m)": 573,
        "First Year": 1968,
        "Last Year": 2000,
        "HLY First Year": 1996,
        "HLY Last Year": 2000,
        "DLY First Year": 1968,
        "DLY Last Year": 2000,
        "MLY First Year": 1968,
        "MLY Last Year": 1999
      },
      {
        "Name": "STOUGHTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "401G7R7",
        "Station ID": 6866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -103.07,
        "Latitude": 493700000,
        "Longitude": -1030400000,
        "Elevation (m)": 610,
        "First Year": 1991,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1996,
        "MLY First Year": 1991,
        "MLY Last Year": 1996
      },
      {
        "Name": "WEYBURN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "401HP5R",
        "Station ID": 10666,
        "WMO ID": 71452,
        "TC ID": "WWF",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -103.8,
        "Latitude": 494200000,
        "Longitude": -1034800000,
        "Elevation (m)": 588.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "BROADVIEW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "401I010",
        "Station ID": 26851,
        "WMO ID": null,
        "TC ID": "WXU",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -102.68,
        "Latitude": 502300000,
        "Longitude": -1024100000,
        "Elevation (m)": 600.1,
        "First Year": 1995,
        "Last Year": 2009,
        "HLY First Year": 1995,
        "HLY Last Year": 2003,
        "DLY First Year": 1995,
        "DLY Last Year": 2009,
        "MLY First Year": 1996,
        "MLY Last Year": 2003
      },
      {
        "Name": "CUTBANK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "401JR89",
        "Station ID": 9819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.29,
        "Longitude (Decimal Degrees)": -106.85,
        "Latitude": 511722000,
        "Longitude": -1065054002,
        "Elevation (m)": 574.8,
        "First Year": 2008,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2008,
        "DLY Last Year": 2020,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "REGINA COTE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "401R001",
        "Station ID": 27546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -104.67,
        "Latitude": 502600000,
        "Longitude": -1044000000,
        "Elevation (m)": 577.3,
        "First Year": 1997,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2002,
        "MLY First Year": 1997,
        "MLY Last Year": 2002
      },
      {
        "Name": "ABBEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020020",
        "Station ID": 3066,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -108.78,
        "Latitude": 504200000,
        "Longitude": -1084700000,
        "Elevation (m)": 694.9,
        "First Year": 1956,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2008,
        "MLY First Year": 1956,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALSASK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020121",
        "Station ID": 3067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -109.9,
        "Latitude": 512500000,
        "Longitude": -1095400000,
        "Elevation (m)": 661.4,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "ALSASK HARDENE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020130",
        "Station ID": 3068,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.33,
        "Longitude (Decimal Degrees)": -109.85,
        "Latitude": 512000000,
        "Longitude": -1095100000,
        "Elevation (m)": 658.4,
        "First Year": 1959,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1997,
        "MLY First Year": 1959,
        "MLY Last Year": 1997
      },
      {
        "Name": "ANEROID",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020160",
        "Station ID": 3069,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -107.3,
        "Latitude": 494300000,
        "Longitude": -1071800000,
        "Elevation (m)": 754.1,
        "First Year": 1916,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 2005,
        "MLY First Year": 1916,
        "MLY Last Year": 2005
      },
      {
        "Name": "ASSINIBOIA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020280",
        "Station ID": 3070,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -105.97,
        "Latitude": 494400000,
        "Longitude": -1055800000,
        "Elevation (m)": 723.9,
        "First Year": 1915,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1997,
        "MLY First Year": 1915,
        "MLY Last Year": 1997
      },
      {
        "Name": "ASSINIBOIA AIRPORT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020286",
        "Station ID": 10805,
        "WMO ID": 71487,
        "TC ID": "WJI",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -105.95,
        "Latitude": 494345090,
        "Longitude": -1055646010,
        "Elevation (m)": 725.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEECHY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020560",
        "Station ID": 3071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -107.31,
        "Latitude": 504955060,
        "Longitude": -1071841030,
        "Elevation (m)": 659.9,
        "First Year": 1926,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 2022,
        "MLY First Year": 1926,
        "MLY Last Year": 2007
      },
      {
        "Name": "BIRSAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020700",
        "Station ID": 3072,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -106.88,
        "Latitude": 510900000,
        "Longitude": -1065300000,
        "Elevation (m)": 589.8,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "BIRSAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020701",
        "Station ID": 3073,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -106.97,
        "Latitude": 510500000,
        "Longitude": -1065800000,
        "Elevation (m)": 594.4,
        "First Year": 1972,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1981,
        "MLY First Year": 1972,
        "MLY Last Year": 1981
      },
      {
        "Name": "BISHOPRIC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020720",
        "Station ID": 3074,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -105.78,
        "Latitude": 500000000,
        "Longitude": -1054700000,
        "Elevation (m)": 679.7,
        "First Year": 1947,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1975,
        "MLY First Year": 1947,
        "MLY Last Year": 1975
      },
      {
        "Name": "BLUMENHOF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4020772",
        "Station ID": 3075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -107.77,
        "Latitude": 500200000,
        "Longitude": -1074600000,
        "Elevation (m)": 899.2,
        "First Year": 1986,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1991,
        "MLY First Year": 1986,
        "MLY Last Year": 1991
      },
      {
        "Name": "CABRI",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021025",
        "Station ID": 3076,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -108.45,
        "Latitude": 503100000,
        "Longitude": -1082700000,
        "Elevation (m)": 678.2,
        "First Year": 1964,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1975,
        "MLY First Year": 1964,
        "MLY Last Year": 1975
      },
      {
        "Name": "CADILLAC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021040",
        "Station ID": 3077,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -107.75,
        "Latitude": 494300000,
        "Longitude": -1074500000,
        "Elevation (m)": 784.9,
        "First Year": 1923,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1987,
        "MLY First Year": 1923,
        "MLY Last Year": 1987
      },
      {
        "Name": "CARMICHAEL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021200",
        "Station ID": 3078,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -108.65,
        "Latitude": 500300000,
        "Longitude": -1083900000,
        "Elevation (m)": 807.4,
        "First Year": 1953,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1971,
        "MLY First Year": 1953,
        "MLY Last Year": 1971
      },
      {
        "Name": "CARMICHAEL EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021240",
        "Station ID": 3079,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -108.65,
        "Latitude": 500300000,
        "Longitude": -1083900000,
        "Elevation (m)": 807.4,
        "First Year": 1951,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1954,
        "MLY First Year": 1951,
        "MLY Last Year": 1954
      },
      {
        "Name": "CHAPLIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021520",
        "Station ID": 3080,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -106.65,
        "Latitude": 502800000,
        "Longitude": -1063900000,
        "Elevation (m)": 671.5,
        "First Year": 1883,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1995,
        "MLY First Year": 1883,
        "MLY Last Year": 1995
      },
      {
        "Name": "CODERRE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021600",
        "Station ID": 3081,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -106.37,
        "Latitude": 500800000,
        "Longitude": -1062200000,
        "Elevation (m)": 684.9,
        "First Year": 1946,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 2008,
        "MLY First Year": 1946,
        "MLY Last Year": 2007
      },
      {
        "Name": "CONGRESS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021650",
        "Station ID": 3082,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -106.12,
        "Latitude": 494600000,
        "Longitude": -1060700000,
        "Elevation (m)": 719.9,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "COURVAL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021850",
        "Station ID": 3083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -106.25,
        "Latitude": 501000000,
        "Longitude": -1061500000,
        "Elevation (m)": 666.3,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1959,
        "MLY Last Year": 1964
      },
      {
        "Name": "COURVAL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021851",
        "Station ID": 3084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -105.88,
        "Latitude": 501100000,
        "Longitude": -1055300000,
        "Elevation (m)": 693.4,
        "First Year": 1971,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1999,
        "MLY First Year": 1971,
        "MLY Last Year": 1999
      },
      {
        "Name": "CYPRESS HILLS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4021996",
        "Station ID": 3085,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -109.47,
        "Latitude": 494000000,
        "Longitude": -1092800000,
        "Elevation (m)": 1196,
        "First Year": 1981,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2014,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEMAINE CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022180",
        "Station ID": 3086,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -107.25,
        "Latitude": 505400000,
        "Longitude": -1071500000,
        "Elevation (m)": 697.1,
        "First Year": 1959,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1974,
        "MLY First Year": 1959,
        "MLY Last Year": 1974
      },
      {
        "Name": "EASTLEIGH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022328",
        "Station ID": 3087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -106.18,
        "Latitude": 501700000,
        "Longitude": -1061100000,
        "Elevation (m)": 709,
        "First Year": 1958,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2007,
        "MLY First Year": 1958,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELBOW CS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022359",
        "Station ID": 3088,
        "WMO ID": 71450,
        "TC ID": "WOK",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -106.58,
        "Latitude": 510800000,
        "Longitude": -1063500000,
        "Elevation (m)": 595,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELBOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022360",
        "Station ID": 3089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.12,
        "Longitude (Decimal Degrees)": -106.6,
        "Latitude": 510700000,
        "Longitude": -1063600000,
        "Elevation (m)": 588.3,
        "First Year": 1918,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1983,
        "MLY First Year": 1918,
        "MLY Last Year": 1983
      },
      {
        "Name": "ELBOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022361",
        "Station ID": 3090,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -106.58,
        "Latitude": 510800000,
        "Longitude": -1063500000,
        "Elevation (m)": 594.7,
        "First Year": 1982,
        "Last Year": 1990,
        "HLY First Year": 1982,
        "HLY Last Year": 1990,
        "DLY First Year": 1982,
        "DLY Last Year": 1990,
        "MLY First Year": 1982,
        "MLY Last Year": 1990
      },
      {
        "Name": "ELBOW 2 NE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022363",
        "Station ID": 3091,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -106.55,
        "Latitude": 511000000,
        "Longitude": -1063300000,
        "Elevation (m)": 605.3,
        "First Year": 1990,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2021,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELROSE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022368",
        "Station ID": 3092,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -108.03,
        "Latitude": 510800000,
        "Longitude": -1080200000,
        "Elevation (m)": 609.9,
        "First Year": 1973,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2006,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "ESTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022440",
        "Station ID": 3093,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -108.77,
        "Latitude": 510900000,
        "Longitude": -1084600000,
        "Elevation (m)": 680,
        "First Year": 1951,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1995,
        "MLY First Year": 1951,
        "MLY Last Year": 1995
      },
      {
        "Name": "FLINTOFT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022540",
        "Station ID": 3094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -106.23,
        "Latitude": 492700000,
        "Longitude": -1061400000,
        "Elevation (m)": 800.1,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "FOX VALLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022680",
        "Station ID": 3095,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -109.43,
        "Latitude": 502400000,
        "Longitude": -1092600000,
        "Elevation (m)": 708.7,
        "First Year": 1951,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1969,
        "MLY First Year": 1951,
        "MLY Last Year": 1969
      },
      {
        "Name": "GARDEN HEAD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022800",
        "Station ID": 3096,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -108.57,
        "Latitude": 494900000,
        "Longitude": -1083400000,
        "Elevation (m)": 905.3,
        "First Year": 1911,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1972,
        "MLY First Year": 1911,
        "MLY Last Year": 1972
      },
      {
        "Name": "GILROY CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022840",
        "Station ID": 3097,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -106.73,
        "Latitude": 505000000,
        "Longitude": -1064400000,
        "Elevation (m)": 646.5,
        "First Year": 1951,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1967,
        "MLY First Year": 1951,
        "MLY Last Year": 1967
      },
      {
        "Name": "GLENTWORTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022934",
        "Station ID": 3098,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -106.67,
        "Latitude": 492700000,
        "Longitude": -1064000000,
        "Elevation (m)": 609.6,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "GOLDEN PRAIRIE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022936",
        "Station ID": 3099,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -109.8,
        "Latitude": 501100000,
        "Longitude": -1094800000,
        "Elevation (m)": 746.6,
        "First Year": 1971,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2008,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRAVELBOURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022960",
        "Station ID": 3100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -106.55,
        "Latitude": 495300000,
        "Longitude": -1063300000,
        "Elevation (m)": 700.1,
        "First Year": 1911,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1994,
        "MLY First Year": 1911,
        "MLY Last Year": 1994
      },
      {
        "Name": "GRAVELBOURG CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022976",
        "Station ID": 3101,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -106.72,
        "Latitude": 495200000,
        "Longitude": -1064300000,
        "Elevation (m)": 700.1,
        "First Year": 1951,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1972,
        "MLY First Year": 1951,
        "MLY Last Year": 1972
      },
      {
        "Name": "ELBOW RADAR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4022L62",
        "Station ID": 9023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -106.58,
        "Latitude": 510800000,
        "Longitude": -1063500000,
        "Elevation (m)": 594.6,
        "First Year": 1999,
        "Last Year": 2000,
        "HLY First Year": 1999,
        "HLY Last Year": 2000,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GULL LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023053",
        "Station ID": 3102,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -108.55,
        "Latitude": 501600000,
        "Longitude": -1083300000,
        "Elevation (m)": 762,
        "First Year": 1971,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1977,
        "MLY First Year": 1971,
        "MLY Last Year": 1977
      },
      {
        "Name": "GULL LAKE CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023060",
        "Station ID": 3103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -108.47,
        "Latitude": 495700000,
        "Longitude": -1082800000,
        "Elevation (m)": 906.8,
        "First Year": 1956,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1991,
        "MLY First Year": 1956,
        "MLY Last Year": 1991
      },
      {
        "Name": "HAZENMORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023160",
        "Station ID": 3104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -107.1,
        "Latitude": 493500000,
        "Longitude": -1070600000,
        "Elevation (m)": 739.1,
        "First Year": 1953,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1979,
        "MLY First Year": 1953,
        "MLY Last Year": 1979
      },
      {
        "Name": "HERBERT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023210",
        "Station ID": 3105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -107.22,
        "Latitude": 502600000,
        "Longitude": -1071300000,
        "Elevation (m)": 701,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "HERBERT 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023212",
        "Station ID": 3106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -107.27,
        "Latitude": 502800000,
        "Longitude": -1071600000,
        "Elevation (m)": 710,
        "First Year": 1984,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2004,
        "MLY First Year": 1984,
        "MLY Last Year": 2004
      },
      {
        "Name": "HIGH POINT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023240",
        "Station ID": 3107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -107.94,
        "Latitude": 505843006,
        "Longitude": -1075607003,
        "Elevation (m)": 647,
        "First Year": 1928,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 2006,
        "MLY First Year": 1928,
        "MLY Last Year": 2006
      },
      {
        "Name": "HODGEVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023260",
        "Station ID": 3108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -106.97,
        "Latitude": 500700000,
        "Longitude": -1065800000,
        "Elevation (m)": 696.5,
        "First Year": 1955,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1969,
        "MLY First Year": 1955,
        "MLY Last Year": 1969
      },
      {
        "Name": "HODGEVILLE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023263",
        "Station ID": 3109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -106.93,
        "Latitude": 495900000,
        "Longitude": -1065600000,
        "Elevation (m)": 723.9,
        "First Year": 1962,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1973,
        "MLY First Year": 1962,
        "MLY Last Year": 1973
      },
      {
        "Name": "HUGHTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023360",
        "Station ID": 3110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -107.92,
        "Latitude": 511400000,
        "Longitude": -1075500000,
        "Elevation (m)": 612.6,
        "First Year": 1922,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1973,
        "MLY First Year": 1922,
        "MLY Last Year": 1973
      },
      {
        "Name": "ILLERBRUN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023430",
        "Station ID": 3111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -108.37,
        "Latitude": 495300000,
        "Longitude": -1082200000,
        "Elevation (m)": 891.5,
        "First Year": 1910,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1949,
        "MLY First Year": 1910,
        "MLY Last Year": 1949
      },
      {
        "Name": "INGEBRIGHT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023500",
        "Station ID": 3112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -109.35,
        "Latitude": 502200000,
        "Longitude": -1092100000,
        "Elevation (m)": 701,
        "First Year": 1964,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1998,
        "MLY First Year": 1964,
        "MLY Last Year": 1998
      },
      {
        "Name": "INSTOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023520",
        "Station ID": 3113,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -108.28,
        "Latitude": 494300000,
        "Longitude": -1081700000,
        "Elevation (m)": 903.4,
        "First Year": 1950,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1976,
        "MLY First Year": 1950,
        "MLY Last Year": 1976
      },
      {
        "Name": "KINCAID",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4023800",
        "Station ID": 3114,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -107,
        "Latitude": 494000000,
        "Longitude": -1070000000,
        "Elevation (m)": 739.1,
        "First Year": 1951,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2000,
        "MLY First Year": 1951,
        "MLY Last Year": 2000
      },
      {
        "Name": "KLINTONEL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024080",
        "Station ID": 3115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -108.92,
        "Latitude": 494100000,
        "Longitude": -1085500000,
        "Elevation (m)": 1074.4,
        "First Year": 1910,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1994,
        "MLY First Year": 1910,
        "MLY Last Year": 1994
      },
      {
        "Name": "KYLE CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024120",
        "Station ID": 3116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -108.02,
        "Latitude": 504400000,
        "Longitude": -1080100000,
        "Elevation (m)": 685.8,
        "First Year": 1954,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1970,
        "MLY First Year": 1954,
        "MLY Last Year": 1970
      },
      {
        "Name": "KYLE I",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024121",
        "Station ID": 3117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -107.93,
        "Latitude": 505200000,
        "Longitude": -1075600000,
        "Elevation (m)": 640.1,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "KYLE SOUTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024122",
        "Station ID": 3118,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -108.22,
        "Latitude": 504200000,
        "Longitude": -1081300000,
        "Elevation (m)": 662.9,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "LAFLECHE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024135",
        "Station ID": 3119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -106.57,
        "Latitude": 493900000,
        "Longitude": -1063400000,
        "Elevation (m)": 750,
        "First Year": 1971,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1997,
        "MLY First Year": 1971,
        "MLY Last Year": 1997
      },
      {
        "Name": "LEADER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024160",
        "Station ID": 3120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -109.55,
        "Latitude": 505300000,
        "Longitude": -1093300000,
        "Elevation (m)": 671.2,
        "First Year": 1922,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1969,
        "MLY First Year": 1922,
        "MLY Last Year": 1969
      },
      {
        "Name": "LEADER 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024161",
        "Station ID": 3121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -109.53,
        "Latitude": 505300000,
        "Longitude": -1093200000,
        "Elevation (m)": 673.6,
        "First Year": 1951,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1997,
        "MLY First Year": 1951,
        "MLY Last Year": 1997
      },
      {
        "Name": "LIMERICK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024400",
        "Station ID": 3122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -106.25,
        "Latitude": 493000000,
        "Longitude": -1061500000,
        "Elevation (m)": 759,
        "First Year": 1951,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1993,
        "MLY First Year": 1951,
        "MLY Last Year": 1993
      },
      {
        "Name": "LUCKY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024714",
        "Station ID": 10724,
        "WMO ID": 71455,
        "TC ID": "WLE",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -107.15,
        "Latitude": 505700000,
        "Longitude": -1070900000,
        "Elevation (m)": 664.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "LUCKY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024715",
        "Station ID": 3123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -107.12,
        "Latitude": 505800000,
        "Longitude": -1070700000,
        "Elevation (m)": 641,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "MACRORIE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024880",
        "Station ID": 3124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -107.08,
        "Latitude": 511900000,
        "Longitude": -1070500000,
        "Elevation (m)": 696.8,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "MAPLE CREEK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024919",
        "Station ID": 10667,
        "WMO ID": 71453,
        "TC ID": "WMQ",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -109.47,
        "Latitude": 495409050,
        "Longitude": -1092758060,
        "Elevation (m)": 766.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAPLE CREEK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024920",
        "Station ID": 3125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -109.48,
        "Latitude": 495400000,
        "Longitude": -1092900000,
        "Elevation (m)": 763.5,
        "First Year": 1884,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1982,
        "MLY First Year": 1884,
        "MLY Last Year": 1982
      },
      {
        "Name": "MAPLE CREEK NORTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024936",
        "Station ID": 3126,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -109.47,
        "Latitude": 500000000,
        "Longitude": -1092800000,
        "Elevation (m)": 764.1,
        "First Year": 1951,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2008,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAPLE CREEK CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024937",
        "Station ID": 3127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -109.67,
        "Latitude": 494900000,
        "Longitude": -1094000000,
        "Elevation (m)": 762,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1954,
        "MLY Last Year": 1961
      },
      {
        "Name": "MAPLE CREEK H S",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4024940",
        "Station ID": 3128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -109.48,
        "Latitude": 495400000,
        "Longitude": -1092900000,
        "Elevation (m)": 767,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "MATADOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4025047",
        "Station ID": 3130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -107.72,
        "Latitude": 504200000,
        "Longitude": -1074300000,
        "Elevation (m)": 676,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "MATADOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "40250MP",
        "Station ID": 3129,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -107.88,
        "Latitude": 504800000,
        "Longitude": -1075300000,
        "Elevation (m)": 670.6,
        "First Year": 1976,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1981,
        "MLY First Year": 1976,
        "MLY Last Year": 1981
      },
      {
        "Name": "MITCHELLTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4025242",
        "Station ID": 3131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -105.63,
        "Latitude": 495800000,
        "Longitude": -1053800000,
        "Elevation (m)": 716.3,
        "First Year": 1971,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1977,
        "MLY First Year": 1971,
        "MLY Last Year": 1977
      },
      {
        "Name": "NEIDPATH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4025485",
        "Station ID": 3132,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -107.1,
        "Latitude": 501300000,
        "Longitude": -1070600000,
        "Elevation (m)": 777.2,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "NEVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4025490",
        "Station ID": 3133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -107.58,
        "Latitude": 495800000,
        "Longitude": -1073500000,
        "Elevation (m)": 853.4,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "PALMER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4025855",
        "Station ID": 3134,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -106.35,
        "Latitude": 495200000,
        "Longitude": -1062100000,
        "Elevation (m)": 727,
        "First Year": 1962,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1991,
        "MLY First Year": 1962,
        "MLY Last Year": 1991
      },
      {
        "Name": "PAMBRUN CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4025860",
        "Station ID": 3135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50,
        "Longitude (Decimal Degrees)": -107.43,
        "Latitude": 500000000,
        "Longitude": -1072600000,
        "Elevation (m)": 777.2,
        "First Year": 1956,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1970,
        "MLY First Year": 1956,
        "MLY Last Year": 1970
      },
      {
        "Name": "PENNANT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026040",
        "Station ID": 3136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -108.23,
        "Latitude": 503200000,
        "Longitude": -1081400000,
        "Elevation (m)": 730,
        "First Year": 1922,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1996,
        "MLY First Year": 1922,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORTREEVE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026175",
        "Station ID": 3137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -109.05,
        "Latitude": 505300000,
        "Longitude": -1090300000,
        "Elevation (m)": 677.9,
        "First Year": 1981,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2002,
        "MLY First Year": 1981,
        "MLY Last Year": 2002
      },
      {
        "Name": "READLYN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026480",
        "Station ID": 3138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -105.67,
        "Latitude": 493500000,
        "Longitude": -1054000000,
        "Elevation (m)": 676.4,
        "First Year": 1934,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1987,
        "MLY First Year": 1934,
        "MLY Last Year": 1987
      },
      {
        "Name": "RICHLEA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026787",
        "Station ID": 3139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -108.6,
        "Latitude": 510900000,
        "Longitude": -1083600000,
        "Elevation (m)": 678.2,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "RIVERHURST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026799",
        "Station ID": 3140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -106.87,
        "Latitude": 505500000,
        "Longitude": -1065200000,
        "Elevation (m)": 646.5,
        "First Year": 1967,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1971,
        "MLY First Year": 1967,
        "MLY Last Year": 1971
      },
      {
        "Name": "ROADENE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026840",
        "Station ID": 3141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -108.75,
        "Latitude": 502900000,
        "Longitude": -1084500000,
        "Elevation (m)": 723,
        "First Year": 1936,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1989,
        "MLY First Year": 1936,
        "MLY Last Year": 1989
      },
      {
        "Name": "ROCK POINT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4026847",
        "Station ID": 3142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -107.26,
        "Latitude": 510914007,
        "Longitude": -1071548004,
        "Elevation (m)": 725.1,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "RUSH LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027000",
        "Station ID": 3143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -107.4,
        "Latitude": 502400000,
        "Longitude": -1072400000,
        "Elevation (m)": 712,
        "First Year": 1952,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1975,
        "MLY First Year": 1952,
        "MLY Last Year": 1975
      },
      {
        "Name": "SANCTUARY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027110",
        "Station ID": 3144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -108.05,
        "Latitude": 510200000,
        "Longitude": -1080300000,
        "Elevation (m)": 609.6,
        "First Year": 1976,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1987,
        "MLY First Year": 1976,
        "MLY Last Year": 1987
      },
      {
        "Name": "SHACKLETON CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027400",
        "Station ID": 3145,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.68,
        "Longitude (Decimal Degrees)": -108.68,
        "Latitude": 504100000,
        "Longitude": -1084100000,
        "Elevation (m)": 708.7,
        "First Year": 1951,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1999,
        "MLY First Year": 1951,
        "MLY Last Year": 1999
      },
      {
        "Name": "SHAMROCK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027440",
        "Station ID": 3146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -106.72,
        "Latitude": 501100000,
        "Longitude": -1064300000,
        "Elevation (m)": 741,
        "First Year": 1945,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1996,
        "MLY First Year": 1945,
        "MLY Last Year": 1996
      },
      {
        "Name": "SHAUNAVON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027480",
        "Station ID": 3147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -108.4,
        "Latitude": 493900000,
        "Longitude": -1082400000,
        "Elevation (m)": 917.4,
        "First Year": 1915,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1979,
        "MLY First Year": 1915,
        "MLY Last Year": 1979
      },
      {
        "Name": "SHAUNAVON CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027482",
        "Station ID": 3148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 494500000,
        "Longitude": -1082500000,
        "Elevation (m)": 908.3,
        "First Year": 1951,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1964,
        "MLY First Year": 1951,
        "MLY Last Year": 1964
      },
      {
        "Name": "SHAUNAVON 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027485",
        "Station ID": 3149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 493900000,
        "Longitude": -1082500000,
        "Elevation (m)": 914.4,
        "First Year": 1971,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2003,
        "MLY First Year": 1971,
        "MLY Last Year": 2003
      },
      {
        "Name": "SHAUNAVON 3",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027486",
        "Station ID": 3150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 495400000,
        "Longitude": -1082500000,
        "Elevation (m)": 892,
        "First Year": 1971,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2008,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "STEWART VALLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027775",
        "Station ID": 3151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -107.8,
        "Latitude": 503600000,
        "Longitude": -1074800000,
        "Elevation (m)": 727,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "STRONGFIELD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027880",
        "Station ID": 3152,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.33,
        "Longitude (Decimal Degrees)": -106.6,
        "Latitude": 512000000,
        "Longitude": -1063600000,
        "Elevation (m)": 613.6,
        "First Year": 1931,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1995,
        "MLY First Year": 1931,
        "MLY Last Year": 1995
      },
      {
        "Name": "SURPRISE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4027960",
        "Station ID": 3153,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -109.98,
        "Latitude": 501800000,
        "Longitude": -1095900000,
        "Elevation (m)": 762,
        "First Year": 1923,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1949,
        "MLY First Year": 1923,
        "MLY Last Year": 1949
      },
      {
        "Name": "SWIFT CURRENT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028035",
        "Station ID": 3154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.33,
        "Longitude (Decimal Degrees)": -107.75,
        "Latitude": 502000000,
        "Longitude": -1074500000,
        "Elevation (m)": 743.7,
        "First Year": 1885,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1938,
        "MLY First Year": 1885,
        "MLY Last Year": 1938
      },
      {
        "Name": "SWIFT CURRENT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028038",
        "Station ID": 48976,
        "WMO ID": 71142,
        "TC ID": "YYN",
        "Latitude (Decimal Degrees)": 50.29,
        "Longitude (Decimal Degrees)": -107.69,
        "Latitude": 501731000,
        "Longitude": -1074126000,
        "Elevation (m)": 816.9,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SWIFT CURRENT A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028040",
        "Station ID": 3155,
        "WMO ID": 71870,
        "TC ID": "YYN",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -107.68,
        "Latitude": 501800000,
        "Longitude": -1074100000,
        "Elevation (m)": 816.86,
        "First Year": 1938,
        "Last Year": 2011,
        "HLY First Year": 1953,
        "HLY Last Year": 2011,
        "DLY First Year": 1938,
        "DLY Last Year": 2011,
        "MLY First Year": 1938,
        "MLY Last Year": 2007
      },
      {
        "Name": "SWIFT CURRENT (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028042",
        "Station ID": 7479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -107.73,
        "Latitude": 501600000,
        "Longitude": -1074400000,
        "Elevation (m)": 826,
        "First Year": 1982,
        "Last Year": 1986,
        "HLY First Year": 1982,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SWIFT CURRENT CDA SRL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028056",
        "Station ID": 3156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -107.75,
        "Latitude": 501700000,
        "Longitude": -1074500000,
        "Elevation (m)": 761.7,
        "First Year": 1956,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1971,
        "MLY First Year": 1956,
        "MLY Last Year": 1971
      },
      {
        "Name": "SWIFT CURRENT CDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028060",
        "Station ID": 3157,
        "WMO ID": 71446,
        "TC ID": "WVC",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -107.73,
        "Latitude": 501600000,
        "Longitude": -1074400000,
        "Elevation (m)": 825,
        "First Year": 1959,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1959,
        "DLY Last Year": 2022,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "TOMPKINS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028101",
        "Station ID": 3158,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -108.8,
        "Latitude": 500300000,
        "Longitude": -1084800000,
        "Elevation (m)": 811,
        "First Year": 1984,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2007,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "VALJEAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028360",
        "Station ID": 3159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.38,
        "Longitude (Decimal Degrees)": -106.55,
        "Latitude": 502300000,
        "Longitude": -1063300000,
        "Elevation (m)": 670.6,
        "First Year": 1951,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1963,
        "MLY First Year": 1951,
        "MLY Last Year": 1963
      },
      {
        "Name": "WAPASHOE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4028500",
        "Station ID": 3160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -109.38,
        "Latitude": 500400000,
        "Longitude": -1092300000,
        "Elevation (m)": 731.5,
        "First Year": 1914,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1960,
        "MLY First Year": 1914,
        "MLY Last Year": 1960
      },
      {
        "Name": "WOOD MOUNTAIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4029029",
        "Station ID": 3161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -106.25,
        "Latitude": 492000000,
        "Longitude": -1061500000,
        "Elevation (m)": 845.8,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "WOODROW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4029030",
        "Station ID": 3162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -106.78,
        "Latitude": 493400000,
        "Longitude": -1064700000,
        "Elevation (m)": 730,
        "First Year": 1962,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2008,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAPORTE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "402DADF",
        "Station ID": 3065,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.22,
        "Longitude (Decimal Degrees)": -109.52,
        "Latitude": 511300000,
        "Longitude": -1093100000,
        "Elevation (m)": 736.1,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "LEADER AIRPORT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "402DAF0",
        "Station ID": 10795,
        "WMO ID": 71459,
        "TC ID": "WJX",
        "Latitude (Decimal Degrees)": 50.91,
        "Longitude (Decimal Degrees)": -109.5,
        "Latitude": 505434070,
        "Longitude": -1093005010,
        "Elevation (m)": 675.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "BENGOUGH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4030569",
        "Station ID": 3163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -105.13,
        "Latitude": 492300000,
        "Longitude": -1050800000,
        "Elevation (m)": 688.7,
        "First Year": 1982,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1997,
        "MLY First Year": 1982,
        "MLY Last Year": 1997
      },
      {
        "Name": "BIG BEAVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4030594",
        "Station ID": 3164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -105.02,
        "Latitude": 490100000,
        "Longitude": -1050100000,
        "Elevation (m)": 742.9,
        "First Year": 1985,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1989,
        "MLY First Year": 1985,
        "MLY Last Year": 1989
      },
      {
        "Name": "BRACKEN CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4030800",
        "Station ID": 3165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -108.1,
        "Latitude": 491200000,
        "Longitude": -1080600000,
        "Elevation (m)": 888.2,
        "First Year": 1951,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1972,
        "MLY First Year": 1951,
        "MLY Last Year": 1972
      },
      {
        "Name": "BRACKEN NORTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4030805",
        "Station ID": 3166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -108.12,
        "Latitude": 492300000,
        "Longitude": -1080700000,
        "Elevation (m)": 914.4,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "CHAMBERY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031480",
        "Station ID": 3167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -108.43,
        "Latitude": 492200000,
        "Longitude": -1082600000,
        "Elevation (m)": 929.6,
        "First Year": 1949,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1963,
        "MLY First Year": 1949,
        "MLY Last Year": 1963
      },
      {
        "Name": "CLAYDON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031581",
        "Station ID": 3168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -109.1,
        "Latitude": 490800000,
        "Longitude": -1090600000,
        "Elevation (m)": 975,
        "First Year": 1971,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2017,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLIMAX",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031585",
        "Station ID": 3169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -108.38,
        "Latitude": 491200000,
        "Longitude": -1082300000,
        "Elevation (m)": 939,
        "First Year": 1962,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2007,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "CONSUL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031760",
        "Station ID": 3170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -109.52,
        "Latitude": 491800000,
        "Longitude": -1093100000,
        "Elevation (m)": 930.9,
        "First Year": 1921,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1970,
        "MLY First Year": 1921,
        "MLY Last Year": 1970
      },
      {
        "Name": "CONSUL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031776",
        "Station ID": 3171,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -109.48,
        "Latitude": 491800000,
        "Longitude": -1092900000,
        "Elevation (m)": 941.5,
        "First Year": 1951,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1989,
        "MLY First Year": 1951,
        "MLY Last Year": 1989
      },
      {
        "Name": "CORONACH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031844",
        "Station ID": 3173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -105.52,
        "Latitude": 490700000,
        "Longitude": -1053100000,
        "Elevation (m)": 772.9,
        "First Year": 1971,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2018,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "CORONACH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031845",
        "Station ID": 3174,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -105.6,
        "Latitude": 491600000,
        "Longitude": -1053600000,
        "Elevation (m)": 831,
        "First Year": 1928,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1959,
        "MLY First Year": 1928,
        "MLY Last Year": 1959
      },
      {
        "Name": "CORONACH SPC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "40318MN",
        "Station ID": 3172,
        "WMO ID": 71516,
        "TC ID": "WAQ",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -105.48,
        "Latitude": 490300000,
        "Longitude": -1052900000,
        "Elevation (m)": 756,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "CYPRESS HILLS PARK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4031999",
        "Station ID": 10888,
        "WMO ID": 71139,
        "TC ID": "WVP",
        "Latitude (Decimal Degrees)": 49.64,
        "Longitude (Decimal Degrees)": -109.51,
        "Latitude": 493826009,
        "Longitude": -1093052004,
        "Elevation (m)": 1270.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "CYPRESS HILLS PARK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032000",
        "Station ID": 3175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -109.48,
        "Latitude": 494000000,
        "Longitude": -1092900000,
        "Elevation (m)": 1371.6,
        "First Year": 1918,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1972,
        "MLY First Year": 1918,
        "MLY Last Year": 1972
      },
      {
        "Name": "EASTEND CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032320",
        "Station ID": 3176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -108.75,
        "Latitude": 493100000,
        "Longitude": -1084500000,
        "Elevation (m)": 912.9,
        "First Year": 1951,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1966,
        "MLY First Year": 1951,
        "MLY Last Year": 1966
      },
      {
        "Name": "EASTEND CDA EPF SOUTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032321",
        "Station ID": 3177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -108.77,
        "Latitude": 492200000,
        "Longitude": -1084600000,
        "Elevation (m)": 914.4,
        "First Year": 1952,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1975,
        "MLY First Year": 1952,
        "MLY Last Year": 1975
      },
      {
        "Name": "EASTEND CYPRESS (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032322",
        "Station ID": 7485,
        "WMO ID": 71131,
        "TC ID": "WEH",
        "Latitude (Decimal Degrees)": 49.44,
        "Longitude (Decimal Degrees)": -108.99,
        "Latitude": 492616010,
        "Longitude": -1085919050,
        "Elevation (m)": 1079.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "EASTEND 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032323",
        "Station ID": 3178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -108.82,
        "Latitude": 493100000,
        "Longitude": -1084900000,
        "Elevation (m)": 914.4,
        "First Year": 1971,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1994,
        "MLY First Year": 1971,
        "MLY Last Year": 1994
      },
      {
        "Name": "EAST POPLAR RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032335",
        "Station ID": 3179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -105.42,
        "Latitude": 490000000,
        "Longitude": -1052500000,
        "Elevation (m)": 739.1,
        "First Year": 1961,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1979,
        "MLY First Year": 1961,
        "MLY Last Year": 1979
      },
      {
        "Name": "FLAP JACK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032510",
        "Station ID": 3180,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -109.88,
        "Latitude": 493200000,
        "Longitude": -1095300000,
        "Elevation (m)": 1036.3,
        "First Year": 1961,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1967,
        "MLY First Year": 1961,
        "MLY Last Year": 1967
      },
      {
        "Name": "FORT WALSH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032640",
        "Station ID": 3181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -109.57,
        "Latitude": 492800000,
        "Longitude": -1093400000,
        "Elevation (m)": 1104.6,
        "First Year": 1952,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1957,
        "MLY First Year": 1952,
        "MLY Last Year": 1957
      },
      {
        "Name": "FRENCHMAN VALLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032762",
        "Station ID": 3182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -107.78,
        "Latitude": 492300000,
        "Longitude": -1074700000,
        "Elevation (m)": 823,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "FRONTIER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4032766",
        "Station ID": 3183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -108.57,
        "Latitude": 491200000,
        "Longitude": -1083400000,
        "Elevation (m)": 901,
        "First Year": 1973,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2008,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "LIDGETT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4034380",
        "Station ID": 3184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -106.58,
        "Latitude": 491800000,
        "Longitude": -1063500000,
        "Elevation (m)": 914.4,
        "First Year": 1922,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1940,
        "MLY First Year": 1922,
        "MLY Last Year": 1940
      },
      {
        "Name": "LISIEUX",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4034500",
        "Station ID": 3185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -105.98,
        "Latitude": 491600000,
        "Longitude": -1055900000,
        "Elevation (m)": 817.2,
        "First Year": 1956,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1959,
        "MLY First Year": 1956,
        "MLY Last Year": 1959
      },
      {
        "Name": "MANKOTA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4034910",
        "Station ID": 3186,
        "WMO ID": 71204,
        "TC ID": "PMK",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -107.02,
        "Latitude": 490557000,
        "Longitude": -1070128030,
        "Elevation (m)": 830,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "MANKOTA 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4034912",
        "Station ID": 3187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -106.95,
        "Latitude": 490000000,
        "Longitude": -1065700000,
        "Elevation (m)": 785.2,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "MERRYFLAT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4035120",
        "Station ID": 3188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -109.77,
        "Latitude": 492800000,
        "Longitude": -1094600000,
        "Elevation (m)": 1036.3,
        "First Year": 1949,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1968,
        "MLY First Year": 1949,
        "MLY Last Year": 1968
      },
      {
        "Name": "MINTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4035200",
        "Station ID": 3189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -104.58,
        "Latitude": 491000000,
        "Longitude": -1043500000,
        "Elevation (m)": 695,
        "First Year": 1954,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1995,
        "MLY First Year": 1954,
        "MLY Last Year": 1995
      },
      {
        "Name": "NASHLYN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4035480",
        "Station ID": 3190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -109.52,
        "Latitude": 491200000,
        "Longitude": -1093100000,
        "Elevation (m)": 944.9,
        "First Year": 1911,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1979,
        "MLY First Year": 1911,
        "MLY Last Year": 1979
      },
      {
        "Name": "RAVENSCRAG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4036440",
        "Station ID": 3191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -109.08,
        "Latitude": 492900000,
        "Longitude": -1090500000,
        "Elevation (m)": 953.1,
        "First Year": 1956,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1969,
        "MLY First Year": 1956,
        "MLY Last Year": 1969
      },
      {
        "Name": "ROBSART",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4036841",
        "Station ID": 3192,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -109.35,
        "Latitude": 492300000,
        "Longitude": -1092100000,
        "Elevation (m)": 961.9,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "ROCKGLEN (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4036844",
        "Station ID": 3193,
        "WMO ID": 71135,
        "TC ID": "WKO",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -105.98,
        "Latitude": 491004040,
        "Longitude": -1055845060,
        "Elevation (m)": 917,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": 1970,
        "HLY Last Year": 2022,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "SENATE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4037360",
        "Station ID": 3194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -109.7,
        "Latitude": 491600000,
        "Longitude": -1094200000,
        "Elevation (m)": 967.7,
        "First Year": 1952,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1977,
        "MLY First Year": 1952,
        "MLY Last Year": 1977
      },
      {
        "Name": "TREELON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038116",
        "Station ID": 3195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -108.38,
        "Latitude": 490000000,
        "Longitude": -1082300000,
        "Elevation (m)": 902,
        "First Year": 1971,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2017,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "VAL-MARIE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038400",
        "Station ID": 3196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -107.85,
        "Latitude": 492212050,
        "Longitude": -1075051090,
        "Elevation (m)": 808,
        "First Year": 1937,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 2010,
        "MLY First Year": 1937,
        "MLY Last Year": 2007
      },
      {
        "Name": "VAL-MARIE EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038410",
        "Station ID": 3197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -107.65,
        "Latitude": 491500000,
        "Longitude": -1073900000,
        "Elevation (m)": 793.1,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "VAL MARIE SOUTHEAST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038412",
        "Station ID": 10821,
        "WMO ID": 71137,
        "TC ID": "WVN",
        "Latitude (Decimal Degrees)": 49.06,
        "Longitude (Decimal Degrees)": -107.59,
        "Latitude": 490330001,
        "Longitude": -1073509010,
        "Elevation (m)": 796,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "VICEROY CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038460",
        "Station ID": 3198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -105.37,
        "Latitude": 493100000,
        "Longitude": -1052200000,
        "Elevation (m)": 725.4,
        "First Year": 1956,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1973,
        "MLY First Year": 1956,
        "MLY Last Year": 1973
      },
      {
        "Name": "VICEROY EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038470",
        "Station ID": 3199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -105.35,
        "Latitude": 493100000,
        "Longitude": -1052100000,
        "Elevation (m)": 725.4,
        "First Year": 1951,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1955,
        "MLY First Year": 1951,
        "MLY Last Year": 1955
      },
      {
        "Name": "VIDORA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038490",
        "Station ID": 3200,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -109.5,
        "Latitude": 492100000,
        "Longitude": -1093000000,
        "Elevation (m)": 944.9,
        "First Year": 1918,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1932,
        "MLY First Year": 1918,
        "MLY Last Year": 1931
      },
      {
        "Name": "WEST POPLAR RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4038740",
        "Station ID": 3201,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -106.38,
        "Latitude": 490000000,
        "Longitude": -1062300000,
        "Elevation (m)": 876,
        "First Year": 1956,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2002,
        "MLY First Year": 1956,
        "MLY Last Year": 2002
      },
      {
        "Name": "WILLOW CREEK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4039000",
        "Station ID": 3202,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -109.92,
        "Latitude": 490100000,
        "Longitude": -1095500000,
        "Elevation (m)": 833.3,
        "First Year": 1910,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1974,
        "MLY First Year": 1910,
        "MLY Last Year": 1974
      },
      {
        "Name": "WILLOW CREEK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4039001",
        "Station ID": 3203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -109.82,
        "Latitude": 490500000,
        "Longitude": -1094900000,
        "Elevation (m)": 861,
        "First Year": 1978,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1998,
        "MLY First Year": 1978,
        "MLY Last Year": 1998
      },
      {
        "Name": "WOODLOT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4039028",
        "Station ID": 3204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -109.93,
        "Latitude": 493400000,
        "Longitude": -1095600000,
        "Elevation (m)": 1009.2,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "ALSASK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040120",
        "Station ID": 3205,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -110,
        "Latitude": 512300000,
        "Longitude": -1100000000,
        "Elevation (m)": 701,
        "First Year": 1913,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1951,
        "MLY First Year": 1913,
        "MLY Last Year": 1951
      },
      {
        "Name": "ANGLIA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040180",
        "Station ID": 3206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -108.17,
        "Latitude": 513400000,
        "Longitude": -1081000000,
        "Elevation (m)": 567.2,
        "First Year": 1913,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1945,
        "MLY First Year": 1913,
        "MLY Last Year": 1945
      },
      {
        "Name": "ARTLAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040267",
        "Station ID": 3207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.78,
        "Longitude (Decimal Degrees)": -109.9,
        "Latitude": 524700000,
        "Longitude": -1095400000,
        "Elevation (m)": 640.1,
        "First Year": 1983,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2005,
        "MLY First Year": 1983,
        "MLY Last Year": 2005
      },
      {
        "Name": "BAD LAKE IHD 102",
        "Province": "SASKATCHEWAN",
        "Climate ID": "404037Q",
        "Station ID": 3208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 511900000,
        "Longitude": -1082500000,
        "Elevation (m)": 637,
        "First Year": 1971,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1986,
        "MLY First Year": 1971,
        "MLY Last Year": 1986
      },
      {
        "Name": "BATTLEFORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040420",
        "Station ID": 3209,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.68,
        "Longitude (Decimal Degrees)": -108.33,
        "Latitude": 524100000,
        "Longitude": -1082000000,
        "Elevation (m)": 493.8,
        "First Year": 1879,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1942,
        "MLY First Year": 1891,
        "MLY Last Year": 1941
      },
      {
        "Name": "BATTLEFORDS UNION HOSPITAL SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040430",
        "Station ID": 46608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -108.29,
        "Latitude": 524621000,
        "Longitude": -1081718000,
        "Elevation (m)": 529,
        "First Year": 2007,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2008,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BICKLEIGH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040587",
        "Station ID": 3210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.29,
        "Longitude (Decimal Degrees)": -108.41,
        "Latitude": 511740000,
        "Longitude": -1082424070,
        "Elevation (m)": 676.4,
        "First Year": 1988,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2021,
        "MLY First Year": 1988,
        "MLY Last Year": 2007
      },
      {
        "Name": "BIGGAR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4040600",
        "Station ID": 3211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -107.98,
        "Latitude": 520400000,
        "Longitude": -1075900000,
        "Elevation (m)": 670.6,
        "First Year": 1917,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 2005,
        "MLY First Year": 1917,
        "MLY Last Year": 2005
      },
      {
        "Name": "BUTTE ST PIERRE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4041000",
        "Station ID": 3212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -109.2,
        "Latitude": 532700000,
        "Longitude": -1091200000,
        "Elevation (m)": 571.5,
        "First Year": 1955,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2022,
        "MLY First Year": 1955,
        "MLY Last Year": 2007
      },
      {
        "Name": "CANDO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4041087",
        "Station ID": 3213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -108.12,
        "Latitude": 522400000,
        "Longitude": -1080700000,
        "Elevation (m)": 716.3,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "CANDO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4041088",
        "Station ID": 3214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -108.27,
        "Latitude": 522400000,
        "Longitude": -1081600000,
        "Elevation (m)": 710,
        "First Year": 1984,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1999,
        "MLY First Year": 1984,
        "MLY Last Year": 1999
      },
      {
        "Name": "CARRUTHERS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4041405",
        "Station ID": 3215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.9,
        "Longitude (Decimal Degrees)": -109.18,
        "Latitude": 525400000,
        "Longitude": -1091100000,
        "Elevation (m)": 643.1,
        "First Year": 1984,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1993,
        "MLY First Year": 1984,
        "MLY Last Year": 1993
      },
      {
        "Name": "DENZIL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042188",
        "Station ID": 3216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -109.58,
        "Latitude": 521800000,
        "Longitude": -1093500000,
        "Elevation (m)": 696,
        "First Year": 1959,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1986,
        "MLY First Year": 1960,
        "MLY Last Year": 1986
      },
      {
        "Name": "EDAM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042338",
        "Station ID": 3217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.18,
        "Longitude (Decimal Degrees)": -108.77,
        "Latitude": 531100000,
        "Longitude": -1084600000,
        "Elevation (m)": 549,
        "First Year": 1985,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1998,
        "MLY First Year": 1985,
        "MLY Last Year": 1998
      },
      {
        "Name": "FRENCHMAN BUTTE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042760",
        "Station ID": 3218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -109.63,
        "Latitude": 533500000,
        "Longitude": -1093800000,
        "Elevation (m)": 549.9,
        "First Year": 1951,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1970,
        "MLY First Year": 1951,
        "MLY Last Year": 1970
      },
      {
        "Name": "GLASLYN CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042880",
        "Station ID": 3219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -108.27,
        "Latitude": 532000000,
        "Longitude": -1081600000,
        "Elevation (m)": 689.8,
        "First Year": 1909,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 1979,
        "MLY First Year": 1909,
        "MLY Last Year": 1979
      },
      {
        "Name": "GLASLYN CDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042881",
        "Station ID": 3220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.32,
        "Longitude (Decimal Degrees)": -108.68,
        "Latitude": 531900000,
        "Longitude": -1084100000,
        "Elevation (m)": 609.6,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "GLASLYN 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042884",
        "Station ID": 3221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -108.13,
        "Latitude": 532500000,
        "Longitude": -1080800000,
        "Elevation (m)": 690.1,
        "First Year": 1983,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1990,
        "MLY First Year": 1983,
        "MLY Last Year": 1990
      },
      {
        "Name": "GLENBUSH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042920",
        "Station ID": 3222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -108,
        "Latitude": 531400000,
        "Longitude": -1080000000,
        "Elevation (m)": 688.5,
        "First Year": 1951,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1971,
        "MLY First Year": 1951,
        "MLY Last Year": 1971
      },
      {
        "Name": "GLENBUSH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4042921",
        "Station ID": 3223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.1,
        "Longitude (Decimal Degrees)": -108.03,
        "Latitude": 530600000,
        "Longitude": -1080200000,
        "Elevation (m)": 632.2,
        "First Year": 1974,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1985,
        "MLY First Year": 1974,
        "MLY Last Year": 1985
      },
      {
        "Name": "HERSCHEL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043215",
        "Station ID": 3224,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.68,
        "Longitude (Decimal Degrees)": -108.3,
        "Latitude": 514100000,
        "Longitude": -1081800000,
        "Elevation (m)": 609.6,
        "First Year": 1958,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1966,
        "MLY First Year": 1958,
        "MLY Last Year": 1966
      },
      {
        "Name": "HERSCHEL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043216",
        "Station ID": 3225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -108.37,
        "Latitude": 513800000,
        "Longitude": -1082200000,
        "Elevation (m)": 582.2,
        "First Year": 1984,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1987,
        "MLY First Year": 1984,
        "MLY Last Year": 1987
      },
      {
        "Name": "HILLMOND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043246",
        "Station ID": 3226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.44,
        "Longitude (Decimal Degrees)": -109.72,
        "Latitude": 532632000,
        "Longitude": -1094324009,
        "Elevation (m)": 583.6,
        "First Year": 1970,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2009,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "HILLMOND 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043248",
        "Station ID": 3227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.43,
        "Longitude (Decimal Degrees)": -109.72,
        "Latitude": 532600000,
        "Longitude": -1094300000,
        "Elevation (m)": 609.6,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "KELFIELD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043653",
        "Station ID": 3228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -108.58,
        "Latitude": 520000000,
        "Longitude": -1083500000,
        "Elevation (m)": 684.9,
        "First Year": 1973,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1990,
        "MLY First Year": 1973,
        "MLY Last Year": 1990
      },
      {
        "Name": "KERROBERT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043750",
        "Station ID": 3229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -109.05,
        "Latitude": 515818020,
        "Longitude": -1090253030,
        "Elevation (m)": 666.4,
        "First Year": 1964,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2008,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "KINDERSLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043888",
        "Station ID": 3230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -109.15,
        "Latitude": 512800000,
        "Longitude": -1090900000,
        "Elevation (m)": 680.6,
        "First Year": 1910,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1971,
        "MLY First Year": 1910,
        "MLY Last Year": 1971
      },
      {
        "Name": "KINDERSLEY A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043899",
        "Station ID": 54280,
        "WMO ID": null,
        "TC ID": "YKY",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -109.18,
        "Latitude": 513055000,
        "Longitude": -1091050000,
        "Elevation (m)": 693.7,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINDERSLEY A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043900",
        "Station ID": 3231,
        "WMO ID": 71129,
        "TC ID": "YKY",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -109.18,
        "Latitude": 513100000,
        "Longitude": -1091100000,
        "Elevation (m)": 693.72,
        "First Year": 1984,
        "Last Year": 2013,
        "HLY First Year": 1984,
        "HLY Last Year": 2013,
        "DLY First Year": 1984,
        "DLY Last Year": 2013,
        "MLY First Year": 1984,
        "MLY Last Year": 2013
      },
      {
        "Name": "KINDERSLEY A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043901",
        "Station ID": 51739,
        "WMO ID": 71129,
        "TC ID": "YKY",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -109.18,
        "Latitude": 513055000,
        "Longitude": -1091050000,
        "Elevation (m)": 693.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINDERSLEY AIRPORT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043902",
        "Station ID": 55278,
        "WMO ID": 73097,
        "TC ID": "PSY",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -109.18,
        "Latitude": 513058003,
        "Longitude": -1091040032,
        "Elevation (m)": 691.11,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINDERSLEY CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043904",
        "Station ID": 3232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -109.17,
        "Latitude": 513000000,
        "Longitude": -1091000000,
        "Elevation (m)": 680.6,
        "First Year": 1951,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1975,
        "MLY First Year": 1951,
        "MLY Last Year": 1975
      },
      {
        "Name": "KINDERSLEY KY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4043920",
        "Station ID": 3233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -109.17,
        "Latitude": 512800000,
        "Longitude": -1091000000,
        "Elevation (m)": 683.4,
        "First Year": 1958,
        "Last Year": 1984,
        "HLY First Year": 1958,
        "HLY Last Year": 1984,
        "DLY First Year": 1958,
        "DLY Last Year": 1984,
        "MLY First Year": 1958,
        "MLY Last Year": 1984
      },
      {
        "Name": "LASHBURN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044152",
        "Station ID": 3234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -109.55,
        "Latitude": 530200000,
        "Longitude": -1093300000,
        "Elevation (m)": 594.4,
        "First Year": 1970,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2000,
        "MLY First Year": 1970,
        "MLY Last Year": 2000
      },
      {
        "Name": "LASHBURN 1",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044155",
        "Station ID": 30619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.12,
        "Longitude (Decimal Degrees)": -109.63,
        "Latitude": 530700000,
        "Longitude": -1093800000,
        "Elevation (m)": 617.2,
        "First Year": 2000,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2006,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "LLOYDMINSTER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044558",
        "Station ID": 3235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.3,
        "Longitude (Decimal Degrees)": -110,
        "Latitude": 531800000,
        "Longitude": -1100000000,
        "Elevation (m)": 646.2,
        "First Year": 1904,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1918,
        "MLY First Year": 1904,
        "MLY Last Year": 1918
      },
      {
        "Name": "LLOYDMINSTER 12E",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044562",
        "Station ID": 3236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -109.67,
        "Latitude": 531700000,
        "Longitude": -1094000000,
        "Elevation (m)": 647.7,
        "First Year": 1974,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1997,
        "MLY First Year": 1974,
        "MLY Last Year": 1997
      },
      {
        "Name": "LOVERNA CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044680",
        "Station ID": 3237,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -110,
        "Latitude": 513900000,
        "Longitude": -1100000000,
        "Elevation (m)": 689.2,
        "First Year": 1951,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1971,
        "MLY First Year": 1951,
        "MLY Last Year": 1971
      },
      {
        "Name": "LUSELAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044735",
        "Station ID": 3238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -109.4,
        "Latitude": 520500000,
        "Longitude": -1092400000,
        "Elevation (m)": 701.3,
        "First Year": 1910,
        "Last Year": 1924,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1924,
        "MLY First Year": 1910,
        "MLY Last Year": 1924
      },
      {
        "Name": "MACKLIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044800",
        "Station ID": 3239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -109.95,
        "Latitude": 522000000,
        "Longitude": -1095700000,
        "Elevation (m)": 667.2,
        "First Year": 1918,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1988,
        "MLY First Year": 1918,
        "MLY Last Year": 1988
      },
      {
        "Name": "MACKLIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044850",
        "Station ID": 45827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -109.95,
        "Latitude": 521958040,
        "Longitude": -1095651000,
        "Elevation (m)": 670.7,
        "First Year": 2007,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2018,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAIDSTONE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4044887",
        "Station ID": 3240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.08,
        "Longitude (Decimal Degrees)": -109.3,
        "Latitude": 530500000,
        "Longitude": -1091800000,
        "Elevation (m)": 591.6,
        "First Year": 1964,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1968,
        "MLY First Year": 1964,
        "MLY Last Year": 1968
      },
      {
        "Name": "MAYMONT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045049",
        "Station ID": 3241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.6,
        "Longitude (Decimal Degrees)": -107.65,
        "Latitude": 523600000,
        "Longitude": -1073900000,
        "Elevation (m)": 563.9,
        "First Year": 1976,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "MEDSTEAD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045070",
        "Station ID": 3242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -108.03,
        "Latitude": 532100000,
        "Longitude": -1080200000,
        "Elevation (m)": 699.5,
        "First Year": 1990,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2008,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "NEILBURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045487",
        "Station ID": 3243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -109.6,
        "Latitude": 524800000,
        "Longitude": -1093600000,
        "Elevation (m)": 668.1,
        "First Year": 1983,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1991,
        "MLY First Year": 1983,
        "MLY Last Year": 1991
      },
      {
        "Name": "NORTH BATTLEFORD A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045600",
        "Station ID": 3244,
        "WMO ID": 71876,
        "TC ID": "YQW",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -108.26,
        "Latitude": 524619004,
        "Longitude": -1081520007,
        "Elevation (m)": 548.33,
        "First Year": 1942,
        "Last Year": 2005,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1942,
        "DLY Last Year": 2005,
        "MLY First Year": 1942,
        "MLY Last Year": 2005
      },
      {
        "Name": "NORTH BATTLEFORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045605",
        "Station ID": 44084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -108.25,
        "Latitude": 524600000,
        "Longitude": -1081500000,
        "Elevation (m)": 548,
        "First Year": 2005,
        "Last Year": 2014,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2014,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORTH BATTLEFORD RCS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045607",
        "Station ID": 44344,
        "WMO ID": 71754,
        "TC ID": "PQW",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -108.26,
        "Latitude": 524619004,
        "Longitude": -1081520007,
        "Elevation (m)": 548,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "ONION LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045660",
        "Station ID": 3245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -109.98,
        "Latitude": 535200000,
        "Longitude": -1095900000,
        "Elevation (m)": null,
        "First Year": 1903,
        "Last Year": 1917,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1917,
        "MLY First Year": 1903,
        "MLY Last Year": 1911
      },
      {
        "Name": "NORTH BATTLEFORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045695",
        "Station ID": 48609,
        "WMO ID": 71876,
        "TC ID": "YQW",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -108.24,
        "Latitude": 524609000,
        "Longitude": -1081437000,
        "Elevation (m)": 548.3,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PAYNTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4045975",
        "Station ID": 3246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -108.95,
        "Latitude": 530100000,
        "Longitude": -1085700000,
        "Elevation (m)": 560.8,
        "First Year": 1970,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1997,
        "MLY First Year": 1970,
        "MLY Last Year": 1997
      },
      {
        "Name": "PRINCE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046200",
        "Station ID": 3247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.97,
        "Longitude (Decimal Degrees)": -108.37,
        "Latitude": 525800000,
        "Longitude": -1082200000,
        "Elevation (m)": 536.1,
        "First Year": 1907,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 1973,
        "MLY First Year": 1907,
        "MLY Last Year": 1973
      },
      {
        "Name": "PRONGUA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046280",
        "Station ID": 3248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.73,
        "Longitude (Decimal Degrees)": -108.55,
        "Latitude": 524400000,
        "Longitude": -1083300000,
        "Elevation (m)": 563,
        "First Year": 1927,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1960,
        "MLY First Year": 1927,
        "MLY Last Year": 1960
      },
      {
        "Name": "RABBIT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046360",
        "Station ID": 3249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.1,
        "Longitude (Decimal Degrees)": -107.87,
        "Latitude": 530600000,
        "Longitude": -1075200000,
        "Elevation (m)": 704.1,
        "First Year": 1930,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1989,
        "MLY First Year": 1930,
        "MLY Last Year": 1989
      },
      {
        "Name": "ROSETOWN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046879",
        "Station ID": 3250,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.62,
        "Longitude (Decimal Degrees)": -108.02,
        "Latitude": 513700000,
        "Longitude": -1080100000,
        "Elevation (m)": 586.1,
        "First Year": 1981,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2000,
        "MLY First Year": 1981,
        "MLY Last Year": 2000
      },
      {
        "Name": "ROSETOWN CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046880",
        "Station ID": 3251,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -107.88,
        "Latitude": 513100000,
        "Longitude": -1075300000,
        "Elevation (m)": 591.3,
        "First Year": 1916,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1981,
        "MLY First Year": 1916,
        "MLY Last Year": 1981
      },
      {
        "Name": "ROSETOWN EAST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046884",
        "Station ID": 10784,
        "WMO ID": 71510,
        "TC ID": "WRJ",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -107.92,
        "Latitude": 513400000,
        "Longitude": -1075500000,
        "Elevation (m)": 586,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "RUDDELL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4046977",
        "Station ID": 3252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.58,
        "Longitude (Decimal Degrees)": -107.85,
        "Latitude": 523500000,
        "Longitude": -1075100000,
        "Elevation (m)": 538,
        "First Year": 1985,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2008,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "RUTHILDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047040",
        "Station ID": 3253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -108.52,
        "Latitude": 515400000,
        "Longitude": -1083100000,
        "Elevation (m)": 694.9,
        "First Year": 1941,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1966,
        "MLY First Year": 1941,
        "MLY Last Year": 1967
      },
      {
        "Name": "ST DONATUS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047050",
        "Station ID": 3254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -109.9,
        "Latitude": 515900000,
        "Longitude": -1095400000,
        "Elevation (m)": 745,
        "First Year": 1973,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1995,
        "MLY First Year": 1973,
        "MLY Last Year": 1995
      },
      {
        "Name": "ST WALBURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047080",
        "Station ID": 3255,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -109.22,
        "Latitude": 533800000,
        "Longitude": -1091300000,
        "Elevation (m)": 640.4,
        "First Year": 1912,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1973,
        "MLY First Year": 1912,
        "MLY Last Year": 1973
      },
      {
        "Name": "ST WALBURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047081",
        "Station ID": 3256,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -109.15,
        "Latitude": 534300000,
        "Longitude": -1090900000,
        "Elevation (m)": 662.9,
        "First Year": 1983,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1994,
        "MLY First Year": 1983,
        "MLY Last Year": 1994
      },
      {
        "Name": "SALT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047100",
        "Station ID": 3257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -109.5,
        "Latitude": 521800000,
        "Longitude": -1093000000,
        "Elevation (m)": 725.4,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "SCOTSTOWN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047238",
        "Station ID": 3258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.38,
        "Longitude (Decimal Degrees)": -109.4,
        "Latitude": 522300000,
        "Longitude": -1092400000,
        "Elevation (m)": 685,
        "First Year": 1962,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2008,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "SCOTT CDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047240",
        "Station ID": 3259,
        "WMO ID": 71489,
        "TC ID": "WTS",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -108.83,
        "Latitude": 522135064,
        "Longitude": -1085005004,
        "Elevation (m)": 659.6,
        "First Year": 1911,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1911,
        "DLY Last Year": 2022,
        "MLY First Year": 1911,
        "MLY Last Year": 2007
      },
      {
        "Name": "SCOTT CS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047241",
        "Station ID": 27490,
        "WMO ID": 71489,
        "TC ID": "WTS",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -108.83,
        "Latitude": 522135064,
        "Longitude": -1085005004,
        "Elevation (m)": 659.6,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "SMILEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047540",
        "Station ID": 3260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -109.47,
        "Latitude": 513800000,
        "Longitude": -1092800000,
        "Elevation (m)": 697.7,
        "First Year": 1987,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1988,
        "MLY First Year": 1987,
        "MLY Last Year": 1987
      },
      {
        "Name": "SONNINGDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047644",
        "Station ID": 3261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -107.77,
        "Latitude": 521900000,
        "Longitude": -1074600000,
        "Elevation (m)": 716.3,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOVEREIGN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047680",
        "Station ID": 3262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -107.75,
        "Latitude": 513000000,
        "Longitude": -1074500000,
        "Elevation (m)": 585.2,
        "First Year": 1954,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "SOVEREIGN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047681",
        "Station ID": 3263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -107.72,
        "Latitude": 513100000,
        "Longitude": -1074300000,
        "Elevation (m)": 586.7,
        "First Year": 1984,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2005,
        "MLY First Year": 1984,
        "MLY Last Year": 2005
      },
      {
        "Name": "SPRUCE LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047730",
        "Station ID": 3264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -109.08,
        "Latitude": 533200000,
        "Longitude": -1090500000,
        "Elevation (m)": 603.8,
        "First Year": 1955,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1971,
        "MLY First Year": 1955,
        "MLY Last Year": 1971
      },
      {
        "Name": "STRANRAER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4047798",
        "Station ID": 3265,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -108.52,
        "Latitude": 514200000,
        "Longitude": -1083100000,
        "Elevation (m)": 640.8,
        "First Year": 1964,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1992,
        "MLY First Year": 1964,
        "MLY Last Year": 1992
      },
      {
        "Name": "TURTLEFORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4048240",
        "Station ID": 3266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.4,
        "Longitude (Decimal Degrees)": -108.95,
        "Latitude": 532400000,
        "Longitude": -1085700000,
        "Elevation (m)": 586.7,
        "First Year": 1920,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1964,
        "MLY First Year": 1920,
        "MLY Last Year": 1964
      },
      {
        "Name": "TURTLEFORD CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4048250",
        "Station ID": 3267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -109,
        "Latitude": 532700000,
        "Longitude": -1090000000,
        "Elevation (m)": 586.7,
        "First Year": 1951,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1971,
        "MLY First Year": 1951,
        "MLY Last Year": 1971
      },
      {
        "Name": "UNITY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4048320",
        "Station ID": 3268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -109.08,
        "Latitude": 522500000,
        "Longitude": -1090500000,
        "Elevation (m)": 640.1,
        "First Year": 1956,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "UNITY SOUTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4048326",
        "Station ID": 3269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -109.2,
        "Latitude": 521400000,
        "Longitude": -1091200000,
        "Elevation (m)": 655.3,
        "First Year": 1986,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2005,
        "MLY First Year": 1986,
        "MLY Last Year": 2005
      },
      {
        "Name": "WASECA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4048520",
        "Station ID": 3270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.13,
        "Longitude (Decimal Degrees)": -109.4,
        "Latitude": 530751080,
        "Longitude": -1092414050,
        "Elevation (m)": 638,
        "First Year": 1907,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1907,
        "DLY Last Year": 2014,
        "MLY First Year": 1907,
        "MLY Last Year": 2007
      },
      {
        "Name": "WASECA RCS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4048521",
        "Station ID": 51798,
        "WMO ID": 71663,
        "TC ID": "PWX",
        "Latitude (Decimal Degrees)": 53.13,
        "Longitude (Decimal Degrees)": -109.41,
        "Latitude": 530753008,
        "Longitude": -1092418006,
        "Elevation (m)": 640,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ABERDEEN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050037",
        "Station ID": 3272,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -106.17,
        "Latitude": 522400000,
        "Longitude": -1061000000,
        "Elevation (m)": 518.2,
        "First Year": 1987,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1991,
        "MLY First Year": 1987,
        "MLY Last Year": 1991
      },
      {
        "Name": "AMYOT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050153",
        "Station ID": 3273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.7,
        "Longitude (Decimal Degrees)": -106.68,
        "Latitude": 534200000,
        "Longitude": -1064100000,
        "Elevation (m)": null,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "ASQUITH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050275",
        "Station ID": 3274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -107.23,
        "Latitude": 520800000,
        "Longitude": -1071400000,
        "Elevation (m)": 519.4,
        "First Year": 1961,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1981,
        "MLY First Year": 1961,
        "MLY Last Year": 1981
      },
      {
        "Name": "BEATTY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050480",
        "Station ID": 3275,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -104.85,
        "Latitude": 525200000,
        "Longitude": -1045100000,
        "Elevation (m)": 451.1,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1956
      },
      {
        "Name": "BEAVER CREEK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050500",
        "Station ID": 6871,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -106.7,
        "Latitude": 515800000,
        "Longitude": -1064200000,
        "Elevation (m)": 487.7,
        "First Year": 1992,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2000,
        "MLY First Year": 1992,
        "MLY Last Year": 2000
      },
      {
        "Name": "BIRCH HILLS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050675",
        "Station ID": 3276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53,
        "Longitude (Decimal Degrees)": -105.48,
        "Latitude": 530000000,
        "Longitude": -1052900000,
        "Elevation (m)": 459.9,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "BLACKSTRAP",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050768",
        "Station ID": 3277,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.78,
        "Longitude (Decimal Degrees)": -106.4,
        "Latitude": 514700000,
        "Longitude": -1062400000,
        "Elevation (m)": 536.4,
        "First Year": 1974,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1981,
        "MLY First Year": 1974,
        "MLY Last Year": 1980
      },
      {
        "Name": "BLAINE LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050770",
        "Station ID": 3278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -106.9,
        "Latitude": 524600000,
        "Longitude": -1065400000,
        "Elevation (m)": 518.2,
        "First Year": 1983,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1983,
        "MLY First Year": 1983,
        "MLY Last Year": 1983
      },
      {
        "Name": "BORDEN 18N",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4050774",
        "Station ID": 29412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.55,
        "Longitude (Decimal Degrees)": -107.23,
        "Latitude": 523300000,
        "Longitude": -1071400000,
        "Elevation (m)": 541.02,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMEO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4051080",
        "Station ID": 3279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -106.55,
        "Latitude": 531700000,
        "Longitude": -1063300000,
        "Elevation (m)": 487.7,
        "First Year": 1952,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1996,
        "MLY First Year": 1952,
        "MLY Last Year": 1996
      },
      {
        "Name": "CARLTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4051147",
        "Station ID": 3280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -106.57,
        "Latitude": 524800000,
        "Longitude": -1063400000,
        "Elevation (m)": 520.6,
        "First Year": 1986,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2009,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "COLONSAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4051636",
        "Station ID": 3281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.93,
        "Longitude (Decimal Degrees)": -105.77,
        "Latitude": 515600000,
        "Longitude": -1054600000,
        "Elevation (m)": 542.5,
        "First Year": 1971,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1994,
        "MLY First Year": 1971,
        "MLY Last Year": 1994
      },
      {
        "Name": "CONQUEST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4051720",
        "Station ID": 3282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -107.25,
        "Latitude": 513100000,
        "Longitude": -1071500000,
        "Elevation (m)": 571.2,
        "First Year": 1951,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1964,
        "MLY First Year": 1951,
        "MLY Last Year": 1964
      },
      {
        "Name": "CUDWORTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4051920",
        "Station ID": 3283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.48,
        "Longitude (Decimal Degrees)": -105.73,
        "Latitude": 522900000,
        "Longitude": -1054400000,
        "Elevation (m)": 572.4,
        "First Year": 1953,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1954,
        "MLY First Year": 1953,
        "MLY Last Year": 1954
      },
      {
        "Name": "DANA CFB",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052070",
        "Station ID": 3284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -105.77,
        "Latitude": 521700000,
        "Longitude": -1054600000,
        "Elevation (m)": 616.6,
        "First Year": 1965,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1987,
        "MLY First Year": 1965,
        "MLY Last Year": 1987
      },
      {
        "Name": "DELISLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052175",
        "Station ID": 3285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.95,
        "Longitude (Decimal Degrees)": -107.15,
        "Latitude": 515700000,
        "Longitude": -1070900000,
        "Elevation (m)": 525.8,
        "First Year": 1961,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "DINSMORE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052210",
        "Station ID": 3286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -107.33,
        "Latitude": 511900000,
        "Longitude": -1072000000,
        "Elevation (m)": 644,
        "First Year": 1962,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1998,
        "MLY First Year": 1962,
        "MLY Last Year": 1998
      },
      {
        "Name": "DONAVON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052230",
        "Station ID": 3287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -107.18,
        "Latitude": 514800000,
        "Longitude": -1071100000,
        "Elevation (m)": 533.4,
        "First Year": 1961,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1967,
        "MLY First Year": 1961,
        "MLY Last Year": 1967
      },
      {
        "Name": "DUNDURN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052280",
        "Station ID": 3288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -106.5,
        "Latitude": 514800000,
        "Longitude": -1063000000,
        "Elevation (m)": 529.4,
        "First Year": 1922,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1985,
        "MLY First Year": 1922,
        "MLY Last Year": 1985
      },
      {
        "Name": "ETHELTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052448",
        "Station ID": 3289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.77,
        "Longitude (Decimal Degrees)": -104.9,
        "Latitude": 524600000,
        "Longitude": -1045400000,
        "Elevation (m)": 466,
        "First Year": 1971,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1997,
        "MLY First Year": 1971,
        "MLY Last Year": 1997
      },
      {
        "Name": "FOXFORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052671",
        "Station ID": 3290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.42,
        "Longitude (Decimal Degrees)": -105.12,
        "Latitude": 532500000,
        "Longitude": -1050700000,
        "Elevation (m)": 467.9,
        "First Year": 1971,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1983,
        "MLY First Year": 1971,
        "MLY Last Year": 1983
      },
      {
        "Name": "GATEHOUSE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052810",
        "Station ID": 3291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -106.05,
        "Latitude": 533500000,
        "Longitude": -1060300000,
        "Elevation (m)": null,
        "First Year": 1959,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1965,
        "MLY First Year": 1959,
        "MLY Last Year": 1965
      },
      {
        "Name": "GLENSIDE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052933",
        "Station ID": 3292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -106.83,
        "Latitude": 512800000,
        "Longitude": -1065000000,
        "Elevation (m)": 568,
        "First Year": 1971,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1993,
        "MLY First Year": 1971,
        "MLY Last Year": 1993
      },
      {
        "Name": "GRASSWOOD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4052956",
        "Station ID": 3293,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.07,
        "Longitude (Decimal Degrees)": -106.63,
        "Latitude": 520400000,
        "Longitude": -1063800000,
        "Elevation (m)": 502.9,
        "First Year": 1981,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1992,
        "MLY First Year": 1981,
        "MLY Last Year": 1992
      },
      {
        "Name": "HAFFORD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053080",
        "Station ID": 3294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.72,
        "Longitude (Decimal Degrees)": -107.37,
        "Latitude": 524300000,
        "Longitude": -1072200000,
        "Elevation (m)": 587,
        "First Year": 1951,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1993,
        "MLY First Year": 1951,
        "MLY Last Year": 1993
      },
      {
        "Name": "HAGUE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053083",
        "Station ID": 3295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.5,
        "Longitude (Decimal Degrees)": -106.48,
        "Latitude": 523000000,
        "Longitude": -1062900000,
        "Elevation (m)": 520,
        "First Year": 1970,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1989,
        "MLY First Year": 1970,
        "MLY Last Year": 1989
      },
      {
        "Name": "HALCYONIA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053090",
        "Station ID": 3296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.5,
        "Longitude (Decimal Degrees)": -107.08,
        "Latitude": 523000000,
        "Longitude": -1070500000,
        "Elevation (m)": 548.6,
        "First Year": 1914,
        "Last Year": 1924,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1924,
        "MLY First Year": 1914,
        "MLY Last Year": 1924
      },
      {
        "Name": "HARRIS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053120",
        "Station ID": 3297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -107.58,
        "Latitude": 514400000,
        "Longitude": -1073500000,
        "Elevation (m)": 577.6,
        "First Year": 1922,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2006,
        "MLY First Year": 1922,
        "MLY Last Year": 2006
      },
      {
        "Name": "HENRIBOURG",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053192",
        "Station ID": 3298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.43,
        "Longitude (Decimal Degrees)": -105.58,
        "Latitude": 532600000,
        "Longitude": -1053500000,
        "Elevation (m)": 477,
        "First Year": 1982,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1985,
        "MLY First Year": 1982,
        "MLY Last Year": 1985
      },
      {
        "Name": "HENRIBOURG CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053200",
        "Station ID": 3299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -105.6,
        "Latitude": 532200000,
        "Longitude": -1053600000,
        "Elevation (m)": 464.8,
        "First Year": 1951,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1966,
        "MLY First Year": 1951,
        "MLY Last Year": 1966
      },
      {
        "Name": "HOEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053264",
        "Station ID": 3300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -105.78,
        "Latitude": 525100000,
        "Longitude": -1054700000,
        "Elevation (m)": 473,
        "First Year": 1986,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2022,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOLBEIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053266",
        "Station ID": 3301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -106.15,
        "Latitude": 531400000,
        "Longitude": -1060900000,
        "Elevation (m)": 488,
        "First Year": 1982,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1986,
        "MLY First Year": 1982,
        "MLY Last Year": 1986
      },
      {
        "Name": "KINISTINO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4053960",
        "Station ID": 3302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -105.02,
        "Latitude": 525700000,
        "Longitude": -1050100000,
        "Elevation (m)": 462.7,
        "First Year": 1911,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1961,
        "MLY First Year": 1911,
        "MLY Last Year": 1961
      },
      {
        "Name": "KRYDOR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4054110",
        "Station ID": 3303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.7,
        "Longitude (Decimal Degrees)": -107.03,
        "Latitude": 524200000,
        "Longitude": -1070200000,
        "Elevation (m)": 594.4,
        "First Year": 1970,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1976,
        "MLY First Year": 1970,
        "MLY Last Year": 1976
      },
      {
        "Name": "LEASK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4054240",
        "Station ID": 3304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -106.75,
        "Latitude": 530100000,
        "Longitude": -1064500000,
        "Elevation (m)": 525.8,
        "First Year": 1953,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1958,
        "MLY First Year": 1953,
        "MLY Last Year": 1958
      },
      {
        "Name": "LEASK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4054241",
        "Station ID": 3305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.07,
        "Longitude (Decimal Degrees)": -106.98,
        "Latitude": 530400000,
        "Longitude": -1065900000,
        "Elevation (m)": 559,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "LENEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4054306",
        "Station ID": 3306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.88,
        "Longitude (Decimal Degrees)": -107.52,
        "Latitude": 515300000,
        "Longitude": -1073100000,
        "Elevation (m)": 555.7,
        "First Year": 1961,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1992,
        "MLY First Year": 1962,
        "MLY Last Year": 1992
      },
      {
        "Name": "MACDOWALL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4054795",
        "Station ID": 3307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -106.02,
        "Latitude": 530100000,
        "Longitude": -1060100000,
        "Elevation (m)": 477,
        "First Year": 1914,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2003,
        "MLY First Year": 1914,
        "MLY Last Year": 2003
      },
      {
        "Name": "MARCELIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055010",
        "Station ID": 3308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -106.8,
        "Latitude": 525500000,
        "Longitude": -1064800000,
        "Elevation (m)": 522.7,
        "First Year": 1962,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1995,
        "MLY First Year": 1962,
        "MLY Last Year": 1995
      },
      {
        "Name": "MARTENSVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055043",
        "Station ID": 3309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -106.67,
        "Latitude": 522000000,
        "Longitude": -1064000000,
        "Elevation (m)": 520,
        "First Year": 1981,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1992,
        "MLY First Year": 1981,
        "MLY Last Year": 1992
      },
      {
        "Name": "MELFORT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055079",
        "Station ID": 10727,
        "WMO ID": 71456,
        "TC ID": "WFF",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 524900000,
        "Longitude": -1043600000,
        "Elevation (m)": 490,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "MELFORT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055080",
        "Station ID": 3310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 525200000,
        "Longitude": -1043600000,
        "Elevation (m)": 462.7,
        "First Year": 1901,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1901,
        "DLY Last Year": 1960,
        "MLY First Year": 1901,
        "MLY Last Year": 1960
      },
      {
        "Name": "MELFORT CDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055085",
        "Station ID": 3311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -104.6,
        "Latitude": 524900000,
        "Longitude": -1043600000,
        "Elevation (m)": 480.1,
        "First Year": 1961,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1997,
        "MLY First Year": 1961,
        "MLY Last Year": 1997
      },
      {
        "Name": "MERIDIAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055115",
        "Station ID": 3312,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.6,
        "Longitude (Decimal Degrees)": -106.05,
        "Latitude": 533600000,
        "Longitude": -1060300000,
        "Elevation (m)": 570,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MILDEN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055165",
        "Station ID": 3313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -107.5,
        "Latitude": 512600000,
        "Longitude": -1073000000,
        "Elevation (m)": 577.6,
        "First Year": 1984,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2008,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "MUSKIKI SPRINGS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055450",
        "Station ID": 3314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -105.68,
        "Latitude": 521900000,
        "Longitude": -1054100000,
        "Elevation (m)": 544.7,
        "First Year": 1923,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1944,
        "MLY First Year": 1923,
        "MLY Last Year": 1944
      },
      {
        "Name": "NORTHSIDE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055640",
        "Station ID": 3315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -105.77,
        "Latitude": 533200000,
        "Longitude": -1054600000,
        "Elevation (m)": 493.8,
        "First Year": 1952,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1969,
        "MLY First Year": 1952,
        "MLY Last Year": 1969
      },
      {
        "Name": "OSLER 6 N",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055699",
        "Station ID": 27321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -106.5,
        "Latitude": 522500000,
        "Longitude": -1063000000,
        "Elevation (m)": 519.1,
        "First Year": 1997,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2009,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "OUTLOOK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055720",
        "Station ID": 3316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -107.05,
        "Latitude": 512900000,
        "Longitude": -1070300000,
        "Elevation (m)": 540.7,
        "First Year": 1915,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1971,
        "MLY First Year": 1915,
        "MLY Last Year": 1971
      },
      {
        "Name": "OUTLOOK 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055722",
        "Station ID": 3317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.62,
        "Longitude (Decimal Degrees)": -106.95,
        "Latitude": 513700000,
        "Longitude": -1065700000,
        "Elevation (m)": 540.1,
        "First Year": 1961,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1964
      },
      {
        "Name": "OUTLOOK PFRA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055736",
        "Station ID": 3318,
        "WMO ID": 71551,
        "TC ID": "WYE",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -107.05,
        "Latitude": 512900000,
        "Longitude": -1070300000,
        "Elevation (m)": 541,
        "First Year": 1952,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1952,
        "DLY Last Year": 2022,
        "MLY First Year": 1952,
        "MLY Last Year": 2007
      },
      {
        "Name": "PARKSIDE CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4055880",
        "Station ID": 3319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.23,
        "Longitude (Decimal Degrees)": -106.58,
        "Latitude": 531400000,
        "Longitude": -1063500000,
        "Elevation (m)": 514.5,
        "First Year": 1929,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1974,
        "MLY First Year": 1929,
        "MLY Last Year": 1974
      },
      {
        "Name": "PILGER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056120",
        "Station ID": 3320,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -105.15,
        "Latitude": 522500000,
        "Longitude": -1050900000,
        "Elevation (m)": 552,
        "First Year": 1911,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 2011,
        "MLY First Year": 1911,
        "MLY Last Year": 2007
      },
      {
        "Name": "PILGER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056121",
        "Station ID": 50977,
        "WMO ID": 71655,
        "TC ID": "PUN",
        "Latitude (Decimal Degrees)": 52.42,
        "Longitude (Decimal Degrees)": -105.15,
        "Latitude": 522500000,
        "Longitude": -1050900000,
        "Elevation (m)": 552,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE ALBERT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056230",
        "Station ID": 3321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.17,
        "Longitude (Decimal Degrees)": -105.75,
        "Latitude": 531000000,
        "Longitude": -1054500000,
        "Elevation (m)": 436.5,
        "First Year": 1884,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1942,
        "MLY First Year": 1884,
        "MLY Last Year": 1942
      },
      {
        "Name": "PRINCE ALBERT A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056240",
        "Station ID": 3322,
        "WMO ID": 71869,
        "TC ID": "YPA",
        "Latitude (Decimal Degrees)": 53.22,
        "Longitude (Decimal Degrees)": -105.67,
        "Latitude": 531300000,
        "Longitude": -1054000000,
        "Elevation (m)": 428.2,
        "First Year": 1942,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1942,
        "DLY Last Year": 2013,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "PRINCE ALBERT A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056241",
        "Station ID": 51878,
        "WMO ID": 71869,
        "TC ID": "YPA",
        "Latitude (Decimal Degrees)": 53.21,
        "Longitude (Decimal Degrees)": -105.67,
        "Latitude": 531252000,
        "Longitude": -1054023000,
        "Elevation (m)": 428.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE ALBERT GLASS FIELD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056243",
        "Station ID": 54498,
        "WMO ID": 73002,
        "TC ID": "PPR",
        "Latitude (Decimal Degrees)": 53.21,
        "Longitude (Decimal Degrees)": -105.68,
        "Latitude": 531237000,
        "Longitude": -1054036000,
        "Elevation (m)": 425.45,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRINCE ALBERT WEYERHAEUSER SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056270",
        "Station ID": 46627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.26,
        "Longitude (Decimal Degrees)": -105.55,
        "Latitude": 531519000,
        "Longitude": -1053258000,
        "Elevation (m)": 438,
        "First Year": 2007,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2008,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "RADISSON 1",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056381",
        "Station ID": 3323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.4,
        "Longitude (Decimal Degrees)": -107.42,
        "Latitude": 522400000,
        "Longitude": -1072500000,
        "Elevation (m)": 520,
        "First Year": 1976,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1995,
        "MLY First Year": 1976,
        "MLY Last Year": 1995
      },
      {
        "Name": "RADISSON 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056382",
        "Station ID": 3324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.47,
        "Longitude (Decimal Degrees)": -107.47,
        "Latitude": 522800000,
        "Longitude": -1072800000,
        "Elevation (m)": 542.5,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "ROSTHERN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4056920",
        "Station ID": 3325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.67,
        "Longitude (Decimal Degrees)": -106.33,
        "Latitude": 524000000,
        "Longitude": -1062000000,
        "Elevation (m)": 509.6,
        "First Year": 1910,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1970,
        "MLY First Year": 1910,
        "MLY Last Year": 1970
      },
      {
        "Name": "ST LOUIS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057070",
        "Station ID": 3326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -105.8,
        "Latitude": 525500000,
        "Longitude": -1054800000,
        "Elevation (m)": 440.4,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "SASKATOON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057118",
        "Station ID": 3327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -106.65,
        "Latitude": 520700000,
        "Longitude": -1063900000,
        "Elevation (m)": 502.9,
        "First Year": 1941,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1942,
        "MLY First Year": 1941,
        "MLY Last Year": 1942
      },
      {
        "Name": "SASKATOON DIEFENBAKER INT'L A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057120",
        "Station ID": 3328,
        "WMO ID": 71866,
        "TC ID": "YXE",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -106.72,
        "Latitude": 521000000,
        "Longitude": -1064300000,
        "Elevation (m)": 504.1,
        "First Year": 1892,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1892,
        "DLY Last Year": 2012,
        "MLY First Year": 1892,
        "MLY Last Year": 2007
      },
      {
        "Name": "SASKATOON CALDER CENTRE SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057122",
        "Station ID": 46647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -106.61,
        "Latitude": 520614000,
        "Longitude": -1063636000,
        "Elevation (m)": 510,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SASK POWER FLETCHER ROAD SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057124",
        "Station ID": 46687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.11,
        "Longitude (Decimal Degrees)": -106.71,
        "Latitude": 520631000,
        "Longitude": -1064218000,
        "Elevation (m)": 485,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "SASKATOON CENTRAL AVE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057130",
        "Station ID": 3329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.1,
        "Longitude (Decimal Degrees)": -106.6,
        "Latitude": 520600000,
        "Longitude": -1063600000,
        "Elevation (m)": 521.2,
        "First Year": 1974,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1989,
        "MLY First Year": 1974,
        "MLY Last Year": 1989
      },
      {
        "Name": "SASKATOON INTL A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057152",
        "Station ID": 50091,
        "WMO ID": null,
        "TC ID": "YXE",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -106.7,
        "Latitude": 521015000,
        "Longitude": -1064200000,
        "Elevation (m)": 504.1,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SASKATOON KERNEN FARM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057154",
        "Station ID": 6872,
        "WMO ID": null,
        "TC ID": "WCD",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -106.55,
        "Latitude": 520900000,
        "Longitude": -1063300000,
        "Elevation (m)": 510,
        "First Year": 1993,
        "Last Year": 2006,
        "HLY First Year": 1996,
        "HLY Last Year": 2006,
        "DLY First Year": 1993,
        "DLY Last Year": 2006,
        "MLY First Year": 1993,
        "MLY Last Year": 2005
      },
      {
        "Name": "SASKATOON KERNEN FARM CS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057155",
        "Station ID": 27489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -106.55,
        "Latitude": 520900000,
        "Longitude": -1063300000,
        "Elevation (m)": 510,
        "First Year": 1996,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2005,
        "MLY First Year": 1996,
        "MLY Last Year": 2005
      },
      {
        "Name": "SASKATOON NATIONAL HYDROLOGY SNOW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057157",
        "Station ID": 46707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.14,
        "Longitude (Decimal Degrees)": -106.62,
        "Latitude": 520826000,
        "Longitude": -1063728000,
        "Elevation (m)": 506,
        "First Year": 2007,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2008,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "SASKATOON NRC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057160",
        "Station ID": 3330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -106.63,
        "Latitude": 520800000,
        "Longitude": -1063800000,
        "Elevation (m)": 515.1,
        "First Year": 1952,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1966,
        "MLY First Year": 1952,
        "MLY Last Year": 1966
      },
      {
        "Name": "SASKATOON RCS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057165",
        "Station ID": 47707,
        "WMO ID": 71496,
        "TC ID": "POX",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -106.72,
        "Latitude": 521025000,
        "Longitude": -1064308001,
        "Elevation (m)": 504.1,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SASKATOON 1",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057170",
        "Station ID": 3331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -106.68,
        "Latitude": 521000000,
        "Longitude": -1064100000,
        "Elevation (m)": 503.8,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "SASKATOON 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057172",
        "Station ID": 3332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -106.67,
        "Latitude": 520700000,
        "Longitude": -1064000000,
        "Elevation (m)": 491,
        "First Year": 1977,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1990,
        "MLY First Year": 1977,
        "MLY Last Year": 1990
      },
      {
        "Name": "SASKATOON SRC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057180",
        "Station ID": 3333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -106.6,
        "Latitude": 520900000,
        "Longitude": -1063600000,
        "Elevation (m)": 496.8,
        "First Year": 1963,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2017,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "SASKATOON U OF S",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057200",
        "Station ID": 3334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -106.63,
        "Latitude": 520800000,
        "Longitude": -1063800000,
        "Elevation (m)": 515.1,
        "First Year": 1915,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1964,
        "MLY First Year": 1915,
        "MLY Last Year": 1964
      },
      {
        "Name": "SASKATOON WATER TP",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057202",
        "Station ID": 3335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -106.68,
        "Latitude": 520700000,
        "Longitude": -1064100000,
        "Elevation (m)": 483.1,
        "First Year": 1974,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2011,
        "MLY First Year": 1974,
        "MLY Last Year": 2007
      },
      {
        "Name": "STAR CITY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057757",
        "Station ID": 3336,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -104.33,
        "Latitude": 525200000,
        "Longitude": -1042000000,
        "Elevation (m)": 465,
        "First Year": 1985,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1992,
        "MLY First Year": 1985,
        "MLY Last Year": 1992
      },
      {
        "Name": "STAR CITY CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057760",
        "Station ID": 3337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.72,
        "Longitude (Decimal Degrees)": -104.33,
        "Latitude": 524300000,
        "Longitude": -1042000000,
        "Elevation (m)": 469.1,
        "First Year": 1951,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1959,
        "MLY First Year": 1951,
        "MLY Last Year": 1959
      },
      {
        "Name": "STURGEON CROSSING",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057890",
        "Station ID": 3338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.73,
        "Longitude (Decimal Degrees)": -106.72,
        "Latitude": 534400000,
        "Longitude": -1064300000,
        "Elevation (m)": 491.9,
        "First Year": 1959,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1984,
        "MLY First Year": 1959,
        "MLY Last Year": 1984
      },
      {
        "Name": "SURBITON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4057920",
        "Station ID": 3339,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.33,
        "Longitude (Decimal Degrees)": -107.23,
        "Latitude": 512000000,
        "Longitude": -1071400000,
        "Elevation (m)": 655.3,
        "First Year": 1916,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1969,
        "MLY First Year": 1916,
        "MLY Last Year": 1969
      },
      {
        "Name": "SUTHERLAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058000",
        "Station ID": 3340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -106.58,
        "Latitude": 520900000,
        "Longitude": -1063500000,
        "Elevation (m)": 506.6,
        "First Year": 1943,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1970,
        "MLY First Year": 1943,
        "MLY Last Year": 1970
      },
      {
        "Name": "VALLEY PARK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058390",
        "Station ID": 3341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.88,
        "Longitude (Decimal Degrees)": -106.82,
        "Latitude": 515300000,
        "Longitude": -1064900000,
        "Elevation (m)": 525.8,
        "First Year": 1961,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1979,
        "MLY First Year": 1962,
        "MLY Last Year": 1979
      },
      {
        "Name": "VANSCOY UPPER AIR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058420",
        "Station ID": 3342,
        "WMO ID": null,
        "TC ID": "WVS",
        "Latitude (Decimal Degrees)": 52.02,
        "Longitude (Decimal Degrees)": -107.03,
        "Latitude": 520100000,
        "Longitude": -1070200000,
        "Elevation (m)": 510,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "VANSCOY COMINCO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058425",
        "Station ID": 3343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.02,
        "Longitude (Decimal Degrees)": -107.1,
        "Latitude": 520100000,
        "Longitude": -1070600000,
        "Elevation (m)": 505,
        "First Year": 1967,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1996,
        "MLY First Year": 1967,
        "MLY Last Year": 1996
      },
      {
        "Name": "VISCOUNT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058492",
        "Station ID": 3344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.95,
        "Longitude (Decimal Degrees)": -105.63,
        "Latitude": 515700000,
        "Longitude": -1053800000,
        "Elevation (m)": 547.1,
        "First Year": 1973,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1996,
        "MLY First Year": 1973,
        "MLY Last Year": 1996
      },
      {
        "Name": "VONDA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058494",
        "Station ID": 3345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -106.1,
        "Latitude": 521900000,
        "Longitude": -1060600000,
        "Elevation (m)": 529,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "WAKAW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058495",
        "Station ID": 3346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.65,
        "Longitude (Decimal Degrees)": -105.72,
        "Latitude": 523900000,
        "Longitude": -1054300000,
        "Elevation (m)": 511.1,
        "First Year": 1963,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1984,
        "MLY First Year": 1963,
        "MLY Last Year": 1984
      },
      {
        "Name": "WEIRDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058728",
        "Station ID": 3347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -105.25,
        "Latitude": 532700000,
        "Longitude": -1051500000,
        "Elevation (m)": 474,
        "First Year": 1985,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1998,
        "MLY First Year": 1985,
        "MLY Last Year": 1998
      },
      {
        "Name": "WELDON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4058732",
        "Station ID": 3348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -105.1,
        "Latitude": 530200000,
        "Longitude": -1050600000,
        "Elevation (m)": 455.7,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "ZELMA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4059220",
        "Station ID": 3349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -105.93,
        "Latitude": 514800000,
        "Longitude": -1055600000,
        "Elevation (m)": 541,
        "First Year": 1981,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1989,
        "MLY First Year": 1981,
        "MLY Last Year": 1989
      },
      {
        "Name": "LANGHAM 2W",
        "Province": "SASKATCHEWAN",
        "Climate ID": "405DJDN",
        "Station ID": 3271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -107.02,
        "Latitude": 522200000,
        "Longitude": -1070100000,
        "Elevation (m)": 502.9,
        "First Year": 1990,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2008,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "CANDLE LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "405I010",
        "Station ID": 27005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.75,
        "Longitude (Decimal Degrees)": -105.27,
        "Latitude": 534500000,
        "Longitude": -1051600000,
        "Elevation (m)": 494.7,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1997,
        "MLY First Year": 1994,
        "MLY Last Year": 1997
      },
      {
        "Name": "BEACON HILL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060452",
        "Station ID": 3350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.34,
        "Longitude (Decimal Degrees)": -109.63,
        "Latitude": 542007006,
        "Longitude": -1093734004,
        "Elevation (m)": 533.4,
        "First Year": 1983,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2017,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "BEARTOOTH ISLAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060468",
        "Station ID": 10713,
        "WMO ID": null,
        "TC ID": "WTF",
        "Latitude (Decimal Degrees)": 59.22,
        "Longitude (Decimal Degrees)": -109.7,
        "Latitude": 591300000,
        "Longitude": -1094200000,
        "Elevation (m)": 238,
        "First Year": 1994,
        "Last Year": 2015,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1994,
        "DLY Last Year": 2015,
        "MLY First Year": 1998,
        "MLY Last Year": 2000
      },
      {
        "Name": "BIG RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060620",
        "Station ID": 3351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -107.03,
        "Latitude": 535000000,
        "Longitude": -1070200000,
        "Elevation (m)": 502.9,
        "First Year": 1957,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1998,
        "MLY First Year": 1962,
        "MLY Last Year": 1998
      },
      {
        "Name": "BIG RIVER EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060628",
        "Station ID": 3352,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.85,
        "Longitude (Decimal Degrees)": -107,
        "Latitude": 535100000,
        "Longitude": -1070000000,
        "Elevation (m)": 485.2,
        "First Year": 1951,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1956,
        "MLY First Year": 1951,
        "MLY Last Year": 1955
      },
      {
        "Name": "BITTERN CREEK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060760",
        "Station ID": 3353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -105.87,
        "Latitude": 535900000,
        "Longitude": -1055200000,
        "Elevation (m)": 518.2,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1956
      },
      {
        "Name": "BRABANT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060790",
        "Station ID": 3354,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.12,
        "Longitude (Decimal Degrees)": -103.75,
        "Latitude": 560700000,
        "Longitude": -1034500000,
        "Elevation (m)": 381,
        "First Year": 1966,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1973,
        "MLY First Year": 1966,
        "MLY Last Year": 1973
      },
      {
        "Name": "BUFFALO NARROWS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060980",
        "Station ID": 3355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.87,
        "Longitude (Decimal Degrees)": -108.48,
        "Latitude": 555200000,
        "Longitude": -1082900000,
        "Elevation (m)": 421.2,
        "First Year": 1960,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1968,
        "MLY First Year": 1960,
        "MLY Last Year": 1968
      },
      {
        "Name": "BUFFALO NARROWS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060981",
        "Station ID": 3356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.85,
        "Longitude (Decimal Degrees)": -108.47,
        "Latitude": 555100000,
        "Longitude": -1082800000,
        "Elevation (m)": 422.5,
        "First Year": 1968,
        "Last Year": 1979,
        "HLY First Year": 1968,
        "HLY Last Year": 1979,
        "DLY First Year": 1968,
        "DLY Last Year": 1979,
        "MLY First Year": 1968,
        "MLY Last Year": 1979
      },
      {
        "Name": "BUFFALO NARROWS A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060982",
        "Station ID": 3357,
        "WMO ID": 71077,
        "TC ID": "YVT",
        "Latitude (Decimal Degrees)": 55.83,
        "Longitude (Decimal Degrees)": -108.43,
        "Latitude": 555000000,
        "Longitude": -1082600000,
        "Elevation (m)": 433.73,
        "First Year": 1979,
        "Last Year": 2012,
        "HLY First Year": 1979,
        "HLY Last Year": 2012,
        "DLY First Year": 1979,
        "DLY Last Year": 2012,
        "MLY First Year": 1979,
        "MLY Last Year": 2007
      },
      {
        "Name": "BUFFALO NARROWS (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060983",
        "Station ID": 7534,
        "WMO ID": 71077,
        "TC ID": "WVT",
        "Latitude (Decimal Degrees)": 55.84,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 555026008,
        "Longitude": -1082514009,
        "Elevation (m)": 440.1,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "BUFFALO NARROWS A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060984",
        "Station ID": 50823,
        "WMO ID": null,
        "TC ID": "YVT",
        "Latitude (Decimal Degrees)": 55.84,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 555031000,
        "Longitude": -1082503000,
        "Elevation (m)": 433.7,
        "First Year": 2012,
        "Last Year": 2019,
        "HLY First Year": 2012,
        "HLY Last Year": 2019,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BUFFALO NARROWS A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4060988",
        "Station ID": 53020,
        "WMO ID": null,
        "TC ID": "YVT",
        "Latitude (Decimal Degrees)": 55.84,
        "Longitude (Decimal Degrees)": -108.42,
        "Latitude": 555031000,
        "Longitude": -1082503000,
        "Elevation (m)": 433.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARSWELL LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061410",
        "Station ID": 3358,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.53,
        "Longitude (Decimal Degrees)": -109.48,
        "Latitude": 583200000,
        "Longitude": -1092900000,
        "Elevation (m)": 330.1,
        "First Year": 1980,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1985,
        "MLY First Year": 1980,
        "MLY Last Year": 1985
      },
      {
        "Name": "CIGAR LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061570",
        "Station ID": 3359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.08,
        "Longitude (Decimal Degrees)": -104.48,
        "Latitude": 580500000,
        "Longitude": -1042900000,
        "Elevation (m)": 467,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "CLUFF LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061590",
        "Station ID": 3360,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.37,
        "Longitude (Decimal Degrees)": -109.52,
        "Latitude": 582200000,
        "Longitude": -1093100000,
        "Elevation (m)": 330.1,
        "First Year": 1980,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1999,
        "MLY First Year": 1980,
        "MLY Last Year": 1999
      },
      {
        "Name": "CLUFF LAKE AUTO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061592",
        "Station ID": 27737,
        "WMO ID": null,
        "TC ID": "XXX",
        "Latitude (Decimal Degrees)": 58.39,
        "Longitude (Decimal Degrees)": -109.52,
        "Latitude": 582328000,
        "Longitude": -1093059000,
        "Elevation (m)": 338.9,
        "First Year": 1996,
        "Last Year": 2009,
        "HLY First Year": 1996,
        "HLY Last Year": 2005,
        "DLY First Year": 1999,
        "DLY Last Year": 2009,
        "MLY First Year": 1999,
        "MLY Last Year": 2005
      },
      {
        "Name": "COLLINS BAY SK",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061620",
        "Station ID": 53218,
        "WMO ID": 71075,
        "TC ID": "WWC",
        "Latitude (Decimal Degrees)": 58.23,
        "Longitude (Decimal Degrees)": -103.68,
        "Latitude": 581345000,
        "Longitude": -1034041000,
        "Elevation (m)": 412.6,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "COLLINS BAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061629",
        "Station ID": 10663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.19,
        "Longitude (Decimal Degrees)": -103.71,
        "Latitude": 581124000,
        "Longitude": -1034242000,
        "Elevation (m)": 490.1,
        "First Year": 1992,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2015,
        "DLY First Year": 1992,
        "DLY Last Year": 2018,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "COLLINS BAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061630",
        "Station ID": 3361,
        "WMO ID": null,
        "TC ID": "WKC",
        "Latitude (Decimal Degrees)": 58.18,
        "Longitude (Decimal Degrees)": -103.7,
        "Latitude": 581100000,
        "Longitude": -1034200000,
        "Elevation (m)": 491.9,
        "First Year": 1972,
        "Last Year": 1990,
        "HLY First Year": 1972,
        "HLY Last Year": 1990,
        "DLY First Year": 1972,
        "DLY Last Year": 1990,
        "MLY First Year": 1972,
        "MLY Last Year": 1990
      },
      {
        "Name": "COLLINS BAY CAMECO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061632",
        "Station ID": 6873,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.18,
        "Longitude (Decimal Degrees)": -103.7,
        "Latitude": 581100000,
        "Longitude": -1034200000,
        "Elevation (m)": 490.1,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "CONTACT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061840",
        "Station ID": 3362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.43,
        "Longitude (Decimal Degrees)": -104.87,
        "Latitude": 552600000,
        "Longitude": -1045200000,
        "Elevation (m)": 365.8,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "CREE LAKE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061860",
        "Station ID": 3363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.38,
        "Longitude (Decimal Degrees)": -106.67,
        "Latitude": 572300000,
        "Longitude": -1064000000,
        "Elevation (m)": 478.5,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "CREE LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4061861",
        "Station ID": 3364,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.35,
        "Longitude (Decimal Degrees)": -107.13,
        "Latitude": 572100000,
        "Longitude": -1070800000,
        "Elevation (m)": 494.6,
        "First Year": 1969,
        "Last Year": 1996,
        "HLY First Year": 1969,
        "HLY Last Year": 1996,
        "DLY First Year": 1969,
        "DLY Last Year": 1996,
        "MLY First Year": 1970,
        "MLY Last Year": 1993
      },
      {
        "Name": "DAWN LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062168",
        "Station ID": 3365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.3,
        "Longitude (Decimal Degrees)": -103.95,
        "Latitude": 581800000,
        "Longitude": -1035700000,
        "Elevation (m)": 450,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "DIVIDE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062220",
        "Station ID": 3366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.9,
        "Longitude (Decimal Degrees)": -108.45,
        "Latitude": 535400000,
        "Longitude": -1082700000,
        "Elevation (m)": 731.5,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "DORE LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062237",
        "Station ID": 3367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.62,
        "Longitude (Decimal Degrees)": -107.4,
        "Latitude": 543700000,
        "Longitude": -1072400000,
        "Elevation (m)": 463.9,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "DORINTOSH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062240",
        "Station ID": 3368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.32,
        "Longitude (Decimal Degrees)": -108.7,
        "Latitude": 541900000,
        "Longitude": -1084200000,
        "Elevation (m)": 502.9,
        "First Year": 1952,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1972,
        "MLY First Year": 1952,
        "MLY Last Year": 1972
      },
      {
        "Name": "FOND DU LAC",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062580",
        "Station ID": 3369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.33,
        "Longitude (Decimal Degrees)": -107.17,
        "Latitude": 592000000,
        "Longitude": -1071000000,
        "Elevation (m)": 210.3,
        "First Year": 1905,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1938,
        "MLY First Year": 1905,
        "MLY Last Year": 1938
      },
      {
        "Name": "FOSTER LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062660",
        "Station ID": 3370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.8,
        "Longitude (Decimal Degrees)": -105.35,
        "Latitude": 564800000,
        "Longitude": -1052100000,
        "Elevation (m)": 513.9,
        "First Year": 1962,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1979,
        "MLY First Year": 1962,
        "MLY Last Year": 1979
      },
      {
        "Name": "GEIKIE RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062825",
        "Station ID": 3371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.7,
        "Longitude (Decimal Degrees)": -103.95,
        "Latitude": 574200000,
        "Longitude": -1035700000,
        "Elevation (m)": 412,
        "First Year": 1985,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1997,
        "MLY First Year": 1985,
        "MLY Last Year": 1997
      },
      {
        "Name": "GOODSOIL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4062940",
        "Station ID": 3372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -109.23,
        "Latitude": 542400000,
        "Longitude": -1091400000,
        "Elevation (m)": 506,
        "First Year": 1956,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1968,
        "MLY First Year": 1956,
        "MLY Last Year": 1968
      },
      {
        "Name": "GREEN LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063020",
        "Station ID": 3373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -107.78,
        "Latitude": 541700000,
        "Longitude": -1074700000,
        "Elevation (m)": 467.9,
        "First Year": 1962,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1990,
        "MLY First Year": 1962,
        "MLY Last Year": 1990
      },
      {
        "Name": "ILE A LA CROSSE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063420",
        "Station ID": 3374,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.45,
        "Longitude (Decimal Degrees)": -107.87,
        "Latitude": 552700000,
        "Longitude": -1075200000,
        "Elevation (m)": 420.6,
        "First Year": 1962,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1982,
        "MLY First Year": 1962,
        "MLY Last Year": 1981
      },
      {
        "Name": "ISLAND FALLS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063560",
        "Station ID": 3375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.53,
        "Longitude (Decimal Degrees)": -102.35,
        "Latitude": 553200000,
        "Longitude": -1022100000,
        "Elevation (m)": 299.3,
        "First Year": 1929,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 2004,
        "MLY First Year": 1929,
        "MLY Last Year": 2004
      },
      {
        "Name": "ISLAND FALLS 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063600",
        "Station ID": 3376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.58,
        "Longitude (Decimal Degrees)": -102.43,
        "Latitude": 553500000,
        "Longitude": -1022600000,
        "Elevation (m)": null,
        "First Year": 1938,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1951,
        "MLY First Year": 1938,
        "MLY Last Year": 1951
      },
      {
        "Name": "ISLAND FALLS (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063605",
        "Station ID": 42883,
        "WMO ID": 71331,
        "TC ID": "PIF",
        "Latitude (Decimal Degrees)": 55.53,
        "Longitude (Decimal Degrees)": -102.35,
        "Latitude": 553200000,
        "Longitude": -1022100000,
        "Elevation (m)": 297.4,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "JIMMY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063621",
        "Station ID": 7563,
        "WMO ID": null,
        "TC ID": "WHN",
        "Latitude (Decimal Degrees)": 54.9,
        "Longitude (Decimal Degrees)": -109.97,
        "Latitude": 545400000,
        "Longitude": -1095800000,
        "Elevation (m)": 636.2,
        "First Year": 1994,
        "Last Year": 2004,
        "HLY First Year": 1994,
        "HLY Last Year": 2004,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "JIMMY LAKE AWOS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063623",
        "Station ID": 30724,
        "WMO ID": 71023,
        "TC ID": "WHN",
        "Latitude (Decimal Degrees)": 54.91,
        "Longitude (Decimal Degrees)": -109.96,
        "Latitude": 545430050,
        "Longitude": -1095743080,
        "Elevation (m)": 637.1,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KEELEY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063650",
        "Station ID": 27280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.87,
        "Longitude (Decimal Degrees)": -108.17,
        "Latitude": 545200000,
        "Longitude": -1081000000,
        "Elevation (m)": 457.2,
        "First Year": 1996,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1997,
        "MLY First Year": 1996,
        "MLY Last Year": 1997
      },
      {
        "Name": "KEY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063753",
        "Station ID": 49448,
        "WMO ID": 71033,
        "TC ID": "PLS",
        "Latitude (Decimal Degrees)": 57.25,
        "Longitude (Decimal Degrees)": -105.6,
        "Latitude": 571500000,
        "Longitude": -1053600000,
        "Elevation (m)": 513.9,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KEY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063755",
        "Station ID": 3377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.25,
        "Longitude (Decimal Degrees)": -105.62,
        "Latitude": 571500000,
        "Longitude": -1053700000,
        "Elevation (m)": 509,
        "First Year": 1976,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2018,
        "MLY First Year": 1976,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEY LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063757",
        "Station ID": 48872,
        "WMO ID": 71499,
        "TC ID": "YKJ",
        "Latitude (Decimal Degrees)": 57.26,
        "Longitude (Decimal Degrees)": -105.62,
        "Latitude": 571523000,
        "Longitude": -1053703000,
        "Elevation (m)": 513.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KEY LAKE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063759",
        "Station ID": 10941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.25,
        "Longitude (Decimal Degrees)": -105.6,
        "Latitude": 571500000,
        "Longitude": -1053600000,
        "Elevation (m)": 513.89,
        "First Year": 1993,
        "Last Year": 2010,
        "HLY First Year": 1994,
        "HLY Last Year": 2010,
        "DLY First Year": 1993,
        "DLY Last Year": 2010,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "KINGSMERE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063940",
        "Station ID": 3378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -106.45,
        "Latitude": 540800000,
        "Longitude": -1062700000,
        "Elevation (m)": null,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1959,
        "MLY Last Year": 1964
      },
      {
        "Name": "KINOOSAO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4063980",
        "Station ID": 3379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.08,
        "Longitude (Decimal Degrees)": -102.02,
        "Latitude": 570500000,
        "Longitude": -1020100000,
        "Elevation (m)": 350.8,
        "First Year": 1962,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1978,
        "MLY First Year": 1962,
        "MLY Last Year": 1978
      },
      {
        "Name": "LAC LA RONGE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064130",
        "Station ID": 3380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.13,
        "Longitude (Decimal Degrees)": -105.33,
        "Latitude": 550800000,
        "Longitude": -1052000000,
        "Elevation (m)": 365.8,
        "First Year": 1921,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1987,
        "MLY First Year": 1921,
        "MLY Last Year": 1987
      },
      {
        "Name": "LA RONGE RCS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064149",
        "Station ID": 29371,
        "WMO ID": 71586,
        "TC ID": "XOX",
        "Latitude (Decimal Degrees)": 55.15,
        "Longitude (Decimal Degrees)": -105.27,
        "Latitude": 550847020,
        "Longitude": -1051613030,
        "Elevation (m)": 378.6,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "LA RONGE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064150",
        "Station ID": 3381,
        "WMO ID": 71922,
        "TC ID": "YVC",
        "Latitude (Decimal Degrees)": 55.15,
        "Longitude (Decimal Degrees)": -105.27,
        "Latitude": 550900000,
        "Longitude": -1051600000,
        "Elevation (m)": 379.2,
        "First Year": 1959,
        "Last Year": 2013,
        "HLY First Year": 1959,
        "HLY Last Year": 2013,
        "DLY First Year": 1959,
        "DLY Last Year": 2013,
        "MLY First Year": 1959,
        "MLY Last Year": 2008
      },
      {
        "Name": "LA RONGE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064155",
        "Station ID": 51425,
        "WMO ID": 71922,
        "TC ID": "YVC",
        "Latitude (Decimal Degrees)": 55.15,
        "Longitude (Decimal Degrees)": -105.27,
        "Latitude": 550905000,
        "Longitude": -1051601000,
        "Elevation (m)": 379.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LEOVILLE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064312",
        "Station ID": 3382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -107.57,
        "Latitude": 533300000,
        "Longitude": -1073400000,
        "Elevation (m)": 606.6,
        "First Year": 1963,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1969,
        "MLY First Year": 1963,
        "MLY Last Year": 1969
      },
      {
        "Name": "LOON LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064590",
        "Station ID": 3383,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.03,
        "Longitude (Decimal Degrees)": -109.03,
        "Latitude": 540200000,
        "Longitude": -1090200000,
        "Elevation (m)": 594.4,
        "First Year": 1930,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1946,
        "MLY First Year": 1930,
        "MLY Last Year": 1946
      },
      {
        "Name": "LOON LAKE CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064600",
        "Station ID": 3384,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -109.1,
        "Latitude": 540300000,
        "Longitude": -1090600000,
        "Elevation (m)": 542.8,
        "First Year": 1951,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2005,
        "MLY First Year": 1951,
        "MLY Last Year": 2005
      },
      {
        "Name": "LOON LAKE RCS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4064620",
        "Station ID": 48148,
        "WMO ID": 71273,
        "TC ID": "PIL",
        "Latitude (Decimal Degrees)": 54.02,
        "Longitude (Decimal Degrees)": -109.14,
        "Latitude": 540111000,
        "Longitude": -1090818000,
        "Elevation (m)": 545.6,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MCLENNAN LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065053",
        "Station ID": 6874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.92,
        "Longitude (Decimal Degrees)": -104.3,
        "Latitude": 555500000,
        "Longitude": -1041800000,
        "Elevation (m)": 418,
        "First Year": 1993,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1993,
        "MLY First Year": 1993,
        "MLY Last Year": 1993
      },
      {
        "Name": "MEADOW LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065055",
        "Station ID": 3385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -108.43,
        "Latitude": 540800000,
        "Longitude": -1082600000,
        "Elevation (m)": 481.3,
        "First Year": 1967,
        "Last Year": 1978,
        "HLY First Year": 1967,
        "HLY Last Year": 1977,
        "DLY First Year": 1967,
        "DLY Last Year": 1978,
        "MLY First Year": 1967,
        "MLY Last Year": 1978
      },
      {
        "Name": "MEADOW LAKE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065057",
        "Station ID": 52000,
        "WMO ID": 71125,
        "TC ID": "YLJ",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -108.05,
        "Latitude": 540731000,
        "Longitude": -1080301022,
        "Elevation (m)": 480.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MEADOW LAKE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065058",
        "Station ID": 3386,
        "WMO ID": 71125,
        "TC ID": "YLJ",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -108.52,
        "Latitude": 540800000,
        "Longitude": -1083100000,
        "Elevation (m)": 480.67,
        "First Year": 1977,
        "Last Year": 2013,
        "HLY First Year": 1977,
        "HLY Last Year": 2013,
        "DLY First Year": 1977,
        "DLY Last Year": 2013,
        "MLY First Year": 1977,
        "MLY Last Year": 2008
      },
      {
        "Name": "MEADOW LAKE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065060",
        "Station ID": 3387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -108.43,
        "Latitude": 540800000,
        "Longitude": -1082600000,
        "Elevation (m)": 493.8,
        "First Year": 1923,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1967,
        "MLY First Year": 1923,
        "MLY Last Year": 1967
      },
      {
        "Name": "MEADOW LAKE A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065063",
        "Station ID": 53021,
        "WMO ID": null,
        "TC ID": "YLJ",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -108.52,
        "Latitude": 540731000,
        "Longitude": -1083122000,
        "Elevation (m)": 480.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MILDRED",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065172",
        "Station ID": 3388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -107.25,
        "Latitude": 532000000,
        "Longitude": -1071500000,
        "Elevation (m)": 617,
        "First Year": 1984,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1993,
        "MLY First Year": 1984,
        "MLY Last Year": 1993
      },
      {
        "Name": "MISSINIPE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065220",
        "Station ID": 6875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.6,
        "Longitude (Decimal Degrees)": -104.77,
        "Latitude": 553600000,
        "Longitude": -1044600000,
        "Elevation (m)": 355,
        "First Year": 1993,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1996,
        "MLY First Year": 1993,
        "MLY Last Year": 1996
      },
      {
        "Name": "MOLANOSA",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065255",
        "Station ID": 3389,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.5,
        "Longitude (Decimal Degrees)": -105.53,
        "Latitude": 543000000,
        "Longitude": -1053200000,
        "Elevation (m)": 508.4,
        "First Year": 1971,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1971,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "MONTREAL LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065260",
        "Station ID": 3390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.62,
        "Longitude (Decimal Degrees)": -105.67,
        "Latitude": 533700000,
        "Longitude": -1054000000,
        "Elevation (m)": 490.4,
        "First Year": 1959,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1959,
        "MLY Last Year": 1959
      },
      {
        "Name": "OTTER RAPIDS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4065710",
        "Station ID": 3391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.65,
        "Longitude (Decimal Degrees)": -104.73,
        "Latitude": 553900000,
        "Longitude": -1044400000,
        "Elevation (m)": 358,
        "First Year": 1964,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2001,
        "MLY First Year": 1964,
        "MLY Last Year": 1964
      },
      {
        "Name": "PIERCELAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4066090",
        "Station ID": 3392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.35,
        "Longitude (Decimal Degrees)": -109.75,
        "Latitude": 542100000,
        "Longitude": -1094500000,
        "Elevation (m)": 548.6,
        "First Year": 1956,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1970,
        "MLY First Year": 1956,
        "MLY Last Year": 1970
      },
      {
        "Name": "PIERCELAND EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4066106",
        "Station ID": 3393,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.4,
        "Longitude (Decimal Degrees)": -109.75,
        "Latitude": 542400000,
        "Longitude": -1094500000,
        "Elevation (m)": 548.6,
        "First Year": 1955,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1960,
        "MLY First Year": 1955,
        "MLY Last Year": 1960
      },
      {
        "Name": "SMOOTH STONE RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067576",
        "Station ID": 3395,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.15,
        "Longitude (Decimal Degrees)": -106.27,
        "Latitude": 550900000,
        "Longitude": -1061600000,
        "Elevation (m)": 434.3,
        "First Year": 1989,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1993,
        "MLY First Year": 1989,
        "MLY Last Year": 1993
      },
      {
        "Name": "SOUTHEND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067655",
        "Station ID": 10242,
        "WMO ID": 71451,
        "TC ID": "WJH",
        "Latitude (Decimal Degrees)": 56.33,
        "Longitude (Decimal Degrees)": -103.28,
        "Latitude": 562000000,
        "Longitude": -1031700000,
        "Elevation (m)": 344.1,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "SOUTH MONTREAL LAKE DNR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067670",
        "Station ID": 3396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.05,
        "Longitude (Decimal Degrees)": -105.8,
        "Latitude": 540300000,
        "Longitude": -1054800000,
        "Elevation (m)": 490.1,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "SPIRITWOOD",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067720",
        "Station ID": 3397,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -107.53,
        "Latitude": 532200000,
        "Longitude": -1073200000,
        "Elevation (m)": 589,
        "First Year": 1945,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1992,
        "MLY First Year": 1945,
        "MLY Last Year": 1992
      },
      {
        "Name": "SPIRITWOOD WEST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067723",
        "Station ID": 10850,
        "WMO ID": 71133,
        "TC ID": "WSR",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -107.55,
        "Latitude": 532200000,
        "Longitude": -1073300000,
        "Elevation (m)": 584.3,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "STANLEY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067740",
        "Station ID": 3398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.42,
        "Longitude (Decimal Degrees)": -104.57,
        "Latitude": 552500000,
        "Longitude": -1043400000,
        "Elevation (m)": 350.5,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "STONY RAPIDS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067795",
        "Station ID": 3399,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.25,
        "Longitude (Decimal Degrees)": -105.83,
        "Latitude": 591500000,
        "Longitude": -1055000000,
        "Elevation (m)": 224.9,
        "First Year": 1960,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1982,
        "MLY First Year": 1960,
        "MLY Last Year": 1982
      },
      {
        "Name": "STONY RAPIDS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067799",
        "Station ID": 48548,
        "WMO ID": 71132,
        "TC ID": "YSF",
        "Latitude (Decimal Degrees)": 59.25,
        "Longitude (Decimal Degrees)": -105.84,
        "Latitude": 591501000,
        "Longitude": -1055029000,
        "Elevation (m)": 244.15,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STONY RAPIDS A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4067PR5",
        "Station ID": 3394,
        "WMO ID": 71132,
        "TC ID": "YSF",
        "Latitude (Decimal Degrees)": 59.25,
        "Longitude (Decimal Degrees)": -105.83,
        "Latitude": 591500000,
        "Longitude": -1055000000,
        "Elevation (m)": 245.4,
        "First Year": 1986,
        "Last Year": 2013,
        "HLY First Year": 1986,
        "HLY Last Year": 2010,
        "DLY First Year": 1986,
        "DLY Last Year": 2013,
        "MLY First Year": 1986,
        "MLY Last Year": 2010
      },
      {
        "Name": "URANIUM CITY A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4068340",
        "Station ID": 3400,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.57,
        "Longitude (Decimal Degrees)": -108.48,
        "Latitude": 593400000,
        "Longitude": -1082900000,
        "Elevation (m)": 318.2,
        "First Year": 1953,
        "Last Year": 1986,
        "HLY First Year": 1959,
        "HLY Last Year": 1986,
        "DLY First Year": 1953,
        "DLY Last Year": 1986,
        "MLY First Year": 1953,
        "MLY Last Year": 1986
      },
      {
        "Name": "VICTOIRE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4068485",
        "Station ID": 3401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.48,
        "Longitude (Decimal Degrees)": -107.03,
        "Latitude": 532900000,
        "Longitude": -1070200000,
        "Elevation (m)": 521.2,
        "First Year": 1957,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1976,
        "MLY First Year": 1957,
        "MLY Last Year": 1976
      },
      {
        "Name": "WASKESIU LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4068559",
        "Station ID": 10688,
        "WMO ID": 71454,
        "TC ID": "WLV",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -106.07,
        "Latitude": 535500000,
        "Longitude": -1060400000,
        "Elevation (m)": 569.4,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "WASKESIU LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4068560",
        "Station ID": 3402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -106.08,
        "Latitude": 535500000,
        "Longitude": -1060500000,
        "Elevation (m)": 532.2,
        "First Year": 1934,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1995,
        "MLY First Year": 1934,
        "MLY Last Year": 1995
      },
      {
        "Name": "WHITESAND DAM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4068840",
        "Station ID": 3403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -103.15,
        "Latitude": 561400000,
        "Longitude": -1030900000,
        "Elevation (m)": 344,
        "First Year": 1937,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 2004,
        "MLY First Year": 1937,
        "MLY Last Year": 2004
      },
      {
        "Name": "WITCHEKAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4069010",
        "Station ID": 3404,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.48,
        "Longitude (Decimal Degrees)": -107.55,
        "Latitude": 532900000,
        "Longitude": -1073300000,
        "Elevation (m)": 579.1,
        "First Year": 1923,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1944,
        "MLY First Year": 1923,
        "MLY Last Year": 1944
      },
      {
        "Name": "WOLLASTON LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4069019",
        "Station ID": 3405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.12,
        "Longitude (Decimal Degrees)": -103.2,
        "Latitude": 580700000,
        "Longitude": -1031200000,
        "Elevation (m)": 414.5,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "WOLLASTON LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4069020",
        "Station ID": 3406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.05,
        "Longitude (Decimal Degrees)": -103.17,
        "Latitude": 580300000,
        "Longitude": -1031000000,
        "Elevation (m)": 396.2,
        "First Year": 1962,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1972,
        "MLY First Year": 1962,
        "MLY Last Year": 1972
      },
      {
        "Name": "MEADOW LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "406N0NM",
        "Station ID": 10198,
        "WMO ID": 71970,
        "TC ID": "WLJ",
        "Latitude (Decimal Degrees)": 54.13,
        "Longitude (Decimal Degrees)": -108.52,
        "Latitude": 540800000,
        "Longitude": -1083100000,
        "Elevation (m)": 481,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "URANIUM CITY (AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "406QLD0",
        "Station ID": 9831,
        "WMO ID": 71076,
        "TC ID": "WDC",
        "Latitude (Decimal Degrees)": 59.57,
        "Longitude (Decimal Degrees)": -108.48,
        "Latitude": 593400000,
        "Longitude": -1082900000,
        "Elevation (m)": 318.2,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "AYLSHAM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4070365",
        "Station ID": 3407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.2,
        "Longitude (Decimal Degrees)": -103.8,
        "Latitude": 531200000,
        "Longitude": -1034800000,
        "Elevation (m)": 362.1,
        "First Year": 1968,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2005,
        "MLY First Year": 1968,
        "MLY Last Year": 2005
      },
      {
        "Name": "CARROT RIVER EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071360",
        "Station ID": 3408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -103.52,
        "Latitude": 532000000,
        "Longitude": -1033100000,
        "Elevation (m)": null,
        "First Year": 1951,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1956,
        "MLY First Year": 1951,
        "MLY Last Year": 1956
      },
      {
        "Name": "CHOICELAND",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071560",
        "Station ID": 3409,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -104.48,
        "Latitude": 533000000,
        "Longitude": -1042900000,
        "Elevation (m)": 442,
        "First Year": 1948,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1994,
        "MLY First Year": 1948,
        "MLY Last Year": 1994
      },
      {
        "Name": "CODETTE ELKHORN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071612",
        "Station ID": 3410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.25,
        "Longitude (Decimal Degrees)": -104.17,
        "Latitude": 531500000,
        "Longitude": -1041000000,
        "Elevation (m)": 398.1,
        "First Year": 1960,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1994,
        "MLY First Year": 1960,
        "MLY Last Year": 1994
      },
      {
        "Name": "CONNELL CREEK EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071680",
        "Station ID": 3411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.15,
        "Longitude (Decimal Degrees)": -103.32,
        "Latitude": 530900000,
        "Longitude": -1031900000,
        "Elevation (m)": null,
        "First Year": 1951,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1959,
        "MLY First Year": 1951,
        "MLY Last Year": 1959
      },
      {
        "Name": "CROOKED RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071913",
        "Station ID": 3412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -103.73,
        "Latitude": 525100000,
        "Longitude": -1034400000,
        "Elevation (m)": 465.4,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "CUB HILLS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071918",
        "Station ID": 3413,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.28,
        "Longitude (Decimal Degrees)": -104.55,
        "Latitude": 541700000,
        "Longitude": -1043300000,
        "Elevation (m)": 716.3,
        "First Year": 1975,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1977,
        "MLY First Year": 1975,
        "MLY Last Year": 1977
      },
      {
        "Name": "CUMBERLAND HOUSE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071960",
        "Station ID": 3414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -102.3,
        "Latitude": 535800000,
        "Longitude": -1021800000,
        "Elevation (m)": 271.3,
        "First Year": 1911,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1965,
        "MLY First Year": 1911,
        "MLY Last Year": 1965
      },
      {
        "Name": "CUMBERLAND HOUSE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071961",
        "Station ID": 3415,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.95,
        "Longitude (Decimal Degrees)": -102.27,
        "Latitude": 535700000,
        "Longitude": -1021600000,
        "Elevation (m)": 268.2,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "CUMBERLAND HOUSE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4071962",
        "Station ID": 3416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.92,
        "Longitude (Decimal Degrees)": -102.37,
        "Latitude": 535500000,
        "Longitude": -1022200000,
        "Elevation (m)": 268.8,
        "First Year": 1978,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1987,
        "MLY First Year": 1978,
        "MLY Last Year": 1987
      },
      {
        "Name": "DESCHAMBAULT LAKE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4072192",
        "Station ID": 3417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.67,
        "Longitude (Decimal Degrees)": -103.4,
        "Latitude": 544000000,
        "Longitude": -1032400000,
        "Elevation (m)": 373,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "LOST RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4074640",
        "Station ID": 3418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.28,
        "Longitude (Decimal Degrees)": -104.33,
        "Latitude": 531700000,
        "Longitude": -1042000000,
        "Elevation (m)": 374.9,
        "First Year": 1911,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1994,
        "MLY First Year": 1911,
        "MLY Last Year": 1994
      },
      {
        "Name": "MILE 97",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075180",
        "Station ID": 3419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.6,
        "Longitude (Decimal Degrees)": -104.22,
        "Latitude": 543600000,
        "Longitude": -1041300000,
        "Elevation (m)": 424,
        "First Year": 1981,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1985,
        "MLY First Year": 1981,
        "MLY Last Year": 1985
      },
      {
        "Name": "NIPAWIN A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075518",
        "Station ID": 3420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -104,
        "Latitude": 532000000,
        "Longitude": -1040000000,
        "Elevation (m)": 371.9,
        "First Year": 1973,
        "Last Year": 2005,
        "HLY First Year": 1973,
        "HLY Last Year": 2005,
        "DLY First Year": 1973,
        "DLY Last Year": 2005,
        "MLY First Year": 1973,
        "MLY Last Year": 2005
      },
      {
        "Name": "NIPAWIN 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075520",
        "Station ID": 3421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.35,
        "Longitude (Decimal Degrees)": -104.02,
        "Latitude": 532100000,
        "Longitude": -1040100000,
        "Elevation (m)": 358.4,
        "First Year": 1927,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1975,
        "MLY First Year": 1927,
        "MLY Last Year": 1975
      },
      {
        "Name": "NIPAWIN AWOS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075521",
        "Station ID": 27625,
        "WMO ID": null,
        "TC ID": "ZBU",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -104,
        "Latitude": 531956000,
        "Longitude": -1040012000,
        "Elevation (m)": 371.9,
        "First Year": 1998,
        "Last Year": 2003,
        "HLY First Year": 1998,
        "HLY Last Year": 2003,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NIPAWIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075522",
        "Station ID": 44103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -104.02,
        "Latitude": 532000000,
        "Longitude": -1040100000,
        "Elevation (m)": 371.9,
        "First Year": 2005,
        "Last Year": 2013,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2013,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "NIPAWIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075525",
        "Station ID": 48608,
        "WMO ID": 71130,
        "TC ID": "YBU",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -104.01,
        "Latitude": 531957000,
        "Longitude": -1040030000,
        "Elevation (m)": 371.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PADDOCKWOOD EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4075840",
        "Station ID": 3422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -105.53,
        "Latitude": 533100000,
        "Longitude": -1053200000,
        "Elevation (m)": 485.2,
        "First Year": 1951,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1957,
        "MLY First Year": 1951,
        "MLY Last Year": 1957
      },
      {
        "Name": "RIDGEDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4076790",
        "Station ID": 3423,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.05,
        "Longitude (Decimal Degrees)": -104.25,
        "Latitude": 530300000,
        "Longitude": -1041500000,
        "Elevation (m)": 413,
        "First Year": 1929,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1966,
        "MLY First Year": 1929,
        "MLY Last Year": 1966
      },
      {
        "Name": "SMOKY BURN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4077557",
        "Station ID": 3424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.37,
        "Longitude (Decimal Degrees)": -103.17,
        "Latitude": 532200000,
        "Longitude": -1031000000,
        "Elevation (m)": 319.1,
        "First Year": 1983,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2008,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "SMOKY BURN CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4077560",
        "Station ID": 3425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -103.13,
        "Latitude": 532000000,
        "Longitude": -1030800000,
        "Elevation (m)": 361.5,
        "First Year": 1951,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1972,
        "MLY First Year": 1951,
        "MLY Last Year": 1971
      },
      {
        "Name": "SNOWDEN CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4077600",
        "Station ID": 3426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -104.68,
        "Latitude": 533200000,
        "Longitude": -1044100000,
        "Elevation (m)": 447.4,
        "First Year": 1951,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1968,
        "MLY First Year": 1951,
        "MLY Last Year": 1968
      },
      {
        "Name": "SNOWDEN WHITETAIL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4077605",
        "Station ID": 30454,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.58,
        "Longitude (Decimal Degrees)": -104.68,
        "Latitude": 533500000,
        "Longitude": -1044100000,
        "Elevation (m)": 452,
        "First Year": 2000,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2002,
        "MLY First Year": 2000,
        "MLY Last Year": 2002
      },
      {
        "Name": "SQUAW RAPIDS",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4077735",
        "Station ID": 3427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.7,
        "Longitude (Decimal Degrees)": -103.35,
        "Latitude": 534200000,
        "Longitude": -1032100000,
        "Elevation (m)": 291.1,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "TISDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4078080",
        "Station ID": 3428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -104,
        "Latitude": 525200000,
        "Longitude": -1040000000,
        "Elevation (m)": 449.9,
        "First Year": 1954,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1981,
        "MLY First Year": 1954,
        "MLY Last Year": 1981
      },
      {
        "Name": "TISDALE SOUTH",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4078084",
        "Station ID": 6876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.76,
        "Longitude (Decimal Degrees)": -104.04,
        "Latitude": 524519000,
        "Longitude": -1040211000,
        "Elevation (m)": 501,
        "First Year": 1993,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2009,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "VALPARAISO",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4078415",
        "Station ID": 3429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.78,
        "Longitude (Decimal Degrees)": -104.2,
        "Latitude": 524700000,
        "Longitude": -1041200000,
        "Elevation (m)": 461.8,
        "First Year": 1963,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1971,
        "MLY First Year": 1963,
        "MLY Last Year": 1971
      },
      {
        "Name": "WHITE FOX CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4078800",
        "Station ID": 3430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -104.08,
        "Latitude": 532700000,
        "Longitude": -1040500000,
        "Elevation (m)": 372.2,
        "First Year": 1951,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1968,
        "MLY First Year": 1951,
        "MLY Last Year": 1968
      },
      {
        "Name": "NIPAWIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "407N51G",
        "Station ID": 10195,
        "WMO ID": 71312,
        "TC ID": "WBU",
        "Latitude (Decimal Degrees)": 53.33,
        "Longitude (Decimal Degrees)": -104,
        "Latitude": 532000000,
        "Longitude": -1040000000,
        "Elevation (m)": 371.9,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARCHERWILL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4080200",
        "Station ID": 3431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.43,
        "Longitude (Decimal Degrees)": -104.02,
        "Latitude": 522600000,
        "Longitude": -1040100000,
        "Elevation (m)": 556,
        "First Year": 1951,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1982,
        "MLY First Year": 1951,
        "MLY Last Year": 1982
      },
      {
        "Name": "ARMIT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4080250",
        "Station ID": 3432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.83,
        "Longitude (Decimal Degrees)": -101.78,
        "Latitude": 525000000,
        "Longitude": -1014700000,
        "Elevation (m)": 308,
        "First Year": 1958,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1994,
        "MLY First Year": 1958,
        "MLY Last Year": 1994
      },
      {
        "Name": "ARRAN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4080260",
        "Station ID": 3433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -101.73,
        "Latitude": 515800000,
        "Longitude": -1014400000,
        "Elevation (m)": 449.6,
        "First Year": 1957,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1971,
        "MLY First Year": 1957,
        "MLY Last Year": 1971
      },
      {
        "Name": "ARRAN 23N",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4080262",
        "Station ID": 3434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -101.63,
        "Latitude": 521200000,
        "Longitude": -1013800000,
        "Elevation (m)": 449.9,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "BERTWELL",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4080580",
        "Station ID": 3435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.6,
        "Longitude (Decimal Degrees)": -102.57,
        "Latitude": 523600000,
        "Longitude": -1023400000,
        "Elevation (m)": 518.2,
        "First Year": 1958,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1958,
        "MLY First Year": 1958,
        "MLY Last Year": 1958
      },
      {
        "Name": "DANBURY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4082072",
        "Station ID": 3436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.13,
        "Longitude (Decimal Degrees)": -102.2,
        "Latitude": 520800000,
        "Longitude": -1021200000,
        "Elevation (m)": 512.1,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "ENDEAVOUR",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4082374",
        "Station ID": 3437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -102.53,
        "Latitude": 521200000,
        "Longitude": -1023200000,
        "Elevation (m)": 582.7,
        "First Year": 1985,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1991,
        "MLY First Year": 1985,
        "MLY Last Year": 1991
      },
      {
        "Name": "ENDEAVOUR 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4082376",
        "Station ID": 6877,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -102.67,
        "Latitude": 521100000,
        "Longitude": -1024000000,
        "Elevation (m)": 541,
        "First Year": 1992,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1994,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "HUDSON BAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083320",
        "Station ID": 3438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -102.4,
        "Latitude": 525200000,
        "Longitude": -1022400000,
        "Elevation (m)": 371.9,
        "First Year": 1943,
        "Last Year": 1978,
        "HLY First Year": 1953,
        "HLY Last Year": 1978,
        "DLY First Year": 1943,
        "DLY Last Year": 1978,
        "MLY First Year": 1943,
        "MLY Last Year": 1978
      },
      {
        "Name": "HUDSON BAY A",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083321",
        "Station ID": 3439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -102.32,
        "Latitude": 524900000,
        "Longitude": -1021900000,
        "Elevation (m)": 358.1,
        "First Year": 1978,
        "Last Year": 1999,
        "HLY First Year": 1978,
        "HLY Last Year": 1999,
        "DLY First Year": 1978,
        "DLY Last Year": 1993,
        "MLY First Year": 1978,
        "MLY Last Year": 1993
      },
      {
        "Name": "HUDSON BAY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083322",
        "Station ID": 7550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -102.32,
        "Latitude": 524900000,
        "Longitude": -1021900000,
        "Elevation (m)": 358.1,
        "First Year": 1992,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2007,
        "DLY First Year": 1992,
        "DLY Last Year": 2007,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUDSON BAY 13W",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083323",
        "Station ID": 6878,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.88,
        "Longitude (Decimal Degrees)": -102.58,
        "Latitude": 525300000,
        "Longitude": -1023500000,
        "Elevation (m)": 422,
        "First Year": 1993,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2017,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUDSON BAY(AUT)",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083324",
        "Station ID": 46367,
        "WMO ID": 71868,
        "TC ID": "ZHB",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -102.32,
        "Latitude": 524900000,
        "Longitude": -1021900000,
        "Elevation (m)": 358.1,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "HUDSON BAY 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083325",
        "Station ID": 3440,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -102.53,
        "Latitude": 525100000,
        "Longitude": -1023200000,
        "Elevation (m)": 395,
        "First Year": 1963,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1993,
        "MLY First Year": 1963,
        "MLY Last Year": 1993
      },
      {
        "Name": "KELVINGTON",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083720",
        "Station ID": 3441,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.15,
        "Longitude (Decimal Degrees)": -103.55,
        "Latitude": 520900000,
        "Longitude": -1033300000,
        "Elevation (m)": 558.7,
        "First Year": 1953,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1967,
        "MLY First Year": 1953,
        "MLY Last Year": 1967
      },
      {
        "Name": "KELVINGTON EXP ST",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4083736",
        "Station ID": 3442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.17,
        "Longitude (Decimal Degrees)": -103.52,
        "Latitude": 521000000,
        "Longitude": -1033100000,
        "Elevation (m)": 594.4,
        "First Year": 1951,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1959,
        "MLY First Year": 1951,
        "MLY Last Year": 1959
      },
      {
        "Name": "LINTLAW",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4084440",
        "Station ID": 3444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -103.27,
        "Latitude": 520500000,
        "Longitude": -1031600000,
        "Elevation (m)": 610.5,
        "First Year": 1926,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1968,
        "MLY First Year": 1926,
        "MLY Last Year": 1968
      },
      {
        "Name": "LAC VERT",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4084ALM",
        "Station ID": 3443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -104.35,
        "Latitude": 523100000,
        "Longitude": -1042100000,
        "Elevation (m)": 571.5,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "MCKAGUE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4085050",
        "Station ID": 3445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.57,
        "Longitude (Decimal Degrees)": -103.83,
        "Latitude": 523400000,
        "Longitude": -1035000000,
        "Elevation (m)": 539,
        "First Year": 1971,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2001,
        "MLY First Year": 1971,
        "MLY Last Year": 2001
      },
      {
        "Name": "MCKAGUE 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4085052",
        "Station ID": 3446,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.58,
        "Longitude (Decimal Degrees)": -103.83,
        "Latitude": 523500000,
        "Longitude": -1035000000,
        "Elevation (m)": 543,
        "First Year": 1985,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2019,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "MISTATIM",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4085241",
        "Station ID": 3447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.85,
        "Longitude (Decimal Degrees)": -103.23,
        "Latitude": 525100000,
        "Longitude": -1031400000,
        "Elevation (m)": 482,
        "First Year": 1977,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1987,
        "MLY First Year": 1977,
        "MLY Last Year": 1987
      },
      {
        "Name": "PELLY",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086000",
        "Station ID": 3448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.08,
        "Longitude (Decimal Degrees)": -101.87,
        "Latitude": 520500000,
        "Longitude": -1015200000,
        "Elevation (m)": 509,
        "First Year": 1951,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2016,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "PELLY 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086001",
        "Station ID": 3449,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -101.9,
        "Latitude": 514400000,
        "Longitude": -1015400000,
        "Elevation (m)": 499,
        "First Year": 1978,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2019,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "PLEASANTDALE",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086136",
        "Station ID": 3450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.58,
        "Longitude (Decimal Degrees)": -104.52,
        "Latitude": 523500000,
        "Longitude": -1043100000,
        "Elevation (m)": 533,
        "First Year": 1985,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1989,
        "MLY First Year": 1985,
        "MLY Last Year": 1989
      },
      {
        "Name": "PORCUPINE PLAIN",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086160",
        "Station ID": 3451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.65,
        "Longitude (Decimal Degrees)": -103.15,
        "Latitude": 523900000,
        "Longitude": -1030900000,
        "Elevation (m)": 464.8,
        "First Year": 1953,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1995,
        "MLY First Year": 1953,
        "MLY Last Year": 1995
      },
      {
        "Name": "PORCUPINE PLAIN 2",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086161",
        "Station ID": 3452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.6,
        "Longitude (Decimal Degrees)": -103.25,
        "Latitude": 523600000,
        "Longitude": -1031500000,
        "Elevation (m)": 498.3,
        "First Year": 1953,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1964,
        "MLY First Year": 1953,
        "MLY Last Year": 1964
      },
      {
        "Name": "PORCUPINE PLAIN 3",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086162",
        "Station ID": 3453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -103.2,
        "Latitude": 524500000,
        "Longitude": -1031200000,
        "Elevation (m)": null,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "PRAIRIE RIVER",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4086180",
        "Station ID": 3454,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.87,
        "Longitude (Decimal Degrees)": -102.98,
        "Latitude": 525200000,
        "Longitude": -1025900000,
        "Elevation (m)": 467,
        "First Year": 1956,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1982,
        "MLY First Year": 1956,
        "MLY Last Year": 1982
      },
      {
        "Name": "SOMME CDA EPF",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4087640",
        "Station ID": 3455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.62,
        "Longitude (Decimal Degrees)": -103.02,
        "Latitude": 523700000,
        "Longitude": -1030100000,
        "Elevation (m)": 438.9,
        "First Year": 1953,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1983,
        "MLY First Year": 1953,
        "MLY Last Year": 1983
      },
      {
        "Name": "WEEKES",
        "Province": "SASKATCHEWAN",
        "Climate ID": "4088720",
        "Station ID": 3456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -102.83,
        "Latitude": 524500000,
        "Longitude": -1025000000,
        "Elevation (m)": 421.5,
        "First Year": 1948,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1966,
        "MLY First Year": 1948,
        "MLY Last Year": 1966
      },
      {
        "Name": "ALEXANDER",
        "Province": "MANITOBA",
        "Climate ID": "5010033",
        "Station ID": 3461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -100.3,
        "Latitude": 495000000,
        "Longitude": -1001800000,
        "Elevation (m)": 427,
        "First Year": 1979,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1999,
        "MLY First Year": 1979,
        "MLY Last Year": 1999
      },
      {
        "Name": "ARROW RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5010114",
        "Station ID": 3462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -100.85,
        "Latitude": 501000000,
        "Longitude": -1005100000,
        "Elevation (m)": 486.2,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "BALDUR",
        "Province": "MANITOBA",
        "Climate ID": "5010140",
        "Station ID": 3463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -99.29,
        "Latitude": 491634900,
        "Longitude": -991732500,
        "Elevation (m)": 449.9,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "BALDUR 5 NORTHEAST",
        "Province": "MANITOBA",
        "Climate ID": "5010143",
        "Station ID": 6880,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -99.17,
        "Latitude": 492500000,
        "Longitude": -991000000,
        "Elevation (m)": 425.3,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "BEDE",
        "Province": "MANITOBA",
        "Climate ID": "5010180",
        "Station ID": 3464,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -100.93,
        "Latitude": 492200000,
        "Longitude": -1005600000,
        "Elevation (m)": 442,
        "First Year": 1956,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1977,
        "MLY First Year": 1956,
        "MLY Last Year": 1977
      },
      {
        "Name": "BELMONT",
        "Province": "MANITOBA",
        "Climate ID": "5010190",
        "Station ID": 3465,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -99.43,
        "Latitude": 492400000,
        "Longitude": -992600000,
        "Elevation (m)": 447.4,
        "First Year": 1891,
        "Last Year": 1906,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1906,
        "MLY First Year": 1891,
        "MLY Last Year": 1906
      },
      {
        "Name": "BELMONT",
        "Province": "MANITOBA",
        "Climate ID": "5010191",
        "Station ID": 3466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -99.45,
        "Latitude": 491800000,
        "Longitude": -992700000,
        "Elevation (m)": 481.6,
        "First Year": 1984,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2016,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "BINSCARTH",
        "Province": "MANITOBA",
        "Climate ID": "5010216",
        "Station ID": 3467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.58,
        "Longitude (Decimal Degrees)": -101.27,
        "Latitude": 503500000,
        "Longitude": -1011600000,
        "Elevation (m)": 525.8,
        "First Year": 1973,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2005,
        "MLY First Year": 1973,
        "MLY Last Year": 2005
      },
      {
        "Name": "BIRDTAIL",
        "Province": "MANITOBA",
        "Climate ID": "5010227",
        "Station ID": 3468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -100.92,
        "Latitude": 504900000,
        "Longitude": -1005500000,
        "Elevation (m)": 577.6,
        "First Year": 1989,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1995,
        "MLY First Year": 1989,
        "MLY Last Year": 1995
      },
      {
        "Name": "BIRTLE",
        "Province": "MANITOBA",
        "Climate ID": "5010240",
        "Station ID": 3469,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -101.05,
        "Latitude": 502600000,
        "Longitude": -1010300000,
        "Elevation (m)": 522,
        "First Year": 1883,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 2000,
        "MLY First Year": 1883,
        "MLY Last Year": 2000
      },
      {
        "Name": "BOGGY CREEK",
        "Province": "MANITOBA",
        "Climate ID": "5010310",
        "Station ID": 3470,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.54,
        "Longitude (Decimal Degrees)": -101.39,
        "Latitude": 513219000,
        "Longitude": -1012328000,
        "Elevation (m)": 609,
        "First Year": 1977,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2014,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRANDON A",
        "Province": "MANITOBA",
        "Climate ID": "5010480",
        "Station ID": 3471,
        "WMO ID": 71140,
        "TC ID": "YBR",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -99.95,
        "Latitude": 495436000,
        "Longitude": -995707000,
        "Elevation (m)": 409.4,
        "First Year": 1941,
        "Last Year": 2012,
        "HLY First Year": 1958,
        "HLY Last Year": 2012,
        "DLY First Year": 1941,
        "DLY Last Year": 2012,
        "MLY First Year": 1941,
        "MLY Last Year": 2012
      },
      {
        "Name": "BRANDON A",
        "Province": "MANITOBA",
        "Climate ID": "5010481",
        "Station ID": 50821,
        "WMO ID": 71140,
        "TC ID": "YBR",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -99.95,
        "Latitude": 495436000,
        "Longitude": -995708000,
        "Elevation (m)": 409.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BRANDON CDA",
        "Province": "MANITOBA",
        "Climate ID": "5010485",
        "Station ID": 3472,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -99.98,
        "Latitude": 495200000,
        "Longitude": -995900000,
        "Elevation (m)": 362.7,
        "First Year": 1890,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1890,
        "DLY Last Year": 2010,
        "MLY First Year": 1890,
        "MLY Last Year": 2007
      },
      {
        "Name": "BRANDON RCS",
        "Province": "MANITOBA",
        "Climate ID": "5010490",
        "Station ID": 49909,
        "WMO ID": 71136,
        "TC ID": "PBO",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -99.95,
        "Latitude": 495400000,
        "Longitude": -995700000,
        "Elevation (m)": 409.4,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BRANDON SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "5010494",
        "Station ID": 3473,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -99.95,
        "Latitude": 494900000,
        "Longitude": -995700000,
        "Elevation (m)": 396.2,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "BRANDON #1 WINTER BAY",
        "Province": "MANITOBA",
        "Climate ID": "5010498",
        "Station ID": 3474,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -99.98,
        "Latitude": 495000000,
        "Longitude": -995900000,
        "Elevation (m)": 396,
        "First Year": 1987,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2002,
        "MLY First Year": 1987,
        "MLY Last Year": 2002
      },
      {
        "Name": "BROOKDALE",
        "Province": "MANITOBA",
        "Climate ID": "5010524",
        "Station ID": 3476,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -99.57,
        "Latitude": 500100000,
        "Longitude": -993400000,
        "Elevation (m)": 392.4,
        "First Year": 1987,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2003,
        "MLY First Year": 1987,
        "MLY Last Year": 2003
      },
      {
        "Name": "CAMERON",
        "Province": "MANITOBA",
        "Climate ID": "5010538",
        "Station ID": 3477,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -100.47,
        "Latitude": 492800000,
        "Longitude": -1002800000,
        "Elevation (m)": 442,
        "First Year": 1984,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2001,
        "MLY First Year": 1984,
        "MLY Last Year": 2001
      },
      {
        "Name": "CARBERRY CS",
        "Province": "MANITOBA",
        "Climate ID": "5010547",
        "Station ID": 27741,
        "WMO ID": 71170,
        "TC ID": "WZE",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -99.36,
        "Latitude": 495420900,
        "Longitude": -992126800,
        "Elevation (m)": 383.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARBERRY",
        "Province": "MANITOBA",
        "Climate ID": "5010548",
        "Station ID": 3478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -99.35,
        "Latitude": 495200000,
        "Longitude": -992100000,
        "Elevation (m)": 385,
        "First Year": 1962,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1999,
        "MLY First Year": 1962,
        "MLY Last Year": 1999
      },
      {
        "Name": "CARDALE",
        "Province": "MANITOBA",
        "Climate ID": "5010550",
        "Station ID": 3479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -100.33,
        "Latitude": 501400000,
        "Longitude": -1002000000,
        "Elevation (m)": 556.3,
        "First Year": 1966,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1972,
        "MLY First Year": 1966,
        "MLY Last Year": 1972
      },
      {
        "Name": "CARROLL",
        "Province": "MANITOBA",
        "Climate ID": "5010554",
        "Station ID": 3480,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -100,
        "Latitude": 493300000,
        "Longitude": -1000000000,
        "Elevation (m)": 487.7,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1969,
        "MLY Last Year": 1984
      },
      {
        "Name": "CARROLL 2W",
        "Province": "MANITOBA",
        "Climate ID": "50105ED",
        "Station ID": 3475,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -100.07,
        "Latitude": 493600000,
        "Longitude": -1000400000,
        "Elevation (m)": 457.2,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "CLANWILLIAM",
        "Province": "MANITOBA",
        "Climate ID": "5010609",
        "Station ID": 27869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.35,
        "Longitude (Decimal Degrees)": -99.81,
        "Latitude": 502105000,
        "Longitude": -994850000,
        "Elevation (m)": 623.3,
        "First Year": 1999,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2001,
        "MLY First Year": 1999,
        "MLY Last Year": 2001
      },
      {
        "Name": "CYPRESS RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5010640",
        "Station ID": 3481,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -99.08,
        "Latitude": 493300000,
        "Longitude": -990500000,
        "Elevation (m)": 374.3,
        "First Year": 1904,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 2012,
        "MLY First Year": 1904,
        "MLY Last Year": 2007
      },
      {
        "Name": "CYPRESS RIVER RCS",
        "Province": "MANITOBA",
        "Climate ID": "5010641",
        "Station ID": 48128,
        "WMO ID": 71239,
        "TC ID": "POY",
        "Latitude (Decimal Degrees)": 49.56,
        "Longitude (Decimal Degrees)": -99.07,
        "Latitude": 493344000,
        "Longitude": -990426000,
        "Elevation (m)": 374,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DELORAINE",
        "Province": "MANITOBA",
        "Climate ID": "5010760",
        "Station ID": 3482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -100.5,
        "Latitude": 491100000,
        "Longitude": -1003000000,
        "Elevation (m)": 500.5,
        "First Year": 1897,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1994,
        "MLY First Year": 1925,
        "MLY Last Year": 1994
      },
      {
        "Name": "DELORAINE 2",
        "Province": "MANITOBA",
        "Climate ID": "5010761",
        "Station ID": 3483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -100.4,
        "Latitude": 491000000,
        "Longitude": -1002400000,
        "Elevation (m)": 533.4,
        "First Year": 1953,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1976,
        "MLY First Year": 1953,
        "MLY Last Year": 1976
      },
      {
        "Name": "DELORAINE 6 SE",
        "Province": "MANITOBA",
        "Climate ID": "5010762",
        "Station ID": 3484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -100.37,
        "Latitude": 490900000,
        "Longitude": -1002200000,
        "Elevation (m)": 548.6,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "EDWIN",
        "Province": "MANITOBA",
        "Climate ID": "5010859",
        "Station ID": 3485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -98.52,
        "Latitude": 494900000,
        "Longitude": -983100000,
        "Elevation (m)": 300.8,
        "First Year": 1986,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1986,
        "MLY First Year": 1986,
        "MLY Last Year": 1986
      },
      {
        "Name": "ELKHORN",
        "Province": "MANITOBA",
        "Climate ID": "5010868",
        "Station ID": 3486,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -101.23,
        "Latitude": 495800000,
        "Longitude": -1011400000,
        "Elevation (m)": 499,
        "First Year": 1891,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1987,
        "MLY First Year": 1891,
        "MLY Last Year": 1987
      },
      {
        "Name": "ERICKSON",
        "Province": "MANITOBA",
        "Climate ID": "5010893",
        "Station ID": 3487,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -99.9,
        "Latitude": 502600000,
        "Longitude": -995400000,
        "Elevation (m)": 635.5,
        "First Year": 1971,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1971,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "ERICKSON",
        "Province": "MANITOBA",
        "Climate ID": "5010894",
        "Station ID": 3488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -99.9,
        "Latitude": 502900000,
        "Longitude": -995400000,
        "Elevation (m)": 648,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1986,
        "MLY First Year": 1985,
        "MLY Last Year": 1986
      },
      {
        "Name": "FAIRFAX",
        "Province": "MANITOBA",
        "Climate ID": "5010906",
        "Station ID": 3489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -100.13,
        "Latitude": 492500000,
        "Longitude": -1000800000,
        "Elevation (m)": 480.1,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1987,
        "MLY Last Year": 1990
      },
      {
        "Name": "FORT ELLICE",
        "Province": "MANITOBA",
        "Climate ID": "5010977",
        "Station ID": 3490,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -101.23,
        "Latitude": 502500000,
        "Longitude": -1011400000,
        "Elevation (m)": null,
        "First Year": 1883,
        "Last Year": 1894,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1894,
        "MLY First Year": 1883,
        "MLY Last Year": 1894
      },
      {
        "Name": "FOXWARREN",
        "Province": "MANITOBA",
        "Climate ID": "5010981",
        "Station ID": 3491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -101.15,
        "Latitude": 503100000,
        "Longitude": -1010900000,
        "Elevation (m)": 533.4,
        "First Year": 1969,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2001,
        "MLY First Year": 1969,
        "MLY Last Year": 2001
      },
      {
        "Name": "FOXWARREN NORTH",
        "Province": "MANITOBA",
        "Climate ID": "5010982",
        "Station ID": 27611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -101.17,
        "Latitude": 503600000,
        "Longitude": -1011000000,
        "Elevation (m)": 556.3,
        "First Year": 1998,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2001,
        "MLY First Year": 1998,
        "MLY Last Year": 2001
      },
      {
        "Name": "ELKHORN 2 EAST",
        "Province": "MANITOBA",
        "Climate ID": "5010QFQ",
        "Station ID": 3460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -101.2,
        "Latitude": 495536200,
        "Longitude": -1011210100,
        "Elevation (m)": 497.5,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "GLENBORO",
        "Province": "MANITOBA",
        "Climate ID": "5011051",
        "Station ID": 3492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -99.33,
        "Latitude": 493400000,
        "Longitude": -992000000,
        "Elevation (m)": 371.9,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "GOODLANDS CDA EPF",
        "Province": "MANITOBA",
        "Climate ID": "5011080",
        "Station ID": 3493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -100.6,
        "Latitude": 490800000,
        "Longitude": -1003600000,
        "Elevation (m)": 499.9,
        "First Year": 1951,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1976,
        "MLY First Year": 1951,
        "MLY Last Year": 1976
      },
      {
        "Name": "GRISWOLD",
        "Province": "MANITOBA",
        "Climate ID": "5011224",
        "Station ID": 3494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -100.47,
        "Latitude": 494900000,
        "Longitude": -1002800000,
        "Elevation (m)": 403.9,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "HAMIOTA",
        "Province": "MANITOBA",
        "Climate ID": "5011240",
        "Station ID": 3495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -100.62,
        "Latitude": 501100000,
        "Longitude": -1003700000,
        "Elevation (m)": 518.2,
        "First Year": 1914,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1991,
        "MLY First Year": 1914,
        "MLY Last Year": 1991
      },
      {
        "Name": "HAMIOTA 2",
        "Province": "MANITOBA",
        "Climate ID": "5011241",
        "Station ID": 3496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -100.6,
        "Latitude": 501100000,
        "Longitude": -1003600000,
        "Elevation (m)": 519.7,
        "First Year": 1975,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2002,
        "MLY First Year": 1975,
        "MLY Last Year": 2002
      },
      {
        "Name": "HARDING",
        "Province": "MANITOBA",
        "Climate ID": "5011275",
        "Station ID": 3497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -100.5,
        "Latitude": 500200000,
        "Longitude": -1003000000,
        "Elevation (m)": 472.4,
        "First Year": 1966,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1983,
        "MLY First Year": 1966,
        "MLY Last Year": 1983
      },
      {
        "Name": "HARGRAVE CDA EPF",
        "Province": "MANITOBA",
        "Climate ID": "5011280",
        "Station ID": 3498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -101.1,
        "Latitude": 495500000,
        "Longitude": -1010600000,
        "Elevation (m)": 487.7,
        "First Year": 1951,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1970,
        "MLY First Year": 1951,
        "MLY Last Year": 1970
      },
      {
        "Name": "HARTNEY",
        "Province": "MANITOBA",
        "Climate ID": "5011282",
        "Station ID": 3499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -100.52,
        "Latitude": 492700000,
        "Longitude": -1003100000,
        "Elevation (m)": 442,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "HARTNEY",
        "Province": "MANITOBA",
        "Climate ID": "5011283",
        "Station ID": 3500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -100.53,
        "Latitude": 493000000,
        "Longitude": -1003200000,
        "Elevation (m)": 428.2,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "HILLVIEW",
        "Province": "MANITOBA",
        "Climate ID": "5011293",
        "Station ID": 3501,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -100.55,
        "Latitude": 495500000,
        "Longitude": -1003300000,
        "Elevation (m)": 426.7,
        "First Year": 1885,
        "Last Year": 1920,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1920,
        "MLY First Year": 1885,
        "MLY Last Year": 1920
      },
      {
        "Name": "INGLIS",
        "Province": "MANITOBA",
        "Climate ID": "5011360",
        "Station ID": 3502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.9,
        "Longitude (Decimal Degrees)": -101.23,
        "Latitude": 505400000,
        "Longitude": -1011400000,
        "Elevation (m)": 594.4,
        "First Year": 1949,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1969,
        "MLY First Year": 1949,
        "MLY Last Year": 1969
      },
      {
        "Name": "KENTON",
        "Province": "MANITOBA",
        "Climate ID": "5011434",
        "Station ID": 3503,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -100.58,
        "Latitude": 495900000,
        "Longitude": -1003500000,
        "Elevation (m)": 449,
        "First Year": 1987,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2006,
        "MLY First Year": 1987,
        "MLY Last Year": 2006
      },
      {
        "Name": "LYLETON",
        "Province": "MANITOBA",
        "Climate ID": "5011600",
        "Station ID": 3504,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -101.18,
        "Latitude": 490300000,
        "Longitude": -1011100000,
        "Elevation (m)": 460.2,
        "First Year": 1951,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1983,
        "MLY First Year": 1951,
        "MLY Last Year": 1983
      },
      {
        "Name": "MACDONALD A",
        "Province": "MANITOBA",
        "Climate ID": "5011680",
        "Station ID": 3505,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -98.45,
        "Latitude": 500500000,
        "Longitude": -982700000,
        "Elevation (m)": 254.5,
        "First Year": 1951,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1959,
        "MLY First Year": 1951,
        "MLY Last Year": 1959
      },
      {
        "Name": "MCCONNELL",
        "Province": "MANITOBA",
        "Climate ID": "5011705",
        "Station ID": 3506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -100.6,
        "Latitude": 501600000,
        "Longitude": -1003600000,
        "Elevation (m)": 541,
        "First Year": 1966,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "MEDORA",
        "Province": "MANITOBA",
        "Climate ID": "5011715",
        "Station ID": 3507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -100.7,
        "Latitude": 491500000,
        "Longitude": -1004200000,
        "Elevation (m)": 458.1,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "MELITA",
        "Province": "MANITOBA",
        "Climate ID": "5011720",
        "Station ID": 3508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -101,
        "Latitude": 492000000,
        "Longitude": -1010000000,
        "Elevation (m)": 442,
        "First Year": 1936,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1960,
        "MLY First Year": 1936,
        "MLY Last Year": 1960
      },
      {
        "Name": "MELITA 2",
        "Province": "MANITOBA",
        "Climate ID": "5011721",
        "Station ID": 6881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -100.98,
        "Latitude": 491700000,
        "Longitude": -1005900000,
        "Elevation (m)": 422,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "MINNEDOSA",
        "Province": "MANITOBA",
        "Climate ID": "5011760",
        "Station ID": 3509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -99.83,
        "Latitude": 501600000,
        "Longitude": -995000000,
        "Elevation (m)": 521.2,
        "First Year": 1880,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1999,
        "MLY First Year": 1881,
        "MLY Last Year": 1999
      },
      {
        "Name": "MINTO",
        "Province": "MANITOBA",
        "Climate ID": "5011762",
        "Station ID": 3510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -100.05,
        "Latitude": 492700000,
        "Longitude": -1000300000,
        "Elevation (m)": 477,
        "First Year": 1979,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1985,
        "MLY First Year": 1979,
        "MLY Last Year": 1985
      },
      {
        "Name": "NAPINKA",
        "Province": "MANITOBA",
        "Climate ID": "5011990",
        "Station ID": 6950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -100.85,
        "Latitude": 492000000,
        "Longitude": -1005100000,
        "Elevation (m)": 434.6,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "NORQUAY",
        "Province": "MANITOBA",
        "Climate ID": "5012046",
        "Station ID": 3511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -98.75,
        "Latitude": 492900000,
        "Longitude": -984500000,
        "Elevation (m)": 457.2,
        "First Year": 1883,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1913,
        "MLY First Year": 1883,
        "MLY Last Year": 1913
      },
      {
        "Name": "OAKNER",
        "Province": "MANITOBA",
        "Climate ID": "5012054",
        "Station ID": 3513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -100.63,
        "Latitude": 500600000,
        "Longitude": -1003800000,
        "Elevation (m)": 502.9,
        "First Year": 1962,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2007,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "OLHA",
        "Province": "MANITOBA",
        "Climate ID": "501205N",
        "Station ID": 3512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -100.55,
        "Latitude": 504000000,
        "Longitude": -1003300000,
        "Elevation (m)": 621.8,
        "First Year": 1966,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1995,
        "MLY First Year": 1966,
        "MLY Last Year": 1995
      },
      {
        "Name": "PIERSON",
        "Province": "MANITOBA",
        "Climate ID": "5012080",
        "Station ID": 3514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -101.27,
        "Latitude": 491100000,
        "Longitude": -1011600000,
        "Elevation (m)": 469,
        "First Year": 1904,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 2007,
        "MLY First Year": 1904,
        "MLY Last Year": 2007
      },
      {
        "Name": "PIPESTONE",
        "Province": "MANITOBA",
        "Climate ID": "5012200",
        "Station ID": 3515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -100.92,
        "Latitude": 493200000,
        "Longitude": -1005500000,
        "Elevation (m)": 437.4,
        "First Year": 1899,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1985,
        "MLY First Year": 1899,
        "MLY Last Year": 1985
      },
      {
        "Name": "PORTAGE LA PRAIRIE",
        "Province": "MANITOBA",
        "Climate ID": "5012279",
        "Station ID": 3516,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -98.3,
        "Latitude": 495800000,
        "Longitude": -981800000,
        "Elevation (m)": 261.2,
        "First Year": 1886,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1918,
        "MLY First Year": 1887,
        "MLY Last Year": 1918
      },
      {
        "Name": "PORTAGE LA PRAIRIE",
        "Province": "MANITOBA",
        "Climate ID": "5012280",
        "Station ID": 3517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -98.3,
        "Latitude": 495800000,
        "Longitude": -981800000,
        "Elevation (m)": 261.2,
        "First Year": 1904,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 1971,
        "MLY First Year": 1904,
        "MLY Last Year": 1971
      },
      {
        "Name": "PORTAGE ROMANCE",
        "Province": "MANITOBA",
        "Climate ID": "5012300",
        "Station ID": 45987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -98.3,
        "Latitude": 495900000,
        "Longitude": -981800000,
        "Elevation (m)": 262.4,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORTAGE SOUTHPORT A",
        "Province": "MANITOBA",
        "Climate ID": "5012320",
        "Station ID": 3518,
        "WMO ID": null,
        "TC ID": "YPG",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -98.27,
        "Latitude": 495400000,
        "Longitude": -981600000,
        "Elevation (m)": 269.7,
        "First Year": 1941,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1941,
        "DLY Last Year": 1992,
        "MLY First Year": 1941,
        "MLY Last Year": 1992
      },
      {
        "Name": "PORTAGE LA PRAIRIE CDA",
        "Province": "MANITOBA",
        "Climate ID": "5012321",
        "Station ID": 3519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -98.27,
        "Latitude": 495700000,
        "Longitude": -981600000,
        "Elevation (m)": 259.1,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1970,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORTAGE LA PRAIRIE 2",
        "Province": "MANITOBA",
        "Climate ID": "5012322",
        "Station ID": 3520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -98.32,
        "Latitude": 495900000,
        "Longitude": -981900000,
        "Elevation (m)": 261.2,
        "First Year": 1962,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1996,
        "MLY First Year": 1962,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORTAGE SOUTHPORT",
        "Province": "MANITOBA",
        "Climate ID": "5012324",
        "Station ID": 10884,
        "WMO ID": 71851,
        "TC ID": "WPG",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -98.28,
        "Latitude": 495411300,
        "Longitude": -981702900,
        "Elevation (m)": 272.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "RAPID CITY FORREST",
        "Province": "MANITOBA",
        "Climate ID": "5012333",
        "Station ID": 3522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -100,
        "Latitude": 500100000,
        "Longitude": -1000000000,
        "Elevation (m)": 487.7,
        "First Year": 1885,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1939,
        "MLY First Year": 1885,
        "MLY Last Year": 1939
      },
      {
        "Name": "RAPID CITY",
        "Province": "MANITOBA",
        "Climate ID": "501233B",
        "Station ID": 3521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -99.93,
        "Latitude": 500700000,
        "Longitude": -995600000,
        "Elevation (m)": 545.6,
        "First Year": 1977,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1997,
        "MLY First Year": 1977,
        "MLY Last Year": 1997
      },
      {
        "Name": "REGENT",
        "Province": "MANITOBA",
        "Climate ID": "5012340",
        "Station ID": 3523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -100.37,
        "Latitude": 491900000,
        "Longitude": -1002200000,
        "Elevation (m)": 500.5,
        "First Year": 1955,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1966,
        "MLY First Year": 1955,
        "MLY Last Year": 1966
      },
      {
        "Name": "RESTON",
        "Province": "MANITOBA",
        "Climate ID": "5012400",
        "Station ID": 3524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -101.1,
        "Latitude": 493300000,
        "Longitude": -1010600000,
        "Elevation (m)": 463.6,
        "First Year": 1952,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1994,
        "MLY First Year": 1952,
        "MLY Last Year": 1994
      },
      {
        "Name": "RIDING MTN AUDY",
        "Province": "MANITOBA",
        "Climate ID": "5012423",
        "Station ID": 3525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -100.23,
        "Latitude": 504300000,
        "Longitude": -1001400000,
        "Elevation (m)": 571.5,
        "First Year": 1977,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1990,
        "MLY First Year": 1977,
        "MLY Last Year": 1990
      },
      {
        "Name": "RIVERS",
        "Province": "MANITOBA",
        "Climate ID": "5012439",
        "Station ID": 3526,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -100.23,
        "Latitude": 500200000,
        "Longitude": -1001400000,
        "Elevation (m)": 475.5,
        "First Year": 1984,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1991,
        "MLY First Year": 1984,
        "MLY Last Year": 1991
      },
      {
        "Name": "RIVERS A",
        "Province": "MANITOBA",
        "Climate ID": "5012440",
        "Station ID": 3527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -100.32,
        "Latitude": 500100000,
        "Longitude": -1001900000,
        "Elevation (m)": 473.4,
        "First Year": 1938,
        "Last Year": 1983,
        "HLY First Year": 1953,
        "HLY Last Year": 1983,
        "DLY First Year": 1938,
        "DLY Last Year": 1970,
        "MLY First Year": 1938,
        "MLY Last Year": 1970
      },
      {
        "Name": "ROBLIN",
        "Province": "MANITOBA",
        "Climate ID": "5012469",
        "Station ID": 27119,
        "WMO ID": 71553,
        "TC ID": "WXB",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -101.36,
        "Latitude": 511100018,
        "Longitude": -1012146068,
        "Elevation (m)": 539.5,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROBLIN",
        "Province": "MANITOBA",
        "Climate ID": "5012471",
        "Station ID": 3528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -101.38,
        "Latitude": 511400000,
        "Longitude": -1012300000,
        "Elevation (m)": 556,
        "First Year": 1969,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1979,
        "MLY First Year": 1969,
        "MLY Last Year": 1979
      },
      {
        "Name": "ROBLIN",
        "Province": "MANITOBA",
        "Climate ID": "5012472",
        "Station ID": 3529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -101.38,
        "Latitude": 511400000,
        "Longitude": -1012300000,
        "Elevation (m)": 556,
        "First Year": 1980,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1997,
        "MLY First Year": 1980,
        "MLY Last Year": 1997
      },
      {
        "Name": "ROBLIN NORTH",
        "Province": "MANITOBA",
        "Climate ID": "5012473",
        "Station ID": 3530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -101.4,
        "Latitude": 512200000,
        "Longitude": -1012400000,
        "Elevation (m)": 587,
        "First Year": 1980,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1986,
        "MLY First Year": 1980,
        "MLY Last Year": 1986
      },
      {
        "Name": "ROSSBURN",
        "Province": "MANITOBA",
        "Climate ID": "5012500",
        "Station ID": 3531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -100.82,
        "Latitude": 504000000,
        "Longitude": -1004900000,
        "Elevation (m)": 590.1,
        "First Year": 1955,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1990,
        "MLY First Year": 1955,
        "MLY Last Year": 1990
      },
      {
        "Name": "ROSSBURN 10N",
        "Province": "MANITOBA",
        "Climate ID": "5012501",
        "Station ID": 3532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -100.83,
        "Latitude": 504800000,
        "Longitude": -1005000000,
        "Elevation (m)": 533.4,
        "First Year": 1971,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1980,
        "MLY First Year": 1971,
        "MLY Last Year": 1980
      },
      {
        "Name": "RUSSELL",
        "Province": "MANITOBA",
        "Climate ID": "5012520",
        "Station ID": 3533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.77,
        "Longitude (Decimal Degrees)": -101.28,
        "Latitude": 504600000,
        "Longitude": -1011700000,
        "Elevation (m)": 567,
        "First Year": 1883,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1990,
        "MLY First Year": 1883,
        "MLY Last Year": 1990
      },
      {
        "Name": "ST ALBANS",
        "Province": "MANITOBA",
        "Climate ID": "5012540",
        "Station ID": 3534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -99.55,
        "Latitude": 494200000,
        "Longitude": -993300000,
        "Elevation (m)": 359.7,
        "First Year": 1885,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1960,
        "MLY First Year": 1885,
        "MLY Last Year": 1960
      },
      {
        "Name": "ST ALPHONSE",
        "Province": "MANITOBA",
        "Climate ID": "5012545",
        "Station ID": 3535,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -99.02,
        "Latitude": 492700000,
        "Longitude": -990100000,
        "Elevation (m)": 403.3,
        "First Year": 1962,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2016,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST LAZARE",
        "Province": "MANITOBA",
        "Climate ID": "5012600",
        "Station ID": 3536,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -101.3,
        "Latitude": 502600000,
        "Longitude": -1011800000,
        "Elevation (m)": 502.9,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "SANDY LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5012620",
        "Station ID": 3537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -100.1,
        "Latitude": 503000000,
        "Longitude": -1000600000,
        "Elevation (m)": 624.8,
        "First Year": 1973,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2005,
        "MLY First Year": 1973,
        "MLY Last Year": 2005
      },
      {
        "Name": "SHOAL LAKE CS",
        "Province": "MANITOBA",
        "Climate ID": "5012654",
        "Station ID": 10927,
        "WMO ID": 71150,
        "TC ID": "WUT",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -100.6,
        "Latitude": 502717600,
        "Longitude": -1003612600,
        "Elevation (m)": 561.3,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHARPE LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5012661",
        "Station ID": 3538,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -100.33,
        "Latitude": 490300000,
        "Longitude": -1002000000,
        "Elevation (m)": 656.8,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "SHILO",
        "Province": "MANITOBA",
        "Climate ID": "5012672",
        "Station ID": 3539,
        "WMO ID": 71853,
        "TC ID": "WLO",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -99.64,
        "Latitude": 494658000,
        "Longitude": -993835000,
        "Elevation (m)": 375.2,
        "First Year": 1954,
        "Last Year": 1999,
        "HLY First Year": 1954,
        "HLY Last Year": 1999,
        "DLY First Year": 1954,
        "DLY Last Year": 1970,
        "MLY First Year": 1954,
        "MLY Last Year": 1970
      },
      {
        "Name": "CFB SHILO CCOC",
        "Province": "MANITOBA",
        "Climate ID": "5012673",
        "Station ID": 43343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -99.63,
        "Latitude": 494726000,
        "Longitude": -993743000,
        "Elevation (m)": 372,
        "First Year": 2004,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2005,
        "MLY First Year": 2004,
        "MLY Last Year": 2005
      },
      {
        "Name": "SHOAL LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5012675",
        "Station ID": 3540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.43,
        "Longitude (Decimal Degrees)": -100.58,
        "Latitude": 502600000,
        "Longitude": -1003500000,
        "Elevation (m)": 543.5,
        "First Year": 1883,
        "Last Year": 1900,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1900,
        "MLY First Year": 1883,
        "MLY Last Year": 1900
      },
      {
        "Name": "SHOAL LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5012676",
        "Station ID": 3541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -100.72,
        "Latitude": 502900000,
        "Longitude": -1004300000,
        "Elevation (m)": 624.8,
        "First Year": 1966,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1977,
        "MLY First Year": 1966,
        "MLY Last Year": 1977
      },
      {
        "Name": "SHOAL LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5012677",
        "Station ID": 3542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -100.58,
        "Latitude": 502700000,
        "Longitude": -1003500000,
        "Elevation (m)": 555.3,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "SILVERTON EXP ST",
        "Province": "MANITOBA",
        "Climate ID": "5012680",
        "Station ID": 3543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.77,
        "Longitude (Decimal Degrees)": -101.15,
        "Latitude": 504600000,
        "Longitude": -1010900000,
        "Elevation (m)": 571.8,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1958,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "SOMERSET",
        "Province": "MANITOBA",
        "Climate ID": "5012710",
        "Station ID": 3544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -98.62,
        "Latitude": 492700000,
        "Longitude": -983700000,
        "Elevation (m)": 487.8,
        "First Year": 1970,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1998,
        "MLY First Year": 1970,
        "MLY Last Year": 1998
      },
      {
        "Name": "SOURIS",
        "Province": "MANITOBA",
        "Climate ID": "5012719",
        "Station ID": 3545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -100.25,
        "Latitude": 493900000,
        "Longitude": -1001500000,
        "Elevation (m)": 432.8,
        "First Year": 1982,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2006,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOURIS",
        "Province": "MANITOBA",
        "Climate ID": "5012720",
        "Station ID": 3546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -100.27,
        "Latitude": 493700000,
        "Longitude": -1001600000,
        "Elevation (m)": 426.7,
        "First Year": 1912,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1972,
        "MLY First Year": 1912,
        "MLY Last Year": 1972
      },
      {
        "Name": "SOURIS 2",
        "Province": "MANITOBA",
        "Climate ID": "5012727",
        "Station ID": 6882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -100.4,
        "Latitude": 493500000,
        "Longitude": -1002400000,
        "Elevation (m)": 428.2,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "STRATHCLAIR",
        "Province": "MANITOBA",
        "Climate ID": "5012796",
        "Station ID": 3547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -100.4,
        "Latitude": 502400000,
        "Longitude": -1002400000,
        "Elevation (m)": 579.1,
        "First Year": 1962,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2013,
        "MLY First Year": 1962,
        "MLY Last Year": 2007
      },
      {
        "Name": "SWAN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5012799",
        "Station ID": 3548,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -98.78,
        "Latitude": 492400000,
        "Longitude": -984700000,
        "Elevation (m)": 481.9,
        "First Year": 1885,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1930,
        "MLY First Year": 1885,
        "MLY Last Year": 1930
      },
      {
        "Name": "TREHERNE",
        "Province": "MANITOBA",
        "Climate ID": "5012930",
        "Station ID": 3549,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -98.7,
        "Latitude": 493800000,
        "Longitude": -984200000,
        "Elevation (m)": 373.4,
        "First Year": 1885,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1978,
        "MLY First Year": 1885,
        "MLY Last Year": 1978
      },
      {
        "Name": "TREHERNE 8N",
        "Province": "MANITOBA",
        "Climate ID": "5012931",
        "Station ID": 3550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -98.75,
        "Latitude": 494400000,
        "Longitude": -984500000,
        "Elevation (m)": 337.1,
        "First Year": 1970,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1973,
        "MLY First Year": 1970,
        "MLY Last Year": 1973
      },
      {
        "Name": "TURTLE MOUNTAIN",
        "Province": "MANITOBA",
        "Climate ID": "5012935",
        "Station ID": 3551,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -100.33,
        "Latitude": 491100000,
        "Longitude": -1002000000,
        "Elevation (m)": 655.3,
        "First Year": 1883,
        "Last Year": 1907,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1907,
        "MLY First Year": 1883,
        "MLY Last Year": 1907
      },
      {
        "Name": "TURTLE MOUNTAIN 1",
        "Province": "MANITOBA",
        "Climate ID": "5012936",
        "Station ID": 3552,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -100.55,
        "Latitude": 490300000,
        "Longitude": -1003300000,
        "Elevation (m)": 563.9,
        "First Year": 1978,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1979,
        "MLY First Year": 1978,
        "MLY Last Year": 1979
      },
      {
        "Name": "TURTLE MOUNTAIN 2",
        "Province": "MANITOBA",
        "Climate ID": "5012937",
        "Station ID": 3553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -100.78,
        "Latitude": 490500000,
        "Longitude": -1004700000,
        "Elevation (m)": 472.1,
        "First Year": 1978,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1986,
        "MLY First Year": 1978,
        "MLY Last Year": 1986
      },
      {
        "Name": "TURTLE MOUNTAIN 3",
        "Province": "MANITOBA",
        "Climate ID": "5012938",
        "Station ID": 3554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -100.7,
        "Latitude": 491100000,
        "Longitude": -1004200000,
        "Elevation (m)": 472.4,
        "First Year": 1978,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1992,
        "MLY First Year": 1978,
        "MLY Last Year": 1992
      },
      {
        "Name": "TURTLE MOUNTAIN 4",
        "Province": "MANITOBA",
        "Climate ID": "5012939",
        "Station ID": 3555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -100.73,
        "Latitude": 491900000,
        "Longitude": -1004400000,
        "Elevation (m)": 442,
        "First Year": 1978,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1986,
        "MLY First Year": 1978,
        "MLY Last Year": 1986
      },
      {
        "Name": "TURTLE MOUNTAIN 5",
        "Province": "MANITOBA",
        "Climate ID": "5012940",
        "Station ID": 3556,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -100.57,
        "Latitude": 492100000,
        "Longitude": -1003400000,
        "Elevation (m)": 464.8,
        "First Year": 1978,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1979,
        "MLY First Year": 1978,
        "MLY Last Year": 1979
      },
      {
        "Name": "TURTLE MOUNTAIN 6",
        "Province": "MANITOBA",
        "Climate ID": "5012941",
        "Station ID": 3557,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -100.32,
        "Latitude": 491800000,
        "Longitude": -1001900000,
        "Elevation (m)": 502.9,
        "First Year": 1978,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2008,
        "MLY First Year": 1978,
        "MLY Last Year": 2007
      },
      {
        "Name": "TURTLE MOUNTAIN 7",
        "Province": "MANITOBA",
        "Climate ID": "5012942",
        "Station ID": 3558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -100.07,
        "Latitude": 491900000,
        "Longitude": -1000400000,
        "Elevation (m)": 499.9,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "TURTLE MOUNTAIN 8",
        "Province": "MANITOBA",
        "Climate ID": "5012943",
        "Station ID": 3559,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -100.3,
        "Latitude": 491200000,
        "Longitude": -1001800000,
        "Elevation (m)": 533.1,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "VIRDEN",
        "Province": "MANITOBA",
        "Climate ID": "5012960",
        "Station ID": 3560,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -100.95,
        "Latitude": 495000000,
        "Longitude": -1005700000,
        "Elevation (m)": 445.4,
        "First Year": 1890,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1890,
        "DLY Last Year": 2005,
        "MLY First Year": 1890,
        "MLY Last Year": 2005
      },
      {
        "Name": "VIRDEN WATER",
        "Province": "MANITOBA",
        "Climate ID": "5012961",
        "Station ID": 47367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.84,
        "Longitude (Decimal Degrees)": -100.93,
        "Latitude": 495029500,
        "Longitude": -1005603700,
        "Elevation (m)": 439.5,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VIRDEN SCARTH",
        "Province": "MANITOBA",
        "Climate ID": "5012965",
        "Station ID": 3561,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -100.97,
        "Latitude": 494400000,
        "Longitude": -1005800000,
        "Elevation (m)": 442,
        "First Year": 1961,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1976,
        "MLY First Year": 1961,
        "MLY Last Year": 1976
      },
      {
        "Name": "WASAGAMING",
        "Province": "MANITOBA",
        "Climate ID": "5013117",
        "Station ID": 3562,
        "WMO ID": 71444,
        "TC ID": "WWP",
        "Latitude (Decimal Degrees)": 50.66,
        "Longitude (Decimal Degrees)": -99.94,
        "Latitude": 503918006,
        "Longitude": -995631008,
        "Elevation (m)": 627.4,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "WASKADA",
        "Province": "MANITOBA",
        "Climate ID": "5013119",
        "Station ID": 3563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -100.8,
        "Latitude": 490600000,
        "Longitude": -1004800000,
        "Elevation (m)": 470.9,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1988
      },
      {
        "Name": "WASKADA",
        "Province": "MANITOBA",
        "Climate ID": "5013120",
        "Station ID": 3564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -100.75,
        "Latitude": 490200000,
        "Longitude": -1004500000,
        "Elevation (m)": 469.4,
        "First Year": 1924,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1987,
        "MLY First Year": 1924,
        "MLY Last Year": 1987
      },
      {
        "Name": "WAWANESA",
        "Province": "MANITOBA",
        "Climate ID": "5013125",
        "Station ID": 3565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -99.68,
        "Latitude": 493600000,
        "Longitude": -994100000,
        "Elevation (m)": 342.9,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "WAWANESA EAST",
        "Province": "MANITOBA",
        "Climate ID": "5013130",
        "Station ID": 3566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -99.6,
        "Latitude": 493500000,
        "Longitude": -993600000,
        "Elevation (m)": 373.4,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "MELITA",
        "Province": "MANITOBA",
        "Climate ID": "501A7AR",
        "Station ID": 10185,
        "WMO ID": 71447,
        "TC ID": "WEI",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -100.99,
        "Latitude": 491652600,
        "Longitude": -1005910900,
        "Elevation (m)": 445.9,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ROBLIN FRIESEN 3 NORTHWEST",
        "Province": "MANITOBA",
        "Climate ID": "501B4G2",
        "Station ID": 6879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -101.4,
        "Latitude": 511600000,
        "Longitude": -1012400000,
        "Elevation (m)": 556.4,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "RIVERS PETTAPIECE",
        "Province": "MANITOBA",
        "Climate ID": "501BMMA",
        "Station ID": 3457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -100.18,
        "Latitude": 500629400,
        "Longitude": -1001044500,
        "Elevation (m)": 487.5,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "OAK LAKE",
        "Province": "MANITOBA",
        "Climate ID": "501K051",
        "Station ID": 3459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -100.63,
        "Latitude": 494600000,
        "Longitude": -1003800000,
        "Elevation (m)": 431,
        "First Year": 1979,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1995,
        "MLY First Year": 1979,
        "MLY Last Year": 1995
      },
      {
        "Name": "ROSSBURN 4 NORTH",
        "Province": "MANITOBA",
        "Climate ID": "501KE01",
        "Station ID": 3458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.75,
        "Longitude (Decimal Degrees)": -100.82,
        "Latitude": 504500000,
        "Longitude": -1004900000,
        "Elevation (m)": 609.6,
        "First Year": 1986,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2022,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALMASIPPI",
        "Province": "MANITOBA",
        "Climate ID": "5020036",
        "Station ID": 3571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -98.2,
        "Latitude": 493300000,
        "Longitude": -981200000,
        "Elevation (m)": 274.3,
        "First Year": 1902,
        "Last Year": 1925,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1925,
        "MLY First Year": 1902,
        "MLY Last Year": 1925
      },
      {
        "Name": "ALTONA",
        "Province": "MANITOBA",
        "Climate ID": "5020040",
        "Station ID": 3572,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -97.55,
        "Latitude": 490600000,
        "Longitude": -973300000,
        "Elevation (m)": 247.8,
        "First Year": 1948,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2008,
        "MLY First Year": 1948,
        "MLY Last Year": 2007
      },
      {
        "Name": "ALTONA CFAM",
        "Province": "MANITOBA",
        "Climate ID": "5020050",
        "Station ID": 3573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -97.55,
        "Latitude": 490600000,
        "Longitude": -973300000,
        "Elevation (m)": 247.5,
        "First Year": 1959,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1968,
        "MLY First Year": 1959,
        "MLY Last Year": 1968
      },
      {
        "Name": "ALTONA SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "5020054",
        "Station ID": 3574,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -97.57,
        "Latitude": 490300000,
        "Longitude": -973400000,
        "Elevation (m)": 249.9,
        "First Year": 1984,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1989,
        "MLY First Year": 1984,
        "MLY Last Year": 1989
      },
      {
        "Name": "ANOLA",
        "Province": "MANITOBA",
        "Climate ID": "5020069",
        "Station ID": 3575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -96.65,
        "Latitude": 495300000,
        "Longitude": -963900000,
        "Elevation (m)": 255.1,
        "First Year": 1986,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1987,
        "MLY First Year": 1986,
        "MLY Last Year": 1987
      },
      {
        "Name": "BIRDS HILL NURSERY",
        "Province": "MANITOBA",
        "Climate ID": "5020222",
        "Station ID": 3576,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -96.83,
        "Latitude": 495900000,
        "Longitude": -965000000,
        "Elevation (m)": 256,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "BIRDS HILL PINE RIDGE",
        "Province": "MANITOBA",
        "Climate ID": "5020224",
        "Station ID": 3577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -96.93,
        "Latitude": 495900000,
        "Longitude": -965600000,
        "Elevation (m)": 238.4,
        "First Year": 1988,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1992,
        "MLY First Year": 1988,
        "MLY Last Year": 1992
      },
      {
        "Name": "BOISSEVAIN",
        "Province": "MANITOBA",
        "Climate ID": "5020320",
        "Station ID": 3578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -100.05,
        "Latitude": 491400000,
        "Longitude": -1000300000,
        "Elevation (m)": 510.5,
        "First Year": 1912,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1981,
        "MLY First Year": 1912,
        "MLY Last Year": 1981
      },
      {
        "Name": "BOISSEVAIN 2",
        "Province": "MANITOBA",
        "Climate ID": "5020321",
        "Station ID": 3579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -100.08,
        "Latitude": 491300000,
        "Longitude": -1000500000,
        "Elevation (m)": 525.8,
        "First Year": 1948,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1970,
        "MLY First Year": 1948,
        "MLY Last Year": 1970
      },
      {
        "Name": "CARMAN",
        "Province": "MANITOBA",
        "Climate ID": "5020551",
        "Station ID": 3582,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -98.15,
        "Latitude": 492600000,
        "Longitude": -980900000,
        "Elevation (m)": 281.9,
        "First Year": 1964,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2014,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "CARMAN 2",
        "Province": "MANITOBA",
        "Climate ID": "5020553",
        "Station ID": 3583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -98,
        "Latitude": 493100000,
        "Longitude": -980000000,
        "Elevation (m)": 265.2,
        "First Year": 1885,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1981,
        "MLY First Year": 1885,
        "MLY Last Year": 1981
      },
      {
        "Name": "CARTWRIGHT",
        "Province": "MANITOBA",
        "Climate ID": "5020555",
        "Station ID": 3584,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -99.35,
        "Latitude": 490600000,
        "Longitude": -992100000,
        "Elevation (m)": 461.5,
        "First Year": 1883,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1976,
        "MLY First Year": 1883,
        "MLY Last Year": 1976
      },
      {
        "Name": "CARTWRIGHT",
        "Province": "MANITOBA",
        "Climate ID": "502055E",
        "Station ID": 3581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -99.42,
        "Latitude": 490300000,
        "Longitude": -992500000,
        "Elevation (m)": 472.1,
        "First Year": 1979,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1992,
        "MLY First Year": 1979,
        "MLY Last Year": 1992
      },
      {
        "Name": "CARTWRIGHT 2",
        "Province": "MANITOBA",
        "Climate ID": "50205N5",
        "Station ID": 3580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -99.52,
        "Latitude": 491000000,
        "Longitude": -993100000,
        "Elevation (m)": 467.3,
        "First Year": 1887,
        "Last Year": 1900,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1887,
        "DLY Last Year": 1900,
        "MLY First Year": 1887,
        "MLY Last Year": 1900
      },
      {
        "Name": "CRYSTAL CITY",
        "Province": "MANITOBA",
        "Climate ID": "5020625",
        "Station ID": 3585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -98.97,
        "Latitude": 490300000,
        "Longitude": -985800000,
        "Elevation (m)": 464.8,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1987,
        "MLY Last Year": 1996
      },
      {
        "Name": "DACOTAH",
        "Province": "MANITOBA",
        "Climate ID": "5020648",
        "Station ID": 3586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -97.65,
        "Latitude": 495200000,
        "Longitude": -973900000,
        "Elevation (m)": 240.8,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "DARLINGFORD",
        "Province": "MANITOBA",
        "Climate ID": "5020670",
        "Station ID": 3587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -98.27,
        "Latitude": 490700000,
        "Longitude": -981600000,
        "Elevation (m)": 457.2,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "DARLINGFORD NORTH",
        "Province": "MANITOBA",
        "Climate ID": "5020672",
        "Station ID": 3588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -98.27,
        "Latitude": 490900000,
        "Longitude": -981600000,
        "Elevation (m)": 432.2,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1983
      },
      {
        "Name": "DEERWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5020720",
        "Station ID": 3589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -98.32,
        "Latitude": 492400000,
        "Longitude": -981900000,
        "Elevation (m)": 338.3,
        "First Year": 1951,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1995,
        "MLY First Year": 1951,
        "MLY Last Year": 1995
      },
      {
        "Name": "DEERWOOD RCS",
        "Province": "MANITOBA",
        "Climate ID": "5020725",
        "Station ID": 29886,
        "WMO ID": 71840,
        "TC ID": "XDW",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -98.32,
        "Latitude": 492359400,
        "Longitude": -981909300,
        "Elevation (m)": 341.4,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "DOMAIN",
        "Province": "MANITOBA",
        "Climate ID": "5020768",
        "Station ID": 3590,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -97.32,
        "Latitude": 493700000,
        "Longitude": -971900000,
        "Elevation (m)": 237.7,
        "First Year": 1979,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1988,
        "MLY First Year": 1979,
        "MLY Last Year": 1988
      },
      {
        "Name": "DOMINION CITY",
        "Province": "MANITOBA",
        "Climate ID": "5020770",
        "Station ID": 3591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -97.15,
        "Latitude": 490900000,
        "Longitude": -970900000,
        "Elevation (m)": 239.6,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "DUFROST",
        "Province": "MANITOBA",
        "Climate ID": "5020807",
        "Station ID": 3592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -97.05,
        "Latitude": 492200000,
        "Longitude": -970300000,
        "Elevation (m)": 242.3,
        "First Year": 1968,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1971,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "DUGALD",
        "Province": "MANITOBA",
        "Climate ID": "5020810",
        "Station ID": 3593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -96.82,
        "Latitude": 495200000,
        "Longitude": -964900000,
        "Elevation (m)": 243.2,
        "First Year": 1962,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1988,
        "MLY First Year": 1962,
        "MLY Last Year": 1988
      },
      {
        "Name": "ELM CREEK",
        "Province": "MANITOBA",
        "Climate ID": "5020870",
        "Station ID": 3594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -98.02,
        "Latitude": 494300000,
        "Longitude": -980100000,
        "Elevation (m)": 252.4,
        "First Year": 1964,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1993,
        "MLY First Year": 1964,
        "MLY Last Year": 1993
      },
      {
        "Name": "EMERSON",
        "Province": "MANITOBA",
        "Climate ID": "5020880",
        "Station ID": 3595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -97.18,
        "Latitude": 490200000,
        "Longitude": -971100000,
        "Elevation (m)": 237.6,
        "First Year": 1877,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1877,
        "DLY Last Year": 1997,
        "MLY First Year": 1877,
        "MLY Last Year": 1997
      },
      {
        "Name": "EMERSON AUTO",
        "Province": "MANITOBA",
        "Climate ID": "5020881",
        "Station ID": 48068,
        "WMO ID": 71560,
        "TC ID": "WEX",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -97.24,
        "Latitude": 490000000,
        "Longitude": -971415000,
        "Elevation (m)": 242,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EMERSON AUT",
        "Province": "MANITOBA",
        "Climate ID": "5020882",
        "Station ID": 26963,
        "WMO ID": 71560,
        "TC ID": "WEX",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -97.24,
        "Latitude": 490000000,
        "Longitude": -971415000,
        "Elevation (m)": 242,
        "First Year": 1996,
        "Last Year": 2009,
        "HLY First Year": 1996,
        "HLY Last Year": 2009,
        "DLY First Year": 1997,
        "DLY Last Year": 2009,
        "MLY First Year": 1997,
        "MLY Last Year": 2007
      },
      {
        "Name": "FANNYSTELLE",
        "Province": "MANITOBA",
        "Climate ID": "5020913",
        "Station ID": 3596,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -97.87,
        "Latitude": 494800000,
        "Longitude": -975200000,
        "Elevation (m)": 243.8,
        "First Year": 1969,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2004,
        "MLY First Year": 1969,
        "MLY Last Year": 2004
      },
      {
        "Name": "FOXTON",
        "Province": "MANITOBA",
        "Climate ID": "5020980",
        "Station ID": 3597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -97.35,
        "Latitude": 502700000,
        "Longitude": -972100000,
        "Elevation (m)": 231.6,
        "First Year": 1883,
        "Last Year": 1898,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1898,
        "MLY First Year": 1883,
        "MLY Last Year": 1898
      },
      {
        "Name": "FORT WHYTE CENTRE",
        "Province": "MANITOBA",
        "Climate ID": "5020990",
        "Station ID": 32210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 494900000,
        "Longitude": -971330000,
        "Elevation (m)": 235,
        "First Year": 2002,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2004,
        "MLY First Year": 2002,
        "MLY Last Year": 2004
      },
      {
        "Name": "CARMAN 3",
        "Province": "MANITOBA",
        "Climate ID": "5020N53",
        "Station ID": 3570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -97.95,
        "Latitude": 493100000,
        "Longitude": -975700000,
        "Elevation (m)": 258.5,
        "First Year": 1984,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1987,
        "MLY First Year": 1984,
        "MLY Last Year": 1987
      },
      {
        "Name": "GLENLEA",
        "Province": "MANITOBA",
        "Climate ID": "5021054",
        "Station ID": 3599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -97.12,
        "Latitude": 493900000,
        "Longitude": -970700000,
        "Elevation (m)": 234.4,
        "First Year": 1967,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2002,
        "MLY First Year": 1967,
        "MLY Last Year": 2002
      },
      {
        "Name": "GLENORA",
        "Province": "MANITOBA",
        "Climate ID": "5021055",
        "Station ID": 3600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -99.18,
        "Latitude": 491600000,
        "Longitude": -991100000,
        "Elevation (m)": 457.2,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "GNADENTHAL",
        "Province": "MANITOBA",
        "Climate ID": "5021058",
        "Station ID": 3601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -97.8,
        "Latitude": 490600000,
        "Longitude": -974800000,
        "Elevation (m)": 262.1,
        "First Year": 1964,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1973,
        "MLY First Year": 1964,
        "MLY Last Year": 1973
      },
      {
        "Name": "GRAYSVILLE",
        "Province": "MANITOBA",
        "Climate ID": "5021160",
        "Station ID": 3602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -98.17,
        "Latitude": 493000000,
        "Longitude": -981000000,
        "Elevation (m)": 283.5,
        "First Year": 1925,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1988,
        "MLY First Year": 1925,
        "MLY Last Year": 1988
      },
      {
        "Name": "GREEN RIDGE",
        "Province": "MANITOBA",
        "Climate ID": "5021205",
        "Station ID": 3603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -96.98,
        "Latitude": 491000000,
        "Longitude": -965900000,
        "Elevation (m)": 251.5,
        "First Year": 1967,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2004,
        "MLY First Year": 1967,
        "MLY Last Year": 2004
      },
      {
        "Name": "GREENWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5021210",
        "Station ID": 3604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.45,
        "Longitude (Decimal Degrees)": -97.2,
        "Latitude": 502700000,
        "Longitude": -971200000,
        "Elevation (m)": null,
        "First Year": 1885,
        "Last Year": 1899,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1899,
        "MLY First Year": 1885,
        "MLY Last Year": 1899
      },
      {
        "Name": "GRETNA (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5021220",
        "Station ID": 3605,
        "WMO ID": 71441,
        "TC ID": "WGN",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -97.56,
        "Latitude": 490151004,
        "Longitude": -973336003,
        "Elevation (m)": 252.6,
        "First Year": 1885,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1885,
        "DLY Last Year": 2022,
        "MLY First Year": 1885,
        "MLY Last Year": 2007
      },
      {
        "Name": "GROSSE ISLE",
        "Province": "MANITOBA",
        "Climate ID": "5021225",
        "Station ID": 3606,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -97.43,
        "Latitude": 500300000,
        "Longitude": -972600000,
        "Elevation (m)": 245.1,
        "First Year": 1964,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2007,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "GUNTON HATCHERY",
        "Province": "MANITOBA",
        "Climate ID": "5021227",
        "Station ID": 3607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -97.17,
        "Latitude": 501700000,
        "Longitude": -971000000,
        "Elevation (m)": 233.2,
        "First Year": 1979,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1996,
        "MLY First Year": 1979,
        "MLY Last Year": 1996
      },
      {
        "Name": "HEADINGLY",
        "Province": "MANITOBA",
        "Climate ID": "5021285",
        "Station ID": 3608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.47,
        "Latitude": 495300000,
        "Longitude": -972800000,
        "Elevation (m)": 239.6,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "HOLLAND",
        "Province": "MANITOBA",
        "Climate ID": "5021302",
        "Station ID": 3609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.61,
        "Longitude (Decimal Degrees)": -98.88,
        "Latitude": 493622000,
        "Longitude": -985230000,
        "Elevation (m)": 374.9,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "HOMEWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5021306",
        "Station ID": 3610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -97.87,
        "Latitude": 493200000,
        "Longitude": -975200000,
        "Elevation (m)": 251.5,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "KALEIDA",
        "Province": "MANITOBA",
        "Climate ID": "5021395",
        "Station ID": 6885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -98.47,
        "Latitude": 490800000,
        "Longitude": -982800000,
        "Elevation (m)": 477.6,
        "First Year": 1993,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2000,
        "MLY First Year": 1993,
        "MLY Last Year": 2000
      },
      {
        "Name": "KILLARNEY",
        "Province": "MANITOBA",
        "Climate ID": "5021480",
        "Station ID": 3611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -99.65,
        "Latitude": 491100000,
        "Longitude": -993900000,
        "Elevation (m)": 495.6,
        "First Year": 1969,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2008,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "KLEEFELD (MAFRI)",
        "Province": "MANITOBA",
        "Climate ID": "5021500",
        "Station ID": 50897,
        "WMO ID": 71646,
        "TC ID": "PTG",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -96.9,
        "Latitude": 493104000,
        "Longitude": -965404000,
        "Elevation (m)": 246.5,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LANDMARK",
        "Province": "MANITOBA",
        "Climate ID": "5021529",
        "Station ID": 3612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -96.88,
        "Latitude": 494000000,
        "Longitude": -965300000,
        "Elevation (m)": 240.8,
        "First Year": 1980,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1993,
        "MLY First Year": 1980,
        "MLY Last Year": 1993
      },
      {
        "Name": "LETELLIER",
        "Province": "MANITOBA",
        "Climate ID": "5021565",
        "Station ID": 3613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -97.3,
        "Latitude": 490800000,
        "Longitude": -971800000,
        "Elevation (m)": 240.2,
        "First Year": 1967,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1974,
        "MLY First Year": 1967,
        "MLY Last Year": 1974
      },
      {
        "Name": "MANITOU STRANGE",
        "Province": "MANITOBA",
        "Climate ID": "5021687",
        "Station ID": 3614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -98.52,
        "Latitude": 491500000,
        "Longitude": -983100000,
        "Elevation (m)": 487.7,
        "First Year": 1981,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2006,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "MANITOU",
        "Province": "MANITOBA",
        "Climate ID": "5021688",
        "Station ID": 3615,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -98.48,
        "Latitude": 491600000,
        "Longitude": -982900000,
        "Elevation (m)": 487.7,
        "First Year": 1970,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1981,
        "MLY First Year": 1970,
        "MLY Last Year": 1981
      },
      {
        "Name": "MARCHAND",
        "Province": "MANITOBA",
        "Climate ID": "5021689",
        "Station ID": 3616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -96.38,
        "Latitude": 492700000,
        "Longitude": -962300000,
        "Elevation (m)": 304.8,
        "First Year": 1972,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1985,
        "MLY First Year": 1972,
        "MLY Last Year": 1984
      },
      {
        "Name": "MARCHAND FORESTRY",
        "Province": "MANITOBA",
        "Climate ID": "5021690",
        "Station ID": 3617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -96.27,
        "Latitude": 492600000,
        "Longitude": -961600000,
        "Elevation (m)": 310.9,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "MARCHAND W4",
        "Province": "MANITOBA",
        "Climate ID": "5021692",
        "Station ID": 3618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -96.47,
        "Latitude": 492600000,
        "Longitude": -962800000,
        "Elevation (m)": 301.8,
        "First Year": 1980,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1993,
        "MLY First Year": 1980,
        "MLY Last Year": 1993
      },
      {
        "Name": "MARQUETTE",
        "Province": "MANITOBA",
        "Climate ID": "5021695",
        "Station ID": 3619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.01,
        "Longitude (Decimal Degrees)": -97.81,
        "Latitude": 500048400,
        "Longitude": -974825700,
        "Elevation (m)": 243.8,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "MIAMI",
        "Province": "MANITOBA",
        "Climate ID": "5021732",
        "Station ID": 3620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -98.23,
        "Latitude": 492200000,
        "Longitude": -981400000,
        "Elevation (m)": 310,
        "First Year": 1933,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1936,
        "MLY First Year": 1933,
        "MLY Last Year": 1936
      },
      {
        "Name": "MIAMI HAWKEN",
        "Province": "MANITOBA",
        "Climate ID": "5021735",
        "Station ID": 3621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -98.37,
        "Latitude": 492000000,
        "Longitude": -982200000,
        "Elevation (m)": 449.6,
        "First Year": 1964,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1975,
        "MLY First Year": 1964,
        "MLY Last Year": 1975
      },
      {
        "Name": "MIAMI ORCHARD",
        "Province": "MANITOBA",
        "Climate ID": "5021736",
        "Station ID": 3622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -98.28,
        "Latitude": 492200000,
        "Longitude": -981700000,
        "Elevation (m)": 329.8,
        "First Year": 1964,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2010,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "MIAMI THIESSEN",
        "Province": "MANITOBA",
        "Climate ID": "5021737",
        "Station ID": 3623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -98.25,
        "Latitude": 492700000,
        "Longitude": -981500000,
        "Elevation (m)": 297,
        "First Year": 1964,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2016,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "MOOSE LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5021812",
        "Station ID": 3624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -95.32,
        "Latitude": 491200000,
        "Longitude": -951900000,
        "Elevation (m)": 344.4,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1983,
        "MLY First Year": 1981,
        "MLY Last Year": 1983
      },
      {
        "Name": "MORDEN",
        "Province": "MANITOBA",
        "Climate ID": "5021840",
        "Station ID": 3625,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -98.1,
        "Latitude": 491100000,
        "Longitude": -980600000,
        "Elevation (m)": 302.1,
        "First Year": 1885,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1971,
        "MLY First Year": 1885,
        "MLY Last Year": 1971
      },
      {
        "Name": "MORDEN CDA",
        "Province": "MANITOBA",
        "Climate ID": "5021848",
        "Station ID": 3626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.18,
        "Longitude (Decimal Degrees)": -98.08,
        "Latitude": 491100000,
        "Longitude": -980500000,
        "Elevation (m)": 297.5,
        "First Year": 1918,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1998,
        "MLY First Year": 1918,
        "MLY Last Year": 1998
      },
      {
        "Name": "MORDEN CDA CS",
        "Province": "MANITOBA",
        "Climate ID": "5021849",
        "Station ID": 29593,
        "WMO ID": 71564,
        "TC ID": "XMD",
        "Latitude (Decimal Degrees)": 49.19,
        "Longitude (Decimal Degrees)": -98.08,
        "Latitude": 491115500,
        "Longitude": -980502200,
        "Elevation (m)": 297.5,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "MORDEN RADIO",
        "Province": "MANITOBA",
        "Climate ID": "5021854",
        "Station ID": 3627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -98,
        "Latitude": 491500000,
        "Longitude": -980000000,
        "Elevation (m)": 276.1,
        "First Year": 1980,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1987,
        "MLY First Year": 1980,
        "MLY Last Year": 1987
      },
      {
        "Name": "MORRIS",
        "Province": "MANITOBA",
        "Climate ID": "5021920",
        "Station ID": 3628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -97.37,
        "Latitude": 492100000,
        "Longitude": -972200000,
        "Elevation (m)": 234.7,
        "First Year": 1883,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1987,
        "MLY First Year": 1883,
        "MLY Last Year": 1987
      },
      {
        "Name": "MORRIS EXP ST",
        "Province": "MANITOBA",
        "Climate ID": "5021960",
        "Station ID": 3629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -97.38,
        "Latitude": 492200000,
        "Longitude": -972300000,
        "Elevation (m)": 235.6,
        "First Year": 1954,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1959,
        "MLY First Year": 1954,
        "MLY Last Year": 1959
      },
      {
        "Name": "MORRIS 2",
        "Province": "MANITOBA",
        "Climate ID": "5021965",
        "Station ID": 3630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -97.48,
        "Latitude": 492600000,
        "Longitude": -972900000,
        "Elevation (m)": 237.7,
        "First Year": 1961,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2007,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "MYRTLE",
        "Province": "MANITOBA",
        "Climate ID": "5021975",
        "Station ID": 3631,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -97.78,
        "Latitude": 492400000,
        "Longitude": -974700000,
        "Elevation (m)": 247.8,
        "First Year": 1966,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2003,
        "MLY First Year": 1966,
        "MLY Last Year": 2003
      },
      {
        "Name": "MYRTLE 2",
        "Province": "MANITOBA",
        "Climate ID": "5021977",
        "Station ID": 3632,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -97.77,
        "Latitude": 492000000,
        "Longitude": -974600000,
        "Elevation (m)": 251.5,
        "First Year": 1968,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1999,
        "MLY First Year": 1968,
        "MLY Last Year": 1999
      },
      {
        "Name": "NAROL",
        "Province": "MANITOBA",
        "Climate ID": "5021992",
        "Station ID": 3633,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -96.97,
        "Latitude": 500300000,
        "Longitude": -965800000,
        "Elevation (m)": 230.7,
        "First Year": 1971,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1997,
        "MLY First Year": 1971,
        "MLY Last Year": 1997
      },
      {
        "Name": "MANITOU NORTH",
        "Province": "MANITOBA",
        "Climate ID": "5021F8Q",
        "Station ID": 3598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -98.52,
        "Latitude": 491600000,
        "Longitude": -983100000,
        "Elevation (m)": 487.7,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "NINETTE",
        "Province": "MANITOBA",
        "Climate ID": "5022040",
        "Station ID": 3638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -99.63,
        "Latitude": 492400000,
        "Longitude": -993800000,
        "Elevation (m)": 416.7,
        "First Year": 1885,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1978,
        "MLY First Year": 1885,
        "MLY Last Year": 1978
      },
      {
        "Name": "NINGA",
        "Province": "MANITOBA",
        "Climate ID": "5022041",
        "Station ID": 3639,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -99.85,
        "Latitude": 491400000,
        "Longitude": -995100000,
        "Elevation (m)": 502.6,
        "First Year": 1910,
        "Last Year": 1921,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1921,
        "MLY First Year": 1910,
        "MLY Last Year": 1921
      },
      {
        "Name": "NIVERVILLE",
        "Province": "MANITOBA",
        "Climate ID": "5022043",
        "Station ID": 3640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -97.05,
        "Latitude": 493600000,
        "Longitude": -970300000,
        "Elevation (m)": 236.8,
        "First Year": 1961,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1992,
        "MLY First Year": 1961,
        "MLY Last Year": 1992
      },
      {
        "Name": "OAKBANK",
        "Province": "MANITOBA",
        "Climate ID": "5022051",
        "Station ID": 3641,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -96.85,
        "Latitude": 495600000,
        "Longitude": -965100000,
        "Elevation (m)": 245.6,
        "First Year": 1885,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 2022,
        "MLY First Year": 1885,
        "MLY Last Year": 2007
      },
      {
        "Name": "OTTERBURNE",
        "Province": "MANITOBA",
        "Climate ID": "5022056",
        "Station ID": 3642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -97.05,
        "Latitude": 493000000,
        "Longitude": -970300000,
        "Elevation (m)": 238.4,
        "First Year": 1961,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1969,
        "MLY First Year": 1961,
        "MLY Last Year": 1969
      },
      {
        "Name": "PEACE GARDENS",
        "Province": "MANITOBA",
        "Climate ID": "5022065",
        "Station ID": 3643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -100.05,
        "Latitude": 490000000,
        "Longitude": -1000300000,
        "Elevation (m)": 693.4,
        "First Year": 1961,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2000,
        "MLY First Year": 1961,
        "MLY Last Year": 2000
      },
      {
        "Name": "PEMBINA CROSSING",
        "Province": "MANITOBA",
        "Climate ID": "5022068",
        "Station ID": 3644,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -98.42,
        "Latitude": 491800000,
        "Longitude": -982500000,
        "Elevation (m)": 472.4,
        "First Year": 1884,
        "Last Year": 1903,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1903,
        "MLY First Year": 1884,
        "MLY Last Year": 1902
      },
      {
        "Name": "PETERSFIELD",
        "Province": "MANITOBA",
        "Climate ID": "5022070",
        "Station ID": 3645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.32,
        "Longitude (Decimal Degrees)": -96.98,
        "Latitude": 501900000,
        "Longitude": -965900000,
        "Elevation (m)": 222.5,
        "First Year": 1960,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1977,
        "MLY First Year": 1960,
        "MLY Last Year": 1977
      },
      {
        "Name": "NINETTE COLE",
        "Province": "MANITOBA",
        "Climate ID": "50220D0",
        "Station ID": 3636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -99.63,
        "Latitude": 492400000,
        "Longitude": -993800000,
        "Elevation (m)": 419.1,
        "First Year": 1979,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1979,
        "MLY First Year": 1979,
        "MLY Last Year": 1979
      },
      {
        "Name": "NINETTE 1 NW",
        "Province": "MANITOBA",
        "Climate ID": "50220M0",
        "Station ID": 3637,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -99.65,
        "Latitude": 492500000,
        "Longitude": -993900000,
        "Elevation (m)": 419.1,
        "First Year": 1976,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1996,
        "MLY First Year": 1976,
        "MLY Last Year": 1996
      },
      {
        "Name": "PIGEON LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5022100",
        "Station ID": 3646,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -97.6,
        "Latitude": 495700000,
        "Longitude": -973600000,
        "Elevation (m)": 237.7,
        "First Year": 1988,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1998,
        "MLY First Year": 1988,
        "MLY Last Year": 1998
      },
      {
        "Name": "PILOT MOUND",
        "Province": "MANITOBA",
        "Climate ID": "5022119",
        "Station ID": 3647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -98.8,
        "Latitude": 490700000,
        "Longitude": -984800000,
        "Elevation (m)": 472.1,
        "First Year": 1885,
        "Last Year": 1899,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1899,
        "MLY First Year": 1885,
        "MLY Last Year": 1899
      },
      {
        "Name": "PILOT MOUND",
        "Province": "MANITOBA",
        "Climate ID": "5022120",
        "Station ID": 3648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -98.88,
        "Latitude": 491200000,
        "Longitude": -985300000,
        "Elevation (m)": 474.6,
        "First Year": 1883,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1957,
        "MLY First Year": 1883,
        "MLY Last Year": 1957
      },
      {
        "Name": "PILOT MOUND (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5022125",
        "Station ID": 3649,
        "WMO ID": 71148,
        "TC ID": "WPO",
        "Latitude (Decimal Degrees)": 49.19,
        "Longitude (Decimal Degrees)": -98.9,
        "Latitude": 491127700,
        "Longitude": -985417500,
        "Elevation (m)": 470.3,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1957,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "PILOT MOUND 2",
        "Province": "MANITOBA",
        "Climate ID": "5022129",
        "Station ID": 3650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -98.9,
        "Latitude": 491200000,
        "Longitude": -985400000,
        "Elevation (m)": 472.4,
        "First Year": 1987,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2005,
        "MLY First Year": 1987,
        "MLY Last Year": 2005
      },
      {
        "Name": "PINEY",
        "Province": "MANITOBA",
        "Climate ID": "5022170",
        "Station ID": 3651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -95.98,
        "Latitude": 490500000,
        "Longitude": -955900000,
        "Elevation (m)": 331,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1959,
        "MLY Last Year": 1964
      },
      {
        "Name": "PINEY",
        "Province": "MANITOBA",
        "Climate ID": "5022171",
        "Station ID": 3652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -96.01,
        "Latitude": 490151400,
        "Longitude": -960021000,
        "Elevation (m)": 325.5,
        "First Year": 1980,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2012,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "PLUM COULEE",
        "Province": "MANITOBA",
        "Climate ID": "5022245",
        "Station ID": 3653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -97.8,
        "Latitude": 490300000,
        "Longitude": -974800000,
        "Elevation (m)": 265.2,
        "First Year": 1961,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2003,
        "MLY First Year": 1961,
        "MLY Last Year": 2003
      },
      {
        "Name": "RATHWELL",
        "Province": "MANITOBA",
        "Climate ID": "5022335",
        "Station ID": 3654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -98.55,
        "Latitude": 494100000,
        "Longitude": -983300000,
        "Elevation (m)": 324.2,
        "First Year": 1967,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2001,
        "MLY First Year": 1967,
        "MLY Last Year": 2001
      },
      {
        "Name": "RICHER",
        "Province": "MANITOBA",
        "Climate ID": "5022420",
        "Station ID": 3655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -96.45,
        "Latitude": 493900000,
        "Longitude": -962700000,
        "Elevation (m)": 291.1,
        "First Year": 1979,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1998,
        "MLY First Year": 1979,
        "MLY Last Year": 1998
      },
      {
        "Name": "ROLAND",
        "Province": "MANITOBA",
        "Climate ID": "5022480",
        "Station ID": 3656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -98,
        "Latitude": 492500000,
        "Longitude": -980000000,
        "Elevation (m)": 266.7,
        "First Year": 1951,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1980,
        "MLY First Year": 1951,
        "MLY Last Year": 1980
      },
      {
        "Name": "ROSEAU RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5022488",
        "Station ID": 3657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -96.83,
        "Latitude": 491300000,
        "Longitude": -965000000,
        "Elevation (m)": 282.2,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "ROSEBANK",
        "Province": "MANITOBA",
        "Climate ID": "5022490",
        "Station ID": 3658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -98.1,
        "Latitude": 492200000,
        "Longitude": -980600000,
        "Elevation (m)": 426.7,
        "First Year": 1892,
        "Last Year": 1915,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1915,
        "MLY First Year": 1892,
        "MLY Last Year": 1915
      },
      {
        "Name": "ROSEISLE",
        "Province": "MANITOBA",
        "Climate ID": "5022491",
        "Station ID": 11004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -98.35,
        "Latitude": 493300000,
        "Longitude": -982100000,
        "Elevation (m)": 308.5,
        "First Year": 2001,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2005,
        "MLY First Year": 2001,
        "MLY Last Year": 2005
      },
      {
        "Name": "ROSE ISLE",
        "Province": "MANITOBA",
        "Climate ID": "5022492",
        "Station ID": 6886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -98.35,
        "Latitude": 493300000,
        "Longitude": -982100000,
        "Elevation (m)": 308.5,
        "First Year": 1993,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2002,
        "MLY First Year": 1993,
        "MLY Last Year": 2002
      },
      {
        "Name": "ROSSER",
        "Province": "MANITOBA",
        "Climate ID": "5022504",
        "Station ID": 3660,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -97.25,
        "Latitude": 495900000,
        "Longitude": -971500000,
        "Elevation (m)": 236.2,
        "First Year": 1964,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1969,
        "MLY First Year": 1964,
        "MLY Last Year": 1969
      },
      {
        "Name": "ST ANDREWS",
        "Province": "MANITOBA",
        "Climate ID": "5022547",
        "Station ID": 3662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -96.98,
        "Latitude": 500500000,
        "Longitude": -965900000,
        "Elevation (m)": 230.1,
        "First Year": 1874,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1992,
        "MLY First Year": 1874,
        "MLY Last Year": 1992
      },
      {
        "Name": "ST BONIFACE BENTWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5022549",
        "Station ID": 3663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -97.08,
        "Latitude": 495200000,
        "Longitude": -970500000,
        "Elevation (m)": 230.7,
        "First Year": 1970,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1980,
        "MLY First Year": 1970,
        "MLY Last Year": 1980
      },
      {
        "Name": "STE ANNE",
        "Province": "MANITOBA",
        "Climate ID": "502254Q",
        "Station ID": 3661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.67,
        "Longitude (Decimal Degrees)": -96.63,
        "Latitude": 494000000,
        "Longitude": -963800000,
        "Elevation (m)": 251.5,
        "First Year": 1979,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2000,
        "MLY First Year": 1979,
        "MLY Last Year": 2000
      },
      {
        "Name": "ST CLAUDE",
        "Province": "MANITOBA",
        "Climate ID": "5022550",
        "Station ID": 3664,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -98.37,
        "Latitude": 493900000,
        "Longitude": -982200000,
        "Elevation (m)": 310.6,
        "First Year": 1964,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2018,
        "MLY First Year": 1964,
        "MLY Last Year": 2007
      },
      {
        "Name": "STE ELIZABETH",
        "Province": "MANITOBA",
        "Climate ID": "5022553",
        "Station ID": 3665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -97.17,
        "Latitude": 491900000,
        "Longitude": -971000000,
        "Elevation (m)": 239,
        "First Year": 1973,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1989,
        "MLY First Year": 1973,
        "MLY Last Year": 1989
      },
      {
        "Name": "ST EUSTACHE",
        "Province": "MANITOBA",
        "Climate ID": "5022554",
        "Station ID": 3666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -97.78,
        "Latitude": 495800000,
        "Longitude": -974700000,
        "Elevation (m)": 243.2,
        "First Year": 1989,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1991,
        "MLY First Year": 1989,
        "MLY Last Year": 1991
      },
      {
        "Name": "ST JEAN BAPTISTE",
        "Province": "MANITOBA",
        "Climate ID": "5022567",
        "Station ID": 6887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -97.4,
        "Latitude": 491600000,
        "Longitude": -972400000,
        "Elevation (m)": 237.7,
        "First Year": 1993,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1997,
        "MLY First Year": 1993,
        "MLY Last Year": 1997
      },
      {
        "Name": "ST LABRE",
        "Province": "MANITOBA",
        "Climate ID": "5022575",
        "Station ID": 3667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -96.04,
        "Latitude": 492109000,
        "Longitude": -960206000,
        "Elevation (m)": 352,
        "First Year": 1981,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST ANDREWS A",
        "Province": "MANITOBA",
        "Climate ID": "50225DP",
        "Station ID": 3659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -97.03,
        "Latitude": 500300000,
        "Longitude": -970200000,
        "Elevation (m)": 231.65,
        "First Year": 1976,
        "Last Year": 1985,
        "HLY First Year": 1976,
        "HLY Last Year": 1985,
        "DLY First Year": 1976,
        "DLY Last Year": 1985,
        "MLY First Year": 1976,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST MALO",
        "Province": "MANITOBA",
        "Climate ID": "5022601",
        "Station ID": 3668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -96.95,
        "Latitude": 491900000,
        "Longitude": -965700000,
        "Elevation (m)": 263,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST NORBERT",
        "Province": "MANITOBA",
        "Climate ID": "5022603",
        "Station ID": 3669,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -97.15,
        "Latitude": 494600000,
        "Longitude": -970900000,
        "Elevation (m)": 231.6,
        "First Year": 1970,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1976,
        "MLY First Year": 1970,
        "MLY Last Year": 1976
      },
      {
        "Name": "ST PIERRE JOLYS",
        "Province": "MANITOBA",
        "Climate ID": "5022606",
        "Station ID": 6888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.46,
        "Longitude (Decimal Degrees)": -97.01,
        "Latitude": 492742080,
        "Longitude": -970049000,
        "Elevation (m)": 281,
        "First Year": 1993,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2005,
        "MLY First Year": 1993,
        "MLY Last Year": 2005
      },
      {
        "Name": "SCHANZENFELD",
        "Province": "MANITOBA",
        "Climate ID": "5022623",
        "Station ID": 6889,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -97.95,
        "Latitude": 490800000,
        "Longitude": -975700000,
        "Elevation (m)": 277.4,
        "First Year": 1991,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2022,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "SELKIRK",
        "Province": "MANITOBA",
        "Climate ID": "5022630",
        "Station ID": 3670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -96.87,
        "Latitude": 501002000,
        "Longitude": -965205000,
        "Elevation (m)": 226,
        "First Year": 1963,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2008,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "SNOWFLAKE",
        "Province": "MANITOBA",
        "Climate ID": "5022703",
        "Station ID": 3671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -98.65,
        "Latitude": 490400000,
        "Longitude": -983900000,
        "Elevation (m)": 474,
        "First Year": 1987,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2011,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPERLING",
        "Province": "MANITOBA",
        "Climate ID": "5022740",
        "Station ID": 3672,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -97.7,
        "Latitude": 493000000,
        "Longitude": -974200000,
        "Elevation (m)": 243.2,
        "First Year": 1969,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2003,
        "MLY First Year": 1969,
        "MLY Last Year": 2003
      },
      {
        "Name": "SPRAGUE",
        "Province": "MANITOBA",
        "Climate ID": "5022759",
        "Station ID": 10196,
        "WMO ID": 71449,
        "TC ID": "WSU",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -95.6,
        "Latitude": 490125000,
        "Longitude": -953554009,
        "Elevation (m)": 328.8,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "SPRAGUE",
        "Province": "MANITOBA",
        "Climate ID": "5022760",
        "Station ID": 3673,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -95.6,
        "Latitude": 490100000,
        "Longitude": -953600000,
        "Elevation (m)": 329.2,
        "First Year": 1915,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1998,
        "MLY First Year": 1915,
        "MLY Last Year": 1998
      },
      {
        "Name": "STARBUCK",
        "Province": "MANITOBA",
        "Climate ID": "5022770",
        "Station ID": 3674,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -97.62,
        "Latitude": 494400000,
        "Longitude": -973700000,
        "Elevation (m)": 237.7,
        "First Year": 1962,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2004,
        "MLY First Year": 1962,
        "MLY Last Year": 2004
      },
      {
        "Name": "STEINBACH",
        "Province": "MANITOBA",
        "Climate ID": "5022780",
        "Station ID": 3675,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -96.77,
        "Latitude": 493200000,
        "Longitude": -964600000,
        "Elevation (m)": 253.6,
        "First Year": 1956,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2005,
        "MLY First Year": 1956,
        "MLY Last Year": 2005
      },
      {
        "Name": "STONEWALL",
        "Province": "MANITOBA",
        "Climate ID": "5022788",
        "Station ID": 3676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -97.33,
        "Latitude": 500700000,
        "Longitude": -972000000,
        "Elevation (m)": 251.5,
        "First Year": 1959,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1991,
        "MLY First Year": 1959,
        "MLY Last Year": 1991
      },
      {
        "Name": "STONY MOUNTAIN",
        "Province": "MANITOBA",
        "Climate ID": "5022790",
        "Station ID": 3677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 500400000,
        "Longitude": -971400000,
        "Elevation (m)": 244.8,
        "First Year": 1878,
        "Last Year": 1937,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1919,
        "MLY First Year": 1878,
        "MLY Last Year": 1937
      },
      {
        "Name": "STONY MOUNTAIN",
        "Province": "MANITOBA",
        "Climate ID": "5022791",
        "Station ID": 3678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -97.17,
        "Latitude": 500700000,
        "Longitude": -971000000,
        "Elevation (m)": 236.2,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "STUARTBURN",
        "Province": "MANITOBA",
        "Climate ID": "5022797",
        "Station ID": 6890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -96.78,
        "Latitude": 490800000,
        "Longitude": -964700000,
        "Elevation (m)": 289.6,
        "First Year": 1993,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2003,
        "MLY First Year": 1993,
        "MLY Last Year": 2003
      },
      {
        "Name": "SUNDOWN",
        "Province": "MANITOBA",
        "Climate ID": "5022798",
        "Station ID": 3679,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -96.23,
        "Latitude": 490100000,
        "Longitude": -961400000,
        "Elevation (m)": 320,
        "First Year": 1980,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2000,
        "MLY First Year": 1980,
        "MLY Last Year": 2000
      },
      {
        "Name": "TEULON",
        "Province": "MANITOBA",
        "Climate ID": "5022855",
        "Station ID": 3680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -97.25,
        "Latitude": 502500000,
        "Longitude": -971500000,
        "Elevation (m)": 266.7,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "TOLSTOI",
        "Province": "MANITOBA",
        "Climate ID": "5022928",
        "Station ID": 6891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -96.8,
        "Latitude": 490400000,
        "Longitude": -964800000,
        "Elevation (m)": 291,
        "First Year": 1993,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1994,
        "MLY First Year": 1993,
        "MLY Last Year": 1994
      },
      {
        "Name": "TURTLE MOUNTAIN 10",
        "Province": "MANITOBA",
        "Climate ID": "5022945",
        "Station ID": 3681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -99.93,
        "Latitude": 490500000,
        "Longitude": -995600000,
        "Elevation (m)": 579.1,
        "First Year": 1978,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1986,
        "MLY First Year": 1978,
        "MLY Last Year": 1986
      },
      {
        "Name": "TURTLE MOUNTAIN 11",
        "Province": "MANITOBA",
        "Climate ID": "5022946",
        "Station ID": 3682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -100.12,
        "Latitude": 490800000,
        "Longitude": -1000700000,
        "Elevation (m)": 579.1,
        "First Year": 1978,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2005,
        "MLY First Year": 1978,
        "MLY Last Year": 2005
      },
      {
        "Name": "TURTLE MOUNTAIN 12",
        "Province": "MANITOBA",
        "Climate ID": "5022947",
        "Station ID": 3683,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -100.23,
        "Latitude": 490600000,
        "Longitude": -1001400000,
        "Elevation (m)": 669,
        "First Year": 1979,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1985,
        "MLY First Year": 1979,
        "MLY Last Year": 1985
      },
      {
        "Name": "STE ANNE SE5",
        "Province": "MANITOBA",
        "Climate ID": "5022EDQ",
        "Station ID": 3634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -96.6,
        "Latitude": 493800000,
        "Longitude": -963600000,
        "Elevation (m)": 257.9,
        "First Year": 1979,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1982,
        "MLY First Year": 1979,
        "MLY Last Year": 1982
      },
      {
        "Name": "SNOWFLAKE 4NE",
        "Province": "MANITOBA",
        "Climate ID": "5022G04",
        "Station ID": 3635,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -98.65,
        "Latitude": 490500000,
        "Longitude": -983900000,
        "Elevation (m)": 474.6,
        "First Year": 1987,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1995,
        "MLY First Year": 1987,
        "MLY Last Year": 1995
      },
      {
        "Name": "VITA",
        "Province": "MANITOBA",
        "Climate ID": "5023000",
        "Station ID": 3684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -96.57,
        "Latitude": 490800000,
        "Longitude": -963400000,
        "Elevation (m)": 301.8,
        "First Year": 1953,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1995,
        "MLY First Year": 1953,
        "MLY Last Year": 1995
      },
      {
        "Name": "VITA",
        "Province": "MANITOBA",
        "Climate ID": "5023001",
        "Station ID": 3685,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -96.55,
        "Latitude": 490500000,
        "Longitude": -963300000,
        "Elevation (m)": 304.8,
        "First Year": 1979,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1983,
        "MLY First Year": 1979,
        "MLY Last Year": 1983
      },
      {
        "Name": "WARREN",
        "Province": "MANITOBA",
        "Climate ID": "5023110",
        "Station ID": 3687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -97.63,
        "Latitude": 500800000,
        "Longitude": -973800000,
        "Elevation (m)": 248.4,
        "First Year": 1924,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1991,
        "MLY First Year": 1924,
        "MLY Last Year": 1991
      },
      {
        "Name": "WHITEMOUTH LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5023149",
        "Station ID": 3688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -95.75,
        "Latitude": 491700000,
        "Longitude": -954500000,
        "Elevation (m)": 350.5,
        "First Year": 1972,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1981,
        "MLY First Year": 1972,
        "MLY Last Year": 1981
      },
      {
        "Name": "WINNIPEG ANDERSON AVE",
        "Province": "MANITOBA",
        "Climate ID": "5023170",
        "Station ID": 3689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.15,
        "Latitude": 495500000,
        "Longitude": -970900000,
        "Elevation (m)": null,
        "First Year": 1955,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1956,
        "MLY First Year": 1955,
        "MLY Last Year": 1955
      },
      {
        "Name": "WINNIPEG ASSINIBOINE PARK",
        "Province": "MANITOBA",
        "Climate ID": "5023174",
        "Station ID": 3690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 495300000,
        "Longitude": -971400000,
        "Elevation (m)": 228.6,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "WINNIPEG BLUMBERG GOLF COURSE",
        "Province": "MANITOBA",
        "Climate ID": "5023178",
        "Station ID": 3691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -97.35,
        "Latitude": 495200000,
        "Longitude": -972100000,
        "Elevation (m)": 236.2,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "WINNIPEG BROOKLANDS",
        "Province": "MANITOBA",
        "Climate ID": "5023180",
        "Station ID": 3692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -97.18,
        "Latitude": 495000000,
        "Longitude": -971100000,
        "Elevation (m)": null,
        "First Year": 1955,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1955,
        "MLY First Year": 1955,
        "MLY Last Year": 1955
      },
      {
        "Name": "WINNIPEG CHARLESWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5023190",
        "Station ID": 3693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -97.28,
        "Latitude": 495100000,
        "Longitude": -971700000,
        "Elevation (m)": 234.7,
        "First Year": 1955,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1966,
        "MLY First Year": 1955,
        "MLY Last Year": 1966
      },
      {
        "Name": "WINNIPEG CHARLESWOOD LAGOON",
        "Province": "MANITOBA",
        "Climate ID": "50231R0",
        "Station ID": 3686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -97.33,
        "Latitude": 495000000,
        "Longitude": -972000000,
        "Elevation (m)": 236.5,
        "First Year": 1964,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "WINNIPEG CLARE AVE",
        "Province": "MANITOBA",
        "Climate ID": "5023200",
        "Station ID": 3695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -97.13,
        "Latitude": 495200000,
        "Longitude": -970800000,
        "Elevation (m)": 232.6,
        "First Year": 1955,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1958,
        "MLY First Year": 1955,
        "MLY Last Year": 1958
      },
      {
        "Name": "WINNIPEG E KILDONAN",
        "Province": "MANITOBA",
        "Climate ID": "5023210",
        "Station ID": 3696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -97.1,
        "Latitude": 495600000,
        "Longitude": -970600000,
        "Elevation (m)": 231.6,
        "First Year": 1955,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1959,
        "MLY First Year": 1955,
        "MLY Last Year": 1959
      },
      {
        "Name": "WINNIPEG FLORA AVE",
        "Province": "MANITOBA",
        "Climate ID": "5023220",
        "Station ID": 3697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.15,
        "Latitude": 495500000,
        "Longitude": -970900000,
        "Elevation (m)": 232.6,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "WINNIPEG RICHARDSON INT'L A",
        "Province": "MANITOBA",
        "Climate ID": "5023222",
        "Station ID": 3698,
        "WMO ID": 71852,
        "TC ID": "YWG",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 495500000,
        "Longitude": -971400000,
        "Elevation (m)": 238.7,
        "First Year": 1938,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1938,
        "DLY Last Year": 2008,
        "MLY First Year": 1938,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEG A FIRE HALL",
        "Province": "MANITOBA",
        "Climate ID": "5023223",
        "Station ID": 27525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -97.25,
        "Latitude": 495400000,
        "Longitude": -971500000,
        "Elevation (m)": 238.9,
        "First Year": 1997,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2002,
        "MLY First Year": 1997,
        "MLY Last Year": 2002
      },
      {
        "Name": "WINNIPEG HANGARLINE ROAD",
        "Province": "MANITOBA",
        "Climate ID": "5023224",
        "Station ID": 27000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 495500000,
        "Longitude": -971400000,
        "Elevation (m)": 238.7,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1997,
        "MLY First Year": 1994,
        "MLY Last Year": 1997
      },
      {
        "Name": "WINNIPEG CHARLESWOOD 2",
        "Province": "MANITOBA",
        "Climate ID": "5023225",
        "Station ID": 43185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -97.27,
        "Latitude": 495100000,
        "Longitude": -971621000,
        "Elevation (m)": 238,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEG RICHARDSON AWOS",
        "Province": "MANITOBA",
        "Climate ID": "5023226",
        "Station ID": 47407,
        "WMO ID": 71852,
        "TC ID": "YWG",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 495500000,
        "Longitude": -971400000,
        "Elevation (m)": 238.7,
        "First Year": 2008,
        "Last Year": 2013,
        "HLY First Year": 2008,
        "HLY Last Year": 2013,
        "DLY First Year": 2008,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WINNIPEG INTL A",
        "Province": "MANITOBA",
        "Climate ID": "5023227",
        "Station ID": 51097,
        "WMO ID": null,
        "TC ID": "YWG",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -97.24,
        "Latitude": 495436000,
        "Longitude": -971424000,
        "Elevation (m)": 238.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WINNIPEG PERTH",
        "Province": "MANITOBA",
        "Climate ID": "5023228",
        "Station ID": 3699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.13,
        "Latitude": 495500000,
        "Longitude": -970800000,
        "Elevation (m)": 234.1,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "WINNIPEG ST BONIFACE",
        "Province": "MANITOBA",
        "Climate ID": "5023230",
        "Station ID": 3700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.1,
        "Latitude": 495300000,
        "Longitude": -970600000,
        "Elevation (m)": 232,
        "First Year": 1955,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1959,
        "MLY First Year": 1955,
        "MLY Last Year": 1959
      },
      {
        "Name": "WINNIPEG ST BONIFACE WW",
        "Province": "MANITOBA",
        "Climate ID": "5023233",
        "Station ID": 3701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.1,
        "Latitude": 495300000,
        "Longitude": -970600000,
        "Elevation (m)": 232,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "WINNIPEG ST JAMES",
        "Province": "MANITOBA",
        "Climate ID": "5023240",
        "Station ID": 3702,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.27,
        "Latitude": 495300000,
        "Longitude": -971600000,
        "Elevation (m)": null,
        "First Year": 1955,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1956,
        "MLY First Year": 1955,
        "MLY Last Year": 1956
      },
      {
        "Name": "WINNIPEG ST JOHNS COLL",
        "Province": "MANITOBA",
        "Climate ID": "5023243",
        "Station ID": 3703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.12,
        "Latitude": 495300000,
        "Longitude": -970700000,
        "Elevation (m)": 231.6,
        "First Year": 1872,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1938,
        "MLY First Year": 1872,
        "MLY Last Year": 1938
      },
      {
        "Name": "WINNIPEG SHERBROOK ST",
        "Province": "MANITOBA",
        "Climate ID": "5023250",
        "Station ID": 3704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.15,
        "Latitude": 495300000,
        "Longitude": -970900000,
        "Elevation (m)": 232.9,
        "First Year": 1955,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1963,
        "MLY First Year": 1955,
        "MLY Last Year": 1963
      },
      {
        "Name": "WINNIPEG SHERBURN ST",
        "Province": "MANITOBA",
        "Climate ID": "5023260",
        "Station ID": 3705,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -97.18,
        "Latitude": 495400000,
        "Longitude": -971100000,
        "Elevation (m)": 232.9,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "WINNIPEG STP",
        "Province": "MANITOBA",
        "Climate ID": "5023261",
        "Station ID": 3706,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.95,
        "Longitude (Decimal Degrees)": -97.1,
        "Latitude": 495700000,
        "Longitude": -970600000,
        "Elevation (m)": 232.6,
        "First Year": 1960,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1989,
        "MLY First Year": 1960,
        "MLY Last Year": 1989
      },
      {
        "Name": "WINNIPEG THE FORKS",
        "Province": "MANITOBA",
        "Climate ID": "5023262",
        "Station ID": 28051,
        "WMO ID": 71579,
        "TC ID": "XWN",
        "Latitude (Decimal Degrees)": 49.89,
        "Longitude (Decimal Degrees)": -97.13,
        "Latitude": 495318008,
        "Longitude": -970746007,
        "Elevation (m)": 230,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEG TRANSCONA",
        "Province": "MANITOBA",
        "Climate ID": "5023263",
        "Station ID": 3707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 495500000,
        "Longitude": -970100000,
        "Elevation (m)": 235,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "WINNIPEG U OF MANITOBA",
        "Province": "MANITOBA",
        "Climate ID": "5023265",
        "Station ID": 3708,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -97.25,
        "Latitude": 495100000,
        "Longitude": -971500000,
        "Elevation (m)": 234.7,
        "First Year": 1939,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1949,
        "MLY First Year": 1939,
        "MLY Last Year": 1949
      },
      {
        "Name": "WINNIPEG U OF MANITOBA",
        "Province": "MANITOBA",
        "Climate ID": "5023270",
        "Station ID": 3709,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -97.13,
        "Latitude": 494800000,
        "Longitude": -970800000,
        "Elevation (m)": 234.7,
        "First Year": 1961,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1968,
        "MLY First Year": 1961,
        "MLY Last Year": 1968
      },
      {
        "Name": "WINNIPEG WINDSOR GOLF COURSE",
        "Province": "MANITOBA",
        "Climate ID": "5023274",
        "Station ID": 3710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -97.08,
        "Latitude": 495200000,
        "Longitude": -970500000,
        "Elevation (m)": 230.1,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "WINNIPEG W KILDONAN",
        "Province": "MANITOBA",
        "Climate ID": "5023280",
        "Station ID": 3711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.13,
        "Latitude": 495500000,
        "Longitude": -970800000,
        "Elevation (m)": null,
        "First Year": 1955,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1955,
        "MLY First Year": 1955,
        "MLY Last Year": 1955
      },
      {
        "Name": "WINNIPEG GRACE HOSPITAL",
        "Province": "MANITOBA",
        "Climate ID": "5023281",
        "Station ID": 43186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -97.28,
        "Latitude": 495255000,
        "Longitude": -971641000,
        "Elevation (m)": 241,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2006,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "WINNIPEG CANGENE",
        "Province": "MANITOBA",
        "Climate ID": "5023282",
        "Station ID": 43187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -97.15,
        "Latitude": 494815000,
        "Longitude": -970844000,
        "Elevation (m)": 233,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEG WTC STAPON",
        "Province": "MANITOBA",
        "Climate ID": "5023283",
        "Station ID": 43193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -97.06,
        "Latitude": 495402000,
        "Longitude": -970353000,
        "Elevation (m)": 234,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEG FED LAB",
        "Province": "MANITOBA",
        "Climate ID": "5023284",
        "Station ID": 43194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -97.16,
        "Latitude": 495429000,
        "Longitude": -970948000,
        "Elevation (m)": 230,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "WOODRIDGE",
        "Province": "MANITOBA",
        "Climate ID": "5023295",
        "Station ID": 3712,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -96.15,
        "Latitude": 491700000,
        "Longitude": -960900000,
        "Elevation (m)": 381,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "WINNIPEG FORT WHYTE",
        "Province": "MANITOBA",
        "Climate ID": "50232KJ",
        "Station ID": 3694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -97.23,
        "Latitude": 494900000,
        "Longitude": -971400000,
        "Elevation (m)": 234.7,
        "First Year": 1985,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1988,
        "MLY First Year": 1985,
        "MLY Last Year": 1988
      },
      {
        "Name": "ZHODA",
        "Province": "MANITOBA",
        "Climate ID": "5023370",
        "Station ID": 3713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -96.52,
        "Latitude": 491500000,
        "Longitude": -963100000,
        "Elevation (m)": 304.8,
        "First Year": 1980,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2000,
        "MLY First Year": 1980,
        "MLY Last Year": 2000
      },
      {
        "Name": "WINNIPEG HURST RESERVOIR",
        "Province": "MANITOBA",
        "Climate ID": "502C2JM",
        "Station ID": 3568,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -97.18,
        "Latitude": 495100000,
        "Longitude": -971100000,
        "Elevation (m)": 235.6,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "WINNIPEG MCPHILLIPS SLEDGE BEDS",
        "Province": "MANITOBA",
        "Climate ID": "502CKBD",
        "Station ID": 3567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -97.12,
        "Latitude": 494900000,
        "Longitude": -970700000,
        "Elevation (m)": 288.6,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "CARMAN U OF M CS",
        "Province": "MANITOBA",
        "Climate ID": "502I001",
        "Station ID": 26857,
        "WMO ID": 71147,
        "TC ID": "WNK",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -98.03,
        "Latitude": 492953200,
        "Longitude": -980147100,
        "Elevation (m)": 268.2,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEG PACIFIC AVENUE",
        "Province": "MANITOBA",
        "Climate ID": "502I002",
        "Station ID": 27170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.17,
        "Latitude": 495500000,
        "Longitude": -971000000,
        "Elevation (m)": 234.7,
        "First Year": 1996,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1999,
        "MLY First Year": 1996,
        "MLY Last Year": 1999
      },
      {
        "Name": "STONEWALL",
        "Province": "MANITOBA",
        "Climate ID": "502KGHG",
        "Station ID": 6883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -97.32,
        "Latitude": 501300000,
        "Longitude": -971900000,
        "Elevation (m)": 248.4,
        "First Year": 1992,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1994,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "POPLAR POINT",
        "Province": "MANITOBA",
        "Climate ID": "502KKNL",
        "Station ID": 6884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -98.9,
        "Latitude": 500300000,
        "Longitude": -985400000,
        "Elevation (m)": 245.4,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "WHITE HORSE PLAINS",
        "Province": "MANITOBA",
        "Climate ID": "502LA48",
        "Station ID": 3569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.93,
        "Longitude (Decimal Degrees)": -97.6,
        "Latitude": 495600000,
        "Longitude": -973600000,
        "Elevation (m)": 237.4,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1989,
        "MLY First Year": 1988,
        "MLY Last Year": 1989
      },
      {
        "Name": "WINNIPEG SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "502M001",
        "Station ID": 27230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -97.13,
        "Latitude": 494700000,
        "Longitude": -970800000,
        "Elevation (m)": 232,
        "First Year": 1996,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2005,
        "MLY First Year": 1996,
        "MLY Last Year": 2005
      },
      {
        "Name": "WINNIPEG A CS",
        "Province": "MANITOBA",
        "Climate ID": "502S001",
        "Station ID": 27174,
        "WMO ID": 71849,
        "TC ID": "XWG",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -97.25,
        "Latitude": 495500000,
        "Longitude": -971458000,
        "Elevation (m)": 238.7,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARBORG",
        "Province": "MANITOBA",
        "Climate ID": "5030080",
        "Station ID": 3717,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -97.08,
        "Latitude": 505600000,
        "Longitude": -970500000,
        "Elevation (m)": 224,
        "First Year": 1951,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2016,
        "MLY First Year": 1951,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARNES",
        "Province": "MANITOBA",
        "Climate ID": "5030109",
        "Station ID": 10206,
        "WMO ID": null,
        "TC ID": "WRE",
        "Latitude (Decimal Degrees)": 50.8,
        "Longitude (Decimal Degrees)": -96.95,
        "Latitude": 504800000,
        "Longitude": -965700000,
        "Elevation (m)": 221.1,
        "First Year": 1994,
        "Last Year": 2000,
        "HLY First Year": 1994,
        "HLY Last Year": 2000,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAUSEJOUR",
        "Province": "MANITOBA",
        "Climate ID": "5030155",
        "Station ID": 3718,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -96.22,
        "Latitude": 500800000,
        "Longitude": -961300000,
        "Elevation (m)": 302.4,
        "First Year": 1959,
        "Last Year": 1985,
        "HLY First Year": 1959,
        "HLY Last Year": 1985,
        "DLY First Year": 1960,
        "DLY Last Year": 1979,
        "MLY First Year": 1960,
        "MLY Last Year": 1979
      },
      {
        "Name": "BEAUSEJOUR 2",
        "Province": "MANITOBA",
        "Climate ID": "5030160",
        "Station ID": 3719,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -96.47,
        "Latitude": 500200000,
        "Longitude": -962800000,
        "Elevation (m)": 251.2,
        "First Year": 1953,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 2005,
        "MLY First Year": 1953,
        "MLY Last Year": 2005
      },
      {
        "Name": "BERENS RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5030200",
        "Station ID": 3720,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.35,
        "Longitude (Decimal Degrees)": -97.03,
        "Latitude": 522100000,
        "Longitude": -970200000,
        "Elevation (m)": 217.3,
        "First Year": 1905,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1964,
        "MLY First Year": 1905,
        "MLY Last Year": 1964
      },
      {
        "Name": "BERENS RIVER A",
        "Province": "MANITOBA",
        "Climate ID": "5030201",
        "Station ID": 53321,
        "WMO ID": null,
        "TC ID": "YBV",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 522132000,
        "Longitude": -970105000,
        "Elevation (m)": 222.2,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BERENS RIVER A",
        "Province": "MANITOBA",
        "Climate ID": "5030202",
        "Station ID": 26964,
        "WMO ID": null,
        "TC ID": "YBV",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 522132000,
        "Longitude": -970106000,
        "Elevation (m)": 222.19,
        "First Year": 1995,
        "Last Year": 2015,
        "HLY First Year": 1995,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BERENS RIVER CS",
        "Province": "MANITOBA",
        "Climate ID": "5030203",
        "Station ID": 3721,
        "WMO ID": 71158,
        "TC ID": "WCF",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 522135052,
        "Longitude": -970119032,
        "Elevation (m)": 221.9,
        "First Year": 1985,
        "Last Year": 2022,
        "HLY First Year": 1985,
        "HLY Last Year": 2022,
        "DLY First Year": 1985,
        "DLY Last Year": 2022,
        "MLY First Year": 1985,
        "MLY Last Year": 2007
      },
      {
        "Name": "BERENS RIVER A",
        "Province": "MANITOBA",
        "Climate ID": "5030204",
        "Station ID": 53318,
        "WMO ID": null,
        "TC ID": "YBV",
        "Latitude (Decimal Degrees)": 52.36,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 522132000,
        "Longitude": -970105000,
        "Elevation (m)": 222.2,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BISSETT",
        "Province": "MANITOBA",
        "Climate ID": "5030280",
        "Station ID": 3722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -95.63,
        "Latitude": 505800000,
        "Longitude": -953800000,
        "Elevation (m)": null,
        "First Year": 1933,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1952,
        "MLY First Year": 1933,
        "MLY Last Year": 1952
      },
      {
        "Name": "BISSETT",
        "Province": "MANITOBA",
        "Climate ID": "5030282",
        "Station ID": 3723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -95.7,
        "Latitude": 510200000,
        "Longitude": -954200000,
        "Elevation (m)": 259,
        "First Year": 1968,
        "Last Year": 1997,
        "HLY First Year": 1968,
        "HLY Last Year": 1985,
        "DLY First Year": 1968,
        "DLY Last Year": 1997,
        "MLY First Year": 1968,
        "MLY Last Year": 1997
      },
      {
        "Name": "BRAINTREE 1",
        "Province": "MANITOBA",
        "Climate ID": "5030334",
        "Station ID": 3724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -95.57,
        "Latitude": 493800000,
        "Longitude": -953400000,
        "Elevation (m)": 315.5,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "BRAINTREE 2",
        "Province": "MANITOBA",
        "Climate ID": "5030335",
        "Station ID": 3725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -95.62,
        "Latitude": 493600000,
        "Longitude": -953700000,
        "Elevation (m)": 315.5,
        "First Year": 1983,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1995,
        "MLY First Year": 1983,
        "MLY Last Year": 1995
      },
      {
        "Name": "BROAD VALLEY",
        "Province": "MANITOBA",
        "Climate ID": "5030510",
        "Station ID": 3726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -97.47,
        "Latitude": 505900000,
        "Longitude": -972800000,
        "Elevation (m)": 259.1,
        "First Year": 1970,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1995,
        "MLY First Year": 1970,
        "MLY Last Year": 1995
      },
      {
        "Name": "BROKENHEAD",
        "Province": "MANITOBA",
        "Climate ID": "5030523",
        "Station ID": 3727,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -96.52,
        "Latitude": 501600000,
        "Longitude": -963100000,
        "Elevation (m)": 228.6,
        "First Year": 1981,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1984,
        "MLY First Year": 1981,
        "MLY Last Year": 1984
      },
      {
        "Name": "CHANNEL ISLAND",
        "Province": "MANITOBA",
        "Climate ID": "5030556",
        "Station ID": 3728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -97.38,
        "Latitude": 521800000,
        "Longitude": -972300000,
        "Elevation (m)": 216.4,
        "First Year": 1886,
        "Last Year": 1904,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1904,
        "MLY First Year": 1886,
        "MLY Last Year": 1904
      },
      {
        "Name": "CHATFIELD",
        "Province": "MANITOBA",
        "Climate ID": "5030559",
        "Station ID": 3729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.82,
        "Longitude (Decimal Degrees)": -97.5,
        "Latitude": 504900000,
        "Longitude": -973000000,
        "Elevation (m)": 265.8,
        "First Year": 1969,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1995,
        "MLY First Year": 1969,
        "MLY Last Year": 1995
      },
      {
        "Name": "ELMA",
        "Province": "MANITOBA",
        "Climate ID": "5030869",
        "Station ID": 3730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -95.93,
        "Latitude": 494600000,
        "Longitude": -955600000,
        "Elevation (m)": 292.7,
        "First Year": 1978,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1992,
        "MLY First Year": 1978,
        "MLY Last Year": 1992
      },
      {
        "Name": "FALCON LAKE TCPL 45",
        "Province": "MANITOBA",
        "Climate ID": "5030912",
        "Station ID": 3731,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -95.37,
        "Latitude": 494100000,
        "Longitude": -952200000,
        "Elevation (m)": 338.3,
        "First Year": 1969,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1997,
        "MLY First Year": 1969,
        "MLY Last Year": 1997
      },
      {
        "Name": "FISHER BRANCH",
        "Province": "MANITOBA",
        "Climate ID": "5030916",
        "Station ID": 3732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -97.55,
        "Latitude": 510600000,
        "Longitude": -973300000,
        "Elevation (m)": 246.9,
        "First Year": 1970,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2005,
        "MLY First Year": 1970,
        "MLY Last Year": 2005
      },
      {
        "Name": "FISHER BRANCH SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "5030917",
        "Station ID": 3733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.05,
        "Longitude (Decimal Degrees)": -97.53,
        "Latitude": 510310030,
        "Longitude": -973142000,
        "Elevation (m)": 259.1,
        "First Year": 1977,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2009,
        "MLY First Year": 1977,
        "MLY Last Year": 2007
      },
      {
        "Name": "FRASERWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5030982",
        "Station ID": 3734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -97.22,
        "Latitude": 503800000,
        "Longitude": -971300000,
        "Elevation (m)": 256,
        "First Year": 1969,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1989,
        "MLY First Year": 1969,
        "MLY Last Year": 1989
      },
      {
        "Name": "GEORGE ISLAND",
        "Province": "MANITOBA",
        "Climate ID": "5030983",
        "Station ID": 3735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -97.63,
        "Latitude": 524800000,
        "Longitude": -973800000,
        "Elevation (m)": 219.5,
        "First Year": 1965,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1983,
        "MLY First Year": 1966,
        "MLY Last Year": 1983
      },
      {
        "Name": "GEORGE ISLAND (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5030984",
        "Station ID": 7333,
        "WMO ID": 71445,
        "TC ID": "WWS",
        "Latitude (Decimal Degrees)": 52.82,
        "Longitude (Decimal Degrees)": -97.62,
        "Latitude": 524907014,
        "Longitude": -973659028,
        "Elevation (m)": 222.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "FISHER BRANCH (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "50309J6",
        "Station ID": 8993,
        "WMO ID": 71442,
        "TC ID": "WSZ",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -97.55,
        "Latitude": 510500100,
        "Longitude": -973316200,
        "Elevation (m)": 253,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "GIMLI",
        "Province": "MANITOBA",
        "Climate ID": "5031038",
        "Station ID": 3736,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 503800000,
        "Longitude": -970100000,
        "Elevation (m)": 222.8,
        "First Year": 1972,
        "Last Year": 1991,
        "HLY First Year": 1972,
        "HLY Last Year": 1991,
        "DLY First Year": 1972,
        "DLY Last Year": 1991,
        "MLY First Year": 1972,
        "MLY Last Year": 1991
      },
      {
        "Name": "GIMLI",
        "Province": "MANITOBA",
        "Climate ID": "5031039",
        "Station ID": 6892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -97.02,
        "Latitude": 503800000,
        "Longitude": -970100000,
        "Elevation (m)": 222.8,
        "First Year": 1993,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2008,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "GIMLI A",
        "Province": "MANITOBA",
        "Climate ID": "5031040",
        "Station ID": 3737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -97.05,
        "Latitude": 503800000,
        "Longitude": -970300000,
        "Elevation (m)": 222.2,
        "First Year": 1944,
        "Last Year": 1971,
        "HLY First Year": 1953,
        "HLY Last Year": 1971,
        "DLY First Year": 1944,
        "DLY Last Year": 1971,
        "MLY First Year": 1944,
        "MLY Last Year": 1971
      },
      {
        "Name": "GIMLI HARBOUR CS",
        "Province": "MANITOBA",
        "Climate ID": "5031041",
        "Station ID": 27867,
        "WMO ID": 71577,
        "TC ID": "XGH",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -96.98,
        "Latitude": 503752000,
        "Longitude": -965856000,
        "Elevation (m)": 217.02,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "GIMLI CLIMATE",
        "Province": "MANITOBA",
        "Climate ID": "5031042",
        "Station ID": 45007,
        "WMO ID": 71748,
        "TC ID": "PGH",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -97.05,
        "Latitude": 503800000,
        "Longitude": -970300000,
        "Elevation (m)": 230.1,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "GIMLI WRB",
        "Province": "MANITOBA",
        "Climate ID": "5031043",
        "Station ID": 3738,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -97.03,
        "Latitude": 503700000,
        "Longitude": -970200000,
        "Elevation (m)": 221,
        "First Year": 1964,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1964,
        "MLY First Year": 1964,
        "MLY Last Year": 1964
      },
      {
        "Name": "GIMLI INDUSTRIAL PARK",
        "Province": "MANITOBA",
        "Climate ID": "50310D0",
        "Station ID": 10728,
        "WMO ID": null,
        "TC ID": "YGM",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -97.05,
        "Latitude": 503800000,
        "Longitude": -970300000,
        "Elevation (m)": 230.1,
        "First Year": 1992,
        "Last Year": 2010,
        "HLY First Year": 1994,
        "HLY Last Year": 2010,
        "DLY First Year": 1992,
        "DLY Last Year": 2010,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRAND BEACH PARK",
        "Province": "MANITOBA",
        "Climate ID": "5031102",
        "Station ID": 3739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.55,
        "Longitude (Decimal Degrees)": -96.58,
        "Latitude": 503300000,
        "Longitude": -963500000,
        "Elevation (m)": 225.6,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "GRAND RAPIDS",
        "Province": "MANITOBA",
        "Climate ID": "5031110",
        "Station ID": 3740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.18,
        "Longitude (Decimal Degrees)": -99.27,
        "Latitude": 531100000,
        "Longitude": -991600000,
        "Elevation (m)": 222.5,
        "First Year": 1960,
        "Last Year": 1979,
        "HLY First Year": 1970,
        "HLY Last Year": 1979,
        "DLY First Year": 1960,
        "DLY Last Year": 1979,
        "MLY First Year": 1960,
        "MLY Last Year": 1979
      },
      {
        "Name": "GRAND RAPIDS HYDRO",
        "Province": "MANITOBA",
        "Climate ID": "5031111",
        "Station ID": 3741,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.16,
        "Longitude (Decimal Degrees)": -99.28,
        "Latitude": 530929001,
        "Longitude": -991700040,
        "Elevation (m)": 222.5,
        "First Year": 1966,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2019,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "GREAT FALLS",
        "Province": "MANITOBA",
        "Climate ID": "5031200",
        "Station ID": 3742,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -96,
        "Latitude": 502800000,
        "Longitude": -960000000,
        "Elevation (m)": 248.7,
        "First Year": 1922,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2003,
        "MLY First Year": 1922,
        "MLY Last Year": 2002
      },
      {
        "Name": "GREAT FALLS CLIMATE",
        "Province": "MANITOBA",
        "Climate ID": "5031201",
        "Station ID": 43243,
        "WMO ID": 71570,
        "TC ID": "PGF",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -95.98,
        "Latitude": 503120001,
        "Longitude": -955838000,
        "Elevation (m)": 253,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "HECLA ISLAND (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5031288",
        "Station ID": 7291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -96.63,
        "Latitude": 511000000,
        "Longitude": -963800000,
        "Elevation (m)": 230,
        "First Year": 1977,
        "Last Year": 1985,
        "HLY First Year": 1977,
        "HLY Last Year": 1985,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HNAUSA",
        "Province": "MANITOBA",
        "Climate ID": "5031298",
        "Station ID": 3743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.93,
        "Longitude (Decimal Degrees)": -97,
        "Latitude": 505600000,
        "Longitude": -970000000,
        "Elevation (m)": 225.2,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "HODGSON",
        "Province": "MANITOBA",
        "Climate ID": "5031300",
        "Station ID": 3744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -97.58,
        "Latitude": 511200000,
        "Longitude": -973500000,
        "Elevation (m)": 231,
        "First Year": 1960,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1967,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "HODGSON 2",
        "Province": "MANITOBA",
        "Climate ID": "5031301",
        "Station ID": 3745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.19,
        "Longitude (Decimal Degrees)": -97.47,
        "Latitude": 511109900,
        "Longitude": -972754500,
        "Elevation (m)": 231.7,
        "First Year": 1966,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2014,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "HODGSON 3",
        "Province": "MANITOBA",
        "Climate ID": "5031310",
        "Station ID": 52858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.21,
        "Longitude (Decimal Degrees)": -97.57,
        "Latitude": 511241090,
        "Longitude": -973405050,
        "Elevation (m)": 231.7,
        "First Year": 2014,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2014,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INDIAN BAY",
        "Province": "MANITOBA",
        "Climate ID": "5031320",
        "Station ID": 3746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -95.2,
        "Latitude": 493726000,
        "Longitude": -951150000,
        "Elevation (m)": 326.7,
        "First Year": 1915,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 2022,
        "MLY First Year": 1915,
        "MLY Last Year": 2007
      },
      {
        "Name": "INWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5031369",
        "Station ID": 6951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -97.5,
        "Latitude": 503000000,
        "Longitude": -973000000,
        "Elevation (m)": 276.5,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "LA BROQUERIE",
        "Province": "MANITOBA",
        "Climate ID": "5031511",
        "Station ID": 6893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -96.53,
        "Latitude": 492900000,
        "Longitude": -963200000,
        "Elevation (m)": 290.2,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "LA BROQUERIE",
        "Province": "MANITOBA",
        "Climate ID": "5031512",
        "Station ID": 30621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -96.52,
        "Latitude": 492844000,
        "Longitude": -963100027,
        "Elevation (m)": 289.9,
        "First Year": 2000,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2004,
        "MLY First Year": 2000,
        "MLY Last Year": 2004
      },
      {
        "Name": "MATHESON ISLAND",
        "Province": "MANITOBA",
        "Climate ID": "5031700",
        "Station ID": 3747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -96.9,
        "Latitude": 514300000,
        "Longitude": -965400000,
        "Elevation (m)": 221,
        "First Year": 1963,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1966
      },
      {
        "Name": "MOSS SPUR",
        "Province": "MANITOBA",
        "Climate ID": "503196F",
        "Station ID": 3748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -96.13,
        "Latitude": 495900000,
        "Longitude": -960800000,
        "Elevation (m)": 283.8,
        "First Year": 1978,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1987,
        "MLY First Year": 1978,
        "MLY Last Year": 1987
      },
      {
        "Name": "NARCISSE",
        "Province": "MANITOBA",
        "Climate ID": "5031991",
        "Station ID": 3749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.67,
        "Longitude (Decimal Degrees)": -97.65,
        "Latitude": 504000000,
        "Longitude": -973900000,
        "Elevation (m)": 271.3,
        "First Year": 1982,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2014,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRAND RAPIDS (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5031A10",
        "Station ID": 7290,
        "WMO ID": 71858,
        "TC ID": "WJD",
        "Latitude (Decimal Degrees)": 53.19,
        "Longitude (Decimal Degrees)": -99.27,
        "Latitude": 531111064,
        "Longitude": -991604098,
        "Elevation (m)": 222.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINAWA",
        "Province": "MANITOBA",
        "Climate ID": "5032160",
        "Station ID": 3750,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -95.92,
        "Latitude": 501300000,
        "Longitude": -955500000,
        "Elevation (m)": 259.1,
        "First Year": 1915,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1951,
        "MLY First Year": 1915,
        "MLY Last Year": 1951
      },
      {
        "Name": "PINAWA CANWARN",
        "Province": "MANITOBA",
        "Climate ID": "5032161",
        "Station ID": 43620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -95.89,
        "Latitude": 500851000,
        "Longitude": -955325000,
        "Elevation (m)": 277.7,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINAWA WNRE",
        "Province": "MANITOBA",
        "Climate ID": "5032162",
        "Station ID": 3751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -96.06,
        "Latitude": 501050000,
        "Longitude": -960330000,
        "Elevation (m)": 266.7,
        "First Year": 1963,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2017,
        "MLY First Year": 1963,
        "MLY Last Year": 2007
      },
      {
        "Name": "PINE DOCK",
        "Province": "MANITOBA",
        "Climate ID": "5032163",
        "Station ID": 3752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -96.8,
        "Latitude": 513800000,
        "Longitude": -964800000,
        "Elevation (m)": 219.5,
        "First Year": 1966,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1997,
        "MLY First Year": 1966,
        "MLY Last Year": 1997
      },
      {
        "Name": "PINE FALLS",
        "Province": "MANITOBA",
        "Climate ID": "5032164",
        "Station ID": 3753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.57,
        "Longitude (Decimal Degrees)": -96.22,
        "Latitude": 503400000,
        "Longitude": -961300000,
        "Elevation (m)": 231,
        "First Year": 1959,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1994,
        "MLY First Year": 1959,
        "MLY Last Year": 1994
      },
      {
        "Name": "POINTE DU BOIS",
        "Province": "MANITOBA",
        "Climate ID": "5032249",
        "Station ID": 3754,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -95.55,
        "Latitude": 501800000,
        "Longitude": -953300000,
        "Elevation (m)": 300.2,
        "First Year": 1967,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1972,
        "MLY First Year": 1967,
        "MLY Last Year": 1972
      },
      {
        "Name": "RENNIE",
        "Province": "MANITOBA",
        "Climate ID": "5032360",
        "Station ID": 3755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -95.53,
        "Latitude": 495100000,
        "Longitude": -953159000,
        "Elevation (m)": 323.1,
        "First Year": 1948,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2022,
        "MLY First Year": 1948,
        "MLY Last Year": 2007
      },
      {
        "Name": "RIVERTON",
        "Province": "MANITOBA",
        "Climate ID": "5032443",
        "Station ID": 3756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -96.98,
        "Latitude": 505900000,
        "Longitude": -965900000,
        "Elevation (m)": 220.4,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "ROSS",
        "Province": "MANITOBA",
        "Climate ID": "5032495",
        "Station ID": 3757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -96.43,
        "Latitude": 494600000,
        "Longitude": -962600000,
        "Elevation (m)": 274.3,
        "First Year": 1967,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1977,
        "MLY First Year": 1967,
        "MLY Last Year": 1977
      },
      {
        "Name": "STE GENEVIEVE",
        "Province": "MANITOBA",
        "Climate ID": "5032560",
        "Station ID": 3758,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -96.6,
        "Latitude": 494500000,
        "Longitude": -963600000,
        "Elevation (m)": 256,
        "First Year": 1950,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1995,
        "MLY First Year": 1950,
        "MLY Last Year": 1995
      },
      {
        "Name": "SEVEN SISTERS FALLS",
        "Province": "MANITOBA",
        "Climate ID": "5032640",
        "Station ID": 3759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -96.02,
        "Latitude": 500700000,
        "Longitude": -960100000,
        "Elevation (m)": 266.7,
        "First Year": 1950,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1970,
        "MLY First Year": 1950,
        "MLY Last Year": 1970
      },
      {
        "Name": "SEVEN SISTERS OYES",
        "Province": "MANITOBA",
        "Climate ID": "5032641",
        "Station ID": 3760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.1,
        "Longitude (Decimal Degrees)": -95.93,
        "Latitude": 500600000,
        "Longitude": -955600000,
        "Elevation (m)": 274.3,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "SPRUCE TCPL 43",
        "Province": "MANITOBA",
        "Climate ID": "5032764",
        "Station ID": 3761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -96.17,
        "Latitude": 493900000,
        "Longitude": -961000000,
        "Elevation (m)": 310.3,
        "First Year": 1969,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1983,
        "MLY First Year": 1969,
        "MLY Last Year": 1983
      },
      {
        "Name": "VICTORIA BEACH (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5032951",
        "Station ID": 9035,
        "WMO ID": 71552,
        "TC ID": "WII",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -96.57,
        "Latitude": 504200000,
        "Longitude": -963400000,
        "Elevation (m)": 220.1,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2006
      },
      {
        "Name": "NORTH JULIUS",
        "Province": "MANITOBA",
        "Climate ID": "503B0MF",
        "Station ID": 3714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -96.18,
        "Latitude": 500400000,
        "Longitude": -961100000,
        "Elevation (m)": 290.2,
        "First Year": 1986,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1992,
        "MLY First Year": 1986,
        "MLY Last Year": 1992
      },
      {
        "Name": "OSTENFELD",
        "Province": "MANITOBA",
        "Climate ID": "503B0NE",
        "Station ID": 3715,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -96.48,
        "Latitude": 494900000,
        "Longitude": -962900000,
        "Elevation (m)": 274.3,
        "First Year": 1973,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2005,
        "MLY First Year": 1973,
        "MLY Last Year": 2005
      },
      {
        "Name": "PINAWA",
        "Province": "MANITOBA",
        "Climate ID": "503B1ER",
        "Station ID": 10186,
        "WMO ID": 71448,
        "TC ID": "WEA",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -96.06,
        "Latitude": 501038020,
        "Longitude": -960353000,
        "Elevation (m)": 268,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "HODGSON 6N",
        "Province": "MANITOBA",
        "Climate ID": "503JC00",
        "Station ID": 3716,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -97.57,
        "Latitude": 511800000,
        "Longitude": -973400000,
        "Elevation (m)": 243.8,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "ALONSA",
        "Province": "MANITOBA",
        "Climate ID": "5040037",
        "Station ID": 3769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -99.05,
        "Latitude": 505000000,
        "Longitude": -990300000,
        "Elevation (m)": 274.3,
        "First Year": 1980,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2007,
        "MLY First Year": 1980,
        "MLY Last Year": 2007
      },
      {
        "Name": "AMARANTH",
        "Province": "MANITOBA",
        "Climate ID": "5040060",
        "Station ID": 3770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -98.72,
        "Latitude": 503800000,
        "Longitude": -984300000,
        "Elevation (m)": 259.1,
        "First Year": 1980,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2004,
        "MLY First Year": 1980,
        "MLY Last Year": 2004
      },
      {
        "Name": "ASHERN",
        "Province": "MANITOBA",
        "Climate ID": "5040120",
        "Station ID": 3771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -98.37,
        "Latitude": 510800000,
        "Longitude": -982200000,
        "Elevation (m)": 263,
        "First Year": 1951,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1991,
        "MLY First Year": 1951,
        "MLY Last Year": 1991
      },
      {
        "Name": "ASHVILLE",
        "Province": "MANITOBA",
        "Climate ID": "5040121",
        "Station ID": 3772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -100.28,
        "Latitude": 511700000,
        "Longitude": -1001700000,
        "Elevation (m)": 320,
        "First Year": 1973,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1979,
        "MLY First Year": 1973,
        "MLY Last Year": 1979
      },
      {
        "Name": "AUSTIN",
        "Province": "MANITOBA",
        "Climate ID": "5040128",
        "Station ID": 3773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -98.98,
        "Latitude": 495400000,
        "Longitude": -985900000,
        "Elevation (m)": 327.7,
        "First Year": 1989,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1995,
        "MLY First Year": 1989,
        "MLY Last Year": 1995
      },
      {
        "Name": "BACHELORS ISLAND MARINE",
        "Province": "MANITOBA",
        "Climate ID": "5040131",
        "Station ID": 10882,
        "WMO ID": 71143,
        "TC ID": "WBL",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -99.9,
        "Latitude": 514500000,
        "Longitude": -995400000,
        "Elevation (m)": 255.9,
        "First Year": 1994,
        "Last Year": 2012,
        "HLY First Year": 1994,
        "HLY Last Year": 2012,
        "DLY First Year": 1994,
        "DLY Last Year": 2012,
        "MLY First Year": 1996,
        "MLY Last Year": 2004
      },
      {
        "Name": "BASSWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5040146",
        "Station ID": 6894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -100.03,
        "Latitude": 501500000,
        "Longitude": -1000200000,
        "Elevation (m)": 582.8,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": 1992,
        "MLY Last Year": 1996
      },
      {
        "Name": "BEAVER",
        "Province": "MANITOBA",
        "Climate ID": "5040165",
        "Station ID": 3774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -98.7,
        "Latitude": 500100000,
        "Longitude": -984200000,
        "Elevation (m)": 262.7,
        "First Year": 1887,
        "Last Year": 1907,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1887,
        "DLY Last Year": 1907,
        "MLY First Year": 1887,
        "MLY Last Year": 1907
      },
      {
        "Name": "BEAVER",
        "Province": "MANITOBA",
        "Climate ID": "5040166",
        "Station ID": 3775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -98.7,
        "Latitude": 500300000,
        "Longitude": -984200000,
        "Elevation (m)": 268.2,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "BIG VALLEY",
        "Province": "MANITOBA",
        "Climate ID": "5040214",
        "Station ID": 3776,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -99.45,
        "Latitude": 502800000,
        "Longitude": -992700000,
        "Elevation (m)": 381,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "BIRCH RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5040218",
        "Station ID": 3777,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.52,
        "Longitude (Decimal Degrees)": -100.92,
        "Latitude": 523100000,
        "Longitude": -1005500000,
        "Elevation (m)": 267.92,
        "First Year": 1970,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2000,
        "MLY First Year": 1970,
        "MLY Last Year": 2000
      },
      {
        "Name": "BIRNIE",
        "Province": "MANITOBA",
        "Climate ID": "5040230",
        "Station ID": 3778,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -99.48,
        "Latitude": 503000000,
        "Longitude": -992900000,
        "Elevation (m)": 432.8,
        "First Year": 1979,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1983,
        "MLY First Year": 1979,
        "MLY Last Year": 1983
      },
      {
        "Name": "DAUPHIN",
        "Province": "MANITOBA",
        "Climate ID": "5040675",
        "Station ID": 3779,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.15,
        "Longitude (Decimal Degrees)": -100.03,
        "Latitude": 510900000,
        "Longitude": -1000200000,
        "Elevation (m)": 291.7,
        "First Year": 1890,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1890,
        "DLY Last Year": 1997,
        "MLY First Year": 1890,
        "MLY Last Year": 1997
      },
      {
        "Name": "DAUPHIN A",
        "Province": "MANITOBA",
        "Climate ID": "5040680",
        "Station ID": 3780,
        "WMO ID": null,
        "TC ID": "PDH",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -100.05,
        "Latitude": 510600000,
        "Longitude": -1000300000,
        "Elevation (m)": 304.5,
        "First Year": 1942,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1942,
        "DLY Last Year": 2010,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAUPHIN CS",
        "Province": "MANITOBA",
        "Climate ID": "5040681",
        "Station ID": 26866,
        "WMO ID": 71550,
        "TC ID": "WZT",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -100.06,
        "Latitude": 510601400,
        "Longitude": -1000324800,
        "Elevation (m)": 304.5,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "DAUPHIN NORTH",
        "Province": "MANITOBA",
        "Climate ID": "5040684",
        "Station ID": 3781,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -100.03,
        "Latitude": 511000000,
        "Longitude": -1000200000,
        "Elevation (m)": 289.6,
        "First Year": 1983,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1985,
        "MLY First Year": 1983,
        "MLY Last Year": 1985
      },
      {
        "Name": "DAUPHIN SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "5040687",
        "Station ID": 3782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -100.07,
        "Latitude": 510100000,
        "Longitude": -1000400000,
        "Elevation (m)": 374.9,
        "First Year": 1985,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1987,
        "MLY First Year": 1985,
        "MLY Last Year": 1987
      },
      {
        "Name": "DAUPHIN",
        "Province": "MANITOBA",
        "Climate ID": "5040689",
        "Station ID": 48868,
        "WMO ID": 71036,
        "TC ID": "YDN",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -100.05,
        "Latitude": 510603000,
        "Longitude": -1000309000,
        "Elevation (m)": 304.5,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DELTA MARSH CS",
        "Province": "MANITOBA",
        "Climate ID": "5040764",
        "Station ID": 3783,
        "WMO ID": 71563,
        "TC ID": "ZUG",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -98.38,
        "Latitude": 501057500,
        "Longitude": -982256100,
        "Elevation (m)": 248.4,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": 1997,
        "HLY Last Year": 2011,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "DELTA WATERFOWL RS",
        "Province": "MANITOBA",
        "Climate ID": "5040765",
        "Station ID": 3784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -98.3,
        "Latitude": 501100000,
        "Longitude": -981800000,
        "Elevation (m)": 247.5,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "DUCK BAY",
        "Province": "MANITOBA",
        "Climate ID": "5040789",
        "Station ID": 5009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.12,
        "Longitude (Decimal Degrees)": -100.2,
        "Latitude": 520700000,
        "Longitude": -1001200000,
        "Elevation (m)": 253.8,
        "First Year": 1991,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1994,
        "MLY First Year": 1991,
        "MLY Last Year": 1994
      },
      {
        "Name": "DURBAN CDA EPF",
        "Province": "MANITOBA",
        "Climate ID": "5040840",
        "Station ID": 3785,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.93,
        "Longitude (Decimal Degrees)": -101.4,
        "Latitude": 515600000,
        "Longitude": -1012400000,
        "Elevation (m)": 419.1,
        "First Year": 1954,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1977,
        "MLY First Year": 1954,
        "MLY Last Year": 1977
      },
      {
        "Name": "EDDYSTONE",
        "Province": "MANITOBA",
        "Climate ID": "5040854",
        "Station ID": 3786,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -99.15,
        "Latitude": 510500000,
        "Longitude": -990900000,
        "Elevation (m)": 262.1,
        "First Year": 1971,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1971,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "ERIKSDALE",
        "Province": "MANITOBA",
        "Climate ID": "5040895",
        "Station ID": 3787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -98.23,
        "Latitude": 505000000,
        "Longitude": -981400000,
        "Elevation (m)": 259.4,
        "First Year": 1959,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1992,
        "MLY First Year": 1959,
        "MLY Last Year": 1992
      },
      {
        "Name": "ERIKSDALE 1",
        "Province": "MANITOBA",
        "Climate ID": "5040896",
        "Station ID": 3788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -98.13,
        "Latitude": 505200000,
        "Longitude": -980800000,
        "Elevation (m)": 268.2,
        "First Year": 1978,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1988,
        "MLY First Year": 1978,
        "MLY Last Year": 1988
      },
      {
        "Name": "ERIKSDALE 2",
        "Province": "MANITOBA",
        "Climate ID": "5040897",
        "Station ID": 3789,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -98,
        "Latitude": 505300000,
        "Longitude": -980000000,
        "Elevation (m)": 267.3,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "ETHELBERT",
        "Province": "MANITOBA",
        "Climate ID": "5040899",
        "Station ID": 3790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -100.38,
        "Latitude": 513200000,
        "Longitude": -1002300000,
        "Elevation (m)": 343,
        "First Year": 1983,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2009,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "FAIRFORD",
        "Province": "MANITOBA",
        "Climate ID": "5040910",
        "Station ID": 3791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -98.67,
        "Latitude": 513500000,
        "Longitude": -984000000,
        "Elevation (m)": 253,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "FORK RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5040973",
        "Station ID": 3792,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.52,
        "Longitude (Decimal Degrees)": -100.02,
        "Latitude": 513100000,
        "Longitude": -1000100000,
        "Elevation (m)": 266.7,
        "First Year": 1982,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2001,
        "MLY First Year": 1982,
        "MLY Last Year": 2001
      },
      {
        "Name": "GILBERT PLAINS",
        "Province": "MANITOBA",
        "Climate ID": "5040985",
        "Station ID": 3793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.1,
        "Longitude (Decimal Degrees)": -100.47,
        "Latitude": 510600000,
        "Longitude": -1002800000,
        "Elevation (m)": 403.9,
        "First Year": 1934,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 2016,
        "MLY First Year": 1934,
        "MLY Last Year": 2007
      },
      {
        "Name": "GILBERT PLAINS 2",
        "Province": "MANITOBA",
        "Climate ID": "5040986",
        "Station ID": 3794,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.17,
        "Longitude (Decimal Degrees)": -100.48,
        "Latitude": 511000000,
        "Longitude": -1002900000,
        "Elevation (m)": 388.9,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "CAYER",
        "Province": "MANITOBA",
        "Climate ID": "5040EN5",
        "Station ID": 3765,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.25,
        "Longitude (Decimal Degrees)": -99.08,
        "Latitude": 511500000,
        "Longitude": -990500000,
        "Elevation (m)": 259.1,
        "First Year": 1972,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1981,
        "MLY First Year": 1972,
        "MLY Last Year": 1981
      },
      {
        "Name": "COWAN",
        "Province": "MANITOBA",
        "Climate ID": "5040FJ3",
        "Station ID": 3766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.03,
        "Longitude (Decimal Degrees)": -100.65,
        "Latitude": 520200000,
        "Longitude": -1003900000,
        "Elevation (m)": 365.8,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "ASHERN NORTHWEST",
        "Province": "MANITOBA",
        "Climate ID": "5040JK0",
        "Station ID": 3767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -98.38,
        "Latitude": 511200000,
        "Longitude": -982300000,
        "Elevation (m)": 266.7,
        "First Year": 1989,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2001,
        "MLY First Year": 1989,
        "MLY Last Year": 2001
      },
      {
        "Name": "FORK RIVER NORTH",
        "Province": "MANITOBA",
        "Climate ID": "5040R73",
        "Station ID": 3768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.57,
        "Longitude (Decimal Degrees)": -99.98,
        "Latitude": 513400000,
        "Longitude": -995900000,
        "Elevation (m)": 259.7,
        "First Year": 1982,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2002,
        "MLY First Year": 1982,
        "MLY Last Year": 2002
      },
      {
        "Name": "GLADSTONE",
        "Province": "MANITOBA",
        "Climate ID": "5041049",
        "Station ID": 3797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -98.95,
        "Latitude": 501300000,
        "Longitude": -985700000,
        "Elevation (m)": 270.4,
        "First Year": 1990,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1998,
        "MLY First Year": 1990,
        "MLY Last Year": 1998
      },
      {
        "Name": "GLADSTONE",
        "Province": "MANITOBA",
        "Climate ID": "5041050",
        "Station ID": 3798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -98.97,
        "Latitude": 502200000,
        "Longitude": -985800000,
        "Elevation (m)": 271.3,
        "First Year": 1961,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1976,
        "MLY First Year": 1961,
        "MLY Last Year": 1976
      },
      {
        "Name": "GLADSTONE SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "50410N0",
        "Station ID": 3796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -99.02,
        "Latitude": 501100000,
        "Longitude": -990100000,
        "Elevation (m)": 281.9,
        "First Year": 1973,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1990,
        "MLY First Year": 1973,
        "MLY Last Year": 1990
      },
      {
        "Name": "GRANDVIEW",
        "Province": "MANITOBA",
        "Climate ID": "5041116",
        "Station ID": 3799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.12,
        "Longitude (Decimal Degrees)": -100.7,
        "Latitude": 510700000,
        "Longitude": -1004200000,
        "Elevation (m)": 442,
        "First Year": 1977,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2003,
        "MLY First Year": 1977,
        "MLY Last Year": 2003
      },
      {
        "Name": "GRANDVIEW CDA EPF",
        "Province": "MANITOBA",
        "Climate ID": "5041120",
        "Station ID": 3800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -100.8,
        "Latitude": 511100000,
        "Longitude": -1004800000,
        "Elevation (m)": 464.5,
        "First Year": 1951,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1970,
        "MLY First Year": 1951,
        "MLY Last Year": 1970
      },
      {
        "Name": "GRASS RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5041140",
        "Station ID": 3801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.52,
        "Longitude (Decimal Degrees)": -98.97,
        "Latitude": 503100000,
        "Longitude": -985800000,
        "Elevation (m)": 269.7,
        "First Year": 1958,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1997,
        "MLY First Year": 1958,
        "MLY Last Year": 1997
      },
      {
        "Name": "GYPSUMVILLE",
        "Province": "MANITOBA",
        "Climate ID": "5041229",
        "Station ID": 3802,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.67,
        "Longitude (Decimal Degrees)": -98.73,
        "Latitude": 514000000,
        "Longitude": -984400000,
        "Elevation (m)": 265.2,
        "First Year": 1966,
        "Last Year": 1987,
        "HLY First Year": 1966,
        "HLY Last Year": 1987,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "GYPSUMVILLE 2",
        "Province": "MANITOBA",
        "Climate ID": "5041230",
        "Station ID": 3803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -98.67,
        "Latitude": 514400000,
        "Longitude": -984000000,
        "Elevation (m)": 260.3,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "GYPSUMVILLE EAST",
        "Province": "MANITOBA",
        "Climate ID": "5041233",
        "Station ID": 3804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -98.53,
        "Latitude": 514400000,
        "Longitude": -983200000,
        "Elevation (m)": 246.9,
        "First Year": 1982,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1982,
        "MLY First Year": 1982,
        "MLY Last Year": 1982
      },
      {
        "Name": "GYPSUMVILLE OLENDER",
        "Province": "MANITOBA",
        "Climate ID": "5041237",
        "Station ID": 3805,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -98.63,
        "Latitude": 514600000,
        "Longitude": -983800000,
        "Elevation (m)": 252.1,
        "First Year": 1982,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1984,
        "MLY First Year": 1982,
        "MLY Last Year": 1984
      },
      {
        "Name": "KATRIME EXP ST",
        "Province": "MANITOBA",
        "Climate ID": "5041400",
        "Station ID": 3806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -98.68,
        "Latitude": 500500000,
        "Longitude": -984100000,
        "Elevation (m)": 270.4,
        "First Year": 1951,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1960,
        "MLY First Year": 1951,
        "MLY Last Year": 1960
      },
      {
        "Name": "KELWOOD",
        "Province": "MANITOBA",
        "Climate ID": "5041425",
        "Station ID": 3807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.62,
        "Longitude (Decimal Degrees)": -99.47,
        "Latitude": 503700000,
        "Longitude": -992800000,
        "Elevation (m)": 352,
        "First Year": 1959,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1993,
        "MLY First Year": 1959,
        "MLY Last Year": 1993
      },
      {
        "Name": "KENVILLE EXP ST",
        "Province": "MANITOBA",
        "Climate ID": "5041440",
        "Station ID": 3808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52,
        "Longitude (Decimal Degrees)": -101.32,
        "Latitude": 520000000,
        "Longitude": -1011900000,
        "Elevation (m)": 387.4,
        "First Year": 1951,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1960,
        "MLY First Year": 1951,
        "MLY Last Year": 1960
      },
      {
        "Name": "KERGWENAN",
        "Province": "MANITOBA",
        "Climate ID": "5041450",
        "Station ID": 3809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -99.53,
        "Latitude": 505700000,
        "Longitude": -993200000,
        "Elevation (m)": 280.4,
        "First Year": 1982,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1986,
        "MLY First Year": 1982,
        "MLY Last Year": 1986
      },
      {
        "Name": "LAKE DAUPHIN",
        "Province": "MANITOBA",
        "Climate ID": "5041523",
        "Station ID": 3810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -99.55,
        "Latitude": 511200000,
        "Longitude": -993300000,
        "Elevation (m)": 263.7,
        "First Year": 1984,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1989,
        "MLY First Year": 1984,
        "MLY Last Year": 1989
      },
      {
        "Name": "LAKELAND",
        "Province": "MANITOBA",
        "Climate ID": "5041525",
        "Station ID": 3811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -98.67,
        "Latitude": 501600000,
        "Longitude": -984000000,
        "Elevation (m)": 256,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "LAKE MANITOBA NARROWS",
        "Province": "MANITOBA",
        "Climate ID": "5041527",
        "Station ID": 3812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.08,
        "Longitude (Decimal Degrees)": -98.78,
        "Latitude": 510500000,
        "Longitude": -984700000,
        "Elevation (m)": 251.5,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1978,
        "MLY Last Year": 1978
      },
      {
        "Name": "LANGRUTH",
        "Province": "MANITOBA",
        "Climate ID": "5041530",
        "Station ID": 3813,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -98.55,
        "Latitude": 502400000,
        "Longitude": -983300000,
        "Elevation (m)": 250,
        "First Year": 1970,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2001,
        "MLY First Year": 1970,
        "MLY Last Year": 2001
      },
      {
        "Name": "LANGRUTH WEST",
        "Province": "MANITOBA",
        "Climate ID": "5041535",
        "Station ID": 31088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.41,
        "Longitude (Decimal Degrees)": -98.8,
        "Latitude": 502450000,
        "Longitude": -984810000,
        "Elevation (m)": 264.3,
        "First Year": 2001,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2005,
        "MLY First Year": 2001,
        "MLY Last Year": 2005
      },
      {
        "Name": "LENSWOOD EXP ST",
        "Province": "MANITOBA",
        "Climate ID": "5041560",
        "Station ID": 3814,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.53,
        "Longitude (Decimal Degrees)": -101,
        "Latitude": 523200000,
        "Longitude": -1010000000,
        "Elevation (m)": 304.8,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1958,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "LUNDAR",
        "Province": "MANITOBA",
        "Climate ID": "5041587",
        "Station ID": 3815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.75,
        "Longitude (Decimal Degrees)": -97.93,
        "Latitude": 504500000,
        "Longitude": -975600000,
        "Elevation (m)": 266.7,
        "First Year": 1969,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2000,
        "MLY First Year": 1969,
        "MLY Last Year": 2000
      },
      {
        "Name": "LUNDAR 4SW",
        "Province": "MANITOBA",
        "Climate ID": "5041588",
        "Station ID": 3816,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -98.1,
        "Latitude": 503900000,
        "Longitude": -980600000,
        "Elevation (m)": 251.5,
        "First Year": 1970,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1988,
        "MLY First Year": 1970,
        "MLY Last Year": 1988
      },
      {
        "Name": "LUNDAR EAST",
        "Province": "MANITOBA",
        "Climate ID": "5041589",
        "Station ID": 30554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -97.85,
        "Latitude": 504302000,
        "Longitude": -975115000,
        "Elevation (m)": 269.7,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2007,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "MACDONALD",
        "Province": "MANITOBA",
        "Climate ID": "5041670",
        "Station ID": 3817,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.17,
        "Longitude (Decimal Degrees)": -98.5,
        "Latitude": 501000000,
        "Longitude": -983000000,
        "Elevation (m)": 249.9,
        "First Year": 1967,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2008,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "MACGREGOR SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "5041680",
        "Station ID": 31107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -98.83,
        "Latitude": 495050000,
        "Longitude": -985000000,
        "Elevation (m)": 361.8,
        "First Year": 2001,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2007,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "MACGREGOR",
        "Province": "MANITOBA",
        "Climate ID": "5041683",
        "Station ID": 3818,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -98.82,
        "Latitude": 495300000,
        "Longitude": -984900000,
        "Elevation (m)": 320,
        "First Year": 1969,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1972
      },
      {
        "Name": "MACGREGOR",
        "Province": "MANITOBA",
        "Climate ID": "5041684",
        "Station ID": 3819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -98.7,
        "Latitude": 495400000,
        "Longitude": -984200000,
        "Elevation (m)": 300.8,
        "First Year": 1973,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1996,
        "MLY First Year": 1973,
        "MLY Last Year": 1996
      },
      {
        "Name": "MAFEKING",
        "Province": "MANITOBA",
        "Climate ID": "5041685",
        "Station ID": 3820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.67,
        "Longitude (Decimal Degrees)": -101.1,
        "Latitude": 523959030,
        "Longitude": -1010552020,
        "Elevation (m)": 325,
        "First Year": 1983,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2022,
        "MLY First Year": 1983,
        "MLY Last Year": 2007
      },
      {
        "Name": "MAGNET",
        "Province": "MANITOBA",
        "Climate ID": "5041686",
        "Station ID": 3821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -99.48,
        "Latitude": 511900000,
        "Longitude": -992900000,
        "Elevation (m)": 263.7,
        "First Year": 1982,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2004,
        "MLY First Year": 1982,
        "MLY Last Year": 2004
      },
      {
        "Name": "MCCREARY",
        "Province": "MANITOBA",
        "Climate ID": "5041707",
        "Station ID": 3822,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -99.42,
        "Latitude": 504400000,
        "Longitude": -992500000,
        "Elevation (m)": 297.2,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "MEADOW PORTAGE",
        "Province": "MANITOBA",
        "Climate ID": "5041711",
        "Station ID": 3823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -99.55,
        "Latitude": 514300000,
        "Longitude": -993300000,
        "Elevation (m)": 249.6,
        "First Year": 1970,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1988,
        "MLY First Year": 1970,
        "MLY Last Year": 1988
      },
      {
        "Name": "MOOSEHORN",
        "Province": "MANITOBA",
        "Climate ID": "5041800",
        "Station ID": 3824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -98.62,
        "Latitude": 511800000,
        "Longitude": -983700000,
        "Elevation (m)": 249.9,
        "First Year": 1910,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1966,
        "MLY First Year": 1910,
        "MLY Last Year": 1966
      },
      {
        "Name": "MOOSEHORN",
        "Province": "MANITOBA",
        "Climate ID": "5041801",
        "Station ID": 3825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -98.4,
        "Latitude": 511700000,
        "Longitude": -982400000,
        "Elevation (m)": 269.1,
        "First Year": 1982,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1984,
        "MLY First Year": 1982,
        "MLY Last Year": 1984
      },
      {
        "Name": "MOOSEHORN COOK",
        "Province": "MANITOBA",
        "Climate ID": "5041804",
        "Station ID": 3826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -98.42,
        "Latitude": 511700000,
        "Longitude": -982500000,
        "Elevation (m)": 265.2,
        "First Year": 1982,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1993,
        "MLY First Year": 1982,
        "MLY Last Year": 1993
      },
      {
        "Name": "MOUNTAIN GAP",
        "Province": "MANITOBA",
        "Climate ID": "5041970",
        "Station ID": 3827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -100.75,
        "Latitude": 510800000,
        "Longitude": -1004500000,
        "Elevation (m)": 460.2,
        "First Year": 1977,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1977,
        "MLY First Year": 1977,
        "MLY Last Year": 1977
      },
      {
        "Name": "MULVILHILL",
        "Province": "MANITOBA",
        "Climate ID": "5041973",
        "Station ID": 6895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -98.2,
        "Latitude": 505700000,
        "Longitude": -981200000,
        "Elevation (m)": 266.7,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "HILBRE",
        "Province": "MANITOBA",
        "Climate ID": "5041B9B",
        "Station ID": 3795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -98.6,
        "Latitude": 513000000,
        "Longitude": -983600000,
        "Elevation (m)": 251.5,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "NEEPAWA A",
        "Province": "MANITOBA",
        "Climate ID": "5042000",
        "Station ID": 3829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -99.5,
        "Latitude": 501400000,
        "Longitude": -993000000,
        "Elevation (m)": 388,
        "First Year": 1945,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1962,
        "MLY First Year": 1945,
        "MLY Last Year": 1962
      },
      {
        "Name": "NEEPAWA CSC",
        "Province": "MANITOBA",
        "Climate ID": "5042003",
        "Station ID": 3830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -99.47,
        "Latitude": 501400000,
        "Longitude": -992800000,
        "Elevation (m)": 368.8,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "NEEPAWA MURRAY 6 SOUTHWEST",
        "Province": "MANITOBA",
        "Climate ID": "5042004",
        "Station ID": 3831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.15,
        "Longitude (Decimal Degrees)": -99.57,
        "Latitude": 500900000,
        "Longitude": -993400000,
        "Elevation (m)": 411.5,
        "First Year": 1986,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2008,
        "MLY First Year": 1986,
        "MLY Last Year": 2007
      },
      {
        "Name": "NEEPAWA WATER",
        "Province": "MANITOBA",
        "Climate ID": "5042005",
        "Station ID": 3832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -99.47,
        "Latitude": 501300000,
        "Longitude": -992800000,
        "Elevation (m)": 358.1,
        "First Year": 1969,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2008,
        "MLY First Year": 1969,
        "MLY Last Year": 2007
      },
      {
        "Name": "OVERFLOWING RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5042058",
        "Station ID": 3834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.15,
        "Longitude (Decimal Degrees)": -101.1,
        "Latitude": 530900000,
        "Longitude": -1010600000,
        "Elevation (m)": 257.9,
        "First Year": 1984,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1993,
        "MLY First Year": 1984,
        "MLY Last Year": 1993
      },
      {
        "Name": "OCHRE RIVER SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "504205E",
        "Station ID": 3833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -99.78,
        "Latitude": 505900000,
        "Longitude": -994700000,
        "Elevation (m)": 315.2,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "PAULSON",
        "Province": "MANITOBA",
        "Climate ID": "5042062",
        "Station ID": 3835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.13,
        "Longitude (Decimal Degrees)": -99.83,
        "Latitude": 510800000,
        "Longitude": -995000000,
        "Elevation (m)": 274,
        "First Year": 1945,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1945,
        "MLY First Year": 1945,
        "MLY Last Year": 1945
      },
      {
        "Name": "OCHRE RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5.04E+09",
        "Station ID": 3828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -99.78,
        "Latitude": 510400000,
        "Longitude": -994700000,
        "Elevation (m)": 280.4,
        "First Year": 1970,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2005,
        "MLY First Year": 1970,
        "MLY Last Year": 2005
      },
      {
        "Name": "PINE RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5042166",
        "Station ID": 7314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -100.57,
        "Latitude": 514800000,
        "Longitude": -1003400000,
        "Elevation (m)": 378,
        "First Year": 2001,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2005,
        "MLY First Year": 2001,
        "MLY Last Year": 2005
      },
      {
        "Name": "PINE RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5042167",
        "Station ID": 3836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.72,
        "Longitude (Decimal Degrees)": -100.32,
        "Latitude": 514300000,
        "Longitude": -1001900000,
        "Elevation (m)": 312.4,
        "First Year": 1963,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1967,
        "MLY First Year": 1963,
        "MLY Last Year": 1967
      },
      {
        "Name": "PINE RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5042168",
        "Station ID": 3837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -100.32,
        "Latitude": 514600000,
        "Longitude": -1001900000,
        "Elevation (m)": 302.1,
        "First Year": 1970,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1984,
        "MLY First Year": 1970,
        "MLY Last Year": 1984
      },
      {
        "Name": "PINE RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5042169",
        "Station ID": 3838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.8,
        "Longitude (Decimal Degrees)": -100.57,
        "Latitude": 514800000,
        "Longitude": -1003400000,
        "Elevation (m)": 378,
        "First Year": 1981,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2002,
        "MLY First Year": 1981,
        "MLY Last Year": 2002
      },
      {
        "Name": "PLUMAS",
        "Province": "MANITOBA",
        "Climate ID": "5042240",
        "Station ID": 3839,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -99.08,
        "Latitude": 502200000,
        "Longitude": -990500000,
        "Elevation (m)": 281,
        "First Year": 1951,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1994,
        "MLY First Year": 1951,
        "MLY Last Year": 1994
      },
      {
        "Name": "PLUMAS",
        "Province": "MANITOBA",
        "Climate ID": "5042241",
        "Station ID": 3840,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -99.03,
        "Latitude": 502500000,
        "Longitude": -990200000,
        "Elevation (m)": 275.8,
        "First Year": 1970,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1988,
        "MLY First Year": 1970,
        "MLY Last Year": 1988
      },
      {
        "Name": "RIDING MOUNTAIN PARK",
        "Province": "MANITOBA",
        "Climate ID": "5042425",
        "Station ID": 3841,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -99.68,
        "Latitude": 504200000,
        "Longitude": -994100000,
        "Elevation (m)": 756.2,
        "First Year": 1960,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1983,
        "MLY First Year": 1960,
        "MLY Last Year": 1983
      },
      {
        "Name": "RORKETON WEST",
        "Province": "MANITOBA",
        "Climate ID": "5042485",
        "Station ID": 3842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.43,
        "Longitude (Decimal Degrees)": -99.73,
        "Latitude": 512600000,
        "Longitude": -994400000,
        "Elevation (m)": 266.4,
        "First Year": 1982,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2003,
        "MLY First Year": 1982,
        "MLY Last Year": 2003
      },
      {
        "Name": "RUSSELL BARNARDO",
        "Province": "MANITOBA",
        "Climate ID": "5042522",
        "Station ID": 3843,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.98,
        "Longitude (Decimal Degrees)": -101.33,
        "Latitude": 505900000,
        "Longitude": -1012000000,
        "Elevation (m)": 557.8,
        "First Year": 1895,
        "Last Year": 1906,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1906,
        "MLY First Year": 1895,
        "MLY Last Year": 1906
      },
      {
        "Name": "ST AMBROISE",
        "Province": "MANITOBA",
        "Climate ID": "5042546",
        "Station ID": 3844,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -98.03,
        "Latitude": 501400000,
        "Longitude": -980200000,
        "Elevation (m)": 251.5,
        "First Year": 1970,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2004,
        "MLY First Year": 1970,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST LAURENT",
        "Province": "MANITOBA",
        "Climate ID": "5042596",
        "Station ID": 3845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -97.63,
        "Latitude": 502500000,
        "Longitude": -973800000,
        "Elevation (m)": 266.7,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "STE ROSE",
        "Province": "MANITOBA",
        "Climate ID": "5042610",
        "Station ID": 3846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.03,
        "Longitude (Decimal Degrees)": -99.38,
        "Latitude": 510200000,
        "Longitude": -992300000,
        "Elevation (m)": 274.3,
        "First Year": 1975,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2009,
        "MLY First Year": 1975,
        "MLY Last Year": 2007
      },
      {
        "Name": "SIDNEY",
        "Province": "MANITOBA",
        "Climate ID": "5042678",
        "Station ID": 3847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -99.08,
        "Latitude": 495400000,
        "Longitude": -990500000,
        "Elevation (m)": 373.4,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "STEEP ROCK",
        "Province": "MANITOBA",
        "Climate ID": "5042774",
        "Station ID": 3848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -98.8,
        "Latitude": 512700000,
        "Longitude": -984800000,
        "Elevation (m)": 249.9,
        "First Year": 1960,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2001,
        "MLY First Year": 1960,
        "MLY Last Year": 2001
      },
      {
        "Name": "SWAN RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5042800",
        "Station ID": 3849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.05,
        "Longitude (Decimal Degrees)": -101.22,
        "Latitude": 520300000,
        "Longitude": -1011300000,
        "Elevation (m)": 346.6,
        "First Year": 1904,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1904,
        "DLY Last Year": 2008,
        "MLY First Year": 1904,
        "MLY Last Year": 2006
      },
      {
        "Name": "SWAN RIVER 2",
        "Province": "MANITOBA",
        "Climate ID": "5042805",
        "Station ID": 3850,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.98,
        "Longitude (Decimal Degrees)": -101.18,
        "Latitude": 515900000,
        "Longitude": -1011100000,
        "Elevation (m)": 411.5,
        "First Year": 1967,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1998,
        "MLY First Year": 1967,
        "MLY Last Year": 1998
      },
      {
        "Name": "TOBARMORE",
        "Province": "MANITOBA",
        "Climate ID": "5042927",
        "Station ID": 3851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -99.45,
        "Latitude": 502800000,
        "Longitude": -992700000,
        "Elevation (m)": 367.5,
        "First Year": 1986,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1986,
        "MLY First Year": 1986,
        "MLY Last Year": 1986
      },
      {
        "Name": "VOGAR",
        "Province": "MANITOBA",
        "Climate ID": "5043020",
        "Station ID": 3852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.92,
        "Longitude (Decimal Degrees)": -98.75,
        "Latitude": 505500000,
        "Longitude": -984500000,
        "Elevation (m)": 249.6,
        "First Year": 1966,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2008,
        "MLY First Year": 1966,
        "MLY Last Year": 2007
      },
      {
        "Name": "MCCREARY",
        "Province": "MANITOBA",
        "Climate ID": "5043158",
        "Station ID": 3853,
        "WMO ID": 71149,
        "TC ID": "WOO",
        "Latitude (Decimal Degrees)": 50.71,
        "Longitude (Decimal Degrees)": -99.53,
        "Latitude": 504243001,
        "Longitude": -993148003,
        "Elevation (m)": 351,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEGOSIS",
        "Province": "MANITOBA",
        "Climate ID": "5043225",
        "Station ID": 3854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.65,
        "Longitude (Decimal Degrees)": -99.93,
        "Latitude": 513900000,
        "Longitude": -995600000,
        "Elevation (m)": 257.6,
        "First Year": 1982,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2007,
        "MLY First Year": 1982,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINNIPEGOSIS SOUTH EAST",
        "Province": "MANITOBA",
        "Climate ID": "5043226",
        "Station ID": 3855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -99.88,
        "Latitude": 513600000,
        "Longitude": -995300000,
        "Elevation (m)": 257.6,
        "First Year": 1982,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1995,
        "MLY First Year": 1982,
        "MLY Last Year": 1999
      },
      {
        "Name": "HILBRE SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "504AB92",
        "Station ID": 3762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -98.57,
        "Latitude": 512800000,
        "Longitude": -983400000,
        "Elevation (m)": 249.9,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "GYPSUMVILLE",
        "Province": "MANITOBA",
        "Climate ID": "504AKCQ",
        "Station ID": 3763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.77,
        "Longitude (Decimal Degrees)": -98.63,
        "Latitude": 514600000,
        "Longitude": -983800000,
        "Elevation (m)": 259.7,
        "First Year": 1985,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1991,
        "MLY First Year": 1985,
        "MLY Last Year": 1991
      },
      {
        "Name": "OAK POINT",
        "Province": "MANITOBA",
        "Climate ID": "504B0NM",
        "Station ID": 3764,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -98.02,
        "Latitude": 503000000,
        "Longitude": -980100000,
        "Elevation (m)": 250.2,
        "First Year": 1973,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1992,
        "MLY First Year": 1973,
        "MLY Last Year": 1992
      },
      {
        "Name": "OAKPOINT MARINE",
        "Province": "MANITOBA",
        "Climate ID": "504K0NM",
        "Station ID": 10881,
        "WMO ID": 71144,
        "TC ID": "WOJ",
        "Latitude (Decimal Degrees)": 50.5,
        "Longitude (Decimal Degrees)": -98.04,
        "Latitude": 502958044,
        "Longitude": -980212030,
        "Elevation (m)": 249.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "SWAN RIVER RCS",
        "Province": "MANITOBA",
        "Climate ID": "504K80K",
        "Station ID": 10188,
        "WMO ID": 71443,
        "TC ID": "WEQ",
        "Latitude (Decimal Degrees)": 52.11,
        "Longitude (Decimal Degrees)": -101.23,
        "Latitude": 520653900,
        "Longitude": -1011358500,
        "Elevation (m)": 334.8,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "CRANBERRY PORTAGE",
        "Province": "MANITOBA",
        "Climate ID": "5050620",
        "Station ID": 3856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.62,
        "Longitude (Decimal Degrees)": -101.35,
        "Latitude": 543700000,
        "Longitude": -1012100000,
        "Elevation (m)": 306,
        "First Year": 1957,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1963,
        "MLY First Year": 1957,
        "MLY Last Year": 1963
      },
      {
        "Name": "FLIN FLON",
        "Province": "MANITOBA",
        "Climate ID": "5050919",
        "Station ID": 10213,
        "WMO ID": 71954,
        "TC ID": "WFO",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -101.68,
        "Latitude": 544100000,
        "Longitude": -1014100000,
        "Elevation (m)": 303.9,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "FLIN FLON",
        "Province": "MANITOBA",
        "Climate ID": "5050920",
        "Station ID": 3857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -101.88,
        "Latitude": 544600000,
        "Longitude": -1015300000,
        "Elevation (m)": 320,
        "First Year": 1927,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 2021,
        "MLY First Year": 1927,
        "MLY Last Year": 2007
      },
      {
        "Name": "FLIN FLON A",
        "Province": "MANITOBA",
        "Climate ID": "5050960",
        "Station ID": 3858,
        "WMO ID": null,
        "TC ID": "YFO",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -101.68,
        "Latitude": 544100000,
        "Longitude": -1014100000,
        "Elevation (m)": 303.9,
        "First Year": 1954,
        "Last Year": 2017,
        "HLY First Year": 1954,
        "HLY Last Year": 2014,
        "DLY First Year": 1968,
        "DLY Last Year": 2017,
        "MLY First Year": 1968,
        "MLY Last Year": 2007
      },
      {
        "Name": "FLIN FLON A",
        "Province": "MANITOBA",
        "Climate ID": "5050961",
        "Station ID": 52578,
        "WMO ID": null,
        "TC ID": "YFO",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -101.68,
        "Latitude": 544041000,
        "Longitude": -1014055000,
        "Elevation (m)": 304.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FLIN FLON CFB",
        "Province": "MANITOBA",
        "Climate ID": "5050970",
        "Station ID": 3859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -101.97,
        "Latitude": 544100000,
        "Longitude": -1015800000,
        "Elevation (m)": 304.8,
        "First Year": 1959,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1971,
        "MLY First Year": 1959,
        "MLY Last Year": 1971
      },
      {
        "Name": "FLIN FLON A",
        "Province": "MANITOBA",
        "Climate ID": "5050973",
        "Station ID": 52961,
        "WMO ID": null,
        "TC ID": "YFO",
        "Latitude (Decimal Degrees)": 54.68,
        "Longitude (Decimal Degrees)": -101.68,
        "Latitude": 544041000,
        "Longitude": -1014055000,
        "Elevation (m)": 304.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HUNTERS POINT MARINE",
        "Province": "MANITOBA",
        "Climate ID": "5051311",
        "Station ID": 10885,
        "WMO ID": null,
        "TC ID": "WHH",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -100.93,
        "Latitude": 530139000,
        "Longitude": -1005605000,
        "Elevation (m)": 256.1,
        "First Year": 1994,
        "Last Year": 2005,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1994,
        "DLY Last Year": 2005,
        "MLY First Year": 1996,
        "MLY Last Year": 2004
      },
      {
        "Name": "NAMEW LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5051986",
        "Station ID": 3860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.22,
        "Longitude (Decimal Degrees)": -101.78,
        "Latitude": 541300000,
        "Longitude": -1014700000,
        "Elevation (m)": 290,
        "First Year": 1986,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1991,
        "MLY First Year": 1986,
        "MLY Last Year": 1991
      },
      {
        "Name": "PASQUIA PROJECT",
        "Province": "MANITOBA",
        "Climate ID": "5052060",
        "Station ID": 3862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -101.53,
        "Latitude": 534300000,
        "Longitude": -1013200000,
        "Elevation (m)": 262.1,
        "First Year": 1956,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2005,
        "MLY First Year": 1956,
        "MLY Last Year": 2005
      },
      {
        "Name": "OONIKUP",
        "Province": "MANITOBA",
        "Climate ID": "50520EE",
        "Station ID": 3861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -101.33,
        "Latitude": 533000000,
        "Longitude": -1012000000,
        "Elevation (m)": null,
        "First Year": 1889,
        "Last Year": 1903,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 1903,
        "MLY First Year": 1889,
        "MLY Last Year": 1893
      },
      {
        "Name": "THE PAS",
        "Province": "MANITOBA",
        "Climate ID": "5052864",
        "Station ID": 3863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -101.25,
        "Latitude": 534900000,
        "Longitude": -1011500000,
        "Elevation (m)": 271.3,
        "First Year": 1910,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1969,
        "MLY First Year": 1910,
        "MLY Last Year": 1969
      },
      {
        "Name": "THE PAS A",
        "Province": "MANITOBA",
        "Climate ID": "5052880",
        "Station ID": 3864,
        "WMO ID": 71867,
        "TC ID": "YQD",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -101.1,
        "Latitude": 535800000,
        "Longitude": -1010600000,
        "Elevation (m)": 270.36,
        "First Year": 1943,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1943,
        "DLY Last Year": 2014,
        "MLY First Year": 1943,
        "MLY Last Year": 2014
      },
      {
        "Name": "THE PAS A",
        "Province": "MANITOBA",
        "Climate ID": "5052881",
        "Station ID": 52438,
        "WMO ID": null,
        "TC ID": "YQD",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -101.09,
        "Latitude": 535817000,
        "Longitude": -1010528000,
        "Elevation (m)": 270.6,
        "First Year": 2014,
        "Last Year": 2018,
        "HLY First Year": 2014,
        "HLY Last Year": 2018,
        "DLY First Year": 2014,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THE PAS A",
        "Province": "MANITOBA",
        "Climate ID": "5052882",
        "Station ID": 54279,
        "WMO ID": 71867,
        "TC ID": "YQD",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -101.09,
        "Latitude": 535817000,
        "Longitude": -1010528000,
        "Elevation (m)": 270.6,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THE PAS CLIMATE",
        "Province": "MANITOBA",
        "Climate ID": "5052890",
        "Station ID": 43983,
        "WMO ID": 71695,
        "TC ID": "PQD",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -101.1,
        "Latitude": 535800000,
        "Longitude": -1010600000,
        "Elevation (m)": 274,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "THE PAS CDA EPF",
        "Province": "MANITOBA",
        "Climate ID": "5052896",
        "Station ID": 3865,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -101.25,
        "Latitude": 534900000,
        "Longitude": -1011500000,
        "Elevation (m)": 271.3,
        "First Year": 1951,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1964,
        "MLY First Year": 1951,
        "MLY Last Year": 1964
      },
      {
        "Name": "WANLESS",
        "Province": "MANITOBA",
        "Climate ID": "5053080",
        "Station ID": 3866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.18,
        "Longitude (Decimal Degrees)": -101.37,
        "Latitude": 541100000,
        "Longitude": -1012200000,
        "Elevation (m)": 271.3,
        "First Year": 1935,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1995,
        "MLY First Year": 1935,
        "MLY Last Year": 1995
      },
      {
        "Name": "BIRD",
        "Province": "MANITOBA",
        "Climate ID": "5060220",
        "Station ID": 3869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.5,
        "Longitude (Decimal Degrees)": -94.2,
        "Latitude": 563000000,
        "Longitude": -941200000,
        "Elevation (m)": 86.9,
        "First Year": 1957,
        "Last Year": 1963,
        "HLY First Year": 1957,
        "HLY Last Year": 1963,
        "DLY First Year": 1957,
        "DLY Last Year": 1963,
        "MLY First Year": 1957,
        "MLY Last Year": 1963
      },
      {
        "Name": "BROCHET A",
        "Province": "MANITOBA",
        "Climate ID": "5060520",
        "Station ID": 3870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.89,
        "Longitude (Decimal Degrees)": -101.68,
        "Latitude": 575310400,
        "Longitude": -1014053200,
        "Elevation (m)": 346.3,
        "First Year": 1948,
        "Last Year": 2009,
        "HLY First Year": 1953,
        "HLY Last Year": 1979,
        "DLY First Year": 1948,
        "DLY Last Year": 2009,
        "MLY First Year": 1948,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURNTWOOD RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5060531",
        "Station ID": 29774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.5,
        "Longitude (Decimal Degrees)": -99.22,
        "Latitude": 553000000,
        "Longitude": -991300000,
        "Elevation (m)": 215,
        "First Year": 2000,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2002,
        "MLY First Year": 2000,
        "MLY Last Year": 2002
      },
      {
        "Name": "CHURCHILL A",
        "Province": "MANITOBA",
        "Climate ID": "5060595",
        "Station ID": 48969,
        "WMO ID": 71618,
        "TC ID": "YYQ",
        "Latitude (Decimal Degrees)": 58.74,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 584421000,
        "Longitude": -940359000,
        "Elevation (m)": 29.3,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHURCHILL A",
        "Province": "MANITOBA",
        "Climate ID": "5060600",
        "Station ID": 3871,
        "WMO ID": null,
        "TC ID": "YYQ",
        "Latitude (Decimal Degrees)": 58.74,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 584421000,
        "Longitude": -940359000,
        "Elevation (m)": 29.26,
        "First Year": 1943,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1943,
        "DLY Last Year": 2008,
        "MLY First Year": 1943,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHURCHILL AWOS",
        "Province": "MANITOBA",
        "Climate ID": "5060601",
        "Station ID": 47408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.74,
        "Longitude (Decimal Degrees)": -94.06,
        "Latitude": 584414000,
        "Longitude": -940326000,
        "Elevation (m)": 28.7,
        "First Year": 2008,
        "Last Year": 2010,
        "HLY First Year": 2008,
        "HLY Last Year": 2010,
        "DLY First Year": 2008,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHURCHILL MARINE",
        "Province": "MANITOBA",
        "Climate ID": "5060602",
        "Station ID": 3872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.78,
        "Longitude (Decimal Degrees)": -94.18,
        "Latitude": 584700000,
        "Longitude": -941100000,
        "Elevation (m)": 13.4,
        "First Year": 1929,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1951,
        "MLY First Year": 1929,
        "MLY Last Year": 1951
      },
      {
        "Name": "CHURCHILL A",
        "Province": "MANITOBA",
        "Climate ID": "5060605",
        "Station ID": 50148,
        "WMO ID": 71618,
        "TC ID": "YYQ",
        "Latitude (Decimal Degrees)": 58.74,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 584421000,
        "Longitude": -940359000,
        "Elevation (m)": 29.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHURCHILL UA",
        "Province": "MANITOBA",
        "Climate ID": "5060606",
        "Station ID": 10745,
        "WMO ID": 71913,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.73,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 584400000,
        "Longitude": -940400000,
        "Elevation (m)": 28.5,
        "First Year": 2000,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2015,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHURCHILL CLIMATE",
        "Province": "MANITOBA",
        "Climate ID": "5060608",
        "Station ID": 44244,
        "WMO ID": 71696,
        "TC ID": "PYQ",
        "Latitude (Decimal Degrees)": 58.73,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 584400000,
        "Longitude": -940400000,
        "Elevation (m)": 28.5,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "CONLIN LAKE WABOWDEN",
        "Province": "MANITOBA",
        "Climate ID": "5060613",
        "Station ID": 3873,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.83,
        "Longitude (Decimal Degrees)": -98.67,
        "Latitude": 545000000,
        "Longitude": -984000000,
        "Elevation (m)": 221,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "CROSS LAKE A",
        "Province": "MANITOBA",
        "Climate ID": "5060621",
        "Station ID": 3874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.62,
        "Longitude (Decimal Degrees)": -97.77,
        "Latitude": 543700000,
        "Longitude": -974600000,
        "Elevation (m)": 216.1,
        "First Year": 1987,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2010,
        "MLY First Year": 1987,
        "MLY Last Year": 2007
      },
      {
        "Name": "CROSS LAKE JENPEG 2",
        "Province": "MANITOBA",
        "Climate ID": "5060622",
        "Station ID": 54658,
        "WMO ID": 71106,
        "TC ID": "PJP",
        "Latitude (Decimal Degrees)": 54.54,
        "Longitude (Decimal Degrees)": -98.03,
        "Latitude": 543223000,
        "Longitude": -980149000,
        "Elevation (m)": 218.8,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CROSS LAKE JENPEG",
        "Province": "MANITOBA",
        "Climate ID": "5060623",
        "Station ID": 3875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.54,
        "Longitude (Decimal Degrees)": -98.03,
        "Latitude": 543223000,
        "Longitude": -980149000,
        "Elevation (m)": 218.8,
        "First Year": 1972,
        "Last Year": 2018,
        "HLY First Year": 2017,
        "HLY Last Year": 2018,
        "DLY First Year": 1972,
        "DLY Last Year": 2017,
        "MLY First Year": 1972,
        "MLY Last Year": 2007
      },
      {
        "Name": "DENBEIGH POINT",
        "Province": "MANITOBA",
        "Climate ID": "5060766",
        "Station ID": 3876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.4,
        "Longitude (Decimal Degrees)": -99.78,
        "Latitude": 552400000,
        "Longitude": -994700000,
        "Elevation (m)": 256,
        "First Year": 1990,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1990,
        "MLY First Year": 1990,
        "MLY Last Year": 1990
      },
      {
        "Name": "GILLAM",
        "Province": "MANITOBA",
        "Climate ID": "5060999",
        "Station ID": 10217,
        "WMO ID": null,
        "TC ID": "WGX",
        "Latitude (Decimal Degrees)": 56.36,
        "Longitude (Decimal Degrees)": -94.7,
        "Latitude": 562132028,
        "Longitude": -944215024,
        "Elevation (m)": 145.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "GILLAM",
        "Province": "MANITOBA",
        "Climate ID": "5061000",
        "Station ID": 3877,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.35,
        "Longitude (Decimal Degrees)": -94.7,
        "Latitude": 562100000,
        "Longitude": -944200000,
        "Elevation (m)": 138.4,
        "First Year": 1943,
        "Last Year": 1964,
        "HLY First Year": 1953,
        "HLY Last Year": 1961,
        "DLY First Year": 1943,
        "DLY Last Year": 1964,
        "MLY First Year": 1943,
        "MLY Last Year": 1964
      },
      {
        "Name": "GILLAM A",
        "Province": "MANITOBA",
        "Climate ID": "5061001",
        "Station ID": 3878,
        "WMO ID": 71912,
        "TC ID": "YGX",
        "Latitude (Decimal Degrees)": 56.36,
        "Longitude (Decimal Degrees)": -94.71,
        "Latitude": 562127000,
        "Longitude": -944238000,
        "Elevation (m)": 145.1,
        "First Year": 1970,
        "Last Year": 2014,
        "HLY First Year": 1970,
        "HLY Last Year": 2014,
        "DLY First Year": 1970,
        "DLY Last Year": 2014,
        "MLY First Year": 1970,
        "MLY Last Year": 2014
      },
      {
        "Name": "GILLAM A",
        "Province": "MANITOBA",
        "Climate ID": "5061022",
        "Station ID": 52758,
        "WMO ID": 71912,
        "TC ID": "YGX",
        "Latitude (Decimal Degrees)": 56.36,
        "Longitude (Decimal Degrees)": -94.71,
        "Latitude": 562128000,
        "Longitude": -944239000,
        "Elevation (m)": 145.1,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GILLAM A",
        "Province": "MANITOBA",
        "Climate ID": "5061025",
        "Station ID": 53698,
        "WMO ID": null,
        "TC ID": "YGX",
        "Latitude (Decimal Degrees)": 56.36,
        "Longitude (Decimal Degrees)": -94.71,
        "Latitude": 562128000,
        "Longitude": -944239000,
        "Elevation (m)": 145.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GODS LAKE NARROWS A",
        "Province": "MANITOBA",
        "Climate ID": "5061067",
        "Station ID": 3879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.55,
        "Longitude (Decimal Degrees)": -94.48,
        "Latitude": 543300000,
        "Longitude": -942900000,
        "Elevation (m)": 189,
        "First Year": 1985,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2002,
        "MLY First Year": 1985,
        "MLY Last Year": 2002
      },
      {
        "Name": "ISLAND LAKE A",
        "Province": "MANITOBA",
        "Climate ID": "5061374",
        "Station ID": 54278,
        "WMO ID": 71145,
        "TC ID": "YIV",
        "Latitude (Decimal Degrees)": 53.86,
        "Longitude (Decimal Degrees)": -94.65,
        "Latitude": 535126000,
        "Longitude": -943913000,
        "Elevation (m)": 235.3,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ISLAND LAKE A",
        "Province": "MANITOBA",
        "Climate ID": "5061375",
        "Station ID": 53398,
        "WMO ID": null,
        "TC ID": "YIV",
        "Latitude (Decimal Degrees)": 53.86,
        "Longitude (Decimal Degrees)": -94.65,
        "Latitude": 535126000,
        "Longitude": -943913000,
        "Elevation (m)": 235.3,
        "First Year": 2015,
        "Last Year": 2021,
        "HLY First Year": 2015,
        "HLY Last Year": 2021,
        "DLY First Year": 2015,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ISLAND LAKE A",
        "Province": "MANITOBA",
        "Climate ID": "5061376",
        "Station ID": 3880,
        "WMO ID": 71145,
        "TC ID": "YIV",
        "Latitude (Decimal Degrees)": 53.85,
        "Longitude (Decimal Degrees)": -94.65,
        "Latitude": 535100000,
        "Longitude": -943900000,
        "Elevation (m)": 235.6,
        "First Year": 1970,
        "Last Year": 2015,
        "HLY First Year": 1970,
        "HLY Last Year": 2015,
        "DLY First Year": 1970,
        "DLY Last Year": 2015,
        "MLY First Year": 1970,
        "MLY Last Year": 2015
      },
      {
        "Name": "ISLAND LAKE (AUT)",
        "Province": "MANITOBA",
        "Climate ID": "5061377",
        "Station ID": 7327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -94.67,
        "Latitude": 535200000,
        "Longitude": -944000000,
        "Elevation (m)": 239.3,
        "First Year": 1979,
        "Last Year": 1986,
        "HLY First Year": 1979,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KELSEY DAM CS",
        "Province": "MANITOBA",
        "Climate ID": "5061422",
        "Station ID": 29533,
        "WMO ID": 71572,
        "TC ID": "ZKD",
        "Latitude (Decimal Degrees)": 56.04,
        "Longitude (Decimal Degrees)": -96.51,
        "Latitude": 560215000,
        "Longitude": -963035000,
        "Elevation (m)": 182.9,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "KELSEY HYDRO",
        "Province": "MANITOBA",
        "Climate ID": "5061423",
        "Station ID": 3881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.03,
        "Longitude (Decimal Degrees)": -96.53,
        "Latitude": 560200000,
        "Longitude": -963200000,
        "Elevation (m)": 182.9,
        "First Year": 1983,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1995,
        "MLY First Year": 1983,
        "MLY Last Year": 1995
      },
      {
        "Name": "KETTLE GS",
        "Province": "MANITOBA",
        "Climate ID": "5061460",
        "Station ID": 3882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.38,
        "Longitude (Decimal Degrees)": -94.65,
        "Latitude": 562300000,
        "Longitude": -943900000,
        "Elevation (m)": 145.4,
        "First Year": 1966,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1973,
        "MLY First Year": 1966,
        "MLY Last Year": 1973
      },
      {
        "Name": "LAURIE RIVER POWER SITE",
        "Province": "MANITOBA",
        "Climate ID": "5061540",
        "Station ID": 3883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -100.98,
        "Latitude": 561400000,
        "Longitude": -1005900000,
        "Elevation (m)": 304.8,
        "First Year": 1960,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1970,
        "MLY First Year": 1960,
        "MLY Last Year": 1970
      },
      {
        "Name": "LEAF RAPIDS",
        "Province": "MANITOBA",
        "Climate ID": "5061550",
        "Station ID": 6896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.47,
        "Longitude (Decimal Degrees)": -100.02,
        "Latitude": 562800000,
        "Longitude": -1000100000,
        "Elevation (m)": 282,
        "First Year": 1991,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1996,
        "MLY First Year": 1991,
        "MLY Last Year": 1996
      },
      {
        "Name": "LIMESTONE GS",
        "Province": "MANITOBA",
        "Climate ID": "5061567",
        "Station ID": 3884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.5,
        "Longitude (Decimal Degrees)": -94.18,
        "Latitude": 563000000,
        "Longitude": -941100000,
        "Elevation (m)": 88.4,
        "First Year": 1985,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1992,
        "MLY First Year": 1985,
        "MLY Last Year": 1992
      },
      {
        "Name": "LITTLE BEAVER RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5061575",
        "Station ID": 29793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.65,
        "Longitude (Decimal Degrees)": -95.67,
        "Latitude": 573900000,
        "Longitude": -954000000,
        "Elevation (m)": 245,
        "First Year": 2000,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2004,
        "MLY First Year": 2000,
        "MLY Last Year": 2001
      },
      {
        "Name": "LONG SPRUCE GEN STN",
        "Province": "MANITOBA",
        "Climate ID": "5061581",
        "Station ID": 3885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.38,
        "Longitude (Decimal Degrees)": -94.37,
        "Latitude": 562300000,
        "Longitude": -942200000,
        "Elevation (m)": 105.2,
        "First Year": 1974,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "LYNN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5061640",
        "Station ID": 3886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.85,
        "Longitude (Decimal Degrees)": -101.03,
        "Latitude": 565100000,
        "Longitude": -1010200000,
        "Elevation (m)": 339.9,
        "First Year": 1952,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1967,
        "MLY First Year": 1952,
        "MLY Last Year": 1967
      },
      {
        "Name": "LYNN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5061645",
        "Station ID": 48588,
        "WMO ID": 71078,
        "TC ID": "YYL",
        "Latitude (Decimal Degrees)": 56.86,
        "Longitude (Decimal Degrees)": -101.08,
        "Latitude": 565150000,
        "Longitude": -1010434000,
        "Elevation (m)": 356.6,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LYNN LAKE A",
        "Province": "MANITOBA",
        "Climate ID": "5061646",
        "Station ID": 3887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.86,
        "Longitude (Decimal Degrees)": -101.08,
        "Latitude": 565150000,
        "Longitude": -1010434000,
        "Elevation (m)": 356.6,
        "First Year": 1959,
        "Last Year": 2005,
        "HLY First Year": 1959,
        "HLY Last Year": 2005,
        "DLY First Year": 1968,
        "DLY Last Year": 2005,
        "MLY First Year": 1968,
        "MLY Last Year": 2005
      },
      {
        "Name": "LYNN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5061648",
        "Station ID": 44085,
        "WMO ID": null,
        "TC ID": "PNK",
        "Latitude (Decimal Degrees)": 56.86,
        "Longitude (Decimal Degrees)": -101.08,
        "Latitude": 565150000,
        "Longitude": -1010434000,
        "Elevation (m)": 356.6,
        "First Year": 2005,
        "Last Year": 2014,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2014,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "LYNN LAKE RCS",
        "Province": "MANITOBA",
        "Climate ID": "5061649",
        "Station ID": 48728,
        "WMO ID": 71632,
        "TC ID": "PLE",
        "Latitude (Decimal Degrees)": 56.85,
        "Longitude (Decimal Degrees)": -101.07,
        "Latitude": 565100420,
        "Longitude": -1010400005,
        "Elevation (m)": 354.2,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MISSI",
        "Province": "MANITOBA",
        "Climate ID": "5061765",
        "Station ID": 29794,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.32,
        "Longitude (Decimal Degrees)": -98.17,
        "Latitude": 571900000,
        "Longitude": -981000000,
        "Elevation (m)": 265,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2007,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "MISSI FALLS",
        "Province": "MANITOBA",
        "Climate ID": "5061766",
        "Station ID": 3888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.32,
        "Longitude (Decimal Degrees)": -98.1,
        "Latitude": 571900000,
        "Longitude": -980600000,
        "Elevation (m)": 266.7,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "MOAK LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5061770",
        "Station ID": 3889,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.92,
        "Longitude (Decimal Degrees)": -97.58,
        "Latitude": 555500000,
        "Longitude": -973500000,
        "Elevation (m)": 196,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1983
      },
      {
        "Name": "NORWAY HOUSE",
        "Province": "MANITOBA",
        "Climate ID": "5062040",
        "Station ID": 48951,
        "WMO ID": 71410,
        "TC ID": "YNE",
        "Latitude (Decimal Degrees)": 53.96,
        "Longitude (Decimal Degrees)": -97.84,
        "Latitude": 535730000,
        "Longitude": -975039000,
        "Elevation (m)": 223.7,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORWAY HOUSE",
        "Province": "MANITOBA",
        "Climate ID": "5062045",
        "Station ID": 44083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.97,
        "Longitude (Decimal Degrees)": -97.85,
        "Latitude": 535800000,
        "Longitude": -975100000,
        "Elevation (m)": 223.7,
        "First Year": 2005,
        "Last Year": 2010,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORWAY HOUSE",
        "Province": "MANITOBA",
        "Climate ID": "5062047",
        "Station ID": 3890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.98,
        "Longitude (Decimal Degrees)": -97.83,
        "Latitude": 535900000,
        "Longitude": -975000000,
        "Elevation (m)": 217,
        "First Year": 1884,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1968,
        "MLY First Year": 1896,
        "MLY Last Year": 1968
      },
      {
        "Name": "NOTIGI",
        "Province": "MANITOBA",
        "Climate ID": "5062049",
        "Station ID": 3891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.9,
        "Longitude (Decimal Degrees)": -99.37,
        "Latitude": 555400000,
        "Longitude": -992200000,
        "Elevation (m)": 262.1,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "OXFORD HOUSE A",
        "Province": "MANITOBA",
        "Climate ID": "5062059",
        "Station ID": 3892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -95.27,
        "Latitude": 544600000,
        "Longitude": -951600000,
        "Elevation (m)": 204.8,
        "First Year": 1985,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1985,
        "MLY First Year": 1985,
        "MLY Last Year": 1985
      },
      {
        "Name": "PICKEREL NARROWS",
        "Province": "MANITOBA",
        "Climate ID": "5062075",
        "Station ID": 3893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.22,
        "Longitude (Decimal Degrees)": -100.58,
        "Latitude": 561300000,
        "Longitude": -1003500000,
        "Elevation (m)": 265.2,
        "First Year": 1963,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1978,
        "MLY First Year": 1963,
        "MLY Last Year": 1978
      },
      {
        "Name": "PIKWITONEI A",
        "Province": "MANITOBA",
        "Climate ID": "5062111",
        "Station ID": 3894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.58,
        "Longitude (Decimal Degrees)": -97.17,
        "Latitude": 553500000,
        "Longitude": -971000000,
        "Elevation (m)": 192,
        "First Year": 1987,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1995,
        "MLY First Year": 1987,
        "MLY Last Year": 1995
      },
      {
        "Name": "PONTON",
        "Province": "MANITOBA",
        "Climate ID": "5062251",
        "Station ID": 3895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.67,
        "Longitude (Decimal Degrees)": -99.17,
        "Latitude": 544000000,
        "Longitude": -991000000,
        "Elevation (m)": 259.1,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "PORT NELSON",
        "Province": "MANITOBA",
        "Climate ID": "5062327",
        "Station ID": 3896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57,
        "Longitude (Decimal Degrees)": -92.85,
        "Latitude": 570000000,
        "Longitude": -925100000,
        "Elevation (m)": 14.9,
        "First Year": 1915,
        "Last Year": 1934,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1934,
        "MLY First Year": 1915,
        "MLY Last Year": 1929
      },
      {
        "Name": "RAT RIVER",
        "Province": "MANITOBA",
        "Climate ID": "5062336",
        "Station ID": 29795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.13,
        "Longitude (Decimal Degrees)": -99.48,
        "Latitude": 560800000,
        "Longitude": -992900000,
        "Elevation (m)": 250,
        "First Year": 2000,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2001,
        "MLY First Year": 2000,
        "MLY Last Year": 2001
      },
      {
        "Name": "RED SUCKER LAKE A",
        "Province": "MANITOBA",
        "Climate ID": "5062337",
        "Station ID": 3897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.17,
        "Longitude (Decimal Degrees)": -93.55,
        "Latitude": 541000000,
        "Longitude": -933300000,
        "Elevation (m)": 227.56,
        "First Year": 1985,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1989,
        "MLY First Year": 1985,
        "MLY Last Year": 1987
      },
      {
        "Name": "REINDEER LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062350",
        "Station ID": 29796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.88,
        "Longitude (Decimal Degrees)": -101.67,
        "Latitude": 575300000,
        "Longitude": -1014000000,
        "Elevation (m)": 225,
        "First Year": 2000,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2001,
        "MLY First Year": 2000,
        "MLY Last Year": 2001
      },
      {
        "Name": "RUTTAN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062528",
        "Station ID": 3898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.62,
        "Longitude (Decimal Degrees)": -99.62,
        "Latitude": 563700000,
        "Longitude": -993700000,
        "Elevation (m)": 304.8,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "RUTTAN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062529",
        "Station ID": 3899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.48,
        "Longitude (Decimal Degrees)": -99.65,
        "Latitude": 562900000,
        "Longitude": -993900000,
        "Elevation (m)": 288.6,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "SHAMATTAWA A",
        "Province": "MANITOBA",
        "Climate ID": "5062656",
        "Station ID": 3900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.87,
        "Longitude (Decimal Degrees)": -92.08,
        "Latitude": 555200000,
        "Longitude": -920500000,
        "Elevation (m)": 89.9,
        "First Year": 1985,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1985,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SNOW LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062706",
        "Station ID": 3901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.88,
        "Longitude (Decimal Degrees)": -100.03,
        "Latitude": 545300000,
        "Longitude": -1000200000,
        "Elevation (m)": 295.7,
        "First Year": 1983,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1998,
        "MLY First Year": 1983,
        "MLY Last Year": 1998
      },
      {
        "Name": "SNOW LAKE ANDERSON BAY",
        "Province": "MANITOBA",
        "Climate ID": "5062707",
        "Station ID": 27870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.82,
        "Longitude (Decimal Degrees)": -99.95,
        "Latitude": 544900000,
        "Longitude": -995700000,
        "Elevation (m)": 259,
        "First Year": 1999,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2000,
        "MLY First Year": 1999,
        "MLY Last Year": 2000
      },
      {
        "Name": "SOUTH BAY",
        "Province": "MANITOBA",
        "Climate ID": "5062730",
        "Station ID": 3902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.67,
        "Longitude (Decimal Degrees)": -99,
        "Latitude": 564000000,
        "Longitude": -990000000,
        "Elevation (m)": 282.9,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "SOUTH INDIAN LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062734",
        "Station ID": 3903,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.78,
        "Longitude (Decimal Degrees)": -98.97,
        "Latitude": 564700000,
        "Longitude": -985800000,
        "Elevation (m)": 259.1,
        "First Year": 1976,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1989,
        "MLY First Year": 1976,
        "MLY Last Year": 1989
      },
      {
        "Name": "SOUTH INDIAN LAKE AIRPORT",
        "Province": "MANITOBA",
        "Climate ID": "5062736",
        "Station ID": 3904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.8,
        "Longitude (Decimal Degrees)": -98.9,
        "Latitude": 564800000,
        "Longitude": -985400000,
        "Elevation (m)": 289,
        "First Year": 1989,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1998,
        "MLY First Year": 1989,
        "MLY Last Year": 1998
      },
      {
        "Name": "SPLIT LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062751",
        "Station ID": 29797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.25,
        "Longitude (Decimal Degrees)": -96.1,
        "Latitude": 561500000,
        "Longitude": -960600000,
        "Elevation (m)": 60,
        "First Year": 2000,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2000,
        "MLY First Year": 2000,
        "MLY Last Year": 2000
      },
      {
        "Name": "TADOULE LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5062835",
        "Station ID": 48972,
        "WMO ID": 71272,
        "TC ID": "YBQ",
        "Latitude (Decimal Degrees)": 58.71,
        "Longitude (Decimal Degrees)": -98.51,
        "Latitude": 584222000,
        "Longitude": -983044000,
        "Elevation (m)": 281.3,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TADOULE LAKE CS",
        "Province": "MANITOBA",
        "Climate ID": "5062840",
        "Station ID": 30475,
        "WMO ID": 71334,
        "TC ID": "XBQ",
        "Latitude (Decimal Degrees)": 58.72,
        "Longitude (Decimal Degrees)": -98.5,
        "Latitude": 584257000,
        "Longitude": -983006000,
        "Elevation (m)": 262.01,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "THOMPSON AIRPORT",
        "Province": "MANITOBA",
        "Climate ID": "5062918",
        "Station ID": 55138,
        "WMO ID": 73072,
        "TC ID": "PTO",
        "Latitude (Decimal Degrees)": 55.8,
        "Longitude (Decimal Degrees)": -97.88,
        "Latitude": 554756000,
        "Longitude": -975236000,
        "Elevation (m)": 214,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THOMPSON A",
        "Province": "MANITOBA",
        "Climate ID": "5062921",
        "Station ID": 53118,
        "WMO ID": 71079,
        "TC ID": "YTH",
        "Latitude (Decimal Degrees)": 55.8,
        "Longitude (Decimal Degrees)": -97.86,
        "Latitude": 554817000,
        "Longitude": -975145000,
        "Elevation (m)": 224,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THOMPSON A",
        "Province": "MANITOBA",
        "Climate ID": "5062922",
        "Station ID": 3905,
        "WMO ID": 71079,
        "TC ID": "YTH",
        "Latitude (Decimal Degrees)": 55.8,
        "Longitude (Decimal Degrees)": -97.86,
        "Latitude": 554812000,
        "Longitude": -975145000,
        "Elevation (m)": 224.03,
        "First Year": 1967,
        "Last Year": 2014,
        "HLY First Year": 1967,
        "HLY Last Year": 2014,
        "DLY First Year": 1967,
        "DLY Last Year": 2014,
        "MLY First Year": 1967,
        "MLY Last Year": 2007
      },
      {
        "Name": "THOMPSON NRC",
        "Province": "MANITOBA",
        "Climate ID": "5062923",
        "Station ID": 3906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.73,
        "Longitude (Decimal Degrees)": -97.85,
        "Latitude": 554400000,
        "Longitude": -975100000,
        "Elevation (m)": 212.8,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "THOMPSON SOUTH",
        "Province": "MANITOBA",
        "Climate ID": "5062925",
        "Station ID": 3907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.73,
        "Longitude (Decimal Degrees)": -97.85,
        "Latitude": 554400000,
        "Longitude": -975100000,
        "Elevation (m)": 199.9,
        "First Year": 1979,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1979,
        "MLY First Year": 1979,
        "MLY Last Year": 1979
      },
      {
        "Name": "THOMPSON ZOO",
        "Province": "MANITOBA",
        "Climate ID": "5062926",
        "Station ID": 3908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.75,
        "Longitude (Decimal Degrees)": -97.87,
        "Latitude": 554507062,
        "Longitude": -975158068,
        "Elevation (m)": 205.7,
        "First Year": 1990,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2013,
        "MLY First Year": 1990,
        "MLY Last Year": 2007
      },
      {
        "Name": "WABOWDEN",
        "Province": "MANITOBA",
        "Climate ID": "5063040",
        "Station ID": 3909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.92,
        "Longitude (Decimal Degrees)": -98.63,
        "Latitude": 545500000,
        "Longitude": -983800000,
        "Elevation (m)": 232.9,
        "First Year": 1943,
        "Last Year": 1971,
        "HLY First Year": 1953,
        "HLY Last Year": 1971,
        "DLY First Year": 1943,
        "DLY Last Year": 1971,
        "MLY First Year": 1943,
        "MLY Last Year": 1971
      },
      {
        "Name": "WABOWDEN",
        "Province": "MANITOBA",
        "Climate ID": "5063041",
        "Station ID": 3910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.92,
        "Longitude (Decimal Degrees)": -98.65,
        "Latitude": 545500000,
        "Longitude": -983900000,
        "Elevation (m)": 231.6,
        "First Year": 1982,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2001,
        "MLY First Year": 1982,
        "MLY Last Year": 2001
      },
      {
        "Name": "WABOWDEN 2",
        "Province": "MANITOBA",
        "Climate ID": "5063044",
        "Station ID": 31169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.93,
        "Longitude (Decimal Degrees)": -98.66,
        "Latitude": 545538800,
        "Longitude": -983937500,
        "Elevation (m)": 231.6,
        "First Year": 2001,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2008,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "WALKER LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5063075",
        "Station ID": 29553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.78,
        "Longitude (Decimal Degrees)": -97,
        "Latitude": 544700000,
        "Longitude": -970000000,
        "Elevation (m)": 200,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 1999,
        "MLY Last Year": 2007
      },
      {
        "Name": "WASKWATIM LAKE",
        "Province": "MANITOBA",
        "Climate ID": "5063121",
        "Station ID": 29555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.55,
        "Longitude (Decimal Degrees)": -98.6,
        "Latitude": 553300000,
        "Longitude": -983600000,
        "Elevation (m)": 260,
        "First Year": 1999,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2007,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "WHISKEY JACK",
        "Province": "MANITOBA",
        "Climate ID": "5063148",
        "Station ID": 3911,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.43,
        "Longitude (Decimal Degrees)": -98.02,
        "Latitude": 542600000,
        "Longitude": -980100000,
        "Elevation (m)": 221,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "YORK FACTORY",
        "Province": "MANITOBA",
        "Climate ID": "5063350",
        "Station ID": 3912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57,
        "Longitude (Decimal Degrees)": -92.3,
        "Latitude": 570000000,
        "Longitude": -921800000,
        "Elevation (m)": 6,
        "First Year": 1874,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 2007,
        "MLY First Year": 1874,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORWAY HOUSE A",
        "Province": "MANITOBA",
        "Climate ID": "506B047",
        "Station ID": 3868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.95,
        "Longitude (Decimal Degrees)": -97.85,
        "Latitude": 535700000,
        "Longitude": -975100000,
        "Elevation (m)": 223.7,
        "First Year": 1973,
        "Last Year": 2005,
        "HLY First Year": 1973,
        "HLY Last Year": 2005,
        "DLY First Year": 1973,
        "DLY Last Year": 2005,
        "MLY First Year": 1973,
        "MLY Last Year": 2005
      },
      {
        "Name": "NORWAY HOUSE FORESTRY",
        "Province": "MANITOBA",
        "Climate ID": "506B0M7",
        "Station ID": 3867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54,
        "Longitude (Decimal Degrees)": -97.8,
        "Latitude": 540000000,
        "Longitude": -974800000,
        "Elevation (m)": 217,
        "First Year": 1970,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2000,
        "MLY First Year": 1970,
        "MLY Last Year": 2000
      },
      {
        "Name": "ATTAWAPISKAT",
        "Province": "ONTARIO",
        "Climate ID": "6010387",
        "Station ID": 3913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -82.45,
        "Latitude": 525500000,
        "Longitude": -822700000,
        "Elevation (m)": 9.1,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "ATTAWAPISKAT A",
        "Province": "ONTARIO",
        "Climate ID": "6010400",
        "Station ID": 52918,
        "WMO ID": 71653,
        "TC ID": "YAT",
        "Latitude (Decimal Degrees)": 52.93,
        "Longitude (Decimal Degrees)": -82.43,
        "Latitude": 525539000,
        "Longitude": -822555000,
        "Elevation (m)": 9.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BIG TROUT LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6010735",
        "Station ID": 49488,
        "WMO ID": 71675,
        "TC ID": "YTL",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -89.9,
        "Latitude": 534904000,
        "Longitude": -895349000,
        "Elevation (m)": 222.5,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BIG TROUT LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6010738",
        "Station ID": 3914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -89.87,
        "Latitude": 535000000,
        "Longitude": -895200000,
        "Elevation (m)": 224.1,
        "First Year": 1939,
        "Last Year": 1992,
        "HLY First Year": 1953,
        "HLY Last Year": 1992,
        "DLY First Year": 1939,
        "DLY Last Year": 1992,
        "MLY First Year": 1939,
        "MLY Last Year": 1992
      },
      {
        "Name": "BIG TROUT LAKE READAC",
        "Province": "ONTARIO",
        "Climate ID": "6010739",
        "Station ID": 10804,
        "WMO ID": null,
        "TC ID": "TBL",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -89.9,
        "Latitude": 534856000,
        "Longitude": -895344000,
        "Elevation (m)": 222.2,
        "First Year": 1992,
        "Last Year": 2013,
        "HLY First Year": 1994,
        "HLY Last Year": 2013,
        "DLY First Year": 1992,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BIG TROUT LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6010740",
        "Station ID": 50857,
        "WMO ID": 71208,
        "TC ID": "OTL",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -89.89,
        "Latitude": 534858000,
        "Longitude": -895330000,
        "Elevation (m)": 222.5,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CENTRAL PATRICIA",
        "Province": "ONTARIO",
        "Climate ID": "6011305",
        "Station ID": 3915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.5,
        "Longitude (Decimal Degrees)": -90.15,
        "Latitude": 513000000,
        "Longitude": -900900000,
        "Elevation (m)": 345,
        "First Year": 1953,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1978,
        "MLY First Year": 1953,
        "MLY Last Year": 1978
      },
      {
        "Name": "DONA LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6012064",
        "Station ID": 3916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.4,
        "Longitude (Decimal Degrees)": -90.12,
        "Latitude": 512400000,
        "Longitude": -900700000,
        "Elevation (m)": 382,
        "First Year": 1990,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1990,
        "MLY First Year": 1990,
        "MLY Last Year": 1990
      },
      {
        "Name": "EAR FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6012198",
        "Station ID": 3917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -93.22,
        "Latitude": 503800000,
        "Longitude": -931300000,
        "Elevation (m)": 360.9,
        "First Year": 1928,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1999,
        "MLY First Year": 1928,
        "MLY Last Year": 1999
      },
      {
        "Name": "EAR FALLS (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6012199",
        "Station ID": 27865,
        "WMO ID": 71295,
        "TC ID": "XEA",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -93.22,
        "Latitude": 503750000,
        "Longitude": -931317000,
        "Elevation (m)": 362.6,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2006
      },
      {
        "Name": "EAST PEN ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6012227",
        "Station ID": 7370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.77,
        "Longitude (Decimal Degrees)": -88.78,
        "Latitude": 564600000,
        "Longitude": -884700000,
        "Elevation (m)": 11,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": 1974,
        "HLY Last Year": 1974,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT HOPE",
        "Province": "ONTARIO",
        "Climate ID": "6012490",
        "Station ID": 3918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.55,
        "Longitude (Decimal Degrees)": -87.97,
        "Latitude": 513300000,
        "Longitude": -875800000,
        "Elevation (m)": null,
        "First Year": 1891,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1930,
        "MLY First Year": 1891,
        "MLY Last Year": 1930
      },
      {
        "Name": "FORT SEVERN A",
        "Province": "ONTARIO",
        "Climate ID": "6012500",
        "Station ID": 45447,
        "WMO ID": null,
        "TC ID": "YER",
        "Latitude (Decimal Degrees)": 56.02,
        "Longitude (Decimal Degrees)": -87.68,
        "Latitude": 560108000,
        "Longitude": -874034000,
        "Elevation (m)": 15.8,
        "First Year": 2006,
        "Last Year": 2013,
        "HLY First Year": 2006,
        "HLY Last Year": 2013,
        "DLY First Year": 2006,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT SEVERN A",
        "Province": "ONTARIO",
        "Climate ID": "6012501",
        "Station ID": 51219,
        "WMO ID": 71099,
        "TC ID": "YER",
        "Latitude (Decimal Degrees)": 56.02,
        "Longitude (Decimal Degrees)": -87.68,
        "Latitude": 560108000,
        "Longitude": -874034000,
        "Elevation (m)": 15.8,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LANSDOWNE HOUSE",
        "Province": "ONTARIO",
        "Climate ID": "6014350",
        "Station ID": 3919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -87.88,
        "Latitude": 521400000,
        "Longitude": -875300000,
        "Elevation (m)": 254.5,
        "First Year": 1941,
        "Last Year": 1989,
        "HLY First Year": 1953,
        "HLY Last Year": 1989,
        "DLY First Year": 1941,
        "DLY Last Year": 1989,
        "MLY First Year": 1941,
        "MLY Last Year": 1989
      },
      {
        "Name": "LANSDOWNE HOUSE A",
        "Province": "ONTARIO",
        "Climate ID": "6014351",
        "Station ID": 51237,
        "WMO ID": null,
        "TC ID": "YLH",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -87.93,
        "Latitude": 521144000,
        "Longitude": -875603000,
        "Elevation (m)": 254.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LANSDOWNE HOUSE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6014353",
        "Station ID": 10244,
        "WMO ID": 71846,
        "TC ID": "WLF",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -87.94,
        "Latitude": 521146000,
        "Longitude": -875610000,
        "Elevation (m)": 253.4,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "LANSDOWNE HOUSE A",
        "Province": "ONTARIO",
        "Climate ID": "6014355",
        "Station ID": 43910,
        "WMO ID": null,
        "TC ID": "YLH",
        "Latitude (Decimal Degrees)": 52.2,
        "Longitude (Decimal Degrees)": -87.94,
        "Latitude": 521145000,
        "Longitude": -875610000,
        "Elevation (m)": 254.2,
        "First Year": 2005,
        "Last Year": 2013,
        "HLY First Year": 2005,
        "HLY Last Year": 2013,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MUSKRAT DAM A",
        "Province": "ONTARIO",
        "Climate ID": "6015025",
        "Station ID": 44483,
        "WMO ID": 71755,
        "TC ID": "ZMD",
        "Latitude (Decimal Degrees)": 53.44,
        "Longitude (Decimal Degrees)": -91.76,
        "Latitude": 532629000,
        "Longitude": -914546000,
        "Elevation (m)": 277.7,
        "First Year": 2005,
        "Last Year": 2012,
        "HLY First Year": 2005,
        "HLY Last Year": 2012,
        "DLY First Year": 2006,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MUSKRAT DAM",
        "Province": "ONTARIO",
        "Climate ID": "6015026",
        "Station ID": 50722,
        "WMO ID": 71678,
        "TC ID": "ZMD",
        "Latitude (Decimal Degrees)": 53.44,
        "Longitude (Decimal Degrees)": -91.76,
        "Latitude": 532629000,
        "Longitude": -914546000,
        "Elevation (m)": 277.7,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEAWANUCK (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6016295",
        "Station ID": 10197,
        "WMO ID": 71434,
        "TC ID": "WWN",
        "Latitude (Decimal Degrees)": 54.98,
        "Longitude (Decimal Degrees)": -85.43,
        "Latitude": 545900000,
        "Longitude": -852600000,
        "Elevation (m)": 52.7,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "PEAWANUCK A",
        "Province": "ONTARIO",
        "Climate ID": "6016298",
        "Station ID": 52878,
        "WMO ID": null,
        "TC ID": "YPO",
        "Latitude (Decimal Degrees)": 54.99,
        "Longitude (Decimal Degrees)": -85.44,
        "Latitude": 545917000,
        "Longitude": -852636000,
        "Elevation (m)": 52.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PICKLE LAKE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6016525",
        "Station ID": 3920,
        "WMO ID": 71835,
        "TC ID": "WPL",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -90.22,
        "Latitude": 512657001,
        "Longitude": -901304007,
        "Elevation (m)": 390.8,
        "First Year": 1930,
        "Last Year": 2022,
        "HLY First Year": 1955,
        "HLY Last Year": 2022,
        "DLY First Year": 1930,
        "DLY Last Year": 2022,
        "MLY First Year": 1930,
        "MLY Last Year": 2006
      },
      {
        "Name": "PICKLE LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6016527",
        "Station ID": 3921,
        "WMO ID": 71845,
        "TC ID": "YPL",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -90.21,
        "Latitude": 512647000,
        "Longitude": -901251000,
        "Elevation (m)": 386.2,
        "First Year": 1990,
        "Last Year": 2012,
        "HLY First Year": 1990,
        "HLY Last Year": 2012,
        "DLY First Year": 1990,
        "DLY Last Year": 2012,
        "MLY First Year": 1990,
        "MLY Last Year": 2012
      },
      {
        "Name": "PICKLE LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6016528",
        "Station ID": 52418,
        "WMO ID": null,
        "TC ID": "YPL",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -90.21,
        "Latitude": 512647000,
        "Longitude": -901251000,
        "Elevation (m)": 386.2,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PICKLE LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6016529",
        "Station ID": 50478,
        "WMO ID": 71845,
        "TC ID": "YPL",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -90.21,
        "Latitude": 512647000,
        "Longitude": -901251000,
        "Elevation (m)": 386.2,
        "First Year": 2012,
        "Last Year": 2019,
        "HLY First Year": 2012,
        "HLY Last Year": 2019,
        "DLY First Year": 2012,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RAT RAPIDS",
        "Province": "ONTARIO",
        "Climate ID": "6016890",
        "Station ID": 3922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.2,
        "Longitude (Decimal Degrees)": -90.23,
        "Latitude": 511200000,
        "Longitude": -901400000,
        "Elevation (m)": 374.9,
        "First Year": 1934,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1953,
        "MLY First Year": 1934,
        "MLY Last Year": 1953
      },
      {
        "Name": "RED LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6016970",
        "Station ID": 50248,
        "WMO ID": 71854,
        "TC ID": "YRL",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -93.79,
        "Latitude": 510402000,
        "Longitude": -934735000,
        "Elevation (m)": 385.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RED LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6016971",
        "Station ID": 52419,
        "WMO ID": null,
        "TC ID": "YRL",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -93.79,
        "Latitude": 510402000,
        "Longitude": -934735000,
        "Elevation (m)": 385.9,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RED LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6016974",
        "Station ID": 10900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -93.8,
        "Latitude": 510400000,
        "Longitude": -934800000,
        "Elevation (m)": 383.1,
        "First Year": 1994,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RED LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6016975",
        "Station ID": 3923,
        "WMO ID": 71854,
        "TC ID": "YRL",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -93.79,
        "Latitude": 510401000,
        "Longitude": -934735000,
        "Elevation (m)": 385.88,
        "First Year": 1930,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1930,
        "DLY Last Year": 2012,
        "MLY First Year": 1930,
        "MLY Last Year": 2012
      },
      {
        "Name": "RED LAKE FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6016979",
        "Station ID": 3924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -93.82,
        "Latitude": 510400000,
        "Longitude": -934900000,
        "Elevation (m)": 375.5,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "SANDY LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6017400",
        "Station ID": 45088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.06,
        "Longitude (Decimal Degrees)": -93.34,
        "Latitude": 530351000,
        "Longitude": -932040000,
        "Elevation (m)": 289.9,
        "First Year": 2006,
        "Last Year": 2012,
        "HLY First Year": 2006,
        "HLY Last Year": 2012,
        "DLY First Year": 2006,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANDY LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6017401",
        "Station ID": 50723,
        "WMO ID": 71587,
        "TC ID": "ZSJ",
        "Latitude (Decimal Degrees)": 53.06,
        "Longitude (Decimal Degrees)": -93.34,
        "Latitude": 530351000,
        "Longitude": -932040000,
        "Elevation (m)": 289.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WINISK",
        "Province": "ONTARIO",
        "Climate ID": "6019548",
        "Station ID": 3925,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.27,
        "Longitude (Decimal Degrees)": -85.22,
        "Latitude": 551600000,
        "Longitude": -851300000,
        "Elevation (m)": 12.2,
        "First Year": 1968,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1979,
        "MLY First Year": 1968,
        "MLY Last Year": 1978
      },
      {
        "Name": "WINISK (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6019549",
        "Station ID": 7578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.23,
        "Longitude (Decimal Degrees)": -85.12,
        "Latitude": 551400000,
        "Longitude": -850700000,
        "Elevation (m)": 13,
        "First Year": 1981,
        "Last Year": 1986,
        "HLY First Year": 1981,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WINISK A",
        "Province": "ONTARIO",
        "Climate ID": "6019550",
        "Station ID": 3926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.23,
        "Longitude (Decimal Degrees)": -85.12,
        "Latitude": 551400000,
        "Longitude": -850700000,
        "Elevation (m)": 12.8,
        "First Year": 1957,
        "Last Year": 1965,
        "HLY First Year": 1957,
        "HLY Last Year": 1965,
        "DLY First Year": 1959,
        "DLY Last Year": 1965,
        "MLY First Year": 1959,
        "MLY Last Year": 1965
      },
      {
        "Name": "EKWAN RIVER (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "601BKEK",
        "Station ID": 10910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.82,
        "Longitude (Decimal Degrees)": -84.92,
        "Latitude": 534900000,
        "Longitude": -845500000,
        "Elevation (m)": 96,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SEVERN RIVER (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "601P68B",
        "Station ID": 10726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.38,
        "Longitude (Decimal Degrees)": -88.33,
        "Latitude": 552300000,
        "Longitude": -882000000,
        "Elevation (m)": 56.4,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ATIKOKAN",
        "Province": "ONTARIO",
        "Climate ID": "6020379",
        "Station ID": 3929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -91.62,
        "Latitude": 484500000,
        "Longitude": -913700000,
        "Elevation (m)": 395.3,
        "First Year": 1966,
        "Last Year": 1988,
        "HLY First Year": 1966,
        "HLY Last Year": 1988,
        "DLY First Year": 1966,
        "DLY Last Year": 1988,
        "MLY First Year": 1966,
        "MLY Last Year": 1988
      },
      {
        "Name": "ATIKOKAN CLI",
        "Province": "ONTARIO",
        "Climate ID": "6020381",
        "Station ID": 3930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -91.63,
        "Latitude": 484400000,
        "Longitude": -913800000,
        "Elevation (m)": 391.4,
        "First Year": 1914,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1971,
        "MLY First Year": 1914,
        "MLY Last Year": 1971
      },
      {
        "Name": "ATIKOKAN MARMION",
        "Province": "ONTARIO",
        "Climate ID": "6020384",
        "Station ID": 3931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.8,
        "Longitude (Decimal Degrees)": -91.58,
        "Latitude": 484800000,
        "Longitude": -913500000,
        "Elevation (m)": 442,
        "First Year": 1979,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2007,
        "MLY First Year": 1979,
        "MLY Last Year": 2006
      },
      {
        "Name": "BARWICK",
        "Province": "ONTARIO",
        "Climate ID": "6020559",
        "Station ID": 3932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -93.97,
        "Latitude": 483800000,
        "Longitude": -935800000,
        "Elevation (m)": 335,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2006
      },
      {
        "Name": "BERGLAND",
        "Province": "ONTARIO",
        "Climate ID": "6020727",
        "Station ID": 3933,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -94.35,
        "Latitude": 485700000,
        "Longitude": -942100000,
        "Elevation (m)": 363.9,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "ATIKOKAN (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6020LPQ",
        "Station ID": 10220,
        "WMO ID": 71747,
        "TC ID": "WCH",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -91.63,
        "Latitude": 484540000,
        "Longitude": -913741000,
        "Elevation (m)": 389.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "DEVLIN",
        "Province": "ONTARIO",
        "Climate ID": "6022010",
        "Station ID": 3934,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -93.75,
        "Latitude": 483100000,
        "Longitude": -934500000,
        "Elevation (m)": 335,
        "First Year": 1978,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1992,
        "MLY First Year": 1978,
        "MLY Last Year": 1992
      },
      {
        "Name": "DEVLIN BELLAMY",
        "Province": "ONTARIO",
        "Climate ID": "6022011",
        "Station ID": 3935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -93.67,
        "Latitude": 483800000,
        "Longitude": -934000000,
        "Elevation (m)": 347.2,
        "First Year": 1978,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1992,
        "MLY First Year": 1979,
        "MLY Last Year": 1992
      },
      {
        "Name": "EMO",
        "Province": "ONTARIO",
        "Climate ID": "6022300",
        "Station ID": 3936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -93.8,
        "Latitude": 483800000,
        "Longitude": -934800000,
        "Elevation (m)": 337.1,
        "First Year": 1922,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1968,
        "MLY First Year": 1922,
        "MLY Last Year": 1968
      },
      {
        "Name": "EMO 2",
        "Province": "ONTARIO",
        "Climate ID": "6022301",
        "Station ID": 3937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -93.88,
        "Latitude": 484500000,
        "Longitude": -935300000,
        "Elevation (m)": 335.3,
        "First Year": 1957,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1968,
        "MLY First Year": 1957,
        "MLY Last Year": 1968
      },
      {
        "Name": "FORT FRANCES RCS",
        "Province": "ONTARIO",
        "Climate ID": "6022474",
        "Station ID": 46507,
        "WMO ID": 71962,
        "TC ID": "TAG",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -93.44,
        "Latitude": 483904000,
        "Longitude": -932620002,
        "Elevation (m)": 342,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORT FRANCES",
        "Province": "ONTARIO",
        "Climate ID": "6022475",
        "Station ID": 3938,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -93.42,
        "Latitude": 483700000,
        "Longitude": -932500000,
        "Elevation (m)": 343.2,
        "First Year": 1892,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1892,
        "DLY Last Year": 1995,
        "MLY First Year": 1892,
        "MLY Last Year": 1995
      },
      {
        "Name": "FORT FRANCES A",
        "Province": "ONTARIO",
        "Climate ID": "6022476",
        "Station ID": 3939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -93.43,
        "Latitude": 483900000,
        "Longitude": -932600000,
        "Elevation (m)": 342,
        "First Year": 1976,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2011,
        "MLY First Year": 1976,
        "MLY Last Year": 2006
      },
      {
        "Name": "FORT FRANCES CDA EPF",
        "Province": "ONTARIO",
        "Climate ID": "6022480",
        "Station ID": 3940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -93.52,
        "Latitude": 483300000,
        "Longitude": -933100000,
        "Elevation (m)": 353.6,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "FORT FRANCES FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6022487",
        "Station ID": 3941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -93.4,
        "Latitude": 483700000,
        "Longitude": -932400000,
        "Elevation (m)": 342,
        "First Year": 1943,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1965,
        "MLY First Year": 1943,
        "MLY Last Year": 1964
      },
      {
        "Name": "KAWENE",
        "Province": "ONTARIO",
        "Climate ID": "6024010",
        "Station ID": 3942,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -91.2,
        "Latitude": 484400000,
        "Longitude": -911200000,
        "Elevation (m)": 454.2,
        "First Year": 1935,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1951,
        "MLY First Year": 1935,
        "MLY Last Year": 1951
      },
      {
        "Name": "MINE CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6025203",
        "Station ID": 3943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -92.62,
        "Latitude": 484600000,
        "Longitude": -923700000,
        "Elevation (m)": 342.9,
        "First Year": 1914,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2005,
        "MLY First Year": 1914,
        "MLY Last Year": 2005
      },
      {
        "Name": "MINE CENTRE SOUTHWEST",
        "Province": "ONTARIO",
        "Climate ID": "6025205",
        "Station ID": 44343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -92.62,
        "Latitude": 484535060,
        "Longitude": -923722000,
        "Elevation (m)": 361,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "MOOSE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6025407",
        "Station ID": 3944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -91.6,
        "Latitude": 485000000,
        "Longitude": -913600000,
        "Elevation (m)": 396.2,
        "First Year": 1950,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1972,
        "MLY First Year": 1950,
        "MLY Last Year": 1972
      },
      {
        "Name": "RAINY RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6026852",
        "Station ID": 3945,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.72,
        "Longitude (Decimal Degrees)": -94.53,
        "Latitude": 484300000,
        "Longitude": -943200000,
        "Elevation (m)": 316,
        "First Year": 1914,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2003,
        "MLY First Year": 1916,
        "MLY Last Year": 2003
      },
      {
        "Name": "RAINY RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6026853",
        "Station ID": 3946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -94.67,
        "Latitude": 484600000,
        "Longitude": -944000000,
        "Elevation (m)": 328.9,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "SLEEMAN",
        "Province": "ONTARIO",
        "Climate ID": "6027825",
        "Station ID": 3947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.72,
        "Longitude (Decimal Degrees)": -94.42,
        "Latitude": 484300000,
        "Longitude": -942500000,
        "Elevation (m)": 335.3,
        "First Year": 1964,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1991,
        "MLY First Year": 1964,
        "MLY Last Year": 1991
      },
      {
        "Name": "STRATTON",
        "Province": "ONTARIO",
        "Climate ID": "6028125",
        "Station ID": 3948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -94.05,
        "Latitude": 484700000,
        "Longitude": -940300000,
        "Elevation (m)": 358.1,
        "First Year": 1970,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1986,
        "MLY First Year": 1970,
        "MLY Last Year": 1986
      },
      {
        "Name": "STRATTON ROEN",
        "Province": "ONTARIO",
        "Climate ID": "6028127",
        "Station ID": 3949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -94.02,
        "Latitude": 485100000,
        "Longitude": -940100000,
        "Elevation (m)": 347.2,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "STRATTON ROMYN",
        "Province": "ONTARIO",
        "Climate ID": "6028128",
        "Station ID": 3950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -94.17,
        "Latitude": 484200000,
        "Longitude": -941000000,
        "Elevation (m)": 366.1,
        "First Year": 1978,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2004,
        "MLY First Year": 1978,
        "MLY Last Year": 2004
      },
      {
        "Name": "EMO HOSKINS",
        "Province": "ONTARIO",
        "Climate ID": "602B300",
        "Station ID": 3927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -93.85,
        "Latitude": 484500000,
        "Longitude": -935100000,
        "Elevation (m)": 351.1,
        "First Year": 1978,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1993,
        "MLY First Year": 1978,
        "MLY Last Year": 1993
      },
      {
        "Name": "RAINY RIVER-COOPER",
        "Province": "ONTARIO",
        "Climate ID": "602FQ5L",
        "Station ID": 6897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -94.62,
        "Latitude": 484400000,
        "Longitude": -943700000,
        "Elevation (m)": 323,
        "First Year": 1991,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1993,
        "MLY First Year": 1991,
        "MLY Last Year": 1993
      },
      {
        "Name": "EMO RADBOURNE",
        "Province": "ONTARIO",
        "Climate ID": "602K300",
        "Station ID": 3928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -93.83,
        "Latitude": 484100000,
        "Longitude": -935000000,
        "Elevation (m)": 350,
        "First Year": 1978,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2002,
        "MLY First Year": 1978,
        "MLY Last Year": 2002
      },
      {
        "Name": "BARCLAY",
        "Province": "ONTARIO",
        "Climate ID": "6030488",
        "Station ID": 3951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -92.82,
        "Latitude": 495000000,
        "Longitude": -924900000,
        "Elevation (m)": 381.3,
        "First Year": 1885,
        "Last Year": 1896,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1896,
        "MLY First Year": 1885,
        "MLY Last Year": 1896
      },
      {
        "Name": "DRYDEN",
        "Province": "ONTARIO",
        "Climate ID": "6032117",
        "Station ID": 3952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -92.83,
        "Latitude": 494700000,
        "Longitude": -925000000,
        "Elevation (m)": 371.9,
        "First Year": 1914,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1997,
        "MLY First Year": 1914,
        "MLY Last Year": 1997
      },
      {
        "Name": "DRYDEN A",
        "Province": "ONTARIO",
        "Climate ID": "6032119",
        "Station ID": 3953,
        "WMO ID": null,
        "TC ID": "YHD",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -92.75,
        "Latitude": 495000000,
        "Longitude": -924500000,
        "Elevation (m)": 412.7,
        "First Year": 1969,
        "Last Year": 2005,
        "HLY First Year": 1969,
        "HLY Last Year": 2005,
        "DLY First Year": 1970,
        "DLY Last Year": 2005,
        "MLY First Year": 1970,
        "MLY Last Year": 2004
      },
      {
        "Name": "DRYDEN A (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6032120",
        "Station ID": 29368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -92.74,
        "Latitude": 494954000,
        "Longitude": -924439000,
        "Elevation (m)": 412.7,
        "First Year": 1999,
        "Last Year": 2010,
        "HLY First Year": 1999,
        "HLY Last Year": 2010,
        "DLY First Year": 1999,
        "DLY Last Year": 2010,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "DRYDEN FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6032122",
        "Station ID": 3954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -92.85,
        "Latitude": 494900000,
        "Longitude": -925100000,
        "Elevation (m)": 373.1,
        "First Year": 1960,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1968,
        "MLY First Year": 1960,
        "MLY Last Year": 1968
      },
      {
        "Name": "DRYDEN REGIONAL",
        "Province": "ONTARIO",
        "Climate ID": "6032125",
        "Station ID": 48869,
        "WMO ID": 71527,
        "TC ID": "YHD",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -92.74,
        "Latitude": 494939000,
        "Longitude": -924439000,
        "Elevation (m)": 412.7,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EAGLE RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6032192",
        "Station ID": 3955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -93.22,
        "Latitude": 494900000,
        "Longitude": -931300000,
        "Elevation (m)": 350,
        "First Year": 1986,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1988,
        "MLY First Year": 1986,
        "MLY Last Year": 1988
      },
      {
        "Name": "GRASSY NARROWS",
        "Province": "ONTARIO",
        "Climate ID": "6033505",
        "Station ID": 44163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.14,
        "Longitude (Decimal Degrees)": -93.98,
        "Latitude": 500827009,
        "Longitude": -935902003,
        "Elevation (m)": 322,
        "First Year": 2005,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2009,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "IGNACE",
        "Province": "ONTARIO",
        "Climate ID": "6033690",
        "Station ID": 3956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -91.65,
        "Latitude": 492500000,
        "Longitude": -913900000,
        "Elevation (m)": 446.5,
        "First Year": 1889,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 1971,
        "MLY First Year": 1889,
        "MLY Last Year": 1971
      },
      {
        "Name": "IGNACE TCPL 58",
        "Province": "ONTARIO",
        "Climate ID": "6033697",
        "Station ID": 3957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -92,
        "Latitude": 492900000,
        "Longitude": -920000000,
        "Elevation (m)": 473,
        "First Year": 1969,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1993,
        "MLY First Year": 1969,
        "MLY Last Year": 1993
      },
      {
        "Name": "INGOLF",
        "Province": "ONTARIO",
        "Climate ID": "6033785",
        "Station ID": 3958,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -95.15,
        "Latitude": 494900000,
        "Longitude": -950900000,
        "Elevation (m)": 363,
        "First Year": 1927,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1941,
        "MLY First Year": 1927,
        "MLY Last Year": 1941
      },
      {
        "Name": "KENORA",
        "Province": "ONTARIO",
        "Climate ID": "6034070",
        "Station ID": 3959,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -94.53,
        "Latitude": 494800000,
        "Longitude": -943200000,
        "Elevation (m)": 335.9,
        "First Year": 1883,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1939,
        "MLY First Year": 1883,
        "MLY Last Year": 1939
      },
      {
        "Name": "KENORA RCS",
        "Province": "ONTARIO",
        "Climate ID": "6034073",
        "Station ID": 47307,
        "WMO ID": 71151,
        "TC ID": "TKR",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -94.38,
        "Latitude": 494726000,
        "Longitude": -942240000,
        "Elevation (m)": 412.7,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KENORA A",
        "Province": "ONTARIO",
        "Climate ID": "6034075",
        "Station ID": 3960,
        "WMO ID": 71850,
        "TC ID": "YQK",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -94.37,
        "Latitude": 494725005,
        "Longitude": -942155003,
        "Elevation (m)": 409.65,
        "First Year": 1938,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1938,
        "DLY Last Year": 2013,
        "MLY First Year": 1938,
        "MLY Last Year": 2013
      },
      {
        "Name": "KENORA A",
        "Province": "ONTARIO",
        "Climate ID": "6034076",
        "Station ID": 51137,
        "WMO ID": 71850,
        "TC ID": "YQK",
        "Latitude (Decimal Degrees)": 49.79,
        "Longitude (Decimal Degrees)": -94.36,
        "Latitude": 494718000,
        "Longitude": -942147000,
        "Elevation (m)": 409.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KENORA TCPL 49",
        "Province": "ONTARIO",
        "Climate ID": "6034077",
        "Station ID": 3961,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -94.48,
        "Latitude": 494700000,
        "Longitude": -942900000,
        "Elevation (m)": 340.2,
        "First Year": 1969,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1991,
        "MLY First Year": 1970,
        "MLY Last Year": 1991
      },
      {
        "Name": "LAC SEUL",
        "Province": "ONTARIO",
        "Climate ID": "6034282",
        "Station ID": 3962,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -92.2,
        "Latitude": 501700000,
        "Longitude": -921200000,
        "Elevation (m)": 352,
        "First Year": 1914,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1941,
        "MLY First Year": 1914,
        "MLY Last Year": 1941
      },
      {
        "Name": "MARTIN",
        "Province": "ONTARIO",
        "Climate ID": "6035000",
        "Station ID": 3963,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -91.13,
        "Latitude": 491500000,
        "Longitude": -910800000,
        "Elevation (m)": 474,
        "First Year": 1957,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1962,
        "MLY First Year": 1957,
        "MLY Last Year": 1961
      },
      {
        "Name": "MARTIN TCPL 60",
        "Province": "ONTARIO",
        "Climate ID": "6035002",
        "Station ID": 3964,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -91.23,
        "Latitude": 491700000,
        "Longitude": -911400000,
        "Elevation (m)": 470.6,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1969,
        "MLY Last Year": 1984
      },
      {
        "Name": "MINAKI",
        "Province": "ONTARIO",
        "Climate ID": "6035190",
        "Station ID": 3965,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -94.67,
        "Latitude": 495900000,
        "Longitude": -944000000,
        "Elevation (m)": 335.3,
        "First Year": 1930,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1967,
        "MLY First Year": 1930,
        "MLY Last Year": 1967
      },
      {
        "Name": "RAWSON LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6036904",
        "Station ID": 3966,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.65,
        "Longitude (Decimal Degrees)": -93.72,
        "Latitude": 493900000,
        "Longitude": -934300000,
        "Elevation (m)": 358.1,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2006
      },
      {
        "Name": "RAWSON LAKE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6036907",
        "Station ID": 30455,
        "WMO ID": 71855,
        "TC ID": "TRA",
        "Latitude (Decimal Degrees)": 49.66,
        "Longitude (Decimal Degrees)": -93.73,
        "Latitude": 493951001,
        "Longitude": -934358002,
        "Elevation (m)": 435,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROYAL ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6037201",
        "Station ID": 10899,
        "WMO ID": 71859,
        "TC ID": "WTX",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -94.76,
        "Latitude": 492807000,
        "Longitude": -944547000,
        "Elevation (m)": 329,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIOUX LOOKOUT",
        "Province": "ONTARIO",
        "Climate ID": "6037768",
        "Station ID": 3967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -91.87,
        "Latitude": 500800000,
        "Longitude": -915200000,
        "Elevation (m)": 364.8,
        "First Year": 1914,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1932,
        "MLY First Year": 1914,
        "MLY Last Year": 1932
      },
      {
        "Name": "SIOUX LOOKOUT",
        "Province": "ONTARIO",
        "Climate ID": "6037770",
        "Station ID": 3968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.13,
        "Longitude (Decimal Degrees)": -91.87,
        "Latitude": 500800000,
        "Longitude": -915200000,
        "Elevation (m)": 374,
        "First Year": 1930,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1938,
        "MLY First Year": 1930,
        "MLY Last Year": 1938
      },
      {
        "Name": "SIOUX LOOKOUT HWOS",
        "Province": "ONTARIO",
        "Climate ID": "6037773",
        "Station ID": 51017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -91.9,
        "Latitude": 500700000,
        "Longitude": -915400000,
        "Elevation (m)": 383.4,
        "First Year": 2013,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2013,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIOUX LOOKOUT A",
        "Province": "ONTARIO",
        "Climate ID": "6037775",
        "Station ID": 3969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.12,
        "Longitude (Decimal Degrees)": -91.9,
        "Latitude": 500700000,
        "Longitude": -915400000,
        "Elevation (m)": 383.1,
        "First Year": 1938,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1938,
        "DLY Last Year": 2013,
        "MLY First Year": 1938,
        "MLY Last Year": 2013
      },
      {
        "Name": "SIOUX LOOKOUT A",
        "Province": "ONTARIO",
        "Climate ID": "6037776",
        "Station ID": 51138,
        "WMO ID": 71842,
        "TC ID": "YXL",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -91.91,
        "Latitude": 500651000,
        "Longitude": -915429000,
        "Elevation (m)": 383.1,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIOUX LOOKOUT AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6037800",
        "Station ID": 54605,
        "WMO ID": 73017,
        "TC ID": "OSL",
        "Latitude (Decimal Degrees)": 50.11,
        "Longitude (Decimal Degrees)": -91.91,
        "Latitude": 500651000,
        "Longitude": -915420000,
        "Elevation (m)": 338.1,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIOUX NARROWS",
        "Province": "ONTARIO",
        "Climate ID": "6037803",
        "Station ID": 3970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -93.97,
        "Latitude": 492900000,
        "Longitude": -935800000,
        "Elevation (m)": 410.6,
        "First Year": 1933,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1956,
        "MLY First Year": 1933,
        "MLY Last Year": 1956
      },
      {
        "Name": "SIOUX NARROWS",
        "Province": "ONTARIO",
        "Climate ID": "6037804",
        "Station ID": 3971,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -94.07,
        "Latitude": 492300000,
        "Longitude": -940400000,
        "Elevation (m)": 342.9,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "VALORA",
        "Province": "ONTARIO",
        "Climate ID": "6039130",
        "Station ID": 3972,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -91.22,
        "Latitude": 494500000,
        "Longitude": -911300000,
        "Elevation (m)": 426.7,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1957,
        "MLY Last Year": 1959
      },
      {
        "Name": "VERMILION BAY TCPL 52",
        "Province": "ONTARIO",
        "Climate ID": "6039136",
        "Station ID": 3973,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -93.63,
        "Latitude": 495000000,
        "Longitude": -933800000,
        "Elevation (m)": 385,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1969,
        "MLY Last Year": 1984
      },
      {
        "Name": "WATCOMB",
        "Province": "ONTARIO",
        "Climate ID": "6039351",
        "Station ID": 3974,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.9,
        "Longitude (Decimal Degrees)": -91.28,
        "Latitude": 495400000,
        "Longitude": -911700000,
        "Elevation (m)": 411.5,
        "First Year": 1969,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "WHITEFISH BAY",
        "Province": "ONTARIO",
        "Climate ID": "6039465",
        "Station ID": 3975,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -93.97,
        "Latitude": 492900000,
        "Longitude": -935800000,
        "Elevation (m)": 410.6,
        "First Year": 1914,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1946,
        "MLY First Year": 1914,
        "MLY Last Year": 1946
      },
      {
        "Name": "ABITIBI CAMP 11",
        "Province": "ONTARIO",
        "Climate ID": "6040010",
        "Station ID": 3980,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -89.35,
        "Latitude": 485600000,
        "Longitude": -892100000,
        "Elevation (m)": 442,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "ABITIBI CAMP 11",
        "Province": "ONTARIO",
        "Climate ID": "6040011",
        "Station ID": 3981,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -89.12,
        "Latitude": 485100000,
        "Longitude": -890700000,
        "Elevation (m)": 491,
        "First Year": 1983,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1988,
        "MLY First Year": 1983,
        "MLY Last Year": 1988
      },
      {
        "Name": "ABITIBI CAMP 228",
        "Province": "ONTARIO",
        "Climate ID": "6040018",
        "Station ID": 3982,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -89.25,
        "Latitude": 485600000,
        "Longitude": -891500000,
        "Elevation (m)": 472.4,
        "First Year": 1969,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1978,
        "MLY First Year": 1969,
        "MLY Last Year": 1978
      },
      {
        "Name": "ABITIBI CAMP 230",
        "Province": "ONTARIO",
        "Climate ID": "6040020",
        "Station ID": 3983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -89.37,
        "Latitude": 492100000,
        "Longitude": -892200000,
        "Elevation (m)": 457.2,
        "First Year": 1969,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1991,
        "MLY First Year": 1969,
        "MLY Last Year": 1991
      },
      {
        "Name": "ABITIBI CAMP 300",
        "Province": "ONTARIO",
        "Climate ID": "6040022",
        "Station ID": 3984,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.63,
        "Longitude (Decimal Degrees)": -89.75,
        "Latitude": 493800000,
        "Longitude": -894500000,
        "Elevation (m)": 426.7,
        "First Year": 1978,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1986,
        "MLY First Year": 1978,
        "MLY Last Year": 1986
      },
      {
        "Name": "AGUASABON",
        "Province": "ONTARIO",
        "Climate ID": "6040081",
        "Station ID": 3985,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -87.17,
        "Latitude": 484700000,
        "Longitude": -871000000,
        "Elevation (m)": 189,
        "First Year": 1950,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1972,
        "MLY First Year": 1950,
        "MLY Last Year": 1972
      },
      {
        "Name": "ARMSTRONG",
        "Province": "ONTARIO",
        "Climate ID": "6040322",
        "Station ID": 3986,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -89.15,
        "Latitude": 501700000,
        "Longitude": -890900000,
        "Elevation (m)": 341.7,
        "First Year": 1926,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1947,
        "MLY First Year": 1926,
        "MLY Last Year": 1947
      },
      {
        "Name": "ARMSTRONG (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6040325",
        "Station ID": 3987,
        "WMO ID": 71841,
        "TC ID": "WYW",
        "Latitude (Decimal Degrees)": 50.29,
        "Longitude (Decimal Degrees)": -88.91,
        "Latitude": 501737000,
        "Longitude": -885419000,
        "Elevation (m)": 322.5,
        "First Year": 1938,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1938,
        "DLY Last Year": 2022,
        "MLY First Year": 1938,
        "MLY Last Year": 1982
      },
      {
        "Name": "ARMSTRONG (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6040326",
        "Station ID": 7581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -89.03,
        "Latitude": 501800000,
        "Longitude": -890200000,
        "Elevation (m)": 350.5,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": 1994,
        "DLY Last Year": 1995,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARMSTRONG A",
        "Province": "ONTARIO",
        "Climate ID": "6040327",
        "Station ID": 52900,
        "WMO ID": null,
        "TC ID": "YYW",
        "Latitude (Decimal Degrees)": 50.29,
        "Longitude (Decimal Degrees)": -88.91,
        "Latitude": 501725000,
        "Longitude": -885435000,
        "Elevation (m)": 322.5,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARMSTRONG JELLIEN",
        "Province": "ONTARIO",
        "Climate ID": "6040330",
        "Station ID": 3988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -89.1,
        "Latitude": 501500000,
        "Longitude": -890600000,
        "Elevation (m)": 341.4,
        "First Year": 1987,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1992,
        "MLY First Year": 1987,
        "MLY Last Year": 1992
      },
      {
        "Name": "BEARDMORE",
        "Province": "ONTARIO",
        "Climate ID": "6040572",
        "Station ID": 3989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -87.95,
        "Latitude": 493700000,
        "Longitude": -875700000,
        "Elevation (m)": 304.8,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "BLACK STURGEON LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6040785",
        "Station ID": 3990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -88.85,
        "Latitude": 491900000,
        "Longitude": -885100000,
        "Elevation (m)": 253,
        "First Year": 1951,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1968,
        "MLY First Year": 1951,
        "MLY Last Year": 1968
      },
      {
        "Name": "BLACK STURGEON LAKE 2",
        "Province": "ONTARIO",
        "Climate ID": "6040786",
        "Station ID": 3991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -88.8,
        "Latitude": 491800000,
        "Longitude": -884800000,
        "Elevation (m)": 253,
        "First Year": 1968,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1971,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "BLACK STURGEON RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6040790",
        "Station ID": 3992,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -88.62,
        "Latitude": 490400000,
        "Longitude": -883700000,
        "Elevation (m)": 219.5,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "BURCHELL LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6041036",
        "Station ID": 3993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -90.58,
        "Latitude": 483700000,
        "Longitude": -903500000,
        "Elevation (m)": 464.8,
        "First Year": 1962,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1967,
        "MLY First Year": 1962,
        "MLY Last Year": 1967
      },
      {
        "Name": "CAMERON FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6041109",
        "Station ID": 3994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -88.35,
        "Latitude": 490900000,
        "Longitude": -882100000,
        "Elevation (m)": 228.6,
        "First Year": 1924,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1998,
        "MLY First Year": 1924,
        "MLY Last Year": 1998
      },
      {
        "Name": "CAMERON FALLS (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6041110",
        "Station ID": 27674,
        "WMO ID": 71291,
        "TC ID": "XCA",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -88.34,
        "Latitude": 490914000,
        "Longitude": -882041000,
        "Elevation (m)": 232.6,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "CARAMAT",
        "Province": "ONTARIO",
        "Climate ID": "6041193",
        "Station ID": 3995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -85.83,
        "Latitude": 491600000,
        "Longitude": -855000000,
        "Elevation (m)": 338.3,
        "First Year": 1949,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1983,
        "MLY First Year": 1949,
        "MLY Last Year": 1983
      },
      {
        "Name": "CARIBOU ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6041221",
        "Station ID": 3996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.33,
        "Longitude (Decimal Degrees)": -85.83,
        "Latitude": 472000000,
        "Longitude": -855000000,
        "Elevation (m)": 186.5,
        "First Year": 1935,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1988,
        "MLY First Year": 1935,
        "MLY Last Year": 1988
      },
      {
        "Name": "CARIBOU ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6041222",
        "Station ID": 7582,
        "WMO ID": 71433,
        "TC ID": "WCI",
        "Latitude (Decimal Degrees)": 47.33,
        "Longitude (Decimal Degrees)": -85.83,
        "Latitude": 472000000,
        "Longitude": -855000000,
        "Elevation (m)": 187,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DOG LAKE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6042036",
        "Station ID": 3998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -89.62,
        "Latitude": 484200000,
        "Longitude": -893700000,
        "Elevation (m)": 420,
        "First Year": 1923,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1958,
        "MLY First Year": 1923,
        "MLY Last Year": 1958
      },
      {
        "Name": "DOG RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6042045",
        "Station ID": 3999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -89.9,
        "Latitude": 485900000,
        "Longitude": -895400000,
        "Elevation (m)": 448.1,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "DONA",
        "Province": "ONTARIO",
        "Climate ID": "6042063",
        "Station ID": 4000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -89.52,
        "Latitude": 483000000,
        "Longitude": -893100000,
        "Elevation (m)": 317.3,
        "First Year": 1926,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1958,
        "MLY First Year": 1926,
        "MLY Last Year": 1958
      },
      {
        "Name": "DORION TCPL 70",
        "Province": "ONTARIO",
        "Climate ID": "6042067",
        "Station ID": 4001,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -88.52,
        "Latitude": 484900000,
        "Longitude": -883100000,
        "Elevation (m)": 192.6,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1970,
        "MLY Last Year": 1984
      },
      {
        "Name": "GERALDTON",
        "Province": "ONTARIO",
        "Climate ID": "6042715",
        "Station ID": 4002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -86.95,
        "Latitude": 494200000,
        "Longitude": -865700000,
        "Elevation (m)": 330.7,
        "First Year": 1967,
        "Last Year": 1981,
        "HLY First Year": 1967,
        "HLY Last Year": 1981,
        "DLY First Year": 1967,
        "DLY Last Year": 1981,
        "MLY First Year": 1967,
        "MLY Last Year": 1981
      },
      {
        "Name": "GERALDTON A",
        "Province": "ONTARIO",
        "Climate ID": "6042716",
        "Station ID": 4003,
        "WMO ID": 71834,
        "TC ID": "YGQ",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -86.93,
        "Latitude": 494658090,
        "Longitude": -865550050,
        "Elevation (m)": 348.4,
        "First Year": 1981,
        "Last Year": 2015,
        "HLY First Year": 1981,
        "HLY Last Year": 2015,
        "DLY First Year": 1981,
        "DLY Last Year": 2015,
        "MLY First Year": 1981,
        "MLY Last Year": 2015
      },
      {
        "Name": "GERALDTON A",
        "Province": "ONTARIO",
        "Climate ID": "6042717",
        "Station ID": 53519,
        "WMO ID": 71834,
        "TC ID": "YGQ",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -86.94,
        "Latitude": 494643000,
        "Longitude": -865619000,
        "Elevation (m)": 348.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GERALDTON A",
        "Province": "ONTARIO",
        "Climate ID": "6042718",
        "Station ID": 54240,
        "WMO ID": null,
        "TC ID": "YGQ",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -86.99,
        "Latitude": 494643000,
        "Longitude": -865919000,
        "Elevation (m)": 348.4,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GERALDTON AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6042719",
        "Station ID": 54858,
        "WMO ID": 73071,
        "TC ID": "OGE",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -86.93,
        "Latitude": 494651276,
        "Longitude": -865556352,
        "Elevation (m)": 340,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GERALDTON FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6042723",
        "Station ID": 4004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -86.87,
        "Latitude": 494200000,
        "Longitude": -865200000,
        "Elevation (m)": 338.3,
        "First Year": 1948,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1969,
        "MLY First Year": 1948,
        "MLY Last Year": 1969
      },
      {
        "Name": "GERALDTON ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6042755",
        "Station ID": 4005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -86.95,
        "Latitude": 494100000,
        "Longitude": -865700000,
        "Elevation (m)": 338.3,
        "First Year": 1950,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1973,
        "MLY First Year": 1950,
        "MLY Last Year": 1973
      },
      {
        "Name": "GRAHAM A",
        "Province": "ONTARIO",
        "Climate ID": "6042975",
        "Station ID": 4006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -90.58,
        "Latitude": 491600000,
        "Longitude": -903500000,
        "Elevation (m)": 503.2,
        "First Year": 1948,
        "Last Year": 1967,
        "HLY First Year": 1953,
        "HLY Last Year": 1967,
        "DLY First Year": 1948,
        "DLY Last Year": 1967,
        "MLY First Year": 1948,
        "MLY Last Year": 1967
      },
      {
        "Name": "FLINT",
        "Province": "ONTARIO",
        "Climate ID": "6042MJ7",
        "Station ID": 3997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -89.68,
        "Latitude": 482100000,
        "Longitude": -894100000,
        "Elevation (m)": 274,
        "First Year": 1979,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2014,
        "MLY First Year": 1979,
        "MLY Last Year": 2006
      },
      {
        "Name": "HANOVER TOWER",
        "Province": "ONTARIO",
        "Climate ID": "6043330",
        "Station ID": 4007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.4,
        "Longitude (Decimal Degrees)": -87.17,
        "Latitude": 502400000,
        "Longitude": -871000000,
        "Elevation (m)": 335.3,
        "First Year": 1952,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1955,
        "MLY First Year": 1952,
        "MLY Last Year": 1956
      },
      {
        "Name": "HAZELWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6043390",
        "Station ID": 54378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -89.27,
        "Latitude": 482800000,
        "Longitude": -891600000,
        "Elevation (m)": 322.5,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HEMLO BATTLE MOUNTAIN",
        "Province": "ONTARIO",
        "Climate ID": "6043452",
        "Station ID": 4008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -85.88,
        "Latitude": 484200000,
        "Longitude": -855300000,
        "Elevation (m)": 335,
        "First Year": 1985,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2001,
        "MLY First Year": 1985,
        "MLY Last Year": 2001
      },
      {
        "Name": "HERON BAY",
        "Province": "ONTARIO",
        "Climate ID": "6043458",
        "Station ID": 4009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -86.28,
        "Latitude": 484000000,
        "Longitude": -861700000,
        "Elevation (m)": 215.2,
        "First Year": 1886,
        "Last Year": 1920,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1920,
        "MLY First Year": 1886,
        "MLY Last Year": 1920
      },
      {
        "Name": "HERON BAY SOUTH",
        "Province": "ONTARIO",
        "Climate ID": "6043460",
        "Station ID": 4010,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -85.82,
        "Latitude": 485900000,
        "Longitude": -854900000,
        "Elevation (m)": 297.2,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "JARVIS LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6043870",
        "Station ID": 4011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -87.82,
        "Latitude": 491500000,
        "Longitude": -874900000,
        "Elevation (m)": 320,
        "First Year": 1952,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1956,
        "MLY First Year": 1952,
        "MLY Last Year": 1955
      },
      {
        "Name": "KAKABEKA FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6043930",
        "Station ID": 4012,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -89.62,
        "Latitude": 482400000,
        "Longitude": -893700000,
        "Elevation (m)": 278,
        "First Year": 1908,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1977,
        "MLY First Year": 1908,
        "MLY Last Year": 1977
      },
      {
        "Name": "KAMINISTIKWIA",
        "Province": "ONTARIO",
        "Climate ID": "6043949",
        "Station ID": 4013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -89.4,
        "Latitude": 483300000,
        "Longitude": -892400000,
        "Elevation (m)": 457.2,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "KASHABOWIE",
        "Province": "ONTARIO",
        "Climate ID": "6044000",
        "Station ID": 4014,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -90.6,
        "Latitude": 483600000,
        "Longitude": -903600000,
        "Elevation (m)": 466.6,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "KENOGAMI DAM",
        "Province": "ONTARIO",
        "Climate ID": "6044050",
        "Station ID": 4015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -86.47,
        "Latitude": 495500000,
        "Longitude": -862800000,
        "Elevation (m)": 312.4,
        "First Year": 1950,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1958,
        "MLY First Year": 1950,
        "MLY Last Year": 1958
      },
      {
        "Name": "KILLALA LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6044115",
        "Station ID": 4016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -86.47,
        "Latitude": 490900000,
        "Longitude": -862800000,
        "Elevation (m)": 289.6,
        "First Year": 1945,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1954,
        "MLY First Year": 1945,
        "MLY Last Year": 1954
      },
      {
        "Name": "KINGFISHER LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6044138",
        "Station ID": 4017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -89.07,
        "Latitude": 483900000,
        "Longitude": -890400000,
        "Elevation (m)": 487.7,
        "First Year": 1975,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1977,
        "MLY First Year": 1975,
        "MLY Last Year": 1977
      },
      {
        "Name": "LAKEHEAD UNIVERSITY",
        "Province": "ONTARIO",
        "Climate ID": "6044298",
        "Station ID": 4018,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -89.27,
        "Latitude": 482600000,
        "Longitude": -891600000,
        "Elevation (m)": 210.3,
        "First Year": 1968,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2002,
        "MLY First Year": 1968,
        "MLY Last Year": 2002
      },
      {
        "Name": "LONGLAC",
        "Province": "ONTARIO",
        "Climate ID": "6044525",
        "Station ID": 4019,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -86.5,
        "Latitude": 494500000,
        "Longitude": -863000000,
        "Elevation (m)": 317,
        "First Year": 1921,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1957,
        "MLY First Year": 1921,
        "MLY Last Year": 1957
      },
      {
        "Name": "LONGLAC P AND P",
        "Province": "ONTARIO",
        "Climate ID": "6044560",
        "Station ID": 4020,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -86.53,
        "Latitude": 494600000,
        "Longitude": -863200000,
        "Elevation (m)": 342.6,
        "First Year": 1951,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1969,
        "MLY First Year": 1951,
        "MLY Last Year": 1969
      },
      {
        "Name": "LONG LAKE CONTROL DAM",
        "Province": "ONTARIO",
        "Climate ID": "6044595",
        "Station ID": 4021,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -87.05,
        "Latitude": 490500000,
        "Longitude": -870300000,
        "Elevation (m)": 317,
        "First Year": 1950,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1964,
        "MLY First Year": 1950,
        "MLY Last Year": 1964
      },
      {
        "Name": "LOON",
        "Province": "ONTARIO",
        "Climate ID": "6044612",
        "Station ID": 4022,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -88.77,
        "Latitude": 483800000,
        "Longitude": -884600000,
        "Elevation (m)": 323.1,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "MACDIARMID",
        "Province": "ONTARIO",
        "Climate ID": "6044735",
        "Station ID": 4023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.43,
        "Longitude (Decimal Degrees)": -88.15,
        "Latitude": 492600000,
        "Longitude": -880900000,
        "Elevation (m)": 312.4,
        "First Year": 1926,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1970,
        "MLY First Year": 1926,
        "MLY Last Year": 1970
      },
      {
        "Name": "MANITOU FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6044890",
        "Station ID": 4024,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -86.1,
        "Latitude": 491200000,
        "Longitude": -860600000,
        "Elevation (m)": null,
        "First Year": 1948,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1955,
        "MLY First Year": 1948,
        "MLY Last Year": 1955
      },
      {
        "Name": "MANITOUWADGE",
        "Province": "ONTARIO",
        "Climate ID": "6044903",
        "Station ID": 4025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -85.8,
        "Latitude": 490900000,
        "Longitude": -854800000,
        "Elevation (m)": 332.2,
        "First Year": 1956,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1995,
        "MLY First Year": 1956,
        "MLY Last Year": 1995
      },
      {
        "Name": "MARATHON",
        "Province": "ONTARIO",
        "Climate ID": "6044959",
        "Station ID": 4026,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.72,
        "Longitude (Decimal Degrees)": -86.4,
        "Latitude": 484300000,
        "Longitude": -862400000,
        "Elevation (m)": 189,
        "First Year": 1945,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1984,
        "MLY First Year": 1945,
        "MLY Last Year": 1984
      },
      {
        "Name": "MARATHON AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6044961",
        "Station ID": 4027,
        "WMO ID": null,
        "TC ID": "YSP",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -86.34,
        "Latitude": 484520000,
        "Longitude": -862040000,
        "Elevation (m)": 315.5,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": 2007,
        "HLY Last Year": 2007,
        "DLY First Year": 1988,
        "DLY Last Year": 1999,
        "MLY First Year": 1988,
        "MLY Last Year": 1999
      },
      {
        "Name": "MARATHON A",
        "Province": "ONTARIO",
        "Climate ID": "6044962",
        "Station ID": 46527,
        "WMO ID": null,
        "TC ID": "YSP",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -86.34,
        "Latitude": 484519000,
        "Longitude": -862040000,
        "Elevation (m)": 314.55,
        "First Year": 2007,
        "Last Year": 2014,
        "HLY First Year": 2007,
        "HLY Last Year": 2014,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARATHON A",
        "Province": "ONTARIO",
        "Climate ID": "6044963",
        "Station ID": 52338,
        "WMO ID": null,
        "TC ID": "YSP",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -86.35,
        "Latitude": 484526000,
        "Longitude": -862045000,
        "Elevation (m)": 314.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARATHON A",
        "Province": "ONTARIO",
        "Climate ID": "6044967",
        "Station ID": 53138,
        "WMO ID": null,
        "TC ID": "YSP",
        "Latitude (Decimal Degrees)": 48.76,
        "Longitude (Decimal Degrees)": -86.35,
        "Latitude": 484526000,
        "Longitude": -862045000,
        "Elevation (m)": 314.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARIE LOUISE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6044974",
        "Station ID": 27291,
        "WMO ID": null,
        "TC ID": "WLK",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -88.8,
        "Latitude": 482200000,
        "Longitude": -884800000,
        "Elevation (m)": 239.9,
        "First Year": 1997,
        "Last Year": 1997,
        "HLY First Year": 1997,
        "HLY Last Year": 1997,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MYRT LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6045541",
        "Station ID": 4028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -90.72,
        "Latitude": 482800000,
        "Longitude": -904300000,
        "Elevation (m)": 518.2,
        "First Year": 1980,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1985,
        "MLY First Year": 1980,
        "MLY Last Year": 1985
      },
      {
        "Name": "NAKINA A",
        "Province": "ONTARIO",
        "Climate ID": "6045550",
        "Station ID": 4029,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -86.7,
        "Latitude": 501100000,
        "Longitude": -864200000,
        "Elevation (m)": 324.6,
        "First Year": 1939,
        "Last Year": 1967,
        "HLY First Year": 1953,
        "HLY Last Year": 1967,
        "DLY First Year": 1939,
        "DLY Last Year": 1967,
        "MLY First Year": 1939,
        "MLY Last Year": 1967
      },
      {
        "Name": "NAKINA FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6045572",
        "Station ID": 4030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -86.7,
        "Latitude": 501100000,
        "Longitude": -864200000,
        "Elevation (m)": 320.6,
        "First Year": 1929,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1959,
        "MLY First Year": 1929,
        "MLY Last Year": 1959
      },
      {
        "Name": "NIPIGON",
        "Province": "ONTARIO",
        "Climate ID": "6045665",
        "Station ID": 4031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -88.25,
        "Latitude": 490000000,
        "Longitude": -881500000,
        "Elevation (m)": 184.4,
        "First Year": 1886,
        "Last Year": 1922,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1922,
        "MLY First Year": 1886,
        "MLY Last Year": 1922
      },
      {
        "Name": "NOLALU",
        "Province": "ONTARIO",
        "Climate ID": "6045675",
        "Station ID": 4032,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -89.88,
        "Latitude": 480900000,
        "Longitude": -895300000,
        "Elevation (m)": 381,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "NOLALU SSW22",
        "Province": "ONTARIO",
        "Climate ID": "6045676",
        "Station ID": 4033,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.1,
        "Longitude (Decimal Degrees)": -89.88,
        "Latitude": 480600000,
        "Longitude": -895300000,
        "Elevation (m)": 349.9,
        "First Year": 1979,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1985,
        "MLY First Year": 1979,
        "MLY Last Year": 1985
      },
      {
        "Name": "NORTH LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6045711",
        "Station ID": 4034,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.13,
        "Longitude (Decimal Degrees)": -90.57,
        "Latitude": 480800000,
        "Longitude": -903400000,
        "Elevation (m)": 478.8,
        "First Year": 1921,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1941,
        "MLY First Year": 1921,
        "MLY Last Year": 1941
      },
      {
        "Name": "ONE ISLAND LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6045781",
        "Station ID": 6898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -89.42,
        "Latitude": 483900000,
        "Longitude": -892500000,
        "Elevation (m)": 457.2,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": 1992,
        "MLY Last Year": 1993
      },
      {
        "Name": "OXALINE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6046164",
        "Station ID": 4035,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -87.57,
        "Latitude": 494200000,
        "Longitude": -873400000,
        "Elevation (m)": 331.3,
        "First Year": 1952,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1956,
        "MLY First Year": 1952,
        "MLY Last Year": 1956
      },
      {
        "Name": "PAYS PLAT",
        "Province": "ONTARIO",
        "Climate ID": "6046281",
        "Station ID": 4036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -87.6,
        "Latitude": 485200000,
        "Longitude": -873600000,
        "Elevation (m)": 185.3,
        "First Year": 1952,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1965,
        "MLY First Year": 1952,
        "MLY Last Year": 1965
      },
      {
        "Name": "PAYS PLAT FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6046283",
        "Station ID": 4037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -87.53,
        "Latitude": 485300000,
        "Longitude": -873200000,
        "Elevation (m)": 190.5,
        "First Year": 1944,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1950,
        "MLY First Year": 1944,
        "MLY Last Year": 1950
      },
      {
        "Name": "BATTLE ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6046285",
        "Station ID": 26859,
        "WMO ID": null,
        "TC ID": "WMF",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -87.55,
        "Latitude": 484500000,
        "Longitude": -873300000,
        "Elevation (m)": 197,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PINE PORTAGE",
        "Province": "ONTARIO",
        "Climate ID": "6046549",
        "Station ID": 4038,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -88.32,
        "Latitude": 491800000,
        "Longitude": -881900000,
        "Elevation (m)": 233.2,
        "First Year": 1950,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1968,
        "MLY First Year": 1950,
        "MLY Last Year": 1968
      },
      {
        "Name": "PORT ARTHUR",
        "Province": "ONTARIO",
        "Climate ID": "6046588",
        "Station ID": 4039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -89.22,
        "Latitude": 482600000,
        "Longitude": -891300000,
        "Elevation (m)": 195.1,
        "First Year": 1877,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1877,
        "DLY Last Year": 1941,
        "MLY First Year": 1877,
        "MLY Last Year": 1941
      },
      {
        "Name": "PORT ARTHUR CKPR",
        "Province": "ONTARIO",
        "Climate ID": "6046590",
        "Station ID": 4040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -89.27,
        "Latitude": 482500000,
        "Longitude": -891600000,
        "Elevation (m)": 188.1,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "PUKASKWA (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6046767",
        "Station ID": 10918,
        "WMO ID": 71750,
        "TC ID": "WCJ",
        "Latitude (Decimal Degrees)": 48.59,
        "Longitude (Decimal Degrees)": -86.29,
        "Latitude": 483518000,
        "Longitude": -861741000,
        "Elevation (m)": 207.6,
        "First Year": 1994,
        "Last Year": 2012,
        "HLY First Year": 1994,
        "HLY Last Year": 2012,
        "DLY First Year": 1996,
        "DLY Last Year": 2012,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "PUKASKWA (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6046768",
        "Station ID": 49848,
        "WMO ID": 71750,
        "TC ID": "WCJ",
        "Latitude (Decimal Degrees)": 48.61,
        "Longitude (Decimal Degrees)": -86.29,
        "Latitude": 483628000,
        "Longitude": -861714000,
        "Elevation (m)": 191.5,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PUKASKWA NATL PARK",
        "Province": "ONTARIO",
        "Climate ID": "6046770",
        "Station ID": 4041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -86.3,
        "Latitude": 483600000,
        "Longitude": -861800000,
        "Elevation (m)": 192,
        "First Year": 1983,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2005,
        "MLY First Year": 1983,
        "MLY Last Year": 2005
      },
      {
        "Name": "QUORN",
        "Province": "ONTARIO",
        "Climate ID": "6046811",
        "Station ID": 4042,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -90.9,
        "Latitude": 492500000,
        "Longitude": -905400000,
        "Elevation (m)": 444.7,
        "First Year": 1915,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1960,
        "MLY First Year": 1915,
        "MLY Last Year": 1960
      },
      {
        "Name": "RAITH TCPL 64",
        "Province": "ONTARIO",
        "Climate ID": "6046856",
        "Station ID": 4043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -89.87,
        "Latitude": 484400000,
        "Longitude": -895200000,
        "Elevation (m)": 433.1,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1969,
        "MLY Last Year": 1984
      },
      {
        "Name": "REDMOND",
        "Province": "ONTARIO",
        "Climate ID": "6046989",
        "Station ID": 4044,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -87.5,
        "Latitude": 501400000,
        "Longitude": -873000000,
        "Elevation (m)": 326.1,
        "First Year": 1952,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1956,
        "MLY First Year": 1952,
        "MLY Last Year": 1956
      },
      {
        "Name": "SAVANNE",
        "Province": "ONTARIO",
        "Climate ID": "6047615",
        "Station ID": 4045,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -90.2,
        "Latitude": 485800000,
        "Longitude": -901200000,
        "Elevation (m)": 459,
        "First Year": 1884,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1954,
        "MLY First Year": 1884,
        "MLY Last Year": 1954
      },
      {
        "Name": "SAVANNE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6047617",
        "Station ID": 4046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -90.1,
        "Latitude": 485000000,
        "Longitude": -900600000,
        "Elevation (m)": 479.5,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "SAVANT LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6047620",
        "Station ID": 4047,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -90.35,
        "Latitude": 502800000,
        "Longitude": -902100000,
        "Elevation (m)": 398.7,
        "First Year": 1930,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1944,
        "MLY First Year": 1930,
        "MLY Last Year": 1944
      },
      {
        "Name": "SCHREIBER",
        "Province": "ONTARIO",
        "Climate ID": "6047627",
        "Station ID": 4048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -87.27,
        "Latitude": 484900000,
        "Longitude": -871600000,
        "Elevation (m)": 301.8,
        "First Year": 1893,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1975,
        "MLY First Year": 1893,
        "MLY Last Year": 1975
      },
      {
        "Name": "SLATE ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6047810",
        "Station ID": 4049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -87,
        "Latitude": 483716000,
        "Longitude": -865945000,
        "Elevation (m)": 185.9,
        "First Year": 1966,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1989,
        "MLY First Year": 1966,
        "MLY Last Year": 1989
      },
      {
        "Name": "STEVENS",
        "Province": "ONTARIO",
        "Climate ID": "6048017",
        "Station ID": 4051,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -85.85,
        "Latitude": 493200000,
        "Longitude": -855100000,
        "Elevation (m)": 324.6,
        "First Year": 1945,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1955,
        "MLY First Year": 1945,
        "MLY Last Year": 1955
      },
      {
        "Name": "STURGEON LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6048145",
        "Station ID": 4052,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -90.97,
        "Latitude": 495300000,
        "Longitude": -905800000,
        "Elevation (m)": 428.2,
        "First Year": 1972,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1994,
        "MLY First Year": 1972,
        "MLY Last Year": 1994
      },
      {
        "Name": "STURGEON RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6048155",
        "Station ID": 46427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -90.85,
        "Latitude": 500435070,
        "Longitude": -905113060,
        "Elevation (m)": 415,
        "First Year": 2007,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUMMIT CONTROL DAM",
        "Province": "ONTARIO",
        "Climate ID": "6048175",
        "Station ID": 4053,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -88.2,
        "Latitude": 503800000,
        "Longitude": -881200000,
        "Elevation (m)": 332.2,
        "First Year": 1950,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1959,
        "MLY First Year": 1950,
        "MLY Last Year": 1959
      },
      {
        "Name": "TERRACE BAY",
        "Province": "ONTARIO",
        "Climate ID": "6048230",
        "Station ID": 4054,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.8,
        "Longitude (Decimal Degrees)": -87.1,
        "Latitude": 484800000,
        "Longitude": -870600000,
        "Elevation (m)": 289,
        "First Year": 1972,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1999,
        "MLY First Year": 1972,
        "MLY Last Year": 1999
      },
      {
        "Name": "TERRACE BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6048231",
        "Station ID": 7593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -87.1,
        "Latitude": 484900000,
        "Longitude": -870600000,
        "Elevation (m)": 289.6,
        "First Year": 1994,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2007,
        "DLY First Year": 1996,
        "DLY Last Year": 2007,
        "MLY First Year": 1996,
        "MLY Last Year": 2006
      },
      {
        "Name": "TERRACE BAY AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6048232",
        "Station ID": 54606,
        "WMO ID": 73018,
        "TC ID": "OTR",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -87.09,
        "Latitude": 484856054,
        "Longitude": -870530041,
        "Elevation (m)": 290,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TERRACE BAY(AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6048235",
        "Station ID": 32393,
        "WMO ID": null,
        "TC ID": "ZTJ",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -87.1,
        "Latitude": 484900000,
        "Longitude": -870600000,
        "Elevation (m)": 289.6,
        "First Year": 2002,
        "Last Year": 2007,
        "HLY First Year": 2002,
        "HLY Last Year": 2007,
        "DLY First Year": 2003,
        "DLY Last Year": 2006,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "THUNDER BAY",
        "Province": "ONTARIO",
        "Climate ID": "6048260",
        "Station ID": 49389,
        "WMO ID": 71072,
        "TC ID": "YQT",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.32,
        "Latitude": 482219000,
        "Longitude": -891918000,
        "Elevation (m)": 199.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THUNDER BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6048261",
        "Station ID": 4055,
        "WMO ID": null,
        "TC ID": "YQT",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.33,
        "Latitude": 482210000,
        "Longitude": -891938038,
        "Elevation (m)": 199,
        "First Year": 1941,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1941,
        "DLY Last Year": 2004,
        "MLY First Year": 1941,
        "MLY Last Year": 2004
      },
      {
        "Name": "THUNDER BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6048262",
        "Station ID": 50132,
        "WMO ID": null,
        "TC ID": "YQT",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.32,
        "Latitude": 482219000,
        "Longitude": -891918000,
        "Elevation (m)": 199.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THUNDER BAY AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6048264",
        "Station ID": 30667,
        "WMO ID": 71749,
        "TC ID": "ZQT",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.32,
        "Latitude": 482200000,
        "Longitude": -891900000,
        "Elevation (m)": 199.3,
        "First Year": 1994,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2012,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "THUNDER BAY BURWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6048266",
        "Station ID": 44324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.41,
        "Longitude (Decimal Degrees)": -89.28,
        "Latitude": 482450200,
        "Longitude": -891641700,
        "Elevation (m)": 200,
        "First Year": 2005,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2020,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "THUNDER BAY CS",
        "Province": "ONTARIO",
        "Climate ID": "6048268",
        "Station ID": 30682,
        "WMO ID": 71667,
        "TC ID": "ZTB",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.33,
        "Latitude": 482210000,
        "Longitude": -891938000,
        "Elevation (m)": 199.4,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "THUNDER BAY AIRPORT MAINTAIR",
        "Province": "ONTARIO",
        "Climate ID": "6048270",
        "Station ID": 42523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.32,
        "Latitude": 482216038,
        "Longitude": -891858056,
        "Elevation (m)": 194.1,
        "First Year": 2003,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2005,
        "MLY First Year": 2003,
        "MLY Last Year": 2005
      },
      {
        "Name": "TRANQUILLO RIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6048864",
        "Station ID": 6899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.23,
        "Longitude (Decimal Degrees)": -89.52,
        "Latitude": 481400000,
        "Longitude": -893100000,
        "Elevation (m)": 317,
        "First Year": 1991,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2007,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "TROUT LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6048951",
        "Station ID": 4056,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -89.37,
        "Latitude": 483700000,
        "Longitude": -892200000,
        "Elevation (m)": 457.2,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "TROWBRIDGE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6048955",
        "Station ID": 7977,
        "WMO ID": null,
        "TC ID": "WAB",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -88.87,
        "Latitude": 481800000,
        "Longitude": -885200000,
        "Elevation (m)": 218,
        "First Year": 1994,
        "Last Year": 2000,
        "HLY First Year": 1994,
        "HLY Last Year": 2000,
        "DLY First Year": 1994,
        "DLY Last Year": 2000,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THUNDER BAY MCS CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6048K6J",
        "Station ID": 4050,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.32,
        "Longitude (Decimal Degrees)": -89.38,
        "Latitude": 481900000,
        "Longitude": -892300000,
        "Elevation (m)": 231.6,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "UPSALA (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6049095",
        "Station ID": 4057,
        "WMO ID": 71435,
        "TC ID": "WDV",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -90.47,
        "Latitude": 490200000,
        "Longitude": -902800000,
        "Elevation (m)": 488.5,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "UPSALA",
        "Province": "ONTARIO",
        "Climate ID": "6049096",
        "Station ID": 4058,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -90.47,
        "Latitude": 490300000,
        "Longitude": -902800000,
        "Elevation (m)": 483.7,
        "First Year": 1947,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1972,
        "MLY First Year": 1947,
        "MLY Last Year": 1972
      },
      {
        "Name": "UPSALA TCPL 62",
        "Province": "ONTARIO",
        "Climate ID": "6049098",
        "Station ID": 4059,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -90.52,
        "Latitude": 490200000,
        "Longitude": -903100000,
        "Elevation (m)": 492.9,
        "First Year": 1970,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1986,
        "MLY First Year": 1970,
        "MLY Last Year": 1986
      },
      {
        "Name": "WABOOSE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6049175",
        "Station ID": 4060,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.78,
        "Longitude (Decimal Degrees)": -87.98,
        "Latitude": 504700000,
        "Longitude": -875900000,
        "Elevation (m)": 329.2,
        "First Year": 1941,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1956,
        "MLY First Year": 1941,
        "MLY Last Year": 1956
      },
      {
        "Name": "WELCOME ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6049443",
        "Station ID": 4061,
        "WMO ID": 71751,
        "TC ID": "WEC",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.12,
        "Latitude": 482209000,
        "Longitude": -890710000,
        "Elevation (m)": 211.4,
        "First Year": 1967,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1967,
        "DLY Last Year": 2022,
        "MLY First Year": 1967,
        "MLY Last Year": 1985
      },
      {
        "Name": "WHITEFISH LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6049466",
        "Station ID": 4062,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.28,
        "Longitude (Decimal Degrees)": -89.92,
        "Latitude": 481700000,
        "Longitude": -895500000,
        "Elevation (m)": 399,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "LITTLE FLATLAND ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6049470",
        "Station ID": 43104,
        "WMO ID": 71468,
        "TC ID": "WKK",
        "Latitude (Decimal Degrees)": 49.69,
        "Longitude (Decimal Degrees)": -88.31,
        "Latitude": 494124000,
        "Longitude": -881821000,
        "Elevation (m)": 261,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PIGEON RIVER",
        "Province": "ONTARIO",
        "Climate ID": "604FNL6",
        "Station ID": 3976,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.08,
        "Longitude (Decimal Degrees)": -89.63,
        "Latitude": 480500000,
        "Longitude": -893800000,
        "Elevation (m)": 289.6,
        "First Year": 1970,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1978,
        "MLY First Year": 1970,
        "MLY Last Year": 1978
      },
      {
        "Name": "THUNDER BAY POMBER",
        "Province": "ONTARIO",
        "Climate ID": "604H26A",
        "Station ID": 3979,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -89.22,
        "Latitude": 483000000,
        "Longitude": -891300000,
        "Elevation (m)": 228.9,
        "First Year": 1979,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1988,
        "MLY First Year": 1979,
        "MLY Last Year": 1988
      },
      {
        "Name": "THUNDER BAY WPCP",
        "Province": "ONTARIO",
        "Climate ID": "604HBFA",
        "Station ID": 3977,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -89.23,
        "Latitude": 482400000,
        "Longitude": -891400000,
        "Elevation (m)": 184.4,
        "First Year": 1960,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1989,
        "MLY First Year": 1960,
        "MLY Last Year": 1989
      },
      {
        "Name": "THUNDER BAY PROVINCIAL PAPER",
        "Province": "ONTARIO",
        "Climate ID": "604HK61",
        "Station ID": 3978,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -89.17,
        "Latitude": 482700000,
        "Longitude": -891000000,
        "Elevation (m)": 184.4,
        "First Year": 1990,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1990,
        "MLY First Year": 1990,
        "MLY Last Year": 1990
      },
      {
        "Name": "BIG THUNDER",
        "Province": "ONTARIO",
        "Climate ID": "604I261",
        "Station ID": 26860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.28,
        "Longitude (Decimal Degrees)": -89.38,
        "Latitude": 481700000,
        "Longitude": -892300000,
        "Elevation (m)": 298.8,
        "First Year": 1995,
        "Last Year": 1995,
        "HLY First Year": 1995,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KATATOTA ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "604M002",
        "Station ID": 10664,
        "WMO ID": 71468,
        "TC ID": "WKK",
        "Latitude (Decimal Degrees)": 49.71,
        "Longitude (Decimal Degrees)": -88.33,
        "Latitude": 494247000,
        "Longitude": -881956000,
        "Elevation (m)": 261,
        "First Year": 1994,
        "Last Year": 2006,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": 1996,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTHERN ONTARIO EER",
        "Province": "ONTARIO",
        "Climate ID": "604S001",
        "Station ID": 26912,
        "WMO ID": null,
        "TC ID": "XQT",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -89.22,
        "Latitude": 482600000,
        "Longitude": -891300000,
        "Elevation (m)": null,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THUNDER BAY A FIREHALL",
        "Province": "ONTARIO",
        "Climate ID": "604S003",
        "Station ID": 27084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -89.32,
        "Latitude": 482200000,
        "Longitude": -891900000,
        "Elevation (m)": 199,
        "First Year": 1995,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2017,
        "MLY First Year": 1995,
        "MLY Last Year": 2006
      },
      {
        "Name": "BLIND RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6050801",
        "Station ID": 4064,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -83,
        "Latitude": 461200000,
        "Longitude": -830000000,
        "Elevation (m)": 183.2,
        "First Year": 1926,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1959,
        "MLY First Year": 1926,
        "MLY Last Year": 1959
      },
      {
        "Name": "BLIND RIVER HYDRO CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6050805",
        "Station ID": 4065,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -83.02,
        "Latitude": 461200000,
        "Longitude": -830100000,
        "Elevation (m)": 189,
        "First Year": 1982,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1989,
        "MLY First Year": 1982,
        "MLY Last Year": 1989
      },
      {
        "Name": "BAR RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6050NNP",
        "Station ID": 4063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -84.05,
        "Latitude": 462600000,
        "Longitude": -840300000,
        "Elevation (m)": 180,
        "First Year": 1988,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1992,
        "MLY First Year": 1988,
        "MLY Last Year": 1992
      },
      {
        "Name": "BRUCE MINES",
        "Province": "ONTARIO",
        "Climate ID": "6051027",
        "Station ID": 4068,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -83.92,
        "Latitude": 461800000,
        "Longitude": -835500000,
        "Elevation (m)": 181.4,
        "First Year": 1898,
        "Last Year": 1914,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 1914,
        "MLY First Year": 1898,
        "MLY Last Year": 1914
      },
      {
        "Name": "BRUCE MINES RADIGAN",
        "Province": "ONTARIO",
        "Climate ID": "605102G",
        "Station ID": 4067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -83.75,
        "Latitude": 461800000,
        "Longitude": -834500000,
        "Elevation (m)": 178,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "DALTON",
        "Province": "ONTARIO",
        "Climate ID": "6051R65",
        "Station ID": 4066,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -84.03,
        "Latitude": 480900000,
        "Longitude": -840200000,
        "Elevation (m)": 342.9,
        "First Year": 1977,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1979,
        "MLY First Year": 1977,
        "MLY Last Year": 1979
      },
      {
        "Name": "ELLIOT LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6052258",
        "Station ID": 4069,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -82.65,
        "Latitude": 462300000,
        "Longitude": -823900000,
        "Elevation (m)": 312.4,
        "First Year": 1959,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1973,
        "MLY First Year": 1959,
        "MLY Last Year": 1973
      },
      {
        "Name": "ELLIOT LAKE A",
        "Province": "ONTARIO",
        "Climate ID": "6052259",
        "Station ID": 7595,
        "WMO ID": null,
        "TC ID": "YEL",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -82.56,
        "Latitude": 462105000,
        "Longitude": -823341000,
        "Elevation (m)": 331.3,
        "First Year": 1994,
        "Last Year": 2012,
        "HLY First Year": 1994,
        "HLY Last Year": 2012,
        "DLY First Year": 1995,
        "DLY Last Year": 2012,
        "MLY First Year": 1995,
        "MLY Last Year": 2011
      },
      {
        "Name": "ELLIOT LAKE DENISON",
        "Province": "ONTARIO",
        "Climate ID": "6052260",
        "Station ID": 4070,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -82.62,
        "Latitude": 463000000,
        "Longitude": -823700000,
        "Elevation (m)": 434.3,
        "First Year": 1974,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1980,
        "MLY First Year": 1974,
        "MLY Last Year": 1979
      },
      {
        "Name": "ELLIOT LAKE STANLEIGH",
        "Province": "ONTARIO",
        "Climate ID": "6052268",
        "Station ID": 4071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -82.65,
        "Latitude": 462500000,
        "Longitude": -823900000,
        "Elevation (m)": 374,
        "First Year": 1984,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1997,
        "MLY First Year": 1984,
        "MLY Last Year": 1997
      },
      {
        "Name": "FRANZ",
        "Province": "ONTARIO",
        "Climate ID": "6052563",
        "Station ID": 4072,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -84.42,
        "Latitude": 482800000,
        "Longitude": -842500000,
        "Elevation (m)": 373.4,
        "First Year": 1917,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1960,
        "MLY First Year": 1917,
        "MLY Last Year": 1960
      },
      {
        "Name": "FRANZ FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6052565",
        "Station ID": 4073,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -84.4,
        "Latitude": 482700000,
        "Longitude": -842400000,
        "Elevation (m)": 371.6,
        "First Year": 1952,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1952,
        "MLY Last Year": 1952
      },
      {
        "Name": "HAWK JUNCTION",
        "Province": "ONTARIO",
        "Climate ID": "6053391",
        "Station ID": 4074,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.08,
        "Longitude (Decimal Degrees)": -84.55,
        "Latitude": 480500000,
        "Longitude": -843300000,
        "Elevation (m)": 327.7,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "HIGH FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6053463",
        "Station ID": 4075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.92,
        "Longitude (Decimal Degrees)": -84.72,
        "Latitude": 475500000,
        "Longitude": -844300000,
        "Elevation (m)": 221,
        "First Year": 1976,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1989,
        "MLY First Year": 1976,
        "MLY Last Year": 1989
      },
      {
        "Name": "HORNEPAYNE",
        "Province": "ONTARIO",
        "Climate ID": "6053570",
        "Station ID": 4076,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -84.8,
        "Latitude": 491400000,
        "Longitude": -844800000,
        "Elevation (m)": 329.2,
        "First Year": 1917,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1989,
        "MLY First Year": 1917,
        "MLY Last Year": 1989
      },
      {
        "Name": "HORNEPAYNE A",
        "Province": "ONTARIO",
        "Climate ID": "6053575",
        "Station ID": 4077,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -84.77,
        "Latitude": 491200000,
        "Longitude": -844600000,
        "Elevation (m)": 335,
        "First Year": 1990,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1995,
        "MLY First Year": 1990,
        "MLY Last Year": 1995
      },
      {
        "Name": "IRON BRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6053803",
        "Station ID": 4078,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -83.35,
        "Latitude": 461600000,
        "Longitude": -832100000,
        "Elevation (m)": 198,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1991,
        "MLY First Year": 1988,
        "MLY Last Year": 1991
      },
      {
        "Name": "IRON BRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6053804",
        "Station ID": 4079,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.28,
        "Longitude (Decimal Degrees)": -83.23,
        "Latitude": 461700000,
        "Longitude": -831400000,
        "Elevation (m)": 182.9,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "IRON BRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6053805",
        "Station ID": 44947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.28,
        "Longitude (Decimal Degrees)": -83.25,
        "Latitude": 461648000,
        "Longitude": -831446026,
        "Elevation (m)": 190,
        "First Year": 2006,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2008,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "KENTVALE",
        "Province": "ONTARIO",
        "Climate ID": "6054078",
        "Station ID": 4080,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -84.07,
        "Latitude": 461300000,
        "Longitude": -840400000,
        "Elevation (m)": 205,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "LAKE SUPERIOR PROVINCIAL PARK",
        "Province": "ONTARIO",
        "Climate ID": "6054425",
        "Station ID": 51958,
        "WMO ID": 71640,
        "TC ID": "TLS",
        "Latitude (Decimal Degrees)": 47.72,
        "Longitude (Decimal Degrees)": -84.81,
        "Latitude": 474323000,
        "Longitude": -844851000,
        "Elevation (m)": 400.62,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MICHIPICOTEN FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6055095",
        "Station ID": 4081,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.88,
        "Longitude (Decimal Degrees)": -84.63,
        "Latitude": 475300000,
        "Longitude": -843800000,
        "Elevation (m)": 199,
        "First Year": 1882,
        "Last Year": 1928,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1928,
        "MLY First Year": 1882,
        "MLY Last Year": 1928
      },
      {
        "Name": "MISSANABIE",
        "Province": "ONTARIO",
        "Climate ID": "6055209",
        "Station ID": 4082,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.32,
        "Longitude (Decimal Degrees)": -84.08,
        "Latitude": 481900000,
        "Longitude": -840500000,
        "Elevation (m)": 334.7,
        "First Year": 1889,
        "Last Year": 1902,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 1902,
        "MLY First Year": 1889,
        "MLY Last Year": 1902
      },
      {
        "Name": "MISSISSAGI ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6055210",
        "Station ID": 4083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -83.38,
        "Latitude": 462600000,
        "Longitude": -832300000,
        "Elevation (m)": 225.6,
        "First Year": 1970,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1997,
        "MLY First Year": 1970,
        "MLY Last Year": 1997
      },
      {
        "Name": "MONTREAL FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6055300",
        "Station ID": 4084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.25,
        "Longitude (Decimal Degrees)": -84.4,
        "Latitude": 471500000,
        "Longitude": -842400000,
        "Elevation (m)": 408.4,
        "First Year": 1932,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 1955,
        "MLY First Year": 1932,
        "MLY Last Year": 1955
      },
      {
        "Name": "MONTREAL FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6055302",
        "Station ID": 4085,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.27,
        "Longitude (Decimal Degrees)": -84.43,
        "Latitude": 471600000,
        "Longitude": -842600000,
        "Elevation (m)": 306.3,
        "First Year": 1976,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1999,
        "MLY First Year": 1976,
        "MLY Last Year": 1999
      },
      {
        "Name": "MONTREAL RIVER (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6055378",
        "Station ID": 41595,
        "WMO ID": null,
        "TC ID": "ZGJ",
        "Latitude (Decimal Degrees)": 47.25,
        "Longitude (Decimal Degrees)": -84.6,
        "Latitude": 471450000,
        "Longitude": -843545000,
        "Elevation (m)": 534.9,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": 2000,
        "HLY Last Year": 2007,
        "DLY First Year": 2003,
        "DLY Last Year": 2006,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "OBA",
        "Province": "ONTARIO",
        "Climate ID": "6055754",
        "Station ID": 4086,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -84.1,
        "Latitude": 490400000,
        "Longitude": -840600000,
        "Elevation (m)": 331,
        "First Year": 1926,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1946,
        "MLY First Year": 1926,
        "MLY Last Year": 1946
      },
      {
        "Name": "PESHU LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6056389",
        "Station ID": 4087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.62,
        "Longitude (Decimal Degrees)": -83.17,
        "Latitude": 463700000,
        "Longitude": -831000000,
        "Elevation (m)": null,
        "First Year": 1950,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1955,
        "MLY First Year": 1950,
        "MLY Last Year": 1955
      },
      {
        "Name": "RAYNER",
        "Province": "ONTARIO",
        "Climate ID": "6056907",
        "Station ID": 4088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -83.5,
        "Latitude": 462000000,
        "Longitude": -833000000,
        "Elevation (m)": 243.8,
        "First Year": 1950,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1970,
        "MLY First Year": 1950,
        "MLY Last Year": 1970
      },
      {
        "Name": "ST JOSEPH ISLAND MTN",
        "Province": "ONTARIO",
        "Climate ID": "6057327",
        "Station ID": 4089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -83.95,
        "Latitude": 461200000,
        "Longitude": -835700000,
        "Elevation (m)": 335.3,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "SAND LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6057437",
        "Station ID": 4090,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.78,
        "Longitude (Decimal Degrees)": -84.53,
        "Latitude": 474700000,
        "Longitude": -843200000,
        "Elevation (m)": 372.5,
        "First Year": 1954,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1959,
        "MLY First Year": 1954,
        "MLY Last Year": 1959
      },
      {
        "Name": "SAULT STE MARIE",
        "Province": "ONTARIO",
        "Climate ID": "6057589",
        "Station ID": 4091,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.53,
        "Longitude (Decimal Degrees)": -84.5,
        "Latitude": 463200000,
        "Longitude": -843000000,
        "Elevation (m)": 205.7,
        "First Year": 1949,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1959,
        "MLY First Year": 1949,
        "MLY Last Year": 1959
      },
      {
        "Name": "SAULT STE MARIE 2",
        "Province": "ONTARIO",
        "Climate ID": "6057590",
        "Station ID": 4092,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.53,
        "Longitude (Decimal Degrees)": -84.33,
        "Latitude": 463200000,
        "Longitude": -842000000,
        "Elevation (m)": 211.8,
        "First Year": 1957,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2002,
        "MLY First Year": 1957,
        "MLY Last Year": 2002
      },
      {
        "Name": "SAULT STE MARIE A",
        "Province": "ONTARIO",
        "Climate ID": "6057591",
        "Station ID": 50092,
        "WMO ID": 71260,
        "TC ID": "YAM",
        "Latitude (Decimal Degrees)": 46.49,
        "Longitude (Decimal Degrees)": -84.51,
        "Latitude": 462906000,
        "Longitude": -843035000,
        "Elevation (m)": 192,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAULT STE MARIE A",
        "Province": "ONTARIO",
        "Climate ID": "6057592",
        "Station ID": 4093,
        "WMO ID": 71260,
        "TC ID": "YAM",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -84.51,
        "Latitude": 462900000,
        "Longitude": -843034000,
        "Elevation (m)": 192,
        "First Year": 1945,
        "Last Year": 2012,
        "HLY First Year": 1961,
        "HLY Last Year": 2012,
        "DLY First Year": 1945,
        "DLY Last Year": 2012,
        "MLY First Year": 1945,
        "MLY Last Year": 2012
      },
      {
        "Name": "SAULT STE. MARIE AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6057593",
        "Station ID": 54838,
        "WMO ID": 73070,
        "TC ID": "OSM",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -84.52,
        "Latitude": 462841000,
        "Longitude": -843100000,
        "Elevation (m)": 187,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAULT STE M FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6057595",
        "Station ID": 4094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -84.37,
        "Latitude": 463000000,
        "Longitude": -842200000,
        "Elevation (m)": 193.2,
        "First Year": 1889,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 1933,
        "MLY First Year": 1889,
        "MLY Last Year": 1933
      },
      {
        "Name": "SAULT STE M INSECTARY",
        "Province": "ONTARIO",
        "Climate ID": "6057597",
        "Station ID": 4095,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -84.47,
        "Latitude": 462800000,
        "Longitude": -842800000,
        "Elevation (m)": 190.5,
        "First Year": 1951,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1954,
        "MLY First Year": 1951,
        "MLY Last Year": 1954
      },
      {
        "Name": "SAULT STE M SHINGWOUK",
        "Province": "ONTARIO",
        "Climate ID": "6057605",
        "Station ID": 4096,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -84.28,
        "Latitude": 463000000,
        "Longitude": -841700000,
        "Elevation (m)": 182.9,
        "First Year": 1954,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "SEARCHMONT",
        "Province": "ONTARIO",
        "Climate ID": "6057678",
        "Station ID": 4097,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.75,
        "Longitude (Decimal Degrees)": -84.08,
        "Latitude": 464500000,
        "Longitude": -840500000,
        "Elevation (m)": 221,
        "First Year": 1915,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1975,
        "MLY First Year": 1915,
        "MLY Last Year": 1975
      },
      {
        "Name": "STEEP HILL FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6058010",
        "Station ID": 4098,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.07,
        "Longitude (Decimal Degrees)": -84.8,
        "Latitude": 480400000,
        "Longitude": -844800000,
        "Elevation (m)": 335.3,
        "First Year": 1915,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1939,
        "MLY First Year": 1915,
        "MLY Last Year": 1939
      },
      {
        "Name": "WALFORD",
        "Province": "ONTARIO",
        "Climate ID": "6059210",
        "Station ID": 4100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -82.23,
        "Latitude": 461200000,
        "Longitude": -821400000,
        "Elevation (m)": 213.7,
        "First Year": 1976,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "WALFORD",
        "Province": "ONTARIO",
        "Climate ID": "6059211",
        "Station ID": 4101,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -82.28,
        "Latitude": 461200000,
        "Longitude": -821700000,
        "Elevation (m)": 182.9,
        "First Year": 1979,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1979,
        "MLY First Year": 1979,
        "MLY Last Year": 1979
      },
      {
        "Name": "WAWA A",
        "Province": "ONTARIO",
        "Climate ID": "6059407",
        "Station ID": 52818,
        "WMO ID": 71738,
        "TC ID": "YXZ",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -84.79,
        "Latitude": 475801000,
        "Longitude": -844711000,
        "Elevation (m)": 287.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WAWA (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6059408",
        "Station ID": 4102,
        "WMO ID": null,
        "TC ID": "WXZ",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -84.78,
        "Latitude": 475800000,
        "Longitude": -844700000,
        "Elevation (m)": 287.1,
        "First Year": 1940,
        "Last Year": 2015,
        "HLY First Year": 1994,
        "HLY Last Year": 2015,
        "DLY First Year": 1940,
        "DLY Last Year": 2006,
        "MLY First Year": 1940,
        "MLY Last Year": 2006
      },
      {
        "Name": "WAWA",
        "Province": "ONTARIO",
        "Climate ID": "6059409",
        "Station ID": 4103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48,
        "Longitude (Decimal Degrees)": -84.8,
        "Latitude": 480000000,
        "Longitude": -844800000,
        "Elevation (m)": 297.2,
        "First Year": 1969,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1984,
        "MLY First Year": 1969,
        "MLY Last Year": 1984
      },
      {
        "Name": "WAWA A",
        "Province": "ONTARIO",
        "Climate ID": "6059413",
        "Station ID": 52998,
        "WMO ID": null,
        "TC ID": "YXZ",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -84.79,
        "Latitude": 475801000,
        "Longitude": -844711000,
        "Elevation (m)": 287.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WHITE RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6059475",
        "Station ID": 4104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -85.28,
        "Latitude": 483600000,
        "Longitude": -851700000,
        "Elevation (m)": 378.9,
        "First Year": 1886,
        "Last Year": 1976,
        "HLY First Year": 1953,
        "HLY Last Year": 1976,
        "DLY First Year": 1886,
        "DLY Last Year": 1976,
        "MLY First Year": 1886,
        "MLY Last Year": 1976
      },
      {
        "Name": "WAWA A",
        "Province": "ONTARIO",
        "Climate ID": "6059D09",
        "Station ID": 4099,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -84.78,
        "Latitude": 475800000,
        "Longitude": -844700000,
        "Elevation (m)": 287.1,
        "First Year": 1977,
        "Last Year": 2014,
        "HLY First Year": 1977,
        "HLY Last Year": 2014,
        "DLY First Year": 1977,
        "DLY Last Year": 2014,
        "MLY First Year": 1977,
        "MLY Last Year": 2014
      },
      {
        "Name": "KILLARNEY (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "605DJ25",
        "Station ID": 8997,
        "WMO ID": 71460,
        "TC ID": "WBE",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -81.48,
        "Latitude": 455800000,
        "Longitude": -812900000,
        "Elevation (m)": 196.3,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AGNEW MINE",
        "Province": "ONTARIO",
        "Climate ID": "6060070",
        "Station ID": 4105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -81.62,
        "Latitude": 462600000,
        "Longitude": -813700000,
        "Elevation (m)": 305.1,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "BENNY",
        "Province": "ONTARIO",
        "Climate ID": "6060725",
        "Station ID": 4106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.52,
        "Longitude (Decimal Degrees)": -81.63,
        "Latitude": 463100000,
        "Longitude": -813800000,
        "Elevation (m)": 386.8,
        "First Year": 1948,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1956,
        "MLY First Year": 1948,
        "MLY Last Year": 1956
      },
      {
        "Name": "BISCOTASING",
        "Province": "ONTARIO",
        "Climate ID": "6060773",
        "Station ID": 4107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.3,
        "Longitude (Decimal Degrees)": -82.1,
        "Latitude": 471800000,
        "Longitude": -820600000,
        "Elevation (m)": 406.9,
        "First Year": 1914,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2000,
        "MLY First Year": 1914,
        "MLY Last Year": 2000
      },
      {
        "Name": "CARTIER",
        "Province": "ONTARIO",
        "Climate ID": "6061263",
        "Station ID": 4108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.7,
        "Longitude (Decimal Degrees)": -81.57,
        "Latitude": 464200000,
        "Longitude": -813400000,
        "Elevation (m)": 420.9,
        "First Year": 1886,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1948,
        "MLY First Year": 1886,
        "MLY Last Year": 1948
      },
      {
        "Name": "CHAPLEAU",
        "Province": "ONTARIO",
        "Climate ID": "6061358",
        "Station ID": 4109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.83,
        "Longitude (Decimal Degrees)": -83.43,
        "Latitude": 475000000,
        "Longitude": -832600000,
        "Elevation (m)": 428.2,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": 1965,
        "HLY Last Year": 1976,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "CHAPLEAU 2",
        "Province": "ONTARIO",
        "Climate ID": "6061359",
        "Station ID": 4110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.83,
        "Longitude (Decimal Degrees)": -83.43,
        "Latitude": 475000000,
        "Longitude": -832600000,
        "Elevation (m)": 431.6,
        "First Year": 1886,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1966,
        "MLY First Year": 1886,
        "MLY Last Year": 1966
      },
      {
        "Name": "CHAPLEAU A",
        "Province": "ONTARIO",
        "Climate ID": "6061361",
        "Station ID": 4111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -83.35,
        "Latitude": 474912000,
        "Longitude": -832048000,
        "Elevation (m)": 448.06,
        "First Year": 1978,
        "Last Year": 2015,
        "HLY First Year": 1994,
        "HLY Last Year": 2015,
        "DLY First Year": 1978,
        "DLY Last Year": 2015,
        "MLY First Year": 1978,
        "MLY Last Year": 2015
      },
      {
        "Name": "CHAPLEAU A",
        "Province": "ONTARIO",
        "Climate ID": "6061362",
        "Station ID": 53602,
        "WMO ID": 71642,
        "TC ID": "YLD",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -83.35,
        "Latitude": 474913000,
        "Longitude": -832049000,
        "Elevation (m)": 448.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHAPLEAU A",
        "Province": "ONTARIO",
        "Climate ID": "6061365",
        "Station ID": 53603,
        "WMO ID": null,
        "TC ID": "YLD",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -83.35,
        "Latitude": 474913000,
        "Longitude": -832049000,
        "Elevation (m)": 448.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHAPLEAU AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6061366",
        "Station ID": 55258,
        "WMO ID": 73135,
        "TC ID": "OCP",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -83.36,
        "Latitude": 474909006,
        "Longitude": -832122086,
        "Elevation (m)": 447,
        "First Year": 2022,
        "Last Year": 2022,
        "HLY First Year": 2022,
        "HLY Last Year": 2022,
        "DLY First Year": 2022,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CONISTON",
        "Province": "ONTARIO",
        "Climate ID": "6061847",
        "Station ID": 4112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -80.82,
        "Latitude": 462800000,
        "Longitude": -804900000,
        "Elevation (m)": 237.4,
        "First Year": 1921,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1976,
        "MLY First Year": 1921,
        "MLY Last Year": 1976
      },
      {
        "Name": "CONISTON STP",
        "Province": "ONTARIO",
        "Climate ID": "6061850",
        "Station ID": 4113,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -80.85,
        "Latitude": 462900000,
        "Longitude": -805100000,
        "Elevation (m)": 267.6,
        "First Year": 1962,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2002,
        "MLY First Year": 1962,
        "MLY Last Year": 2002
      },
      {
        "Name": "COPPER CLIFF",
        "Province": "ONTARIO",
        "Climate ID": "6061870",
        "Station ID": 4114,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -81.05,
        "Latitude": 462900000,
        "Longitude": -810300000,
        "Elevation (m)": 274.3,
        "First Year": 1906,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1968,
        "MLY First Year": 1906,
        "MLY Last Year": 1968
      },
      {
        "Name": "FOLEYET",
        "Province": "ONTARIO",
        "Climate ID": "6062425",
        "Station ID": 4115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -82.43,
        "Latitude": 481500000,
        "Longitude": -822600000,
        "Elevation (m)": 328.6,
        "First Year": 1931,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1974,
        "MLY First Year": 1931,
        "MLY Last Year": 1973
      },
      {
        "Name": "GARSON",
        "Province": "ONTARIO",
        "Climate ID": "6062665",
        "Station ID": 4116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.57,
        "Longitude (Decimal Degrees)": -80.87,
        "Latitude": 463400000,
        "Longitude": -805200000,
        "Elevation (m)": 289.6,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "GOGAMA TREE NURSERY",
        "Province": "ONTARIO",
        "Climate ID": "6062860",
        "Station ID": 4117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.68,
        "Longitude (Decimal Degrees)": -81.72,
        "Latitude": 474100000,
        "Longitude": -814300000,
        "Elevation (m)": 352,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "LIVELY",
        "Province": "ONTARIO",
        "Climate ID": "6064460",
        "Station ID": 4118,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -81.15,
        "Latitude": 462600000,
        "Longitude": -810900000,
        "Elevation (m)": 282,
        "First Year": 1981,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1991,
        "MLY First Year": 1981,
        "MLY Last Year": 1991
      },
      {
        "Name": "MANVILLE REEVES",
        "Province": "ONTARIO",
        "Climate ID": "6064942",
        "Station ID": 4119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -82.08,
        "Latitude": 481300000,
        "Longitude": -820500000,
        "Elevation (m)": 335.3,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "MASSEY",
        "Province": "ONTARIO",
        "Climate ID": "6065005",
        "Station ID": 4120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -82.07,
        "Latitude": 461300000,
        "Longitude": -820400000,
        "Elevation (m)": 187.5,
        "First Year": 1963,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1964,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "MASSEY",
        "Province": "ONTARIO",
        "Climate ID": "6065006",
        "Station ID": 4121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.19,
        "Longitude (Decimal Degrees)": -82.02,
        "Latitude": 461135088,
        "Longitude": -820129022,
        "Elevation (m)": 200,
        "First Year": 1983,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2022,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "MATTAGAMI LAKE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6065015",
        "Station ID": 4122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.02,
        "Longitude (Decimal Degrees)": -81.55,
        "Latitude": 480100000,
        "Longitude": -813300000,
        "Elevation (m)": 327.7,
        "First Year": 1950,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1957,
        "MLY First Year": 1950,
        "MLY Last Year": 1957
      },
      {
        "Name": "MATTAGAMI PATROL",
        "Province": "ONTARIO",
        "Climate ID": "6065020",
        "Station ID": 4123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.9,
        "Longitude (Decimal Degrees)": -81.55,
        "Latitude": 475400000,
        "Longitude": -813300000,
        "Elevation (m)": 329.2,
        "First Year": 1957,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1961,
        "MLY First Year": 1957,
        "MLY Last Year": 1961
      },
      {
        "Name": "MCVITTIES",
        "Province": "ONTARIO",
        "Climate ID": "6065043",
        "Station ID": 4124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.28,
        "Longitude (Decimal Degrees)": -80.85,
        "Latitude": 461700000,
        "Longitude": -805100000,
        "Elevation (m)": 213.4,
        "First Year": 1950,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1974,
        "MLY First Year": 1950,
        "MLY Last Year": 1974
      },
      {
        "Name": "MONETVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6065250",
        "Station ID": 4125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.14,
        "Longitude (Decimal Degrees)": -80.31,
        "Latitude": 460833006,
        "Longitude": -801825044,
        "Elevation (m)": 221,
        "First Year": 1963,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2012,
        "MLY First Year": 1963,
        "MLY Last Year": 2006
      },
      {
        "Name": "MONETVILLE 2",
        "Province": "ONTARIO",
        "Climate ID": "6065252",
        "Station ID": 53618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -80.3,
        "Latitude": 461255000,
        "Longitude": -801811000,
        "Elevation (m)": 205,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RAMSAY",
        "Province": "ONTARIO",
        "Climate ID": "6066873",
        "Station ID": 4126,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -82.33,
        "Latitude": 472700000,
        "Longitude": -822000000,
        "Elevation (m)": 429.8,
        "First Year": 1973,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1983,
        "MLY First Year": 1973,
        "MLY Last Year": 1983
      },
      {
        "Name": "RAMSEY",
        "Province": "ONTARIO",
        "Climate ID": "6066875",
        "Station ID": 4127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -82.32,
        "Latitude": 472500000,
        "Longitude": -821900000,
        "Elevation (m)": 426.1,
        "First Year": 1948,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1967,
        "MLY First Year": 1948,
        "MLY Last Year": 1967
      },
      {
        "Name": "RAMSEY 2",
        "Province": "ONTARIO",
        "Climate ID": "6066877",
        "Station ID": 4128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.47,
        "Longitude (Decimal Degrees)": -81.87,
        "Latitude": 472800000,
        "Longitude": -815200000,
        "Elevation (m)": 402,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "RUEL",
        "Province": "ONTARIO",
        "Climate ID": "6067211",
        "Station ID": 4129,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.3,
        "Longitude (Decimal Degrees)": -81.45,
        "Latitude": 471800000,
        "Longitude": -812700000,
        "Elevation (m)": 409.7,
        "First Year": 1915,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1959,
        "MLY First Year": 1915,
        "MLY Last Year": 1959
      },
      {
        "Name": "ST CHARLES",
        "Province": "ONTARIO",
        "Climate ID": "6067308",
        "Station ID": 4130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -80.52,
        "Latitude": 462200000,
        "Longitude": -803100000,
        "Elevation (m)": 235.9,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "SUDBURY CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6068145",
        "Station ID": 49508,
        "WMO ID": 71733,
        "TC ID": "TSB",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -80.8,
        "Latitude": 463756090,
        "Longitude": -804746080,
        "Elevation (m)": 348,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUDBURY",
        "Province": "ONTARIO",
        "Climate ID": "6068148",
        "Station ID": 4131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -80.98,
        "Latitude": 462900000,
        "Longitude": -805900000,
        "Elevation (m)": 259.1,
        "First Year": 1887,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1887,
        "DLY Last Year": 1977,
        "MLY First Year": 1914,
        "MLY Last Year": 1977
      },
      {
        "Name": "SUDBURY A",
        "Province": "ONTARIO",
        "Climate ID": "6068150",
        "Station ID": 4132,
        "WMO ID": 71730,
        "TC ID": "YSB",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -80.8,
        "Latitude": 463732000,
        "Longitude": -804752000,
        "Elevation (m)": 348.39,
        "First Year": 1954,
        "Last Year": 2013,
        "HLY First Year": 1954,
        "HLY Last Year": 2013,
        "DLY First Year": 1954,
        "DLY Last Year": 2013,
        "MLY First Year": 1954,
        "MLY Last Year": 2013
      },
      {
        "Name": "SUDBURY A",
        "Province": "ONTARIO",
        "Climate ID": "6068153",
        "Station ID": 50840,
        "WMO ID": 71730,
        "TC ID": "YSB",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -80.8,
        "Latitude": 463732000,
        "Longitude": -804752000,
        "Elevation (m)": 348.4,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUDBURY MOE",
        "Province": "ONTARIO",
        "Climate ID": "6068155",
        "Station ID": 4133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -81.03,
        "Latitude": 462800000,
        "Longitude": -810200000,
        "Elevation (m)": 259.1,
        "First Year": 1977,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1979,
        "MLY First Year": 1977,
        "MLY Last Year": 1979
      },
      {
        "Name": "SUDBURY SCIENCE NORTH",
        "Province": "ONTARIO",
        "Climate ID": "6068158",
        "Station ID": 4134,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -81,
        "Latitude": 462800000,
        "Longitude": -810000000,
        "Elevation (m)": 263,
        "First Year": 1986,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1996,
        "MLY First Year": 1986,
        "MLY Last Year": 1996
      },
      {
        "Name": "TURBINE",
        "Province": "ONTARIO",
        "Climate ID": "6068980",
        "Station ID": 4135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -81.57,
        "Latitude": 462300000,
        "Longitude": -813400000,
        "Elevation (m)": 205.7,
        "First Year": 1914,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1990,
        "MLY First Year": 1914,
        "MLY Last Year": 1990
      },
      {
        "Name": "WABAGISHIK",
        "Province": "ONTARIO",
        "Climate ID": "6069165",
        "Station ID": 4137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -81.52,
        "Latitude": 461900000,
        "Longitude": -813100000,
        "Elevation (m)": 213.4,
        "First Year": 1978,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1989,
        "MLY First Year": 1978,
        "MLY Last Year": 1989
      },
      {
        "Name": "WAHNAPITAE-STOKES",
        "Province": "ONTARIO",
        "Climate ID": "6069197",
        "Station ID": 4138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -80.72,
        "Latitude": 462600000,
        "Longitude": -804300000,
        "Elevation (m)": 750,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "WEBBWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6069428",
        "Station ID": 4139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -81.88,
        "Latitude": 461600000,
        "Longitude": -815300000,
        "Elevation (m)": 196,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "WARREN",
        "Province": "ONTARIO",
        "Climate ID": "6069K90",
        "Station ID": 4136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -80.32,
        "Latitude": 462600000,
        "Longitude": -801900000,
        "Elevation (m)": 212,
        "First Year": 1987,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1998,
        "MLY First Year": 1987,
        "MLY Last Year": 1998
      },
      {
        "Name": "ABITIBI CANYON",
        "Province": "ONTARIO",
        "Climate ID": "6070027",
        "Station ID": 4141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -81.57,
        "Latitude": 495300000,
        "Longitude": -813400000,
        "Elevation (m)": 204.2,
        "First Year": 1931,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1963,
        "MLY First Year": 1931,
        "MLY Last Year": 1963
      },
      {
        "Name": "BONNER LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6070QK6",
        "Station ID": 4140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.38,
        "Longitude (Decimal Degrees)": -82.12,
        "Latitude": 492300000,
        "Longitude": -820725000,
        "Elevation (m)": 245,
        "First Year": 1990,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2022,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "COCHRANE",
        "Province": "ONTARIO",
        "Climate ID": "6071712",
        "Station ID": 4142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -81.03,
        "Latitude": 490400000,
        "Longitude": -810200000,
        "Elevation (m)": 274.9,
        "First Year": 1910,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1993,
        "MLY First Year": 1910,
        "MLY Last Year": 1993
      },
      {
        "Name": "CONNAUGHT",
        "Province": "ONTARIO",
        "Climate ID": "6071855",
        "Station ID": 4143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -80.92,
        "Latitude": 483700000,
        "Longitude": -805500000,
        "Elevation (m)": 281.3,
        "First Year": 1962,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1981,
        "MLY First Year": 1962,
        "MLY Last Year": 1981
      },
      {
        "Name": "CONNAUGHT",
        "Province": "ONTARIO",
        "Climate ID": "6071860",
        "Station ID": 48908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -80.9,
        "Latitude": 483700000,
        "Longitude": -805400000,
        "Elevation (m)": 281.3,
        "First Year": 2010,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2010,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DYMOND ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6072183",
        "Station ID": 4144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.52,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 473100000,
        "Longitude": -794100000,
        "Elevation (m)": 198.1,
        "First Year": 1973,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1998,
        "MLY First Year": 1973,
        "MLY Last Year": 1998
      },
      {
        "Name": "EARLTON A",
        "Province": "ONTARIO",
        "Climate ID": "6072223",
        "Station ID": 49489,
        "WMO ID": 71684,
        "TC ID": "YXR",
        "Latitude (Decimal Degrees)": 47.7,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 474142000,
        "Longitude": -795056000,
        "Elevation (m)": 243.8,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EARLTON AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6072224",
        "Station ID": 30246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.7,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 474142000,
        "Longitude": -795056000,
        "Elevation (m)": 243.4,
        "First Year": 2000,
        "Last Year": 2011,
        "HLY First Year": 2000,
        "HLY Last Year": 2011,
        "DLY First Year": 2000,
        "DLY Last Year": 2011,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "EARLTON A",
        "Province": "ONTARIO",
        "Climate ID": "6072225",
        "Station ID": 4145,
        "WMO ID": null,
        "TC ID": "YXR",
        "Latitude (Decimal Degrees)": 47.7,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 474200000,
        "Longitude": -795100000,
        "Elevation (m)": 243.2,
        "First Year": 1938,
        "Last Year": 2005,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1938,
        "DLY Last Year": 2005,
        "MLY First Year": 1938,
        "MLY Last Year": 2004
      },
      {
        "Name": "EARLTON CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6072230",
        "Station ID": 47687,
        "WMO ID": 71213,
        "TC ID": "TXR",
        "Latitude (Decimal Degrees)": 47.7,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 474142000,
        "Longitude": -795056000,
        "Elevation (m)": 243.4,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ENGLEHART",
        "Province": "ONTARIO",
        "Climate ID": "6072325",
        "Station ID": 4146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 474900000,
        "Longitude": -795400000,
        "Elevation (m)": 251.5,
        "First Year": 1948,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2000,
        "MLY First Year": 1948,
        "MLY Last Year": 2000
      },
      {
        "Name": "FORT ALBANY",
        "Province": "ONTARIO",
        "Climate ID": "6072460",
        "Station ID": 4147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.22,
        "Longitude (Decimal Degrees)": -81.67,
        "Latitude": 521300000,
        "Longitude": -814000000,
        "Elevation (m)": 15.2,
        "First Year": 1968,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1993,
        "MLY First Year": 1968,
        "MLY Last Year": 1993
      },
      {
        "Name": "FREDERICKHOUSE LAKE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6072595",
        "Station ID": 4148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -80.98,
        "Latitude": 484600000,
        "Longitude": -805900000,
        "Elevation (m)": 283.5,
        "First Year": 1950,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1961,
        "MLY First Year": 1950,
        "MLY Last Year": 1961
      },
      {
        "Name": "HAILEYBURY",
        "Province": "ONTARIO",
        "Climate ID": "6073138",
        "Station ID": 4149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 472700000,
        "Longitude": -793800000,
        "Elevation (m)": 189,
        "First Year": 1893,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1893,
        "DLY Last Year": 1977,
        "MLY First Year": 1893,
        "MLY Last Year": 1977
      },
      {
        "Name": "HEASLIP",
        "Province": "ONTARIO",
        "Climate ID": "6073420",
        "Station ID": 4150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 474800000,
        "Longitude": -795000000,
        "Elevation (m)": 221.9,
        "First Year": 1928,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1967,
        "MLY First Year": 1928,
        "MLY Last Year": 1967
      },
      {
        "Name": "HOUND CHUTE",
        "Province": "ONTARIO",
        "Climate ID": "6073600",
        "Station ID": 4151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.3,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 471800000,
        "Longitude": -794200000,
        "Elevation (m)": 278.9,
        "First Year": 1950,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1969,
        "MLY First Year": 1950,
        "MLY Last Year": 1969
      },
      {
        "Name": "HUNTA",
        "Province": "ONTARIO",
        "Climate ID": "6073630",
        "Station ID": 4152,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -81.27,
        "Latitude": 490700000,
        "Longitude": -811600000,
        "Elevation (m)": 274.3,
        "First Year": 1950,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1973,
        "MLY First Year": 1950,
        "MLY Last Year": 1973
      },
      {
        "Name": "INDIAN CHUTE",
        "Province": "ONTARIO",
        "Climate ID": "6073750",
        "Station ID": 4153,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.85,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 475100000,
        "Longitude": -802700000,
        "Elevation (m)": 292.6,
        "First Year": 1950,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1972,
        "MLY First Year": 1950,
        "MLY Last Year": 1972
      },
      {
        "Name": "IROQUOIS FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6073810",
        "Station ID": 4154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -80.67,
        "Latitude": 484500000,
        "Longitude": -804000000,
        "Elevation (m)": 259.1,
        "First Year": 1913,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1998,
        "MLY First Year": 1913,
        "MLY Last Year": 1998
      },
      {
        "Name": "ISLAND FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6073840",
        "Station ID": 4155,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.58,
        "Longitude (Decimal Degrees)": -81.38,
        "Latitude": 493500000,
        "Longitude": -812300000,
        "Elevation (m)": 213,
        "First Year": 1955,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1996,
        "MLY First Year": 1955,
        "MLY Last Year": 1996
      },
      {
        "Name": "KAPUSKASING CDA",
        "Province": "ONTARIO",
        "Climate ID": "6073960",
        "Station ID": 4156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -82.43,
        "Latitude": 492400000,
        "Longitude": -822600000,
        "Elevation (m)": 217.9,
        "First Year": 1918,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 2001,
        "MLY First Year": 1918,
        "MLY Last Year": 2001
      },
      {
        "Name": "KAPUSKASING A",
        "Province": "ONTARIO",
        "Climate ID": "6073975",
        "Station ID": 4157,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.41,
        "Longitude (Decimal Degrees)": -82.47,
        "Latitude": 492450000,
        "Longitude": -822803000,
        "Elevation (m)": 226.5,
        "First Year": 1937,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1937,
        "DLY Last Year": 2014,
        "MLY First Year": 1937,
        "MLY Last Year": 2014
      },
      {
        "Name": "KAPUSKASING A",
        "Province": "ONTARIO",
        "Climate ID": "6073976",
        "Station ID": 52604,
        "WMO ID": 71831,
        "TC ID": "YYU",
        "Latitude (Decimal Degrees)": 49.41,
        "Longitude (Decimal Degrees)": -82.47,
        "Latitude": 492442000,
        "Longitude": -822806000,
        "Elevation (m)": 226.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KAPUSKASING A",
        "Province": "ONTARIO",
        "Climate ID": "6073978",
        "Station ID": 54260,
        "WMO ID": null,
        "TC ID": "YYU",
        "Latitude (Decimal Degrees)": 49.41,
        "Longitude (Decimal Degrees)": -82.47,
        "Latitude": 492442000,
        "Longitude": -822806000,
        "Elevation (m)": 226.5,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KAPUSKASING CDA ON",
        "Province": "ONTARIO",
        "Climate ID": "6073980",
        "Station ID": 30435,
        "WMO ID": 71299,
        "TC ID": "XKA",
        "Latitude (Decimal Degrees)": 49.41,
        "Longitude (Decimal Degrees)": -82.44,
        "Latitude": 492423000,
        "Longitude": -822637000,
        "Elevation (m)": 218,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "KIDD CREEK MINE",
        "Province": "ONTARIO",
        "Climate ID": "6074100",
        "Station ID": 4158,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -81.37,
        "Latitude": 484100000,
        "Longitude": -812200000,
        "Elevation (m)": 277.4,
        "First Year": 1968,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1979,
        "MLY First Year": 1968,
        "MLY Last Year": 1979
      },
      {
        "Name": "KIRKLAND LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6074207",
        "Station ID": 55367,
        "WMO ID": 71301,
        "TC ID": "XKI",
        "Latitude (Decimal Degrees)": 48.09,
        "Longitude (Decimal Degrees)": -80.06,
        "Latitude": 480517054,
        "Longitude": -800341035,
        "Elevation (m)": 280,
        "First Year": 2022,
        "Last Year": 2022,
        "HLY First Year": 2022,
        "HLY Last Year": 2022,
        "DLY First Year": 2022,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KIRKLAND LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6074209",
        "Station ID": 4159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -80,
        "Latitude": 480900000,
        "Longitude": -800000000,
        "Elevation (m)": 324,
        "First Year": 1950,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1996,
        "MLY First Year": 1950,
        "MLY Last Year": 1996
      },
      {
        "Name": "KIRKLAND LAKE CS",
        "Province": "ONTARIO",
        "Climate ID": "6074211",
        "Station ID": 27535,
        "WMO ID": 71301,
        "TC ID": "XKI",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -80,
        "Latitude": 480900000,
        "Longitude": -800000000,
        "Elevation (m)": 324,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "LOWBUSH",
        "Province": "ONTARIO",
        "Climate ID": "6074620",
        "Station ID": 4160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.92,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 485500000,
        "Longitude": -800700000,
        "Elevation (m)": 270.4,
        "First Year": 1951,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1966,
        "MLY First Year": 1951,
        "MLY Last Year": 1966
      },
      {
        "Name": "LOWER STURGEON",
        "Province": "ONTARIO",
        "Climate ID": "6074630",
        "Station ID": 4161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -81.48,
        "Latitude": 484900000,
        "Longitude": -812900000,
        "Elevation (m)": 246.9,
        "First Year": 1950,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1967,
        "MLY First Year": 1950,
        "MLY Last Year": 1967
      },
      {
        "Name": "MATHESON",
        "Province": "ONTARIO",
        "Climate ID": "6075012",
        "Station ID": 4162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 483200000,
        "Longitude": -802700000,
        "Elevation (m)": 259.1,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "MATHESON ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6075013",
        "Station ID": 4163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -80.47,
        "Latitude": 483200000,
        "Longitude": -802800000,
        "Elevation (m)": 274,
        "First Year": 1983,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1992,
        "MLY First Year": 1983,
        "MLY Last Year": 1992
      },
      {
        "Name": "MATTICE TCPL",
        "Province": "ONTARIO",
        "Climate ID": "6075024",
        "Station ID": 4164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -83.17,
        "Latitude": 493600000,
        "Longitude": -831000000,
        "Elevation (m)": 233.2,
        "First Year": 1966,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1995,
        "MLY First Year": 1966,
        "MLY Last Year": 1995
      },
      {
        "Name": "MISTINIKON LAKE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6075211",
        "Station ID": 4165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -80.72,
        "Latitude": 480300000,
        "Longitude": -804300000,
        "Elevation (m)": 320,
        "First Year": 1950,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1960,
        "MLY First Year": 1950,
        "MLY Last Year": 1960
      },
      {
        "Name": "MONTREAL RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6075379",
        "Station ID": 4166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.12,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 470700000,
        "Longitude": -792900000,
        "Elevation (m)": 182.9,
        "First Year": 1910,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1967,
        "MLY First Year": 1910,
        "MLY Last Year": 1967
      },
      {
        "Name": "MOOSE FACTORY",
        "Province": "ONTARIO",
        "Climate ID": "6075400",
        "Station ID": 4167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.23,
        "Longitude (Decimal Degrees)": -80.5,
        "Latitude": 511400000,
        "Longitude": -803000000,
        "Elevation (m)": 8.8,
        "First Year": 1877,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1877,
        "DLY Last Year": 1938,
        "MLY First Year": 1877,
        "MLY Last Year": 1929
      },
      {
        "Name": "MOOSONEE",
        "Province": "ONTARIO",
        "Climate ID": "6075420",
        "Station ID": 48950,
        "WMO ID": 71398,
        "TC ID": "YMO",
        "Latitude (Decimal Degrees)": 51.29,
        "Longitude (Decimal Degrees)": -80.61,
        "Latitude": 511728000,
        "Longitude": -803628000,
        "Elevation (m)": 9.1,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOOSONEE UA",
        "Province": "ONTARIO",
        "Climate ID": "6075425",
        "Station ID": 4168,
        "WMO ID": 71836,
        "TC ID": "WZC",
        "Latitude (Decimal Degrees)": 51.27,
        "Longitude (Decimal Degrees)": -80.65,
        "Latitude": 511600000,
        "Longitude": -803900000,
        "Elevation (m)": 10,
        "First Year": 1932,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 1995,
        "DLY First Year": 1932,
        "DLY Last Year": 2022,
        "MLY First Year": 1932,
        "MLY Last Year": 2006
      },
      {
        "Name": "MOOSONEE A",
        "Province": "ONTARIO",
        "Climate ID": "6075428",
        "Station ID": 7632,
        "WMO ID": 71836,
        "TC ID": "YMO",
        "Latitude (Decimal Degrees)": 51.29,
        "Longitude (Decimal Degrees)": -80.61,
        "Latitude": 511729000,
        "Longitude": -803627000,
        "Elevation (m)": 9.1,
        "First Year": 1994,
        "Last Year": 2006,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": 1995,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOOSONEE AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6075431",
        "Station ID": 30242,
        "WMO ID": 71836,
        "TC ID": "YMO",
        "Latitude (Decimal Degrees)": 51.28,
        "Longitude (Decimal Degrees)": -80.6,
        "Latitude": 511700000,
        "Longitude": -803600000,
        "Elevation (m)": 9.1,
        "First Year": 2006,
        "Last Year": 2010,
        "HLY First Year": 2006,
        "HLY Last Year": 2010,
        "DLY First Year": 2006,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOOSONEE RCS",
        "Province": "ONTARIO",
        "Climate ID": "6075435",
        "Station ID": 42123,
        "WMO ID": 71309,
        "TC ID": "XZC",
        "Latitude (Decimal Degrees)": 51.29,
        "Longitude (Decimal Degrees)": -80.62,
        "Latitude": 511730000,
        "Longitude": -803705000,
        "Elevation (m)": 9.1,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "NAGAGAMI",
        "Province": "ONTARIO",
        "Climate ID": "6075542",
        "Station ID": 4169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -84.52,
        "Latitude": 494600000,
        "Longitude": -843100000,
        "Elevation (m)": 243.8,
        "First Year": 1964,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1969,
        "MLY First Year": 1964,
        "MLY Last Year": 1969
      },
      {
        "Name": "NAGAGAMI (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6075543",
        "Station ID": 7633,
        "WMO ID": 71832,
        "TC ID": "WNZ",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -84.16,
        "Latitude": 494448600,
        "Longitude": -840949300,
        "Elevation (m)": 264,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "NEW LISKEARD",
        "Province": "ONTARIO",
        "Climate ID": "6075594",
        "Station ID": 4170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.5,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 473000000,
        "Longitude": -794000000,
        "Elevation (m)": 194.2,
        "First Year": 1923,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1984,
        "MLY First Year": 1923,
        "MLY Last Year": 1984
      },
      {
        "Name": "OGOKI POST A",
        "Province": "ONTARIO",
        "Climate ID": "6075786",
        "Station ID": 52898,
        "WMO ID": 71652,
        "TC ID": "YKP",
        "Latitude (Decimal Degrees)": 51.66,
        "Longitude (Decimal Degrees)": -85.9,
        "Latitude": 513931000,
        "Longitude": -855404000,
        "Elevation (m)": 181,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PAGWA",
        "Province": "ONTARIO",
        "Climate ID": "6076180",
        "Station ID": 4171,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.05,
        "Longitude (Decimal Degrees)": -85.3,
        "Latitude": 500300000,
        "Longitude": -851800000,
        "Elevation (m)": 189,
        "First Year": 1918,
        "Last Year": 1934,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1934,
        "MLY First Year": 1918,
        "MLY Last Year": 1934
      },
      {
        "Name": "PAGWA A",
        "Province": "ONTARIO",
        "Climate ID": "6076200",
        "Station ID": 4172,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -85.27,
        "Latitude": 500200000,
        "Longitude": -851600000,
        "Elevation (m)": 189,
        "First Year": 1938,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1964,
        "MLY First Year": 1938,
        "MLY Last Year": 1964
      },
      {
        "Name": "PINARD",
        "Province": "ONTARIO",
        "Climate ID": "6076540",
        "Station ID": 4173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -81.6,
        "Latitude": 495100000,
        "Longitude": -813600000,
        "Elevation (m)": 231.6,
        "First Year": 1963,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1982,
        "MLY First Year": 1963,
        "MLY Last Year": 1982
      },
      {
        "Name": "PORCUPINE ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6076572",
        "Station ID": 4174,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -81.27,
        "Latitude": 482800000,
        "Longitude": -811600000,
        "Elevation (m)": 298.7,
        "First Year": 1969,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2001,
        "MLY First Year": 1969,
        "MLY Last Year": 2001
      },
      {
        "Name": "PORQUIS JUNCTION A",
        "Province": "ONTARIO",
        "Climate ID": "6076575",
        "Station ID": 4175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -80.8,
        "Latitude": 484400000,
        "Longitude": -804800000,
        "Elevation (m)": 307.5,
        "First Year": 1938,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1955,
        "MLY First Year": 1938,
        "MLY Last Year": 1955
      },
      {
        "Name": "RAMORE TS",
        "Province": "ONTARIO",
        "Climate ID": "6076870",
        "Station ID": 4176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -80.43,
        "Latitude": 482000000,
        "Longitude": -802600000,
        "Elevation (m)": 286.5,
        "First Year": 1960,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "SANDY FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6077475",
        "Station ID": 4177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -81.43,
        "Latitude": 483100000,
        "Longitude": -812600000,
        "Elevation (m)": 262.1,
        "First Year": 1950,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1964,
        "MLY First Year": 1950,
        "MLY Last Year": 1964
      },
      {
        "Name": "SMOKY FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6077845",
        "Station ID": 4178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.07,
        "Longitude (Decimal Degrees)": -82.17,
        "Latitude": 500400000,
        "Longitude": -821000000,
        "Elevation (m)": 182.9,
        "First Year": 1933,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1997,
        "MLY First Year": 1933,
        "MLY Last Year": 1997
      },
      {
        "Name": "TIMMINS",
        "Province": "ONTARIO",
        "Climate ID": "6078280",
        "Station ID": 4179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -81.33,
        "Latitude": 483000000,
        "Longitude": -812000000,
        "Elevation (m)": 335.3,
        "First Year": 1922,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1957,
        "MLY First Year": 1922,
        "MLY Last Year": 1957
      },
      {
        "Name": "TIMMINS CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6078282",
        "Station ID": 47547,
        "WMO ID": 71193,
        "TC ID": "TMS",
        "Latitude (Decimal Degrees)": 48.56,
        "Longitude (Decimal Degrees)": -81.39,
        "Latitude": 483326000,
        "Longitude": -812325000,
        "Elevation (m)": 294.4,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TIMMINS VICTOR POWER A",
        "Province": "ONTARIO",
        "Climate ID": "6078285",
        "Station ID": 4180,
        "WMO ID": 71739,
        "TC ID": "YTS",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -81.38,
        "Latitude": 483411000,
        "Longitude": -812236000,
        "Elevation (m)": 294.7,
        "First Year": 1955,
        "Last Year": 2012,
        "HLY First Year": 1955,
        "HLY Last Year": 2012,
        "DLY First Year": 1955,
        "DLY Last Year": 2012,
        "MLY First Year": 1955,
        "MLY Last Year": 2011
      },
      {
        "Name": "TIMMINS A",
        "Province": "ONTARIO",
        "Climate ID": "6078286",
        "Station ID": 50460,
        "WMO ID": 71739,
        "TC ID": "YTS",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -81.38,
        "Latitude": 483414000,
        "Longitude": -812236000,
        "Elevation (m)": 294.7,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TIMMINS ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6078290",
        "Station ID": 4181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -81.37,
        "Latitude": 482800000,
        "Longitude": -812200000,
        "Elevation (m)": 307.8,
        "First Year": 1951,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1969,
        "MLY First Year": 1951,
        "MLY Last Year": 1969
      },
      {
        "Name": "TWIN FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6079040",
        "Station ID": 4182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -80.58,
        "Latitude": 484500000,
        "Longitude": -803500000,
        "Elevation (m)": 274.3,
        "First Year": 1955,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1973,
        "MLY First Year": 1955,
        "MLY Last Year": 1973
      },
      {
        "Name": "UPPER NOTCH",
        "Province": "ONTARIO",
        "Climate ID": "6079068",
        "Station ID": 4183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.25,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 471500000,
        "Longitude": -793500000,
        "Elevation (m)": 240.8,
        "First Year": 1950,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1971,
        "MLY First Year": 1950,
        "MLY Last Year": 1971
      },
      {
        "Name": "WAWAITIN",
        "Province": "ONTARIO",
        "Climate ID": "6079415",
        "Station ID": 4184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -81.4,
        "Latitude": 482100000,
        "Longitude": -812400000,
        "Elevation (m)": 271.3,
        "First Year": 1913,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1965,
        "MLY First Year": 1913,
        "MLY Last Year": 1965
      },
      {
        "Name": "ONAKAWANA (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "607EP80",
        "Station ID": 10685,
        "WMO ID": null,
        "TC ID": "WOQ",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -81.42,
        "Latitude": 503600000,
        "Longitude": -812500000,
        "Elevation (m)": 42.1,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALGONQUIN PARK",
        "Province": "ONTARIO",
        "Climate ID": "6080189",
        "Station ID": 4187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -78.55,
        "Latitude": 453500000,
        "Longitude": -783300000,
        "Elevation (m)": 432.5,
        "First Year": 1917,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1960,
        "MLY First Year": 1917,
        "MLY Last Year": 1960
      },
      {
        "Name": "ALGONQUIN PARK EAST",
        "Province": "ONTARIO",
        "Climate ID": "6080191",
        "Station ID": 4188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -78.27,
        "Latitude": 453200000,
        "Longitude": -781600000,
        "Elevation (m)": 411.5,
        "First Year": 1961,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1972,
        "MLY First Year": 1961,
        "MLY Last Year": 1972
      },
      {
        "Name": "ALGONQUIN PARK EAST GATE",
        "Province": "ONTARIO",
        "Climate ID": "6080192",
        "Station ID": 42967,
        "WMO ID": 71581,
        "TC ID": "TNK",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -78.27,
        "Latitude": 453200000,
        "Longitude": -781600000,
        "Elevation (m)": 397,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "ALGONQUIN PARK WEST",
        "Province": "ONTARIO",
        "Climate ID": "6080193",
        "Station ID": 4189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -78.85,
        "Latitude": 452000000,
        "Longitude": -785100000,
        "Elevation (m)": 426.7,
        "First Year": 1961,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1972,
        "MLY First Year": 1961,
        "MLY Last Year": 1972
      },
      {
        "Name": "BEAR ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6080577",
        "Station ID": 4190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.98,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 465900000,
        "Longitude": -800500000,
        "Elevation (m)": 293.8,
        "First Year": 1916,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1962,
        "MLY First Year": 1916,
        "MLY Last Year": 1962
      },
      {
        "Name": "BIG CHAUDIERE FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6080729",
        "Station ID": 4191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 460900000,
        "Longitude": -800100000,
        "Elevation (m)": null,
        "First Year": 1918,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1933,
        "MLY First Year": 1918,
        "MLY Last Year": 1933
      },
      {
        "Name": "BONFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6080HB6",
        "Station ID": 4186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 461300000,
        "Longitude": -790800000,
        "Elevation (m)": 265,
        "First Year": 1983,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1992,
        "MLY First Year": 1983,
        "MLY Last Year": 1992
      },
      {
        "Name": "CRYSTAL FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6081928",
        "Station ID": 4192,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.45,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 462700000,
        "Longitude": -795200000,
        "Elevation (m)": 227.1,
        "First Year": 1922,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1988,
        "MLY First Year": 1922,
        "MLY Last Year": 1988
      },
      {
        "Name": "CRYSTAL FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6081930",
        "Station ID": 43520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.44,
        "Longitude (Decimal Degrees)": -79.91,
        "Latitude": 462637000,
        "Longitude": -795422000,
        "Elevation (m)": 235,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2006,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "DWIGHT",
        "Province": "ONTARIO",
        "Climate ID": "6082178",
        "Station ID": 4193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -78.9,
        "Latitude": 452300000,
        "Longitude": -785400000,
        "Elevation (m)": 404,
        "First Year": 1973,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2005,
        "MLY First Year": 1973,
        "MLY Last Year": 2005
      },
      {
        "Name": "FRENCH R CHAUDIERE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6082612",
        "Station ID": 4194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 460800000,
        "Longitude": -800100000,
        "Elevation (m)": 198.1,
        "First Year": 1969,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2003,
        "MLY First Year": 1969,
        "MLY Last Year": 2003
      },
      {
        "Name": "LA CAVE",
        "Province": "ONTARIO",
        "Climate ID": "6084278",
        "Station ID": 4195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -78.73,
        "Latitude": 462200000,
        "Longitude": -784400000,
        "Elevation (m)": 172.2,
        "First Year": 1950,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1975,
        "MLY First Year": 1950,
        "MLY Last Year": 1975
      },
      {
        "Name": "LAKE OPEONGO",
        "Province": "ONTARIO",
        "Climate ID": "6084300",
        "Station ID": 4196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -78.37,
        "Latitude": 453800000,
        "Longitude": -782200000,
        "Elevation (m)": 402.9,
        "First Year": 1962,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1989,
        "MLY First Year": 1962,
        "MLY Last Year": 1989
      },
      {
        "Name": "LAKE TRAVERSE",
        "Province": "ONTARIO",
        "Climate ID": "6084307",
        "Station ID": 4197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -78.07,
        "Latitude": 455700000,
        "Longitude": -780400000,
        "Elevation (m)": 236.2,
        "First Year": 1965,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1987,
        "MLY First Year": 1965,
        "MLY Last Year": 1987
      },
      {
        "Name": "MADAWASKA",
        "Province": "ONTARIO",
        "Climate ID": "6084770",
        "Station ID": 4198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -77.98,
        "Latitude": 453000000,
        "Longitude": -775900000,
        "Elevation (m)": 316.4,
        "First Year": 1915,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 2000,
        "MLY First Year": 1915,
        "MLY Last Year": 2000
      },
      {
        "Name": "MATTAWA",
        "Province": "ONTARIO",
        "Climate ID": "6085023",
        "Station ID": 4199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -78.68,
        "Latitude": 461500000,
        "Longitude": -784100000,
        "Elevation (m)": 171.3,
        "First Year": 1882,
        "Last Year": 1899,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1899,
        "MLY First Year": 1882,
        "MLY Last Year": 1899
      },
      {
        "Name": "NORTH BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6085680",
        "Station ID": 52318,
        "WMO ID": 71731,
        "TC ID": "YYB",
        "Latitude (Decimal Degrees)": 46.36,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 462150000,
        "Longitude": -792527000,
        "Elevation (m)": 370.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH BAY",
        "Province": "ONTARIO",
        "Climate ID": "6085682",
        "Station ID": 4200,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 461900000,
        "Longitude": -792800000,
        "Elevation (m)": 201.2,
        "First Year": 1887,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1887,
        "DLY Last Year": 1982,
        "MLY First Year": 1887,
        "MLY Last Year": 1982
      },
      {
        "Name": "NORTH BAY AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6085695",
        "Station ID": 54604,
        "WMO ID": 73016,
        "TC ID": "TZN",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 462230000,
        "Longitude": -792409000,
        "Elevation (m)": 370.3,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6085700",
        "Station ID": 4201,
        "WMO ID": 71731,
        "TC ID": "YYB",
        "Latitude (Decimal Degrees)": 46.36,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 462149000,
        "Longitude": -792522000,
        "Elevation (m)": 370.3,
        "First Year": 1939,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1939,
        "DLY Last Year": 2013,
        "MLY First Year": 1939,
        "MLY Last Year": 2013
      },
      {
        "Name": "NORTH BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6085701",
        "Station ID": 50839,
        "WMO ID": null,
        "TC ID": "YYB",
        "Latitude (Decimal Degrees)": 46.36,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 462150000,
        "Longitude": -792527000,
        "Elevation (m)": 370.3,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH BAY NIPISSING(AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6085703",
        "Station ID": 7647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 461900000,
        "Longitude": -792900000,
        "Elevation (m)": 203.5,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": 1994,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH BAY OWRC",
        "Province": "ONTARIO",
        "Climate ID": "6085704",
        "Station ID": 4202,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 461800000,
        "Longitude": -792800000,
        "Elevation (m)": 198.1,
        "First Year": 1960,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1971,
        "MLY First Year": 1960,
        "MLY Last Year": 1971
      },
      {
        "Name": "RED CEDAR LAKE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6086930",
        "Station ID": 4203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.68,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 464100000,
        "Longitude": -800100000,
        "Elevation (m)": 269.7,
        "First Year": 1950,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1954,
        "MLY First Year": 1950,
        "MLY Last Year": 1954
      },
      {
        "Name": "RUTHERGLEN",
        "Province": "ONTARIO",
        "Climate ID": "6087255",
        "Station ID": 4204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -79.07,
        "Latitude": 461500000,
        "Longitude": -790400000,
        "Elevation (m)": 240.5,
        "First Year": 1891,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1940,
        "MLY First Year": 1891,
        "MLY Last Year": 1940
      },
      {
        "Name": "STURGEON FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6088144",
        "Station ID": 4205,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 462200000,
        "Longitude": -795600000,
        "Elevation (m)": 201,
        "First Year": 1883,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 2002,
        "MLY First Year": 1883,
        "MLY Last Year": 2002
      },
      {
        "Name": "TIMAGAMI",
        "Province": "ONTARIO",
        "Climate ID": "6088271",
        "Station ID": 4206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.08,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 470500000,
        "Longitude": -795100000,
        "Elevation (m)": 350.5,
        "First Year": 1966,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1973,
        "MLY First Year": 1966,
        "MLY Last Year": 1973
      },
      {
        "Name": "CACHE BAY",
        "Province": "ONTARIO",
        "Climate ID": "608A06G",
        "Station ID": 4185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 462300000,
        "Longitude": -800100000,
        "Elevation (m)": 198.1,
        "First Year": 1981,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1985,
        "MLY First Year": 1981,
        "MLY Last Year": 1985
      },
      {
        "Name": "COCKBURN ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6091718",
        "Station ID": 4207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -83.3,
        "Latitude": 455700000,
        "Longitude": -831800000,
        "Elevation (m)": 185.9,
        "First Year": 1897,
        "Last Year": 1910,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1910,
        "MLY First Year": 1897,
        "MLY Last Year": 1910
      },
      {
        "Name": "GORE BAY",
        "Province": "ONTARIO",
        "Climate ID": "6092915",
        "Station ID": 4208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -82.47,
        "Latitude": 455500000,
        "Longitude": -822800000,
        "Elevation (m)": 190.5,
        "First Year": 1881,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1881,
        "DLY Last Year": 1983,
        "MLY First Year": 1881,
        "MLY Last Year": 1983
      },
      {
        "Name": "GORE BAY-MANITOULIN",
        "Province": "ONTARIO",
        "Climate ID": "6092919",
        "Station ID": 48372,
        "WMO ID": null,
        "TC ID": "YZE",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -82.57,
        "Latitude": 455254000,
        "Longitude": -823402000,
        "Elevation (m)": 189.89,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GORE BAY CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6092920",
        "Station ID": 48788,
        "WMO ID": 71956,
        "TC ID": "TZE",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -82.57,
        "Latitude": 455300000,
        "Longitude": -823400000,
        "Elevation (m)": 188.6,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GORE BAY A",
        "Province": "ONTARIO",
        "Climate ID": "6092925",
        "Station ID": 4209,
        "WMO ID": null,
        "TC ID": "YZE",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -82.57,
        "Latitude": 455300000,
        "Longitude": -823400000,
        "Elevation (m)": 193.5,
        "First Year": 1947,
        "Last Year": 2006,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1947,
        "DLY Last Year": 2006,
        "MLY First Year": 1947,
        "MLY Last Year": 2006
      },
      {
        "Name": "GORE BAY  AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6092930",
        "Station ID": 30240,
        "WMO ID": null,
        "TC ID": "OZE",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -82.57,
        "Latitude": 455300000,
        "Longitude": -823400000,
        "Elevation (m)": 193.5,
        "First Year": 2005,
        "Last Year": 2012,
        "HLY First Year": 2005,
        "HLY Last Year": 2012,
        "DLY First Year": 2005,
        "DLY Last Year": 2011,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "GREAT DUCK ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6093004",
        "Station ID": 4210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -82.97,
        "Latitude": 453900000,
        "Longitude": -825800000,
        "Elevation (m)": 182.9,
        "First Year": 1966,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1985,
        "MLY First Year": 1966,
        "MLY Last Year": 1985
      },
      {
        "Name": "GREAT DUCK ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6093005",
        "Station ID": 7981,
        "WMO ID": 71462,
        "TC ID": "WNL",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -82.95,
        "Latitude": 453800000,
        "Longitude": -825700000,
        "Elevation (m)": 182.8,
        "First Year": 1994,
        "Last Year": 2021,
        "HLY First Year": 1994,
        "HLY Last Year": 2021,
        "DLY First Year": 1994,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KAGAWONG",
        "Province": "ONTARIO",
        "Climate ID": "6093900",
        "Station ID": 4211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -82.27,
        "Latitude": 455500000,
        "Longitude": -821600000,
        "Elevation (m)": 179.8,
        "First Year": 1951,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1961,
        "MLY First Year": 1951,
        "MLY Last Year": 1961
      },
      {
        "Name": "LITTLE CURRENT",
        "Province": "ONTARIO",
        "Climate ID": "6094449",
        "Station ID": 4212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -81.92,
        "Latitude": 455800000,
        "Longitude": -815500000,
        "Elevation (m)": 190.5,
        "First Year": 1986,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1989,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "LITTLE CURRENT",
        "Province": "ONTARIO",
        "Climate ID": "6094450",
        "Station ID": 4213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -81.9,
        "Latitude": 455600000,
        "Longitude": -815400000,
        "Elevation (m)": 187.5,
        "First Year": 1871,
        "Last Year": 1895,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1895,
        "MLY First Year": 1871,
        "MLY Last Year": 1893
      },
      {
        "Name": "PROVIDENCE BAY",
        "Province": "ONTARIO",
        "Climate ID": "6096755",
        "Station ID": 4214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -82.23,
        "Latitude": 454000000,
        "Longitude": -821400000,
        "Elevation (m)": 192,
        "First Year": 1897,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1940,
        "MLY First Year": 1897,
        "MLY Last Year": 1940
      },
      {
        "Name": "SANDFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6097426",
        "Station ID": 27603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.69,
        "Longitude (Decimal Degrees)": -81.98,
        "Latitude": 454124060,
        "Longitude": -815855092,
        "Elevation (m)": 230.8,
        "First Year": 1998,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2018,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOUTH BAYMOUTH",
        "Province": "ONTARIO",
        "Climate ID": "6097915",
        "Station ID": 4215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -82.02,
        "Latitude": 453500000,
        "Longitude": -820100000,
        "Elevation (m)": 181.7,
        "First Year": 1954,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1993,
        "MLY First Year": 1954,
        "MLY Last Year": 1993
      },
      {
        "Name": "ALEXANDRIA",
        "Province": "ONTARIO",
        "Climate ID": "6100166",
        "Station ID": 42003,
        "WMO ID": null,
        "TC ID": "TAL",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -74.61,
        "Latitude": 451925000,
        "Longitude": -743651000,
        "Elevation (m)": 77,
        "First Year": 2003,
        "Last Year": 2006,
        "HLY First Year": 2003,
        "HLY Last Year": 2006,
        "DLY First Year": 2003,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALFRED",
        "Province": "ONTARIO",
        "Climate ID": "6100172",
        "Station ID": 4219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -74.88,
        "Latitude": 453300000,
        "Longitude": -745300000,
        "Elevation (m)": 54,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "ALFRED AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6100174",
        "Station ID": 4220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -74.88,
        "Latitude": 453300000,
        "Longitude": -745300000,
        "Elevation (m)": 54,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "ALFRED",
        "Province": "ONTARIO",
        "Climate ID": "6100176",
        "Station ID": 42006,
        "WMO ID": null,
        "TC ID": "TAE",
        "Latitude (Decimal Degrees)": 45.56,
        "Longitude (Decimal Degrees)": -74.88,
        "Latitude": 453318000,
        "Longitude": -745232000,
        "Elevation (m)": 57,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALMONTE",
        "Province": "ONTARIO",
        "Climate ID": "6100226",
        "Station ID": 4221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.18,
        "Longitude (Decimal Degrees)": -76.23,
        "Latitude": 451100000,
        "Longitude": -761400000,
        "Elevation (m)": 125,
        "First Year": 1912,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1980,
        "MLY First Year": 1912,
        "MLY Last Year": 1980
      },
      {
        "Name": "APPLE HILL",
        "Province": "ONTARIO",
        "Climate ID": "6100284",
        "Station ID": 4222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -74.75,
        "Latitude": 451300000,
        "Longitude": -744500000,
        "Elevation (m)": 91.4,
        "First Year": 1950,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1961,
        "MLY First Year": 1950,
        "MLY Last Year": 1961
      },
      {
        "Name": "APPLETON",
        "Province": "ONTARIO",
        "Climate ID": "6100285",
        "Station ID": 6901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.19,
        "Longitude (Decimal Degrees)": -76.11,
        "Latitude": 451109024,
        "Longitude": -760646098,
        "Elevation (m)": 133,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2006
      },
      {
        "Name": "ARDEN",
        "Province": "ONTARIO",
        "Climate ID": "6100310",
        "Station ID": 4223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -76.95,
        "Latitude": 444200000,
        "Longitude": -765700000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1911,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1911,
        "MLY First Year": 1895,
        "MLY Last Year": 1911
      },
      {
        "Name": "ARNPRIOR",
        "Province": "ONTARIO",
        "Climate ID": "6100340",
        "Station ID": 4224,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -76.38,
        "Latitude": 452600000,
        "Longitude": -762300000,
        "Elevation (m)": 99.1,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "ARNPRIOR GRANDON",
        "Province": "ONTARIO",
        "Climate ID": "6100345",
        "Station ID": 4225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -76.37,
        "Latitude": 452500000,
        "Longitude": -762200000,
        "Elevation (m)": 106.7,
        "First Year": 1959,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1999,
        "MLY First Year": 1959,
        "MLY Last Year": 1999
      },
      {
        "Name": "ASHTON",
        "Province": "ONTARIO",
        "Climate ID": "6100353",
        "Station ID": 4226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -75.97,
        "Latitude": 451200000,
        "Longitude": -755800000,
        "Elevation (m)": 136.9,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "ASHTON STN SESIA FARM",
        "Province": "ONTARIO",
        "Climate ID": "6100363",
        "Station ID": 4227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -76.07,
        "Latitude": 451000000,
        "Longitude": -760400000,
        "Elevation (m)": 137.2,
        "First Year": 1959,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1973,
        "MLY First Year": 1959,
        "MLY Last Year": 1973
      },
      {
        "Name": "ATHENS",
        "Province": "ONTARIO",
        "Climate ID": "6100375",
        "Station ID": 4228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -75.92,
        "Latitude": 443900000,
        "Longitude": -755500000,
        "Elevation (m)": 121.9,
        "First Year": 1969,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1978,
        "MLY First Year": 1969,
        "MLY Last Year": 1978
      },
      {
        "Name": "AVONMORE",
        "Province": "ONTARIO",
        "Climate ID": "6100398",
        "Station ID": 4229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -74.97,
        "Latitude": 451012042,
        "Longitude": -745815024,
        "Elevation (m)": 91.4,
        "First Year": 1976,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2006,
        "MLY First Year": 1976,
        "MLY Last Year": 2006
      },
      {
        "Name": "BARK LAKE DAM",
        "Province": "ONTARIO",
        "Climate ID": "6100493",
        "Station ID": 4230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -77.8,
        "Latitude": 452500000,
        "Longitude": -774800000,
        "Elevation (m)": 335.3,
        "First Year": 1950,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1971,
        "MLY First Year": 1950,
        "MLY Last Year": 1971
      },
      {
        "Name": "BARRETT CHUTE",
        "Province": "ONTARIO",
        "Climate ID": "6100521",
        "Station ID": 4231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -76.77,
        "Latitude": 451500000,
        "Longitude": -764600000,
        "Elevation (m)": 160,
        "First Year": 1950,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1968,
        "MLY First Year": 1950,
        "MLY Last Year": 1968
      },
      {
        "Name": "BARRY'S BAY",
        "Province": "ONTARIO",
        "Climate ID": "6100558",
        "Station ID": 4232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -77.67,
        "Latitude": 452600000,
        "Longitude": -774000000,
        "Elevation (m)": 289.6,
        "First Year": 1976,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1982,
        "MLY First Year": 1976,
        "MLY Last Year": 1982
      },
      {
        "Name": "BELLROCK",
        "Province": "ONTARIO",
        "Climate ID": "6100720",
        "Station ID": 4233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -76.77,
        "Latitude": 442900000,
        "Longitude": -764600000,
        "Elevation (m)": 146.3,
        "First Year": 1957,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1978,
        "MLY First Year": 1957,
        "MLY Last Year": 1978
      },
      {
        "Name": "BELLS CORNERS",
        "Province": "ONTARIO",
        "Climate ID": "6100722",
        "Station ID": 6952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -75.82,
        "Latitude": 452000000,
        "Longitude": -754900000,
        "Elevation (m)": 75,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "BOURGET",
        "Province": "ONTARIO",
        "Climate ID": "6100828",
        "Station ID": 4234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -75.17,
        "Latitude": 452800000,
        "Longitude": -751000000,
        "Elevation (m)": 65.5,
        "First Year": 1950,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1951,
        "MLY First Year": 1950,
        "MLY Last Year": 1951
      },
      {
        "Name": "BROCKVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6100969",
        "Station ID": 4235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -75.7,
        "Latitude": 443600000,
        "Longitude": -754200000,
        "Elevation (m)": 91.4,
        "First Year": 1871,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1980,
        "MLY First Year": 1871,
        "MLY Last Year": 1980
      },
      {
        "Name": "BROCKVILLE CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6100970",
        "Station ID": 47567,
        "WMO ID": 71161,
        "TC ID": "TBO",
        "Latitude (Decimal Degrees)": 44.64,
        "Longitude (Decimal Degrees)": -75.75,
        "Latitude": 443807000,
        "Longitude": -754454000,
        "Elevation (m)": 120.4,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BROCKVILLE PCC",
        "Province": "ONTARIO",
        "Climate ID": "6100971",
        "Station ID": 4236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 443600000,
        "Longitude": -754000000,
        "Elevation (m)": 96,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2006
      },
      {
        "Name": "CALABOGIE",
        "Province": "ONTARIO",
        "Climate ID": "6101077",
        "Station ID": 4237,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -76.73,
        "Latitude": 451500000,
        "Longitude": -764400000,
        "Elevation (m)": 155.4,
        "First Year": 1950,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1956,
        "MLY First Year": 1950,
        "MLY Last Year": 1956
      },
      {
        "Name": "CARDINAL",
        "Province": "ONTARIO",
        "Climate ID": "6101200",
        "Station ID": 4238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.8,
        "Longitude (Decimal Degrees)": -75.37,
        "Latitude": 444800000,
        "Longitude": -752200000,
        "Elevation (m)": 52,
        "First Year": 1970,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2000,
        "MLY First Year": 1970,
        "MLY Last Year": 2000
      },
      {
        "Name": "CARLETON PLACE",
        "Province": "ONTARIO",
        "Climate ID": "6101249",
        "Station ID": 4239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -76.17,
        "Latitude": 450900000,
        "Longitude": -761000000,
        "Elevation (m)": 136.9,
        "First Year": 1948,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1976,
        "MLY First Year": 1948,
        "MLY Last Year": 1976
      },
      {
        "Name": "CARLETON PLACE",
        "Province": "ONTARIO",
        "Climate ID": "6101250",
        "Station ID": 4240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -76.2,
        "Latitude": 450900000,
        "Longitude": -761200000,
        "Elevation (m)": 145,
        "First Year": 1984,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1999,
        "MLY First Year": 1984,
        "MLY Last Year": 1999
      },
      {
        "Name": "CARP",
        "Province": "ONTARIO",
        "Climate ID": "6101260",
        "Station ID": 4241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -75.98,
        "Latitude": 451800000,
        "Longitude": -755900000,
        "Elevation (m)": 114.3,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "CATARAQUI TS",
        "Province": "ONTARIO",
        "Climate ID": "6101265",
        "Station ID": 4242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.37,
        "Longitude (Decimal Degrees)": -76.62,
        "Latitude": 442200000,
        "Longitude": -763700000,
        "Elevation (m)": 144.8,
        "First Year": 1960,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1995,
        "MLY First Year": 1960,
        "MLY Last Year": 1995
      },
      {
        "Name": "CHALK RIVER AECL",
        "Province": "ONTARIO",
        "Climate ID": "6101335",
        "Station ID": 4243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -77.37,
        "Latitude": 460300000,
        "Longitude": -772200000,
        "Elevation (m)": 121.9,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2006
      },
      {
        "Name": "CHATS FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6101440",
        "Station ID": 4244,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -76.23,
        "Latitude": 452800000,
        "Longitude": -761400000,
        "Elevation (m)": 93.9,
        "First Year": 1950,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1992,
        "MLY First Year": 1950,
        "MLY Last Year": 1992
      },
      {
        "Name": "CHENAUX",
        "Province": "ONTARIO",
        "Climate ID": "6101494",
        "Station ID": 4245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -76.68,
        "Latitude": 453500000,
        "Longitude": -764100000,
        "Elevation (m)": 84.1,
        "First Year": 1950,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1990,
        "MLY First Year": 1950,
        "MLY Last Year": 1990
      },
      {
        "Name": "CHESTERVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6101500",
        "Station ID": 4246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -75.23,
        "Latitude": 450600000,
        "Longitude": -751400000,
        "Elevation (m)": 70.1,
        "First Year": 1965,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1983,
        "MLY First Year": 1965,
        "MLY Last Year": 1983
      },
      {
        "Name": "CHESTERVILLE 2",
        "Province": "ONTARIO",
        "Climate ID": "6101502",
        "Station ID": 4247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -75.2,
        "Latitude": 450100000,
        "Longitude": -751200000,
        "Elevation (m)": 85,
        "First Year": 1983,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1997,
        "MLY First Year": 1983,
        "MLY Last Year": 1997
      },
      {
        "Name": "CITY VIEW",
        "Province": "ONTARIO",
        "Climate ID": "6101521",
        "Station ID": 4248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -75.73,
        "Latitude": 452100000,
        "Longitude": -754400000,
        "Elevation (m)": 88.4,
        "First Year": 1953,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1960,
        "MLY First Year": 1953,
        "MLY Last Year": 1960
      },
      {
        "Name": "CLAYBANK",
        "Province": "ONTARIO",
        "Climate ID": "6101555",
        "Station ID": 4249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -76.4,
        "Latitude": 452500000,
        "Longitude": -762400000,
        "Elevation (m)": 106.7,
        "First Year": 1961,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1994,
        "MLY First Year": 1961,
        "MLY Last Year": 1994
      },
      {
        "Name": "CLONTARF",
        "Province": "ONTARIO",
        "Climate ID": "6101658",
        "Station ID": 4250,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -77.15,
        "Latitude": 452300000,
        "Longitude": -770900000,
        "Elevation (m)": 244.8,
        "First Year": 1882,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1957,
        "MLY First Year": 1882,
        "MLY Last Year": 1957
      },
      {
        "Name": "COBDEN ONTARIO HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6101675",
        "Station ID": 4251,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -76.87,
        "Latitude": 453800000,
        "Longitude": -765200000,
        "Elevation (m)": 140.2,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "COMBERMERE",
        "Province": "ONTARIO",
        "Climate ID": "6101820",
        "Station ID": 4252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -77.62,
        "Latitude": 452200000,
        "Longitude": -773700000,
        "Elevation (m)": 286.5,
        "First Year": 1956,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2009,
        "MLY First Year": 1956,
        "MLY Last Year": 2006
      },
      {
        "Name": "CORNWALL",
        "Province": "ONTARIO",
        "Climate ID": "6101872",
        "Station ID": 4253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -74.73,
        "Latitude": 450100000,
        "Longitude": -744400000,
        "Elevation (m)": 53.3,
        "First Year": 1867,
        "Last Year": 1887,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1867,
        "DLY Last Year": 1887,
        "MLY First Year": 1867,
        "MLY Last Year": 1887
      },
      {
        "Name": "CORNWALL",
        "Province": "ONTARIO",
        "Climate ID": "6101873",
        "Station ID": 4254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -74.7,
        "Latitude": 450200000,
        "Longitude": -744200000,
        "Elevation (m)": 58.5,
        "First Year": 1948,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1950,
        "MLY First Year": 1948,
        "MLY Last Year": 1950
      },
      {
        "Name": "CORNWALL",
        "Province": "ONTARIO",
        "Climate ID": "6101874",
        "Station ID": 4255,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -74.75,
        "Latitude": 450056082,
        "Longitude": -744456040,
        "Elevation (m)": 64,
        "First Year": 1950,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2022,
        "MLY First Year": 1951,
        "MLY Last Year": 2006
      },
      {
        "Name": "CORNWALL",
        "Province": "ONTARIO",
        "Climate ID": "6101875",
        "Station ID": 42004,
        "WMO ID": null,
        "TC ID": "TCW",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -74.68,
        "Latitude": 450153000,
        "Longitude": -744053000,
        "Elevation (m)": 58,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CORNWALL COLLEGE",
        "Province": "ONTARIO",
        "Climate ID": "6101880",
        "Station ID": 4256,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -74.72,
        "Latitude": 450200000,
        "Longitude": -744300000,
        "Elevation (m)": 54.9,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "CORNWALL CUMBERLAND ST",
        "Province": "ONTARIO",
        "Climate ID": "6101885",
        "Station ID": 4257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -74.75,
        "Latitude": 450300000,
        "Longitude": -744500000,
        "Elevation (m)": 59.4,
        "First Year": 1960,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "CORNWALL ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6101901",
        "Station ID": 4258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -74.8,
        "Latitude": 450200000,
        "Longitude": -744800000,
        "Elevation (m)": 76.2,
        "First Year": 1954,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1995,
        "MLY First Year": 1955,
        "MLY Last Year": 1995
      },
      {
        "Name": "CORNWALL ST LHS",
        "Province": "ONTARIO",
        "Climate ID": "6101909",
        "Station ID": 4259,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -74.72,
        "Latitude": 450200000,
        "Longitude": -744300000,
        "Elevation (m)": 53.3,
        "First Year": 1958,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1963,
        "MLY First Year": 1958,
        "MLY Last Year": 1963
      },
      {
        "Name": "CROW LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6101920",
        "Station ID": 4260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 444400000,
        "Longitude": -763600000,
        "Elevation (m)": 173.7,
        "First Year": 1972,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1991,
        "MLY First Year": 1972,
        "MLY Last Year": 1991
      },
      {
        "Name": "CUMBERLAND",
        "Province": "ONTARIO",
        "Climate ID": "6101935",
        "Station ID": 4261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -75.45,
        "Latitude": 453000000,
        "Longitude": -752700000,
        "Elevation (m)": 91.4,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "DACRE",
        "Province": "ONTARIO",
        "Climate ID": "6101943",
        "Station ID": 4262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -76.92,
        "Latitude": 452300000,
        "Longitude": -765500000,
        "Elevation (m)": 221,
        "First Year": 1926,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1936,
        "MLY First Year": 1926,
        "MLY Last Year": 1936
      },
      {
        "Name": "DALHOUSIE L HIGH FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6101955",
        "Station ID": 4263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -76.62,
        "Latitude": 445800000,
        "Longitude": -763700000,
        "Elevation (m)": 160,
        "First Year": 1923,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1983,
        "MLY First Year": 1923,
        "MLY Last Year": 1983
      },
      {
        "Name": "DALHOUSIE MILLS",
        "Province": "ONTARIO",
        "Climate ID": "6101958",
        "Station ID": 4264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -74.47,
        "Latitude": 451900000,
        "Longitude": -742800000,
        "Elevation (m)": 68.6,
        "First Year": 1968,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2004,
        "MLY First Year": 1968,
        "MLY Last Year": 2004
      },
      {
        "Name": "DALKEITH",
        "Province": "ONTARIO",
        "Climate ID": "6101960",
        "Station ID": 4265,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -74.62,
        "Latitude": 452600000,
        "Longitude": -743700000,
        "Elevation (m)": 67.1,
        "First Year": 1961,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1978,
        "MLY First Year": 1961,
        "MLY Last Year": 1978
      },
      {
        "Name": "DALKEITH PYM",
        "Province": "ONTARIO",
        "Climate ID": "6101962",
        "Station ID": 4266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -74.58,
        "Latitude": 452600000,
        "Longitude": -743500000,
        "Elevation (m)": 76.2,
        "First Year": 1978,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1987,
        "MLY First Year": 1978,
        "MLY Last Year": 1987
      },
      {
        "Name": "DELTA",
        "Province": "ONTARIO",
        "Climate ID": "6101986",
        "Station ID": 4267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -76.13,
        "Latitude": 443700000,
        "Longitude": -760800000,
        "Elevation (m)": 97.5,
        "First Year": 1969,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1994,
        "MLY First Year": 1969,
        "MLY Last Year": 1994
      },
      {
        "Name": "DES JOACHIMS",
        "Province": "ONTARIO",
        "Climate ID": "6102009",
        "Station ID": 4269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -77.7,
        "Latitude": 461100000,
        "Longitude": -774200000,
        "Elevation (m)": 129.5,
        "First Year": 1950,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1977,
        "MLY First Year": 1950,
        "MLY Last Year": 1977
      },
      {
        "Name": "DOMVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6102054",
        "Station ID": 4270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.78,
        "Longitude (Decimal Degrees)": -75.53,
        "Latitude": 444700000,
        "Longitude": -753200000,
        "Elevation (m)": 115.2,
        "First Year": 1948,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1954,
        "MLY First Year": 1948,
        "MLY Last Year": 1954
      },
      {
        "Name": "DUNROBIN",
        "Province": "ONTARIO",
        "Climate ID": "6102150",
        "Station ID": 4271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -76.03,
        "Latitude": 452600000,
        "Longitude": -760200000,
        "Elevation (m)": 65.5,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "DUNVEGAN",
        "Province": "ONTARIO",
        "Climate ID": "6102153",
        "Station ID": 4272,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -74.78,
        "Latitude": 452000000,
        "Longitude": -744700000,
        "Elevation (m)": 68.6,
        "First Year": 1947,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1949,
        "MLY First Year": 1947,
        "MLY Last Year": 1949
      },
      {
        "Name": "EGANVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6102250",
        "Station ID": 4273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -77.1,
        "Latitude": 453300000,
        "Longitude": -770600000,
        "Elevation (m)": 164.6,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "EGANVILLE 2",
        "Province": "ONTARIO",
        "Climate ID": "6102252",
        "Station ID": 43600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -77.1,
        "Latitude": 453253030,
        "Longitude": -770610090,
        "Elevation (m)": 184.8,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2006,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "FITZROY HARBOUR",
        "Province": "ONTARIO",
        "Climate ID": "6102417",
        "Station ID": 4274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -76.22,
        "Latitude": 452800000,
        "Longitude": -761300000,
        "Elevation (m)": 74.1,
        "First Year": 1870,
        "Last Year": 1906,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1906,
        "MLY First Year": 1870,
        "MLY Last Year": 1906
      },
      {
        "Name": "FOURNIER",
        "Province": "ONTARIO",
        "Climate ID": "6102516",
        "Station ID": 4275,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -74.9,
        "Latitude": 452600000,
        "Longitude": -745400000,
        "Elevation (m)": 64.9,
        "First Year": 1957,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1959,
        "MLY First Year": 1957,
        "MLY Last Year": 1959
      },
      {
        "Name": "FOYMOUNT",
        "Province": "ONTARIO",
        "Climate ID": "6102531",
        "Station ID": 4276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -77.3,
        "Latitude": 452000000,
        "Longitude": -771800000,
        "Elevation (m)": 426.7,
        "First Year": 1956,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1974,
        "MLY First Year": 1956,
        "MLY Last Year": 1974
      },
      {
        "Name": "GANANOQUE",
        "Province": "ONTARIO",
        "Climate ID": "6102662",
        "Station ID": 4277,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -76.2,
        "Latitude": 441900000,
        "Longitude": -761200000,
        "Elevation (m)": 79.2,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "GLENBURNIE",
        "Province": "ONTARIO",
        "Climate ID": "6102808",
        "Station ID": 4278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 442000000,
        "Longitude": -763000000,
        "Elevation (m)": 114.3,
        "First Year": 1972,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1999,
        "MLY First Year": 1972,
        "MLY Last Year": 1999
      },
      {
        "Name": "GLEN GORDON",
        "Province": "ONTARIO",
        "Climate ID": "6102832",
        "Station ID": 4279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -74.53,
        "Latitude": 451000000,
        "Longitude": -743200000,
        "Elevation (m)": 53.3,
        "First Year": 1967,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1999,
        "MLY First Year": 1967,
        "MLY Last Year": 1999
      },
      {
        "Name": "GLOUCESTER DESJARDINS",
        "Province": "ONTARIO",
        "Climate ID": "6102839",
        "Station ID": 4280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -75.5,
        "Latitude": 452000000,
        "Longitude": -753000000,
        "Elevation (m)": 76.2,
        "First Year": 1975,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1977,
        "MLY First Year": 1975,
        "MLY Last Year": 1977
      },
      {
        "Name": "GLOUCESTER KETTLES",
        "Province": "ONTARIO",
        "Climate ID": "6102840",
        "Station ID": 4281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -75.55,
        "Latitude": 452100000,
        "Longitude": -753300000,
        "Elevation (m)": 76.2,
        "First Year": 1975,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1982,
        "MLY First Year": 1975,
        "MLY Last Year": 1982
      },
      {
        "Name": "GLOUCESTER RCN",
        "Province": "ONTARIO",
        "Climate ID": "6102841",
        "Station ID": 4282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -75.52,
        "Latitude": 451800000,
        "Longitude": -753100000,
        "Elevation (m)": 80.8,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "GLOUCESTER TINKER",
        "Province": "ONTARIO",
        "Climate ID": "6102842",
        "Station ID": 4283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -75.47,
        "Latitude": 452100000,
        "Longitude": -752800000,
        "Elevation (m)": 76.2,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "GODFREY",
        "Province": "ONTARIO",
        "Climate ID": "6102857",
        "Station ID": 4284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -76.63,
        "Latitude": 443400000,
        "Longitude": -763800000,
        "Elevation (m)": 160,
        "First Year": 1981,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2003,
        "MLY First Year": 1981,
        "MLY Last Year": 2003
      },
      {
        "Name": "DRUMMOND CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6102J13",
        "Station ID": 4268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -76.25,
        "Latitude": 450156082,
        "Longitude": -761510098,
        "Elevation (m)": 145,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2006
      },
      {
        "Name": "GREENFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6103006",
        "Station ID": 4285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -74.67,
        "Latitude": 452100000,
        "Longitude": -744000000,
        "Elevation (m)": 83.8,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "GRENADIER ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6103024",
        "Station ID": 10903,
        "WMO ID": 71281,
        "TC ID": "WGH",
        "Latitude (Decimal Degrees)": 44.42,
        "Longitude (Decimal Degrees)": -75.85,
        "Latitude": 442500000,
        "Longitude": -755100000,
        "Elevation (m)": 82,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "HARROWSMITH BRACKEN",
        "Province": "ONTARIO",
        "Climate ID": "6103366",
        "Station ID": 4286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -76.7,
        "Latitude": 442300000,
        "Longitude": -764200000,
        "Elevation (m)": 145.1,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "HARTINGTON IHD",
        "Province": "ONTARIO",
        "Climate ID": "6103367",
        "Station ID": 4287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.43,
        "Longitude (Decimal Degrees)": -76.69,
        "Latitude": 442541028,
        "Longitude": -764125086,
        "Elevation (m)": 160,
        "First Year": 1967,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2022,
        "MLY First Year": 1968,
        "MLY Last Year": 2006
      },
      {
        "Name": "HAWKESBURY",
        "Province": "ONTARIO",
        "Climate ID": "6103390",
        "Station ID": 4288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -74.63,
        "Latitude": 453700000,
        "Longitude": -743800000,
        "Elevation (m)": 45.7,
        "First Year": 1950,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1963,
        "MLY First Year": 1950,
        "MLY Last Year": 1963
      },
      {
        "Name": "HINCHINBROOKE",
        "Province": "ONTARIO",
        "Climate ID": "6103470",
        "Station ID": 4289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -76.68,
        "Latitude": 443500000,
        "Longitude": -764100000,
        "Elevation (m)": 180.4,
        "First Year": 1961,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1973,
        "MLY First Year": 1961,
        "MLY Last Year": 1973
      },
      {
        "Name": "IROQUOIS",
        "Province": "ONTARIO",
        "Climate ID": "6103808",
        "Station ID": 10950,
        "WMO ID": null,
        "TC ID": "WIH",
        "Latitude (Decimal Degrees)": 44.83,
        "Longitude (Decimal Degrees)": -75.33,
        "Latitude": 445000000,
        "Longitude": -752000000,
        "Elevation (m)": 74.1,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KEMPTVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6104025",
        "Station ID": 4291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 450000000,
        "Longitude": -753800000,
        "Elevation (m)": 99.4,
        "First Year": 1928,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1997,
        "MLY First Year": 1928,
        "MLY Last Year": 1997
      },
      {
        "Name": "KEMPTVILLE CS",
        "Province": "ONTARIO",
        "Climate ID": "6104027",
        "Station ID": 27534,
        "WMO ID": 71300,
        "TC ID": "XKE",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 450000000,
        "Longitude": -753800000,
        "Elevation (m)": 99.4,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "KILLALOE",
        "Province": "ONTARIO",
        "Climate ID": "6104125",
        "Station ID": 4293,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -77.42,
        "Latitude": 453400000,
        "Longitude": -772500000,
        "Elevation (m)": 174,
        "First Year": 1938,
        "Last Year": 1972,
        "HLY First Year": 1953,
        "HLY Last Year": 1972,
        "DLY First Year": 1938,
        "DLY Last Year": 1972,
        "MLY First Year": 1938,
        "MLY Last Year": 1972
      },
      {
        "Name": "KINGSTON CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6104142",
        "Station ID": 47267,
        "WMO ID": 71820,
        "TC ID": "TKG",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 441324000,
        "Longitude": -763558000,
        "Elevation (m)": 93,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINGSTON",
        "Province": "ONTARIO",
        "Climate ID": "6104145",
        "Station ID": 4294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 441500000,
        "Longitude": -763000000,
        "Elevation (m)": 103.6,
        "First Year": 1947,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1949,
        "MLY First Year": 1947,
        "MLY Last Year": 1949
      },
      {
        "Name": "KINGSTON A",
        "Province": "ONTARIO",
        "Climate ID": "6104146",
        "Station ID": 4295,
        "WMO ID": null,
        "TC ID": "YGK",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 441307092,
        "Longitude": -763548008,
        "Elevation (m)": 92.4,
        "First Year": 1930,
        "Last Year": 2012,
        "HLY First Year": 1967,
        "HLY Last Year": 2012,
        "DLY First Year": 1930,
        "DLY Last Year": 1996,
        "MLY First Year": 1930,
        "MLY Last Year": 1996
      },
      {
        "Name": "KINGSTON BEVERLEY ST",
        "Province": "ONTARIO",
        "Climate ID": "6104147",
        "Station ID": 4296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 441300000,
        "Longitude": -763000000,
        "Elevation (m)": 83.8,
        "First Year": 1960,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "KINGSTON A",
        "Province": "ONTARIO",
        "Climate ID": "6104149",
        "Station ID": 50428,
        "WMO ID": null,
        "TC ID": "YGK",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 441333000,
        "Longitude": -763548000,
        "Elevation (m)": 92.4,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINGSTON A",
        "Province": "ONTARIO",
        "Climate ID": "6104152",
        "Station ID": 52985,
        "WMO ID": null,
        "TC ID": "YGK",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 441333000,
        "Longitude": -763548000,
        "Elevation (m)": 92.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KINGSTON MARINE",
        "Province": "ONTARIO",
        "Climate ID": "6104153",
        "Station ID": 4297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -76.45,
        "Latitude": 441400000,
        "Longitude": -762700000,
        "Elevation (m)": 106.7,
        "First Year": 1939,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1967,
        "MLY First Year": 1939,
        "MLY Last Year": 1967
      },
      {
        "Name": "KINGSTON N & C GAS",
        "Province": "ONTARIO",
        "Climate ID": "6104160",
        "Station ID": 4298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -76.57,
        "Latitude": 441400000,
        "Longitude": -763400000,
        "Elevation (m)": 91.1,
        "First Year": 1965,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1977,
        "MLY First Year": 1965,
        "MLY Last Year": 1977
      },
      {
        "Name": "KINGSTON ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6104165",
        "Station ID": 4299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 441600000,
        "Longitude": -763000000,
        "Elevation (m)": 91.4,
        "First Year": 1945,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1971,
        "MLY First Year": 1945,
        "MLY Last Year": 1971
      },
      {
        "Name": "KINGSTON PUMPING STATION",
        "Province": "ONTARIO",
        "Climate ID": "6104175",
        "Station ID": 4300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.24,
        "Longitude (Decimal Degrees)": -76.48,
        "Latitude": 441438052,
        "Longitude": -762850040,
        "Elevation (m)": 76.5,
        "First Year": 1960,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2007,
        "MLY First Year": 1960,
        "MLY Last Year": 2006
      },
      {
        "Name": "KINGSTON QUEENS U",
        "Province": "ONTARIO",
        "Climate ID": "6104185",
        "Station ID": 4301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 441500000,
        "Longitude": -763000000,
        "Elevation (m)": 103.6,
        "First Year": 1872,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1957,
        "MLY First Year": 1872,
        "MLY Last Year": 1957
      },
      {
        "Name": "KILLALOE O'GRADY",
        "Province": "ONTARIO",
        "Climate ID": "61041BE",
        "Station ID": 4292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -77.48,
        "Latitude": 453000000,
        "Longitude": -772900000,
        "Elevation (m)": 395.9,
        "First Year": 1977,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1979,
        "MLY First Year": 1977,
        "MLY Last Year": 1979
      },
      {
        "Name": "LAGGAN",
        "Province": "ONTARIO",
        "Climate ID": "6104293",
        "Station ID": 4302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -74.72,
        "Latitude": 452300000,
        "Longitude": -744300000,
        "Elevation (m)": 91.4,
        "First Year": 1961,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1961,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LANCASTER",
        "Province": "ONTARIO",
        "Climate ID": "6104340",
        "Station ID": 4303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -74.47,
        "Latitude": 450900000,
        "Longitude": -742800000,
        "Elevation (m)": 50.3,
        "First Year": 1961,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1973,
        "MLY First Year": 1961,
        "MLY Last Year": 1973
      },
      {
        "Name": "LANSDOWNE",
        "Province": "ONTARIO",
        "Climate ID": "6104347",
        "Station ID": 4304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -76.03,
        "Latitude": 443000000,
        "Longitude": -760200000,
        "Elevation (m)": 103.6,
        "First Year": 1895,
        "Last Year": 1909,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1909,
        "MLY First Year": 1895,
        "MLY Last Year": 1909
      },
      {
        "Name": "LANSDOWNE",
        "Province": "ONTARIO",
        "Climate ID": "6104348",
        "Station ID": 4305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -76,
        "Latitude": 442400000,
        "Longitude": -760000000,
        "Elevation (m)": 102.1,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "LEONARD",
        "Province": "ONTARIO",
        "Climate ID": "6104400",
        "Station ID": 4306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -75.32,
        "Latitude": 452300000,
        "Longitude": -751900000,
        "Elevation (m)": 76.2,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "LYN",
        "Province": "ONTARIO",
        "Climate ID": "6104723",
        "Station ID": 4307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -75.78,
        "Latitude": 443500000,
        "Longitude": -754700000,
        "Elevation (m)": 114.3,
        "First Year": 1960,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1969,
        "MLY First Year": 1960,
        "MLY Last Year": 1969
      },
      {
        "Name": "LYNDHURST SHAWMERE",
        "Province": "ONTARIO",
        "Climate ID": "6104725",
        "Station ID": 4308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -76.08,
        "Latitude": 443100000,
        "Longitude": -760500000,
        "Elevation (m)": 86.9,
        "First Year": 1976,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2021,
        "MLY First Year": 1976,
        "MLY Last Year": 2006
      },
      {
        "Name": "MACCUE",
        "Province": "ONTARIO",
        "Climate ID": "6104733",
        "Station ID": 4309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -76.17,
        "Latitude": 445300000,
        "Longitude": -761000000,
        "Elevation (m)": null,
        "First Year": 1883,
        "Last Year": 1918,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1918,
        "MLY First Year": 1883,
        "MLY Last Year": 1918
      },
      {
        "Name": "MAITLAND",
        "Province": "ONTARIO",
        "Climate ID": "6104840",
        "Station ID": 4310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 443800000,
        "Longitude": -753800000,
        "Elevation (m)": 100.6,
        "First Year": 1953,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1954,
        "MLY First Year": 1953,
        "MLY Last Year": 1954
      },
      {
        "Name": "MALLORYTOWN GRAHAM LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6104880",
        "Station ID": 4311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -75.88,
        "Latitude": 443400000,
        "Longitude": -755300000,
        "Elevation (m)": 121.9,
        "First Year": 1961,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1989,
        "MLY First Year": 1961,
        "MLY Last Year": 1989
      },
      {
        "Name": "MALLORYTOWN LANDING",
        "Province": "ONTARIO",
        "Climate ID": "6104882",
        "Station ID": 4312,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.45,
        "Longitude (Decimal Degrees)": -75.87,
        "Latitude": 442700000,
        "Longitude": -755200000,
        "Elevation (m)": 83.8,
        "First Year": 1977,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1991,
        "MLY First Year": 1978,
        "MLY Last Year": 1991
      },
      {
        "Name": "MANOTICK",
        "Province": "ONTARIO",
        "Climate ID": "6104931",
        "Station ID": 4313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -75.68,
        "Latitude": 451400000,
        "Longitude": -754100000,
        "Elevation (m)": 86.9,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1956
      },
      {
        "Name": "MANOTICK",
        "Province": "ONTARIO",
        "Climate ID": "6104932",
        "Station ID": 4314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -75.7,
        "Latitude": 451400000,
        "Longitude": -754200000,
        "Elevation (m)": 83.8,
        "First Year": 1975,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1986,
        "MLY First Year": 1975,
        "MLY Last Year": 1986
      },
      {
        "Name": "KINGSTON (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6104ADF",
        "Station ID": 7671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 441300000,
        "Longitude": -763600000,
        "Elevation (m)": 93,
        "First Year": 1994,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KILLALOE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6104JK5",
        "Station ID": 4290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -77.5,
        "Latitude": 452900000,
        "Longitude": -773000000,
        "Elevation (m)": 396.2,
        "First Year": 1982,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1984,
        "MLY First Year": 1982,
        "MLY Last Year": 1984
      },
      {
        "Name": "MATAWATCHAN",
        "Province": "ONTARIO",
        "Climate ID": "6105010",
        "Station ID": 4315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -77.12,
        "Latitude": 450800000,
        "Longitude": -770700000,
        "Elevation (m)": 299.9,
        "First Year": 1983,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1988,
        "MLY First Year": 1983,
        "MLY Last Year": 1988
      },
      {
        "Name": "MERIVALE CDA",
        "Province": "ONTARIO",
        "Climate ID": "6105061",
        "Station ID": 4316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -75.73,
        "Latitude": 451800000,
        "Longitude": -754400000,
        "Elevation (m)": 89.9,
        "First Year": 1972,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1977,
        "MLY First Year": 1972,
        "MLY Last Year": 1977
      },
      {
        "Name": "METCALFE OSGOODE",
        "Province": "ONTARIO",
        "Climate ID": "6105066",
        "Station ID": 4317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -75.47,
        "Latitude": 451400000,
        "Longitude": -752800000,
        "Elevation (m)": 83.8,
        "First Year": 1968,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1976,
        "MLY First Year": 1968,
        "MLY Last Year": 1976
      },
      {
        "Name": "MONTAGUE",
        "Province": "ONTARIO",
        "Climate ID": "6105262",
        "Station ID": 4318,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.93,
        "Longitude (Decimal Degrees)": -75.95,
        "Latitude": 445600000,
        "Longitude": -755700000,
        "Elevation (m)": 178.3,
        "First Year": 1895,
        "Last Year": 1915,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1915,
        "MLY First Year": 1895,
        "MLY Last Year": 1914
      },
      {
        "Name": "MOOSE CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6105395",
        "Station ID": 4319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -75.03,
        "Latitude": 451500000,
        "Longitude": -750200000,
        "Elevation (m)": 83.8,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "MOOSE CREEK WELLS",
        "Province": "ONTARIO",
        "Climate ID": "6105397",
        "Station ID": 41738,
        "WMO ID": 71635,
        "TC ID": "TCK",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -74.96,
        "Latitude": 451504000,
        "Longitude": -745746000,
        "Elevation (m)": 84.5,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MORRISBURG",
        "Province": "ONTARIO",
        "Climate ID": "6105460",
        "Station ID": 4320,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -75.19,
        "Latitude": 445525026,
        "Longitude": -751118036,
        "Elevation (m)": 81.7,
        "First Year": 1913,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2008,
        "MLY First Year": 1913,
        "MLY Last Year": 2006
      },
      {
        "Name": "MOUNT ST PATRICK",
        "Province": "ONTARIO",
        "Climate ID": "6105512",
        "Station ID": 4321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -76.88,
        "Latitude": 452000000,
        "Longitude": -765300000,
        "Elevation (m)": 213.4,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "NAVAN",
        "Province": "ONTARIO",
        "Climate ID": "6105576",
        "Station ID": 4322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -75.52,
        "Latitude": 452600000,
        "Longitude": -753100000,
        "Elevation (m)": 76.2,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "NORTH AUGUSTA",
        "Province": "ONTARIO",
        "Climate ID": "6105678",
        "Station ID": 4323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -75.78,
        "Latitude": 444500000,
        "Longitude": -754700000,
        "Elevation (m)": 117.3,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "NORTH AUGUSTA MAHONEY",
        "Province": "ONTARIO",
        "Climate ID": "6105679",
        "Station ID": 4324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -75.73,
        "Latitude": 444400000,
        "Longitude": -754400000,
        "Elevation (m)": 121.9,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1974,
        "MLY Last Year": 1980
      },
      {
        "Name": "NORTH GOWER",
        "Province": "ONTARIO",
        "Climate ID": "6105709",
        "Station ID": 4325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -75.72,
        "Latitude": 450800000,
        "Longitude": -754300000,
        "Elevation (m)": 93,
        "First Year": 1902,
        "Last Year": 1925,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1925,
        "MLY First Year": 1902,
        "MLY Last Year": 1925
      },
      {
        "Name": "NORTH GOWER",
        "Province": "ONTARIO",
        "Climate ID": "6105710",
        "Station ID": 31028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -75.74,
        "Latitude": 450600000,
        "Longitude": -754430000,
        "Elevation (m)": 103,
        "First Year": 2001,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2005,
        "MLY First Year": 2001,
        "MLY Last Year": 2005
      },
      {
        "Name": "OAK VALLEY",
        "Province": "ONTARIO",
        "Climate ID": "6105730",
        "Station ID": 27614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -75.37,
        "Latitude": 450003060,
        "Longitude": -752155056,
        "Elevation (m)": 80.5,
        "First Year": 1998,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2009,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "OMPAH",
        "Province": "ONTARIO",
        "Climate ID": "6105760",
        "Station ID": 26771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -76.86,
        "Latitude": 445827072,
        "Longitude": -765122062,
        "Elevation (m)": 251,
        "First Year": 1994,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2010,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "OMPAH-SEITZ",
        "Province": "ONTARIO",
        "Climate ID": "6105762",
        "Station ID": 26773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -76.78,
        "Latitude": 450313050,
        "Longitude": -764702088,
        "Elevation (m)": 276,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "ORLEANS VEH PRVG GND",
        "Province": "ONTARIO",
        "Climate ID": "6105832",
        "Station ID": 4326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -75.57,
        "Latitude": 452700000,
        "Longitude": -753400000,
        "Elevation (m)": 89,
        "First Year": 1953,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1958,
        "MLY First Year": 1953,
        "MLY Last Year": 1958
      },
      {
        "Name": "OTTAWA",
        "Province": "ONTARIO",
        "Climate ID": "6105887",
        "Station ID": 4327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -75.72,
        "Latitude": 452400000,
        "Longitude": -754300000,
        "Elevation (m)": 71.9,
        "First Year": 1872,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1935,
        "MLY First Year": 1872,
        "MLY Last Year": 1935
      },
      {
        "Name": "OTTAWA ALBION RD",
        "Province": "ONTARIO",
        "Climate ID": "6105910",
        "Station ID": 4328,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 452000000,
        "Longitude": -753800000,
        "Elevation (m)": 97.5,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "OTTAWA ALTA VISTA",
        "Province": "ONTARIO",
        "Climate ID": "6105913",
        "Station ID": 4329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -75.75,
        "Latitude": 452300000,
        "Longitude": -754500000,
        "Elevation (m)": 80.8,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1961,
        "MLY Last Year": 1963
      },
      {
        "Name": "OTTAWA BECKWITH RD",
        "Province": "ONTARIO",
        "Climate ID": "6105938",
        "Station ID": 4330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 452400000,
        "Longitude": -754000000,
        "Elevation (m)": 61,
        "First Year": 1955,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1961,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "OTTAWA BILLINGS BRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6105950",
        "Station ID": 4331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -75.65,
        "Latitude": 452100000,
        "Longitude": -753900000,
        "Elevation (m)": 92,
        "First Year": 1953,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1954,
        "MLY First Year": 1953,
        "MLY Last Year": 1954
      },
      {
        "Name": "OTTAWA BRITANNIA",
        "Province": "ONTARIO",
        "Climate ID": "6105960",
        "Station ID": 4332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -75.8,
        "Latitude": 452200000,
        "Longitude": -754800000,
        "Elevation (m)": 57.6,
        "First Year": 1972,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1984,
        "MLY First Year": 1972,
        "MLY Last Year": 1984
      },
      {
        "Name": "OTTAWA CDA",
        "Province": "ONTARIO",
        "Climate ID": "6105976",
        "Station ID": 4333,
        "WMO ID": null,
        "TC ID": "WCG",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -75.72,
        "Latitude": 452300000,
        "Longitude": -754300000,
        "Elevation (m)": 79.2,
        "First Year": 1889,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 2022,
        "MLY First Year": 1889,
        "MLY Last Year": 2006
      },
      {
        "Name": "OTTAWA CDA RCS",
        "Province": "ONTARIO",
        "Climate ID": "6105978",
        "Station ID": 30578,
        "WMO ID": 71063,
        "TC ID": "XOA",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -75.72,
        "Latitude": 452300000,
        "Longitude": -754300000,
        "Elevation (m)": 79.2,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "OTTAWA CITY HALL",
        "Province": "ONTARIO",
        "Climate ID": "6105980",
        "Station ID": 4334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -75.7,
        "Latitude": 452600000,
        "Longitude": -754200000,
        "Elevation (m)": 56.4,
        "First Year": 1966,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "OTTAWA HAZELDEAN",
        "Province": "ONTARIO",
        "Climate ID": "6105993",
        "Station ID": 4335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -75.9,
        "Latitude": 451900000,
        "Longitude": -755400000,
        "Elevation (m)": 106.7,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "OTTAWA HOGS BACK",
        "Province": "ONTARIO",
        "Climate ID": "6105995",
        "Station ID": 4336,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -75.68,
        "Latitude": 452200000,
        "Longitude": -754100000,
        "Elevation (m)": 79.2,
        "First Year": 1953,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1954,
        "MLY First Year": 1953,
        "MLY Last Year": 1954
      },
      {
        "Name": "OTTAWA MACDONALD-CARTIER INT'L A",
        "Province": "ONTARIO",
        "Climate ID": "6106000",
        "Station ID": 4337,
        "WMO ID": 71628,
        "TC ID": "YOW",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 451921000,
        "Longitude": -754009000,
        "Elevation (m)": 114,
        "First Year": 1938,
        "Last Year": 2011,
        "HLY First Year": 1953,
        "HLY Last Year": 2011,
        "DLY First Year": 1938,
        "DLY Last Year": 2011,
        "MLY First Year": 1938,
        "MLY Last Year": 2011
      },
      {
        "Name": "OTTAWA INTL A",
        "Province": "ONTARIO",
        "Climate ID": "6106001",
        "Station ID": 49568,
        "WMO ID": 71628,
        "TC ID": "YOW",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 451900000,
        "Longitude": -754000000,
        "Elevation (m)": 114.9,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OTTAWA KANATA",
        "Province": "ONTARIO",
        "Climate ID": "6106003",
        "Station ID": 4338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -75.92,
        "Latitude": 452000000,
        "Longitude": -755500000,
        "Elevation (m)": 100.6,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "OTTAWA LA SALLE ACAD",
        "Province": "ONTARIO",
        "Climate ID": "6106014",
        "Station ID": 4339,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -75.7,
        "Latitude": 452600000,
        "Longitude": -754200000,
        "Elevation (m)": 61,
        "First Year": 1954,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1967,
        "MLY First Year": 1954,
        "MLY Last Year": 1967
      },
      {
        "Name": "RIDEAU NARROWS LOCKS",
        "Province": "ONTARIO",
        "Climate ID": "6106022",
        "Station ID": 26861,
        "WMO ID": null,
        "TC ID": "WJM",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -76.3,
        "Latitude": 444200000,
        "Longitude": -761800000,
        "Elevation (m)": 125,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": 1994,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OTTAWA LEMIEUX ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6106052",
        "Station ID": 4340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -75.73,
        "Latitude": 452500000,
        "Longitude": -754400000,
        "Elevation (m)": 61,
        "First Year": 1953,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1979,
        "MLY First Year": 1953,
        "MLY Last Year": 1979
      },
      {
        "Name": "OTTAWA NEPEAN",
        "Province": "ONTARIO",
        "Climate ID": "6106080",
        "Station ID": 4341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -75.75,
        "Latitude": 452300000,
        "Longitude": -754500000,
        "Elevation (m)": 83.8,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "OTTAWA NRC",
        "Province": "ONTARIO",
        "Climate ID": "6106090",
        "Station ID": 4342,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -75.62,
        "Latitude": 452700000,
        "Longitude": -753700000,
        "Elevation (m)": 97.5,
        "First Year": 1951,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1984,
        "MLY First Year": 1952,
        "MLY Last Year": 1984
      },
      {
        "Name": "OTTAWA RIDEAU WARD",
        "Province": "ONTARIO",
        "Climate ID": "6106098",
        "Station ID": 4343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 452400000,
        "Longitude": -753800000,
        "Elevation (m)": 71.3,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "OTTAWA ROCKCLIFFE A",
        "Province": "ONTARIO",
        "Climate ID": "6106100",
        "Station ID": 4344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 452700000,
        "Longitude": -753800000,
        "Elevation (m)": 54.3,
        "First Year": 1942,
        "Last Year": 1964,
        "HLY First Year": 1953,
        "HLY Last Year": 1964,
        "DLY First Year": 1942,
        "DLY Last Year": 1964,
        "MLY First Year": 1942,
        "MLY Last Year": 1964
      },
      {
        "Name": "OTTAWA SOUTH MARCH",
        "Province": "ONTARIO",
        "Climate ID": "6106102",
        "Station ID": 4345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -75.93,
        "Latitude": 452100000,
        "Longitude": -755600000,
        "Elevation (m)": 86.9,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "OTTAWA STOLPORT A",
        "Province": "ONTARIO",
        "Climate ID": "6106103",
        "Station ID": 7684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -75.65,
        "Latitude": 452800000,
        "Longitude": -753900000,
        "Elevation (m)": 58.5,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": 1974,
        "HLY Last Year": 1976,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OTTAWA U OF O",
        "Province": "ONTARIO",
        "Climate ID": "6106105",
        "Station ID": 4346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -75.68,
        "Latitude": 452500000,
        "Longitude": -754100000,
        "Elevation (m)": 65.5,
        "First Year": 1954,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "OUTLET",
        "Province": "ONTARIO",
        "Climate ID": "6106123",
        "Station ID": 4347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -76.05,
        "Latitude": 442900000,
        "Longitude": -760300000,
        "Elevation (m)": 91.4,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "PEMBROKE EDDY MATCH",
        "Province": "ONTARIO",
        "Climate ID": "6106362",
        "Station ID": 4348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -77.15,
        "Latitude": 455000000,
        "Longitude": -770900000,
        "Elevation (m)": 125,
        "First Year": 1866,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 1969,
        "MLY First Year": 1866,
        "MLY Last Year": 1969
      },
      {
        "Name": "PEMBROKE FORESTRY STN",
        "Province": "ONTARIO",
        "Climate ID": "6106365",
        "Station ID": 4349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -77.18,
        "Latitude": 454800000,
        "Longitude": -771100000,
        "Elevation (m)": 121.9,
        "First Year": 1926,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1942,
        "MLY First Year": 1926,
        "MLY Last Year": 1942
      },
      {
        "Name": "PEMBROKE CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6106367",
        "Station ID": 49068,
        "WMO ID": 71196,
        "TC ID": "TPM",
        "Latitude (Decimal Degrees)": 45.86,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 455137000,
        "Longitude": -771508000,
        "Elevation (m)": 161,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PEMBROKE SE",
        "Province": "ONTARIO",
        "Climate ID": "6106369",
        "Station ID": 31127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -76.98,
        "Latitude": 454500000,
        "Longitude": -765900000,
        "Elevation (m)": 148,
        "First Year": 1999,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2004,
        "MLY First Year": 1999,
        "MLY Last Year": 2004
      },
      {
        "Name": "PERCH LAKE MAIN IHD",
        "Province": "ONTARIO",
        "Climate ID": "6106378",
        "Station ID": 4350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -77.63,
        "Latitude": 460400000,
        "Longitude": -773800000,
        "Elevation (m)": 167.6,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "PERTH ONTARIO HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6106385",
        "Station ID": 4351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -76.27,
        "Latitude": 445500000,
        "Longitude": -761600000,
        "Elevation (m)": 134.1,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "PETAWAWA AWOS 2",
        "Province": "ONTARIO",
        "Climate ID": "6106396",
        "Station ID": 47527,
        "WMO ID": 71625,
        "TC ID": "YWA",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -77.32,
        "Latitude": 455700000,
        "Longitude": -771900000,
        "Elevation (m)": 130.1,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PETAWAWA A",
        "Province": "ONTARIO",
        "Climate ID": "6106398",
        "Station ID": 4352,
        "WMO ID": 71625,
        "TC ID": "YWA",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -77.32,
        "Latitude": 455700000,
        "Longitude": -771900000,
        "Elevation (m)": 130.1,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": 1969,
        "HLY Last Year": 2022,
        "DLY First Year": 1970,
        "DLY Last Year": 2008,
        "MLY First Year": 1971,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETAWAWA NAT FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6106400",
        "Station ID": 4353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.98,
        "Longitude (Decimal Degrees)": -77.43,
        "Latitude": 455900000,
        "Longitude": -772600000,
        "Elevation (m)": 183,
        "First Year": 1931,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1999,
        "MLY First Year": 1931,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORT ELMSLEY",
        "Province": "ONTARIO",
        "Climate ID": "6106660",
        "Station ID": 4354,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -76.13,
        "Latitude": 445300000,
        "Longitude": -760800000,
        "Elevation (m)": 129.5,
        "First Year": 1948,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1968,
        "MLY First Year": 1948,
        "MLY Last Year": 1968
      },
      {
        "Name": "PORTLAND",
        "Province": "ONTARIO",
        "Climate ID": "6106677",
        "Station ID": 4355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -76.2,
        "Latitude": 444200000,
        "Longitude": -761200000,
        "Elevation (m)": 139,
        "First Year": 1953,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1958,
        "MLY First Year": 1953,
        "MLY Last Year": 1958
      },
      {
        "Name": "PURDY",
        "Province": "ONTARIO",
        "Climate ID": "6106779",
        "Station ID": 4356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -77.72,
        "Latitude": 451900000,
        "Longitude": -774300000,
        "Elevation (m)": 490.7,
        "First Year": 1955,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1993,
        "MLY First Year": 1955,
        "MLY Last Year": 1993
      },
      {
        "Name": "RAMSAYVILLE CRF",
        "Province": "ONTARIO",
        "Climate ID": "6106874",
        "Station ID": 4357,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -75.55,
        "Latitude": 452500000,
        "Longitude": -753300000,
        "Elevation (m)": 79.2,
        "First Year": 1972,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "RENFREW",
        "Province": "ONTARIO",
        "Climate ID": "6107002",
        "Station ID": 4359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -76.7,
        "Latitude": 452900000,
        "Longitude": -764200000,
        "Elevation (m)": 129.5,
        "First Year": 1968,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1996,
        "MLY First Year": 1968,
        "MLY Last Year": 1996
      },
      {
        "Name": "RENFREW",
        "Province": "ONTARIO",
        "Climate ID": "6107003",
        "Station ID": 4360,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -76.65,
        "Latitude": 452600000,
        "Longitude": -763900000,
        "Elevation (m)": 126.8,
        "First Year": 1882,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1929,
        "MLY First Year": 1882,
        "MLY Last Year": 1929
      },
      {
        "Name": "RENFREW SAND POINT",
        "Province": "ONTARIO",
        "Climate ID": "6107004",
        "Station ID": 4361,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -76.43,
        "Latitude": 452900000,
        "Longitude": -762600000,
        "Elevation (m)": 126.8,
        "First Year": 1929,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1959,
        "MLY First Year": 1929,
        "MLY Last Year": 1959
      },
      {
        "Name": "RICHMOND",
        "Province": "ONTARIO",
        "Climate ID": "6107010",
        "Station ID": 4362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -75.78,
        "Latitude": 451000000,
        "Longitude": -754700000,
        "Elevation (m)": 99.1,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "RICHMOND",
        "Province": "ONTARIO",
        "Climate ID": "6107011",
        "Station ID": 4363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.18,
        "Longitude (Decimal Degrees)": -75.85,
        "Latitude": 451100000,
        "Longitude": -755100000,
        "Elevation (m)": 97.5,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "RIDEAU CANAL BOBS LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6107017",
        "Station ID": 4364,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -76.6,
        "Latitude": 444400000,
        "Longitude": -763600000,
        "Elevation (m)": 178.3,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1954,
        "MLY Last Year": 1961
      },
      {
        "Name": "RIDEAU C BURRITS RAPIDS",
        "Province": "ONTARIO",
        "Climate ID": "6107031",
        "Station ID": 4365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -75.8,
        "Latitude": 445900000,
        "Longitude": -754800000,
        "Elevation (m)": 88.4,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1969,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "RIDEAU CANAL JONES FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6107045",
        "Station ID": 4366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -76.23,
        "Latitude": 443300000,
        "Longitude": -761400000,
        "Elevation (m)": 100.6,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1969,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "RIDEAU CANAL KILMARNOCK",
        "Province": "ONTARIO",
        "Climate ID": "6107059",
        "Station ID": 4367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -75.93,
        "Latitude": 445300000,
        "Longitude": -755600000,
        "Elevation (m)": 106.7,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1969,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "RIDEAU CANAL LONG ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6107073",
        "Station ID": 4368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -75.7,
        "Latitude": 451500000,
        "Longitude": -754200000,
        "Elevation (m)": 83.8,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1969,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "RIDEAU CANAL NARROWS",
        "Province": "ONTARIO",
        "Climate ID": "6107087",
        "Station ID": 4369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -76.3,
        "Latitude": 444200000,
        "Longitude": -761800000,
        "Elevation (m)": 125,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1969,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "RIDEAU CANAL PERTH",
        "Province": "ONTARIO",
        "Climate ID": "6107096",
        "Station ID": 4370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.9,
        "Longitude (Decimal Degrees)": -76.25,
        "Latitude": 445400000,
        "Longitude": -761500000,
        "Elevation (m)": 134.1,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "RICHMOND",
        "Province": "ONTARIO",
        "Climate ID": "61070AA",
        "Station ID": 4358,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -75.9,
        "Latitude": 450900000,
        "Longitude": -755400000,
        "Elevation (m)": 110,
        "First Year": 1981,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1984,
        "MLY First Year": 1981,
        "MLY Last Year": 1984
      },
      {
        "Name": "RIDEAU CANAL UPP BREWERS",
        "Province": "ONTARIO",
        "Climate ID": "6107105",
        "Station ID": 4371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.42,
        "Longitude (Decimal Degrees)": -76.32,
        "Latitude": 442500000,
        "Longitude": -761900000,
        "Elevation (m)": 99.1,
        "First Year": 1954,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1967,
        "MLY First Year": 1954,
        "MLY Last Year": 1969
      },
      {
        "Name": "RIDEAU CANAL WOLFE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6107119",
        "Station ID": 4372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -76.4,
        "Latitude": 443600000,
        "Longitude": -762400000,
        "Elevation (m)": 125,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1954,
        "MLY Last Year": 1961
      },
      {
        "Name": "RIDEAU FERRY",
        "Province": "ONTARIO",
        "Climate ID": "6107133",
        "Station ID": 4373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.85,
        "Longitude (Decimal Degrees)": -76.15,
        "Latitude": 445100000,
        "Longitude": -760900000,
        "Elevation (m)": 129.5,
        "First Year": 1948,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1969,
        "MLY First Year": 1948,
        "MLY Last Year": 1963
      },
      {
        "Name": "ROLPHTON",
        "Province": "ONTARIO",
        "Climate ID": "6107182",
        "Station ID": 4374,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -77.65,
        "Latitude": 461100000,
        "Longitude": -773900000,
        "Elevation (m)": 136.5,
        "First Year": 1990,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2001,
        "MLY First Year": 1990,
        "MLY Last Year": 2001
      },
      {
        "Name": "ROLPHTON NPD",
        "Province": "ONTARIO",
        "Climate ID": "6107184",
        "Station ID": 4375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -77.67,
        "Latitude": 461100000,
        "Longitude": -774000000,
        "Elevation (m)": 121.9,
        "First Year": 1977,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1987,
        "MLY First Year": 1977,
        "MLY Last Year": 1987
      },
      {
        "Name": "RUSSELL",
        "Province": "ONTARIO",
        "Climate ID": "6107247",
        "Station ID": 4376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.26,
        "Longitude (Decimal Degrees)": -75.36,
        "Latitude": 451546008,
        "Longitude": -752134032,
        "Elevation (m)": 76.2,
        "First Year": 1954,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2013,
        "MLY First Year": 1954,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST. ALBERT",
        "Province": "ONTARIO",
        "Climate ID": "6107276",
        "Station ID": 4377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.29,
        "Longitude (Decimal Degrees)": -75.06,
        "Latitude": 451714004,
        "Longitude": -750349026,
        "Elevation (m)": 80,
        "First Year": 1986,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2022,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST ELMO",
        "Province": "ONTARIO",
        "Climate ID": "6107310",
        "Station ID": 4378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -74.85,
        "Latitude": 451900000,
        "Longitude": -745100000,
        "Elevation (m)": 83.8,
        "First Year": 1966,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST RAPHAEL",
        "Province": "ONTARIO",
        "Climate ID": "6107352",
        "Station ID": 4379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -74.58,
        "Latitude": 451300000,
        "Longitude": -743500000,
        "Elevation (m)": 68.6,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "SANDHURST",
        "Province": "ONTARIO",
        "Climate ID": "6107434",
        "Station ID": 6902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -76.88,
        "Latitude": 440800000,
        "Longitude": -765300000,
        "Elevation (m)": 82.5,
        "First Year": 1993,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2002,
        "MLY First Year": 1993,
        "MLY Last Year": 2002
      },
      {
        "Name": "SARSFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6107533",
        "Station ID": 4380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -75.35,
        "Latitude": 452600000,
        "Longitude": -752100000,
        "Elevation (m)": 85,
        "First Year": 1985,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1989,
        "MLY First Year": 1985,
        "MLY Last Year": 1989
      },
      {
        "Name": "SHIRLEY BAY",
        "Province": "ONTARIO",
        "Climate ID": "6107699",
        "Station ID": 4381,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -75.88,
        "Latitude": 452100000,
        "Longitude": -755300000,
        "Elevation (m)": 76.2,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "SMITHS FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6107834",
        "Station ID": 4382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -76.05,
        "Latitude": 445500000,
        "Longitude": -760300000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1923,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1922,
        "MLY First Year": 1895,
        "MLY Last Year": 1923
      },
      {
        "Name": "SMITHS FALLS WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6107835",
        "Station ID": 4383,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.9,
        "Longitude (Decimal Degrees)": -76,
        "Latitude": 445400000,
        "Longitude": -760000000,
        "Elevation (m)": 121.9,
        "First Year": 1964,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1983,
        "MLY First Year": 1964,
        "MLY Last Year": 1983
      },
      {
        "Name": "SMITHS FALLS TS",
        "Province": "ONTARIO",
        "Climate ID": "6107836",
        "Station ID": 4384,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -76,
        "Latitude": 445300000,
        "Longitude": -760000000,
        "Elevation (m)": 114.3,
        "First Year": 1982,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1989,
        "MLY First Year": 1982,
        "MLY Last Year": 1989
      },
      {
        "Name": "SOUTH MOUNTAIN",
        "Province": "ONTARIO",
        "Climate ID": "6107955",
        "Station ID": 4385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -75.48,
        "Latitude": 445800000,
        "Longitude": -752900000,
        "Elevation (m)": 84.7,
        "First Year": 1960,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1996,
        "MLY First Year": 1960,
        "MLY Last Year": 1996
      },
      {
        "Name": "SPENCERVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6107971",
        "Station ID": 4386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.85,
        "Longitude (Decimal Degrees)": -75.55,
        "Latitude": 445100000,
        "Longitude": -753300000,
        "Elevation (m)": 94.5,
        "First Year": 1953,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1959,
        "MLY First Year": 1953,
        "MLY Last Year": 1959
      },
      {
        "Name": "STEWARTVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6108027",
        "Station ID": 4387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 452400000,
        "Longitude": -763000000,
        "Elevation (m)": 131.1,
        "First Year": 1950,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1969,
        "MLY First Year": 1950,
        "MLY Last Year": 1969
      },
      {
        "Name": "STONECLIFFE",
        "Province": "ONTARIO",
        "Climate ID": "6108060",
        "Station ID": 4388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -78.1,
        "Latitude": 460900000,
        "Longitude": -780600000,
        "Elevation (m)": 149,
        "First Year": 1876,
        "Last Year": 1921,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1876,
        "DLY Last Year": 1921,
        "MLY First Year": 1876,
        "MLY Last Year": 1921
      },
      {
        "Name": "SYDENHAM",
        "Province": "ONTARIO",
        "Climate ID": "6108195",
        "Station ID": 4389,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -76.58,
        "Latitude": 442400000,
        "Longitude": -763500000,
        "Elevation (m)": 133.2,
        "First Year": 1903,
        "Last Year": 1917,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1917,
        "MLY First Year": 1903,
        "MLY Last Year": 1917
      },
      {
        "Name": "VANKLEEK HILL",
        "Province": "ONTARIO",
        "Climate ID": "6109132",
        "Station ID": 4390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -74.65,
        "Latitude": 453100000,
        "Longitude": -743900000,
        "Elevation (m)": 88.4,
        "First Year": 1896,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1961,
        "MLY First Year": 1902,
        "MLY Last Year": 1961
      },
      {
        "Name": "WESTPORT",
        "Province": "ONTARIO",
        "Climate ID": "6109458",
        "Station ID": 4391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.68,
        "Longitude (Decimal Degrees)": -76.42,
        "Latitude": 444100000,
        "Longitude": -762500000,
        "Elevation (m)": 135.3,
        "First Year": 1895,
        "Last Year": 1920,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1920,
        "MLY First Year": 1895,
        "MLY Last Year": 1920
      },
      {
        "Name": "WHITE LAKE FISH CULTURE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6109503",
        "Station ID": 43823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -75.75,
        "Latitude": 444611028,
        "Longitude": -754450082,
        "Elevation (m)": 204,
        "First Year": 2005,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2005,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "WINCHESTER CS",
        "Province": "ONTARIO",
        "Climate ID": "6109515",
        "Station ID": 27605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -75.33,
        "Latitude": 450300000,
        "Longitude": -752000000,
        "Elevation (m)": 90,
        "First Year": 1998,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2001,
        "MLY First Year": 1998,
        "MLY Last Year": 2001
      },
      {
        "Name": "WINCHESTER",
        "Province": "ONTARIO",
        "Climate ID": "6109517",
        "Station ID": 42005,
        "WMO ID": null,
        "TC ID": "TWN",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -75.34,
        "Latitude": 450304000,
        "Longitude": -752012000,
        "Elevation (m)": 75,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WOLFE ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6109558",
        "Station ID": 4392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -76.53,
        "Latitude": 440900000,
        "Longitude": -763200000,
        "Elevation (m)": 90,
        "First Year": 1986,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1996,
        "MLY First Year": 1986,
        "MLY Last Year": 1996
      },
      {
        "Name": "WOODLAWN",
        "Province": "ONTARIO",
        "Climate ID": "6109590",
        "Station ID": 4393,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -76.1,
        "Latitude": 453000000,
        "Longitude": -760600000,
        "Elevation (m)": 91.4,
        "First Year": 1975,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1982,
        "MLY First Year": 1975,
        "MLY Last Year": 1982
      },
      {
        "Name": "MERIVALE TS",
        "Province": "ONTARIO",
        "Climate ID": "6.10E+63",
        "Station ID": 4216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -75.73,
        "Latitude": 452000000,
        "Longitude": -754400000,
        "Elevation (m)": 97.5,
        "First Year": 1983,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1994,
        "MLY First Year": 1983,
        "MLY Last Year": 1994
      },
      {
        "Name": "PERTH",
        "Province": "ONTARIO",
        "Climate ID": "610F3Q0",
        "Station ID": 4218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -76.2,
        "Latitude": 445300000,
        "Longitude": -761200000,
        "Elevation (m)": 133.2,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "PETAWAWA HOFFMAN",
        "Province": "ONTARIO",
        "Climate ID": "610FC98",
        "Station ID": 6900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 455300000,
        "Longitude": -771500000,
        "Elevation (m)": 153,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "PERTH",
        "Province": "ONTARIO",
        "Climate ID": "610FLPR",
        "Station ID": 4217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -76.28,
        "Latitude": 445500000,
        "Longitude": -761700000,
        "Elevation (m)": 145,
        "First Year": 1986,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1987,
        "MLY First Year": 1986,
        "MLY Last Year": 1987
      },
      {
        "Name": "ALLAN PARK",
        "Province": "ONTARIO",
        "Climate ID": "6110210",
        "Station ID": 4400,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -80.93,
        "Latitude": 441000000,
        "Longitude": -805600000,
        "Elevation (m)": 285,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "ALLISTON",
        "Province": "ONTARIO",
        "Climate ID": "6110216",
        "Station ID": 4401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 440900000,
        "Longitude": -795100000,
        "Elevation (m)": 213.4,
        "First Year": 1953,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1975,
        "MLY First Year": 1953,
        "MLY Last Year": 1975
      },
      {
        "Name": "ALLISTON NELSON",
        "Province": "ONTARIO",
        "Climate ID": "6110218",
        "Station ID": 4402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 440905028,
        "Longitude": -795220088,
        "Elevation (m)": 221,
        "First Year": 1973,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2008,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "ANGUS",
        "Province": "ONTARIO",
        "Climate ID": "6110270",
        "Station ID": 4403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 441900000,
        "Longitude": -795200000,
        "Elevation (m)": 191.1,
        "First Year": 1930,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1965,
        "MLY First Year": 1930,
        "MLY Last Year": 1965
      },
      {
        "Name": "ANGUS CAMPHILL",
        "Province": "ONTARIO",
        "Climate ID": "6110275",
        "Station ID": 27001,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.28,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 441700000,
        "Longitude": -795100000,
        "Elevation (m)": 212,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1997,
        "MLY First Year": 1994,
        "MLY Last Year": 1997
      },
      {
        "Name": "AYTON",
        "Province": "ONTARIO",
        "Climate ID": "6110439",
        "Station ID": 4404,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -80.83,
        "Latitude": 440500000,
        "Longitude": -805000000,
        "Elevation (m)": 361,
        "First Year": 1988,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1993,
        "MLY First Year": 1988,
        "MLY Last Year": 1993
      },
      {
        "Name": "BALA",
        "Province": "ONTARIO",
        "Climate ID": "6110458",
        "Station ID": 4405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 450000000,
        "Longitude": -793000000,
        "Elevation (m)": 225.6,
        "First Year": 1883,
        "Last Year": 1907,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1907,
        "MLY First Year": 1883,
        "MLY Last Year": 1907
      },
      {
        "Name": "BALACLAVA",
        "Province": "ONTARIO",
        "Climate ID": "6110470",
        "Station ID": 44967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.67,
        "Longitude (Decimal Degrees)": -80.78,
        "Latitude": 444013020,
        "Longitude": -804636030,
        "Elevation (m)": 222,
        "First Year": 2006,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2016,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "BALDWIN",
        "Province": "ONTARIO",
        "Climate ID": "6110480",
        "Station ID": 43540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 441601038,
        "Longitude": -791908028,
        "Elevation (m)": 229,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "BARRIE",
        "Province": "ONTARIO",
        "Climate ID": "6110549",
        "Station ID": 4406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 442400000,
        "Longitude": -794100000,
        "Elevation (m)": 228.6,
        "First Year": 1866,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 1958,
        "MLY First Year": 1866,
        "MLY Last Year": 1958
      },
      {
        "Name": "BARRIE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6110552",
        "Station ID": 7697,
        "WMO ID": null,
        "TC ID": "WCU",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 442300000,
        "Longitude": -794700000,
        "Elevation (m)": 295,
        "First Year": 1992,
        "Last Year": 2003,
        "HLY First Year": 1994,
        "HLY Last Year": 2003,
        "DLY First Year": 1992,
        "DLY Last Year": 2003,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BARRIE CKVR",
        "Province": "ONTARIO",
        "Climate ID": "6110555",
        "Station ID": 4407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 442100000,
        "Longitude": -794200000,
        "Elevation (m)": 312.4,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "BARRIE LANDFILL",
        "Province": "ONTARIO",
        "Climate ID": "6110556",
        "Station ID": 50048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.39,
        "Longitude (Decimal Degrees)": -79.74,
        "Latitude": 442307000,
        "Longitude": -794410000,
        "Elevation (m)": 305,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BARRIE WPCC",
        "Province": "ONTARIO",
        "Climate ID": "6110557",
        "Station ID": 4408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -79.69,
        "Latitude": 442233012,
        "Longitude": -794123010,
        "Elevation (m)": 221,
        "First Year": 1968,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2009,
        "MLY First Year": 1968,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEATRICE",
        "Province": "ONTARIO",
        "Climate ID": "6110605",
        "Station ID": 4409,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 450800000,
        "Longitude": -792300000,
        "Elevation (m)": 289.6,
        "First Year": 1876,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1876,
        "DLY Last Year": 1979,
        "MLY First Year": 1876,
        "MLY Last Year": 1979
      },
      {
        "Name": "BEATRICE 2",
        "Province": "ONTARIO",
        "Climate ID": "6110606",
        "Station ID": 4410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 450800000,
        "Longitude": -792400000,
        "Elevation (m)": 297.2,
        "First Year": 1979,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2008,
        "MLY First Year": 1979,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEATRICE CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6110607",
        "Station ID": 44183,
        "WMO ID": 71694,
        "TC ID": "TBT",
        "Latitude (Decimal Degrees)": 45.14,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 450825000,
        "Longitude": -792410000,
        "Elevation (m)": 297.2,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEAUSOLEIL",
        "Province": "ONTARIO",
        "Climate ID": "6110617",
        "Station ID": 10973,
        "WMO ID": null,
        "TC ID": "WPB",
        "Latitude (Decimal Degrees)": 44.85,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 445100000,
        "Longitude": -795200000,
        "Elevation (m)": 183,
        "First Year": 1994,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2007,
        "DLY First Year": 1994,
        "DLY Last Year": 2007,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEAVERTON",
        "Province": "ONTARIO",
        "Climate ID": "6110633",
        "Station ID": 4411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.42,
        "Longitude (Decimal Degrees)": -79.15,
        "Latitude": 442500000,
        "Longitude": -790900000,
        "Elevation (m)": 230.1,
        "First Year": 1948,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1959,
        "MLY First Year": 1948,
        "MLY Last Year": 1959
      },
      {
        "Name": "BEETON",
        "Province": "ONTARIO",
        "Climate ID": "6110661",
        "Station ID": 4412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 440600000,
        "Longitude": -794700000,
        "Elevation (m)": 232.9,
        "First Year": 1916,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1970,
        "MLY First Year": 1916,
        "MLY Last Year": 1970
      },
      {
        "Name": "BEETON ASSAGI",
        "Province": "ONTARIO",
        "Climate ID": "6110662",
        "Station ID": 4413,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 440700000,
        "Longitude": -794600000,
        "Elevation (m)": 221,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "BEETON GRAHAM",
        "Province": "ONTARIO",
        "Climate ID": "6110663",
        "Station ID": 4414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 440500000,
        "Longitude": -794700000,
        "Elevation (m)": 228.6,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "BEETON WATSON",
        "Province": "ONTARIO",
        "Climate ID": "6110667",
        "Station ID": 4415,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 440400000,
        "Longitude": -795100000,
        "Elevation (m)": 251.5,
        "First Year": 1968,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1971,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "BIG CHUTE",
        "Province": "ONTARIO",
        "Climate ID": "6110731",
        "Station ID": 4416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 445300000,
        "Longitude": -794000000,
        "Elevation (m)": 190.5,
        "First Year": 1913,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1972,
        "MLY First Year": 1913,
        "MLY Last Year": 1972
      },
      {
        "Name": "BINGHAM CHUTE",
        "Province": "ONTARIO",
        "Climate ID": "6110745",
        "Station ID": 4417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.08,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 460500000,
        "Longitude": -792400000,
        "Elevation (m)": 242.3,
        "First Year": 1933,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1970,
        "MLY First Year": 1933,
        "MLY Last Year": 1970
      },
      {
        "Name": "BOGNOR",
        "Province": "ONTARIO",
        "Climate ID": "6110823",
        "Station ID": 4418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.67,
        "Longitude (Decimal Degrees)": -80.83,
        "Latitude": 444000000,
        "Longitude": -805000000,
        "Elevation (m)": 249.9,
        "First Year": 1883,
        "Last Year": 1900,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1900,
        "MLY First Year": 1883,
        "MLY Last Year": 1900
      },
      {
        "Name": "BORDEN A",
        "Province": "ONTARIO",
        "Climate ID": "6110827",
        "Station ID": 4419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 441600000,
        "Longitude": -795600000,
        "Elevation (m)": 227.4,
        "First Year": 1966,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1970,
        "MLY First Year": 1966,
        "MLY Last Year": 1970
      },
      {
        "Name": "BRACEBRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6110851",
        "Station ID": 4420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 450300000,
        "Longitude": -791900000,
        "Elevation (m)": 250,
        "First Year": 1984,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1989,
        "MLY First Year": 1984,
        "MLY Last Year": 1989
      },
      {
        "Name": "BRACEBRIDGE ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6110854",
        "Station ID": 4421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 450200000,
        "Longitude": -791800000,
        "Elevation (m)": 266.7,
        "First Year": 1983,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1987,
        "MLY First Year": 1983,
        "MLY Last Year": 1987
      },
      {
        "Name": "BRADFORD BROWN",
        "Province": "ONTARIO",
        "Climate ID": "6110859",
        "Station ID": 4422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 440900000,
        "Longitude": -793400000,
        "Elevation (m)": 292.6,
        "First Year": 1972,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1976,
        "MLY First Year": 1972,
        "MLY Last Year": 1976
      },
      {
        "Name": "BRECHIN BEACH",
        "Province": "ONTARIO",
        "Climate ID": "611095R",
        "Station ID": 4423,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.53,
        "Longitude (Decimal Degrees)": -79.2,
        "Latitude": 443200000,
        "Longitude": -791200000,
        "Elevation (m)": 219.5,
        "First Year": 1985,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1994,
        "MLY First Year": 1986,
        "MLY Last Year": 1994
      },
      {
        "Name": "BRITT (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6110967",
        "Station ID": 7704,
        "WMO ID": null,
        "TC ID": "WBI",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -80.53,
        "Latitude": 454800000,
        "Longitude": -803200000,
        "Elevation (m)": 190,
        "First Year": 1992,
        "Last Year": 2010,
        "HLY First Year": 1994,
        "HLY Last Year": 2001,
        "DLY First Year": 1992,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BORDEN STP",
        "Province": "ONTARIO",
        "Climate ID": "6110HK7",
        "Station ID": 4399,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.3,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 441800000,
        "Longitude": -795400000,
        "Elevation (m)": 201.2,
        "First Year": 1985,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1992,
        "MLY First Year": 1985,
        "MLY Last Year": 1992
      },
      {
        "Name": "BURKS FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6111045",
        "Station ID": 4425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 453600000,
        "Longitude": -793400000,
        "Elevation (m)": 320,
        "First Year": 1888,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1986,
        "MLY First Year": 1888,
        "MLY Last Year": 1986
      },
      {
        "Name": "BURK'S FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6111050",
        "Station ID": 45349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.66,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 453918054,
        "Longitude": -792400000,
        "Elevation (m)": 335,
        "First Year": 2006,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2010,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "BURK'S FALLS 2",
        "Province": "ONTARIO",
        "Climate ID": "61110M6",
        "Station ID": 4424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 453500000,
        "Longitude": -793600000,
        "Elevation (m)": 310,
        "First Year": 1988,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1995,
        "MLY First Year": 1988,
        "MLY Last Year": 1995
      },
      {
        "Name": "CAMP BORDEN",
        "Province": "ONTARIO",
        "Climate ID": "6111143",
        "Station ID": 4426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 441600000,
        "Longitude": -795400000,
        "Elevation (m)": 221,
        "First Year": 1960,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "CAMP BORDEN A",
        "Province": "ONTARIO",
        "Climate ID": "6111145",
        "Station ID": 4427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 441900000,
        "Longitude": -795400000,
        "Elevation (m)": 233.2,
        "First Year": 1926,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1945,
        "MLY First Year": 1926,
        "MLY Last Year": 1945
      },
      {
        "Name": "CHARLINCH",
        "Province": "ONTARIO",
        "Climate ID": "6111375",
        "Station ID": 4428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 452700000,
        "Longitude": -791500000,
        "Elevation (m)": 326.1,
        "First Year": 1883,
        "Last Year": 1893,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1893,
        "MLY First Year": 1883,
        "MLY Last Year": 1893
      },
      {
        "Name": "CHATSWORTH",
        "Province": "ONTARIO",
        "Climate ID": "6111467",
        "Station ID": 4429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -80.91,
        "Latitude": 442342054,
        "Longitude": -805420008,
        "Elevation (m)": 305,
        "First Year": 1952,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2013,
        "MLY First Year": 1953,
        "MLY Last Year": 2006
      },
      {
        "Name": "CLARKSBURG",
        "Province": "ONTARIO",
        "Climate ID": "6111546",
        "Station ID": 4430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -80.47,
        "Latitude": 443100000,
        "Longitude": -802800000,
        "Elevation (m)": 231.6,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "COLDWATER",
        "Province": "ONTARIO",
        "Climate ID": "6111766",
        "Station ID": 4431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 444200000,
        "Longitude": -794000000,
        "Elevation (m)": 182.9,
        "First Year": 1883,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1954,
        "MLY First Year": 1883,
        "MLY Last Year": 1954
      },
      {
        "Name": "COLDWATER WARMINSTER",
        "Province": "ONTARIO",
        "Climate ID": "6111769",
        "Station ID": 4432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -79.54,
        "Latitude": 443752038,
        "Longitude": -793220058,
        "Elevation (m)": 285,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2006
      },
      {
        "Name": "COLLINGWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6111792",
        "Station ID": 10955,
        "WMO ID": 71270,
        "TC ID": "WCO",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 443000000,
        "Longitude": -801300000,
        "Elevation (m)": 178.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "COLLINGWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6111793",
        "Station ID": 4433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 442900000,
        "Longitude": -801300000,
        "Elevation (m)": 182.9,
        "First Year": 1869,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1869,
        "DLY Last Year": 1974,
        "MLY First Year": 1869,
        "MLY Last Year": 1974
      },
      {
        "Name": "COLLINGWOOD A",
        "Province": "ONTARIO",
        "Climate ID": "6111794",
        "Station ID": 4434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.45,
        "Longitude (Decimal Degrees)": -80.17,
        "Latitude": 442700000,
        "Longitude": -801000000,
        "Elevation (m)": 220.7,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "COLLINGWOOD SMART BROS",
        "Province": "ONTARIO",
        "Climate ID": "6111797",
        "Station ID": 4435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -80.25,
        "Latitude": 443000000,
        "Longitude": -801500000,
        "Elevation (m)": 198.1,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "COLLINGWOOD WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6111800",
        "Station ID": 4436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 443000000,
        "Longitude": -801300000,
        "Elevation (m)": 180,
        "First Year": 1973,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1997,
        "MLY First Year": 1973,
        "MLY Last Year": 1997
      },
      {
        "Name": "COOKSTOWN",
        "Province": "ONTARIO",
        "Climate ID": "6111859",
        "Station ID": 4437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.21,
        "Longitude (Decimal Degrees)": -79.69,
        "Latitude": 441224042,
        "Longitude": -794141088,
        "Elevation (m)": 243.8,
        "First Year": 1972,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2007,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "DALRYMPLE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6111965",
        "Station ID": 4438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 443600000,
        "Longitude": -790800000,
        "Elevation (m)": 228.6,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "DORSET",
        "Province": "ONTARIO",
        "Climate ID": "6112070",
        "Station ID": 4439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 451000000,
        "Longitude": -785000000,
        "Elevation (m)": 347.2,
        "First Year": 1949,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1969,
        "MLY First Year": 1949,
        "MLY Last Year": 1969
      },
      {
        "Name": "DORSET MOE",
        "Province": "ONTARIO",
        "Climate ID": "6112072",
        "Station ID": 4440,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -78.93,
        "Latitude": 451300000,
        "Longitude": -785600000,
        "Elevation (m)": 323.1,
        "First Year": 1976,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2002,
        "MLY First Year": 1976,
        "MLY Last Year": 2002
      },
      {
        "Name": "DUNCHURCH",
        "Province": "ONTARIO",
        "Climate ID": "6112133",
        "Station ID": 4441,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -79.88,
        "Latitude": 453700000,
        "Longitude": -795300000,
        "Elevation (m)": 268.2,
        "First Year": 1973,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2014,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "DURHAM",
        "Province": "ONTARIO",
        "Climate ID": "6112171",
        "Station ID": 4442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.18,
        "Longitude (Decimal Degrees)": -80.82,
        "Latitude": 441100000,
        "Longitude": -804900000,
        "Elevation (m)": 384,
        "First Year": 1882,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 2003,
        "MLY First Year": 1882,
        "MLY Last Year": 2003
      },
      {
        "Name": "ELMVALE",
        "Province": "ONTARIO",
        "Climate ID": "6112278",
        "Station ID": 4443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 443500000,
        "Longitude": -795400000,
        "Elevation (m)": 213.4,
        "First Year": 1947,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1951,
        "MLY First Year": 1947,
        "MLY Last Year": 1951
      },
      {
        "Name": "EMSDALE",
        "Province": "ONTARIO",
        "Climate ID": "6112313",
        "Station ID": 4444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 453000000,
        "Longitude": -791400000,
        "Elevation (m)": 327.7,
        "First Year": 1895,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1952,
        "MLY First Year": 1895,
        "MLY Last Year": 1952
      },
      {
        "Name": "ESSA ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6112340",
        "Station ID": 4445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -79.82,
        "Latitude": 442100000,
        "Longitude": -794900000,
        "Elevation (m)": 216.4,
        "First Year": 1958,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2001,
        "MLY First Year": 1958,
        "MLY Last Year": 2001
      },
      {
        "Name": "EUGENIA",
        "Province": "ONTARIO",
        "Climate ID": "6112350",
        "Station ID": 4446,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -80.55,
        "Latitude": 442000000,
        "Longitude": -803300000,
        "Elevation (m)": 274.3,
        "First Year": 1916,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1969,
        "MLY First Year": 1916,
        "MLY Last Year": 1969
      },
      {
        "Name": "FESSERTON",
        "Province": "ONTARIO",
        "Climate ID": "6112410",
        "Station ID": 4447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 444400000,
        "Longitude": -794100000,
        "Elevation (m)": 179.8,
        "First Year": 1961,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1964
      },
      {
        "Name": "GILFORD",
        "Province": "ONTARIO",
        "Climate ID": "6112775",
        "Station ID": 4448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 441300000,
        "Longitude": -793300000,
        "Elevation (m)": 251.5,
        "First Year": 1959,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1968,
        "MLY First Year": 1959,
        "MLY Last Year": 1968
      },
      {
        "Name": "GILFORD",
        "Province": "ONTARIO",
        "Climate ID": "6112778",
        "Station ID": 4449,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.2,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 441200000,
        "Longitude": -793400000,
        "Elevation (m)": 266.7,
        "First Year": 1976,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1983,
        "MLY First Year": 1976,
        "MLY Last Year": 1983
      },
      {
        "Name": "GRAVENHURST",
        "Province": "ONTARIO",
        "Climate ID": "6113000",
        "Station ID": 4450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 445500000,
        "Longitude": -792200000,
        "Elevation (m)": 248.4,
        "First Year": 1870,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1949,
        "MLY First Year": 1870,
        "MLY Last Year": 1949
      },
      {
        "Name": "GREY COUNTY FOREST",
        "Province": "ONTARIO",
        "Climate ID": "6113030",
        "Station ID": 4451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -80.8,
        "Latitude": 440700000,
        "Longitude": -804800000,
        "Elevation (m)": 365.8,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "HANOVER",
        "Province": "ONTARIO",
        "Climate ID": "6113329",
        "Station ID": 4452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -81.01,
        "Latitude": 440659058,
        "Longitude": -810021042,
        "Elevation (m)": 270,
        "First Year": 1972,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2008,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "HARP LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6113358",
        "Station ID": 4453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -79.12,
        "Latitude": 452300000,
        "Longitude": -790700000,
        "Elevation (m)": 335.3,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "HIDDEN VALLEY",
        "Province": "ONTARIO",
        "Climate ID": "6113400",
        "Station ID": 45347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.36,
        "Longitude (Decimal Degrees)": -79.14,
        "Latitude": 452130072,
        "Longitude": -790809042,
        "Elevation (m)": 320,
        "First Year": 2006,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HOLLY",
        "Province": "ONTARIO",
        "Climate ID": "6113479",
        "Station ID": 4454,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -79.72,
        "Latitude": 441900000,
        "Longitude": -794300000,
        "Elevation (m)": 301.8,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "HOLSTEIN",
        "Province": "ONTARIO",
        "Climate ID": "6113480",
        "Station ID": 4455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -80.77,
        "Latitude": 440300000,
        "Longitude": -804600000,
        "Elevation (m)": 407.2,
        "First Year": 1953,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1966,
        "MLY First Year": 1953,
        "MLY Last Year": 1966
      },
      {
        "Name": "HONEY HBR BEAUSOLEIL",
        "Province": "ONTARIO",
        "Climate ID": "6113490",
        "Station ID": 4456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.85,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 445100000,
        "Longitude": -795200000,
        "Elevation (m)": 182.9,
        "First Year": 1974,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2003,
        "MLY First Year": 1974,
        "MLY Last Year": 2003
      },
      {
        "Name": "HOPEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6113510",
        "Station ID": 4457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -80.57,
        "Latitude": 440500000,
        "Longitude": -803400000,
        "Elevation (m)": 479.5,
        "First Year": 1947,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1964,
        "MLY First Year": 1947,
        "MLY Last Year": 1964
      },
      {
        "Name": "HORSESHOE VALLEY",
        "Province": "ONTARIO",
        "Climate ID": "6113580",
        "Station ID": 4458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 443300000,
        "Longitude": -794000000,
        "Elevation (m)": 335.3,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "HUNTSVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6113660",
        "Station ID": 4459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 451900000,
        "Longitude": -791500000,
        "Elevation (m)": 289.9,
        "First Year": 1879,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1961,
        "MLY First Year": 1879,
        "MLY Last Year": 1960
      },
      {
        "Name": "HUNTSVILLE WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6113663",
        "Station ID": 4460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 452100000,
        "Longitude": -791000000,
        "Elevation (m)": 320.6,
        "First Year": 1960,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2003,
        "MLY First Year": 1961,
        "MLY Last Year": 2003
      },
      {
        "Name": "HUNTSVILLE TAWINGO",
        "Province": "ONTARIO",
        "Climate ID": "6113665",
        "Station ID": 4461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 452100000,
        "Longitude": -791900000,
        "Elevation (m)": 289.6,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "KATRINE",
        "Province": "ONTARIO",
        "Climate ID": "6114005",
        "Station ID": 4462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 453500000,
        "Longitude": -792100000,
        "Elevation (m)": 299.9,
        "First Year": 1949,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1949,
        "MLY First Year": 1949,
        "MLY Last Year": 1949
      },
      {
        "Name": "KATRINE DOE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6114006",
        "Station ID": 4463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 453200000,
        "Longitude": -792400000,
        "Elevation (m)": 305.1,
        "First Year": 1977,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1995,
        "MLY First Year": 1977,
        "MLY Last Year": 1995
      },
      {
        "Name": "KESWICK",
        "Province": "ONTARIO",
        "Climate ID": "6114089",
        "Station ID": 4464,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 441300000,
        "Longitude": -792600000,
        "Elevation (m)": 228.6,
        "First Year": 1970,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "KIMBERLEY",
        "Province": "ONTARIO",
        "Climate ID": "6114126",
        "Station ID": 4465,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -80.53,
        "Latitude": 442300000,
        "Longitude": -803200000,
        "Elevation (m)": 236.2,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "LAFONTAINE",
        "Province": "ONTARIO",
        "Climate ID": "6114290",
        "Station ID": 4466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 444500000,
        "Longitude": -800500000,
        "Elevation (m)": 182.9,
        "First Year": 1947,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1967,
        "MLY First Year": 1947,
        "MLY Last Year": 1967
      },
      {
        "Name": "LAGOON CITY",
        "Province": "ONTARIO",
        "Climate ID": "6114295",
        "Station ID": 10911,
        "WMO ID": 71282,
        "TC ID": "WGL",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -79.22,
        "Latitude": 443250000,
        "Longitude": -791300000,
        "Elevation (m)": 220.7,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "LIONS HEAD",
        "Province": "ONTARIO",
        "Climate ID": "6114437",
        "Station ID": 4467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.95,
        "Longitude (Decimal Degrees)": -81.22,
        "Latitude": 445700000,
        "Longitude": -811300000,
        "Elevation (m)": 248.1,
        "First Year": 1883,
        "Last Year": 1906,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1906,
        "MLY First Year": 1883,
        "MLY Last Year": 1906
      },
      {
        "Name": "MAGNETAWAN",
        "Province": "ONTARIO",
        "Climate ID": "6114805",
        "Station ID": 4468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 454000000,
        "Longitude": -794700000,
        "Elevation (m)": 280.4,
        "First Year": 1924,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1958,
        "MLY First Year": 1924,
        "MLY Last Year": 1957
      },
      {
        "Name": "MARKDALE",
        "Province": "ONTARIO",
        "Climate ID": "6114977",
        "Station ID": 4469,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -80.63,
        "Latitude": 441900000,
        "Longitude": -803800000,
        "Elevation (m)": 426.7,
        "First Year": 1969,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "MARKDALE",
        "Province": "ONTARIO",
        "Climate ID": "6114978",
        "Station ID": 4470,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -80.68,
        "Latitude": 442400000,
        "Longitude": -804100000,
        "Elevation (m)": 426.7,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "MARKDALE",
        "Province": "ONTARIO",
        "Climate ID": "6114979",
        "Station ID": 43046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -80.71,
        "Latitude": 441608040,
        "Longitude": -804243008,
        "Elevation (m)": 411,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "MEAFORD",
        "Province": "ONTARIO",
        "Climate ID": "6115057",
        "Station ID": 4471,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -80.58,
        "Latitude": 443500000,
        "Longitude": -803500000,
        "Elevation (m)": 216.4,
        "First Year": 1899,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1966,
        "MLY First Year": 1899,
        "MLY Last Year": 1966
      },
      {
        "Name": "MEAFORD WILLOWMERE",
        "Province": "ONTARIO",
        "Climate ID": "6115059",
        "Station ID": 4472,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -80.63,
        "Latitude": 443700000,
        "Longitude": -803800000,
        "Elevation (m)": 213.4,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1982
      },
      {
        "Name": "MIDHURST",
        "Province": "ONTARIO",
        "Climate ID": "6115099",
        "Station ID": 4473,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.45,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 442700000,
        "Longitude": -794600000,
        "Elevation (m)": 225.6,
        "First Year": 1947,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1996,
        "MLY First Year": 1947,
        "MLY Last Year": 1996
      },
      {
        "Name": "MIDLAND WATER POLLUTION CONTROL PLANT",
        "Province": "ONTARIO",
        "Climate ID": "6115127",
        "Station ID": 4474,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.76,
        "Longitude (Decimal Degrees)": -79.88,
        "Latitude": 444528056,
        "Longitude": -795231014,
        "Elevation (m)": 180,
        "First Year": 1889,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 2017,
        "MLY First Year": 1889,
        "MLY Last Year": 2006
      },
      {
        "Name": "MIDLAND HURONIA A",
        "Province": "ONTARIO",
        "Climate ID": "6115130",
        "Station ID": 4475,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.68,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 444100000,
        "Longitude": -795600000,
        "Elevation (m)": 234.7,
        "First Year": 1987,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2002,
        "MLY First Year": 1987,
        "MLY Last Year": 2002
      },
      {
        "Name": "MILFORD BAY",
        "Province": "ONTARIO",
        "Climate ID": "6115150",
        "Station ID": 4476,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 450600000,
        "Longitude": -792900000,
        "Elevation (m)": 251.5,
        "First Year": 1965,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1984,
        "MLY First Year": 1966,
        "MLY Last Year": 1984
      },
      {
        "Name": "MOONSTONE",
        "Province": "ONTARIO",
        "Climate ID": "6115389",
        "Station ID": 6903,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 443800000,
        "Longitude": -794000000,
        "Elevation (m)": 201.2,
        "First Year": 1992,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2001,
        "MLY First Year": 1992,
        "MLY Last Year": 2001
      },
      {
        "Name": "MUSKOKA AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6115524",
        "Station ID": 30186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 445829000,
        "Longitude": -791812000,
        "Elevation (m)": 281.9,
        "First Year": 2000,
        "Last Year": 2013,
        "HLY First Year": 2000,
        "HLY Last Year": 2009,
        "DLY First Year": 2004,
        "DLY Last Year": 2013,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "MUSKOKA A",
        "Province": "ONTARIO",
        "Climate ID": "6115525",
        "Station ID": 4477,
        "WMO ID": 71630,
        "TC ID": "YQA",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 445800000,
        "Longitude": -791800000,
        "Elevation (m)": 281.9,
        "First Year": 1934,
        "Last Year": 2015,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1934,
        "DLY Last Year": 2015,
        "MLY First Year": 1934,
        "MLY Last Year": 2006
      },
      {
        "Name": "MUSKOKA SNOW",
        "Province": "ONTARIO",
        "Climate ID": "6115526",
        "Station ID": 54398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -79.05,
        "Latitude": 445900000,
        "Longitude": -790300000,
        "Elevation (m)": 290,
        "First Year": 2016,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2016,
        "DLY Last Year": 2017,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MUSKOKA",
        "Province": "ONTARIO",
        "Climate ID": "6115529",
        "Station ID": 48368,
        "WMO ID": 71532,
        "TC ID": "YQA",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 445829000,
        "Longitude": -791812000,
        "Elevation (m)": 281.9,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NIPISSING",
        "Province": "ONTARIO",
        "Climate ID": "6115668",
        "Station ID": 4478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -79.53,
        "Latitude": 460800000,
        "Longitude": -793200000,
        "Elevation (m)": null,
        "First Year": 1964,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1966,
        "MLY First Year": 1964,
        "MLY Last Year": 1966
      },
      {
        "Name": "NIPISSING",
        "Province": "ONTARIO",
        "Climate ID": "6115669",
        "Station ID": 45547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.1,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 460601032,
        "Longitude": -793114040,
        "Elevation (m)": 213.4,
        "First Year": 2006,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2018,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "NOTTAWASAGA ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6115718",
        "Station ID": 4479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.53,
        "Longitude (Decimal Degrees)": -80.25,
        "Latitude": 443200000,
        "Longitude": -801500000,
        "Elevation (m)": 179.8,
        "First Year": 1891,
        "Last Year": 1903,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1891,
        "DLY Last Year": 1903,
        "MLY First Year": 1891,
        "MLY Last Year": 1903
      },
      {
        "Name": "ORILLIA",
        "Province": "ONTARIO",
        "Climate ID": "6115810",
        "Station ID": 4480,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 443700000,
        "Longitude": -792400000,
        "Elevation (m)": 224,
        "First Year": 1871,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1961,
        "MLY First Year": 1871,
        "MLY Last Year": 1961
      },
      {
        "Name": "ORILLIA BRAIN",
        "Province": "ONTARIO",
        "Climate ID": "6115811",
        "Station ID": 6904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -79.44,
        "Latitude": 443610000,
        "Longitude": -792620000,
        "Elevation (m)": 250,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2006
      },
      {
        "Name": "ORILLIA STP",
        "Province": "ONTARIO",
        "Climate ID": "6115818",
        "Station ID": 4481,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 443500000,
        "Longitude": -792400000,
        "Elevation (m)": 224,
        "First Year": 1957,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1965,
        "MLY First Year": 1957,
        "MLY Last Year": 1965
      },
      {
        "Name": "ORILLIA TS",
        "Province": "ONTARIO",
        "Climate ID": "6115820",
        "Station ID": 4482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 443700000,
        "Longitude": -792500000,
        "Elevation (m)": 219.5,
        "First Year": 1965,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1993,
        "MLY First Year": 1965,
        "MLY Last Year": 1993
      },
      {
        "Name": "ORO",
        "Province": "ONTARIO",
        "Climate ID": "6115850",
        "Station ID": 4483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 443100000,
        "Longitude": -793500000,
        "Elevation (m)": 304.8,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "ORO WAM",
        "Province": "ONTARIO",
        "Climate ID": "6115856",
        "Station ID": 4484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 443100000,
        "Longitude": -792700000,
        "Elevation (m)": 221,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "OWEN SOUND",
        "Province": "ONTARIO",
        "Climate ID": "6116128",
        "Station ID": 4485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -80.92,
        "Latitude": 443400000,
        "Longitude": -805500000,
        "Elevation (m)": 182,
        "First Year": 1878,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1962,
        "MLY First Year": 1879,
        "MLY Last Year": 1962
      },
      {
        "Name": "OWEN SOUND MOE",
        "Province": "ONTARIO",
        "Climate ID": "6116132",
        "Station ID": 4486,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -80.93,
        "Latitude": 443500000,
        "Longitude": -805600000,
        "Elevation (m)": 178.9,
        "First Year": 1964,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2007,
        "MLY First Year": 1965,
        "MLY Last Year": 2006
      },
      {
        "Name": "PA UDORA STRONG",
        "Province": "ONTARIO",
        "Climate ID": "6116200",
        "Station ID": 52719,
        "WMO ID": null,
        "TC ID": "L3I",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -79.21,
        "Latitude": 441503600,
        "Longitude": -791218300,
        "Elevation (m)": 266.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA HARDWOOD MOUNTAIN BIKE PARK",
        "Province": "ONTARIO",
        "Climate ID": "6116201",
        "Station ID": 52729,
        "WMO ID": null,
        "TC ID": "Z3N",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -79.59,
        "Latitude": 443108900,
        "Longitude": -793524200,
        "Elevation (m)": 334.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PARRY SOUND",
        "Province": "ONTARIO",
        "Climate ID": "6116254",
        "Station ID": 4487,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -80,
        "Latitude": 452000000,
        "Longitude": -800000000,
        "Elevation (m)": 193.5,
        "First Year": 1874,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1976,
        "MLY First Year": 1874,
        "MLY Last Year": 1976
      },
      {
        "Name": "PARRY SOUND",
        "Province": "ONTARIO",
        "Climate ID": "6116255",
        "Station ID": 4488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 452100000,
        "Longitude": -800300000,
        "Elevation (m)": 198.4,
        "First Year": 1979,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1991,
        "MLY First Year": 1979,
        "MLY Last Year": 1991
      },
      {
        "Name": "PARRY SOUND 2",
        "Province": "ONTARIO",
        "Climate ID": "6116256",
        "Station ID": 27003,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -80.03,
        "Latitude": 452100000,
        "Longitude": -800200000,
        "Elevation (m)": 210,
        "First Year": 1994,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1996,
        "MLY First Year": 1994,
        "MLY Last Year": 1996
      },
      {
        "Name": "PARRY SOUND CCG",
        "Province": "ONTARIO",
        "Climate ID": "6116257",
        "Station ID": 32128,
        "WMO ID": 71172,
        "TC ID": "XPC",
        "Latitude (Decimal Degrees)": 45.34,
        "Longitude (Decimal Degrees)": -80.04,
        "Latitude": 452039000,
        "Longitude": -800239000,
        "Elevation (m)": 176.3,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "PARRY SOUND MARTYR IS",
        "Province": "ONTARIO",
        "Climate ID": "6116258",
        "Station ID": 4489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -80.25,
        "Latitude": 451600000,
        "Longitude": -801500000,
        "Elevation (m)": 179.8,
        "First Year": 1975,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1996,
        "MLY First Year": 1975,
        "MLY Last Year": 1996
      },
      {
        "Name": "PARRY SOUND ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6116260",
        "Station ID": 4490,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": null,
        "Longitude (Decimal Degrees)": null,
        "Latitude": null,
        "Longitude": null,
        "Elevation (m)": null,
        "First Year": 1964,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1968,
        "MLY First Year": 1964,
        "MLY Last Year": 1968
      },
      {
        "Name": "PARRY SOUND HARBOUR",
        "Province": "ONTARIO",
        "Climate ID": "6116262",
        "Station ID": 31047,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.34,
        "Longitude (Decimal Degrees)": -80.03,
        "Latitude": 452006090,
        "Longitude": -800135022,
        "Elevation (m)": 214,
        "First Year": 2001,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2013,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "PEFFERLAW",
        "Province": "ONTARIO",
        "Climate ID": "6116308",
        "Station ID": 4491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -79.22,
        "Latitude": 441900000,
        "Longitude": -791300000,
        "Elevation (m)": 228.6,
        "First Year": 1948,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1959,
        "MLY First Year": 1948,
        "MLY Last Year": 1959
      },
      {
        "Name": "PEFFERLAW",
        "Province": "ONTARIO",
        "Climate ID": "6116309",
        "Station ID": 4492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -79.22,
        "Latitude": 442000000,
        "Longitude": -791300000,
        "Elevation (m)": 219.5,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "PORT LAW",
        "Province": "ONTARIO",
        "Climate ID": "6116679",
        "Station ID": 27082,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -80.47,
        "Latitude": 441500000,
        "Longitude": -802800000,
        "Elevation (m)": 495.3,
        "First Year": 1995,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2014,
        "MLY First Year": 1995,
        "MLY Last Year": 2006
      },
      {
        "Name": "POWASSAN",
        "Province": "ONTARIO",
        "Climate ID": "6116702",
        "Station ID": 4493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 460700000,
        "Longitude": -791500000,
        "Elevation (m)": 274.3,
        "First Year": 1974,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2010,
        "MLY First Year": 1974,
        "MLY Last Year": 2006
      },
      {
        "Name": "POWASSAN VALLEYVIEW",
        "Province": "ONTARIO",
        "Climate ID": "6116703",
        "Station ID": 4494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.08,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 460500000,
        "Longitude": -792100000,
        "Elevation (m)": 274.3,
        "First Year": 1970,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1974,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "PRESQU'ILE",
        "Province": "ONTARIO",
        "Climate ID": "6116705",
        "Station ID": 4495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -80.92,
        "Latitude": 444200000,
        "Longitude": -805500000,
        "Elevation (m)": null,
        "First Year": 1875,
        "Last Year": 1898,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1875,
        "DLY Last Year": 1898,
        "MLY First Year": 1875,
        "MLY Last Year": 1898
      },
      {
        "Name": "PRICEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6116718",
        "Station ID": 4496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.2,
        "Longitude (Decimal Degrees)": -80.64,
        "Latitude": 441213002,
        "Longitude": -803816044,
        "Elevation (m)": 472.1,
        "First Year": 1980,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2014,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "PROTON STATION",
        "Province": "ONTARIO",
        "Climate ID": "6116750",
        "Station ID": 4497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -80.52,
        "Latitude": 441000000,
        "Longitude": -803100000,
        "Elevation (m)": 480.1,
        "First Year": 1969,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2002,
        "MLY First Year": 1969,
        "MLY Last Year": 2002
      },
      {
        "Name": "RAGGED RAPIDS",
        "Province": "ONTARIO",
        "Climate ID": "6116843",
        "Station ID": 4498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 450100000,
        "Longitude": -794100000,
        "Elevation (m)": 228.6,
        "First Year": 1950,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1971,
        "MLY First Year": 1950,
        "MLY Last Year": 1971
      },
      {
        "Name": "RAVENNA",
        "Province": "ONTARIO",
        "Climate ID": "6116899",
        "Station ID": 4499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.53,
        "Longitude (Decimal Degrees)": -80.42,
        "Latitude": 443200000,
        "Longitude": -802500000,
        "Elevation (m)": null,
        "First Year": 1948,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1953,
        "MLY First Year": 1948,
        "MLY Last Year": 1952
      },
      {
        "Name": "RAVENNA",
        "Province": "ONTARIO",
        "Climate ID": "6116900",
        "Station ID": 4500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -80.42,
        "Latitude": 442800000,
        "Longitude": -802500000,
        "Elevation (m)": 396.2,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "RAVENSCLIFFE",
        "Province": "ONTARIO",
        "Climate ID": "6116901",
        "Station ID": 45348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 452114094,
        "Longitude": -791613020,
        "Elevation (m)": 305,
        "First Year": 2006,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2016,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "RAVENSHOE",
        "Province": "ONTARIO",
        "Climate ID": "6116902",
        "Station ID": 4501,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 441300000,
        "Longitude": -792400000,
        "Elevation (m)": 250.9,
        "First Year": 1970,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1992,
        "MLY First Year": 1970,
        "MLY Last Year": 1992
      },
      {
        "Name": "ROBERTS LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6117167",
        "Station ID": 4503,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -79.82,
        "Latitude": 451400000,
        "Longitude": -794900000,
        "Elevation (m)": 243.8,
        "First Year": 1971,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1976,
        "MLY First Year": 1971,
        "MLY Last Year": 1976
      },
      {
        "Name": "ROCKLYN",
        "Province": "ONTARIO",
        "Climate ID": "6117169",
        "Station ID": 4504,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -80.63,
        "Latitude": 442800000,
        "Longitude": -803800000,
        "Elevation (m)": 433,
        "First Year": 1989,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2004,
        "MLY First Year": 1989,
        "MLY Last Year": 2004
      },
      {
        "Name": "RONVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6117186",
        "Station ID": 4505,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -78.95,
        "Latitude": 451500000,
        "Longitude": -785700000,
        "Elevation (m)": 316.4,
        "First Year": 1908,
        "Last Year": 1926,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1926,
        "MLY First Year": 1908,
        "MLY Last Year": 1926
      },
      {
        "Name": "ROSSEAU LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6117189",
        "Station ID": 4506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 451500000,
        "Longitude": -793800000,
        "Elevation (m)": 243.8,
        "First Year": 1968,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1969,
        "MLY First Year": 1969,
        "MLY Last Year": 1969
      },
      {
        "Name": "SCOTIA",
        "Province": "ONTARIO",
        "Climate ID": "6117663",
        "Station ID": 4507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 453100000,
        "Longitude": -791800000,
        "Elevation (m)": 390.4,
        "First Year": 1924,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1978,
        "MLY First Year": 1924,
        "MLY Last Year": 1978
      },
      {
        "Name": "SEVERN BRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6117682",
        "Station ID": 4508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 444600000,
        "Longitude": -792000000,
        "Elevation (m)": 222.5,
        "First Year": 1975,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1995,
        "MLY First Year": 1975,
        "MLY Last Year": 1995
      },
      {
        "Name": "SHANTY BAY",
        "Province": "ONTARIO",
        "Climate ID": "6117684",
        "Station ID": 4509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 442358050,
        "Longitude": -793758074,
        "Elevation (m)": 250,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "BARRIE-ORO",
        "Province": "ONTARIO",
        "Climate ID": "6117700",
        "Station ID": 42183,
        "WMO ID": 71314,
        "TC ID": "XBI",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 442900000,
        "Longitude": -793300000,
        "Elevation (m)": 289,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "SINGHAMPTON",
        "Province": "ONTARIO",
        "Climate ID": "6117750",
        "Station ID": 4510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -80.25,
        "Latitude": 442100000,
        "Longitude": -801500000,
        "Elevation (m)": 497,
        "First Year": 1985,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1988,
        "MLY First Year": 1985,
        "MLY Last Year": 1988
      },
      {
        "Name": "SOUTH FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6117943",
        "Station ID": 4511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 450000000,
        "Longitude": -791800000,
        "Elevation (m)": 231,
        "First Year": 1920,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1971,
        "MLY First Year": 1920,
        "MLY Last Year": 1971
      },
      {
        "Name": "SOUTH RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6117957",
        "Station ID": 4512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 455000000,
        "Longitude": -792300000,
        "Elevation (m)": 396.2,
        "First Year": 1977,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1995,
        "MLY First Year": 1977,
        "MLY Last Year": 1995
      },
      {
        "Name": "SPRUCEDALE",
        "Province": "ONTARIO",
        "Climate ID": "6117981",
        "Station ID": 44303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -79.49,
        "Latitude": 452530000,
        "Longitude": -792915000,
        "Elevation (m)": 337.1,
        "First Year": 2003,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2021,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "STAYNER",
        "Province": "ONTARIO",
        "Climate ID": "6117998",
        "Station ID": 4513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.37,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 442200000,
        "Longitude": -800500000,
        "Elevation (m)": 292.6,
        "First Year": 1969,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "STAYNER",
        "Province": "ONTARIO",
        "Climate ID": "6117999",
        "Station ID": 4514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -80.1,
        "Latitude": 442800000,
        "Longitude": -800600000,
        "Elevation (m)": 217.6,
        "First Year": 1948,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1957,
        "MLY First Year": 1948,
        "MLY Last Year": 1957
      },
      {
        "Name": "STAYNER MARTYN",
        "Province": "ONTARIO",
        "Climate ID": "6117R99",
        "Station ID": 4502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 442800000,
        "Longitude": -800500000,
        "Elevation (m)": 192,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "STAYNER 2",
        "Province": "ONTARIO",
        "Climate ID": "6118000",
        "Station ID": 4515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.43,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 442600000,
        "Longitude": -800500000,
        "Elevation (m)": 198.1,
        "First Year": 1955,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1959,
        "MLY First Year": 1955,
        "MLY Last Year": 1959
      },
      {
        "Name": "STROUD LEONARDS BEACH",
        "Province": "ONTARIO",
        "Climate ID": "6118142",
        "Station ID": 4516,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -79.53,
        "Latitude": 442000000,
        "Longitude": -793200000,
        "Elevation (m)": 221,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "SUTTON WEST",
        "Province": "ONTARIO",
        "Climate ID": "6118187",
        "Station ID": 4517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 441900000,
        "Longitude": -791800000,
        "Elevation (m)": 137.2,
        "First Year": 1871,
        "Last Year": 1908,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1908,
        "MLY First Year": 1871,
        "MLY Last Year": 1908
      },
      {
        "Name": "THORNBURY 3",
        "Province": "ONTARIO",
        "Climate ID": "6118240",
        "Station ID": 44987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -80.47,
        "Latitude": 443304014,
        "Longitude": -802803060,
        "Elevation (m)": 212,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "THORNBURY MATTS MOTEL",
        "Province": "ONTARIO",
        "Climate ID": "6118252",
        "Station ID": 4518,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 443300000,
        "Longitude": -802700000,
        "Elevation (m)": 190.5,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "THORNBURY ORCHARD",
        "Province": "ONTARIO",
        "Climate ID": "6118253",
        "Station ID": 4519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -80.48,
        "Latitude": 443400000,
        "Longitude": -802900000,
        "Elevation (m)": 201.2,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "THORNTON",
        "Province": "ONTARIO",
        "Climate ID": "6118258",
        "Station ID": 4520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.28,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 441700000,
        "Longitude": -794100000,
        "Elevation (m)": 300.2,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "TOTTENHAM",
        "Province": "ONTARIO",
        "Climate ID": "6118848",
        "Station ID": 4521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -79.82,
        "Latitude": 440200000,
        "Longitude": -794900000,
        "Elevation (m)": 250.9,
        "First Year": 1971,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1979,
        "MLY First Year": 1971,
        "MLY Last Year": 1979
      },
      {
        "Name": "TOTTENHAM PEARSON",
        "Province": "ONTARIO",
        "Climate ID": "6118849",
        "Station ID": 4522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -79.8,
        "Latitude": 440100000,
        "Longitude": -794800000,
        "Elevation (m)": 259.1,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "TRETHEWEY FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6118920",
        "Station ID": 4523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -79.28,
        "Latitude": 445900000,
        "Longitude": -791700000,
        "Elevation (m)": 277.4,
        "First Year": 1950,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1956,
        "MLY First Year": 1950,
        "MLY Last Year": 1956
      },
      {
        "Name": "TROUT CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6118938",
        "Station ID": 4524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 460200000,
        "Longitude": -792300000,
        "Elevation (m)": 333.1,
        "First Year": 1981,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2006,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "UDORA",
        "Province": "ONTARIO",
        "Climate ID": "6119055",
        "Station ID": 4525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.26,
        "Longitude (Decimal Degrees)": -79.16,
        "Latitude": 441545000,
        "Longitude": -790941004,
        "Elevation (m)": 262,
        "First Year": 1989,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2022,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "UPLANDS",
        "Province": "ONTARIO",
        "Climate ID": "6119064",
        "Station ID": 4526,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 454800000,
        "Longitude": -792500000,
        "Elevation (m)": null,
        "First Year": 1886,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1913,
        "MLY First Year": 1886,
        "MLY Last Year": 1913
      },
      {
        "Name": "UTTERSON ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6119115",
        "Station ID": 4528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 451200000,
        "Longitude": -792100000,
        "Elevation (m)": 297.2,
        "First Year": 1971,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1983,
        "MLY First Year": 1971,
        "MLY Last Year": 1983
      },
      {
        "Name": "VALLENTYNE",
        "Province": "ONTARIO",
        "Climate ID": "6119129",
        "Station ID": 4529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 441600000,
        "Longitude": -791000000,
        "Elevation (m)": 277.4,
        "First Year": 1983,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1989,
        "MLY First Year": 1983,
        "MLY Last Year": 1989
      },
      {
        "Name": "VANKOUGHNET",
        "Province": "ONTARIO",
        "Climate ID": "61191LK",
        "Station ID": 4527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -79,
        "Latitude": 450200000,
        "Longitude": -790000000,
        "Elevation (m)": 304.8,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "WALTERS FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6119274",
        "Station ID": 4530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -80.73,
        "Latitude": 443100000,
        "Longitude": -804400000,
        "Elevation (m)": 304.8,
        "First Year": 1983,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2006,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "WASAGA BEACH",
        "Province": "ONTARIO",
        "Climate ID": "6119293",
        "Station ID": 4531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 443000000,
        "Longitude": -800300000,
        "Elevation (m)": 179.8,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "WASAGA BEACH",
        "Province": "ONTARIO",
        "Climate ID": "6119294",
        "Station ID": 4532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 442900000,
        "Longitude": -800300000,
        "Elevation (m)": 182.9,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "WASDELLS",
        "Province": "ONTARIO",
        "Climate ID": "6119295",
        "Station ID": 4533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.78,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 444700000,
        "Longitude": -791800000,
        "Elevation (m)": 217.6,
        "First Year": 1950,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1957,
        "MLY First Year": 1950,
        "MLY Last Year": 1957
      },
      {
        "Name": "WASHAGO",
        "Province": "ONTARIO",
        "Climate ID": "6119325",
        "Station ID": 4534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 444500000,
        "Longitude": -792000000,
        "Elevation (m)": 221.6,
        "First Year": 1927,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1970,
        "MLY First Year": 1927,
        "MLY Last Year": 1970
      },
      {
        "Name": "WAUBAUSHENE",
        "Province": "ONTARIO",
        "Climate ID": "6119399",
        "Station ID": 4535,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 444600000,
        "Longitude": -794200000,
        "Elevation (m)": 181.4,
        "First Year": 1936,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1956,
        "MLY First Year": 1936,
        "MLY Last Year": 1956
      },
      {
        "Name": "WESTERN ISLANDS",
        "Province": "ONTARIO",
        "Climate ID": "6119452",
        "Station ID": 4536,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -80.37,
        "Latitude": 450200000,
        "Longitude": -802200000,
        "Elevation (m)": 183.5,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "WIARTON",
        "Province": "ONTARIO",
        "Climate ID": "6119494",
        "Station ID": 10787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -81.11,
        "Latitude": 444445000,
        "Longitude": -810626000,
        "Elevation (m)": 222.5,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WIARTON",
        "Province": "ONTARIO",
        "Climate ID": "6119495",
        "Station ID": 4537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -81.13,
        "Latitude": 444500000,
        "Longitude": -810800000,
        "Elevation (m)": 182,
        "First Year": 1883,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1936,
        "MLY First Year": 1883,
        "MLY Last Year": 1936
      },
      {
        "Name": "WIARTON A",
        "Province": "ONTARIO",
        "Climate ID": "6119498",
        "Station ID": 54259,
        "WMO ID": null,
        "TC ID": "YVV",
        "Latitude (Decimal Degrees)": 44.74,
        "Longitude (Decimal Degrees)": -81.11,
        "Latitude": 444439000,
        "Longitude": -810631000,
        "Elevation (m)": 221.9,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WIARTON A",
        "Province": "ONTARIO",
        "Climate ID": "6119499",
        "Station ID": 53139,
        "WMO ID": 71633,
        "TC ID": "YVV",
        "Latitude (Decimal Degrees)": 44.74,
        "Longitude (Decimal Degrees)": -81.11,
        "Latitude": 444439000,
        "Longitude": -810631000,
        "Elevation (m)": 221.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WIARTON A",
        "Province": "ONTARIO",
        "Climate ID": "6119500",
        "Station ID": 4538,
        "WMO ID": 71633,
        "TC ID": "YVV",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -81.11,
        "Latitude": 444445000,
        "Longitude": -810626000,
        "Elevation (m)": 222.2,
        "First Year": 1947,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1947,
        "DLY Last Year": 2014,
        "MLY First Year": 1947,
        "MLY Last Year": 2014
      },
      {
        "Name": "WILDER LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6119511",
        "Station ID": 29391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.16,
        "Longitude (Decimal Degrees)": -80.76,
        "Latitude": 440919026,
        "Longitude": -804534026,
        "Elevation (m)": 390,
        "First Year": 1999,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2009,
        "MLY First Year": 1999,
        "MLY Last Year": 2006
      },
      {
        "Name": "WYEVALE",
        "Province": "ONTARIO",
        "Climate ID": "6119668",
        "Station ID": 4539,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -79.92,
        "Latitude": 443900000,
        "Longitude": -795500000,
        "Elevation (m)": 690.1,
        "First Year": 1978,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1979,
        "MLY First Year": 1978,
        "MLY Last Year": 1979
      },
      {
        "Name": "WASAGA BEACH",
        "Province": "ONTARIO",
        "Climate ID": "6119K9K",
        "Station ID": 10088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 443115000,
        "Longitude": -800115000,
        "Elevation (m)": 182,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": 1988,
        "HLY Last Year": 1991,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WESTERN ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6119M51",
        "Station ID": 7733,
        "WMO ID": 71438,
        "TC ID": "WMZ",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -80.37,
        "Latitude": 450200000,
        "Longitude": -802200000,
        "Elevation (m)": 191.1,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BORDEN",
        "Province": "ONTARIO",
        "Climate ID": "611B001",
        "Station ID": 27197,
        "WMO ID": null,
        "TC ID": "YBN",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -79.92,
        "Latitude": 441556000,
        "Longitude": -795510000,
        "Elevation (m)": 222.5,
        "First Year": 1996,
        "Last Year": 2013,
        "HLY First Year": 1996,
        "HLY Last Year": 2013,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BORDEN AWOS",
        "Province": "ONTARIO",
        "Climate ID": "611B002",
        "Station ID": 29516,
        "WMO ID": 71534,
        "TC ID": "YBN",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -79.91,
        "Latitude": 441620000,
        "Longitude": -795442000,
        "Elevation (m)": 222.5,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EGBERT (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "611BKE0",
        "Station ID": 10753,
        "WMO ID": null,
        "TC ID": "WET",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 441400000,
        "Longitude": -794700000,
        "Elevation (m)": 251,
        "First Year": 1990,
        "Last Year": 1993,
        "HLY First Year": 1990,
        "HLY Last Year": 1993,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRAVENHURST BOOTH",
        "Province": "ONTARIO",
        "Climate ID": "611C001",
        "Station ID": 4394,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 445500000,
        "Longitude": -792400000,
        "Elevation (m)": 243.8,
        "First Year": 1980,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1994,
        "MLY First Year": 1980,
        "MLY Last Year": 1994
      },
      {
        "Name": "MAPLE GROVE",
        "Province": "ONTARIO",
        "Climate ID": "611DR50",
        "Station ID": 4395,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -80.37,
        "Latitude": 442400000,
        "Longitude": -802200000,
        "Elevation (m)": 495.3,
        "First Year": 1985,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1991,
        "MLY First Year": 1985,
        "MLY Last Year": 1991
      },
      {
        "Name": "EGBERT CS",
        "Province": "ONTARIO",
        "Climate ID": "6.11E+03",
        "Station ID": 27604,
        "WMO ID": 71296,
        "TC ID": "XET",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 441400000,
        "Longitude": -794700000,
        "Elevation (m)": 251,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "GLOUCESTER POOL",
        "Province": "ONTARIO",
        "Climate ID": "611G001",
        "Station ID": 27335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.82,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 444900000,
        "Longitude": -794100000,
        "Elevation (m)": 191.6,
        "First Year": 1997,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2001,
        "MLY First Year": 1997,
        "MLY Last Year": 2001
      },
      {
        "Name": "THORNBURY SLAMA",
        "Province": "ONTARIO",
        "Climate ID": "611HBEC",
        "Station ID": 4396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -80.49,
        "Latitude": 443425032,
        "Longitude": -802907068,
        "Elevation (m)": 213.4,
        "First Year": 1968,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2005,
        "MLY First Year": 1968,
        "MLY Last Year": 2005
      },
      {
        "Name": "GAMEBRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "611K661",
        "Station ID": 4398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -79.1,
        "Latitude": 443100000,
        "Longitude": -790600000,
        "Elevation (m)": 236,
        "First Year": 1989,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1993,
        "MLY First Year": 1989,
        "MLY Last Year": 1993
      },
      {
        "Name": "EGBERT CARE",
        "Province": "ONTARIO",
        "Climate ID": "611KBE0",
        "Station ID": 4397,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 441400000,
        "Longitude": -794700000,
        "Elevation (m)": 252,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2007,
        "MLY First Year": 1988,
        "MLY Last Year": 2006
      },
      {
        "Name": "GRAVENHURST BEAVER CREEK",
        "Province": "ONTARIO",
        "Climate ID": "611S002",
        "Station ID": 27087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 445800000,
        "Longitude": -791800000,
        "Elevation (m)": 273,
        "First Year": 1995,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2001,
        "MLY First Year": 1995,
        "MLY Last Year": 2001
      },
      {
        "Name": "ALVINSTON",
        "Province": "ONTARIO",
        "Climate ID": "6120250",
        "Station ID": 4541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.87,
        "Longitude (Decimal Degrees)": -81.88,
        "Latitude": 425200000,
        "Longitude": -815300000,
        "Elevation (m)": 221,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "ARKONA",
        "Province": "ONTARIO",
        "Climate ID": "6120315",
        "Station ID": 4542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -81.92,
        "Latitude": 430200000,
        "Longitude": -815500000,
        "Elevation (m)": 182.9,
        "First Year": 1882,
        "Last Year": 1915,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1915,
        "MLY First Year": 1882,
        "MLY Last Year": 1915
      },
      {
        "Name": "BELGRAVE",
        "Province": "ONTARIO",
        "Climate ID": "6120670",
        "Station ID": 4543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -81.38,
        "Latitude": 434800000,
        "Longitude": -812300000,
        "Elevation (m)": 335.3,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "BLENHEIM",
        "Province": "ONTARIO",
        "Climate ID": "6120795",
        "Station ID": 4544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -82.02,
        "Latitude": 424000000,
        "Longitude": -820100000,
        "Elevation (m)": 205.1,
        "First Year": 1883,
        "Last Year": 1897,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1897,
        "MLY First Year": 1883,
        "MLY Last Year": 1897
      },
      {
        "Name": "BLYTH",
        "Province": "ONTARIO",
        "Climate ID": "6120819",
        "Station ID": 4545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -81.38,
        "Latitude": 434306024,
        "Longitude": -812301080,
        "Elevation (m)": 350.5,
        "First Year": 1959,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2010,
        "MLY First Year": 1959,
        "MLY Last Year": 2006
      },
      {
        "Name": "BRUCEFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6121025",
        "Station ID": 4547,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -81.55,
        "Latitude": 433300000,
        "Longitude": -813300000,
        "Elevation (m)": 259.1,
        "First Year": 1903,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1993,
        "MLY First Year": 1903,
        "MLY Last Year": 1993
      },
      {
        "Name": "BRUSSELS",
        "Province": "ONTARIO",
        "Climate ID": "6121030",
        "Station ID": 4548,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -81.23,
        "Latitude": 434400000,
        "Longitude": -811400000,
        "Elevation (m)": 339.9,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "CAIRO",
        "Province": "ONTARIO",
        "Climate ID": "6121067",
        "Station ID": 4549,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.7,
        "Longitude (Decimal Degrees)": -81.9,
        "Latitude": 424200000,
        "Longitude": -815400000,
        "Elevation (m)": 198.1,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "BRUCE ONTARIO HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "61210K7",
        "Station ID": 4546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -81.58,
        "Latitude": 442000000,
        "Longitude": -813500000,
        "Elevation (m)": 179.8,
        "First Year": 1971,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1977,
        "MLY First Year": 1971,
        "MLY Last Year": 1977
      },
      {
        "Name": "CAMLACHIE",
        "Province": "ONTARIO",
        "Climate ID": "6121123",
        "Station ID": 4550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -82.18,
        "Latitude": 430200000,
        "Longitude": -821100000,
        "Elevation (m)": 192,
        "First Year": 1959,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1968,
        "MLY First Year": 1959,
        "MLY Last Year": 1968
      },
      {
        "Name": "CENTRALIA A",
        "Province": "ONTARIO",
        "Climate ID": "6121275",
        "Station ID": 4551,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.3,
        "Longitude (Decimal Degrees)": -81.52,
        "Latitude": 431800000,
        "Longitude": -813100000,
        "Elevation (m)": 253,
        "First Year": 1945,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1966,
        "MLY First Year": 1945,
        "MLY Last Year": 1966
      },
      {
        "Name": "CHESLEY",
        "Province": "ONTARIO",
        "Climate ID": "6121499",
        "Station ID": 4552,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.28,
        "Longitude (Decimal Degrees)": -81.13,
        "Latitude": 441700000,
        "Longitude": -810800000,
        "Elevation (m)": 251.5,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "CLIFFORD",
        "Province": "ONTARIO",
        "Climate ID": "6121603",
        "Station ID": 4553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -81.12,
        "Latitude": 435800000,
        "Longitude": -810700000,
        "Elevation (m)": 365.8,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "CLINTON",
        "Province": "ONTARIO",
        "Climate ID": "6121630",
        "Station ID": 4554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.6,
        "Longitude (Decimal Degrees)": -81.53,
        "Latitude": 433600000,
        "Longitude": -813200000,
        "Elevation (m)": 274.3,
        "First Year": 1956,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1966,
        "MLY First Year": 1956,
        "MLY Last Year": 1966
      },
      {
        "Name": "COVE ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6121912",
        "Station ID": 4556,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -81.73,
        "Latitude": 452000000,
        "Longitude": -814400000,
        "Elevation (m)": 179.8,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "CYPRUS LAKE CS",
        "Province": "ONTARIO",
        "Climate ID": "6121940",
        "Station ID": 27531,
        "WMO ID": null,
        "TC ID": "XCY",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -81.53,
        "Latitude": 451400000,
        "Longitude": -813200000,
        "Elevation (m)": 190,
        "First Year": 1995,
        "Last Year": 2009,
        "HLY First Year": 2000,
        "HLY Last Year": 2009,
        "DLY First Year": 1995,
        "DLY Last Year": 2009,
        "MLY First Year": 1995,
        "MLY Last Year": 2006
      },
      {
        "Name": "DASHWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6121969",
        "Station ID": 4557,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.37,
        "Longitude (Decimal Degrees)": -81.62,
        "Latitude": 432200000,
        "Longitude": -813700000,
        "Elevation (m)": 253,
        "First Year": 1976,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2000,
        "MLY First Year": 1976,
        "MLY Last Year": 2000
      },
      {
        "Name": "COURTRIGHT",
        "Province": "ONTARIO",
        "Climate ID": "61219J1",
        "Station ID": 4555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.76,
        "Longitude (Decimal Degrees)": -82.45,
        "Latitude": 424550016,
        "Longitude": -822706054,
        "Elevation (m)": 185.9,
        "First Year": 1969,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1997,
        "MLY First Year": 1969,
        "MLY Last Year": 1997
      },
      {
        "Name": "COVE ISLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "61219J2",
        "Station ID": 9004,
        "WMO ID": 71439,
        "TC ID": "WWX",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -81.73,
        "Latitude": 452000000,
        "Longitude": -814400000,
        "Elevation (m)": 180.7,
        "First Year": 1994,
        "Last Year": 2021,
        "HLY First Year": 1994,
        "HLY Last Year": 2021,
        "DLY First Year": 1994,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DOUGLAS POINT",
        "Province": "ONTARIO",
        "Climate ID": "6122078",
        "Station ID": 4558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -81.6,
        "Latitude": 442000000,
        "Longitude": -813600000,
        "Elevation (m)": 179.8,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "DOUGLAS POINT",
        "Province": "ONTARIO",
        "Climate ID": "6122079",
        "Station ID": 4559,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -81.6,
        "Latitude": 442000000,
        "Longitude": -813600000,
        "Elevation (m)": 179.8,
        "First Year": 1965,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1971,
        "MLY First Year": 1965,
        "MLY Last Year": 1971
      },
      {
        "Name": "EXETER",
        "Province": "ONTARIO",
        "Climate ID": "6122370",
        "Station ID": 4560,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -81.5,
        "Latitude": 432100000,
        "Longitude": -813000000,
        "Elevation (m)": 262.1,
        "First Year": 1961,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2008,
        "MLY First Year": 1961,
        "MLY Last Year": 2006
      },
      {
        "Name": "FERNDALE",
        "Province": "ONTARIO",
        "Climate ID": "6122405",
        "Station ID": 4561,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -81.3,
        "Latitude": 445800000,
        "Longitude": -811800000,
        "Elevation (m)": 186.8,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "FOREST",
        "Province": "ONTARIO",
        "Climate ID": "6122450",
        "Station ID": 4562,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -82.03,
        "Latitude": 431000000,
        "Longitude": -820200000,
        "Elevation (m)": 191,
        "First Year": 1924,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1991,
        "MLY First Year": 1924,
        "MLY Last Year": 1991
      },
      {
        "Name": "FOREST MCNABB",
        "Province": "ONTARIO",
        "Climate ID": "6122452",
        "Station ID": 4563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.1,
        "Longitude (Decimal Degrees)": -82,
        "Latitude": 430600000,
        "Longitude": -820000000,
        "Elevation (m)": 218.2,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "FORMOSA",
        "Province": "ONTARIO",
        "Climate ID": "6122456",
        "Station ID": 4564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -81.28,
        "Latitude": 440400000,
        "Longitude": -811700000,
        "Elevation (m)": 304.8,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "GODERICH",
        "Province": "ONTARIO",
        "Climate ID": "6122845",
        "Station ID": 4565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -81.7,
        "Latitude": 434500000,
        "Longitude": -814200000,
        "Elevation (m)": 219.5,
        "First Year": 1866,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 1950,
        "MLY First Year": 1866,
        "MLY Last Year": 1950
      },
      {
        "Name": "GODERICH",
        "Province": "ONTARIO",
        "Climate ID": "6122847",
        "Station ID": 7747,
        "WMO ID": 71261,
        "TC ID": "WGD",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -81.72,
        "Latitude": 434600000,
        "Longitude": -814300000,
        "Elevation (m)": 213.7,
        "First Year": 1986,
        "Last Year": 2022,
        "HLY First Year": 1986,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "GODERICH LIGHTHOUSE",
        "Province": "ONTARIO",
        "Climate ID": "6122848",
        "Station ID": 4566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -81.72,
        "Latitude": 434500000,
        "Longitude": -814300000,
        "Elevation (m)": null,
        "First Year": 1874,
        "Last Year": 1911,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1911,
        "MLY First Year": 1874,
        "MLY Last Year": 1911
      },
      {
        "Name": "GODERICH MUNICIPAL A",
        "Province": "ONTARIO",
        "Climate ID": "6122849",
        "Station ID": 4567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -81.7,
        "Latitude": 434600000,
        "Longitude": -814200000,
        "Elevation (m)": 213.4,
        "First Year": 1969,
        "Last Year": 1980,
        "HLY First Year": 1969,
        "HLY Last Year": 1980,
        "DLY First Year": 1969,
        "DLY Last Year": 1980,
        "MLY First Year": 1969,
        "MLY Last Year": 1980
      },
      {
        "Name": "GODERICH TOWNSHIP",
        "Province": "ONTARIO",
        "Climate ID": "6122851",
        "Station ID": 4568,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -81.7,
        "Latitude": 434300000,
        "Longitude": -814200000,
        "Elevation (m)": 221,
        "First Year": 1912,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1970,
        "MLY First Year": 1912,
        "MLY Last Year": 1970
      },
      {
        "Name": "GRAND BEND",
        "Province": "ONTARIO",
        "Climate ID": "6122985",
        "Station ID": 4573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.32,
        "Longitude (Decimal Degrees)": -81.75,
        "Latitude": 431900000,
        "Longitude": -814500000,
        "Elevation (m)": 207.3,
        "First Year": 1961,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1961,
        "MLY First Year": 1961,
        "MLY Last Year": 1961
      },
      {
        "Name": "GRAND BEND RES STN 1",
        "Province": "ONTARIO",
        "Climate ID": "612298H",
        "Station ID": 4571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -81.72,
        "Latitude": 432000000,
        "Longitude": -814300000,
        "Elevation (m)": 187.5,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "GRAND BEND RES STN 2",
        "Province": "ONTARIO",
        "Climate ID": "612298Q",
        "Station ID": 4572,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -81.65,
        "Latitude": 432000000,
        "Longitude": -813900000,
        "Elevation (m)": 210.3,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "GRAND BEND RES STN 3",
        "Province": "ONTARIO",
        "Climate ID": "61229H8",
        "Station ID": 4570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -81.6,
        "Latitude": 432000000,
        "Longitude": -813600000,
        "Elevation (m)": 246.9,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "GRAND BEND RES STN 4",
        "Province": "ONTARIO",
        "Climate ID": "61229HH",
        "Station ID": 4569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.3,
        "Longitude (Decimal Degrees)": -81.57,
        "Latitude": 431800000,
        "Longitude": -813400000,
        "Elevation (m)": 245.4,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "HURON PARK",
        "Province": "ONTARIO",
        "Climate ID": "6123672",
        "Station ID": 4574,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.32,
        "Longitude (Decimal Degrees)": -81.5,
        "Latitude": 431900000,
        "Longitude": -813000000,
        "Elevation (m)": 253,
        "First Year": 1979,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1995,
        "MLY First Year": 1979,
        "MLY Last Year": 1995
      },
      {
        "Name": "NAIRN",
        "Province": "ONTARIO",
        "Climate ID": "6124123",
        "Station ID": 26891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.09,
        "Longitude (Decimal Degrees)": -81.57,
        "Latitude": 430538094,
        "Longitude": -813424018,
        "Elevation (m)": 233,
        "First Year": 1994,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2011,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "KINCARDINE",
        "Province": "ONTARIO",
        "Climate ID": "6124127",
        "Station ID": 4575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -81.62,
        "Latitude": 441022014,
        "Longitude": -813724042,
        "Elevation (m)": 200,
        "First Year": 1870,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 2022,
        "MLY First Year": 1870,
        "MLY Last Year": 2006
      },
      {
        "Name": "KINCARDINE A",
        "Province": "ONTARIO",
        "Climate ID": "6124128",
        "Station ID": 27002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.2,
        "Longitude (Decimal Degrees)": -81.6,
        "Latitude": 441200000,
        "Longitude": -813600000,
        "Elevation (m)": 234.2,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1997,
        "MLY First Year": 1994,
        "MLY Last Year": 1997
      },
      {
        "Name": "LUCKNOW",
        "Province": "ONTARIO",
        "Climate ID": "6124700",
        "Station ID": 4576,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -81.5,
        "Latitude": 435700000,
        "Longitude": -813000000,
        "Elevation (m)": 289.6,
        "First Year": 1885,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1993,
        "MLY First Year": 1885,
        "MLY Last Year": 1993
      },
      {
        "Name": "MILLER LAKE FOREST",
        "Province": "ONTARIO",
        "Climate ID": "6125155",
        "Station ID": 4577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -81.52,
        "Latitude": 450600000,
        "Longitude": -813100000,
        "Elevation (m)": 198.1,
        "First Year": 1952,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1971,
        "MLY First Year": 1952,
        "MLY Last Year": 1971
      },
      {
        "Name": "NEWBURY",
        "Province": "ONTARIO",
        "Climate ID": "6125582",
        "Station ID": 4578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.72,
        "Longitude (Decimal Degrees)": -81.85,
        "Latitude": 424300000,
        "Longitude": -815100000,
        "Elevation (m)": 205.7,
        "First Year": 1980,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1987,
        "MLY First Year": 1980,
        "MLY Last Year": 1987
      },
      {
        "Name": "NORTH BRUCE",
        "Province": "ONTARIO",
        "Climate ID": "6125705",
        "Station ID": 4579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -81.42,
        "Latitude": 442300000,
        "Longitude": -812500000,
        "Elevation (m)": 213.4,
        "First Year": 1888,
        "Last Year": 1922,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1922,
        "MLY First Year": 1888,
        "MLY Last Year": 1922
      },
      {
        "Name": "OIL CITY",
        "Province": "ONTARIO",
        "Climate ID": "6125766",
        "Station ID": 4580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.92,
        "Longitude (Decimal Degrees)": -82.03,
        "Latitude": 425500000,
        "Longitude": -820200000,
        "Elevation (m)": 207.9,
        "First Year": 1953,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1959,
        "MLY First Year": 1953,
        "MLY Last Year": 1959
      },
      {
        "Name": "OIL SPRINGS",
        "Province": "ONTARIO",
        "Climate ID": "6125770",
        "Station ID": 4581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.78,
        "Longitude (Decimal Degrees)": -82.12,
        "Latitude": 424700000,
        "Longitude": -820700000,
        "Elevation (m)": 207.3,
        "First Year": 1960,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1967,
        "MLY First Year": 1960,
        "MLY Last Year": 1967
      },
      {
        "Name": "OLIPHANT",
        "Province": "ONTARIO",
        "Climate ID": "6125GP0",
        "Station ID": 10087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.72,
        "Longitude (Decimal Degrees)": -81.28,
        "Latitude": 444300000,
        "Longitude": -811630000,
        "Elevation (m)": 181.8,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": 1994,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PAISLEY",
        "Province": "ONTARIO",
        "Climate ID": "6126210",
        "Station ID": 4582,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -81.37,
        "Latitude": 441600000,
        "Longitude": -812200000,
        "Elevation (m)": 244.1,
        "First Year": 1961,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1992,
        "MLY First Year": 1961,
        "MLY Last Year": 1992
      },
      {
        "Name": "PETROLIA",
        "Province": "ONTARIO",
        "Climate ID": "6126497",
        "Station ID": 4583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.9,
        "Longitude (Decimal Degrees)": -82.17,
        "Latitude": 425400000,
        "Longitude": -821000000,
        "Elevation (m)": 201.2,
        "First Year": 1953,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1964,
        "MLY First Year": 1953,
        "MLY Last Year": 1964
      },
      {
        "Name": "PETROLIA ROKEBY",
        "Province": "ONTARIO",
        "Climate ID": "6126498",
        "Station ID": 44063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.86,
        "Longitude (Decimal Degrees)": -82.05,
        "Latitude": 425123070,
        "Longitude": -820255026,
        "Elevation (m)": 208,
        "First Year": 2005,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2014,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETROLIA TOWN",
        "Province": "ONTARIO",
        "Climate ID": "6126499",
        "Station ID": 4584,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -82.17,
        "Latitude": 425300000,
        "Longitude": -821000000,
        "Elevation (m)": 201.2,
        "First Year": 1960,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2005,
        "MLY First Year": 1960,
        "MLY Last Year": 2005
      },
      {
        "Name": "PINERY",
        "Province": "ONTARIO",
        "Climate ID": "6126552",
        "Station ID": 4585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -81.85,
        "Latitude": 431500000,
        "Longitude": -815100000,
        "Elevation (m)": 182.9,
        "First Year": 1979,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1984,
        "MLY First Year": 1979,
        "MLY Last Year": 1984
      },
      {
        "Name": "POINT CLARK",
        "Province": "ONTARIO",
        "Climate ID": "6126558",
        "Station ID": 4586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -81.68,
        "Latitude": 440500000,
        "Longitude": -814100000,
        "Elevation (m)": 181.4,
        "First Year": 1871,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1913,
        "MLY First Year": 1871,
        "MLY Last Year": 1913
      },
      {
        "Name": "PORT ELGIN",
        "Province": "ONTARIO",
        "Climate ID": "6126654",
        "Station ID": 6905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -81.37,
        "Latitude": 442800000,
        "Longitude": -812200000,
        "Elevation (m)": 204,
        "First Year": 1992,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2003,
        "MLY First Year": 1992,
        "MLY Last Year": 2003
      },
      {
        "Name": "SALTFORD",
        "Province": "ONTARIO",
        "Climate ID": "6127418",
        "Station ID": 4587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -81.68,
        "Latitude": 434500000,
        "Longitude": -814100000,
        "Elevation (m)": 228.6,
        "First Year": 1976,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1994,
        "MLY First Year": 1976,
        "MLY Last Year": 1994
      },
      {
        "Name": "SARNIA",
        "Province": "ONTARIO",
        "Climate ID": "6127510",
        "Station ID": 48373,
        "WMO ID": 71704,
        "TC ID": "YZR",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -82.31,
        "Latitude": 425958000,
        "Longitude": -821832000,
        "Elevation (m)": 181.4,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SARNIA",
        "Province": "ONTARIO",
        "Climate ID": "6127513",
        "Station ID": 4588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -82.37,
        "Latitude": 425800000,
        "Longitude": -822200000,
        "Elevation (m)": 190.5,
        "First Year": 1882,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1961,
        "MLY First Year": 1882,
        "MLY Last Year": 1961
      },
      {
        "Name": "SARNIA AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6127514",
        "Station ID": 4589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.99,
        "Longitude (Decimal Degrees)": -82.3,
        "Latitude": 425932058,
        "Longitude": -821817088,
        "Elevation (m)": 180.6,
        "First Year": 1967,
        "Last Year": 2007,
        "HLY First Year": 1967,
        "HLY Last Year": 2005,
        "DLY First Year": 1967,
        "DLY Last Year": 2007,
        "MLY First Year": 1967,
        "MLY Last Year": 2006
      },
      {
        "Name": "SARNIA CHRIS HADFIELD A",
        "Province": "ONTARIO",
        "Climate ID": "6127518",
        "Station ID": 44004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -82.31,
        "Latitude": 425958000,
        "Longitude": -821832000,
        "Elevation (m)": 181,
        "First Year": 2005,
        "Last Year": 2011,
        "HLY First Year": 2005,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2011,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "SARNIA CLIMATE",
        "Province": "ONTARIO",
        "Climate ID": "6127519",
        "Station ID": 44323,
        "WMO ID": 71746,
        "TC ID": "TZR",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -82.3,
        "Latitude": 430000000,
        "Longitude": -821800000,
        "Elevation (m)": 181,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SARNIA POLYSAR",
        "Province": "ONTARIO",
        "Climate ID": "6127520",
        "Station ID": 4590,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.95,
        "Longitude (Decimal Degrees)": -82.43,
        "Latitude": 425700000,
        "Longitude": -822600000,
        "Elevation (m)": 189,
        "First Year": 1959,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1973,
        "MLY First Year": 1959,
        "MLY Last Year": 1973
      },
      {
        "Name": "SARNIA WATERWORKS",
        "Province": "ONTARIO",
        "Climate ID": "6127527",
        "Station ID": 4591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -82.42,
        "Latitude": 430000000,
        "Longitude": -822500000,
        "Elevation (m)": 178.9,
        "First Year": 1961,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1975,
        "MLY First Year": 1961,
        "MLY Last Year": 1975
      },
      {
        "Name": "SAUBLE FOREST",
        "Province": "ONTARIO",
        "Climate ID": "6127551",
        "Station ID": 4592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.68,
        "Longitude (Decimal Degrees)": -81.25,
        "Latitude": 444100000,
        "Longitude": -811500000,
        "Elevation (m)": 182.9,
        "First Year": 1952,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1970,
        "MLY First Year": 1952,
        "MLY Last Year": 1970
      },
      {
        "Name": "SOUTHAMPTON",
        "Province": "ONTARIO",
        "Climate ID": "6127887",
        "Station ID": 4593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -81.37,
        "Latitude": 443000000,
        "Longitude": -812200000,
        "Elevation (m)": 185.9,
        "First Year": 1872,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1982,
        "MLY First Year": 1872,
        "MLY Last Year": 1982
      },
      {
        "Name": "SOUTHAMPTON 2",
        "Province": "ONTARIO",
        "Climate ID": "6127890",
        "Station ID": 42623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.49,
        "Longitude (Decimal Degrees)": -81.36,
        "Latitude": 442940020,
        "Longitude": -812150010,
        "Elevation (m)": 195,
        "First Year": 2004,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2010,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOUTHAMPTON TOMORI",
        "Province": "ONTARIO",
        "Climate ID": "6127895",
        "Station ID": 4594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -81.32,
        "Latitude": 443000000,
        "Longitude": -811900000,
        "Elevation (m)": 213.4,
        "First Year": 1968,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1979,
        "MLY First Year": 1968,
        "MLY Last Year": 1979
      },
      {
        "Name": "SUNSHINE",
        "Province": "ONTARIO",
        "Climate ID": "6128182",
        "Station ID": 4595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -81.33,
        "Latitude": 434700000,
        "Longitude": -812000000,
        "Elevation (m)": null,
        "First Year": 1883,
        "Last Year": 1904,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1904,
        "MLY First Year": 1883,
        "MLY Last Year": 1904
      },
      {
        "Name": "TARA",
        "Province": "ONTARIO",
        "Climate ID": "6128206",
        "Station ID": 4596,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -81.16,
        "Latitude": 443110080,
        "Longitude": -810925060,
        "Elevation (m)": 230,
        "First Year": 1986,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2011,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "THEDFORD",
        "Province": "ONTARIO",
        "Climate ID": "6128240",
        "Station ID": 4597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -81.83,
        "Latitude": 430900000,
        "Longitude": -815000000,
        "Elevation (m)": 207.9,
        "First Year": 1883,
        "Last Year": 1897,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1897,
        "MLY First Year": 1883,
        "MLY Last Year": 1897
      },
      {
        "Name": "TOBERMORY",
        "Province": "ONTARIO",
        "Climate ID": "6128320",
        "Station ID": 4598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -81.67,
        "Latitude": 451500000,
        "Longitude": -814000000,
        "Elevation (m)": 182.9,
        "First Year": 1888,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1983,
        "MLY First Year": 1888,
        "MLY Last Year": 1983
      },
      {
        "Name": "TOBERMORY CYPRUS LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6128323",
        "Station ID": 4599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -81.53,
        "Latitude": 451400000,
        "Longitude": -813200000,
        "Elevation (m)": 190,
        "First Year": 1988,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1994,
        "MLY First Year": 1988,
        "MLY Last Year": 1994
      },
      {
        "Name": "TOBERMORY RCS",
        "Province": "ONTARIO",
        "Climate ID": "6128330",
        "Station ID": 45607,
        "WMO ID": 71767,
        "TC ID": "TTR",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -81.63,
        "Latitude": 451400000,
        "Longitude": -813800000,
        "Elevation (m)": 213.5,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WALKERTON",
        "Province": "ONTARIO",
        "Climate ID": "6129235",
        "Station ID": 4600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -81.15,
        "Latitude": 440800000,
        "Longitude": -810900000,
        "Elevation (m)": 243.8,
        "First Year": 1902,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1971,
        "MLY First Year": 1902,
        "MLY Last Year": 1971
      },
      {
        "Name": "WATFORD",
        "Province": "ONTARIO",
        "Climate ID": "6129389",
        "Station ID": 4601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -81.97,
        "Latitude": 425800000,
        "Longitude": -815800000,
        "Elevation (m)": 216.1,
        "First Year": 1883,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1961,
        "MLY First Year": 1883,
        "MLY Last Year": 1961
      },
      {
        "Name": "WINGHAM CKNX",
        "Province": "ONTARIO",
        "Climate ID": "6129543",
        "Station ID": 4602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -81.3,
        "Latitude": 435300000,
        "Longitude": -811800000,
        "Elevation (m)": 342.9,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "WROXETER",
        "Province": "ONTARIO",
        "Climate ID": "6129660",
        "Station ID": 4603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -81.15,
        "Latitude": 435148018,
        "Longitude": -810908016,
        "Elevation (m)": 335,
        "First Year": 1966,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2021,
        "MLY First Year": 1966,
        "MLY Last Year": 2006
      },
      {
        "Name": "WYOMING",
        "Province": "ONTARIO",
        "Climate ID": "6129670",
        "Station ID": 4604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.93,
        "Longitude (Decimal Degrees)": -82.12,
        "Latitude": 425600000,
        "Longitude": -820700000,
        "Elevation (m)": 216.1,
        "First Year": 1871,
        "Last Year": 1907,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1907,
        "MLY First Year": 1871,
        "MLY Last Year": 1907
      },
      {
        "Name": "ZURICH",
        "Province": "ONTARIO",
        "Climate ID": "6129850",
        "Station ID": 4605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.4,
        "Longitude (Decimal Degrees)": -81.6,
        "Latitude": 432400000,
        "Longitude": -813600000,
        "Elevation (m)": 256.6,
        "First Year": 1881,
        "Last Year": 1898,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1881,
        "DLY Last Year": 1898,
        "MLY First Year": 1881,
        "MLY Last Year": 1898
      },
      {
        "Name": "THEDFORD",
        "Province": "ONTARIO",
        "Climate ID": "612HKLR",
        "Station ID": 4540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -81.86,
        "Latitude": 431032016,
        "Longitude": -815121012,
        "Elevation (m)": 200,
        "First Year": 1986,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2014,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "AMHERSTBURG",
        "Province": "ONTARIO",
        "Climate ID": "6130257",
        "Station ID": 4607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.1,
        "Longitude (Decimal Degrees)": -83.09,
        "Latitude": 420612090,
        "Longitude": -830540068,
        "Elevation (m)": 182,
        "First Year": 1988,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2022,
        "MLY First Year": 1988,
        "MLY Last Year": 2006
      },
      {
        "Name": "AYLMER",
        "Province": "ONTARIO",
        "Climate ID": "6130409",
        "Station ID": 4608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.77,
        "Longitude (Decimal Degrees)": -80.98,
        "Latitude": 424600000,
        "Longitude": -805900000,
        "Elevation (m)": 228.6,
        "First Year": 1948,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1981,
        "MLY First Year": 1948,
        "MLY Last Year": 1981
      },
      {
        "Name": "AYLMER",
        "Province": "ONTARIO",
        "Climate ID": "6130411",
        "Station ID": 27323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.77,
        "Longitude (Decimal Degrees)": -80.98,
        "Latitude": 424600000,
        "Longitude": -805900000,
        "Elevation (m)": 232,
        "First Year": 1996,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2004,
        "MLY First Year": 1996,
        "MLY Last Year": 2004
      },
      {
        "Name": "AYLMER 2",
        "Province": "ONTARIO",
        "Climate ID": "6130415",
        "Station ID": 4609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.77,
        "Longitude (Decimal Degrees)": -81,
        "Latitude": 424600000,
        "Longitude": -810000000,
        "Elevation (m)": 231.6,
        "First Year": 1958,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1961,
        "MLY First Year": 1958,
        "MLY Last Year": 1961
      },
      {
        "Name": "AYLMER ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6130420",
        "Station ID": 4610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.78,
        "Longitude (Decimal Degrees)": -80.98,
        "Latitude": 424700000,
        "Longitude": -805900000,
        "Elevation (m)": 235.9,
        "First Year": 1983,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2000,
        "MLY First Year": 1983,
        "MLY Last Year": 2000
      },
      {
        "Name": "BELLE RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6130681",
        "Station ID": 10084,
        "WMO ID": null,
        "TC ID": "WDD",
        "Latitude (Decimal Degrees)": 42.3,
        "Longitude (Decimal Degrees)": -82.7,
        "Latitude": 421800000,
        "Longitude": -824200000,
        "Elevation (m)": 183.5,
        "First Year": 1994,
        "Last Year": 2005,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1994,
        "DLY Last Year": 2005,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BINBROOK",
        "Province": "ONTARIO",
        "Climate ID": "6130740",
        "Station ID": 4611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 430700000,
        "Longitude": -795000000,
        "Elevation (m)": 205.7,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "BLENHEIM 1",
        "Province": "ONTARIO",
        "Climate ID": "6130815",
        "Station ID": 44806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.31,
        "Longitude (Decimal Degrees)": -82.03,
        "Latitude": 421827036,
        "Longitude": -820154006,
        "Elevation (m)": 200,
        "First Year": 2006,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2009,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "CALEDONIA",
        "Province": "ONTARIO",
        "Climate ID": "6131081",
        "Station ID": 4612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.08,
        "Longitude (Decimal Degrees)": -79.95,
        "Latitude": 430500000,
        "Longitude": -795700000,
        "Elevation (m)": 205.7,
        "First Year": 1931,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1966,
        "MLY First Year": 1931,
        "MLY Last Year": 1966
      },
      {
        "Name": "CANBORO",
        "Province": "ONTARIO",
        "Climate ID": "6131165",
        "Station ID": 4613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 425800000,
        "Longitude": -793500000,
        "Elevation (m)": 182.9,
        "First Year": 1946,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1971,
        "MLY First Year": 1946,
        "MLY Last Year": 1971
      },
      {
        "Name": "CANFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6131167",
        "Station ID": 4614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -79.75,
        "Latitude": 430000000,
        "Longitude": -794500000,
        "Elevation (m)": 189,
        "First Year": 1969,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1971,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "CHATHAM",
        "Province": "ONTARIO",
        "Climate ID": "6131386",
        "Station ID": 4615,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.4,
        "Longitude (Decimal Degrees)": -82.2,
        "Latitude": 422400000,
        "Longitude": -821200000,
        "Elevation (m)": 182.9,
        "First Year": 1933,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1967,
        "MLY First Year": 1933,
        "MLY Last Year": 1967
      },
      {
        "Name": "CHATHAM",
        "Province": "ONTARIO",
        "Climate ID": "6131387",
        "Station ID": 4616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.43,
        "Longitude (Decimal Degrees)": -82.27,
        "Latitude": 422600000,
        "Longitude": -821600000,
        "Elevation (m)": 178,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "CHATHAM 2",
        "Province": "ONTARIO",
        "Climate ID": "6131388",
        "Station ID": 4617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.4,
        "Longitude (Decimal Degrees)": -82.2,
        "Latitude": 422400000,
        "Longitude": -821200000,
        "Elevation (m)": 181.4,
        "First Year": 1879,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1946,
        "MLY First Year": 1879,
        "MLY Last Year": 1946
      },
      {
        "Name": "CHATHAM CFCO",
        "Province": "ONTARIO",
        "Climate ID": "6131413",
        "Station ID": 4618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.38,
        "Longitude (Decimal Degrees)": -82.2,
        "Latitude": 422300000,
        "Longitude": -821200000,
        "Elevation (m)": 181.4,
        "First Year": 1946,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1962,
        "MLY First Year": 1946,
        "MLY Last Year": 1962
      },
      {
        "Name": "CHATHAM KENT",
        "Province": "ONTARIO",
        "Climate ID": "6131414",
        "Station ID": 52118,
        "WMO ID": null,
        "TC ID": "YCK",
        "Latitude (Decimal Degrees)": 42.31,
        "Longitude (Decimal Degrees)": -82.08,
        "Latitude": 421821000,
        "Longitude": -820457000,
        "Elevation (m)": 196.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHATHAM WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6131415",
        "Station ID": 4619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.39,
        "Longitude (Decimal Degrees)": -82.22,
        "Latitude": 422324024,
        "Longitude": -821255008,
        "Elevation (m)": 180,
        "First Year": 1983,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2019,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "CHATHAM WATERWORKS",
        "Province": "ONTARIO",
        "Climate ID": "6131416",
        "Station ID": 4620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.42,
        "Longitude (Decimal Degrees)": -82.18,
        "Latitude": 422500000,
        "Longitude": -821100000,
        "Elevation (m)": 182.9,
        "First Year": 1965,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1983,
        "MLY First Year": 1965,
        "MLY Last Year": 1983
      },
      {
        "Name": "CLEAR CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6131575",
        "Station ID": 4621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.58,
        "Longitude (Decimal Degrees)": -80.57,
        "Latitude": 423500000,
        "Longitude": -803400000,
        "Elevation (m)": 190.2,
        "First Year": 1950,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1961,
        "MLY First Year": 1950,
        "MLY Last Year": 1961
      },
      {
        "Name": "COTTAM",
        "Province": "ONTARIO",
        "Climate ID": "6131910",
        "Station ID": 4622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.12,
        "Longitude (Decimal Degrees)": -82.75,
        "Latitude": 420700000,
        "Longitude": -824500000,
        "Elevation (m)": 184.7,
        "First Year": 1882,
        "Last Year": 1926,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1926,
        "MLY First Year": 1882,
        "MLY Last Year": 1921
      },
      {
        "Name": "DEALTOWN",
        "Province": "ONTARIO",
        "Climate ID": "6131970",
        "Station ID": 4623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.28,
        "Longitude (Decimal Degrees)": -82.12,
        "Latitude": 421700000,
        "Longitude": -820700000,
        "Elevation (m)": null,
        "First Year": 1883,
        "Last Year": 1904,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1904,
        "MLY First Year": 1883,
        "MLY Last Year": 1904
      },
      {
        "Name": "DELHI CDA",
        "Province": "ONTARIO",
        "Climate ID": "6131982",
        "Station ID": 4624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.87,
        "Longitude (Decimal Degrees)": -80.55,
        "Latitude": 425200000,
        "Longitude": -803300000,
        "Elevation (m)": 231.6,
        "First Year": 1934,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1997,
        "MLY First Year": 1934,
        "MLY Last Year": 1997
      },
      {
        "Name": "DELHI CS",
        "Province": "ONTARIO",
        "Climate ID": "6131983",
        "Station ID": 27528,
        "WMO ID": 71573,
        "TC ID": "XDI",
        "Latitude (Decimal Degrees)": 42.87,
        "Longitude (Decimal Degrees)": -80.55,
        "Latitude": 425200000,
        "Longitude": -803300000,
        "Elevation (m)": 231.7,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "DRESDEN",
        "Province": "ONTARIO",
        "Climate ID": "6132090",
        "Station ID": 4625,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.58,
        "Longitude (Decimal Degrees)": -82.18,
        "Latitude": 423500000,
        "Longitude": -821100000,
        "Elevation (m)": 182.9,
        "First Year": 1956,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1996,
        "MLY First Year": 1956,
        "MLY Last Year": 1996
      },
      {
        "Name": "DUNNVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6132144",
        "Station ID": 4626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.92,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 425500000,
        "Longitude": -794200000,
        "Elevation (m)": 178.3,
        "First Year": 1899,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1962,
        "MLY First Year": 1899,
        "MLY Last Year": 1962
      },
      {
        "Name": "DUNNVILLE 2",
        "Province": "ONTARIO",
        "Climate ID": "6132145",
        "Station ID": 4627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.85,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 425100000,
        "Longitude": -794000000,
        "Elevation (m)": 178.3,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "DUNNVILLE PUMPING STN",
        "Province": "ONTARIO",
        "Climate ID": "6132148",
        "Station ID": 4628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.83,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 425000000,
        "Longitude": -793700000,
        "Elevation (m)": 175.3,
        "First Year": 1962,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1990,
        "MLY First Year": 1962,
        "MLY Last Year": 1990
      },
      {
        "Name": "DUTTON",
        "Province": "ONTARIO",
        "Climate ID": "6132175",
        "Station ID": 4629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -81.53,
        "Latitude": 424000000,
        "Longitude": -813200000,
        "Elevation (m)": 219.8,
        "First Year": 1883,
        "Last Year": 1914,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1914,
        "MLY First Year": 1883,
        "MLY Last Year": 1914
      },
      {
        "Name": "DUTTON 2",
        "Province": "ONTARIO",
        "Climate ID": "6132176",
        "Station ID": 4630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -81.53,
        "Latitude": 424000000,
        "Longitude": -813200000,
        "Elevation (m)": 219.8,
        "First Year": 1913,
        "Last Year": 1928,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1928,
        "MLY First Year": 1913,
        "MLY Last Year": 1928
      },
      {
        "Name": "ERIEAU",
        "Province": "ONTARIO",
        "Climate ID": "6132334",
        "Station ID": 4631,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.27,
        "Longitude (Decimal Degrees)": -81.93,
        "Latitude": 421600000,
        "Longitude": -815600000,
        "Elevation (m)": 177.1,
        "First Year": 1979,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1979,
        "MLY First Year": 1979,
        "MLY Last Year": 1979
      },
      {
        "Name": "ERIEAU BAY",
        "Province": "ONTARIO",
        "Climate ID": "6132336",
        "Station ID": 44847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.26,
        "Longitude (Decimal Degrees)": -81.92,
        "Latitude": 421542036,
        "Longitude": -815510026,
        "Elevation (m)": 176,
        "First Year": 2006,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2007,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "ESSEX",
        "Province": "ONTARIO",
        "Climate ID": "6132344",
        "Station ID": 4632,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.17,
        "Longitude (Decimal Degrees)": -82.83,
        "Latitude": 421000000,
        "Longitude": -825000000,
        "Elevation (m)": 195.1,
        "First Year": 1961,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1968,
        "MLY First Year": 1961,
        "MLY Last Year": 1968
      },
      {
        "Name": "FINGAL",
        "Province": "ONTARIO",
        "Climate ID": "6132415",
        "Station ID": 4633,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -81.3,
        "Latitude": 424000000,
        "Longitude": -811800000,
        "Elevation (m)": 213.4,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "FONTHILL",
        "Province": "ONTARIO",
        "Climate ID": "6132435",
        "Station ID": 4634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 430200000,
        "Longitude": -791800000,
        "Elevation (m)": 236.2,
        "First Year": 1945,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1969,
        "MLY First Year": 1945,
        "MLY Last Year": 1969
      },
      {
        "Name": "FORT ERIE",
        "Province": "ONTARIO",
        "Climate ID": "6132470",
        "Station ID": 4635,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -78.97,
        "Latitude": 425300000,
        "Longitude": -785800000,
        "Elevation (m)": 179.8,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2006
      },
      {
        "Name": "GLENCOLIN",
        "Province": "ONTARIO",
        "Climate ID": "6132830",
        "Station ID": 4636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.8,
        "Longitude (Decimal Degrees)": -80.87,
        "Latitude": 424800000,
        "Longitude": -805200000,
        "Elevation (m)": 236.2,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1965
      },
      {
        "Name": "GRIMSBY",
        "Province": "ONTARIO",
        "Climate ID": "6133047",
        "Station ID": 4637,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 431200000,
        "Longitude": -793400000,
        "Elevation (m)": 90.8,
        "First Year": 1910,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1985,
        "MLY First Year": 1910,
        "MLY Last Year": 1985
      },
      {
        "Name": "GRIMSBY ALLAN",
        "Province": "ONTARIO",
        "Climate ID": "6133050",
        "Station ID": 4638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 431000000,
        "Longitude": -793600000,
        "Elevation (m)": 190.5,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "GRIMSBY CHATEAU GAI",
        "Province": "ONTARIO",
        "Climate ID": "6133052",
        "Station ID": 4639,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 431200000,
        "Longitude": -793800000,
        "Elevation (m)": 91.1,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "GRIMSBY MOUNTAIN",
        "Province": "ONTARIO",
        "Climate ID": "6133055",
        "Station ID": 44123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.56,
        "Latitude": 431101044,
        "Longitude": -793330042,
        "Elevation (m)": 170.6,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "GRIMSBY ROCK CHAPEL",
        "Province": "ONTARIO",
        "Climate ID": "6133057",
        "Station ID": 4640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 431100000,
        "Longitude": -793500000,
        "Elevation (m)": 198.1,
        "First Year": 1914,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1966,
        "MLY First Year": 1914,
        "MLY Last Year": 1966
      },
      {
        "Name": "GRIMSBY ROYAL OAK",
        "Province": "ONTARIO",
        "Climate ID": "6133059",
        "Station ID": 4641,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 431100000,
        "Longitude": -793300000,
        "Elevation (m)": 190.5,
        "First Year": 1965,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1974,
        "MLY First Year": 1965,
        "MLY Last Year": 1974
      },
      {
        "Name": "HAGERSVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6133120",
        "Station ID": 4642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -80.07,
        "Latitude": 425800000,
        "Longitude": -800400000,
        "Elevation (m)": 221,
        "First Year": 1948,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2004,
        "MLY First Year": 1948,
        "MLY Last Year": 2004
      },
      {
        "Name": "HAGERSVILLE 2",
        "Province": "ONTARIO",
        "Climate ID": "6133121",
        "Station ID": 4643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.93,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 425600000,
        "Longitude": -800500000,
        "Elevation (m)": 213.4,
        "First Year": 1956,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1984,
        "MLY First Year": 1956,
        "MLY Last Year": 1984
      },
      {
        "Name": "HARROW CDA",
        "Province": "ONTARIO",
        "Climate ID": "6133360",
        "Station ID": 4644,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.03,
        "Longitude (Decimal Degrees)": -82.9,
        "Latitude": 420200000,
        "Longitude": -825400000,
        "Elevation (m)": 190.5,
        "First Year": 1917,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1993,
        "MLY First Year": 1917,
        "MLY Last Year": 1993
      },
      {
        "Name": "HARROW CDA AUTO",
        "Province": "ONTARIO",
        "Climate ID": "6133362",
        "Station ID": 30266,
        "WMO ID": 71298,
        "TC ID": "XHA",
        "Latitude (Decimal Degrees)": 42.03,
        "Longitude (Decimal Degrees)": -82.9,
        "Latitude": 420200000,
        "Longitude": -825400000,
        "Elevation (m)": 191,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "JARVIS",
        "Province": "ONTARIO",
        "Climate ID": "6133855",
        "Station ID": 4645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 425300000,
        "Longitude": -800500000,
        "Elevation (m)": 214.9,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "JORDAN",
        "Province": "ONTARIO",
        "Climate ID": "6133880",
        "Station ID": 4646,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 430700000,
        "Longitude": -792300000,
        "Elevation (m)": 175.3,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "KINGSVILLE MOE",
        "Province": "ONTARIO",
        "Climate ID": "6134190",
        "Station ID": 4647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.04,
        "Longitude (Decimal Degrees)": -82.67,
        "Latitude": 420235064,
        "Longitude": -824026088,
        "Elevation (m)": 200,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2006
      },
      {
        "Name": "KOHLER",
        "Province": "ONTARIO",
        "Climate ID": "6134257",
        "Station ID": 4648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.9,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 425400000,
        "Longitude": -795200000,
        "Elevation (m)": 205.7,
        "First Year": 1949,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1967,
        "MLY First Year": 1949,
        "MLY Last Year": 1967
      },
      {
        "Name": "LANGTON",
        "Province": "ONTARIO",
        "Climate ID": "6134344",
        "Station ID": 4649,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.73,
        "Longitude (Decimal Degrees)": -80.58,
        "Latitude": 424400000,
        "Longitude": -803500000,
        "Elevation (m)": 228.6,
        "First Year": 1967,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1977,
        "MLY First Year": 1967,
        "MLY Last Year": 1977
      },
      {
        "Name": "LEAMINGTON",
        "Province": "ONTARIO",
        "Climate ID": "6134390",
        "Station ID": 4650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.05,
        "Longitude (Decimal Degrees)": -82.63,
        "Latitude": 420300000,
        "Longitude": -823800000,
        "Elevation (m)": 213.4,
        "First Year": 1916,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1978,
        "MLY First Year": 1916,
        "MLY Last Year": 1978
      },
      {
        "Name": "LONG POINT",
        "Province": "ONTARIO",
        "Climate ID": "6134610",
        "Station ID": 4651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.55,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 423300000,
        "Longitude": -800300000,
        "Elevation (m)": 175.3,
        "First Year": 1936,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1985,
        "MLY First Year": 1936,
        "MLY Last Year": 1985
      },
      {
        "Name": "LYONS",
        "Province": "ONTARIO",
        "Climate ID": "6134729",
        "Station ID": 4652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.85,
        "Longitude (Decimal Degrees)": -80.98,
        "Latitude": 425100000,
        "Longitude": -805900000,
        "Elevation (m)": 258.8,
        "First Year": 1883,
        "Last Year": 1894,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1894,
        "MLY First Year": 1883,
        "MLY Last Year": 1894
      },
      {
        "Name": "MARSTON VERHEGGHE",
        "Province": "ONTARIO",
        "Climate ID": "6134996",
        "Station ID": 4653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.7,
        "Longitude (Decimal Degrees)": -80.6,
        "Latitude": 424200000,
        "Longitude": -803600000,
        "Elevation (m)": 220.1,
        "First Year": 1978,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1987,
        "MLY First Year": 1978,
        "MLY Last Year": 1987
      },
      {
        "Name": "LONG POINT (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6134F10",
        "Station ID": 9026,
        "WMO ID": 71464,
        "TC ID": "WPS",
        "Latitude (Decimal Degrees)": 42.53,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 423200000,
        "Longitude": -800300000,
        "Elevation (m)": 175,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MORPETH",
        "Province": "ONTARIO",
        "Climate ID": "6135125",
        "Station ID": 44826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.38,
        "Longitude (Decimal Degrees)": -81.87,
        "Latitude": 422250040,
        "Longitude": -815220022,
        "Elevation (m)": 198,
        "First Year": 2006,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2011,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "NANTICOKE ESSO",
        "Province": "ONTARIO",
        "Climate ID": "6135573",
        "Station ID": 4655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.83,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 425000000,
        "Longitude": -800300000,
        "Elevation (m)": 198.1,
        "First Year": 1975,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1994,
        "MLY First Year": 1975,
        "MLY Last Year": 1994
      },
      {
        "Name": "NEW GLASGOW",
        "Province": "ONTARIO",
        "Climate ID": "6135583",
        "Station ID": 4656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.51,
        "Longitude (Decimal Degrees)": -81.64,
        "Latitude": 423052062,
        "Longitude": -813810092,
        "Elevation (m)": 198.1,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2006
      },
      {
        "Name": "NIAGARA FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6135638",
        "Station ID": 4657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 430800000,
        "Longitude": -790500000,
        "Elevation (m)": 182.9,
        "First Year": 1902,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 1995,
        "MLY First Year": 1902,
        "MLY Last Year": 1995
      },
      {
        "Name": "NIAGARA FALLS CHIPPAWA",
        "Province": "ONTARIO",
        "Climate ID": "6135642",
        "Station ID": 4658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.07,
        "Longitude (Decimal Degrees)": -79.05,
        "Latitude": 430400000,
        "Longitude": -790300000,
        "Elevation (m)": 175,
        "First Year": 1988,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "NIAGARA FALLS NPCSH",
        "Province": "ONTARIO",
        "Climate ID": "6135657",
        "Station ID": 4659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -79.05,
        "Latitude": 430800000,
        "Longitude": -790300000,
        "Elevation (m)": 175.3,
        "First Year": 1980,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2011,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "NIAGARA FALLS ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6135660",
        "Station ID": 4660,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.08,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 430500000,
        "Longitude": -790500000,
        "Elevation (m)": 198.1,
        "First Year": 1921,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1972,
        "MLY First Year": 1921,
        "MLY Last Year": 1972
      },
      {
        "Name": "NIAGARA ON THE LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6135FF4",
        "Station ID": 4654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 431500000,
        "Longitude": -790800000,
        "Elevation (m)": 81.1,
        "First Year": 1983,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1996,
        "MLY First Year": 1983,
        "MLY Last Year": 1996
      },
      {
        "Name": "PA ATMOS FENWICK",
        "Province": "ONTARIO",
        "Climate ID": "6136285",
        "Station ID": 52743,
        "WMO ID": null,
        "TC ID": "A0T",
        "Latitude (Decimal Degrees)": 43.05,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 430303980,
        "Longitude": -792154620,
        "Elevation (m)": 193,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ROYAL CANADIAN HENLEY",
        "Province": "ONTARIO",
        "Climate ID": "6136290",
        "Station ID": 52734,
        "WMO ID": null,
        "TC ID": "Z4W",
        "Latitude (Decimal Degrees)": 43.19,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 431137600,
        "Longitude": -791615800,
        "Elevation (m)": 90.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA VINELAND STONEY RIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6136300",
        "Station ID": 52722,
        "WMO ID": null,
        "TC ID": "L6B",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 430857100,
        "Longitude": -792257200,
        "Elevation (m)": 112.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA WAINFLEET SKYDIVE BURNABY",
        "Province": "ONTARIO",
        "Climate ID": "6136303",
        "Station ID": 52639,
        "WMO ID": null,
        "TC ID": "L6A",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 425242600,
        "Longitude": -792116200,
        "Elevation (m)": 180.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA WELLAND FLATWATER",
        "Province": "ONTARIO",
        "Climate ID": "6136305",
        "Station ID": 52499,
        "WMO ID": null,
        "TC ID": "W5Z",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -79.26,
        "Latitude": 425755080,
        "Longitude": -791518700,
        "Elevation (m)": 186.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA WINONA VINE ESTATES",
        "Province": "ONTARIO",
        "Climate ID": "6136308",
        "Station ID": 52721,
        "WMO ID": null,
        "TC ID": "L5B",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 431257200,
        "Longitude": -794056700,
        "Elevation (m)": 101.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PELEE ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6136335",
        "Station ID": 4664,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 41.75,
        "Longitude (Decimal Degrees)": -82.68,
        "Latitude": 414500000,
        "Longitude": -824100000,
        "Elevation (m)": 175.3,
        "First Year": 1888,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1987,
        "MLY First Year": 1888,
        "MLY Last Year": 1987
      },
      {
        "Name": "PELEE ISLAND A",
        "Province": "ONTARIO",
        "Climate ID": "6136336",
        "Station ID": 4665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 41.78,
        "Longitude (Decimal Degrees)": -82.68,
        "Latitude": 414700000,
        "Longitude": -824100000,
        "Elevation (m)": 174,
        "First Year": 1989,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1994,
        "MLY First Year": 1989,
        "MLY Last Year": 1994
      },
      {
        "Name": "PELEE ISLAND U OF W",
        "Province": "ONTARIO",
        "Climate ID": "6136339",
        "Station ID": 4666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 41.78,
        "Longitude (Decimal Degrees)": -82.68,
        "Latitude": 414700000,
        "Longitude": -824100000,
        "Elevation (m)": 174,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "PORT BURWELL",
        "Province": "ONTARIO",
        "Climate ID": "6136597",
        "Station ID": 4670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.65,
        "Longitude (Decimal Degrees)": -80.82,
        "Latitude": 423900000,
        "Longitude": -804900000,
        "Elevation (m)": 180.4,
        "First Year": 1899,
        "Last Year": 1921,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1921,
        "MLY First Year": 1899,
        "MLY Last Year": 1918
      },
      {
        "Name": "PORT BURWELL RES STN 1",
        "Province": "ONTARIO",
        "Climate ID": "613659R",
        "Station ID": 4669,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.68,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 424100000,
        "Longitude": -805300000,
        "Elevation (m)": 211.8,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "PORT BURWELL RES STN 2",
        "Province": "ONTARIO",
        "Climate ID": "61365R9",
        "Station ID": 4668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.72,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 424300000,
        "Longitude": -805300000,
        "Elevation (m)": 224,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "PORT BURWELL RES STN 3",
        "Province": "ONTARIO",
        "Climate ID": "61365RR",
        "Station ID": 4667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.75,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 424500000,
        "Longitude": -805300000,
        "Elevation (m)": 230.1,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "PORT COLBORNE",
        "Province": "ONTARIO",
        "Climate ID": "6136606",
        "Station ID": 4671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 425300000,
        "Longitude": -791500000,
        "Elevation (m)": 175.3,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1964,
        "MLY Last Year": 2006
      },
      {
        "Name": "PORT COLBORNE LIGHT",
        "Province": "ONTARIO",
        "Climate ID": "6136607",
        "Station ID": 4672,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.87,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 425200000,
        "Longitude": -791500000,
        "Elevation (m)": 175.3,
        "First Year": 1966,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1984,
        "MLY First Year": 1966,
        "MLY Last Year": 1984
      },
      {
        "Name": "PORT CREWE",
        "Province": "ONTARIO",
        "Climate ID": "6136619",
        "Station ID": 4673,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.2,
        "Longitude (Decimal Degrees)": -82.2,
        "Latitude": 421200000,
        "Longitude": -821200000,
        "Elevation (m)": 195,
        "First Year": 1988,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1994,
        "MLY First Year": 1988,
        "MLY Last Year": 1994
      },
      {
        "Name": "PORT DALHOUSIE",
        "Province": "ONTARIO",
        "Climate ID": "6136626",
        "Station ID": 4674,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 431100000,
        "Longitude": -791600000,
        "Elevation (m)": 91.4,
        "First Year": 1874,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1996,
        "MLY First Year": 1874,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORT DALHOUSIE CORBETT",
        "Province": "ONTARIO",
        "Climate ID": "6136628",
        "Station ID": 4675,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.28,
        "Latitude": 431200000,
        "Longitude": -791700000,
        "Elevation (m)": 86.9,
        "First Year": 1978,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1982,
        "MLY First Year": 1978,
        "MLY Last Year": 1982
      },
      {
        "Name": "PORT DOVER",
        "Province": "ONTARIO",
        "Climate ID": "6136643",
        "Station ID": 4676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.78,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 424700000,
        "Longitude": -801300000,
        "Elevation (m)": 185.9,
        "First Year": 1874,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1983,
        "MLY First Year": 1874,
        "MLY Last Year": 1983
      },
      {
        "Name": "PORT STANLEY",
        "Province": "ONTARIO",
        "Climate ID": "6136694",
        "Station ID": 4677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -81.22,
        "Latitude": 424000000,
        "Longitude": -811300000,
        "Elevation (m)": 182.9,
        "First Year": 1871,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1993,
        "MLY First Year": 1872,
        "MLY Last Year": 1993
      },
      {
        "Name": "PORT WELLER (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6136699",
        "Station ID": 7790,
        "WMO ID": 71432,
        "TC ID": "WWZ",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -79.22,
        "Latitude": 431500000,
        "Longitude": -791300000,
        "Elevation (m)": 79,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "PORT BURWELL RES STN 4",
        "Province": "ONTARIO",
        "Climate ID": "6.14E+102",
        "Station ID": 4663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.78,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 424700000,
        "Longitude": -805300000,
        "Elevation (m)": 234.7,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "PORT BURWELL RES STN 5",
        "Province": "ONTARIO",
        "Climate ID": "6136E9R",
        "Station ID": 4662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.83,
        "Longitude (Decimal Degrees)": -80.87,
        "Latitude": 425000000,
        "Longitude": -805200000,
        "Elevation (m)": 234.7,
        "First Year": 1962,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1962,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "PORT BURWELL RES STN 6",
        "Province": "ONTARIO",
        "Climate ID": "6136ER9",
        "Station ID": 4661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 424000000,
        "Longitude": -805300000,
        "Elevation (m)": 201.2,
        "First Year": 1962,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "RAINHAM CENTURY FARM",
        "Province": "ONTARIO",
        "Climate ID": "6137135",
        "Station ID": 48748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.85,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 425100000,
        "Longitude": -794600000,
        "Elevation (m)": 178,
        "First Year": 2010,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2010,
        "DLY Last Year": 2016,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIDGETOWN",
        "Province": "ONTARIO",
        "Climate ID": "6137147",
        "Station ID": 4678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.45,
        "Longitude (Decimal Degrees)": -81.88,
        "Latitude": 422700000,
        "Longitude": -815300000,
        "Elevation (m)": 205.7,
        "First Year": 1883,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1986,
        "MLY First Year": 1883,
        "MLY Last Year": 1988
      },
      {
        "Name": "RIDGETOWN AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6137149",
        "Station ID": 4679,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.45,
        "Longitude (Decimal Degrees)": -81.88,
        "Latitude": 422700000,
        "Longitude": -815300000,
        "Elevation (m)": 205.7,
        "First Year": 1986,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2003,
        "MLY First Year": 1989,
        "MLY Last Year": 2003
      },
      {
        "Name": "RIDGETOWN RCS",
        "Province": "ONTARIO",
        "Climate ID": "6137154",
        "Station ID": 32473,
        "WMO ID": 71307,
        "TC ID": "XRG",
        "Latitude (Decimal Degrees)": 42.45,
        "Longitude (Decimal Degrees)": -81.88,
        "Latitude": 422700000,
        "Longitude": -815300000,
        "Elevation (m)": 205.7,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "RIDGEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6137161",
        "Station ID": 4680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.04,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 430230000,
        "Longitude": -791930000,
        "Elevation (m)": 236.2,
        "First Year": 1950,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2022,
        "MLY First Year": 1950,
        "MLY Last Year": 2006
      },
      {
        "Name": "RODNEY",
        "Province": "ONTARIO",
        "Climate ID": "6137176",
        "Station ID": 4681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.55,
        "Longitude (Decimal Degrees)": -81.62,
        "Latitude": 423300000,
        "Longitude": -813700000,
        "Elevation (m)": 207,
        "First Year": 1984,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1991,
        "MLY First Year": 1984,
        "MLY Last Year": 1991
      },
      {
        "Name": "ST CATHARINES",
        "Province": "ONTARIO",
        "Climate ID": "6137285",
        "Station ID": 4682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 431200000,
        "Longitude": -791500000,
        "Elevation (m)": 91.4,
        "First Year": 1882,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1995,
        "MLY First Year": 1882,
        "MLY Last Year": 1995
      },
      {
        "Name": "ST. CATHARINES / NIAGARA DISTRICT A",
        "Province": "ONTARIO",
        "Climate ID": "6137286",
        "Station ID": 50131,
        "WMO ID": null,
        "TC ID": "YSN",
        "Latitude (Decimal Degrees)": 43.19,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 431130000,
        "Longitude": -791018000,
        "Elevation (m)": 97.8,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST CATHARINES A",
        "Province": "ONTARIO",
        "Climate ID": "6137287",
        "Station ID": 4683,
        "WMO ID": null,
        "TC ID": "YSN",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 431200000,
        "Longitude": -791000000,
        "Elevation (m)": 97.8,
        "First Year": 1971,
        "Last Year": 2012,
        "HLY First Year": 1971,
        "HLY Last Year": 2012,
        "DLY First Year": 1971,
        "DLY Last Year": 2000,
        "MLY First Year": 1971,
        "MLY Last Year": 2000
      },
      {
        "Name": "ST CATHARINES BROCK U",
        "Province": "ONTARIO",
        "Climate ID": "6137290",
        "Station ID": 4684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 430703072,
        "Longitude": -791451024,
        "Elevation (m)": 176.9,
        "First Year": 1967,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2017,
        "MLY First Year": 1967,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST CATHARINES CDA",
        "Province": "ONTARIO",
        "Climate ID": "6137301",
        "Station ID": 4685,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 431100000,
        "Longitude": -791400000,
        "Elevation (m)": 99.1,
        "First Year": 1928,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1964,
        "MLY First Year": 1928,
        "MLY Last Year": 1964
      },
      {
        "Name": "ST CATHARINES A",
        "Province": "ONTARIO",
        "Climate ID": "6137304",
        "Station ID": 53000,
        "WMO ID": null,
        "TC ID": "YSN",
        "Latitude (Decimal Degrees)": 43.19,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 431130000,
        "Longitude": -791016000,
        "Elevation (m)": 97.8,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST CATHARINES POWER GLEN",
        "Province": "ONTARIO",
        "Climate ID": "6137306",
        "Station ID": 4686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 430700000,
        "Longitude": -791500000,
        "Elevation (m)": 121.9,
        "First Year": 1965,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2007,
        "MLY First Year": 1965,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST JOACHIM",
        "Province": "ONTARIO",
        "Climate ID": "6137323",
        "Station ID": 4687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.17,
        "Longitude (Decimal Degrees)": -82.63,
        "Latitude": 421000000,
        "Longitude": -823800000,
        "Elevation (m)": 178,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1958,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "ST THOMAS",
        "Province": "ONTARIO",
        "Climate ID": "6137361",
        "Station ID": 4688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.78,
        "Longitude (Decimal Degrees)": -81.17,
        "Latitude": 424700000,
        "Longitude": -811000000,
        "Elevation (m)": 236.2,
        "First Year": 1882,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1980,
        "MLY First Year": 1882,
        "MLY Last Year": 1980
      },
      {
        "Name": "ST THOMAS WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6137362",
        "Station ID": 4689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.77,
        "Longitude (Decimal Degrees)": -81.21,
        "Latitude": 424606006,
        "Longitude": -811218042,
        "Elevation (m)": 209.1,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST WILLIAMS",
        "Province": "ONTARIO",
        "Climate ID": "6137399",
        "Station ID": 4690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.7,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 424200000,
        "Longitude": -802700000,
        "Elevation (m)": 213.4,
        "First Year": 1954,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1988,
        "MLY First Year": 1954,
        "MLY Last Year": 1988
      },
      {
        "Name": "ST WILLIAMS AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6137401",
        "Station ID": 4691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.7,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 424200000,
        "Longitude": -802700000,
        "Elevation (m)": 213.4,
        "First Year": 1989,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1998,
        "MLY First Year": 1989,
        "MLY Last Year": 1998
      },
      {
        "Name": "SIMCOE",
        "Province": "ONTARIO",
        "Climate ID": "6137730",
        "Station ID": 4692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.85,
        "Longitude (Decimal Degrees)": -80.27,
        "Latitude": 425100000,
        "Longitude": -801600000,
        "Elevation (m)": 240.5,
        "First Year": 1961,
        "Last Year": 1986,
        "HLY First Year": 1962,
        "HLY Last Year": 1986,
        "DLY First Year": 1962,
        "DLY Last Year": 1986,
        "MLY First Year": 1961,
        "MLY Last Year": 1986
      },
      {
        "Name": "SIMCOE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6137732",
        "Station ID": 7793,
        "WMO ID": null,
        "TC ID": "WSI",
        "Latitude (Decimal Degrees)": 42.85,
        "Longitude (Decimal Degrees)": -80.27,
        "Latitude": 425100000,
        "Longitude": -801600000,
        "Elevation (m)": 240.5,
        "First Year": 1992,
        "Last Year": 1999,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": 1992,
        "DLY Last Year": 1999,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SIMCOE",
        "Province": "ONTARIO",
        "Climate ID": "6137735",
        "Station ID": 4693,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.87,
        "Longitude (Decimal Degrees)": -80.33,
        "Latitude": 425200000,
        "Longitude": -802000000,
        "Elevation (m)": 222.5,
        "First Year": 1866,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 1961,
        "MLY First Year": 1866,
        "MLY Last Year": 1961
      },
      {
        "Name": "SMITHVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6137838",
        "Station ID": 4694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.1,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 430600000,
        "Longitude": -793300000,
        "Elevation (m)": 190.5,
        "First Year": 1957,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1969,
        "MLY First Year": 1957,
        "MLY Last Year": 1969
      },
      {
        "Name": "SMITHVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6137839",
        "Station ID": 28037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.09,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 430527042,
        "Longitude": -793247052,
        "Elevation (m)": 183.5,
        "First Year": 2000,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2001,
        "MLY First Year": 2000,
        "MLY Last Year": 2001
      },
      {
        "Name": "SOUTHEAST SHOAL (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6137937",
        "Station ID": 9032,
        "WMO ID": 71466,
        "TC ID": "WNB",
        "Latitude (Decimal Degrees)": 41.83,
        "Longitude (Decimal Degrees)": -82.47,
        "Latitude": 415000000,
        "Longitude": -822800000,
        "Elevation (m)": 195.3,
        "First Year": 1994,
        "Last Year": 2016,
        "HLY First Year": 1994,
        "HLY Last Year": 2016,
        "DLY First Year": 1994,
        "DLY Last Year": 2016,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SPARTA",
        "Province": "ONTARIO",
        "Climate ID": "6137968",
        "Station ID": 4695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.75,
        "Longitude (Decimal Degrees)": -81.08,
        "Latitude": 424500000,
        "Longitude": -810500000,
        "Elevation (m)": 228.6,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "SPRINGFORD",
        "Province": "ONTARIO",
        "Climate ID": "6137979",
        "Station ID": 4696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.92,
        "Longitude (Decimal Degrees)": -80.67,
        "Latitude": 425500000,
        "Longitude": -804000000,
        "Elevation (m)": 248,
        "First Year": 1986,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1997,
        "MLY First Year": 1986,
        "MLY Last Year": 1997
      },
      {
        "Name": "TALBOTVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6138202",
        "Station ID": 4697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.8,
        "Longitude (Decimal Degrees)": -81.25,
        "Latitude": 424800000,
        "Longitude": -811500000,
        "Elevation (m)": 234.7,
        "First Year": 1953,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1960,
        "MLY First Year": 1953,
        "MLY Last Year": 1960
      },
      {
        "Name": "THOROLD",
        "Province": "ONTARIO",
        "Climate ID": "6138259",
        "Station ID": 27337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -79.2,
        "Latitude": 430700000,
        "Longitude": -791200000,
        "Elevation (m)": 173,
        "First Year": 1997,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2003,
        "MLY First Year": 1997,
        "MLY Last Year": 2003
      },
      {
        "Name": "TILLSONBURG",
        "Province": "ONTARIO",
        "Climate ID": "6138267",
        "Station ID": 4698,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.85,
        "Longitude (Decimal Degrees)": -80.75,
        "Latitude": 425100000,
        "Longitude": -804500000,
        "Elevation (m)": 236.2,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "TILLSONBURG NORTH",
        "Province": "ONTARIO",
        "Climate ID": "6138269",
        "Station ID": 27488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.86,
        "Longitude (Decimal Degrees)": -80.74,
        "Latitude": 425138076,
        "Longitude": -804426004,
        "Elevation (m)": 235,
        "First Year": 1997,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2016,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "TILLSONBURG WWTP",
        "Province": "ONTARIO",
        "Climate ID": "6138270",
        "Station ID": 4699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.86,
        "Longitude (Decimal Degrees)": -80.72,
        "Latitude": 425119020,
        "Longitude": -804318072,
        "Elevation (m)": 213.4,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2006
      },
      {
        "Name": "VANESSA",
        "Province": "ONTARIO",
        "Climate ID": "6139131",
        "Station ID": 4700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -80.4,
        "Latitude": 425800000,
        "Longitude": -802400000,
        "Elevation (m)": 239.3,
        "First Year": 1961,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1961,
        "MLY First Year": 1961,
        "MLY Last Year": 1961
      },
      {
        "Name": "VINELAND",
        "Province": "ONTARIO",
        "Climate ID": "6139141",
        "Station ID": 6907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 430900000,
        "Longitude": -792500000,
        "Elevation (m)": 110,
        "First Year": 1993,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2013,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "VINELAND BALLS FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6139142",
        "Station ID": 4701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 430800000,
        "Longitude": -792300000,
        "Elevation (m)": 144.8,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "VINELAND RITTENHOUSE",
        "Province": "ONTARIO",
        "Climate ID": "6139143",
        "Station ID": 4702,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 431000000,
        "Longitude": -792500000,
        "Elevation (m)": 94.5,
        "First Year": 1965,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2001,
        "MLY First Year": 1965,
        "MLY Last Year": 2001
      },
      {
        "Name": "VINELAND STATION",
        "Province": "ONTARIO",
        "Climate ID": "6139145",
        "Station ID": 4703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 431100000,
        "Longitude": -792400000,
        "Elevation (m)": 79.2,
        "First Year": 1924,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1988,
        "MLY First Year": 1924,
        "MLY Last Year": 1988
      },
      {
        "Name": "VINELAND STATION AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6139146",
        "Station ID": 4704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 431100000,
        "Longitude": -792400000,
        "Elevation (m)": 79.2,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "VINELAND STATION RCS",
        "Province": "ONTARIO",
        "Climate ID": "6139148",
        "Station ID": 31367,
        "WMO ID": 71171,
        "TC ID": "XVN",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 431100000,
        "Longitude": -792400000,
        "Elevation (m)": 79.2,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "VIRGIL BRIGHTS",
        "Province": "ONTARIO",
        "Climate ID": "6139149",
        "Station ID": 4705,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.15,
        "Latitude": 431200000,
        "Longitude": -790900000,
        "Elevation (m)": 99.1,
        "First Year": 1965,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1978,
        "MLY First Year": 1965,
        "MLY Last Year": 1978
      },
      {
        "Name": "WALLACEBURG",
        "Province": "ONTARIO",
        "Climate ID": "6139265",
        "Station ID": 4706,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.58,
        "Longitude (Decimal Degrees)": -82.4,
        "Latitude": 423500000,
        "Longitude": -822400000,
        "Elevation (m)": 176.8,
        "First Year": 1905,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1997,
        "MLY First Year": 1905,
        "MLY Last Year": 1997
      },
      {
        "Name": "WALLACETOWN",
        "Province": "ONTARIO",
        "Climate ID": "6139270",
        "Station ID": 4707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.63,
        "Longitude (Decimal Degrees)": -81.45,
        "Latitude": 423800000,
        "Longitude": -812700000,
        "Elevation (m)": 213.4,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "WALSINGHAM SANTO",
        "Province": "ONTARIO",
        "Climate ID": "6139272",
        "Station ID": 4708,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.67,
        "Longitude (Decimal Degrees)": -80.6,
        "Latitude": 424000000,
        "Longitude": -803600000,
        "Elevation (m)": 205.7,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "WARDSVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6139287",
        "Station ID": 4709,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.6,
        "Longitude (Decimal Degrees)": -81.77,
        "Latitude": 423600000,
        "Longitude": -814600000,
        "Elevation (m)": 213.4,
        "First Year": 1970,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1977,
        "MLY First Year": 1970,
        "MLY Last Year": 1977
      },
      {
        "Name": "WATERFORD",
        "Province": "ONTARIO",
        "Climate ID": "6139355",
        "Station ID": 4710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -80.28,
        "Latitude": 425800000,
        "Longitude": -801700000,
        "Elevation (m)": 232,
        "First Year": 1948,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1959,
        "MLY First Year": 1948,
        "MLY Last Year": 1959
      },
      {
        "Name": "WATERFORD",
        "Province": "ONTARIO",
        "Climate ID": "6139356",
        "Station ID": 4711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -80.23,
        "Latitude": 425300000,
        "Longitude": -801400000,
        "Elevation (m)": 222.5,
        "First Year": 1971,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2014,
        "MLY First Year": 1971,
        "MLY Last Year": 2006
      },
      {
        "Name": "WELLAND",
        "Province": "ONTARIO",
        "Climate ID": "6139445",
        "Station ID": 4712,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.99,
        "Longitude (Decimal Degrees)": -79.26,
        "Latitude": 425933096,
        "Longitude": -791540098,
        "Elevation (m)": 175.3,
        "First Year": 1872,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 2014,
        "MLY First Year": 1872,
        "MLY Last Year": 2006
      },
      {
        "Name": "WELLAND CHOW",
        "Province": "ONTARIO",
        "Climate ID": "6139447",
        "Station ID": 4713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.93,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 425600000,
        "Longitude": -791500000,
        "Elevation (m)": 175.3,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "WELLAND-PELHAM",
        "Province": "ONTARIO",
        "Climate ID": "6139449",
        "Station ID": 44283,
        "WMO ID": 71752,
        "TC ID": "TWL",
        "Latitude (Decimal Degrees)": 42.97,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 425800000,
        "Longitude": -792000000,
        "Elevation (m)": 178,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "WILSONVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6139514",
        "Station ID": 4714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -80.32,
        "Latitude": 430000000,
        "Longitude": -801900000,
        "Elevation (m)": 248.4,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1959,
        "MLY Last Year": 1964
      },
      {
        "Name": "WINDSOR RIVERSIDE",
        "Province": "ONTARIO",
        "Climate ID": "6139520",
        "Station ID": 4715,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.33,
        "Longitude (Decimal Degrees)": -82.93,
        "Latitude": 422000000,
        "Longitude": -825600000,
        "Elevation (m)": 188.4,
        "First Year": 1866,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 2022,
        "MLY First Year": 1866,
        "MLY Last Year": 2006
      },
      {
        "Name": "WINDSOR A",
        "Province": "ONTARIO",
        "Climate ID": "6139525",
        "Station ID": 4716,
        "WMO ID": 71538,
        "TC ID": "YQG",
        "Latitude (Decimal Degrees)": 42.28,
        "Longitude (Decimal Degrees)": -82.96,
        "Latitude": 421632000,
        "Longitude": -825720000,
        "Elevation (m)": 189.6,
        "First Year": 1940,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1940,
        "DLY Last Year": 2014,
        "MLY First Year": 1940,
        "MLY Last Year": 2014
      },
      {
        "Name": "WINDSOR A",
        "Province": "ONTARIO",
        "Climate ID": "6139527",
        "Station ID": 52838,
        "WMO ID": null,
        "TC ID": "YQG",
        "Latitude (Decimal Degrees)": 42.28,
        "Longitude (Decimal Degrees)": -82.96,
        "Latitude": 421634000,
        "Longitude": -825719000,
        "Elevation (m)": 189.6,
        "First Year": 2014,
        "Last Year": 2018,
        "HLY First Year": 2014,
        "HLY Last Year": 2018,
        "DLY First Year": 2014,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WINDSOR A",
        "Province": "ONTARIO",
        "Climate ID": "6139530",
        "Station ID": 54738,
        "WMO ID": 71538,
        "TC ID": "YQG",
        "Latitude (Decimal Degrees)": 42.28,
        "Longitude (Decimal Degrees)": -82.96,
        "Latitude": 421634000,
        "Longitude": -825719000,
        "Elevation (m)": 189.6,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WINDSOR FORD PLANT",
        "Province": "ONTARIO",
        "Climate ID": "6139533",
        "Station ID": 4717,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.33,
        "Longitude (Decimal Degrees)": -83.02,
        "Latitude": 422000000,
        "Longitude": -830100000,
        "Elevation (m)": 174.7,
        "First Year": 1980,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1993,
        "MLY First Year": 1980,
        "MLY Last Year": 1993
      },
      {
        "Name": "WINDSOR SOUTH",
        "Province": "ONTARIO",
        "Climate ID": "6139535",
        "Station ID": 4718,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.3,
        "Longitude (Decimal Degrees)": -83.03,
        "Latitude": 421800000,
        "Longitude": -830200000,
        "Elevation (m)": 182.9,
        "First Year": 1952,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1955,
        "MLY First Year": 1952,
        "MLY Last Year": 1955
      },
      {
        "Name": "WINDSOR UNIVERSITY",
        "Province": "ONTARIO",
        "Climate ID": "6139538",
        "Station ID": 4719,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.3,
        "Longitude (Decimal Degrees)": -83.07,
        "Latitude": 421800000,
        "Longitude": -830400000,
        "Elevation (m)": 179.8,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "WINONA",
        "Province": "ONTARIO",
        "Climate ID": "6139556",
        "Station ID": 4720,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 431300000,
        "Longitude": -793800000,
        "Elevation (m)": 83.8,
        "First Year": 1977,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1977,
        "MLY First Year": 1977,
        "MLY Last Year": 1977
      },
      {
        "Name": "WOODSLEE CDA",
        "Province": "ONTARIO",
        "Climate ID": "6139600",
        "Station ID": 4721,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.22,
        "Longitude (Decimal Degrees)": -82.73,
        "Latitude": 421300000,
        "Longitude": -824400000,
        "Elevation (m)": 182.9,
        "First Year": 1946,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1986,
        "MLY First Year": 1946,
        "MLY Last Year": 1988
      },
      {
        "Name": "WOODSLEE CDA AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6139602",
        "Station ID": 4722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.22,
        "Longitude (Decimal Degrees)": -82.73,
        "Latitude": 421300000,
        "Longitude": -824400000,
        "Elevation (m)": 182.9,
        "First Year": 1986,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2001,
        "MLY First Year": 1989,
        "MLY Last Year": 2001
      },
      {
        "Name": "HARROW AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "613CC60",
        "Station ID": 6906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.03,
        "Longitude (Decimal Degrees)": -82.9,
        "Latitude": 420200000,
        "Longitude": -825400000,
        "Elevation (m)": 190.5,
        "First Year": 1992,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 2000,
        "MLY First Year": 1992,
        "MLY Last Year": 2000
      },
      {
        "Name": "PORT COLBORNE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "613F606",
        "Station ID": 9005,
        "WMO ID": 71463,
        "TC ID": "WPC",
        "Latitude (Decimal Degrees)": 42.87,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 425200000,
        "Longitude": -791500000,
        "Elevation (m)": 183.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "POINT PELEE",
        "Province": "ONTARIO",
        "Climate ID": "613FN58",
        "Station ID": 4606,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 41.95,
        "Longitude (Decimal Degrees)": -82.52,
        "Latitude": 415700000,
        "Longitude": -823100000,
        "Elevation (m)": 176.8,
        "First Year": 1974,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1997,
        "MLY First Year": 1974,
        "MLY Last Year": 1997
      },
      {
        "Name": "ERIEAU (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "613KLLM",
        "Station ID": 9006,
        "WMO ID": 71465,
        "TC ID": "WAJ",
        "Latitude (Decimal Degrees)": 42.25,
        "Longitude (Decimal Degrees)": -81.9,
        "Latitude": 421500413,
        "Longitude": -815400517,
        "Elevation (m)": 178,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NIAGARA ON THE LAKE GOLF",
        "Province": "ONTARIO",
        "Climate ID": "613N001",
        "Station ID": 27206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -79.07,
        "Latitude": 431500000,
        "Longitude": -790400000,
        "Elevation (m)": 255,
        "First Year": 1996,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 1997,
        "MLY First Year": 1996,
        "MLY Last Year": 1997
      },
      {
        "Name": "POINT PELEE CS",
        "Province": "ONTARIO",
        "Climate ID": "613P001",
        "Station ID": 27533,
        "WMO ID": 71303,
        "TC ID": "XPT",
        "Latitude (Decimal Degrees)": 41.95,
        "Longitude (Decimal Degrees)": -82.52,
        "Latitude": 415655009,
        "Longitude": -823108002,
        "Elevation (m)": 176.8,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2006
      },
      {
        "Name": "HARROW TST",
        "Province": "ONTARIO",
        "Climate ID": "613ZZZZ",
        "Station ID": 31147,
        "WMO ID": null,
        "TC ID": "XHW",
        "Latitude (Decimal Degrees)": 42.03,
        "Longitude (Decimal Degrees)": -82.9,
        "Latitude": 420200000,
        "Longitude": -825400000,
        "Elevation (m)": 191,
        "First Year": 2001,
        "Last Year": 2002,
        "HLY First Year": 2001,
        "HLY Last Year": 2002,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ACTON MONITOR STN",
        "Province": "ONTARIO",
        "Climate ID": "6140041",
        "Station ID": 4728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 433900000,
        "Longitude": -800300000,
        "Elevation (m)": 411.5,
        "First Year": 1966,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1971,
        "MLY First Year": 1966,
        "MLY Last Year": 1971
      },
      {
        "Name": "AILSA CRAIG",
        "Province": "ONTARIO",
        "Climate ID": "6140091",
        "Station ID": 4729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -81.53,
        "Latitude": 430900000,
        "Longitude": -813200000,
        "Elevation (m)": 228.6,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "APPS MILL",
        "Province": "ONTARIO",
        "Climate ID": "6140286",
        "Station ID": 4730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 430800000,
        "Longitude": -802300000,
        "Elevation (m)": 230.1,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "ARTHUR",
        "Province": "ONTARIO",
        "Climate ID": "6140348",
        "Station ID": 4731,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -80.57,
        "Latitude": 434900000,
        "Longitude": -803400000,
        "Elevation (m)": 452,
        "First Year": 1964,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1988,
        "MLY First Year": 1964,
        "MLY Last Year": 1987
      },
      {
        "Name": "AYR",
        "Province": "ONTARIO",
        "Climate ID": "6140437",
        "Station ID": 4732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.28,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 431700000,
        "Longitude": -802700000,
        "Elevation (m)": 289.6,
        "First Year": 1956,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1961,
        "MLY First Year": 1956,
        "MLY Last Year": 1961
      },
      {
        "Name": "BLUE SPRINGS CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6140818",
        "Station ID": 4733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 433800000,
        "Longitude": -800700000,
        "Elevation (m)": 373.4,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "BRANTFORD",
        "Province": "ONTARIO",
        "Climate ID": "6140941",
        "Station ID": 4734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -80.27,
        "Latitude": 430800000,
        "Longitude": -801600000,
        "Elevation (m)": 205.7,
        "First Year": 1876,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1876,
        "DLY Last Year": 1963,
        "MLY First Year": 1876,
        "MLY Last Year": 1963
      },
      {
        "Name": "BRANTFORD AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6140942",
        "Station ID": 53378,
        "WMO ID": 71602,
        "TC ID": "TBF",
        "Latitude (Decimal Degrees)": 43.14,
        "Longitude (Decimal Degrees)": -80.33,
        "Latitude": 430815000,
        "Longitude": -801953000,
        "Elevation (m)": 245.5,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BRANTFORD BRANT PARK",
        "Province": "ONTARIO",
        "Climate ID": "6140948",
        "Station ID": 4735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -80.3,
        "Latitude": 430900000,
        "Longitude": -801800000,
        "Elevation (m)": 213.4,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "BRANTFORD MORELL",
        "Province": "ONTARIO",
        "Climate ID": "6140951",
        "Station ID": 4736,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -80.28,
        "Latitude": 430900000,
        "Longitude": -801700000,
        "Elevation (m)": 198.1,
        "First Year": 1959,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1964,
        "MLY First Year": 1959,
        "MLY Last Year": 1964
      },
      {
        "Name": "BRANTFORD MOE",
        "Province": "ONTARIO",
        "Climate ID": "6140954",
        "Station ID": 4737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -80.23,
        "Latitude": 430800000,
        "Longitude": -801400000,
        "Elevation (m)": 196,
        "First Year": 1960,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2013,
        "MLY First Year": 1960,
        "MLY Last Year": 2006
      },
      {
        "Name": "BLUE CORWHIN",
        "Province": "ONTARIO",
        "Climate ID": "6140Q17",
        "Station ID": 4727,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.53,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 433200000,
        "Longitude": -800700000,
        "Elevation (m)": 350.5,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "BLUE ROCKWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6140QAG",
        "Station ID": 4725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 433500000,
        "Longitude": -800700000,
        "Elevation (m)": 350.5,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "BLUE SCOUT",
        "Province": "ONTARIO",
        "Climate ID": "6140QJ7",
        "Station ID": 4726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 433700000,
        "Longitude": -800500000,
        "Elevation (m)": 342.9,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "BURFORD",
        "Province": "ONTARIO",
        "Climate ID": "6141040",
        "Station ID": 4738,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.1,
        "Longitude (Decimal Degrees)": -80.43,
        "Latitude": 430600000,
        "Longitude": -802600000,
        "Elevation (m)": 259.1,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "CAMBRIDGE GALT MOE",
        "Province": "ONTARIO",
        "Climate ID": "6141095",
        "Station ID": 4739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -80.32,
        "Latitude": 432000000,
        "Longitude": -801900000,
        "Elevation (m)": 268.2,
        "First Year": 1879,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1994,
        "MLY First Year": 1879,
        "MLY Last Year": 1994
      },
      {
        "Name": "CAMBRIDGE-STEWART",
        "Province": "ONTARIO",
        "Climate ID": "6141100",
        "Station ID": 4740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -80.3,
        "Latitude": 432100000,
        "Longitude": -801800000,
        "Elevation (m)": 289,
        "First Year": 1973,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2000,
        "MLY First Year": 1973,
        "MLY Last Year": 2000
      },
      {
        "Name": "CANNING",
        "Province": "ONTARIO",
        "Climate ID": "6141169",
        "Station ID": 4741,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 431100000,
        "Longitude": -802700000,
        "Elevation (m)": 259.1,
        "First Year": 1968,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1971,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "CATHCART",
        "Province": "ONTARIO",
        "Climate ID": "6141268",
        "Station ID": 4742,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -80.57,
        "Latitude": 430700000,
        "Longitude": -803400000,
        "Elevation (m)": 269.7,
        "First Year": 1962,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1970,
        "MLY First Year": 1962,
        "MLY Last Year": 1970
      },
      {
        "Name": "CLIFFORD",
        "Province": "ONTARIO",
        "Climate ID": "6141602",
        "Station ID": 4743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -80.97,
        "Latitude": 435800000,
        "Longitude": -805800000,
        "Elevation (m)": 373.4,
        "First Year": 1950,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1967,
        "MLY First Year": 1950,
        "MLY Last Year": 1968
      },
      {
        "Name": "COLDSTREAM",
        "Province": "ONTARIO",
        "Climate ID": "6141763",
        "Station ID": 4744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.02,
        "Longitude (Decimal Degrees)": -81.5,
        "Latitude": 430100000,
        "Longitude": -813000000,
        "Elevation (m)": null,
        "First Year": 1888,
        "Last Year": 1899,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1899,
        "MLY First Year": 1888,
        "MLY Last Year": 1899
      },
      {
        "Name": "CONESTOGO",
        "Province": "ONTARIO",
        "Climate ID": "6141843",
        "Station ID": 4745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -80.52,
        "Latitude": 433300000,
        "Longitude": -803100000,
        "Elevation (m)": null,
        "First Year": 1880,
        "Last Year": 1899,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1899,
        "MLY First Year": 1880,
        "MLY Last Year": 1899
      },
      {
        "Name": "CROMARTY",
        "Province": "ONTARIO",
        "Climate ID": "6141919",
        "Station ID": 4746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.42,
        "Longitude (Decimal Degrees)": -81.33,
        "Latitude": 432500000,
        "Longitude": -812000000,
        "Elevation (m)": 327.7,
        "First Year": 1970,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1991,
        "MLY First Year": 1970,
        "MLY Last Year": 1991
      },
      {
        "Name": "CULLODEN EASEY",
        "Province": "ONTARIO",
        "Climate ID": "6141933",
        "Station ID": 4747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.89,
        "Longitude (Decimal Degrees)": -80.85,
        "Latitude": 425322040,
        "Longitude": -805048060,
        "Elevation (m)": 280,
        "First Year": 1974,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2007,
        "MLY First Year": 1974,
        "MLY Last Year": 2006
      },
      {
        "Name": "DAMASCUS",
        "Province": "ONTARIO",
        "Climate ID": "6141966",
        "Station ID": 4748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -80.48,
        "Latitude": 435500000,
        "Longitude": -802900000,
        "Elevation (m)": 487.7,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1975,
        "MLY Last Year": 1977
      },
      {
        "Name": "DOON",
        "Province": "ONTARIO",
        "Climate ID": "6142065",
        "Station ID": 4749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.4,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 432400000,
        "Longitude": -802700000,
        "Elevation (m)": 312.4,
        "First Year": 1948,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1953,
        "MLY First Year": 1948,
        "MLY Last Year": 1953
      },
      {
        "Name": "DORCHESTER",
        "Province": "ONTARIO",
        "Climate ID": "6142066",
        "Station ID": 4750,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -81.03,
        "Latitude": 430007032,
        "Longitude": -810158068,
        "Elevation (m)": 271.3,
        "First Year": 1976,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2017,
        "MLY First Year": 1976,
        "MLY Last Year": 2006
      },
      {
        "Name": "DRUMBO",
        "Province": "ONTARIO",
        "Climate ID": "6142110",
        "Station ID": 4751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.23,
        "Longitude (Decimal Degrees)": -80.55,
        "Latitude": 431400000,
        "Longitude": -803300000,
        "Elevation (m)": 304.8,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "DRUMBO HARRINGTON",
        "Province": "ONTARIO",
        "Climate ID": "6142113",
        "Station ID": 4752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.23,
        "Longitude (Decimal Degrees)": -80.52,
        "Latitude": 431400000,
        "Longitude": -803100000,
        "Elevation (m)": 281.9,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "EGREMONT",
        "Province": "ONTARIO",
        "Climate ID": "6142252",
        "Station ID": 4753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44,
        "Longitude (Decimal Degrees)": -80.67,
        "Latitude": 440000000,
        "Longitude": -804000000,
        "Elevation (m)": 442.3,
        "First Year": 1880,
        "Last Year": 1894,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1894,
        "MLY First Year": 1880,
        "MLY Last Year": 1894
      },
      {
        "Name": "ELMIRA",
        "Province": "ONTARIO",
        "Climate ID": "6142275",
        "Station ID": 4754,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.6,
        "Longitude (Decimal Degrees)": -80.53,
        "Latitude": 433600000,
        "Longitude": -803200000,
        "Elevation (m)": 350.5,
        "First Year": 1955,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1994,
        "MLY First Year": 1955,
        "MLY Last Year": 1994
      },
      {
        "Name": "ELORA",
        "Province": "ONTARIO",
        "Climate ID": "6142284",
        "Station ID": 4755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -80.4,
        "Latitude": 434100000,
        "Longitude": -802400000,
        "Elevation (m)": 388.3,
        "First Year": 1882,
        "Last Year": 1923,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1923,
        "MLY First Year": 1882,
        "MLY Last Year": 1923
      },
      {
        "Name": "ELORA RESEARCH STN",
        "Province": "ONTARIO",
        "Climate ID": "6142285",
        "Station ID": 4756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -80.42,
        "Latitude": 433900000,
        "Longitude": -802500000,
        "Elevation (m)": 376.4,
        "First Year": 1969,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1985,
        "MLY First Year": 1969,
        "MLY Last Year": 1985
      },
      {
        "Name": "ELORA RCS",
        "Province": "ONTARIO",
        "Climate ID": "6142286",
        "Station ID": 41983,
        "WMO ID": 71352,
        "TC ID": "ZEL",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -80.42,
        "Latitude": 433900000,
        "Longitude": -802500000,
        "Elevation (m)": 376.4,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "EMBRO INNES",
        "Province": "ONTARIO",
        "Climate ID": "6142295",
        "Station ID": 4757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -80.93,
        "Latitude": 431500000,
        "Longitude": -805600000,
        "Elevation (m)": 358.1,
        "First Year": 1977,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2003,
        "MLY First Year": 1977,
        "MLY Last Year": 2003
      },
      {
        "Name": "FALKLAND",
        "Province": "ONTARIO",
        "Climate ID": "6142373",
        "Station ID": 4758,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 430800000,
        "Longitude": -802700000,
        "Elevation (m)": 262.1,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "FERGUS",
        "Province": "ONTARIO",
        "Climate ID": "6142398",
        "Station ID": 4759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 434200000,
        "Longitude": -802300000,
        "Elevation (m)": null,
        "First Year": 1882,
        "Last Year": 1894,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1894,
        "MLY First Year": 1882,
        "MLY Last Year": 1894
      },
      {
        "Name": "FERGUS SHAND DAM",
        "Province": "ONTARIO",
        "Climate ID": "6142400",
        "Station ID": 4760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -80.33,
        "Latitude": 434405088,
        "Longitude": -801949098,
        "Elevation (m)": 417.6,
        "First Year": 1939,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 2022,
        "MLY First Year": 1939,
        "MLY Last Year": 2006
      },
      {
        "Name": "FERGUS MOE",
        "Province": "ONTARIO",
        "Climate ID": "6142402",
        "Station ID": 4761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 434206006,
        "Longitude": -802244028,
        "Elevation (m)": 396.2,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2006
      },
      {
        "Name": "FOLDENS",
        "Province": "ONTARIO",
        "Climate ID": "6142420",
        "Station ID": 4762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.02,
        "Longitude (Decimal Degrees)": -80.78,
        "Latitude": 430106078,
        "Longitude": -804651036,
        "Elevation (m)": 328,
        "First Year": 1963,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2016,
        "MLY First Year": 1963,
        "MLY Last Year": 2006
      },
      {
        "Name": "FULLARTON",
        "Province": "ONTARIO",
        "Climate ID": "6142627",
        "Station ID": 4763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.38,
        "Longitude (Decimal Degrees)": -81.2,
        "Latitude": 432300000,
        "Longitude": -811200000,
        "Elevation (m)": 335.3,
        "First Year": 1956,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1967,
        "MLY First Year": 1956,
        "MLY Last Year": 1967
      },
      {
        "Name": "GLANWORTH CFPL",
        "Province": "ONTARIO",
        "Climate ID": "6142798",
        "Station ID": 4764,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.88,
        "Longitude (Decimal Degrees)": -81.2,
        "Latitude": 425300000,
        "Longitude": -811200000,
        "Elevation (m)": 280.1,
        "First Year": 1961,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1982,
        "MLY First Year": 1961,
        "MLY Last Year": 1982
      },
      {
        "Name": "GLEN ALLAN",
        "Province": "ONTARIO",
        "Climate ID": "6142803",
        "Station ID": 4765,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -80.71,
        "Latitude": 434102058,
        "Longitude": -804237086,
        "Elevation (m)": 400,
        "First Year": 1955,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2013,
        "MLY First Year": 1955,
        "MLY Last Year": 2006
      },
      {
        "Name": "GLENCOE",
        "Province": "ONTARIO",
        "Climate ID": "6142819",
        "Station ID": 4766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.7,
        "Longitude (Decimal Degrees)": -81.7,
        "Latitude": 424200000,
        "Longitude": -814200000,
        "Elevation (m)": 221,
        "First Year": 1948,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1958,
        "MLY First Year": 1948,
        "MLY Last Year": 1958
      },
      {
        "Name": "GRAND VALLEY",
        "Province": "ONTARIO",
        "Climate ID": "6142990",
        "Station ID": 4768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -80.35,
        "Latitude": 435300000,
        "Longitude": -802100000,
        "Elevation (m)": 470.3,
        "First Year": 1910,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1939,
        "MLY First Year": 1910,
        "MLY Last Year": 1939
      },
      {
        "Name": "GRAND VALLEY WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6142991",
        "Station ID": 4769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -80.33,
        "Latitude": 435300000,
        "Longitude": -802000000,
        "Elevation (m)": 464.8,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "GRANTON",
        "Province": "ONTARIO",
        "Climate ID": "6142993",
        "Station ID": 4770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -81.33,
        "Latitude": 431200000,
        "Longitude": -812000000,
        "Elevation (m)": 315.5,
        "First Year": 1873,
        "Last Year": 1886,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1873,
        "DLY Last Year": 1886,
        "MLY First Year": 1873,
        "MLY Last Year": 1886
      },
      {
        "Name": "GRANTON",
        "Province": "ONTARIO",
        "Climate ID": "6142994",
        "Station ID": 4771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.23,
        "Longitude (Decimal Degrees)": -81.32,
        "Latitude": 431400000,
        "Longitude": -811900000,
        "Elevation (m)": 298.7,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "GRAND BEND RES STN 5",
        "Province": "ONTARIO",
        "Climate ID": "61429HQ",
        "Station ID": 4767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -81.47,
        "Latitude": 431300000,
        "Longitude": -812800000,
        "Elevation (m)": 262.1,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "GUELPH ARBORETUM",
        "Province": "ONTARIO",
        "Climate ID": "6143069",
        "Station ID": 4772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 433300000,
        "Longitude": -801300000,
        "Elevation (m)": 327.7,
        "First Year": 1975,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1997,
        "MLY First Year": 1975,
        "MLY Last Year": 1997
      },
      {
        "Name": "GUELPH OAC",
        "Province": "ONTARIO",
        "Climate ID": "6143083",
        "Station ID": 4774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.52,
        "Longitude (Decimal Degrees)": -80.23,
        "Latitude": 433100000,
        "Longitude": -801400000,
        "Elevation (m)": 333.8,
        "First Year": 1881,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1881,
        "DLY Last Year": 1973,
        "MLY First Year": 1881,
        "MLY Last Year": 1973
      },
      {
        "Name": "GUELPH TURFGRASS",
        "Province": "ONTARIO",
        "Climate ID": "6143089",
        "Station ID": 45407,
        "WMO ID": 71833,
        "TC ID": "TGT",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 433300000,
        "Longitude": -801300000,
        "Elevation (m)": 325,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "GUELPH OAC PHYSICS DEPT",
        "Province": "ONTARIO",
        "Climate ID": "614308C",
        "Station ID": 4773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -80.27,
        "Latitude": 433300000,
        "Longitude": -801600000,
        "Elevation (m)": 340.5,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "GUELPH TURFGRASS CS",
        "Province": "ONTARIO",
        "Climate ID": "6143090",
        "Station ID": 27545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 433300000,
        "Longitude": -801300000,
        "Elevation (m)": 325,
        "First Year": 1995,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2004,
        "MLY First Year": 1995,
        "MLY Last Year": 2004
      },
      {
        "Name": "GUELPH TURFGRASS INSTITUTE",
        "Province": "ONTARIO",
        "Climate ID": "6143092",
        "Station ID": 55299,
        "WMO ID": 73073,
        "TC ID": "OGI",
        "Latitude (Decimal Degrees)": 43.54,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 433230741,
        "Longitude": -801319492,
        "Elevation (m)": 334.55,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HARLEY",
        "Province": "ONTARIO",
        "Climate ID": "6143355",
        "Station ID": 4775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.07,
        "Longitude (Decimal Degrees)": -80.48,
        "Latitude": 430400000,
        "Longitude": -802900000,
        "Elevation (m)": 259.1,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "HAYSVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6143395",
        "Station ID": 4776,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -80.63,
        "Latitude": 432100000,
        "Longitude": -803800000,
        "Elevation (m)": 320,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "HILLSBURGH",
        "Province": "ONTARIO",
        "Climate ID": "6143465",
        "Station ID": 4777,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -80.17,
        "Latitude": 434600000,
        "Longitude": -801000000,
        "Elevation (m)": 427,
        "First Year": 1981,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1992,
        "MLY First Year": 1981,
        "MLY Last Year": 1992
      },
      {
        "Name": "ILDERTON",
        "Province": "ONTARIO",
        "Climate ID": "6143720",
        "Station ID": 4778,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -81.38,
        "Latitude": 430700000,
        "Longitude": -812300000,
        "Elevation (m)": 284.4,
        "First Year": 1951,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1956,
        "MLY First Year": 1951,
        "MLY Last Year": 1956
      },
      {
        "Name": "ILDERTON BEAR CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6143722",
        "Station ID": 4779,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.05,
        "Longitude (Decimal Degrees)": -81.43,
        "Latitude": 430300000,
        "Longitude": -812600000,
        "Elevation (m)": 266.7,
        "First Year": 1971,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2001,
        "MLY First Year": 1971,
        "MLY Last Year": 2001
      },
      {
        "Name": "INGERSOLL",
        "Province": "ONTARIO",
        "Climate ID": "6143780",
        "Station ID": 4780,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.05,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 430300000,
        "Longitude": -805300000,
        "Elevation (m)": 266.7,
        "First Year": 1870,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1969,
        "MLY First Year": 1870,
        "MLY Last Year": 1969
      },
      {
        "Name": "JAMESTOWN",
        "Province": "ONTARIO",
        "Climate ID": "6143905",
        "Station ID": 44787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -81.18,
        "Latitude": 434809084,
        "Longitude": -811101032,
        "Elevation (m)": 319,
        "First Year": 2006,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2015,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "KITCHENER",
        "Province": "ONTARIO",
        "Climate ID": "6144232",
        "Station ID": 4781,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.43,
        "Longitude (Decimal Degrees)": -80.5,
        "Latitude": 432600000,
        "Longitude": -803000000,
        "Elevation (m)": 342.9,
        "First Year": 1914,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1977,
        "MLY First Year": 1914,
        "MLY Last Year": 1977
      },
      {
        "Name": "KITCHENER/WATERLOO",
        "Province": "ONTARIO",
        "Climate ID": "6144239",
        "Station ID": 48569,
        "WMO ID": 71368,
        "TC ID": "YKF",
        "Latitude (Decimal Degrees)": 43.46,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 432739000,
        "Longitude": -802243000,
        "Elevation (m)": 321.6,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KITCHENER CITY ENG 1",
        "Province": "ONTARIO",
        "Climate ID": "6144240",
        "Station ID": 4782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -80.48,
        "Latitude": 432700000,
        "Longitude": -802900000,
        "Elevation (m)": 320,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1954,
        "MLY Last Year": 1961
      },
      {
        "Name": "KITCHENER CITY ENG 2",
        "Province": "ONTARIO",
        "Climate ID": "6144241",
        "Station ID": 4783,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -80.48,
        "Latitude": 432700000,
        "Longitude": -802900000,
        "Elevation (m)": 320,
        "First Year": 1961,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1961,
        "MLY First Year": 1961,
        "MLY Last Year": 1961
      },
      {
        "Name": "KITCHENER OWRC",
        "Province": "ONTARIO",
        "Climate ID": "6144245",
        "Station ID": 4784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.4,
        "Longitude (Decimal Degrees)": -80.43,
        "Latitude": 432400000,
        "Longitude": -802600000,
        "Elevation (m)": 281.9,
        "First Year": 1962,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1975,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "KOMOKA",
        "Province": "ONTARIO",
        "Climate ID": "6144261",
        "Station ID": 4785,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.95,
        "Longitude (Decimal Degrees)": -81.45,
        "Latitude": 425700000,
        "Longitude": -812700000,
        "Elevation (m)": 240.8,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "LISTOWEL",
        "Province": "ONTARIO",
        "Climate ID": "6144442",
        "Station ID": 4786,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -80.97,
        "Latitude": 434500000,
        "Longitude": -805800000,
        "Elevation (m)": 381,
        "First Year": 1880,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1966,
        "MLY First Year": 1880,
        "MLY Last Year": 1966
      },
      {
        "Name": "LISTOWEL ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6144444",
        "Station ID": 4787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -80.95,
        "Latitude": 434300000,
        "Longitude": -805700000,
        "Elevation (m)": 390,
        "First Year": 1983,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1987,
        "MLY First Year": 1983,
        "MLY Last Year": 1987
      },
      {
        "Name": "LONDON",
        "Province": "ONTARIO",
        "Climate ID": "6144470",
        "Station ID": 4788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.98,
        "Longitude (Decimal Degrees)": -81.2,
        "Latitude": 425900000,
        "Longitude": -811200000,
        "Elevation (m)": 246.3,
        "First Year": 1871,
        "Last Year": 1891,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1891,
        "MLY First Year": 1871,
        "MLY Last Year": 1891
      },
      {
        "Name": "LONDON A",
        "Province": "ONTARIO",
        "Climate ID": "6144473",
        "Station ID": 50093,
        "WMO ID": 71623,
        "TC ID": "YXU",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -81.15,
        "Latitude": 430159000,
        "Longitude": -810904000,
        "Elevation (m)": 278,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LONDON INT'L AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6144475",
        "Station ID": 4789,
        "WMO ID": 71623,
        "TC ID": "YXU",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -81.15,
        "Latitude": 430159000,
        "Longitude": -810904000,
        "Elevation (m)": 278,
        "First Year": 1940,
        "Last Year": 2017,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1940,
        "DLY Last Year": 2017,
        "MLY First Year": 1940,
        "MLY Last Year": 2006
      },
      {
        "Name": "LONDON CS",
        "Province": "ONTARIO",
        "Climate ID": "6144478",
        "Station ID": 10999,
        "WMO ID": 71622,
        "TC ID": "WSN",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -81.15,
        "Latitude": 430200000,
        "Longitude": -810900000,
        "Elevation (m)": 278,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "LONDON LAMBETH A",
        "Province": "ONTARIO",
        "Climate ID": "6144481",
        "Station ID": 4790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.98,
        "Longitude (Decimal Degrees)": -81.22,
        "Latitude": 425900000,
        "Longitude": -811300000,
        "Elevation (m)": 246.3,
        "First Year": 1930,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1941,
        "MLY First Year": 1930,
        "MLY Last Year": 1941
      },
      {
        "Name": "LONDON NW",
        "Province": "ONTARIO",
        "Climate ID": "6144483",
        "Station ID": 28071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -81.37,
        "Latitude": 430200000,
        "Longitude": -812200000,
        "Elevation (m)": 277,
        "First Year": 1999,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2001,
        "MLY First Year": 1999,
        "MLY Last Year": 2001
      },
      {
        "Name": "LONDON SHARON DRIVE",
        "Province": "ONTARIO",
        "Climate ID": "6144500",
        "Station ID": 4791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.03,
        "Longitude (Decimal Degrees)": -81.28,
        "Latitude": 430200000,
        "Longitude": -811700000,
        "Elevation (m)": 274.3,
        "First Year": 1956,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1993,
        "MLY First Year": 1956,
        "MLY Last Year": 1993
      },
      {
        "Name": "LONDON SOUTH",
        "Province": "ONTARIO",
        "Climate ID": "6144505",
        "Station ID": 4792,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.98,
        "Longitude (Decimal Degrees)": -81.22,
        "Latitude": 425900000,
        "Longitude": -811300000,
        "Elevation (m)": 246.3,
        "First Year": 1883,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1932,
        "MLY First Year": 1883,
        "MLY Last Year": 1932
      },
      {
        "Name": "LUCAN",
        "Province": "ONTARIO",
        "Climate ID": "6144665",
        "Station ID": 4793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -81.42,
        "Latitude": 431100000,
        "Longitude": -812500000,
        "Elevation (m)": 297.2,
        "First Year": 1871,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1975,
        "MLY First Year": 1871,
        "MLY Last Year": 1975
      },
      {
        "Name": "LUTHER DAM",
        "Province": "ONTARIO",
        "Climate ID": "6144715",
        "Station ID": 4794,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -80.4,
        "Latitude": 435800000,
        "Longitude": -802400000,
        "Elevation (m)": 483.1,
        "First Year": 1951,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1954,
        "MLY First Year": 1951,
        "MLY Last Year": 1954
      },
      {
        "Name": "METCALFE",
        "Province": "ONTARIO",
        "Climate ID": "6145064",
        "Station ID": 4795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.9,
        "Longitude (Decimal Degrees)": -81.7,
        "Latitude": 425400000,
        "Longitude": -814200000,
        "Elevation (m)": 213.4,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "MILLERS LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6145160",
        "Station ID": 4796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.28,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 431700000,
        "Longitude": -802300000,
        "Elevation (m)": 304.8,
        "First Year": 1964,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1971,
        "MLY First Year": 1964,
        "MLY Last Year": 1971
      },
      {
        "Name": "MITCHELL",
        "Province": "ONTARIO",
        "Climate ID": "6145239",
        "Station ID": 4797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.47,
        "Longitude (Decimal Degrees)": -81.18,
        "Latitude": 432800000,
        "Longitude": -811100000,
        "Elevation (m)": 335.3,
        "First Year": 1948,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1964,
        "MLY First Year": 1948,
        "MLY Last Year": 1964
      },
      {
        "Name": "MONKTON",
        "Province": "ONTARIO",
        "Climate ID": "6145258",
        "Station ID": 26770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -81.03,
        "Latitude": 434000000,
        "Longitude": -810200000,
        "Elevation (m)": 360,
        "First Year": 1994,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1996,
        "MLY First Year": 1994,
        "MLY Last Year": 1996
      },
      {
        "Name": "MONTICELLO",
        "Province": "ONTARIO",
        "Climate ID": "6145267",
        "Station ID": 4798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -80.4,
        "Latitude": 435800000,
        "Longitude": -802400000,
        "Elevation (m)": 481.6,
        "First Year": 1954,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1995,
        "MLY First Year": 1954,
        "MLY Last Year": 1995
      },
      {
        "Name": "MORRISTON",
        "Province": "ONTARIO",
        "Climate ID": "6145495",
        "Station ID": 4799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.47,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 432800000,
        "Longitude": -800700000,
        "Elevation (m)": 304.8,
        "First Year": 1948,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1966,
        "MLY First Year": 1948,
        "MLY Last Year": 1966
      },
      {
        "Name": "MOSSLEY",
        "Province": "ONTARIO",
        "Climate ID": "6145497",
        "Station ID": 4800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.92,
        "Longitude (Decimal Degrees)": -80.97,
        "Latitude": 425500000,
        "Longitude": -805800000,
        "Elevation (m)": 274.3,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "MOUNT BRYDGES",
        "Province": "ONTARIO",
        "Climate ID": "6145499",
        "Station ID": 4801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.9,
        "Longitude (Decimal Degrees)": -81.55,
        "Latitude": 425400000,
        "Longitude": -813300000,
        "Elevation (m)": 238.4,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1961
      },
      {
        "Name": "MOUNT FOREST",
        "Province": "ONTARIO",
        "Climate ID": "6145502",
        "Station ID": 27207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -80.75,
        "Latitude": 435800000,
        "Longitude": -804500000,
        "Elevation (m)": 404,
        "First Year": 1996,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2003,
        "MLY First Year": 1996,
        "MLY Last Year": 2003
      },
      {
        "Name": "MOUNT FOREST",
        "Province": "ONTARIO",
        "Climate ID": "6145503",
        "Station ID": 4802,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -80.75,
        "Latitude": 435900000,
        "Longitude": -804500000,
        "Elevation (m)": 414.5,
        "First Year": 1891,
        "Last Year": 1986,
        "HLY First Year": 1962,
        "HLY Last Year": 1986,
        "DLY First Year": 1891,
        "DLY Last Year": 1986,
        "MLY First Year": 1891,
        "MLY Last Year": 1986
      },
      {
        "Name": "MOUNT FOREST (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6145504",
        "Station ID": 7844,
        "WMO ID": 71631,
        "TC ID": "WLS",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -80.75,
        "Latitude": 435900000,
        "Longitude": -804500000,
        "Elevation (m)": 414.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "MOUNTSBERG",
        "Province": "ONTARIO",
        "Climate ID": "6145516",
        "Station ID": 4803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.47,
        "Longitude (Decimal Degrees)": -80.03,
        "Latitude": 432800000,
        "Longitude": -800200000,
        "Elevation (m)": 304.8,
        "First Year": 1976,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1985,
        "MLY First Year": 1976,
        "MLY Last Year": 1985
      },
      {
        "Name": "MUIR",
        "Province": "ONTARIO",
        "Climate ID": "6145520",
        "Station ID": 4804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.1,
        "Longitude (Decimal Degrees)": -80.58,
        "Latitude": 430600000,
        "Longitude": -803500000,
        "Elevation (m)": null,
        "First Year": 1955,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1956,
        "MLY First Year": 1955,
        "MLY Last Year": 1956
      },
      {
        "Name": "NEWTON",
        "Province": "ONTARIO",
        "Climate ID": "6145621",
        "Station ID": 4805,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -80.88,
        "Latitude": 433500000,
        "Longitude": -805300000,
        "Elevation (m)": 373.4,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "OXFORD CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6146166",
        "Station ID": 4806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.1,
        "Longitude (Decimal Degrees)": -80.68,
        "Latitude": 430600000,
        "Longitude": -804100000,
        "Elevation (m)": 297.2,
        "First Year": 1961,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1965,
        "MLY First Year": 1961,
        "MLY Last Year": 1965
      },
      {
        "Name": "PARIS",
        "Province": "ONTARIO",
        "Climate ID": "6146240",
        "Station ID": 4807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -80.45,
        "Latitude": 431100000,
        "Longitude": -802700000,
        "Elevation (m)": 266.7,
        "First Year": 1870,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1967,
        "MLY First Year": 1870,
        "MLY Last Year": 1967
      },
      {
        "Name": "PARKHILL",
        "Province": "ONTARIO",
        "Climate ID": "6146248",
        "Station ID": 4808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -81.7,
        "Latitude": 431000000,
        "Longitude": -814200000,
        "Elevation (m)": 201.8,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "POPLAR MILLS",
        "Province": "ONTARIO",
        "Climate ID": "6146563",
        "Station ID": 4809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.15,
        "Longitude (Decimal Degrees)": -81.2,
        "Latitude": 430900000,
        "Longitude": -811200000,
        "Elevation (m)": 297.2,
        "First Year": 1956,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1972,
        "MLY First Year": 1956,
        "MLY Last Year": 1972
      },
      {
        "Name": "PRESTON",
        "Province": "ONTARIO",
        "Climate ID": "6146711",
        "Station ID": 4810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.4,
        "Longitude (Decimal Degrees)": -80.42,
        "Latitude": 432400000,
        "Longitude": -802500000,
        "Elevation (m)": 291.1,
        "First Year": 1953,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1996,
        "MLY First Year": 1953,
        "MLY Last Year": 1996
      },
      {
        "Name": "PRESTON WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6146714",
        "Station ID": 4811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.38,
        "Longitude (Decimal Degrees)": -80.35,
        "Latitude": 432300000,
        "Longitude": -802100000,
        "Elevation (m)": 272.8,
        "First Year": 1970,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1997,
        "MLY First Year": 1970,
        "MLY Last Year": 1997
      },
      {
        "Name": "PRIMROSE WRAY",
        "Province": "ONTARIO",
        "Climate ID": "6146723",
        "Station ID": 4812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -80.1,
        "Latitude": 440400000,
        "Longitude": -800600000,
        "Elevation (m)": 464.8,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "PRINCETON",
        "Province": "ONTARIO",
        "Climate ID": "6146728",
        "Station ID": 4813,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -80.63,
        "Latitude": 431300000,
        "Longitude": -803800000,
        "Elevation (m)": 285,
        "First Year": 1883,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1913,
        "MLY First Year": 1883,
        "MLY Last Year": 1913
      },
      {
        "Name": "PROSPECT HILL",
        "Province": "ONTARIO",
        "Climate ID": "6146745",
        "Station ID": 4814,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -81.23,
        "Latitude": 431300000,
        "Longitude": -811400000,
        "Elevation (m)": 312.4,
        "First Year": 1956,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1973,
        "MLY First Year": 1956,
        "MLY Last Year": 1973
      },
      {
        "Name": "REDICKVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6146939",
        "Station ID": 4815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -80.22,
        "Latitude": 441400000,
        "Longitude": -801300000,
        "Elevation (m)": 525.8,
        "First Year": 1944,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1985,
        "MLY First Year": 1944,
        "MLY Last Year": 1985
      },
      {
        "Name": "ROSEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6147188",
        "Station ID": 4816,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -80.47,
        "Latitude": 432113026,
        "Longitude": -802825056,
        "Elevation (m)": 328,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "RUSKVIEW",
        "Province": "ONTARIO",
        "Climate ID": "6147229",
        "Station ID": 4817,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -80.13,
        "Latitude": 441400000,
        "Longitude": -800800000,
        "Elevation (m)": 472.4,
        "First Year": 1985,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2007,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST GEORGE",
        "Province": "ONTARIO",
        "Climate ID": "6147314",
        "Station ID": 4818,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.23,
        "Longitude (Decimal Degrees)": -80.2,
        "Latitude": 431400000,
        "Longitude": -801200000,
        "Elevation (m)": 220.1,
        "First Year": 1883,
        "Last Year": 1901,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1901,
        "MLY First Year": 1883,
        "MLY Last Year": 1901
      },
      {
        "Name": "ST MARYS",
        "Province": "ONTARIO",
        "Climate ID": "6147340",
        "Station ID": 4819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -81.18,
        "Latitude": 431500000,
        "Longitude": -811100000,
        "Elevation (m)": 317,
        "First Year": 1888,
        "Last Year": 1903,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1903,
        "MLY First Year": 1888,
        "MLY Last Year": 1903
      },
      {
        "Name": "SALEM",
        "Province": "ONTARIO",
        "Climate ID": "6147410",
        "Station ID": 27530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.71,
        "Longitude (Decimal Degrees)": -80.47,
        "Latitude": 434234086,
        "Longitude": -802811016,
        "Elevation (m)": 430,
        "First Year": 1997,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2005,
        "MLY First Year": 1997,
        "MLY Last Year": 2005
      },
      {
        "Name": "SCOTLAND",
        "Province": "ONTARIO",
        "Climate ID": "6147664",
        "Station ID": 4820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43,
        "Longitude (Decimal Degrees)": -80.43,
        "Latitude": 430003000,
        "Longitude": -802540000,
        "Elevation (m)": 247,
        "First Year": 1971,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2014,
        "MLY First Year": 1971,
        "MLY Last Year": 2006
      },
      {
        "Name": "SHELBURNE WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6147693",
        "Station ID": 4821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -80.18,
        "Latitude": 440500000,
        "Longitude": -801100000,
        "Elevation (m)": 484.9,
        "First Year": 1981,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1994
      },
      {
        "Name": "STRATFORD",
        "Province": "ONTARIO",
        "Climate ID": "6148100",
        "Station ID": 4822,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.38,
        "Longitude (Decimal Degrees)": -81,
        "Latitude": 432300000,
        "Longitude": -810000000,
        "Elevation (m)": 363,
        "First Year": 1865,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1865,
        "DLY Last Year": 1959,
        "MLY First Year": 1865,
        "MLY Last Year": 1959
      },
      {
        "Name": "STRATFORD WWTP",
        "Province": "ONTARIO",
        "Climate ID": "6148105",
        "Station ID": 4823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.37,
        "Longitude (Decimal Degrees)": -81,
        "Latitude": 432208016,
        "Longitude": -810017058,
        "Elevation (m)": 345,
        "First Year": 1959,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2016,
        "MLY First Year": 1959,
        "MLY Last Year": 2006
      },
      {
        "Name": "STRATHBURN",
        "Province": "ONTARIO",
        "Climate ID": "6148115",
        "Station ID": 4824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.72,
        "Longitude (Decimal Degrees)": -81.68,
        "Latitude": 424300000,
        "Longitude": -814100000,
        "Elevation (m)": 214.9,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "STRATHROY",
        "Province": "ONTARIO",
        "Climate ID": "6148120",
        "Station ID": 4825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.95,
        "Longitude (Decimal Degrees)": -81.65,
        "Latitude": 425700000,
        "Longitude": -813900000,
        "Elevation (m)": 228.6,
        "First Year": 1879,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1879,
        "DLY Last Year": 1996,
        "MLY First Year": 1879,
        "MLY Last Year": 1996
      },
      {
        "Name": "STRATHROY-MULLIFARRY",
        "Province": "ONTARIO",
        "Climate ID": "6148122",
        "Station ID": 27647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.98,
        "Longitude (Decimal Degrees)": -81.64,
        "Latitude": 425850022,
        "Longitude": -813834086,
        "Elevation (m)": 243,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "TAVISTOCK",
        "Province": "ONTARIO",
        "Climate ID": "6148212",
        "Station ID": 4826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.32,
        "Longitude (Decimal Degrees)": -80.83,
        "Latitude": 431900000,
        "Longitude": -805000000,
        "Elevation (m)": 343.2,
        "First Year": 1967,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1988,
        "MLY First Year": 1967,
        "MLY Last Year": 1988
      },
      {
        "Name": "THAMESFORD",
        "Province": "ONTARIO",
        "Climate ID": "6148233",
        "Station ID": 4827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.07,
        "Longitude (Decimal Degrees)": -81,
        "Latitude": 430400000,
        "Longitude": -810000000,
        "Elevation (m)": 289.6,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "VIOLET HILL",
        "Province": "ONTARIO",
        "Climate ID": "6149148",
        "Station ID": 4828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -80.07,
        "Latitude": 440400000,
        "Longitude": -800400000,
        "Elevation (m)": 434.3,
        "First Year": 1963,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1968,
        "MLY First Year": 1963,
        "MLY Last Year": 1968
      },
      {
        "Name": "WALDEMAR",
        "Province": "ONTARIO",
        "Climate ID": "6149205",
        "Station ID": 4829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -80.28,
        "Latitude": 435300000,
        "Longitude": -801700000,
        "Elevation (m)": 449.6,
        "First Year": 1955,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1985,
        "MLY First Year": 1955,
        "MLY Last Year": 1984
      },
      {
        "Name": "WATERLOO FIRE HALL",
        "Province": "ONTARIO",
        "Climate ID": "6149380",
        "Station ID": 4830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.47,
        "Longitude (Decimal Degrees)": -80.52,
        "Latitude": 432800000,
        "Longitude": -803100000,
        "Elevation (m)": 317,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "WATERLOO WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6149386",
        "Station ID": 4831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.48,
        "Longitude (Decimal Degrees)": -80.52,
        "Latitude": 432900000,
        "Longitude": -803100000,
        "Elevation (m)": 327.7,
        "First Year": 1962,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2000,
        "MLY First Year": 1962,
        "MLY Last Year": 2000
      },
      {
        "Name": "WATERLOO WELLINGTON A",
        "Province": "ONTARIO",
        "Climate ID": "6149387",
        "Station ID": 4832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 432700000,
        "Longitude": -802300000,
        "Elevation (m)": 317,
        "First Year": 1966,
        "Last Year": 2003,
        "HLY First Year": 1966,
        "HLY Last Year": 2002,
        "DLY First Year": 1970,
        "DLY Last Year": 2003,
        "MLY First Year": 1970,
        "MLY Last Year": 2002
      },
      {
        "Name": "REGION OF WATERLOO INT'L AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6149388",
        "Station ID": 32008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.46,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 432732000,
        "Longitude": -802239000,
        "Elevation (m)": 321.26,
        "First Year": 2002,
        "Last Year": 2011,
        "HLY First Year": 2002,
        "HLY Last Year": 2010,
        "DLY First Year": 2002,
        "DLY Last Year": 2011,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "WATERLOO WELLINGTON 2",
        "Province": "ONTARIO",
        "Climate ID": "6149389",
        "Station ID": 42424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -80.38,
        "Latitude": 432700000,
        "Longitude": -802300000,
        "Elevation (m)": 313.6,
        "First Year": 2003,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2017,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "WESTMINSTER",
        "Province": "ONTARIO",
        "Climate ID": "6149454",
        "Station ID": 4833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.93,
        "Longitude (Decimal Degrees)": -81.28,
        "Latitude": 425600000,
        "Longitude": -811700000,
        "Elevation (m)": 266.7,
        "First Year": 1882,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1933,
        "MLY First Year": 1882,
        "MLY Last Year": 1933
      },
      {
        "Name": "WESTMINSTER TWP WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6149455",
        "Station ID": 4834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.92,
        "Longitude (Decimal Degrees)": -81.22,
        "Latitude": 425500000,
        "Longitude": -811300000,
        "Elevation (m)": 259.1,
        "First Year": 1961,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1994,
        "MLY First Year": 1961,
        "MLY Last Year": 1994
      },
      {
        "Name": "WOODSTOCK",
        "Province": "ONTARIO",
        "Climate ID": "6149625",
        "Station ID": 4835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.14,
        "Longitude (Decimal Degrees)": -80.77,
        "Latitude": 430810044,
        "Longitude": -804614040,
        "Elevation (m)": 281.9,
        "First Year": 1870,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 2022,
        "MLY First Year": 1870,
        "MLY Last Year": 2006
      },
      {
        "Name": "WOODSTOCK GOLF COURSE",
        "Province": "ONTARIO",
        "Climate ID": "6149630",
        "Station ID": 4836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -80.78,
        "Latitude": 431200000,
        "Longitude": -804700000,
        "Elevation (m)": 317,
        "First Year": 1960,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1965,
        "MLY First Year": 1960,
        "MLY Last Year": 1965
      },
      {
        "Name": "WOODSTOCK WATERWORKS",
        "Province": "ONTARIO",
        "Climate ID": "6149645",
        "Station ID": 4837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.13,
        "Longitude (Decimal Degrees)": -80.77,
        "Latitude": 430800000,
        "Longitude": -804600000,
        "Elevation (m)": 283.2,
        "First Year": 1959,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1966,
        "MLY First Year": 1959,
        "MLY Last Year": 1966
      },
      {
        "Name": "ELORA AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "614B2H4",
        "Station ID": 4723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -80.42,
        "Latitude": 433900000,
        "Longitude": -802500000,
        "Elevation (m)": 376.4,
        "First Year": 1986,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2003,
        "MLY First Year": 1986,
        "MLY Last Year": 2003
      },
      {
        "Name": "HARRISTON",
        "Province": "ONTARIO",
        "Climate ID": "614CCNR",
        "Station ID": 6908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -80.8,
        "Latitude": 435400000,
        "Longitude": -804800000,
        "Elevation (m)": 401,
        "First Year": 1992,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1995,
        "MLY First Year": 1992,
        "MLY Last Year": 1995
      },
      {
        "Name": "NILESTOWN",
        "Province": "ONTARIO",
        "Climate ID": "614N003",
        "Station ID": 27322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 42.98,
        "Longitude (Decimal Degrees)": -81.08,
        "Latitude": 425900000,
        "Longitude": -810500000,
        "Elevation (m)": 265,
        "First Year": 1997,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2001,
        "MLY First Year": 1997,
        "MLY Last Year": 2001
      },
      {
        "Name": "NEWTON",
        "Province": "ONTARIO",
        "Climate ID": "614NFK0",
        "Station ID": 4724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -80.9,
        "Latitude": 433500000,
        "Longitude": -805400000,
        "Elevation (m)": 382,
        "First Year": 1986,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2000,
        "MLY First Year": 1986,
        "MLY Last Year": 2000
      },
      {
        "Name": "ACTON BEARDMORE",
        "Province": "ONTARIO",
        "Climate ID": "6150039",
        "Station ID": 4849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -80.03,
        "Latitude": 433800000,
        "Longitude": -800200000,
        "Elevation (m)": 320,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "ALBERTON",
        "Province": "ONTARIO",
        "Climate ID": "6150060",
        "Station ID": 26775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.18,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 431100000,
        "Longitude": -800300000,
        "Elevation (m)": 215,
        "First Year": 1994,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2008,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "ALBION",
        "Province": "ONTARIO",
        "Climate ID": "6150100",
        "Station ID": 4850,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 435600000,
        "Longitude": -795000000,
        "Elevation (m)": 274.3,
        "First Year": 1956,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2000,
        "MLY First Year": 1956,
        "MLY Last Year": 2000
      },
      {
        "Name": "ALBION FIELD CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6150103",
        "Station ID": 4851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 435500000,
        "Longitude": -795000000,
        "Elevation (m)": 281.9,
        "First Year": 1969,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2001,
        "MLY First Year": 1969,
        "MLY Last Year": 2001
      },
      {
        "Name": "ALDERSHOT",
        "Province": "ONTARIO",
        "Climate ID": "6150135",
        "Station ID": 4852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.32,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 431900000,
        "Longitude": -795200000,
        "Elevation (m)": 144.8,
        "First Year": 1947,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1977,
        "MLY First Year": 1947,
        "MLY Last Year": 1977
      },
      {
        "Name": "ALLOA",
        "Province": "ONTARIO",
        "Climate ID": "6150224",
        "Station ID": 4853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 434300000,
        "Longitude": -795200000,
        "Elevation (m)": 262.1,
        "First Year": 1951,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1954,
        "MLY First Year": 1951,
        "MLY Last Year": 1954
      },
      {
        "Name": "ALTON",
        "Province": "ONTARIO",
        "Climate ID": "6150243",
        "Station ID": 4854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.85,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 435100000,
        "Longitude": -800500000,
        "Elevation (m)": 401.4,
        "First Year": 1887,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1887,
        "DLY Last Year": 1964,
        "MLY First Year": 1887,
        "MLY Last Year": 1963
      },
      {
        "Name": "AURORA",
        "Province": "ONTARIO",
        "Climate ID": "6150395",
        "Station ID": 4855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 435700000,
        "Longitude": -792400000,
        "Elevation (m)": 270.1,
        "First Year": 1883,
        "Last Year": 1919,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1919,
        "MLY First Year": 1883,
        "MLY Last Year": 1919
      },
      {
        "Name": "AURORA MOE",
        "Province": "ONTARIO",
        "Climate ID": "6150396",
        "Station ID": 4856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 440100000,
        "Longitude": -792800000,
        "Elevation (m)": 259.1,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "AURORA PAYMENT",
        "Province": "ONTARIO",
        "Climate ID": "6150397",
        "Station ID": 4857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 435900000,
        "Longitude": -792800000,
        "Elevation (m)": 281.9,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "AURORA NE",
        "Province": "ONTARIO",
        "Climate ID": "6150398",
        "Station ID": 41803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 440100000,
        "Longitude": -792700000,
        "Elevation (m)": 260,
        "First Year": 2003,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2009,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "BALLANTRAE",
        "Province": "ONTARIO",
        "Climate ID": "6150460",
        "Station ID": 4858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 440300000,
        "Longitude": -791800000,
        "Elevation (m)": 327.7,
        "First Year": 1979,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1992,
        "MLY First Year": 1979,
        "MLY Last Year": 1992
      },
      {
        "Name": "BELLEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6150689",
        "Station ID": 4859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -77.39,
        "Latitude": 440902052,
        "Longitude": -772341046,
        "Elevation (m)": 76.2,
        "First Year": 1866,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 2022,
        "MLY First Year": 1866,
        "MLY Last Year": 2006
      },
      {
        "Name": "BELLEVILLE QUINTE",
        "Province": "ONTARIO",
        "Climate ID": "6150690",
        "Station ID": 55238,
        "WMO ID": 73042,
        "TC ID": "OBQ",
        "Latitude (Decimal Degrees)": 44.16,
        "Longitude (Decimal Degrees)": -77.44,
        "Latitude": 440933705,
        "Longitude": -772623430,
        "Elevation (m)": 96.28,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BELLEVILLE OWRC",
        "Province": "ONTARIO",
        "Climate ID": "6150700",
        "Station ID": 4860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -77.37,
        "Latitude": 441000000,
        "Longitude": -772200000,
        "Elevation (m)": 77.7,
        "First Year": 1959,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1975,
        "MLY First Year": 1959,
        "MLY Last Year": 1975
      },
      {
        "Name": "BELLEVILLE PAR LAB",
        "Province": "ONTARIO",
        "Climate ID": "6150717",
        "Station ID": 4861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -77.35,
        "Latitude": 441000000,
        "Longitude": -772100000,
        "Elevation (m)": 88.4,
        "First Year": 1929,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1959,
        "MLY First Year": 1929,
        "MLY Last Year": 1959
      },
      {
        "Name": "BLACKSTOCK",
        "Province": "ONTARIO",
        "Climate ID": "6150790",
        "Station ID": 31048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 440600000,
        "Longitude": -785000000,
        "Elevation (m)": 291,
        "First Year": 2001,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2016,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "BLOOMFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6150815",
        "Station ID": 4862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -77.22,
        "Latitude": 435900000,
        "Longitude": -771300000,
        "Elevation (m)": 91.4,
        "First Year": 1896,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 2014,
        "MLY First Year": 1896,
        "MLY Last Year": 2006
      },
      {
        "Name": "BLOOMFIELD WEST",
        "Province": "ONTARIO",
        "Climate ID": "6150816",
        "Station ID": 4863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 435900000,
        "Longitude": -771500000,
        "Elevation (m)": 90,
        "First Year": 1966,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1997,
        "MLY First Year": 1966,
        "MLY Last Year": 1997
      },
      {
        "Name": "BOLTON SPS",
        "Province": "ONTARIO",
        "Climate ID": "6150825",
        "Station ID": 4864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -79.73,
        "Latitude": 435300000,
        "Longitude": -794400000,
        "Elevation (m)": 213.1,
        "First Year": 1978,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1992,
        "MLY First Year": 1978,
        "MLY Last Year": 1992
      },
      {
        "Name": "BOWMANVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6150829",
        "Station ID": 4865,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -78.65,
        "Latitude": 435500000,
        "Longitude": -783900000,
        "Elevation (m)": 121.9,
        "First Year": 1947,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1957,
        "MLY First Year": 1947,
        "MLY Last Year": 1957
      },
      {
        "Name": "BOWMANVILLE MOSTERT",
        "Province": "ONTARIO",
        "Climate ID": "6150830",
        "Station ID": 4866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -78.67,
        "Latitude": 435500000,
        "Longitude": -784000000,
        "Elevation (m)": 99.1,
        "First Year": 1966,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2002,
        "MLY First Year": 1966,
        "MLY Last Year": 2002
      },
      {
        "Name": "BRADFORD",
        "Province": "ONTARIO",
        "Climate ID": "6150857",
        "Station ID": 4867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 440600000,
        "Longitude": -793000000,
        "Elevation (m)": 220.7,
        "First Year": 1951,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1957,
        "MLY First Year": 1951,
        "MLY Last Year": 1957
      },
      {
        "Name": "BRADFORD 2",
        "Province": "ONTARIO",
        "Climate ID": "6150858",
        "Station ID": 4868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.53,
        "Latitude": 440700000,
        "Longitude": -793200000,
        "Elevation (m)": 221,
        "First Year": 1954,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1958,
        "MLY First Year": 1954,
        "MLY Last Year": 1958
      },
      {
        "Name": "BRADFORD HIGH SCHOOL",
        "Province": "ONTARIO",
        "Climate ID": "6150860",
        "Station ID": 4869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 440700000,
        "Longitude": -793000000,
        "Elevation (m)": 251.5,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "BRADFORD MUCK RESEARCH",
        "Province": "ONTARIO",
        "Climate ID": "6150863",
        "Station ID": 4870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 440200000,
        "Longitude": -793600000,
        "Elevation (m)": 221,
        "First Year": 1974,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1998,
        "MLY First Year": 1974,
        "MLY Last Year": 1998
      },
      {
        "Name": "BRADFORD OAC",
        "Province": "ONTARIO",
        "Climate ID": "6150865",
        "Station ID": 4871,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 440600000,
        "Longitude": -793600000,
        "Elevation (m)": 243.8,
        "First Year": 1960,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1966,
        "MLY First Year": 1960,
        "MLY Last Year": 1966
      },
      {
        "Name": "BRADFORD ODA",
        "Province": "ONTARIO",
        "Climate ID": "6150866",
        "Station ID": 4872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 440700000,
        "Longitude": -793300000,
        "Elevation (m)": 221,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "BRADFORD SPRINGDALE",
        "Province": "ONTARIO",
        "Climate ID": "6150867",
        "Station ID": 4873,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 440200000,
        "Longitude": -793700000,
        "Elevation (m)": 221,
        "First Year": 1966,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1981
      },
      {
        "Name": "BRAMALEA",
        "Province": "ONTARIO",
        "Climate ID": "6150898",
        "Station ID": 4874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.72,
        "Latitude": 434100000,
        "Longitude": -794300000,
        "Elevation (m)": 205.7,
        "First Year": 1967,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1971,
        "MLY First Year": 1967,
        "MLY Last Year": 1971
      },
      {
        "Name": "BRAMPTON",
        "Province": "ONTARIO",
        "Climate ID": "6150913",
        "Station ID": 4875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 434200000,
        "Longitude": -794600000,
        "Elevation (m)": 221,
        "First Year": 1870,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1964,
        "MLY First Year": 1871,
        "MLY Last Year": 1964
      },
      {
        "Name": "BRAMPTON AIRPORT",
        "Province": "ONTARIO",
        "Climate ID": "6150914",
        "Station ID": 6953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.88,
        "Latitude": 434600000,
        "Longitude": -795300000,
        "Elevation (m)": 274.3,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "BRAMPTON MOE",
        "Province": "ONTARIO",
        "Climate ID": "6150916",
        "Station ID": 4876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 434000000,
        "Longitude": -794200000,
        "Elevation (m)": 182.9,
        "First Year": 1962,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1993,
        "MLY First Year": 1963,
        "MLY Last Year": 1993
      },
      {
        "Name": "BRIGHTON",
        "Province": "ONTARIO",
        "Climate ID": "6150961",
        "Station ID": 4878,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -77.73,
        "Latitude": 440400000,
        "Longitude": -774400000,
        "Elevation (m)": 92.7,
        "First Year": 1948,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1950,
        "MLY First Year": 1948,
        "MLY Last Year": 1950
      },
      {
        "Name": "BRODDYTOWN",
        "Province": "ONTARIO",
        "Climate ID": "6150997",
        "Station ID": 4879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 433700000,
        "Longitude": -793600000,
        "Elevation (m)": 144.8,
        "First Year": 1951,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1956,
        "MLY First Year": 1951,
        "MLY Last Year": 1956
      },
      {
        "Name": "BLUE ACTON",
        "Province": "ONTARIO",
        "Climate ID": "6150HA7",
        "Station ID": 4848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.6,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 433600000,
        "Longitude": -800300000,
        "Elevation (m)": 365.8,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "BROUGHAM",
        "Province": "ONTARIO",
        "Climate ID": "6151000",
        "Station ID": 4880,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -79.12,
        "Latitude": 435500000,
        "Longitude": -790700000,
        "Elevation (m)": 198.1,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "BURKETON MCLAUGHLIN",
        "Province": "ONTARIO",
        "Climate ID": "6151042",
        "Station ID": 4881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -78.8,
        "Latitude": 440200000,
        "Longitude": -784800000,
        "Elevation (m)": 312.4,
        "First Year": 1969,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2002,
        "MLY First Year": 1969,
        "MLY Last Year": 2002
      },
      {
        "Name": "BURLINGTON",
        "Province": "ONTARIO",
        "Climate ID": "6151053",
        "Station ID": 4882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -79.8,
        "Latitude": 432100000,
        "Longitude": -794800000,
        "Elevation (m)": 105.2,
        "First Year": 1947,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1974,
        "MLY First Year": 1947,
        "MLY Last Year": 1974
      },
      {
        "Name": "BURLINGTON DRURY LANE",
        "Province": "ONTARIO",
        "Climate ID": "6151055",
        "Station ID": 4883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -79.8,
        "Latitude": 432000000,
        "Longitude": -794800000,
        "Elevation (m)": 91.4,
        "First Year": 1961,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1970,
        "MLY First Year": 1961,
        "MLY Last Year": 1970
      },
      {
        "Name": "BURLINGTON ELIZABETH GDN",
        "Province": "ONTARIO",
        "Climate ID": "6151057",
        "Station ID": 4884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.37,
        "Longitude (Decimal Degrees)": -79.73,
        "Latitude": 432200000,
        "Longitude": -794400000,
        "Elevation (m)": 83.8,
        "First Year": 1961,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1977,
        "MLY First Year": 1961,
        "MLY Last Year": 1977
      },
      {
        "Name": "BURLINGTON FIRE HQ'S",
        "Province": "ONTARIO",
        "Climate ID": "6151059",
        "Station ID": 4885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -79.82,
        "Latitude": 432100000,
        "Longitude": -794900000,
        "Elevation (m)": 114.3,
        "First Year": 1970,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1983,
        "MLY First Year": 1970,
        "MLY Last Year": 1983
      },
      {
        "Name": "BURLINGTON PIERS (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6151061",
        "Station ID": 7868,
        "WMO ID": 71437,
        "TC ID": "WWB",
        "Latitude (Decimal Degrees)": 43.3,
        "Longitude (Decimal Degrees)": -79.8,
        "Latitude": 431800000,
        "Longitude": -794800000,
        "Elevation (m)": 77.4,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BURLINGTON SKYWAY",
        "Province": "ONTARIO",
        "Climate ID": "6151063",
        "Station ID": 4886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.32,
        "Longitude (Decimal Degrees)": -79.8,
        "Latitude": 431900000,
        "Longitude": -794800000,
        "Elevation (m)": 76.2,
        "First Year": 1968,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1970,
        "MLY First Year": 1968,
        "MLY Last Year": 1970
      },
      {
        "Name": "BURLINGTON TS",
        "Province": "ONTARIO",
        "Climate ID": "6151064",
        "Station ID": 4887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 432000000,
        "Longitude": -795000000,
        "Elevation (m)": 99.1,
        "First Year": 1951,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1999,
        "MLY First Year": 1951,
        "MLY Last Year": 1999
      },
      {
        "Name": "BURNHAMTHORPE",
        "Province": "ONTARIO",
        "Climate ID": "6151065",
        "Station ID": 4888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 433700000,
        "Longitude": -793600000,
        "Elevation (m)": 144.8,
        "First Year": 1951,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1955,
        "MLY First Year": 1951,
        "MLY Last Year": 1955
      },
      {
        "Name": "CALEDON",
        "Province": "ONTARIO",
        "Climate ID": "6151079",
        "Station ID": 4889,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 435200000,
        "Longitude": -795600000,
        "Elevation (m)": 384,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "CALEDON NORTH",
        "Province": "ONTARIO",
        "Climate ID": "6151080",
        "Station ID": 27485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -79.95,
        "Latitude": 435600000,
        "Longitude": -795700000,
        "Elevation (m)": 406,
        "First Year": 1998,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2006,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "CALEDON VILLAGE",
        "Province": "ONTARIO",
        "Climate ID": "6151082",
        "Station ID": 11008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.85,
        "Longitude (Decimal Degrees)": -80,
        "Latitude": 435100000,
        "Longitude": -800000000,
        "Elevation (m)": 423,
        "First Year": 1993,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1995,
        "MLY First Year": 1993,
        "MLY Last Year": 1995
      },
      {
        "Name": "CAMBORNE",
        "Province": "ONTARIO",
        "Climate ID": "6151090",
        "Station ID": 6910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -78.22,
        "Latitude": 440300000,
        "Longitude": -781300000,
        "Elevation (m)": 175.3,
        "First Year": 1991,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2002,
        "MLY First Year": 1991,
        "MLY Last Year": 2002
      },
      {
        "Name": "CAMPBELLCROFT",
        "Province": "ONTARIO",
        "Climate ID": "6151135",
        "Station ID": 4890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -78.38,
        "Latitude": 440600000,
        "Longitude": -782300000,
        "Elevation (m)": 198.1,
        "First Year": 1961,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1964
      },
      {
        "Name": "CAMPBELLCROFT GANARASKA",
        "Province": "ONTARIO",
        "Climate ID": "6151136",
        "Station ID": 4891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -78.5,
        "Latitude": 440400000,
        "Longitude": -783000000,
        "Elevation (m)": 259.1,
        "First Year": 1979,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1992,
        "MLY First Year": 1979,
        "MLY Last Year": 1992
      },
      {
        "Name": "CAMPBELLFORD",
        "Province": "ONTARIO",
        "Climate ID": "6151137",
        "Station ID": 4892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.3,
        "Longitude (Decimal Degrees)": -77.8,
        "Latitude": 441800000,
        "Longitude": -774800000,
        "Elevation (m)": 146.3,
        "First Year": 1915,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1997,
        "MLY First Year": 1915,
        "MLY Last Year": 1997
      },
      {
        "Name": "CAMPBELLS CROSS",
        "Province": "ONTARIO",
        "Climate ID": "6151140",
        "Station ID": 4893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 434800000,
        "Longitude": -795200000,
        "Elevation (m)": 281.9,
        "First Year": 1951,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1951,
        "MLY First Year": 1951,
        "MLY Last Year": 1951
      },
      {
        "Name": "CAMPBELLVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6151141",
        "Station ID": 4894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.47,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 432800000,
        "Longitude": -795600000,
        "Elevation (m)": 259.1,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "CASTLETON",
        "Province": "ONTARIO",
        "Climate ID": "6151264",
        "Station ID": 4895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -77.88,
        "Latitude": 440700000,
        "Longitude": -775300000,
        "Elevation (m)": 167.6,
        "First Year": 1972,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1973,
        "MLY First Year": 1972,
        "MLY Last Year": 1973
      },
      {
        "Name": "CEDAR VALLEY",
        "Province": "ONTARIO",
        "Climate ID": "6151272",
        "Station ID": 4896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 440400000,
        "Longitude": -792200000,
        "Elevation (m)": 283.5,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "CENTRETON",
        "Province": "ONTARIO",
        "Climate ID": "6151307",
        "Station ID": 4897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -78.07,
        "Latitude": 440500000,
        "Longitude": -780400000,
        "Elevation (m)": 244,
        "First Year": 1990,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "CENTREVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6151309",
        "Station ID": 4898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -76.91,
        "Latitude": 442412090,
        "Longitude": -765428092,
        "Elevation (m)": 150,
        "First Year": 1985,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2022,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "CHELTENHAM",
        "Province": "ONTARIO",
        "Climate ID": "6151486",
        "Station ID": 4899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 434500000,
        "Longitude": -795600000,
        "Elevation (m)": 281.9,
        "First Year": 1950,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1951,
        "MLY First Year": 1950,
        "MLY Last Year": 1951
      },
      {
        "Name": "CHRISTIE CONSERVATION",
        "Province": "ONTARIO",
        "Climate ID": "6151512",
        "Station ID": 4901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.28,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 431700000,
        "Longitude": -800100000,
        "Elevation (m)": 251.5,
        "First Year": 1976,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1994,
        "MLY First Year": 1976,
        "MLY Last Year": 1994
      },
      {
        "Name": "CLAREMONT",
        "Province": "ONTARIO",
        "Climate ID": "6151545",
        "Station ID": 4902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -79.07,
        "Latitude": 435700000,
        "Longitude": -790400000,
        "Elevation (m)": 175.3,
        "First Year": 1962,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1991,
        "MLY First Year": 1962,
        "MLY Last Year": 1991
      },
      {
        "Name": "CLARKSON",
        "Province": "ONTARIO",
        "Climate ID": "6151548",
        "Station ID": 4903,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.52,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 433100000,
        "Longitude": -793700000,
        "Elevation (m)": 93,
        "First Year": 1949,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1967,
        "MLY First Year": 1949,
        "MLY Last Year": 1967
      },
      {
        "Name": "CLAREMONT FIELD CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "61515DE",
        "Station ID": 4900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 435700000,
        "Longitude": -790500000,
        "Elevation (m)": 182.9,
        "First Year": 1970,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1971,
        "MLY First Year": 1970,
        "MLY Last Year": 1971
      },
      {
        "Name": "COBOURG (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6151684",
        "Station ID": 7870,
        "WMO ID": 71431,
        "TC ID": "WNC",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -78.17,
        "Latitude": 435700000,
        "Longitude": -781000000,
        "Elevation (m)": 77.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "COBOURG",
        "Province": "ONTARIO",
        "Climate ID": "6151685",
        "Station ID": 4904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -78.17,
        "Latitude": 435800000,
        "Longitude": -781000000,
        "Elevation (m)": 78.6,
        "First Year": 1948,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1958,
        "MLY First Year": 1948,
        "MLY Last Year": 1958
      },
      {
        "Name": "COBOURG STP",
        "Province": "ONTARIO",
        "Climate ID": "6151689",
        "Station ID": 4905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -78.18,
        "Latitude": 435800000,
        "Longitude": -781100000,
        "Elevation (m)": 79.2,
        "First Year": 1970,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2022,
        "MLY First Year": 1970,
        "MLY Last Year": 2006
      },
      {
        "Name": "COBOURG 2",
        "Province": "ONTARIO",
        "Climate ID": "6151690",
        "Station ID": 4906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -78.15,
        "Latitude": 435700000,
        "Longitude": -780900000,
        "Elevation (m)": 83.8,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "CODRINGTON",
        "Province": "ONTARIO",
        "Climate ID": "6151725",
        "Station ID": 4907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -77.8,
        "Latitude": 441000000,
        "Longitude": -774800000,
        "Elevation (m)": 144.8,
        "First Year": 1965,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1973,
        "MLY First Year": 1965,
        "MLY Last Year": 1973
      },
      {
        "Name": "COLBORNE",
        "Province": "ONTARIO",
        "Climate ID": "6151745",
        "Station ID": 6954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44,
        "Longitude (Decimal Degrees)": -77.88,
        "Latitude": 440000000,
        "Longitude": -775300000,
        "Elevation (m)": 105,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "COLD CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6151750",
        "Station ID": 4908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 435500000,
        "Longitude": -794200000,
        "Elevation (m)": 251,
        "First Year": 1961,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1991,
        "MLY First Year": 1961,
        "MLY Last Year": 1991
      },
      {
        "Name": "COOKSVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6151860",
        "Station ID": 4909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.57,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 433400000,
        "Longitude": -793300000,
        "Elevation (m)": 91.4,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "COPETOWN",
        "Province": "ONTARIO",
        "Climate ID": "6151866",
        "Station ID": 4910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 431500000,
        "Longitude": -800700000,
        "Elevation (m)": 228.6,
        "First Year": 1970,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1993,
        "MLY First Year": 1970,
        "MLY Last Year": 1993
      },
      {
        "Name": "CREWSONS CORNERS",
        "Province": "ONTARIO",
        "Climate ID": "6151915",
        "Station ID": 4912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -80.1,
        "Latitude": 433700000,
        "Longitude": -800600000,
        "Elevation (m)": 358.1,
        "First Year": 1957,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1973,
        "MLY First Year": 1957,
        "MLY Last Year": 1973
      },
      {
        "Name": "CROYDON",
        "Province": "ONTARIO",
        "Climate ID": "6151921",
        "Station ID": 4913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -77,
        "Latitude": 443400000,
        "Longitude": -770000000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1908,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1908,
        "MLY First Year": 1895,
        "MLY Last Year": 1908
      },
      {
        "Name": "CRESSY",
        "Province": "ONTARIO",
        "Climate ID": "61519JM",
        "Station ID": 4911,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -76.85,
        "Latitude": 440600000,
        "Longitude": -765100000,
        "Elevation (m)": 83.8,
        "First Year": 1966,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2002,
        "MLY First Year": 1966,
        "MLY Last Year": 2002
      },
      {
        "Name": "DESERONTO",
        "Province": "ONTARIO",
        "Climate ID": "6152007",
        "Station ID": 4914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.18,
        "Longitude (Decimal Degrees)": -77.07,
        "Latitude": 441100000,
        "Longitude": -770400000,
        "Elevation (m)": 80.5,
        "First Year": 1882,
        "Last Year": 1905,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1905,
        "MLY First Year": 1882,
        "MLY Last Year": 1905
      },
      {
        "Name": "DRUMQUIN",
        "Province": "ONTARIO",
        "Climate ID": "6152114",
        "Station ID": 4915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 433300000,
        "Longitude": -794600000,
        "Elevation (m)": 182.9,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "EDGELEY",
        "Province": "ONTARIO",
        "Climate ID": "6152235",
        "Station ID": 4916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 434700000,
        "Longitude": -793300000,
        "Elevation (m)": 167.6,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "ERINDALE",
        "Province": "ONTARIO",
        "Climate ID": "6152335",
        "Station ID": 4917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.57,
        "Longitude (Decimal Degrees)": -79.65,
        "Latitude": 433400000,
        "Longitude": -793900000,
        "Elevation (m)": 140.2,
        "First Year": 1959,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1978,
        "MLY First Year": 1959,
        "MLY Last Year": 1978
      },
      {
        "Name": "FRANKFORD MOE",
        "Province": "ONTARIO",
        "Climate ID": "6152555",
        "Station ID": 4918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -77.6,
        "Latitude": 441400000,
        "Longitude": -773600000,
        "Elevation (m)": 114.3,
        "First Year": 1959,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1994,
        "MLY First Year": 1959,
        "MLY Last Year": 1994
      },
      {
        "Name": "FRENCHMANS BAY",
        "Province": "ONTARIO",
        "Climate ID": "6152605",
        "Station ID": 4919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 434900000,
        "Longitude": -790500000,
        "Elevation (m)": 76.2,
        "First Year": 1959,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2004,
        "MLY First Year": 1959,
        "MLY Last Year": 2004
      },
      {
        "Name": "GALT OSMOND",
        "Province": "ONTARIO",
        "Climate ID": "6152661",
        "Station ID": 4920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.35,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 432100000,
        "Longitude": -800700000,
        "Elevation (m)": 269.7,
        "First Year": 1965,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1978,
        "MLY First Year": 1965,
        "MLY Last Year": 1978
      },
      {
        "Name": "GARDEN HILL",
        "Province": "ONTARIO",
        "Climate ID": "6152663",
        "Station ID": 4921,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -78.4,
        "Latitude": 440200000,
        "Longitude": -782400000,
        "Elevation (m)": 175.3,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "GEORGETOWN",
        "Province": "ONTARIO",
        "Climate ID": "6152691",
        "Station ID": 4922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.95,
        "Latitude": 433900000,
        "Longitude": -795700000,
        "Elevation (m)": 274.3,
        "First Year": 1882,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1966,
        "MLY First Year": 1882,
        "MLY Last Year": 1966
      },
      {
        "Name": "GEORGETOWN WWTP",
        "Province": "ONTARIO",
        "Climate ID": "6152695",
        "Station ID": 4923,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.64,
        "Longitude (Decimal Degrees)": -79.88,
        "Latitude": 433824018,
        "Longitude": -795245018,
        "Elevation (m)": 221,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2006
      },
      {
        "Name": "GLEN HAFFY MONO MILLS",
        "Province": "ONTARIO",
        "Climate ID": "6152833",
        "Station ID": 4924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -79.95,
        "Latitude": 435600000,
        "Longitude": -795700000,
        "Elevation (m)": 434.3,
        "First Year": 1959,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2003,
        "MLY First Year": 1959,
        "MLY Last Year": 2003
      },
      {
        "Name": "GLENORA RS",
        "Province": "ONTARIO",
        "Climate ID": "6152837",
        "Station ID": 4925,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -77.05,
        "Latitude": 440200000,
        "Longitude": -770300000,
        "Elevation (m)": 129.5,
        "First Year": 1958,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1969,
        "MLY First Year": 1958,
        "MLY Last Year": 1969
      },
      {
        "Name": "GORES LANDING",
        "Province": "ONTARIO",
        "Climate ID": "6152950",
        "Station ID": 4926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -78.22,
        "Latitude": 440800000,
        "Longitude": -781300000,
        "Elevation (m)": 190.5,
        "First Year": 1943,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1966,
        "MLY First Year": 1943,
        "MLY Last Year": 1966
      },
      {
        "Name": "GORES LANDING",
        "Province": "ONTARIO",
        "Climate ID": "6152951",
        "Station ID": 4927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -78.27,
        "Latitude": 440500000,
        "Longitude": -781600000,
        "Elevation (m)": 260.6,
        "First Year": 1970,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1982,
        "MLY First Year": 1970,
        "MLY Last Year": 1982
      },
      {
        "Name": "GORMLEY ARDENLEE",
        "Province": "ONTARIO",
        "Climate ID": "6152953",
        "Station ID": 4928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 435200000,
        "Longitude": -792300000,
        "Elevation (m)": 198.1,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "GREEN RIVER",
        "Province": "ONTARIO",
        "Climate ID": "6153011",
        "Station ID": 4929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -79.18,
        "Latitude": 435400000,
        "Longitude": -791100000,
        "Elevation (m)": 190.5,
        "First Year": 1953,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1957,
        "MLY First Year": 1953,
        "MLY Last Year": 1957
      },
      {
        "Name": "GREENWOOD MTRCA",
        "Province": "ONTARIO",
        "Climate ID": "6153020",
        "Station ID": 4930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -79.07,
        "Latitude": 435400000,
        "Longitude": -790400000,
        "Elevation (m)": 128,
        "First Year": 1960,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1993,
        "MLY First Year": 1960,
        "MLY Last Year": 1993
      },
      {
        "Name": "PA HALTON HILLS",
        "Province": "ONTARIO",
        "Climate ID": "6153170",
        "Station ID": 52498,
        "WMO ID": null,
        "TC ID": "L4A",
        "Latitude (Decimal Degrees)": 43.6,
        "Longitude (Decimal Degrees)": -80.06,
        "Latitude": 433555300,
        "Longitude": -800318200,
        "Elevation (m)": 382.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAMILTON",
        "Province": "ONTARIO",
        "Climate ID": "6153192",
        "Station ID": 4931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.27,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 431600000,
        "Longitude": -795400000,
        "Elevation (m)": 92.4,
        "First Year": 1866,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 1958,
        "MLY First Year": 1866,
        "MLY Last Year": 1958
      },
      {
        "Name": "HAMILTON A",
        "Province": "ONTARIO",
        "Climate ID": "6153193",
        "Station ID": 49908,
        "WMO ID": 71263,
        "TC ID": "YHM",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -79.94,
        "Latitude": 431025000,
        "Longitude": -795606000,
        "Elevation (m)": 237.7,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAMILTON A",
        "Province": "ONTARIO",
        "Climate ID": "6153194",
        "Station ID": 4932,
        "WMO ID": 71263,
        "TC ID": "YHM",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 431018072,
        "Longitude": -795603036,
        "Elevation (m)": 237.7,
        "First Year": 1959,
        "Last Year": 2011,
        "HLY First Year": 1970,
        "HLY Last Year": 2011,
        "DLY First Year": 1959,
        "DLY Last Year": 2011,
        "MLY First Year": 1959,
        "MLY Last Year": 2011
      },
      {
        "Name": "HAMILTON GAGE PARK",
        "Province": "ONTARIO",
        "Climate ID": "6153238",
        "Station ID": 4933,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.23,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 431400000,
        "Longitude": -795200000,
        "Elevation (m)": 99.1,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1956
      },
      {
        "Name": "HAMILTON MARINE POLICE",
        "Province": "ONTARIO",
        "Climate ID": "6153264",
        "Station ID": 4934,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.27,
        "Longitude (Decimal Degrees)": -79.87,
        "Latitude": 431600000,
        "Longitude": -795200000,
        "Elevation (m)": 76.8,
        "First Year": 1980,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1983,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "HAMILTON MUNICIPAL LAB",
        "Province": "ONTARIO",
        "Climate ID": "6153290",
        "Station ID": 4935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 431500000,
        "Longitude": -794600000,
        "Elevation (m)": 76.2,
        "First Year": 1967,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1994,
        "MLY First Year": 1967,
        "MLY Last Year": 1994
      },
      {
        "Name": "HAMILTON PSYCH HOSPITAL",
        "Province": "ONTARIO",
        "Climate ID": "6153298",
        "Station ID": 4936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.23,
        "Longitude (Decimal Degrees)": -79.9,
        "Latitude": 431400000,
        "Longitude": -795400000,
        "Elevation (m)": 198.1,
        "First Year": 1960,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1993,
        "MLY First Year": 1960,
        "MLY Last Year": 1993
      },
      {
        "Name": "HAMILTON RBG",
        "Province": "ONTARIO",
        "Climate ID": "6153300",
        "Station ID": 4937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.28,
        "Longitude (Decimal Degrees)": -79.88,
        "Latitude": 431700000,
        "Longitude": -795300000,
        "Elevation (m)": 102.1,
        "First Year": 1950,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1997,
        "MLY First Year": 1950,
        "MLY Last Year": 1997
      },
      {
        "Name": "HAMILTON RBG CS",
        "Province": "ONTARIO",
        "Climate ID": "6153301",
        "Station ID": 27529,
        "WMO ID": 71297,
        "TC ID": "XHM",
        "Latitude (Decimal Degrees)": 43.29,
        "Longitude (Decimal Degrees)": -79.91,
        "Latitude": 431730000,
        "Longitude": -795430000,
        "Elevation (m)": 102,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "HANLON",
        "Province": "ONTARIO",
        "Climate ID": "6153325",
        "Station ID": 4938,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.67,
        "Latitude": 433900000,
        "Longitude": -794000000,
        "Elevation (m)": 175.3,
        "First Year": 1950,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1951,
        "MLY First Year": 1950,
        "MLY Last Year": 1951
      },
      {
        "Name": "HANNON",
        "Province": "ONTARIO",
        "Climate ID": "6153328",
        "Station ID": 4939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.17,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 431000000,
        "Longitude": -795000000,
        "Elevation (m)": 213.4,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "HAROLD WILDWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6153357",
        "Station ID": 4940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -77.55,
        "Latitude": 442300000,
        "Longitude": -773300000,
        "Elevation (m)": 161.5,
        "First Year": 1983,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1993,
        "MLY First Year": 1983,
        "MLY Last Year": 1993
      },
      {
        "Name": "HAVELOCK",
        "Province": "ONTARIO",
        "Climate ID": "6153380",
        "Station ID": 52558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.42,
        "Longitude (Decimal Degrees)": -77.93,
        "Latitude": 442500000,
        "Longitude": -775600000,
        "Elevation (m)": 217,
        "First Year": 2013,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2013,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HEART LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6153410",
        "Station ID": 4941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 434400000,
        "Longitude": -794700000,
        "Elevation (m)": 259.1,
        "First Year": 1957,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1989,
        "MLY First Year": 1957,
        "MLY Last Year": 1989
      },
      {
        "Name": "HOLLAND LANDING",
        "Province": "ONTARIO",
        "Climate ID": "6153475",
        "Station ID": 4942,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 440600000,
        "Longitude": -792900000,
        "Elevation (m)": 259.1,
        "First Year": 1964,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1969,
        "MLY First Year": 1964,
        "MLY Last Year": 1969
      },
      {
        "Name": "HOLLAND MARSH",
        "Province": "ONTARIO",
        "Climate ID": "6153477",
        "Station ID": 4943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 440700000,
        "Longitude": -793000000,
        "Elevation (m)": 217.9,
        "First Year": 1946,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1948,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HORNBY",
        "Province": "ONTARIO",
        "Climate ID": "6153540",
        "Station ID": 4944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 433300000,
        "Longitude": -795000000,
        "Elevation (m)": 198.1,
        "First Year": 1947,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1959,
        "MLY First Year": 1947,
        "MLY Last Year": 1959
      },
      {
        "Name": "HORNBY",
        "Province": "ONTARIO",
        "Climate ID": "6153545",
        "Station ID": 4945,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.57,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 433400000,
        "Longitude": -795100000,
        "Elevation (m)": 198.1,
        "First Year": 1967,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1978,
        "MLY First Year": 1967,
        "MLY Last Year": 1978
      },
      {
        "Name": "HORNBY TRAFALGAR TS",
        "Province": "ONTARIO",
        "Climate ID": "6153552",
        "Station ID": 4946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.53,
        "Longitude (Decimal Degrees)": -79.73,
        "Latitude": 433200000,
        "Longitude": -794400000,
        "Elevation (m)": 182.9,
        "First Year": 1982,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2001,
        "MLY First Year": 1982,
        "MLY Last Year": 2001
      },
      {
        "Name": "HUTTONVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6153676",
        "Station ID": 4947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.82,
        "Latitude": 434000000,
        "Longitude": -794900000,
        "Elevation (m)": 237.7,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "IDA",
        "Province": "ONTARIO",
        "Climate ID": "6153678",
        "Station ID": 4948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -78.43,
        "Latitude": 441500000,
        "Longitude": -782600000,
        "Elevation (m)": 251.5,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "IVANHOE",
        "Province": "ONTARIO",
        "Climate ID": "6153843",
        "Station ID": 4949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -77.52,
        "Latitude": 442300000,
        "Longitude": -773100000,
        "Elevation (m)": 175,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "JANETVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6153853",
        "Station ID": 4950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -78.63,
        "Latitude": 441300000,
        "Longitude": -783800000,
        "Elevation (m)": 296.9,
        "First Year": 1981,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2006,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "KALADAR",
        "Province": "ONTARIO",
        "Climate ID": "6153935",
        "Station ID": 27612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -77.12,
        "Latitude": 443852000,
        "Longitude": -770702040,
        "Elevation (m)": 214.7,
        "First Year": 1998,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2015,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "KING CITY",
        "Province": "ONTARIO",
        "Climate ID": "6154130",
        "Station ID": 4951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 435800000,
        "Longitude": -793100000,
        "Elevation (m)": 291.4,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "KING CITY 2",
        "Province": "ONTARIO",
        "Climate ID": "6154131",
        "Station ID": 4952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 435600000,
        "Longitude": -793100000,
        "Elevation (m)": 304.8,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "KING CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6154135",
        "Station ID": 4953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 435400000,
        "Longitude": -793700000,
        "Elevation (m)": 236.2,
        "First Year": 1959,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1978,
        "MLY First Year": 1959,
        "MLY Last Year": 1978
      },
      {
        "Name": "KING SMOKE TREE",
        "Province": "ONTARIO",
        "Climate ID": "6154142",
        "Station ID": 4954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 440100000,
        "Longitude": -793100000,
        "Elevation (m)": 352,
        "First Year": 1974,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2003,
        "MLY First Year": 1974,
        "MLY Last Year": 2003
      },
      {
        "Name": "KING CITY NORTH",
        "Province": "ONTARIO",
        "Climate ID": "6154150",
        "Station ID": 54958,
        "WMO ID": 73074,
        "TC ID": "OKN",
        "Latitude (Decimal Degrees)": 43.96,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 435748679,
        "Longitude": -793426800,
        "Elevation (m)": 361,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAKEPORT",
        "Province": "ONTARIO",
        "Climate ID": "6154302",
        "Station ID": 4955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -77.92,
        "Latitude": 435900000,
        "Longitude": -775500000,
        "Elevation (m)": 89.9,
        "First Year": 1952,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1959,
        "MLY First Year": 1952,
        "MLY Last Year": 1959
      },
      {
        "Name": "LAKEVIEW MOE",
        "Province": "ONTARIO",
        "Climate ID": "6154310",
        "Station ID": 4956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.57,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 433400000,
        "Longitude": -793400000,
        "Elevation (m)": 76.2,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "LESKARD",
        "Province": "ONTARIO",
        "Climate ID": "6154410",
        "Station ID": 4957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -78.65,
        "Latitude": 440100000,
        "Longitude": -783900000,
        "Elevation (m)": 64.9,
        "First Year": 1978,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1996,
        "MLY First Year": 1978,
        "MLY Last Year": 1996
      },
      {
        "Name": "LONG BRANCH",
        "Province": "ONTARIO",
        "Climate ID": "6154520",
        "Station ID": 4958,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 433500000,
        "Longitude": -793300000,
        "Elevation (m)": 76.2,
        "First Year": 1951,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1951,
        "MLY First Year": 1951,
        "MLY Last Year": 1951
      },
      {
        "Name": "LONG SAULT IHD",
        "Province": "ONTARIO",
        "Climate ID": "6154611",
        "Station ID": 4959,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -78.72,
        "Latitude": 440300000,
        "Longitude": -784300000,
        "Elevation (m)": 342.9,
        "First Year": 1967,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1969,
        "MLY First Year": 1967,
        "MLY Last Year": 1969
      },
      {
        "Name": "MADOC",
        "Province": "ONTARIO",
        "Climate ID": "6154779",
        "Station ID": 27618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.53,
        "Longitude (Decimal Degrees)": -77.48,
        "Latitude": 443200042,
        "Longitude": -772839096,
        "Elevation (m)": 222,
        "First Year": 1998,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2006,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "MADOC",
        "Province": "ONTARIO",
        "Climate ID": "6154780",
        "Station ID": 4960,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -77.47,
        "Latitude": 443100000,
        "Longitude": -772800000,
        "Elevation (m)": 182.9,
        "First Year": 1903,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1969,
        "MLY First Year": 1904,
        "MLY Last Year": 1969
      },
      {
        "Name": "MAIN DUCK ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6154820",
        "Station ID": 4961,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -76.63,
        "Latitude": 435600000,
        "Longitude": -763800000,
        "Elevation (m)": 75,
        "First Year": 1959,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1986,
        "MLY First Year": 1959,
        "MLY Last Year": 1986
      },
      {
        "Name": "MAPLE",
        "Province": "ONTARIO",
        "Climate ID": "6154950",
        "Station ID": 4962,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 435200000,
        "Longitude": -792900000,
        "Elevation (m)": 244.1,
        "First Year": 1961,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1990,
        "MLY First Year": 1961,
        "MLY Last Year": 1989
      },
      {
        "Name": "MAPLE SHERWOOD EAST",
        "Province": "ONTARIO",
        "Climate ID": "6154951",
        "Station ID": 4963,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.85,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 435100000,
        "Longitude": -793100000,
        "Elevation (m)": 237.7,
        "First Year": 1961,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1968,
        "MLY First Year": 1961,
        "MLY Last Year": 1968
      },
      {
        "Name": "MARKHAM",
        "Province": "ONTARIO",
        "Climate ID": "6154987",
        "Station ID": 4964,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 435300000,
        "Longitude": -791500000,
        "Elevation (m)": 195.1,
        "First Year": 1957,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1972,
        "MLY First Year": 1957,
        "MLY Last Year": 1972
      },
      {
        "Name": "MARKHAM 2",
        "Province": "ONTARIO",
        "Climate ID": "6154988",
        "Station ID": 4965,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 435300000,
        "Longitude": -791600000,
        "Elevation (m)": 199.6,
        "First Year": 1959,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1965,
        "MLY First Year": 1959,
        "MLY Last Year": 1965
      },
      {
        "Name": "MARKHAM MTRCA",
        "Province": "ONTARIO",
        "Climate ID": "6154990",
        "Station ID": 4966,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.28,
        "Latitude": 435200000,
        "Longitude": -791700000,
        "Elevation (m)": 167.6,
        "First Year": 1964,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1979,
        "MLY First Year": 1964,
        "MLY Last Year": 1979
      },
      {
        "Name": "MARKHAM MOE",
        "Province": "ONTARIO",
        "Climate ID": "6154992",
        "Station ID": 4967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 435200000,
        "Longitude": -791500000,
        "Elevation (m)": 175.3,
        "First Year": 1961,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1980,
        "MLY First Year": 1961,
        "MLY Last Year": 1980
      },
      {
        "Name": "MARKHAM WATERWORKS",
        "Province": "ONTARIO",
        "Climate ID": "6154994",
        "Station ID": 4968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 435000000,
        "Longitude": -792100000,
        "Elevation (m)": 182.9,
        "First Year": 1961,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1979,
        "MLY First Year": 1961,
        "MLY Last Year": 1979
      },
      {
        "Name": "MARMORA",
        "Province": "ONTARIO",
        "Climate ID": "6154995",
        "Station ID": 4969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -77.7,
        "Latitude": 442900000,
        "Longitude": -774200000,
        "Elevation (m)": 203.8,
        "First Year": 1981,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1993,
        "MLY First Year": 1981,
        "MLY Last Year": 1993
      },
      {
        "Name": "MARSH HILL",
        "Province": "ONTARIO",
        "Climate ID": "6155000",
        "Station ID": 31009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -79.07,
        "Latitude": 440900000,
        "Longitude": -790400000,
        "Elevation (m)": 285,
        "First Year": 2001,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2011,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "MEYERSBURG",
        "Province": "ONTARIO",
        "Climate ID": "6155071",
        "Station ID": 4973,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -77.8,
        "Latitude": 441500000,
        "Longitude": -774800000,
        "Elevation (m)": 125,
        "First Year": 1930,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1971,
        "MLY First Year": 1930,
        "MLY Last Year": 1971
      },
      {
        "Name": "MIDDLEPORT TS",
        "Province": "ONTARIO",
        "Climate ID": "6155097",
        "Station ID": 4974,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.12,
        "Longitude (Decimal Degrees)": -80.03,
        "Latitude": 430700000,
        "Longitude": -800200000,
        "Elevation (m)": 206,
        "First Year": 1980,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2000,
        "MLY First Year": 1980,
        "MLY Last Year": 2000
      },
      {
        "Name": "MILFORD",
        "Province": "ONTARIO",
        "Climate ID": "6155148",
        "Station ID": 4975,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -77.05,
        "Latitude": 435600000,
        "Longitude": -770300000,
        "Elevation (m)": 103,
        "First Year": 1984,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1992,
        "MLY First Year": 1984,
        "MLY Last Year": 1992
      },
      {
        "Name": "MILLBROOK",
        "Province": "ONTARIO",
        "Climate ID": "6155154",
        "Station ID": 4976,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -78.48,
        "Latitude": 440900000,
        "Longitude": -782900000,
        "Elevation (m)": 274.3,
        "First Year": 1975,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1985,
        "MLY First Year": 1975,
        "MLY Last Year": 1985
      },
      {
        "Name": "MILLGROVE",
        "Province": "ONTARIO",
        "Climate ID": "6155183",
        "Station ID": 4977,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.32,
        "Longitude (Decimal Degrees)": -79.97,
        "Latitude": 431900000,
        "Longitude": -795800000,
        "Elevation (m)": 255.1,
        "First Year": 1951,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2006,
        "MLY First Year": 1951,
        "MLY Last Year": 2006
      },
      {
        "Name": "MILTON KELSO",
        "Province": "ONTARIO",
        "Climate ID": "6155187",
        "Station ID": 4978,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.5,
        "Longitude (Decimal Degrees)": -79.95,
        "Latitude": 433000000,
        "Longitude": -795700000,
        "Elevation (m)": 243.8,
        "First Year": 1966,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1987,
        "MLY First Year": 1966,
        "MLY Last Year": 1987
      },
      {
        "Name": "MOBILE UPPER AIR STATION-ONTARIO",
        "Province": "ONTARIO",
        "Climate ID": "6155200",
        "Station ID": 32513,
        "WMO ID": 71117,
        "TC ID": "EWO",
        "Latitude (Decimal Degrees)": 43.27,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 431611064,
        "Longitude": -794649008,
        "Elevation (m)": 76.1,
        "First Year": 2019,
        "Last Year": 2021,
        "HLY First Year": 2019,
        "HLY Last Year": 2021,
        "DLY First Year": 2019,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONO MILLS THERAFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6155257",
        "Station ID": 4979,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -79.98,
        "Latitude": 435800000,
        "Longitude": -795900000,
        "Elevation (m)": 419.1,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "MORGANSTON",
        "Province": "ONTARIO",
        "Climate ID": "6155450",
        "Station ID": 4980,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -77.83,
        "Latitude": 441000000,
        "Longitude": -775000000,
        "Elevation (m)": 175.3,
        "First Year": 1974,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1978,
        "MLY First Year": 1974,
        "MLY Last Year": 1978
      },
      {
        "Name": "MORVEN IHD",
        "Province": "ONTARIO",
        "Climate ID": "6155496",
        "Station ID": 4981,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -76.85,
        "Latitude": 441500000,
        "Longitude": -765100000,
        "Elevation (m)": 106.7,
        "First Year": 1967,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1976,
        "MLY First Year": 1967,
        "MLY Last Year": 1976
      },
      {
        "Name": "MOUNTAIN VIEW",
        "Province": "ONTARIO",
        "Climate ID": "6155498",
        "Station ID": 4982,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -77.38,
        "Latitude": 440500000,
        "Longitude": -772300000,
        "Elevation (m)": 79.2,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "NEWMARKET",
        "Province": "ONTARIO",
        "Climate ID": "6155616",
        "Station ID": 4983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 440400000,
        "Longitude": -792600000,
        "Elevation (m)": 281.9,
        "First Year": 1871,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1967,
        "MLY First Year": 1871,
        "MLY Last Year": 1967
      },
      {
        "Name": "NEWMARKET CHERRYWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6155617",
        "Station ID": 4984,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 440400000,
        "Longitude": -792800000,
        "Elevation (m)": 259.1,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "NEWMARKET WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6155619",
        "Station ID": 4985,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 440400000,
        "Longitude": -792600000,
        "Elevation (m)": 245,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "NOBLETON",
        "Province": "ONTARIO",
        "Climate ID": "6155680",
        "Station ID": 44263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 435457072,
        "Longitude": -793558056,
        "Elevation (m)": 278,
        "First Year": 2005,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2007,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "NORVAL",
        "Province": "ONTARIO",
        "Climate ID": "6155713",
        "Station ID": 4986,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 433900000,
        "Longitude": -795100000,
        "Elevation (m)": 228.6,
        "First Year": 1969,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1977,
        "MLY First Year": 1969,
        "MLY Last Year": 1977
      },
      {
        "Name": "OAK RIDGES",
        "Province": "ONTARIO",
        "Climate ID": "6155722",
        "Station ID": 4987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 435800000,
        "Longitude": -792800000,
        "Elevation (m)": 321.6,
        "First Year": 1918,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1979,
        "MLY First Year": 1918,
        "MLY Last Year": 1979
      },
      {
        "Name": "OAKVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6155744",
        "Station ID": 4988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.42,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 432500000,
        "Longitude": -794100000,
        "Elevation (m)": 83.8,
        "First Year": 1956,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1971,
        "MLY First Year": 1956,
        "MLY Last Year": 1971
      },
      {
        "Name": "OAKVILLE STP",
        "Province": "ONTARIO",
        "Climate ID": "6155746",
        "Station ID": 4989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.42,
        "Longitude (Decimal Degrees)": -79.68,
        "Latitude": 432500000,
        "Longitude": -794100000,
        "Elevation (m)": 83.8,
        "First Year": 1964,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1970,
        "MLY First Year": 1964,
        "MLY Last Year": 1970
      },
      {
        "Name": "OAKVILLE TWN",
        "Province": "ONTARIO",
        "Climate ID": "6155750",
        "Station ID": 45667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.51,
        "Longitude (Decimal Degrees)": -79.69,
        "Latitude": 433045000,
        "Longitude": -794124000,
        "Elevation (m)": 168,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ORANGEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6155788",
        "Station ID": 4990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -80.05,
        "Latitude": 435500000,
        "Longitude": -800300000,
        "Elevation (m)": 404.8,
        "First Year": 1883,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1967,
        "MLY First Year": 1883,
        "MLY Last Year": 1967
      },
      {
        "Name": "ORANGEVILLE MOE",
        "Province": "ONTARIO",
        "Climate ID": "6155790",
        "Station ID": 4991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -80.09,
        "Latitude": 435506066,
        "Longitude": -800511064,
        "Elevation (m)": 411.5,
        "First Year": 1961,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2015,
        "MLY First Year": 1961,
        "MLY Last Year": 2006
      },
      {
        "Name": "ORLAND",
        "Province": "ONTARIO",
        "Climate ID": "6155830",
        "Station ID": 4992,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -77.82,
        "Latitude": 440800000,
        "Longitude": -774900000,
        "Elevation (m)": 144.8,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "ORONO",
        "Province": "ONTARIO",
        "Climate ID": "6155854",
        "Station ID": 4993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -78.62,
        "Latitude": 435800000,
        "Longitude": -783700000,
        "Elevation (m)": 147.8,
        "First Year": 1923,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1996,
        "MLY First Year": 1923,
        "MLY Last Year": 1996
      },
      {
        "Name": "WHITBY MUELLER",
        "Province": "ONTARIO",
        "Climate ID": "6155870",
        "Station ID": 31008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -78.9,
        "Latitude": 435400000,
        "Longitude": -785400000,
        "Elevation (m)": 120,
        "First Year": 2001,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2006,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "WHITBY PRINGLE CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6155874",
        "Station ID": 27004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -78.93,
        "Latitude": 435200000,
        "Longitude": -785600000,
        "Elevation (m)": 82,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 1995,
        "MLY First Year": 1994,
        "MLY Last Year": 1995
      },
      {
        "Name": "OSHAWA",
        "Province": "ONTARIO",
        "Climate ID": "6155875",
        "Station ID": 48649,
        "WMO ID": 71697,
        "TC ID": "YOO",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -78.88,
        "Latitude": 435522000,
        "Longitude": -785300041,
        "Elevation (m)": 139.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OSHAWA",
        "Province": "ONTARIO",
        "Climate ID": "6155876",
        "Station ID": 4994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -78.87,
        "Latitude": 435400000,
        "Longitude": -785200000,
        "Elevation (m)": 112.8,
        "First Year": 1882,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1959,
        "MLY First Year": 1882,
        "MLY Last Year": 1959
      },
      {
        "Name": "OSHAWA FIRE HALL #3",
        "Province": "ONTARIO",
        "Climate ID": "6155877",
        "Station ID": 4995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -78.87,
        "Latitude": 435600000,
        "Longitude": -785200000,
        "Elevation (m)": 143,
        "First Year": 1976,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1992,
        "MLY First Year": 1976,
        "MLY Last Year": 1992
      },
      {
        "Name": "OSHAWA WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6155878",
        "Station ID": 4996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 435200000,
        "Longitude": -785000000,
        "Elevation (m)": 83.8,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2006
      },
      {
        "Name": "MISSISSAUGA",
        "Province": "ONTARIO",
        "Climate ID": "6155BA0",
        "Station ID": 4970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 433500000,
        "Longitude": -793700000,
        "Elevation (m)": 118,
        "First Year": 1979,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1983,
        "MLY First Year": 1979,
        "MLY Last Year": 1983
      },
      {
        "Name": "OAKVILLE GERARD",
        "Province": "ONTARIO",
        "Climate ID": "6155PD4",
        "Station ID": 4971,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.43,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 432541007,
        "Longitude": -794149062,
        "Elevation (m)": 92,
        "First Year": 1990,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2006,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "OAKVILLE GLEN ABBEY",
        "Province": "ONTARIO",
        "Climate ID": "6155PM4",
        "Station ID": 4972,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -79.72,
        "Latitude": 432700000,
        "Longitude": -794300000,
        "Elevation (m)": 132,
        "First Year": 1983,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1987,
        "MLY First Year": 1983,
        "MLY Last Year": 1987
      },
      {
        "Name": "PA ATMOS BRAMPTON",
        "Province": "ONTARIO",
        "Climate ID": "6156130",
        "Station ID": 52738,
        "WMO ID": null,
        "TC ID": "A4T",
        "Latitude (Decimal Degrees)": 43.79,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 434717760,
        "Longitude": -794614760,
        "Elevation (m)": 246,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS CAMPBELLVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6156131",
        "Station ID": 52740,
        "WMO ID": null,
        "TC ID": "A7T",
        "Latitude (Decimal Degrees)": 43.44,
        "Longitude (Decimal Degrees)": -80.01,
        "Latitude": 432634760,
        "Longitude": -800043010,
        "Elevation (m)": 315,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS CLAREMONT",
        "Province": "ONTARIO",
        "Climate ID": "6156132",
        "Station ID": 52737,
        "WMO ID": null,
        "TC ID": "A3T",
        "Latitude (Decimal Degrees)": 43.94,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 435609800,
        "Longitude": -790505400,
        "Elevation (m)": 167,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS ERIN",
        "Province": "ONTARIO",
        "Climate ID": "6156133",
        "Station ID": 52735,
        "WMO ID": null,
        "TC ID": "A1T",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -80.12,
        "Latitude": 434933620,
        "Longitude": -800712840,
        "Elevation (m)": 470,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS HAMILTON",
        "Province": "ONTARIO",
        "Climate ID": "6156134",
        "Station ID": 52742,
        "WMO ID": null,
        "TC ID": "A9T",
        "Latitude (Decimal Degrees)": 43.19,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 431138800,
        "Longitude": -794946900,
        "Elevation (m)": 198,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS MISSISSAUGA",
        "Province": "ONTARIO",
        "Climate ID": "6156135",
        "Station ID": 52741,
        "WMO ID": null,
        "TC ID": "A8T",
        "Latitude (Decimal Degrees)": 43.53,
        "Longitude (Decimal Degrees)": -79.65,
        "Latitude": 433147100,
        "Longitude": -793848500,
        "Elevation (m)": 141,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS NORTH YORK",
        "Province": "ONTARIO",
        "Climate ID": "6156136",
        "Station ID": 53258,
        "WMO ID": null,
        "TC ID": "A6T",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.31,
        "Latitude": 434517700,
        "Longitude": -791853200,
        "Elevation (m)": 178,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS NORVAL",
        "Province": "ONTARIO",
        "Climate ID": "6156137",
        "Station ID": 52739,
        "WMO ID": null,
        "TC ID": "A5T",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.84,
        "Latitude": 433725600,
        "Longitude": -795021200,
        "Elevation (m)": 240,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ATMOS VAUGHAN",
        "Province": "ONTARIO",
        "Climate ID": "6156138",
        "Station ID": 52736,
        "WMO ID": null,
        "TC ID": "A2T",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -79.54,
        "Latitude": 435147700,
        "Longitude": -793228900,
        "Elevation (m)": 254,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA AJAX COMMUNITY CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6156150",
        "Station ID": 52703,
        "WMO ID": null,
        "TC ID": "L3C",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.04,
        "Latitude": 435155800,
        "Longitude": -790209900,
        "Elevation (m)": 117.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA AJAX PAO TAU",
        "Province": "ONTARIO",
        "Climate ID": "6156152",
        "Station ID": 52705,
        "WMO ID": null,
        "TC ID": "L3E",
        "Latitude (Decimal Degrees)": 43.91,
        "Longitude (Decimal Degrees)": -79.06,
        "Latitude": 435424300,
        "Longitude": -790327500,
        "Elevation (m)": 148.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA AJAX VILLAGE CHRYSLER",
        "Province": "ONTARIO",
        "Climate ID": "6156153",
        "Station ID": 52702,
        "WMO ID": null,
        "TC ID": "L3B",
        "Latitude (Decimal Degrees)": 43.84,
        "Longitude (Decimal Degrees)": -79.03,
        "Latitude": 435029700,
        "Longitude": -790130500,
        "Elevation (m)": 114.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA AJAX WINTERMERE SOD",
        "Province": "ONTARIO",
        "Climate ID": "6156154",
        "Station ID": 52704,
        "WMO ID": null,
        "TC ID": "L3D",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -79.04,
        "Latitude": 435356500,
        "Longitude": -790217200,
        "Elevation (m)": 124.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA AJAX WATER SUPPLY",
        "Province": "ONTARIO",
        "Climate ID": "6156155",
        "Station ID": 52701,
        "WMO ID": null,
        "TC ID": "L3A",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.01,
        "Latitude": 434922000,
        "Longitude": -790025500,
        "Elevation (m)": 92.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA CALEDON EQUESTRIAN PARK",
        "Province": "ONTARIO",
        "Climate ID": "6156156",
        "Station ID": 52728,
        "WMO ID": null,
        "TC ID": "Z1N",
        "Latitude (Decimal Degrees)": 43.96,
        "Longitude (Decimal Degrees)": -79.84,
        "Latitude": 435744200,
        "Longitude": -795008800,
        "Elevation (m)": 301.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA DUFFERIN AND ST. CLAIR CIBC",
        "Province": "ONTARIO",
        "Climate ID": "6156157",
        "Station ID": 53838,
        "WMO ID": null,
        "TC ID": "L1B",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.44,
        "Latitude": 434041500,
        "Longitude": -792634600,
        "Elevation (m)": 173.5,
        "First Year": 2015,
        "Last Year": 2015,
        "HLY First Year": 2015,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA CLAREMONT SILO FARM",
        "Province": "ONTARIO",
        "Climate ID": "6156158",
        "Station ID": 52706,
        "WMO ID": null,
        "TC ID": "L3F",
        "Latitude (Decimal Degrees)": 43.99,
        "Longitude (Decimal Degrees)": -79.1,
        "Latitude": 435937900,
        "Longitude": -790543900,
        "Elevation (m)": 263.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA TORONTO INTERNATIONAL TRAP AND SKEET",
        "Province": "ONTARIO",
        "Climate ID": "6156159",
        "Station ID": 52731,
        "WMO ID": null,
        "TC ID": "Z5N",
        "Latitude (Decimal Degrees)": 44.19,
        "Longitude (Decimal Degrees)": -79.66,
        "Latitude": 441108100,
        "Longitude": -793950800,
        "Elevation (m)": 234.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA CONCORD RYDER",
        "Province": "ONTARIO",
        "Climate ID": "6156160",
        "Station ID": 52699,
        "WMO ID": null,
        "TC ID": "L1F",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 434903200,
        "Longitude": -793124200,
        "Elevation (m)": 226.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA DOWNSVIEW PARK",
        "Province": "ONTARIO",
        "Climate ID": "6156161",
        "Station ID": 52698,
        "WMO ID": null,
        "TC ID": "L1E",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 434451800,
        "Longitude": -792847600,
        "Elevation (m)": 207.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA KING CITY",
        "Province": "ONTARIO",
        "Climate ID": "6156162",
        "Station ID": 53778,
        "WMO ID": null,
        "TC ID": "L2D",
        "Latitude (Decimal Degrees)": 43.96,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 435750490,
        "Longitude": -793421790,
        "Elevation (m)": 366.5,
        "First Year": 2015,
        "Last Year": 2018,
        "HLY First Year": 2015,
        "HLY Last Year": 2018,
        "DLY First Year": 2018,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA HERSHEY CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6156164",
        "Station ID": 52732,
        "WMO ID": null,
        "TC ID": "Z1W",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.66,
        "Latitude": 433757600,
        "Longitude": -793922800,
        "Elevation (m)": 203.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA MARKHAM NORTH TOYOTA",
        "Province": "ONTARIO",
        "Climate ID": "6156165",
        "Station ID": 52700,
        "WMO ID": null,
        "TC ID": "L2F",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.34,
        "Latitude": 434901000,
        "Longitude": -792033810,
        "Elevation (m)": 187.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA BOUSFIELD FARMS",
        "Province": "ONTARIO",
        "Climate ID": "6156166",
        "Station ID": 52733,
        "WMO ID": null,
        "TC ID": "Z2W",
        "Latitude (Decimal Degrees)": 43.48,
        "Longitude (Decimal Degrees)": -79.89,
        "Latitude": 432838800,
        "Longitude": -795330400,
        "Elevation (m)": 197.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA TORONTO NORTH YORK MOTORS",
        "Province": "ONTARIO",
        "Climate ID": "6156168",
        "Station ID": 52678,
        "WMO ID": null,
        "TC ID": "L1D",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 434307100,
        "Longitude": -792807400,
        "Elevation (m)": 186.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA GENERAL MOTORS CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6156169",
        "Station ID": 52727,
        "WMO ID": null,
        "TC ID": "Z5E",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -78.86,
        "Latitude": 435350500,
        "Longitude": -785132500,
        "Elevation (m)": 125.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA DOWNSVIEW",
        "Province": "ONTARIO",
        "Climate ID": "6156170",
        "Station ID": 53760,
        "WMO ID": null,
        "TC ID": "L2C",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 434649300,
        "Longitude": -792802500,
        "Elevation (m)": 198.5,
        "First Year": 2015,
        "Last Year": 2018,
        "HLY First Year": 2015,
        "HLY Last Year": 2018,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ROYAL CANADIAN YACHT CLUB",
        "Province": "ONTARIO",
        "Climate ID": "6156171",
        "Station ID": 52618,
        "WMO ID": null,
        "TC ID": "Z2D",
        "Latitude (Decimal Degrees)": 43.64,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 433822300,
        "Longitude": -792053700,
        "Elevation (m)": 84.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA SCARBOROUGH TORONTO HUNT",
        "Province": "ONTARIO",
        "Climate ID": "6156172",
        "Station ID": 52641,
        "WMO ID": null,
        "TC ID": "L2A",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 434100000,
        "Longitude": -791614900,
        "Elevation (m)": 133.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA STONEY CREEK CARDINAL SCHOOL",
        "Province": "ONTARIO",
        "Climate ID": "6156174",
        "Station ID": 52720,
        "WMO ID": null,
        "TC ID": "L5A",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -79.75,
        "Latitude": 431320600,
        "Longitude": -794442600,
        "Elevation (m)": 104.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA MATTAMY ATHLETIC CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6156175",
        "Station ID": 52723,
        "WMO ID": null,
        "TC ID": "Z1D",
        "Latitude (Decimal Degrees)": 43.66,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 433942720,
        "Longitude": -792248540,
        "Elevation (m)": 137.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA TORONTO HYUNDAI",
        "Province": "ONTARIO",
        "Climate ID": "6156177",
        "Station ID": 52640,
        "WMO ID": null,
        "TC ID": "L1C",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 434156200,
        "Longitude": -792705700,
        "Elevation (m)": 186.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA U OF T SCARBOROUGH TENNIS CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6156179",
        "Station ID": 52724,
        "WMO ID": null,
        "TC ID": "Z1E",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.19,
        "Latitude": 434648300,
        "Longitude": -791107400,
        "Elevation (m)": 116.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA MONOPOLY PROPERTY MANAGEMENT",
        "Province": "ONTARIO",
        "Climate ID": "6156180",
        "Station ID": 52725,
        "WMO ID": null,
        "TC ID": "Z2E",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -79.31,
        "Latitude": 435127000,
        "Longitude": -791829600,
        "Elevation (m)": 176.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA UXBRIDGE LEE ACRES",
        "Province": "ONTARIO",
        "Climate ID": "6156181",
        "Station ID": 52718,
        "WMO ID": null,
        "TC ID": "L3H",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -79.21,
        "Latitude": 441015700,
        "Longitude": -791237800,
        "Elevation (m)": 290.5,
        "First Year": 2015,
        "Last Year": 2015,
        "HLY First Year": 2015,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA UXBRIDGE TARIS",
        "Province": "ONTARIO",
        "Climate ID": "6156182",
        "Station ID": 52707,
        "WMO ID": null,
        "TC ID": "L3G",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -79.12,
        "Latitude": 440316000,
        "Longitude": -790655000,
        "Elevation (m)": 359.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA YORK UNIVERSITY",
        "Province": "ONTARIO",
        "Climate ID": "6156183",
        "Station ID": 53758,
        "WMO ID": null,
        "TC ID": "L2B",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.51,
        "Latitude": 434631200,
        "Longitude": -793035900,
        "Elevation (m)": 200.5,
        "First Year": 2015,
        "Last Year": 2015,
        "HLY First Year": 2015,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA WHITBY ABILITIES CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6156184",
        "Station ID": 52726,
        "WMO ID": null,
        "TC ID": "Z4E",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -78.94,
        "Latitude": 435140600,
        "Longitude": -785638500,
        "Elevation (m)": 99.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA ANGUS GLEN GOLF CLUB",
        "Province": "ONTARIO",
        "Climate ID": "6156186",
        "Station ID": 52638,
        "WMO ID": null,
        "TC ID": "Z3E",
        "Latitude (Decimal Degrees)": 43.91,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 435429800,
        "Longitude": -791923400,
        "Elevation (m)": 230.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PA HAMILTON SOCCER",
        "Province": "ONTARIO",
        "Climate ID": "6156187",
        "Station ID": 53759,
        "WMO ID": null,
        "TC ID": "Z3W",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 431503600,
        "Longitude": -794946400,
        "Elevation (m)": 93.5,
        "First Year": 2015,
        "Last Year": 2015,
        "HLY First Year": 2015,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PALERMO",
        "Province": "ONTARIO",
        "Climate ID": "6156215",
        "Station ID": 4997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.42,
        "Longitude (Decimal Degrees)": -79.77,
        "Latitude": 432500000,
        "Longitude": -794600000,
        "Elevation (m)": 155.4,
        "First Year": 1962,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1967,
        "MLY First Year": 1962,
        "MLY Last Year": 1967
      },
      {
        "Name": "PALGRAVE",
        "Province": "ONTARIO",
        "Climate ID": "6156227",
        "Station ID": 4998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -79.85,
        "Latitude": 435700000,
        "Longitude": -795100000,
        "Elevation (m)": 312.7,
        "First Year": 1956,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1984,
        "MLY First Year": 1956,
        "MLY Last Year": 1984
      },
      {
        "Name": "PARMA",
        "Province": "ONTARIO",
        "Climate ID": "6156251",
        "Station ID": 4999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -76.93,
        "Latitude": 440900000,
        "Longitude": -765600000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1907,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1907,
        "MLY First Year": 1895,
        "MLY Last Year": 1907
      },
      {
        "Name": "PETERS CORNERS",
        "Province": "ONTARIO",
        "Climate ID": "6156470",
        "Station ID": 5000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.3,
        "Longitude (Decimal Degrees)": -80.1,
        "Latitude": 431800000,
        "Longitude": -800600000,
        "Elevation (m)": 243.8,
        "First Year": 1952,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1970,
        "MLY First Year": 1952,
        "MLY Last Year": 1970
      },
      {
        "Name": "PICKERING",
        "Province": "ONTARIO",
        "Climate ID": "6156513",
        "Station ID": 5001,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.85,
        "Longitude (Decimal Degrees)": -79.05,
        "Latitude": 435100000,
        "Longitude": -790300000,
        "Elevation (m)": 91.4,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "PICKERING AUDLEY",
        "Province": "ONTARIO",
        "Climate ID": "6156515",
        "Station ID": 5002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -79.05,
        "Latitude": 435400000,
        "Longitude": -790300000,
        "Elevation (m)": 109.7,
        "First Year": 1958,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1985,
        "MLY First Year": 1958,
        "MLY Last Year": 1985
      },
      {
        "Name": "PICKERING DUNBARTON",
        "Province": "ONTARIO",
        "Climate ID": "6156516",
        "Station ID": 5003,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -79.12,
        "Latitude": 435000000,
        "Longitude": -790700000,
        "Elevation (m)": 107,
        "First Year": 1986,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1990,
        "MLY First Year": 1986,
        "MLY Last Year": 1989
      },
      {
        "Name": "PICKERING FIRE HALL #5",
        "Province": "ONTARIO",
        "Climate ID": "6156518",
        "Station ID": 5004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 435000000,
        "Longitude": -790500000,
        "Elevation (m)": 83.8,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "PICTON",
        "Province": "ONTARIO",
        "Climate ID": "6156533",
        "Station ID": 5005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -77.13,
        "Latitude": 440100000,
        "Longitude": -770800000,
        "Elevation (m)": 76.2,
        "First Year": 1915,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1995,
        "MLY First Year": 1915,
        "MLY Last Year": 1995
      },
      {
        "Name": "PICTON A",
        "Province": "ONTARIO",
        "Climate ID": "6156535",
        "Station ID": 5006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.98,
        "Longitude (Decimal Degrees)": -77.15,
        "Latitude": 435900000,
        "Longitude": -770900000,
        "Elevation (m)": 97.5,
        "First Year": 1956,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1961
      },
      {
        "Name": "PINE GROVE",
        "Province": "ONTARIO",
        "Climate ID": "6156545",
        "Station ID": 5007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 434800000,
        "Longitude": -793500000,
        "Elevation (m)": 182.9,
        "First Year": 1957,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1996,
        "MLY First Year": 1957,
        "MLY Last Year": 1996
      },
      {
        "Name": "POINT PETRE (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6156559",
        "Station ID": 7925,
        "WMO ID": 71430,
        "TC ID": "WQP",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -77.15,
        "Latitude": 435000000,
        "Longitude": -770900000,
        "Elevation (m)": 78.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PONTYPOOL",
        "Province": "ONTARIO",
        "Climate ID": "6156561",
        "Station ID": 27866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -78.63,
        "Latitude": 440400000,
        "Longitude": -783800000,
        "Elevation (m)": 372.7,
        "First Year": 1999,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2003,
        "MLY First Year": 1999,
        "MLY Last Year": 2003
      },
      {
        "Name": "PORT CREDIT",
        "Province": "ONTARIO",
        "Climate ID": "6156609",
        "Station ID": 5008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.57,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 433400000,
        "Longitude": -793700000,
        "Elevation (m)": 99.1,
        "First Year": 1871,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1959,
        "MLY First Year": 1871,
        "MLY Last Year": 1959
      },
      {
        "Name": "PORT CREDIT APPLEWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6156615",
        "Station ID": 5011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.57,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 433400000,
        "Longitude": -793000000,
        "Elevation (m)": 91.4,
        "First Year": 1958,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1961,
        "MLY First Year": 1958,
        "MLY Last Year": 1961
      },
      {
        "Name": "PORT DARLINGTON WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6156634",
        "Station ID": 5012,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -78.65,
        "Latitude": 435400000,
        "Longitude": -783900000,
        "Elevation (m)": 80.2,
        "First Year": 1982,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1994,
        "MLY First Year": 1982,
        "MLY Last Year": 1994
      },
      {
        "Name": "PORT HOPE",
        "Province": "ONTARIO",
        "Climate ID": "6156670",
        "Station ID": 5013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -78.28,
        "Latitude": 435700000,
        "Longitude": -781700000,
        "Elevation (m)": 80.8,
        "First Year": 1882,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1992,
        "MLY First Year": 1882,
        "MLY Last Year": 1992
      },
      {
        "Name": "PORT PERRY NONQUON",
        "Province": "ONTARIO",
        "Climate ID": "6156682",
        "Station ID": 5014,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -78.97,
        "Latitude": 440900000,
        "Longitude": -785800000,
        "Elevation (m)": 253,
        "First Year": 1983,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2007,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "QUEENSVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6156807",
        "Station ID": 5015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 440700000,
        "Longitude": -792500000,
        "Elevation (m)": 268.2,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "MONO CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6157000",
        "Station ID": 51938,
        "WMO ID": 71098,
        "TC ID": "TRY",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 440156100,
        "Longitude": -800128010,
        "Elevation (m)": 436,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RICHMOND HILL",
        "Province": "ONTARIO",
        "Climate ID": "6157012",
        "Station ID": 5016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 435238094,
        "Longitude": -792652074,
        "Elevation (m)": 240,
        "First Year": 1959,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2014,
        "MLY First Year": 1959,
        "MLY Last Year": 2006
      },
      {
        "Name": "RICHMOND HILL WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6157014",
        "Station ID": 5022,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 435200000,
        "Longitude": -792600000,
        "Elevation (m)": 217.9,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "RICHMOND HILL 2",
        "Province": "ONTARIO",
        "Climate ID": "6157015",
        "Station ID": 5023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 435400000,
        "Longitude": -792400000,
        "Elevation (m)": 233,
        "First Year": 1989,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1991,
        "MLY First Year": 1989,
        "MLY Last Year": 1991
      },
      {
        "Name": "ROUGE HILLS",
        "Province": "ONTARIO",
        "Climate ID": "6157190",
        "Station ID": 5024,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 434800000,
        "Longitude": -790800000,
        "Elevation (m)": 114.3,
        "First Year": 1954,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "ROUGE PARK",
        "Province": "ONTARIO",
        "Climate ID": "6157194",
        "Station ID": 5025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 434900000,
        "Longitude": -790800000,
        "Elevation (m)": 125,
        "First Year": 1960,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1993,
        "MLY First Year": 1960,
        "MLY Last Year": 1993
      },
      {
        "Name": "SANDHILL",
        "Province": "ONTARIO",
        "Climate ID": "6157430",
        "Station ID": 5026,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -80.03,
        "Latitude": 435500000,
        "Longitude": -800200000,
        "Elevation (m)": 289.6,
        "First Year": 1946,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1947,
        "MLY First Year": 1946,
        "MLY Last Year": 1947
      },
      {
        "Name": "SANDHILL",
        "Province": "ONTARIO",
        "Climate ID": "6157431",
        "Station ID": 5027,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.81,
        "Longitude (Decimal Degrees)": -79.81,
        "Latitude": 434829088,
        "Longitude": -794829076,
        "Elevation (m)": 270,
        "First Year": 1981,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2010,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "SCARBOROUGH JUNCTION",
        "Province": "ONTARIO",
        "Climate ID": "6157625",
        "Station ID": 5028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.22,
        "Latitude": 434300000,
        "Longitude": -791300000,
        "Elevation (m)": 167,
        "First Year": 1883,
        "Last Year": 1912,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1912,
        "MLY First Year": 1883,
        "MLY Last Year": 1912
      },
      {
        "Name": "SEAGRAVE",
        "Province": "ONTARIO",
        "Climate ID": "6157672",
        "Station ID": 5029,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.18,
        "Longitude (Decimal Degrees)": -78.93,
        "Latitude": 441100000,
        "Longitude": -785600000,
        "Elevation (m)": 256,
        "First Year": 1981,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1987,
        "MLY First Year": 1981,
        "MLY Last Year": 1987
      },
      {
        "Name": "SHANNONVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6157683",
        "Station ID": 5030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.2,
        "Longitude (Decimal Degrees)": -77.22,
        "Latitude": 441200000,
        "Longitude": -771300000,
        "Elevation (m)": null,
        "First Year": 1883,
        "Last Year": 1894,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1894,
        "MLY First Year": 1883,
        "MLY Last Year": 1894
      },
      {
        "Name": "SHARON",
        "Province": "ONTARIO",
        "Climate ID": "6157685",
        "Station ID": 5031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 440600000,
        "Longitude": -792600000,
        "Elevation (m)": 262.4,
        "First Year": 1886,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1999,
        "MLY First Year": 1886,
        "MLY Last Year": 1999
      },
      {
        "Name": "SMITHFIELD CDA",
        "Province": "ONTARIO",
        "Climate ID": "6157831",
        "Station ID": 5032,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -77.67,
        "Latitude": 440500000,
        "Longitude": -774000000,
        "Elevation (m)": 119,
        "First Year": 1949,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1990,
        "MLY First Year": 1949,
        "MLY Last Year": 1988
      },
      {
        "Name": "SMITHFIELD CDA AUTOMATIC CLIMATE STATION",
        "Province": "ONTARIO",
        "Climate ID": "6157832",
        "Station ID": 5033,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -77.67,
        "Latitude": 440500000,
        "Longitude": -774000000,
        "Elevation (m)": 119,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1989,
        "MLY Last Year": 1996
      },
      {
        "Name": "SNELGROVE",
        "Province": "ONTARIO",
        "Climate ID": "6157859",
        "Station ID": 5034,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 434500000,
        "Longitude": -795000000,
        "Elevation (m)": 251.5,
        "First Year": 1950,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1962,
        "MLY First Year": 1950,
        "MLY Last Year": 1962
      },
      {
        "Name": "SANDFORD SOLETUDE",
        "Province": "ONTARIO",
        "Climate ID": "6157875",
        "Station ID": 31049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 440900000,
        "Longitude": -791400000,
        "Elevation (m)": 247,
        "First Year": 2001,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2006,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOPER CREEK WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6157884",
        "Station ID": 5035,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.9,
        "Longitude (Decimal Degrees)": -78.67,
        "Latitude": 435400000,
        "Longitude": -784000000,
        "Elevation (m)": 83.8,
        "First Year": 1978,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1982,
        "MLY First Year": 1978,
        "MLY Last Year": 1982
      },
      {
        "Name": "SOUTH BAY",
        "Province": "ONTARIO",
        "Climate ID": "6157913",
        "Station ID": 5036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -77.03,
        "Latitude": 435500000,
        "Longitude": -770200000,
        "Elevation (m)": 76.2,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "SPEYSIDE IHD",
        "Province": "ONTARIO",
        "Climate ID": "6157975",
        "Station ID": 5037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -79.98,
        "Latitude": 433500000,
        "Longitude": -795900000,
        "Elevation (m)": 335.3,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "SPEYSIDE IHD",
        "Province": "ONTARIO",
        "Climate ID": "6157976",
        "Station ID": 5038,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.55,
        "Longitude (Decimal Degrees)": -80.02,
        "Latitude": 433300000,
        "Longitude": -800100000,
        "Elevation (m)": 327.7,
        "First Year": 1968,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1975,
        "MLY First Year": 1968,
        "MLY Last Year": 1975
      },
      {
        "Name": "STELLA-AMHERST",
        "Province": "ONTARIO",
        "Climate ID": "6158012",
        "Station ID": 5041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -76.65,
        "Latitude": 440900000,
        "Longitude": -763900000,
        "Elevation (m)": 78,
        "First Year": 1978,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1982,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "STEWARTOWN",
        "Province": "ONTARIO",
        "Climate ID": "6158025",
        "Station ID": 5042,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.93,
        "Latitude": 433700000,
        "Longitude": -795600000,
        "Elevation (m)": 259.1,
        "First Year": 1970,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1976,
        "MLY First Year": 1970,
        "MLY Last Year": 1976
      },
      {
        "Name": "STIRLING",
        "Province": "ONTARIO",
        "Climate ID": "6158050",
        "Station ID": 5043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -77.63,
        "Latitude": 441900000,
        "Longitude": -773800000,
        "Elevation (m)": 138.7,
        "First Year": 1940,
        "Last Year": 1968,
        "HLY First Year": 1953,
        "HLY Last Year": 1968,
        "DLY First Year": 1940,
        "DLY Last Year": 1968,
        "MLY First Year": 1940,
        "MLY Last Year": 1968
      },
      {
        "Name": "STIRLING",
        "Province": "ONTARIO",
        "Climate ID": "6158051",
        "Station ID": 27615,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.29,
        "Longitude (Decimal Degrees)": -77.55,
        "Latitude": 441735094,
        "Longitude": -773317082,
        "Elevation (m)": 130,
        "First Year": 1998,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2007,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "STIRLING",
        "Province": "ONTARIO",
        "Climate ID": "6158052",
        "Station ID": 46387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.29,
        "Longitude (Decimal Degrees)": -77.55,
        "Latitude": 441735094,
        "Longitude": -773317082,
        "Elevation (m)": 130,
        "First Year": 2007,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STONEY CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6158065",
        "Station ID": 5044,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.22,
        "Longitude (Decimal Degrees)": -79.75,
        "Latitude": 431300000,
        "Longitude": -794500000,
        "Elevation (m)": 89,
        "First Year": 1884,
        "Last Year": 1927,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1884,
        "DLY Last Year": 1927,
        "MLY First Year": 1884,
        "MLY Last Year": 1927
      },
      {
        "Name": "STOUFFVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6158080",
        "Station ID": 5045,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 440000000,
        "Longitude": -791600000,
        "Elevation (m)": 312.4,
        "First Year": 1960,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1981,
        "MLY First Year": 1960,
        "MLY Last Year": 1981
      },
      {
        "Name": "STOUFFVILLE BLUEGRASS",
        "Province": "ONTARIO",
        "Climate ID": "6158082",
        "Station ID": 5046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44,
        "Longitude (Decimal Degrees)": -79.2,
        "Latitude": 440000000,
        "Longitude": -791200000,
        "Elevation (m)": 297.2,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "STOUFFVILLE WPCP",
        "Province": "ONTARIO",
        "Climate ID": "6158084",
        "Station ID": 5047,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 435800000,
        "Longitude": -791500000,
        "Elevation (m)": 266.7,
        "First Year": 1971,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1993,
        "MLY First Year": 1971,
        "MLY Last Year": 1993
      },
      {
        "Name": "STREETSVILLE OWRC",
        "Province": "ONTARIO",
        "Climate ID": "6158140",
        "Station ID": 5048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -79.7,
        "Latitude": 433500000,
        "Longitude": -794200000,
        "Elevation (m)": 144.8,
        "First Year": 1963,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1971,
        "MLY First Year": 1963,
        "MLY Last Year": 1971
      },
      {
        "Name": "THORNHILL GRANDVIEW",
        "Province": "ONTARIO",
        "Climate ID": "6158255",
        "Station ID": 5049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 434800000,
        "Longitude": -792500000,
        "Elevation (m)": 199.3,
        "First Year": 1965,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2007,
        "MLY First Year": 1965,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO",
        "Province": "ONTARIO",
        "Climate ID": "6158350",
        "Station ID": 5051,
        "WMO ID": 71266,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 434000000,
        "Longitude": -792400000,
        "Elevation (m)": 112.5,
        "First Year": 1840,
        "Last Year": 2017,
        "HLY First Year": 1953,
        "HLY Last Year": 1969,
        "DLY First Year": 1840,
        "DLY Last Year": 2017,
        "MLY First Year": 1840,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO SOLAR RADIATION",
        "Province": "ONTARIO",
        "Climate ID": "6158352",
        "Station ID": 41863,
        "WMO ID": 71626,
        "TC ID": "TRF",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 434000000,
        "Longitude": -792400000,
        "Elevation (m)": 166,
        "First Year": 2018,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2018,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO CITY",
        "Province": "ONTARIO",
        "Climate ID": "6158355",
        "Station ID": 31688,
        "WMO ID": 71508,
        "TC ID": "XTO",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 434000000,
        "Longitude": -792400000,
        "Elevation (m)": 112.5,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2003,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO CITY CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6158359",
        "Station ID": 48549,
        "WMO ID": 71265,
        "TC ID": "YTZ",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 433739000,
        "Longitude": -792346000,
        "Elevation (m)": 76.8,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO AGINCOURT",
        "Province": "ONTARIO",
        "Climate ID": "6158363",
        "Station ID": 5052,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 434700000,
        "Longitude": -791600000,
        "Elevation (m)": 179.8,
        "First Year": 1895,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1968,
        "MLY First Year": 1895,
        "MLY Last Year": 1968
      },
      {
        "Name": "TORONTO ASHBRIDGES BAY",
        "Province": "ONTARIO",
        "Climate ID": "6158370",
        "Station ID": 5053,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 434000000,
        "Longitude": -791900000,
        "Elevation (m)": 74.1,
        "First Year": 1958,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1997,
        "MLY First Year": 1958,
        "MLY Last Year": 1997
      },
      {
        "Name": "TORONTO BALMY BEACH",
        "Province": "ONTARIO",
        "Climate ID": "6158381",
        "Station ID": 5054,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.28,
        "Latitude": 434000000,
        "Longitude": -791700000,
        "Elevation (m)": 99.1,
        "First Year": 1953,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1956,
        "MLY First Year": 1953,
        "MLY Last Year": 1955
      },
      {
        "Name": "TORONTO BEACON ROAD",
        "Province": "ONTARIO",
        "Climate ID": "6158384",
        "Station ID": 5055,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 434500000,
        "Longitude": -791600000,
        "Elevation (m)": 167.6,
        "First Year": 1962,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1975,
        "MLY First Year": 1962,
        "MLY Last Year": 1975
      },
      {
        "Name": "TORONTO BERMONDSEY",
        "Province": "ONTARIO",
        "Climate ID": "6158385",
        "Station ID": 5056,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 434300000,
        "Longitude": -791900000,
        "Elevation (m)": 138.4,
        "First Year": 1973,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1984,
        "MLY First Year": 1973,
        "MLY Last Year": 1984
      },
      {
        "Name": "TORONTO BEVERLEY HILLS",
        "Province": "ONTARIO",
        "Climate ID": "6158386",
        "Station ID": 5057,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 434400000,
        "Longitude": -793000000,
        "Elevation (m)": 144.8,
        "First Year": 1957,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1981,
        "MLY First Year": 1957,
        "MLY Last Year": 1981
      },
      {
        "Name": "TORONTO BLACK CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6158398",
        "Station ID": 5058,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 434600000,
        "Longitude": -793100000,
        "Elevation (m)": 185.9,
        "First Year": 1960,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1979,
        "MLY First Year": 1960,
        "MLY Last Year": 1979
      },
      {
        "Name": "TORONTO AMESBURY",
        "Province": "ONTARIO",
        "Climate ID": "61583FL",
        "Station ID": 5050,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 434200000,
        "Longitude": -792900000,
        "Elevation (m)": 153.9,
        "First Year": 1980,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1987,
        "MLY First Year": 1980,
        "MLY Last Year": 1987
      },
      {
        "Name": "TORONTO BLOORDALE",
        "Province": "ONTARIO",
        "Climate ID": "6158401",
        "Station ID": 5060,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 433900000,
        "Longitude": -793300000,
        "Elevation (m)": 132.6,
        "First Year": 1957,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1965,
        "MLY First Year": 1957,
        "MLY Last Year": 1965
      },
      {
        "Name": "TORONTO BOOTH",
        "Province": "ONTARIO",
        "Climate ID": "6158406",
        "Station ID": 5061,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 433900000,
        "Longitude": -792100000,
        "Elevation (m)": 77.1,
        "First Year": 1980,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1993,
        "MLY First Year": 1980,
        "MLY Last Year": 1993
      },
      {
        "Name": "TORONTO BRIDLEWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6158408",
        "Station ID": 5062,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 434700000,
        "Longitude": -791900000,
        "Elevation (m)": 182.9,
        "First Year": 1967,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1980,
        "MLY First Year": 1967,
        "MLY Last Year": 1980
      },
      {
        "Name": "TORONTO BUTTONVILLE A",
        "Province": "ONTARIO",
        "Climate ID": "6158409",
        "Station ID": 54239,
        "WMO ID": null,
        "TC ID": "YKZ",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 435139000,
        "Longitude": -792207000,
        "Elevation (m)": 198.1,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO BUTTONVILLE A",
        "Province": "ONTARIO",
        "Climate ID": "6158410",
        "Station ID": 53678,
        "WMO ID": 71639,
        "TC ID": "YKZ",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 435139000,
        "Longitude": -792207000,
        "Elevation (m)": 198.1,
        "First Year": 2015,
        "Last Year": 2019,
        "HLY First Year": 2015,
        "HLY Last Year": 2019,
        "DLY First Year": 2015,
        "DLY Last Year": 2019,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO BURNHAMTHORPE",
        "Province": "ONTARIO",
        "Climate ID": "6158411",
        "Station ID": 43203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.64,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 433836000,
        "Longitude": -793416000,
        "Elevation (m)": 143,
        "First Year": 2004,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2004,
        "DLY Last Year": 2008,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO BROADVIEW",
        "Province": "ONTARIO",
        "Climate ID": "6158412",
        "Station ID": 5063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 434000000,
        "Longitude": -792100000,
        "Elevation (m)": 99.1,
        "First Year": 1955,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1961,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "TORONTO CURRAN HALL",
        "Province": "ONTARIO",
        "Climate ID": "6158415",
        "Station ID": 5064,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.2,
        "Latitude": 434500000,
        "Longitude": -791200000,
        "Elevation (m)": 143.3,
        "First Year": 1960,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1965,
        "MLY First Year": 1960,
        "MLY Last Year": 1965
      },
      {
        "Name": "TORONTO DEER PARK",
        "Province": "ONTARIO",
        "Climate ID": "6158417",
        "Station ID": 5065,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 434100000,
        "Longitude": -792300000,
        "Elevation (m)": 143.6,
        "First Year": 1890,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1890,
        "DLY Last Year": 1933,
        "MLY First Year": 1890,
        "MLY Last Year": 1933
      },
      {
        "Name": "TORONTO DISCO",
        "Province": "ONTARIO",
        "Climate ID": "6158418",
        "Station ID": 5066,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.62,
        "Latitude": 434200000,
        "Longitude": -793700000,
        "Elevation (m)": 160,
        "First Year": 1976,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1977,
        "MLY First Year": 1976,
        "MLY Last Year": 1977
      },
      {
        "Name": "TORONTO DON MILLS",
        "Province": "ONTARIO",
        "Climate ID": "6158420",
        "Station ID": 5067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 434700000,
        "Longitude": -792000000,
        "Elevation (m)": null,
        "First Year": 1963,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1966
      },
      {
        "Name": "TORONTO DORSET PARK",
        "Province": "ONTARIO",
        "Climate ID": "6158422",
        "Station ID": 5068,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.28,
        "Latitude": 434500000,
        "Longitude": -791700000,
        "Elevation (m)": 167.6,
        "First Year": 1957,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1965,
        "MLY First Year": 1959,
        "MLY Last Year": 1965
      },
      {
        "Name": "TORONTO 407 AND YONGE",
        "Province": "ONTARIO",
        "Climate ID": "6158440",
        "Station ID": 27448,
        "WMO ID": null,
        "TC ID": "WHD",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 435000000,
        "Longitude": -792600000,
        "Elevation (m)": 195,
        "First Year": 1998,
        "Last Year": 1999,
        "HLY First Year": 1998,
        "HLY Last Year": 1999,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO 407 AND MAVIS",
        "Province": "ONTARIO",
        "Climate ID": "6158442",
        "Station ID": 27447,
        "WMO ID": null,
        "TC ID": "WTR",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.73,
        "Latitude": 433900000,
        "Longitude": -794400000,
        "Elevation (m)": 203.66,
        "First Year": 1998,
        "Last Year": 1999,
        "HLY First Year": 1998,
        "HLY Last Year": 1999,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO DOWNSVIEW A",
        "Province": "ONTARIO",
        "Climate ID": "6158443",
        "Station ID": 5069,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 434500000,
        "Longitude": -792900000,
        "Elevation (m)": 198.1,
        "First Year": 1956,
        "Last Year": 1982,
        "HLY First Year": 1956,
        "HLY Last Year": 1982,
        "DLY First Year": 1956,
        "DLY Last Year": 1982,
        "MLY First Year": 1957,
        "MLY Last Year": 1982
      },
      {
        "Name": "TORONTO DOWNSVIEW S",
        "Province": "ONTARIO",
        "Climate ID": "6158474",
        "Station ID": 5070,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 434300000,
        "Longitude": -792900000,
        "Elevation (m)": 160,
        "First Year": 1951,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1970,
        "MLY First Year": 1951,
        "MLY Last Year": 1970
      },
      {
        "Name": "TORONTO DUNN LORING WOOD",
        "Province": "ONTARIO",
        "Climate ID": "6158480",
        "Station ID": 5071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 434800000,
        "Longitude": -792100000,
        "Elevation (m)": 175.3,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "TORONTO BROADWAY",
        "Province": "ONTARIO",
        "Climate ID": "61584J2",
        "Station ID": 5059,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 434300000,
        "Longitude": -792100000,
        "Elevation (m)": 139,
        "First Year": 1981,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1988,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO EAST YORK",
        "Province": "ONTARIO",
        "Climate ID": "6158505",
        "Station ID": 5072,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 434200000,
        "Longitude": -792000000,
        "Elevation (m)": 121.9,
        "First Year": 1951,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1957,
        "MLY First Year": 1951,
        "MLY Last Year": 1957
      },
      {
        "Name": "TORONTO ELLESMERE",
        "Province": "ONTARIO",
        "Climate ID": "6158520",
        "Station ID": 5073,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 434600000,
        "Longitude": -791600000,
        "Elevation (m)": 164,
        "First Year": 1959,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1994,
        "MLY First Year": 1959,
        "MLY Last Year": 1994
      },
      {
        "Name": "TORONTO ETOBICOKE",
        "Province": "ONTARIO",
        "Climate ID": "6158525",
        "Station ID": 5074,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.53,
        "Latitude": 433800000,
        "Longitude": -793200000,
        "Elevation (m)": 118.9,
        "First Year": 1963,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1980,
        "MLY First Year": 1963,
        "MLY Last Year": 1980
      },
      {
        "Name": "TORONTO FALLINGBROOK",
        "Province": "ONTARIO",
        "Climate ID": "6158536",
        "Station ID": 5075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 434100000,
        "Longitude": -791600000,
        "Elevation (m)": 129.5,
        "First Year": 1957,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1976,
        "MLY First Year": 1957,
        "MLY Last Year": 1976
      },
      {
        "Name": "TORONTO GLENDALE",
        "Province": "ONTARIO",
        "Climate ID": "6158550",
        "Station ID": 5076,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 434500000,
        "Longitude": -792500000,
        "Elevation (m)": 137.2,
        "First Year": 1958,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1969,
        "MLY First Year": 1958,
        "MLY Last Year": 1969
      },
      {
        "Name": "TORONTO GLENVIEW",
        "Province": "ONTARIO",
        "Climate ID": "6158567",
        "Station ID": 5077,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 434200000,
        "Longitude": -792700000,
        "Elevation (m)": 173.7,
        "First Year": 1953,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1966,
        "MLY First Year": 1953,
        "MLY Last Year": 1966
      },
      {
        "Name": "TORONTO GREENWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6158575",
        "Station ID": 5078,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 434000000,
        "Longitude": -791900000,
        "Elevation (m)": 99.1,
        "First Year": 1966,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1981,
        "MLY First Year": 1966,
        "MLY Last Year": 1981
      },
      {
        "Name": "TORONTO HEADLAND (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6158578",
        "Station ID": 7940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 433700000,
        "Longitude": -792100000,
        "Elevation (m)": 87,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": 1994,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO HIBISCUS COURT",
        "Province": "ONTARIO",
        "Climate ID": "6158585",
        "Station ID": 5079,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 434500000,
        "Longitude": -793500000,
        "Elevation (m)": 198.1,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "TORONTO HIGHLAND CREEK",
        "Province": "ONTARIO",
        "Climate ID": "6158598",
        "Station ID": 5080,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 434700000,
        "Longitude": -791000000,
        "Elevation (m)": 114.3,
        "First Year": 1955,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1972,
        "MLY First Year": 1955,
        "MLY Last Year": 1972
      },
      {
        "Name": "TORONTO HIGH PARK",
        "Province": "ONTARIO",
        "Climate ID": "6158629",
        "Station ID": 5081,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 433900000,
        "Longitude": -792800000,
        "Elevation (m)": 106.7,
        "First Year": 1951,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1962,
        "MLY First Year": 1951,
        "MLY Last Year": 1962
      },
      {
        "Name": "TORONTO HUMBER BAY",
        "Province": "ONTARIO",
        "Climate ID": "6158645",
        "Station ID": 5082,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 433800000,
        "Longitude": -793000000,
        "Elevation (m)": 83.8,
        "First Year": 1957,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1962,
        "MLY First Year": 1957,
        "MLY Last Year": 1962
      },
      {
        "Name": "TORONTO HUMBER SUMMIT",
        "Province": "ONTARIO",
        "Climate ID": "6158647",
        "Station ID": 5083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 434500000,
        "Longitude": -793300000,
        "Elevation (m)": 149.4,
        "First Year": 1969,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1972
      },
      {
        "Name": "TORONTO ISLAND",
        "Province": "ONTARIO",
        "Climate ID": "6158660",
        "Station ID": 5084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 433700000,
        "Longitude": -792400000,
        "Elevation (m)": 78,
        "First Year": 1905,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1905,
        "DLY Last Year": 1959,
        "MLY First Year": 1953,
        "MLY Last Year": 1959
      },
      {
        "Name": "TORONTO ISLAND A",
        "Province": "ONTARIO",
        "Climate ID": "6158665",
        "Station ID": 5085,
        "WMO ID": 71265,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 433743000,
        "Longitude": -792342000,
        "Elevation (m)": 76.5,
        "First Year": 1957,
        "Last Year": 2006,
        "HLY First Year": 1957,
        "HLY Last Year": 2006,
        "DLY First Year": 1957,
        "DLY Last Year": 2006,
        "MLY First Year": 1957,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO IS A (AUT)",
        "Province": "ONTARIO",
        "Climate ID": "6158666",
        "Station ID": 5086,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 433800000,
        "Longitude": -792400000,
        "Elevation (m)": 76.5,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO CITY CENTRE",
        "Province": "ONTARIO",
        "Climate ID": "6158667",
        "Station ID": 30247,
        "WMO ID": 71265,
        "TC ID": "YTZ",
        "Latitude (Decimal Degrees)": 43.63,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 433743000,
        "Longitude": -792342000,
        "Elevation (m)": 76.81,
        "First Year": 2006,
        "Last Year": 2014,
        "HLY First Year": 2006,
        "HLY Last Year": 2010,
        "DLY First Year": 2006,
        "DLY Last Year": 2014,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO ISLINGTON",
        "Province": "ONTARIO",
        "Climate ID": "6158691",
        "Station ID": 5087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 433900000,
        "Longitude": -793300000,
        "Elevation (m)": 132.6,
        "First Year": 1951,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1975,
        "MLY First Year": 1951,
        "MLY Last Year": 1975
      },
      {
        "Name": "TORONTO JANE-WILSON",
        "Province": "ONTARIO",
        "Climate ID": "6158712",
        "Station ID": 5092,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 434300000,
        "Longitude": -793100000,
        "Elevation (m)": 129.5,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "TORONTO KEELE-FINCH",
        "Province": "ONTARIO",
        "Climate ID": "6158718",
        "Station ID": 5093,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 434600000,
        "Longitude": -792900000,
        "Elevation (m)": 199.9,
        "First Year": 1973,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1987,
        "MLY First Year": 1973,
        "MLY Last Year": 1987
      },
      {
        "Name": "TORONTO KINGSWAY",
        "Province": "ONTARIO",
        "Climate ID": "6158722",
        "Station ID": 5094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 433900000,
        "Longitude": -793100000,
        "Elevation (m)": 114.3,
        "First Year": 1951,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1961,
        "MLY First Year": 1951,
        "MLY Last Year": 1961
      },
      {
        "Name": "TORONTO LEASIDE S",
        "Province": "ONTARIO",
        "Climate ID": "6158730",
        "Station ID": 5095,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 434200000,
        "Longitude": -792200000,
        "Elevation (m)": 137.2,
        "First Year": 1951,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1956,
        "MLY First Year": 1951,
        "MLY Last Year": 1956
      },
      {
        "Name": "TORONTO INTL A",
        "Province": "ONTARIO",
        "Climate ID": "6158731",
        "Station ID": 51459,
        "WMO ID": 71624,
        "TC ID": "YYZ",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 434036000,
        "Longitude": -793750000,
        "Elevation (m)": 173.4,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO LESLIE EGLINTON",
        "Province": "ONTARIO",
        "Climate ID": "6158732",
        "Station ID": 5096,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 434300000,
        "Longitude": -792100000,
        "Elevation (m)": 133.3,
        "First Year": 1985,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1987,
        "MLY First Year": 1985,
        "MLY Last Year": 1987
      },
      {
        "Name": "TORONTO LESTER B. PEARSON INT'L A",
        "Province": "ONTARIO",
        "Climate ID": "6158733",
        "Station ID": 5097,
        "WMO ID": 71624,
        "TC ID": "YYZ",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 434038000,
        "Longitude": -793750000,
        "Elevation (m)": 173.4,
        "First Year": 1937,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1937,
        "DLY Last Year": 2013,
        "MLY First Year": 1937,
        "MLY Last Year": 2013
      },
      {
        "Name": "TORONTO LLOYDMINSTER",
        "Province": "ONTARIO",
        "Climate ID": "6158734",
        "Station ID": 5098,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 434800000,
        "Longitude": -792400000,
        "Elevation (m)": 167.6,
        "First Year": 1968,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1969,
        "MLY First Year": 1968,
        "MLY Last Year": 1969
      },
      {
        "Name": "TORONTO MALVERN",
        "Province": "ONTARIO",
        "Climate ID": "6158738",
        "Station ID": 5099,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 434800000,
        "Longitude": -791400000,
        "Elevation (m)": 160,
        "First Year": 1974,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1993,
        "MLY First Year": 1974,
        "MLY Last Year": 1993
      },
      {
        "Name": "TORONTO MET RES STN",
        "Province": "ONTARIO",
        "Climate ID": "6158740",
        "Station ID": 5100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 434800000,
        "Longitude": -793300000,
        "Elevation (m)": 193.5,
        "First Year": 1965,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1988,
        "MLY First Year": 1965,
        "MLY Last Year": 1988
      },
      {
        "Name": "TORONTO METRO ZOO",
        "Province": "ONTARIO",
        "Climate ID": "6158741",
        "Station ID": 5102,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.18,
        "Latitude": 434900000,
        "Longitude": -791100000,
        "Elevation (m)": 143.3,
        "First Year": 1976,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1993,
        "MLY First Year": 1976,
        "MLY Last Year": 1993
      },
      {
        "Name": "TORONTO MIMICO",
        "Province": "ONTARIO",
        "Climate ID": "6158745",
        "Station ID": 5103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.62,
        "Longitude (Decimal Degrees)": -79.48,
        "Latitude": 433700000,
        "Longitude": -792900000,
        "Elevation (m)": 76.2,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "TORONTO NASHDENE",
        "Province": "ONTARIO",
        "Climate ID": "6158748",
        "Station ID": 5104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.82,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 434900000,
        "Longitude": -791500000,
        "Elevation (m)": 177.4,
        "First Year": 1983,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1987,
        "MLY First Year": 1983,
        "MLY Last Year": 1987
      },
      {
        "Name": "TORONTO NEW INT'L A",
        "Province": "ONTARIO",
        "Climate ID": "6158749",
        "Station ID": 5105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 435700000,
        "Longitude": -790800000,
        "Elevation (m)": 262.7,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": 1973,
        "HLY Last Year": 1976,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "TORONTO EAST YORK DUSTAN",
        "Province": "ONTARIO",
        "Climate ID": "6158751",
        "Station ID": 45967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -79.34,
        "Latitude": 434148030,
        "Longitude": -792019014,
        "Elevation (m)": 125,
        "First Year": 2007,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2007,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO NEWTON",
        "Province": "ONTARIO",
        "Climate ID": "6158752",
        "Station ID": 5106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.8,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 434800000,
        "Longitude": -792500000,
        "Elevation (m)": 198.1,
        "First Year": 1971,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1975
      },
      {
        "Name": "TORONTO NEWTONBROOK",
        "Province": "ONTARIO",
        "Climate ID": "6158753",
        "Station ID": 5107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 434700000,
        "Longitude": -792500000,
        "Elevation (m)": 182.9,
        "First Year": 1953,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1955,
        "MLY First Year": 1954,
        "MLY Last Year": 1955
      },
      {
        "Name": "TORONTO NORTHCLIFFE",
        "Province": "ONTARIO",
        "Climate ID": "6158762",
        "Station ID": 5108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.68,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 434100000,
        "Longitude": -792700000,
        "Elevation (m)": 167.6,
        "First Year": 1957,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1981,
        "MLY First Year": 1957,
        "MLY Last Year": 1981
      },
      {
        "Name": "TOR OLD BURNHAMTHORPE",
        "Province": "ONTARIO",
        "Climate ID": "6158763",
        "Station ID": 5109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 433900000,
        "Longitude": -793500000,
        "Elevation (m)": 144.8,
        "First Year": 1973,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1995,
        "MLY First Year": 1973,
        "MLY Last Year": 1995
      },
      {
        "Name": "TORONTO OLD WESTON RD",
        "Province": "ONTARIO",
        "Climate ID": "6158764",
        "Station ID": 5110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 433900000,
        "Longitude": -792800000,
        "Elevation (m)": 121.9,
        "First Year": 1966,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1990,
        "MLY First Year": 1966,
        "MLY Last Year": 1990
      },
      {
        "Name": "TORONTO PINE RIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6158765",
        "Station ID": 5111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 434300000,
        "Longitude": -791400000,
        "Elevation (m)": 173.7,
        "First Year": 1958,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1965,
        "MLY First Year": 1958,
        "MLY Last Year": 1965
      },
      {
        "Name": "TORONTO REXDALE",
        "Province": "ONTARIO",
        "Climate ID": "6158768",
        "Station ID": 5112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 434400000,
        "Longitude": -793400000,
        "Elevation (m)": 152.4,
        "First Year": 1957,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1966,
        "MLY First Year": 1957,
        "MLY Last Year": 1966
      },
      {
        "Name": "TORONTO SANCTBURY",
        "Province": "ONTARIO",
        "Climate ID": "6158771",
        "Station ID": 5113,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.58,
        "Latitude": 434000000,
        "Longitude": -793500000,
        "Elevation (m)": 149.4,
        "First Year": 1970,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1979,
        "MLY First Year": 1970,
        "MLY Last Year": 1979
      },
      {
        "Name": "TORONTO SCARBOROUGH",
        "Province": "ONTARIO",
        "Climate ID": "6158776",
        "Station ID": 5114,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 434300000,
        "Longitude": -791400000,
        "Elevation (m)": 156.7,
        "First Year": 1961,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1971,
        "MLY First Year": 1961,
        "MLY Last Year": 1971
      },
      {
        "Name": "TORONTO SCARLETT RD",
        "Province": "ONTARIO",
        "Climate ID": "6158777",
        "Station ID": 5115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.5,
        "Latitude": 434000000,
        "Longitude": -793000000,
        "Elevation (m)": 121.9,
        "First Year": 1951,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1962,
        "MLY First Year": 1951,
        "MLY Last Year": 1962
      },
      {
        "Name": "TORONTO STUART AVENUE",
        "Province": "ONTARIO",
        "Climate ID": "6158778",
        "Station ID": 5116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 434600000,
        "Longitude": -792600000,
        "Elevation (m)": 190.5,
        "First Year": 1959,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1963,
        "MLY First Year": 1959,
        "MLY Last Year": 1963
      },
      {
        "Name": "TORONTO SUNNYBROOK",
        "Province": "ONTARIO",
        "Climate ID": "6158779",
        "Station ID": 5117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 434300000,
        "Longitude": -792300000,
        "Elevation (m)": 157,
        "First Year": 1962,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1993,
        "MLY First Year": 1962,
        "MLY Last Year": 1993
      },
      {
        "Name": "TORONTO VICTORIA",
        "Province": "ONTARIO",
        "Climate ID": "6158780",
        "Station ID": 5118,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 434300000,
        "Longitude": -791900000,
        "Elevation (m)": 121.9,
        "First Year": 1957,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1961,
        "MLY First Year": 1957,
        "MLY Last Year": 1961
      },
      {
        "Name": "TORONTO WEST DEANE PARK",
        "Province": "ONTARIO",
        "Climate ID": "6158783",
        "Station ID": 5119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.57,
        "Latitude": 434000000,
        "Longitude": -793400000,
        "Elevation (m)": 140.2,
        "First Year": 1959,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1974,
        "MLY First Year": 1959,
        "MLY Last Year": 1974
      },
      {
        "Name": "TORONTO WEST HILL",
        "Province": "ONTARIO",
        "Climate ID": "6158784",
        "Station ID": 5120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 434600000,
        "Longitude": -791000000,
        "Elevation (m)": 97.5,
        "First Year": 1951,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1986,
        "MLY First Year": 1951,
        "MLY Last Year": 1986
      },
      {
        "Name": "TORONTO WESTWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6158792",
        "Station ID": 5121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 434300000,
        "Longitude": -793800000,
        "Elevation (m)": 167.6,
        "First Year": 1967,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1972,
        "MLY First Year": 1967,
        "MLY Last Year": 1972
      },
      {
        "Name": "TOR SCARBOROUGH COLLEGE",
        "Province": "ONTARIO",
        "Climate ID": "61587P6",
        "Station ID": 5091,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.18,
        "Latitude": 434700000,
        "Longitude": -791100000,
        "Elevation (m)": 129.5,
        "First Year": 1972,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1980,
        "MLY First Year": 1972,
        "MLY Last Year": 1980
      },
      {
        "Name": "TORONTO SENECA HILL",
        "Province": "ONTARIO",
        "Climate ID": "61587PG",
        "Station ID": 5088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 434700000,
        "Longitude": -792100000,
        "Elevation (m)": 189,
        "First Year": 1973,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1987,
        "MLY First Year": 1973,
        "MLY Last Year": 1987
      },
      {
        "Name": "TORONTO SHERBOURNE",
        "Province": "ONTARIO",
        "Climate ID": "61587PP",
        "Station ID": 5089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.65,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 433900000,
        "Longitude": -792200000,
        "Elevation (m)": 76.2,
        "First Year": 1966,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1979,
        "MLY First Year": 1966,
        "MLY Last Year": 1979
      },
      {
        "Name": "TORONTO TABER PARK",
        "Province": "ONTARIO",
        "Climate ID": "61587PR",
        "Station ID": 5090,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.25,
        "Latitude": 434500000,
        "Longitude": -791500000,
        "Elevation (m)": null,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TORONTO WEXFORD",
        "Province": "ONTARIO",
        "Climate ID": "6158815",
        "Station ID": 5122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 434500000,
        "Longitude": -791800000,
        "Elevation (m)": 173.7,
        "First Year": 1953,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1958,
        "MLY First Year": 1953,
        "MLY Last Year": 1958
      },
      {
        "Name": "TORONTO WILLOWDALE",
        "Province": "ONTARIO",
        "Climate ID": "6158830",
        "Station ID": 5123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.42,
        "Latitude": 434600000,
        "Longitude": -792500000,
        "Elevation (m)": 190.5,
        "First Year": 1953,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1970,
        "MLY First Year": 1953,
        "MLY Last Year": 1970
      },
      {
        "Name": "TORONTO WILSON HEIGHTS",
        "Province": "ONTARIO",
        "Climate ID": "6158846",
        "Station ID": 5124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 434400000,
        "Longitude": -792600000,
        "Elevation (m)": 190.5,
        "First Year": 1953,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1973,
        "MLY First Year": 1953,
        "MLY Last Year": 1973
      },
      {
        "Name": "TRAFALGAR MARINE",
        "Province": "ONTARIO",
        "Climate ID": "6158860",
        "Station ID": 5125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.5,
        "Longitude (Decimal Degrees)": -79.73,
        "Latitude": 433000000,
        "Longitude": -794400000,
        "Elevation (m)": 175.3,
        "First Year": 1959,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1971,
        "MLY First Year": 1959,
        "MLY Last Year": 1971
      },
      {
        "Name": "TRENTON AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6158872",
        "Station ID": 30723,
        "WMO ID": null,
        "TC ID": "XTR",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -77.53,
        "Latitude": 440708000,
        "Longitude": -773141000,
        "Elevation (m)": 86.3,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TRENTON A",
        "Province": "ONTARIO",
        "Climate ID": "6158875",
        "Station ID": 5126,
        "WMO ID": 71621,
        "TC ID": "YTR",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -77.53,
        "Latitude": 440708000,
        "Longitude": -773141000,
        "Elevation (m)": 86.3,
        "First Year": 1935,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1935,
        "DLY Last Year": 2022,
        "MLY First Year": 1935,
        "MLY Last Year": 2017
      },
      {
        "Name": "TRENTON DOMTAR",
        "Province": "ONTARIO",
        "Climate ID": "6158878",
        "Station ID": 5127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -77.5,
        "Latitude": 440700000,
        "Longitude": -773000000,
        "Elevation (m)": 80,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "TRENTON ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6158885",
        "Station ID": 5128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -77.6,
        "Latitude": 440800000,
        "Longitude": -773600000,
        "Elevation (m)": 88.4,
        "First Year": 1915,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1992,
        "MLY First Year": 1915,
        "MLY Last Year": 1992
      },
      {
        "Name": "TRENTON MOE",
        "Province": "ONTARIO",
        "Climate ID": "6158888",
        "Station ID": 5130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -77.57,
        "Latitude": 440700000,
        "Longitude": -773400000,
        "Elevation (m)": 76.2,
        "First Year": 1960,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1978,
        "MLY First Year": 1960,
        "MLY Last Year": 1978
      },
      {
        "Name": "TORONTO BRYNSTON",
        "Province": "ONTARIO",
        "Climate ID": "6158D1B",
        "Station ID": 5039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.67,
        "Longitude (Decimal Degrees)": -79.55,
        "Latitude": 434000000,
        "Longitude": -793300000,
        "Elevation (m)": 149.4,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "TORONTO CASTLEMERE",
        "Province": "ONTARIO",
        "Climate ID": "6158M1K",
        "Station ID": 5040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.32,
        "Latitude": 434700000,
        "Longitude": -791900000,
        "Elevation (m)": 184.4,
        "First Year": 1971,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1980,
        "MLY First Year": 1971,
        "MLY Last Year": 1980
      },
      {
        "Name": "TWEED",
        "Province": "ONTARIO",
        "Climate ID": "6159010",
        "Station ID": 5133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -77.28,
        "Latitude": 443000000,
        "Longitude": -771700000,
        "Elevation (m)": 144.8,
        "First Year": 1925,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1925,
        "DLY Last Year": 1972,
        "MLY First Year": 1925,
        "MLY Last Year": 1972
      },
      {
        "Name": "TWEED ONTARIO HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6159019",
        "Station ID": 5135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -77.32,
        "Latitude": 443000000,
        "Longitude": -771900000,
        "Elevation (m)": 175,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1983,
        "MLY Last Year": 1986
      },
      {
        "Name": "TYRONE",
        "Province": "ONTARIO",
        "Climate ID": "6159048",
        "Station ID": 5136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -78.73,
        "Latitude": 440100000,
        "Longitude": -784400000,
        "Elevation (m)": 205.7,
        "First Year": 1967,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1999,
        "MLY First Year": 1967,
        "MLY Last Year": 1999
      },
      {
        "Name": "UNIONVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6159058",
        "Station ID": 5137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 435600000,
        "Longitude": -792000000,
        "Elevation (m)": 236.2,
        "First Year": 1960,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1974,
        "MLY First Year": 1960,
        "MLY Last Year": 1974
      },
      {
        "Name": "UNIONVILLE OWRC",
        "Province": "ONTARIO",
        "Climate ID": "6159060",
        "Station ID": 5138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -79.3,
        "Latitude": 435200000,
        "Longitude": -791800000,
        "Elevation (m)": 173.7,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "UXBRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6159122",
        "Station ID": 5139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 440500000,
        "Longitude": -790800000,
        "Elevation (m)": 270.1,
        "First Year": 1899,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1950,
        "MLY First Year": 1899,
        "MLY Last Year": 1923
      },
      {
        "Name": "UXBRIDGE WEST",
        "Province": "ONTARIO",
        "Climate ID": "6159123",
        "Station ID": 50637,
        "WMO ID": 71932,
        "TC ID": "TUX",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.16,
        "Latitude": 440554000,
        "Longitude": -790949020,
        "Elevation (m)": 325,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UXBRIDGE 2",
        "Province": "ONTARIO",
        "Climate ID": "6159124",
        "Station ID": 5140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.12,
        "Longitude (Decimal Degrees)": -79.1,
        "Latitude": 440700000,
        "Longitude": -790600000,
        "Elevation (m)": 270.1,
        "First Year": 1948,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1977,
        "MLY First Year": 1948,
        "MLY Last Year": 1977
      },
      {
        "Name": "UXBRIDGE 3",
        "Province": "ONTARIO",
        "Climate ID": "6159125",
        "Station ID": 5141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -79.08,
        "Latitude": 440800000,
        "Longitude": -790500000,
        "Elevation (m)": 269.7,
        "First Year": 1970,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1981,
        "MLY First Year": 1970,
        "MLY Last Year": 1981
      },
      {
        "Name": "VALENS",
        "Province": "ONTARIO",
        "Climate ID": "6159127",
        "Station ID": 5142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.38,
        "Longitude (Decimal Degrees)": -80.13,
        "Latitude": 432300000,
        "Longitude": -800800000,
        "Elevation (m)": 281.9,
        "First Year": 1968,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1994,
        "MLY First Year": 1968,
        "MLY Last Year": 1994
      },
      {
        "Name": "VAN WAGNERS BEACH",
        "Province": "ONTARIO",
        "Climate ID": "6159133",
        "Station ID": 5143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.25,
        "Longitude (Decimal Degrees)": -79.75,
        "Latitude": 431500000,
        "Longitude": -794500000,
        "Elevation (m)": 76.2,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "VICTORIA",
        "Province": "ONTARIO",
        "Climate ID": "6159138",
        "Station ID": 5144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.77,
        "Longitude (Decimal Degrees)": -79.88,
        "Latitude": 434600000,
        "Longitude": -795300000,
        "Elevation (m)": 288.6,
        "First Year": 1952,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1954,
        "MLY First Year": 1952,
        "MLY Last Year": 1954
      },
      {
        "Name": "WARKWORTH",
        "Province": "ONTARIO",
        "Climate ID": "6159289",
        "Station ID": 27616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.2,
        "Longitude (Decimal Degrees)": -77.83,
        "Latitude": 441200000,
        "Longitude": -775000000,
        "Elevation (m)": 150,
        "First Year": 1998,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2000,
        "MLY First Year": 1998,
        "MLY Last Year": 2000
      },
      {
        "Name": "WELLINGTON",
        "Province": "ONTARIO",
        "Climate ID": "6159449",
        "Station ID": 5145,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -77.33,
        "Latitude": 435700000,
        "Longitude": -772000000,
        "Elevation (m)": 76,
        "First Year": 1948,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1991,
        "MLY First Year": 1948,
        "MLY Last Year": 1991
      },
      {
        "Name": "WEXFORD",
        "Province": "ONTARIO",
        "Climate ID": "6159459",
        "Station ID": 5146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -79.28,
        "Latitude": 434400000,
        "Longitude": -791700000,
        "Elevation (m)": 169.2,
        "First Year": 1912,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1929,
        "MLY First Year": 1912,
        "MLY Last Year": 1929
      },
      {
        "Name": "WILCOX LAKE",
        "Province": "ONTARIO",
        "Climate ID": "6159510",
        "Station ID": 5147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.95,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 435700000,
        "Longitude": -792600000,
        "Elevation (m)": 289.6,
        "First Year": 1960,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1979,
        "MLY First Year": 1960,
        "MLY Last Year": 1979
      },
      {
        "Name": "WOODBRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6159575",
        "Station ID": 5148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.6,
        "Latitude": 434700000,
        "Longitude": -793600000,
        "Elevation (m)": 164,
        "First Year": 1948,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2005,
        "MLY First Year": 1948,
        "MLY Last Year": 2005
      },
      {
        "Name": "WOOLER",
        "Province": "ONTARIO",
        "Climate ID": "6159651",
        "Station ID": 6911,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.2,
        "Longitude (Decimal Degrees)": -77.72,
        "Latitude": 441202046,
        "Longitude": -774304062,
        "Elevation (m)": 123,
        "First Year": 1991,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2012,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "WOOLER",
        "Province": "ONTARIO",
        "Climate ID": "6159652",
        "Station ID": 5149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.15,
        "Longitude (Decimal Degrees)": -77.7,
        "Latitude": 440900000,
        "Longitude": -774200000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1912,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1912,
        "MLY First Year": 1895,
        "MLY Last Year": 1912
      },
      {
        "Name": "UXBRIDGE",
        "Province": "ONTARIO",
        "Climate ID": "6159A26",
        "Station ID": 5132,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -79.12,
        "Latitude": 440600000,
        "Longitude": -790700000,
        "Elevation (m)": 274.3,
        "First Year": 1983,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1992,
        "MLY First Year": 1983,
        "MLY Last Year": 1992
      },
      {
        "Name": "VANDORF",
        "Province": "ONTARIO",
        "Climate ID": "6159AC0",
        "Station ID": 5131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.02,
        "Longitude (Decimal Degrees)": -79.35,
        "Latitude": 440100000,
        "Longitude": -792100000,
        "Elevation (m)": 320,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "MT ALBION CONSERVATION",
        "Province": "ONTARIO",
        "Climate ID": "615E498",
        "Station ID": 4839,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.2,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 431200000,
        "Longitude": -795000000,
        "Elevation (m)": 190.5,
        "First Year": 1976,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1983,
        "MLY First Year": 1976,
        "MLY Last Year": 1983
      },
      {
        "Name": "MILTON SOUTH",
        "Province": "ONTARIO",
        "Climate ID": "615EAQG",
        "Station ID": 6909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -79.83,
        "Latitude": 432700000,
        "Longitude": -795000000,
        "Elevation (m)": 175,
        "First Year": 1993,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1997,
        "MLY First Year": 1993,
        "MLY Last Year": 1997
      },
      {
        "Name": "MOUNTAINVIEW",
        "Province": "ONTARIO",
        "Climate ID": "615EMR7",
        "Station ID": 4838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -77.3,
        "Latitude": 440307092,
        "Longitude": -771806090,
        "Elevation (m)": 108,
        "First Year": 1986,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2018,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "TORONTO TORBARRIE",
        "Province": "ONTARIO",
        "Climate ID": "615H779",
        "Station ID": 4842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -79.52,
        "Latitude": 434400000,
        "Longitude": -793100000,
        "Elevation (m)": 139.9,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "TORONTO YORK MILLS",
        "Province": "ONTARIO",
        "Climate ID": "615HHDF",
        "Station ID": 4840,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.75,
        "Longitude (Decimal Degrees)": -79.38,
        "Latitude": 434500000,
        "Longitude": -792300000,
        "Elevation (m)": 153.3,
        "First Year": 1973,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1987,
        "MLY First Year": 1973,
        "MLY Last Year": 1987
      },
      {
        "Name": "TORONTO BUTTONVILLE A",
        "Province": "ONTARIO",
        "Climate ID": "615HMAK",
        "Station ID": 4841,
        "WMO ID": 71639,
        "TC ID": "YKZ",
        "Latitude (Decimal Degrees)": 43.86,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 435144000,
        "Longitude": -792212000,
        "Elevation (m)": 198.1,
        "First Year": 1986,
        "Last Year": 2015,
        "HLY First Year": 1986,
        "HLY Last Year": 2015,
        "DLY First Year": 1986,
        "DLY Last Year": 2015,
        "MLY First Year": 1986,
        "MLY Last Year": 2015
      },
      {
        "Name": "BRUCES MILL",
        "Province": "ONTARIO",
        "Climate ID": "615J02G",
        "Station ID": 4843,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44,
        "Longitude (Decimal Degrees)": -79.33,
        "Latitude": 440000000,
        "Longitude": -792000000,
        "Elevation (m)": 358.1,
        "First Year": 1968,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1974,
        "MLY First Year": 1969,
        "MLY Last Year": 1974
      },
      {
        "Name": "NEWMARKET 3",
        "Province": "ONTARIO",
        "Climate ID": "615N002",
        "Station ID": 27227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 440300000,
        "Longitude": -792600000,
        "Elevation (m)": 240,
        "First Year": 1996,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2002,
        "MLY First Year": 1996,
        "MLY Last Year": 2002
      },
      {
        "Name": "NEWMARKET 2",
        "Province": "ONTARIO",
        "Climate ID": "615N619",
        "Station ID": 4845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.03,
        "Longitude (Decimal Degrees)": -79.4,
        "Latitude": 440200000,
        "Longitude": -792400000,
        "Elevation (m)": 292,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "OAKVILLE SOUTHEAST WPCP",
        "Province": "ONTARIO",
        "Climate ID": "615N745",
        "Station ID": 4846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.48,
        "Longitude (Decimal Degrees)": -79.63,
        "Latitude": 432900000,
        "Longitude": -793800000,
        "Elevation (m)": 86.9,
        "First Year": 1970,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2001,
        "MLY First Year": 1970,
        "MLY Last Year": 2001
      },
      {
        "Name": "NAPANEE",
        "Province": "ONTARIO",
        "Climate ID": "615NNPL",
        "Station ID": 4844,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -76.97,
        "Latitude": 441400000,
        "Longitude": -765800000,
        "Elevation (m)": 80,
        "First Year": 1987,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2001,
        "MLY First Year": 1987,
        "MLY Last Year": 2001
      },
      {
        "Name": "WESTOVER",
        "Province": "ONTARIO",
        "Climate ID": "615R457",
        "Station ID": 4847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.33,
        "Longitude (Decimal Degrees)": -80.08,
        "Latitude": 432000000,
        "Longitude": -800500000,
        "Elevation (m)": 259.1,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "TORONTO NORTH YORK",
        "Province": "ONTARIO",
        "Climate ID": "615S001",
        "Station ID": 26953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -79.47,
        "Latitude": 434648072,
        "Longitude": -792804038,
        "Elevation (m)": 187,
        "First Year": 1994,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2021,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOUTHERN ONTARIO EMERGENCY PORTABLE WEATHER STATION",
        "Province": "ONTARIO",
        "Climate ID": "615S002",
        "Station ID": 29846,
        "WMO ID": null,
        "TC ID": "WOW",
        "Latitude (Decimal Degrees)": 43.27,
        "Longitude (Decimal Degrees)": -79.78,
        "Latitude": 431600000,
        "Longitude": -794700000,
        "Elevation (m)": null,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BOLTON NORTH",
        "Province": "ONTARIO",
        "Climate ID": "615S004",
        "Station ID": 27083,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.92,
        "Longitude (Decimal Degrees)": -79.75,
        "Latitude": 435500000,
        "Longitude": -794500000,
        "Elevation (m)": 261,
        "First Year": 1995,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1995,
        "DLY Last Year": 2001,
        "MLY First Year": 1995,
        "MLY Last Year": 2001
      },
      {
        "Name": "APSLEY",
        "Province": "ONTARIO",
        "Climate ID": "6160297",
        "Station ID": 5152,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -78.08,
        "Latitude": 444600000,
        "Longitude": -780500000,
        "Elevation (m)": 274.3,
        "First Year": 1922,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1957,
        "MLY First Year": 1922,
        "MLY Last Year": 1957
      },
      {
        "Name": "BANCROFT",
        "Province": "ONTARIO",
        "Climate ID": "6160465",
        "Station ID": 5153,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -77.85,
        "Latitude": 450300000,
        "Longitude": -775100000,
        "Elevation (m)": 326.7,
        "First Year": 1882,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1985,
        "MLY First Year": 1882,
        "MLY Last Year": 1985
      },
      {
        "Name": "BANCROFT L'AMABLE",
        "Province": "ONTARIO",
        "Climate ID": "6160468",
        "Station ID": 5154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -77.8,
        "Latitude": 450000000,
        "Longitude": -774800000,
        "Elevation (m)": 347,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1986,
        "MLY First Year": 1985,
        "MLY Last Year": 1986
      },
      {
        "Name": "BANCROFT OMNR",
        "Province": "ONTARIO",
        "Climate ID": "6160473",
        "Station ID": 5155,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -77.87,
        "Latitude": 450300000,
        "Longitude": -775200000,
        "Elevation (m)": 344.4,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1987,
        "MLY Last Year": 1990
      },
      {
        "Name": "BOBCAYGEON",
        "Province": "ONTARIO",
        "Climate ID": "6160820",
        "Station ID": 5156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -78.62,
        "Latitude": 443100000,
        "Longitude": -783700000,
        "Elevation (m)": 260,
        "First Year": 1986,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1993,
        "MLY First Year": 1986,
        "MLY Last Year": 1993
      },
      {
        "Name": "BOBCAYGEON",
        "Province": "ONTARIO",
        "Climate ID": "6160822",
        "Station ID": 5157,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -78.52,
        "Latitude": 443500000,
        "Longitude": -783100000,
        "Elevation (m)": 248.1,
        "First Year": 1981,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1985,
        "MLY First Year": 1981,
        "MLY Last Year": 1985
      },
      {
        "Name": "BURLEIGH FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6161050",
        "Station ID": 5158,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -78.22,
        "Latitude": 443400000,
        "Longitude": -781300000,
        "Elevation (m)": 243.8,
        "First Year": 1975,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1994,
        "MLY First Year": 1976,
        "MLY Last Year": 1994
      },
      {
        "Name": "CLOYNE ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6161662",
        "Station ID": 5159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.82,
        "Longitude (Decimal Degrees)": -77.18,
        "Latitude": 444900000,
        "Longitude": -771100000,
        "Elevation (m)": 283.5,
        "First Year": 1967,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1981,
        "MLY First Year": 1967,
        "MLY Last Year": 1981
      },
      {
        "Name": "COBOCONK",
        "Province": "ONTARIO",
        "Climate ID": "6161682",
        "Station ID": 5160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -78.77,
        "Latitude": 443600000,
        "Longitude": -784600000,
        "Elevation (m)": 289.6,
        "First Year": 1970,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1978,
        "MLY First Year": 1970,
        "MLY Last Year": 1978
      },
      {
        "Name": "COE HILL",
        "Province": "ONTARIO",
        "Climate ID": "6161739",
        "Station ID": 5161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -77.83,
        "Latitude": 445300000,
        "Longitude": -775000000,
        "Elevation (m)": 321,
        "First Year": 1948,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1957,
        "MLY First Year": 1948,
        "MLY Last Year": 1957
      },
      {
        "Name": "COE HILL",
        "Province": "ONTARIO",
        "Climate ID": "6161740",
        "Station ID": 43923,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -77.88,
        "Latitude": 445521024,
        "Longitude": -775305076,
        "Elevation (m)": 360,
        "First Year": 2005,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2018,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "DENBIGH",
        "Province": "ONTARIO",
        "Climate ID": "6161990",
        "Station ID": 5162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 450700000,
        "Longitude": -771500000,
        "Elevation (m)": null,
        "First Year": 1883,
        "Last Year": 1896,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1896,
        "MLY First Year": 1883,
        "MLY Last Year": 1896
      },
      {
        "Name": "ENNISMORE",
        "Province": "ONTARIO",
        "Climate ID": "6162328",
        "Station ID": 5163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -78.45,
        "Latitude": 442300000,
        "Longitude": -782700000,
        "Elevation (m)": null,
        "First Year": 1882,
        "Last Year": 1910,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1910,
        "MLY First Year": 1882,
        "MLY Last Year": 1910
      },
      {
        "Name": "FENELON FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6162375",
        "Station ID": 5164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.53,
        "Longitude (Decimal Degrees)": -78.73,
        "Latitude": 443200000,
        "Longitude": -784400000,
        "Elevation (m)": 259.1,
        "First Year": 1915,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1970,
        "MLY First Year": 1915,
        "MLY Last Year": 1970
      },
      {
        "Name": "FENELON FALLS",
        "Province": "ONTARIO",
        "Climate ID": "6162376",
        "Station ID": 5165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -78.75,
        "Latitude": 443500000,
        "Longitude": -784500000,
        "Elevation (m)": 259.1,
        "First Year": 1981,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2000,
        "MLY First Year": 1981,
        "MLY Last Year": 2000
      },
      {
        "Name": "GILMOUR",
        "Province": "ONTARIO",
        "Climate ID": "6162787",
        "Station ID": 5166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.85,
        "Longitude (Decimal Degrees)": -77.6,
        "Latitude": 445100000,
        "Longitude": -773600000,
        "Elevation (m)": 309.7,
        "First Year": 1948,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1957,
        "MLY First Year": 1948,
        "MLY Last Year": 1956
      },
      {
        "Name": "GOODERHAM",
        "Province": "ONTARIO",
        "Climate ID": "6162883",
        "Station ID": 5167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -78.38,
        "Latitude": 445500000,
        "Longitude": -782300000,
        "Elevation (m)": 325.2,
        "First Year": 1948,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1968,
        "MLY First Year": 1948,
        "MLY Last Year": 1968
      },
      {
        "Name": "HALIBURTON A",
        "Province": "ONTARIO",
        "Climate ID": "6163156",
        "Station ID": 5168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -78.58,
        "Latitude": 450000000,
        "Longitude": -783500000,
        "Elevation (m)": 320,
        "First Year": 1883,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1992,
        "MLY First Year": 1883,
        "MLY Last Year": 1992
      },
      {
        "Name": "HALIBURTON 2",
        "Province": "ONTARIO",
        "Climate ID": "6163170",
        "Station ID": 5169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -78.48,
        "Latitude": 450300000,
        "Longitude": -782900000,
        "Elevation (m)": 320,
        "First Year": 1949,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1955,
        "MLY First Year": 1949,
        "MLY Last Year": 1955
      },
      {
        "Name": "HALIBURTON 3",
        "Province": "ONTARIO",
        "Climate ID": "6163171",
        "Station ID": 5170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -78.53,
        "Latitude": 450156094,
        "Longitude": -783152014,
        "Elevation (m)": 330,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2022,
        "MLY First Year": 1987,
        "MLY Last Year": 2006
      },
      {
        "Name": "HARTLEY",
        "Province": "ONTARIO",
        "Climate ID": "6163360",
        "Station ID": 31011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.43,
        "Longitude (Decimal Degrees)": -78.9,
        "Latitude": 442600000,
        "Longitude": -785400000,
        "Elevation (m)": 290,
        "First Year": 2001,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2008,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "HYBLA MARSHALL",
        "Province": "ONTARIO",
        "Climate ID": "6163677",
        "Station ID": 5171,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -77.85,
        "Latitude": 450900000,
        "Longitude": -775100000,
        "Elevation (m)": 411.5,
        "First Year": 1974,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1980,
        "MLY First Year": 1974,
        "MLY Last Year": 1980
      },
      {
        "Name": "JERMYN",
        "Province": "ONTARIO",
        "Climate ID": "6163872",
        "Station ID": 5172,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -78.17,
        "Latitude": 441900000,
        "Longitude": -781000000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1905,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1905,
        "MLY First Year": 1895,
        "MLY Last Year": 1905
      },
      {
        "Name": "KINMOUNT",
        "Province": "ONTARIO",
        "Climate ID": "6164198",
        "Station ID": 5173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -78.63,
        "Latitude": 444500000,
        "Longitude": -783800000,
        "Elevation (m)": 280.4,
        "First Year": 1897,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 1950,
        "MLY First Year": 1897,
        "MLY Last Year": 1950
      },
      {
        "Name": "KIRKFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6164207",
        "Station ID": 5174,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -78.98,
        "Latitude": 443400000,
        "Longitude": -785900000,
        "Elevation (m)": 274,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "LAKEFIELD",
        "Province": "ONTARIO",
        "Climate ID": "6164297",
        "Station ID": 5175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -78.23,
        "Latitude": 442800000,
        "Longitude": -781400000,
        "Elevation (m)": 236,
        "First Year": 1874,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1990,
        "MLY First Year": 1874,
        "MLY Last Year": 1990
      },
      {
        "Name": "LINDSAY",
        "Province": "ONTARIO",
        "Climate ID": "6164430",
        "Station ID": 5176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -78.75,
        "Latitude": 442100000,
        "Longitude": -784500000,
        "Elevation (m)": 266.7,
        "First Year": 1880,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1971,
        "MLY First Year": 1880,
        "MLY Last Year": 1971
      },
      {
        "Name": "LINDSAY FILTRATION PLANT",
        "Province": "ONTARIO",
        "Climate ID": "6164432",
        "Station ID": 5177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -78.73,
        "Latitude": 442100000,
        "Longitude": -784400000,
        "Elevation (m)": 251.5,
        "First Year": 1964,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1990,
        "MLY First Year": 1965,
        "MLY Last Year": 1990
      },
      {
        "Name": "LINDSAY FROST",
        "Province": "ONTARIO",
        "Climate ID": "6164433",
        "Station ID": 5178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.34,
        "Longitude (Decimal Degrees)": -78.74,
        "Latitude": 442018072,
        "Longitude": -784425002,
        "Elevation (m)": 262.1,
        "First Year": 1974,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2010,
        "MLY First Year": 1974,
        "MLY Last Year": 2006
      },
      {
        "Name": "LITTLE BRITAIN",
        "Province": "ONTARIO",
        "Climate ID": "6164446",
        "Station ID": 5179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -78.92,
        "Latitude": 441300000,
        "Longitude": -785500000,
        "Elevation (m)": 259.1,
        "First Year": 1981,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1981,
        "MLY First Year": 1981,
        "MLY Last Year": 1981
      },
      {
        "Name": "LORNEVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6164615",
        "Station ID": 5180,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.48,
        "Longitude (Decimal Degrees)": -78.95,
        "Latitude": 442900000,
        "Longitude": -785700000,
        "Elevation (m)": 274.3,
        "First Year": 1965,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1987,
        "MLY First Year": 1965,
        "MLY Last Year": 1987
      },
      {
        "Name": "MINDEN",
        "Province": "ONTARIO",
        "Climate ID": "6165195",
        "Station ID": 5181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.93,
        "Longitude (Decimal Degrees)": -78.72,
        "Latitude": 445600000,
        "Longitude": -784300000,
        "Elevation (m)": 274.3,
        "First Year": 1883,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 2011,
        "MLY First Year": 1883,
        "MLY Last Year": 2006
      },
      {
        "Name": "MINDEN FORESTRY",
        "Province": "ONTARIO",
        "Climate ID": "6165197",
        "Station ID": 5182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -78.7,
        "Latitude": 444500000,
        "Longitude": -784200000,
        "Elevation (m)": 304.8,
        "First Year": 1948,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1955,
        "MLY First Year": 1948,
        "MLY Last Year": 1955
      },
      {
        "Name": "NORWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6165715",
        "Station ID": 27613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -78,
        "Latitude": 442100000,
        "Longitude": -780000000,
        "Elevation (m)": 207,
        "First Year": 1998,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2002,
        "MLY First Year": 1998,
        "MLY Last Year": 2002
      },
      {
        "Name": "NORWOOD",
        "Province": "ONTARIO",
        "Climate ID": "6165716",
        "Station ID": 5183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.38,
        "Longitude (Decimal Degrees)": -77.97,
        "Latitude": 442300000,
        "Longitude": -775800000,
        "Elevation (m)": 213.4,
        "First Year": 1875,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1875,
        "DLY Last Year": 1980,
        "MLY First Year": 1875,
        "MLY Last Year": 1980
      },
      {
        "Name": "OTONABEE",
        "Province": "ONTARIO",
        "Climate ID": "6165880",
        "Station ID": 5184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 442000000,
        "Longitude": -781800000,
        "Elevation (m)": 205.4,
        "First Year": 1895,
        "Last Year": 1911,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1911,
        "MLY First Year": 1895,
        "MLY Last Year": 1911
      },
      {
        "Name": "PA BLAIRHAMPTON GOLF CLUB",
        "Province": "ONTARIO",
        "Climate ID": "6166400",
        "Station ID": 52730,
        "WMO ID": null,
        "TC ID": "Z4N",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -78.66,
        "Latitude": 445949200,
        "Longitude": -783931900,
        "Elevation (m)": 333.5,
        "First Year": 2014,
        "Last Year": 2015,
        "HLY First Year": 2014,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PETERBOROUGH A",
        "Province": "ONTARIO",
        "Climate ID": "6166415",
        "Station ID": 48952,
        "WMO ID": 71436,
        "TC ID": "YPQ",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -78.36,
        "Latitude": 441348000,
        "Longitude": -782148000,
        "Elevation (m)": 191.4,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PETERBOROUGH",
        "Province": "ONTARIO",
        "Climate ID": "6166416",
        "Station ID": 5185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.28,
        "Longitude (Decimal Degrees)": -78.32,
        "Latitude": 441700000,
        "Longitude": -781900000,
        "Elevation (m)": 193.5,
        "First Year": 1866,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1866,
        "DLY Last Year": 1970,
        "MLY First Year": 1866,
        "MLY Last Year": 1970
      },
      {
        "Name": "PETERBOROUGH A",
        "Province": "ONTARIO",
        "Climate ID": "6166418",
        "Station ID": 5186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -78.37,
        "Latitude": 441400000,
        "Longitude": -782200000,
        "Elevation (m)": 191.4,
        "First Year": 1969,
        "Last Year": 2007,
        "HLY First Year": 1969,
        "HLY Last Year": 2005,
        "DLY First Year": 1969,
        "DLY Last Year": 2007,
        "MLY First Year": 1969,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETERBOROUGH AWOS",
        "Province": "ONTARIO",
        "Climate ID": "6166420",
        "Station ID": 29906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -78.37,
        "Latitude": 441400000,
        "Longitude": -782200000,
        "Elevation (m)": 191.4,
        "First Year": 2004,
        "Last Year": 2010,
        "HLY First Year": 2004,
        "HLY Last Year": 2010,
        "DLY First Year": 2004,
        "DLY Last Year": 2010,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETERBOROUGH CHEX",
        "Province": "ONTARIO",
        "Climate ID": "6166425",
        "Station ID": 5187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 442000000,
        "Longitude": -781800000,
        "Elevation (m)": 243.8,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "PETERBOROUGH DOBBIN TS",
        "Province": "ONTARIO",
        "Climate ID": "6166428",
        "Station ID": 5188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.32,
        "Longitude (Decimal Degrees)": -78.4,
        "Latitude": 441900000,
        "Longitude": -782400000,
        "Elevation (m)": 243.8,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "PETERBOROUGH ONT HYDRO",
        "Province": "ONTARIO",
        "Climate ID": "6166443",
        "Station ID": 5189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -78.32,
        "Latitude": 442000000,
        "Longitude": -781900000,
        "Elevation (m)": 207.3,
        "First Year": 1949,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1965,
        "MLY First Year": 1949,
        "MLY Last Year": 1965
      },
      {
        "Name": "PETERBOROUGH STP",
        "Province": "ONTARIO",
        "Climate ID": "6166450",
        "Station ID": 5190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.28,
        "Longitude (Decimal Degrees)": -78.32,
        "Latitude": 441700000,
        "Longitude": -781900000,
        "Elevation (m)": 192,
        "First Year": 1964,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1993,
        "MLY First Year": 1964,
        "MLY Last Year": 1993
      },
      {
        "Name": "PETERBOROUGH TORRANCE",
        "Province": "ONTARIO",
        "Climate ID": "6166452",
        "Station ID": 5191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -78.32,
        "Latitude": 441400000,
        "Longitude": -781900000,
        "Elevation (m)": 655,
        "First Year": 1990,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2000,
        "MLY First Year": 1990,
        "MLY Last Year": 2000
      },
      {
        "Name": "PETERBOROUGH TRENT U",
        "Province": "ONTARIO",
        "Climate ID": "6166455",
        "Station ID": 5192,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.37,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 442200000,
        "Longitude": -781800000,
        "Elevation (m)": 198.1,
        "First Year": 1968,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2012,
        "MLY First Year": 1968,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETERBOROUGH TRENT U",
        "Province": "ONTARIO",
        "Climate ID": "6166456",
        "Station ID": 43763,
        "WMO ID": 71672,
        "TC ID": "TPQ",
        "Latitude (Decimal Degrees)": 44.35,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 442100000,
        "Longitude": -781800000,
        "Elevation (m)": 216,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "QUEENSBORO",
        "Province": "ONTARIO",
        "Climate ID": "6166800",
        "Station ID": 5193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -77.42,
        "Latitude": 443600000,
        "Longitude": -772500000,
        "Elevation (m)": 195.1,
        "First Year": 1914,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1946,
        "MLY First Year": 1914,
        "MLY Last Year": 1946
      },
      {
        "Name": "STONY LAKE SANDOR FARM",
        "Province": "ONTARIO",
        "Climate ID": "6168070",
        "Station ID": 5194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -78.1,
        "Latitude": 443300000,
        "Longitude": -780600000,
        "Elevation (m)": 243.8,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "SONYA SUNDANCE MEADOWS",
        "Province": "ONTARIO",
        "Climate ID": "6168100",
        "Station ID": 31010,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -78.95,
        "Latitude": 441300000,
        "Longitude": -785700000,
        "Elevation (m)": 275,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": 2001,
        "MLY Last Year": 2006
      },
      {
        "Name": "TAPLEY",
        "Province": "ONTARIO",
        "Climate ID": "6168525",
        "Station ID": 44205,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.17,
        "Longitude (Decimal Degrees)": -78.5,
        "Latitude": 441014040,
        "Longitude": -783005094,
        "Elevation (m)": 270,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "URSA",
        "Province": "ONTARIO",
        "Climate ID": "6169110",
        "Station ID": 5195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.9,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 445400000,
        "Longitude": -781800000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1913,
        "MLY First Year": 1895,
        "MLY Last Year": 1913
      },
      {
        "Name": "WEST GUILFORD",
        "Province": "ONTARIO",
        "Climate ID": "6169453",
        "Station ID": 5196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -78.68,
        "Latitude": 450600000,
        "Longitude": -784100000,
        "Elevation (m)": 327.7,
        "First Year": 1968,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1987,
        "MLY First Year": 1968,
        "MLY Last Year": 1987
      },
      {
        "Name": "WOODVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6169647",
        "Station ID": 5197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -78.98,
        "Latitude": 442400000,
        "Longitude": -785900000,
        "Elevation (m)": 283.5,
        "First Year": 1987,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2000,
        "MLY First Year": 1987,
        "MLY Last Year": 2000
      },
      {
        "Name": "WOODVILLE",
        "Province": "ONTARIO",
        "Climate ID": "6169648",
        "Station ID": 5198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -78.9,
        "Latitude": 442400000,
        "Longitude": -785400000,
        "Elevation (m)": 281.9,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "HASTINGS",
        "Province": "ONTARIO",
        "Climate ID": "616C3P9",
        "Station ID": 5150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.28,
        "Longitude (Decimal Degrees)": -78,
        "Latitude": 441700000,
        "Longitude": -780000000,
        "Elevation (m)": 190.2,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "BANCROFT AUTO",
        "Province": "ONTARIO",
        "Climate ID": "616I001",
        "Station ID": 26799,
        "WMO ID": 71294,
        "TC ID": "WRK",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -77.88,
        "Latitude": 450417000,
        "Longitude": -775244000,
        "Elevation (m)": 330.7,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETERBOROUGH",
        "Province": "ONTARIO",
        "Climate ID": "616I002",
        "Station ID": 26862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.23,
        "Longitude (Decimal Degrees)": -78.37,
        "Latitude": 441400000,
        "Longitude": -782200000,
        "Elevation (m)": 191,
        "First Year": 1995,
        "Last Year": 1996,
        "HLY First Year": 1995,
        "HLY Last Year": 1996,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BURNT RIVER-CLARK",
        "Province": "ONTARIO",
        "Climate ID": "616J0FN",
        "Station ID": 6958,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -78.7,
        "Latitude": 443800000,
        "Longitude": -784200000,
        "Elevation (m)": 259,
        "First Year": 1992,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1992,
        "MLY First Year": 1992,
        "MLY Last Year": 1992
      },
      {
        "Name": "ROSEDALE",
        "Province": "ONTARIO",
        "Climate ID": "616PA87",
        "Station ID": 5151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -78.78,
        "Latitude": 443400000,
        "Longitude": -784700000,
        "Elevation (m)": 259.1,
        "First Year": 1975,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1994,
        "MLY First Year": 1975,
        "MLY Last Year": 1994
      },
      {
        "Name": "WARSAW",
        "Province": "ONTARIO",
        "Climate ID": "616RKR0",
        "Station ID": 6912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.46,
        "Longitude (Decimal Degrees)": -78.13,
        "Latitude": 442746068,
        "Longitude": -780748036,
        "Elevation (m)": 230,
        "First Year": 1991,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2008,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "BARRIERE STONEHAM",
        "Province": "QUEBEC",
        "Climate ID": "7010478",
        "Station ID": 5205,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.17,
        "Longitude (Decimal Degrees)": -71.25,
        "Latitude": 471000000,
        "Longitude": -711500000,
        "Elevation (m)": 597.4,
        "First Year": 1963,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1976,
        "MLY First Year": 1963,
        "MLY Last Year": 1976
      },
      {
        "Name": "BARRIERE TOURILLI",
        "Province": "QUEBEC",
        "Climate ID": "7010480",
        "Station ID": 5206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.17,
        "Longitude (Decimal Degrees)": -71.62,
        "Latitude": 471000000,
        "Longitude": -713700000,
        "Elevation (m)": 493.8,
        "First Year": 1949,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1960,
        "MLY First Year": 1949,
        "MLY Last Year": 1960
      },
      {
        "Name": "BEAUPORT",
        "Province": "QUEBEC",
        "Climate ID": "7010565",
        "Station ID": 27803,
        "WMO ID": 71578,
        "TC ID": "XBO",
        "Latitude (Decimal Degrees)": 46.84,
        "Longitude (Decimal Degrees)": -71.2,
        "Latitude": 465013000,
        "Longitude": -711150000,
        "Elevation (m)": 10,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAUPORT",
        "Province": "QUEBEC",
        "Climate ID": "7010566",
        "Station ID": 5207,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.88,
        "Longitude (Decimal Degrees)": -71.2,
        "Latitude": 465300000,
        "Longitude": -711200000,
        "Elevation (m)": 84.1,
        "First Year": 1982,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1985,
        "MLY First Year": 1982,
        "MLY Last Year": 1985
      },
      {
        "Name": "BERTHIERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7010720",
        "Station ID": 5208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -73.18,
        "Latitude": 460300000,
        "Longitude": -731100000,
        "Elevation (m)": 12.2,
        "First Year": 1919,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1995,
        "MLY First Year": 1919,
        "MLY Last Year": 1995
      },
      {
        "Name": "CAP DE LA MADELEINE",
        "Province": "QUEBEC",
        "Climate ID": "7011045",
        "Station ID": 5209,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -72.53,
        "Latitude": 462200000,
        "Longitude": -723200000,
        "Elevation (m)": 17.1,
        "First Year": 1920,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1932,
        "MLY First Year": 1920,
        "MLY Last Year": 1932
      },
      {
        "Name": "CAP ROUGE",
        "Province": "QUEBEC",
        "Climate ID": "7011170",
        "Station ID": 5210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.77,
        "Longitude (Decimal Degrees)": -71.3,
        "Latitude": 464600000,
        "Longitude": -711800000,
        "Elevation (m)": 12.2,
        "First Year": 1911,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1940,
        "MLY First Year": 1911,
        "MLY Last Year": 1940
      },
      {
        "Name": "CHAMPLAIN",
        "Province": "QUEBEC",
        "Climate ID": "7011290",
        "Station ID": 5211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -72.33,
        "Latitude": 462800000,
        "Longitude": -722000000,
        "Elevation (m)": 12,
        "First Year": 1980,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2011,
        "MLY First Year": 1981,
        "MLY Last Year": 2011
      },
      {
        "Name": "CHARLESBOURG JAR ZOO",
        "Province": "QUEBEC",
        "Climate ID": "7011308",
        "Station ID": 5212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -71.3,
        "Latitude": 465400000,
        "Longitude": -711800000,
        "Elevation (m)": 152.4,
        "First Year": 1963,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1977,
        "MLY First Year": 1963,
        "MLY Last Year": 1977
      },
      {
        "Name": "CHARLESBOURG PARC ORLEAN",
        "Province": "QUEBEC",
        "Climate ID": "7011309",
        "Station ID": 5213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.27,
        "Latitude": 465200000,
        "Longitude": -711600000,
        "Elevation (m)": 114.3,
        "First Year": 1971,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2007,
        "MLY First Year": 1971,
        "MLY Last Year": 1997
      },
      {
        "Name": "CHUTE PANET",
        "Province": "QUEBEC",
        "Climate ID": "7011600",
        "Station ID": 5214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.87,
        "Latitude": 465200000,
        "Longitude": -715200000,
        "Elevation (m)": 152.4,
        "First Year": 1949,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1982,
        "MLY First Year": 1949,
        "MLY Last Year": 1982
      },
      {
        "Name": "CLUB TOURILLI",
        "Province": "QUEBEC",
        "Climate ID": "7011760",
        "Station ID": 5215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.03,
        "Longitude (Decimal Degrees)": -71.7,
        "Latitude": 470200000,
        "Longitude": -714200000,
        "Elevation (m)": 265.2,
        "First Year": 1949,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1951,
        "MLY First Year": 1949,
        "MLY Last Year": 1951
      },
      {
        "Name": "CLUB TOURILLI",
        "Province": "QUEBEC",
        "Climate ID": "7011800",
        "Station ID": 5216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.08,
        "Longitude (Decimal Degrees)": -71.9,
        "Latitude": 470500000,
        "Longitude": -715400000,
        "Elevation (m)": 249.9,
        "First Year": 1949,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1953,
        "MLY First Year": 1949,
        "MLY Last Year": 1953
      },
      {
        "Name": "COTEAU DU LAC",
        "Province": "QUEBEC",
        "Climate ID": "7011947",
        "Station ID": 5217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -74.17,
        "Latitude": 451900000,
        "Longitude": -741000000,
        "Elevation (m)": 49.4,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2018
      },
      {
        "Name": "COURVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7011950",
        "Station ID": 5218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 465200000,
        "Longitude": -711000000,
        "Elevation (m)": 76.2,
        "First Year": 1963,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1964,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "DEPOT JOBIN",
        "Province": "QUEBEC",
        "Climate ID": "7011960",
        "Station ID": 5219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -71.63,
        "Latitude": 471400000,
        "Longitude": -713800000,
        "Elevation (m)": 688.8,
        "First Year": 1950,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1959,
        "MLY First Year": 1950,
        "MLY Last Year": 1959
      },
      {
        "Name": "DESCHAMBAULT",
        "Province": "QUEBEC",
        "Climate ID": "7011982",
        "Station ID": 5220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -71.92,
        "Latitude": 464000000,
        "Longitude": -715500000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2018
      },
      {
        "Name": "DESCHAMBAULT",
        "Province": "QUEBEC",
        "Climate ID": "7011983",
        "Station ID": 27325,
        "WMO ID": 71389,
        "TC ID": "WHQ",
        "Latitude (Decimal Degrees)": 46.69,
        "Longitude (Decimal Degrees)": -71.97,
        "Latitude": 464127000,
        "Longitude": -715818000,
        "Elevation (m)": 61,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DOMAINE-DE-MAIZERETS",
        "Province": "QUEBEC",
        "Climate ID": "7012040",
        "Station ID": 6913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.83,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 465000000,
        "Longitude": -711300000,
        "Elevation (m)": 6,
        "First Year": 1991,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1994,
        "MLY First Year": 1991,
        "MLY Last Year": 1994
      },
      {
        "Name": "DONNACONA",
        "Province": "QUEBEC",
        "Climate ID": "7012070",
        "Station ID": 5221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -71.75,
        "Latitude": 464000000,
        "Longitude": -714500000,
        "Elevation (m)": 10.7,
        "First Year": 1918,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1964,
        "MLY First Year": 1918,
        "MLY Last Year": 1964
      },
      {
        "Name": "DONNACONA 2",
        "Province": "QUEBEC",
        "Climate ID": "7012071",
        "Station ID": 5222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.68,
        "Longitude (Decimal Degrees)": -71.73,
        "Latitude": 464100000,
        "Longitude": -714400000,
        "Elevation (m)": 45.7,
        "First Year": 1952,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2008,
        "MLY First Year": 1953,
        "MLY Last Year": 2008
      },
      {
        "Name": "DUBERGER",
        "Province": "QUEBEC",
        "Climate ID": "7012232",
        "Station ID": 5223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -71.3,
        "Latitude": 464900000,
        "Longitude": -711800000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1993,
        "MLY First Year": 1971,
        "MLY Last Year": 1993
      },
      {
        "Name": "DUCHESNAY",
        "Province": "QUEBEC",
        "Climate ID": "7012240",
        "Station ID": 5224,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.65,
        "Latitude": 465200000,
        "Longitude": -713900000,
        "Elevation (m)": 166.1,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "HEROUXVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7013100",
        "Station ID": 5225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -72.6,
        "Latitude": 464000000,
        "Longitude": -723600000,
        "Elevation (m)": 145,
        "First Year": 1966,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2021,
        "MLY First Year": 1966,
        "MLY Last Year": 2018
      },
      {
        "Name": "HERVEY JONCTION",
        "Province": "QUEBEC",
        "Climate ID": "7013102",
        "Station ID": 5226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -72.47,
        "Latitude": 465100000,
        "Longitude": -722800000,
        "Elevation (m)": 177.4,
        "First Year": 1926,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1978,
        "MLY First Year": 1926,
        "MLY Last Year": 1978
      },
      {
        "Name": "HUDSON HEIGHTS",
        "Province": "QUEBEC",
        "Climate ID": "7013200",
        "Station ID": 5227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -74.17,
        "Latitude": 452800000,
        "Longitude": -741000000,
        "Elevation (m)": 26.8,
        "First Year": 1952,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1955,
        "MLY First Year": 1952,
        "MLY Last Year": 1955
      },
      {
        "Name": "ILE BOUCHARD",
        "Province": "QUEBEC",
        "Climate ID": "7013279",
        "Station ID": 5228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.82,
        "Longitude (Decimal Degrees)": -73.35,
        "Latitude": 454900000,
        "Longitude": -732100000,
        "Elevation (m)": 9.1,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ILE D'ORLEANS",
        "Province": "QUEBEC",
        "Climate ID": "70132G9",
        "Station ID": 8989,
        "WMO ID": 71424,
        "TC ID": "WER",
        "Latitude (Decimal Degrees)": 47,
        "Longitude (Decimal Degrees)": -70.81,
        "Latitude": 465948000,
        "Longitude": -704830000,
        "Elevation (m)": 3.6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "JOLIETTE",
        "Province": "QUEBEC",
        "Climate ID": "7013360",
        "Station ID": 5229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -73.43,
        "Latitude": 460200000,
        "Longitude": -732600000,
        "Elevation (m)": 58.5,
        "First Year": 1913,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1972,
        "MLY First Year": 1913,
        "MLY Last Year": 1972
      },
      {
        "Name": "JOLIETTE VILLE",
        "Province": "QUEBEC",
        "Climate ID": "7013362",
        "Station ID": 5230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.02,
        "Longitude (Decimal Degrees)": -73.43,
        "Latitude": 460100000,
        "Longitude": -732600000,
        "Elevation (m)": 56,
        "First Year": 1967,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2011,
        "MLY First Year": 1967,
        "MLY Last Year": 2011
      },
      {
        "Name": "LAC BROWN",
        "Province": "QUEBEC",
        "Climate ID": "7013570",
        "Station ID": 5231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -73.17,
        "Latitude": 465500000,
        "Longitude": -731000000,
        "Elevation (m)": 365.8,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "LAC EDOUARD",
        "Province": "QUEBEC",
        "Climate ID": "7013640",
        "Station ID": 5232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.65,
        "Longitude (Decimal Degrees)": -72.27,
        "Latitude": 473900000,
        "Longitude": -721600000,
        "Elevation (m)": 369.7,
        "First Year": 1949,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1958,
        "MLY First Year": 1949,
        "MLY Last Year": 1958
      },
      {
        "Name": "LAC EDOUARD S",
        "Province": "QUEBEC",
        "Climate ID": "7013642",
        "Station ID": 5233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.67,
        "Longitude (Decimal Degrees)": -72.27,
        "Latitude": 474000000,
        "Longitude": -721600000,
        "Elevation (m)": 364.2,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "LAC JACQUES CARTIER",
        "Province": "QUEBEC",
        "Climate ID": "7013655",
        "Station ID": 5234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -71.23,
        "Latitude": 473400000,
        "Longitude": -711400000,
        "Elevation (m)": 798.6,
        "First Year": 1963,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1974
      },
      {
        "Name": "LAC MINOGAMI",
        "Province": "QUEBEC",
        "Climate ID": "7013678",
        "Station ID": 5235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -72.87,
        "Latitude": 464000000,
        "Longitude": -725200000,
        "Elevation (m)": 259.1,
        "First Year": 1964,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1978,
        "MLY First Year": 1964,
        "MLY Last Year": 1978
      },
      {
        "Name": "LAKE EDWARD",
        "Province": "QUEBEC",
        "Climate ID": "7013735",
        "Station ID": 5236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.65,
        "Longitude (Decimal Degrees)": -72.25,
        "Latitude": 473900000,
        "Longitude": -721500000,
        "Elevation (m)": 406.3,
        "First Year": 1910,
        "Last Year": 1925,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1925,
        "MLY First Year": 1910,
        "MLY Last Year": 1925
      },
      {
        "Name": "L'ASSOMPTION",
        "Province": "QUEBEC",
        "Climate ID": "7014160",
        "Station ID": 5237,
        "WMO ID": 71524,
        "TC ID": "WEW",
        "Latitude (Decimal Degrees)": 45.81,
        "Longitude (Decimal Degrees)": -73.43,
        "Latitude": 454834000,
        "Longitude": -732605000,
        "Elevation (m)": 21,
        "First Year": 1930,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1930,
        "DLY Last Year": 2022,
        "MLY First Year": 1930,
        "MLY Last Year": 1994
      },
      {
        "Name": "LAVALTRIE CDA",
        "Province": "QUEBEC",
        "Climate ID": "7014260",
        "Station ID": 5238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -73.32,
        "Latitude": 455600000,
        "Longitude": -731900000,
        "Elevation (m)": 30.5,
        "First Year": 1957,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1982,
        "MLY First Year": 1957,
        "MLY Last Year": 1982
      },
      {
        "Name": "LES CEDRES",
        "Province": "QUEBEC",
        "Climate ID": "7014290",
        "Station ID": 5239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -74.05,
        "Latitude": 451800000,
        "Longitude": -740300000,
        "Elevation (m)": 47.2,
        "First Year": 1913,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2022,
        "MLY First Year": 1913,
        "MLY Last Year": 2018
      },
      {
        "Name": "LES SAULES",
        "Province": "QUEBEC",
        "Climate ID": "7014298",
        "Station ID": 5240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -71.32,
        "Latitude": 464900000,
        "Longitude": -711900000,
        "Elevation (m)": 25.9,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "LINTON",
        "Province": "QUEBEC",
        "Climate ID": "7014325",
        "Station ID": 5241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.27,
        "Longitude (Decimal Degrees)": -72.25,
        "Latitude": 471600000,
        "Longitude": -721500000,
        "Elevation (m)": 270.4,
        "First Year": 1949,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1955,
        "MLY First Year": 1949,
        "MLY Last Year": 1955
      },
      {
        "Name": "LORETTEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7014329",
        "Station ID": 5242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.37,
        "Latitude": 465200000,
        "Longitude": -712200000,
        "Elevation (m)": 129.5,
        "First Year": 1977,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1982,
        "MLY First Year": 1977,
        "MLY Last Year": 1982
      },
      {
        "Name": "LOUISEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7014332",
        "Station ID": 5243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -73.02,
        "Latitude": 461600000,
        "Longitude": -730100000,
        "Elevation (m)": 45,
        "First Year": 1967,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2022,
        "MLY First Year": 1967,
        "MLY Last Year": 2018
      },
      {
        "Name": "MASCOUCHE",
        "Province": "QUEBEC",
        "Climate ID": "7014629",
        "Station ID": 5244,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -73.6,
        "Latitude": 454500000,
        "Longitude": -733600000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2006,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "MASKINONGE",
        "Province": "QUEBEC",
        "Climate ID": "7014630",
        "Station ID": 5245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -73.02,
        "Latitude": 461400000,
        "Longitude": -730100000,
        "Elevation (m)": 15.2,
        "First Year": 1957,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1973,
        "MLY First Year": 1957,
        "MLY Last Year": 1973
      },
      {
        "Name": "NEUFCHATEL",
        "Province": "QUEBEC",
        "Climate ID": "7015417",
        "Station ID": 5246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -71.37,
        "Latitude": 465100000,
        "Longitude": -712200000,
        "Elevation (m)": 91.4,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "NOTRE DAME DE LA MERCI",
        "Province": "QUEBEC",
        "Climate ID": "7015665",
        "Station ID": 5247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -74.05,
        "Latitude": 461400000,
        "Longitude": -740300000,
        "Elevation (m)": 365.8,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "OKA",
        "Province": "QUEBEC",
        "Climate ID": "7015730",
        "Station ID": 5248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -74.07,
        "Latitude": 453000000,
        "Longitude": -740400000,
        "Elevation (m)": 91.4,
        "First Year": 1937,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 2022,
        "MLY First Year": 1937,
        "MLY Last Year": 2018
      },
      {
        "Name": "L'ETAPE",
        "Province": "QUEBEC",
        "Climate ID": "7015791",
        "Station ID": 10871,
        "WMO ID": 71382,
        "TC ID": "WPD",
        "Latitude (Decimal Degrees)": 47.56,
        "Longitude (Decimal Degrees)": -71.23,
        "Latitude": 473344000,
        "Longitude": -711344000,
        "Elevation (m)": 791.2,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "QUEBEC",
        "Province": "QUEBEC",
        "Climate ID": "7016280",
        "Station ID": 5249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.8,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 464800000,
        "Longitude": -711300000,
        "Elevation (m)": 90.2,
        "First Year": 1872,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1959,
        "MLY First Year": 1872,
        "MLY Last Year": 1959
      },
      {
        "Name": "QUEBEC 2",
        "Province": "QUEBEC",
        "Climate ID": "7016281",
        "Station ID": 5250,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.8,
        "Longitude (Decimal Degrees)": -71.27,
        "Latitude": 464800000,
        "Longitude": -711600000,
        "Elevation (m)": 76.2,
        "First Year": 1956,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1968,
        "MLY First Year": 1956,
        "MLY Last Year": 1968
      },
      {
        "Name": "QUEBEC INTL A",
        "Province": "QUEBEC",
        "Climate ID": "7016293",
        "Station ID": 51457,
        "WMO ID": null,
        "TC ID": "YQB",
        "Latitude (Decimal Degrees)": 46.79,
        "Longitude (Decimal Degrees)": -71.39,
        "Latitude": 464728000,
        "Longitude": -712336000,
        "Elevation (m)": 74.4,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QUEBEC/JEAN LESAGE INTL A",
        "Province": "QUEBEC",
        "Climate ID": "7016294",
        "Station ID": 5251,
        "WMO ID": 71708,
        "TC ID": "YQB",
        "Latitude (Decimal Degrees)": 46.8,
        "Longitude (Decimal Degrees)": -71.38,
        "Latitude": 464800000,
        "Longitude": -712300000,
        "Elevation (m)": 74.4,
        "First Year": 1943,
        "Last Year": 2017,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1943,
        "DLY Last Year": 2017,
        "MLY First Year": 1943,
        "MLY Last Year": 2012
      },
      {
        "Name": "RAWDON",
        "Province": "QUEBEC",
        "Climate ID": "7016350",
        "Station ID": 43189,
        "WMO ID": null,
        "TC ID": "MWD",
        "Latitude (Decimal Degrees)": 46.02,
        "Longitude (Decimal Degrees)": -73.81,
        "Latitude": 460111339,
        "Longitude": -734853579,
        "Elevation (m)": 210,
        "First Year": 2005,
        "Last Year": 2007,
        "HLY First Year": 2005,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIGAUD",
        "Province": "QUEBEC",
        "Climate ID": "7016470",
        "Station ID": 5252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -74.37,
        "Latitude": 453000000,
        "Longitude": -742200000,
        "Elevation (m)": 46,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "RIGAUD 2",
        "Province": "QUEBEC",
        "Climate ID": "7016472",
        "Station ID": 41737,
        "WMO ID": null,
        "TC ID": "MRI",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -74.29,
        "Latitude": 452857000,
        "Longitude": -741733000,
        "Elevation (m)": 30.2,
        "First Year": 2003,
        "Last Year": 2006,
        "HLY First Year": 2003,
        "HLY Last Year": 2006,
        "DLY First Year": 2003,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAINT-POLYCARPE",
        "Province": "QUEBEC",
        "Climate ID": "7016474",
        "Station ID": 32462,
        "WMO ID": null,
        "TC ID": "MPL",
        "Latitude (Decimal Degrees)": 45.31,
        "Longitude (Decimal Degrees)": -74.29,
        "Latitude": 451819000,
        "Longitude": -741735000,
        "Elevation (m)": 55.7,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": 2003,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIVIERE A PIERRE",
        "Province": "QUEBEC",
        "Climate ID": "7016560",
        "Station ID": 5253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47,
        "Longitude (Decimal Degrees)": -72.17,
        "Latitude": 470000000,
        "Longitude": -721000000,
        "Elevation (m)": 221,
        "First Year": 1949,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1994,
        "MLY First Year": 1949,
        "MLY Last Year": 1994
      },
      {
        "Name": "RIVIERE VERTE OUEST",
        "Province": "QUEBEC",
        "Climate ID": "7016675",
        "Station ID": 5254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.98,
        "Longitude (Decimal Degrees)": -71.83,
        "Latitude": 465900000,
        "Longitude": -715000000,
        "Elevation (m)": 213.4,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST ALBAN",
        "Province": "QUEBEC",
        "Climate ID": "7016800",
        "Station ID": 5255,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.72,
        "Longitude (Decimal Degrees)": -72.08,
        "Latitude": 464300000,
        "Longitude": -720500000,
        "Elevation (m)": 76.2,
        "First Year": 1949,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2022,
        "MLY First Year": 1949,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST ALEXIS DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7016816",
        "Station ID": 5256,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.53,
        "Longitude (Decimal Degrees)": -73.15,
        "Latitude": 463200000,
        "Longitude": -730900000,
        "Elevation (m)": 183,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "STE ANNE DE LA PERADE",
        "Province": "QUEBEC",
        "Climate ID": "7016840",
        "Station ID": 5257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.58,
        "Longitude (Decimal Degrees)": -72.23,
        "Latitude": 463500000,
        "Longitude": -721400000,
        "Elevation (m)": 16,
        "First Year": 1949,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2022,
        "MLY First Year": 1949,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST AUGUSTIN",
        "Province": "QUEBEC",
        "Climate ID": "7016900",
        "Station ID": 5258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.73,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 464400000,
        "Longitude": -713000000,
        "Elevation (m)": 57.9,
        "First Year": 1964,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1989,
        "MLY First Year": 1964,
        "MLY Last Year": 1989
      },
      {
        "Name": "STE BEATRIX",
        "Province": "QUEBEC",
        "Climate ID": "7016902",
        "Station ID": 5259,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -73.6,
        "Latitude": 461200000,
        "Longitude": -733600000,
        "Elevation (m)": 198,
        "First Year": 1974,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2022,
        "MLY First Year": 1974,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST BENOIT",
        "Province": "QUEBEC",
        "Climate ID": "7016906",
        "Station ID": 5260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -74.05,
        "Latitude": 453400000,
        "Longitude": -740300000,
        "Elevation (m)": 53,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "STE CATHERINE",
        "Province": "QUEBEC",
        "Climate ID": "7016932",
        "Station ID": 5261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -71.62,
        "Latitude": 465100000,
        "Longitude": -713700000,
        "Elevation (m)": 152.4,
        "First Year": 1964,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1992,
        "MLY First Year": 1964,
        "MLY Last Year": 1992
      },
      {
        "Name": "ST CHARLES DE MANDEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7016960",
        "Station ID": 5262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -73.35,
        "Latitude": 462100000,
        "Longitude": -732100000,
        "Elevation (m)": 167.6,
        "First Year": 1921,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 2022,
        "MLY First Year": 1921,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST CHARLES MANDEVILLE 2",
        "Province": "QUEBEC",
        "Climate ID": "7016981",
        "Station ID": 5263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -73.35,
        "Latitude": 462200000,
        "Longitude": -732100000,
        "Elevation (m)": 173.7,
        "First Year": 1968,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1970,
        "MLY First Year": 1968,
        "MLY Last Year": 1970
      },
      {
        "Name": "STE CHRISTINE",
        "Province": "QUEBEC",
        "Climate ID": "7017000",
        "Station ID": 5266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -71.92,
        "Latitude": 464900000,
        "Longitude": -715500000,
        "Elevation (m)": 152.1,
        "First Year": 1950,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2022,
        "MLY First Year": 1950,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST CLET NORD",
        "Province": "QUEBEC",
        "Climate ID": "7017032",
        "Station ID": 5267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -74.25,
        "Latitude": 452300000,
        "Longitude": -741500000,
        "Elevation (m)": 61,
        "First Year": 1966,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST COME",
        "Province": "QUEBEC",
        "Climate ID": "7017080",
        "Station ID": 5268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.28,
        "Longitude (Decimal Degrees)": -73.75,
        "Latitude": 461700000,
        "Longitude": -734500000,
        "Elevation (m)": 244,
        "First Year": 1950,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2021,
        "MLY First Year": 1950,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST DONAT",
        "Province": "QUEBEC",
        "Climate ID": "7017100",
        "Station ID": 5269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -74.2,
        "Latitude": 461900000,
        "Longitude": -741200000,
        "Elevation (m)": 388.6,
        "First Year": 1964,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2014,
        "MLY First Year": 1964,
        "MLY Last Year": 2014
      },
      {
        "Name": "STE ELIZABETH",
        "Province": "QUEBEC",
        "Climate ID": "7017148",
        "Station ID": 5270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -73.37,
        "Latitude": 460400000,
        "Longitude": -732200000,
        "Elevation (m)": 45.7,
        "First Year": 1979,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1985,
        "MLY First Year": 1979,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST ETIENNE DES GRES",
        "Province": "QUEBEC",
        "Climate ID": "7017220",
        "Station ID": 5271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.45,
        "Longitude (Decimal Degrees)": -72.8,
        "Latitude": 462700000,
        "Longitude": -724800000,
        "Elevation (m)": 106.7,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "STE FOY",
        "Province": "QUEBEC",
        "Climate ID": "7017265",
        "Station ID": 5272,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.75,
        "Longitude (Decimal Degrees)": -71.27,
        "Latitude": 464500000,
        "Longitude": -711600000,
        "Elevation (m)": 91.4,
        "First Year": 1958,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1972,
        "MLY First Year": 1958,
        "MLY Last Year": 1972
      },
      {
        "Name": "ST GABRIEL DE BRANDON",
        "Province": "QUEBEC",
        "Climate ID": "7017270",
        "Station ID": 5273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -73.38,
        "Latitude": 461800000,
        "Longitude": -732300000,
        "Elevation (m)": 198.1,
        "First Year": 1919,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1985,
        "MLY First Year": 1919,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST JACQUES",
        "Province": "QUEBEC",
        "Climate ID": "7017380",
        "Station ID": 5274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 455700000,
        "Longitude": -733500000,
        "Elevation (m)": 69,
        "First Year": 1957,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2018,
        "MLY First Year": 1957,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST JANVIER",
        "Province": "QUEBEC",
        "Climate ID": "7017386",
        "Station ID": 5275,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -73.88,
        "Latitude": 454400000,
        "Longitude": -735300000,
        "Elevation (m)": 61,
        "First Year": 1973,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2016,
        "MLY First Year": 1973,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST JOSEPH DE MEKINAC",
        "Province": "QUEBEC",
        "Climate ID": "7017422",
        "Station ID": 5276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -72.68,
        "Latitude": 465500000,
        "Longitude": -724100000,
        "Elevation (m)": 121.9,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST JUSTIN",
        "Province": "QUEBEC",
        "Climate ID": "7017435",
        "Station ID": 5277,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -73.1,
        "Latitude": 461600000,
        "Longitude": -730600000,
        "Elevation (m)": 46,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST LAZARE",
        "Province": "QUEBEC",
        "Climate ID": "7017445",
        "Station ID": 5278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -74.2,
        "Latitude": 452400000,
        "Longitude": -741200000,
        "Elevation (m)": 99.1,
        "First Year": 1963,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1985,
        "MLY First Year": 1963,
        "MLY Last Year": 1985
      },
      {
        "Name": "SAINT-LIN-LAURENTIDES",
        "Province": "QUEBEC",
        "Climate ID": "7017479",
        "Station ID": 42903,
        "WMO ID": null,
        "TC ID": "MLT",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -73.7,
        "Latitude": 455051547,
        "Longitude": -734150989,
        "Elevation (m)": 50,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST LIN DES LAURENTIDES",
        "Province": "QUEBEC",
        "Climate ID": "7017480",
        "Station ID": 5279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -73.75,
        "Latitude": 455100000,
        "Longitude": -734500000,
        "Elevation (m)": 64,
        "First Year": 1913,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1981,
        "MLY First Year": 1913,
        "MLY Last Year": 1981
      },
      {
        "Name": "ST MATHIEU",
        "Province": "QUEBEC",
        "Climate ID": "7017555",
        "Station ID": 5280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.58,
        "Longitude (Decimal Degrees)": -72.93,
        "Latitude": 463500000,
        "Longitude": -725600000,
        "Elevation (m)": 167.6,
        "First Year": 1978,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1994,
        "MLY First Year": 1978,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST NARCISSE",
        "Province": "QUEBEC",
        "Climate ID": "7017585",
        "Station ID": 5281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.53,
        "Longitude (Decimal Degrees)": -72.43,
        "Latitude": 463200000,
        "Longitude": -722600000,
        "Elevation (m)": 46,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST PAULIN",
        "Province": "QUEBEC",
        "Climate ID": "7017640",
        "Station ID": 5282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -73.02,
        "Latitude": 462500000,
        "Longitude": -730100000,
        "Elevation (m)": 167,
        "First Year": 1950,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1991,
        "MLY First Year": 1951,
        "MLY Last Year": 1991
      },
      {
        "Name": "ST PLACIDE",
        "Province": "QUEBEC",
        "Climate ID": "7017657",
        "Station ID": 5283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -74.2,
        "Latitude": 453300000,
        "Longitude": -741200000,
        "Elevation (m)": 40,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE THERESE SEMINAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7017753",
        "Station ID": 5284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -73.85,
        "Latitude": 453800000,
        "Longitude": -735100000,
        "Elevation (m)": 45.7,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1961,
        "MLY Last Year": 1961
      },
      {
        "Name": "STE THERESE OUEST",
        "Province": "QUEBEC",
        "Climate ID": "7017755",
        "Station ID": 5285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -73.88,
        "Latitude": 453900000,
        "Longitude": -735300000,
        "Elevation (m)": 61,
        "First Year": 1961,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2010,
        "MLY First Year": 1961,
        "MLY Last Year": 2010
      },
      {
        "Name": "ST THOMAS DE CAXTON",
        "Province": "QUEBEC",
        "Climate ID": "7017757",
        "Station ID": 5286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -72.8,
        "Latitude": 462200000,
        "Longitude": -724800000,
        "Elevation (m)": 53,
        "First Year": 1980,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1991,
        "MLY First Year": 1981,
        "MLY Last Year": 1991
      },
      {
        "Name": "ST TITE",
        "Province": "QUEBEC",
        "Climate ID": "7017760",
        "Station ID": 5287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.73,
        "Longitude (Decimal Degrees)": -72.57,
        "Latitude": 464400000,
        "Longitude": -723400000,
        "Elevation (m)": 141.7,
        "First Year": 1920,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1985,
        "MLY First Year": 1920,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST UBALD",
        "Province": "QUEBEC",
        "Climate ID": "7017767",
        "Station ID": 5288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.68,
        "Longitude (Decimal Degrees)": -72.32,
        "Latitude": 464100000,
        "Longitude": -721900000,
        "Elevation (m)": null,
        "First Year": 1963,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1964,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "ST ZENON",
        "Province": "QUEBEC",
        "Climate ID": "7017786",
        "Station ID": 5289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.62,
        "Longitude (Decimal Degrees)": -73.87,
        "Latitude": 463700000,
        "Longitude": -735200000,
        "Elevation (m)": 442,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE FOY MATAPEDIA",
        "Province": "QUEBEC",
        "Climate ID": "7017B65",
        "Station ID": 5265,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.75,
        "Longitude (Decimal Degrees)": -71.28,
        "Latitude": 464500000,
        "Longitude": -711700000,
        "Elevation (m)": 45.7,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "STE FOY (PIE XII)",
        "Province": "QUEBEC",
        "Climate ID": "7017BFN",
        "Station ID": 5264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.78,
        "Longitude (Decimal Degrees)": -71.32,
        "Latitude": 464700000,
        "Longitude": -711900000,
        "Elevation (m)": 79.2,
        "First Year": 1972,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1996,
        "MLY First Year": 1972,
        "MLY Last Year": 1996
      },
      {
        "Name": "SHAWINIGAN",
        "Province": "QUEBEC",
        "Climate ID": "7018000",
        "Station ID": 5290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.57,
        "Longitude (Decimal Degrees)": -72.75,
        "Latitude": 463400000,
        "Longitude": -724500000,
        "Elevation (m)": 121.9,
        "First Year": 1902,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1902,
        "DLY Last Year": 2004,
        "MLY First Year": 1902,
        "MLY Last Year": 2004
      },
      {
        "Name": "SHAWINIGAN",
        "Province": "QUEBEC",
        "Climate ID": "7018001",
        "Station ID": 27646,
        "WMO ID": 71370,
        "TC ID": "XSH",
        "Latitude (Decimal Degrees)": 46.56,
        "Longitude (Decimal Degrees)": -72.73,
        "Latitude": 463348000,
        "Longitude": -724400000,
        "Elevation (m)": 110,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TERREBONNE HEIGHTS",
        "Province": "QUEBEC",
        "Climate ID": "7018410",
        "Station ID": 5291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -73.63,
        "Latitude": 454500000,
        "Longitude": -733800000,
        "Elevation (m)": 53.3,
        "First Year": 1961,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "TROIS RIVIERES A-2",
        "Province": "QUEBEC",
        "Climate ID": "7018560",
        "Station ID": 52138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -72.67,
        "Latitude": 462100000,
        "Longitude": -724000000,
        "Elevation (m)": null,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TROIS RIVIERES A",
        "Province": "QUEBEC",
        "Climate ID": "7018561",
        "Station ID": 51698,
        "WMO ID": null,
        "TC ID": "YRQ",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -72.68,
        "Latitude": 462106000,
        "Longitude": -724050000,
        "Elevation (m)": 60.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TROIS-RIVIERES",
        "Province": "QUEBEC",
        "Climate ID": "7018562",
        "Station ID": 10764,
        "WMO ID": 71724,
        "TC ID": "WTY",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -72.52,
        "Latitude": 462113000,
        "Longitude": -723058000,
        "Elevation (m)": 6,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "TROIS-RIVIERES",
        "Province": "QUEBEC",
        "Climate ID": "7018563",
        "Station ID": 8321,
        "WMO ID": 71729,
        "TC ID": "GRQ",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -72.68,
        "Latitude": 462200000,
        "Longitude": -724100000,
        "Elevation (m)": 62.2,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": 1963,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TROIS RIVIERES",
        "Province": "QUEBEC",
        "Climate ID": "7018564",
        "Station ID": 5292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -72.6,
        "Latitude": 462200000,
        "Longitude": -723600000,
        "Elevation (m)": 53.3,
        "First Year": 1934,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1986,
        "MLY First Year": 1934,
        "MLY Last Year": 1986
      },
      {
        "Name": "VALCARTIER",
        "Province": "QUEBEC",
        "Climate ID": "7018572",
        "Station ID": 10965,
        "WMO ID": null,
        "TC ID": "WQG",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 465400000,
        "Longitude": -713000000,
        "Elevation (m)": 167.9,
        "First Year": 1994,
        "Last Year": 2008,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": 1994,
        "DLY Last Year": 2008,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VALCARTIER A",
        "Province": "QUEBEC",
        "Climate ID": "7018573",
        "Station ID": 29452,
        "WMO ID": 71716,
        "TC ID": "YOY",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 465410000,
        "Longitude": -713013000,
        "Elevation (m)": 167.6,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VALCARTIER A",
        "Province": "QUEBEC",
        "Climate ID": "7018574",
        "Station ID": 5293,
        "WMO ID": 71716,
        "TC ID": "YOY",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 465410000,
        "Longitude": -713013000,
        "Elevation (m)": 167.6,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": 1978,
        "HLY Last Year": 2022,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "VALCARTIER FES",
        "Province": "QUEBEC",
        "Climate ID": "7018577",
        "Station ID": 5294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.95,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 465700000,
        "Longitude": -713000000,
        "Elevation (m)": 184.4,
        "First Year": 1955,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1977,
        "MLY First Year": 1955,
        "MLY Last Year": 1977
      },
      {
        "Name": "VAUDREUIL",
        "Province": "QUEBEC",
        "Climate ID": "7018697",
        "Station ID": 5295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -74.03,
        "Latitude": 452400000,
        "Longitude": -740200000,
        "Elevation (m)": 27.4,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1971
      },
      {
        "Name": "VILLENEUVE",
        "Province": "QUEBEC",
        "Climate ID": "7018762",
        "Station ID": 5296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 465200000,
        "Longitude": -711000000,
        "Elevation (m)": 2,
        "First Year": 1985,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 1988,
        "MLY First Year": 1985,
        "MLY Last Year": 1987
      },
      {
        "Name": "VILLEROY RADAR",
        "Province": "QUEBEC",
        "Climate ID": "7018765",
        "Station ID": 8678,
        "WMO ID": null,
        "TC ID": "WVY",
        "Latitude (Decimal Degrees)": 46.45,
        "Longitude (Decimal Degrees)": -71.92,
        "Latitude": 462700000,
        "Longitude": -715500000,
        "Elevation (m)": 100,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": 1991,
        "HLY Last Year": 1992,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VILLEROY (STE-FRANCOISE)",
        "Province": "QUEBEC",
        "Climate ID": "7018766",
        "Station ID": 10812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -72.06,
        "Latitude": 461813300,
        "Longitude": -720338600,
        "Elevation (m)": 96.7,
        "First Year": 1993,
        "Last Year": 1999,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": 1993,
        "DLY Last Year": 1999,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "COURVILLE DE POISSY",
        "Province": "QUEBEC",
        "Climate ID": "701A9E0",
        "Station ID": 5200,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.88,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 465300000,
        "Longitude": -711000000,
        "Elevation (m)": 114.3,
        "First Year": 1974,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1982,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "DALHOUSIE STATION",
        "Province": "QUEBEC",
        "Climate ID": "701A9EC",
        "Station ID": 5199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -74.47,
        "Latitude": 451800000,
        "Longitude": -742800000,
        "Elevation (m)": 70,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1976,
        "MLY Last Year": 1994
      },
      {
        "Name": "TROIS RIVIERES AQUEDUC",
        "Province": "QUEBEC",
        "Climate ID": "701HE63",
        "Station ID": 5201,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -72.62,
        "Latitude": 462300000,
        "Longitude": -723700000,
        "Elevation (m)": 54.9,
        "First Year": 1974,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2009,
        "MLY First Year": 1974,
        "MLY Last Year": 2009
      },
      {
        "Name": "DE BEAUJEU",
        "Province": "QUEBEC",
        "Climate ID": "701J9NM",
        "Station ID": 5202,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -74.33,
        "Latitude": 452000000,
        "Longitude": -742000000,
        "Elevation (m)": 61,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ILE AUX GRUES",
        "Province": "QUEBEC",
        "Climate ID": "701LBPH",
        "Station ID": 10247,
        "WMO ID": null,
        "TC ID": "WIG",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -70.53,
        "Latitude": 470425000,
        "Longitude": -703159000,
        "Elevation (m)": 18.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAC AUX SABLES",
        "Province": "QUEBEC",
        "Climate ID": "701LEEH",
        "Station ID": 5203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -72.4,
        "Latitude": 465200000,
        "Longitude": -722400000,
        "Elevation (m)": 160,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1964,
        "MLY Last Year": 2018
      },
      {
        "Name": "LA COURVILLOISE",
        "Province": "QUEBEC",
        "Climate ID": "701LF83",
        "Station ID": 5204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -71.15,
        "Latitude": 465400000,
        "Longitude": -710900000,
        "Elevation (m)": 114,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1991,
        "MLY First Year": 1988,
        "MLY Last Year": 1991
      },
      {
        "Name": "LAC SAINT-PIERRE",
        "Province": "QUEBEC",
        "Climate ID": "701LP0N",
        "Station ID": 10975,
        "WMO ID": 71198,
        "TC ID": "WBS",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -72.92,
        "Latitude": 461100000,
        "Longitude": -725500000,
        "Elevation (m)": 16.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STE-FOY (U. LAVAL)",
        "Province": "QUEBEC",
        "Climate ID": "701Q004",
        "Station ID": 27377,
        "WMO ID": 71392,
        "TC ID": "WJB",
        "Latitude (Decimal Degrees)": 46.78,
        "Longitude (Decimal Degrees)": -71.29,
        "Latitude": 464649000,
        "Longitude": -711715000,
        "Elevation (m)": 91.4,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LEMIEUX",
        "Province": "QUEBEC",
        "Climate ID": "701Q009",
        "Station ID": 29714,
        "WMO ID": 71616,
        "TC ID": "MLU",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -72.06,
        "Latitude": 461813000,
        "Longitude": -720339000,
        "Elevation (m)": 97.2,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QUEBEC/JEAN LESAGE INTL",
        "Province": "QUEBEC",
        "Climate ID": "701S001",
        "Station ID": 26892,
        "WMO ID": 71714,
        "TC ID": "WQB",
        "Latitude (Decimal Degrees)": 46.8,
        "Longitude (Decimal Degrees)": -71.38,
        "Latitude": 464813000,
        "Longitude": -712254000,
        "Elevation (m)": 60,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2016
      },
      {
        "Name": "ABERCORN",
        "Province": "QUEBEC",
        "Climate ID": "7020040",
        "Station ID": 5308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -72.67,
        "Latitude": 450200000,
        "Longitude": -724000000,
        "Elevation (m)": 149.4,
        "First Year": 1950,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1985,
        "MLY First Year": 1950,
        "MLY Last Year": 1985
      },
      {
        "Name": "ACTON VALE",
        "Province": "QUEBEC",
        "Climate ID": "7020055",
        "Station ID": 5309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -72.57,
        "Latitude": 453900000,
        "Longitude": -723400000,
        "Elevation (m)": 94.5,
        "First Year": 1964,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1973,
        "MLY First Year": 1964,
        "MLY Last Year": 1973
      },
      {
        "Name": "ARTHABASKA",
        "Province": "QUEBEC",
        "Climate ID": "7020305",
        "Station ID": 5310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.02,
        "Longitude (Decimal Degrees)": -71.95,
        "Latitude": 460100000,
        "Longitude": -715700000,
        "Elevation (m)": 140,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2022,
        "MLY First Year": 1969,
        "MLY Last Year": 2018
      },
      {
        "Name": "ASBESTOS",
        "Province": "QUEBEC",
        "Climate ID": "7020360",
        "Station ID": 5311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -71.95,
        "Latitude": 454600000,
        "Longitude": -715700000,
        "Elevation (m)": 228.6,
        "First Year": 1948,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1987,
        "MLY First Year": 1948,
        "MLY Last Year": 1987
      },
      {
        "Name": "AUDET",
        "Province": "QUEBEC",
        "Climate ID": "7020391",
        "Station ID": 5312,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -70.73,
        "Latitude": 453900000,
        "Longitude": -704400000,
        "Elevation (m)": 513.6,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "AUTEUIL",
        "Province": "QUEBEC",
        "Climate ID": "7020392",
        "Station ID": 5313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -73.73,
        "Latitude": 453900000,
        "Longitude": -734400000,
        "Elevation (m)": 53,
        "First Year": 1983,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2016,
        "MLY First Year": 1983,
        "MLY Last Year": 2016
      },
      {
        "Name": "BEAUCEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7020560",
        "Station ID": 5314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -70.77,
        "Latitude": 461200000,
        "Longitude": -704600000,
        "Elevation (m)": 160,
        "First Year": 1913,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1985,
        "MLY First Year": 1913,
        "MLY Last Year": 1985
      },
      {
        "Name": "BEAUHARNOIS",
        "Province": "QUEBEC",
        "Climate ID": "7020562",
        "Station ID": 10948,
        "WMO ID": null,
        "TC ID": "WBH",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -73.92,
        "Latitude": 451000000,
        "Longitude": -735500000,
        "Elevation (m)": 33.9,
        "First Year": 1994,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAUHARNOIS",
        "Province": "QUEBEC",
        "Climate ID": "7020564",
        "Station ID": 42007,
        "WMO ID": null,
        "TC ID": "MBR",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -73.91,
        "Latitude": 451910000,
        "Longitude": -735444000,
        "Elevation (m)": 22,
        "First Year": 2003,
        "Last Year": 2006,
        "HLY First Year": 2003,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEAUSEJOUR",
        "Province": "QUEBEC",
        "Climate ID": "7020567",
        "Station ID": 5315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 464000000,
        "Longitude": -711000000,
        "Elevation (m)": 106.7,
        "First Year": 1975,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2020,
        "MLY First Year": 1975,
        "MLY Last Year": 2018
      },
      {
        "Name": "BECANCOUR",
        "Province": "QUEBEC",
        "Climate ID": "7020570",
        "Station ID": 5316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -72.43,
        "Latitude": 462000000,
        "Longitude": -722600000,
        "Elevation (m)": 14.9,
        "First Year": 1966,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1994,
        "MLY First Year": 1966,
        "MLY Last Year": 1994
      },
      {
        "Name": "BETHANIE",
        "Province": "QUEBEC",
        "Climate ID": "7020750",
        "Station ID": 5317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -72.43,
        "Latitude": 453000000,
        "Longitude": -722600000,
        "Elevation (m)": 198.1,
        "First Year": 1968,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1985,
        "MLY First Year": 1968,
        "MLY Last Year": 1985
      },
      {
        "Name": "BIRCHTON",
        "Province": "QUEBEC",
        "Climate ID": "7020785",
        "Station ID": 5318,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.67,
        "Latitude": 452200000,
        "Longitude": -714000000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "BISHOPTON",
        "Province": "QUEBEC",
        "Climate ID": "7020800",
        "Station ID": 5319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -71.57,
        "Latitude": 453500000,
        "Longitude": -713400000,
        "Elevation (m)": 213.4,
        "First Year": 1948,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1996,
        "MLY First Year": 1948,
        "MLY Last Year": 1996
      },
      {
        "Name": "BLACK LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7020808",
        "Station ID": 5320,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -71.37,
        "Latitude": 460300000,
        "Longitude": -712200000,
        "Elevation (m)": 262.7,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "BON CONSEIL EXP ST",
        "Province": "QUEBEC",
        "Climate ID": "7020820",
        "Station ID": 5321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46,
        "Longitude (Decimal Degrees)": -72.4,
        "Latitude": 460000000,
        "Longitude": -722400000,
        "Elevation (m)": 76.2,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "BONSECOURS",
        "Province": "QUEBEC",
        "Climate ID": "7020828",
        "Station ID": 5322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -72.27,
        "Latitude": 452400000,
        "Longitude": -721600000,
        "Elevation (m)": 297.2,
        "First Year": 1967,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2022,
        "MLY First Year": 1967,
        "MLY Last Year": 2018
      },
      {
        "Name": "BRIGHAM",
        "Province": "QUEBEC",
        "Climate ID": "7020833",
        "Station ID": 5323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -72.85,
        "Latitude": 451500000,
        "Longitude": -725100000,
        "Elevation (m)": 91.4,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1968,
        "MLY Last Year": 1972
      },
      {
        "Name": "BRITANNIA MILLS",
        "Province": "QUEBEC",
        "Climate ID": "7020835",
        "Station ID": 5324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -72.82,
        "Latitude": 453800000,
        "Longitude": -724900000,
        "Elevation (m)": 68.6,
        "First Year": 1968,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1975,
        "MLY First Year": 1968,
        "MLY Last Year": 1975
      },
      {
        "Name": "BROME",
        "Province": "QUEBEC",
        "Climate ID": "7020840",
        "Station ID": 5325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.18,
        "Longitude (Decimal Degrees)": -72.57,
        "Latitude": 451100000,
        "Longitude": -723400000,
        "Elevation (m)": 205.7,
        "First Year": 1875,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1875,
        "DLY Last Year": 2014,
        "MLY First Year": 1875,
        "MLY Last Year": 2014
      },
      {
        "Name": "BROMONT",
        "Province": "QUEBEC",
        "Climate ID": "7020850",
        "Station ID": 5326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -72.63,
        "Latitude": 451800000,
        "Longitude": -723800000,
        "Elevation (m)": 175.3,
        "First Year": 1968,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1978,
        "MLY First Year": 1968,
        "MLY Last Year": 1978
      },
      {
        "Name": "BROMPTONVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7020860",
        "Station ID": 5327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -71.95,
        "Latitude": 452900000,
        "Longitude": -715700000,
        "Elevation (m)": 130,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2018
      },
      {
        "Name": "BROUGHTON STATION",
        "Province": "QUEBEC",
        "Climate ID": "7020865",
        "Station ID": 5328,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -71.12,
        "Latitude": 461200000,
        "Longitude": -710700000,
        "Elevation (m)": 373.4,
        "First Year": 1965,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1981,
        "MLY First Year": 1965,
        "MLY Last Year": 1981
      },
      {
        "Name": "BULWER",
        "Province": "QUEBEC",
        "Climate ID": "7020877",
        "Station ID": 5329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -71.7,
        "Latitude": 452100000,
        "Longitude": -714200000,
        "Elevation (m)": 288,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "BURROUGHS FALLS",
        "Province": "QUEBEC",
        "Climate ID": "7020880",
        "Station ID": 5330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -72,
        "Latitude": 450900000,
        "Longitude": -720000000,
        "Elevation (m)": 210.3,
        "First Year": 1952,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1954,
        "MLY First Year": 1952,
        "MLY Last Year": 1954
      },
      {
        "Name": "BURY",
        "Province": "QUEBEC",
        "Climate ID": "7020884",
        "Station ID": 5331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -71.52,
        "Latitude": 452900000,
        "Longitude": -713100000,
        "Elevation (m)": 289.6,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "BURY",
        "Province": "QUEBEC",
        "Climate ID": "7020885",
        "Station ID": 5332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -71.48,
        "Latitude": 453100000,
        "Longitude": -712900000,
        "Elevation (m)": 378,
        "First Year": 1970,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1994,
        "MLY First Year": 1970,
        "MLY Last Year": 1994
      },
      {
        "Name": "BURY (COLLEGE D'ALZON)",
        "Province": "QUEBEC",
        "Climate ID": "7020888",
        "Station ID": 5333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 452900000,
        "Longitude": -713000000,
        "Elevation (m)": 289.6,
        "First Year": 1963,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1971,
        "MLY First Year": 1963,
        "MLY Last Year": 1968
      },
      {
        "Name": "BARNSTON",
        "Province": "QUEBEC",
        "Climate ID": "7020DDP",
        "Station ID": 5307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.08,
        "Longitude (Decimal Degrees)": -71.88,
        "Latitude": 450500000,
        "Longitude": -715300000,
        "Elevation (m)": 445,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "CHARTIERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7021320",
        "Station ID": 5334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -71.2,
        "Latitude": 451700000,
        "Longitude": -711200000,
        "Elevation (m)": 518.2,
        "First Year": 1951,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1990,
        "MLY First Year": 1951,
        "MLY Last Year": 1990
      },
      {
        "Name": "CHRISTMAS BROOK NORTH",
        "Province": "QUEBEC",
        "Climate ID": "7021460",
        "Station ID": 5335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -71.37,
        "Latitude": 452500000,
        "Longitude": -712200000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "CHUTE HEMMINGS",
        "Province": "QUEBEC",
        "Climate ID": "7021580",
        "Station ID": 5336,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -72.45,
        "Latitude": 455200000,
        "Longitude": -722700000,
        "Elevation (m)": 86.9,
        "First Year": 1931,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1986,
        "MLY First Year": 1931,
        "MLY Last Year": 1984
      },
      {
        "Name": "CLIFTON RIVER",
        "Province": "QUEBEC",
        "Climate ID": "7021718",
        "Station ID": 5337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -71.57,
        "Latitude": 451800000,
        "Longitude": -713400000,
        "Elevation (m)": 342.9,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "CLIFTON RIVER SOUTH",
        "Province": "QUEBEC",
        "Climate ID": "7021719",
        "Station ID": 5338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 451400000,
        "Longitude": -713000000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "COATICOOK",
        "Province": "QUEBEC",
        "Climate ID": "7021840",
        "Station ID": 5339,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -71.8,
        "Latitude": 450900000,
        "Longitude": -714800000,
        "Elevation (m)": 259.1,
        "First Year": 1949,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2022,
        "MLY First Year": 1949,
        "MLY Last Year": 2018
      },
      {
        "Name": "COMPTON STATION",
        "Province": "QUEBEC",
        "Climate ID": "7021916",
        "Station ID": 5340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -71.85,
        "Latitude": 451200000,
        "Longitude": -715100000,
        "Elevation (m)": 297.2,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "COMPTON STATION CDA FE",
        "Province": "QUEBEC",
        "Climate ID": "7021917",
        "Station ID": 5341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -71.87,
        "Latitude": 451300000,
        "Longitude": -715200000,
        "Elevation (m)": 312.4,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "COOKSHIRE",
        "Province": "QUEBEC",
        "Climate ID": "7021918",
        "Station ID": 5342,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -71.63,
        "Latitude": 452500000,
        "Longitude": -713800000,
        "Elevation (m)": 212.8,
        "First Year": 1964,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1980,
        "MLY First Year": 1964,
        "MLY Last Year": 1980
      },
      {
        "Name": "COTE ST LUC",
        "Province": "QUEBEC",
        "Climate ID": "7021945",
        "Station ID": 5343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -73.67,
        "Latitude": 452700000,
        "Longitude": -734000000,
        "Elevation (m)": 48.8,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "COVEY HILL",
        "Province": "QUEBEC",
        "Climate ID": "7021950",
        "Station ID": 42605,
        "WMO ID": null,
        "TC ID": "MCW",
        "Latitude (Decimal Degrees)": 45.01,
        "Longitude (Decimal Degrees)": -73.87,
        "Latitude": 450050000,
        "Longitude": -735224000,
        "Elevation (m)": 262,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CRANBOURNE",
        "Province": "QUEBEC",
        "Climate ID": "7021952",
        "Station ID": 5344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -70.7,
        "Latitude": 462300000,
        "Longitude": -704200000,
        "Elevation (m)": null,
        "First Year": 1875,
        "Last Year": 1890,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1875,
        "DLY Last Year": 1890,
        "MLY First Year": 1875,
        "MLY Last Year": 1890
      },
      {
        "Name": "DANVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7021954",
        "Station ID": 5345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.82,
        "Longitude (Decimal Degrees)": -71.98,
        "Latitude": 454900000,
        "Longitude": -715900000,
        "Elevation (m)": 190,
        "First Year": 1871,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 2022,
        "MLY First Year": 1871,
        "MLY Last Year": 2018
      },
      {
        "Name": "DISRAELI",
        "Province": "QUEBEC",
        "Climate ID": "7022000",
        "Station ID": 5346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -71.32,
        "Latitude": 455500000,
        "Longitude": -711900000,
        "Elevation (m)": 350,
        "First Year": 1908,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1908,
        "DLY Last Year": 1991,
        "MLY First Year": 1908,
        "MLY Last Year": 1991
      },
      {
        "Name": "DOUANES ARMSTRONG",
        "Province": "QUEBEC",
        "Climate ID": "7022127",
        "Station ID": 5347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.82,
        "Longitude (Decimal Degrees)": -70.4,
        "Latitude": 454900000,
        "Longitude": -702400000,
        "Elevation (m)": 563.9,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "DRUMMONDVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7022160",
        "Station ID": 5348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -72.48,
        "Latitude": 455300000,
        "Longitude": -722900000,
        "Elevation (m)": 82.3,
        "First Year": 1913,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2022,
        "MLY First Year": 1913,
        "MLY Last Year": 2018
      },
      {
        "Name": "DUNHAM",
        "Province": "QUEBEC",
        "Climate ID": "7022245",
        "Station ID": 5350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -72.82,
        "Latitude": 450600000,
        "Longitude": -724900000,
        "Elevation (m)": 204.2,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "DUNHAM",
        "Province": "QUEBEC",
        "Climate ID": "7022246",
        "Station ID": 5351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -72.82,
        "Latitude": 450900000,
        "Longitude": -724900000,
        "Elevation (m)": 121,
        "First Year": 1968,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1985,
        "MLY First Year": 1968,
        "MLY Last Year": 1985
      },
      {
        "Name": "DUVERNAY",
        "Province": "QUEBEC",
        "Climate ID": "7022250",
        "Station ID": 5352,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 453800000,
        "Longitude": -734100000,
        "Elevation (m)": 30.5,
        "First Year": 1960,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1961,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "EAST ANGUS",
        "Province": "QUEBEC",
        "Climate ID": "7022280",
        "Station ID": 5353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -71.67,
        "Latitude": 452900000,
        "Longitude": -714000000,
        "Elevation (m)": 189,
        "First Year": 1919,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1985,
        "MLY First Year": 1919,
        "MLY Last Year": 1985
      },
      {
        "Name": "EAST BROUGHTON",
        "Province": "QUEBEC",
        "Climate ID": "7022285",
        "Station ID": 5354,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -71.08,
        "Latitude": 461300000,
        "Longitude": -710500000,
        "Elevation (m)": 365.8,
        "First Year": 1965,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1974,
        "MLY First Year": 1965,
        "MLY Last Year": 1974
      },
      {
        "Name": "EAST CLIFTON NORTH",
        "Province": "QUEBEC",
        "Climate ID": "7022288",
        "Station ID": 5355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -71.57,
        "Latitude": 451600000,
        "Longitude": -713400000,
        "Elevation (m)": 373.7,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "DURHAM SUD",
        "Province": "QUEBEC",
        "Climate ID": "70222DR",
        "Station ID": 5349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -72.35,
        "Latitude": 453800000,
        "Longitude": -722100000,
        "Elevation (m)": 191.1,
        "First Year": 1981,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1994
      },
      {
        "Name": "EAST HEREFORD",
        "Province": "QUEBEC",
        "Climate ID": "7022300",
        "Station ID": 5356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.08,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 450500000,
        "Longitude": -713000000,
        "Elevation (m)": 358,
        "First Year": 1949,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1994,
        "MLY First Year": 1949,
        "MLY Last Year": 1994
      },
      {
        "Name": "EATON SECOND BRANCH",
        "Province": "QUEBEC",
        "Climate ID": "7022306",
        "Station ID": 5357,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -71.43,
        "Latitude": 451600000,
        "Longitude": -712600000,
        "Elevation (m)": 491.6,
        "First Year": 1966,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1981,
        "MLY First Year": 1966,
        "MLY Last Year": 1981
      },
      {
        "Name": "FARNHAM",
        "Province": "QUEBEC",
        "Climate ID": "7022320",
        "Station ID": 5358,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -72.9,
        "Latitude": 451800000,
        "Longitude": -725400000,
        "Elevation (m)": 68,
        "First Year": 1917,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 2022,
        "MLY First Year": 1917,
        "MLY Last Year": 2018
      },
      {
        "Name": "FARNHAM S",
        "Province": "QUEBEC",
        "Climate ID": "7022325",
        "Station ID": 5359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -72.97,
        "Latitude": 451500000,
        "Longitude": -725800000,
        "Elevation (m)": 59.4,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "FLANDERS",
        "Province": "QUEBEC",
        "Climate ID": "7022374",
        "Station ID": 5360,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.55,
        "Latitude": 452200000,
        "Longitude": -713300000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "FLEURY",
        "Province": "QUEBEC",
        "Climate ID": "7022375",
        "Station ID": 5361,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -73,
        "Latitude": 454800000,
        "Longitude": -730000000,
        "Elevation (m)": 30.5,
        "First Year": 1967,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2022,
        "MLY First Year": 1967,
        "MLY Last Year": 2018
      },
      {
        "Name": "FORTIERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7022494",
        "Station ID": 5362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -72.05,
        "Latitude": 462900000,
        "Longitude": -720300000,
        "Elevation (m)": 53.3,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "FRAMPTON",
        "Province": "QUEBEC",
        "Climate ID": "7022553",
        "Station ID": 5363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -70.83,
        "Latitude": 462600000,
        "Longitude": -705000000,
        "Elevation (m)": 426.7,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "FRANKLIN CENTRE",
        "Province": "QUEBEC",
        "Climate ID": "7022560",
        "Station ID": 5364,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -73.9,
        "Latitude": 450200000,
        "Longitude": -735400000,
        "Elevation (m)": 144.8,
        "First Year": 1954,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1985,
        "MLY First Year": 1955,
        "MLY Last Year": 1985
      },
      {
        "Name": "FRELIGHSBURG",
        "Province": "QUEBEC",
        "Climate ID": "7022579",
        "Station ID": 10815,
        "WMO ID": 71373,
        "TC ID": "WFQ",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -72.85,
        "Latitude": 450200000,
        "Longitude": -725100000,
        "Elevation (m)": 224,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FRELIGHSBURG",
        "Province": "QUEBEC",
        "Climate ID": "7022580",
        "Station ID": 5365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -72.87,
        "Latitude": 450300000,
        "Longitude": -725200000,
        "Elevation (m)": 112.8,
        "First Year": 1960,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1965,
        "MLY First Year": 1960,
        "MLY Last Year": 1965
      },
      {
        "Name": "GARTHBY",
        "Province": "QUEBEC",
        "Climate ID": "7022596",
        "Station ID": 5366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -71.38,
        "Latitude": 455000000,
        "Longitude": -712300000,
        "Elevation (m)": 251,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "GENTILLY",
        "Province": "QUEBEC",
        "Climate ID": "7022700",
        "Station ID": 5367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.4,
        "Longitude (Decimal Degrees)": -72.37,
        "Latitude": 462400000,
        "Longitude": -722200000,
        "Elevation (m)": 6.1,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "GEORGEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7022720",
        "Station ID": 5368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -72.23,
        "Latitude": 450800000,
        "Longitude": -721400000,
        "Elevation (m)": 266.7,
        "First Year": 1951,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2021,
        "MLY First Year": 1951,
        "MLY Last Year": 2018
      },
      {
        "Name": "GRANBY",
        "Province": "QUEBEC",
        "Climate ID": "7022800",
        "Station ID": 5369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -72.72,
        "Latitude": 452300000,
        "Longitude": -724300000,
        "Elevation (m)": 175,
        "First Year": 1948,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2022,
        "MLY First Year": 1948,
        "MLY Last Year": 2018
      },
      {
        "Name": "GRANBY",
        "Province": "QUEBEC",
        "Climate ID": "7022802",
        "Station ID": 42013,
        "WMO ID": null,
        "TC ID": "MGB",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -72.77,
        "Latitude": 452222000,
        "Longitude": -724626000,
        "Elevation (m)": 86,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAM NORD",
        "Province": "QUEBEC",
        "Climate ID": "7022985",
        "Station ID": 5370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -71.63,
        "Latitude": 455600000,
        "Longitude": -713800000,
        "Elevation (m)": 403.9,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "HATLEY",
        "Province": "QUEBEC",
        "Climate ID": "7023005",
        "Station ID": 5371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -71.95,
        "Latitude": 451200000,
        "Longitude": -715700000,
        "Elevation (m)": 304.8,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "HEMMINGFORD",
        "Province": "QUEBEC",
        "Climate ID": "7023073",
        "Station ID": 5372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 450300000,
        "Longitude": -733500000,
        "Elevation (m)": 84.7,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1965
      },
      {
        "Name": "HEMMINGFORD FOUR WINDS",
        "Province": "QUEBEC",
        "Climate ID": "7023075",
        "Station ID": 5373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -73.72,
        "Latitude": 450400000,
        "Longitude": -734300000,
        "Elevation (m)": 61,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2018
      },
      {
        "Name": "HOWICK",
        "Province": "QUEBEC",
        "Climate ID": "7023200",
        "Station ID": 42008,
        "WMO ID": null,
        "TC ID": "MHW",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -73.87,
        "Latitude": 451018000,
        "Longitude": -735215000,
        "Elevation (m)": 42,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HUNTINGDON",
        "Province": "QUEBEC",
        "Climate ID": "7023240",
        "Station ID": 5374,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -74.17,
        "Latitude": 450300000,
        "Longitude": -741000000,
        "Elevation (m)": 49.1,
        "First Year": 1870,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 2008,
        "MLY First Year": 1870,
        "MLY Last Year": 2008
      },
      {
        "Name": "HUNTINGDON TEAFIELD",
        "Province": "QUEBEC",
        "Climate ID": "7023256",
        "Station ID": 5375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -74.23,
        "Latitude": 450800000,
        "Longitude": -741400000,
        "Elevation (m)": 53.3,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "IBERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7023270",
        "Station ID": 5376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -73.25,
        "Latitude": 452000000,
        "Longitude": -731500000,
        "Elevation (m)": 30.5,
        "First Year": 1963,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2016,
        "MLY First Year": 1963,
        "MLY Last Year": 2016
      },
      {
        "Name": "ILE CHARRON",
        "Province": "QUEBEC",
        "Climate ID": "702327R",
        "Station ID": 10790,
        "WMO ID": null,
        "TC ID": "WVZ",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -73.49,
        "Latitude": 453502000,
        "Longitude": -732930000,
        "Elevation (m)": 11.5,
        "First Year": 1994,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VARENNES",
        "Province": "QUEBEC",
        "Climate ID": "702327X",
        "Station ID": 26856,
        "WMO ID": 71184,
        "TC ID": "WHM",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -73.38,
        "Latitude": 454323000,
        "Longitude": -732236000,
        "Elevation (m)": 17.9,
        "First Year": 1994,
        "Last Year": 2016,
        "HLY First Year": 1994,
        "HLY Last Year": 2016,
        "DLY First Year": 1994,
        "DLY Last Year": 2016,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INVERNESS",
        "Province": "QUEBEC",
        "Climate ID": "7023284",
        "Station ID": 5377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -71.52,
        "Latitude": 461500000,
        "Longitude": -713100000,
        "Elevation (m)": 236.2,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "ISLAND BROOK",
        "Province": "QUEBEC",
        "Climate ID": "7023312",
        "Station ID": 5378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -71.47,
        "Latitude": 452300000,
        "Longitude": -712800000,
        "Elevation (m)": 345.6,
        "First Year": 1965,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1981,
        "MLY First Year": 1965,
        "MLY Last Year": 1981
      },
      {
        "Name": "ISLAND BROOK NORTH",
        "Province": "QUEBEC",
        "Climate ID": "7023313",
        "Station ID": 5379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -71.45,
        "Latitude": 452700000,
        "Longitude": -712700000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "KINGSEY",
        "Province": "QUEBEC",
        "Climate ID": "7023460",
        "Station ID": 5380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -72,
        "Latitude": 455000000,
        "Longitude": -720000000,
        "Elevation (m)": 129.5,
        "First Year": 1957,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1974,
        "MLY First Year": 1957,
        "MLY Last Year": 1974
      },
      {
        "Name": "KINNEARS MILLS",
        "Province": "QUEBEC",
        "Climate ID": "7023465",
        "Station ID": 5381,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -71.38,
        "Latitude": 461200000,
        "Longitude": -712300000,
        "Elevation (m)": 365.8,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "KNOWLTON",
        "Province": "QUEBEC",
        "Climate ID": "7023530",
        "Station ID": 5382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -72.5,
        "Latitude": 451300000,
        "Longitude": -723000000,
        "Elevation (m)": 205.7,
        "First Year": 1958,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1982,
        "MLY First Year": 1958,
        "MLY Last Year": 1982
      },
      {
        "Name": "L'ARTIFICE",
        "Province": "QUEBEC",
        "Climate ID": "7023545",
        "Station ID": 42009,
        "WMO ID": null,
        "TC ID": "MLA",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -73.83,
        "Latitude": 450411000,
        "Longitude": -734958000,
        "Elevation (m)": 61,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAC BROMONT",
        "Province": "QUEBEC",
        "Climate ID": "7023568",
        "Station ID": 5383,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -72.67,
        "Latitude": 451600000,
        "Longitude": -724000000,
        "Elevation (m)": 137.2,
        "First Year": 1968,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1973,
        "MLY First Year": 1968,
        "MLY Last Year": 1973
      },
      {
        "Name": "LAC MEGANTIC",
        "Province": "QUEBEC",
        "Climate ID": "7023675",
        "Station ID": 5384,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -70.87,
        "Latitude": 453600000,
        "Longitude": -705200000,
        "Elevation (m)": 400.5,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "LAC MEGANTIC 2",
        "Province": "QUEBEC",
        "Climate ID": "7023677",
        "Station ID": 5385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -70.87,
        "Latitude": 453600000,
        "Longitude": -705200000,
        "Elevation (m)": 426,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "LAC MEGANTIC",
        "Province": "QUEBEC",
        "Climate ID": "7023680",
        "Station ID": 51638,
        "WMO ID": null,
        "TC ID": "MUS",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -70.88,
        "Latitude": 453600000,
        "Longitude": -705300000,
        "Elevation (m)": 460.3,
        "First Year": 2013,
        "Last Year": 2019,
        "HLY First Year": 2013,
        "HLY Last Year": 2019,
        "DLY First Year": 2018,
        "DLY Last Year": 2019,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NANTES",
        "Province": "QUEBEC",
        "Climate ID": "7023681",
        "Station ID": 54938,
        "WMO ID": 73115,
        "TC ID": "MUS",
        "Latitude (Decimal Degrees)": 45.61,
        "Longitude (Decimal Degrees)": -70.93,
        "Latitude": 453622092,
        "Longitude": -705557086,
        "Elevation (m)": 477,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAKE MEGANTIC",
        "Province": "QUEBEC",
        "Climate ID": "7023800",
        "Station ID": 5386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -70.88,
        "Latitude": 453600000,
        "Longitude": -705300000,
        "Elevation (m)": 400.5,
        "First Year": 1913,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1947,
        "MLY First Year": 1913,
        "MLY Last Year": 1947
      },
      {
        "Name": "LAMBTON",
        "Province": "QUEBEC",
        "Climate ID": "7024000",
        "Station ID": 5387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -71.08,
        "Latitude": 455000000,
        "Longitude": -710500000,
        "Elevation (m)": 365.8,
        "First Year": 1915,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1994,
        "MLY First Year": 1915,
        "MLY Last Year": 1994
      },
      {
        "Name": "LA PATRIE",
        "Province": "QUEBEC",
        "Climate ID": "7024080",
        "Station ID": 5388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -71.25,
        "Latitude": 452400000,
        "Longitude": -711500000,
        "Elevation (m)": 381,
        "First Year": 1949,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1981,
        "MLY First Year": 1949,
        "MLY Last Year": 1981
      },
      {
        "Name": "LAPRAIRIE",
        "Province": "QUEBEC",
        "Climate ID": "7024100",
        "Station ID": 5389,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -73.43,
        "Latitude": 452300000,
        "Longitude": -732600000,
        "Elevation (m)": 30,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "LA PROVIDENCE",
        "Province": "QUEBEC",
        "Climate ID": "7024102",
        "Station ID": 5390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -72.95,
        "Latitude": 453700000,
        "Longitude": -725700000,
        "Elevation (m)": 33,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "LA SALLE",
        "Province": "QUEBEC",
        "Climate ID": "7024118",
        "Station ID": 5391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -73.62,
        "Latitude": 452600000,
        "Longitude": -733700000,
        "Elevation (m)": 22.9,
        "First Year": 1973,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1983,
        "MLY First Year": 1973,
        "MLY Last Year": 1983
      },
      {
        "Name": "LAURIERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7024250",
        "Station ID": 5392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -71.67,
        "Latitude": 462000000,
        "Longitude": -714000000,
        "Elevation (m)": 152,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "LAUZON",
        "Province": "QUEBEC",
        "Climate ID": "7024254",
        "Station ID": 5393,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -71.1,
        "Latitude": 464900000,
        "Longitude": -710600000,
        "Elevation (m)": 69,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1979,
        "MLY Last Year": 2018
      },
      {
        "Name": "LAVAL DES RAPIDES",
        "Province": "QUEBEC",
        "Climate ID": "7024256",
        "Station ID": 5394,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -73.7,
        "Latitude": 453200000,
        "Longitude": -734200000,
        "Elevation (m)": 36.6,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "LAWRENCE",
        "Province": "QUEBEC",
        "Climate ID": "7024263",
        "Station ID": 5395,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -71.43,
        "Latitude": 452500000,
        "Longitude": -712600000,
        "Elevation (m)": 417.3,
        "First Year": 1966,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1981,
        "MLY First Year": 1966,
        "MLY Last Year": 1981
      },
      {
        "Name": "LEARNED PLAIN",
        "Province": "QUEBEC",
        "Climate ID": "7024270",
        "Station ID": 5396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -71.55,
        "Latitude": 452600000,
        "Longitude": -713300000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "LENNOXVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7024280",
        "Station ID": 5397,
        "WMO ID": 71611,
        "TC ID": "WQH",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.82,
        "Latitude": 452208000,
        "Longitude": -714925000,
        "Elevation (m)": 181,
        "First Year": 1888,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1888,
        "DLY Last Year": 2022,
        "MLY First Year": 1888,
        "MLY Last Year": 1995
      },
      {
        "Name": "LINGWICK",
        "Province": "QUEBEC",
        "Climate ID": "7024320",
        "Station ID": 5398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -71.37,
        "Latitude": 453800000,
        "Longitude": -712200000,
        "Elevation (m)": 266.7,
        "First Year": 1952,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2022,
        "MLY First Year": 1952,
        "MLY Last Year": 2018
      },
      {
        "Name": "LITTLE CANADA EAST",
        "Province": "QUEBEC",
        "Climate ID": "7024327",
        "Station ID": 5399,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.2,
        "Latitude": 452200000,
        "Longitude": -711200000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "MACDONALD COLLEGE",
        "Province": "QUEBEC",
        "Climate ID": "7024400",
        "Station ID": 5400,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -73.93,
        "Latitude": 452500000,
        "Longitude": -735600000,
        "Elevation (m)": 27.4,
        "First Year": 1906,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1906,
        "DLY Last Year": 1976,
        "MLY First Year": 1906,
        "MLY Last Year": 1976
      },
      {
        "Name": "MAGOG",
        "Province": "QUEBEC",
        "Climate ID": "7024440",
        "Station ID": 5401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -72.12,
        "Latitude": 451600000,
        "Longitude": -720700000,
        "Elevation (m)": 274,
        "First Year": 1948,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2022,
        "MLY First Year": 1948,
        "MLY Last Year": 2018
      },
      {
        "Name": "MANSEAU",
        "Province": "QUEBEC",
        "Climate ID": "7024615",
        "Station ID": 5402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -71.98,
        "Latitude": 462000000,
        "Longitude": -715900000,
        "Elevation (m)": 96,
        "First Year": 1978,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1994,
        "MLY First Year": 1978,
        "MLY Last Year": 1994
      },
      {
        "Name": "MAPLE LEAF",
        "Province": "QUEBEC",
        "Climate ID": "7024623",
        "Station ID": 5403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 452000000,
        "Longitude": -713000000,
        "Elevation (m)": 366.1,
        "First Year": 1965,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1976,
        "MLY First Year": 1965,
        "MLY Last Year": 1976
      },
      {
        "Name": "MAPLE LEAF EAST",
        "Province": "QUEBEC",
        "Climate ID": "7024624",
        "Station ID": 5404,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -71.4,
        "Latitude": 452000000,
        "Longitude": -712400000,
        "Elevation (m)": 444.7,
        "First Year": 1965,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1981,
        "MLY First Year": 1965,
        "MLY Last Year": 1981
      },
      {
        "Name": "MAPLE LEAF SOUTH",
        "Province": "QUEBEC",
        "Climate ID": "7024625",
        "Station ID": 5405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -71.47,
        "Latitude": 451900000,
        "Longitude": -712800000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "MARIEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7024627",
        "Station ID": 5406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -73.13,
        "Latitude": 452400000,
        "Longitude": -730800000,
        "Elevation (m)": 38,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2018
      },
      {
        "Name": "MARTINVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7024628",
        "Station ID": 5407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -71.73,
        "Latitude": 451800000,
        "Longitude": -714400000,
        "Elevation (m)": 259.1,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "MCTAVISH",
        "Province": "QUEBEC",
        "Climate ID": "7024745",
        "Station ID": 10761,
        "WMO ID": 71612,
        "TC ID": "WTA",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 453017070,
        "Longitude": -733445000,
        "Elevation (m)": 72.8,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MEGANTIC A",
        "Province": "QUEBEC",
        "Climate ID": "7024800",
        "Station ID": 5408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -70.87,
        "Latitude": 453500000,
        "Longitude": -705200000,
        "Elevation (m)": 415.1,
        "First Year": 1939,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1962,
        "MLY First Year": 1939,
        "MLY Last Year": 1962
      },
      {
        "Name": "MELOCHEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7024858",
        "Station ID": 5409,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -73.95,
        "Latitude": 451900000,
        "Longitude": -735700000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "MERCIER",
        "Province": "QUEBEC",
        "Climate ID": "7024878",
        "Station ID": 5410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -73.78,
        "Latitude": 451900000,
        "Longitude": -734700000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "MILAN",
        "Province": "QUEBEC",
        "Climate ID": "7024920",
        "Station ID": 5411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -71.12,
        "Latitude": 453500000,
        "Longitude": -710700000,
        "Elevation (m)": 481.6,
        "First Year": 1949,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2015,
        "MLY First Year": 1949,
        "MLY Last Year": 2015
      },
      {
        "Name": "MOBILE UPPER AIR STATION-QUEBEC",
        "Province": "QUEBEC",
        "Climate ID": "7025000",
        "Station ID": 47888,
        "WMO ID": 71054,
        "TC ID": "EUQ",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -73.93,
        "Latitude": 452536000,
        "Longitude": -735544040,
        "Elevation (m)": 38,
        "First Year": 2014,
        "Last Year": 2021,
        "HLY First Year": 2014,
        "HLY Last Year": 2021,
        "DLY First Year": 2017,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOE'S RIVER",
        "Province": "QUEBEC",
        "Climate ID": "7025060",
        "Station ID": 5412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.25,
        "Longitude (Decimal Degrees)": -71.78,
        "Latitude": 451500000,
        "Longitude": -714700000,
        "Elevation (m)": 254.5,
        "First Year": 1963,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1981,
        "MLY First Year": 1963,
        "MLY Last Year": 1981
      },
      {
        "Name": "MONT MEGANTIC",
        "Province": "QUEBEC",
        "Climate ID": "7025212",
        "Station ID": 5413,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -71.15,
        "Latitude": 452700000,
        "Longitude": -710900000,
        "Elevation (m)": 1111,
        "First Year": 1978,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1989,
        "MLY First Year": 1978,
        "MLY Last Year": 1989
      },
      {
        "Name": "MONTREAL ADAC A",
        "Province": "QUEBEC",
        "Climate ID": "7025228",
        "Station ID": 8343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -73.55,
        "Latitude": 452900000,
        "Longitude": -733300000,
        "Elevation (m)": 21.3,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": 1974,
        "HLY Last Year": 1976,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONT-ORFORD",
        "Province": "QUEBEC",
        "Climate ID": "7025229",
        "Station ID": 10892,
        "WMO ID": null,
        "TC ID": "WHY",
        "Latitude (Decimal Degrees)": 45.31,
        "Longitude (Decimal Degrees)": -72.24,
        "Latitude": 451842360,
        "Longitude": -721426432,
        "Elevation (m)": 846.06,
        "First Year": 1993,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2007,
        "DLY First Year": 1993,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONTREAL ICE CONTROL",
        "Province": "QUEBEC",
        "Climate ID": "7025245",
        "Station ID": 5414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -73.5,
        "Latitude": 452800000,
        "Longitude": -733000000,
        "Elevation (m)": 14.3,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "MONTREAL/PIERRE ELLIOTT TRUDEAU INTL A",
        "Province": "QUEBEC",
        "Climate ID": "7025250",
        "Station ID": 5415,
        "WMO ID": 71627,
        "TC ID": "YUL",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -73.75,
        "Latitude": 452800000,
        "Longitude": -734500000,
        "Elevation (m)": 35.97,
        "First Year": 1941,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1941,
        "DLY Last Year": 2013,
        "MLY First Year": 1941,
        "MLY Last Year": 2013
      },
      {
        "Name": "MONTREAL INTL A",
        "Province": "QUEBEC",
        "Climate ID": "7025251",
        "Station ID": 51157,
        "WMO ID": 71627,
        "TC ID": "YUL",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -73.74,
        "Latitude": 452814000,
        "Longitude": -734427000,
        "Elevation (m)": 36,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONTREAL-EST",
        "Province": "QUEBEC",
        "Climate ID": "7025252",
        "Station ID": 26855,
        "WMO ID": null,
        "TC ID": "WPQ",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -73.55,
        "Latitude": 453805000,
        "Longitude": -733311000,
        "Elevation (m)": 50.4,
        "First Year": 1994,
        "Last Year": 2008,
        "HLY First Year": 1994,
        "HLY Last Year": 2008,
        "DLY First Year": 1994,
        "DLY Last Year": 2008,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONTREAL JAR BOT",
        "Province": "QUEBEC",
        "Climate ID": "7025257",
        "Station ID": 5416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -73.55,
        "Latitude": 453400000,
        "Longitude": -733300000,
        "Elevation (m)": 45.7,
        "First Year": 1948,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1989,
        "MLY First Year": 1948,
        "MLY Last Year": 1989
      },
      {
        "Name": "MONTREAL JEAN BREBEUF",
        "Province": "QUEBEC",
        "Climate ID": "7025260",
        "Station ID": 5417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -73.62,
        "Latitude": 453000000,
        "Longitude": -733700000,
        "Elevation (m)": 132.6,
        "First Year": 1956,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1985,
        "MLY First Year": 1956,
        "MLY Last Year": 1985
      },
      {
        "Name": "MONTREAL LAFONTAINE",
        "Province": "QUEBEC",
        "Climate ID": "7025267",
        "Station ID": 5418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -73.57,
        "Latitude": 453100000,
        "Longitude": -733400000,
        "Elevation (m)": 41.1,
        "First Year": 1971,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1992,
        "MLY First Year": 1971,
        "MLY Last Year": 1992
      },
      {
        "Name": "MONTREAL LOYOLA",
        "Province": "QUEBEC",
        "Climate ID": "7025270",
        "Station ID": 5419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -73.63,
        "Latitude": 452800000,
        "Longitude": -733800000,
        "Elevation (m)": 55.8,
        "First Year": 1964,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1964,
        "MLY First Year": 1964,
        "MLY Last Year": 1964
      },
      {
        "Name": "MONTREAL MCGILL",
        "Province": "QUEBEC",
        "Climate ID": "7025280",
        "Station ID": 5420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 453000000,
        "Longitude": -733500000,
        "Elevation (m)": 56.9,
        "First Year": 1871,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1993,
        "MLY First Year": 1871,
        "MLY Last Year": 1993
      },
      {
        "Name": "MONTREAL PERSILLIER",
        "Province": "QUEBEC",
        "Climate ID": "7025315",
        "Station ID": 5421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": null,
        "Longitude (Decimal Degrees)": null,
        "Latitude": null,
        "Longitude": null,
        "Elevation (m)": null,
        "First Year": 1948,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1950,
        "MLY First Year": 1948,
        "MLY Last Year": 1950
      },
      {
        "Name": "MONT ST HILAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7025328",
        "Station ID": 5422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -73.18,
        "Latitude": 453500000,
        "Longitude": -731100000,
        "Elevation (m)": 30.5,
        "First Year": 1976,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1979,
        "MLY First Year": 1976,
        "MLY Last Year": 1979
      },
      {
        "Name": "MONT ST HILAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7025330",
        "Station ID": 5423,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -73.08,
        "Latitude": 453300000,
        "Longitude": -730500000,
        "Elevation (m)": 173.7,
        "First Year": 1960,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1969,
        "MLY First Year": 1960,
        "MLY Last Year": 1969
      },
      {
        "Name": "MONT ST HILAIRE MCGILL",
        "Province": "QUEBEC",
        "Climate ID": "7025332",
        "Station ID": 5424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -73.17,
        "Latitude": 453300000,
        "Longitude": -731000000,
        "Elevation (m)": 210.3,
        "First Year": 1967,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1974,
        "MLY First Year": 1967,
        "MLY Last Year": 1973
      },
      {
        "Name": "NEW MEXICO",
        "Province": "QUEBEC",
        "Climate ID": "7025425",
        "Station ID": 5425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -71.48,
        "Latitude": 452100000,
        "Longitude": -712900000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "NICOLET",
        "Province": "QUEBEC",
        "Climate ID": "7025440",
        "Station ID": 5426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -72.62,
        "Latitude": 461200000,
        "Longitude": -723700000,
        "Elevation (m)": 30.4,
        "First Year": 1913,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2018,
        "MLY First Year": 1913,
        "MLY Last Year": 2018
      },
      {
        "Name": "NICOLET",
        "Province": "QUEBEC",
        "Climate ID": "7025442",
        "Station ID": 10732,
        "WMO ID": 71723,
        "TC ID": "WNQ",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -72.66,
        "Latitude": 461333000,
        "Longitude": -723926000,
        "Elevation (m)": 8,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH HATLEY",
        "Province": "QUEBEC",
        "Climate ID": "7025660",
        "Station ID": 5427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -71.95,
        "Latitude": 451700000,
        "Longitude": -715700000,
        "Elevation (m)": 243.8,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "NOTRE DAME DES BOIS",
        "Province": "QUEBEC",
        "Climate ID": "7025670",
        "Station ID": 5428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -71.08,
        "Latitude": 452400000,
        "Longitude": -710500000,
        "Elevation (m)": 502.9,
        "First Year": 1962,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2016,
        "MLY First Year": 1962,
        "MLY Last Year": 2016
      },
      {
        "Name": "ORMSTOWN",
        "Province": "QUEBEC",
        "Climate ID": "7025745",
        "Station ID": 5429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -74.05,
        "Latitude": 450700000,
        "Longitude": -740300000,
        "Elevation (m)": 45.7,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2018
      },
      {
        "Name": "PETIT LAC",
        "Province": "QUEBEC",
        "Climate ID": "7026005",
        "Station ID": 5430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -71.58,
        "Latitude": 452400000,
        "Longitude": -713500000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "PHILIPSBURG",
        "Province": "QUEBEC",
        "Climate ID": "7026040",
        "Station ID": 5431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -73.08,
        "Latitude": 450200000,
        "Longitude": -730500000,
        "Elevation (m)": 53.3,
        "First Year": 1950,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2015,
        "MLY First Year": 1950,
        "MLY Last Year": 2015
      },
      {
        "Name": "PIEDMONT",
        "Province": "QUEBEC",
        "Climate ID": "7026042",
        "Station ID": 42014,
        "WMO ID": null,
        "TC ID": "MPD",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -74.09,
        "Latitude": 455300000,
        "Longitude": -740532000,
        "Elevation (m)": 175,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PIERREVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7026043",
        "Station ID": 5432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.08,
        "Longitude (Decimal Degrees)": -72.83,
        "Latitude": 460500000,
        "Longitude": -725000000,
        "Elevation (m)": 15.2,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2018
      },
      {
        "Name": "PLESSISVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7026058",
        "Station ID": 5433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -71.75,
        "Latitude": 461200000,
        "Longitude": -714500000,
        "Elevation (m)": null,
        "First Year": 1947,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1948,
        "MLY First Year": 1947,
        "MLY Last Year": 1948
      },
      {
        "Name": "POINTE CLAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7026073",
        "Station ID": 5435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -73.8,
        "Latitude": 452800000,
        "Longitude": -734800000,
        "Elevation (m)": 41.1,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "POINTE DU MOULIN",
        "Province": "QUEBEC",
        "Climate ID": "702607F",
        "Station ID": 5434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -73.92,
        "Latitude": 452100000,
        "Longitude": -735500000,
        "Elevation (m)": 38.1,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "POSTE SARAGUAY",
        "Province": "QUEBEC",
        "Climate ID": "7026213",
        "Station ID": 5436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -73.75,
        "Latitude": 453000000,
        "Longitude": -734500000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "PRINCEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7026250",
        "Station ID": 5437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -71.92,
        "Latitude": 460900000,
        "Longitude": -715500000,
        "Elevation (m)": 160,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "RANBORO EST",
        "Province": "QUEBEC",
        "Climate ID": "7026344",
        "Station ID": 5438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -71.52,
        "Latitude": 451900000,
        "Longitude": -713100000,
        "Elevation (m)": 342.9,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1974,
        "MLY Last Year": 1981
      },
      {
        "Name": "RANBORO SUD",
        "Province": "QUEBEC",
        "Climate ID": "7026345",
        "Station ID": 5439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -71.53,
        "Latitude": 451900000,
        "Longitude": -713200000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "RICHMOND",
        "Province": "QUEBEC",
        "Climate ID": "7026465",
        "Station ID": 5440,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -72.13,
        "Latitude": 453800000,
        "Longitude": -720800000,
        "Elevation (m)": 123.1,
        "First Year": 1871,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 2022,
        "MLY First Year": 1871,
        "MLY Last Year": 2018
      },
      {
        "Name": "RIVIERE DES PRAIRIES",
        "Province": "QUEBEC",
        "Climate ID": "7026612",
        "Station ID": 5441,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -73.5,
        "Latitude": 454200000,
        "Longitude": -733000000,
        "Elevation (m)": 9,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "ROUGEMONT",
        "Province": "QUEBEC",
        "Climate ID": "7026700",
        "Station ID": 5442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -73.1,
        "Latitude": 452600000,
        "Longitude": -730600000,
        "Elevation (m)": 39.9,
        "First Year": 1956,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1985,
        "MLY First Year": 1962,
        "MLY Last Year": 1984
      },
      {
        "Name": "ROUGEMONT",
        "Province": "QUEBEC",
        "Climate ID": "7026702",
        "Station ID": 42011,
        "WMO ID": null,
        "TC ID": "MRG",
        "Latitude (Decimal Degrees)": 45.41,
        "Longitude (Decimal Degrees)": -73.05,
        "Latitude": 452444000,
        "Longitude": -730303000,
        "Elevation (m)": 54,
        "First Year": 2003,
        "Last Year": 2006,
        "HLY First Year": 2003,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROXTON FALLS",
        "Province": "QUEBEC",
        "Climate ID": "7026730",
        "Station ID": 5443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -72.53,
        "Latitude": 453300000,
        "Longitude": -723200000,
        "Elevation (m)": 129.5,
        "First Year": 1968,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1982,
        "MLY First Year": 1968,
        "MLY Last Year": 1982
      },
      {
        "Name": "RUSSELTOWN",
        "Province": "QUEBEC",
        "Climate ID": "7026732",
        "Station ID": 42010,
        "WMO ID": null,
        "TC ID": "MRU",
        "Latitude (Decimal Degrees)": 45.06,
        "Longitude (Decimal Degrees)": -73.8,
        "Latitude": 450329000,
        "Longitude": -734746000,
        "Elevation (m)": 65,
        "First Year": 2003,
        "Last Year": 2007,
        "HLY First Year": 2003,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SABREVOIS",
        "Province": "QUEBEC",
        "Climate ID": "7026734",
        "Station ID": 5444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -73.2,
        "Latitude": 451300000,
        "Longitude": -731200000,
        "Elevation (m)": 38.1,
        "First Year": 1975,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2021,
        "MLY First Year": 1975,
        "MLY Last Year": 2018
      },
      {
        "Name": "RUISSEAU CRANBERRY",
        "Province": "QUEBEC",
        "Climate ID": "7026735",
        "Station ID": 42643,
        "WMO ID": null,
        "TC ID": "MRY",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 450710000,
        "Longitude": -734046000,
        "Elevation (m)": 59,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SACRE COEUR DE MARIE",
        "Province": "QUEBEC",
        "Climate ID": "7026739",
        "Station ID": 5445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 460800000,
        "Longitude": -711000000,
        "Elevation (m)": 467.9,
        "First Year": 1964,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1985,
        "MLY First Year": 1964,
        "MLY Last Year": 1984
      },
      {
        "Name": "ST ADRIEN D IRLANDE",
        "Province": "QUEBEC",
        "Climate ID": "7026754",
        "Station ID": 5446,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -71.45,
        "Latitude": 460700000,
        "Longitude": -712700000,
        "Elevation (m)": 442,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST AMABLE",
        "Province": "QUEBEC",
        "Climate ID": "7026818",
        "Station ID": 5447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -73.3,
        "Latitude": 454000000,
        "Longitude": -731800000,
        "Elevation (m)": 41.1,
        "First Year": 1980,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2016,
        "MLY First Year": 1980,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST ANICET",
        "Province": "QUEBEC",
        "Climate ID": "7026836",
        "Station ID": 5448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -74.35,
        "Latitude": 450800000,
        "Longitude": -742100000,
        "Elevation (m)": 53.3,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2018
      },
      {
        "Name": "STE ANNE DE BELLEVUE",
        "Province": "QUEBEC",
        "Climate ID": "7026839",
        "Station ID": 5449,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -73.93,
        "Latitude": 452600000,
        "Longitude": -735600000,
        "Elevation (m)": 39,
        "First Year": 1969,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1992,
        "MLY First Year": 1969,
        "MLY Last Year": 1992
      },
      {
        "Name": "ST ANTOINE DE PONTBRIAND",
        "Province": "QUEBEC",
        "Climate ID": "7026885",
        "Station ID": 5450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -71.25,
        "Latitude": 460900000,
        "Longitude": -711500000,
        "Elevation (m)": 449.6,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST BENOIT LABRE",
        "Province": "QUEBEC",
        "Climate ID": "7026915",
        "Station ID": 5451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -70.8,
        "Latitude": 460300000,
        "Longitude": -704800000,
        "Elevation (m)": 304.8,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST BERNARD DE LACOLLE",
        "Province": "QUEBEC",
        "Climate ID": "7026916",
        "Station ID": 5452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.08,
        "Longitude (Decimal Degrees)": -73.38,
        "Latitude": 450500000,
        "Longitude": -732300000,
        "Elevation (m)": 49.3,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "STE BRIGIDE",
        "Province": "QUEBEC",
        "Climate ID": "7026918",
        "Station ID": 5453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -73.07,
        "Latitude": 451900000,
        "Longitude": -730400000,
        "Elevation (m)": 47.2,
        "First Year": 1977,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1982,
        "MLY First Year": 1977,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST BRUNO",
        "Province": "QUEBEC",
        "Climate ID": "7026920",
        "Station ID": 5454,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -73.35,
        "Latitude": 453300000,
        "Longitude": -732100000,
        "Elevation (m)": 61,
        "First Year": 1926,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1958,
        "MLY First Year": 1926,
        "MLY Last Year": 1958
      },
      {
        "Name": "SAINT-CAMILLE-DE-LELLIS",
        "Province": "QUEBEC",
        "Climate ID": "7026930",
        "Station ID": 55158,
        "WMO ID": 73105,
        "TC ID": "GSA",
        "Latitude (Decimal Degrees)": 46.49,
        "Longitude (Decimal Degrees)": -70.21,
        "Latitude": 462940047,
        "Longitude": -701225060,
        "Elevation (m)": 402,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STE CECILE",
        "Province": "QUEBEC",
        "Climate ID": "7026936",
        "Station ID": 5455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -70.93,
        "Latitude": 454000000,
        "Longitude": -705600000,
        "Elevation (m)": 518.2,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "STE-CLOTILDE",
        "Province": "QUEBEC",
        "Climate ID": "7027039",
        "Station ID": 10762,
        "WMO ID": 71614,
        "TC ID": "WIT",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 451002000,
        "Longitude": -734044000,
        "Elevation (m)": 53,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STE CLOTHILDE CDA",
        "Province": "QUEBEC",
        "Climate ID": "7027040",
        "Station ID": 5456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 451000000,
        "Longitude": -734100000,
        "Elevation (m)": 56.4,
        "First Year": 1937,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1990,
        "MLY First Year": 1937,
        "MLY Last Year": 1990
      },
      {
        "Name": "SAINT-CELESTIN",
        "Province": "QUEBEC",
        "Climate ID": "7027075",
        "Station ID": 42603,
        "WMO ID": null,
        "TC ID": "MSC",
        "Latitude (Decimal Degrees)": 46.21,
        "Longitude (Decimal Degrees)": -72.45,
        "Latitude": 461221000,
        "Longitude": -722645000,
        "Elevation (m)": 55,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAINT-CHRYSOSTOME",
        "Province": "QUEBEC",
        "Climate ID": "7027081",
        "Station ID": 42784,
        "WMO ID": null,
        "TC ID": "MYT",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -73.77,
        "Latitude": 450759042,
        "Longitude": -734609079,
        "Elevation (m)": 55,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": 2004,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST COME DE LINIERE",
        "Province": "QUEBEC",
        "Climate ID": "7027083",
        "Station ID": 5457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -70.52,
        "Latitude": 460300000,
        "Longitude": -703100000,
        "Elevation (m)": 243.8,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2018
      },
      {
        "Name": "SAINT-CONSTANT",
        "Province": "QUEBEC",
        "Climate ID": "7027084",
        "Station ID": 42783,
        "WMO ID": null,
        "TC ID": "MCT",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 451948010,
        "Longitude": -733448070,
        "Elevation (m)": 39,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": 2004,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST CONSTANT",
        "Province": "QUEBEC",
        "Climate ID": "7027085",
        "Station ID": 5458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 452200000,
        "Longitude": -733500000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "STE CROIX",
        "Province": "QUEBEC",
        "Climate ID": "7027088",
        "Station ID": 5459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.62,
        "Longitude (Decimal Degrees)": -71.78,
        "Latitude": 463700000,
        "Longitude": -714700000,
        "Elevation (m)": 70,
        "First Year": 1973,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1993,
        "MLY First Year": 1973,
        "MLY Last Year": 1993
      },
      {
        "Name": "STE DOROTHEE",
        "Province": "QUEBEC",
        "Climate ID": "7027110",
        "Station ID": 5460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -73.78,
        "Latitude": 453100000,
        "Longitude": -734700000,
        "Elevation (m)": 23,
        "First Year": 1963,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1997,
        "MLY First Year": 1963,
        "MLY Last Year": 1997
      },
      {
        "Name": "STE EDWIDGE",
        "Province": "QUEBEC",
        "Climate ID": "7027120",
        "Station ID": 5461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -71.68,
        "Latitude": 451200000,
        "Longitude": -714100000,
        "Elevation (m)": 381,
        "First Year": 1951,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1985,
        "MLY First Year": 1951,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST EPHREM",
        "Province": "QUEBEC",
        "Climate ID": "7027200",
        "Station ID": 5462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -70.97,
        "Latitude": 460400000,
        "Longitude": -705800000,
        "Elevation (m)": 312.4,
        "First Year": 1929,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 2022,
        "MLY First Year": 1929,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST FERDINAND",
        "Province": "QUEBEC",
        "Climate ID": "7027248",
        "Station ID": 5463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.1,
        "Longitude (Decimal Degrees)": -71.58,
        "Latitude": 460600000,
        "Longitude": -713500000,
        "Elevation (m)": 297,
        "First Year": 1965,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2014,
        "MLY First Year": 1965,
        "MLY Last Year": 2014
      },
      {
        "Name": "ST FLAVIEN",
        "Province": "QUEBEC",
        "Climate ID": "7027259",
        "Station ID": 5464,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -71.57,
        "Latitude": 462900000,
        "Longitude": -713400000,
        "Elevation (m)": 137.2,
        "First Year": 1963,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2016,
        "MLY First Year": 1963,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST FLAVIEN CDA FE",
        "Province": "QUEBEC",
        "Climate ID": "7027260",
        "Station ID": 5465,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.52,
        "Longitude (Decimal Degrees)": -71.6,
        "Latitude": 463100000,
        "Longitude": -713600000,
        "Elevation (m)": 109.7,
        "First Year": 1960,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1965,
        "MLY First Year": 1960,
        "MLY Last Year": 1965
      },
      {
        "Name": "ST FORTUNAT",
        "Province": "QUEBEC",
        "Climate ID": "7027264",
        "Station ID": 5466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -71.6,
        "Latitude": 455800000,
        "Longitude": -713600000,
        "Elevation (m)": 465,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE FRANCOISE ROMAINE",
        "Province": "QUEBEC",
        "Climate ID": "7027267",
        "Station ID": 5482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -71.93,
        "Latitude": 462900000,
        "Longitude": -715600000,
        "Elevation (m)": 91.4,
        "First Year": 1963,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1985,
        "MLY First Year": 1963,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST GEDEON",
        "Province": "QUEBEC",
        "Climate ID": "7027277",
        "Station ID": 5483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -70.62,
        "Latitude": 455200000,
        "Longitude": -703700000,
        "Elevation (m)": 281.9,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE GENEVIEVE",
        "Province": "QUEBEC",
        "Climate ID": "7027280",
        "Station ID": 5484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -73.85,
        "Latitude": 453000000,
        "Longitude": -735100000,
        "Elevation (m)": 22.9,
        "First Year": 1952,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2015,
        "MLY First Year": 1952,
        "MLY Last Year": 2015
      },
      {
        "Name": "ST GEORGES",
        "Province": "QUEBEC",
        "Climate ID": "7027283",
        "Station ID": 5485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -70.7,
        "Latitude": 460900000,
        "Longitude": -704200000,
        "Elevation (m)": 167.6,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST GUILLAUME",
        "Province": "QUEBEC",
        "Climate ID": "7027302",
        "Station ID": 5486,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -72.77,
        "Latitude": 455300000,
        "Longitude": -724600000,
        "Elevation (m)": 43.9,
        "First Year": 1963,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2015,
        "MLY First Year": 1963,
        "MLY Last Year": 2015
      },
      {
        "Name": "STE HELENE DE CHESTER",
        "Province": "QUEBEC",
        "Climate ID": "7027306",
        "Station ID": 5487,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -71.72,
        "Latitude": 460200000,
        "Longitude": -714300000,
        "Elevation (m)": 457.2,
        "First Year": 1965,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1973,
        "MLY First Year": 1965,
        "MLY Last Year": 1973
      },
      {
        "Name": "ST HERMENEGILDE",
        "Province": "QUEBEC",
        "Climate ID": "7027308",
        "Station ID": 5488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -71.7,
        "Latitude": 450700000,
        "Longitude": -714200000,
        "Elevation (m)": 480,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST HILAIRE DORSET",
        "Province": "QUEBEC",
        "Climate ID": "7027309",
        "Station ID": 5489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -70.87,
        "Latitude": 455200000,
        "Longitude": -705200000,
        "Elevation (m)": 506,
        "First Year": 1965,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1991,
        "MLY First Year": 1965,
        "MLY Last Year": 1991
      },
      {
        "Name": "MONTREAL/ST-HUBERT A",
        "Province": "QUEBEC",
        "Climate ID": "7027320",
        "Station ID": 5490,
        "WMO ID": 71371,
        "TC ID": "YHU",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -73.42,
        "Latitude": 453100000,
        "Longitude": -732500000,
        "Elevation (m)": 27.4,
        "First Year": 1928,
        "Last Year": 2015,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1928,
        "DLY Last Year": 2015,
        "MLY First Year": 1928,
        "MLY Last Year": 2015
      },
      {
        "Name": "MONTREAL/ST-HUBERT A",
        "Province": "QUEBEC",
        "Climate ID": "7027322",
        "Station ID": 30170,
        "WMO ID": 71371,
        "TC ID": "YHU",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -73.42,
        "Latitude": 453100000,
        "Longitude": -732500000,
        "Elevation (m)": 27.4,
        "First Year": 2004,
        "Last Year": 2010,
        "HLY First Year": 2004,
        "HLY Last Year": 2010,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2010
      },
      {
        "Name": "MONTREAL/ST-HUBERT",
        "Province": "QUEBEC",
        "Climate ID": "7027329",
        "Station ID": 48374,
        "WMO ID": 71371,
        "TC ID": "YHU",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -73.42,
        "Latitude": 453103000,
        "Longitude": -732501000,
        "Elevation (m)": 27.4,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST HYACINTHE",
        "Province": "QUEBEC",
        "Climate ID": "7027360",
        "Station ID": 5491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -72.95,
        "Latitude": 453800000,
        "Longitude": -725700000,
        "Elevation (m)": 31.1,
        "First Year": 1890,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1890,
        "DLY Last Year": 1979,
        "MLY First Year": 1890,
        "MLY Last Year": 1979
      },
      {
        "Name": "ST HYACINTHE 2",
        "Province": "QUEBEC",
        "Climate ID": "7027361",
        "Station ID": 5492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -72.92,
        "Latitude": 453400000,
        "Longitude": -725500000,
        "Elevation (m)": 33,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST ISIDORE D'AUCKLAND",
        "Province": "QUEBEC",
        "Climate ID": "7027372",
        "Station ID": 5493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -71.52,
        "Latitude": 451600000,
        "Longitude": -713100000,
        "Elevation (m)": 393.5,
        "First Year": 1964,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1981,
        "MLY First Year": 1964,
        "MLY Last Year": 1981
      },
      {
        "Name": "ST JACQUES DE LEEDS",
        "Province": "QUEBEC",
        "Climate ID": "7027382",
        "Station ID": 5494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -71.37,
        "Latitude": 461600000,
        "Longitude": -712200000,
        "Elevation (m)": 290,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST JEAN",
        "Province": "QUEBEC",
        "Climate ID": "7027390",
        "Station ID": 5495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -73.27,
        "Latitude": 451800000,
        "Longitude": -731600000,
        "Elevation (m)": 41.5,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1964
      },
      {
        "Name": "ST JEAN CHRYSOSTOME",
        "Province": "QUEBEC",
        "Climate ID": "7027391",
        "Station ID": 5496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.72,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 464300000,
        "Longitude": -711300000,
        "Elevation (m)": 53.3,
        "First Year": 1968,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1985,
        "MLY First Year": 1969,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST JEAN DE BREBEUF",
        "Province": "QUEBEC",
        "Climate ID": "7027392",
        "Station ID": 5497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -71.45,
        "Latitude": 461100000,
        "Longitude": -712700000,
        "Elevation (m)": 263.7,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST JULIEN",
        "Province": "QUEBEC",
        "Climate ID": "7027426",
        "Station ID": 5498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46,
        "Longitude (Decimal Degrees)": -71.53,
        "Latitude": 460000000,
        "Longitude": -713200000,
        "Elevation (m)": 452.6,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST LAMBERT",
        "Province": "QUEBEC",
        "Climate ID": "7027437",
        "Station ID": 10939,
        "WMO ID": null,
        "TC ID": "WLH",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -73.52,
        "Latitude": 453000000,
        "Longitude": -733100000,
        "Elevation (m)": 6.7,
        "First Year": 1994,
        "Last Year": 1994,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST LAURENT (COLLEGE)",
        "Province": "QUEBEC",
        "Climate ID": "7027440",
        "Station ID": 5499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -73.67,
        "Latitude": 453100000,
        "Longitude": -734000000,
        "Elevation (m)": 38.1,
        "First Year": 1931,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1967,
        "MLY First Year": 1931,
        "MLY Last Year": 1967
      },
      {
        "Name": "SAINT-GERMAIN-DE-GRANTHAM",
        "Province": "QUEBEC",
        "Climate ID": "7027470",
        "Station ID": 47587,
        "WMO ID": 71967,
        "TC ID": "MSI",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -72.54,
        "Latitude": 454931200,
        "Longitude": -723213100,
        "Elevation (m)": 85,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAINT-LOUIS",
        "Province": "QUEBEC",
        "Climate ID": "7027490",
        "Station ID": 43191,
        "WMO ID": null,
        "TC ID": "MLI",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -73,
        "Latitude": 455212018,
        "Longitude": -730016319,
        "Elevation (m)": 31,
        "First Year": 2005,
        "Last Year": 2007,
        "HLY First Year": 2005,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST LUDGER",
        "Province": "QUEBEC",
        "Climate ID": "7027516",
        "Station ID": 5500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -70.68,
        "Latitude": 454500000,
        "Longitude": -704100000,
        "Elevation (m)": 335,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1964,
        "MLY Last Year": 2018
      },
      {
        "Name": "STE MADELEINE",
        "Province": "QUEBEC",
        "Climate ID": "7027517",
        "Station ID": 5501,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -73.13,
        "Latitude": 453700000,
        "Longitude": -730800000,
        "Elevation (m)": 30,
        "First Year": 1979,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2016,
        "MLY First Year": 1979,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST MALO D AUCKLAND",
        "Province": "QUEBEC",
        "Climate ID": "7027520",
        "Station ID": 5502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 451200000,
        "Longitude": -713000000,
        "Elevation (m)": 564,
        "First Year": 1949,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2022,
        "MLY First Year": 1949,
        "MLY Last Year": 2018
      },
      {
        "Name": "STE MARTINE",
        "Province": "QUEBEC",
        "Climate ID": "7027540",
        "Station ID": 5503,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -73.85,
        "Latitude": 451300000,
        "Longitude": -735100000,
        "Elevation (m)": 38.1,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST MATHIAS",
        "Province": "QUEBEC",
        "Climate ID": "7027550",
        "Station ID": 5504,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -71.4,
        "Latitude": 451800000,
        "Longitude": -712400000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "ST MATHIEU LAPRAIRIE",
        "Province": "QUEBEC",
        "Climate ID": "7027560",
        "Station ID": 5505,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -73.53,
        "Latitude": 452100000,
        "Longitude": -733200000,
        "Elevation (m)": 30.5,
        "First Year": 1948,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1982,
        "MLY First Year": 1948,
        "MLY Last Year": 1982
      },
      {
        "Name": "SAINT-MICHEL-DE-NAPIERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7027570",
        "Station ID": 42604,
        "WMO ID": null,
        "TC ID": "MSX",
        "Latitude (Decimal Degrees)": 45.19,
        "Longitude (Decimal Degrees)": -73.62,
        "Latitude": 451113000,
        "Longitude": -733727000,
        "Elevation (m)": 59,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": 2004,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST NAZAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7027588",
        "Station ID": 5506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -72.62,
        "Latitude": 454400000,
        "Longitude": -723700000,
        "Elevation (m)": 68.6,
        "First Year": 1973,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2021,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST ODILON",
        "Province": "QUEBEC",
        "Climate ID": "7027593",
        "Station ID": 5507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -70.65,
        "Latitude": 462100000,
        "Longitude": -703900000,
        "Elevation (m)": 358.1,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST PIERRE BAPTISTE",
        "Province": "QUEBEC",
        "Climate ID": "7027653",
        "Station ID": 5508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -71.63,
        "Latitude": 461200000,
        "Longitude": -713800000,
        "Elevation (m)": 289.6,
        "First Year": 1965,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1982,
        "MLY First Year": 1965,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST PIERRE DE BROUGHTON",
        "Province": "QUEBEC",
        "Climate ID": "7027656",
        "Station ID": 5509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 461500000,
        "Longitude": -711300000,
        "Elevation (m)": 365.8,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST PROSPER",
        "Province": "QUEBEC",
        "Climate ID": "7027660",
        "Station ID": 5510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -70.5,
        "Latitude": 461300000,
        "Longitude": -703000000,
        "Elevation (m)": 281.9,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST REMI",
        "Province": "QUEBEC",
        "Climate ID": "7027690",
        "Station ID": 5511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -73.6,
        "Latitude": 451700000,
        "Longitude": -733600000,
        "Elevation (m)": 53.3,
        "First Year": 1963,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1991,
        "MLY First Year": 1963,
        "MLY Last Year": 1991
      },
      {
        "Name": "ST ROMUALD",
        "Province": "QUEBEC",
        "Climate ID": "7027700",
        "Station ID": 5512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.77,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 464600000,
        "Longitude": -711300000,
        "Elevation (m)": 38.1,
        "First Year": 1963,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1968,
        "MLY First Year": 1963,
        "MLY Last Year": 1968
      },
      {
        "Name": "STE ROSALIE",
        "Province": "QUEBEC",
        "Climate ID": "7027718",
        "Station ID": 5513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -72.87,
        "Latitude": 453700000,
        "Longitude": -725200000,
        "Elevation (m)": 42.7,
        "First Year": 1968,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1982,
        "MLY First Year": 1968,
        "MLY Last Year": 1982
      },
      {
        "Name": "SAINTE-SABINE",
        "Province": "QUEBEC",
        "Climate ID": "7027720",
        "Station ID": 42012,
        "WMO ID": null,
        "TC ID": "MSB",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -73.02,
        "Latitude": 451324000,
        "Longitude": -730124000,
        "Elevation (m)": 49,
        "First Year": 2003,
        "Last Year": 2006,
        "HLY First Year": 2003,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST SEBASTIEN",
        "Province": "QUEBEC",
        "Climate ID": "7027725",
        "Station ID": 5514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -70.95,
        "Latitude": 454600000,
        "Longitude": -705700000,
        "Elevation (m)": 442,
        "First Year": 1963,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2018,
        "MLY First Year": 1963,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST SEBASTIEN CDA FE",
        "Province": "QUEBEC",
        "Climate ID": "7027730",
        "Station ID": 5515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -73.15,
        "Latitude": 450800000,
        "Longitude": -730900000,
        "Elevation (m)": 61,
        "First Year": 1960,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1962,
        "MLY First Year": 1960,
        "MLY Last Year": 1961
      },
      {
        "Name": "ST SEVERIN",
        "Province": "QUEBEC",
        "Climate ID": "7027733",
        "Station ID": 5516,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -71.05,
        "Latitude": 462000000,
        "Longitude": -710300000,
        "Elevation (m)": 442,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2017
      },
      {
        "Name": "STE SOPHIE",
        "Province": "QUEBEC",
        "Climate ID": "7027737",
        "Station ID": 5517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -71.7,
        "Latitude": 460900000,
        "Longitude": -714200000,
        "Elevation (m)": 312.4,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST SYLVESTRE",
        "Province": "QUEBEC",
        "Climate ID": "7027740",
        "Station ID": 5518,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -71.12,
        "Latitude": 462000000,
        "Longitude": -710700000,
        "Elevation (m)": 457.2,
        "First Year": 1957,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1964,
        "MLY First Year": 1962,
        "MLY Last Year": 1964
      },
      {
        "Name": "ST THEOPHILE",
        "Province": "QUEBEC",
        "Climate ID": "7027750",
        "Station ID": 5519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -70.48,
        "Latitude": 455600000,
        "Longitude": -702900000,
        "Elevation (m)": 395,
        "First Year": 1950,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2018,
        "MLY First Year": 1950,
        "MLY Last Year": 2017
      },
      {
        "Name": "ST VALERIEN",
        "Province": "QUEBEC",
        "Climate ID": "7027772",
        "Station ID": 5520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -72.67,
        "Latitude": 453400000,
        "Longitude": -724000000,
        "Elevation (m)": 91,
        "First Year": 1968,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1994,
        "MLY First Year": 1968,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE VICTOIRE",
        "Province": "QUEBEC",
        "Climate ID": "7027780",
        "Station ID": 5521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -73.08,
        "Latitude": 455700000,
        "Longitude": -730500000,
        "Elevation (m)": 22.9,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "ST WENCESLAS",
        "Province": "QUEBEC",
        "Climate ID": "7027783",
        "Station ID": 5522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -72.33,
        "Latitude": 461000000,
        "Longitude": -722000000,
        "Elevation (m)": 83.8,
        "First Year": 1979,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2018,
        "MLY First Year": 1979,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST ZACHARIE",
        "Province": "QUEBEC",
        "Climate ID": "7027785",
        "Station ID": 5523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -70.38,
        "Latitude": 460700000,
        "Longitude": -702300000,
        "Elevation (m)": 480.1,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST ZEPHIRIN",
        "Province": "QUEBEC",
        "Climate ID": "7027787",
        "Station ID": 5524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -72.58,
        "Latitude": 460400000,
        "Longitude": -723500000,
        "Elevation (m)": 51.8,
        "First Year": 1975,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1994,
        "MLY First Year": 1975,
        "MLY Last Year": 1994
      },
      {
        "Name": "SAWYERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7027800",
        "Station ID": 5525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -71.57,
        "Latitude": 452000000,
        "Longitude": -713400000,
        "Elevation (m)": 276.5,
        "First Year": 1952,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1981,
        "MLY First Year": 1952,
        "MLY Last Year": 1981
      },
      {
        "Name": "SAWYERVILLE NORD",
        "Province": "QUEBEC",
        "Climate ID": "7027802",
        "Station ID": 5526,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.53,
        "Latitude": 452200000,
        "Longitude": -713200000,
        "Elevation (m)": 345.9,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2018
      },
      {
        "Name": "SCOTT",
        "Province": "QUEBEC",
        "Climate ID": "7027840",
        "Station ID": 5527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -71.08,
        "Latitude": 463000000,
        "Longitude": -710500000,
        "Elevation (m)": 144.8,
        "First Year": 1950,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2022,
        "MLY First Year": 1950,
        "MLY Last Year": 2018
      },
      {
        "Name": "SHERBROOKE",
        "Province": "QUEBEC",
        "Climate ID": "7028120",
        "Station ID": 5528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -71.9,
        "Latitude": 452400000,
        "Longitude": -715400000,
        "Elevation (m)": 181.4,
        "First Year": 1900,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1900,
        "DLY Last Year": 1972,
        "MLY First Year": 1900,
        "MLY Last Year": 1972
      },
      {
        "Name": "SHERBROOKE",
        "Province": "QUEBEC",
        "Climate ID": "7028121",
        "Station ID": 5529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.93,
        "Latitude": 452200000,
        "Longitude": -715600000,
        "Elevation (m)": 254.5,
        "First Year": 1975,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1985,
        "MLY First Year": 1975,
        "MLY Last Year": 1985
      },
      {
        "Name": "SHERBROOKE",
        "Province": "QUEBEC",
        "Climate ID": "7028123",
        "Station ID": 48371,
        "WMO ID": 71610,
        "TC ID": "YSC",
        "Latitude (Decimal Degrees)": 45.44,
        "Longitude (Decimal Degrees)": -71.69,
        "Latitude": 452619000,
        "Longitude": -714129000,
        "Elevation (m)": 241.4,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SHERBROOKE A",
        "Province": "QUEBEC",
        "Climate ID": "7028124",
        "Station ID": 5530,
        "WMO ID": 71610,
        "TC ID": "YSC",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -71.68,
        "Latitude": 452600000,
        "Longitude": -714100000,
        "Elevation (m)": 241.4,
        "First Year": 1962,
        "Last Year": 2016,
        "HLY First Year": 1962,
        "HLY Last Year": 2005,
        "DLY First Year": 1962,
        "DLY Last Year": 2016,
        "MLY First Year": 1962,
        "MLY Last Year": 2004
      },
      {
        "Name": "SHERBROOKE A",
        "Province": "QUEBEC",
        "Climate ID": "7028126",
        "Station ID": 30171,
        "WMO ID": null,
        "TC ID": "GSC",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -71.68,
        "Latitude": 452600000,
        "Longitude": -714100000,
        "Elevation (m)": 241.4,
        "First Year": 2004,
        "Last Year": 2010,
        "HLY First Year": 2004,
        "HLY Last Year": 2009,
        "DLY First Year": 2005,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "SHERBROOKE (UNIVERSITE)",
        "Province": "QUEBEC",
        "Climate ID": "7028128",
        "Station ID": 5531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -71.93,
        "Latitude": 452300000,
        "Longitude": -715600000,
        "Elevation (m)": 244.1,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "SOREL",
        "Province": "QUEBEC",
        "Climate ID": "7028200",
        "Station ID": 5532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -73.12,
        "Latitude": 460200000,
        "Longitude": -730700000,
        "Elevation (m)": 14.6,
        "First Year": 1914,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2022,
        "MLY First Year": 1914,
        "MLY Last Year": 2018
      },
      {
        "Name": "SOUTH DURHAM",
        "Province": "QUEBEC",
        "Climate ID": "7028210",
        "Station ID": 5533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -72.28,
        "Latitude": 453700000,
        "Longitude": -721700000,
        "Elevation (m)": 195.1,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "SOUTH DURHAM",
        "Province": "QUEBEC",
        "Climate ID": "7028211",
        "Station ID": 5534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -72.35,
        "Latitude": 453800000,
        "Longitude": -722100000,
        "Elevation (m)": 190.5,
        "First Year": 1968,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1981,
        "MLY First Year": 1968,
        "MLY Last Year": 1981
      },
      {
        "Name": "STANHOPE",
        "Province": "QUEBEC",
        "Climate ID": "7028240",
        "Station ID": 5535,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -71.78,
        "Latitude": 450100000,
        "Longitude": -714700000,
        "Elevation (m)": 388.6,
        "First Year": 1954,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1984,
        "MLY First Year": 1954,
        "MLY Last Year": 1984
      },
      {
        "Name": "STANSTEAD",
        "Province": "QUEBEC",
        "Climate ID": "7028280",
        "Station ID": 5536,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -72.1,
        "Latitude": 450100000,
        "Longitude": -720600000,
        "Elevation (m)": 320,
        "First Year": 1948,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1991,
        "MLY First Year": 1948,
        "MLY Last Year": 1991
      },
      {
        "Name": "SUTTON",
        "Province": "QUEBEC",
        "Climate ID": "7028290",
        "Station ID": 5537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -72.62,
        "Latitude": 450700000,
        "Longitude": -723700000,
        "Elevation (m)": 180.4,
        "First Year": 1960,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1972,
        "MLY First Year": 1960,
        "MLY Last Year": 1972
      },
      {
        "Name": "SUTTON",
        "Province": "QUEBEC",
        "Climate ID": "7028292",
        "Station ID": 5538,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -72.68,
        "Latitude": 450400000,
        "Longitude": -724100000,
        "Elevation (m)": 243.8,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2018
      },
      {
        "Name": "SUTTON JONCTION",
        "Province": "QUEBEC",
        "Climate ID": "7028295",
        "Station ID": 5539,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -72.63,
        "Latitude": 450900000,
        "Longitude": -723800000,
        "Elevation (m)": 213.4,
        "First Year": 1963,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1985,
        "MLY First Year": 1963,
        "MLY Last Year": 1985
      },
      {
        "Name": "SUTTON JUNCTION (AUT)",
        "Province": "QUEBEC",
        "Climate ID": "7028296",
        "Station ID": 5540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -72.58,
        "Latitude": 450900000,
        "Longitude": -723500000,
        "Elevation (m)": 238,
        "First Year": 1971,
        "Last Year": 1986,
        "HLY First Year": 1977,
        "HLY Last Year": 1986,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "THETFORD MINES",
        "Province": "QUEBEC",
        "Climate ID": "7028440",
        "Station ID": 5541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -71.32,
        "Latitude": 460400000,
        "Longitude": -711900000,
        "Elevation (m)": 310.9,
        "First Year": 1922,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1965,
        "MLY First Year": 1922,
        "MLY Last Year": 1965
      },
      {
        "Name": "THETFORD MINES",
        "Province": "QUEBEC",
        "Climate ID": "7028441",
        "Station ID": 5542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.1,
        "Longitude (Decimal Degrees)": -71.35,
        "Latitude": 460600000,
        "Longitude": -712100000,
        "Elevation (m)": 381,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 2016
      },
      {
        "Name": "THETFORD MINES RCS",
        "Province": "QUEBEC",
        "Climate ID": "7028442",
        "Station ID": 45309,
        "WMO ID": 71494,
        "TC ID": "MTH",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -71.27,
        "Latitude": 460257002,
        "Longitude": -711558004,
        "Elevation (m)": 430,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UPTON",
        "Province": "QUEBEC",
        "Climate ID": "7028570",
        "Station ID": 5543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -72.68,
        "Latitude": 453900000,
        "Longitude": -724100000,
        "Elevation (m)": 61,
        "First Year": 1968,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1982,
        "MLY First Year": 1968,
        "MLY Last Year": 1982
      },
      {
        "Name": "VALCOURT",
        "Province": "QUEBEC",
        "Climate ID": "7028580",
        "Station ID": 5544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -72.32,
        "Latitude": 452900000,
        "Longitude": -721900000,
        "Elevation (m)": 205.7,
        "First Year": 1968,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1982,
        "MLY First Year": 1968,
        "MLY Last Year": 1982
      },
      {
        "Name": "VALLEE JONCTION",
        "Province": "QUEBEC",
        "Climate ID": "7028676",
        "Station ID": 5545,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -70.93,
        "Latitude": 462300000,
        "Longitude": -705600000,
        "Elevation (m)": 152.4,
        "First Year": 1965,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2018,
        "MLY First Year": 1965,
        "MLY Last Year": 2017
      },
      {
        "Name": "VALLEYFIELD",
        "Province": "QUEBEC",
        "Climate ID": "7028680",
        "Station ID": 5562,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -74.1,
        "Latitude": 451700000,
        "Longitude": -740600000,
        "Elevation (m)": 45.7,
        "First Year": 1952,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2019,
        "MLY First Year": 1952,
        "MLY Last Year": 2015
      },
      {
        "Name": "VALLEYFIELD (ECLUSE)",
        "Province": "QUEBEC",
        "Climate ID": "7028682",
        "Station ID": 10952,
        "WMO ID": null,
        "TC ID": "WVE",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -74.1,
        "Latitude": 451400000,
        "Longitude": -740600000,
        "Elevation (m)": 46.3,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VAL RACINE",
        "Province": "QUEBEC",
        "Climate ID": "7028695",
        "Station ID": 5563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -71.07,
        "Latitude": 452900000,
        "Longitude": -710400000,
        "Elevation (m)": 495,
        "First Year": 1962,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1991,
        "MLY First Year": 1962,
        "MLY Last Year": 1991
      },
      {
        "Name": "VERCHERES",
        "Province": "QUEBEC",
        "Climate ID": "7028700",
        "Station ID": 5564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -73.37,
        "Latitude": 454600000,
        "Longitude": -732200000,
        "Elevation (m)": 21,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "VICTORIAVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7028720",
        "Station ID": 5565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -71.97,
        "Latitude": 460300000,
        "Longitude": -715800000,
        "Elevation (m)": 137.2,
        "First Year": 1949,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1984,
        "MLY First Year": 1949,
        "MLY Last Year": 1984
      },
      {
        "Name": "BEAUCEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7028754",
        "Station ID": 26777,
        "WMO ID": 71323,
        "TC ID": "WHV",
        "Latitude (Decimal Degrees)": 46.21,
        "Longitude (Decimal Degrees)": -70.79,
        "Latitude": 461218000,
        "Longitude": -704706000,
        "Elevation (m)": 229.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VIMY RIDGE",
        "Province": "QUEBEC",
        "Climate ID": "7028770",
        "Station ID": 5566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46,
        "Longitude (Decimal Degrees)": -71.42,
        "Latitude": 460000000,
        "Longitude": -712500000,
        "Elevation (m)": 365.8,
        "First Year": 1965,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1978,
        "MLY First Year": 1965,
        "MLY Last Year": 1978
      },
      {
        "Name": "WARDEN",
        "Province": "QUEBEC",
        "Climate ID": "7028890",
        "Station ID": 5567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -72.5,
        "Latitude": 452300000,
        "Longitude": -723000000,
        "Elevation (m)": 198.1,
        "First Year": 1968,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1991,
        "MLY First Year": 1968,
        "MLY Last Year": 1991
      },
      {
        "Name": "WATOPEKA",
        "Province": "QUEBEC",
        "Climate ID": "7028900",
        "Station ID": 5568,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -71.75,
        "Latitude": 453800000,
        "Longitude": -714500000,
        "Elevation (m)": 152.4,
        "First Year": 1942,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1968,
        "MLY First Year": 1942,
        "MLY Last Year": 1968
      },
      {
        "Name": "WEST DITTON",
        "Province": "QUEBEC",
        "Climate ID": "7028906",
        "Station ID": 5569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -71.3,
        "Latitude": 452400000,
        "Longitude": -711800000,
        "Elevation (m)": 512,
        "First Year": 1965,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1993,
        "MLY First Year": 1965,
        "MLY Last Year": 1993
      },
      {
        "Name": "WOBURN",
        "Province": "QUEBEC",
        "Climate ID": "7028945",
        "Station ID": 5570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -70.87,
        "Latitude": 452300000,
        "Longitude": -705200000,
        "Elevation (m)": 434.3,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "WOBURN",
        "Province": "QUEBEC",
        "Climate ID": "7028946",
        "Station ID": 5571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -70.87,
        "Latitude": 452300000,
        "Longitude": -705200000,
        "Elevation (m)": 396,
        "First Year": 1973,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1997,
        "MLY First Year": 1973,
        "MLY Last Year": 1997
      },
      {
        "Name": "COTE-STE-CATHERINE",
        "Province": "QUEBEC",
        "Climate ID": "702A9MD",
        "Station ID": 10949,
        "WMO ID": null,
        "TC ID": "WCS",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -73.57,
        "Latitude": 452500000,
        "Longitude": -733400000,
        "Elevation (m)": 16.6,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DAVELUYVILLE",
        "Province": "QUEBEC",
        "Climate ID": "702A9ND",
        "Station ID": 5297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -72.2,
        "Latitude": 461100000,
        "Longitude": -721200000,
        "Elevation (m)": 83.8,
        "First Year": 1966,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1994,
        "MLY First Year": 1966,
        "MLY Last Year": 1994
      },
      {
        "Name": "EATON",
        "Province": "QUEBEC",
        "Climate ID": "702BC0E",
        "Station ID": 5298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -71.6,
        "Latitude": 452200000,
        "Longitude": -713600000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "FABREVILLE",
        "Province": "QUEBEC",
        "Climate ID": "702BL09",
        "Station ID": 5299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -73.82,
        "Latitude": 453500000,
        "Longitude": -734900000,
        "Elevation (m)": 30.5,
        "First Year": 1977,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1984,
        "MLY First Year": 1977,
        "MLY Last Year": 1984
      },
      {
        "Name": "LAC MEMPHREMAGOG",
        "Province": "QUEBEC",
        "Climate ID": "702CFGG",
        "Station ID": 10801,
        "WMO ID": null,
        "TC ID": "WTT",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -72.17,
        "Latitude": 451600000,
        "Longitude": -721000000,
        "Elevation (m)": 208.7,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STE-ANNE-DE-BELLEVUE 1",
        "Province": "QUEBEC",
        "Climate ID": "702FHL8",
        "Station ID": 10873,
        "WMO ID": 71377,
        "TC ID": "WVQ",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -73.93,
        "Latitude": 452538000,
        "Longitude": -735545000,
        "Elevation (m)": 39,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST-ANICET 1",
        "Province": "QUEBEC",
        "Climate ID": "702FQLF",
        "Station ID": 10982,
        "WMO ID": 71712,
        "TC ID": "WBZ",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -74.29,
        "Latitude": 450715000,
        "Longitude": -741722000,
        "Elevation (m)": 49.1,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST CAMILLE WOLFE",
        "Province": "QUEBEC",
        "Climate ID": "702FR30",
        "Station ID": 5300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -71.73,
        "Latitude": 454000000,
        "Longitude": -714400000,
        "Elevation (m)": 268.2,
        "First Year": 1975,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2022,
        "MLY First Year": 1975,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST FRANCOIS DE LAVAL",
        "Province": "QUEBEC",
        "Climate ID": "702G266",
        "Station ID": 5302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -73.58,
        "Latitude": 454000000,
        "Longitude": -733500000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1984
      },
      {
        "Name": "SAWYER BROOK",
        "Province": "QUEBEC",
        "Climate ID": "702GGR7",
        "Station ID": 5301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -71.62,
        "Latitude": 452000000,
        "Longitude": -713700000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "SHERMAN BROOK NORTH",
        "Province": "QUEBEC",
        "Climate ID": "702HACH",
        "Station ID": 5303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 452700000,
        "Longitude": -713000000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "STATTON BROOK NORTH",
        "Province": "QUEBEC",
        "Climate ID": "702HBHA",
        "Station ID": 5304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -71.48,
        "Latitude": 452500000,
        "Longitude": -712900000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "SUCKER POND NORTH",
        "Province": "QUEBEC",
        "Climate ID": "702HBHG",
        "Station ID": 5305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -71.6,
        "Latitude": 451800000,
        "Longitude": -713600000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "EATON RIVER NORTH",
        "Province": "QUEBEC",
        "Climate ID": "702K30E",
        "Station ID": 5306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -71.5,
        "Latitude": 451400000,
        "Longitude": -713000000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "L'ACADIE",
        "Province": "QUEBEC",
        "Climate ID": "702LED4",
        "Station ID": 10843,
        "WMO ID": 71372,
        "TC ID": "WIZ",
        "Latitude (Decimal Degrees)": 45.29,
        "Longitude (Decimal Degrees)": -73.35,
        "Latitude": 451738000,
        "Longitude": -732058000,
        "Elevation (m)": 43.8,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAUZON (AUT)",
        "Province": "QUEBEC",
        "Climate ID": "702MKNL",
        "Station ID": 10248,
        "WMO ID": null,
        "TC ID": "WLU",
        "Latitude (Decimal Degrees)": 46.83,
        "Longitude (Decimal Degrees)": -71.16,
        "Latitude": 464955000,
        "Longitude": -710931000,
        "Elevation (m)": 20,
        "First Year": 1994,
        "Last Year": 2007,
        "HLY First Year": 1994,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONTREAL/PIERRE ELLIOTT TRUDEAU INTL",
        "Province": "QUEBEC",
        "Climate ID": "702S006",
        "Station ID": 30165,
        "WMO ID": 71183,
        "TC ID": "WTQ",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -73.74,
        "Latitude": 452804000,
        "Longitude": -734430000,
        "Elevation (m)": 32.1,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ANGERS",
        "Province": "QUEBEC",
        "Climate ID": "7030170",
        "Station ID": 5574,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -75.55,
        "Latitude": 453300000,
        "Longitude": -753300000,
        "Elevation (m)": 91,
        "First Year": 1962,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2022,
        "MLY First Year": 1962,
        "MLY Last Year": 2018
      },
      {
        "Name": "ARUNDEL",
        "Province": "QUEBEC",
        "Climate ID": "7030310",
        "Station ID": 5575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -74.62,
        "Latitude": 455700000,
        "Longitude": -743700000,
        "Elevation (m)": 191.4,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2017
      },
      {
        "Name": "BARK LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7030447",
        "Station ID": 5576,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -75.33,
        "Latitude": 463000000,
        "Longitude": -752000000,
        "Elevation (m)": 364.2,
        "First Year": 1912,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1929,
        "MLY First Year": 1912,
        "MLY Last Year": 1929
      },
      {
        "Name": "BARRAGE MERCIER",
        "Province": "QUEBEC",
        "Climate ID": "7030457",
        "Station ID": 5577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.72,
        "Longitude (Decimal Degrees)": -75.98,
        "Latitude": 464300000,
        "Longitude": -755900000,
        "Elevation (m)": 236.2,
        "First Year": 1927,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1980,
        "MLY First Year": 1927,
        "MLY Last Year": 1980
      },
      {
        "Name": "BARRAGE MITCHINAMECUS",
        "Province": "QUEBEC",
        "Climate ID": "7030462",
        "Station ID": 5578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.22,
        "Longitude (Decimal Degrees)": -75.17,
        "Latitude": 471300000,
        "Longitude": -751000000,
        "Elevation (m)": 390.1,
        "First Year": 1941,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1967,
        "MLY First Year": 1941,
        "MLY Last Year": 1967
      },
      {
        "Name": "BARRIERE QUINN",
        "Province": "QUEBEC",
        "Climate ID": "7030475",
        "Station ID": 5579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.72,
        "Longitude (Decimal Degrees)": -76.07,
        "Latitude": 464300000,
        "Longitude": -760400000,
        "Elevation (m)": 256.3,
        "First Year": 1963,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1973,
        "MLY First Year": 1963,
        "MLY Last Year": 1973
      },
      {
        "Name": "BELL FALLS",
        "Province": "QUEBEC",
        "Climate ID": "7030640",
        "Station ID": 5580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -74.68,
        "Latitude": 454600000,
        "Longitude": -744100000,
        "Elevation (m)": 121.9,
        "First Year": 1919,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1994,
        "MLY First Year": 1919,
        "MLY Last Year": 1994
      },
      {
        "Name": "BLANCHE",
        "Province": "QUEBEC",
        "Climate ID": "7030810",
        "Station ID": 5581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -75.33,
        "Latitude": 454500000,
        "Longitude": -752000000,
        "Elevation (m)": 213,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "BUCKINGHAM",
        "Province": "QUEBEC",
        "Climate ID": "7030874",
        "Station ID": 5582,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -75.42,
        "Latitude": 453500000,
        "Longitude": -752500000,
        "Elevation (m)": 137.2,
        "First Year": 1960,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1985,
        "MLY First Year": 1960,
        "MLY Last Year": 1985
      },
      {
        "Name": "CAMP TAMARACOUTA",
        "Province": "QUEBEC",
        "Climate ID": "7031017",
        "Station ID": 5583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -74.2,
        "Latitude": 455000000,
        "Longitude": -741200000,
        "Elevation (m)": 281.9,
        "First Year": 1971,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1987,
        "MLY First Year": 1971,
        "MLY Last Year": 1987
      },
      {
        "Name": "CHARTERIS",
        "Province": "QUEBEC",
        "Climate ID": "7031315",
        "Station ID": 5584,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.68,
        "Longitude (Decimal Degrees)": -76.43,
        "Latitude": 454100000,
        "Longitude": -762600000,
        "Elevation (m)": 168,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2018
      },
      {
        "Name": "CHATHAM-BROWNSBURG",
        "Province": "QUEBEC",
        "Climate ID": "7031320",
        "Station ID": 43190,
        "WMO ID": null,
        "TC ID": "MBB",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -74.41,
        "Latitude": 453723080,
        "Longitude": -742439020,
        "Elevation (m)": 75,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHELSEA",
        "Province": "QUEBEC",
        "Climate ID": "7031360",
        "Station ID": 5585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -75.78,
        "Latitude": 453100000,
        "Longitude": -754700000,
        "Elevation (m)": 112.5,
        "First Year": 1927,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 2022,
        "MLY First Year": 1927,
        "MLY Last Year": 2018
      },
      {
        "Name": "CHENEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7031375",
        "Station ID": 5586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.9,
        "Longitude (Decimal Degrees)": -75.08,
        "Latitude": 455400000,
        "Longitude": -750500000,
        "Elevation (m)": 222.5,
        "First Year": 1964,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2022,
        "MLY First Year": 1964,
        "MLY Last Year": 2018
      },
      {
        "Name": "CHUTE ST PHILIPPE",
        "Province": "QUEBEC",
        "Climate ID": "7031636",
        "Station ID": 5587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.65,
        "Longitude (Decimal Degrees)": -75.25,
        "Latitude": 463900000,
        "Longitude": -751500000,
        "Elevation (m)": 251.5,
        "First Year": 1972,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1996,
        "MLY First Year": 1972,
        "MLY Last Year": 1996
      },
      {
        "Name": "FERME NEUVE",
        "Province": "QUEBEC",
        "Climate ID": "7032370",
        "Station ID": 5588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.7,
        "Longitude (Decimal Degrees)": -75.45,
        "Latitude": 464200000,
        "Longitude": -752700000,
        "Elevation (m)": 213.4,
        "First Year": 1957,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1985,
        "MLY First Year": 1957,
        "MLY Last Year": 1985
      },
      {
        "Name": "FORT COULONGE",
        "Province": "QUEBEC",
        "Climate ID": "7032440",
        "Station ID": 5589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.82,
        "Longitude (Decimal Degrees)": -76.75,
        "Latitude": 454900000,
        "Longitude": -764500000,
        "Elevation (m)": 106.7,
        "First Year": 1948,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1989,
        "MLY First Year": 1948,
        "MLY Last Year": 1989
      },
      {
        "Name": "GATINEAU",
        "Province": "QUEBEC",
        "Climate ID": "7032680",
        "Station ID": 5590,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -75.65,
        "Latitude": 452900000,
        "Longitude": -753900000,
        "Elevation (m)": 48.8,
        "First Year": 1953,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1973,
        "MLY First Year": 1953,
        "MLY Last Year": 1973
      },
      {
        "Name": "GATINEAU A",
        "Province": "QUEBEC",
        "Climate ID": "7032681",
        "Station ID": 8375,
        "WMO ID": null,
        "TC ID": "YND",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -75.57,
        "Latitude": 453100000,
        "Longitude": -753400000,
        "Elevation (m)": 64.3,
        "First Year": 1981,
        "Last Year": 2012,
        "HLY First Year": 1981,
        "HLY Last Year": 2012,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OTTAWA GATINEAU A",
        "Province": "QUEBEC",
        "Climate ID": "7032682",
        "Station ID": 50719,
        "WMO ID": null,
        "TC ID": "YND",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -75.56,
        "Latitude": 453118000,
        "Longitude": -753349000,
        "Elevation (m)": 64.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OTTAWA GATINEAU A",
        "Province": "QUEBEC",
        "Climate ID": "7032685",
        "Station ID": 53001,
        "WMO ID": null,
        "TC ID": "YND",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -75.56,
        "Latitude": 453118000,
        "Longitude": -753349000,
        "Elevation (m)": 64.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HARRINGTON",
        "Province": "QUEBEC",
        "Climate ID": "7032990",
        "Station ID": 43192,
        "WMO ID": null,
        "TC ID": "MHN",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -74.64,
        "Latitude": 454752030,
        "Longitude": -743839080,
        "Elevation (m)": 168,
        "First Year": 2004,
        "Last Year": 2006,
        "HLY First Year": 2004,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HARRINGTON FOREST FARM",
        "Province": "QUEBEC",
        "Climate ID": "7032997",
        "Station ID": 5591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -74.63,
        "Latitude": 455100000,
        "Longitude": -743800000,
        "Elevation (m)": 182.9,
        "First Year": 1963,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1991,
        "MLY First Year": 1963,
        "MLY Last Year": 1991
      },
      {
        "Name": "HIGH FALLS",
        "Province": "QUEBEC",
        "Climate ID": "7033120",
        "Station ID": 5592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -75.65,
        "Latitude": 455100000,
        "Longitude": -753900000,
        "Elevation (m)": 189,
        "First Year": 1933,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1972,
        "MLY First Year": 1933,
        "MLY Last Year": 1972
      },
      {
        "Name": "HIGH FALLS",
        "Province": "QUEBEC",
        "Climate ID": "7033121",
        "Station ID": 27717,
        "WMO ID": 71391,
        "TC ID": "XHF",
        "Latitude (Decimal Degrees)": 45.84,
        "Longitude (Decimal Degrees)": -75.65,
        "Latitude": 455022000,
        "Longitude": -753853000,
        "Elevation (m)": 194.6,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HUBERDEAU",
        "Province": "QUEBEC",
        "Climate ID": "7033160",
        "Station ID": 5593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -74.63,
        "Latitude": 455800000,
        "Longitude": -743800000,
        "Elevation (m)": 213.4,
        "First Year": 1913,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1980,
        "MLY First Year": 1913,
        "MLY Last Year": 1980
      },
      {
        "Name": "LACHUTE",
        "Province": "QUEBEC",
        "Climate ID": "7033650",
        "Station ID": 5594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -74.33,
        "Latitude": 453900000,
        "Longitude": -742000000,
        "Elevation (m)": 91.4,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "LAC KIAMIKA",
        "Province": "QUEBEC",
        "Climate ID": "7033665",
        "Station ID": 5595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.62,
        "Longitude (Decimal Degrees)": -75.12,
        "Latitude": 463700000,
        "Longitude": -750700000,
        "Elevation (m)": 283.5,
        "First Year": 1952,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1971,
        "MLY First Year": 1952,
        "MLY Last Year": 1971
      },
      {
        "Name": "LAC MASSON",
        "Province": "QUEBEC",
        "Climate ID": "7033673",
        "Station ID": 5596,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -74.05,
        "Latitude": 460200000,
        "Longitude": -740300000,
        "Elevation (m)": 312.4,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "LAC POISSON BLANC",
        "Province": "QUEBEC",
        "Climate ID": "7033685",
        "Station ID": 5597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -75.82,
        "Latitude": 455800000,
        "Longitude": -754900000,
        "Elevation (m)": 228.6,
        "First Year": 1963,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1966
      },
      {
        "Name": "LAC ST DENIS",
        "Province": "QUEBEC",
        "Climate ID": "7033700",
        "Station ID": 5598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -74.32,
        "Latitude": 455600000,
        "Longitude": -741900000,
        "Elevation (m)": 406.3,
        "First Year": 1957,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1986,
        "MLY First Year": 1963,
        "MLY Last Year": 1986
      },
      {
        "Name": "LA MACAZA",
        "Province": "QUEBEC",
        "Climate ID": "7033939",
        "Station ID": 5599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -74.77,
        "Latitude": 462200000,
        "Longitude": -744600000,
        "Elevation (m)": 243.8,
        "First Year": 1976,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2022,
        "MLY First Year": 1976,
        "MLY Last Year": 2018
      },
      {
        "Name": "LA MACAZA A",
        "Province": "QUEBEC",
        "Climate ID": "7033940",
        "Station ID": 5600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.4,
        "Longitude (Decimal Degrees)": -74.78,
        "Latitude": 462400000,
        "Longitude": -744700000,
        "Elevation (m)": 245.1,
        "First Year": 1956,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1976,
        "MLY First Year": 1956,
        "MLY Last Year": 1976
      },
      {
        "Name": "LA MINERVE",
        "Province": "QUEBEC",
        "Climate ID": "7034010",
        "Station ID": 5601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -74.93,
        "Latitude": 461500000,
        "Longitude": -745600000,
        "Elevation (m)": 274.3,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "L'ANNONCIATION",
        "Province": "QUEBEC",
        "Climate ID": "7034040",
        "Station ID": 5602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -74.87,
        "Latitude": 462500000,
        "Longitude": -745200000,
        "Elevation (m)": 243.8,
        "First Year": 1953,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1982,
        "MLY First Year": 1953,
        "MLY Last Year": 1982
      },
      {
        "Name": "LOCHABER",
        "Province": "QUEBEC",
        "Climate ID": "7034310",
        "Station ID": 43188,
        "WMO ID": null,
        "TC ID": "MHB",
        "Latitude (Decimal Degrees)": 45.64,
        "Longitude (Decimal Degrees)": -75.21,
        "Latitude": 453822020,
        "Longitude": -751228000,
        "Elevation (m)": 105,
        "First Year": 2004,
        "Last Year": 2005,
        "HLY First Year": 2004,
        "HLY Last Year": 2005,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LUCERNE",
        "Province": "QUEBEC",
        "Climate ID": "7034350",
        "Station ID": 5603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -75.97,
        "Latitude": 453200000,
        "Longitude": -755800000,
        "Elevation (m)": 100.6,
        "First Year": 1911,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1945,
        "MLY First Year": 1911,
        "MLY Last Year": 1945
      },
      {
        "Name": "LUSKVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7034365",
        "Station ID": 5604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -76.05,
        "Latitude": 453200000,
        "Longitude": -760300000,
        "Elevation (m)": 68.9,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1981,
        "MLY Last Year": 2018
      },
      {
        "Name": "LYTTON",
        "Province": "QUEBEC",
        "Climate ID": "7034395",
        "Station ID": 5605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.68,
        "Longitude (Decimal Degrees)": -76.03,
        "Latitude": 464100000,
        "Longitude": -760200000,
        "Elevation (m)": 213.1,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "MANIWAKI UA",
        "Province": "QUEBEC",
        "Climate ID": "7034480",
        "Station ID": 5607,
        "WMO ID": 71722,
        "TC ID": "WMW",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -76.01,
        "Latitude": 461806500,
        "Longitude": -760021800,
        "Elevation (m)": 188.58,
        "First Year": 1953,
        "Last Year": 2018,
        "HLY First Year": 1953,
        "HLY Last Year": 1993,
        "DLY First Year": 1953,
        "DLY Last Year": 2018,
        "MLY First Year": 1953,
        "MLY Last Year": 1993
      },
      {
        "Name": "MANIWAKI 2",
        "Province": "QUEBEC",
        "Climate ID": "7034481",
        "Station ID": 5609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -75.98,
        "Latitude": 462300000,
        "Longitude": -755900000,
        "Elevation (m)": 173.7,
        "First Year": 1913,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1975,
        "MLY First Year": 1913,
        "MLY Last Year": 1975
      },
      {
        "Name": "MANIWAKI AIRPORT",
        "Province": "QUEBEC",
        "Climate ID": "7034482",
        "Station ID": 5606,
        "WMO ID": 71721,
        "TC ID": "WMJ",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -75.99,
        "Latitude": 461629000,
        "Longitude": -755931000,
        "Elevation (m)": 199.7,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2005
      },
      {
        "Name": "MASSON",
        "Province": "QUEBEC",
        "Climate ID": "7034635",
        "Station ID": 5610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -75.42,
        "Latitude": 453200000,
        "Longitude": -752500000,
        "Elevation (m)": 54.9,
        "First Year": 1963,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1979,
        "MLY First Year": 1963,
        "MLY Last Year": 1979
      },
      {
        "Name": "MONTREAL MIRABEL INTL A",
        "Province": "QUEBEC",
        "Climate ID": "7034900",
        "Station ID": 49608,
        "WMO ID": null,
        "TC ID": "YMX",
        "Latitude (Decimal Degrees)": 45.68,
        "Longitude (Decimal Degrees)": -74.04,
        "Latitude": 454050000,
        "Longitude": -740219000,
        "Elevation (m)": 82.3,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONTEBELLO",
        "Province": "QUEBEC",
        "Climate ID": "7035109",
        "Station ID": 5611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -74.93,
        "Latitude": 453900000,
        "Longitude": -745600000,
        "Elevation (m)": 45.7,
        "First Year": 1976,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1985,
        "MLY First Year": 1976,
        "MLY Last Year": 1985
      },
      {
        "Name": "MONTEBELLO (SEDBERGH)",
        "Province": "QUEBEC",
        "Climate ID": "7035110",
        "Station ID": 5612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -74.93,
        "Latitude": 454200000,
        "Longitude": -745600000,
        "Elevation (m)": 196.6,
        "First Year": 1956,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2015,
        "MLY First Year": 1956,
        "MLY Last Year": 2015
      },
      {
        "Name": "MONTEBELLO SEIGNIORY",
        "Province": "QUEBEC",
        "Climate ID": "7035112",
        "Station ID": 5613,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -74.95,
        "Latitude": 453900000,
        "Longitude": -745700000,
        "Elevation (m)": 52.4,
        "First Year": 1930,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1975,
        "MLY First Year": 1930,
        "MLY Last Year": 1975
      },
      {
        "Name": "MONT LAURIER",
        "Province": "QUEBEC",
        "Climate ID": "7035158",
        "Station ID": 5614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.57,
        "Longitude (Decimal Degrees)": -75.5,
        "Latitude": 463400000,
        "Longitude": -753000000,
        "Elevation (m)": 243.8,
        "First Year": 1920,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1963,
        "MLY First Year": 1920,
        "MLY Last Year": 1963
      },
      {
        "Name": "MONT LAURIER",
        "Province": "QUEBEC",
        "Climate ID": "7035160",
        "Station ID": 5615,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.57,
        "Longitude (Decimal Degrees)": -75.55,
        "Latitude": 463400000,
        "Longitude": -753300000,
        "Elevation (m)": 244,
        "First Year": 1963,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2014,
        "MLY First Year": 1963,
        "MLY Last Year": 2014
      },
      {
        "Name": "MONTREAL/MIRABEL INT'L A",
        "Province": "QUEBEC",
        "Climate ID": "7035290",
        "Station ID": 5616,
        "WMO ID": null,
        "TC ID": "YMX",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -74.03,
        "Latitude": 454000000,
        "Longitude": -740200000,
        "Elevation (m)": 82.6,
        "First Year": 1975,
        "Last Year": 2012,
        "HLY First Year": 1975,
        "HLY Last Year": 2012,
        "DLY First Year": 1975,
        "DLY Last Year": 2008,
        "MLY First Year": 1975,
        "MLY Last Year": 2008
      },
      {
        "Name": "MORIN HEIGHTS",
        "Province": "QUEBEC",
        "Climate ID": "7035360",
        "Station ID": 5617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -74.27,
        "Latitude": 455500000,
        "Longitude": -741600000,
        "Elevation (m)": 289.6,
        "First Year": 1948,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1982,
        "MLY First Year": 1948,
        "MLY Last Year": 1982
      },
      {
        "Name": "NOMININGUE",
        "Province": "QUEBEC",
        "Climate ID": "7035520",
        "Station ID": 5618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.4,
        "Longitude (Decimal Degrees)": -75.08,
        "Latitude": 462400000,
        "Longitude": -750500000,
        "Elevation (m)": 274,
        "First Year": 1913,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2013,
        "MLY First Year": 1913,
        "MLY Last Year": 2013
      },
      {
        "Name": "NOTRE DAME DE LA PAIX",
        "Province": "QUEBEC",
        "Climate ID": "7035666",
        "Station ID": 5619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -74.98,
        "Latitude": 454800000,
        "Longitude": -745900000,
        "Elevation (m)": 183,
        "First Year": 1979,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2022,
        "MLY First Year": 1979,
        "MLY Last Year": 2018
      },
      {
        "Name": "NOTRE DAME DU LAUS",
        "Province": "QUEBEC",
        "Climate ID": "7035680",
        "Station ID": 5620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 460700000,
        "Longitude": -753800000,
        "Elevation (m)": 213.4,
        "First Year": 1929,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1991,
        "MLY First Year": 1929,
        "MLY Last Year": 1991
      },
      {
        "Name": "OTTER LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7035760",
        "Station ID": 5621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -76.43,
        "Latitude": 455100000,
        "Longitude": -762600000,
        "Elevation (m)": 213.4,
        "First Year": 1948,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2004,
        "MLY First Year": 1948,
        "MLY Last Year": 2004
      },
      {
        "Name": "PERKINS",
        "Province": "QUEBEC",
        "Climate ID": "7036000",
        "Station ID": 5622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -75.62,
        "Latitude": 453600000,
        "Longitude": -753700000,
        "Elevation (m)": 152.4,
        "First Year": 1911,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 1991,
        "MLY First Year": 1911,
        "MLY Last Year": 1991
      },
      {
        "Name": "POINTE AU CHENE",
        "Province": "QUEBEC",
        "Climate ID": "7036063",
        "Station ID": 5623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -74.8,
        "Latitude": 453900000,
        "Longitude": -744800000,
        "Elevation (m)": 51,
        "First Year": 1958,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2009,
        "MLY First Year": 1958,
        "MLY Last Year": 2009
      },
      {
        "Name": "STE AGATHE DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7036760",
        "Station ID": 5624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -74.28,
        "Latitude": 460300000,
        "Longitude": -741700000,
        "Elevation (m)": 365.8,
        "First Year": 1899,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1958,
        "MLY First Year": 1899,
        "MLY Last Year": 1958
      },
      {
        "Name": "STE AGATHE DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7036762",
        "Station ID": 5625,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -74.28,
        "Latitude": 460300000,
        "Longitude": -741700000,
        "Elevation (m)": 394.7,
        "First Year": 1965,
        "Last Year": 1992,
        "HLY First Year": 1965,
        "HLY Last Year": 1992,
        "DLY First Year": 1966,
        "DLY Last Year": 1992,
        "MLY First Year": 1966,
        "MLY Last Year": 1992
      },
      {
        "Name": "STE AGATHE MANOR HOUSE",
        "Province": "QUEBEC",
        "Climate ID": "7036780",
        "Station ID": 5626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.08,
        "Longitude (Decimal Degrees)": -74.3,
        "Latitude": 460500000,
        "Longitude": -741800000,
        "Elevation (m)": 391.7,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "SAINT-ANDRE-AVELIN",
        "Province": "QUEBEC",
        "Climate ID": "7036800",
        "Station ID": 43103,
        "WMO ID": null,
        "TC ID": "MAN",
        "Latitude (Decimal Degrees)": 45.81,
        "Longitude (Decimal Degrees)": -75.06,
        "Latitude": 454851007,
        "Longitude": -750347005,
        "Elevation (m)": 195,
        "First Year": 2004,
        "Last Year": 2007,
        "HLY First Year": 2004,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STE ANNE DU LAC",
        "Province": "QUEBEC",
        "Climate ID": "7036855",
        "Station ID": 5627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -75.33,
        "Latitude": 465100000,
        "Longitude": -752000000,
        "Elevation (m)": 262.1,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST EMILE DE SUFFOLK",
        "Province": "QUEBEC",
        "Climate ID": "7037175",
        "Station ID": 5628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -74.92,
        "Latitude": 455700000,
        "Longitude": -745500000,
        "Elevation (m)": 228.6,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "ST FAUSTIN",
        "Province": "QUEBEC",
        "Climate ID": "7037230",
        "Station ID": 5629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -74.48,
        "Latitude": 460700000,
        "Longitude": -742900000,
        "Elevation (m)": 365.8,
        "First Year": 1967,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1994,
        "MLY First Year": 1967,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST HIPPOLYTE",
        "Province": "QUEBEC",
        "Climate ID": "7037310",
        "Station ID": 5630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.98,
        "Longitude (Decimal Degrees)": -74,
        "Latitude": 455900000,
        "Longitude": -740000000,
        "Elevation (m)": 365.8,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST JEROME",
        "Province": "QUEBEC",
        "Climate ID": "7037400",
        "Station ID": 5631,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -74.05,
        "Latitude": 454800000,
        "Longitude": -740300000,
        "Elevation (m)": 169.5,
        "First Year": 1932,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 2022,
        "MLY First Year": 1932,
        "MLY Last Year": 2018
      },
      {
        "Name": "SHAWBRIDGE BOYS FARM",
        "Province": "QUEBEC",
        "Climate ID": "7037985",
        "Station ID": 5632,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -74.07,
        "Latitude": 455300000,
        "Longitude": -740400000,
        "Elevation (m)": 185.9,
        "First Year": 1959,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1967,
        "MLY First Year": 1959,
        "MLY Last Year": 1967
      },
      {
        "Name": "SHAWVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7038040",
        "Station ID": 5633,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -76.47,
        "Latitude": 453700000,
        "Longitude": -762800000,
        "Elevation (m)": 167.6,
        "First Year": 1948,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2012,
        "MLY First Year": 1948,
        "MLY Last Year": 2012
      },
      {
        "Name": "SHAWVILLE WINSLOW",
        "Province": "QUEBEC",
        "Climate ID": "7038046",
        "Station ID": 5634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -76.5,
        "Latitude": 454600000,
        "Longitude": -763000000,
        "Elevation (m)": 166.1,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "SHEENBORO",
        "Province": "QUEBEC",
        "Climate ID": "7038080",
        "Station ID": 5635,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 455800000,
        "Longitude": -771500000,
        "Elevation (m)": 137.2,
        "First Year": 1948,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2014,
        "MLY First Year": 1948,
        "MLY Last Year": 2014
      },
      {
        "Name": "TAPINI FARM",
        "Province": "QUEBEC",
        "Climate ID": "7038350",
        "Station ID": 5636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.93,
        "Longitude (Decimal Degrees)": -75.13,
        "Latitude": 465600000,
        "Longitude": -750800000,
        "Elevation (m)": 274.3,
        "First Year": 1963,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1977,
        "MLY First Year": 1963,
        "MLY Last Year": 1977
      },
      {
        "Name": "THURSO",
        "Province": "QUEBEC",
        "Climate ID": "7038500",
        "Station ID": 5637,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -75.27,
        "Latitude": 453600000,
        "Longitude": -751600000,
        "Elevation (m)": 54.9,
        "First Year": 1958,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1985,
        "MLY First Year": 1958,
        "MLY Last Year": 1985
      },
      {
        "Name": "VAL DES BOIS",
        "Province": "QUEBEC",
        "Climate ID": "7038587",
        "Station ID": 5638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.9,
        "Longitude (Decimal Degrees)": -75.6,
        "Latitude": 455400000,
        "Longitude": -753600000,
        "Elevation (m)": 198.1,
        "First Year": 1972,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1994,
        "MLY First Year": 1972,
        "MLY Last Year": 1994
      },
      {
        "Name": "WAKEFIELD",
        "Province": "QUEBEC",
        "Climate ID": "7038835",
        "Station ID": 5639,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -75.9,
        "Latitude": 453600000,
        "Longitude": -755400000,
        "Elevation (m)": 152.4,
        "First Year": 1963,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1993,
        "MLY First Year": 1963,
        "MLY Last Year": 1993
      },
      {
        "Name": "WALTHAM",
        "Province": "QUEBEC",
        "Climate ID": "7038880",
        "Station ID": 5640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -76.92,
        "Latitude": 455500000,
        "Longitude": -765500000,
        "Elevation (m)": 112.5,
        "First Year": 1953,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1994,
        "MLY First Year": 1953,
        "MLY Last Year": 1994
      },
      {
        "Name": "WILSONS CORNERS",
        "Province": "QUEBEC",
        "Climate ID": "7038915",
        "Station ID": 5641,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -75.7,
        "Latitude": 454300000,
        "Longitude": -754200000,
        "Elevation (m)": 175.3,
        "First Year": 1945,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1962,
        "MLY First Year": 1945,
        "MLY Last Year": 1962
      },
      {
        "Name": "WRIGHT",
        "Province": "QUEBEC",
        "Climate ID": "7038975",
        "Station ID": 5642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -76.05,
        "Latitude": 460400000,
        "Longitude": -760300000,
        "Elevation (m)": 141.7,
        "First Year": 1967,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2022,
        "MLY First Year": 1967,
        "MLY Last Year": 2018
      },
      {
        "Name": "DUHAMEL",
        "Province": "QUEBEC",
        "Climate ID": "703BBD2",
        "Station ID": 5572,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.02,
        "Longitude (Decimal Degrees)": -75.08,
        "Latitude": 460100000,
        "Longitude": -750500000,
        "Elevation (m)": 244,
        "First Year": 1972,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1994,
        "MLY First Year": 1972,
        "MLY Last Year": 1994
      },
      {
        "Name": "LAC PARENT",
        "Province": "QUEBEC",
        "Climate ID": "703CFQD",
        "Station ID": 5573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -74.87,
        "Latitude": 472500000,
        "Longitude": -745200000,
        "Elevation (m)": 396.2,
        "First Year": 1968,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1973,
        "MLY First Year": 1968,
        "MLY Last Year": 1973
      },
      {
        "Name": "ST-JOVITE",
        "Province": "QUEBEC",
        "Climate ID": "703GDKB",
        "Station ID": 10868,
        "WMO ID": 71376,
        "TC ID": "WJT",
        "Latitude (Decimal Degrees)": 46.08,
        "Longitude (Decimal Degrees)": -74.56,
        "Latitude": 460449000,
        "Longitude": -743321000,
        "Elevation (m)": 238.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAIE-COMEAU A",
        "Province": "QUEBEC",
        "Climate ID": "7040440",
        "Station ID": 5662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -68.2,
        "Latitude": 490800000,
        "Longitude": -681200000,
        "Elevation (m)": 21.6,
        "First Year": 1947,
        "Last Year": 2005,
        "HLY First Year": 1956,
        "HLY Last Year": 2005,
        "DLY First Year": 1947,
        "DLY Last Year": 2005,
        "MLY First Year": 1947,
        "MLY Last Year": 2004
      },
      {
        "Name": "BAIE-COMEAU A",
        "Province": "QUEBEC",
        "Climate ID": "7040442",
        "Station ID": 30173,
        "WMO ID": 71890,
        "TC ID": "GBC",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -68.2,
        "Latitude": 490800000,
        "Longitude": -681200000,
        "Elevation (m)": 21.6,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "BAIE-COMEAU",
        "Province": "QUEBEC",
        "Climate ID": "7040444",
        "Station ID": 48968,
        "WMO ID": 71691,
        "TC ID": "YBC",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -68.2,
        "Latitude": 490757000,
        "Longitude": -681216000,
        "Elevation (m)": 21.6,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAIE COMEAU CBA",
        "Province": "QUEBEC",
        "Climate ID": "7040445",
        "Station ID": 5663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -68.15,
        "Latitude": 491300000,
        "Longitude": -680900000,
        "Elevation (m)": 7.6,
        "First Year": 1958,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1966,
        "MLY First Year": 1958,
        "MLY Last Year": 1966
      },
      {
        "Name": "BAIE ST PAUL",
        "Province": "QUEBEC",
        "Climate ID": "7040446",
        "Station ID": 5664,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -70.5,
        "Latitude": 472500000,
        "Longitude": -703000000,
        "Elevation (m)": 32,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "BARRIERE CLERMONT",
        "Province": "QUEBEC",
        "Climate ID": "70404PA",
        "Station ID": 5661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.7,
        "Longitude (Decimal Degrees)": -70.22,
        "Latitude": 474200000,
        "Longitude": -701300000,
        "Elevation (m)": 83.8,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "BERSIMIS",
        "Province": "QUEBEC",
        "Climate ID": "7040680",
        "Station ID": 5665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -68.62,
        "Latitude": 485800000,
        "Longitude": -683700000,
        "Elevation (m)": 45.7,
        "First Year": 1923,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1961,
        "MLY First Year": 1923,
        "MLY Last Year": 1960
      },
      {
        "Name": "BERSIMIS S",
        "Province": "QUEBEC",
        "Climate ID": "7040690",
        "Station ID": 5666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -68.65,
        "Latitude": 485700000,
        "Longitude": -683900000,
        "Elevation (m)": 18.3,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "BLANC SABLON",
        "Province": "QUEBEC",
        "Climate ID": "7040811",
        "Station ID": 5667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -57.2,
        "Latitude": 512500000,
        "Longitude": -571200000,
        "Elevation (m)": 7.6,
        "First Year": 1965,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1975,
        "MLY First Year": 1965,
        "MLY Last Year": 1975
      },
      {
        "Name": "BLANC SABLON",
        "Province": "QUEBEC",
        "Climate ID": "7040812",
        "Station ID": 5668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -57.22,
        "Latitude": 512500000,
        "Longitude": -571300000,
        "Elevation (m)": 19.2,
        "First Year": 1967,
        "Last Year": 1982,
        "HLY First Year": 1967,
        "HLY Last Year": 1982,
        "DLY First Year": 1967,
        "DLY Last Year": 1982,
        "MLY First Year": 1967,
        "MLY Last Year": 1982
      },
      {
        "Name": "LOURDES DE BLANC SABLON A",
        "Province": "QUEBEC",
        "Climate ID": "7040813",
        "Station ID": 5669,
        "WMO ID": 71808,
        "TC ID": "YBX",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -57.18,
        "Latitude": 512700000,
        "Longitude": -571100000,
        "Elevation (m)": 37.18,
        "First Year": 1970,
        "Last Year": 2014,
        "HLY First Year": 1970,
        "HLY Last Year": 2014,
        "DLY First Year": 1982,
        "DLY Last Year": 2014,
        "MLY First Year": 1982,
        "MLY Last Year": 2014
      },
      {
        "Name": "BLOUGH LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7040814",
        "Station ID": 5670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.75,
        "Longitude (Decimal Degrees)": -68.33,
        "Latitude": 514500000,
        "Longitude": -682000000,
        "Elevation (m)": null,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "LOURDES DE BLANC SABLON A",
        "Province": "QUEBEC",
        "Climate ID": "7040815",
        "Station ID": 53140,
        "WMO ID": 71808,
        "TC ID": "YBX",
        "Latitude (Decimal Degrees)": 51.44,
        "Longitude (Decimal Degrees)": -57.19,
        "Latitude": 512631000,
        "Longitude": -571110000,
        "Elevation (m)": 37.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LOURDES DE BLANC SABLON A",
        "Province": "QUEBEC",
        "Climate ID": "7040816",
        "Station ID": 54298,
        "WMO ID": null,
        "TC ID": "YBX",
        "Latitude (Decimal Degrees)": 51.44,
        "Longitude (Decimal Degrees)": -57.19,
        "Latitude": 512631000,
        "Longitude": -571110000,
        "Elevation (m)": 37.2,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BARRIERE JALBERT",
        "Province": "QUEBEC",
        "Climate ID": "7040MGJ",
        "Station ID": 5658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -70.7,
        "Latitude": 482700000,
        "Longitude": -704200000,
        "Elevation (m)": 175.3,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "BAIE COMEAU",
        "Province": "QUEBEC",
        "Climate ID": "7040MLR",
        "Station ID": 5659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -68.18,
        "Latitude": 491400000,
        "Longitude": -681100000,
        "Elevation (m)": 68.6,
        "First Year": 1966,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1980,
        "MLY First Year": 1966,
        "MLY Last Year": 1980
      },
      {
        "Name": "BAIE JOHAN BEETZ",
        "Province": "QUEBEC",
        "Climate ID": "7040MMN",
        "Station ID": 5660,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -62.8,
        "Latitude": 501700000,
        "Longitude": -624800000,
        "Elevation (m)": 7.6,
        "First Year": 1965,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2004,
        "MLY First Year": 1965,
        "MLY Last Year": 2004
      },
      {
        "Name": "CAP AUX CORBEAUX",
        "Province": "QUEBEC",
        "Climate ID": "7041020",
        "Station ID": 5671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -70.45,
        "Latitude": 472700000,
        "Longitude": -702700000,
        "Elevation (m)": 350.5,
        "First Year": 1958,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1972,
        "MLY First Year": 1958,
        "MLY Last Year": 1972
      },
      {
        "Name": "CAPE WHITTLE",
        "Province": "QUEBEC",
        "Climate ID": "7041100",
        "Station ID": 5672,
        "WMO ID": 71374,
        "TC ID": "WQW",
        "Latitude (Decimal Degrees)": 50.16,
        "Longitude (Decimal Degrees)": -60.06,
        "Latitude": 500947000,
        "Longitude": -600342000,
        "Elevation (m)": 7,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 1968
      },
      {
        "Name": "CAP-ROUGE",
        "Province": "QUEBEC",
        "Climate ID": "7041166",
        "Station ID": 10963,
        "WMO ID": 71186,
        "TC ID": "WQM",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -70.54,
        "Latitude": 482227000,
        "Longitude": -703207000,
        "Elevation (m)": 7.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAP TOURMENTE",
        "Province": "QUEBEC",
        "Climate ID": "7041177",
        "Station ID": 5673,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -70.78,
        "Latitude": 470400000,
        "Longitude": -704700000,
        "Elevation (m)": 6.1,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "CHARLEVOIX A",
        "Province": "QUEBEC",
        "Climate ID": "7041310",
        "Station ID": 8455,
        "WMO ID": null,
        "TC ID": "YML",
        "Latitude (Decimal Degrees)": 47.6,
        "Longitude (Decimal Degrees)": -70.23,
        "Latitude": 473600000,
        "Longitude": -701400000,
        "Elevation (m)": 298,
        "First Year": 1994,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2018,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHARLEVOIX (MRC)",
        "Province": "QUEBEC",
        "Climate ID": "7041312",
        "Station ID": 27442,
        "WMO ID": 71319,
        "TC ID": "WIS",
        "Latitude (Decimal Degrees)": 47.28,
        "Longitude (Decimal Degrees)": -70.64,
        "Latitude": 471703000,
        "Longitude": -703815000,
        "Elevation (m)": 722.74,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHATEAU RICHER",
        "Province": "QUEBEC",
        "Climate ID": "7041330",
        "Station ID": 5674,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.97,
        "Longitude (Decimal Degrees)": -71.03,
        "Latitude": 465800000,
        "Longitude": -710200000,
        "Elevation (m)": 15.2,
        "First Year": 1980,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2021,
        "MLY First Year": 1981,
        "MLY Last Year": 2018
      },
      {
        "Name": "CHEVERY",
        "Province": "QUEBEC",
        "Climate ID": "7041382",
        "Station ID": 8456,
        "WMO ID": 71814,
        "TC ID": "WDM",
        "Latitude (Decimal Degrees)": 50.47,
        "Longitude (Decimal Degrees)": -59.64,
        "Latitude": 502754000,
        "Longitude": -593822000,
        "Elevation (m)": 7.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHUTE AUX OUTARDES",
        "Province": "QUEBEC",
        "Climate ID": "7041530",
        "Station ID": 5675,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -68.38,
        "Latitude": 490800000,
        "Longitude": -682300000,
        "Elevation (m)": 15.2,
        "First Year": 1963,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1978,
        "MLY First Year": 1963,
        "MLY Last Year": 1978
      },
      {
        "Name": "CLARKE CITY",
        "Province": "QUEBEC",
        "Climate ID": "7041710",
        "Station ID": 5676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -66.63,
        "Latitude": 501100000,
        "Longitude": -663800000,
        "Elevation (m)": 53.3,
        "First Year": 1903,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1903,
        "DLY Last Year": 1981,
        "MLY First Year": 1903,
        "MLY Last Year": 1981
      },
      {
        "Name": "CLARKE CITY S",
        "Province": "QUEBEC",
        "Climate ID": "7041715",
        "Station ID": 5677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.2,
        "Longitude (Decimal Degrees)": -66.63,
        "Latitude": 501200000,
        "Longitude": -663800000,
        "Elevation (m)": 61,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "CLERMONT",
        "Province": "QUEBEC",
        "Climate ID": "7041717",
        "Station ID": 5678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.68,
        "Longitude (Decimal Degrees)": -70.25,
        "Latitude": 474100000,
        "Longitude": -701500000,
        "Elevation (m)": 152.4,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "COROSSOL",
        "Province": "QUEBEC",
        "Climate ID": "7041934",
        "Station ID": 10246,
        "WMO ID": null,
        "TC ID": "WGA",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -66.38,
        "Latitude": 500500000,
        "Longitude": -662300000,
        "Elevation (m)": 45.7,
        "First Year": 1994,
        "Last Year": 1996,
        "HLY First Year": 1994,
        "HLY Last Year": 1996,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAP-TOURMENTE",
        "Province": "QUEBEC",
        "Climate ID": "7041JG6",
        "Station ID": 10869,
        "WMO ID": 71384,
        "TC ID": "WTN",
        "Latitude (Decimal Degrees)": 47.08,
        "Longitude (Decimal Degrees)": -70.78,
        "Latitude": 470443000,
        "Longitude": -704651000,
        "Elevation (m)": 6,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FIRE LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7042371",
        "Station ID": 5679,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -67.37,
        "Latitude": 522000000,
        "Longitude": -672200000,
        "Elevation (m)": 609.6,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "FORESTVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7042378",
        "Station ID": 5680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.73,
        "Longitude (Decimal Degrees)": -69.08,
        "Latitude": 484400000,
        "Longitude": -690500000,
        "Elevation (m)": 76.2,
        "First Year": 1963,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1996,
        "MLY First Year": 1963,
        "MLY Last Year": 1996
      },
      {
        "Name": "FORESTVILLE NORD",
        "Province": "QUEBEC",
        "Climate ID": "7042385",
        "Station ID": 5681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -69.08,
        "Latitude": 484500000,
        "Longitude": -690500000,
        "Elevation (m)": 68.6,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "FORET MONTMORENCY",
        "Province": "QUEBEC",
        "Climate ID": "7042388",
        "Station ID": 5682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.32,
        "Longitude (Decimal Degrees)": -71.15,
        "Latitude": 471900000,
        "Longitude": -710900000,
        "Elevation (m)": 640,
        "First Year": 1965,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2002,
        "MLY First Year": 1965,
        "MLY Last Year": 2002
      },
      {
        "Name": "FORET MONTMORENCY RCS",
        "Province": "QUEBEC",
        "Climate ID": "7042395",
        "Station ID": 32413,
        "WMO ID": 71212,
        "TC ID": "MFM",
        "Latitude (Decimal Degrees)": 47.32,
        "Longitude (Decimal Degrees)": -71.15,
        "Latitude": 471922000,
        "Longitude": -710854000,
        "Elevation (m)": 672.8,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GAGNON A",
        "Province": "QUEBEC",
        "Climate ID": "7042590",
        "Station ID": 5683,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.95,
        "Longitude (Decimal Degrees)": -68.13,
        "Latitude": 515700000,
        "Longitude": -680800000,
        "Elevation (m)": 567.2,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": 1965,
        "HLY Last Year": 1985,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "GAGNONVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7042591",
        "Station ID": 5684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.97,
        "Longitude (Decimal Degrees)": -68.17,
        "Latitude": 515800000,
        "Longitude": -681000000,
        "Elevation (m)": 548.6,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "GETHSEMANIE",
        "Province": "QUEBEC",
        "Climate ID": "7042724",
        "Station ID": 5685,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -60.67,
        "Latitude": 501300000,
        "Longitude": -604000000,
        "Elevation (m)": 8,
        "First Year": 1965,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1989,
        "MLY First Year": 1965,
        "MLY Last Year": 1988
      },
      {
        "Name": "GODBOUT",
        "Province": "QUEBEC",
        "Climate ID": "7042749",
        "Station ID": 5686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -67.62,
        "Latitude": 491900000,
        "Longitude": -673700000,
        "Elevation (m)": 30,
        "First Year": 1971,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2015,
        "MLY First Year": 1971,
        "MLY Last Year": 2015
      },
      {
        "Name": "GRANDES BERGERONNES",
        "Province": "QUEBEC",
        "Climate ID": "7042840",
        "Station ID": 5687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -69.52,
        "Latitude": 481500000,
        "Longitude": -693100000,
        "Elevation (m)": 61,
        "First Year": 1951,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2015,
        "MLY First Year": 1951,
        "MLY Last Year": 2015
      },
      {
        "Name": "GRANDES-BERGERONNES",
        "Province": "QUEBEC",
        "Climate ID": "7042845",
        "Station ID": 54698,
        "WMO ID": 71942,
        "TC ID": "MGZ",
        "Latitude (Decimal Degrees)": 48.26,
        "Longitude (Decimal Degrees)": -69.52,
        "Latitude": 481529000,
        "Longitude": -693122000,
        "Elevation (m)": 61,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRAND FONDS",
        "Province": "QUEBEC",
        "Climate ID": "7042870",
        "Station ID": 5688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.75,
        "Longitude (Decimal Degrees)": -70.12,
        "Latitude": 474500000,
        "Longitude": -700700000,
        "Elevation (m)": 365.8,
        "First Year": 1968,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1991,
        "MLY First Year": 1968,
        "MLY Last Year": 1991
      },
      {
        "Name": "HARRINGTON HARBOUR",
        "Province": "QUEBEC",
        "Climate ID": "7043000",
        "Station ID": 5690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.53,
        "Longitude (Decimal Degrees)": -59.5,
        "Latitude": 503200000,
        "Longitude": -593000000,
        "Elevation (m)": 7.6,
        "First Year": 1911,
        "Last Year": 1978,
        "HLY First Year": 1953,
        "HLY Last Year": 1978,
        "DLY First Year": 1911,
        "DLY Last Year": 1978,
        "MLY First Year": 1911,
        "MLY Last Year": 1978
      },
      {
        "Name": "HAVRE ST PIERRE",
        "Province": "QUEBEC",
        "Climate ID": "7043012",
        "Station ID": 5691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 501500000,
        "Longitude": -633500000,
        "Elevation (m)": 6.1,
        "First Year": 1964,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1985,
        "MLY First Year": 1964,
        "MLY Last Year": 1984
      },
      {
        "Name": "HAVRE ST PIERRE A",
        "Province": "QUEBEC",
        "Climate ID": "7043017",
        "Station ID": 5692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.25,
        "Longitude (Decimal Degrees)": -63.6,
        "Latitude": 501500000,
        "Longitude": -633600000,
        "Elevation (m)": 4.9,
        "First Year": 1967,
        "Last Year": 1978,
        "HLY First Year": 1967,
        "HLY Last Year": 1978,
        "DLY First Year": 1967,
        "DLY Last Year": 1978,
        "MLY First Year": 1967,
        "MLY Last Year": 1978
      },
      {
        "Name": "HAVRE-SAINT-PIERRE A",
        "Province": "QUEBEC",
        "Climate ID": "7043018",
        "Station ID": 5693,
        "WMO ID": 71313,
        "TC ID": "YGV",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -63.6,
        "Latitude": 501700000,
        "Longitude": -633600000,
        "Elevation (m)": 37.8,
        "First Year": 1983,
        "Last Year": 2008,
        "HLY First Year": 1983,
        "HLY Last Year": 2008,
        "DLY First Year": 1983,
        "DLY Last Year": 2008,
        "MLY First Year": 1984,
        "MLY Last Year": 1994
      },
      {
        "Name": "HAVRE ST. PIERRE",
        "Province": "QUEBEC",
        "Climate ID": "7043019",
        "Station ID": 48970,
        "WMO ID": 71584,
        "TC ID": "YGV",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -63.61,
        "Latitude": 501655000,
        "Longitude": -633641000,
        "Elevation (m)": 37.8,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HAVRE-SAINT-PIERRE A",
        "Province": "QUEBEC",
        "Climate ID": "7043020",
        "Station ID": 30130,
        "WMO ID": 71313,
        "TC ID": "YGV",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -63.6,
        "Latitude": 501700000,
        "Longitude": -633600000,
        "Elevation (m)": 37.8,
        "First Year": 2000,
        "Last Year": 2011,
        "HLY First Year": 2000,
        "HLY Last Year": 2011,
        "DLY First Year": 2008,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LABRIEVILLE B1",
        "Province": "QUEBEC",
        "Climate ID": "7043540",
        "Station ID": 5694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -69.55,
        "Latitude": 491800000,
        "Longitude": -693300000,
        "Elevation (m)": 152.4,
        "First Year": 1955,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1994,
        "MLY First Year": 1955,
        "MLY Last Year": 1994
      },
      {
        "Name": "LABRIEVILLE B2",
        "Province": "QUEBEC",
        "Climate ID": "7043542",
        "Station ID": 5695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -69.23,
        "Latitude": 491000000,
        "Longitude": -691400000,
        "Elevation (m)": 198.1,
        "First Year": 1960,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1968,
        "MLY First Year": 1960,
        "MLY Last Year": 1968
      },
      {
        "Name": "LABRIEVILLE 2",
        "Province": "QUEBEC",
        "Climate ID": "7043543",
        "Station ID": 5696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -69.58,
        "Latitude": 491900000,
        "Longitude": -693500000,
        "Elevation (m)": 137.2,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "LA GALETTE",
        "Province": "QUEBEC",
        "Climate ID": "7043713",
        "Station ID": 5697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.73,
        "Longitude (Decimal Degrees)": -70.73,
        "Latitude": 474400000,
        "Longitude": -704400000,
        "Elevation (m)": 740.7,
        "First Year": 1963,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1972,
        "MLY First Year": 1963,
        "MLY Last Year": 1972
      },
      {
        "Name": "LAKE EON",
        "Province": "QUEBEC",
        "Climate ID": "7043740",
        "Station ID": 5698,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -63.28,
        "Latitude": 515200000,
        "Longitude": -631700000,
        "Elevation (m)": 560.8,
        "First Year": 1955,
        "Last Year": 1977,
        "HLY First Year": 1955,
        "HLY Last Year": 1977,
        "DLY First Year": 1955,
        "DLY Last Year": 1977,
        "MLY First Year": 1955,
        "MLY Last Year": 1977
      },
      {
        "Name": "LA MALBAIE",
        "Province": "QUEBEC",
        "Climate ID": "7043960",
        "Station ID": 5699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.67,
        "Longitude (Decimal Degrees)": -70.15,
        "Latitude": 474000000,
        "Longitude": -700900000,
        "Elevation (m)": 22.9,
        "First Year": 1913,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2004,
        "MLY First Year": 1913,
        "MLY Last Year": 2004
      },
      {
        "Name": "ILE AUX PERROQUETS",
        "Province": "QUEBEC",
        "Climate ID": "7043B79",
        "Station ID": 10937,
        "WMO ID": 71375,
        "TC ID": "WQR",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -64.21,
        "Latitude": 501315000,
        "Longitude": -641226000,
        "Elevation (m)": 9,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ILE ROUGE",
        "Province": "QUEBEC",
        "Climate ID": "7043BP9",
        "Station ID": 8674,
        "WMO ID": 71426,
        "TC ID": "WAF",
        "Latitude (Decimal Degrees)": 48.07,
        "Longitude (Decimal Degrees)": -69.56,
        "Latitude": 480408000,
        "Longitude": -693320000,
        "Elevation (m)": 5.9,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAC BARBELL",
        "Province": "QUEBEC",
        "Climate ID": "7043EE9",
        "Station ID": 5689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.9,
        "Longitude (Decimal Degrees)": -68.17,
        "Latitude": 515400000,
        "Longitude": -681000000,
        "Elevation (m)": 533.4,
        "First Year": 1968,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1976,
        "MLY First Year": 1968,
        "MLY Last Year": 1975
      },
      {
        "Name": "LA TABATIERE",
        "Province": "QUEBEC",
        "Climate ID": "7044168",
        "Station ID": 5700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.83,
        "Longitude (Decimal Degrees)": -58.97,
        "Latitude": 505000000,
        "Longitude": -585800000,
        "Elevation (m)": 7.6,
        "First Year": 1971,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1985,
        "MLY First Year": 1971,
        "MLY Last Year": 1985
      },
      {
        "Name": "LES BUISSONS",
        "Province": "QUEBEC",
        "Climate ID": "7044288",
        "Station ID": 5701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -68.38,
        "Latitude": 490700000,
        "Longitude": -682300000,
        "Elevation (m)": 15,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "LES CHAINONS",
        "Province": "QUEBEC",
        "Climate ID": "7044291",
        "Station ID": 5702,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -70.37,
        "Latitude": 492100000,
        "Longitude": -702200000,
        "Elevation (m)": 609.6,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1975,
        "MLY Last Year": 1978
      },
      {
        "Name": "LES EBOULEMENTS",
        "Province": "QUEBEC",
        "Climate ID": "7044292",
        "Station ID": 5703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.5,
        "Longitude (Decimal Degrees)": -70.25,
        "Latitude": 473000000,
        "Longitude": -701500000,
        "Elevation (m)": 267,
        "First Year": 1968,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1994,
        "MLY First Year": 1969,
        "MLY Last Year": 1994
      },
      {
        "Name": "LONGUE-POINTE-DE-MINGAN",
        "Province": "QUEBEC",
        "Climate ID": "7044328",
        "Station ID": 10977,
        "WMO ID": 71512,
        "TC ID": "WBT",
        "Latitude (Decimal Degrees)": 50.27,
        "Longitude (Decimal Degrees)": -64.23,
        "Latitude": 501617000,
        "Longitude": -641335000,
        "Elevation (m)": 11,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MANIC 5",
        "Province": "QUEBEC",
        "Climate ID": "7044451",
        "Station ID": 5704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -68.73,
        "Latitude": 503900000,
        "Longitude": -684400000,
        "Elevation (m)": 167.6,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "MANIC 2 LB1",
        "Province": "QUEBEC",
        "Climate ID": "7044453",
        "Station ID": 5705,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -68.4,
        "Latitude": 491800000,
        "Longitude": -682400000,
        "Elevation (m)": 122,
        "First Year": 1963,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1991,
        "MLY First Year": 1963,
        "MLY Last Year": 1991
      },
      {
        "Name": "MANIC MILLE 76",
        "Province": "QUEBEC",
        "Climate ID": "7044460",
        "Station ID": 5706,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.97,
        "Longitude (Decimal Degrees)": -68.75,
        "Latitude": 495800000,
        "Longitude": -684500000,
        "Elevation (m)": 335.3,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "MANICOUAGAN A",
        "Province": "QUEBEC",
        "Climate ID": "7044470",
        "Station ID": 5707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -68.83,
        "Latitude": 503900000,
        "Longitude": -685000000,
        "Elevation (m)": 406.3,
        "First Year": 1961,
        "Last Year": 1971,
        "HLY First Year": 1961,
        "HLY Last Year": 1971,
        "DLY First Year": 1961,
        "DLY Last Year": 1971,
        "MLY First Year": 1961,
        "MLY Last Year": 1971
      },
      {
        "Name": "MECATINA R",
        "Province": "QUEBEC",
        "Climate ID": "7044760",
        "Station ID": 5708,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.83,
        "Longitude (Decimal Degrees)": -62.88,
        "Latitude": 515000000,
        "Longitude": -625300000,
        "Elevation (m)": 524.3,
        "First Year": 1943,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1952,
        "MLY First Year": 1943,
        "MLY Last Year": 1952
      },
      {
        "Name": "MILE 134",
        "Province": "QUEBEC",
        "Climate ID": "7044960",
        "Station ID": 5709,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.92,
        "Longitude (Decimal Degrees)": -65.82,
        "Latitude": 515500000,
        "Longitude": -654900000,
        "Elevation (m)": null,
        "First Year": 1952,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1953,
        "MLY First Year": 1952,
        "MLY Last Year": 1953
      },
      {
        "Name": "MINGAN",
        "Province": "QUEBEC",
        "Climate ID": "7044980",
        "Station ID": 5710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -64.15,
        "Latitude": 501700000,
        "Longitude": -640900000,
        "Elevation (m)": 23.2,
        "First Year": 1943,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1981,
        "MLY First Year": 1943,
        "MLY Last Year": 1981
      },
      {
        "Name": "MINGAN A",
        "Province": "QUEBEC",
        "Climate ID": "7044981",
        "Station ID": 5711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -64.13,
        "Latitude": 501700000,
        "Longitude": -640800000,
        "Elevation (m)": 21.6,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": 1978,
        "HLY Last Year": 1983,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "MOISIE",
        "Province": "QUEBEC",
        "Climate ID": "7045070",
        "Station ID": 5712,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -66.2,
        "Latitude": 501300000,
        "Longitude": -661200000,
        "Elevation (m)": 45.7,
        "First Year": 1966,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1973,
        "MLY First Year": 1966,
        "MLY Last Year": 1973
      },
      {
        "Name": "MONT STE ANNE",
        "Province": "QUEBEC",
        "Climate ID": "7045325",
        "Station ID": 5713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.08,
        "Longitude (Decimal Degrees)": -70.9,
        "Latitude": 470500000,
        "Longitude": -705400000,
        "Elevation (m)": 182.9,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "MONT STE-ANNE",
        "Province": "QUEBEC",
        "Climate ID": "7045326",
        "Station ID": 43184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -70.91,
        "Latitude": 470424000,
        "Longitude": -705424000,
        "Elevation (m)": 167.6,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONT WRIGHT",
        "Province": "QUEBEC",
        "Climate ID": "7045340",
        "Station ID": 5714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.75,
        "Longitude (Decimal Degrees)": -67.33,
        "Latitude": 524500000,
        "Longitude": -672000000,
        "Elevation (m)": 716.3,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "NATASHQUAN A",
        "Province": "QUEBEC",
        "Climate ID": "7045398",
        "Station ID": 48971,
        "WMO ID": 71513,
        "TC ID": "YNA",
        "Latitude (Decimal Degrees)": 50.19,
        "Longitude (Decimal Degrees)": -61.79,
        "Latitude": 501124000,
        "Longitude": -614720000,
        "Elevation (m)": 11.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NATASHQUAN A",
        "Province": "QUEBEC",
        "Climate ID": "7045400",
        "Station ID": 5715,
        "WMO ID": null,
        "TC ID": "YNA",
        "Latitude (Decimal Degrees)": 50.18,
        "Longitude (Decimal Degrees)": -61.82,
        "Latitude": 501100000,
        "Longitude": -614900000,
        "Elevation (m)": 10.7,
        "First Year": 1914,
        "Last Year": 2008,
        "HLY First Year": 1957,
        "HLY Last Year": 2008,
        "DLY First Year": 1914,
        "DLY Last Year": 2008,
        "MLY First Year": 1914,
        "MLY Last Year": 2003
      },
      {
        "Name": "NATASHQUAN AIRPORT",
        "Province": "QUEBEC",
        "Climate ID": "7045401",
        "Station ID": 30168,
        "WMO ID": 71813,
        "TC ID": "MNT",
        "Latitude (Decimal Degrees)": 50.19,
        "Longitude (Decimal Degrees)": -61.81,
        "Latitude": 501124004,
        "Longitude": -614839001,
        "Elevation (m)": 11.7,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NOTRE DAME DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7045672",
        "Station ID": 5716,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.67,
        "Longitude (Decimal Degrees)": -70.42,
        "Latitude": 474000000,
        "Longitude": -702500000,
        "Elevation (m)": 267,
        "First Year": 1968,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1994,
        "MLY First Year": 1969,
        "MLY Last Year": 1994
      },
      {
        "Name": "OUTARDES 4",
        "Province": "QUEBEC",
        "Climate ID": "7045774",
        "Station ID": 5717,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.7,
        "Longitude (Decimal Degrees)": -68.92,
        "Latitude": 494200000,
        "Longitude": -685500000,
        "Elevation (m)": 289.6,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "PENTECOTE",
        "Province": "QUEBEC",
        "Climate ID": "7045910",
        "Station ID": 5718,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -67.17,
        "Latitude": 494400000,
        "Longitude": -671000000,
        "Elevation (m)": 15,
        "First Year": 1971,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2004,
        "MLY First Year": 1971,
        "MLY Last Year": 2004
      },
      {
        "Name": "PETITE RIV ST FRANCOIS",
        "Province": "QUEBEC",
        "Climate ID": "7046004",
        "Station ID": 5719,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.32,
        "Longitude (Decimal Degrees)": -70.57,
        "Latitude": 471900000,
        "Longitude": -703400000,
        "Elevation (m)": 15,
        "First Year": 1972,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2004,
        "MLY First Year": 1972,
        "MLY Last Year": 2004
      },
      {
        "Name": "PETIT SAGUENAY",
        "Province": "QUEBEC",
        "Climate ID": "7046010",
        "Station ID": 5720,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.18,
        "Longitude (Decimal Degrees)": -70.05,
        "Latitude": 481100000,
        "Longitude": -700300000,
        "Elevation (m)": 122,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "POINTE DE L'ISLET",
        "Province": "QUEBEC",
        "Climate ID": "7046059",
        "Station ID": 10966,
        "WMO ID": 71190,
        "TC ID": "WQD",
        "Latitude (Decimal Degrees)": 48.14,
        "Longitude (Decimal Degrees)": -69.72,
        "Latitude": 480808000,
        "Longitude": -694259000,
        "Elevation (m)": 7,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POINTE DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7046076",
        "Station ID": 5721,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -67.37,
        "Latitude": 491700000,
        "Longitude": -672200000,
        "Elevation (m)": 3,
        "First Year": 1882,
        "Last Year": 1900,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1900,
        "MLY First Year": 1882,
        "MLY Last Year": 1900
      },
      {
        "Name": "POINTE CLAVEAU",
        "Province": "QUEBEC",
        "Climate ID": "704607C",
        "Station ID": 10964,
        "WMO ID": 71189,
        "TC ID": "WQV",
        "Latitude (Decimal Degrees)": 48.26,
        "Longitude (Decimal Degrees)": -70.11,
        "Latitude": 481537000,
        "Longitude": -700652000,
        "Elevation (m)": 4,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POSTE MONTAGNAIS",
        "Province": "QUEBEC",
        "Climate ID": "7046212",
        "Station ID": 5722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.88,
        "Longitude (Decimal Degrees)": -65.73,
        "Latitude": 515300000,
        "Longitude": -654400000,
        "Elevation (m)": 609.6,
        "First Year": 1973,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2004,
        "MLY First Year": 1973,
        "MLY Last Year": 2004
      },
      {
        "Name": "QCMR MILE 56",
        "Province": "QUEBEC",
        "Climate ID": "7046296",
        "Station ID": 5723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.48,
        "Longitude (Decimal Degrees)": -67.42,
        "Latitude": 502900000,
        "Longitude": -672500000,
        "Elevation (m)": 374.9,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "QCMR MILE 106",
        "Province": "QUEBEC",
        "Climate ID": "7046297",
        "Station ID": 5724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -67.5,
        "Latitude": 510400000,
        "Longitude": -673000000,
        "Elevation (m)": 485.2,
        "First Year": 1962,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1963,
        "MLY First Year": 1962,
        "MLY Last Year": 1963
      },
      {
        "Name": "QCMR MILE 146",
        "Province": "QUEBEC",
        "Climate ID": "7046298",
        "Station ID": 5725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.55,
        "Longitude (Decimal Degrees)": -67.43,
        "Latitude": 513300000,
        "Longitude": -672600000,
        "Elevation (m)": 582.2,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "RIVIERE AUX PEKANS",
        "Province": "QUEBEC",
        "Climate ID": "7046580",
        "Station ID": 5726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.22,
        "Longitude (Decimal Degrees)": -67.1,
        "Latitude": 531300000,
        "Longitude": -670600000,
        "Elevation (m)": 487.7,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "RIVIERE ETERNITE",
        "Province": "QUEBEC",
        "Climate ID": "7046619",
        "Station ID": 5727,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -70.4,
        "Latitude": 481500000,
        "Longitude": -702400000,
        "Elevation (m)": 256,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "RIVIERE ST AUGUSTIN",
        "Province": "QUEBEC",
        "Climate ID": "7046663",
        "Station ID": 5728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.22,
        "Longitude (Decimal Degrees)": -58.63,
        "Latitude": 511300000,
        "Longitude": -583800000,
        "Elevation (m)": 7.6,
        "First Year": 1967,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1995,
        "MLY First Year": 1967,
        "MLY Last Year": 1995
      },
      {
        "Name": "SACRE COEUR",
        "Province": "QUEBEC",
        "Climate ID": "7046735",
        "Station ID": 5729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -69.87,
        "Latitude": 481500000,
        "Longitude": -695200000,
        "Elevation (m)": 121.9,
        "First Year": 1982,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1994,
        "MLY First Year": 1982,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE ANNE DE BEAUPRE",
        "Province": "QUEBEC",
        "Climate ID": "7046837",
        "Station ID": 5730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.03,
        "Longitude (Decimal Degrees)": -70.92,
        "Latitude": 470200000,
        "Longitude": -705500000,
        "Elevation (m)": 76.2,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST CASSIEN DES CAPS",
        "Province": "QUEBEC",
        "Climate ID": "7046931",
        "Station ID": 5731,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.37,
        "Longitude (Decimal Degrees)": -70.62,
        "Latitude": 472200000,
        "Longitude": -703700000,
        "Elevation (m)": 373.4,
        "First Year": 1972,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1994,
        "MLY First Year": 1972,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST FEREOL",
        "Province": "QUEBEC",
        "Climate ID": "7047250",
        "Station ID": 5732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.12,
        "Longitude (Decimal Degrees)": -70.83,
        "Latitude": 470700000,
        "Longitude": -705000000,
        "Elevation (m)": 228.6,
        "First Year": 1915,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1983,
        "MLY First Year": 1915,
        "MLY Last Year": 1983
      },
      {
        "Name": "ST FIDELE",
        "Province": "QUEBEC",
        "Climate ID": "7047257",
        "Station ID": 5733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.73,
        "Longitude (Decimal Degrees)": -70,
        "Latitude": 474400000,
        "Longitude": -700000000,
        "Elevation (m)": 259.1,
        "First Year": 1968,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1981,
        "MLY First Year": 1968,
        "MLY Last Year": 1981
      },
      {
        "Name": "ST FIDELE 2",
        "Province": "QUEBEC",
        "Climate ID": "7047258",
        "Station ID": 5734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.73,
        "Longitude (Decimal Degrees)": -69.98,
        "Latitude": 474400000,
        "Longitude": -695900000,
        "Elevation (m)": 251.5,
        "First Year": 1971,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1979,
        "MLY First Year": 1971,
        "MLY Last Year": 1979
      },
      {
        "Name": "ST JEAN (IO)",
        "Province": "QUEBEC",
        "Climate ID": "7047396",
        "Station ID": 5735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -70.92,
        "Latitude": 465500000,
        "Longitude": -705500000,
        "Elevation (m)": 30.5,
        "First Year": 1972,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1994,
        "MLY First Year": 1972,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST JOACHIM",
        "Province": "QUEBEC",
        "Climate ID": "7047415",
        "Station ID": 5736,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -70.83,
        "Latitude": 470400000,
        "Longitude": -705000000,
        "Elevation (m)": 7.6,
        "First Year": 1922,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1940,
        "MLY First Year": 1922,
        "MLY Last Year": 1940
      },
      {
        "Name": "ST LAURENT ILE D'ORLEANS",
        "Province": "QUEBEC",
        "Climate ID": "7047442",
        "Station ID": 5737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -71.05,
        "Latitude": 465200000,
        "Longitude": -710300000,
        "Elevation (m)": 61,
        "First Year": 1964,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1974,
        "MLY First Year": 1964,
        "MLY Last Year": 1974
      },
      {
        "Name": "STE ROSE DU NORD",
        "Province": "QUEBEC",
        "Climate ID": "7047722",
        "Station ID": 5738,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -70.53,
        "Latitude": 482400000,
        "Longitude": -703200000,
        "Elevation (m)": 205,
        "First Year": 1973,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2016,
        "MLY First Year": 1973,
        "MLY Last Year": 2016
      },
      {
        "Name": "ST SIMEON",
        "Province": "QUEBEC",
        "Climate ID": "7047735",
        "Station ID": 5739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.85,
        "Longitude (Decimal Degrees)": -69.87,
        "Latitude": 475100000,
        "Longitude": -695200000,
        "Elevation (m)": 15.2,
        "First Year": 1968,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2004,
        "MLY First Year": 1968,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST TITE DES CAPS",
        "Province": "QUEBEC",
        "Climate ID": "7047762",
        "Station ID": 5740,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.15,
        "Longitude (Decimal Degrees)": -70.77,
        "Latitude": 470900000,
        "Longitude": -704600000,
        "Elevation (m)": 320,
        "First Year": 1968,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1981,
        "MLY First Year": 1968,
        "MLY Last Year": 1981
      },
      {
        "Name": "ST URBAIN",
        "Province": "QUEBEC",
        "Climate ID": "7047770",
        "Station ID": 5741,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -70.55,
        "Latitude": 473400000,
        "Longitude": -703300000,
        "Elevation (m)": 91.4,
        "First Year": 1957,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2004,
        "MLY First Year": 1957,
        "MLY Last Year": 2004
      },
      {
        "Name": "SEPT-ILES A",
        "Province": "QUEBEC",
        "Climate ID": "7047910",
        "Station ID": 5742,
        "WMO ID": null,
        "TC ID": "YZV",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -66.27,
        "Latitude": 501300000,
        "Longitude": -661600000,
        "Elevation (m)": 54.9,
        "First Year": 1944,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1944,
        "DLY Last Year": 2002,
        "MLY First Year": 1944,
        "MLY Last Year": 2001
      },
      {
        "Name": "SEPT-ILES A",
        "Province": "QUEBEC",
        "Climate ID": "7047911",
        "Station ID": 52199,
        "WMO ID": null,
        "TC ID": "YZV",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -66.27,
        "Latitude": 501324000,
        "Longitude": -661556000,
        "Elevation (m)": 54.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SEPT-ILES UA",
        "Province": "QUEBEC",
        "Climate ID": "7047912",
        "Station ID": 8486,
        "WMO ID": 71811,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -66.25,
        "Latitude": 501300000,
        "Longitude": -661500000,
        "Elevation (m)": 53.1,
        "First Year": 2002,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2002,
        "DLY Last Year": 2017,
        "MLY First Year": 2002,
        "MLY Last Year": 2004
      },
      {
        "Name": "SEPT-ILES",
        "Province": "QUEBEC",
        "Climate ID": "7047914",
        "Station ID": 42683,
        "WMO ID": 71279,
        "TC ID": "XZV",
        "Latitude (Decimal Degrees)": 50.22,
        "Longitude (Decimal Degrees)": -66.25,
        "Latitude": 501300000,
        "Longitude": -661500000,
        "Elevation (m)": 52.6,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2005
      },
      {
        "Name": "SHELTER BAY",
        "Province": "QUEBEC",
        "Climate ID": "7048090",
        "Station ID": 5744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.03,
        "Longitude (Decimal Degrees)": -66.9,
        "Latitude": 500200000,
        "Longitude": -665400000,
        "Elevation (m)": 76.2,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "TADOUSSAC",
        "Province": "QUEBEC",
        "Climate ID": "7048320",
        "Station ID": 5745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -69.7,
        "Latitude": 480900000,
        "Longitude": -694200000,
        "Elevation (m)": 70.1,
        "First Year": 1913,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2004,
        "MLY First Year": 1913,
        "MLY Last Year": 2004
      },
      {
        "Name": "TETE A LA BALEINE",
        "Province": "QUEBEC",
        "Climate ID": "7048421",
        "Station ID": 5746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -59.32,
        "Latitude": 504200000,
        "Longitude": -591900000,
        "Elevation (m)": 9.1,
        "First Year": 1965,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1995,
        "MLY First Year": 1966,
        "MLY Last Year": 1995
      },
      {
        "Name": "WACOUNO LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7048800",
        "Station ID": 5747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.42,
        "Longitude (Decimal Degrees)": -65.63,
        "Latitude": 512500000,
        "Longitude": -653800000,
        "Elevation (m)": 589.8,
        "First Year": 1951,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1951,
        "MLY Last Year": 1952
      },
      {
        "Name": "TOUR LAC AUX PERLES",
        "Province": "QUEBEC",
        "Climate ID": "7048N13",
        "Station ID": 5743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -69.83,
        "Latitude": 491000000,
        "Longitude": -695000000,
        "Elevation (m)": 442,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "CAOTIBI",
        "Province": "QUEBEC",
        "Climate ID": "704A0J9",
        "Station ID": 5643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -67.52,
        "Latitude": 504300000,
        "Longitude": -673100000,
        "Elevation (m)": 609.6,
        "First Year": 1975,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1980,
        "MLY First Year": 1977,
        "MLY Last Year": 1980
      },
      {
        "Name": "FERMONT",
        "Province": "QUEBEC",
        "Climate ID": "704BC70",
        "Station ID": 5644,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.8,
        "Longitude (Decimal Degrees)": -67.08,
        "Latitude": 524800000,
        "Longitude": -670500000,
        "Elevation (m)": 594.4,
        "First Year": 1976,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2004,
        "MLY First Year": 1976,
        "MLY Last Year": 2004
      },
      {
        "Name": "HAUTERIVE",
        "Province": "QUEBEC",
        "Climate ID": "704C00H",
        "Station ID": 5646,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -68.3,
        "Latitude": 491200000,
        "Longitude": -681800000,
        "Elevation (m)": 61,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1965
      },
      {
        "Name": "LAC EON",
        "Province": "QUEBEC",
        "Climate ID": "704C64L",
        "Station ID": 8404,
        "WMO ID": 71421,
        "TC ID": "WEO",
        "Latitude (Decimal Degrees)": 51.87,
        "Longitude (Decimal Degrees)": -63.28,
        "Latitude": 515202000,
        "Longitude": -631701000,
        "Elevation (m)": 588.9,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ILE AUX COUDRES",
        "Province": "QUEBEC",
        "Climate ID": "704CBGH",
        "Station ID": 5645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -70.38,
        "Latitude": 472300000,
        "Longitude": -702300000,
        "Elevation (m)": 15.2,
        "First Year": 1968,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2004,
        "MLY First Year": 1969,
        "MLY Last Year": 2004
      },
      {
        "Name": "LES PALISSADES",
        "Province": "QUEBEC",
        "Climate ID": "704DB97",
        "Station ID": 5648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.9,
        "Longitude (Decimal Degrees)": -69.93,
        "Latitude": 475400000,
        "Longitude": -695600000,
        "Elevation (m)": 137.2,
        "First Year": 1975,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1977,
        "MLY First Year": 1975,
        "MLY Last Year": 1977
      },
      {
        "Name": "LES ESCOUMINS",
        "Province": "QUEBEC",
        "Climate ID": "704DB9E",
        "Station ID": 5647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -69.42,
        "Latitude": 482100000,
        "Longitude": -692500000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "MATAMEK",
        "Province": "QUEBEC",
        "Climate ID": "704DFLR",
        "Station ID": 5649,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -65.97,
        "Latitude": 501700000,
        "Longitude": -655800000,
        "Elevation (m)": 4.6,
        "First Year": 1975,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1994,
        "MLY First Year": 1975,
        "MLY Last Year": 1994
      },
      {
        "Name": "POINTE-DES-MONTS",
        "Province": "QUEBEC",
        "Climate ID": "704F0PN",
        "Station ID": 9029,
        "WMO ID": 71427,
        "TC ID": "WTG",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -67.38,
        "Latitude": 491900000,
        "Longitude": -672252000,
        "Elevation (m)": 5.9,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIVIERE AU TONNERRE",
        "Province": "QUEBEC",
        "Climate ID": "704FEG0",
        "Station ID": 5650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.28,
        "Longitude (Decimal Degrees)": -64.78,
        "Latitude": 501700000,
        "Longitude": -644700000,
        "Elevation (m)": 15.2,
        "First Year": 1965,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2022,
        "MLY First Year": 1965,
        "MLY Last Year": 2018
      },
      {
        "Name": "RIVIERE ST JEAN",
        "Province": "QUEBEC",
        "Climate ID": "704FFF5",
        "Station ID": 5651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.3,
        "Longitude (Decimal Degrees)": -64.32,
        "Latitude": 501800000,
        "Longitude": -641900000,
        "Elevation (m)": 14.9,
        "First Year": 1972,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "ST FRANCOIS IO",
        "Province": "QUEBEC",
        "Climate ID": "704GBFF",
        "Station ID": 5652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.98,
        "Longitude (Decimal Degrees)": -70.83,
        "Latitude": 465900000,
        "Longitude": -705000000,
        "Elevation (m)": 48.8,
        "First Year": 1968,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1993,
        "MLY First Year": 1968,
        "MLY Last Year": 1993
      },
      {
        "Name": "ST HILARION",
        "Province": "QUEBEC",
        "Climate ID": "704GC09",
        "Station ID": 5653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.6,
        "Longitude (Decimal Degrees)": -70.4,
        "Latitude": 473600000,
        "Longitude": -702400000,
        "Elevation (m)": 411.5,
        "First Year": 1968,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 2004,
        "MLY First Year": 1968,
        "MLY Last Year": 2004
      },
      {
        "Name": "SAULT AU MOUTON",
        "Province": "QUEBEC",
        "Climate ID": "704GG90",
        "Station ID": 5654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -69.25,
        "Latitude": 483300000,
        "Longitude": -691500000,
        "Elevation (m)": 46.9,
        "First Year": 1982,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1994,
        "MLY First Year": 1982,
        "MLY Last Year": 1994
      },
      {
        "Name": "TOULNUSTOUC",
        "Province": "QUEBEC",
        "Climate ID": "704HE0G",
        "Station ID": 5655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -67.95,
        "Latitude": 500500000,
        "Longitude": -675700000,
        "Elevation (m)": 274.3,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "CAMP M-107",
        "Province": "QUEBEC",
        "Climate ID": "704J01E",
        "Station ID": 5657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -67.48,
        "Latitude": 510400000,
        "Longitude": -672900000,
        "Elevation (m)": 487.7,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "CAMP PROFOND",
        "Province": "QUEBEC",
        "Climate ID": "704J0AF",
        "Station ID": 5656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -67.38,
        "Latitude": 500100000,
        "Longitude": -672300000,
        "Elevation (m)": 106.7,
        "First Year": 1974,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1975,
        "MLY First Year": 1974,
        "MLY Last Year": 1975
      },
      {
        "Name": "POINTE NOIRE CS",
        "Province": "QUEBEC",
        "Climate ID": "704Q003",
        "Station ID": 27326,
        "WMO ID": 71390,
        "TC ID": "WIP",
        "Latitude (Decimal Degrees)": 50.16,
        "Longitude (Decimal Degrees)": -66.43,
        "Latitude": 500945000,
        "Longitude": -662602000,
        "Elevation (m)": 24,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAIE-COMEAU",
        "Province": "QUEBEC",
        "Climate ID": "704S001",
        "Station ID": 26889,
        "WMO ID": 71829,
        "TC ID": "WFW",
        "Latitude (Decimal Degrees)": 49.26,
        "Longitude (Decimal Degrees)": -68.15,
        "Latitude": 491533000,
        "Longitude": -680845000,
        "Elevation (m)": 129.5,
        "First Year": 1995,
        "Last Year": 2022,
        "HLY First Year": 1995,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BLANC-SABLON A (AUT)",
        "Province": "QUEBEC",
        "Climate ID": "704S004",
        "Station ID": 30167,
        "WMO ID": 71808,
        "TC ID": "YBX",
        "Latitude (Decimal Degrees)": 51.45,
        "Longitude (Decimal Degrees)": -57.18,
        "Latitude": 512700000,
        "Longitude": -571100000,
        "Elevation (m)": 36.9,
        "First Year": 2008,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2008,
        "DLY Last Year": 2008,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALBERTVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7050085",
        "Station ID": 5759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -67.35,
        "Latitude": 481800000,
        "Longitude": -672100000,
        "Elevation (m)": 335.3,
        "First Year": 1963,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1985,
        "MLY First Year": 1963,
        "MLY Last Year": 1985
      },
      {
        "Name": "AMHERST ISLAND CDA EPF",
        "Province": "QUEBEC",
        "Climate ID": "7050100",
        "Station ID": 5760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.22,
        "Longitude (Decimal Degrees)": -61.97,
        "Latitude": 471300000,
        "Longitude": -615800000,
        "Elevation (m)": 15.2,
        "First Year": 1957,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1962,
        "MLY First Year": 1959,
        "MLY Last Year": 1962
      },
      {
        "Name": "AMQUI",
        "Province": "QUEBEC",
        "Climate ID": "7050140",
        "Station ID": 5761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -67.45,
        "Latitude": 483100000,
        "Longitude": -672700000,
        "Elevation (m)": 182.9,
        "First Year": 1957,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1997,
        "MLY First Year": 1957,
        "MLY Last Year": 1997
      },
      {
        "Name": "AMQUI",
        "Province": "QUEBEC",
        "Climate ID": "7050145",
        "Station ID": 26887,
        "WMO ID": 71386,
        "TC ID": "WZS",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -67.43,
        "Latitude": 482819000,
        "Longitude": -672605000,
        "Elevation (m)": 166,
        "First Year": 1995,
        "Last Year": 2019,
        "HLY First Year": 1995,
        "HLY Last Year": 2019,
        "DLY First Year": 1996,
        "DLY Last Year": 2019,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ANSE AU GRIFFON",
        "Province": "QUEBEC",
        "Climate ID": "7050195",
        "Station ID": 5762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -64.28,
        "Latitude": 485600000,
        "Longitude": -641700000,
        "Elevation (m)": 61,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "ANTICOSTI HEATH POINT",
        "Province": "QUEBEC",
        "Climate ID": "7050198",
        "Station ID": 5763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -61.7,
        "Latitude": 490600000,
        "Longitude": -614200000,
        "Elevation (m)": 9.1,
        "First Year": 1882,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1935,
        "MLY First Year": 1882,
        "MLY Last Year": 1935
      },
      {
        "Name": "ANTICOSTI SW POINT",
        "Province": "QUEBEC",
        "Climate ID": "7050200",
        "Station ID": 5764,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -63.55,
        "Latitude": 492400000,
        "Longitude": -633300000,
        "Elevation (m)": 7.3,
        "First Year": 1872,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1954,
        "MLY First Year": 1872,
        "MLY Last Year": 1954
      },
      {
        "Name": "ANTICOSTI WEST POINT",
        "Province": "QUEBEC",
        "Climate ID": "7050210",
        "Station ID": 5765,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -64.53,
        "Latitude": 495200000,
        "Longitude": -643200000,
        "Elevation (m)": 9.1,
        "First Year": 1882,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1933,
        "MLY First Year": 1882,
        "MLY Last Year": 1933
      },
      {
        "Name": "ARMAGH",
        "Province": "QUEBEC",
        "Climate ID": "7050240",
        "Station ID": 5766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.75,
        "Longitude (Decimal Degrees)": -70.53,
        "Latitude": 464500000,
        "Longitude": -703200000,
        "Elevation (m)": 358,
        "First Year": 1916,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1994,
        "MLY First Year": 1916,
        "MLY Last Year": 1994
      },
      {
        "Name": "ARMAGH STATION",
        "Province": "QUEBEC",
        "Climate ID": "7050245",
        "Station ID": 5767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.72,
        "Longitude (Decimal Degrees)": -70.62,
        "Latitude": 464300000,
        "Longitude": -703700000,
        "Elevation (m)": 312.4,
        "First Year": 1966,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1986,
        "MLY First Year": 1966,
        "MLY Last Year": 1986
      },
      {
        "Name": "BARRAGE LAC MORIN",
        "Province": "QUEBEC",
        "Climate ID": "7050455",
        "Station ID": 5768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.65,
        "Longitude (Decimal Degrees)": -69.52,
        "Latitude": 473900000,
        "Longitude": -693100000,
        "Elevation (m)": 198.1,
        "First Year": 1942,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1984,
        "MLY First Year": 1942,
        "MLY Last Year": 1984
      },
      {
        "Name": "BARRAGE MISTIGOUGECHE",
        "Province": "QUEBEC",
        "Climate ID": "7050460",
        "Station ID": 5769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.18,
        "Longitude (Decimal Degrees)": -68.02,
        "Latitude": 481100000,
        "Longitude": -680100000,
        "Elevation (m)": 320,
        "First Year": 1961,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1966,
        "MLY First Year": 1961,
        "MLY Last Year": 1966
      },
      {
        "Name": "BARRAGE MITIS",
        "Province": "QUEBEC",
        "Climate ID": "7050463",
        "Station ID": 5770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -67.9,
        "Latitude": 482000000,
        "Longitude": -675400000,
        "Elevation (m)": 266.7,
        "First Year": 1926,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1966,
        "MLY First Year": 1926,
        "MLY Last Year": 1966
      },
      {
        "Name": "BERRY MOUNTAIN",
        "Province": "QUEBEC",
        "Climate ID": "7050675",
        "Station ID": 5771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -66.15,
        "Latitude": 484000000,
        "Longitude": -660900000,
        "Elevation (m)": 152.4,
        "First Year": 1963,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1972,
        "MLY First Year": 1963,
        "MLY Last Year": 1972
      },
      {
        "Name": "BIC",
        "Province": "QUEBEC",
        "Climate ID": "7050760",
        "Station ID": 5772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -68.7,
        "Latitude": 482200000,
        "Longitude": -684200000,
        "Elevation (m)": 18.3,
        "First Year": 1882,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1968,
        "MLY First Year": 1882,
        "MLY Last Year": 1968
      },
      {
        "Name": "BIC",
        "Province": "QUEBEC",
        "Climate ID": "7050762",
        "Station ID": 5773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -68.67,
        "Latitude": 482400000,
        "Longitude": -684000000,
        "Elevation (m)": 23,
        "First Year": 1966,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1985,
        "MLY First Year": 1966,
        "MLY Last Year": 1985
      },
      {
        "Name": "BIRD ROCKS",
        "Province": "QUEBEC",
        "Climate ID": "7050790",
        "Station ID": 5774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.85,
        "Longitude (Decimal Degrees)": -61.13,
        "Latitude": 475100000,
        "Longitude": -610800000,
        "Elevation (m)": 32.3,
        "First Year": 1881,
        "Last Year": 1934,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1881,
        "DLY Last Year": 1934,
        "MLY First Year": 1881,
        "MLY Last Year": 1934
      },
      {
        "Name": "BONAVENTURE",
        "Province": "QUEBEC",
        "Climate ID": "7050817",
        "Station ID": 5775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -65.48,
        "Latitude": 480300000,
        "Longitude": -652900000,
        "Elevation (m)": 19.5,
        "First Year": 1917,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1948,
        "MLY First Year": 1917,
        "MLY Last Year": 1948
      },
      {
        "Name": "BARRIERE MISTIGOUGECHE",
        "Province": "QUEBEC",
        "Climate ID": "7050M73",
        "Station ID": 5758,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.2,
        "Longitude (Decimal Degrees)": -68.07,
        "Latitude": 481200000,
        "Longitude": -680400000,
        "Elevation (m)": 312.4,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "BARRIERE MITIS",
        "Province": "QUEBEC",
        "Climate ID": "7050MGC",
        "Station ID": 5756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -67.93,
        "Latitude": 482200000,
        "Longitude": -675600000,
        "Elevation (m)": 236.2,
        "First Year": 1966,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1982,
        "MLY First Year": 1966,
        "MLY Last Year": 1982
      },
      {
        "Name": "BAIE DES SABLES",
        "Province": "QUEBEC",
        "Climate ID": "7050MM5",
        "Station ID": 5757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -67.83,
        "Latitude": 484200000,
        "Longitude": -675000000,
        "Elevation (m)": 99,
        "First Year": 1981,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2004,
        "MLY First Year": 1981,
        "MLY Last Year": 2004
      },
      {
        "Name": "CAP CHAT",
        "Province": "QUEBEC",
        "Climate ID": "7051040",
        "Station ID": 5776,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -66.75,
        "Latitude": 490500000,
        "Longitude": -664500000,
        "Elevation (m)": 36.6,
        "First Year": 1882,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1974,
        "MLY First Year": 1882,
        "MLY Last Year": 1974
      },
      {
        "Name": "CAP CHAT DELVAL",
        "Province": "QUEBEC",
        "Climate ID": "7051041",
        "Station ID": 5777,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -66.7,
        "Latitude": 490600000,
        "Longitude": -664200000,
        "Elevation (m)": 30.5,
        "First Year": 1974,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1983,
        "MLY First Year": 1974,
        "MLY Last Year": 1983
      },
      {
        "Name": "CAP CHAT EST",
        "Province": "QUEBEC",
        "Climate ID": "7051042",
        "Station ID": 5778,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -66.65,
        "Latitude": 490700000,
        "Longitude": -663900000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "CAP-D'ESPOIR",
        "Province": "QUEBEC",
        "Climate ID": "7051050",
        "Station ID": 9001,
        "WMO ID": 71429,
        "TC ID": "WRZ",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -64.32,
        "Latitude": 482510000,
        "Longitude": -641901000,
        "Elevation (m)": 15.4,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAP DES ROSIERS",
        "Province": "QUEBEC",
        "Climate ID": "7051055",
        "Station ID": 5779,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -64.2,
        "Latitude": 485100000,
        "Longitude": -641200000,
        "Elevation (m)": 15,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "CAP CHAT (AUT)",
        "Province": "QUEBEC",
        "Climate ID": "70510M0",
        "Station ID": 9000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -66.73,
        "Latitude": 490500000,
        "Longitude": -664400000,
        "Elevation (m)": 35.3,
        "First Year": 1993,
        "Last Year": 1996,
        "HLY First Year": 1994,
        "HLY Last Year": 1996,
        "DLY First Year": 1993,
        "DLY Last Year": 1996,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPLAN",
        "Province": "QUEBEC",
        "Climate ID": "7051120",
        "Station ID": 5780,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.1,
        "Longitude (Decimal Degrees)": -65.68,
        "Latitude": 480600000,
        "Longitude": -654100000,
        "Elevation (m)": 22.9,
        "First Year": 1947,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 2004,
        "MLY First Year": 1947,
        "MLY Last Year": 2004
      },
      {
        "Name": "CAP MADELEINE",
        "Province": "QUEBEC",
        "Climate ID": "7051160",
        "Station ID": 5781,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -65.32,
        "Latitude": 491400000,
        "Longitude": -651900000,
        "Elevation (m)": 2,
        "First Year": 1882,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1994,
        "MLY First Year": 1882,
        "MLY Last Year": 1994
      },
      {
        "Name": "CAP-MADELEINE",
        "Province": "QUEBEC",
        "Climate ID": "7051163",
        "Station ID": 8999,
        "WMO ID": 71425,
        "TC ID": "WSF",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -65.32,
        "Latitude": 491503000,
        "Longitude": -651929000,
        "Elevation (m)": 29,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAP SEIZE",
        "Province": "QUEBEC",
        "Climate ID": "7051175",
        "Station ID": 5782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -66.4,
        "Latitude": 490100000,
        "Longitude": -662400000,
        "Elevation (m)": 229,
        "First Year": 1967,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1994,
        "MLY First Year": 1967,
        "MLY Last Year": 1994
      },
      {
        "Name": "CARLETON",
        "Province": "QUEBEC",
        "Climate ID": "7051186",
        "Station ID": 5783,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.12,
        "Longitude (Decimal Degrees)": -66.13,
        "Latitude": 480700000,
        "Longitude": -660800000,
        "Elevation (m)": 12.2,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1976,
        "MLY Last Year": 1984
      },
      {
        "Name": "CASCAPEDIA",
        "Province": "QUEBEC",
        "Climate ID": "7051188",
        "Station ID": 5784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -66.22,
        "Latitude": 484200000,
        "Longitude": -661300000,
        "Elevation (m)": null,
        "First Year": 1918,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1942,
        "MLY First Year": 1918,
        "MLY Last Year": 1942
      },
      {
        "Name": "CAUSAPSCAL",
        "Province": "QUEBEC",
        "Climate ID": "7051200",
        "Station ID": 5785,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -67.23,
        "Latitude": 482200000,
        "Longitude": -671400000,
        "Elevation (m)": 168,
        "First Year": 1913,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2021,
        "MLY First Year": 1913,
        "MLY Last Year": 2018
      },
      {
        "Name": "CAUSAPSCAL AIRPORT",
        "Province": "QUEBEC",
        "Climate ID": "7051201",
        "Station ID": 54558,
        "WMO ID": 73005,
        "TC ID": "GCL",
        "Latitude (Decimal Degrees)": 48.31,
        "Longitude (Decimal Degrees)": -67.25,
        "Latitude": 481831074,
        "Longitude": -671509090,
        "Elevation (m)": 123,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAUSAPSCAL RECHERCHES",
        "Province": "QUEBEC",
        "Climate ID": "7051240",
        "Station ID": 5786,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -67.17,
        "Latitude": 483000000,
        "Longitude": -671000000,
        "Elevation (m)": 332.2,
        "First Year": 1951,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1973,
        "MLY First Year": 1951,
        "MLY Last Year": 1973
      },
      {
        "Name": "CHANDLER",
        "Province": "QUEBEC",
        "Climate ID": "7051292",
        "Station ID": 5787,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -64.68,
        "Latitude": 482100000,
        "Longitude": -644100000,
        "Elevation (m)": 38.1,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "CORTEREAL",
        "Province": "QUEBEC",
        "Climate ID": "7051940",
        "Station ID": 5788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -64.6,
        "Latitude": 485400000,
        "Longitude": -643600000,
        "Elevation (m)": 21.3,
        "First Year": 1956,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1972,
        "MLY First Year": 1956,
        "MLY Last Year": 1972
      },
      {
        "Name": "DEPOT JOHN",
        "Province": "QUEBEC",
        "Climate ID": "7051970",
        "Station ID": 5789,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -67.27,
        "Latitude": 483900000,
        "Longitude": -671600000,
        "Elevation (m)": 99.1,
        "First Year": 1963,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1971,
        "MLY First Year": 1963,
        "MLY Last Year": 1971
      },
      {
        "Name": "FAME POINT",
        "Province": "QUEBEC",
        "Climate ID": "7052310",
        "Station ID": 5791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.1,
        "Longitude (Decimal Degrees)": -64.6,
        "Latitude": 490600000,
        "Longitude": -643600000,
        "Elevation (m)": 53.6,
        "First Year": 1928,
        "Last Year": 1944,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1944,
        "MLY First Year": 1928,
        "MLY Last Year": 1944
      },
      {
        "Name": "FAREWELL COVE",
        "Province": "QUEBEC",
        "Climate ID": "7052316",
        "Station ID": 5792,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -64.45,
        "Latitude": 485200000,
        "Longitude": -642700000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "GASPE",
        "Province": "QUEBEC",
        "Climate ID": "7052600",
        "Station ID": 5793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 485000000,
        "Longitude": -642900000,
        "Elevation (m)": 30,
        "First Year": 1895,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1985,
        "MLY First Year": 1895,
        "MLY Last Year": 1984
      },
      {
        "Name": "GASPE A",
        "Province": "QUEBEC",
        "Climate ID": "7052601",
        "Station ID": 51297,
        "WMO ID": 71188,
        "TC ID": "YGP",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 484631000,
        "Longitude": -642847000,
        "Elevation (m)": 34.1,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GASPE A",
        "Province": "QUEBEC",
        "Climate ID": "7052602",
        "Station ID": 54299,
        "WMO ID": null,
        "TC ID": "YGP",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 484631000,
        "Longitude": -642847000,
        "Elevation (m)": 34.1,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GASPE AIRPORT",
        "Province": "QUEBEC",
        "Climate ID": "7052603",
        "Station ID": 54918,
        "WMO ID": 73116,
        "TC ID": "GGA",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 484700000,
        "Longitude": -642900000,
        "Elevation (m)": 36,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GASPE A",
        "Province": "QUEBEC",
        "Climate ID": "7052605",
        "Station ID": 5794,
        "WMO ID": 71188,
        "TC ID": "YGP",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 484637000,
        "Longitude": -642841000,
        "Elevation (m)": 34.1,
        "First Year": 1965,
        "Last Year": 2013,
        "HLY First Year": 1965,
        "HLY Last Year": 2013,
        "DLY First Year": 1968,
        "DLY Last Year": 2013,
        "MLY First Year": 1968,
        "MLY Last Year": 2013
      },
      {
        "Name": "GASPE 2",
        "Province": "QUEBEC",
        "Climate ID": "7052609",
        "Station ID": 5795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -64.7,
        "Latitude": 490700000,
        "Longitude": -644200000,
        "Elevation (m)": 26.5,
        "First Year": 1947,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1949,
        "MLY First Year": 1947,
        "MLY Last Year": 1949
      },
      {
        "Name": "GRANDE RIVIERE",
        "Province": "QUEBEC",
        "Climate ID": "7052820",
        "Station ID": 5796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.38,
        "Longitude (Decimal Degrees)": -64.53,
        "Latitude": 482300000,
        "Longitude": -643200000,
        "Elevation (m)": 8,
        "First Year": 1963,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1997,
        "MLY First Year": 1963,
        "MLY Last Year": 1997
      },
      {
        "Name": "GRANDE VALLEE",
        "Province": "QUEBEC",
        "Climate ID": "7052865",
        "Station ID": 5797,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -65.15,
        "Latitude": 491200000,
        "Longitude": -650900000,
        "Elevation (m)": 7.6,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "GRINDSTONE ISLAND",
        "Province": "QUEBEC",
        "Climate ID": "7052960",
        "Station ID": 5798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -61.87,
        "Latitude": 472300000,
        "Longitude": -615200000,
        "Elevation (m)": 59.7,
        "First Year": 1871,
        "Last Year": 1983,
        "HLY First Year": 1953,
        "HLY Last Year": 1983,
        "DLY First Year": 1871,
        "DLY Last Year": 1983,
        "MLY First Year": 1871,
        "MLY Last Year": 1983
      },
      {
        "Name": "ETANG A LA TRUITE",
        "Province": "QUEBEC",
        "Climate ID": "7052C09",
        "Station ID": 5790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -66.78,
        "Latitude": 484100000,
        "Longitude": -664700000,
        "Elevation (m)": 337.1,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "HAVRE-AUBERT",
        "Province": "QUEBEC",
        "Climate ID": "7053009",
        "Station ID": 5799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -61.85,
        "Latitude": 471400000,
        "Longitude": -615100000,
        "Elevation (m)": 15.2,
        "First Year": 1978,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1985,
        "MLY First Year": 1978,
        "MLY Last Year": 1985
      },
      {
        "Name": "HAVRE AUX MAISONS",
        "Province": "QUEBEC",
        "Climate ID": "7053010",
        "Station ID": 5800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -61.8,
        "Latitude": 472500000,
        "Longitude": -614800000,
        "Elevation (m)": 15.2,
        "First Year": 1963,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1977,
        "MLY First Year": 1963,
        "MLY Last Year": 1977
      },
      {
        "Name": "HEATH POINT",
        "Province": "QUEBEC",
        "Climate ID": "7053038",
        "Station ID": 8675,
        "WMO ID": 71423,
        "TC ID": "WHP",
        "Latitude (Decimal Degrees)": 49.09,
        "Longitude (Decimal Degrees)": -61.7,
        "Latitude": 490507000,
        "Longitude": -614202000,
        "Elevation (m)": 7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HONFLEUR",
        "Province": "QUEBEC",
        "Climate ID": "7053140",
        "Station ID": 5801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.68,
        "Longitude (Decimal Degrees)": -70.85,
        "Latitude": 464100000,
        "Longitude": -705100000,
        "Elevation (m)": 175,
        "First Year": 1957,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2005,
        "MLY First Year": 1957,
        "MLY Last Year": 2005
      },
      {
        "Name": "ILES DE LA MADELEINE A",
        "Province": "QUEBEC",
        "Climate ID": "7053250",
        "Station ID": 51139,
        "WMO ID": 71709,
        "TC ID": "YGR",
        "Latitude (Decimal Degrees)": 47.43,
        "Longitude (Decimal Degrees)": -61.78,
        "Latitude": 472530000,
        "Longitude": -614641000,
        "Elevation (m)": 10.7,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ILES DE LA MADELEINE A",
        "Province": "QUEBEC",
        "Climate ID": "7053254",
        "Station ID": 53002,
        "WMO ID": null,
        "TC ID": "YGR",
        "Latitude (Decimal Degrees)": 47.43,
        "Longitude (Decimal Degrees)": -61.78,
        "Latitude": 472530000,
        "Longitude": -614641000,
        "Elevation (m)": 10.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KAMOURASKA",
        "Province": "QUEBEC",
        "Climate ID": "7053380",
        "Station ID": 5802,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -69.85,
        "Latitude": 473500000,
        "Longitude": -695100000,
        "Elevation (m)": 9.1,
        "First Year": 1973,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1984,
        "MLY First Year": 1973,
        "MLY Last Year": 1984
      },
      {
        "Name": "LAC HUMQUI",
        "Province": "QUEBEC",
        "Climate ID": "7053649",
        "Station ID": 5803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.28,
        "Longitude (Decimal Degrees)": -67.57,
        "Latitude": 481700000,
        "Longitude": -673400000,
        "Elevation (m)": 235.9,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2018
      },
      {
        "Name": "L'ALVERNE",
        "Province": "QUEBEC",
        "Climate ID": "7053935",
        "Station ID": 5804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.13,
        "Longitude (Decimal Degrees)": -66.7,
        "Latitude": 480800000,
        "Longitude": -664200000,
        "Elevation (m)": 290,
        "First Year": 1977,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1999,
        "MLY First Year": 1977,
        "MLY Last Year": 1999
      },
      {
        "Name": "LAMARTINE",
        "Province": "QUEBEC",
        "Climate ID": "7053980",
        "Station ID": 5805,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.03,
        "Longitude (Decimal Degrees)": -70.35,
        "Latitude": 470200000,
        "Longitude": -702100000,
        "Elevation (m)": 63,
        "First Year": 1960,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1994,
        "MLY First Year": 1960,
        "MLY Last Year": 1994
      },
      {
        "Name": "ILES DE LA MADELEINE",
        "Province": "QUEBEC",
        "Climate ID": "7053KGR",
        "Station ID": 10763,
        "WMO ID": 71710,
        "TC ID": "WGR",
        "Latitude (Decimal Degrees)": 47.43,
        "Longitude (Decimal Degrees)": -61.77,
        "Latitude": 472531000,
        "Longitude": -614629000,
        "Elevation (m)": 7.6,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1993,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA POCATIERE CDA",
        "Province": "QUEBEC",
        "Climate ID": "7054095",
        "Station ID": 5806,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.35,
        "Longitude (Decimal Degrees)": -70.03,
        "Latitude": 472100000,
        "Longitude": -700200000,
        "Elevation (m)": 30.5,
        "First Year": 1899,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1899,
        "DLY Last Year": 1996,
        "MLY First Year": 1899,
        "MLY Last Year": 1996
      },
      {
        "Name": "LA POCATIERE",
        "Province": "QUEBEC",
        "Climate ID": "7054096",
        "Station ID": 26893,
        "WMO ID": 71713,
        "TC ID": "WST",
        "Latitude (Decimal Degrees)": 47.36,
        "Longitude (Decimal Degrees)": -70.03,
        "Latitude": 472121000,
        "Longitude": -700155000,
        "Elevation (m)": 31,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LE GITE",
        "Province": "QUEBEC",
        "Climate ID": "7054278",
        "Station ID": 5807,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -66.13,
        "Latitude": 485700000,
        "Longitude": -660800000,
        "Elevation (m)": 228.9,
        "First Year": 1967,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1982,
        "MLY First Year": 1967,
        "MLY Last Year": 1982
      },
      {
        "Name": "LES FONDS",
        "Province": "QUEBEC",
        "Climate ID": "7054296",
        "Station ID": 5808,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -66.68,
        "Latitude": 490300000,
        "Longitude": -664100000,
        "Elevation (m)": 14.9,
        "First Year": 1969,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1989,
        "MLY First Year": 1969,
        "MLY Last Year": 1989
      },
      {
        "Name": "LUCEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7054355",
        "Station ID": 5809,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -68.37,
        "Latitude": 483100000,
        "Longitude": -682200000,
        "Elevation (m)": 38.1,
        "First Year": 1964,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1985,
        "MLY First Year": 1964,
        "MLY Last Year": 1985
      },
      {
        "Name": "MANCHE D'EPEE",
        "Province": "QUEBEC",
        "Climate ID": "7054448",
        "Station ID": 6914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -65.43,
        "Latitude": 491400000,
        "Longitude": -652600000,
        "Elevation (m)": 30,
        "First Year": 1992,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1994,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "MATANE",
        "Province": "QUEBEC",
        "Climate ID": "7054640",
        "Station ID": 5810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -67.47,
        "Latitude": 485100000,
        "Longitude": -672800000,
        "Elevation (m)": 30,
        "First Year": 1951,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2004,
        "MLY First Year": 1951,
        "MLY Last Year": 2004
      },
      {
        "Name": "MATAPEDIA",
        "Province": "QUEBEC",
        "Climate ID": "7054680",
        "Station ID": 5811,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -66.93,
        "Latitude": 475800000,
        "Longitude": -665600000,
        "Elevation (m)": 16.2,
        "First Year": 1927,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1973,
        "MLY First Year": 1927,
        "MLY Last Year": 1973
      },
      {
        "Name": "MINE MADELEINE",
        "Province": "QUEBEC",
        "Climate ID": "7054975",
        "Station ID": 5812,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -66.02,
        "Latitude": 485900000,
        "Longitude": -660100000,
        "Elevation (m)": 780.3,
        "First Year": 1974,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1982,
        "MLY First Year": 1974,
        "MLY Last Year": 1982
      },
      {
        "Name": "MONT BLEU",
        "Province": "QUEBEC",
        "Climate ID": "7055104",
        "Station ID": 5813,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -69.38,
        "Latitude": 473400000,
        "Longitude": -692300000,
        "Elevation (m)": 657.1,
        "First Year": 1963,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1989,
        "MLY First Year": 1963,
        "MLY Last Year": 1989
      },
      {
        "Name": "MONT-JOLI A",
        "Province": "QUEBEC",
        "Climate ID": "7055120",
        "Station ID": 5814,
        "WMO ID": 71718,
        "TC ID": "YYY",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -68.22,
        "Latitude": 483600000,
        "Longitude": -681300000,
        "Elevation (m)": 52.4,
        "First Year": 1943,
        "Last Year": 2013,
        "HLY First Year": 1953,
        "HLY Last Year": 2013,
        "DLY First Year": 1943,
        "DLY Last Year": 2013,
        "MLY First Year": 1943,
        "MLY Last Year": 2005
      },
      {
        "Name": "MONT JOLI A",
        "Province": "QUEBEC",
        "Climate ID": "7055121",
        "Station ID": 50841,
        "WMO ID": 71718,
        "TC ID": "YYY",
        "Latitude (Decimal Degrees)": 48.61,
        "Longitude (Decimal Degrees)": -68.21,
        "Latitude": 483632000,
        "Longitude": -681227000,
        "Elevation (m)": 52.4,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONT-JOLI",
        "Province": "QUEBEC",
        "Climate ID": "7055122",
        "Station ID": 54118,
        "WMO ID": 71658,
        "TC ID": "MJI",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -68.19,
        "Latitude": 483615901,
        "Longitude": -681140682,
        "Elevation (m)": 55,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONT LOGAN",
        "Province": "QUEBEC",
        "Climate ID": "7055180",
        "Station ID": 5815,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -66.63,
        "Latitude": 485400000,
        "Longitude": -663800000,
        "Elevation (m)": 1127.8,
        "First Year": 1963,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1975,
        "MLY First Year": 1963,
        "MLY Last Year": 1975
      },
      {
        "Name": "MONT LOUIS",
        "Province": "QUEBEC",
        "Climate ID": "7055200",
        "Station ID": 5816,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.23,
        "Longitude (Decimal Degrees)": -65.73,
        "Latitude": 491400000,
        "Longitude": -654400000,
        "Elevation (m)": 15.2,
        "First Year": 1933,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1994,
        "MLY First Year": 1933,
        "MLY Last Year": 1994
      },
      {
        "Name": "MONTMAGNY",
        "Province": "QUEBEC",
        "Climate ID": "7055210",
        "Station ID": 5817,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.97,
        "Longitude (Decimal Degrees)": -70.58,
        "Latitude": 465800000,
        "Longitude": -703500000,
        "Elevation (m)": 15.2,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "MURDOCHVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7055380",
        "Station ID": 5818,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -65.52,
        "Latitude": 485700000,
        "Longitude": -653100000,
        "Elevation (m)": 574.5,
        "First Year": 1952,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1995,
        "MLY First Year": 1952,
        "MLY Last Year": 1995
      },
      {
        "Name": "NEW CARLISLE",
        "Province": "QUEBEC",
        "Climate ID": "7055420",
        "Station ID": 5819,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.03,
        "Longitude (Decimal Degrees)": -65.27,
        "Latitude": 480200000,
        "Longitude": -651600000,
        "Elevation (m)": 45,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "NEW CARLISLE 1",
        "Province": "QUEBEC",
        "Climate ID": "7055422",
        "Station ID": 10898,
        "WMO ID": 71619,
        "TC ID": "WOC",
        "Latitude (Decimal Degrees)": 48.01,
        "Longitude (Decimal Degrees)": -65.33,
        "Latitude": 480043000,
        "Longitude": -651954000,
        "Elevation (m)": 46.4,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NEW RICHMOND",
        "Province": "QUEBEC",
        "Climate ID": "7055430",
        "Station ID": 5820,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.17,
        "Longitude (Decimal Degrees)": -65.8,
        "Latitude": 481000000,
        "Longitude": -654800000,
        "Elevation (m)": 47,
        "First Year": 1964,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1991,
        "MLY First Year": 1964,
        "MLY Last Year": 1991
      },
      {
        "Name": "NEW RICHMOND EAST",
        "Province": "QUEBEC",
        "Climate ID": "7055440",
        "Station ID": 54559,
        "WMO ID": 71659,
        "TC ID": "MJN",
        "Latitude (Decimal Degrees)": 48.16,
        "Longitude (Decimal Degrees)": -65.84,
        "Latitude": 480923064,
        "Longitude": -655031002,
        "Elevation (m)": 2,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NOTRE DAME DU LAC",
        "Province": "QUEBEC",
        "Climate ID": "7055675",
        "Station ID": 5821,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.6,
        "Longitude (Decimal Degrees)": -68.8,
        "Latitude": 473600000,
        "Longitude": -684800000,
        "Elevation (m)": 320,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "NOTRE DAME DU ROSAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7055685",
        "Station ID": 5822,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.83,
        "Longitude (Decimal Degrees)": -70.4,
        "Latitude": 465000000,
        "Longitude": -702400000,
        "Elevation (m)": 373.4,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "NOUVELLE",
        "Province": "QUEBEC",
        "Climate ID": "7055705",
        "Station ID": 5823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.1,
        "Longitude (Decimal Degrees)": -66.3,
        "Latitude": 480600000,
        "Longitude": -661800000,
        "Elevation (m)": 7,
        "First Year": 1969,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2004,
        "MLY First Year": 1969,
        "MLY Last Year": 2004
      },
      {
        "Name": "OUIMET",
        "Province": "QUEBEC",
        "Climate ID": "7055770",
        "Station ID": 5824,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.32,
        "Longitude (Decimal Degrees)": -68.2,
        "Latitude": 481900000,
        "Longitude": -681200000,
        "Elevation (m)": 305,
        "First Year": 1963,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1993,
        "MLY First Year": 1963,
        "MLY Last Year": 1993
      },
      {
        "Name": "PARC DE LA GASPESIE",
        "Province": "QUEBEC",
        "Climate ID": "7055790",
        "Station ID": 5825,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -66.37,
        "Latitude": 485900000,
        "Longitude": -662200000,
        "Elevation (m)": 208.2,
        "First Year": 1963,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1965,
        "MLY First Year": 1963,
        "MLY Last Year": 1965
      },
      {
        "Name": "PARC METIS",
        "Province": "QUEBEC",
        "Climate ID": "7055793",
        "Station ID": 5826,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -68.13,
        "Latitude": 483800000,
        "Longitude": -680800000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1985,
        "MLY First Year": 1971,
        "MLY Last Year": 1985
      },
      {
        "Name": "PARKE",
        "Province": "QUEBEC",
        "Climate ID": "7055808",
        "Station ID": 5827,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -69.52,
        "Latitude": 473400000,
        "Longitude": -693100000,
        "Elevation (m)": 281.9,
        "First Year": 1963,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1982,
        "MLY First Year": 1963,
        "MLY Last Year": 1982
      },
      {
        "Name": "PERCE",
        "Province": "QUEBEC",
        "Climate ID": "7055920",
        "Station ID": 5828,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -64.2,
        "Latitude": 483100000,
        "Longitude": -641200000,
        "Elevation (m)": 87.8,
        "First Year": 1896,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1951,
        "MLY First Year": 1896,
        "MLY Last Year": 1951
      },
      {
        "Name": "PETITE MATANE",
        "Province": "QUEBEC",
        "Climate ID": "7056003",
        "Station ID": 5830,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -67.45,
        "Latitude": 485200000,
        "Longitude": -672700000,
        "Elevation (m)": 3,
        "First Year": 1972,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1985,
        "MLY First Year": 1972,
        "MLY Last Year": 1985
      },
      {
        "Name": "POINTE AU PERE",
        "Province": "QUEBEC",
        "Climate ID": "7056067",
        "Station ID": 5831,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -68.48,
        "Latitude": 483000000,
        "Longitude": -682900000,
        "Elevation (m)": 7.6,
        "First Year": 1873,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1873,
        "DLY Last Year": 1982,
        "MLY First Year": 1873,
        "MLY Last Year": 1982
      },
      {
        "Name": "POINTE-AU-PERE (INRS)",
        "Province": "QUEBEC",
        "Climate ID": "7056068",
        "Station ID": 27194,
        "WMO ID": 71554,
        "TC ID": "WYQ",
        "Latitude (Decimal Degrees)": 48.51,
        "Longitude (Decimal Degrees)": -68.47,
        "Latitude": 483050000,
        "Longitude": -682806000,
        "Elevation (m)": 4.9,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POINTE FREGATE",
        "Province": "QUEBEC",
        "Climate ID": "70560GF",
        "Station ID": 5829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -64.93,
        "Latitude": 491200000,
        "Longitude": -645600000,
        "Elevation (m)": 6.1,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "PORT DANIEL",
        "Province": "QUEBEC",
        "Climate ID": "7056120",
        "Station ID": 5832,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -64.98,
        "Latitude": 480900000,
        "Longitude": -645900000,
        "Elevation (m)": 68.6,
        "First Year": 1927,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 2004,
        "MLY First Year": 1927,
        "MLY Last Year": 2004
      },
      {
        "Name": "PORT MENIER",
        "Province": "QUEBEC",
        "Climate ID": "7056200",
        "Station ID": 5833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -64.35,
        "Latitude": 494900000,
        "Longitude": -642100000,
        "Elevation (m)": 5.5,
        "First Year": 1929,
        "Last Year": 1974,
        "HLY First Year": 1960,
        "HLY Last Year": 1974,
        "DLY First Year": 1929,
        "DLY Last Year": 1974,
        "MLY First Year": 1929,
        "MLY Last Year": 1974
      },
      {
        "Name": "PORT MENIER",
        "Province": "QUEBEC",
        "Climate ID": "7056201",
        "Station ID": 5834,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -64.45,
        "Latitude": 495100000,
        "Longitude": -642700000,
        "Elevation (m)": 34.4,
        "First Year": 1974,
        "Last Year": 1981,
        "HLY First Year": 1974,
        "HLY Last Year": 1981,
        "DLY First Year": 1974,
        "DLY Last Year": 1981,
        "MLY First Year": 1974,
        "MLY Last Year": 1981
      },
      {
        "Name": "PORT-MENIER",
        "Province": "QUEBEC",
        "Climate ID": "7056202",
        "Station ID": 8536,
        "WMO ID": 71810,
        "TC ID": "WBY",
        "Latitude (Decimal Degrees)": 49.84,
        "Longitude (Decimal Degrees)": -64.29,
        "Latitude": 495019000,
        "Longitude": -641736000,
        "Elevation (m)": 55.2,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PRICE",
        "Province": "QUEBEC",
        "Climate ID": "7056240",
        "Station ID": 5835,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -68.13,
        "Latitude": 483600000,
        "Longitude": -680800000,
        "Elevation (m)": 73.2,
        "First Year": 1931,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1985,
        "MLY First Year": 1931,
        "MLY Last Year": 1985
      },
      {
        "Name": "RIMOUSKI",
        "Province": "QUEBEC",
        "Climate ID": "7056480",
        "Station ID": 5836,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -68.52,
        "Latitude": 482700000,
        "Longitude": -683100000,
        "Elevation (m)": 35.7,
        "First Year": 1952,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2022,
        "MLY First Year": 1952,
        "MLY Last Year": 2017
      },
      {
        "Name": "RIVIERE AU RENARD",
        "Province": "QUEBEC",
        "Climate ID": "7056563",
        "Station ID": 5837,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -64.4,
        "Latitude": 490100000,
        "Longitude": -642400000,
        "Elevation (m)": 40.8,
        "First Year": 1958,
        "Last Year": 1977,
        "HLY First Year": 1958,
        "HLY Last Year": 1977,
        "DLY First Year": 1958,
        "DLY Last Year": 1977,
        "MLY First Year": 1958,
        "MLY Last Year": 1977
      },
      {
        "Name": "RIVIERE BLEUE",
        "Province": "QUEBEC",
        "Climate ID": "7056600",
        "Station ID": 5838,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.43,
        "Longitude (Decimal Degrees)": -69.03,
        "Latitude": 472600000,
        "Longitude": -690200000,
        "Elevation (m)": 213,
        "First Year": 1950,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2004,
        "MLY First Year": 1950,
        "MLY Last Year": 2004
      },
      {
        "Name": "RIVIERE DU LOUP",
        "Province": "QUEBEC",
        "Climate ID": "7056615",
        "Station ID": 5839,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -69.55,
        "Latitude": 474800000,
        "Longitude": -693300000,
        "Elevation (m)": 148.4,
        "First Year": 1965,
        "Last Year": 1980,
        "HLY First Year": 1965,
        "HLY Last Year": 1980,
        "DLY First Year": 1965,
        "DLY Last Year": 1980,
        "MLY First Year": 1965,
        "MLY Last Year": 1980
      },
      {
        "Name": "RIVIERE-DU-LOUP",
        "Province": "QUEBEC",
        "Climate ID": "7056616",
        "Station ID": 8539,
        "WMO ID": 71715,
        "TC ID": "WNH",
        "Latitude (Decimal Degrees)": 47.81,
        "Longitude (Decimal Degrees)": -69.55,
        "Latitude": 474821000,
        "Longitude": -693255000,
        "Elevation (m)": 146.5,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIVIERE MANIE",
        "Province": "QUEBEC",
        "Climate ID": "7056640",
        "Station ID": 5840,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.4,
        "Longitude (Decimal Degrees)": -69.65,
        "Latitude": 472400000,
        "Longitude": -693900000,
        "Elevation (m)": 307.8,
        "First Year": 1953,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1964,
        "MLY First Year": 1953,
        "MLY Last Year": 1964
      },
      {
        "Name": "ROMIEU SUD",
        "Province": "QUEBEC",
        "Climate ID": "7056695",
        "Station ID": 5841,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -66.8,
        "Latitude": 485600000,
        "Longitude": -664800000,
        "Elevation (m)": 396.2,
        "First Year": 1966,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1971,
        "MLY First Year": 1966,
        "MLY Last Year": 1971
      },
      {
        "Name": "ST ALEXIS DE MATAPEDIA",
        "Province": "QUEBEC",
        "Climate ID": "7056814",
        "Station ID": 5842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.98,
        "Longitude (Decimal Degrees)": -67.07,
        "Latitude": 475900000,
        "Longitude": -670400000,
        "Elevation (m)": 274,
        "First Year": 1964,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2004,
        "MLY First Year": 1964,
        "MLY Last Year": 2004
      },
      {
        "Name": "STE ANNE DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7056850",
        "Station ID": 5843,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.13,
        "Longitude (Decimal Degrees)": -66.47,
        "Latitude": 490800000,
        "Longitude": -662800000,
        "Elevation (m)": 15.2,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "RIVIERE-DU-LOUP / ST-ANTONIN",
        "Province": "QUEBEC",
        "Climate ID": "7056870",
        "Station ID": 43165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -69.45,
        "Latitude": 474800000,
        "Longitude": -692700000,
        "Elevation (m)": 152,
        "First Year": 2005,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2005,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST ARSENE",
        "Province": "QUEBEC",
        "Climate ID": "7056890",
        "Station ID": 5844,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.95,
        "Longitude (Decimal Degrees)": -69.38,
        "Latitude": 475700000,
        "Longitude": -692300000,
        "Elevation (m)": 76.2,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST BRUNO KAMOURASKA",
        "Province": "QUEBEC",
        "Climate ID": "7056922",
        "Station ID": 5845,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -69.78,
        "Latitude": 472700000,
        "Longitude": -694700000,
        "Elevation (m)": 198.1,
        "First Year": 1964,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2004,
        "MLY First Year": 1964,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST CAMILLE",
        "Province": "QUEBEC",
        "Climate ID": "7056930",
        "Station ID": 5846,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -70.22,
        "Latitude": 462900000,
        "Longitude": -701300000,
        "Elevation (m)": 396,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST CHARLES GARNIER",
        "Province": "QUEBEC",
        "Climate ID": "7056970",
        "Station ID": 5847,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -68.05,
        "Latitude": 482000000,
        "Longitude": -680300000,
        "Elevation (m)": 323.1,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST CLEMENT",
        "Province": "QUEBEC",
        "Climate ID": "7057024",
        "Station ID": 5848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.92,
        "Longitude (Decimal Degrees)": -69.1,
        "Latitude": 475500000,
        "Longitude": -690600000,
        "Elevation (m)": 259.1,
        "First Year": 1964,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2004,
        "MLY First Year": 1964,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST DAMASE DE MATANE",
        "Province": "QUEBEC",
        "Climate ID": "7057089",
        "Station ID": 5849,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -67.82,
        "Latitude": 483900000,
        "Longitude": -674900000,
        "Elevation (m)": 190.5,
        "First Year": 1976,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1985,
        "MLY First Year": 1976,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST DAMASE DES AULNAIES",
        "Province": "QUEBEC",
        "Climate ID": "7057090",
        "Station ID": 5850,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.2,
        "Longitude (Decimal Degrees)": -70.13,
        "Latitude": 471200000,
        "Longitude": -700800000,
        "Elevation (m)": 274,
        "First Year": 1973,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1994,
        "MLY First Year": 1973,
        "MLY Last Year": 1994
      },
      {
        "Name": "SAINT-DENIS-DE-KAMOURASKA",
        "Province": "QUEBEC",
        "Climate ID": "7057096",
        "Station ID": 43681,
        "WMO ID": null,
        "TC ID": "MSD",
        "Latitude (Decimal Degrees)": 47.51,
        "Longitude (Decimal Degrees)": -69.88,
        "Latitude": 473043000,
        "Longitude": -695300000,
        "Elevation (m)": 14,
        "First Year": 2005,
        "Last Year": 2007,
        "HLY First Year": 2005,
        "HLY Last Year": 2007,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST ELEUTHERE",
        "Province": "QUEBEC",
        "Climate ID": "7057145",
        "Station ID": 5851,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.48,
        "Longitude (Decimal Degrees)": -69.28,
        "Latitude": 472900000,
        "Longitude": -691700000,
        "Elevation (m)": 290,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST ELZEAR DE BONAVENTURE",
        "Province": "QUEBEC",
        "Climate ID": "7057160",
        "Station ID": 5852,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.18,
        "Longitude (Decimal Degrees)": -65.35,
        "Latitude": 481100000,
        "Longitude": -652100000,
        "Elevation (m)": 229,
        "First Year": 1948,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 2004,
        "MLY First Year": 1948,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST GABRIEL",
        "Province": "QUEBEC",
        "Climate ID": "7057269",
        "Station ID": 5853,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.48,
        "Longitude (Decimal Degrees)": -68.15,
        "Latitude": 482900000,
        "Longitude": -680900000,
        "Elevation (m)": 259.1,
        "First Year": 1981,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1994
      },
      {
        "Name": "STE GERMAINE",
        "Province": "QUEBEC",
        "Climate ID": "7057287",
        "Station ID": 5854,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -70.47,
        "Latitude": 462500000,
        "Longitude": -702800000,
        "Elevation (m)": 510.5,
        "First Year": 1963,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2013,
        "MLY First Year": 1963,
        "MLY Last Year": 2013
      },
      {
        "Name": "ST GUY",
        "Province": "QUEBEC",
        "Climate ID": "7057304",
        "Station ID": 5855,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -68.82,
        "Latitude": 480300000,
        "Longitude": -684900000,
        "Elevation (m)": 320,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST JEAN DE BREBEUF DUGAL",
        "Province": "QUEBEC",
        "Climate ID": "7057393",
        "Station ID": 5856,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.2,
        "Longitude (Decimal Degrees)": -66.33,
        "Latitude": 481200000,
        "Longitude": -662000000,
        "Elevation (m)": 320,
        "First Year": 1963,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1968,
        "MLY First Year": 1963,
        "MLY Last Year": 1968
      },
      {
        "Name": "ST JEAN DE CHERBOURG",
        "Province": "QUEBEC",
        "Climate ID": "7057395",
        "Station ID": 5857,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -67.12,
        "Latitude": 485300000,
        "Longitude": -670700000,
        "Elevation (m)": 350.5,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "STE LUCIE",
        "Province": "QUEBEC",
        "Climate ID": "7057515",
        "Station ID": 5858,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.73,
        "Longitude (Decimal Degrees)": -70.02,
        "Latitude": 464400000,
        "Longitude": -700100000,
        "Elevation (m)": 373,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST MALACHIE",
        "Province": "QUEBEC",
        "Climate ID": "7057518",
        "Station ID": 5859,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.55,
        "Longitude (Decimal Degrees)": -70.82,
        "Latitude": 463300000,
        "Longitude": -704900000,
        "Elevation (m)": 221,
        "First Year": 1965,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1994,
        "MLY First Year": 1965,
        "MLY Last Year": 1994
      },
      {
        "Name": "SAINTE MARGUERITE MARIE",
        "Province": "QUEBEC",
        "Climate ID": "7057525",
        "Station ID": 5860,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.32,
        "Longitude (Decimal Degrees)": -67.1,
        "Latitude": 481900000,
        "Longitude": -670600000,
        "Elevation (m)": 445,
        "First Year": 1978,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1991,
        "MLY First Year": 1978,
        "MLY Last Year": 1991
      },
      {
        "Name": "SAINT MICHEL",
        "Province": "QUEBEC",
        "Climate ID": "7057567",
        "Station ID": 5861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -70.88,
        "Latitude": 465200000,
        "Longitude": -705300000,
        "Elevation (m)": 69,
        "First Year": 1978,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2022,
        "MLY First Year": 1978,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST MODESTE",
        "Province": "QUEBEC",
        "Climate ID": "7057574",
        "Station ID": 5862,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.85,
        "Longitude (Decimal Degrees)": -69.38,
        "Latitude": 475100000,
        "Longitude": -692300000,
        "Elevation (m)": 152.4,
        "First Year": 1973,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1985,
        "MLY First Year": 1973,
        "MLY Last Year": 1985
      },
      {
        "Name": "ST OCTAVE DE L'AVENIR",
        "Province": "QUEBEC",
        "Climate ID": "7057590",
        "Station ID": 5863,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -66.55,
        "Latitude": 485900000,
        "Longitude": -663300000,
        "Elevation (m)": 381,
        "First Year": 1963,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1997,
        "MLY First Year": 1963,
        "MLY Last Year": 1997
      },
      {
        "Name": "ST PAMPHILE",
        "Province": "QUEBEC",
        "Climate ID": "7057600",
        "Station ID": 5864,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.97,
        "Longitude (Decimal Degrees)": -69.78,
        "Latitude": 465800000,
        "Longitude": -694700000,
        "Elevation (m)": 388,
        "First Year": 1950,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2004,
        "MLY First Year": 1950,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST PASCAL",
        "Province": "QUEBEC",
        "Climate ID": "7057625",
        "Station ID": 5865,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.53,
        "Longitude (Decimal Degrees)": -69.82,
        "Latitude": 473200000,
        "Longitude": -694900000,
        "Elevation (m)": 30.5,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "ST PAULIN D'ALIBAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7057645",
        "Station ID": 5866,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.92,
        "Longitude (Decimal Degrees)": -66.83,
        "Latitude": 485500000,
        "Longitude": -665000000,
        "Elevation (m)": 381,
        "First Year": 1967,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1971,
        "MLY First Year": 1967,
        "MLY Last Year": 1971
      },
      {
        "Name": "STE PERPETUE",
        "Province": "QUEBEC",
        "Climate ID": "7057650",
        "Station ID": 5867,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.05,
        "Longitude (Decimal Degrees)": -69.93,
        "Latitude": 470300000,
        "Longitude": -695600000,
        "Elevation (m)": 411,
        "First Year": 1963,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1997,
        "MLY First Year": 1963,
        "MLY Last Year": 1997
      },
      {
        "Name": "ST PHILEMON",
        "Province": "QUEBEC",
        "Climate ID": "7057652",
        "Station ID": 5868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -70.42,
        "Latitude": 464000000,
        "Longitude": -702500000,
        "Elevation (m)": 449.9,
        "First Year": 1982,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1993,
        "MLY First Year": 1982,
        "MLY Last Year": 1993
      },
      {
        "Name": "ST RAPHAEL",
        "Province": "QUEBEC",
        "Climate ID": "7057680",
        "Station ID": 5869,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -70.75,
        "Latitude": 464900000,
        "Longitude": -704500000,
        "Elevation (m)": 106.7,
        "First Year": 1949,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1982,
        "MLY First Year": 1949,
        "MLY Last Year": 1982
      },
      {
        "Name": "ST RENE DE MATANE",
        "Province": "QUEBEC",
        "Climate ID": "7057692",
        "Station ID": 5870,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -67.38,
        "Latitude": 484100000,
        "Longitude": -672300000,
        "Elevation (m)": 61,
        "First Year": 1973,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2004,
        "MLY First Year": 1973,
        "MLY Last Year": 2004
      },
      {
        "Name": "STE ROSE DU DEGELIS",
        "Province": "QUEBEC",
        "Climate ID": "7057720",
        "Station ID": 5871,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -68.63,
        "Latitude": 473400000,
        "Longitude": -683800000,
        "Elevation (m)": 150.9,
        "First Year": 1932,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1932,
        "DLY Last Year": 2004,
        "MLY First Year": 1932,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST VALLIER",
        "Province": "QUEBEC",
        "Climate ID": "7057775",
        "Station ID": 5872,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.83,
        "Longitude (Decimal Degrees)": -70.83,
        "Latitude": 465000000,
        "Longitude": -705000000,
        "Elevation (m)": 91.4,
        "First Year": 1959,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "SQUATECK",
        "Province": "QUEBEC",
        "Climate ID": "7058220",
        "Station ID": 5873,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.88,
        "Longitude (Decimal Degrees)": -68.7,
        "Latitude": 475300000,
        "Longitude": -684200000,
        "Elevation (m)": 198,
        "First Year": 1963,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1994,
        "MLY First Year": 1963,
        "MLY Last Year": 1994
      },
      {
        "Name": "TRINITE DES MONTS",
        "Province": "QUEBEC",
        "Climate ID": "7058520",
        "Station ID": 5874,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.13,
        "Longitude (Decimal Degrees)": -68.48,
        "Latitude": 480800000,
        "Longitude": -682900000,
        "Elevation (m)": 262,
        "First Year": 1950,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2004,
        "MLY First Year": 1950,
        "MLY Last Year": 2004
      },
      {
        "Name": "TROIS PISTOLES",
        "Province": "QUEBEC",
        "Climate ID": "7058560",
        "Station ID": 5875,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -69.12,
        "Latitude": 480900000,
        "Longitude": -690700000,
        "Elevation (m)": 58,
        "First Year": 1951,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2004,
        "MLY First Year": 1951,
        "MLY Last Year": 2004
      },
      {
        "Name": "TROUT RIVER DEPOT",
        "Province": "QUEBEC",
        "Climate ID": "7058565",
        "Station ID": 5876,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -66.78,
        "Latitude": 484100000,
        "Longitude": -664700000,
        "Elevation (m)": 336.8,
        "First Year": 1963,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1981,
        "MLY First Year": 1963,
        "MLY Last Year": 1981
      },
      {
        "Name": "VAL D ESPOIR",
        "Province": "QUEBEC",
        "Climate ID": "7058590",
        "Station ID": 5877,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 483100000,
        "Longitude": -642300000,
        "Elevation (m)": 91.4,
        "First Year": 1963,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1993,
        "MLY First Year": 1963,
        "MLY Last Year": 1993
      },
      {
        "Name": "WAKEHAM",
        "Province": "QUEBEC",
        "Climate ID": "7058840",
        "Station ID": 5878,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -64.62,
        "Latitude": 485100000,
        "Longitude": -643700000,
        "Elevation (m)": 15.2,
        "First Year": 1953,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1973,
        "MLY First Year": 1953,
        "MLY Last Year": 1973
      },
      {
        "Name": "CARLETON SUR MER",
        "Province": "QUEBEC",
        "Climate ID": "705AA86",
        "Station ID": 8496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.13,
        "Longitude (Decimal Degrees)": -66.12,
        "Latitude": 480800000,
        "Longitude": -660700000,
        "Elevation (m)": 541,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ILES DE LA MADELEINE A",
        "Province": "QUEBEC",
        "Climate ID": "705C2G9",
        "Station ID": 5748,
        "WMO ID": 71709,
        "TC ID": "YGR",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -61.78,
        "Latitude": 472500000,
        "Longitude": -614700000,
        "Elevation (m)": 10.7,
        "First Year": 1978,
        "Last Year": 2013,
        "HLY First Year": 1978,
        "HLY Last Year": 2013,
        "DLY First Year": 1983,
        "DLY Last Year": 2013,
        "MLY First Year": 1983,
        "MLY Last Year": 2002
      },
      {
        "Name": "MURDOCHVILLE HOLLAND",
        "Province": "QUEBEC",
        "Climate ID": "705ECH0",
        "Station ID": 5749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -65.52,
        "Latitude": 485700000,
        "Longitude": -653100000,
        "Elevation (m)": 624.8,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "ST PACOME",
        "Province": "QUEBEC",
        "Climate ID": "705GE9G",
        "Station ID": 5750,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -69.95,
        "Latitude": 472300000,
        "Longitude": -695700000,
        "Elevation (m)": 83.8,
        "First Year": 1971,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1982,
        "MLY First Year": 1971,
        "MLY Last Year": 1982
      },
      {
        "Name": "FOND D'ORMES",
        "Province": "QUEBEC",
        "Climate ID": "705KC75",
        "Station ID": 5751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -68.47,
        "Latitude": 481300000,
        "Longitude": -682800000,
        "Elevation (m)": 175.9,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FONTENELLE",
        "Province": "QUEBEC",
        "Climate ID": "705KL75",
        "Station ID": 5752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -64.65,
        "Latitude": 485600000,
        "Longitude": -643900000,
        "Elevation (m)": 15,
        "First Year": 1972,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2004,
        "MLY First Year": 1972,
        "MLY Last Year": 2004
      },
      {
        "Name": "LADRIERE",
        "Province": "QUEBEC",
        "Climate ID": "705LG09",
        "Station ID": 5753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -68.78,
        "Latitude": 481500000,
        "Longitude": -684700000,
        "Elevation (m)": 148,
        "First Year": 1976,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2004,
        "MLY First Year": 1976,
        "MLY Last Year": 2004
      },
      {
        "Name": "ILE BICQUETTE",
        "Province": "QUEBEC",
        "Climate ID": "705LKPQ",
        "Station ID": 10938,
        "WMO ID": 71385,
        "TC ID": "WQO",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -68.89,
        "Latitude": 482456000,
        "Longitude": -685334000,
        "Elevation (m)": 7,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TOUR NO 4 (MCDONALD)",
        "Province": "QUEBEC",
        "Climate ID": "705Q5AF",
        "Station ID": 5755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -63.22,
        "Latitude": 494300000,
        "Longitude": -631300000,
        "Elevation (m)": 198.1,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "TOUR PELLETIER",
        "Province": "QUEBEC",
        "Climate ID": "705QEA6",
        "Station ID": 5754,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -69.37,
        "Latitude": 473500000,
        "Longitude": -692200000,
        "Elevation (m)": null,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "CAP-CHAT",
        "Province": "QUEBEC",
        "Climate ID": "705S002",
        "Station ID": 27195,
        "WMO ID": 71428,
        "TC ID": "WSG",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -66.65,
        "Latitude": 490633000,
        "Longitude": -663916000,
        "Elevation (m)": 5,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AIGREMONT",
        "Province": "QUEBEC",
        "Climate ID": "7060070",
        "Station ID": 5886,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.3,
        "Longitude (Decimal Degrees)": -73.85,
        "Latitude": 491800000,
        "Longitude": -735100000,
        "Elevation (m)": 403.9,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "ALBANEL",
        "Province": "QUEBEC",
        "Climate ID": "7060080",
        "Station ID": 5887,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -72.45,
        "Latitude": 485300000,
        "Longitude": -722700000,
        "Elevation (m)": 152,
        "First Year": 1922,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1991,
        "MLY First Year": 1922,
        "MLY Last Year": 1991
      },
      {
        "Name": "ARVIDA",
        "Province": "QUEBEC",
        "Climate ID": "7060320",
        "Station ID": 5888,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 482600000,
        "Longitude": -711000000,
        "Elevation (m)": 102.1,
        "First Year": 1931,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1989,
        "MLY First Year": 1931,
        "MLY Last Year": 1988
      },
      {
        "Name": "BAGOTVILLE A",
        "Province": "QUEBEC",
        "Climate ID": "7060400",
        "Station ID": 5889,
        "WMO ID": 71727,
        "TC ID": "YBG",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -71,
        "Latitude": 482000000,
        "Longitude": -710000000,
        "Elevation (m)": 159.1,
        "First Year": 1942,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1942,
        "DLY Last Year": 2022,
        "MLY First Year": 1942,
        "MLY Last Year": 2017
      },
      {
        "Name": "BARRIERE N CHIBOUGAMAU",
        "Province": "QUEBEC",
        "Climate ID": "7060474",
        "Station ID": 5891,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -74.17,
        "Latitude": 493000000,
        "Longitude": -741000000,
        "Elevation (m)": 396.2,
        "First Year": 1963,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1973
      },
      {
        "Name": "BRE #3 RIV-AUX-RATS",
        "Province": "QUEBEC",
        "Climate ID": "7060479",
        "Station ID": 5892,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -72.25,
        "Latitude": 491300000,
        "Longitude": -721500000,
        "Elevation (m)": 335.3,
        "First Year": 1963,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1969,
        "MLY First Year": 1963,
        "MLY Last Year": 1969
      },
      {
        "Name": "BARRIERE SUD LA DORE",
        "Province": "QUEBEC",
        "Climate ID": "706047H",
        "Station ID": 5890,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -72.73,
        "Latitude": 484900000,
        "Longitude": -724400000,
        "Elevation (m)": 198.1,
        "First Year": 1963,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1975,
        "MLY First Year": 1963,
        "MLY Last Year": 1975
      },
      {
        "Name": "BONNARD",
        "Province": "QUEBEC",
        "Climate ID": "7060825",
        "Station ID": 5893,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -71.05,
        "Latitude": 504400000,
        "Longitude": -710300000,
        "Elevation (m)": 506,
        "First Year": 1961,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2000,
        "MLY First Year": 1961,
        "MLY Last Year": 2000
      },
      {
        "Name": "BONNARD 1",
        "Province": "QUEBEC",
        "Climate ID": "7060826",
        "Station ID": 10874,
        "WMO ID": 71383,
        "TC ID": "WKD",
        "Latitude (Decimal Degrees)": 50.73,
        "Longitude (Decimal Degrees)": -71.01,
        "Latitude": 504344000,
        "Longitude": -710047000,
        "Elevation (m)": 498,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BARRIERE MILOT",
        "Province": "QUEBEC",
        "Climate ID": "7060DPC",
        "Station ID": 5884,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -71.77,
        "Latitude": 485400000,
        "Longitude": -714600000,
        "Elevation (m)": 167.6,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "BARRIERE ETIENNICHE",
        "Province": "QUEBEC",
        "Climate ID": "7060M7A",
        "Station ID": 5885,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -71.38,
        "Latitude": 492700000,
        "Longitude": -712300000,
        "Elevation (m)": 320,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "CHAMOUCHOUANE",
        "Province": "QUEBEC",
        "Climate ID": "7061288",
        "Station ID": 26797,
        "WMO ID": 71523,
        "TC ID": "WEE",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -73.36,
        "Latitude": 491643000,
        "Longitude": -732120000,
        "Elevation (m)": 303.8,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHEMIN CHIBOUGAMAU",
        "Province": "QUEBEC",
        "Climate ID": "7061368",
        "Station ID": 5894,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -73.63,
        "Latitude": 491700000,
        "Longitude": -733800000,
        "Elevation (m)": 365.8,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "CHEMIN CHUTE DES PASSES",
        "Province": "QUEBEC",
        "Climate ID": "7061372",
        "Station ID": 5895,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -71.4,
        "Latitude": 492500000,
        "Longitude": -712400000,
        "Elevation (m)": 365.8,
        "First Year": 1967,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1975,
        "MLY First Year": 1967,
        "MLY Last Year": 1975
      },
      {
        "Name": "CHICOUTIMI",
        "Province": "QUEBEC",
        "Climate ID": "7061440",
        "Station ID": 5896,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -71.08,
        "Latitude": 482500000,
        "Longitude": -710500000,
        "Elevation (m)": 15.2,
        "First Year": 1871,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1979,
        "MLY First Year": 1871,
        "MLY Last Year": 1979
      },
      {
        "Name": "CHICOUTIMI OUEST",
        "Province": "QUEBEC",
        "Climate ID": "7061441",
        "Station ID": 5897,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -71.08,
        "Latitude": 482500000,
        "Longitude": -710500000,
        "Elevation (m)": 175,
        "First Year": 1989,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1991,
        "MLY First Year": 1989,
        "MLY Last Year": 1991
      },
      {
        "Name": "CHICOUTIMI UNIVERSITE",
        "Province": "QUEBEC",
        "Climate ID": "7061442",
        "Station ID": 5898,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -71.05,
        "Latitude": 482500000,
        "Longitude": -710300000,
        "Elevation (m)": 76.2,
        "First Year": 1976,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1985,
        "MLY First Year": 1976,
        "MLY Last Year": 1985
      },
      {
        "Name": "CHUTE A LA SAVANE",
        "Province": "QUEBEC",
        "Climate ID": "7061475",
        "Station ID": 5899,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -71.85,
        "Latitude": 484700000,
        "Longitude": -715100000,
        "Elevation (m)": 140.2,
        "First Year": 1951,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1975,
        "MLY First Year": 1951,
        "MLY Last Year": 1974
      },
      {
        "Name": "CHUTE A MURDOCK",
        "Province": "QUEBEC",
        "Climate ID": "7061480",
        "Station ID": 5900,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -71.25,
        "Latitude": 483100000,
        "Longitude": -711500000,
        "Elevation (m)": 188.4,
        "First Year": 1921,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1921,
        "DLY Last Year": 1957,
        "MLY First Year": 1921,
        "MLY Last Year": 1957
      },
      {
        "Name": "CHUTE AUX GALETS",
        "Province": "QUEBEC",
        "Climate ID": "7061520",
        "Station ID": 5901,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -71.2,
        "Latitude": 483900000,
        "Longitude": -711200000,
        "Elevation (m)": 152.4,
        "First Year": 1919,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1963,
        "MLY First Year": 1919,
        "MLY Last Year": 1962
      },
      {
        "Name": "CHUTE DES PASSES",
        "Province": "QUEBEC",
        "Climate ID": "7061540",
        "Station ID": 5902,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -71.15,
        "Latitude": 495100000,
        "Longitude": -710900000,
        "Elevation (m)": 270.7,
        "First Year": 1957,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1957,
        "MLY First Year": 1957,
        "MLY Last Year": 1957
      },
      {
        "Name": "CHUTE-DES-PASSES",
        "Province": "QUEBEC",
        "Climate ID": "7061541",
        "Station ID": 5903,
        "WMO ID": 71522,
        "TC ID": "WDT",
        "Latitude (Decimal Degrees)": 49.84,
        "Longitude (Decimal Degrees)": -71.17,
        "Latitude": 495025000,
        "Longitude": -711006000,
        "Elevation (m)": 398.2,
        "First Year": 1960,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1960,
        "DLY Last Year": 2022,
        "MLY First Year": 1960,
        "MLY Last Year": 1976
      },
      {
        "Name": "CHUTE DES PASSES 2",
        "Province": "QUEBEC",
        "Climate ID": "7061542",
        "Station ID": 5904,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -71.15,
        "Latitude": 494900000,
        "Longitude": -710900000,
        "Elevation (m)": 266.7,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "CHUTE DU DIABLE",
        "Province": "QUEBEC",
        "Climate ID": "7061560",
        "Station ID": 5905,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -71.7,
        "Latitude": 484500000,
        "Longitude": -714200000,
        "Elevation (m)": 173.7,
        "First Year": 1951,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1977,
        "MLY First Year": 1951,
        "MLY Last Year": 1976
      },
      {
        "Name": "DOLBEAU",
        "Province": "QUEBEC",
        "Climate ID": "7062020",
        "Station ID": 5906,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -72.23,
        "Latitude": 485300000,
        "Longitude": -721400000,
        "Elevation (m)": 127.4,
        "First Year": 1930,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1938,
        "MLY First Year": 1930,
        "MLY Last Year": 1938
      },
      {
        "Name": "FERLAND",
        "Province": "QUEBEC",
        "Climate ID": "7062368",
        "Station ID": 5907,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.2,
        "Longitude (Decimal Degrees)": -70.83,
        "Latitude": 481200000,
        "Longitude": -705000000,
        "Elevation (m)": 198.1,
        "First Year": 1971,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1997,
        "MLY First Year": 1971,
        "MLY Last Year": 1997
      },
      {
        "Name": "HEBERTVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7063040",
        "Station ID": 5908,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -71.67,
        "Latitude": 482700000,
        "Longitude": -714000000,
        "Elevation (m)": 157,
        "First Year": 1951,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1958,
        "MLY First Year": 1951,
        "MLY Last Year": 1958
      },
      {
        "Name": "HEMON",
        "Province": "QUEBEC",
        "Climate ID": "7063090",
        "Station ID": 5909,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -72.6,
        "Latitude": 490400000,
        "Longitude": -723600000,
        "Elevation (m)": 182.9,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "ISLE MALIGNE",
        "Province": "QUEBEC",
        "Climate ID": "7063320",
        "Station ID": 5910,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -71.63,
        "Latitude": 483500000,
        "Longitude": -713800000,
        "Elevation (m)": 76.2,
        "First Year": 1924,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1924,
        "DLY Last Year": 1991,
        "MLY First Year": 1924,
        "MLY Last Year": 1991
      },
      {
        "Name": "JONQUIERE",
        "Province": "QUEBEC",
        "Climate ID": "7063370",
        "Station ID": 5911,
        "WMO ID": 71617,
        "TC ID": "WJO",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -71.14,
        "Latitude": 482530012,
        "Longitude": -710833001,
        "Elevation (m)": 135.62,
        "First Year": 1963,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1963,
        "DLY Last Year": 2022,
        "MLY First Year": 1963,
        "MLY Last Year": 1975
      },
      {
        "Name": "KENOGAMI",
        "Province": "QUEBEC",
        "Climate ID": "7063400",
        "Station ID": 5912,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -71.25,
        "Latitude": 482500000,
        "Longitude": -711500000,
        "Elevation (m)": 115.5,
        "First Year": 1916,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1972,
        "MLY First Year": 1916,
        "MLY Last Year": 1972
      },
      {
        "Name": "LAC BOUCHETTE",
        "Province": "QUEBEC",
        "Climate ID": "7063560",
        "Station ID": 5913,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -72.17,
        "Latitude": 481300000,
        "Longitude": -721000000,
        "Elevation (m)": 358,
        "First Year": 1949,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2015,
        "MLY First Year": 1949,
        "MLY Last Year": 2015
      },
      {
        "Name": "LAC DES COMMISSAIRES",
        "Province": "QUEBEC",
        "Climate ID": "7063610",
        "Station ID": 5914,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.18,
        "Longitude (Decimal Degrees)": -72.25,
        "Latitude": 481100000,
        "Longitude": -721500000,
        "Elevation (m)": 358.1,
        "First Year": 1966,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "LAC HA HA",
        "Province": "QUEBEC",
        "Climate ID": "7063647",
        "Station ID": 5915,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48,
        "Longitude (Decimal Degrees)": -70.78,
        "Latitude": 480000000,
        "Longitude": -704700000,
        "Elevation (m)": 502.9,
        "First Year": 1964,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1970,
        "MLY First Year": 1964,
        "MLY Last Year": 1970
      },
      {
        "Name": "LAC ONATCHIWAY",
        "Province": "QUEBEC",
        "Climate ID": "7063683",
        "Station ID": 5916,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -71.03,
        "Latitude": 485400000,
        "Longitude": -710200000,
        "Elevation (m)": 304.8,
        "First Year": 1920,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1981,
        "MLY First Year": 1920,
        "MLY Last Year": 1981
      },
      {
        "Name": "LAC PAMOUSCACHIOU",
        "Province": "QUEBEC",
        "Climate ID": "7063684",
        "Station ID": 5917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.28,
        "Longitude (Decimal Degrees)": -70.98,
        "Latitude": 491700000,
        "Longitude": -705900000,
        "Elevation (m)": 350.5,
        "First Year": 1973,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1976,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "LAC STE CROIX",
        "Province": "QUEBEC",
        "Climate ID": "7063690",
        "Station ID": 5918,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -71.75,
        "Latitude": 482500000,
        "Longitude": -714500000,
        "Elevation (m)": 152,
        "First Year": 1958,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2018,
        "MLY First Year": 1958,
        "MLY Last Year": 2017
      },
      {
        "Name": "LATERRIERE",
        "Province": "QUEBEC",
        "Climate ID": "7064180",
        "Station ID": 5919,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -71.13,
        "Latitude": 481800000,
        "Longitude": -710800000,
        "Elevation (m)": 152.4,
        "First Year": 1963,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1982,
        "MLY First Year": 1963,
        "MLY Last Year": 1982
      },
      {
        "Name": "LATERRIERE",
        "Province": "QUEBEC",
        "Climate ID": "7064181",
        "Station ID": 30722,
        "WMO ID": 71040,
        "TC ID": "XLT",
        "Latitude (Decimal Degrees)": 48.31,
        "Longitude (Decimal Degrees)": -71.13,
        "Latitude": 481826000,
        "Longitude": -710745000,
        "Elevation (m)": 162.7,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MANUAN LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7064620",
        "Station ID": 5920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.63,
        "Longitude (Decimal Degrees)": -70.53,
        "Latitude": 503800000,
        "Longitude": -703200000,
        "Elevation (m)": 495.3,
        "First Year": 1942,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1961,
        "MLY First Year": 1942,
        "MLY Last Year": 1961
      },
      {
        "Name": "MESY",
        "Province": "QUEBEC",
        "Climate ID": "7064890",
        "Station ID": 5921,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.27,
        "Longitude (Decimal Degrees)": -71.68,
        "Latitude": 481600000,
        "Longitude": -714100000,
        "Elevation (m)": 384,
        "First Year": 1963,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1978,
        "MLY First Year": 1963,
        "MLY Last Year": 1978
      },
      {
        "Name": "MISTASSIBI",
        "Province": "QUEBEC",
        "Climate ID": "7064993",
        "Station ID": 5922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -72.03,
        "Latitude": 491900000,
        "Longitude": -720200000,
        "Elevation (m)": 243.8,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "MISTASSINI",
        "Province": "QUEBEC",
        "Climate ID": "7064998",
        "Station ID": 5923,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -72.2,
        "Latitude": 485100000,
        "Longitude": -721200000,
        "Elevation (m)": 121.9,
        "First Year": 1912,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1994,
        "MLY First Year": 1912,
        "MLY Last Year": 1994
      },
      {
        "Name": "MISTOOK",
        "Province": "QUEBEC",
        "Climate ID": "7065012",
        "Station ID": 10797,
        "WMO ID": 71381,
        "TC ID": "WIX",
        "Latitude (Decimal Degrees)": 48.6,
        "Longitude (Decimal Degrees)": -71.72,
        "Latitude": 483554000,
        "Longitude": -714257000,
        "Elevation (m)": 112.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONT APICA",
        "Province": "QUEBEC",
        "Climate ID": "7065100",
        "Station ID": 5924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -71.42,
        "Latitude": 475800000,
        "Longitude": -712500000,
        "Elevation (m)": 548.6,
        "First Year": 1957,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1990,
        "MLY First Year": 1963,
        "MLY Last Year": 1990
      },
      {
        "Name": "MURDOCK WILSON",
        "Province": "QUEBEC",
        "Climate ID": "7065383",
        "Station ID": 5925,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -71.25,
        "Latitude": 483100000,
        "Longitude": -711500000,
        "Elevation (m)": 91.4,
        "First Year": 1958,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1961,
        "MLY First Year": 1959,
        "MLY Last Year": 1961
      },
      {
        "Name": "NORMANDIN",
        "Province": "QUEBEC",
        "Climate ID": "7065639",
        "Station ID": 10872,
        "WMO ID": 71379,
        "TC ID": "WOD",
        "Latitude (Decimal Degrees)": 48.84,
        "Longitude (Decimal Degrees)": -72.55,
        "Latitude": 485030000,
        "Longitude": -723249000,
        "Elevation (m)": 137.2,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORMANDIN CDA",
        "Province": "QUEBEC",
        "Climate ID": "7065640",
        "Station ID": 5926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -72.53,
        "Latitude": 485100000,
        "Longitude": -723200000,
        "Elevation (m)": 137.2,
        "First Year": 1936,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1992,
        "MLY First Year": 1936,
        "MLY Last Year": 1992
      },
      {
        "Name": "NOTRE DAME DE LORETTE",
        "Province": "QUEBEC",
        "Climate ID": "7065667",
        "Station ID": 5927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -72.32,
        "Latitude": 490400000,
        "Longitude": -721900000,
        "Elevation (m)": 182.9,
        "First Year": 1977,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1992,
        "MLY First Year": 1977,
        "MLY Last Year": 1992
      },
      {
        "Name": "ONATCHIWAY",
        "Province": "QUEBEC",
        "Climate ID": "7065738",
        "Station ID": 27294,
        "WMO ID": 71387,
        "TC ID": "WXC",
        "Latitude (Decimal Degrees)": 48.89,
        "Longitude (Decimal Degrees)": -71.03,
        "Latitude": 485339000,
        "Longitude": -710154000,
        "Elevation (m)": 304,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PASSE DANGEREUSE DAM",
        "Province": "QUEBEC",
        "Climate ID": "7065840",
        "Station ID": 5928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -71.27,
        "Latitude": 495300000,
        "Longitude": -711600000,
        "Elevation (m)": 457.2,
        "First Year": 1942,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1961,
        "MLY First Year": 1942,
        "MLY Last Year": 1961
      },
      {
        "Name": "PERIBONCA",
        "Province": "QUEBEC",
        "Climate ID": "7065960",
        "Station ID": 5929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -72.03,
        "Latitude": 484600000,
        "Longitude": -720200000,
        "Elevation (m)": 103,
        "First Year": 1951,
        "Last Year": 2018,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2018,
        "MLY First Year": 1951,
        "MLY Last Year": 2016
      },
      {
        "Name": "PORTAGE DES ROCHES",
        "Province": "QUEBEC",
        "Climate ID": "7066080",
        "Station ID": 5930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 481800000,
        "Longitude": -711300000,
        "Elevation (m)": 164.6,
        "First Year": 1926,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1926,
        "DLY Last Year": 1984,
        "MLY First Year": 1926,
        "MLY Last Year": 1984
      },
      {
        "Name": "RIVIERE A MARS",
        "Province": "QUEBEC",
        "Climate ID": "7066550",
        "Station ID": 5931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48,
        "Longitude (Decimal Degrees)": -71,
        "Latitude": 480000000,
        "Longitude": -710000000,
        "Elevation (m)": 518.2,
        "First Year": 1963,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1963,
        "MLY First Year": 1963,
        "MLY Last Year": 1963
      },
      {
        "Name": "RIVIERE AUX RATS",
        "Province": "QUEBEC",
        "Climate ID": "7066583",
        "Station ID": 5932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -72.2,
        "Latitude": 492500000,
        "Longitude": -721200000,
        "Elevation (m)": 185.9,
        "First Year": 1963,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1974
      },
      {
        "Name": "RIVIERE JOLY",
        "Province": "QUEBEC",
        "Climate ID": "7066623",
        "Station ID": 5933,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -71.18,
        "Latitude": 490900000,
        "Longitude": -711100000,
        "Elevation (m)": 350.5,
        "First Year": 1977,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1977,
        "MLY First Year": 1977,
        "MLY Last Year": 1977
      },
      {
        "Name": "ROBERVAL A",
        "Province": "QUEBEC",
        "Climate ID": "7066685",
        "Station ID": 5934,
        "WMO ID": 71728,
        "TC ID": "YRJ",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -72.27,
        "Latitude": 483100000,
        "Longitude": -721600000,
        "Elevation (m)": 178.6,
        "First Year": 1957,
        "Last Year": 2014,
        "HLY First Year": 1958,
        "HLY Last Year": 2014,
        "DLY First Year": 1957,
        "DLY Last Year": 2014,
        "MLY First Year": 1957,
        "MLY Last Year": 2005
      },
      {
        "Name": "ROBERVAL A",
        "Province": "QUEBEC",
        "Climate ID": "7066686",
        "Station ID": 52080,
        "WMO ID": 71728,
        "TC ID": "YRJ",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -72.27,
        "Latitude": 483112000,
        "Longitude": -721557000,
        "Elevation (m)": 178.6,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROBERVAL A",
        "Province": "QUEBEC",
        "Climate ID": "7066687",
        "Station ID": 54300,
        "WMO ID": null,
        "TC ID": "YRJ",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -72.27,
        "Latitude": 483112000,
        "Longitude": -721557000,
        "Elevation (m)": 178.6,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROBERVAL NORD",
        "Province": "QUEBEC",
        "Climate ID": "7066688",
        "Station ID": 5935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -72.23,
        "Latitude": 483200000,
        "Longitude": -721400000,
        "Elevation (m)": 102.1,
        "First Year": 1888,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1888,
        "DLY Last Year": 1967,
        "MLY First Year": 1888,
        "MLY Last Year": 1967
      },
      {
        "Name": "ST AMBROISE",
        "Province": "QUEBEC",
        "Climate ID": "7066820",
        "Station ID": 5936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -71.33,
        "Latitude": 483400000,
        "Longitude": -712000000,
        "Elevation (m)": 121.9,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2022,
        "MLY First Year": 1954,
        "MLY Last Year": 2018
      },
      {
        "Name": "ST COEUR DE MARIE",
        "Province": "QUEBEC",
        "Climate ID": "7067060",
        "Station ID": 5937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -71.72,
        "Latitude": 483800000,
        "Longitude": -714300000,
        "Elevation (m)": 106.7,
        "First Year": 1957,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1994,
        "MLY First Year": 1957,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST FELICIEN",
        "Province": "QUEBEC",
        "Climate ID": "7067240",
        "Station ID": 5938,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -72.45,
        "Latitude": 483900000,
        "Longitude": -722700000,
        "Elevation (m)": 111.6,
        "First Year": 1938,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1953,
        "MLY First Year": 1938,
        "MLY Last Year": 1953
      },
      {
        "Name": "ST-HENRI",
        "Province": "QUEBEC",
        "Climate ID": "7067307",
        "Station ID": 10799,
        "WMO ID": null,
        "TC ID": "WIA",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -71.85,
        "Latitude": 483900000,
        "Longitude": -715100000,
        "Elevation (m)": 103,
        "First Year": 1994,
        "Last Year": 2009,
        "HLY First Year": 1994,
        "HLY Last Year": 2009,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST LEON DE LABRECQUE",
        "Province": "QUEBEC",
        "Climate ID": "7067460",
        "Station ID": 5939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -71.52,
        "Latitude": 484000000,
        "Longitude": -713100000,
        "Elevation (m)": 131.1,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1963,
        "MLY Last Year": 2004
      },
      {
        "Name": "ST PRIME",
        "Province": "QUEBEC",
        "Climate ID": "7067658",
        "Station ID": 5940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -72.42,
        "Latitude": 483700000,
        "Longitude": -722500000,
        "Elevation (m)": 121.9,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2018
      },
      {
        "Name": "SHIPSHAW",
        "Province": "QUEBEC",
        "Climate ID": "7068160",
        "Station ID": 5941,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -71.22,
        "Latitude": 482700000,
        "Longitude": -711300000,
        "Elevation (m)": 22.9,
        "First Year": 1943,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1993,
        "MLY First Year": 1943,
        "MLY Last Year": 1993
      },
      {
        "Name": "VAN BRUYSSEL",
        "Province": "QUEBEC",
        "Climate ID": "7068696",
        "Station ID": 5942,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.95,
        "Longitude (Decimal Degrees)": -72.17,
        "Latitude": 475700000,
        "Longitude": -721000000,
        "Elevation (m)": 394.4,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "LAC ALEX",
        "Province": "QUEBEC",
        "Climate ID": "706CEE5",
        "Station ID": 5879,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.25,
        "Longitude (Decimal Degrees)": -71.47,
        "Latitude": 491500000,
        "Longitude": -712800000,
        "Elevation (m)": 457.2,
        "First Year": 1965,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1966
      },
      {
        "Name": "LAC NICAUBA S",
        "Province": "QUEBEC",
        "Climate ID": "706CFH0",
        "Station ID": 5880,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.37,
        "Longitude (Decimal Degrees)": -74.12,
        "Latitude": 492200000,
        "Longitude": -740700000,
        "Elevation (m)": 384,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "LA DORE",
        "Province": "QUEBEC",
        "Climate ID": "706CP09",
        "Station ID": 5881,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -72.72,
        "Latitude": 484600000,
        "Longitude": -724300000,
        "Elevation (m)": 182.9,
        "First Year": 1975,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1993,
        "MLY First Year": 1975,
        "MLY Last Year": 1993
      },
      {
        "Name": "RIVIERE CHIGOUBICHE",
        "Province": "QUEBEC",
        "Climate ID": "706FFA0",
        "Station ID": 5882,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -73.3,
        "Latitude": 485900000,
        "Longitude": -731800000,
        "Elevation (m)": 335.3,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ST-GEDEON",
        "Province": "QUEBEC",
        "Climate ID": "706G2PF",
        "Station ID": 10796,
        "WMO ID": null,
        "TC ID": "WIU",
        "Latitude (Decimal Degrees)": 48.48,
        "Longitude (Decimal Degrees)": -71.78,
        "Latitude": 482900000,
        "Longitude": -714700000,
        "Elevation (m)": 103,
        "First Year": 1994,
        "Last Year": 2006,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAC BENOIT",
        "Province": "QUEBEC",
        "Climate ID": "706I001",
        "Station ID": 26778,
        "WMO ID": 71520,
        "TC ID": "WDE",
        "Latitude (Decimal Degrees)": 51.53,
        "Longitude (Decimal Degrees)": -71.11,
        "Latitude": 513157000,
        "Longitude": -710639000,
        "Elevation (m)": 549,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1995,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MANOUANE EST",
        "Province": "QUEBEC",
        "Climate ID": "706I155",
        "Station ID": 26798,
        "WMO ID": 71521,
        "TC ID": "WDP",
        "Latitude (Decimal Degrees)": 50.66,
        "Longitude (Decimal Degrees)": -70.53,
        "Latitude": 503923000,
        "Longitude": -703155000,
        "Elevation (m)": 497,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FALARDEAU",
        "Province": "QUEBEC",
        "Climate ID": "706KC09",
        "Station ID": 5883,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -71.13,
        "Latitude": 483800000,
        "Longitude": -710800000,
        "Elevation (m)": 198.1,
        "First Year": 1979,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2004,
        "MLY First Year": 1979,
        "MLY Last Year": 2004
      },
      {
        "Name": "LA BAIE",
        "Province": "QUEBEC",
        "Climate ID": "706Q001",
        "Station ID": 27315,
        "WMO ID": 71388,
        "TC ID": "WUX",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -70.92,
        "Latitude": 481753172,
        "Longitude": -705510333,
        "Elevation (m)": 151.64,
        "First Year": 1997,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1997,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BARRAGE A LAC KEMPT",
        "Province": "QUEBEC",
        "Climate ID": "7070448",
        "Station ID": 5946,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.55,
        "Longitude (Decimal Degrees)": -74.18,
        "Latitude": 473300000,
        "Longitude": -741100000,
        "Elevation (m)": 420.6,
        "First Year": 1913,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1970,
        "MLY First Year": 1913,
        "MLY Last Year": 1970
      },
      {
        "Name": "BARRAGE CICONCINE",
        "Province": "QUEBEC",
        "Climate ID": "7070450",
        "Station ID": 5947,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -73.03,
        "Latitude": 472300000,
        "Longitude": -730200000,
        "Elevation (m)": 205.7,
        "First Year": 1942,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1967,
        "MLY First Year": 1942,
        "MLY Last Year": 1967
      },
      {
        "Name": "BGE C LAC CHATEAUVERT",
        "Province": "QUEBEC",
        "Climate ID": "7070451",
        "Station ID": 5948,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.77,
        "Longitude (Decimal Degrees)": -73.9,
        "Latitude": 474600000,
        "Longitude": -735400000,
        "Elevation (m)": 382.5,
        "First Year": 1942,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1942,
        "DLY Last Year": 1982,
        "MLY First Year": 1942,
        "MLY Last Year": 1981
      },
      {
        "Name": "BARRAGE GOUIN",
        "Province": "QUEBEC",
        "Climate ID": "7070454",
        "Station ID": 5949,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -74.1,
        "Latitude": 482100000,
        "Longitude": -740600000,
        "Elevation (m)": 403.9,
        "First Year": 1913,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1982,
        "MLY First Year": 1913,
        "MLY Last Year": 1981
      },
      {
        "Name": "BARRAGE MATTAWIN",
        "Province": "QUEBEC",
        "Climate ID": "7070456",
        "Station ID": 5950,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -73.65,
        "Latitude": 465100000,
        "Longitude": -733900000,
        "Elevation (m)": 365.8,
        "First Year": 1929,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1982,
        "MLY First Year": 1929,
        "MLY Last Year": 1982
      },
      {
        "Name": "BARRAGE MONDONAC",
        "Province": "QUEBEC",
        "Climate ID": "7070464",
        "Station ID": 5951,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -73.93,
        "Latitude": 472700000,
        "Longitude": -735600000,
        "Elevation (m)": 457.2,
        "First Year": 1943,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1966,
        "MLY First Year": 1943,
        "MLY Last Year": 1966
      },
      {
        "Name": "BELLE PLAGE",
        "Province": "QUEBEC",
        "Climate ID": "7070595",
        "Station ID": 5952,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -74.02,
        "Latitude": 484100000,
        "Longitude": -740100000,
        "Elevation (m)": 411.5,
        "First Year": 1975,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1985,
        "MLY First Year": 1975,
        "MLY Last Year": 1985
      },
      {
        "Name": "BARRIERE ST GUILLAUME",
        "Province": "QUEBEC",
        "Climate ID": "7070DP6",
        "Station ID": 5944,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.6,
        "Longitude (Decimal Degrees)": -74.17,
        "Latitude": 463600000,
        "Longitude": -741000000,
        "Elevation (m)": 411.5,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "BARRAGE GOUIN S",
        "Province": "QUEBEC",
        "Climate ID": "7070ME4",
        "Station ID": 5945,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -74.1,
        "Latitude": 482100000,
        "Longitude": -740600000,
        "Elevation (m)": 403.9,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "CLOVA",
        "Province": "QUEBEC",
        "Climate ID": "7071720",
        "Station ID": 5953,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.12,
        "Longitude (Decimal Degrees)": -75.37,
        "Latitude": 480700000,
        "Longitude": -752200000,
        "Elevation (m)": 423.4,
        "First Year": 1943,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1943,
        "DLY Last Year": 1958,
        "MLY First Year": 1943,
        "MLY Last Year": 1958
      },
      {
        "Name": "COMMISSAIRE",
        "Province": "QUEBEC",
        "Climate ID": "7071912",
        "Station ID": 44786,
        "WMO ID": null,
        "TC ID": "MCM",
        "Latitude (Decimal Degrees)": 47.99,
        "Longitude (Decimal Degrees)": -72.3,
        "Latitude": 475928000,
        "Longitude": -721810000,
        "Elevation (m)": 419,
        "First Year": 2006,
        "Last Year": 2008,
        "HLY First Year": 2006,
        "HLY Last Year": 2008,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GRANDE ANSE",
        "Province": "QUEBEC",
        "Climate ID": "7072816",
        "Station ID": 5954,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.1,
        "Longitude (Decimal Degrees)": -72.93,
        "Latitude": 470600000,
        "Longitude": -725600000,
        "Elevation (m)": 119,
        "First Year": 1982,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2015,
        "MLY First Year": 1982,
        "MLY Last Year": 2015
      },
      {
        "Name": "LAC INMAN",
        "Province": "QUEBEC",
        "Climate ID": "7073652",
        "Station ID": 5955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.98,
        "Longitude (Decimal Degrees)": -73.18,
        "Latitude": 465900000,
        "Longitude": -731100000,
        "Elevation (m)": 320,
        "First Year": 1973,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1982,
        "MLY First Year": 1973,
        "MLY Last Year": 1982
      },
      {
        "Name": "LAC MARTEL",
        "Province": "QUEBEC",
        "Climate ID": "7073672",
        "Station ID": 5956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.08,
        "Longitude (Decimal Degrees)": -73.12,
        "Latitude": 480500000,
        "Longitude": -730700000,
        "Elevation (m)": 365.8,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "LA TUQUE",
        "Province": "QUEBEC",
        "Climate ID": "7074240",
        "Station ID": 5957,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.4,
        "Longitude (Decimal Degrees)": -72.78,
        "Latitude": 472400000,
        "Longitude": -724700000,
        "Elevation (m)": 152,
        "First Year": 1911,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1911,
        "DLY Last Year": 2004,
        "MLY First Year": 1911,
        "MLY Last Year": 2004
      },
      {
        "Name": "MANOUAN SANMAUR",
        "Province": "QUEBEC",
        "Climate ID": "7074600",
        "Station ID": 5959,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.9,
        "Longitude (Decimal Degrees)": -73.8,
        "Latitude": 475400000,
        "Longitude": -734800000,
        "Elevation (m)": 356.9,
        "First Year": 1919,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1972,
        "MLY First Year": 1919,
        "MLY Last Year": 1972
      },
      {
        "Name": "MANOUAN SANMAUR 2",
        "Province": "QUEBEC",
        "Climate ID": "7074601",
        "Station ID": 5960,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.9,
        "Longitude (Decimal Degrees)": -73.8,
        "Latitude": 475400000,
        "Longitude": -734800000,
        "Elevation (m)": 356.9,
        "First Year": 1963,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1978,
        "MLY First Year": 1963,
        "MLY Last Year": 1978
      },
      {
        "Name": "OBIDJUAN",
        "Province": "QUEBEC",
        "Climate ID": "7075720",
        "Station ID": 5961,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -74.93,
        "Latitude": 483800000,
        "Longitude": -745600000,
        "Elevation (m)": 408.4,
        "First Year": 1923,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1952,
        "MLY First Year": 1923,
        "MLY Last Year": 1952
      },
      {
        "Name": "OSKELANEO",
        "Province": "QUEBEC",
        "Climate ID": "7075750",
        "Station ID": 5962,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.12,
        "Longitude (Decimal Degrees)": -75.2,
        "Latitude": 480700000,
        "Longitude": -751200000,
        "Elevation (m)": 415.1,
        "First Year": 1963,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1974
      },
      {
        "Name": "OSKELANEO 1",
        "Province": "QUEBEC",
        "Climate ID": "7075751",
        "Station ID": 5963,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.12,
        "Longitude (Decimal Degrees)": -75.17,
        "Latitude": 480700000,
        "Longitude": -751000000,
        "Elevation (m)": 396.2,
        "First Year": 1923,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1923,
        "DLY Last Year": 1933,
        "MLY First Year": 1923,
        "MLY Last Year": 1933
      },
      {
        "Name": "OSKELANEO 2",
        "Province": "QUEBEC",
        "Climate ID": "7075752",
        "Station ID": 5964,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": null,
        "Longitude (Decimal Degrees)": null,
        "Latitude": null,
        "Longitude": null,
        "Elevation (m)": null,
        "First Year": 1927,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1939,
        "MLY First Year": 1927,
        "MLY Last Year": 1939
      },
      {
        "Name": "PARENT S",
        "Province": "QUEBEC",
        "Climate ID": "7075799",
        "Station ID": 5965,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.92,
        "Longitude (Decimal Degrees)": -74.62,
        "Latitude": 475500000,
        "Longitude": -743700000,
        "Elevation (m)": 410,
        "First Year": 1982,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2004,
        "MLY First Year": 1982,
        "MLY Last Year": 2004
      },
      {
        "Name": "PARENT",
        "Province": "QUEBEC",
        "Climate ID": "7075800",
        "Station ID": 5966,
        "WMO ID": 71726,
        "TC ID": "WPK",
        "Latitude (Decimal Degrees)": 47.92,
        "Longitude (Decimal Degrees)": -74.62,
        "Latitude": 475520008,
        "Longitude": -743727007,
        "Elevation (m)": 444.71,
        "First Year": 1943,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1943,
        "DLY Last Year": 2022,
        "MLY First Year": 1943,
        "MLY Last Year": 1978
      },
      {
        "Name": "PARENT",
        "Province": "QUEBEC",
        "Climate ID": "7075801",
        "Station ID": 5967,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.88,
        "Longitude (Decimal Degrees)": -74.68,
        "Latitude": 475300000,
        "Longitude": -744100000,
        "Elevation (m)": 627.9,
        "First Year": 1960,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1964,
        "MLY First Year": 1960,
        "MLY Last Year": 1964
      },
      {
        "Name": "RAPIDE BLANC",
        "Province": "QUEBEC",
        "Climate ID": "7076360",
        "Station ID": 5968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -72.97,
        "Latitude": 474800000,
        "Longitude": -725800000,
        "Elevation (m)": 277.1,
        "First Year": 1931,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1974,
        "MLY First Year": 1931,
        "MLY Last Year": 1974
      },
      {
        "Name": "ST MICHEL DES SAINTS",
        "Province": "QUEBEC",
        "Climate ID": "7077570",
        "Station ID": 5969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.68,
        "Longitude (Decimal Degrees)": -73.92,
        "Latitude": 464100000,
        "Longitude": -735500000,
        "Elevation (m)": 350.5,
        "First Year": 1966,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2022,
        "MLY First Year": 1966,
        "MLY Last Year": 2018
      },
      {
        "Name": "SAINT-MICHEL-DES-SAINTS",
        "Province": "QUEBEC",
        "Climate ID": "7077571",
        "Station ID": 48188,
        "WMO ID": 71737,
        "TC ID": "MMY",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -74.09,
        "Latitude": 464856010,
        "Longitude": -740538050,
        "Elevation (m)": 429.9,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANMAUR",
        "Province": "QUEBEC",
        "Climate ID": "7077790",
        "Station ID": 5970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.88,
        "Longitude (Decimal Degrees)": -73.8,
        "Latitude": 475300000,
        "Longitude": -734800000,
        "Elevation (m)": 356.6,
        "First Year": 1930,
        "Last Year": 1943,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1930,
        "DLY Last Year": 1943,
        "MLY First Year": 1930,
        "MLY Last Year": 1943
      },
      {
        "Name": "LA TUQUE",
        "Province": "QUEBEC",
        "Climate ID": "707DBD4",
        "Station ID": 10875,
        "WMO ID": 71378,
        "TC ID": "WDQ",
        "Latitude (Decimal Degrees)": 47.41,
        "Longitude (Decimal Degrees)": -72.79,
        "Latitude": 472440000,
        "Longitude": -724715000,
        "Elevation (m)": 168.9,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA TUQUE S",
        "Province": "QUEBEC",
        "Climate ID": "707DBDD",
        "Station ID": 5943,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.4,
        "Longitude (Decimal Degrees)": -72.78,
        "Latitude": 472400000,
        "Longitude": -724700000,
        "Elevation (m)": 167.6,
        "First Year": 1973,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1990,
        "MLY First Year": 1973,
        "MLY Last Year": 1990
      },
      {
        "Name": "BARRAGE CABONGA",
        "Province": "QUEBEC",
        "Climate ID": "7080449",
        "Station ID": 5975,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.32,
        "Longitude (Decimal Degrees)": -76.47,
        "Latitude": 471900000,
        "Longitude": -762800000,
        "Elevation (m)": 365.8,
        "First Year": 1929,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1969,
        "MLY First Year": 1929,
        "MLY Last Year": 1969
      },
      {
        "Name": "BARRAGE ANGLIERS",
        "Province": "QUEBEC",
        "Climate ID": "7080452",
        "Station ID": 5976,
        "WMO ID": null,
        "TC ID": "WRC",
        "Latitude (Decimal Degrees)": 47.55,
        "Longitude (Decimal Degrees)": -79.24,
        "Latitude": 473307000,
        "Longitude": -791409000,
        "Elevation (m)": 266.9,
        "First Year": 1911,
        "Last Year": 2007,
        "HLY First Year": 1996,
        "HLY Last Year": 2007,
        "DLY First Year": 1911,
        "DLY Last Year": 2007,
        "MLY First Year": 1911,
        "MLY Last Year": 1996
      },
      {
        "Name": "BARRAGE TEMISCAMINGUE",
        "Province": "QUEBEC",
        "Climate ID": "7080468",
        "Station ID": 5977,
        "WMO ID": 71732,
        "TC ID": "WBA",
        "Latitude (Decimal Degrees)": 46.71,
        "Longitude (Decimal Degrees)": -79.1,
        "Latitude": 464235000,
        "Longitude": -790604000,
        "Elevation (m)": 181.4,
        "First Year": 1910,
        "Last Year": 2022,
        "HLY First Year": 1997,
        "HLY Last Year": 2022,
        "DLY First Year": 1910,
        "DLY Last Year": 2022,
        "MLY First Year": 1910,
        "MLY Last Year": 1995
      },
      {
        "Name": "BELLETERRE",
        "Province": "QUEBEC",
        "Climate ID": "7080600",
        "Station ID": 5978,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -78.7,
        "Latitude": 472300000,
        "Longitude": -784200000,
        "Elevation (m)": 321.6,
        "First Year": 1951,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2004,
        "MLY First Year": 1951,
        "MLY Last Year": 2004
      },
      {
        "Name": "CADILLAC",
        "Province": "QUEBEC",
        "Climate ID": "7081000",
        "Station ID": 5979,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -78.38,
        "Latitude": 481300000,
        "Longitude": -782300000,
        "Elevation (m)": 320.6,
        "First Year": 1951,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1984,
        "MLY First Year": 1951,
        "MLY Last Year": 1984
      },
      {
        "Name": "CADILLAC",
        "Province": "QUEBEC",
        "Climate ID": "7081002",
        "Station ID": 5980,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.2,
        "Longitude (Decimal Degrees)": -78.33,
        "Latitude": 481200000,
        "Longitude": -782000000,
        "Elevation (m)": 324.6,
        "First Year": 1976,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1978,
        "MLY First Year": 1976,
        "MLY Last Year": 1978
      },
      {
        "Name": "GRAND LAC VICTORIA",
        "Province": "QUEBEC",
        "Climate ID": "7082880",
        "Station ID": 5981,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.83,
        "Longitude (Decimal Degrees)": -77.37,
        "Latitude": 475000000,
        "Longitude": -772200000,
        "Elevation (m)": 329.2,
        "First Year": 1927,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1984,
        "MLY First Year": 1927,
        "MLY Last Year": 1984
      },
      {
        "Name": "KIPAWA LANIEL",
        "Province": "QUEBEC",
        "Climate ID": "7083480",
        "Station ID": 5982,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.05,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 470300000,
        "Longitude": -791600000,
        "Elevation (m)": 280.4,
        "First Year": 1920,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 2001,
        "MLY First Year": 1920,
        "MLY Last Year": 2001
      },
      {
        "Name": "LAC DES LOUPS A",
        "Province": "QUEBEC",
        "Climate ID": "7083620",
        "Station ID": 5983,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.98,
        "Longitude (Decimal Degrees)": -76.48,
        "Latitude": 465900000,
        "Longitude": -762900000,
        "Elevation (m)": 387.1,
        "First Year": 1956,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1967,
        "MLY First Year": 1956,
        "MLY Last Year": 1967
      },
      {
        "Name": "LAC DOZOIS (BGE BOURQUE)",
        "Province": "QUEBEC",
        "Climate ID": "7083630",
        "Station ID": 5984,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.63,
        "Longitude (Decimal Degrees)": -77.3,
        "Latitude": 473800000,
        "Longitude": -771800000,
        "Elevation (m)": 350.5,
        "First Year": 1948,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1967,
        "MLY First Year": 1948,
        "MLY Last Year": 1967
      },
      {
        "Name": "LE DOMAINE",
        "Province": "QUEBEC",
        "Climate ID": "7084276",
        "Station ID": 5985,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.03,
        "Longitude (Decimal Degrees)": -76.53,
        "Latitude": 470200000,
        "Longitude": -763200000,
        "Elevation (m)": 365.8,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1986,
        "MLY First Year": 1973,
        "MLY Last Year": 1986
      },
      {
        "Name": "MANNEVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7084560",
        "Station ID": 5986,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -78.48,
        "Latitude": 483300000,
        "Longitude": -782900000,
        "Elevation (m)": 311.2,
        "First Year": 1949,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1993,
        "MLY First Year": 1949,
        "MLY Last Year": 1993
      },
      {
        "Name": "MONTBEILLARD",
        "Province": "QUEBEC",
        "Climate ID": "7085102",
        "Station ID": 5987,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 480300000,
        "Longitude": -791600000,
        "Elevation (m)": 290,
        "First Year": 1973,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2004,
        "MLY First Year": 1973,
        "MLY Last Year": 2004
      },
      {
        "Name": "MONT BRUN",
        "Province": "QUEBEC",
        "Climate ID": "7085106",
        "Station ID": 5988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -78.73,
        "Latitude": 482500000,
        "Longitude": -784400000,
        "Elevation (m)": 304.8,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2018
      },
      {
        "Name": "MOOSE BAY KIPAWA",
        "Province": "QUEBEC",
        "Climate ID": "7085345",
        "Station ID": 5989,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.78,
        "Longitude (Decimal Degrees)": -78.98,
        "Latitude": 464700000,
        "Longitude": -785900000,
        "Elevation (m)": 274.3,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "NORANDA",
        "Province": "QUEBEC",
        "Climate ID": "7085560",
        "Station ID": 5990,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -79.02,
        "Latitude": 481500000,
        "Longitude": -790100000,
        "Elevation (m)": 286.5,
        "First Year": 1950,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1971,
        "MLY First Year": 1950,
        "MLY Last Year": 1971
      },
      {
        "Name": "NORANDA LAKE DUFAULT",
        "Province": "QUEBEC",
        "Climate ID": "7085600",
        "Station ID": 5991,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.28,
        "Longitude (Decimal Degrees)": -79,
        "Latitude": 481700000,
        "Longitude": -790000000,
        "Elevation (m)": 292.9,
        "First Year": 1949,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1951,
        "MLY First Year": 1949,
        "MLY Last Year": 1951
      },
      {
        "Name": "NOTRE DAME DU NORD",
        "Province": "QUEBEC",
        "Climate ID": "7085682",
        "Station ID": 5992,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -79.45,
        "Latitude": 473700000,
        "Longitude": -792700000,
        "Elevation (m)": 214.9,
        "First Year": 1986,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1988,
        "MLY First Year": 1986,
        "MLY Last Year": 1987
      },
      {
        "Name": "RAPIDE DES JOACHIMS",
        "Province": "QUEBEC",
        "Climate ID": "7086380",
        "Station ID": 5993,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -77.7,
        "Latitude": 461200000,
        "Longitude": -774200000,
        "Elevation (m)": 137.2,
        "First Year": 1975,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2012,
        "MLY First Year": 1976,
        "MLY Last Year": 2012
      },
      {
        "Name": "RAPIDE-SEPT",
        "Province": "QUEBEC",
        "Climate ID": "7086400",
        "Station ID": 5994,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.77,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 474600000,
        "Longitude": -781800000,
        "Elevation (m)": 310.9,
        "First Year": 1941,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1975,
        "MLY First Year": 1941,
        "MLY Last Year": 1975
      },
      {
        "Name": "RAPIDE NO 2",
        "Province": "QUEBEC",
        "Climate ID": "7086440",
        "Station ID": 5995,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.93,
        "Longitude (Decimal Degrees)": -78.57,
        "Latitude": 475600000,
        "Longitude": -783400000,
        "Elevation (m)": 289.6,
        "First Year": 1952,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1969,
        "MLY First Year": 1952,
        "MLY Last Year": 1969
      },
      {
        "Name": "RED PINE CHUTE",
        "Province": "QUEBEC",
        "Climate ID": "7086452",
        "Station ID": 5996,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -78.62,
        "Latitude": 465100000,
        "Longitude": -783700000,
        "Elevation (m)": 274.3,
        "First Year": 1927,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1941,
        "MLY First Year": 1927,
        "MLY Last Year": 1941
      },
      {
        "Name": "REMIGNY",
        "Province": "QUEBEC",
        "Climate ID": "7086460",
        "Station ID": 5997,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.72,
        "Longitude (Decimal Degrees)": -79.23,
        "Latitude": 474300000,
        "Longitude": -791400000,
        "Elevation (m)": 289.9,
        "First Year": 1971,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2004,
        "MLY First Year": 1971,
        "MLY Last Year": 2004
      },
      {
        "Name": "RIVIERE KINOJEVIS",
        "Province": "QUEBEC",
        "Climate ID": "7086630",
        "Station ID": 5998,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -78.87,
        "Latitude": 481300000,
        "Longitude": -785200000,
        "Elevation (m)": 289.6,
        "First Year": 1974,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1994,
        "MLY First Year": 1974,
        "MLY Last Year": 1994
      },
      {
        "Name": "ROQUEMAURE",
        "Province": "QUEBEC",
        "Climate ID": "7086697",
        "Station ID": 10893,
        "WMO ID": null,
        "TC ID": "WGQ",
        "Latitude (Decimal Degrees)": 48.63,
        "Longitude (Decimal Degrees)": -79.46,
        "Latitude": 483757000,
        "Longitude": -792728000,
        "Elevation (m)": 269.4,
        "First Year": 1994,
        "Last Year": 2010,
        "HLY First Year": 1994,
        "HLY Last Year": 2003,
        "DLY First Year": 1994,
        "DLY Last Year": 2010,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROUYN",
        "Province": "QUEBEC",
        "Climate ID": "7086716",
        "Station ID": 10849,
        "WMO ID": 71734,
        "TC ID": "WUY",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -79.03,
        "Latitude": 481445000,
        "Longitude": -790203000,
        "Elevation (m)": 318,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1994,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROUYN",
        "Province": "QUEBEC",
        "Climate ID": "7086717",
        "Station ID": 5999,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.28,
        "Longitude (Decimal Degrees)": -78.97,
        "Latitude": 481700000,
        "Longitude": -785800000,
        "Elevation (m)": 289.6,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "ROUYN",
        "Province": "QUEBEC",
        "Climate ID": "7086718",
        "Station ID": 6000,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -78.92,
        "Latitude": 481800000,
        "Longitude": -785500000,
        "Elevation (m)": 274.3,
        "First Year": 1927,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1927,
        "DLY Last Year": 1950,
        "MLY First Year": 1927,
        "MLY Last Year": 1950
      },
      {
        "Name": "ROUYN-NORANDA A",
        "Province": "QUEBEC",
        "Climate ID": "7086719",
        "Station ID": 50090,
        "WMO ID": null,
        "TC ID": "YUY",
        "Latitude (Decimal Degrees)": 48.21,
        "Longitude (Decimal Degrees)": -78.84,
        "Latitude": 481222000,
        "Longitude": -785008000,
        "Elevation (m)": 301.1,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ROUYN A",
        "Province": "QUEBEC",
        "Climate ID": "7086720",
        "Station ID": 6001,
        "WMO ID": null,
        "TC ID": "YUY",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 481300000,
        "Longitude": -785000000,
        "Elevation (m)": 301.1,
        "First Year": 1950,
        "Last Year": 2012,
        "HLY First Year": 1954,
        "HLY Last Year": 2012,
        "DLY First Year": 1950,
        "DLY Last Year": 1955,
        "MLY First Year": 1950,
        "MLY Last Year": 1961
      },
      {
        "Name": "VILLE MARIE",
        "Province": "QUEBEC",
        "Climate ID": "7088760",
        "Station ID": 6002,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.35,
        "Longitude (Decimal Degrees)": -79.43,
        "Latitude": 472100000,
        "Longitude": -792600000,
        "Elevation (m)": 213,
        "First Year": 1913,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2004,
        "MLY First Year": 1913,
        "MLY Last Year": 2004
      },
      {
        "Name": "EVAIN",
        "Province": "QUEBEC",
        "Climate ID": "708B309",
        "Station ID": 5971,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.25,
        "Longitude (Decimal Degrees)": -79.1,
        "Latitude": 481500000,
        "Longitude": -790600000,
        "Elevation (m)": 302.4,
        "First Year": 1986,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1991,
        "MLY First Year": 1986,
        "MLY Last Year": 1991
      },
      {
        "Name": "LATULIPE",
        "Province": "QUEBEC",
        "Climate ID": "708DBCE",
        "Station ID": 5972,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.43,
        "Longitude (Decimal Degrees)": -79.02,
        "Latitude": 472600000,
        "Longitude": -790100000,
        "Elevation (m)": 274.3,
        "First Year": 1971,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2004,
        "MLY First Year": 1971,
        "MLY Last Year": 2004
      },
      {
        "Name": "ROUYN MCWATTERS",
        "Province": "QUEBEC",
        "Climate ID": "708FGB0",
        "Station ID": 5973,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -78.87,
        "Latitude": 481300000,
        "Longitude": -785200000,
        "Elevation (m)": 286.5,
        "First Year": 1966,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1975,
        "MLY First Year": 1966,
        "MLY Last Year": 1975
      },
      {
        "Name": "LAC SASSAGENAGA",
        "Province": "QUEBEC",
        "Climate ID": "708L70F",
        "Station ID": 5974,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.13,
        "Longitude (Decimal Degrees)": -78.62,
        "Latitude": 470800000,
        "Longitude": -783700000,
        "Elevation (m)": 335.3,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "ABITIBI POST",
        "Province": "QUEBEC",
        "Climate ID": "7090050",
        "Station ID": 6018,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.72,
        "Longitude (Decimal Degrees)": -79.37,
        "Latitude": 484300000,
        "Longitude": -792200000,
        "Elevation (m)": 259.1,
        "First Year": 1896,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1896,
        "DLY Last Year": 1936,
        "MLY First Year": 1896,
        "MLY Last Year": 1936
      },
      {
        "Name": "AMOS",
        "Province": "QUEBEC",
        "Climate ID": "7090120",
        "Station ID": 6019,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -78.13,
        "Latitude": 483400000,
        "Longitude": -780800000,
        "Elevation (m)": 310,
        "First Year": 1913,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 2000,
        "MLY First Year": 1913,
        "MLY Last Year": 2000
      },
      {
        "Name": "BARRAUTE",
        "Province": "QUEBEC",
        "Climate ID": "7090470",
        "Station ID": 6020,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -77.65,
        "Latitude": 482200000,
        "Longitude": -773900000,
        "Elevation (m)": 305,
        "First Year": 1958,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1994,
        "MLY First Year": 1959,
        "MLY Last Year": 1994
      },
      {
        "Name": "BARRIERE LOUVICOURT",
        "Province": "QUEBEC",
        "Climate ID": "7090473",
        "Station ID": 6021,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.87,
        "Longitude (Decimal Degrees)": -77.33,
        "Latitude": 475200000,
        "Longitude": -772000000,
        "Elevation (m)": 317.3,
        "First Year": 1963,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1974
      },
      {
        "Name": "BARVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7090520",
        "Station ID": 6022,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -77.47,
        "Latitude": 482500000,
        "Longitude": -772800000,
        "Elevation (m)": 318.5,
        "First Year": 1953,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1957,
        "MLY First Year": 1953,
        "MLY Last Year": 1957
      },
      {
        "Name": "BROUILLAN",
        "Province": "QUEBEC",
        "Climate ID": "7090866",
        "Station ID": 6023,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.8,
        "Longitude (Decimal Degrees)": -78.95,
        "Latitude": 494800000,
        "Longitude": -785700000,
        "Elevation (m)": 259.1,
        "First Year": 1977,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1986,
        "MLY First Year": 1977,
        "MLY Last Year": 1986
      },
      {
        "Name": "CACHE LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7090960",
        "Station ID": 6024,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.83,
        "Longitude (Decimal Degrees)": -74.42,
        "Latitude": 495000000,
        "Longitude": -742500000,
        "Elevation (m)": 381.9,
        "First Year": 1951,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1960,
        "MLY First Year": 1951,
        "MLY Last Year": 1960
      },
      {
        "Name": "CHAPAIS",
        "Province": "QUEBEC",
        "Climate ID": "7091295",
        "Station ID": 6025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -74.87,
        "Latitude": 494700000,
        "Longitude": -745200000,
        "Elevation (m)": 402,
        "First Year": 1962,
        "Last Year": 1971,
        "HLY First Year": 1962,
        "HLY Last Year": 1971,
        "DLY First Year": 1962,
        "DLY Last Year": 1971,
        "MLY First Year": 1962,
        "MLY Last Year": 1971
      },
      {
        "Name": "CHAPAIS",
        "Province": "QUEBEC",
        "Climate ID": "7091299",
        "Station ID": 27443,
        "WMO ID": 71824,
        "TC ID": "WUK",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -74.98,
        "Latitude": 494921000,
        "Longitude": -745831000,
        "Elevation (m)": 381.1,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2001,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHAPAIS 2",
        "Province": "QUEBEC",
        "Climate ID": "7091305",
        "Station ID": 6026,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -74.85,
        "Latitude": 494700000,
        "Longitude": -745100000,
        "Elevation (m)": 396.2,
        "First Year": 1962,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 2004,
        "MLY First Year": 1962,
        "MLY Last Year": 2004
      },
      {
        "Name": "CHIBOUGAMAU-CHAPAIS A",
        "Province": "QUEBEC",
        "Climate ID": "7091399",
        "Station ID": 10865,
        "WMO ID": 71822,
        "TC ID": "YMT",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -74.53,
        "Latitude": 494600000,
        "Longitude": -743200000,
        "Elevation (m)": 387.1,
        "First Year": 1992,
        "Last Year": 2008,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1992,
        "DLY Last Year": 2008,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHIBOUGAMAU",
        "Province": "QUEBEC",
        "Climate ID": "7091400",
        "Station ID": 6027,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -74.37,
        "Latitude": 495500000,
        "Longitude": -742200000,
        "Elevation (m)": 378,
        "First Year": 1936,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1975,
        "MLY First Year": 1936,
        "MLY Last Year": 1975
      },
      {
        "Name": "CHIBOUGAMAU A",
        "Province": "QUEBEC",
        "Climate ID": "7091401",
        "Station ID": 6028,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -74.42,
        "Latitude": 494900000,
        "Longitude": -742500000,
        "Elevation (m)": 401.7,
        "First Year": 1971,
        "Last Year": 1982,
        "HLY First Year": 1971,
        "HLY Last Year": 1982,
        "DLY First Year": 1971,
        "DLY Last Year": 1982,
        "MLY First Year": 1971,
        "MLY Last Year": 1982
      },
      {
        "Name": "CHIBOUGAMAU CHAPAIS A",
        "Province": "QUEBEC",
        "Climate ID": "7091404",
        "Station ID": 6029,
        "WMO ID": 71822,
        "TC ID": "YMT",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -74.53,
        "Latitude": 494600000,
        "Longitude": -743200000,
        "Elevation (m)": 387.1,
        "First Year": 1982,
        "Last Year": 2016,
        "HLY First Year": 1982,
        "HLY Last Year": 2014,
        "DLY First Year": 1982,
        "DLY Last Year": 2016,
        "MLY First Year": 1982,
        "MLY Last Year": 1992
      },
      {
        "Name": "CHIBOUGAMAU CHAPAIS A",
        "Province": "QUEBEC",
        "Climate ID": "7091405",
        "Station ID": 52603,
        "WMO ID": null,
        "TC ID": "YMT",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -74.53,
        "Latitude": 494619000,
        "Longitude": -743141000,
        "Elevation (m)": 387.1,
        "First Year": 2014,
        "Last Year": 2016,
        "HLY First Year": 2014,
        "HLY Last Year": 2016,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHIBOUGAMAU CHAPAIS A",
        "Province": "QUEBEC",
        "Climate ID": "7091409",
        "Station ID": 53019,
        "WMO ID": null,
        "TC ID": "YMT",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -74.53,
        "Latitude": 494619000,
        "Longitude": -743141000,
        "Elevation (m)": 387.1,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHIBOUGAMAU-CHAPAIS",
        "Province": "QUEBEC",
        "Climate ID": "7091410",
        "Station ID": 54599,
        "WMO ID": 73011,
        "TC ID": "GCH",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -74.53,
        "Latitude": 494638058,
        "Longitude": -743149068,
        "Elevation (m)": 381,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "COOPER LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7091920",
        "Station ID": 6030,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -74.42,
        "Latitude": 490000000,
        "Longitude": -742500000,
        "Elevation (m)": 426.7,
        "First Year": 1951,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1962,
        "MLY First Year": 1951,
        "MLY Last Year": 1962
      },
      {
        "Name": "DESMARAISVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7091984",
        "Station ID": 6031,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.52,
        "Longitude (Decimal Degrees)": -76.17,
        "Latitude": 493100000,
        "Longitude": -761000000,
        "Elevation (m)": 289.9,
        "First Year": 1978,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1984,
        "MLY First Year": 1978,
        "MLY Last Year": 1984
      },
      {
        "Name": "DESTOR",
        "Province": "QUEBEC",
        "Climate ID": "7091985",
        "Station ID": 6032,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -79.03,
        "Latitude": 483300000,
        "Longitude": -790200000,
        "Elevation (m)": 335.3,
        "First Year": 1965,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1968,
        "MLY First Year": 1965,
        "MLY Last Year": 1968
      },
      {
        "Name": "DOUCET",
        "Province": "QUEBEC",
        "Climate ID": "7092130",
        "Station ID": 6033,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.22,
        "Longitude (Decimal Degrees)": -76.62,
        "Latitude": 481300000,
        "Longitude": -763700000,
        "Elevation (m)": 376.7,
        "First Year": 1922,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1939,
        "MLY First Year": 1922,
        "MLY Last Year": 1939
      },
      {
        "Name": "EASTMAIN",
        "Province": "QUEBEC",
        "Climate ID": "7092305",
        "Station ID": 6034,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -78.52,
        "Latitude": 521500000,
        "Longitude": -783100000,
        "Elevation (m)": 6.1,
        "First Year": 1960,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1993,
        "MLY First Year": 1960,
        "MLY Last Year": 1993
      },
      {
        "Name": "EASTMAIN RIVER A",
        "Province": "QUEBEC",
        "Climate ID": "7092306",
        "Station ID": 52605,
        "WMO ID": null,
        "TC ID": "ZEM",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -78.52,
        "Latitude": 521335000,
        "Longitude": -783121000,
        "Elevation (m)": 7.3,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EASTMAIN A",
        "Province": "QUEBEC",
        "Climate ID": "709230N",
        "Station ID": 9002,
        "WMO ID": null,
        "TC ID": "ZEM",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -78.52,
        "Latitude": 521400000,
        "Longitude": -783100000,
        "Elevation (m)": 7.32,
        "First Year": 1992,
        "Last Year": 2014,
        "HLY First Year": 1992,
        "HLY Last Year": 2014,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EASTMAIN RIVER A",
        "Province": "QUEBEC",
        "Climate ID": "7092310",
        "Station ID": 53338,
        "WMO ID": null,
        "TC ID": "ZEM",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -78.52,
        "Latitude": 521335000,
        "Longitude": -783121000,
        "Elevation (m)": 7.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FORSYTHE",
        "Province": "QUEBEC",
        "Climate ID": "7092395",
        "Station ID": 6035,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.23,
        "Longitude (Decimal Degrees)": -76.43,
        "Latitude": 481400000,
        "Longitude": -762600000,
        "Elevation (m)": 374.6,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "FORT GEORGE",
        "Province": "QUEBEC",
        "Climate ID": "7092480",
        "Station ID": 6036,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -79,
        "Latitude": 535000000,
        "Longitude": -790000000,
        "Elevation (m)": 6.7,
        "First Year": 1915,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1969,
        "MLY First Year": 1915,
        "MLY Last Year": 1969
      },
      {
        "Name": "FORT RUPERT",
        "Province": "QUEBEC",
        "Climate ID": "7092524",
        "Station ID": 6037,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -78.75,
        "Latitude": 512900000,
        "Longitude": -784500000,
        "Elevation (m)": 12.2,
        "First Year": 1964,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1974,
        "MLY First Year": 1964,
        "MLY Last Year": 1974
      },
      {
        "Name": "HELENE",
        "Province": "QUEBEC",
        "Climate ID": "7093055",
        "Station ID": 6041,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -77.5,
        "Latitude": 532700000,
        "Longitude": -773000000,
        "Elevation (m)": 152.4,
        "First Year": 1975,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1979,
        "MLY First Year": 1975,
        "MLY Last Year": 1979
      },
      {
        "Name": "JOUTEL",
        "Province": "QUEBEC",
        "Climate ID": "7093376",
        "Station ID": 6042,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.47,
        "Longitude (Decimal Degrees)": -78.3,
        "Latitude": 492800000,
        "Longitude": -781800000,
        "Elevation (m)": 289.6,
        "First Year": 1973,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1998,
        "MLY First Year": 1973,
        "MLY Last Year": 1998
      },
      {
        "Name": "LAC CACHE",
        "Province": "QUEBEC",
        "Climate ID": "7093585",
        "Station ID": 6043,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.82,
        "Longitude (Decimal Degrees)": -74.43,
        "Latitude": 494900000,
        "Longitude": -742600000,
        "Elevation (m)": 350.5,
        "First Year": 1963,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1973,
        "MLY First Year": 1963,
        "MLY Last Year": 1973
      },
      {
        "Name": "LAC CHARRON",
        "Province": "QUEBEC",
        "Climate ID": "7093593",
        "Station ID": 6044,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -74.33,
        "Latitude": 493000000,
        "Longitude": -742000000,
        "Elevation (m)": 365.8,
        "First Year": 1951,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1952,
        "MLY First Year": 1951,
        "MLY Last Year": 1952
      },
      {
        "Name": "LAC NICAUBA",
        "Province": "QUEBEC",
        "Climate ID": "7093680",
        "Station ID": 6045,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -74.33,
        "Latitude": 492500000,
        "Longitude": -742000000,
        "Elevation (m)": 381,
        "First Year": 1952,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1960,
        "MLY First Year": 1952,
        "MLY Last Year": 1960
      },
      {
        "Name": "LAC 76475347 SITE 1",
        "Province": "QUEBEC",
        "Climate ID": "7093707",
        "Station ID": 8651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.78,
        "Longitude (Decimal Degrees)": -76.78,
        "Latitude": 534700000,
        "Longitude": -764700000,
        "Elevation (m)": null,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA FERME",
        "Province": "QUEBEC",
        "Climate ID": "7093710",
        "Station ID": 6046,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -78.17,
        "Latitude": 483500000,
        "Longitude": -781000000,
        "Elevation (m)": 320.3,
        "First Year": 1917,
        "Last Year": 1936,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1917,
        "DLY Last Year": 1936,
        "MLY First Year": 1917,
        "MLY Last Year": 1936
      },
      {
        "Name": "LA GRANDE 4 A",
        "Province": "QUEBEC",
        "Climate ID": "7093714",
        "Station ID": 49648,
        "WMO ID": 71003,
        "TC ID": "YAH",
        "Latitude (Decimal Degrees)": 53.75,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 534517000,
        "Longitude": -734031000,
        "Elevation (m)": 306.3,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA GRANDE RIVIERE A",
        "Province": "QUEBEC",
        "Climate ID": "7093715",
        "Station ID": 6047,
        "WMO ID": 71827,
        "TC ID": "YGL",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -77.7,
        "Latitude": 533800000,
        "Longitude": -774200000,
        "Elevation (m)": 195.1,
        "First Year": 1976,
        "Last Year": 2012,
        "HLY First Year": 1976,
        "HLY Last Year": 2012,
        "DLY First Year": 1976,
        "DLY Last Year": 2012,
        "MLY First Year": 1976,
        "MLY Last Year": 2005
      },
      {
        "Name": "LA GRANDE RIVIERE A",
        "Province": "QUEBEC",
        "Climate ID": "7093716",
        "Station ID": 50822,
        "WMO ID": 71827,
        "TC ID": "YGL",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -77.7,
        "Latitude": 533731000,
        "Longitude": -774215000,
        "Elevation (m)": 194.8,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA GRANDE IV A",
        "Province": "QUEBEC",
        "Climate ID": "7093GAC",
        "Station ID": 10798,
        "WMO ID": null,
        "TC ID": "YAH",
        "Latitude (Decimal Degrees)": 53.76,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 534526000,
        "Longitude": -734045000,
        "Elevation (m)": 306.3,
        "First Year": 2005,
        "Last Year": 2013,
        "HLY First Year": 2005,
        "HLY Last Year": 2013,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA GRANDE III A",
        "Province": "QUEBEC",
        "Climate ID": "7093GAL",
        "Station ID": 8992,
        "WMO ID": null,
        "TC ID": "YAD",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -76.2,
        "Latitude": 533400000,
        "Longitude": -761200000,
        "Elevation (m)": 236.22,
        "First Year": 1994,
        "Last Year": 2015,
        "HLY First Year": 1994,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LA GRANDE IV A",
        "Province": "QUEBEC",
        "Climate ID": "7093GJ3",
        "Station ID": 6038,
        "WMO ID": null,
        "TC ID": "YAH",
        "Latitude (Decimal Degrees)": 53.76,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 534526000,
        "Longitude": -734045000,
        "Elevation (m)": 306.3,
        "First Year": 1985,
        "Last Year": 2018,
        "HLY First Year": 1985,
        "HLY Last Year": 2005,
        "DLY First Year": 1985,
        "DLY Last Year": 2018,
        "MLY First Year": 1986,
        "MLY Last Year": 1995
      },
      {
        "Name": "LA GRANDE IV",
        "Province": "QUEBEC",
        "Climate ID": "7093GJ5",
        "Station ID": 44204,
        "WMO ID": 71823,
        "TC ID": "GAH",
        "Latitude (Decimal Degrees)": 53.76,
        "Longitude (Decimal Degrees)": -73.68,
        "Latitude": 534526000,
        "Longitude": -734043000,
        "Elevation (m)": 302.8,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAC 76475347 SITE 2",
        "Province": "QUEBEC",
        "Climate ID": "7093P07",
        "Station ID": 6039,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.75,
        "Longitude (Decimal Degrees)": -76.7,
        "Latitude": 534500000,
        "Longitude": -764200000,
        "Elevation (m)": null,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "LA GRANDE LG3",
        "Province": "QUEBEC",
        "Climate ID": "7093P1L",
        "Station ID": 6040,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.72,
        "Longitude (Decimal Degrees)": -75.95,
        "Latitude": 534300000,
        "Longitude": -755700000,
        "Elevation (m)": 243.8,
        "First Year": 1974,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1984,
        "MLY First Year": 1974,
        "MLY Last Year": 1984
      },
      {
        "Name": "LA MORANDIERE",
        "Province": "QUEBEC",
        "Climate ID": "7094026",
        "Station ID": 6048,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.62,
        "Longitude (Decimal Degrees)": -77.62,
        "Latitude": 483700000,
        "Longitude": -773700000,
        "Elevation (m)": 296.9,
        "First Year": 1963,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 2004,
        "MLY First Year": 1965,
        "MLY Last Year": 2004
      },
      {
        "Name": "LA SARRE",
        "Province": "QUEBEC",
        "Climate ID": "7094120",
        "Station ID": 6049,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -79.22,
        "Latitude": 484700000,
        "Longitude": -791300000,
        "Elevation (m)": 244.1,
        "First Year": 1951,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2004,
        "MLY First Year": 1951,
        "MLY Last Year": 2004
      },
      {
        "Name": "LA SARRE",
        "Province": "QUEBEC",
        "Climate ID": "7094121",
        "Station ID": 6050,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -79.1,
        "Latitude": 484700000,
        "Longitude": -790600000,
        "Elevation (m)": 289.6,
        "First Year": 1978,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1983,
        "MLY First Year": 1978,
        "MLY Last Year": 1983
      },
      {
        "Name": "LEBEL SUR QUEVILLON",
        "Province": "QUEBEC",
        "Climate ID": "7094275",
        "Station ID": 6051,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -76.97,
        "Latitude": 490300000,
        "Longitude": -765800000,
        "Elevation (m)": 304.5,
        "First Year": 1967,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2004,
        "MLY First Year": 1967,
        "MLY Last Year": 2004
      },
      {
        "Name": "MATAGAMI A",
        "Province": "QUEBEC",
        "Climate ID": "7094636",
        "Station ID": 49491,
        "WMO ID": 71947,
        "TC ID": "YNM",
        "Latitude (Decimal Degrees)": 49.76,
        "Longitude (Decimal Degrees)": -77.8,
        "Latitude": 494542000,
        "Longitude": -774810000,
        "Elevation (m)": 279.8,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MATAGAMI",
        "Province": "QUEBEC",
        "Climate ID": "7094637",
        "Station ID": 10791,
        "WMO ID": 71821,
        "TC ID": "GMG",
        "Latitude (Decimal Degrees)": 49.76,
        "Longitude (Decimal Degrees)": -77.79,
        "Latitude": 494528020,
        "Longitude": -774735060,
        "Elevation (m)": 281.03,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MATAGAMI",
        "Province": "QUEBEC",
        "Climate ID": "7094638",
        "Station ID": 6052,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.73,
        "Longitude (Decimal Degrees)": -77.63,
        "Latitude": 494400000,
        "Longitude": -773800000,
        "Elevation (m)": 256,
        "First Year": 1963,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1974,
        "MLY First Year": 1963,
        "MLY Last Year": 1974
      },
      {
        "Name": "MATAGAMI A",
        "Province": "QUEBEC",
        "Climate ID": "7094639",
        "Station ID": 6053,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -77.82,
        "Latitude": 494600000,
        "Longitude": -774900000,
        "Elevation (m)": 281.3,
        "First Year": 1973,
        "Last Year": 1991,
        "HLY First Year": 1973,
        "HLY Last Year": 1991,
        "DLY First Year": 1973,
        "DLY Last Year": 1991,
        "MLY First Year": 1973,
        "MLY Last Year": 1991
      },
      {
        "Name": "MEGISCANE",
        "Province": "QUEBEC",
        "Climate ID": "7094840",
        "Station ID": 6054,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -77.08,
        "Latitude": 482400000,
        "Longitude": -770500000,
        "Elevation (m)": 416.7,
        "First Year": 1952,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1957,
        "MLY First Year": 1952,
        "MLY Last Year": 1957
      },
      {
        "Name": "MIQUELON",
        "Province": "QUEBEC",
        "Climate ID": "7094985",
        "Station ID": 6055,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.42,
        "Longitude (Decimal Degrees)": -76.45,
        "Latitude": 492500000,
        "Longitude": -762700000,
        "Elevation (m)": 304.8,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1976
      },
      {
        "Name": "MISTASSINI POST",
        "Province": "QUEBEC",
        "Climate ID": "7095000",
        "Station ID": 6056,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.42,
        "Longitude (Decimal Degrees)": -73.88,
        "Latitude": 502500000,
        "Longitude": -735300000,
        "Elevation (m)": 379.8,
        "First Year": 1885,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1980,
        "MLY First Year": 1885,
        "MLY Last Year": 1980
      },
      {
        "Name": "NEMISCAU",
        "Province": "QUEBEC",
        "Climate ID": "7095409",
        "Station ID": 6057,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.32,
        "Longitude (Decimal Degrees)": -77.02,
        "Latitude": 511900000,
        "Longitude": -770100000,
        "Elevation (m)": 231.3,
        "First Year": 1964,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1972,
        "MLY First Year": 1964,
        "MLY Last Year": 1972
      },
      {
        "Name": "NEMISCAU A",
        "Province": "QUEBEC",
        "Climate ID": "7095412",
        "Station ID": 8676,
        "WMO ID": null,
        "TC ID": "YHH",
        "Latitude (Decimal Degrees)": 51.7,
        "Longitude (Decimal Degrees)": -76.12,
        "Latitude": 514200000,
        "Longitude": -760700000,
        "Elevation (m)": 244.5,
        "First Year": 1994,
        "Last Year": 2015,
        "HLY First Year": 1994,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NITCHEQUON",
        "Province": "QUEBEC",
        "Climate ID": "7095480",
        "Station ID": 6058,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.2,
        "Longitude (Decimal Degrees)": -70.9,
        "Latitude": 531200000,
        "Longitude": -705400000,
        "Elevation (m)": 536.1,
        "First Year": 1942,
        "Last Year": 1985,
        "HLY First Year": 1953,
        "HLY Last Year": 1985,
        "DLY First Year": 1942,
        "DLY Last Year": 1985,
        "MLY First Year": 1942,
        "MLY Last Year": 1985
      },
      {
        "Name": "NOUVEAU COMPTOIR",
        "Province": "QUEBEC",
        "Climate ID": "7095700",
        "Station ID": 6059,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53,
        "Longitude (Decimal Degrees)": -78.82,
        "Latitude": 530000000,
        "Longitude": -784900000,
        "Elevation (m)": 9.1,
        "First Year": 1964,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1975,
        "MLY First Year": 1964,
        "MLY Last Year": 1975
      },
      {
        "Name": "OPEMISKA",
        "Province": "QUEBEC",
        "Climate ID": "7095740",
        "Station ID": 6060,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -74.87,
        "Latitude": 494700000,
        "Longitude": -745200000,
        "Elevation (m)": 314.6,
        "First Year": 1955,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1956,
        "MLY First Year": 1955,
        "MLY Last Year": 1956
      },
      {
        "Name": "PALMAROLLE",
        "Province": "QUEBEC",
        "Climate ID": "7095785",
        "Station ID": 6061,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 484000000,
        "Longitude": -791000000,
        "Elevation (m)": 275.8,
        "First Year": 1986,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1988,
        "MLY First Year": 1986,
        "MLY Last Year": 1988
      },
      {
        "Name": "PETIT LAC OPINACA",
        "Province": "QUEBEC",
        "Climate ID": "7096007",
        "Station ID": 6062,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.37,
        "Longitude (Decimal Degrees)": -76.77,
        "Latitude": 522200000,
        "Longitude": -764600000,
        "Elevation (m)": null,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "POSTE DE CHAPAIS",
        "Province": "QUEBEC",
        "Climate ID": "7096205",
        "Station ID": 6063,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.48,
        "Longitude (Decimal Degrees)": -75.3,
        "Latitude": 492900000,
        "Longitude": -751800000,
        "Elevation (m)": 381,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "POULARIES",
        "Province": "QUEBEC",
        "Climate ID": "7096215",
        "Station ID": 6064,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.68,
        "Longitude (Decimal Degrees)": -78.98,
        "Latitude": 484100000,
        "Longitude": -785900000,
        "Elevation (m)": 290,
        "First Year": 1969,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1994,
        "MLY First Year": 1969,
        "MLY Last Year": 1994
      },
      {
        "Name": "PRESSE",
        "Province": "QUEBEC",
        "Climate ID": "7096230",
        "Station ID": 6065,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.23,
        "Longitude (Decimal Degrees)": -76.73,
        "Latitude": 481400000,
        "Longitude": -764400000,
        "Elevation (m)": 366.1,
        "First Year": 1970,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1970,
        "MLY First Year": 1970,
        "MLY Last Year": 1970
      },
      {
        "Name": "RAPIDE DES CEDRES",
        "Province": "QUEBEC",
        "Climate ID": "7096375",
        "Station ID": 6066,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -77.07,
        "Latitude": 490100000,
        "Longitude": -770400000,
        "Elevation (m)": 279.5,
        "First Year": 1963,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1979,
        "MLY First Year": 1963,
        "MLY Last Year": 1979
      },
      {
        "Name": "RENAULT",
        "Province": "QUEBEC",
        "Climate ID": "7096462",
        "Station ID": 6067,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.78,
        "Longitude (Decimal Degrees)": -75.87,
        "Latitude": 494700000,
        "Longitude": -755200000,
        "Elevation (m)": 335.3,
        "First Year": 1977,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1980,
        "MLY First Year": 1977,
        "MLY Last Year": 1980
      },
      {
        "Name": "RIVIERE EASTMAIN",
        "Province": "QUEBEC",
        "Climate ID": "7096618",
        "Station ID": 6068,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -77.1,
        "Latitude": 521900000,
        "Longitude": -770600000,
        "Elevation (m)": 246.6,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "RIVIERE HEVA",
        "Province": "QUEBEC",
        "Climate ID": "7096621",
        "Station ID": 6069,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.2,
        "Longitude (Decimal Degrees)": -78.13,
        "Latitude": 481200000,
        "Longitude": -780800000,
        "Elevation (m)": 305,
        "First Year": 1984,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2004,
        "MLY First Year": 1984,
        "MLY Last Year": 2004
      },
      {
        "Name": "RIVIERE MICHEL",
        "Province": "QUEBEC",
        "Climate ID": "7096650",
        "Station ID": 6070,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.33,
        "Longitude (Decimal Degrees)": -77.08,
        "Latitude": 522000000,
        "Longitude": -770500000,
        "Elevation (m)": 182.9,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "RIVIERE RUPERT",
        "Province": "QUEBEC",
        "Climate ID": "7096661",
        "Station ID": 6071,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.35,
        "Longitude (Decimal Degrees)": -77.33,
        "Latitude": 512100000,
        "Longitude": -772000000,
        "Elevation (m)": 259.1,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "RIVIERE TEMISCAMIE",
        "Province": "QUEBEC",
        "Climate ID": "7096669",
        "Station ID": 6072,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.95,
        "Longitude (Decimal Degrees)": -73.03,
        "Latitude": 505700000,
        "Longitude": -730200000,
        "Elevation (m)": 411.5,
        "First Year": 1973,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1977,
        "MLY First Year": 1973,
        "MLY Last Year": 1977
      },
      {
        "Name": "RUPERT",
        "Province": "QUEBEC",
        "Climate ID": "7096733",
        "Station ID": 6073,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -77.15,
        "Latitude": 521100000,
        "Longitude": -770900000,
        "Elevation (m)": 243.8,
        "First Year": 1977,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1977,
        "MLY First Year": 1977,
        "MLY Last Year": 1977
      },
      {
        "Name": "SENNETERRE",
        "Province": "QUEBEC",
        "Climate ID": "7097888",
        "Station ID": 6074,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -77.25,
        "Latitude": 482400000,
        "Longitude": -771500000,
        "Elevation (m)": 316.4,
        "First Year": 1940,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1951,
        "MLY First Year": 1940,
        "MLY Last Year": 1951
      },
      {
        "Name": "SENNETERRE",
        "Province": "QUEBEC",
        "Climate ID": "7097900",
        "Station ID": 6075,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -77.27,
        "Latitude": 482000000,
        "Longitude": -771600000,
        "Elevation (m)": 310,
        "First Year": 1967,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1994,
        "MLY First Year": 1967,
        "MLY Last Year": 1994
      },
      {
        "Name": "SENNETERRE A",
        "Province": "QUEBEC",
        "Climate ID": "7097902",
        "Station ID": 6076,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.33,
        "Longitude (Decimal Degrees)": -77.18,
        "Latitude": 482000000,
        "Longitude": -771100000,
        "Elevation (m)": 338.6,
        "First Year": 1960,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1960,
        "MLY First Year": 1960,
        "MLY Last Year": 1960
      },
      {
        "Name": "SENNETERRE (RADAR)",
        "Province": "QUEBEC",
        "Climate ID": "7097906",
        "Station ID": 6077,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.37,
        "Longitude (Decimal Degrees)": -77.23,
        "Latitude": 482200000,
        "Longitude": -771400000,
        "Elevation (m)": 313.9,
        "First Year": 1957,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1975,
        "MLY First Year": 1957,
        "MLY Last Year": 1975
      },
      {
        "Name": "TASCHEREAU",
        "Province": "QUEBEC",
        "Climate ID": "7098360",
        "Station ID": 6078,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -78.7,
        "Latitude": 484000000,
        "Longitude": -784200000,
        "Elevation (m)": 310,
        "First Year": 1951,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 2004,
        "MLY First Year": 1952,
        "MLY Last Year": 2004
      },
      {
        "Name": "TASCHEREAU 2",
        "Province": "QUEBEC",
        "Climate ID": "7098362",
        "Station ID": 6079,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -78.68,
        "Latitude": 484000000,
        "Longitude": -784100000,
        "Elevation (m)": 317,
        "First Year": 1963,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1966,
        "MLY First Year": 1963,
        "MLY Last Year": 1966
      },
      {
        "Name": "VAL-D'OR A",
        "Province": "QUEBEC",
        "Climate ID": "7098600",
        "Station ID": 6081,
        "WMO ID": 71725,
        "TC ID": "YVO",
        "Latitude (Decimal Degrees)": 48.06,
        "Longitude (Decimal Degrees)": -77.79,
        "Latitude": 480323000,
        "Longitude": -774712000,
        "Elevation (m)": 337.4,
        "First Year": 1951,
        "Last Year": 2022,
        "HLY First Year": 1955,
        "HLY Last Year": 2012,
        "DLY First Year": 1951,
        "DLY Last Year": 2022,
        "MLY First Year": 1951,
        "MLY Last Year": 2005
      },
      {
        "Name": "VAL-D'OR",
        "Province": "QUEBEC",
        "Climate ID": "7098603",
        "Station ID": 30172,
        "WMO ID": 71725,
        "TC ID": "GVO",
        "Latitude (Decimal Degrees)": 48.06,
        "Longitude (Decimal Degrees)": -77.79,
        "Latitude": 480323004,
        "Longitude": -774711007,
        "Elevation (m)": 338.88,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VAL D'OR A",
        "Province": "QUEBEC",
        "Climate ID": "7098605",
        "Station ID": 49390,
        "WMO ID": 71941,
        "TC ID": "YVO",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -77.78,
        "Latitude": 480312000,
        "Longitude": -774658000,
        "Elevation (m)": 337.4,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VAL D'OR A",
        "Province": "QUEBEC",
        "Climate ID": "7098606",
        "Station ID": 50720,
        "WMO ID": null,
        "TC ID": "YVO",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -77.78,
        "Latitude": 480312000,
        "Longitude": -774658000,
        "Elevation (m)": 337.4,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VAL ST GILLES",
        "Province": "QUEBEC",
        "Climate ID": "70986RN",
        "Station ID": 6080,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -79.13,
        "Latitude": 485900000,
        "Longitude": -790800000,
        "Elevation (m)": 290,
        "First Year": 1973,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2004,
        "MLY First Year": 1973,
        "MLY Last Year": 2004
      },
      {
        "Name": "WASKAGANISH A",
        "Province": "QUEBEC",
        "Climate ID": "7098895",
        "Station ID": 10216,
        "WMO ID": null,
        "TC ID": "YKQ",
        "Latitude (Decimal Degrees)": 51.48,
        "Longitude (Decimal Degrees)": -78.75,
        "Latitude": 512900000,
        "Longitude": -784500000,
        "Elevation (m)": 24.08,
        "First Year": 1992,
        "Last Year": 2014,
        "HLY First Year": 1992,
        "HLY Last Year": 2014,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WASKAGANISH A",
        "Province": "QUEBEC",
        "Climate ID": "7098896",
        "Station ID": 52081,
        "WMO ID": null,
        "TC ID": "YKQ",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -78.76,
        "Latitude": 512824000,
        "Longitude": -784530000,
        "Elevation (m)": 24.08,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WASKAGANISH A",
        "Province": "QUEBEC",
        "Climate ID": "7098898",
        "Station ID": 53018,
        "WMO ID": null,
        "TC ID": "YKQ",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -78.76,
        "Latitude": 512824000,
        "Longitude": -784530000,
        "Elevation (m)": 24.1,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WEMINDJI A",
        "Province": "QUEBEC",
        "Climate ID": "7098904",
        "Station ID": 10212,
        "WMO ID": null,
        "TC ID": "YNC",
        "Latitude (Decimal Degrees)": 53.02,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 530100000,
        "Longitude": -785000000,
        "Elevation (m)": 20.11,
        "First Year": 1992,
        "Last Year": 2014,
        "HLY First Year": 1992,
        "HLY Last Year": 2014,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WEMINDJI A",
        "Province": "QUEBEC",
        "Climate ID": "7098905",
        "Station ID": 53158,
        "WMO ID": null,
        "TC ID": "YNC",
        "Latitude (Decimal Degrees)": 53.01,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 530038000,
        "Longitude": -784952000,
        "Elevation (m)": 20.1,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WEMINDJI A",
        "Province": "QUEBEC",
        "Climate ID": "7098908",
        "Station ID": 53159,
        "WMO ID": null,
        "TC ID": "YNC",
        "Latitude (Decimal Degrees)": 53.01,
        "Longitude (Decimal Degrees)": -78.83,
        "Latitude": 530038000,
        "Longitude": -784952000,
        "Elevation (m)": 20.1,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAMP DES PINS",
        "Province": "QUEBEC",
        "Climate ID": "709A0J4",
        "Station ID": 6003,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -77.67,
        "Latitude": 533300000,
        "Longitude": -774000000,
        "Elevation (m)": 175.3,
        "First Year": 1975,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1990,
        "MLY First Year": 1975,
        "MLY Last Year": 1990
      },
      {
        "Name": "DUPARQUET",
        "Province": "QUEBEC",
        "Climate ID": "709BBDH",
        "Station ID": 6004,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.52,
        "Longitude (Decimal Degrees)": -79.27,
        "Latitude": 483100000,
        "Longitude": -791600000,
        "Elevation (m)": 289.6,
        "First Year": 1981,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1994
      },
      {
        "Name": "LAC RANDAL",
        "Province": "QUEBEC",
        "Climate ID": "709C6HE",
        "Station ID": 6010,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -77.35,
        "Latitude": 512200000,
        "Longitude": -772100000,
        "Elevation (m)": 228.6,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "LA GRANDE LG4",
        "Province": "QUEBEC",
        "Climate ID": "709C71L",
        "Station ID": 6011,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -73.5,
        "Latitude": 535000000,
        "Longitude": -733000000,
        "Elevation (m)": 335.3,
        "First Year": 1978,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1985,
        "MLY First Year": 1978,
        "MLY Last Year": 1985
      },
      {
        "Name": "LAC CHICOBI",
        "Province": "QUEBEC",
        "Climate ID": "709CE9F",
        "Station ID": 6007,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.83,
        "Longitude (Decimal Degrees)": -78.48,
        "Latitude": 485000000,
        "Longitude": -782900000,
        "Elevation (m)": 310.9,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "LAC BERRY",
        "Province": "QUEBEC",
        "Climate ID": "709CEE9",
        "Station ID": 6005,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.8,
        "Longitude (Decimal Degrees)": -78.28,
        "Latitude": 484800000,
        "Longitude": -781700000,
        "Elevation (m)": 305,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2018
      },
      {
        "Name": "LAC ALBANEL",
        "Province": "QUEBEC",
        "Climate ID": "709CENL",
        "Station ID": 6006,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.97,
        "Longitude (Decimal Degrees)": -73.03,
        "Latitude": 505800000,
        "Longitude": -730200000,
        "Elevation (m)": 396.2,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "LAC ALLARD",
        "Province": "QUEBEC",
        "Climate ID": "709CNEN",
        "Station ID": 6008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -77.68,
        "Latitude": 504300000,
        "Longitude": -774100000,
        "Elevation (m)": 228.6,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "LAC ATTILA",
        "Province": "QUEBEC",
        "Climate ID": "709CNN6",
        "Station ID": 6009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.57,
        "Longitude (Decimal Degrees)": -77.62,
        "Latitude": 533400000,
        "Longitude": -773700000,
        "Elevation (m)": 152.4,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "MATAGAMI (MILLE 42)",
        "Province": "QUEBEC",
        "Climate ID": "709DFCH",
        "Station ID": 6012,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -77.97,
        "Latitude": 490400000,
        "Longitude": -775800000,
        "Elevation (m)": 325.2,
        "First Year": 1978,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1978,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIVIERE TURGEON",
        "Province": "QUEBEC",
        "Climate ID": "709FFG0",
        "Station ID": 6013,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -79.17,
        "Latitude": 485900000,
        "Longitude": -791000000,
        "Elevation (m)": 283.5,
        "First Year": 1965,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1972,
        "MLY First Year": 1965,
        "MLY Last Year": 1972
      },
      {
        "Name": "CAMP DES SAULES",
        "Province": "QUEBEC",
        "Climate ID": "709J0A4",
        "Station ID": 6014,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.18,
        "Longitude (Decimal Degrees)": -77.15,
        "Latitude": 521100000,
        "Longitude": -770900000,
        "Elevation (m)": 243.8,
        "First Year": 1978,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1984,
        "MLY First Year": 1978,
        "MLY Last Year": 1984
      },
      {
        "Name": "LAC NATHALIE",
        "Province": "QUEBEC",
        "Climate ID": "709LFGH",
        "Station ID": 6015,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.45,
        "Longitude (Decimal Degrees)": -77.45,
        "Latitude": 532700000,
        "Longitude": -772700000,
        "Elevation (m)": 160,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "LAC CARAVAN",
        "Province": "QUEBEC",
        "Climate ID": "709LNR0",
        "Station ID": 6016,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -77.37,
        "Latitude": 530200000,
        "Longitude": -772200000,
        "Elevation (m)": 228.6,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "LOUVICOURT",
        "Province": "QUEBEC",
        "Climate ID": "709M332",
        "Station ID": 6017,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.07,
        "Longitude (Decimal Degrees)": -77.38,
        "Latitude": 480400000,
        "Longitude": -772300000,
        "Elevation (m)": 320,
        "First Year": 1980,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1994,
        "MLY First Year": 1980,
        "MLY Last Year": 1994
      },
      {
        "Name": "AKULIVIK A",
        "Province": "QUEBEC",
        "Climate ID": "7100071",
        "Station ID": 54067,
        "WMO ID": null,
        "TC ID": "YKO",
        "Latitude (Decimal Degrees)": 60.82,
        "Longitude (Decimal Degrees)": -78.15,
        "Latitude": 604907000,
        "Longitude": -780855000,
        "Elevation (m)": 23.2,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AKULIVIK A",
        "Province": "QUEBEC",
        "Climate ID": "7100072",
        "Station ID": 54068,
        "WMO ID": null,
        "TC ID": "YKO",
        "Latitude (Decimal Degrees)": 60.82,
        "Longitude (Decimal Degrees)": -78.15,
        "Latitude": 604907000,
        "Longitude": -780855000,
        "Elevation (m)": 23.2,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AKULIVIK A",
        "Province": "QUEBEC",
        "Climate ID": "7100075",
        "Station ID": 10789,
        "WMO ID": null,
        "TC ID": "YKO",
        "Latitude (Decimal Degrees)": 60.82,
        "Longitude (Decimal Degrees)": -78.15,
        "Latitude": 604900000,
        "Longitude": -780900000,
        "Elevation (m)": 23.16,
        "First Year": 1992,
        "Last Year": 2015,
        "HLY First Year": 1992,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INUKJUAK A",
        "Province": "QUEBEC",
        "Climate ID": "7103280",
        "Station ID": 48974,
        "WMO ID": 71306,
        "TC ID": "YPH",
        "Latitude (Decimal Degrees)": 58.47,
        "Longitude (Decimal Degrees)": -78.08,
        "Latitude": 582819000,
        "Longitude": -780437000,
        "Elevation (m)": 25.3,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INUKJUAK UA",
        "Province": "QUEBEC",
        "Climate ID": "7103282",
        "Station ID": 6082,
        "WMO ID": 71907,
        "TC ID": "WPH",
        "Latitude (Decimal Degrees)": 58.47,
        "Longitude (Decimal Degrees)": -78.08,
        "Latitude": 582800000,
        "Longitude": -780500000,
        "Elevation (m)": 24.4,
        "First Year": 1921,
        "Last Year": 1996,
        "HLY First Year": 1953,
        "HLY Last Year": 1995,
        "DLY First Year": 1921,
        "DLY Last Year": 1996,
        "MLY First Year": 1922,
        "MLY Last Year": 1994
      },
      {
        "Name": "INUKJUAK A",
        "Province": "QUEBEC",
        "Climate ID": "7103283",
        "Station ID": 10204,
        "WMO ID": null,
        "TC ID": "YPH",
        "Latitude (Decimal Degrees)": 58.47,
        "Longitude (Decimal Degrees)": -78.08,
        "Latitude": 582819000,
        "Longitude": -780437000,
        "Elevation (m)": 26.21,
        "First Year": 1992,
        "Last Year": 2014,
        "HLY First Year": 1992,
        "HLY Last Year": 2014,
        "DLY First Year": 1994,
        "DLY Last Year": 2008,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INUKJUAK",
        "Province": "QUEBEC",
        "Climate ID": "7103285",
        "Station ID": 48268,
        "WMO ID": null,
        "TC ID": "MQN",
        "Latitude (Decimal Degrees)": 58.45,
        "Longitude (Decimal Degrees)": -78.17,
        "Latitude": 582704175,
        "Longitude": -781003864,
        "Elevation (m)": 10,
        "First Year": 2009,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IVUJIVIK A",
        "Province": "QUEBEC",
        "Climate ID": "7103327",
        "Station ID": 8660,
        "WMO ID": null,
        "TC ID": "YIK",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -77.93,
        "Latitude": 622456000,
        "Longitude": -775531000,
        "Elevation (m)": 38.7,
        "First Year": 1992,
        "Last Year": 2015,
        "HLY First Year": 1992,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IVUJIVIK A",
        "Province": "QUEBEC",
        "Climate ID": "7103328",
        "Station ID": 54065,
        "WMO ID": null,
        "TC ID": "YIK",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -77.93,
        "Latitude": 622502000,
        "Longitude": -775531000,
        "Elevation (m)": 38.7,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "IVUJIVIK A",
        "Province": "QUEBEC",
        "Climate ID": "7103329",
        "Station ID": 54066,
        "WMO ID": null,
        "TC ID": "YIK",
        "Latitude (Decimal Degrees)": 62.42,
        "Longitude (Decimal Degrees)": -77.93,
        "Latitude": 622502000,
        "Longitude": -775531000,
        "Elevation (m)": 38.7,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KUUJJUARAPIK A",
        "Province": "QUEBEC",
        "Climate ID": "7103536",
        "Station ID": 6083,
        "WMO ID": null,
        "TC ID": "YGW",
        "Latitude (Decimal Degrees)": 55.28,
        "Longitude (Decimal Degrees)": -77.75,
        "Latitude": 551700000,
        "Longitude": -774500000,
        "Elevation (m)": 12.2,
        "First Year": 1925,
        "Last Year": 2014,
        "HLY First Year": 1957,
        "HLY Last Year": 2014,
        "DLY First Year": 1925,
        "DLY Last Year": 2014,
        "MLY First Year": 1926,
        "MLY Last Year": 2009
      },
      {
        "Name": "KUUJJUARAPIK A",
        "Province": "QUEBEC",
        "Climate ID": "7103537",
        "Station ID": 52201,
        "WMO ID": null,
        "TC ID": "YGW",
        "Latitude (Decimal Degrees)": 55.28,
        "Longitude (Decimal Degrees)": -77.77,
        "Latitude": 551655000,
        "Longitude": -774555000,
        "Elevation (m)": 12.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KUUJJUARAPIK",
        "Province": "QUEBEC",
        "Climate ID": "7103539",
        "Station ID": 53978,
        "WMO ID": 71615,
        "TC ID": "MFJ",
        "Latitude (Decimal Degrees)": 55.37,
        "Longitude (Decimal Degrees)": -77.57,
        "Latitude": 552200000,
        "Longitude": -773400000,
        "Elevation (m)": 30,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POVUNGNITUK",
        "Province": "QUEBEC",
        "Climate ID": "7106220",
        "Station ID": 6084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.03,
        "Longitude (Decimal Degrees)": -77.27,
        "Latitude": 600200000,
        "Longitude": -771600000,
        "Elevation (m)": 15.2,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "PUVIRNITUQ A",
        "Province": "QUEBEC",
        "Climate ID": "7106222",
        "Station ID": 10877,
        "WMO ID": null,
        "TC ID": "YPX",
        "Latitude (Decimal Degrees)": 60.05,
        "Longitude (Decimal Degrees)": -77.28,
        "Latitude": 600300000,
        "Longitude": -771700000,
        "Elevation (m)": 25.3,
        "First Year": 1993,
        "Last Year": 2014,
        "HLY First Year": 1993,
        "HLY Last Year": 2014,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PUVIRNITUQ A",
        "Province": "QUEBEC",
        "Climate ID": "7106223",
        "Station ID": 52038,
        "WMO ID": null,
        "TC ID": "YPX",
        "Latitude (Decimal Degrees)": 60.05,
        "Longitude (Decimal Degrees)": -77.29,
        "Latitude": 600308000,
        "Longitude": -771715000,
        "Elevation (m)": 25.3,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UMIUJAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7108564",
        "Station ID": 54070,
        "WMO ID": null,
        "TC ID": "YMU",
        "Latitude (Decimal Degrees)": 56.54,
        "Longitude (Decimal Degrees)": -76.52,
        "Latitude": 563210000,
        "Longitude": -763106000,
        "Elevation (m)": 76.5,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UMIUJAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7108566",
        "Station ID": 54069,
        "WMO ID": null,
        "TC ID": "YMU",
        "Latitude (Decimal Degrees)": 56.54,
        "Longitude (Decimal Degrees)": -76.52,
        "Latitude": 563210000,
        "Longitude": -763106000,
        "Elevation (m)": 76.5,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UMIUJAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7108568",
        "Station ID": 10878,
        "WMO ID": null,
        "TC ID": "YMU",
        "Latitude (Decimal Degrees)": 56.53,
        "Longitude (Decimal Degrees)": -76.52,
        "Latitude": 563156000,
        "Longitude": -763112008,
        "Elevation (m)": 76.5,
        "First Year": 1993,
        "Last Year": 2015,
        "HLY First Year": 1993,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "INUKJUAK",
        "Province": "QUEBEC",
        "Climate ID": "710S005",
        "Station ID": 30166,
        "WMO ID": 71907,
        "TC ID": "MJK",
        "Latitude (Decimal Degrees)": 58.47,
        "Longitude (Decimal Degrees)": -78.08,
        "Latitude": 582819000,
        "Longitude": -780437000,
        "Elevation (m)": 26.21,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ASBESTOS HILL",
        "Province": "QUEBEC",
        "Climate ID": "7110370",
        "Station ID": 6087,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.8,
        "Longitude (Decimal Degrees)": -73.95,
        "Latitude": 614800000,
        "Longitude": -735700000,
        "Elevation (m)": 483.7,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1963
      },
      {
        "Name": "AUPALUK A",
        "Province": "QUEBEC",
        "Climate ID": "7110600",
        "Station ID": 54038,
        "WMO ID": null,
        "TC ID": "YLA",
        "Latitude (Decimal Degrees)": 59.3,
        "Longitude (Decimal Degrees)": -69.6,
        "Latitude": 591748000,
        "Longitude": -693559000,
        "Elevation (m)": 36.9,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "AUPALUK A",
        "Province": "QUEBEC",
        "Climate ID": "7110601",
        "Station ID": 54058,
        "WMO ID": null,
        "TC ID": "YLA",
        "Latitude (Decimal Degrees)": 59.3,
        "Longitude (Decimal Degrees)": -69.6,
        "Latitude": 591748000,
        "Longitude": -693559000,
        "Elevation (m)": 36.9,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BORDER A",
        "Province": "QUEBEC",
        "Climate ID": "7110830",
        "Station ID": 6088,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -63.22,
        "Latitude": 552000000,
        "Longitude": -631300000,
        "Elevation (m)": 464.8,
        "First Year": 1965,
        "Last Year": 1979,
        "HLY First Year": 1965,
        "HLY Last Year": 1979,
        "DLY First Year": 1965,
        "DLY Last Year": 1979,
        "MLY First Year": 1965,
        "MLY Last Year": 1979
      },
      {
        "Name": "BORDER (AUT)",
        "Province": "QUEBEC",
        "Climate ID": "7110831",
        "Station ID": 8668,
        "WMO ID": null,
        "TC ID": "WTB",
        "Latitude (Decimal Degrees)": 55.33,
        "Longitude (Decimal Degrees)": -63.22,
        "Latitude": 552000000,
        "Longitude": -631300000,
        "Elevation (m)": 479,
        "First Year": 1993,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": 1993,
        "DLY Last Year": 1998,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAIE WAKEHAM",
        "Province": "QUEBEC",
        "Climate ID": "7110DMF",
        "Station ID": 6086,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.6,
        "Longitude (Decimal Degrees)": -71.97,
        "Latitude": 613600000,
        "Longitude": -715800000,
        "Elevation (m)": 6.1,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "AUPALUK A",
        "Province": "QUEBEC",
        "Climate ID": "7110LRJ",
        "Station ID": 10855,
        "WMO ID": null,
        "TC ID": "YLA",
        "Latitude (Decimal Degrees)": 59.3,
        "Longitude (Decimal Degrees)": -69.6,
        "Latitude": 591810000,
        "Longitude": -693559000,
        "Elevation (m)": 36.9,
        "First Year": 1992,
        "Last Year": 2015,
        "HLY First Year": 1992,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE HOPES ADVANCE",
        "Province": "QUEBEC",
        "Climate ID": "7111080",
        "Station ID": 6089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.08,
        "Longitude (Decimal Degrees)": -69.55,
        "Latitude": 610500000,
        "Longitude": -693300000,
        "Elevation (m)": 73.2,
        "First Year": 1928,
        "Last Year": 1971,
        "HLY First Year": 1953,
        "HLY Last Year": 1971,
        "DLY First Year": 1928,
        "DLY Last Year": 1971,
        "MLY First Year": 1929,
        "MLY Last Year": 1971
      },
      {
        "Name": "DECEPTION BAY",
        "Province": "QUEBEC",
        "Climate ID": "7111955",
        "Station ID": 6090,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.12,
        "Longitude (Decimal Degrees)": -74.62,
        "Latitude": 620700000,
        "Longitude": -743700000,
        "Elevation (m)": 30.5,
        "First Year": 1963,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1963,
        "DLY Last Year": 1973,
        "MLY First Year": 1963,
        "MLY Last Year": 1973
      },
      {
        "Name": "DUPLANTER",
        "Province": "QUEBEC",
        "Climate ID": "7112249",
        "Station ID": 6091,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.83,
        "Longitude (Decimal Degrees)": -69.88,
        "Latitude": 545000000,
        "Longitude": -695300000,
        "Elevation (m)": 533.4,
        "First Year": 1978,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1984,
        "MLY First Year": 1978,
        "MLY Last Year": 1984
      },
      {
        "Name": "FORT MCKENZIE",
        "Province": "QUEBEC",
        "Climate ID": "7112520",
        "Station ID": 6092,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.88,
        "Longitude (Decimal Degrees)": -69.05,
        "Latitude": 565300000,
        "Longitude": -690300000,
        "Elevation (m)": 76.2,
        "First Year": 1938,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1951,
        "MLY First Year": 1938,
        "MLY Last Year": 1951
      },
      {
        "Name": "HOPES ADVANCE BAY",
        "Province": "QUEBEC",
        "Climate ID": "7113150",
        "Station ID": 6093,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 59.33,
        "Longitude (Decimal Degrees)": -69.77,
        "Latitude": 592000000,
        "Longitude": -694600000,
        "Elevation (m)": 46.6,
        "First Year": 1959,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1959,
        "MLY First Year": 1959,
        "MLY Last Year": 1959
      },
      {
        "Name": "INDIAN HOUSE LAKE",
        "Province": "QUEBEC",
        "Climate ID": "7113280",
        "Station ID": 6094,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 56.23,
        "Longitude (Decimal Degrees)": -64.73,
        "Latitude": 561400000,
        "Longitude": -644400000,
        "Elevation (m)": 310.9,
        "First Year": 1944,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1944,
        "DLY Last Year": 1964,
        "MLY First Year": 1944,
        "MLY Last Year": 1964
      },
      {
        "Name": "KANGIQSUJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113379",
        "Station ID": 54061,
        "WMO ID": null,
        "TC ID": "YKG",
        "Latitude (Decimal Degrees)": 61.59,
        "Longitude (Decimal Degrees)": -71.93,
        "Latitude": 613519000,
        "Longitude": -715546000,
        "Elevation (m)": 157.6,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIQSUJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113380",
        "Station ID": 54062,
        "WMO ID": null,
        "TC ID": "YKG",
        "Latitude (Decimal Degrees)": 61.59,
        "Longitude (Decimal Degrees)": -71.93,
        "Latitude": 613519000,
        "Longitude": -715546000,
        "Elevation (m)": 157.6,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIQSUJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113381",
        "Station ID": 10252,
        "WMO ID": null,
        "TC ID": "YKG",
        "Latitude (Decimal Degrees)": 61.59,
        "Longitude (Decimal Degrees)": -71.93,
        "Latitude": 613527001,
        "Longitude": -715555002,
        "Elevation (m)": 157.58,
        "First Year": 1992,
        "Last Year": 2015,
        "HLY First Year": 1992,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIRSUK A",
        "Province": "QUEBEC",
        "Climate ID": "7113382",
        "Station ID": 10205,
        "WMO ID": null,
        "TC ID": "YAS",
        "Latitude (Decimal Degrees)": 60.02,
        "Longitude (Decimal Degrees)": -70,
        "Latitude": 600123000,
        "Longitude": -700013000,
        "Elevation (m)": 123.75,
        "First Year": 1992,
        "Last Year": 2015,
        "HLY First Year": 1992,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIRSUK A",
        "Province": "QUEBEC",
        "Climate ID": "7113383",
        "Station ID": 54071,
        "WMO ID": null,
        "TC ID": "YAS",
        "Latitude (Decimal Degrees)": 60.03,
        "Longitude (Decimal Degrees)": -70,
        "Latitude": 600138000,
        "Longitude": -695957000,
        "Elevation (m)": 123.8,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIRSUK A",
        "Province": "QUEBEC",
        "Climate ID": "7113384",
        "Station ID": 54072,
        "WMO ID": null,
        "TC ID": "YAS",
        "Latitude (Decimal Degrees)": 60.03,
        "Longitude (Decimal Degrees)": -70,
        "Latitude": 600138000,
        "Longitude": -695957000,
        "Elevation (m)": 123.8,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIQSUALUJJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113390",
        "Station ID": 54073,
        "WMO ID": null,
        "TC ID": "YLU",
        "Latitude (Decimal Degrees)": 58.71,
        "Longitude (Decimal Degrees)": -65.99,
        "Latitude": 584241000,
        "Longitude": -655934000,
        "Elevation (m)": 66.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIQSUALUJJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113391",
        "Station ID": 54074,
        "WMO ID": null,
        "TC ID": "YLU",
        "Latitude (Decimal Degrees)": 58.71,
        "Longitude (Decimal Degrees)": -65.99,
        "Latitude": 584241000,
        "Longitude": -655934000,
        "Elevation (m)": 66.1,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KUUJJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113534",
        "Station ID": 6095,
        "WMO ID": 71906,
        "TC ID": "YVP",
        "Latitude (Decimal Degrees)": 58.1,
        "Longitude (Decimal Degrees)": -68.42,
        "Latitude": 580600000,
        "Longitude": -682500000,
        "Elevation (m)": 39.93,
        "First Year": 1947,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1947,
        "DLY Last Year": 2014,
        "MLY First Year": 1947,
        "MLY Last Year": 2005
      },
      {
        "Name": "KUUJJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7113535",
        "Station ID": 52179,
        "WMO ID": 71906,
        "TC ID": "YVP",
        "Latitude (Decimal Degrees)": 58.09,
        "Longitude (Decimal Degrees)": -68.42,
        "Latitude": 580522000,
        "Longitude": -682520000,
        "Elevation (m)": 39.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PARC NATIONAL DES PINGUALUIT",
        "Province": "QUEBEC",
        "Climate ID": "7115800",
        "Station ID": 48228,
        "WMO ID": 71976,
        "TC ID": "MIN",
        "Latitude (Decimal Degrees)": 61.31,
        "Longitude (Decimal Degrees)": -73.67,
        "Latitude": 611846199,
        "Longitude": -734006264,
        "Elevation (m)": 503.4,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PAYNE RIVER",
        "Province": "QUEBEC",
        "Climate ID": "7115880",
        "Station ID": 6096,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 60.1,
        "Longitude (Decimal Degrees)": -71.07,
        "Latitude": 600600000,
        "Longitude": -710400000,
        "Elevation (m)": null,
        "First Year": 1954,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1958,
        "MLY First Year": 1954,
        "MLY Last Year": 1958
      },
      {
        "Name": "QUAQTAQ",
        "Province": "QUEBEC",
        "Climate ID": "7116270",
        "Station ID": 6097,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 61.05,
        "Longitude (Decimal Degrees)": -69.63,
        "Latitude": 610300000,
        "Longitude": -693800000,
        "Elevation (m)": 30.4,
        "First Year": 1971,
        "Last Year": 1989,
        "HLY First Year": 1971,
        "HLY Last Year": 1989,
        "DLY First Year": 1971,
        "DLY Last Year": 1988,
        "MLY First Year": 1971,
        "MLY Last Year": 1987
      },
      {
        "Name": "QUAQTAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7116271",
        "Station ID": 54059,
        "WMO ID": null,
        "TC ID": "YHA",
        "Latitude (Decimal Degrees)": 61.05,
        "Longitude (Decimal Degrees)": -69.62,
        "Latitude": 610247000,
        "Longitude": -693704000,
        "Elevation (m)": 32.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QUAQTAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7116272",
        "Station ID": 10245,
        "WMO ID": null,
        "TC ID": "YHA",
        "Latitude (Decimal Degrees)": 61.05,
        "Longitude (Decimal Degrees)": -69.62,
        "Latitude": 610254096,
        "Longitude": -693726046,
        "Elevation (m)": 32.31,
        "First Year": 1989,
        "Last Year": 2015,
        "HLY First Year": 1989,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "QUAQTAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7116273",
        "Station ID": 54060,
        "WMO ID": null,
        "TC ID": "YHA",
        "Latitude (Decimal Degrees)": 61.05,
        "Longitude (Decimal Degrees)": -69.62,
        "Latitude": 610247000,
        "Longitude": -693704000,
        "Elevation (m)": 32.3,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RIVIERE AUX FEUILLES",
        "Province": "QUEBEC",
        "Climate ID": "7116505",
        "Station ID": 44143,
        "WMO ID": 71743,
        "TC ID": "MRF",
        "Latitude (Decimal Degrees)": 57.91,
        "Longitude (Decimal Degrees)": -72.98,
        "Latitude": 575434006,
        "Longitude": -725833004,
        "Elevation (m)": 171.39,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SALLUIT",
        "Province": "QUEBEC",
        "Climate ID": "7117800",
        "Station ID": 52378,
        "WMO ID": 71641,
        "TC ID": "MFX",
        "Latitude (Decimal Degrees)": 62.18,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 621046005,
        "Longitude": -754013006,
        "Elevation (m)": 228,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SALLUIT A",
        "Province": "QUEBEC",
        "Climate ID": "7117801",
        "Station ID": 54063,
        "WMO ID": null,
        "TC ID": "YZG",
        "Latitude (Decimal Degrees)": 62.18,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 621046000,
        "Longitude": -754002000,
        "Elevation (m)": 227.1,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SALLUIT A",
        "Province": "QUEBEC",
        "Climate ID": "7117802",
        "Station ID": 54064,
        "WMO ID": null,
        "TC ID": "YZG",
        "Latitude (Decimal Degrees)": 62.18,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 621046000,
        "Longitude": -754002000,
        "Elevation (m)": 227.1,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SCHEFFERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7117821",
        "Station ID": 10876,
        "WMO ID": null,
        "TC ID": "ZKL",
        "Latitude (Decimal Degrees)": 54.8,
        "Longitude (Decimal Degrees)": -66.8,
        "Latitude": 544800000,
        "Longitude": -664800000,
        "Elevation (m)": 518.2,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1993,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SCHEFFERVILLE A",
        "Province": "QUEBEC",
        "Climate ID": "7117823",
        "Station ID": 49649,
        "WMO ID": 71921,
        "TC ID": "YKL",
        "Latitude (Decimal Degrees)": 54.81,
        "Longitude (Decimal Degrees)": -66.81,
        "Latitude": 544819000,
        "Longitude": -664819000,
        "Elevation (m)": 520.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SCHEFFERVILLE COTE-NORD",
        "Province": "QUEBEC",
        "Climate ID": "7117824",
        "Station ID": 54878,
        "WMO ID": 73075,
        "TC ID": "GKM",
        "Latitude (Decimal Degrees)": 54.8,
        "Longitude (Decimal Degrees)": -66.8,
        "Latitude": 544809072,
        "Longitude": -664816032,
        "Elevation (m)": 517.2,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SCHEFFERVILLE A",
        "Province": "QUEBEC",
        "Climate ID": "7117825",
        "Station ID": 6098,
        "WMO ID": null,
        "TC ID": "YKL",
        "Latitude (Decimal Degrees)": 54.8,
        "Longitude (Decimal Degrees)": -66.82,
        "Latitude": 544800000,
        "Longitude": -664900000,
        "Elevation (m)": 521.8,
        "First Year": 1948,
        "Last Year": 2010,
        "HLY First Year": 1953,
        "HLY Last Year": 2010,
        "DLY First Year": 1948,
        "DLY Last Year": 2010,
        "MLY First Year": 1948,
        "MLY Last Year": 1993
      },
      {
        "Name": "SCHEFFERVILLE",
        "Province": "QUEBEC",
        "Climate ID": "7117827",
        "Station ID": 30164,
        "WMO ID": 71828,
        "TC ID": "GKL",
        "Latitude (Decimal Degrees)": 54.8,
        "Longitude (Decimal Degrees)": -66.8,
        "Latitude": 544800000,
        "Longitude": -664800000,
        "Elevation (m)": 517.2,
        "First Year": 2005,
        "Last Year": 2018,
        "HLY First Year": 2005,
        "HLY Last Year": 2018,
        "DLY First Year": 2007,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TASIUJAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7118365",
        "Station ID": 54018,
        "WMO ID": null,
        "TC ID": "YTQ",
        "Latitude (Decimal Degrees)": 58.67,
        "Longitude (Decimal Degrees)": -69.96,
        "Latitude": 584004000,
        "Longitude": -695721000,
        "Elevation (m)": 36.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TASIUJAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7118366",
        "Station ID": 54019,
        "WMO ID": null,
        "TC ID": "YTQ",
        "Latitude (Decimal Degrees)": 58.67,
        "Longitude (Decimal Degrees)": -69.96,
        "Latitude": 584004000,
        "Longitude": -695721000,
        "Elevation (m)": 36.9,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TASIUJAQ A",
        "Province": "QUEBEC",
        "Climate ID": "7118369",
        "Station ID": 10856,
        "WMO ID": null,
        "TC ID": "YTQ",
        "Latitude (Decimal Degrees)": 58.67,
        "Longitude (Decimal Degrees)": -69.95,
        "Latitude": 584016000,
        "Longitude": -695655000,
        "Elevation (m)": 36.9,
        "First Year": 1993,
        "Last Year": 2015,
        "HLY First Year": 1993,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SALLUIT A",
        "Province": "QUEBEC",
        "Climate ID": "711GG87",
        "Station ID": 10226,
        "WMO ID": null,
        "TC ID": "YZG",
        "Latitude (Decimal Degrees)": 62.18,
        "Longitude (Decimal Degrees)": -75.67,
        "Latitude": 621055005,
        "Longitude": -754010001,
        "Elevation (m)": 227.08,
        "First Year": 1969,
        "Last Year": 2015,
        "HLY First Year": 1969,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KANGIQSUALUJJUAQ A",
        "Province": "QUEBEC",
        "Climate ID": "711L380",
        "Station ID": 10684,
        "WMO ID": null,
        "TC ID": "YLU",
        "Latitude (Decimal Degrees)": 58.71,
        "Longitude (Decimal Degrees)": -65.99,
        "Latitude": 584227080,
        "Longitude": -655915020,
        "Elevation (m)": 66.14,
        "First Year": 1992,
        "Last Year": 2015,
        "HLY First Year": 1992,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUGLUK",
        "Province": "QUEBEC",
        "Climate ID": "711Q2H7",
        "Station ID": 6085,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 62.2,
        "Longitude (Decimal Degrees)": -75.63,
        "Latitude": 621200000,
        "Longitude": -753800000,
        "Elevation (m)": 15.2,
        "First Year": 1966,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1967,
        "MLY First Year": 1966,
        "MLY Last Year": 1967
      },
      {
        "Name": "ACADIA FOREST EXP ST",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100100",
        "Station ID": 6106,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.99,
        "Longitude (Decimal Degrees)": -66.36,
        "Latitude": 455925000,
        "Longitude": -662148000,
        "Elevation (m)": 54,
        "First Year": 1955,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 2010,
        "MLY First Year": 1955,
        "MLY Last Year": 2006
      },
      {
        "Name": "ACADIEVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100105",
        "Station ID": 6107,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.73,
        "Longitude (Decimal Degrees)": -65.28,
        "Latitude": 464400000,
        "Longitude": -651700000,
        "Elevation (m)": 61,
        "First Year": 1980,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1995,
        "MLY First Year": 1980,
        "MLY Last Year": 1995
      },
      {
        "Name": "ALMA",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100200",
        "Station ID": 6108,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -64.95,
        "Latitude": 453600000,
        "Longitude": -645700000,
        "Elevation (m)": 42.7,
        "First Year": 1950,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2011,
        "MLY First Year": 1950,
        "MLY Last Year": 2006
      },
      {
        "Name": "AROOSTOOK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100300",
        "Station ID": 6109,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.71,
        "Longitude (Decimal Degrees)": -67.72,
        "Latitude": 464244000,
        "Longitude": -674256000,
        "Elevation (m)": 80,
        "First Year": 1929,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 2022,
        "MLY First Year": 1929,
        "MLY Last Year": 2005
      },
      {
        "Name": "ARTHURETTE BIRCH RIDGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100350",
        "Station ID": 6110,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.75,
        "Longitude (Decimal Degrees)": -67.47,
        "Latitude": 464500000,
        "Longitude": -672800000,
        "Elevation (m)": 214.9,
        "First Year": 1967,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1983,
        "MLY First Year": 1967,
        "MLY Last Year": 1983
      },
      {
        "Name": "ASHTON HILL",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100400",
        "Station ID": 6111,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.1,
        "Longitude (Decimal Degrees)": -65.73,
        "Latitude": 470600000,
        "Longitude": -654400000,
        "Elevation (m)": 278.9,
        "First Year": 1935,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1947,
        "MLY First Year": 1935,
        "MLY Last Year": 1947
      },
      {
        "Name": "BALTIMORE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100430",
        "Station ID": 6112,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -64.82,
        "Latitude": 455300000,
        "Longitude": -644900000,
        "Elevation (m)": 182.9,
        "First Year": 1982,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1997,
        "MLY First Year": 1982,
        "MLY Last Year": 1997
      },
      {
        "Name": "BAS CARAQUET",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100467",
        "Station ID": 6915,
        "WMO ID": 71598,
        "TC ID": "WXS",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -64.83,
        "Latitude": 474808000,
        "Longitude": -645000000,
        "Elevation (m)": 5,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "BAS CARAQUET",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100468",
        "Station ID": 6113,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -64.87,
        "Latitude": 474800000,
        "Longitude": -645200000,
        "Elevation (m)": 10.7,
        "First Year": 1982,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2022,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "BATHURST",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100500",
        "Station ID": 6114,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -65.65,
        "Latitude": 473700000,
        "Longitude": -653900000,
        "Elevation (m)": 12.2,
        "First Year": 1872,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1972,
        "MLY First Year": 1872,
        "MLY Last Year": 1972
      },
      {
        "Name": "BATHURST",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100502",
        "Station ID": 6115,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -65.65,
        "Latitude": 473700000,
        "Longitude": -653900000,
        "Elevation (m)": 4.6,
        "First Year": 1964,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1992,
        "MLY First Year": 1964,
        "MLY Last Year": 1992
      },
      {
        "Name": "BATHURST A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100503",
        "Station ID": 6916,
        "WMO ID": 71574,
        "TC ID": "ZBF",
        "Latitude (Decimal Degrees)": 47.63,
        "Longitude (Decimal Degrees)": -65.75,
        "Latitude": 473745050,
        "Longitude": -654454020,
        "Elevation (m)": 58.8,
        "First Year": 1992,
        "Last Year": 2013,
        "HLY First Year": 1994,
        "HLY Last Year": 2013,
        "DLY First Year": 1992,
        "DLY Last Year": 2013,
        "MLY First Year": 1992,
        "MLY Last Year": 2013
      },
      {
        "Name": "BATHURST A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100505",
        "Station ID": 51418,
        "WMO ID": null,
        "TC ID": "ZBF",
        "Latitude (Decimal Degrees)": 47.63,
        "Longitude (Decimal Degrees)": -65.74,
        "Latitude": 473747000,
        "Longitude": -654420000,
        "Elevation (m)": 58.8,
        "First Year": 2013,
        "Last Year": 2018,
        "HLY First Year": 2013,
        "HLY Last Year": 2018,
        "DLY First Year": 2013,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BATHURST A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100506",
        "Station ID": 54283,
        "WMO ID": 71574,
        "TC ID": "ZBF",
        "Latitude (Decimal Degrees)": 47.63,
        "Longitude (Decimal Degrees)": -65.74,
        "Latitude": 473747000,
        "Longitude": -654420000,
        "Elevation (m)": 58.8,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEECHWOOD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100512",
        "Station ID": 6116,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.53,
        "Longitude (Decimal Degrees)": -67.67,
        "Latitude": 463200000,
        "Longitude": -674000000,
        "Elevation (m)": 91.4,
        "First Year": 1966,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1997,
        "MLY First Year": 1966,
        "MLY Last Year": 1997
      },
      {
        "Name": "BELLEDUNE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100514",
        "Station ID": 6117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.9,
        "Longitude (Decimal Degrees)": -65.83,
        "Latitude": 475400000,
        "Longitude": -655000000,
        "Elevation (m)": 7.6,
        "First Year": 1971,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1990,
        "MLY First Year": 1971,
        "MLY Last Year": 1990
      },
      {
        "Name": "BELLEDUNE CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100515",
        "Station ID": 6917,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.9,
        "Longitude (Decimal Degrees)": -65.83,
        "Latitude": 475400000,
        "Longitude": -655000000,
        "Elevation (m)": 7.6,
        "First Year": 1991,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2002,
        "MLY First Year": 1991,
        "MLY Last Year": 2002
      },
      {
        "Name": "BERRYTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100516",
        "Station ID": 6118,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -64.88,
        "Latitude": 455500000,
        "Longitude": -645300000,
        "Elevation (m)": 106.7,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "BERTRAND",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100518",
        "Station ID": 6119,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.75,
        "Longitude (Decimal Degrees)": -65.07,
        "Latitude": 474500000,
        "Longitude": -650400000,
        "Elevation (m)": 22.9,
        "First Year": 1964,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2011,
        "MLY First Year": 1964,
        "MLY Last Year": 2006
      },
      {
        "Name": "BIG BALD MOUNTAIN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100525",
        "Station ID": 6120,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.2,
        "Longitude (Decimal Degrees)": -66.43,
        "Latitude": 471200000,
        "Longitude": -662600000,
        "Elevation (m)": null,
        "First Year": 1935,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1946,
        "MLY First Year": 1935,
        "MLY Last Year": 1946
      },
      {
        "Name": "BLISSVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100550",
        "Station ID": 6121,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -66.55,
        "Latitude": 453700000,
        "Longitude": -663300000,
        "Elevation (m)": 22.3,
        "First Year": 1940,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1951,
        "MLY First Year": 1940,
        "MLY Last Year": 1951
      },
      {
        "Name": "BLUE MOUNTAIN TOWER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100558",
        "Station ID": 6122,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -66.3,
        "Latitude": 474800000,
        "Longitude": -661800000,
        "Elevation (m)": 160,
        "First Year": 1935,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1951,
        "MLY First Year": 1935,
        "MLY Last Year": 1950
      },
      {
        "Name": "BON ACCORD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100566",
        "Station ID": 6123,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.65,
        "Longitude (Decimal Degrees)": -67.58,
        "Latitude": 463903050,
        "Longitude": -673504030,
        "Elevation (m)": 450.3,
        "First Year": 1966,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2011,
        "MLY First Year": 1966,
        "MLY Last Year": 2005
      },
      {
        "Name": "BROCKWAY",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100570",
        "Station ID": 6124,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -67.12,
        "Latitude": 453000000,
        "Longitude": -670700000,
        "Elevation (m)": 83.8,
        "First Year": 1958,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1966,
        "MLY First Year": 1958,
        "MLY Last Year": 1966
      },
      {
        "Name": "BRUNSWICK MINES",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100583",
        "Station ID": 6125,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.48,
        "Longitude (Decimal Degrees)": -65.88,
        "Latitude": 472900000,
        "Longitude": -655300000,
        "Elevation (m)": 213.4,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "BOUCTOUCHE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100590",
        "Station ID": 6126,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.52,
        "Longitude (Decimal Degrees)": -64.72,
        "Latitude": 463100000,
        "Longitude": -644300000,
        "Elevation (m)": 10.7,
        "First Year": 1965,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1999,
        "MLY First Year": 1965,
        "MLY Last Year": 1999
      },
      {
        "Name": "BOUCTOUCHE CDA",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100592",
        "Station ID": 6127,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -64.77,
        "Latitude": 462600000,
        "Longitude": -644600000,
        "Elevation (m)": 36,
        "First Year": 1982,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1991,
        "MLY First Year": 1982,
        "MLY Last Year": 1991
      },
      {
        "Name": "BOUCTOUCHE CDA CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100593",
        "Station ID": 6918,
        "WMO ID": 71666,
        "TC ID": "ABT",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -64.77,
        "Latitude": 462549006,
        "Longitude": -644605009,
        "Elevation (m)": 35.9,
        "First Year": 1991,
        "Last Year": 2021,
        "HLY First Year": 2005,
        "HLY Last Year": 2021,
        "DLY First Year": 1991,
        "DLY Last Year": 2021,
        "MLY First Year": 1991,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURNT CHURCH",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100600",
        "Station ID": 6128,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.2,
        "Longitude (Decimal Degrees)": -65.13,
        "Latitude": 471200000,
        "Longitude": -650800000,
        "Elevation (m)": 7.6,
        "First Year": 1934,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1958,
        "MLY First Year": 1934,
        "MLY Last Year": 1958
      },
      {
        "Name": "BURNT CHURCH",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100601",
        "Station ID": 6129,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -65.13,
        "Latitude": 471400000,
        "Longitude": -650800000,
        "Elevation (m)": 15.2,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "CAMPBELLTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100700",
        "Station ID": 6130,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48,
        "Longitude (Decimal Degrees)": -66.67,
        "Latitude": 480000000,
        "Longitude": -664000000,
        "Elevation (m)": 7.6,
        "First Year": 1945,
        "Last Year": 1966,
        "HLY First Year": 1953,
        "HLY Last Year": 1966,
        "DLY First Year": 1945,
        "DLY Last Year": 1966,
        "MLY First Year": 1945,
        "MLY Last Year": 1966
      },
      {
        "Name": "CAMPBELLTON POWER ST",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100701",
        "Station ID": 6131,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48,
        "Longitude (Decimal Degrees)": -66.68,
        "Latitude": 480000000,
        "Longitude": -664100000,
        "Elevation (m)": 25.6,
        "First Year": 1937,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1978,
        "MLY First Year": 1937,
        "MLY Last Year": 1978
      },
      {
        "Name": "CANOBIE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100770",
        "Station ID": 6132,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.68,
        "Longitude (Decimal Degrees)": -65.38,
        "Latitude": 474100000,
        "Longitude": -652300000,
        "Elevation (m)": 33.5,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "CANTERBURY",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100775",
        "Station ID": 6133,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.88,
        "Longitude (Decimal Degrees)": -67.47,
        "Latitude": 455300000,
        "Longitude": -672800000,
        "Elevation (m)": 173.8,
        "First Year": 1970,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1990,
        "MLY First Year": 1970,
        "MLY Last Year": 1990
      },
      {
        "Name": "CAP PELE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100779",
        "Station ID": 6134,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -64.23,
        "Latitude": 461100000,
        "Longitude": -641400000,
        "Elevation (m)": 30.5,
        "First Year": 1971,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1978,
        "MLY First Year": 1971,
        "MLY Last Year": 1978
      },
      {
        "Name": "CARAQUET",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100785",
        "Station ID": 6135,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -64.93,
        "Latitude": 474800000,
        "Longitude": -645600000,
        "Elevation (m)": 18.3,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1984
      },
      {
        "Name": "CENTREVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100850",
        "Station ID": 6136,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.38,
        "Longitude (Decimal Degrees)": -67.7,
        "Latitude": 462300000,
        "Longitude": -674200000,
        "Elevation (m)": 143,
        "First Year": 1965,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1993,
        "MLY First Year": 1966,
        "MLY Last Year": 1993
      },
      {
        "Name": "CHARLO A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100880",
        "Station ID": 6137,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.98,
        "Longitude (Decimal Degrees)": -66.33,
        "Latitude": 475900000,
        "Longitude": -662000000,
        "Elevation (m)": 40.2,
        "First Year": 1966,
        "Last Year": 2003,
        "HLY First Year": 1966,
        "HLY Last Year": 2003,
        "DLY First Year": 1966,
        "DLY Last Year": 2003,
        "MLY First Year": 1966,
        "MLY Last Year": 2002
      },
      {
        "Name": "CHARLO AUTO",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100885",
        "Station ID": 42103,
        "WMO ID": 71315,
        "TC ID": "ZCR",
        "Latitude (Decimal Degrees)": 47.99,
        "Longitude (Decimal Degrees)": -66.33,
        "Latitude": 475924000,
        "Longitude": -662003000,
        "Elevation (m)": 42,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHARLO FALLS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100900",
        "Station ID": 6138,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -66.48,
        "Latitude": 475800000,
        "Longitude": -662900000,
        "Elevation (m)": 16.8,
        "First Year": 1934,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1958,
        "MLY First Year": 1934,
        "MLY Last Year": 1958
      },
      {
        "Name": "MIRAMICHI RCS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100989",
        "Station ID": 10808,
        "WMO ID": 71744,
        "TC ID": "ACQ",
        "Latitude (Decimal Degrees)": 47.01,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 470034000,
        "Longitude": -652754000,
        "Elevation (m)": 33,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHATHAM",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8100990",
        "Station ID": 6139,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.05,
        "Longitude (Decimal Degrees)": -65.48,
        "Latitude": 470300000,
        "Longitude": -652900000,
        "Elevation (m)": 29.9,
        "First Year": 1873,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1873,
        "DLY Last Year": 1947,
        "MLY First Year": 1873,
        "MLY Last Year": 1947
      },
      {
        "Name": "MIRAMICHI A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101000",
        "Station ID": 6140,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.01,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 470034090,
        "Longitude": -652804040,
        "Elevation (m)": 32.9,
        "First Year": 1943,
        "Last Year": 2005,
        "HLY First Year": 1953,
        "HLY Last Year": 2005,
        "DLY First Year": 1943,
        "DLY Last Year": 2005,
        "MLY First Year": 1943,
        "MLY Last Year": 2005
      },
      {
        "Name": "MIRAMICHI SNOW",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101002",
        "Station ID": 50009,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.99,
        "Longitude (Decimal Degrees)": -65.5,
        "Latitude": 465935070,
        "Longitude": -653017038,
        "Elevation (m)": 33,
        "First Year": 2012,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2012,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHATHAM NBEPC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101020",
        "Station ID": 6141,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.05,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 470300000,
        "Longitude": -652800000,
        "Elevation (m)": 3.7,
        "First Year": 1960,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1980,
        "MLY First Year": 1960,
        "MLY Last Year": 1980
      },
      {
        "Name": "CHIPMAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101098",
        "Station ID": 6142,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -65.87,
        "Latitude": 461100000,
        "Longitude": -655200000,
        "Elevation (m)": 10.7,
        "First Year": 1919,
        "Last Year": 1932,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1932,
        "MLY First Year": 1919,
        "MLY Last Year": 1932
      },
      {
        "Name": "CHIPMAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101100",
        "Station ID": 6143,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -65.87,
        "Latitude": 461100000,
        "Longitude": -655200000,
        "Elevation (m)": 10.7,
        "First Year": 1931,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1967,
        "MLY First Year": 1931,
        "MLY Last Year": 1967
      },
      {
        "Name": "CHIPMAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101101",
        "Station ID": 6144,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -65.9,
        "Latitude": 460900000,
        "Longitude": -655400000,
        "Elevation (m)": 19.8,
        "First Year": 1968,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1968,
        "MLY First Year": 1968,
        "MLY Last Year": 1968
      },
      {
        "Name": "COLDBROOK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101150",
        "Station ID": 6145,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -66.03,
        "Latitude": 451900000,
        "Longitude": -660200000,
        "Elevation (m)": 4,
        "First Year": 1956,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1962,
        "MLY First Year": 1956,
        "MLY Last Year": 1961
      },
      {
        "Name": "COLESON COVE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101151",
        "Station ID": 6146,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -66.2,
        "Latitude": 450900000,
        "Longitude": -661200000,
        "Elevation (m)": 30.5,
        "First Year": 1972,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2001,
        "MLY First Year": 1972,
        "MLY Last Year": 2001
      },
      {
        "Name": "COLLINA",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101152",
        "Station ID": 27623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.79,
        "Longitude (Decimal Degrees)": -65.65,
        "Latitude": 454732030,
        "Longitude": -653912060,
        "Elevation (m)": 250,
        "First Year": 1998,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 1999,
        "MLY First Year": 1998,
        "MLY Last Year": 1999
      },
      {
        "Name": "COOKVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101153",
        "Station ID": 6147,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -64.33,
        "Latitude": 460200000,
        "Longitude": -642000000,
        "Elevation (m)": 36.6,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "DALHOUSIE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101170",
        "Station ID": 6148,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.07,
        "Longitude (Decimal Degrees)": -66.37,
        "Latitude": 480400000,
        "Longitude": -662200000,
        "Elevation (m)": 11.9,
        "First Year": 1872,
        "Last Year": 1916,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1916,
        "MLY First Year": 1872,
        "MLY Last Year": 1916
      },
      {
        "Name": "DAWSON SETTLEMENT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101178",
        "Station ID": 6149,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -64.78,
        "Latitude": 455600000,
        "Longitude": -644700000,
        "Elevation (m)": 152.4,
        "First Year": 1964,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1979,
        "MLY First Year": 1964,
        "MLY Last Year": 1979
      },
      {
        "Name": "DOAKTOWN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101200",
        "Station ID": 6150,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.55,
        "Longitude (Decimal Degrees)": -66.14,
        "Latitude": 463309050,
        "Longitude": -660825050,
        "Elevation (m)": 57,
        "First Year": 1934,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 2009,
        "MLY First Year": 1934,
        "MLY Last Year": 2006
      },
      {
        "Name": "DOAKTOWN AUTO RCS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101201",
        "Station ID": 51537,
        "WMO ID": 71038,
        "TC ID": "ADN",
        "Latitude (Decimal Degrees)": 46.59,
        "Longitude (Decimal Degrees)": -66.01,
        "Latitude": 463506090,
        "Longitude": -660035071,
        "Elevation (m)": 43,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DORCHESTER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101230",
        "Station ID": 6151,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -64.52,
        "Latitude": 455500000,
        "Longitude": -643100000,
        "Elevation (m)": 6.4,
        "First Year": 1871,
        "Last Year": 1938,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1938,
        "MLY First Year": 1871,
        "MLY Last Year": 1938
      },
      {
        "Name": "DUNGARVON RIVER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101272",
        "Station ID": 6152,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -66.65,
        "Latitude": 464900000,
        "Longitude": -663900000,
        "Elevation (m)": 317,
        "First Year": 1986,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1987,
        "MLY First Year": 1986,
        "MLY Last Year": 1987
      },
      {
        "Name": "EAST CANAAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101280",
        "Station ID": 6153,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -65.37,
        "Latitude": 460400000,
        "Longitude": -652200000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1979,
        "MLY First Year": 1973,
        "MLY Last Year": 1979
      },
      {
        "Name": "EDMUNDSTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101300",
        "Station ID": 6154,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.37,
        "Longitude (Decimal Degrees)": -68.33,
        "Latitude": 472200000,
        "Longitude": -682000000,
        "Elevation (m)": 173.7,
        "First Year": 1913,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1957,
        "MLY First Year": 1913,
        "MLY Last Year": 1956
      },
      {
        "Name": "EDMUNDSTON FRASER CO",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101301",
        "Station ID": 6155,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.37,
        "Longitude (Decimal Degrees)": -68.33,
        "Latitude": 472200000,
        "Longitude": -682000000,
        "Elevation (m)": 152.4,
        "First Year": 1949,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 1979,
        "MLY First Year": 1949,
        "MLY Last Year": 1980
      },
      {
        "Name": "EDMUNDSTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101303",
        "Station ID": 43323,
        "WMO ID": 71590,
        "TC ID": "ERM",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -68.32,
        "Latitude": 472500000,
        "Longitude": -681928040,
        "Elevation (m)": 154.2,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "EVERETT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101348",
        "Station ID": 6156,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -67.3,
        "Latitude": 470400000,
        "Longitude": -671800000,
        "Elevation (m)": 145.1,
        "First Year": 1983,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1989,
        "MLY First Year": 1983,
        "MLY Last Year": 1989
      },
      {
        "Name": "FREDERICTON A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101500",
        "Station ID": 6157,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -66.53,
        "Latitude": 455219670,
        "Longitude": -663140410,
        "Elevation (m)": 20.7,
        "First Year": 1951,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2010,
        "DLY First Year": 1951,
        "DLY Last Year": 2012,
        "MLY First Year": 1951,
        "MLY Last Year": 2008
      },
      {
        "Name": "FREDERICTON INTL A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101505",
        "Station ID": 48568,
        "WMO ID": 71700,
        "TC ID": "YFC",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -66.54,
        "Latitude": 455208000,
        "Longitude": -663214000,
        "Elevation (m)": 20.7,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FREDERICTON AQUATIC CENTRE C/S",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101507",
        "Station ID": 10981,
        "WMO ID": null,
        "TC ID": "WZF",
        "Latitude (Decimal Degrees)": 45.96,
        "Longitude (Decimal Degrees)": -66.65,
        "Latitude": 455751060,
        "Longitude": -663917020,
        "Elevation (m)": 8.3,
        "First Year": 1994,
        "Last Year": 2021,
        "HLY First Year": 2005,
        "HLY Last Year": 2021,
        "DLY First Year": 1994,
        "DLY Last Year": 2021,
        "MLY First Year": 1995,
        "MLY Last Year": 1998
      },
      {
        "Name": "FREDERICTON CDA CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101605",
        "Station ID": 30309,
        "WMO ID": 71668,
        "TC ID": "AFC",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -66.61,
        "Latitude": 455513000,
        "Longitude": -663632010,
        "Elevation (m)": 35.08,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "FREDERICTON UNB",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101700",
        "Station ID": 6159,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -66.6,
        "Latitude": 455700000,
        "Longitude": -663600000,
        "Elevation (m)": 50,
        "First Year": 1871,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1952,
        "MLY First Year": 1871,
        "MLY Last Year": 1952
      },
      {
        "Name": "FUNDY PARK (ALMA) CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101746",
        "Station ID": 6919,
        "WMO ID": 71191,
        "TC ID": "AFY",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -64.95,
        "Latitude": 453600000,
        "Longitude": -645700000,
        "Elevation (m)": 42.7,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "GAGETOWN A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101792",
        "Station ID": 7558,
        "WMO ID": 71701,
        "TC ID": "YCX",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -66.43,
        "Latitude": 455000000,
        "Longitude": -662600000,
        "Elevation (m)": 50.6,
        "First Year": 1976,
        "Last Year": 2022,
        "HLY First Year": 1976,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2017,
        "MLY First Year": 2011,
        "MLY Last Year": 2017
      },
      {
        "Name": "GAGETOWN AWOS A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101794",
        "Station ID": 29493,
        "WMO ID": 71701,
        "TC ID": "YCX",
        "Latitude (Decimal Degrees)": 45.84,
        "Longitude (Decimal Degrees)": -66.45,
        "Latitude": 455020000,
        "Longitude": -662659000,
        "Elevation (m)": 50.6,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "GAGETOWN 2",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101800",
        "Station ID": 6160,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.78,
        "Longitude (Decimal Degrees)": -66.15,
        "Latitude": 454700000,
        "Longitude": -660900000,
        "Elevation (m)": 33.5,
        "First Year": 1897,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 2011,
        "MLY First Year": 1897,
        "MLY Last Year": 2006
      },
      {
        "Name": "GANNET ROCK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101815",
        "Station ID": 10221,
        "WMO ID": null,
        "TC ID": "WAK",
        "Latitude (Decimal Degrees)": 44.52,
        "Longitude (Decimal Degrees)": -66.78,
        "Latitude": 443100000,
        "Longitude": -664700000,
        "Elevation (m)": 5.2,
        "First Year": 1993,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1996,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GOUNAMITZ RIVER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101850",
        "Station ID": 6161,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.67,
        "Longitude (Decimal Degrees)": -67.93,
        "Latitude": 474000000,
        "Longitude": -675600000,
        "Elevation (m)": 296.9,
        "First Year": 1981,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1984,
        "MLY First Year": 1981,
        "MLY Last Year": 1984
      },
      {
        "Name": "GRANDE DIGUE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101880",
        "Station ID": 6162,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -64.57,
        "Latitude": 461900000,
        "Longitude": -643400000,
        "Elevation (m)": 24.4,
        "First Year": 1984,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1990,
        "MLY First Year": 1984,
        "MLY Last Year": 1990
      },
      {
        "Name": "GRAND FALLS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101900",
        "Station ID": 6163,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.05,
        "Longitude (Decimal Degrees)": -67.73,
        "Latitude": 470300000,
        "Longitude": -674400000,
        "Elevation (m)": 152.4,
        "First Year": 1913,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1966,
        "MLY First Year": 1913,
        "MLY Last Year": 1966
      },
      {
        "Name": "GRAND FALLS DRUMMOND",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101904",
        "Station ID": 6164,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.03,
        "Longitude (Decimal Degrees)": -67.7,
        "Latitude": 470200000,
        "Longitude": -674200000,
        "Elevation (m)": 228.6,
        "First Year": 1965,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1992,
        "MLY First Year": 1966,
        "MLY Last Year": 1992
      },
      {
        "Name": "GRAND MANAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101920",
        "Station ID": 6165,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -66.77,
        "Latitude": 444400000,
        "Longitude": -664600000,
        "Elevation (m)": 7.6,
        "First Year": 1874,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1965,
        "MLY First Year": 1874,
        "MLY Last Year": 1965
      },
      {
        "Name": "GRAND MANAN SAR CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8101925",
        "Station ID": 30598,
        "WMO ID": 71030,
        "TC ID": "XGM",
        "Latitude (Decimal Degrees)": 44.71,
        "Longitude (Decimal Degrees)": -66.8,
        "Latitude": 444243400,
        "Longitude": -664806900,
        "Elevation (m)": 78.2,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "GREEN RIVER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102000",
        "Station ID": 6166,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.47,
        "Longitude (Decimal Degrees)": -68.23,
        "Latitude": 472800000,
        "Longitude": -681400000,
        "Elevation (m)": 182.9,
        "First Year": 1934,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1959,
        "MLY First Year": 1934,
        "MLY Last Year": 1959
      },
      {
        "Name": "HAMPTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102100",
        "Station ID": 6167,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -65.83,
        "Latitude": 453200000,
        "Longitude": -655000000,
        "Elevation (m)": 15.8,
        "First Year": 1950,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1965,
        "MLY First Year": 1950,
        "MLY Last Year": 1965
      },
      {
        "Name": "HAMTOWN CORNER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102110",
        "Station ID": 6168,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -66.78,
        "Latitude": 460700000,
        "Longitude": -664700000,
        "Elevation (m)": 243.8,
        "First Year": 1973,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1989,
        "MLY First Year": 1973,
        "MLY Last Year": 1989
      },
      {
        "Name": "HARCOURT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102150",
        "Station ID": 6169,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.52,
        "Longitude (Decimal Degrees)": -65.28,
        "Latitude": 463100000,
        "Longitude": -651700000,
        "Elevation (m)": 45.7,
        "First Year": 1957,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1966,
        "MLY First Year": 1957,
        "MLY Last Year": 1966
      },
      {
        "Name": "HARCOURT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102151",
        "Station ID": 6170,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -65.27,
        "Latitude": 463000000,
        "Longitude": -651600000,
        "Elevation (m)": 39.6,
        "First Year": 1981,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2004,
        "MLY First Year": 1981,
        "MLY Last Year": 2004
      },
      {
        "Name": "HARDWOOD RIDGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102160",
        "Station ID": 6171,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -65.92,
        "Latitude": 461000000,
        "Longitude": -655500000,
        "Elevation (m)": null,
        "First Year": 1920,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1942,
        "MLY First Year": 1920,
        "MLY Last Year": 1942
      },
      {
        "Name": "HARPER SETTLEMENT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102180",
        "Station ID": 6172,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -65.3,
        "Latitude": 455200000,
        "Longitude": -651800000,
        "Elevation (m)": 103.6,
        "First Year": 1989,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1989,
        "MLY First Year": 1989,
        "MLY Last Year": 1989
      },
      {
        "Name": "HARVEY STATION",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102200",
        "Station ID": 6173,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -67,
        "Latitude": 454400000,
        "Longitude": -670000000,
        "Elevation (m)": 152.4,
        "First Year": 1920,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1920,
        "DLY Last Year": 1976,
        "MLY First Year": 1920,
        "MLY Last Year": 1976
      },
      {
        "Name": "HARVEY STATION",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102201",
        "Station ID": 6174,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -67.03,
        "Latitude": 454000000,
        "Longitude": -670200000,
        "Elevation (m)": 182.9,
        "First Year": 1976,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1996,
        "MLY First Year": 1976,
        "MLY Last Year": 1996
      },
      {
        "Name": "HARVEY STATION",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102202",
        "Station ID": 27482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -67.02,
        "Latitude": 454400000,
        "Longitude": -670100000,
        "Elevation (m)": 194,
        "First Year": 1997,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2004,
        "MLY First Year": 1997,
        "MLY Last Year": 2004
      },
      {
        "Name": "HAUT SHIPPAGAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102206",
        "Station ID": 6175,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.75,
        "Longitude (Decimal Degrees)": -64.77,
        "Latitude": 474500000,
        "Longitude": -644600000,
        "Elevation (m)": 6,
        "First Year": 1986,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2010,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "HAVELOCK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102210",
        "Station ID": 6176,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -65.35,
        "Latitude": 460200000,
        "Longitude": -652100000,
        "Elevation (m)": 91.4,
        "First Year": 1979,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1993,
        "MLY First Year": 1979,
        "MLY Last Year": 1993
      },
      {
        "Name": "HAVELOCK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102212",
        "Station ID": 26970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46,
        "Longitude (Decimal Degrees)": -65.32,
        "Latitude": 460000000,
        "Longitude": -651900000,
        "Elevation (m)": 98.5,
        "First Year": 1994,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2003,
        "MLY First Year": 1994,
        "MLY Last Year": 2003
      },
      {
        "Name": "HILLSBOROUGH",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102220",
        "Station ID": 6177,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -64.68,
        "Latitude": 455700000,
        "Longitude": -644100000,
        "Elevation (m)": 40.8,
        "First Year": 1961,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1962,
        "MLY First Year": 1961,
        "MLY Last Year": 1962
      },
      {
        "Name": "HOLMESVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102226",
        "Station ID": 6178,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.58,
        "Longitude (Decimal Degrees)": -67.6,
        "Latitude": 463500000,
        "Longitude": -673600000,
        "Elevation (m)": 189,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "HOYT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102230",
        "Station ID": 6179,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.55,
        "Longitude (Decimal Degrees)": -66.53,
        "Latitude": 453300000,
        "Longitude": -663200000,
        "Elevation (m)": 121.9,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "HOYT BLISSVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102234",
        "Station ID": 6180,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -66.57,
        "Latitude": 453600000,
        "Longitude": -663400000,
        "Elevation (m)": 15.2,
        "First Year": 1981,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2001,
        "MLY First Year": 1981,
        "MLY Last Year": 2001
      },
      {
        "Name": "JUNIPER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102275",
        "Station ID": 6181,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.55,
        "Longitude (Decimal Degrees)": -67.17,
        "Latitude": 463300000,
        "Longitude": -671000000,
        "Elevation (m)": 259.1,
        "First Year": 1969,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2017,
        "MLY First Year": 1969,
        "MLY Last Year": 2004
      },
      {
        "Name": "KEDGWICK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102300",
        "Station ID": 6182,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.65,
        "Longitude (Decimal Degrees)": -67.35,
        "Latitude": 473900000,
        "Longitude": -672100000,
        "Elevation (m)": 274.3,
        "First Year": 1931,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1931,
        "DLY Last Year": 1994,
        "MLY First Year": 1931,
        "MLY Last Year": 1994
      },
      {
        "Name": "KESWICK RIDGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102308",
        "Station ID": 6183,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46,
        "Longitude (Decimal Degrees)": -66.88,
        "Latitude": 460000000,
        "Longitude": -665300000,
        "Elevation (m)": 121.9,
        "First Year": 1964,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1968,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "KESWICK RIDGE MACTAQUAC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102312",
        "Station ID": 6184,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -66.87,
        "Latitude": 455800000,
        "Longitude": -665200000,
        "Elevation (m)": 7.6,
        "First Year": 1965,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1978,
        "MLY First Year": 1965,
        "MLY Last Year": 1978
      },
      {
        "Name": "KINGS LANDING",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102314",
        "Station ID": 6185,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -66.95,
        "Latitude": 455100000,
        "Longitude": -665700000,
        "Elevation (m)": 67.4,
        "First Year": 1975,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1978,
        "MLY First Year": 1975,
        "MLY Last Year": 1978
      },
      {
        "Name": "KINGSLEY IHD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102316",
        "Station ID": 6186,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -66.73,
        "Latitude": 460400000,
        "Longitude": -664400000,
        "Elevation (m)": 164.6,
        "First Year": 1973,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1981,
        "MLY First Year": 1973,
        "MLY Last Year": 1981
      },
      {
        "Name": "KOUCHIBOUGUAC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102325",
        "Station ID": 6187,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.77,
        "Longitude (Decimal Degrees)": -65,
        "Latitude": 464600000,
        "Longitude": -650000000,
        "Elevation (m)": 35.1,
        "First Year": 1972,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1995,
        "MLY First Year": 1972,
        "MLY Last Year": 1995
      },
      {
        "Name": "KOUCHIBOUGUAC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102326",
        "Station ID": 54538,
        "WMO ID": 71670,
        "TC ID": "AKC",
        "Latitude (Decimal Degrees)": 46.79,
        "Longitude (Decimal Degrees)": -65.01,
        "Latitude": 464721004,
        "Longitude": -650047000,
        "Elevation (m)": 21,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2016,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "KOUCHIBOUGUAC CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102328",
        "Station ID": 26968,
        "WMO ID": 71670,
        "TC ID": "AKC",
        "Latitude (Decimal Degrees)": 46.78,
        "Longitude (Decimal Degrees)": -65.02,
        "Latitude": 464700000,
        "Longitude": -650100000,
        "Elevation (m)": 21,
        "First Year": 1995,
        "Last Year": 2018,
        "HLY First Year": 2005,
        "HLY Last Year": 2018,
        "DLY First Year": 1995,
        "DLY Last Year": 2018,
        "MLY First Year": 1995,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAMEQUE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102335",
        "Station ID": 6188,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.83,
        "Longitude (Decimal Degrees)": -64.6,
        "Latitude": 475000000,
        "Longitude": -643600000,
        "Elevation (m)": 15.2,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "LAMEQUE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102336",
        "Station ID": 6189,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -64.65,
        "Latitude": 474800000,
        "Longitude": -643900000,
        "Elevation (m)": 6.1,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "LITTLE RIVER MINE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102350",
        "Station ID": 6190,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.28,
        "Longitude (Decimal Degrees)": -66.07,
        "Latitude": 471700000,
        "Longitude": -660400000,
        "Elevation (m)": 341.4,
        "First Year": 1956,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1992,
        "MLY First Year": 1956,
        "MLY Last Year": 1992
      },
      {
        "Name": "LITTLE SHEMOGUE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102369",
        "Station ID": 6191,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 460700000,
        "Longitude": -640200000,
        "Elevation (m)": 18.3,
        "First Year": 1986,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1993,
        "MLY First Year": 1986,
        "MLY Last Year": 1993
      },
      {
        "Name": "LONG LAKE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102500",
        "Station ID": 6192,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -66.93,
        "Latitude": 470400000,
        "Longitude": -665600000,
        "Elevation (m)": 381,
        "First Year": 1954,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1957,
        "MLY First Year": 1954,
        "MLY Last Year": 1957
      },
      {
        "Name": "LOWER HOLMESVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102522",
        "Station ID": 6193,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.57,
        "Longitude (Decimal Degrees)": -67.58,
        "Latitude": 463400000,
        "Longitude": -673500000,
        "Elevation (m)": 144.8,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "MACTAQUAC PROV PARK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102536",
        "Station ID": 6194,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.95,
        "Longitude (Decimal Degrees)": -66.9,
        "Latitude": 455716000,
        "Longitude": -665355000,
        "Elevation (m)": 110,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2006
      },
      {
        "Name": "MAGAGUADAVIC LAKE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102550",
        "Station ID": 6195,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -67.2,
        "Latitude": 454400000,
        "Longitude": -671200000,
        "Elevation (m)": 106.7,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1967
      },
      {
        "Name": "MAISONNETTE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102555",
        "Station ID": 6196,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -65.08,
        "Latitude": 474900000,
        "Longitude": -650500000,
        "Elevation (m)": 7.9,
        "First Year": 1978,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1978,
        "MLY First Year": 1978,
        "MLY Last Year": 1978
      },
      {
        "Name": "MAPLETON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102566",
        "Station ID": 6197,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -67.23,
        "Latitude": 461100000,
        "Longitude": -671400000,
        "Elevation (m)": 167.6,
        "First Year": 1972,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2011,
        "MLY First Year": 1972,
        "MLY Last Year": 2005
      },
      {
        "Name": "MCADAM",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102600",
        "Station ID": 6198,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -67.33,
        "Latitude": 453500000,
        "Longitude": -672000000,
        "Elevation (m)": 139.9,
        "First Year": 1872,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1976,
        "MLY First Year": 1872,
        "MLY Last Year": 1976
      },
      {
        "Name": "MCDONALDS CORNER CDA",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102700",
        "Station ID": 6199,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.78,
        "Longitude (Decimal Degrees)": -66,
        "Latitude": 454700000,
        "Longitude": -660000000,
        "Elevation (m)": 38.1,
        "First Year": 1948,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1969,
        "MLY First Year": 1948,
        "MLY Last Year": 1969
      },
      {
        "Name": "MCGIVNEY",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102800",
        "Station ID": 6200,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -66.57,
        "Latitude": 462200000,
        "Longitude": -663400000,
        "Elevation (m)": 176.8,
        "First Year": 1953,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1976,
        "MLY First Year": 1953,
        "MLY Last Year": 1976
      },
      {
        "Name": "MCGRAW BROOK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102808",
        "Station ID": 6201,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -66.12,
        "Latitude": 464900000,
        "Longitude": -660700000,
        "Elevation (m)": 53.3,
        "First Year": 1969,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1995,
        "MLY First Year": 1969,
        "MLY Last Year": 1995
      },
      {
        "Name": "MECHANIC SETTLEMENT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102848",
        "Station ID": 45090,
        "WMO ID": 71787,
        "TC ID": "AMS",
        "Latitude (Decimal Degrees)": 45.69,
        "Longitude (Decimal Degrees)": -65.17,
        "Latitude": 454137040,
        "Longitude": -650954040,
        "Elevation (m)": 403,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "MIDDLE BROOK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102950",
        "Station ID": 6202,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.67,
        "Longitude (Decimal Degrees)": -66.52,
        "Latitude": 474000000,
        "Longitude": -663100000,
        "Elevation (m)": null,
        "First Year": 1936,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 1946,
        "MLY First Year": 1936,
        "MLY Last Year": 1946
      },
      {
        "Name": "MILLTOWN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8102975",
        "Station ID": 6203,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.17,
        "Longitude (Decimal Degrees)": -67.3,
        "Latitude": 451000000,
        "Longitude": -671800000,
        "Elevation (m)": 15.8,
        "First Year": 1964,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1983,
        "MLY First Year": 1964,
        "MLY Last Year": 1983
      },
      {
        "Name": "MINTO",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103000",
        "Station ID": 6204,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -66.03,
        "Latitude": 460200000,
        "Longitude": -660200000,
        "Elevation (m)": 22.9,
        "First Year": 1933,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1993,
        "MLY First Year": 1933,
        "MLY Last Year": 1993
      },
      {
        "Name": "MISCOU ISLAND (AUT)",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103050",
        "Station ID": 6205,
        "WMO ID": 71719,
        "TC ID": "WMI",
        "Latitude (Decimal Degrees)": 48.01,
        "Longitude (Decimal Degrees)": -64.49,
        "Latitude": 480032002,
        "Longitude": -642939008,
        "Elevation (m)": 4,
        "First Year": 1957,
        "Last Year": 2022,
        "HLY First Year": 1964,
        "HLY Last Year": 2022,
        "DLY First Year": 1957,
        "DLY Last Year": 2022,
        "MLY First Year": 1957,
        "MLY Last Year": 2007
      },
      {
        "Name": "MONCTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103100",
        "Station ID": 6206,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.1,
        "Longitude (Decimal Degrees)": -64.79,
        "Latitude": 460605000,
        "Longitude": -644725040,
        "Elevation (m)": 12.2,
        "First Year": 1881,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1881,
        "DLY Last Year": 2013,
        "MLY First Year": 1881,
        "MLY Last Year": 2006
      },
      {
        "Name": "MONCTON A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103200",
        "Station ID": 6207,
        "WMO ID": 71705,
        "TC ID": "YQM",
        "Latitude (Decimal Degrees)": 46.11,
        "Longitude (Decimal Degrees)": -64.68,
        "Latitude": 460619100,
        "Longitude": -644101700,
        "Elevation (m)": 70.7,
        "First Year": 1939,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1939,
        "DLY Last Year": 2012,
        "MLY First Year": 1939,
        "MLY Last Year": 2012
      },
      {
        "Name": "MONCTON/GREATER MONCTON ROMEO LEBLANC INTL A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103201",
        "Station ID": 50309,
        "WMO ID": 71705,
        "TC ID": "YQM",
        "Latitude (Decimal Degrees)": 46.11,
        "Longitude (Decimal Degrees)": -64.68,
        "Latitude": 460644000,
        "Longitude": -644043000,
        "Elevation (m)": 70.7,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MONCTON / GREATER MONCTON ROMEO LEBLANC INTL A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103202",
        "Station ID": 54282,
        "WMO ID": null,
        "TC ID": "YQM",
        "Latitude (Decimal Degrees)": 46.11,
        "Longitude (Decimal Degrees)": -64.68,
        "Latitude": 460644000,
        "Longitude": -644043000,
        "Elevation (m)": 70.7,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOUNT CARLETON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103256",
        "Station ID": 6208,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -66.93,
        "Latitude": 472500000,
        "Longitude": -665600000,
        "Elevation (m)": 265.1,
        "First Year": 1973,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 2001,
        "MLY First Year": 1973,
        "MLY Last Year": 2001
      },
      {
        "Name": "MOUNT PLEASANT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103300",
        "Station ID": 6209,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -66.82,
        "Latitude": 452600000,
        "Longitude": -664900000,
        "Elevation (m)": 358.1,
        "First Year": 1935,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1952,
        "MLY First Year": 1935,
        "MLY Last Year": 1952
      },
      {
        "Name": "MUSQUASH",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103400",
        "Station ID": 6210,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -66.33,
        "Latitude": 451200000,
        "Longitude": -662000000,
        "Elevation (m)": 15.2,
        "First Year": 1922,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 1981,
        "MLY First Year": 1922,
        "MLY Last Year": 1981
      },
      {
        "Name": "NACKAWIC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103425",
        "Station ID": 6211,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.97,
        "Longitude (Decimal Degrees)": -67.23,
        "Latitude": 455800000,
        "Longitude": -671400000,
        "Elevation (m)": 45.7,
        "First Year": 1966,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1972,
        "MLY First Year": 1966,
        "MLY Last Year": 1972
      },
      {
        "Name": "NAUWIGEWAUK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103441",
        "Station ID": 6212,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -65.9,
        "Latitude": 452800000,
        "Longitude": -655400000,
        "Elevation (m)": 65.5,
        "First Year": 1990,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2001,
        "MLY First Year": 1990,
        "MLY Last Year": 2001
      },
      {
        "Name": "NEPISIGUIT FALLS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103500",
        "Station ID": 6213,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.4,
        "Longitude (Decimal Degrees)": -65.78,
        "Latitude": 472400000,
        "Longitude": -654700000,
        "Elevation (m)": 106.1,
        "First Year": 1922,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2006,
        "MLY First Year": 1922,
        "MLY Last Year": 2006
      },
      {
        "Name": "NEWCASTLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103600",
        "Station ID": 6214,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47,
        "Longitude (Decimal Degrees)": -65.57,
        "Latitude": 470000000,
        "Longitude": -653400000,
        "Elevation (m)": 15.2,
        "First Year": 1948,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1959,
        "MLY First Year": 1948,
        "MLY Last Year": 1959
      },
      {
        "Name": "NICTAU",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103700",
        "Station ID": 6215,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -67.15,
        "Latitude": 471400000,
        "Longitude": -670900000,
        "Elevation (m)": 169.7,
        "First Year": 1978,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2001,
        "MLY First Year": 1978,
        "MLY Last Year": 2001
      },
      {
        "Name": "NINE MILE BRK (CAMP 68)",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103706",
        "Station ID": 6216,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.68,
        "Longitude (Decimal Degrees)": -68.12,
        "Latitude": 474100000,
        "Longitude": -680700000,
        "Elevation (m)": 426.7,
        "First Year": 1972,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1981,
        "MLY First Year": 1972,
        "MLY Last Year": 1981
      },
      {
        "Name": "OAK POINT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103780",
        "Station ID": 49868,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.51,
        "Longitude (Decimal Degrees)": -66.1,
        "Latitude": 453025000,
        "Longitude": -660555000,
        "Elevation (m)": 11,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OROMOCTO",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103800",
        "Station ID": 6217,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.83,
        "Longitude (Decimal Degrees)": -66.47,
        "Latitude": 455000000,
        "Longitude": -662800000,
        "Elevation (m)": 45.7,
        "First Year": 1957,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1994,
        "MLY First Year": 1957,
        "MLY Last Year": 1994
      },
      {
        "Name": "PARKER RIDGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103825",
        "Station ID": 6218,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.48,
        "Longitude (Decimal Degrees)": -66.52,
        "Latitude": 462900000,
        "Longitude": -663100000,
        "Elevation (m)": null,
        "First Year": 1886,
        "Last Year": 1896,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 1896,
        "MLY First Year": 1886,
        "MLY Last Year": 1896
      },
      {
        "Name": "PARKINDALE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103828",
        "Station ID": 6219,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -65.07,
        "Latitude": 455200000,
        "Longitude": -650400000,
        "Elevation (m)": 152.7,
        "First Year": 1983,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2019,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "PENNFIELD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103845",
        "Station ID": 6220,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -66.73,
        "Latitude": 450600000,
        "Longitude": -664400000,
        "Elevation (m)": 22.9,
        "First Year": 1961,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2003,
        "MLY First Year": 1961,
        "MLY Last Year": 2003
      },
      {
        "Name": "PENNFIELD RIDGE A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103850",
        "Station ID": 6221,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -66.68,
        "Latitude": 450800000,
        "Longitude": -664100000,
        "Elevation (m)": 73.2,
        "First Year": 1941,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1951,
        "MLY First Year": 1941,
        "MLY Last Year": 1951
      },
      {
        "Name": "PENOBSQUIS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103860",
        "Station ID": 6222,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.78,
        "Longitude (Decimal Degrees)": -65.38,
        "Latitude": 454700000,
        "Longitude": -652300000,
        "Elevation (m)": 44.2,
        "First Year": 1961,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1974,
        "MLY First Year": 1961,
        "MLY Last Year": 1974
      },
      {
        "Name": "PETITCODIAC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103900",
        "Station ID": 6223,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.93,
        "Longitude (Decimal Degrees)": -65.17,
        "Latitude": 455600000,
        "Longitude": -651000000,
        "Elevation (m)": 29.3,
        "First Year": 1934,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1954,
        "MLY First Year": 1934,
        "MLY Last Year": 1954
      },
      {
        "Name": "PETIT ROCHER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8103910",
        "Station ID": 6224,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -65.73,
        "Latitude": 474800000,
        "Longitude": -654400000,
        "Elevation (m)": 12.2,
        "First Year": 1980,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1982,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "PLASTER ROCK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104000",
        "Station ID": 6225,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -67.4,
        "Latitude": 465400000,
        "Longitude": -672400000,
        "Elevation (m)": 160,
        "First Year": 1947,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1993,
        "MLY First Year": 1947,
        "MLY Last Year": 1993
      },
      {
        "Name": "PLASTER ROCK NBEPC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104001",
        "Station ID": 6226,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.9,
        "Longitude (Decimal Degrees)": -67.38,
        "Latitude": 465400000,
        "Longitude": -672300000,
        "Elevation (m)": 140.2,
        "First Year": 1955,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1967,
        "MLY First Year": 1955,
        "MLY Last Year": 1967
      },
      {
        "Name": "PLEASANT MOUNTAIN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104010",
        "Station ID": 6227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -66.83,
        "Latitude": 452700000,
        "Longitude": -665000000,
        "Elevation (m)": 190.5,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "POINT ESCUMINAC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104100",
        "Station ID": 6228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.12,
        "Longitude (Decimal Degrees)": -64.82,
        "Latitude": 470700000,
        "Longitude": -644900000,
        "Elevation (m)": 7.9,
        "First Year": 1885,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1885,
        "DLY Last Year": 1951,
        "MLY First Year": 1885,
        "MLY Last Year": 1951
      },
      {
        "Name": "POINT ESCUMINAC (AUT)",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104103",
        "Station ID": 9821,
        "WMO ID": null,
        "TC ID": "WPJ",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -64.8,
        "Latitude": 470400000,
        "Longitude": -644800000,
        "Elevation (m)": 4,
        "First Year": 1986,
        "Last Year": 2002,
        "HLY First Year": 1986,
        "HLY Last Year": 2002,
        "DLY First Year": 1992,
        "DLY Last Year": 2002,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "POINT LEPREAU",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104200",
        "Station ID": 6229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": 66.47,
        "Latitude": 450400000,
        "Longitude": 662800000,
        "Elevation (m)": 5.3,
        "First Year": 1872,
        "Last Year": 1952,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1952,
        "MLY First Year": 1872,
        "MLY Last Year": 1952
      },
      {
        "Name": "POINT LEPREAU CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104201",
        "Station ID": 27746,
        "WMO ID": 71699,
        "TC ID": "WPE",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -66.45,
        "Latitude": 450423040,
        "Longitude": -662657090,
        "Elevation (m)": 6,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 1992,
        "MLY Last Year": 2007
      },
      {
        "Name": "RAPIDS DEPOT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104284",
        "Station ID": 6230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -67.75,
        "Latitude": 474900000,
        "Longitude": -674500000,
        "Elevation (m)": 167.6,
        "First Year": 1972,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1988,
        "MLY First Year": 1972,
        "MLY Last Year": 1988
      },
      {
        "Name": "RED PINES",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104295",
        "Station ID": 48768,
        "WMO ID": 71634,
        "TC ID": "ARP",
        "Latitude (Decimal Degrees)": 47.44,
        "Longitude (Decimal Degrees)": -65.6,
        "Latitude": 472622000,
        "Longitude": -653552000,
        "Elevation (m)": 124,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RENOUS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104300",
        "Station ID": 6231,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.8,
        "Longitude (Decimal Degrees)": -65.8,
        "Latitude": 464800000,
        "Longitude": -654800000,
        "Elevation (m)": 45.7,
        "First Year": 1953,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1978,
        "MLY First Year": 1953,
        "MLY Last Year": 1978
      },
      {
        "Name": "REXTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104400",
        "Station ID": 6232,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.67,
        "Longitude (Decimal Degrees)": -64.87,
        "Latitude": 464000000,
        "Longitude": -645200000,
        "Elevation (m)": 4.6,
        "First Year": 1922,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2013,
        "MLY First Year": 1922,
        "MLY Last Year": 2006
      },
      {
        "Name": "RILEY BROOK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104450",
        "Station ID": 6233,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.18,
        "Longitude (Decimal Degrees)": -67.2,
        "Latitude": 471100000,
        "Longitude": -671200000,
        "Elevation (m)": 170.7,
        "First Year": 1955,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1970,
        "MLY First Year": 1955,
        "MLY Last Year": 1970
      },
      {
        "Name": "ROBICHAUD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104458",
        "Station ID": 6234,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 461300000,
        "Longitude": -642300000,
        "Elevation (m)": 7.6,
        "First Year": 1982,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1993,
        "MLY First Year": 1982,
        "MLY Last Year": 1993
      },
      {
        "Name": "ROGERSVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104465",
        "Station ID": 6235,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.77,
        "Longitude (Decimal Degrees)": -65.45,
        "Latitude": 464600000,
        "Longitude": -652700000,
        "Elevation (m)": 64,
        "First Year": 1974,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1976,
        "MLY First Year": 1974,
        "MLY Last Year": 1976
      },
      {
        "Name": "ROSEVALE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104475",
        "Station ID": 6236,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -64.82,
        "Latitude": 455200000,
        "Longitude": -644900000,
        "Elevation (m)": 274.3,
        "First Year": 1964,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1974,
        "MLY First Year": 1964,
        "MLY Last Year": 1974
      },
      {
        "Name": "ROTHESAY",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104477",
        "Station ID": 6237,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -65.98,
        "Latitude": 452300000,
        "Longitude": -655900000,
        "Elevation (m)": 45.7,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "ROYAL ROAD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104480",
        "Station ID": 6238,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.05,
        "Longitude (Decimal Degrees)": -66.72,
        "Latitude": 460300000,
        "Longitude": -664300000,
        "Elevation (m)": 115.8,
        "First Year": 1965,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1993,
        "MLY First Year": 1965,
        "MLY Last Year": 1993
      },
      {
        "Name": "ROYAL ROAD WEST",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104482",
        "Station ID": 6239,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.08,
        "Longitude (Decimal Degrees)": -66.73,
        "Latitude": 460500000,
        "Longitude": -664400000,
        "Elevation (m)": 160,
        "First Year": 1966,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1981,
        "MLY First Year": 1966,
        "MLY Last Year": 1981
      },
      {
        "Name": "SACKVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104500",
        "Station ID": 6240,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.9,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 455400000,
        "Longitude": -642300000,
        "Elevation (m)": 24.4,
        "First Year": 1878,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1980,
        "MLY First Year": 1878,
        "MLY Last Year": 1980
      },
      {
        "Name": "SACKVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104501",
        "Station ID": 6241,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 455100000,
        "Longitude": -642300000,
        "Elevation (m)": 45.7,
        "First Year": 1980,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2002,
        "MLY First Year": 1980,
        "MLY Last Year": 2002
      },
      {
        "Name": "ST ANDRE DE MADAWASKA",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104595",
        "Station ID": 6242,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.12,
        "Longitude (Decimal Degrees)": -67.75,
        "Latitude": 470700000,
        "Longitude": -674500000,
        "Elevation (m)": 219.5,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "ST ANDRE EAST",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104596",
        "Station ID": 6243,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.1,
        "Longitude (Decimal Degrees)": -67.72,
        "Latitude": 470600000,
        "Longitude": -674300000,
        "Elevation (m)": 198.1,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "ST ANDREWS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104600",
        "Station ID": 6244,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.08,
        "Longitude (Decimal Degrees)": -67.08,
        "Latitude": 450500000,
        "Longitude": -670500000,
        "Elevation (m)": 15.2,
        "First Year": 1874,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1991,
        "MLY First Year": 1874,
        "MLY Last Year": 1991
      },
      {
        "Name": "ST GEORGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104700",
        "Station ID": 6245,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -66.83,
        "Latitude": 450800000,
        "Longitude": -665000000,
        "Elevation (m)": 33.5,
        "First Year": 1919,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1981,
        "MLY First Year": 1919,
        "MLY Last Year": 1981
      },
      {
        "Name": "ST GEORGE P AND P CO",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104705",
        "Station ID": 6246,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -66.82,
        "Latitude": 450800000,
        "Longitude": -664900000,
        "Elevation (m)": 22.9,
        "First Year": 1958,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1968,
        "MLY First Year": 1958,
        "MLY Last Year": 1968
      },
      {
        "Name": "ST ISIDORE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104750",
        "Station ID": 6247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -65.05,
        "Latitude": 473400000,
        "Longitude": -650300000,
        "Elevation (m)": 61,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "SAINT JOHN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104800",
        "Station ID": 6248,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -66.08,
        "Latitude": 451700000,
        "Longitude": -660500000,
        "Elevation (m)": 30.5,
        "First Year": 1871,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1970,
        "MLY First Year": 1871,
        "MLY Last Year": 1970
      },
      {
        "Name": "SAINT JOHN A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104899",
        "Station ID": 6249,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -66.1,
        "Latitude": 451800000,
        "Longitude": -660600000,
        "Elevation (m)": 29.9,
        "First Year": 1939,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1951,
        "MLY First Year": 1939,
        "MLY Last Year": 1951
      },
      {
        "Name": "SAINT JOHN A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104900",
        "Station ID": 6250,
        "WMO ID": 71609,
        "TC ID": "YSJ",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -65.89,
        "Latitude": 451905000,
        "Longitude": -655308050,
        "Elevation (m)": 108.8,
        "First Year": 1946,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1946,
        "DLY Last Year": 2012,
        "MLY First Year": 1947,
        "MLY Last Year": 2008
      },
      {
        "Name": "SAINT JOHN A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104901",
        "Station ID": 50310,
        "WMO ID": 71609,
        "TC ID": "YSJ",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -65.89,
        "Latitude": 451858000,
        "Longitude": -655324000,
        "Elevation (m)": 108.8,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GARNETT SETTLEMENT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104905",
        "Station ID": 55378,
        "WMO ID": 73038,
        "TC ID": "AJH",
        "Latitude (Decimal Degrees)": 45.29,
        "Longitude (Decimal Degrees)": -65.81,
        "Latitude": 451723622,
        "Longitude": -654849147,
        "Elevation (m)": 94.29,
        "First Year": 2022,
        "Last Year": 2022,
        "HLY First Year": 2022,
        "HLY Last Year": 2022,
        "DLY First Year": 2022,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SAINT JOHN BLINDMAN LAKE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104918",
        "Station ID": 6251,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -65.98,
        "Latitude": 451900000,
        "Longitude": -655900000,
        "Elevation (m)": 61,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "SAINT JOHN BRIDGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104920",
        "Station ID": 6252,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -66.07,
        "Latitude": 451600000,
        "Longitude": -660400000,
        "Elevation (m)": 7.6,
        "First Year": 1968,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1976,
        "MLY First Year": 1969,
        "MLY Last Year": 1976
      },
      {
        "Name": "SAINT JOHN DOMINION PARK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104921",
        "Station ID": 6253,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.27,
        "Longitude (Decimal Degrees)": -66.13,
        "Latitude": 451600000,
        "Longitude": -660800000,
        "Elevation (m)": 6.1,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "SAINT JOHN LORNEVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104923",
        "Station ID": 6254,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.18,
        "Longitude (Decimal Degrees)": -66.17,
        "Latitude": 451100000,
        "Longitude": -661000000,
        "Elevation (m)": 22.9,
        "First Year": 1971,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1971,
        "MLY First Year": 1971,
        "MLY Last Year": 1971
      },
      {
        "Name": "SAINT JOHN MISPEC PARK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104924",
        "Station ID": 6255,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -65.95,
        "Latitude": 451300000,
        "Longitude": -655700000,
        "Elevation (m)": 9.1,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "ST LEONARD A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104928",
        "Station ID": 6256,
        "WMO ID": 71703,
        "TC ID": "YSL",
        "Latitude (Decimal Degrees)": 47.16,
        "Longitude (Decimal Degrees)": -67.83,
        "Latitude": 470928050,
        "Longitude": -674955014,
        "Elevation (m)": 242.2,
        "First Year": 1985,
        "Last Year": 2014,
        "HLY First Year": 1985,
        "HLY Last Year": 2014,
        "DLY First Year": 1985,
        "DLY Last Year": 2014,
        "MLY First Year": 1985,
        "MLY Last Year": 2014
      },
      {
        "Name": "ST LEONARD A",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104932",
        "Station ID": 53579,
        "WMO ID": null,
        "TC ID": "YSL",
        "Latitude (Decimal Degrees)": 47.16,
        "Longitude (Decimal Degrees)": -67.84,
        "Latitude": 470926000,
        "Longitude": -675011000,
        "Elevation (m)": 242,
        "First Year": 2015,
        "Last Year": 2017,
        "HLY First Year": 2015,
        "HLY Last Year": 2017,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST QUENTIN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104933",
        "Station ID": 6257,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.47,
        "Longitude (Decimal Degrees)": -67.47,
        "Latitude": 472800000,
        "Longitude": -672800000,
        "Elevation (m)": null,
        "First Year": 1919,
        "Last Year": 1942,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1942,
        "MLY First Year": 1919,
        "MLY Last Year": 1942
      },
      {
        "Name": "ST. STEPHEN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104935",
        "Station ID": 43383,
        "WMO ID": 71607,
        "TC ID": "WSS",
        "Latitude (Decimal Degrees)": 45.21,
        "Longitude (Decimal Degrees)": -67.25,
        "Latitude": 451236600,
        "Longitude": -671510300,
        "Elevation (m)": 26.1,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST STEPHEN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104936",
        "Station ID": 6258,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.18,
        "Longitude (Decimal Degrees)": -67.27,
        "Latitude": 451100000,
        "Longitude": -671600000,
        "Elevation (m)": 10.7,
        "First Year": 1898,
        "Last Year": 1915,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 1915,
        "MLY First Year": 1898,
        "MLY Last Year": 1915
      },
      {
        "Name": "ST STEPHEN (AUT)",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104937",
        "Station ID": 7026,
        "WMO ID": 71607,
        "TC ID": "WSS",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -67.25,
        "Latitude": 451300000,
        "Longitude": -671500000,
        "Elevation (m)": 26.1,
        "First Year": 1977,
        "Last Year": 2006,
        "HLY First Year": 1977,
        "HLY Last Year": 2006,
        "DLY First Year": 1992,
        "DLY Last Year": 2006,
        "MLY First Year": 2005,
        "MLY Last Year": 2006
      },
      {
        "Name": "SEARSVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104938",
        "Station ID": 6259,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -65.7,
        "Latitude": 454300000,
        "Longitude": -654200000,
        "Elevation (m)": 61,
        "First Year": 1965,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1986,
        "MLY First Year": 1966,
        "MLY Last Year": 1986
      },
      {
        "Name": "SHEDIAC",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104944",
        "Station ID": 6260,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -64.53,
        "Latitude": 461400000,
        "Longitude": -643200000,
        "Elevation (m)": null,
        "First Year": 1933,
        "Last Year": 1940,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1940,
        "MLY First Year": 1933,
        "MLY Last Year": 1940
      },
      {
        "Name": "SHELDON POINT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104950",
        "Station ID": 6261,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -66.13,
        "Latitude": 451200000,
        "Longitude": -660800000,
        "Elevation (m)": 24.4,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "SHIPPEGAN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8104975",
        "Station ID": 6262,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.75,
        "Longitude (Decimal Degrees)": -64.72,
        "Latitude": 474500000,
        "Longitude": -644300000,
        "Elevation (m)": 4,
        "First Year": 1969,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1981,
        "MLY First Year": 1969,
        "MLY Last Year": 1981
      },
      {
        "Name": "SISSON DAM",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105000",
        "Station ID": 6263,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.27,
        "Longitude (Decimal Degrees)": -67.25,
        "Latitude": 471600000,
        "Longitude": -671500000,
        "Elevation (m)": 278.9,
        "First Year": 1953,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1957,
        "MLY First Year": 1953,
        "MLY Last Year": 1957
      },
      {
        "Name": "SOUTH BRANCH KEDGWICK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105042",
        "Station ID": 6264,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.87,
        "Longitude (Decimal Degrees)": -67.93,
        "Latitude": 475200000,
        "Longitude": -675600000,
        "Elevation (m)": 274.3,
        "First Year": 1983,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1991,
        "MLY First Year": 1983,
        "MLY Last Year": 1991
      },
      {
        "Name": "SOUTH TETAGOUCHE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105058",
        "Station ID": 6265,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -65.9,
        "Latitude": 473500000,
        "Longitude": -655400000,
        "Elevation (m)": 182.9,
        "First Year": 1983,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2000,
        "MLY First Year": 1983,
        "MLY Last Year": 2000
      },
      {
        "Name": "SOUTHWEST HEAD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105065",
        "Station ID": 6266,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -66.9,
        "Latitude": 443600000,
        "Longitude": -665400000,
        "Elevation (m)": 48.8,
        "First Year": 1973,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1987,
        "MLY First Year": 1973,
        "MLY Last Year": 1987
      },
      {
        "Name": "SUMMIT DEPOT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105100",
        "Station ID": 6267,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.78,
        "Longitude (Decimal Degrees)": -68.33,
        "Latitude": 474700000,
        "Longitude": -682000000,
        "Elevation (m)": 411.5,
        "First Year": 1947,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1973,
        "MLY First Year": 1947,
        "MLY Last Year": 1973
      },
      {
        "Name": "SUSSEX",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105200",
        "Station ID": 6268,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -65.53,
        "Latitude": 454300000,
        "Longitude": -653200000,
        "Elevation (m)": 21.3,
        "First Year": 1897,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 2009,
        "MLY First Year": 1897,
        "MLY Last Year": 2006
      },
      {
        "Name": "SUSSEX 2",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105202",
        "Station ID": 6269,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -65.52,
        "Latitude": 454300000,
        "Longitude": -653100000,
        "Elevation (m)": null,
        "First Year": 1939,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1947,
        "MLY First Year": 1939,
        "MLY Last Year": 1947
      },
      {
        "Name": "SUSSEX FOUR CORNERS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105210",
        "Station ID": 54078,
        "WMO ID": 71656,
        "TC ID": "ASF",
        "Latitude (Decimal Degrees)": 45.74,
        "Longitude (Decimal Degrees)": -65.53,
        "Latitude": 454430048,
        "Longitude": -653144039,
        "Elevation (m)": 47,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TIDE HEAD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105300",
        "Station ID": 6270,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.98,
        "Longitude (Decimal Degrees)": -66.82,
        "Latitude": 475900000,
        "Longitude": -664900000,
        "Elevation (m)": 38.1,
        "First Year": 1952,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1966,
        "MLY First Year": 1952,
        "MLY Last Year": 1966
      },
      {
        "Name": "TIDE HEAD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105302",
        "Station ID": 6271,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -66.77,
        "Latitude": 475800000,
        "Longitude": -664600000,
        "Elevation (m)": 18.3,
        "First Year": 1972,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1986,
        "MLY First Year": 1972,
        "MLY Last Year": 1986
      },
      {
        "Name": "TOBIQUE NARROWS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105400",
        "Station ID": 6272,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.78,
        "Longitude (Decimal Degrees)": -67.68,
        "Latitude": 464700000,
        "Longitude": -674100000,
        "Elevation (m)": 91.4,
        "First Year": 1953,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1957,
        "MLY First Year": 1954,
        "MLY Last Year": 1957
      },
      {
        "Name": "TOWER HILL CDA",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105500",
        "Station ID": 6273,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -67.22,
        "Latitude": 451900000,
        "Longitude": -671300000,
        "Elevation (m)": 167.6,
        "First Year": 1946,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1964,
        "MLY First Year": 1946,
        "MLY Last Year": 1964
      },
      {
        "Name": "TRACADIE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105505",
        "Station ID": 6274,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.52,
        "Longitude (Decimal Degrees)": -64.92,
        "Latitude": 473100000,
        "Longitude": -645500000,
        "Elevation (m)": 15.2,
        "First Year": 1970,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1988,
        "MLY First Year": 1970,
        "MLY Last Year": 1988
      },
      {
        "Name": "TURTLE CREEK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105518",
        "Station ID": 6275,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -64.8,
        "Latitude": 455500000,
        "Longitude": -644800000,
        "Elevation (m)": 141.7,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1966,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "TURTLE CREEK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105520",
        "Station ID": 6276,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46,
        "Longitude (Decimal Degrees)": -64.9,
        "Latitude": 460000000,
        "Longitude": -645400000,
        "Elevation (m)": 45.7,
        "First Year": 1964,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 2015,
        "MLY First Year": 1965,
        "MLY Last Year": 2005
      },
      {
        "Name": "UPPER BLACKVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105540",
        "Station ID": 6277,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -65.87,
        "Latitude": 463800000,
        "Longitude": -655200000,
        "Elevation (m)": 22.9,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "UPPER HOLMESVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105546",
        "Station ID": 6278,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.62,
        "Longitude (Decimal Degrees)": -67.62,
        "Latitude": 463700000,
        "Longitude": -673700000,
        "Elevation (m)": 182.9,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "UPSALQUITCH",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105550",
        "Station ID": 6279,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.85,
        "Longitude (Decimal Degrees)": -66.85,
        "Latitude": 475100000,
        "Longitude": -665100000,
        "Elevation (m)": 48.5,
        "First Year": 1919,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1948,
        "MLY First Year": 1919,
        "MLY Last Year": 1948
      },
      {
        "Name": "UPSALQUITCH LAKE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105551",
        "Station ID": 6280,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.46,
        "Longitude (Decimal Degrees)": -66.42,
        "Latitude": 472720070,
        "Longitude": -662455003,
        "Elevation (m)": 624.8,
        "First Year": 1967,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2015,
        "MLY First Year": 1967,
        "MLY Last Year": 2006
      },
      {
        "Name": "WELSFORD",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105560",
        "Station ID": 6281,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.47,
        "Longitude (Decimal Degrees)": -66.33,
        "Latitude": 452800000,
        "Longitude": -662000000,
        "Elevation (m)": 22.9,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1974
      },
      {
        "Name": "WIGGINS POINT",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105568",
        "Station ID": 6282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -66,
        "Latitude": 455500000,
        "Longitude": -660000000,
        "Elevation (m)": 15.2,
        "First Year": 1970,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1996,
        "MLY First Year": 1970,
        "MLY Last Year": 1996
      },
      {
        "Name": "WILD GOOSE RIVER",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105569",
        "Station ID": 6283,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -68.23,
        "Latitude": 474900000,
        "Longitude": -681400000,
        "Elevation (m)": 434.3,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "WILLIAMSBURG",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105580",
        "Station ID": 6284,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -66.72,
        "Latitude": 462000000,
        "Longitude": -664300000,
        "Elevation (m)": 321.6,
        "First Year": 1912,
        "Last Year": 1926,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1926,
        "MLY First Year": 1912,
        "MLY Last Year": 1925
      },
      {
        "Name": "WOLFE LAKE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105595",
        "Station ID": 6285,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -65.15,
        "Latitude": 454000000,
        "Longitude": -650900000,
        "Elevation (m)": 304.8,
        "First Year": 1976,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1991,
        "MLY First Year": 1976,
        "MLY Last Year": 1991
      },
      {
        "Name": "WOLFE LAKE CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105597",
        "Station ID": 6920,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -65.15,
        "Latitude": 454000000,
        "Longitude": -650900000,
        "Elevation (m)": 304.8,
        "First Year": 1993,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2007,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "WOODSTOCK",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105600",
        "Station ID": 6286,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -67.55,
        "Latitude": 461013000,
        "Longitude": -673313000,
        "Elevation (m)": 153,
        "First Year": 1886,
        "Last Year": 2020,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1886,
        "DLY Last Year": 2020,
        "MLY First Year": 1886,
        "MLY Last Year": 2006
      },
      {
        "Name": "WOODSTOCK NEWBRIDGE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105603",
        "Station ID": 53238,
        "WMO ID": 71606,
        "TC ID": "EWD",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -67.54,
        "Latitude": 460908000,
        "Longitude": -673234000,
        "Elevation (m)": 154,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WOODSTOCK GRAFTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "8105605",
        "Station ID": 6287,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -67.57,
        "Latitude": 460900000,
        "Longitude": -673400000,
        "Elevation (m)": 32,
        "First Year": 1964,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1965,
        "MLY First Year": 1964,
        "MLY Last Year": 1965
      },
      {
        "Name": "EDMUNDSTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810AL00",
        "Station ID": 6099,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.35,
        "Longitude (Decimal Degrees)": -68.19,
        "Latitude": 472047000,
        "Longitude": -681116000,
        "Elevation (m)": 163,
        "First Year": 1983,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2009,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "SAINT JOHN MILLEDGEVILLE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810D9BC",
        "Station ID": 6100,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -66.08,
        "Latitude": 451700000,
        "Longitude": -660500000,
        "Elevation (m)": 30.5,
        "First Year": 1972,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1972,
        "MLY First Year": 1972,
        "MLY Last Year": 1972
      },
      {
        "Name": "SAINT JOHN SPRUCE LAKE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810D9KN",
        "Station ID": 6101,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -66.18,
        "Latitude": 451300000,
        "Longitude": -661100000,
        "Elevation (m)": 61,
        "First Year": 1971,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1973,
        "MLY First Year": 1971,
        "MLY Last Year": 1973
      },
      {
        "Name": "ST LEONARD CS",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810I001",
        "Station ID": 26823,
        "WMO ID": 71019,
        "TC ID": "WIY",
        "Latitude (Decimal Degrees)": 47.16,
        "Longitude (Decimal Degrees)": -67.83,
        "Latitude": 470928050,
        "Longitude": -674955014,
        "Elevation (m)": 245,
        "First Year": 1995,
        "Last Year": 2017,
        "HLY First Year": 1995,
        "HLY Last Year": 2017,
        "DLY First Year": 2001,
        "DLY Last Year": 2017,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "COLES ISLAND",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810JAE0",
        "Station ID": 6102,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -65.82,
        "Latitude": 455500000,
        "Longitude": -654900000,
        "Elevation (m)": 15.2,
        "First Year": 1987,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2006,
        "MLY First Year": 1987,
        "MLY Last Year": 2006
      },
      {
        "Name": "EDMUNDSTON",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810JL00",
        "Station ID": 6103,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.37,
        "Longitude (Decimal Degrees)": -68.28,
        "Latitude": 472200000,
        "Longitude": -681700000,
        "Elevation (m)": 198.1,
        "First Year": 1979,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1983,
        "MLY First Year": 1979,
        "MLY Last Year": 1983
      },
      {
        "Name": "SEAL COVE",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810M937",
        "Station ID": 6105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -66.83,
        "Latitude": 443900000,
        "Longitude": -665000000,
        "Elevation (m)": 20,
        "First Year": 1987,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2004,
        "MLY First Year": 1987,
        "MLY Last Year": 2004
      },
      {
        "Name": "SAINT JOHN UNB",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810MRBE",
        "Station ID": 6104,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -66.08,
        "Latitude": 451900000,
        "Longitude": -660500000,
        "Elevation (m)": 106.7,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "SCOTCHTOWN",
        "Province": "NEW BRUNSWICK",
        "Climate ID": "810N002",
        "Station ID": 30117,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.9,
        "Longitude (Decimal Degrees)": -66.12,
        "Latitude": 455400000,
        "Longitude": -660700000,
        "Elevation (m)": 16.2,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2007,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "ABERCROMBIE POINT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200015",
        "Station ID": 6288,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -62.72,
        "Latitude": 453900000,
        "Longitude": -624300000,
        "Elevation (m)": 10.7,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "AMHERST (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200091",
        "Station ID": 7103,
        "WMO ID": null,
        "TC ID": "WAH",
        "Latitude (Decimal Degrees)": 45.85,
        "Longitude (Decimal Degrees)": -64.27,
        "Latitude": 455100000,
        "Longitude": -641600000,
        "Elevation (m)": 23,
        "First Year": 1992,
        "Last Year": 2005,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1992,
        "DLY Last Year": 2005,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ANNAPOLIS ROYAL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200100",
        "Station ID": 6289,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -65.52,
        "Latitude": 444500000,
        "Longitude": -653100000,
        "Elevation (m)": 7.7,
        "First Year": 1914,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 2007,
        "MLY First Year": 1914,
        "MLY Last Year": 2006
      },
      {
        "Name": "ANTIGONISH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200150",
        "Station ID": 6290,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -61.97,
        "Latitude": 453800000,
        "Longitude": -615800000,
        "Elevation (m)": 9.1,
        "First Year": 1880,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1880,
        "DLY Last Year": 1947,
        "MLY First Year": 1880,
        "MLY Last Year": 1947
      },
      {
        "Name": "ANTIGONISH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200151",
        "Station ID": 6291,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -61.98,
        "Latitude": 453700000,
        "Longitude": -615900000,
        "Elevation (m)": 30.5,
        "First Year": 1979,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1982,
        "MLY First Year": 1979,
        "MLY Last Year": 1982
      },
      {
        "Name": "ANTIGONISH SNOW",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200152",
        "Station ID": 47247,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -61.9,
        "Latitude": 453700000,
        "Longitude": -615400000,
        "Elevation (m)": 30,
        "First Year": 2009,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2009,
        "DLY Last Year": 2009,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "APRIL BROOK IHD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200155",
        "Station ID": 6292,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -61.13,
        "Latitude": 461400000,
        "Longitude": -610800000,
        "Elevation (m)": 61,
        "First Year": 1966,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1976,
        "MLY First Year": 1966,
        "MLY Last Year": 1976
      },
      {
        "Name": "ARGYLE HEAD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200170",
        "Station ID": 6293,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -65.87,
        "Latitude": 435000000,
        "Longitude": -655200000,
        "Elevation (m)": 15.2,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1983,
        "MLY First Year": 1981,
        "MLY Last Year": 1983
      },
      {
        "Name": "ASHDALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200180",
        "Station ID": 30681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -63.88,
        "Latitude": 450000000,
        "Longitude": -635300000,
        "Elevation (m)": 70.05,
        "First Year": 2000,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2004,
        "MLY First Year": 2000,
        "MLY Last Year": 2004
      },
      {
        "Name": "AVON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200200",
        "Station ID": 6294,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -64.22,
        "Latitude": 445300000,
        "Longitude": -641300000,
        "Elevation (m)": 23.7,
        "First Year": 1949,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1949,
        "DLY Last Year": 2001,
        "MLY First Year": 1949,
        "MLY Last Year": 2001
      },
      {
        "Name": "AVONDALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200210",
        "Station ID": 6921,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -64.12,
        "Latitude": 450100000,
        "Longitude": -640700000,
        "Elevation (m)": 42.1,
        "First Year": 1993,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2006,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "AYLESFORD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200220",
        "Station ID": 6295,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -64.87,
        "Latitude": 450200000,
        "Longitude": -645200000,
        "Elevation (m)": 39.9,
        "First Year": 1961,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1984,
        "MLY First Year": 1961,
        "MLY Last Year": 1984
      },
      {
        "Name": "BACCARO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200250",
        "Station ID": 6296,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.47,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 432800000,
        "Longitude": -652800000,
        "Elevation (m)": 3,
        "First Year": 1957,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1979,
        "MLY First Year": 1957,
        "MLY Last Year": 1979
      },
      {
        "Name": "BACCARO POINT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200254",
        "Station ID": 10914,
        "WMO ID": null,
        "TC ID": "WCP",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 432658000,
        "Longitude": -652817000,
        "Elevation (m)": 12.7,
        "First Year": 1994,
        "Last Year": 2009,
        "HLY First Year": 1994,
        "HLY Last Year": 2009,
        "DLY First Year": 2005,
        "DLY Last Year": 2007,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BACCARO PT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200255",
        "Station ID": 46007,
        "WMO ID": 71920,
        "TC ID": "ACP",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 432703000,
        "Longitude": -652818000,
        "Elevation (m)": 4.6,
        "First Year": 2007,
        "Last Year": 2022,
        "HLY First Year": 2007,
        "HLY Last Year": 2022,
        "DLY First Year": 2007,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "BADDECK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200300",
        "Station ID": 6297,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.1,
        "Longitude (Decimal Degrees)": -60.75,
        "Latitude": 460600000,
        "Longitude": -604500000,
        "Elevation (m)": 7.6,
        "First Year": 1875,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1875,
        "DLY Last Year": 2000,
        "MLY First Year": 1875,
        "MLY Last Year": 2000
      },
      {
        "Name": "BADDECK BELL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200301",
        "Station ID": 30386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.1,
        "Longitude (Decimal Degrees)": -60.75,
        "Latitude": 460600000,
        "Longitude": -604500000,
        "Elevation (m)": 16.6,
        "First Year": 2000,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2010,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "BARRIE BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200400",
        "Station ID": 6298,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -61.43,
        "Latitude": 453900000,
        "Longitude": -612600000,
        "Elevation (m)": 10.7,
        "First Year": 1950,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1972,
        "MLY First Year": 1950,
        "MLY Last Year": 1972
      },
      {
        "Name": "BARRINGTON CCR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200415",
        "Station ID": 10969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -65.47,
        "Latitude": 432708000,
        "Longitude": -652820000,
        "Elevation (m)": 21,
        "First Year": 1994,
        "Last Year": 1996,
        "HLY First Year": 1994,
        "HLY Last Year": 1996,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BAYSWATER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200437",
        "Station ID": 6299,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.5,
        "Longitude (Decimal Degrees)": -64.08,
        "Latitude": 443000000,
        "Longitude": -640500000,
        "Elevation (m)": 30.5,
        "First Year": 1990,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1994,
        "MLY First Year": 1990,
        "MLY Last Year": 1994
      },
      {
        "Name": "BEAR RIVER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200500",
        "Station ID": 6300,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.57,
        "Longitude (Decimal Degrees)": -65.63,
        "Latitude": 443400000,
        "Longitude": -653800000,
        "Elevation (m)": 7.6,
        "First Year": 1952,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 2006,
        "MLY First Year": 1952,
        "MLY Last Year": 2006
      },
      {
        "Name": "BEAVERBANK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200550",
        "Station ID": 6301,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.9,
        "Longitude (Decimal Degrees)": -63.72,
        "Latitude": 445400000,
        "Longitude": -634300000,
        "Elevation (m)": 182.9,
        "First Year": 1871,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1964,
        "MLY First Year": 1871,
        "MLY Last Year": 1964
      },
      {
        "Name": "BEAVER ISLAND (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200558",
        "Station ID": 10078,
        "WMO ID": 71403,
        "TC ID": "WBV",
        "Latitude (Decimal Degrees)": 44.82,
        "Longitude (Decimal Degrees)": -62.33,
        "Latitude": 444900000,
        "Longitude": -622000000,
        "Elevation (m)": 16,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEDFORD BASIN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200573",
        "Station ID": 43406,
        "WMO ID": 71329,
        "TC ID": "ABB",
        "Latitude (Decimal Degrees)": 44.71,
        "Longitude (Decimal Degrees)": -63.63,
        "Latitude": 444228008,
        "Longitude": -633803001,
        "Elevation (m)": 3.5,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEDFORD RANGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200574",
        "Station ID": 43123,
        "WMO ID": 71325,
        "TC ID": "ABR",
        "Latitude (Decimal Degrees)": 44.75,
        "Longitude (Decimal Degrees)": -63.66,
        "Latitude": 444445090,
        "Longitude": -633942080,
        "Elevation (m)": 9.6,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BEDFORD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200575",
        "Station ID": 6302,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -63.67,
        "Latitude": 444400000,
        "Longitude": -634000000,
        "Elevation (m)": 30.5,
        "First Year": 1954,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1966,
        "MLY First Year": 1955,
        "MLY Last Year": 1966
      },
      {
        "Name": "BEDFORD MWO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200576",
        "Station ID": 26824,
        "WMO ID": null,
        "TC ID": "WAZ",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -63.67,
        "Latitude": 444400000,
        "Longitude": -634000000,
        "Elevation (m)": 32.9,
        "First Year": 1995,
        "Last Year": 1998,
        "HLY First Year": 1995,
        "HLY Last Year": 1998,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BERRYS BAY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200577",
        "Station ID": 27602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.66,
        "Longitude (Decimal Degrees)": -65.26,
        "Latitude": 433931062,
        "Longitude": -651521024,
        "Elevation (m)": 7,
        "First Year": 1998,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2001,
        "MLY First Year": 1998,
        "MLY Last Year": 2001
      },
      {
        "Name": "BERWICK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200578",
        "Station ID": 7105,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.08,
        "Longitude (Decimal Degrees)": -64.73,
        "Latitude": 450500000,
        "Longitude": -644400000,
        "Elevation (m)": 39.6,
        "First Year": 1994,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2015,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "BIG INTERVALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200579",
        "Station ID": 6303,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.83,
        "Longitude (Decimal Degrees)": -60.62,
        "Latitude": 465000000,
        "Longitude": -603700000,
        "Elevation (m)": 39.6,
        "First Year": 1984,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1992,
        "MLY First Year": 1984,
        "MLY Last Year": 1992
      },
      {
        "Name": "BIRCHTOWN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200581",
        "Station ID": 6304,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.45,
        "Longitude (Decimal Degrees)": -61.48,
        "Latitude": 452700000,
        "Longitude": -612900000,
        "Elevation (m)": 68.6,
        "First Year": 1958,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1977,
        "MLY First Year": 1958,
        "MLY Last Year": 1977
      },
      {
        "Name": "BLACK RIVER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200584",
        "Station ID": 6305,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -61.33,
        "Latitude": 460800000,
        "Longitude": -612000000,
        "Elevation (m)": 106.7,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "BLUE MOUNTAIN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200587",
        "Station ID": 6306,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -62.42,
        "Latitude": 452900000,
        "Longitude": -622500000,
        "Elevation (m)": 213.4,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "BRAESHORE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200590",
        "Station ID": 6307,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -62.65,
        "Latitude": 454200000,
        "Longitude": -623900000,
        "Elevation (m)": 7.6,
        "First Year": 1975,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1984,
        "MLY First Year": 1975,
        "MLY Last Year": 1984
      },
      {
        "Name": "BRIDGETOWN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200596",
        "Station ID": 27930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.83,
        "Longitude (Decimal Degrees)": -65.3,
        "Latitude": 445000000,
        "Longitude": -651800000,
        "Elevation (m)": 8,
        "First Year": 1998,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2012,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "BRIDGEWATER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200600",
        "Station ID": 6308,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -64.55,
        "Latitude": 442400000,
        "Longitude": -643300000,
        "Elevation (m)": 27.4,
        "First Year": 1961,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2014,
        "MLY First Year": 1961,
        "MLY Last Year": 2006
      },
      {
        "Name": "BRIER ISLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200604",
        "Station ID": 10859,
        "WMO ID": 71988,
        "TC ID": "WVU",
        "Latitude (Decimal Degrees)": 44.29,
        "Longitude (Decimal Degrees)": -66.35,
        "Latitude": 441709000,
        "Longitude": -662048000,
        "Elevation (m)": 15.8,
        "First Year": 1969,
        "Last Year": 2022,
        "HLY First Year": 1969,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAMBRIDGE STATION",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200630",
        "Station ID": 6309,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -64.62,
        "Latitude": 450400000,
        "Longitude": -643700000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "CAMDEN IHD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200635",
        "Station ID": 6310,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -63.2,
        "Latitude": 451900000,
        "Longitude": -631200000,
        "Elevation (m)": 182.9,
        "First Year": 1973,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1979,
        "MLY First Year": 1973,
        "MLY Last Year": 1979
      },
      {
        "Name": "CANNING",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200638",
        "Station ID": 6311,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -64.45,
        "Latitude": 450900000,
        "Longitude": -642700000,
        "Elevation (m)": 27.1,
        "First Year": 1979,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1981,
        "MLY First Year": 1979,
        "MLY Last Year": 1981
      },
      {
        "Name": "CANSO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200640",
        "Station ID": 6312,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -60.97,
        "Latitude": 451900000,
        "Longitude": -605800000,
        "Elevation (m)": 25.9,
        "First Year": 1963,
        "Last Year": 1971,
        "HLY First Year": 1963,
        "HLY Last Year": 1971,
        "DLY First Year": 1963,
        "DLY Last Year": 1971,
        "MLY First Year": 1964,
        "MLY Last Year": 1971
      },
      {
        "Name": "CAPE GEORGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200647",
        "Station ID": 10936,
        "WMO ID": null,
        "TC ID": "WGU",
        "Latitude (Decimal Degrees)": 45.87,
        "Longitude (Decimal Degrees)": -61.9,
        "Latitude": 455227000,
        "Longitude": -615405000,
        "Elevation (m)": 120,
        "First Year": 1994,
        "Last Year": 2002,
        "HLY First Year": 1994,
        "HLY Last Year": 2002,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE JOHN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200650",
        "Station ID": 6313,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.78,
        "Longitude (Decimal Degrees)": -63.08,
        "Latitude": 454700000,
        "Longitude": -630500000,
        "Elevation (m)": 15.2,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "CAPE NORTH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200670",
        "Station ID": 6314,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.02,
        "Longitude (Decimal Degrees)": -60.45,
        "Latitude": 470100000,
        "Longitude": -602700000,
        "Elevation (m)": 47.2,
        "First Year": 1958,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1959,
        "MLY First Year": 1958,
        "MLY Last Year": 1959
      },
      {
        "Name": "CAPE SABLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200700",
        "Station ID": 6315,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.38,
        "Longitude (Decimal Degrees)": -65.62,
        "Latitude": 432300000,
        "Longitude": -653700000,
        "Elevation (m)": 3,
        "First Year": 1948,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1986,
        "MLY First Year": 1948,
        "MLY Last Year": 1986
      },
      {
        "Name": "CAP LA RONDE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200750",
        "Station ID": 6316,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -60.9,
        "Latitude": 453400000,
        "Longitude": -605400000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "CARIBOU POINT (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200774",
        "Station ID": 8990,
        "WMO ID": 71415,
        "TC ID": "WBK",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -62.68,
        "Latitude": 454600000,
        "Longitude": -624100000,
        "Elevation (m)": 2.4,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHAIN LAKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200800",
        "Station ID": 6317,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -63.63,
        "Latitude": 443800000,
        "Longitude": -633800000,
        "Elevation (m)": 59.4,
        "First Year": 1945,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1977,
        "MLY First Year": 1945,
        "MLY Last Year": 1977
      },
      {
        "Name": "CHARLESVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200810",
        "Station ID": 6318,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.58,
        "Longitude (Decimal Degrees)": -65.78,
        "Latitude": 433500000,
        "Longitude": -654700000,
        "Elevation (m)": 2.7,
        "First Year": 1978,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2017,
        "MLY First Year": 1978,
        "MLY Last Year": 2006
      },
      {
        "Name": "CHESTER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200816",
        "Station ID": 6319,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -64.25,
        "Latitude": 443300000,
        "Longitude": -641500000,
        "Elevation (m)": 60.7,
        "First Year": 1983,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "CHESTER BASIN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200818",
        "Station ID": 6320,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -64.32,
        "Latitude": 443300000,
        "Longitude": -641900000,
        "Elevation (m)": 13.4,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1984,
        "MLY Last Year": 1984
      },
      {
        "Name": "CHETICAMP",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200824",
        "Station ID": 6321,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -61.05,
        "Latitude": 463800000,
        "Longitude": -610300000,
        "Elevation (m)": 335.3,
        "First Year": 1935,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1935,
        "DLY Last Year": 1945,
        "MLY First Year": 1935,
        "MLY Last Year": 1945
      },
      {
        "Name": "CHETICAMP",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200825",
        "Station ID": 6322,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.65,
        "Longitude (Decimal Degrees)": -60.95,
        "Latitude": 463900000,
        "Longitude": -605700000,
        "Elevation (m)": 11,
        "First Year": 1956,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1998,
        "MLY First Year": 1956,
        "MLY Last Year": 1998
      },
      {
        "Name": "CHETICAMP CS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200827",
        "Station ID": 27600,
        "WMO ID": 71959,
        "TC ID": "XCH",
        "Latitude (Decimal Degrees)": 46.65,
        "Longitude (Decimal Degrees)": -60.95,
        "Latitude": 463842000,
        "Longitude": -605650000,
        "Elevation (m)": 43.9,
        "First Year": 1998,
        "Last Year": 2018,
        "HLY First Year": 1999,
        "HLY Last Year": 2018,
        "DLY First Year": 1998,
        "DLY Last Year": 2018,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHETICAMP HIGHLANDS NATIONAL PARK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200828",
        "Station ID": 54618,
        "WMO ID": 73023,
        "TC ID": "AHT",
        "Latitude (Decimal Degrees)": 46.65,
        "Longitude (Decimal Degrees)": -60.95,
        "Latitude": 463904130,
        "Longitude": -605716790,
        "Elevation (m)": 8,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHETICAMP SNOW",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200830",
        "Station ID": 49769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -60.95,
        "Latitude": 463800000,
        "Longitude": -605700000,
        "Elevation (m)": 27,
        "First Year": 2011,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CLAM HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200850",
        "Station ID": 6323,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -62.88,
        "Latitude": 444400000,
        "Longitude": -625300000,
        "Elevation (m)": 7.6,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "CLARENCE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200860",
        "Station ID": 6324,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -65.17,
        "Latitude": 445500000,
        "Longitude": -651000000,
        "Elevation (m)": 51.5,
        "First Year": 1958,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1993,
        "MLY First Year": 1958,
        "MLY Last Year": 1993
      },
      {
        "Name": "CLEMENTSVALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200875",
        "Station ID": 6325,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -65.57,
        "Latitude": 443700000,
        "Longitude": -653400000,
        "Elevation (m)": 106.7,
        "First Year": 1961,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1980,
        "MLY First Year": 1961,
        "MLY Last Year": 1980
      },
      {
        "Name": "CLIFTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200900",
        "Station ID": 6326,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -63.42,
        "Latitude": 452100000,
        "Longitude": -632500000,
        "Elevation (m)": 15.2,
        "First Year": 1948,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1989,
        "MLY First Year": 1948,
        "MLY Last Year": 1989
      },
      {
        "Name": "CLYDESDALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200940",
        "Station ID": 6327,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -62.03,
        "Latitude": 453800000,
        "Longitude": -620200000,
        "Elevation (m)": 56.7,
        "First Year": 1990,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1995,
        "MLY First Year": 1990,
        "MLY Last Year": 1995
      },
      {
        "Name": "COLLEGE GRANT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8200989",
        "Station ID": 6328,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -63.1,
        "Latitude": 453900000,
        "Longitude": -630600000,
        "Elevation (m)": 96.9,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "COLLEGEVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201000",
        "Station ID": 6329,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.48,
        "Longitude (Decimal Degrees)": -62.02,
        "Latitude": 452900000,
        "Longitude": -620100000,
        "Elevation (m)": 76.2,
        "First Year": 1916,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 2016,
        "MLY First Year": 1916,
        "MLY Last Year": 2006
      },
      {
        "Name": "COLLEGEVILLE AUTO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201001",
        "Station ID": 53538,
        "WMO ID": 71698,
        "TC ID": "AGL",
        "Latitude (Decimal Degrees)": 45.49,
        "Longitude (Decimal Degrees)": -62.01,
        "Latitude": 452928300,
        "Longitude": -620053900,
        "Elevation (m)": 69,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "COPPER LAKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201100",
        "Station ID": 6330,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -61.97,
        "Latitude": 452300000,
        "Longitude": -615800000,
        "Elevation (m)": 96.9,
        "First Year": 1945,
        "Last Year": 1974,
        "HLY First Year": 1953,
        "HLY Last Year": 1974,
        "DLY First Year": 1945,
        "DLY Last Year": 1974,
        "MLY First Year": 1945,
        "MLY Last Year": 1974
      },
      {
        "Name": "CORNWALL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201200",
        "Station ID": 6331,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.53,
        "Longitude (Decimal Degrees)": -64.57,
        "Latitude": 443200000,
        "Longitude": -643400000,
        "Elevation (m)": null,
        "First Year": 1950,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1957,
        "MLY First Year": 1950,
        "MLY Last Year": 1957
      },
      {
        "Name": "DARTMOUTH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201292",
        "Station ID": 6332,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.67,
        "Longitude (Decimal Degrees)": -63.57,
        "Latitude": 444000000,
        "Longitude": -633400000,
        "Elevation (m)": 22.9,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "DAYTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201336",
        "Station ID": 6333,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.87,
        "Longitude (Decimal Degrees)": -66.1,
        "Latitude": 435200000,
        "Longitude": -660600000,
        "Elevation (m)": 8.9,
        "First Year": 1988,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1997,
        "MLY First Year": 1988,
        "MLY Last Year": 1997
      },
      {
        "Name": "DEBERT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201380",
        "Station ID": 6334,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -63.42,
        "Latitude": 452500000,
        "Longitude": -632500000,
        "Elevation (m)": 38.1,
        "First Year": 1982,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2001,
        "MLY First Year": 1982,
        "MLY Last Year": 2001
      },
      {
        "Name": "DEBERT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201390",
        "Station ID": 42243,
        "WMO ID": 71317,
        "TC ID": "ZDB",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -63.47,
        "Latitude": 452500000,
        "Longitude": -632800000,
        "Elevation (m)": 37.5,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "DEBERT A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201400",
        "Station ID": 6335,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -63.45,
        "Latitude": 452500000,
        "Longitude": -632700000,
        "Elevation (m)": 44.2,
        "First Year": 1945,
        "Last Year": 1960,
        "HLY First Year": 1953,
        "HLY Last Year": 1960,
        "DLY First Year": 1945,
        "DLY Last Year": 1960,
        "MLY First Year": 1945,
        "MLY Last Year": 1960
      },
      {
        "Name": "DEMING",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201410",
        "Station ID": 6336,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -61.18,
        "Latitude": 451259007,
        "Longitude": -611040090,
        "Elevation (m)": 15.8,
        "First Year": 1956,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2011,
        "MLY First Year": 1956,
        "MLY Last Year": 2006
      },
      {
        "Name": "DICKIE BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201500",
        "Station ID": 6337,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -61.5,
        "Latitude": 452100000,
        "Longitude": -613000000,
        "Elevation (m)": 2.8,
        "First Year": 1950,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1994,
        "MLY First Year": 1950,
        "MLY Last Year": 1994
      },
      {
        "Name": "DIGBY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201600",
        "Station ID": 6338,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -65.75,
        "Latitude": 443700000,
        "Longitude": -654500000,
        "Elevation (m)": 12.2,
        "First Year": 1872,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1965,
        "MLY First Year": 1872,
        "MLY Last Year": 1965
      },
      {
        "Name": "DIGBY AIRPORT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201601",
        "Station ID": 6339,
        "WMO ID": null,
        "TC ID": "YID",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -65.78,
        "Latitude": 443300000,
        "Longitude": -654700000,
        "Elevation (m)": 152.1,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "DIGBY CKDY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201602",
        "Station ID": 6340,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.62,
        "Longitude (Decimal Degrees)": -65.77,
        "Latitude": 443700000,
        "Longitude": -654600000,
        "Elevation (m)": 60.7,
        "First Year": 1970,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1985,
        "MLY First Year": 1970,
        "MLY Last Year": 1985
      },
      {
        "Name": "DIGBY PRIM POINT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201605",
        "Station ID": 6341,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.68,
        "Longitude (Decimal Degrees)": -65.78,
        "Latitude": 444100000,
        "Longitude": -654700000,
        "Elevation (m)": 21.3,
        "First Year": 1965,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1985,
        "MLY First Year": 1965,
        "MLY Last Year": 1985
      },
      {
        "Name": "EAST JORDAN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201685",
        "Station ID": 6342,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.73,
        "Longitude (Decimal Degrees)": -65.2,
        "Latitude": 434400000,
        "Longitude": -651200000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1980,
        "MLY First Year": 1973,
        "MLY Last Year": 1980
      },
      {
        "Name": "EAST RIVER ST MARY'S",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201690",
        "Station ID": 6343,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.38,
        "Longitude (Decimal Degrees)": -62.17,
        "Latitude": 452300000,
        "Longitude": -621000000,
        "Elevation (m)": 52.4,
        "First Year": 1975,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1980,
        "MLY First Year": 1975,
        "MLY Last Year": 1980
      },
      {
        "Name": "EAST WALLACE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201695",
        "Station ID": 30387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -63.43,
        "Latitude": 454800000,
        "Longitude": -632600000,
        "Elevation (m)": 17,
        "First Year": 2000,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2004,
        "MLY First Year": 2000,
        "MLY Last Year": 2004
      },
      {
        "Name": "ECUM SECUM",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201700",
        "Station ID": 6344,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -62.18,
        "Latitude": 445900000,
        "Longitude": -621100000,
        "Elevation (m)": 15.3,
        "First Year": 1940,
        "Last Year": 1986,
        "HLY First Year": 1953,
        "HLY Last Year": 1961,
        "DLY First Year": 1940,
        "DLY Last Year": 1986,
        "MLY First Year": 1940,
        "MLY Last Year": 1986
      },
      {
        "Name": "EDDY POINT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201716",
        "Station ID": 6345,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.52,
        "Longitude (Decimal Degrees)": -61.25,
        "Latitude": 453100000,
        "Longitude": -611500000,
        "Elevation (m)": 66.1,
        "First Year": 1971,
        "Last Year": 1985,
        "HLY First Year": 1971,
        "HLY Last Year": 1985,
        "DLY First Year": 1971,
        "DLY Last Year": 1985,
        "MLY First Year": 1972,
        "MLY Last Year": 1985
      },
      {
        "Name": "ELMSDALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201750",
        "Station ID": 6346,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -63.52,
        "Latitude": 445800000,
        "Longitude": -633100000,
        "Elevation (m)": 15.2,
        "First Year": 1961,
        "Last Year": 1964,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1964,
        "MLY First Year": 1961,
        "MLY Last Year": 1964
      },
      {
        "Name": "FARMINGTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201766",
        "Station ID": 6347,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -64.67,
        "Latitude": 443800000,
        "Longitude": -644000000,
        "Elevation (m)": 151.5,
        "First Year": 1982,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2003,
        "MLY First Year": 1982,
        "MLY Last Year": 2003
      },
      {
        "Name": "ESKASONI FIRST NATION AUTOMATIC WEATHER STATION",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201780",
        "Station ID": 49748,
        "WMO ID": 71175,
        "TC ID": "AEI",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -60.65,
        "Latitude": 455514000,
        "Longitude": -603849000,
        "Elevation (m)": 28,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "FIVE MILE LAKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201800",
        "Station ID": 6348,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.95,
        "Longitude (Decimal Degrees)": -64,
        "Latitude": 445700000,
        "Longitude": -640000000,
        "Elevation (m)": 45.7,
        "First Year": 1933,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 1978,
        "MLY First Year": 1933,
        "MLY Last Year": 1978
      },
      {
        "Name": "FRASER BROOK IHD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201850",
        "Station ID": 6349,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.33,
        "Longitude (Decimal Degrees)": -63.17,
        "Latitude": 452000000,
        "Longitude": -631000000,
        "Elevation (m)": 121.9,
        "First Year": 1965,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1977,
        "MLY First Year": 1966,
        "MLY Last Year": 1977
      },
      {
        "Name": "FRIZZLETON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201900",
        "Station ID": 6350,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -60.97,
        "Latitude": 462200000,
        "Longitude": -605800000,
        "Elevation (m)": null,
        "First Year": 1916,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1951,
        "MLY First Year": 1916,
        "MLY Last Year": 1953
      },
      {
        "Name": "GARLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201925",
        "Station ID": 6351,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -64.78,
        "Latitude": 450700000,
        "Longitude": -644700000,
        "Elevation (m)": 205.7,
        "First Year": 1964,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1972,
        "MLY First Year": 1964,
        "MLY Last Year": 1972
      },
      {
        "Name": "GLENORA FALLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201950",
        "Station ID": 6352,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -61.37,
        "Latitude": 460700000,
        "Longitude": -612200000,
        "Elevation (m)": 76.2,
        "First Year": 1954,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1961,
        "MLY First Year": 1954,
        "MLY Last Year": 1961
      },
      {
        "Name": "GRAND ETANG",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201969",
        "Station ID": 10792,
        "WMO ID": 73030,
        "TC ID": "WZQ",
        "Latitude (Decimal Degrees)": 46.55,
        "Longitude (Decimal Degrees)": -61.05,
        "Latitude": 463300000,
        "Longitude": -610300000,
        "Elevation (m)": 12.8,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GREAT VILLAGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8201980",
        "Station ID": 6353,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.42,
        "Longitude (Decimal Degrees)": -63.62,
        "Latitude": 452500000,
        "Longitude": -633700000,
        "Elevation (m)": 22,
        "First Year": 1980,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2004,
        "MLY First Year": 1980,
        "MLY Last Year": 2004
      },
      {
        "Name": "GREENWOOD A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202000",
        "Station ID": 6354,
        "WMO ID": 71397,
        "TC ID": "YZX",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -64.92,
        "Latitude": 445900000,
        "Longitude": -645500000,
        "Elevation (m)": 28,
        "First Year": 1942,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1942,
        "DLY Last Year": 2022,
        "MLY First Year": 1942,
        "MLY Last Year": 2017
      },
      {
        "Name": "EMERGENCY WEATHER STATION #2",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202195",
        "Station ID": 50208,
        "WMO ID": null,
        "TC ID": "ERU",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -64.46,
        "Latitude": 444340000,
        "Longitude": -642722000,
        "Elevation (m)": 135,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALIFAX",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202198",
        "Station ID": 6355,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -63.6,
        "Latitude": 443900000,
        "Longitude": -633600000,
        "Elevation (m)": 29.6,
        "First Year": 1871,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1933,
        "MLY First Year": 1871,
        "MLY Last Year": 1933
      },
      {
        "Name": "HALIFAX",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202200",
        "Station ID": 6356,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -63.57,
        "Latitude": 443900000,
        "Longitude": -633400000,
        "Elevation (m)": 31.7,
        "First Year": 1939,
        "Last Year": 1974,
        "HLY First Year": 1953,
        "HLY Last Year": 1963,
        "DLY First Year": 1939,
        "DLY Last Year": 1974,
        "MLY First Year": 1939,
        "MLY Last Year": 1974
      },
      {
        "Name": "HALIFAX CITADEL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202220",
        "Station ID": 6357,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.65,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 443900000,
        "Longitude": -633500000,
        "Elevation (m)": 70.1,
        "First Year": 1933,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 2002,
        "MLY First Year": 1933,
        "MLY Last Year": 2002
      },
      {
        "Name": "HALIFAX COMMONS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202221",
        "Station ID": 49128,
        "WMO ID": null,
        "TC ID": "AHF",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 443800000,
        "Longitude": -633500000,
        "Elevation (m)": 44,
        "First Year": 2010,
        "Last Year": 2011,
        "HLY First Year": 2010,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALIFAX DOCKYARD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202240",
        "Station ID": 43405,
        "WMO ID": 71328,
        "TC ID": "AHD",
        "Latitude (Decimal Degrees)": 44.66,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 443919000,
        "Longitude": -633436000,
        "Elevation (m)": 3.8,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALIFAX STANFIELD INT'L A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202249",
        "Station ID": 53938,
        "WMO ID": null,
        "TC ID": "YHZ",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -63.51,
        "Latitude": 445252000,
        "Longitude": -633031000,
        "Elevation (m)": 145.4,
        "First Year": 2019,
        "Last Year": 2022,
        "HLY First Year": 2019,
        "HLY Last Year": 2022,
        "DLY First Year": 2019,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALIFAX STANFIELD INT'L A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202250",
        "Station ID": 6358,
        "WMO ID": 71395,
        "TC ID": "YHZ",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -63.5,
        "Latitude": 445248060,
        "Longitude": -633000050,
        "Elevation (m)": 145.4,
        "First Year": 1953,
        "Last Year": 2012,
        "HLY First Year": 1961,
        "HLY Last Year": 2012,
        "DLY First Year": 1953,
        "DLY Last Year": 2012,
        "MLY First Year": 1953,
        "MLY Last Year": 2012
      },
      {
        "Name": "HALIFAX STANFIELD INT'L A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202251",
        "Station ID": 50620,
        "WMO ID": 71395,
        "TC ID": "YHZ",
        "Latitude (Decimal Degrees)": 44.88,
        "Longitude (Decimal Degrees)": -63.51,
        "Latitude": 445252000,
        "Longitude": -633031000,
        "Elevation (m)": 145.4,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALIFAX KOOTENAY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202252",
        "Station ID": 43124,
        "WMO ID": 71326,
        "TC ID": "AHK",
        "Latitude (Decimal Degrees)": 44.59,
        "Longitude (Decimal Degrees)": -63.55,
        "Latitude": 443515500,
        "Longitude": -633303000,
        "Elevation (m)": 52,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALIFAX WINDSOR PARK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202255",
        "Station ID": 43403,
        "WMO ID": 71327,
        "TC ID": "AHW",
        "Latitude (Decimal Degrees)": 44.66,
        "Longitude (Decimal Degrees)": -63.61,
        "Latitude": 443925000,
        "Longitude": -633632000,
        "Elevation (m)": 51,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HALL'S HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202260",
        "Station ID": 6359,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.2,
        "Longitude (Decimal Degrees)": -64.62,
        "Latitude": 451200000,
        "Longitude": -643700000,
        "Elevation (m)": 7.9,
        "First Year": 1978,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1980,
        "MLY First Year": 1978,
        "MLY Last Year": 1980
      },
      {
        "Name": "HAMMONDS PLAINS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202270",
        "Station ID": 6360,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -63.77,
        "Latitude": 444400000,
        "Longitude": -634600000,
        "Elevation (m)": 106.7,
        "First Year": 1969,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1971
      },
      {
        "Name": "HARMONY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202300",
        "Station ID": 6361,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.42,
        "Longitude (Decimal Degrees)": -65.05,
        "Latitude": 442500000,
        "Longitude": -650300000,
        "Elevation (m)": 106.7,
        "First Year": 1950,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1986,
        "MLY First Year": 1950,
        "MLY Last Year": 1986
      },
      {
        "Name": "HART ISLAND (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202318",
        "Station ID": 7173,
        "WMO ID": 71419,
        "TC ID": "WRN",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -60.98,
        "Latitude": 452100000,
        "Longitude": -605900000,
        "Elevation (m)": 8.2,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HEAD JEDDORE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202337",
        "Station ID": 6362,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.78,
        "Longitude (Decimal Degrees)": -63.07,
        "Latitude": 444700000,
        "Longitude": -630400000,
        "Elevation (m)": 19,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "HELLS GATE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202350",
        "Station ID": 6363,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -64.42,
        "Latitude": 450300000,
        "Longitude": -642500000,
        "Elevation (m)": 91.4,
        "First Year": 1957,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1957,
        "MLY First Year": 1957,
        "MLY Last Year": 1957
      },
      {
        "Name": "HOLLOW BRIDGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202400",
        "Station ID": 6364,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 450000000,
        "Longitude": -642300000,
        "Elevation (m)": 127.7,
        "First Year": 1950,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1954,
        "MLY First Year": 1950,
        "MLY Last Year": 1955
      },
      {
        "Name": "HOPEWELL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202415",
        "Station ID": 6365,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.5,
        "Longitude (Decimal Degrees)": -62.72,
        "Latitude": 453000000,
        "Longitude": -624300000,
        "Elevation (m)": 152.4,
        "First Year": 1968,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1984,
        "MLY First Year": 1968,
        "MLY Last Year": 1984
      },
      {
        "Name": "HUBBARDS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202420",
        "Station ID": 6366,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -64.08,
        "Latitude": 443800000,
        "Longitude": -640500000,
        "Elevation (m)": 30.5,
        "First Year": 1972,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1980,
        "MLY First Year": 1972,
        "MLY Last Year": 1980
      },
      {
        "Name": "INDIAN BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202465",
        "Station ID": 6367,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -60.53,
        "Latitude": 462200000,
        "Longitude": -603200000,
        "Elevation (m)": 13.2,
        "First Year": 1965,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1989,
        "MLY First Year": 1965,
        "MLY Last Year": 1989
      },
      {
        "Name": "INGONISH BEACH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202500",
        "Station ID": 6368,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.65,
        "Longitude (Decimal Degrees)": -60.4,
        "Latitude": 463900000,
        "Longitude": -602400000,
        "Elevation (m)": 7.9,
        "First Year": 1950,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2000,
        "MLY First Year": 1950,
        "MLY Last Year": 2000
      },
      {
        "Name": "INGONISH BEACH RCS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202502",
        "Station ID": 30326,
        "WMO ID": 71027,
        "TC ID": "XIB",
        "Latitude (Decimal Degrees)": 46.66,
        "Longitude (Decimal Degrees)": -60.41,
        "Latitude": 463946001,
        "Longitude": -602423000,
        "Elevation (m)": 10,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2000,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "INVERNESS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202535",
        "Station ID": 6922,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -61.3,
        "Latitude": 461200000,
        "Longitude": -611800000,
        "Elevation (m)": 35.1,
        "First Year": 1991,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2001,
        "MLY First Year": 1991,
        "MLY Last Year": 2001
      },
      {
        "Name": "INVERNESS (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202550",
        "Station ID": 6369,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -61.3,
        "Latitude": 461400000,
        "Longitude": -611800000,
        "Elevation (m)": 38.9,
        "First Year": 1973,
        "Last Year": 1986,
        "HLY First Year": 1977,
        "HLY Last Year": 1986,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "JACKSON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202565",
        "Station ID": 6370,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -63.83,
        "Latitude": 453500000,
        "Longitude": -635000000,
        "Elevation (m)": 91.4,
        "First Year": 1982,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2004,
        "MLY First Year": 1982,
        "MLY Last Year": 2004
      },
      {
        "Name": "KEBLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202580",
        "Station ID": 6371,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -63.18,
        "Latitude": 454200000,
        "Longitude": -631100000,
        "Elevation (m)": 31.1,
        "First Year": 1980,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1980,
        "MLY First Year": 1980,
        "MLY Last Year": 1980
      },
      {
        "Name": "KEJIMKUJIK PARK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202590",
        "Station ID": 6372,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.43,
        "Longitude (Decimal Degrees)": -65.2,
        "Latitude": 442600000,
        "Longitude": -651200000,
        "Elevation (m)": 126.8,
        "First Year": 1966,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1994,
        "MLY First Year": 1966,
        "MLY Last Year": 1994
      },
      {
        "Name": "KEJIMKUJIK 1",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202592",
        "Station ID": 6923,
        "WMO ID": 71599,
        "TC ID": "WKG",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -65.2,
        "Latitude": 442411020,
        "Longitude": -651211070,
        "Elevation (m)": 125,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "KEMPTVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202700",
        "Station ID": 6373,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -65.77,
        "Latitude": 440500000,
        "Longitude": -654600000,
        "Elevation (m)": 76.2,
        "First Year": 1950,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1991,
        "MLY First Year": 1950,
        "MLY Last Year": 1991
      },
      {
        "Name": "KEMPTVILLE 2",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202701",
        "Station ID": 6374,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -65.78,
        "Latitude": 440500000,
        "Longitude": -654700000,
        "Elevation (m)": 61,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "KENTVILLE CDA",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202800",
        "Station ID": 6375,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 450400000,
        "Longitude": -642900000,
        "Elevation (m)": 48.8,
        "First Year": 1913,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1996,
        "MLY First Year": 1913,
        "MLY Last Year": 1996
      },
      {
        "Name": "KENTVILLE CDA CS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202810",
        "Station ID": 27141,
        "WMO ID": 71671,
        "TC ID": "XKT",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 450400000,
        "Longitude": -642900000,
        "Elevation (m)": 48.7,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "LAKE AINSLIE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202856",
        "Station ID": 6955,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -61.2,
        "Latitude": 461100000,
        "Longitude": -611200000,
        "Elevation (m)": 84.7,
        "First Year": 1991,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1991,
        "MLY First Year": 1991,
        "MLY Last Year": 1991
      },
      {
        "Name": "LAKE CHARLOTTE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202890",
        "Station ID": 6376,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.73,
        "Longitude (Decimal Degrees)": -62.95,
        "Latitude": 444400000,
        "Longitude": -625700000,
        "Elevation (m)": 7.6,
        "First Year": 1971,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1972,
        "MLY First Year": 1971,
        "MLY Last Year": 1972
      },
      {
        "Name": "LAKE MAJOR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202896",
        "Station ID": 27742,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.72,
        "Longitude (Decimal Degrees)": -63.48,
        "Latitude": 444300000,
        "Longitude": -632900000,
        "Elevation (m)": 62.5,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 1999,
        "MLY Last Year": 2006
      },
      {
        "Name": "LAKE ROSSIGNOL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202900",
        "Station ID": 6377,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.22,
        "Longitude (Decimal Degrees)": -65.23,
        "Latitude": 441300000,
        "Longitude": -651400000,
        "Elevation (m)": 85.3,
        "First Year": 1941,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1941,
        "DLY Last Year": 1967,
        "MLY First Year": 1941,
        "MLY Last Year": 1967
      },
      {
        "Name": "LAWRENCETOWN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202925",
        "Station ID": 6378,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.87,
        "Longitude (Decimal Degrees)": -65.17,
        "Latitude": 445200000,
        "Longitude": -651000000,
        "Elevation (m)": 30.5,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "LILY DALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202950",
        "Station ID": 6379,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.37,
        "Longitude (Decimal Degrees)": -64.3,
        "Latitude": 442200000,
        "Longitude": -641800000,
        "Elevation (m)": 61,
        "First Year": 1956,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1958,
        "MLY First Year": 1956,
        "MLY Last Year": 1958
      },
      {
        "Name": "LISCOMB GAME SANCTUARY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8202975",
        "Station ID": 6380,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -62.5,
        "Latitude": 450300000,
        "Longitude": -623000000,
        "Elevation (m)": 61,
        "First Year": 1962,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1970,
        "MLY First Year": 1962,
        "MLY Last Year": 1970
      },
      {
        "Name": "LIVERPOOL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203000",
        "Station ID": 6381,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.05,
        "Longitude (Decimal Degrees)": -64.72,
        "Latitude": 440300000,
        "Longitude": -644300000,
        "Elevation (m)": 5.5,
        "First Year": 1940,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1959,
        "MLY First Year": 1940,
        "MLY Last Year": 1959
      },
      {
        "Name": "LIVERPOOL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203001",
        "Station ID": 6382,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.07,
        "Longitude (Decimal Degrees)": -64.67,
        "Latitude": 440400000,
        "Longitude": -644000000,
        "Elevation (m)": 15.2,
        "First Year": 1913,
        "Last Year": 1939,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1913,
        "DLY Last Year": 1939,
        "MLY First Year": 1913,
        "MLY Last Year": 1939
      },
      {
        "Name": "LIVERPOOL BIG FALLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203100",
        "Station ID": 6383,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.13,
        "Longitude (Decimal Degrees)": -64.93,
        "Latitude": 440800000,
        "Longitude": -645600000,
        "Elevation (m)": 49.6,
        "First Year": 1940,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 2012,
        "MLY First Year": 1940,
        "MLY Last Year": 2006
      },
      {
        "Name": "LIVERPOOL MILTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203120",
        "Station ID": 6384,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.08,
        "Longitude (Decimal Degrees)": -64.77,
        "Latitude": 440500000,
        "Longitude": -644600000,
        "Elevation (m)": 28.5,
        "First Year": 1966,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2013,
        "MLY First Year": 1966,
        "MLY Last Year": 2005
      },
      {
        "Name": "LOCH LOMOND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203150",
        "Station ID": 6385,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -60.62,
        "Latitude": 454400000,
        "Longitude": -603700000,
        "Elevation (m)": 30.5,
        "First Year": 1958,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1973,
        "MLY First Year": 1958,
        "MLY Last Year": 1973
      },
      {
        "Name": "LOCH LOMOND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203151",
        "Station ID": 6386,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -60.6,
        "Latitude": 454400000,
        "Longitude": -603600000,
        "Elevation (m)": 45.7,
        "First Year": 1973,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1978,
        "MLY First Year": 1973,
        "MLY Last Year": 1978
      },
      {
        "Name": "LORNE STATION",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203156",
        "Station ID": 6387,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.43,
        "Longitude (Decimal Degrees)": -62.72,
        "Latitude": 452600000,
        "Longitude": -624300000,
        "Elevation (m)": 118.9,
        "First Year": 1984,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1992,
        "MLY First Year": 1984,
        "MLY Last Year": 1992
      },
      {
        "Name": "LOUISBOURG",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203161",
        "Station ID": 6388,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.9,
        "Longitude (Decimal Degrees)": -60,
        "Latitude": 455400000,
        "Longitude": -600000000,
        "Elevation (m)": 45.7,
        "First Year": 1972,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2013,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "LOUISBOURG",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203163",
        "Station ID": 54578,
        "WMO ID": 71596,
        "TC ID": "AUU",
        "Latitude (Decimal Degrees)": 45.92,
        "Longitude (Decimal Degrees)": -59.97,
        "Latitude": 455516000,
        "Longitude": -595817000,
        "Elevation (m)": 15,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LOWER L'ARDOISE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203164",
        "Station ID": 6389,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -60.75,
        "Latitude": 453600000,
        "Longitude": -604500000,
        "Elevation (m)": 15.2,
        "First Year": 1974,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1990,
        "MLY First Year": 1974,
        "MLY Last Year": 1990
      },
      {
        "Name": "LOWER MEAGHERS GRANT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203165",
        "Station ID": 6390,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -63.23,
        "Latitude": 445500000,
        "Longitude": -631400000,
        "Elevation (m)": 41.1,
        "First Year": 1967,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1993,
        "MLY First Year": 1967,
        "MLY Last Year": 1993
      },
      {
        "Name": "LOWER SACKVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203170",
        "Station ID": 6391,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -63.67,
        "Latitude": 444600000,
        "Longitude": -634000000,
        "Elevation (m)": 48.8,
        "First Year": 1964,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1972,
        "MLY First Year": 1964,
        "MLY Last Year": 1972
      },
      {
        "Name": "LR.SACKVILLE/BEDFORD SNOW",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203171",
        "Station ID": 49668,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.76,
        "Longitude (Decimal Degrees)": -63.66,
        "Latitude": 444520052,
        "Longitude": -633931040,
        "Elevation (m)": 59,
        "First Year": 2011,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LUCASVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203200",
        "Station ID": 6392,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -63.75,
        "Latitude": 444600000,
        "Longitude": -634500000,
        "Elevation (m)": 129.5,
        "First Year": 1951,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1951,
        "DLY Last Year": 1954,
        "MLY First Year": 1951,
        "MLY Last Year": 1954
      },
      {
        "Name": "LUNENBURG",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203210",
        "Station ID": 31829,
        "WMO ID": null,
        "TC ID": "XLB",
        "Latitude (Decimal Degrees)": 44.36,
        "Longitude (Decimal Degrees)": -64.3,
        "Latitude": 442136094,
        "Longitude": -641746082,
        "Elevation (m)": 3.8,
        "First Year": 2002,
        "Last Year": 2022,
        "HLY First Year": 2002,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2007,
        "MLY Last Year": 2007
      },
      {
        "Name": "LYONS BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203230",
        "Station ID": 6393,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.66,
        "Longitude (Decimal Degrees)": -62.8,
        "Latitude": 453930001,
        "Longitude": -624748009,
        "Elevation (m)": 28.9,
        "First Year": 1984,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2017,
        "MLY First Year": 1984,
        "MLY Last Year": 2006
      },
      {
        "Name": "MABOU",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203249",
        "Station ID": 6394,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -61.38,
        "Latitude": 460400000,
        "Longitude": -612300000,
        "Elevation (m)": 32,
        "First Year": 1986,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 2014,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "MACKAYS CORNER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203268",
        "Station ID": 6395,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -62.83,
        "Latitude": 454600000,
        "Longitude": -625000000,
        "Elevation (m)": 2.7,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "MAHONE BAY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203300",
        "Station ID": 6396,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 442800000,
        "Longitude": -642300000,
        "Elevation (m)": 25.9,
        "First Year": 1872,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1960,
        "MLY First Year": 1872,
        "MLY Last Year": 1960
      },
      {
        "Name": "MAITLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203320",
        "Station ID": 6397,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -63.5,
        "Latitude": 451900000,
        "Longitude": -633000000,
        "Elevation (m)": 15.2,
        "First Year": 1977,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1978,
        "MLY First Year": 1977,
        "MLY Last Year": 1978
      },
      {
        "Name": "MALAGASH POINT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203396",
        "Station ID": 6398,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.78,
        "Longitude (Decimal Degrees)": -63.28,
        "Latitude": 454700000,
        "Longitude": -631700000,
        "Elevation (m)": 7.6,
        "First Year": 1989,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2000,
        "MLY First Year": 1989,
        "MLY Last Year": 2000
      },
      {
        "Name": "MALAY FALLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203400",
        "Station ID": 6399,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -62.48,
        "Latitude": 445900000,
        "Longitude": -622900000,
        "Elevation (m)": 39.6,
        "First Year": 1950,
        "Last Year": 2000,
        "HLY First Year": 1999,
        "HLY Last Year": 2000,
        "DLY First Year": 1950,
        "DLY Last Year": 2000,
        "MLY First Year": 1950,
        "MLY Last Year": 2000
      },
      {
        "Name": "MALAY FALLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203405",
        "Station ID": 30668,
        "WMO ID": 73029,
        "TC ID": "XMY",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -62.48,
        "Latitude": 445900000,
        "Longitude": -622900000,
        "Elevation (m)": 39.6,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "MARGAREE FORKS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203422",
        "Station ID": 6400,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -61.08,
        "Latitude": 462200000,
        "Longitude": -610500000,
        "Elevation (m)": 15.2,
        "First Year": 1960,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1975,
        "MLY First Year": 1960,
        "MLY Last Year": 1975
      },
      {
        "Name": "MARGAREE FORKS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203423",
        "Station ID": 6401,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -61.1,
        "Latitude": 462000000,
        "Longitude": -610600000,
        "Elevation (m)": 22.9,
        "First Year": 1976,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2004,
        "MLY First Year": 1976,
        "MLY Last Year": 2004
      },
      {
        "Name": "MARGAREE HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203425",
        "Station ID": 6402,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -61.1,
        "Latitude": 462600000,
        "Longitude": -610600000,
        "Elevation (m)": 5.5,
        "First Year": 1958,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1971,
        "MLY First Year": 1958,
        "MLY Last Year": 1971
      },
      {
        "Name": "MARTOCK 35M",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203428",
        "Station ID": 49109,
        "WMO ID": null,
        "TC ID": "AMB",
        "Latitude (Decimal Degrees)": 44.94,
        "Longitude (Decimal Degrees)": -64.17,
        "Latitude": 445612002,
        "Longitude": -641015005,
        "Elevation (m)": 35,
        "First Year": 2010,
        "Last Year": 2011,
        "HLY First Year": 2010,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARTOCK 197M",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203429",
        "Station ID": 49108,
        "WMO ID": null,
        "TC ID": "AMC",
        "Latitude (Decimal Degrees)": 44.93,
        "Longitude (Decimal Degrees)": -64.16,
        "Latitude": 445547007,
        "Longitude": -640946006,
        "Elevation (m)": 197,
        "First Year": 2010,
        "Last Year": 2011,
        "HLY First Year": 2010,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARYVALE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203430",
        "Station ID": 6403,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -62.05,
        "Latitude": 454500000,
        "Longitude": -620300000,
        "Elevation (m)": 45.7,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "MAVILLETTE EXP ST",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203450",
        "Station ID": 6404,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.1,
        "Longitude (Decimal Degrees)": -66.18,
        "Latitude": 440600000,
        "Longitude": -661100000,
        "Elevation (m)": 30.5,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "MCNABS ISLAND (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203478",
        "Station ID": 7169,
        "WMO ID": null,
        "TC ID": "XMI",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -63.53,
        "Latitude": 443600000,
        "Longitude": -633200000,
        "Elevation (m)": 15.4,
        "First Year": 1999,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "METEGHAN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203498",
        "Station ID": 6405,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.18,
        "Longitude (Decimal Degrees)": -66.18,
        "Latitude": 441100000,
        "Longitude": -661100000,
        "Elevation (m)": 12.2,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "METEGHAN RIVER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203500",
        "Station ID": 6406,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -66.13,
        "Latitude": 441600000,
        "Longitude": -660800000,
        "Elevation (m)": 15.2,
        "First Year": 1937,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1986,
        "MLY First Year": 1937,
        "MLY Last Year": 1986
      },
      {
        "Name": "MIDDLEBORO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203510",
        "Station ID": 6407,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -63.57,
        "Latitude": 454600000,
        "Longitude": -633400000,
        "Elevation (m)": 30.5,
        "First Year": 1981,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2004,
        "MLY First Year": 1981,
        "MLY Last Year": 2004
      },
      {
        "Name": "MIDDLE CLYDE RIVER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203520",
        "Station ID": 6408,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.85,
        "Longitude (Decimal Degrees)": -65.52,
        "Latitude": 435100000,
        "Longitude": -653100000,
        "Elevation (m)": 53.3,
        "First Year": 1990,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1995,
        "MLY First Year": 1990,
        "MLY Last Year": 1995
      },
      {
        "Name": "MIDDLE MUSQUODOBOIT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203535",
        "Station ID": 6409,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -63.1,
        "Latitude": 450400000,
        "Longitude": -630600000,
        "Elevation (m)": 47.8,
        "First Year": 1961,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2019,
        "MLY First Year": 1961,
        "MLY Last Year": 2006
      },
      {
        "Name": "MIDDLETON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203550",
        "Station ID": 6410,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.95,
        "Longitude (Decimal Degrees)": -65.07,
        "Latitude": 445700000,
        "Longitude": -650400000,
        "Elevation (m)": 21.3,
        "First Year": 1914,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1914,
        "DLY Last Year": 1946,
        "MLY First Year": 1914,
        "MLY Last Year": 1946
      },
      {
        "Name": "MILFORD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203564",
        "Station ID": 6411,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -63.45,
        "Latitude": 450100000,
        "Longitude": -632700000,
        "Elevation (m)": 30.5,
        "First Year": 1981,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1982,
        "MLY First Year": 1981,
        "MLY Last Year": 1982
      },
      {
        "Name": "MILFORD STATION",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203567",
        "Station ID": 6924,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.03,
        "Longitude (Decimal Degrees)": -63.4,
        "Latitude": 450200000,
        "Longitude": -632400000,
        "Elevation (m)": 31.5,
        "First Year": 1993,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2000,
        "MLY First Year": 1993,
        "MLY Last Year": 2000
      },
      {
        "Name": "MILL VILLAGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203570",
        "Station ID": 6412,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.18,
        "Longitude (Decimal Degrees)": -64.67,
        "Latitude": 441100000,
        "Longitude": -644000000,
        "Elevation (m)": 15.2,
        "First Year": 1973,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1993,
        "MLY First Year": 1973,
        "MLY Last Year": 1993
      },
      {
        "Name": "MINASVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203578",
        "Station ID": 6956,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -63.82,
        "Latitude": 451700000,
        "Longitude": -634900000,
        "Elevation (m)": 61.6,
        "First Year": 1991,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1992,
        "MLY First Year": 1991,
        "MLY Last Year": 1992
      },
      {
        "Name": "MOUNT UNIACKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203600",
        "Station ID": 6413,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.9,
        "Longitude (Decimal Degrees)": -63.83,
        "Latitude": 445400000,
        "Longitude": -635000000,
        "Elevation (m)": 158.5,
        "First Year": 1919,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 2003,
        "MLY First Year": 1919,
        "MLY Last Year": 2003
      },
      {
        "Name": "NAPPAN CDA",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203700",
        "Station ID": 6414,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.77,
        "Longitude (Decimal Degrees)": -64.25,
        "Latitude": 454600000,
        "Longitude": -641500000,
        "Elevation (m)": 19.8,
        "First Year": 1890,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1890,
        "DLY Last Year": 2005,
        "MLY First Year": 1890,
        "MLY Last Year": 2005
      },
      {
        "Name": "NAPPAN AUTO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203702",
        "Station ID": 42083,
        "WMO ID": 71311,
        "TC ID": "XNP",
        "Latitude (Decimal Degrees)": 45.76,
        "Longitude (Decimal Degrees)": -64.24,
        "Latitude": 454534400,
        "Longitude": -641429200,
        "Elevation (m)": 19.8,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "NEILS HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203740",
        "Station ID": 6415,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -60.32,
        "Latitude": 464900000,
        "Longitude": -601900000,
        "Elevation (m)": 22.9,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "NEW GERMANY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203800",
        "Station ID": 6416,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.55,
        "Longitude (Decimal Degrees)": -64.7,
        "Latitude": 443300000,
        "Longitude": -644200000,
        "Elevation (m)": 78.6,
        "First Year": 1952,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1960,
        "MLY First Year": 1952,
        "MLY Last Year": 1960
      },
      {
        "Name": "NEW GLASGOW TRENTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8203905",
        "Station ID": 6417,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -62.62,
        "Latitude": 453700000,
        "Longitude": -623700000,
        "Elevation (m)": 93.3,
        "First Year": 1945,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1950,
        "MLY First Year": 1945,
        "MLY Last Year": 1950
      },
      {
        "Name": "NEW GRAFTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204000",
        "Station ID": 6418,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.42,
        "Longitude (Decimal Degrees)": -65.18,
        "Latitude": 442500000,
        "Longitude": -651100000,
        "Elevation (m)": null,
        "First Year": 1950,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1967,
        "MLY First Year": 1950,
        "MLY Last Year": 1967
      },
      {
        "Name": "ALDERSVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204020",
        "Station ID": 55218,
        "WMO ID": 73081,
        "TC ID": "ANR",
        "Latitude (Decimal Degrees)": 44.83,
        "Longitude (Decimal Degrees)": -64.51,
        "Latitude": 444936006,
        "Longitude": -643032005,
        "Elevation (m)": 237,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NOEL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204100",
        "Station ID": 6419,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -63.73,
        "Latitude": 451700000,
        "Longitude": -634400000,
        "Elevation (m)": 45.7,
        "First Year": 1934,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1960,
        "MLY First Year": 1934,
        "MLY Last Year": 1960
      },
      {
        "Name": "NOEL SHORE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204124",
        "Station ID": 6420,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.32,
        "Longitude (Decimal Degrees)": -63.65,
        "Latitude": 451900000,
        "Longitude": -633900000,
        "Elevation (m)": 40.8,
        "First Year": 1979,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1980,
        "MLY First Year": 1979,
        "MLY Last Year": 1980
      },
      {
        "Name": "NOEL SHORE EXP ST",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204125",
        "Station ID": 6421,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.3,
        "Longitude (Decimal Degrees)": -63.65,
        "Latitude": 451800000,
        "Longitude": -633900000,
        "Elevation (m)": 91.4,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "NORTHEAST MARGAREE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204150",
        "Station ID": 6422,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.33,
        "Longitude (Decimal Degrees)": -60.97,
        "Latitude": 462000000,
        "Longitude": -605800000,
        "Elevation (m)": 83.8,
        "First Year": 1955,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1974,
        "MLY First Year": 1955,
        "MLY Last Year": 1974
      },
      {
        "Name": "NORTHEAST MARGAREE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204151",
        "Station ID": 6423,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -61,
        "Latitude": 462100000,
        "Longitude": -610000000,
        "Elevation (m)": 31.1,
        "First Year": 1955,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1987,
        "MLY First Year": 1955,
        "MLY Last Year": 1987
      },
      {
        "Name": "NORTHEAST MARGAREE (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204154",
        "Station ID": 10661,
        "WMO ID": 73027,
        "TC ID": "WNS",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -60.98,
        "Latitude": 462206090,
        "Longitude": -605833080,
        "Elevation (m)": 54,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH KINGSTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204158",
        "Station ID": 6925,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -64.97,
        "Latitude": 450100000,
        "Longitude": -645800000,
        "Elevation (m)": 70.1,
        "First Year": 1993,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1997,
        "MLY First Year": 1993,
        "MLY Last Year": 1997
      },
      {
        "Name": "NORTH MOUNTAIN CS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204161",
        "Station ID": 27592,
        "WMO ID": 71604,
        "TC ID": "XNM",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -60.67,
        "Latitude": 464901000,
        "Longitude": -604020000,
        "Elevation (m)": 439.4,
        "First Year": 1998,
        "Last Year": 2022,
        "HLY First Year": 1998,
        "HLY Last Year": 2022,
        "DLY First Year": 1998,
        "DLY Last Year": 2022,
        "MLY First Year": 1998,
        "MLY Last Year": 2007
      },
      {
        "Name": "NORTH SYDNEY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204165",
        "Station ID": 6424,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -60.27,
        "Latitude": 461200000,
        "Longitude": -601600000,
        "Elevation (m)": 3,
        "First Year": 1973,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1974,
        "MLY First Year": 1973,
        "MLY Last Year": 1974
      },
      {
        "Name": "OSBORNE HEAD DND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204190",
        "Station ID": 43404,
        "WMO ID": 71324,
        "TC ID": "AOS",
        "Latitude (Decimal Degrees)": 44.61,
        "Longitude (Decimal Degrees)": -63.42,
        "Latitude": 443646002,
        "Longitude": -632523007,
        "Elevation (m)": 30,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "UPPER STEWIACKE RCS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204193",
        "Station ID": 44363,
        "WMO ID": 71753,
        "TC ID": "AOH",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -63.06,
        "Latitude": 451359000,
        "Longitude": -630319000,
        "Elevation (m)": 23.5,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "UPPER STEWIACKE SNOW",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204195",
        "Station ID": 50008,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.23,
        "Longitude (Decimal Degrees)": -63.05,
        "Latitude": 451354300,
        "Longitude": -630303800,
        "Elevation (m)": 23.5,
        "First Year": 2012,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2012,
        "DLY Last Year": 2012,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "OVERTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204197",
        "Station ID": 6425,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -66.15,
        "Latitude": 435000000,
        "Longitude": -660900000,
        "Elevation (m)": 15.2,
        "First Year": 1972,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1975,
        "MLY First Year": 1972,
        "MLY Last Year": 1975
      },
      {
        "Name": "OXFORD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204200",
        "Station ID": 6426,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.73,
        "Longitude (Decimal Degrees)": -63.87,
        "Latitude": 454400000,
        "Longitude": -635200000,
        "Elevation (m)": 12.8,
        "First Year": 1953,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1979,
        "MLY First Year": 1953,
        "MLY Last Year": 1979
      },
      {
        "Name": "PARADISE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204300",
        "Station ID": 6427,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.83,
        "Longitude (Decimal Degrees)": -65.23,
        "Latitude": 445000000,
        "Longitude": -651400000,
        "Elevation (m)": 45.7,
        "First Year": 1950,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1997,
        "MLY First Year": 1950,
        "MLY Last Year": 1997
      },
      {
        "Name": "PARRSBORO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204400",
        "Station ID": 6428,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.4,
        "Longitude (Decimal Degrees)": -64.33,
        "Latitude": 452400000,
        "Longitude": -642000000,
        "Elevation (m)": 24.3,
        "First Year": 1897,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1897,
        "DLY Last Year": 2002,
        "MLY First Year": 1897,
        "MLY Last Year": 2002
      },
      {
        "Name": "PARRSBORO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204402",
        "Station ID": 43183,
        "WMO ID": 71493,
        "TC ID": "APR",
        "Latitude (Decimal Degrees)": 45.41,
        "Longitude (Decimal Degrees)": -64.35,
        "Latitude": 452448012,
        "Longitude": -642046074,
        "Elevation (m)": 30.89,
        "First Year": 2004,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "PENTZ",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204405",
        "Station ID": 6429,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.3,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 441800000,
        "Longitude": -642300000,
        "Elevation (m)": 14.9,
        "First Year": 1978,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1981,
        "MLY First Year": 1978,
        "MLY Last Year": 1981
      },
      {
        "Name": "PICTOU",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204420",
        "Station ID": 6430,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -62.68,
        "Latitude": 454200000,
        "Longitude": -624100000,
        "Elevation (m)": 24.4,
        "First Year": 1870,
        "Last Year": 1906,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1906,
        "MLY First Year": 1870,
        "MLY Last Year": 1906
      },
      {
        "Name": "PICTOU",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204421",
        "Station ID": 6431,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.68,
        "Longitude (Decimal Degrees)": -62.7,
        "Latitude": 454100000,
        "Longitude": -624200000,
        "Elevation (m)": 22.9,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": 1966,
        "MLY Last Year": 1966
      },
      {
        "Name": "PICTOU LYONS BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204423",
        "Station ID": 6432,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.67,
        "Longitude (Decimal Degrees)": -62.78,
        "Latitude": 454000000,
        "Longitude": -624700000,
        "Elevation (m)": 15.2,
        "First Year": 1968,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1972,
        "MLY First Year": 1969,
        "MLY Last Year": 1972
      },
      {
        "Name": "PIRATE HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204425",
        "Station ID": 6433,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -61.38,
        "Latitude": 453500000,
        "Longitude": -612300000,
        "Elevation (m)": 30.5,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "PLEASANT BAY GRAND ANSE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204450",
        "Station ID": 6434,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.82,
        "Longitude (Decimal Degrees)": -60.77,
        "Latitude": 464900000,
        "Longitude": -604600000,
        "Elevation (m)": 28.9,
        "First Year": 1955,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1996,
        "MLY First Year": 1955,
        "MLY Last Year": 1996
      },
      {
        "Name": "POCKWOCK LAKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204453",
        "Station ID": 6435,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.77,
        "Longitude (Decimal Degrees)": -63.83,
        "Latitude": 444600000,
        "Longitude": -635000000,
        "Elevation (m)": 164.6,
        "First Year": 1979,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2022,
        "MLY First Year": 1979,
        "MLY Last Year": 2006
      },
      {
        "Name": "POINT EDWARD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204455",
        "Station ID": 6436,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -60.23,
        "Latitude": 460900000,
        "Longitude": -601400000,
        "Elevation (m)": 15.2,
        "First Year": 1966,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1970,
        "MLY First Year": 1966,
        "MLY Last Year": 1970
      },
      {
        "Name": "POINT ACONI",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204456",
        "Station ID": 6437,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -60.33,
        "Latitude": 461900000,
        "Longitude": -602000000,
        "Elevation (m)": 11.7,
        "First Year": 1990,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2013,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "POINT TUPPER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204457",
        "Station ID": 6438,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.58,
        "Longitude (Decimal Degrees)": -61.33,
        "Latitude": 453500000,
        "Longitude": -612000000,
        "Elevation (m)": 29,
        "First Year": 1970,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1979,
        "MLY First Year": 1970,
        "MLY Last Year": 1979
      },
      {
        "Name": "POINTE DE L'EGLISE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204459",
        "Station ID": 6439,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.33,
        "Longitude (Decimal Degrees)": -66.12,
        "Latitude": 442000000,
        "Longitude": -660700000,
        "Elevation (m)": 4.9,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "PORT DUFFERIN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204477",
        "Station ID": 6440,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -62.38,
        "Latitude": 445500000,
        "Longitude": -622300000,
        "Elevation (m)": 18.3,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1989,
        "MLY First Year": 1987,
        "MLY Last Year": 1989
      },
      {
        "Name": "PORT HASTINGS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204480",
        "Station ID": 6441,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -61.4,
        "Latitude": 453800000,
        "Longitude": -612400000,
        "Elevation (m)": 23.1,
        "First Year": 1874,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1989,
        "MLY First Year": 1874,
        "MLY Last Year": 1989
      },
      {
        "Name": "PORT HAWKESBURY A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204491",
        "Station ID": 6442,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.65,
        "Longitude (Decimal Degrees)": -61.38,
        "Latitude": 453900000,
        "Longitude": -612300000,
        "Elevation (m)": 114.9,
        "First Year": 1985,
        "Last Year": 2004,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": 1985,
        "DLY Last Year": 2004,
        "MLY First Year": 1985,
        "MLY Last Year": 2004
      },
      {
        "Name": "HAWKESBURY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204493",
        "Station ID": 44523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -61.37,
        "Latitude": 453700000,
        "Longitude": -612200000,
        "Elevation (m)": 45.7,
        "First Year": 2006,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2006,
        "DLY Last Year": 2009,
        "MLY First Year": 2006,
        "MLY Last Year": 2006
      },
      {
        "Name": "PORT HAWKESBURY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204495",
        "Station ID": 48668,
        "WMO ID": 71041,
        "TC ID": "YPD",
        "Latitude (Decimal Degrees)": 45.66,
        "Longitude (Decimal Degrees)": -61.37,
        "Latitude": 453924000,
        "Longitude": -612205000,
        "Elevation (m)": 114.9,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PORT HOOD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204500",
        "Station ID": 6443,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.98,
        "Longitude (Decimal Degrees)": -61.53,
        "Latitude": 455900000,
        "Longitude": -613200000,
        "Elevation (m)": 27.4,
        "First Year": 1950,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1993,
        "MLY First Year": 1950,
        "MLY Last Year": 1993
      },
      {
        "Name": "PORT MORIEN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204510",
        "Station ID": 6444,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -59.87,
        "Latitude": 460800000,
        "Longitude": -595200000,
        "Elevation (m)": null,
        "First Year": 1873,
        "Last Year": 1906,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1873,
        "DLY Last Year": 1906,
        "MLY First Year": 1873,
        "MLY Last Year": 1906
      },
      {
        "Name": "PORT MORIEN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204512",
        "Station ID": 6445,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -59.9,
        "Latitude": 460800000,
        "Longitude": -595400000,
        "Elevation (m)": 45.7,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "PORT MORIEN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204513",
        "Station ID": 6446,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.12,
        "Longitude (Decimal Degrees)": -59.97,
        "Latitude": 460700000,
        "Longitude": -595800000,
        "Elevation (m)": 48.8,
        "First Year": 1976,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 1976,
        "MLY First Year": 1976,
        "MLY Last Year": 1976
      },
      {
        "Name": "PUGWASH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204525",
        "Station ID": 6447,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.84,
        "Longitude (Decimal Degrees)": -63.66,
        "Latitude": 455036000,
        "Longitude": -633919000,
        "Elevation (m)": 4.6,
        "First Year": 1965,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2014,
        "MLY First Year": 1965,
        "MLY Last Year": 2006
      },
      {
        "Name": "RAWDON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204550",
        "Station ID": 6448,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -63.78,
        "Latitude": 450300000,
        "Longitude": -634700000,
        "Elevation (m)": 182.9,
        "First Year": 1956,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1976,
        "MLY First Year": 1957,
        "MLY Last Year": 1976
      },
      {
        "Name": "RIVER DENYS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204565",
        "Station ID": 6449,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.8,
        "Longitude (Decimal Degrees)": -61.22,
        "Latitude": 454800000,
        "Longitude": -611300000,
        "Elevation (m)": 27.4,
        "First Year": 1966,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1988,
        "MLY First Year": 1966,
        "MLY Last Year": 1988
      },
      {
        "Name": "RIVER HEBERT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204570",
        "Station ID": 6450,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.6,
        "Longitude (Decimal Degrees)": -64.35,
        "Latitude": 453600000,
        "Longitude": -642100000,
        "Elevation (m)": 24.4,
        "First Year": 1962,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1980,
        "MLY First Year": 1962,
        "MLY Last Year": 1980
      },
      {
        "Name": "RIVER JOHN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204577",
        "Station ID": 6926,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -63,
        "Latitude": 454300000,
        "Longitude": -630000000,
        "Elevation (m)": 57.9,
        "First Year": 1992,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1995,
        "MLY First Year": 1992,
        "MLY Last Year": 1995
      },
      {
        "Name": "RIVER JOHN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204578",
        "Station ID": 6451,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -63.12,
        "Latitude": 454500000,
        "Longitude": -630700000,
        "Elevation (m)": 13.1,
        "First Year": 1979,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1986,
        "MLY First Year": 1979,
        "MLY Last Year": 1986
      },
      {
        "Name": "ROSEWAY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204600",
        "Station ID": 6452,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.78,
        "Longitude (Decimal Degrees)": -65.35,
        "Latitude": 434700000,
        "Longitude": -652100000,
        "Elevation (m)": 15.2,
        "First Year": 1950,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1995,
        "MLY First Year": 1950,
        "MLY Last Year": 1995
      },
      {
        "Name": "RUTH FALLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204620",
        "Station ID": 6453,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.93,
        "Longitude (Decimal Degrees)": -62.5,
        "Latitude": 445600000,
        "Longitude": -623000000,
        "Elevation (m)": 7.6,
        "First Year": 1961,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1986,
        "MLY First Year": 1961,
        "MLY Last Year": 1986
      },
      {
        "Name": "SABLE ISLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204700",
        "Station ID": 6454,
        "WMO ID": 71600,
        "TC ID": "ESA",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -60.01,
        "Latitude": 435556000,
        "Longitude": -600034000,
        "Elevation (m)": 5,
        "First Year": 1891,
        "Last Year": 2017,
        "HLY First Year": 1953,
        "HLY Last Year": 2016,
        "DLY First Year": 1891,
        "DLY Last Year": 2017,
        "MLY First Year": 1891,
        "MLY Last Year": 2007
      },
      {
        "Name": "SABLE ISLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204701",
        "Station ID": 26864,
        "WMO ID": null,
        "TC ID": "YSA",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -60,
        "Latitude": 435600000,
        "Longitude": -600000000,
        "Elevation (m)": 4,
        "First Year": 1995,
        "Last Year": 2006,
        "HLY First Year": 1995,
        "HLY Last Year": 1998,
        "DLY First Year": 2002,
        "DLY Last Year": 2006,
        "MLY First Year": 2002,
        "MLY Last Year": 2006
      },
      {
        "Name": "SABLE ISLAND EAST END",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204702",
        "Station ID": 6455,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.97,
        "Longitude (Decimal Degrees)": -59.77,
        "Latitude": 435800000,
        "Longitude": -594600000,
        "Elevation (m)": 7.6,
        "First Year": 1883,
        "Last Year": 1930,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1930,
        "MLY First Year": 1883,
        "MLY Last Year": 1930
      },
      {
        "Name": "SABLE ISLAND A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204703",
        "Station ID": 52202,
        "WMO ID": 71595,
        "TC ID": "WSA",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -59.96,
        "Latitude": 435546000,
        "Longitude": -595735000,
        "Elevation (m)": 1.2,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SABLE ISLAND 1",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204707",
        "Station ID": 10848,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -60.02,
        "Latitude": 435600000,
        "Longitude": -600100000,
        "Elevation (m)": 4,
        "First Year": 1992,
        "Last Year": 1996,
        "HLY First Year": 1994,
        "HLY Last Year": 1994,
        "DLY First Year": 1992,
        "DLY Last Year": 1996,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SABLE ISLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204708",
        "Station ID": 54639,
        "WMO ID": 73025,
        "TC ID": "ASB",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -60.01,
        "Latitude": 435553000,
        "Longitude": -600030000,
        "Elevation (m)": 1.2,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST MARGARET'S BAY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204800",
        "Station ID": 6456,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.7,
        "Longitude (Decimal Degrees)": -63.9,
        "Latitude": 444200000,
        "Longitude": -635400000,
        "Elevation (m)": 17.4,
        "First Year": 1922,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1922,
        "DLY Last Year": 2021,
        "MLY First Year": 1922,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST PAUL ISLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204900",
        "Station ID": 6457,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.2,
        "Longitude (Decimal Degrees)": -60.15,
        "Latitude": 471200000,
        "Longitude": -600900000,
        "Elevation (m)": 31.7,
        "First Year": 1928,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1928,
        "DLY Last Year": 1956,
        "MLY First Year": 1929,
        "MLY Last Year": 1956
      },
      {
        "Name": "ST PAUL ISLAND (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8204909",
        "Station ID": 9033,
        "WMO ID": 71418,
        "TC ID": "WEF",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -60.14,
        "Latitude": 471358002,
        "Longitude": -600840001,
        "Elevation (m)": 27,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SALMON HOLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205000",
        "Station ID": 6458,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.93,
        "Longitude (Decimal Degrees)": -64.03,
        "Latitude": 445600000,
        "Longitude": -640200000,
        "Elevation (m)": 83.8,
        "First Year": 1938,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 2013,
        "MLY First Year": 1938,
        "MLY Last Year": 2005
      },
      {
        "Name": "SALT SPRINGS EXP ST",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205050",
        "Station ID": 6459,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.53,
        "Longitude (Decimal Degrees)": -62.95,
        "Latitude": 453200000,
        "Longitude": -625700000,
        "Elevation (m)": 137.2,
        "First Year": 1957,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1958,
        "MLY First Year": 1957,
        "MLY Last Year": 1958
      },
      {
        "Name": "SAMBRO ISLAND",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205055",
        "Station ID": 6460,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.43,
        "Longitude (Decimal Degrees)": -63.57,
        "Latitude": 442600000,
        "Longitude": -633400000,
        "Elevation (m)": 5.8,
        "First Year": 1966,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1969,
        "MLY First Year": 1966,
        "MLY Last Year": 1969
      },
      {
        "Name": "SANDY COVE NRC",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205062",
        "Station ID": 6461,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.47,
        "Longitude (Decimal Degrees)": -63.57,
        "Latitude": 442800000,
        "Longitude": -633400000,
        "Elevation (m)": 10.1,
        "First Year": 1975,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 2001,
        "MLY First Year": 1975,
        "MLY Last Year": 2001
      },
      {
        "Name": "SAULNIERVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205070",
        "Station ID": 6462,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.27,
        "Longitude (Decimal Degrees)": -66.12,
        "Latitude": 441600000,
        "Longitude": -660700000,
        "Elevation (m)": 45.7,
        "First Year": 1916,
        "Last Year": 1937,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1916,
        "DLY Last Year": 1937,
        "MLY First Year": 1916,
        "MLY Last Year": 1937
      },
      {
        "Name": "SEAFOAM",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205079",
        "Station ID": 6463,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.78,
        "Longitude (Decimal Degrees)": -62.97,
        "Latitude": 454700000,
        "Longitude": -625800000,
        "Elevation (m)": 16.8,
        "First Year": 1984,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1989,
        "MLY First Year": 1984,
        "MLY Last Year": 1989
      },
      {
        "Name": "SHARPE BROOK IHD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205085",
        "Station ID": 6464,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -64.63,
        "Latitude": 450100000,
        "Longitude": -643800000,
        "Elevation (m)": 137.2,
        "First Year": 1967,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1978,
        "MLY First Year": 1967,
        "MLY Last Year": 1978
      },
      {
        "Name": "SHEARWATER A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205090",
        "Station ID": 6465,
        "WMO ID": 71601,
        "TC ID": "YAW",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -63.5,
        "Latitude": 443800000,
        "Longitude": -633000000,
        "Elevation (m)": 44,
        "First Year": 1944,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1944,
        "DLY Last Year": 2007,
        "MLY First Year": 1944,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHEARWATER AUTO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205091",
        "Station ID": 27223,
        "WMO ID": null,
        "TC ID": "WAW",
        "Latitude (Decimal Degrees)": 44.64,
        "Longitude (Decimal Degrees)": -63.51,
        "Latitude": 443811000,
        "Longitude": -633024000,
        "Elevation (m)": 53,
        "First Year": 1996,
        "Last Year": 2010,
        "HLY First Year": 1996,
        "HLY Last Year": 2010,
        "DLY First Year": 1997,
        "DLY Last Year": 2010,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "SHEARWATER RCS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205092",
        "Station ID": 47187,
        "WMO ID": 71264,
        "TC ID": "AAW",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -63.51,
        "Latitude": 443747000,
        "Longitude": -633048000,
        "Elevation (m)": 24,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SHEARWATER JETTY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205093",
        "Station ID": 10945,
        "WMO ID": 71330,
        "TC ID": "WZU",
        "Latitude (Decimal Degrees)": 44.63,
        "Longitude (Decimal Degrees)": -63.52,
        "Latitude": 443740000,
        "Longitude": -633124000,
        "Elevation (m)": 5.5,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SHEET HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205100",
        "Station ID": 6466,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.92,
        "Longitude (Decimal Degrees)": -62.48,
        "Latitude": 445500000,
        "Longitude": -622900000,
        "Elevation (m)": 9.1,
        "First Year": 1950,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1962,
        "MLY First Year": 1950,
        "MLY Last Year": 1962
      },
      {
        "Name": "SHEFFIELD MILLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205120",
        "Station ID": 6467,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -64.5,
        "Latitude": 450800000,
        "Longitude": -643000000,
        "Elevation (m)": 30.2,
        "First Year": 1958,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1993,
        "MLY First Year": 1958,
        "MLY Last Year": 1993
      },
      {
        "Name": "SHELBURNE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205126",
        "Station ID": 6468,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.72,
        "Longitude (Decimal Degrees)": -65.25,
        "Latitude": 434300000,
        "Longitude": -651500000,
        "Elevation (m)": 29.9,
        "First Year": 1972,
        "Last Year": 1988,
        "HLY First Year": 1981,
        "HLY Last Year": 1988,
        "DLY First Year": 1972,
        "DLY Last Year": 1988,
        "MLY First Year": 1972,
        "MLY Last Year": 1988
      },
      {
        "Name": "SHELBURNE SANDY POINT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205130",
        "Station ID": 54603,
        "WMO ID": 73015,
        "TC ID": "ESB",
        "Latitude (Decimal Degrees)": 43.7,
        "Longitude (Decimal Degrees)": -65.32,
        "Latitude": 434200000,
        "Longitude": -651900000,
        "Elevation (m)": 46.03,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SHUNACADIE BEACH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205148",
        "Station ID": 6469,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.02,
        "Longitude (Decimal Degrees)": -60.67,
        "Latitude": 460100000,
        "Longitude": -604000000,
        "Elevation (m)": 1,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "SOUTH ALTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205170",
        "Station ID": 6470,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.07,
        "Longitude (Decimal Degrees)": -64.53,
        "Latitude": 450400000,
        "Longitude": -643200000,
        "Elevation (m)": null,
        "First Year": 1912,
        "Last Year": 1933,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1912,
        "DLY Last Year": 1933,
        "MLY First Year": 1912,
        "MLY Last Year": 1933
      },
      {
        "Name": "SOUTH HIGHLANDS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205180",
        "Station ID": 6471,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -61.38,
        "Latitude": 461000000,
        "Longitude": -612300000,
        "Elevation (m)": 320,
        "First Year": 1958,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1960,
        "MLY First Year": 1958,
        "MLY Last Year": 1960
      },
      {
        "Name": "SOUTH MOUNTAIN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205185",
        "Station ID": 26969,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.02,
        "Longitude (Decimal Degrees)": -64.68,
        "Latitude": 450100000,
        "Longitude": -644100000,
        "Elevation (m)": 134.7,
        "First Year": 1994,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1994,
        "DLY Last Year": 2006,
        "MLY First Year": 1994,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOUTH SIDE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205192",
        "Station ID": 6472,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.45,
        "Longitude (Decimal Degrees)": -65.6,
        "Latitude": 432700000,
        "Longitude": -653600000,
        "Elevation (m)": 4,
        "First Year": 1988,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1992,
        "MLY First Year": 1988,
        "MLY Last Year": 1992
      },
      {
        "Name": "SOUTH SIDE HARBOUR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205193",
        "Station ID": 27282,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -61.9,
        "Latitude": 453700000,
        "Longitude": -615400000,
        "Elevation (m)": 30,
        "First Year": 1997,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1997,
        "DLY Last Year": 2011,
        "MLY First Year": 1997,
        "MLY Last Year": 2006
      },
      {
        "Name": "SPRINGFIELD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205200",
        "Station ID": 6473,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.67,
        "Longitude (Decimal Degrees)": -64.85,
        "Latitude": 444000000,
        "Longitude": -645100000,
        "Elevation (m)": 167.3,
        "First Year": 1919,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 2003,
        "MLY First Year": 1919,
        "MLY Last Year": 2003
      },
      {
        "Name": "SPRINGHILL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205215",
        "Station ID": 6474,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -64.08,
        "Latitude": 453700000,
        "Longitude": -640500000,
        "Elevation (m)": 182.9,
        "First Year": 1918,
        "Last Year": 1929,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1918,
        "DLY Last Year": 1929,
        "MLY First Year": 1918,
        "MLY Last Year": 1929
      },
      {
        "Name": "SPRINGHILL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205217",
        "Station ID": 6475,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -64.07,
        "Latitude": 453700000,
        "Longitude": -640400000,
        "Elevation (m)": 170.1,
        "First Year": 1982,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1997,
        "MLY First Year": 1982,
        "MLY Last Year": 1997
      },
      {
        "Name": "SPRUCE HILL LAKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205300",
        "Station ID": 6476,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.58,
        "Longitude (Decimal Degrees)": -63.65,
        "Latitude": 443500000,
        "Longitude": -633900000,
        "Elevation (m)": 107.6,
        "First Year": 1945,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1977,
        "MLY First Year": 1945,
        "MLY Last Year": 1977
      },
      {
        "Name": "SPRYFIELD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205315",
        "Station ID": 6477,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.6,
        "Longitude (Decimal Degrees)": -63.6,
        "Latitude": 443600000,
        "Longitude": -633600000,
        "Elevation (m)": 45.7,
        "First Year": 1973,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1973,
        "MLY First Year": 1973,
        "MLY Last Year": 1973
      },
      {
        "Name": "STELLARTON",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205400",
        "Station ID": 6478,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -62.65,
        "Latitude": 453400000,
        "Longitude": -623900000,
        "Elevation (m)": 7.6,
        "First Year": 1940,
        "Last Year": 1959,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1940,
        "DLY Last Year": 1959,
        "MLY First Year": 1941,
        "MLY Last Year": 1959
      },
      {
        "Name": "STELLARTON LOURDES",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205401",
        "Station ID": 6479,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.57,
        "Longitude (Decimal Degrees)": -62.65,
        "Latitude": 453400000,
        "Longitude": -623900000,
        "Elevation (m)": 10.7,
        "First Year": 1952,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1976,
        "MLY First Year": 1952,
        "MLY Last Year": 1976
      },
      {
        "Name": "STEWIACKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205490",
        "Station ID": 6480,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.13,
        "Longitude (Decimal Degrees)": -63.33,
        "Latitude": 450800000,
        "Longitude": -632000000,
        "Elevation (m)": 45.7,
        "First Year": 1962,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1966,
        "MLY First Year": 1962,
        "MLY Last Year": 1966
      },
      {
        "Name": "STILLWATER",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205600",
        "Station ID": 6481,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.18,
        "Longitude (Decimal Degrees)": -62,
        "Latitude": 451100000,
        "Longitude": -620000000,
        "Elevation (m)": 17.1,
        "First Year": 1915,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 1979,
        "MLY First Year": 1915,
        "MLY Last Year": 1979
      },
      {
        "Name": "STILLWATER SHERBROOKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205601",
        "Station ID": 6482,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.14,
        "Longitude (Decimal Degrees)": -61.98,
        "Latitude": 450836020,
        "Longitude": -615857040,
        "Elevation (m)": 14,
        "First Year": 1967,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2004,
        "MLY First Year": 1967,
        "MLY Last Year": 2004
      },
      {
        "Name": "SUGAR CAMP BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205623",
        "Station ID": 6483,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -61.32,
        "Latitude": 454200000,
        "Longitude": -611900000,
        "Elevation (m)": 30.5,
        "First Year": 1988,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1992,
        "MLY First Year": 1988,
        "MLY Last Year": 1992
      },
      {
        "Name": "SUMMERVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205650",
        "Station ID": 6484,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.12,
        "Longitude (Decimal Degrees)": -64.18,
        "Latitude": 450700000,
        "Longitude": -641100000,
        "Elevation (m)": 38.1,
        "First Year": 1965,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2004,
        "MLY First Year": 1965,
        "MLY Last Year": 2004
      },
      {
        "Name": "SYDNEY CCR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205697",
        "Station ID": 10970,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -60.16,
        "Latitude": 461003000,
        "Longitude": -600953000,
        "Elevation (m)": 88,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SYDNEY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205698",
        "Station ID": 6485,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.15,
        "Longitude (Decimal Degrees)": -60.2,
        "Latitude": 460900000,
        "Longitude": -601200000,
        "Elevation (m)": 14.6,
        "First Year": 1870,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1941,
        "MLY First Year": 1870,
        "MLY Last Year": 1941
      },
      {
        "Name": "SYDNEY A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205700",
        "Station ID": 6486,
        "WMO ID": 71707,
        "TC ID": "YQY",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -60.05,
        "Latitude": 461000000,
        "Longitude": -600253300,
        "Elevation (m)": 61.9,
        "First Year": 1941,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1941,
        "DLY Last Year": 2014,
        "MLY First Year": 1941,
        "MLY Last Year": 2014
      },
      {
        "Name": "SYDNEY A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205701",
        "Station ID": 52518,
        "WMO ID": 71707,
        "TC ID": "YQY",
        "Latitude (Decimal Degrees)": 46.16,
        "Longitude (Decimal Degrees)": -60.05,
        "Latitude": 460941000,
        "Longitude": -600253000,
        "Elevation (m)": 61.9,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SYDNEY CS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205702",
        "Station ID": 44503,
        "WMO ID": 71758,
        "TC ID": "AQY",
        "Latitude (Decimal Degrees)": 46.16,
        "Longitude (Decimal Degrees)": -60.04,
        "Latitude": 460948000,
        "Longitude": -600230000,
        "Elevation (m)": 62.5,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "SYDNEY A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205703",
        "Station ID": 54281,
        "WMO ID": null,
        "TC ID": "YQY",
        "Latitude (Decimal Degrees)": 46.16,
        "Longitude (Decimal Degrees)": -60.05,
        "Latitude": 460941000,
        "Longitude": -600253000,
        "Elevation (m)": 61.9,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TATAMAGOUCHE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205774",
        "Station ID": 6927,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.68,
        "Longitude (Decimal Degrees)": -63.23,
        "Latitude": 454100000,
        "Longitude": -631400000,
        "Elevation (m)": 18,
        "First Year": 1993,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2021,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "TATAMAGOUCHE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205775",
        "Station ID": 6487,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.75,
        "Longitude (Decimal Degrees)": -63.37,
        "Latitude": 454500000,
        "Longitude": -632200000,
        "Elevation (m)": 7.6,
        "First Year": 1966,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1978,
        "MLY First Year": 1966,
        "MLY Last Year": 1978
      },
      {
        "Name": "TIMBERLEA",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205800",
        "Station ID": 6488,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.67,
        "Longitude (Decimal Degrees)": -63.75,
        "Latitude": 444000000,
        "Longitude": -634500000,
        "Elevation (m)": 83.8,
        "First Year": 1946,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1946,
        "DLY Last Year": 1962,
        "MLY First Year": 1948,
        "MLY Last Year": 1963
      },
      {
        "Name": "TRACADIE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205895",
        "Station ID": 41575,
        "WMO ID": 71308,
        "TC ID": "XTD",
        "Latitude (Decimal Degrees)": 45.61,
        "Longitude (Decimal Degrees)": -61.68,
        "Latitude": 453630080,
        "Longitude": -614047020,
        "Elevation (m)": 66.67,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "TRAFALGAR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205900",
        "Station ID": 6489,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.28,
        "Longitude (Decimal Degrees)": -62.67,
        "Latitude": 451700000,
        "Longitude": -624000000,
        "Elevation (m)": 152.4,
        "First Year": 1919,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1919,
        "DLY Last Year": 1981,
        "MLY First Year": 1919,
        "MLY Last Year": 1981
      },
      {
        "Name": "TRENTON MUNICIPAL A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205950",
        "Station ID": 27868,
        "WMO ID": null,
        "TC ID": "YTN",
        "Latitude (Decimal Degrees)": 45.61,
        "Longitude (Decimal Degrees)": -62.62,
        "Latitude": 453641000,
        "Longitude": -623719000,
        "Elevation (m)": 97.23,
        "First Year": 1999,
        "Last Year": 2005,
        "HLY First Year": 1999,
        "HLY Last Year": 2000,
        "DLY First Year": 1999,
        "DLY Last Year": 2005,
        "MLY First Year": 1999,
        "MLY Last Year": 2005
      },
      {
        "Name": "TRURO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205988",
        "Station ID": 6490,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.35,
        "Longitude (Decimal Degrees)": -63.3,
        "Latitude": 452100000,
        "Longitude": -631800000,
        "Elevation (m)": 12.2,
        "First Year": 1872,
        "Last Year": 1915,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1915,
        "MLY First Year": 1872,
        "MLY Last Year": 1915
      },
      {
        "Name": "TRURO",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205990",
        "Station ID": 6491,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -63.27,
        "Latitude": 452200000,
        "Longitude": -631600000,
        "Elevation (m)": 39.9,
        "First Year": 1960,
        "Last Year": 2002,
        "HLY First Year": 1960,
        "HLY Last Year": 1986,
        "DLY First Year": 1960,
        "DLY Last Year": 2002,
        "MLY First Year": 1960,
        "MLY Last Year": 2002
      },
      {
        "Name": "TRURO (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8205992",
        "Station ID": 7162,
        "WMO ID": null,
        "TC ID": "WUR",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -63.27,
        "Latitude": 452200000,
        "Longitude": -631600000,
        "Elevation (m)": 39.9,
        "First Year": 1992,
        "Last Year": 2005,
        "HLY First Year": 1994,
        "HLY Last Year": 2005,
        "DLY First Year": 1992,
        "DLY Last Year": 2005,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TRURO NSAC",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206000",
        "Station ID": 6492,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.37,
        "Longitude (Decimal Degrees)": -63.3,
        "Latitude": 452200000,
        "Longitude": -631800000,
        "Elevation (m)": 23.5,
        "First Year": 1910,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1966,
        "MLY First Year": 1910,
        "MLY Last Year": 1966
      },
      {
        "Name": "TUSKET",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206100",
        "Station ID": 6493,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.88,
        "Longitude (Decimal Degrees)": -65.98,
        "Latitude": 435300000,
        "Longitude": -655900000,
        "Elevation (m)": 9.1,
        "First Year": 1950,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 2007,
        "MLY First Year": 1950,
        "MLY Last Year": 2006
      },
      {
        "Name": "UPPER MUSQUODOBOIT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206180",
        "Station ID": 6494,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.15,
        "Longitude (Decimal Degrees)": -62.93,
        "Latitude": 450900000,
        "Longitude": -625600000,
        "Elevation (m)": 61,
        "First Year": 1967,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1971,
        "MLY First Year": 1967,
        "MLY Last Year": 1971
      },
      {
        "Name": "UPPER STEWIACKE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206200",
        "Station ID": 6495,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -63,
        "Latitude": 451300000,
        "Longitude": -630000000,
        "Elevation (m)": 22.9,
        "First Year": 1915,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1915,
        "DLY Last Year": 2005,
        "MLY First Year": 1915,
        "MLY Last Year": 2005
      },
      {
        "Name": "URBANIA",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206208",
        "Station ID": 6496,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -63.43,
        "Latitude": 451300000,
        "Longitude": -632600000,
        "Elevation (m)": 61.9,
        "First Year": 1986,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1986,
        "DLY Last Year": 1991,
        "MLY First Year": 1986,
        "MLY Last Year": 1991
      },
      {
        "Name": "WATERVILLE CAMBRIDGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206222",
        "Station ID": 6497,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -64.65,
        "Latitude": 450300000,
        "Longitude": -643900000,
        "Elevation (m)": 30.6,
        "First Year": 1980,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2022,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "WENTWORTH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206225",
        "Station ID": 6498,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.7,
        "Longitude (Decimal Degrees)": -63.55,
        "Latitude": 454200000,
        "Longitude": -633300000,
        "Elevation (m)": 45.7,
        "First Year": 1957,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1966,
        "MLY First Year": 1957,
        "MLY Last Year": 1966
      },
      {
        "Name": "WENTWORTH 66M",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206226",
        "Station ID": 49111,
        "WMO ID": null,
        "TC ID": "AWW",
        "Latitude (Decimal Degrees)": 45.61,
        "Longitude (Decimal Degrees)": -63.56,
        "Latitude": 453645000,
        "Longitude": -633330006,
        "Elevation (m)": 66,
        "First Year": 2010,
        "Last Year": 2011,
        "HLY First Year": 2010,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WENTWORTH 280M",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206227",
        "Station ID": 49110,
        "WMO ID": null,
        "TC ID": "AWT",
        "Latitude (Decimal Degrees)": 45.61,
        "Longitude (Decimal Degrees)": -63.55,
        "Latitude": 453623006,
        "Longitude": -633248000,
        "Elevation (m)": 280,
        "First Year": 2010,
        "Last Year": 2011,
        "HLY First Year": 2010,
        "HLY Last Year": 2011,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WENTWORTH VALLEY",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206228",
        "Station ID": 6499,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.62,
        "Longitude (Decimal Degrees)": -63.57,
        "Latitude": 453700000,
        "Longitude": -633400000,
        "Elevation (m)": 54.9,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "WEST BRANCH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206232",
        "Station ID": 6500,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.68,
        "Longitude (Decimal Degrees)": -63.07,
        "Latitude": 454100000,
        "Longitude": -630400000,
        "Elevation (m)": 63.1,
        "First Year": 1984,
        "Last Year": 1986,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1986,
        "MLY First Year": 1984,
        "MLY Last Year": 1986
      },
      {
        "Name": "WESTERN HEAD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206240",
        "Station ID": 6501,
        "WMO ID": 71411,
        "TC ID": "WWE",
        "Latitude (Decimal Degrees)": 43.99,
        "Longitude (Decimal Degrees)": -64.66,
        "Latitude": 435924070,
        "Longitude": -643951008,
        "Elevation (m)": 10.06,
        "First Year": 1959,
        "Last Year": 2022,
        "HLY First Year": 1959,
        "HLY Last Year": 2022,
        "DLY First Year": 1959,
        "DLY Last Year": 2022,
        "MLY First Year": 1959,
        "MLY Last Year": 2007
      },
      {
        "Name": "WEST NEW ANNAN",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206246",
        "Station ID": 6502,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.63,
        "Longitude (Decimal Degrees)": -63.37,
        "Latitude": 453800000,
        "Longitude": -632200000,
        "Elevation (m)": 89.9,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1989,
        "MLY First Year": 1988,
        "MLY Last Year": 1989
      },
      {
        "Name": "WESTPHAL",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206250",
        "Station ID": 6503,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.68,
        "Longitude (Decimal Degrees)": -63.52,
        "Latitude": 444100000,
        "Longitude": -633100000,
        "Elevation (m)": 67.7,
        "First Year": 1955,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1998,
        "MLY First Year": 1955,
        "MLY Last Year": 1998
      },
      {
        "Name": "WESTPORT",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206260",
        "Station ID": 6504,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.25,
        "Longitude (Decimal Degrees)": -66.37,
        "Latitude": 441500000,
        "Longitude": -662200000,
        "Elevation (m)": 18.2,
        "First Year": 1961,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1993,
        "MLY First Year": 1961,
        "MLY Last Year": 1993
      },
      {
        "Name": "WEYMOUTH FALLS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206275",
        "Station ID": 6505,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.4,
        "Longitude (Decimal Degrees)": -65.95,
        "Latitude": 442400000,
        "Longitude": -655700000,
        "Elevation (m)": 10.7,
        "First Year": 1965,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2000,
        "MLY First Year": 1965,
        "MLY Last Year": 2000
      },
      {
        "Name": "WHITEHEAD",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206300",
        "Station ID": 6506,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.22,
        "Longitude (Decimal Degrees)": -61.18,
        "Latitude": 451300000,
        "Longitude": -611100000,
        "Elevation (m)": 13.7,
        "First Year": 1883,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1883,
        "DLY Last Year": 1960,
        "MLY First Year": 1883,
        "MLY Last Year": 1960
      },
      {
        "Name": "WHITE ROCK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206316",
        "Station ID": 6507,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.05,
        "Longitude (Decimal Degrees)": -64.38,
        "Latitude": 450300000,
        "Longitude": -642300000,
        "Elevation (m)": 38.1,
        "First Year": 1977,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2021,
        "MLY First Year": 1977,
        "MLY Last Year": 2006
      },
      {
        "Name": "WINDSOR",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206400",
        "Station ID": 6508,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45,
        "Longitude (Decimal Degrees)": -64.1,
        "Latitude": 450000000,
        "Longitude": -640600000,
        "Elevation (m)": 15.2,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "WINDSOR FALMOUTH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206404",
        "Station ID": 6509,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.97,
        "Longitude (Decimal Degrees)": -64.2,
        "Latitude": 445800000,
        "Longitude": -641200000,
        "Elevation (m)": 33.4,
        "First Year": 1989,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1992,
        "MLY First Year": 1989,
        "MLY Last Year": 1992
      },
      {
        "Name": "WINDSOR FALMOUTH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206405",
        "Station ID": 6510,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -64.17,
        "Latitude": 445900000,
        "Longitude": -641000000,
        "Elevation (m)": 14.6,
        "First Year": 1962,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1988,
        "MLY First Year": 1962,
        "MLY Last Year": 1988
      },
      {
        "Name": "WINDSOR KINGS COLLEGE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206410",
        "Station ID": 6511,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.98,
        "Longitude (Decimal Degrees)": -64.1,
        "Latitude": 445900000,
        "Longitude": -640600000,
        "Elevation (m)": 27.4,
        "First Year": 1871,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1948,
        "MLY First Year": 1871,
        "MLY Last Year": 1948
      },
      {
        "Name": "WINDSOR MARTOCK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206415",
        "Station ID": 6512,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 44.93,
        "Longitude (Decimal Degrees)": -64.17,
        "Latitude": 445600000,
        "Longitude": -641000000,
        "Elevation (m)": 38.1,
        "First Year": 1979,
        "Last Year": 2005,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2005,
        "MLY First Year": 1979,
        "MLY Last Year": 2005
      },
      {
        "Name": "WOLFVILLE",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206440",
        "Station ID": 6513,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.1,
        "Longitude (Decimal Degrees)": -64.37,
        "Latitude": 450600000,
        "Longitude": -642200000,
        "Elevation (m)": 41.1,
        "First Year": 1870,
        "Last Year": 1949,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1949,
        "MLY First Year": 1870,
        "MLY Last Year": 1949
      },
      {
        "Name": "WRECK COVE BROOK",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206450",
        "Station ID": 6514,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.53,
        "Longitude (Decimal Degrees)": -60.45,
        "Latitude": 463200000,
        "Longitude": -602700000,
        "Elevation (m)": 76.2,
        "First Year": 1976,
        "Last Year": 2012,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1976,
        "DLY Last Year": 2012,
        "MLY First Year": 1976,
        "MLY Last Year": 2006
      },
      {
        "Name": "YARMOUTH",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206490",
        "Station ID": 6515,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -66.03,
        "Latitude": 435000000,
        "Longitude": -660200000,
        "Elevation (m)": 30.8,
        "First Year": 1870,
        "Last Year": 1941,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1870,
        "DLY Last Year": 1941,
        "MLY First Year": 1870,
        "MLY Last Year": 1941
      },
      {
        "Name": "YARMOUTH RCS",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206491",
        "Station ID": 50408,
        "WMO ID": 71884,
        "TC ID": "EQI",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -66.09,
        "Latitude": 434951010,
        "Longitude": -660517010,
        "Elevation (m)": 36,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YARMOUTH A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206495",
        "Station ID": 50133,
        "WMO ID": 71603,
        "TC ID": "YQI",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -66.09,
        "Latitude": 434937000,
        "Longitude": -660517000,
        "Elevation (m)": 42.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YARMOUTH A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206496",
        "Station ID": 54284,
        "WMO ID": null,
        "TC ID": "YQI",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -66.09,
        "Latitude": 434937000,
        "Longitude": -660517000,
        "Elevation (m)": 42.9,
        "First Year": 2016,
        "Last Year": 2022,
        "HLY First Year": 2016,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "YARMOUTH A",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206500",
        "Station ID": 6516,
        "WMO ID": null,
        "TC ID": "YQI",
        "Latitude (Decimal Degrees)": 43.83,
        "Longitude (Decimal Degrees)": -66.09,
        "Latitude": 434951000,
        "Longitude": -660519000,
        "Elevation (m)": 43,
        "First Year": 1940,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1940,
        "DLY Last Year": 2012,
        "MLY First Year": 1940,
        "MLY Last Year": 2012
      },
      {
        "Name": "YARMOUTH CDA EPF",
        "Province": "NOVA SCOTIA",
        "Climate ID": "8206600",
        "Station ID": 6517,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 43.93,
        "Longitude (Decimal Degrees)": -66.12,
        "Latitude": 435600000,
        "Longitude": -660700000,
        "Elevation (m)": 38.1,
        "First Year": 1956,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1962,
        "MLY First Year": 1956,
        "MLY Last Year": 1962
      },
      {
        "Name": "FOURCHU HEAD (AUT)",
        "Province": "NOVA SCOTIA",
        "Climate ID": "820J8C7",
        "Station ID": 9833,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 45.72,
        "Longitude (Decimal Degrees)": -60.23,
        "Latitude": 454300000,
        "Longitude": -601400000,
        "Elevation (m)": 10,
        "First Year": 1993,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": 1993,
        "DLY Last Year": 1997,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALBANY",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300060",
        "Station ID": 6518,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.27,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 461600000,
        "Longitude": -633500000,
        "Elevation (m)": 33.5,
        "First Year": 1984,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1993,
        "MLY First Year": 1984,
        "MLY Last Year": 1993
      },
      {
        "Name": "ALBERTON",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300080",
        "Station ID": 6519,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.85,
        "Longitude (Decimal Degrees)": -64.02,
        "Latitude": 465100000,
        "Longitude": -640100000,
        "Elevation (m)": 3,
        "First Year": 1969,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2008,
        "MLY First Year": 1969,
        "MLY Last Year": 2006
      },
      {
        "Name": "ALBERTON SNOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300085",
        "Station ID": 49988,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.81,
        "Longitude (Decimal Degrees)": -64.06,
        "Latitude": 464853090,
        "Longitude": -640340090,
        "Elevation (m)": 8.5,
        "First Year": 2012,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2012,
        "DLY Last Year": 2019,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALLISTON",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300100",
        "Station ID": 6520,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -62.6,
        "Latitude": 460400000,
        "Longitude": -623600000,
        "Elevation (m)": 61,
        "First Year": 1936,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1936,
        "DLY Last Year": 2016,
        "MLY First Year": 1936,
        "MLY Last Year": 2006
      },
      {
        "Name": "ALLISTON SNOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300102",
        "Station ID": 47227,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.07,
        "Longitude (Decimal Degrees)": -62.6,
        "Latitude": 460400000,
        "Longitude": -623600000,
        "Elevation (m)": 61,
        "First Year": 2008,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2008,
        "DLY Last Year": 2009,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ARGYLE SHORE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300108",
        "Station ID": 6521,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.17,
        "Longitude (Decimal Degrees)": -63.35,
        "Latitude": 461000000,
        "Longitude": -632100000,
        "Elevation (m)": 15.2,
        "First Year": 1988,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1991,
        "MLY First Year": 1988,
        "MLY Last Year": 1991
      },
      {
        "Name": "BANGOR",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300128",
        "Station ID": 6522,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -62.68,
        "Latitude": 462100000,
        "Longitude": -624100000,
        "Elevation (m)": 36.6,
        "First Year": 1971,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2004,
        "MLY First Year": 1971,
        "MLY Last Year": 2004
      },
      {
        "Name": "BORDEN",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300150",
        "Station ID": 6523,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -63.7,
        "Latitude": 461500000,
        "Longitude": -634200000,
        "Elevation (m)": 7.6,
        "First Year": 1958,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1967,
        "MLY First Year": 1958,
        "MLY Last Year": 1967
      },
      {
        "Name": "BRACKLEY BEACH",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300200",
        "Station ID": 6524,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -63.33,
        "Latitude": 462500000,
        "Longitude": -632000000,
        "Elevation (m)": 48.5,
        "First Year": 1952,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1956,
        "MLY First Year": 1952,
        "MLY Last Year": 1956
      },
      {
        "Name": "CHARLOTTETOWN",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300298",
        "Station ID": 6525,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -63.17,
        "Latitude": 461400000,
        "Longitude": -631000000,
        "Elevation (m)": 13.7,
        "First Year": 1872,
        "Last Year": 1934,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1872,
        "DLY Last Year": 1934,
        "MLY First Year": 1872,
        "MLY Last Year": 1934
      },
      {
        "Name": "CHARLOTTETOWN A",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300300",
        "Station ID": 6526,
        "WMO ID": 71706,
        "TC ID": "YYG",
        "Latitude (Decimal Degrees)": 46.29,
        "Longitude (Decimal Degrees)": -63.13,
        "Latitude": 461719020,
        "Longitude": -630743070,
        "Elevation (m)": 48.8,
        "First Year": 1943,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1943,
        "DLY Last Year": 2012,
        "MLY First Year": 1943,
        "MLY Last Year": 2012
      },
      {
        "Name": "CHARLOTTETOWN A",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300301",
        "Station ID": 50621,
        "WMO ID": 71706,
        "TC ID": "YYG",
        "Latitude (Decimal Degrees)": 46.29,
        "Longitude (Decimal Degrees)": -63.12,
        "Latitude": 461721000,
        "Longitude": -630709000,
        "Elevation (m)": 50.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHARLOTTETOWN CDA",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300400",
        "Station ID": 6527,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -63.13,
        "Latitude": 461500000,
        "Longitude": -630800000,
        "Elevation (m)": 22.6,
        "First Year": 1910,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1910,
        "DLY Last Year": 1992,
        "MLY First Year": 1910,
        "MLY Last Year": 1992
      },
      {
        "Name": "CHARLOTTETOWN CDA CS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300401",
        "Station ID": 6928,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -63.13,
        "Latitude": 461500000,
        "Longitude": -630800000,
        "Elevation (m)": 22.6,
        "First Year": 1991,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2002,
        "MLY First Year": 1991,
        "MLY Last Year": 2001
      },
      {
        "Name": "CHARLOTTETOWN SDU",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300405",
        "Station ID": 6528,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -63.13,
        "Latitude": 461500000,
        "Longitude": -630800000,
        "Elevation (m)": 24.4,
        "First Year": 1968,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1970,
        "MLY First Year": 1969,
        "MLY Last Year": 1970
      },
      {
        "Name": "CONFEDERATION BRIDGE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300412",
        "Station ID": 27846,
        "WMO ID": 0,
        "TC ID": "XBP",
        "Latitude (Decimal Degrees)": 46.23,
        "Longitude (Decimal Degrees)": -63.73,
        "Latitude": 461400000,
        "Longitude": -634400000,
        "Elevation (m)": 54.1,
        "First Year": 1999,
        "Last Year": 2006,
        "HLY First Year": 1999,
        "HLY Last Year": 2006,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "EAST BALTIC",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300416",
        "Station ID": 6529,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -62.17,
        "Latitude": 462600000,
        "Longitude": -621000000,
        "Elevation (m)": 61,
        "First Year": 1971,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2000,
        "MLY First Year": 1971,
        "MLY Last Year": 2000
      },
      {
        "Name": "EAST POINT (AUT)",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300418",
        "Station ID": 7177,
        "WMO ID": 71412,
        "TC ID": "WEP",
        "Latitude (Decimal Degrees)": 46.46,
        "Longitude (Decimal Degrees)": -61.99,
        "Latitude": 462736000,
        "Longitude": -615918000,
        "Elevation (m)": 7.7,
        "First Year": 1992,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "ELLERSLIE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300420",
        "Station ID": 6530,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.62,
        "Longitude (Decimal Degrees)": -63.92,
        "Latitude": 463700000,
        "Longitude": -635500000,
        "Elevation (m)": 7.6,
        "First Year": 1945,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1945,
        "DLY Last Year": 1987,
        "MLY First Year": 1946,
        "MLY Last Year": 1987
      },
      {
        "Name": "ELMWOOD",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300425",
        "Station ID": 6929,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -63.33,
        "Latitude": 461500000,
        "Longitude": -632000000,
        "Elevation (m)": 67,
        "First Year": 1993,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2015,
        "MLY First Year": 1993,
        "MLY Last Year": 2006
      },
      {
        "Name": "ELMWOOD SNOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300426",
        "Station ID": 49770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.25,
        "Longitude (Decimal Degrees)": -63.33,
        "Latitude": 461500000,
        "Longitude": -632000000,
        "Elevation (m)": 67,
        "First Year": 2011,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GEORGETOWN",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300430",
        "Station ID": 6531,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.18,
        "Longitude (Decimal Degrees)": -62.58,
        "Latitude": 461100000,
        "Longitude": -623500000,
        "Elevation (m)": 11.6,
        "First Year": 1874,
        "Last Year": 1899,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1899,
        "MLY First Year": 1874,
        "MLY Last Year": 1899
      },
      {
        "Name": "HAMILTON",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300438",
        "Station ID": 6532,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -63.8,
        "Latitude": 462500000,
        "Longitude": -634800000,
        "Elevation (m)": null,
        "First Year": 1898,
        "Last Year": 1913,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1898,
        "DLY Last Year": 1913,
        "MLY First Year": 1898,
        "MLY Last Year": 1913
      },
      {
        "Name": "HUNTER RIVER",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300439",
        "Station ID": 6533,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.35,
        "Longitude (Decimal Degrees)": -63.33,
        "Latitude": 462100000,
        "Longitude": -632000000,
        "Elevation (m)": 61,
        "First Year": 1971,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1984,
        "MLY First Year": 1971,
        "MLY Last Year": 1984
      },
      {
        "Name": "KILMAHUMAIG",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300441",
        "Station ID": 6534,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.83,
        "Longitude (Decimal Degrees)": -64.05,
        "Latitude": 465000000,
        "Longitude": -640300000,
        "Elevation (m)": 6.1,
        "First Year": 1878,
        "Last Year": 1893,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1878,
        "DLY Last Year": 1893,
        "MLY First Year": 1878,
        "MLY Last Year": 1893
      },
      {
        "Name": "KINGSBORO",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300442",
        "Station ID": 30618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.39,
        "Longitude (Decimal Degrees)": -62.1,
        "Latitude": 462330000,
        "Longitude": -620615000,
        "Elevation (m)": 18,
        "First Year": 2000,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2007,
        "MLY First Year": 2000,
        "MLY Last Year": 2006
      },
      {
        "Name": "MONTAGUE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300445",
        "Station ID": 6535,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.2,
        "Longitude (Decimal Degrees)": -62.67,
        "Latitude": 461200000,
        "Longitude": -624000000,
        "Elevation (m)": 50.3,
        "First Year": 1961,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1977,
        "MLY First Year": 1961,
        "MLY Last Year": 1977
      },
      {
        "Name": "MONTICELLO",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300447",
        "Station ID": 6536,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -62.47,
        "Latitude": 462800000,
        "Longitude": -622800000,
        "Elevation (m)": 32,
        "First Year": 1959,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 2003,
        "MLY First Year": 1959,
        "MLY Last Year": 2003
      },
      {
        "Name": "KINGSBORO SNOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300452",
        "Station ID": 49771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.41,
        "Longitude (Decimal Degrees)": -62.08,
        "Latitude": 462425007,
        "Longitude": -620456008,
        "Elevation (m)": 18.2,
        "First Year": 2011,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2016,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MORELL SNOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300455",
        "Station ID": 49772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -62.72,
        "Latitude": 462600000,
        "Longitude": -624300000,
        "Elevation (m)": 10,
        "First Year": 2011,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2016,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NEW GLASGOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300497",
        "Station ID": 6537,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.41,
        "Longitude (Decimal Degrees)": -63.35,
        "Latitude": 462432080,
        "Longitude": -632101040,
        "Elevation (m)": 6.1,
        "First Year": 1971,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 2022,
        "MLY First Year": 1971,
        "MLY Last Year": 2006
      },
      {
        "Name": "LONG CREEK SNOW",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300499",
        "Station ID": 49768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -63.42,
        "Latitude": 462555020,
        "Longitude": -632455000,
        "Elevation (m)": 30.3,
        "First Year": 2011,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LONG RIVER",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300500",
        "Station ID": 6538,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.5,
        "Longitude (Decimal Degrees)": -63.55,
        "Latitude": 463000000,
        "Longitude": -633300000,
        "Elevation (m)": 18,
        "First Year": 1957,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2003,
        "MLY First Year": 1957,
        "MLY Last Year": 2003
      },
      {
        "Name": "LONG RIVER",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300502",
        "Station ID": 41823,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.49,
        "Longitude (Decimal Degrees)": -63.56,
        "Latitude": 462917000,
        "Longitude": -633339020,
        "Elevation (m)": 35,
        "First Year": 2003,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2003,
        "DLY Last Year": 2004,
        "MLY First Year": 2003,
        "MLY Last Year": 2004
      },
      {
        "Name": "NEWTOWN CROSS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300510",
        "Station ID": 6539,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.13,
        "Longitude (Decimal Degrees)": -62.83,
        "Latitude": 460800000,
        "Longitude": -625000000,
        "Elevation (m)": 41.1,
        "First Year": 1984,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1997,
        "MLY First Year": 1984,
        "MLY Last Year": 1997
      },
      {
        "Name": "NORTH CAPE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300516",
        "Station ID": 10814,
        "WMO ID": 71987,
        "TC ID": "WNE",
        "Latitude (Decimal Degrees)": 47.06,
        "Longitude (Decimal Degrees)": -64,
        "Latitude": 470329000,
        "Longitude": -635955000,
        "Elevation (m)": 7.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "O'LEARY",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300525",
        "Station ID": 6540,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.7,
        "Longitude (Decimal Degrees)": -64.26,
        "Latitude": 464201000,
        "Longitude": -641542080,
        "Elevation (m)": 38.1,
        "First Year": 1957,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 2004,
        "MLY First Year": 1957,
        "MLY Last Year": 2004
      },
      {
        "Name": "ROSE VALLEY",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300550",
        "Station ID": 6541,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.32,
        "Longitude (Decimal Degrees)": -63.5,
        "Latitude": 461900000,
        "Longitude": -633000000,
        "Elevation (m)": 99.1,
        "First Year": 1957,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1960,
        "MLY First Year": 1957,
        "MLY Last Year": 1960
      },
      {
        "Name": "ST NICHOLAS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300560",
        "Station ID": 6930,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.43,
        "Longitude (Decimal Degrees)": -63.93,
        "Latitude": 462600000,
        "Longitude": -635600000,
        "Elevation (m)": 9.2,
        "First Year": 1992,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1994,
        "MLY First Year": 1992,
        "MLY Last Year": 1994
      },
      {
        "Name": "ST. PETERS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300562",
        "Station ID": 41903,
        "WMO ID": 71310,
        "TC ID": "ZSP",
        "Latitude (Decimal Degrees)": 46.45,
        "Longitude (Decimal Degrees)": -62.58,
        "Latitude": 462701000,
        "Longitude": -623433000,
        "Elevation (m)": 29.7,
        "First Year": 2003,
        "Last Year": 2022,
        "HLY First Year": 2003,
        "HLY Last Year": 2022,
        "DLY First Year": 2003,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "SEA COW POND",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300570",
        "Station ID": 6542,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.03,
        "Longitude (Decimal Degrees)": -64,
        "Latitude": 470200000,
        "Longitude": -640000000,
        "Elevation (m)": 7.6,
        "First Year": 1974,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1974,
        "MLY First Year": 1974,
        "MLY Last Year": 1974
      },
      {
        "Name": "SOURIS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300585",
        "Station ID": 6543,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.37,
        "Longitude (Decimal Degrees)": -62.22,
        "Latitude": 462200000,
        "Longitude": -621300000,
        "Elevation (m)": 57.9,
        "First Year": 1967,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1987,
        "MLY First Year": 1967,
        "MLY Last Year": 1987
      },
      {
        "Name": "SOUTH PINETTE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300588",
        "Station ID": 6544,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.03,
        "Longitude (Decimal Degrees)": -62.88,
        "Latitude": 460200000,
        "Longitude": -625300000,
        "Elevation (m)": 18.3,
        "First Year": 1982,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1983,
        "MLY First Year": 1982,
        "MLY Last Year": 1983
      },
      {
        "Name": "STANHOPE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300590",
        "Station ID": 6545,
        "WMO ID": null,
        "TC ID": "ANH",
        "Latitude (Decimal Degrees)": 46.42,
        "Longitude (Decimal Degrees)": -63.08,
        "Latitude": 462459000,
        "Longitude": -630459000,
        "Elevation (m)": 3,
        "First Year": 1961,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 1961,
        "DLY Last Year": 2022,
        "MLY First Year": 1961,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUMMERSIDE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300596",
        "Station ID": 10800,
        "WMO ID": 71702,
        "TC ID": "WSD",
        "Latitude (Decimal Degrees)": 46.44,
        "Longitude (Decimal Degrees)": -63.84,
        "Latitude": 462628000,
        "Longitude": -635017000,
        "Elevation (m)": 12.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "SUMMERSIDE CDA",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300600",
        "Station ID": 6546,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.4,
        "Longitude (Decimal Degrees)": -63.78,
        "Latitude": 462400000,
        "Longitude": -634700000,
        "Elevation (m)": 4.6,
        "First Year": 1929,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1929,
        "DLY Last Year": 1963,
        "MLY First Year": 1929,
        "MLY Last Year": 1963
      },
      {
        "Name": "SUMMERSIDE SNOW 2",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300605",
        "Station ID": 49773,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.39,
        "Longitude (Decimal Degrees)": -63.6,
        "Latitude": 462312020,
        "Longitude": -633557003,
        "Elevation (m)": 37.9,
        "First Year": 2011,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2015,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SUMMERSIDE A",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300700",
        "Station ID": 6547,
        "WMO ID": null,
        "TC ID": "YSU",
        "Latitude (Decimal Degrees)": 46.44,
        "Longitude (Decimal Degrees)": -63.83,
        "Latitude": 462620000,
        "Longitude": -634954000,
        "Elevation (m)": 19.5,
        "First Year": 1942,
        "Last Year": 2002,
        "HLY First Year": 1953,
        "HLY Last Year": 2001,
        "DLY First Year": 1942,
        "DLY Last Year": 2002,
        "MLY First Year": 1942,
        "MLY Last Year": 2002
      },
      {
        "Name": "TIGNISH",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300800",
        "Station ID": 6548,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.95,
        "Longitude (Decimal Degrees)": -64.07,
        "Latitude": 465700000,
        "Longitude": -640400000,
        "Elevation (m)": 22.9,
        "First Year": 1971,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1993,
        "MLY First Year": 1972,
        "MLY Last Year": 1993
      },
      {
        "Name": "TYNE VALLEY",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300845",
        "Station ID": 6549,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.58,
        "Longitude (Decimal Degrees)": -63.93,
        "Latitude": 463431080,
        "Longitude": -635550000,
        "Elevation (m)": 15.2,
        "First Year": 1990,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2009,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "TYNE VALLEY 2",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300846",
        "Station ID": 49788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.55,
        "Longitude (Decimal Degrees)": -63.97,
        "Latitude": 463245030,
        "Longitude": -635809080,
        "Elevation (m)": 22.7,
        "First Year": 2011,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2011,
        "DLY Last Year": 2013,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "VERNON BRIDGE",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300870",
        "Station ID": 30701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.16,
        "Longitude (Decimal Degrees)": -62.88,
        "Latitude": 460937012,
        "Longitude": -625236048,
        "Elevation (m)": 25,
        "First Year": 2000,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2000,
        "DLY Last Year": 2001,
        "MLY First Year": 2000,
        "MLY Last Year": 2001
      },
      {
        "Name": "WELLINGTON",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300900",
        "Station ID": 6550,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.47,
        "Longitude (Decimal Degrees)": -64.05,
        "Latitude": 462800000,
        "Longitude": -640300000,
        "Elevation (m)": 50.3,
        "First Year": 1957,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1993,
        "MLY First Year": 1957,
        "MLY Last Year": 1993
      },
      {
        "Name": "VICTORIA",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8300907",
        "Station ID": 6931,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.22,
        "Longitude (Decimal Degrees)": -63.49,
        "Latitude": 461256000,
        "Longitude": -632915000,
        "Elevation (m)": 3.5,
        "First Year": 1993,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 2004,
        "MLY First Year": 1993,
        "MLY Last Year": 2004
      },
      {
        "Name": "MAPLE PLAINS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "8305500",
        "Station ID": 31029,
        "WMO ID": null,
        "TC ID": "XMP",
        "Latitude (Decimal Degrees)": 46.3,
        "Longitude (Decimal Degrees)": -63.58,
        "Latitude": 461810080,
        "Longitude": -633432040,
        "Elevation (m)": 45.7,
        "First Year": 2001,
        "Last Year": 2022,
        "HLY First Year": 2001,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "HARRINGTON CDA CS",
        "Province": "PRINCE EDWARD ISLAND",
        "Climate ID": "830P001",
        "Station ID": 30308,
        "WMO ID": 71350,
        "TC ID": "AHR",
        "Latitude (Decimal Degrees)": 46.34,
        "Longitude (Decimal Degrees)": -63.17,
        "Latitude": 462037020,
        "Longitude": -631011050,
        "Elevation (m)": 53,
        "First Year": 2000,
        "Last Year": 2022,
        "HLY First Year": 2004,
        "HLY Last Year": 2022,
        "DLY First Year": 2000,
        "DLY Last Year": 2022,
        "MLY First Year": 2000,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARGENTIA A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400100",
        "Station ID": 6556,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.3,
        "Longitude (Decimal Degrees)": -54,
        "Latitude": 471800000,
        "Longitude": -540000000,
        "Elevation (m)": 13.7,
        "First Year": 1945,
        "Last Year": 1970,
        "HLY First Year": 1953,
        "HLY Last Year": 1970,
        "DLY First Year": 1945,
        "DLY Last Year": 1970,
        "MLY First Year": 1945,
        "MLY Last Year": 1970
      },
      {
        "Name": "ARGENTIA A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400102",
        "Station ID": 6557,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.3,
        "Longitude (Decimal Degrees)": -54,
        "Latitude": 471800000,
        "Longitude": -540000000,
        "Elevation (m)": 15.5,
        "First Year": 1976,
        "Last Year": 1986,
        "HLY First Year": 1976,
        "HLY Last Year": 1986,
        "DLY First Year": 1976,
        "DLY Last Year": 1986,
        "MLY First Year": 1976,
        "MLY Last Year": 1986
      },
      {
        "Name": "ARGENTIA (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400104",
        "Station ID": 10113,
        "WMO ID": 71807,
        "TC ID": "WAR",
        "Latitude (Decimal Degrees)": 47.29,
        "Longitude (Decimal Degrees)": -53.99,
        "Latitude": 471738000,
        "Longitude": -535936000,
        "Elevation (m)": 19,
        "First Year": 1987,
        "Last Year": 2022,
        "HLY First Year": 1987,
        "HLY Last Year": 2022,
        "DLY First Year": 1992,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "ARNOLDS COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400135",
        "Station ID": 6558,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.75,
        "Longitude (Decimal Degrees)": -54,
        "Latitude": 474500000,
        "Longitude": -540000000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1994,
        "MLY First Year": 1971,
        "MLY Last Year": 1994
      },
      {
        "Name": "AVONDALE CDA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400225",
        "Station ID": 6559,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -53.23,
        "Latitude": 472500000,
        "Longitude": -531400000,
        "Elevation (m)": 132.6,
        "First Year": 1955,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1996,
        "MLY First Year": 1955,
        "MLY Last Year": 1996
      },
      {
        "Name": "BADGER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400300",
        "Station ID": 6560,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -56.05,
        "Latitude": 485900000,
        "Longitude": -560300000,
        "Elevation (m)": 100.6,
        "First Year": 1955,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1967,
        "MLY First Year": 1955,
        "MLY Last Year": 1967
      },
      {
        "Name": "BADGER (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400301",
        "Station ID": 6561,
        "WMO ID": 71400,
        "TC ID": "WDI",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -56.07,
        "Latitude": 485800000,
        "Longitude": -560400000,
        "Elevation (m)": 102.7,
        "First Year": 1973,
        "Last Year": 2022,
        "HLY First Year": 1986,
        "HLY Last Year": 2022,
        "DLY First Year": 1973,
        "DLY Last Year": 2022,
        "MLY First Year": 1973,
        "MLY Last Year": 2007
      },
      {
        "Name": "BADGER C",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400309",
        "Station ID": 6562,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -56.05,
        "Latitude": 485800000,
        "Longitude": -560300000,
        "Elevation (m)": 102,
        "First Year": 1988,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1989,
        "MLY First Year": 1988,
        "MLY Last Year": 1989
      },
      {
        "Name": "BAIE VERTE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400350",
        "Station ID": 6563,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.98,
        "Longitude (Decimal Degrees)": -56.18,
        "Latitude": 495900000,
        "Longitude": -561100000,
        "Elevation (m)": 110.3,
        "First Year": 1958,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1995,
        "MLY First Year": 1958,
        "MLY Last Year": 1995
      },
      {
        "Name": "BAIE VERTE JUNCTION",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400359",
        "Station ID": 6564,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -56.43,
        "Latitude": 492700000,
        "Longitude": -562600000,
        "Elevation (m)": 78,
        "First Year": 1989,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1994,
        "MLY First Year": 1989,
        "MLY Last Year": 1994
      },
      {
        "Name": "BAY D'ESPOIR 1",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400411",
        "Station ID": 6565,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.88,
        "Longitude (Decimal Degrees)": -55.83,
        "Latitude": 475300000,
        "Longitude": -555000000,
        "Elevation (m)": 11.3,
        "First Year": 1965,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1965,
        "MLY First Year": 1965,
        "MLY Last Year": 1965
      },
      {
        "Name": "BAY D'ESPOIR 2",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400412",
        "Station ID": 6566,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.98,
        "Longitude (Decimal Degrees)": -55.8,
        "Latitude": 475900000,
        "Longitude": -554800000,
        "Elevation (m)": 22.9,
        "First Year": 1965,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1967,
        "MLY First Year": 1965,
        "MLY Last Year": 1967
      },
      {
        "Name": "BAY D'ESPOIR GEN STN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400413",
        "Station ID": 6567,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.98,
        "Longitude (Decimal Degrees)": -55.8,
        "Latitude": 475900000,
        "Longitude": -554800000,
        "Elevation (m)": 22.9,
        "First Year": 1967,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 2019,
        "MLY First Year": 1967,
        "MLY Last Year": 2006
      },
      {
        "Name": "BAY D'ESPOIR LONG POND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400414",
        "Station ID": 6568,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.98,
        "Longitude (Decimal Degrees)": -55.82,
        "Latitude": 475900000,
        "Longitude": -554900000,
        "Elevation (m)": 169.2,
        "First Year": 1982,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1997,
        "MLY First Year": 1982,
        "MLY Last Year": 1997
      },
      {
        "Name": "BAY D'ESPOIR ST ALBANS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400415",
        "Station ID": 6569,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.97,
        "Longitude (Decimal Degrees)": -55.85,
        "Latitude": 475800000,
        "Longitude": -555100000,
        "Elevation (m)": 229.8,
        "First Year": 1966,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1968,
        "MLY First Year": 1966,
        "MLY Last Year": 1968
      },
      {
        "Name": "BENTON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400505",
        "Station ID": 6570,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.9,
        "Longitude (Decimal Degrees)": -54.42,
        "Latitude": 485400000,
        "Longitude": -542500000,
        "Elevation (m)": 48,
        "First Year": 1988,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1993,
        "MLY First Year": 1988,
        "MLY Last Year": 1993
      },
      {
        "Name": "BERRY HILL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400506",
        "Station ID": 55298,
        "WMO ID": 71588,
        "TC ID": "XRH",
        "Latitude (Decimal Degrees)": 49.62,
        "Longitude (Decimal Degrees)": -57.92,
        "Latitude": 493711005,
        "Longitude": -575509005,
        "Elevation (m)": 122,
        "First Year": 2021,
        "Last Year": 2022,
        "HLY First Year": 2021,
        "HLY Last Year": 2022,
        "DLY First Year": 2021,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BISHOPS FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400549",
        "Station ID": 6571,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -55.5,
        "Latitude": 490100000,
        "Longitude": -553000000,
        "Elevation (m)": 21.3,
        "First Year": 1988,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1993,
        "MLY First Year": 1988,
        "MLY Last Year": 1993
      },
      {
        "Name": "BISHOPS FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400550",
        "Station ID": 6572,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -55.47,
        "Latitude": 490100000,
        "Longitude": -552800000,
        "Elevation (m)": 9.1,
        "First Year": 1955,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1962,
        "MLY First Year": 1955,
        "MLY Last Year": 1961
      },
      {
        "Name": "BISHOPS FALLS PEAT MINE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400552",
        "Station ID": 6573,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -55.42,
        "Latitude": 490300000,
        "Longitude": -552500000,
        "Elevation (m)": 15.2,
        "First Year": 1980,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1984,
        "MLY First Year": 1980,
        "MLY Last Year": 1982
      },
      {
        "Name": "BLACK DUCK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400570",
        "Station ID": 6574,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -58.37,
        "Latitude": 483400000,
        "Longitude": -582200000,
        "Elevation (m)": 35.4,
        "First Year": 1981,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2004,
        "MLY First Year": 1981,
        "MLY Last Year": 2004
      },
      {
        "Name": "BOAT HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400578",
        "Station ID": 6575,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.43,
        "Longitude (Decimal Degrees)": -54.84,
        "Latitude": 472550000,
        "Longitude": -545034000,
        "Elevation (m)": 15.2,
        "First Year": 1982,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2009,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "BONAVISTA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400600",
        "Station ID": 6576,
        "WMO ID": null,
        "TC ID": "WVA",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -53.11,
        "Latitude": 484002000,
        "Longitude": -530651000,
        "Elevation (m)": 25.6,
        "First Year": 1956,
        "Last Year": 2006,
        "HLY First Year": 1959,
        "HLY Last Year": 2006,
        "DLY First Year": 1956,
        "DLY Last Year": 2006,
        "MLY First Year": 1956,
        "MLY Last Year": 2006
      },
      {
        "Name": "BONAVISTA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400601",
        "Station ID": 45047,
        "WMO ID": 71178,
        "TC ID": "AVA",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -53.11,
        "Latitude": 484002000,
        "Longitude": -530651000,
        "Elevation (m)": 25.6,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "BOTWOOD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400650",
        "Station ID": 6577,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.15,
        "Longitude (Decimal Degrees)": -55.35,
        "Latitude": 490900000,
        "Longitude": -552100000,
        "Elevation (m)": 15.2,
        "First Year": 1937,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1995,
        "MLY First Year": 1937,
        "MLY Last Year": 1995
      },
      {
        "Name": "BRANCH",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400666",
        "Station ID": 6578,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.88,
        "Longitude (Decimal Degrees)": -53.97,
        "Latitude": 465259000,
        "Longitude": -535805000,
        "Elevation (m)": 11.8,
        "First Year": 1983,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2017,
        "MLY First Year": 1983,
        "MLY Last Year": 2005
      },
      {
        "Name": "BROWNSDALE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400675",
        "Station ID": 27617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.03,
        "Longitude (Decimal Degrees)": -53.12,
        "Latitude": 480201000,
        "Longitude": -530708000,
        "Elevation (m)": 10,
        "First Year": 1998,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2015,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "BUCHANS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400698",
        "Station ID": 6579,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -56.87,
        "Latitude": 484900000,
        "Longitude": -565200000,
        "Elevation (m)": 269.7,
        "First Year": 1965,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 2011,
        "MLY First Year": 1965,
        "MLY Last Year": 2006
      },
      {
        "Name": "BUCHANS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400699",
        "Station ID": 6580,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -56.85,
        "Latitude": 484900000,
        "Longitude": -565100000,
        "Elevation (m)": 219.5,
        "First Year": 1937,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1947,
        "MLY First Year": 1937,
        "MLY Last Year": 1946
      },
      {
        "Name": "BUCHANS A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400700",
        "Station ID": 6581,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -56.83,
        "Latitude": 485100000,
        "Longitude": -565000000,
        "Elevation (m)": 276.1,
        "First Year": 1943,
        "Last Year": 1965,
        "HLY First Year": 1953,
        "HLY Last Year": 1965,
        "DLY First Year": 1943,
        "DLY Last Year": 1965,
        "MLY First Year": 1943,
        "MLY Last Year": 1965
      },
      {
        "Name": "BUCHANS JUNCTION",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400711",
        "Station ID": 6582,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.85,
        "Longitude (Decimal Degrees)": -56.47,
        "Latitude": 485100000,
        "Longitude": -562800000,
        "Elevation (m)": 160.8,
        "First Year": 1988,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1997,
        "MLY First Year": 1988,
        "MLY Last Year": 1997
      },
      {
        "Name": "BULL ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400755",
        "Station ID": 26829,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -53.9,
        "Latitude": 474900000,
        "Longitude": -535400000,
        "Elevation (m)": 119,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BURGEO",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400798",
        "Station ID": 6583,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -57.62,
        "Latitude": 473700000,
        "Longitude": -573700000,
        "Elevation (m)": 10.6,
        "First Year": 1966,
        "Last Year": 1995,
        "HLY First Year": 1966,
        "HLY Last Year": 1995,
        "DLY First Year": 1966,
        "DLY Last Year": 1995,
        "MLY First Year": 1966,
        "MLY Last Year": 1995
      },
      {
        "Name": "BURGEO 2",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400800",
        "Station ID": 6584,
        "WMO ID": null,
        "TC ID": "WBF",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -57.62,
        "Latitude": 473700000,
        "Longitude": -573700000,
        "Elevation (m)": 10.6,
        "First Year": 1939,
        "Last Year": 2006,
        "HLY First Year": 1994,
        "HLY Last Year": 2006,
        "DLY First Year": 1939,
        "DLY Last Year": 2006,
        "MLY First Year": 1939,
        "MLY Last Year": 2006
      },
      {
        "Name": "BURGEO NL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400801",
        "Station ID": 45287,
        "WMO ID": 71152,
        "TC ID": "ABF",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -57.62,
        "Latitude": 473700000,
        "Longitude": -573700000,
        "Elevation (m)": 10.6,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "BURIN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400810",
        "Station ID": 6585,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47,
        "Longitude (Decimal Degrees)": -55.17,
        "Latitude": 470000000,
        "Longitude": -551000000,
        "Elevation (m)": 15.2,
        "First Year": 1909,
        "Last Year": 1931,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1909,
        "DLY Last Year": 1931,
        "MLY First Year": 1909,
        "MLY Last Year": 1931
      },
      {
        "Name": "BURNT POND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400812",
        "Station ID": 6586,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.17,
        "Longitude (Decimal Degrees)": -57.33,
        "Latitude": 481000000,
        "Longitude": -572000000,
        "Elevation (m)": 298.7,
        "First Year": 1972,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2022,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "CAPE BROYLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400850",
        "Station ID": 6587,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.1,
        "Longitude (Decimal Degrees)": -52.93,
        "Latitude": 470600000,
        "Longitude": -525600000,
        "Elevation (m)": 6.1,
        "First Year": 1955,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1997,
        "MLY First Year": 1955,
        "MLY Last Year": 1997
      },
      {
        "Name": "CAPE NORMAN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400940",
        "Station ID": 6588,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.63,
        "Longitude (Decimal Degrees)": -55.9,
        "Latitude": 513800000,
        "Longitude": -555400000,
        "Elevation (m)": 18.6,
        "First Year": 1882,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1946,
        "MLY First Year": 1882,
        "MLY Last Year": 1946
      },
      {
        "Name": "BUTLERVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8400QJK",
        "Station ID": 6555,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -53.32,
        "Latitude": 473445000,
        "Longitude": -531858000,
        "Elevation (m)": 16,
        "First Year": 1988,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2019,
        "MLY First Year": 1988,
        "MLY Last Year": 2006
      },
      {
        "Name": "CAPE RACE (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401000",
        "Station ID": 6590,
        "WMO ID": 71800,
        "TC ID": "WRA",
        "Latitude (Decimal Degrees)": 46.66,
        "Longitude (Decimal Degrees)": -53.08,
        "Latitude": 463936000,
        "Longitude": -530435000,
        "Elevation (m)": 26.5,
        "First Year": 1920,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1920,
        "DLY Last Year": 2022,
        "MLY First Year": 1920,
        "MLY Last Year": 2007
      },
      {
        "Name": "CAPE ST GEORGE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401053",
        "Station ID": 6591,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.47,
        "Longitude (Decimal Degrees)": -59.27,
        "Latitude": 482800000,
        "Longitude": -591600000,
        "Elevation (m)": 45.7,
        "First Year": 1974,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 1977,
        "MLY First Year": 1974,
        "MLY Last Year": 1977
      },
      {
        "Name": "CAPPAHAYDEN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401070",
        "Station ID": 6592,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -52.95,
        "Latitude": 465200000,
        "Longitude": -525700000,
        "Elevation (m)": 15.2,
        "First Year": 1981,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1999,
        "MLY First Year": 1981,
        "MLY Last Year": 1999
      },
      {
        "Name": "CARBONEAR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401075",
        "Station ID": 6593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.73,
        "Longitude (Decimal Degrees)": -53.23,
        "Latitude": 474400000,
        "Longitude": -531400000,
        "Elevation (m)": 23.5,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "CARMANVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401078",
        "Station ID": 6594,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -54.27,
        "Latitude": 492400000,
        "Longitude": -541600000,
        "Elevation (m)": 4,
        "First Year": 1982,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2003,
        "MLY First Year": 1982,
        "MLY Last Year": 2003
      },
      {
        "Name": "CARTYVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401102",
        "Station ID": 6596,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.23,
        "Longitude (Decimal Degrees)": -58.82,
        "Latitude": 481400000,
        "Longitude": -584900000,
        "Elevation (m)": 30.5,
        "First Year": 1982,
        "Last Year": 2010,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2010,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "CAT ARM RIVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401104",
        "Station ID": 6597,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.08,
        "Longitude (Decimal Degrees)": -56.92,
        "Latitude": 500500000,
        "Longitude": -565500000,
        "Elevation (m)": 381,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "CHANNEL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401125",
        "Station ID": 6598,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -59.15,
        "Latitude": 473700000,
        "Longitude": -590900000,
        "Elevation (m)": 15.2,
        "First Year": 1874,
        "Last Year": 1951,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1951,
        "MLY First Year": 1874,
        "MLY Last Year": 1951
      },
      {
        "Name": "CHARLESTON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401128",
        "Station ID": 6599,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.38,
        "Longitude (Decimal Degrees)": -53.67,
        "Latitude": 482300000,
        "Longitude": -534000000,
        "Elevation (m)": 13,
        "First Year": 1989,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2019,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "CLARENVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401140",
        "Station ID": 6600,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.15,
        "Longitude (Decimal Degrees)": -53.97,
        "Latitude": 480900000,
        "Longitude": -535800000,
        "Elevation (m)": 7.6,
        "First Year": 1978,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1982,
        "MLY First Year": 1978,
        "MLY Last Year": 1982
      },
      {
        "Name": "CLARENVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401141",
        "Station ID": 6601,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.36,
        "Longitude (Decimal Degrees)": -53.96,
        "Latitude": 482148001,
        "Longitude": -535750004,
        "Elevation (m)": 66.6,
        "First Year": 1982,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2016,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "CLUNYS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401150",
        "Station ID": 6602,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.2,
        "Longitude (Decimal Degrees)": -52.95,
        "Latitude": 471200000,
        "Longitude": -525700000,
        "Elevation (m)": 121.9,
        "First Year": 1955,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1960,
        "MLY First Year": 1955,
        "MLY Last Year": 1960
      },
      {
        "Name": "COLINET",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401200",
        "Station ID": 6603,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.22,
        "Longitude (Decimal Degrees)": -53.55,
        "Latitude": 471300000,
        "Longitude": -533300000,
        "Elevation (m)": 27.4,
        "First Year": 1938,
        "Last Year": 1992,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1938,
        "DLY Last Year": 1992,
        "MLY First Year": 1938,
        "MLY Last Year": 1992
      },
      {
        "Name": "COLINET PEAT BOG CDA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401250",
        "Station ID": 6604,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.22,
        "Longitude (Decimal Degrees)": -53.5,
        "Latitude": 471300000,
        "Longitude": -533000000,
        "Elevation (m)": 104.2,
        "First Year": 1957,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1979,
        "MLY First Year": 1957,
        "MLY Last Year": 1979
      },
      {
        "Name": "COLINET PEAT BOG CDA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401251",
        "Station ID": 6605,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -53.52,
        "Latitude": 471400000,
        "Longitude": -533100000,
        "Elevation (m)": 54.9,
        "First Year": 1980,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1996,
        "MLY First Year": 1980,
        "MLY Last Year": 1996
      },
      {
        "Name": "COME BY CHANCE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401257",
        "Station ID": 6606,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.8,
        "Longitude (Decimal Degrees)": -54,
        "Latitude": 474800000,
        "Longitude": -540000000,
        "Elevation (m)": 34,
        "First Year": 1968,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1968,
        "DLY Last Year": 1995,
        "MLY First Year": 1968,
        "MLY Last Year": 1995
      },
      {
        "Name": "COMFORT COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401259",
        "Station ID": 6607,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -54.88,
        "Latitude": 491600000,
        "Longitude": -545300000,
        "Elevation (m)": 99.4,
        "First Year": 1967,
        "Last Year": 1996,
        "HLY First Year": 1967,
        "HLY Last Year": 1996,
        "DLY First Year": 1967,
        "DLY Last Year": 1996,
        "MLY First Year": 1967,
        "MLY Last Year": 1996
      },
      {
        "Name": "COMFORT COVE DECCA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401260",
        "Station ID": 6608,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.35,
        "Longitude (Decimal Degrees)": -54.87,
        "Latitude": 492100000,
        "Longitude": -545200000,
        "Elevation (m)": 7.3,
        "First Year": 1962,
        "Last Year": 1965,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1965,
        "MLY First Year": 1962,
        "MLY Last Year": 1965
      },
      {
        "Name": "CONNE RIVER (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401267",
        "Station ID": 10860,
        "WMO ID": null,
        "TC ID": "WFY",
        "Latitude (Decimal Degrees)": 48.17,
        "Longitude (Decimal Degrees)": -55.48,
        "Latitude": 481000000,
        "Longitude": -552900000,
        "Elevation (m)": 182.9,
        "First Year": 1994,
        "Last Year": 1999,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CORMACK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401286",
        "Station ID": 6609,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -57.4,
        "Latitude": 491900000,
        "Longitude": -572400000,
        "Elevation (m)": 153.9,
        "First Year": 1980,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2016,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "CORMACK RCS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401295",
        "Station ID": 50677,
        "WMO ID": 71367,
        "TC ID": "ACM",
        "Latitude (Decimal Degrees)": 49.32,
        "Longitude (Decimal Degrees)": -57.39,
        "Latitude": 491919031,
        "Longitude": -572336010,
        "Elevation (m)": 165.8,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CORNER BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401298",
        "Station ID": 6933,
        "WMO ID": 71973,
        "TC ID": "WKB",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -57.92,
        "Latitude": 485600000,
        "Longitude": -575500000,
        "Elevation (m)": 151.8,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "CORNER BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401300",
        "Station ID": 6610,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -57.95,
        "Latitude": 485700000,
        "Longitude": -575700000,
        "Elevation (m)": 4.6,
        "First Year": 1933,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 2022,
        "MLY First Year": 1933,
        "MLY Last Year": 2006
      },
      {
        "Name": "CORNER BROOK AVALON TEL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401302",
        "Station ID": 6611,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -57.95,
        "Latitude": 485700000,
        "Longitude": -575700000,
        "Elevation (m)": 15.2,
        "First Year": 1960,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1963,
        "MLY First Year": 1960,
        "MLY Last Year": 1962
      },
      {
        "Name": "COW HEAD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401335",
        "Station ID": 6612,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.91,
        "Longitude (Decimal Degrees)": -57.79,
        "Latitude": 495442000,
        "Longitude": -574720000,
        "Elevation (m)": 15.2,
        "First Year": 1982,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2019,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "DANIELS HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401400",
        "Station ID": 6613,
        "WMO ID": 71185,
        "TC ID": "WDH",
        "Latitude (Decimal Degrees)": 50.24,
        "Longitude (Decimal Degrees)": -57.58,
        "Latitude": 501411000,
        "Longitude": -573452000,
        "Elevation (m)": 19,
        "First Year": 1946,
        "Last Year": 2018,
        "HLY First Year": 1953,
        "HLY Last Year": 2018,
        "DLY First Year": 1946,
        "DLY Last Year": 2018,
        "MLY First Year": 1946,
        "MLY Last Year": 2007
      },
      {
        "Name": "DANIEL'S HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401405",
        "Station ID": 54638,
        "WMO ID": 73024,
        "TC ID": "ADL",
        "Latitude (Decimal Degrees)": 50.24,
        "Longitude (Decimal Degrees)": -57.58,
        "Latitude": 501409000,
        "Longitude": -573451000,
        "Elevation (m)": 19,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DANIELS HARBOUR 2",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401410",
        "Station ID": 10810,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.23,
        "Longitude (Decimal Degrees)": -57.58,
        "Latitude": 501400000,
        "Longitude": -573500000,
        "Elevation (m)": 19,
        "First Year": 1993,
        "Last Year": 1999,
        "HLY First Year": 1993,
        "HLY Last Year": 1996,
        "DLY First Year": 1996,
        "DLY Last Year": 1999,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEER LAKE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401500",
        "Station ID": 6614,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -57.43,
        "Latitude": 491000000,
        "Longitude": -572600000,
        "Elevation (m)": 10.7,
        "First Year": 1933,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1933,
        "DLY Last Year": 2022,
        "MLY First Year": 1933,
        "MLY Last Year": 2006
      },
      {
        "Name": "DEER LAKE A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401501",
        "Station ID": 6615,
        "WMO ID": 71809,
        "TC ID": "YDF",
        "Latitude (Decimal Degrees)": 49.22,
        "Longitude (Decimal Degrees)": -57.4,
        "Latitude": 491300000,
        "Longitude": -572400000,
        "Elevation (m)": 21.9,
        "First Year": 1965,
        "Last Year": 2012,
        "HLY First Year": 1965,
        "HLY Last Year": 2012,
        "DLY First Year": 1965,
        "DLY Last Year": 2012,
        "MLY First Year": 1965,
        "MLY Last Year": 2008
      },
      {
        "Name": "DEER LAKE A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401502",
        "Station ID": 50068,
        "WMO ID": 71809,
        "TC ID": "YDF",
        "Latitude (Decimal Degrees)": 49.21,
        "Longitude (Decimal Degrees)": -57.39,
        "Latitude": 491233000,
        "Longitude": -572340000,
        "Elevation (m)": 21.9,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DEVIL COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401506",
        "Station ID": 6616,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.02,
        "Longitude (Decimal Degrees)": -56.77,
        "Latitude": 500100000,
        "Longitude": -564600000,
        "Elevation (m)": 5.2,
        "First Year": 1988,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1988,
        "MLY First Year": 1988,
        "MLY Last Year": 1988
      },
      {
        "Name": "DEVILS HEAD (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401509",
        "Station ID": 7084,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.12,
        "Longitude (Decimal Degrees)": -58.42,
        "Latitude": 490700000,
        "Longitude": -582500000,
        "Elevation (m)": 68.6,
        "First Year": 1985,
        "Last Year": 1985,
        "HLY First Year": 1985,
        "HLY Last Year": 1985,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "DOVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401518",
        "Station ID": 6617,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.88,
        "Longitude (Decimal Degrees)": -53.97,
        "Latitude": 485300000,
        "Longitude": -535800000,
        "Elevation (m)": 21.3,
        "First Year": 1983,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1984,
        "MLY First Year": 1983,
        "MLY Last Year": 1984
      },
      {
        "Name": "DOYLES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401524",
        "Station ID": 6618,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.83,
        "Longitude (Decimal Degrees)": -59.18,
        "Latitude": 475000000,
        "Longitude": -591100000,
        "Elevation (m)": 30.5,
        "First Year": 1980,
        "Last Year": 1981,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1981,
        "MLY First Year": 1980,
        "MLY Last Year": 1981
      },
      {
        "Name": "DOYLES CDA EPF",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401525",
        "Station ID": 6619,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.83,
        "Longitude (Decimal Degrees)": -59.2,
        "Latitude": 475000000,
        "Longitude": -591200000,
        "Elevation (m)": 7.6,
        "First Year": 1954,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1963,
        "MLY First Year": 1954,
        "MLY Last Year": 1963
      },
      {
        "Name": "DUNVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401528",
        "Station ID": 6620,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.27,
        "Longitude (Decimal Degrees)": -53.92,
        "Latitude": 471600000,
        "Longitude": -535500000,
        "Elevation (m)": 15,
        "First Year": 1990,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1997,
        "MLY First Year": 1990,
        "MLY Last Year": 1997
      },
      {
        "Name": "EBBEGUNBAEG LAKE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401530",
        "Station ID": 6621,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.3,
        "Longitude (Decimal Degrees)": -56.42,
        "Latitude": 481800000,
        "Longitude": -562500000,
        "Elevation (m)": 259.1,
        "First Year": 1972,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1974,
        "MLY First Year": 1972,
        "MLY Last Year": 1974
      },
      {
        "Name": "ENGLEE (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401538",
        "Station ID": 10251,
        "WMO ID": 71417,
        "TC ID": "WDA",
        "Latitude (Decimal Degrees)": 50.72,
        "Longitude (Decimal Degrees)": -56.11,
        "Latitude": 504316000,
        "Longitude": -560646000,
        "Elevation (m)": 30.3,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "EXPLOITS DAM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401550",
        "Station ID": 6622,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.77,
        "Longitude (Decimal Degrees)": -56.6,
        "Latitude": 484600000,
        "Longitude": -563600000,
        "Elevation (m)": 153.6,
        "First Year": 1956,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 2008,
        "MLY First Year": 1956,
        "MLY Last Year": 2006
      },
      {
        "Name": "FEROLLE POINT (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401565",
        "Station ID": 9034,
        "WMO ID": 71406,
        "TC ID": "WXI",
        "Latitude (Decimal Degrees)": 51.02,
        "Longitude (Decimal Degrees)": -57.1,
        "Latitude": 510100000,
        "Longitude": -570600000,
        "Elevation (m)": 5.8,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "FLOWERS COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401582",
        "Station ID": 6623,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -56.73,
        "Latitude": 511800000,
        "Longitude": -564400000,
        "Elevation (m)": 9.1,
        "First Year": 1971,
        "Last Year": 1980,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1980,
        "MLY First Year": 1971,
        "MLY Last Year": 1980
      },
      {
        "Name": "FLOWERS COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401583",
        "Station ID": 6624,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.33,
        "Longitude (Decimal Degrees)": -56.68,
        "Latitude": 512000000,
        "Longitude": -564100000,
        "Elevation (m)": 9,
        "First Year": 1980,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 2008,
        "MLY First Year": 1980,
        "MLY Last Year": 2005
      },
      {
        "Name": "FLOWERS ISLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401585",
        "Station ID": 6625,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.3,
        "Longitude (Decimal Degrees)": -56.75,
        "Latitude": 511800000,
        "Longitude": -564500000,
        "Elevation (m)": 6.1,
        "First Year": 1967,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1970,
        "MLY First Year": 1967,
        "MLY Last Year": 1970
      },
      {
        "Name": "FOGO",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401599",
        "Station ID": 6626,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -54.3,
        "Latitude": 494300000,
        "Longitude": -541800000,
        "Elevation (m)": 11.8,
        "First Year": 1990,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2004,
        "MLY First Year": 1990,
        "MLY Last Year": 2004
      },
      {
        "Name": "FOGO",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401600",
        "Station ID": 6627,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -54.28,
        "Latitude": 494300000,
        "Longitude": -541700000,
        "Elevation (m)": 146.9,
        "First Year": 1873,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1873,
        "DLY Last Year": 1950,
        "MLY First Year": 1873,
        "MLY Last Year": 1950
      },
      {
        "Name": "FOGO",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401601",
        "Station ID": 6628,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.72,
        "Longitude (Decimal Degrees)": -54.27,
        "Latitude": 494300000,
        "Longitude": -541600000,
        "Elevation (m)": 15.2,
        "First Year": 1972,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1989,
        "MLY First Year": 1972,
        "MLY Last Year": 1988
      },
      {
        "Name": "FORTUNE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401618",
        "Station ID": 6629,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.07,
        "Longitude (Decimal Degrees)": -55.82,
        "Latitude": 470400000,
        "Longitude": -554900000,
        "Elevation (m)": 15.2,
        "First Year": 1980,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1989,
        "MLY First Year": 1980,
        "MLY Last Year": 1989
      },
      {
        "Name": "GALLANTS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401642",
        "Station ID": 6630,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.7,
        "Longitude (Decimal Degrees)": -58.23,
        "Latitude": 484200000,
        "Longitude": -581400000,
        "Elevation (m)": 143,
        "First Year": 1982,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2016,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "GAMBO",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401650",
        "Station ID": 6631,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.75,
        "Longitude (Decimal Degrees)": -54.23,
        "Latitude": 484500000,
        "Longitude": -541400000,
        "Elevation (m)": 1.8,
        "First Year": 1957,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1991,
        "MLY First Year": 1957,
        "MLY Last Year": 1991
      },
      {
        "Name": "GAMBO",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401651",
        "Station ID": 6632,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.78,
        "Longitude (Decimal Degrees)": -54.21,
        "Latitude": 484657005,
        "Longitude": -541252006,
        "Elevation (m)": 17,
        "First Year": 1982,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2006,
        "MLY First Year": 1982,
        "MLY Last Year": 2005
      },
      {
        "Name": "GANDER INT'L A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401700",
        "Station ID": 6633,
        "WMO ID": 71803,
        "TC ID": "YQX",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -54.58,
        "Latitude": 485647000,
        "Longitude": -543437000,
        "Elevation (m)": 151.2,
        "First Year": 1937,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1937,
        "DLY Last Year": 2012,
        "MLY First Year": 1937,
        "MLY Last Year": 2012
      },
      {
        "Name": "GANDER INTL A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401703",
        "Station ID": 50088,
        "WMO ID": 71803,
        "TC ID": "YQX",
        "Latitude (Decimal Degrees)": 48.94,
        "Longitude (Decimal Degrees)": -54.57,
        "Latitude": 485613000,
        "Longitude": -543405000,
        "Elevation (m)": 151.2,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GANDER AIRPORT CS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401705",
        "Station ID": 30346,
        "WMO ID": 71742,
        "TC ID": "XGD",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -54.57,
        "Latitude": 485646000,
        "Longitude": -543401000,
        "Elevation (m)": 151,
        "First Year": 2005,
        "Last Year": 2022,
        "HLY First Year": 2005,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "GANDER CCR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401710",
        "Station ID": 10968,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -54.58,
        "Latitude": 485642000,
        "Longitude": -543502000,
        "Elevation (m)": 152,
        "First Year": 1994,
        "Last Year": 1995,
        "HLY First Year": 1994,
        "HLY Last Year": 1995,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GANDER BAY SOUTH",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401711",
        "Station ID": 6634,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -54.48,
        "Latitude": 491600000,
        "Longitude": -542900000,
        "Elevation (m)": 7.6,
        "First Year": 1983,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1995,
        "MLY First Year": 1983,
        "MLY Last Year": 1995
      },
      {
        "Name": "GARNISH",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401728",
        "Station ID": 6635,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -55.36,
        "Latitude": 471347000,
        "Longitude": -552139000,
        "Elevation (m)": 10.8,
        "First Year": 1982,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2009,
        "MLY First Year": 1982,
        "MLY Last Year": 2005
      },
      {
        "Name": "GBS PLATFORM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401760",
        "Station ID": 26832,
        "WMO ID": null,
        "TC ID": "WZY",
        "Latitude (Decimal Degrees)": 47.82,
        "Longitude (Decimal Degrees)": -53.87,
        "Latitude": 474900000,
        "Longitude": -535200000,
        "Elevation (m)": null,
        "First Year": 1995,
        "Last Year": 1997,
        "HLY First Year": 1995,
        "HLY Last Year": 1997,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GLENWOOD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401799",
        "Station ID": 6636,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.98,
        "Longitude (Decimal Degrees)": -54.85,
        "Latitude": 485900000,
        "Longitude": -545100000,
        "Elevation (m)": 97,
        "First Year": 1990,
        "Last Year": 2003,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2003,
        "MLY First Year": 1990,
        "MLY Last Year": 2003
      },
      {
        "Name": "GLENWOOD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401800",
        "Station ID": 6637,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -54.87,
        "Latitude": 490000000,
        "Longitude": -545200000,
        "Elevation (m)": 30.5,
        "First Year": 1937,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1937,
        "DLY Last Year": 1977,
        "MLY First Year": 1937,
        "MLY Last Year": 1977
      },
      {
        "Name": "GOOBIES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401880",
        "Station ID": 6638,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.95,
        "Longitude (Decimal Degrees)": -53.97,
        "Latitude": 475657000,
        "Longitude": -535755000,
        "Elevation (m)": 72,
        "First Year": 1978,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2011,
        "MLY First Year": 1978,
        "MLY Last Year": 2006
      },
      {
        "Name": "GRAND BANK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401999",
        "Station ID": 6639,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.1,
        "Longitude (Decimal Degrees)": -55.75,
        "Latitude": 470600000,
        "Longitude": -554500000,
        "Elevation (m)": 13,
        "First Year": 1990,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1997,
        "MLY First Year": 1990,
        "MLY Last Year": 1997
      },
      {
        "Name": "DOYLES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8401EK4",
        "Station ID": 6589,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.85,
        "Longitude (Decimal Degrees)": -59.25,
        "Latitude": 475100000,
        "Longitude": -591500000,
        "Elevation (m)": 13.1,
        "First Year": 1981,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2011,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "GRAND BANK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402000",
        "Station ID": 6642,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.1,
        "Longitude (Decimal Degrees)": -55.77,
        "Latitude": 470600000,
        "Longitude": -554600000,
        "Elevation (m)": 1.5,
        "First Year": 1934,
        "Last Year": 1966,
        "HLY First Year": 1953,
        "HLY Last Year": 1966,
        "DLY First Year": 1934,
        "DLY Last Year": 1966,
        "MLY First Year": 1934,
        "MLY Last Year": 1966
      },
      {
        "Name": "GRAND FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402050",
        "Station ID": 6643,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.93,
        "Longitude (Decimal Degrees)": -55.67,
        "Latitude": 485600000,
        "Longitude": -554000000,
        "Elevation (m)": 60,
        "First Year": 1934,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 2006,
        "MLY First Year": 1934,
        "MLY Last Year": 2003
      },
      {
        "Name": "GRAND LAKE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402052",
        "Station ID": 6644,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -57.2,
        "Latitude": 490500000,
        "Longitude": -571200000,
        "Elevation (m)": 89.9,
        "First Year": 1978,
        "Last Year": 1984,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1984,
        "MLY First Year": 1978,
        "MLY Last Year": 1984
      },
      {
        "Name": "GRAND LAKE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402053",
        "Station ID": 5020,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.08,
        "Longitude (Decimal Degrees)": -57.18,
        "Latitude": 490500000,
        "Longitude": -571100000,
        "Elevation (m)": 306.9,
        "First Year": 1984,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1990,
        "MLY First Year": 1984,
        "MLY Last Year": 1990
      },
      {
        "Name": "GREY RIVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402057",
        "Station ID": 6645,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -57.1,
        "Latitude": 473500000,
        "Longitude": -570600000,
        "Elevation (m)": 12,
        "First Year": 1982,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1997,
        "MLY First Year": 1982,
        "MLY Last Year": 1997
      },
      {
        "Name": "GULL POND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402060",
        "Station ID": 6646,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.2,
        "Longitude (Decimal Degrees)": -56.15,
        "Latitude": 491200000,
        "Longitude": -560900000,
        "Elevation (m)": 152.4,
        "First Year": 1964,
        "Last Year": 1971,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1964,
        "DLY Last Year": 1971,
        "MLY First Year": 1964,
        "MLY Last Year": 1971
      },
      {
        "Name": "HAMPDEN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402065",
        "Station ID": 6647,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.55,
        "Longitude (Decimal Degrees)": -56.87,
        "Latitude": 493300000,
        "Longitude": -565200000,
        "Elevation (m)": 20.7,
        "First Year": 1958,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1962,
        "MLY First Year": 1958,
        "MLY Last Year": 1962
      },
      {
        "Name": "HAMPDEN WHITE BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402069",
        "Station ID": 6648,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.53,
        "Longitude (Decimal Degrees)": -56.87,
        "Latitude": 493200000,
        "Longitude": -565200000,
        "Elevation (m)": 29.5,
        "First Year": 1983,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1996,
        "MLY First Year": 1983,
        "MLY Last Year": 1996
      },
      {
        "Name": "HARBOUR BRETON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402071",
        "Station ID": 6649,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.47,
        "Longitude (Decimal Degrees)": -55.83,
        "Latitude": 472800000,
        "Longitude": -555000000,
        "Elevation (m)": 30,
        "First Year": 1983,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2021,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "HARBOUR DEEP",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402073",
        "Station ID": 6650,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.37,
        "Longitude (Decimal Degrees)": -56.52,
        "Latitude": 502200000,
        "Longitude": -563100000,
        "Elevation (m)": 11,
        "First Year": 1990,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2002,
        "MLY First Year": 1990,
        "MLY Last Year": 2002
      },
      {
        "Name": "HARBOUR GRACE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402075",
        "Station ID": 6651,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.72,
        "Longitude (Decimal Degrees)": -53.15,
        "Latitude": 474300000,
        "Longitude": -530900000,
        "Elevation (m)": 12.2,
        "First Year": 1957,
        "Last Year": 1958,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1958,
        "MLY First Year": 1957,
        "MLY Last Year": 1958
      },
      {
        "Name": "HARBOUR GRACE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402076",
        "Station ID": 6652,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.68,
        "Longitude (Decimal Degrees)": -53.2,
        "Latitude": 474100000,
        "Longitude": -531200000,
        "Elevation (m)": 7.1,
        "First Year": 1979,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 1993,
        "MLY First Year": 1979,
        "MLY Last Year": 1993
      },
      {
        "Name": "HAWKE'S BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402078",
        "Station ID": 6653,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.6,
        "Longitude (Decimal Degrees)": -57.18,
        "Latitude": 503600000,
        "Longitude": -571100000,
        "Elevation (m)": 11.2,
        "First Year": 1984,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2006,
        "MLY First Year": 1984,
        "MLY Last Year": 2006
      },
      {
        "Name": "HEARTS CONTENT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402080",
        "Station ID": 6654,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.87,
        "Longitude (Decimal Degrees)": -53.38,
        "Latitude": 475200000,
        "Longitude": -532300000,
        "Elevation (m)": 8.5,
        "First Year": 1961,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2002,
        "MLY First Year": 1961,
        "MLY Last Year": 2002
      },
      {
        "Name": "GREY RIVER (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "84020NP",
        "Station ID": 10662,
        "WMO ID": null,
        "TC ID": "WGC",
        "Latitude (Decimal Degrees)": 47.75,
        "Longitude (Decimal Degrees)": -56.93,
        "Latitude": 474500000,
        "Longitude": -565600000,
        "Elevation (m)": 13.7,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "HEATHERTON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402100",
        "Station ID": 6655,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.28,
        "Longitude (Decimal Degrees)": -58.55,
        "Latitude": 481700000,
        "Longitude": -583300000,
        "Elevation (m)": null,
        "First Year": 1954,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1956,
        "MLY First Year": 1954,
        "MLY Last Year": 1956
      },
      {
        "Name": "HINDS LAKE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402210",
        "Station ID": 6656,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.05,
        "Longitude (Decimal Degrees)": -57.13,
        "Latitude": 490300000,
        "Longitude": -570800000,
        "Elevation (m)": 299.9,
        "First Year": 1978,
        "Last Year": 1979,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 1979,
        "MLY First Year": 1978,
        "MLY Last Year": 1979
      },
      {
        "Name": "HOLYROOD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402300",
        "Station ID": 6657,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -53.13,
        "Latitude": 472300000,
        "Longitude": -530800000,
        "Elevation (m)": 10.7,
        "First Year": 1952,
        "Last Year": 1970,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1970,
        "MLY First Year": 1952,
        "MLY Last Year": 1970
      },
      {
        "Name": "HOLYROOD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402303",
        "Station ID": 27229,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -53.12,
        "Latitude": 472255000,
        "Longitude": -530724000,
        "Elevation (m)": 133.5,
        "First Year": 1996,
        "Last Year": 2013,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2013,
        "MLY First Year": 1996,
        "MLY Last Year": 2006
      },
      {
        "Name": "HOLYROOD GEN STN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402309",
        "Station ID": 6658,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -53.1,
        "Latitude": 472700000,
        "Longitude": -530600000,
        "Elevation (m)": 6,
        "First Year": 1970,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 2016,
        "MLY First Year": 1970,
        "MLY Last Year": 2006
      },
      {
        "Name": "HOLYROOD ULTRAMAR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402310",
        "Station ID": 6659,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.38,
        "Longitude (Decimal Degrees)": -53.13,
        "Latitude": 472300000,
        "Longitude": -530800000,
        "Elevation (m)": 7,
        "First Year": 1961,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1982,
        "MLY First Year": 1962,
        "MLY Last Year": 1982
      },
      {
        "Name": "HOPE BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402383",
        "Station ID": 6660,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.73,
        "Longitude (Decimal Degrees)": -58.08,
        "Latitude": 474400000,
        "Longitude": -580500000,
        "Elevation (m)": 131.3,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "HOWLEY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402415",
        "Station ID": 6661,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.17,
        "Longitude (Decimal Degrees)": -57.12,
        "Latitude": 491000000,
        "Longitude": -570700000,
        "Elevation (m)": 93.6,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1987,
        "MLY Last Year": 1990
      },
      {
        "Name": "INDIAN BAY B.B.",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402436",
        "Station ID": 6662,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.04,
        "Longitude (Decimal Degrees)": -53.88,
        "Latitude": 490220000,
        "Longitude": -535232000,
        "Elevation (m)": 1.2,
        "First Year": 1987,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 2019,
        "MLY First Year": 1987,
        "MLY Last Year": 2006
      },
      {
        "Name": "ISLE AUX MORTS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402450",
        "Station ID": 6663,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -58.97,
        "Latitude": 473500000,
        "Longitude": -585800000,
        "Elevation (m)": 4.6,
        "First Year": 1982,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2004,
        "MLY First Year": 1982,
        "MLY Last Year": 2004
      },
      {
        "Name": "JACKSONS ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402470",
        "Station ID": 6664,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -56.82,
        "Latitude": 495200000,
        "Longitude": -564900000,
        "Elevation (m)": 46,
        "First Year": 1982,
        "Last Year": 1982,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1982,
        "MLY First Year": 1982,
        "MLY Last Year": 1982
      },
      {
        "Name": "JACKSONS ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402471",
        "Station ID": 6665,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -56.78,
        "Latitude": 495200000,
        "Longitude": -564700000,
        "Elevation (m)": 10,
        "First Year": 1982,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1995,
        "MLY First Year": 1982,
        "MLY Last Year": 1995
      },
      {
        "Name": "JEFFREY'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402474",
        "Station ID": 6935,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.23,
        "Longitude (Decimal Degrees)": -58.83,
        "Latitude": 481400000,
        "Longitude": -585000000,
        "Elevation (m)": 18.3,
        "First Year": 1992,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1992,
        "DLY Last Year": 1995,
        "MLY First Year": 1992,
        "MLY Last Year": 1995
      },
      {
        "Name": "KELLIGREWS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402478",
        "Station ID": 7089,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.5,
        "Longitude (Decimal Degrees)": -53.02,
        "Latitude": 473000000,
        "Longitude": -530100000,
        "Elevation (m)": 3.3,
        "First Year": 1987,
        "Last Year": 1989,
        "HLY First Year": 1987,
        "HLY Last Year": 1989,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "LAKE AMBROSE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402500",
        "Station ID": 6666,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -56.65,
        "Latitude": 483500000,
        "Longitude": -563900000,
        "Elevation (m)": 268.8,
        "First Year": 1955,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1957,
        "MLY First Year": 1955,
        "MLY Last Year": 1957
      },
      {
        "Name": "LAMALINE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402516",
        "Station ID": 6667,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.87,
        "Longitude (Decimal Degrees)": -55.13,
        "Latitude": 465200000,
        "Longitude": -550800000,
        "Elevation (m)": 2.7,
        "First Year": 1983,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1998,
        "MLY First Year": 1983,
        "MLY Last Year": 1998
      },
      {
        "Name": "LA SCIE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402520",
        "Station ID": 6668,
        "WMO ID": 71337,
        "TC ID": "WAG",
        "Latitude (Decimal Degrees)": 49.92,
        "Longitude (Decimal Degrees)": -55.67,
        "Latitude": 495500000,
        "Longitude": -554000000,
        "Elevation (m)": 194,
        "First Year": 1984,
        "Last Year": 2022,
        "HLY First Year": 1984,
        "HLY Last Year": 2022,
        "DLY First Year": 1984,
        "DLY Last Year": 2022,
        "MLY First Year": 1984,
        "MLY Last Year": 2007
      },
      {
        "Name": "LETHBRIDGE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402544",
        "Station ID": 6669,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -53.9,
        "Latitude": 482100000,
        "Longitude": -535406000,
        "Elevation (m)": 15.2,
        "First Year": 1954,
        "Last Year": 2022,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 2022,
        "MLY First Year": 1954,
        "MLY Last Year": 2006
      },
      {
        "Name": "LOCKLEVEN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402563",
        "Station ID": 6670,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.17,
        "Longitude (Decimal Degrees)": -58.87,
        "Latitude": 481000000,
        "Longitude": -585200000,
        "Elevation (m)": 45.7,
        "First Year": 1987,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1997,
        "MLY First Year": 1988,
        "MLY Last Year": 1997
      },
      {
        "Name": "LOCKSTON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402565",
        "Station ID": 6671,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.4,
        "Longitude (Decimal Degrees)": -53.38,
        "Latitude": 482400000,
        "Longitude": -532300000,
        "Elevation (m)": 18,
        "First Year": 1966,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1999,
        "MLY First Year": 1966,
        "MLY Last Year": 1999
      },
      {
        "Name": "LOGY BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402568",
        "Station ID": 6672,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -52.66,
        "Latitude": 473727000,
        "Longitude": -523951000,
        "Elevation (m)": 27.4,
        "First Year": 1969,
        "Last Year": 2004,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 2004,
        "MLY First Year": 1969,
        "MLY Last Year": 2004
      },
      {
        "Name": "LONG HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402569",
        "Station ID": 6673,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -53.82,
        "Latitude": 472500000,
        "Longitude": -534900000,
        "Elevation (m)": 8.4,
        "First Year": 1969,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1969,
        "DLY Last Year": 1999,
        "MLY First Year": 1969,
        "MLY Last Year": 1999
      },
      {
        "Name": "LOOKOUT BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402588",
        "Station ID": 6674,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -58.3,
        "Latitude": 482100000,
        "Longitude": -581800000,
        "Elevation (m)": 106.7,
        "First Year": 1956,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1956,
        "MLY First Year": 1956,
        "MLY Last Year": 1956
      },
      {
        "Name": "MARKLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402590",
        "Station ID": 6675,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.32,
        "Longitude (Decimal Degrees)": -53.55,
        "Latitude": 471900000,
        "Longitude": -533300000,
        "Elevation (m)": 56,
        "First Year": 1981,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1994,
        "MLY First Year": 1981,
        "MLY Last Year": 1994
      },
      {
        "Name": "MARYSTOWN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402592",
        "Station ID": 6676,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.17,
        "Longitude (Decimal Degrees)": -55.15,
        "Latitude": 471000000,
        "Longitude": -550900000,
        "Elevation (m)": 15.2,
        "First Year": 1970,
        "Last Year": 1972,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1972,
        "MLY First Year": 1970,
        "MLY Last Year": 1972
      },
      {
        "Name": "MIDDLE ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402644",
        "Station ID": 6677,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -56.08,
        "Latitude": 494100000,
        "Longitude": -560500000,
        "Elevation (m)": 47.8,
        "First Year": 1988,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2021,
        "MLY First Year": 1988,
        "MLY Last Year": 2006
      },
      {
        "Name": "MILLERTOWN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402755",
        "Station ID": 6678,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49,
        "Longitude (Decimal Degrees)": -56.35,
        "Latitude": 490000000,
        "Longitude": -562100000,
        "Elevation (m)": 210.9,
        "First Year": 1934,
        "Last Year": 1946,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1934,
        "DLY Last Year": 1946,
        "MLY First Year": 1934,
        "MLY Last Year": 1946
      },
      {
        "Name": "MILLERTOWN RCS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402757",
        "Station ID": 50678,
        "WMO ID": 71856,
        "TC ID": "AMR",
        "Latitude (Decimal Degrees)": 48.82,
        "Longitude (Decimal Degrees)": -56.54,
        "Latitude": 484900119,
        "Longitude": -563236695,
        "Elevation (m)": 203.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MOBILE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402759",
        "Station ID": 6936,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.25,
        "Longitude (Decimal Degrees)": -52.85,
        "Latitude": 471500000,
        "Longitude": -525100000,
        "Elevation (m)": 5.5,
        "First Year": 1993,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1996,
        "MLY First Year": 1993,
        "MLY Last Year": 1996
      },
      {
        "Name": "MUSGRAVE HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402770",
        "Station ID": 6679,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -53.98,
        "Latitude": 492700000,
        "Longitude": -535900000,
        "Elevation (m)": 3,
        "First Year": 1978,
        "Last Year": 2009,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2009,
        "MLY First Year": 1978,
        "MLY Last Year": 2006
      },
      {
        "Name": "NEW CHELSEA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402840",
        "Station ID": 6680,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.03,
        "Longitude (Decimal Degrees)": -53.22,
        "Latitude": 480200000,
        "Longitude": -531300000,
        "Elevation (m)": 9,
        "First Year": 1961,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1991,
        "MLY First Year": 1961,
        "MLY Last Year": 1991
      },
      {
        "Name": "NORTH EAST POND RIVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402873",
        "Station ID": 6681,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.63,
        "Longitude (Decimal Degrees)": -52.83,
        "Latitude": 473800000,
        "Longitude": -525000000,
        "Elevation (m)": 91.4,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "NORTH HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402874",
        "Station ID": 6682,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.13,
        "Longitude (Decimal Degrees)": -53.67,
        "Latitude": 470800000,
        "Longitude": -534000000,
        "Elevation (m)": 11,
        "First Year": 1988,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2007,
        "MLY First Year": 1988,
        "MLY Last Year": 2006
      },
      {
        "Name": "PETTY HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402925",
        "Station ID": 6683,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.47,
        "Longitude (Decimal Degrees)": -52.72,
        "Latitude": 472800000,
        "Longitude": -524300000,
        "Elevation (m)": 6.1,
        "First Year": 1955,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1999,
        "MLY First Year": 1955,
        "MLY Last Year": 1999
      },
      {
        "Name": "PICCADILLY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402945",
        "Station ID": 6684,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -58.92,
        "Latitude": 483300000,
        "Longitude": -585500000,
        "Elevation (m)": 13.1,
        "First Year": 1980,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1989,
        "MLY First Year": 1980,
        "MLY Last Year": 1989
      },
      {
        "Name": "PIERRES BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402950",
        "Station ID": 6685,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.28,
        "Longitude (Decimal Degrees)": -52.82,
        "Latitude": 471700000,
        "Longitude": -524900000,
        "Elevation (m)": 15.2,
        "First Year": 1955,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1978,
        "MLY First Year": 1956,
        "MLY Last Year": 1978
      },
      {
        "Name": "PLACENTIA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402956",
        "Station ID": 6686,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.23,
        "Longitude (Decimal Degrees)": -54.02,
        "Latitude": 471400000,
        "Longitude": -540100000,
        "Elevation (m)": 14,
        "First Year": 1970,
        "Last Year": 1975,
        "HLY First Year": 1970,
        "HLY Last Year": 1975,
        "DLY First Year": 1970,
        "DLY Last Year": 1975,
        "MLY First Year": 1970,
        "MLY Last Year": 1975
      },
      {
        "Name": "PLACENTIA JUNCTION",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402957",
        "Station ID": 6687,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.4,
        "Longitude (Decimal Degrees)": -53.6,
        "Latitude": 472400000,
        "Longitude": -533600000,
        "Elevation (m)": 92,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "PLUM POINT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402958",
        "Station ID": 6688,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.07,
        "Longitude (Decimal Degrees)": -56.88,
        "Latitude": 510400000,
        "Longitude": -565300000,
        "Elevation (m)": 6.1,
        "First Year": 1972,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 2019,
        "MLY First Year": 1972,
        "MLY Last Year": 2006
      },
      {
        "Name": "POINT LEAMINGTON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402966",
        "Station ID": 6689,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.33,
        "Longitude (Decimal Degrees)": -55.4,
        "Latitude": 492000000,
        "Longitude": -552400000,
        "Elevation (m)": 7.6,
        "First Year": 1982,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2017,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "POINTE RICHE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402968",
        "Station ID": 6690,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.7,
        "Longitude (Decimal Degrees)": -57.42,
        "Latitude": 504200000,
        "Longitude": -572500000,
        "Elevation (m)": 10.7,
        "First Year": 1882,
        "Last Year": 1947,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1882,
        "DLY Last Year": 1947,
        "MLY First Year": 1882,
        "MLY Last Year": 1941
      },
      {
        "Name": "POOLS COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402972",
        "Station ID": 6691,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.68,
        "Longitude (Decimal Degrees)": -55.43,
        "Latitude": 474100000,
        "Longitude": -552600000,
        "Elevation (m)": 10.7,
        "First Year": 1978,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1978,
        "DLY Last Year": 2001,
        "MLY First Year": 1978,
        "MLY Last Year": 2001
      },
      {
        "Name": "POOLS COVE FORTUNE BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402973",
        "Station ID": 6692,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.7,
        "Longitude (Decimal Degrees)": -55.58,
        "Latitude": 474200000,
        "Longitude": -553500000,
        "Elevation (m)": 150,
        "First Year": 1979,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2000,
        "MLY First Year": 1979,
        "MLY Last Year": 2000
      },
      {
        "Name": "PORT AUX BASQUES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402975",
        "Station ID": 6693,
        "WMO ID": 71197,
        "TC ID": "WZB",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -59.15,
        "Latitude": 473426000,
        "Longitude": -590917000,
        "Elevation (m)": 39.7,
        "First Year": 1909,
        "Last Year": 2017,
        "HLY First Year": 1966,
        "HLY Last Year": 2017,
        "DLY First Year": 1909,
        "DLY Last Year": 2017,
        "MLY First Year": 1909,
        "MLY Last Year": 2007
      },
      {
        "Name": "PORT AUX BASQUES 3",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402977",
        "Station ID": 10809,
        "WMO ID": null,
        "TC ID": "WOF",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -59.17,
        "Latitude": 473400000,
        "Longitude": -591000000,
        "Elevation (m)": 40.2,
        "First Year": 1994,
        "Last Year": 1998,
        "HLY First Year": 1994,
        "HLY Last Year": 1998,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PORT AUX BASQUES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402980",
        "Station ID": 54640,
        "WMO ID": 73026,
        "TC ID": "APB",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -59.15,
        "Latitude": 473426000,
        "Longitude": -590917000,
        "Elevation (m)": 39.7,
        "First Year": 2017,
        "Last Year": 2022,
        "HLY First Year": 2017,
        "HLY Last Year": 2022,
        "DLY First Year": 2017,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "PARADISE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402R20",
        "Station ID": 6641,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.53,
        "Longitude (Decimal Degrees)": -52.83,
        "Latitude": 473200000,
        "Longitude": -525000000,
        "Elevation (m)": 136,
        "First Year": 1989,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1990,
        "MLY First Year": 1989,
        "MLY Last Year": 1990
      },
      {
        "Name": "PARADISE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402RB0",
        "Station ID": 6934,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.53,
        "Longitude (Decimal Degrees)": -52.85,
        "Latitude": 473200000,
        "Longitude": -525100000,
        "Elevation (m)": 154,
        "First Year": 1991,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1995,
        "MLY First Year": 1991,
        "MLY Last Year": 1995
      },
      {
        "Name": "PARADISE RIVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8402RK0",
        "Station ID": 6640,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -54.43,
        "Latitude": 473700000,
        "Longitude": -542600000,
        "Elevation (m)": 125,
        "First Year": 1990,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1991,
        "MLY First Year": 1990,
        "MLY Last Year": 1991
      },
      {
        "Name": "PORT AUX BASQUES CHANNEL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403000",
        "Station ID": 6695,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -59.15,
        "Latitude": 473700000,
        "Longitude": -590900000,
        "Elevation (m)": 15.2,
        "First Year": 1952,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1953,
        "MLY First Year": 1952,
        "MLY Last Year": 1953
      },
      {
        "Name": "PORT BLANDFORD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403008",
        "Station ID": 6696,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.35,
        "Longitude (Decimal Degrees)": -54.17,
        "Latitude": 482058003,
        "Longitude": -541013002,
        "Elevation (m)": 39.5,
        "First Year": 1982,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2006,
        "MLY First Year": 1982,
        "MLY Last Year": 2006
      },
      {
        "Name": "PORT SAUNDERS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403040",
        "Station ID": 6697,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.65,
        "Longitude (Decimal Degrees)": -57.2,
        "Latitude": 503900000,
        "Longitude": -571200000,
        "Elevation (m)": 90.8,
        "First Year": 1979,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2001,
        "MLY First Year": 1979,
        "MLY Last Year": 2001
      },
      {
        "Name": "PORTUGAL COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403044",
        "Station ID": 27593,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.65,
        "Longitude (Decimal Degrees)": -52.82,
        "Latitude": 473843000,
        "Longitude": -524901000,
        "Elevation (m)": 192,
        "First Year": 1998,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1998,
        "DLY Last Year": 2006,
        "MLY First Year": 1998,
        "MLY Last Year": 2006
      },
      {
        "Name": "PORTUGAL COVE, CONCEPTION BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403045",
        "Station ID": 6698,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -52.83,
        "Latitude": 473700000,
        "Longitude": -525000000,
        "Elevation (m)": 137.2,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1987,
        "MLY Last Year": 1996
      },
      {
        "Name": "PORT UNION",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403050",
        "Station ID": 6699,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -53.08,
        "Latitude": 483000000,
        "Longitude": -530500000,
        "Elevation (m)": 6.1,
        "First Year": 1966,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 2014,
        "MLY First Year": 1966,
        "MLY Last Year": 2006
      },
      {
        "Name": "PURBECK'S COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403060",
        "Station ID": 6700,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.75,
        "Longitude (Decimal Degrees)": -56.65,
        "Latitude": 494500000,
        "Longitude": -563900000,
        "Elevation (m)": 9.1,
        "First Year": 1988,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1993,
        "MLY First Year": 1988,
        "MLY Last Year": 1993
      },
      {
        "Name": "RATTLING BROOK DEPOT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403075",
        "Station ID": 6701,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.97,
        "Longitude (Decimal Degrees)": -55.53,
        "Latitude": 485800000,
        "Longitude": -553200000,
        "Elevation (m)": 18.3,
        "First Year": 1956,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1957,
        "MLY First Year": 1956,
        "MLY Last Year": 1957
      },
      {
        "Name": "RED CLIFF",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403082",
        "Station ID": 6937,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.95,
        "Longitude (Decimal Degrees)": -55.75,
        "Latitude": 485700000,
        "Longitude": -554500000,
        "Elevation (m)": 82,
        "First Year": 1991,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1996,
        "MLY First Year": 1991,
        "MLY Last Year": 1996
      },
      {
        "Name": "RED HARBOUR PB",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403083",
        "Station ID": 6702,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.3,
        "Longitude (Decimal Degrees)": -55.01,
        "Latitude": 471757000,
        "Longitude": -550036000,
        "Elevation (m)": 24,
        "First Year": 1989,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 2006,
        "MLY First Year": 1989,
        "MLY Last Year": 2006
      },
      {
        "Name": "RATTLING BRK NORRIS ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403085",
        "Station ID": 6703,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -55.3,
        "Latitude": 490400000,
        "Longitude": -551800000,
        "Elevation (m)": 8.8,
        "First Year": 1958,
        "Last Year": 2008,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 2008,
        "MLY First Year": 1958,
        "MLY Last Year": 2006
      },
      {
        "Name": "ROBERT'S ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403093",
        "Station ID": 6704,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -55.82,
        "Latitude": 493000000,
        "Longitude": -554900000,
        "Elevation (m)": 29.9,
        "First Year": 1982,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1996,
        "MLY First Year": 1982,
        "MLY Last Year": 1996
      },
      {
        "Name": "ROCKY HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403096",
        "Station ID": 6705,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -57.88,
        "Latitude": 493400000,
        "Longitude": -575300000,
        "Elevation (m)": 67.7,
        "First Year": 1972,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1993,
        "MLY First Year": 1972,
        "MLY Last Year": 1993
      },
      {
        "Name": "ROCKY HARBOUR CS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403097",
        "Station ID": 6938,
        "WMO ID": 71588,
        "TC ID": "XRH",
        "Latitude (Decimal Degrees)": 49.57,
        "Longitude (Decimal Degrees)": -57.88,
        "Latitude": 493412000,
        "Longitude": -575240000,
        "Elevation (m)": 67.7,
        "First Year": 1993,
        "Last Year": 2021,
        "HLY First Year": 1999,
        "HLY Last Year": 2021,
        "DLY First Year": 1993,
        "DLY Last Year": 2021,
        "MLY First Year": 1993,
        "MLY Last Year": 2007
      },
      {
        "Name": "RODDICKTON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403098",
        "Station ID": 6706,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.87,
        "Longitude (Decimal Degrees)": -56.12,
        "Latitude": 505200000,
        "Longitude": -560700000,
        "Elevation (m)": 12.2,
        "First Year": 1971,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1990,
        "MLY First Year": 1971,
        "MLY Last Year": 1990
      },
      {
        "Name": "ROUND HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403210",
        "Station ID": 6707,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.85,
        "Longitude (Decimal Degrees)": -55.67,
        "Latitude": 495100000,
        "Longitude": -554000000,
        "Elevation (m)": 14.9,
        "First Year": 1977,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 1993,
        "MLY First Year": 1977,
        "MLY Last Year": 1993
      },
      {
        "Name": "SAGONA ISLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403255",
        "Station ID": 8991,
        "WMO ID": 71408,
        "TC ID": "WZN",
        "Latitude (Decimal Degrees)": 47.37,
        "Longitude (Decimal Degrees)": -55.79,
        "Latitude": 472204000,
        "Longitude": -554741000,
        "Elevation (m)": 59.7,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST ALBANS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403290",
        "Station ID": 6708,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.87,
        "Longitude (Decimal Degrees)": -55.85,
        "Latitude": 475200000,
        "Longitude": -555100000,
        "Elevation (m)": 13.4,
        "First Year": 1968,
        "Last Year": 1983,
        "HLY First Year": 1968,
        "HLY Last Year": 1983,
        "DLY First Year": 1968,
        "DLY Last Year": 1983,
        "MLY First Year": 1969,
        "MLY Last Year": 1983
      },
      {
        "Name": "ST ANDREWS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403300",
        "Station ID": 6709,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.77,
        "Longitude (Decimal Degrees)": -59.33,
        "Latitude": 474600000,
        "Longitude": -592000000,
        "Elevation (m)": 10.7,
        "First Year": 1943,
        "Last Year": 1966,
        "HLY First Year": 1953,
        "HLY Last Year": 1966,
        "DLY First Year": 1943,
        "DLY Last Year": 1966,
        "MLY First Year": 1943,
        "MLY Last Year": 1966
      },
      {
        "Name": "ST. ANTHONY A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403389",
        "Station ID": 48348,
        "WMO ID": 71819,
        "TC ID": "YAY",
        "Latitude (Decimal Degrees)": 51.39,
        "Longitude (Decimal Degrees)": -56.07,
        "Latitude": 512331000,
        "Longitude": -560408000,
        "Elevation (m)": 32.9,
        "First Year": 2009,
        "Last Year": 2022,
        "HLY First Year": 2009,
        "HLY Last Year": 2022,
        "DLY First Year": 2009,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST ANTHONY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403399",
        "Station ID": 27205,
        "WMO ID": 71558,
        "TC ID": "WDW",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -56.1,
        "Latitude": 512300000,
        "Longitude": -560600000,
        "Elevation (m)": 29.3,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1996,
        "HLY Last Year": 2022,
        "DLY First Year": 1999,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST ANTHONY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403400",
        "Station ID": 6710,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -55.58,
        "Latitude": 512200000,
        "Longitude": -553500000,
        "Elevation (m)": 17.4,
        "First Year": 1945,
        "Last Year": 1965,
        "HLY First Year": 1953,
        "HLY Last Year": 1965,
        "DLY First Year": 1945,
        "DLY Last Year": 1965,
        "MLY First Year": 1946,
        "MLY Last Year": 1965
      },
      {
        "Name": "ST ANTHONY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403401",
        "Station ID": 6711,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.37,
        "Longitude (Decimal Degrees)": -55.6,
        "Latitude": 512200000,
        "Longitude": -553600000,
        "Elevation (m)": 11.5,
        "First Year": 1970,
        "Last Year": 1996,
        "HLY First Year": 1970,
        "HLY Last Year": 1996,
        "DLY First Year": 1970,
        "DLY Last Year": 1996,
        "MLY First Year": 1970,
        "MLY Last Year": 1996
      },
      {
        "Name": "ST ANTHONY A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403402",
        "Station ID": 6712,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.4,
        "Longitude (Decimal Degrees)": -56.08,
        "Latitude": 512400000,
        "Longitude": -560500000,
        "Elevation (m)": 28,
        "First Year": 1966,
        "Last Year": 1987,
        "HLY First Year": 1966,
        "HLY Last Year": 1987,
        "DLY First Year": 1966,
        "DLY Last Year": 1970,
        "MLY First Year": 1966,
        "MLY Last Year": 1970
      },
      {
        "Name": "ST ANTHONY AWOS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403403",
        "Station ID": 47507,
        "WMO ID": 71819,
        "TC ID": "YAY",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -56.1,
        "Latitude": 512300000,
        "Longitude": -560600000,
        "Elevation (m)": 32.9,
        "First Year": 2008,
        "Last Year": 2014,
        "HLY First Year": 2008,
        "HLY Last Year": 2009,
        "DLY First Year": 2009,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST BRENDANS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403410",
        "Station ID": 6713,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.87,
        "Longitude (Decimal Degrees)": -53.67,
        "Latitude": 485200000,
        "Longitude": -534000000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 1974,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1974,
        "MLY First Year": 1971,
        "MLY Last Year": 1974
      },
      {
        "Name": "ST BRIDE'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403417",
        "Station ID": 6714,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -54.18,
        "Latitude": 465519000,
        "Longitude": -541048000,
        "Elevation (m)": 15.2,
        "First Year": 1988,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 2001,
        "MLY First Year": 1988,
        "MLY Last Year": 2001
      },
      {
        "Name": "ST BRIDE'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403418",
        "Station ID": 6715,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -54.17,
        "Latitude": 465500000,
        "Longitude": -541000000,
        "Elevation (m)": 78.8,
        "First Year": 1984,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1987,
        "MLY First Year": 1984,
        "MLY Last Year": 1987
      },
      {
        "Name": "ST GEORGES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403448",
        "Station ID": 6716,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -58.5,
        "Latitude": 482500000,
        "Longitude": -583000000,
        "Elevation (m)": null,
        "First Year": 1895,
        "Last Year": 1945,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1895,
        "DLY Last Year": 1945,
        "MLY First Year": 1895,
        "MLY Last Year": 1945
      },
      {
        "Name": "ST GEORGES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403450",
        "Station ID": 6717,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.43,
        "Longitude (Decimal Degrees)": -58.47,
        "Latitude": 482600000,
        "Longitude": -582800000,
        "Elevation (m)": 11.7,
        "First Year": 1956,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1993,
        "MLY First Year": 1956,
        "MLY Last Year": 1993
      },
      {
        "Name": "ST JOHN'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403500",
        "Station ID": 6718,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -52.7,
        "Latitude": 473400000,
        "Longitude": -524200000,
        "Elevation (m)": 38.1,
        "First Year": 1874,
        "Last Year": 1956,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1874,
        "DLY Last Year": 1956,
        "MLY First Year": 1874,
        "MLY Last Year": 1956
      },
      {
        "Name": "ST JOHN'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403501",
        "Station ID": 6719,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.58,
        "Longitude (Decimal Degrees)": -52.73,
        "Latitude": 473500000,
        "Longitude": -524400000,
        "Elevation (m)": 61,
        "First Year": 1957,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1975,
        "MLY First Year": 1957,
        "MLY Last Year": 1975
      },
      {
        "Name": "ST. JOHN'S INTL A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403505",
        "Station ID": 50089,
        "WMO ID": 71801,
        "TC ID": "YYT",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -52.75,
        "Latitude": 473707000,
        "Longitude": -524509000,
        "Elevation (m)": 140.5,
        "First Year": 2012,
        "Last Year": 2022,
        "HLY First Year": 2012,
        "HLY Last Year": 2022,
        "DLY First Year": 2012,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST JOHN'S A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403506",
        "Station ID": 6720,
        "WMO ID": 71801,
        "TC ID": "YYT",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -52.74,
        "Latitude": 473720000,
        "Longitude": -524434000,
        "Elevation (m)": 140.5,
        "First Year": 1942,
        "Last Year": 2012,
        "HLY First Year": 1953,
        "HLY Last Year": 2012,
        "DLY First Year": 1942,
        "DLY Last Year": 2012,
        "MLY First Year": 1942,
        "MLY Last Year": 2012
      },
      {
        "Name": "ST JOHN'S THORBURN ROAD",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403523",
        "Station ID": 6721,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -52.8,
        "Latitude": 473400000,
        "Longitude": -524800000,
        "Elevation (m)": 185.9,
        "First Year": 1988,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1995,
        "MLY First Year": 1988,
        "MLY Last Year": 1995
      },
      {
        "Name": "ST JOHN'S WEST CDA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403600",
        "Station ID": 6722,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.52,
        "Longitude (Decimal Degrees)": -52.78,
        "Latitude": 473100000,
        "Longitude": -524700000,
        "Elevation (m)": 114.3,
        "First Year": 1950,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1950,
        "DLY Last Year": 1996,
        "MLY First Year": 1950,
        "MLY Last Year": 1996
      },
      {
        "Name": "ST JOHNS WEST CLIMATE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403603",
        "Station ID": 48871,
        "WMO ID": 71250,
        "TC ID": "AJW",
        "Latitude (Decimal Degrees)": 47.51,
        "Longitude (Decimal Degrees)": -52.78,
        "Latitude": 473048080,
        "Longitude": -524700020,
        "Elevation (m)": 110,
        "First Year": 2010,
        "Last Year": 2022,
        "HLY First Year": 2010,
        "HLY Last Year": 2022,
        "DLY First Year": 2010,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST JOHN'S WEST CDA CS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403605",
        "Station ID": 27115,
        "WMO ID": null,
        "TC ID": "XSW",
        "Latitude (Decimal Degrees)": 47.52,
        "Longitude (Decimal Degrees)": -52.78,
        "Latitude": 473056000,
        "Longitude": -524705000,
        "Elevation (m)": 114,
        "First Year": 1996,
        "Last Year": 2013,
        "HLY First Year": 1999,
        "HLY Last Year": 2013,
        "DLY First Year": 1996,
        "DLY Last Year": 2007,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "ST LAWRENCE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403615",
        "Station ID": 6723,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -55.38,
        "Latitude": 465500000,
        "Longitude": -552300000,
        "Elevation (m)": 48.5,
        "First Year": 1966,
        "Last Year": 1997,
        "HLY First Year": 1966,
        "HLY Last Year": 1996,
        "DLY First Year": 1966,
        "DLY Last Year": 1997,
        "MLY First Year": 1966,
        "MLY Last Year": 1997
      },
      {
        "Name": "ST LAWRENCE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403616",
        "Station ID": 27621,
        "WMO ID": null,
        "TC ID": "WDS",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -55.38,
        "Latitude": 465500000,
        "Longitude": -552300000,
        "Elevation (m)": 48.5,
        "First Year": 1994,
        "Last Year": 2006,
        "HLY First Year": 1998,
        "HLY Last Year": 2006,
        "DLY First Year": 1994,
        "DLY Last Year": 2006,
        "MLY First Year": 2004,
        "MLY Last Year": 2006
      },
      {
        "Name": "ST SHOTTS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403617",
        "Station ID": 6724,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.63,
        "Longitude (Decimal Degrees)": -53.58,
        "Latitude": 463800000,
        "Longitude": -533500000,
        "Elevation (m)": 45.7,
        "First Year": 1971,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1995,
        "MLY First Year": 1971,
        "MLY Last Year": 1995
      },
      {
        "Name": "ST STEPHENS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403618",
        "Station ID": 6725,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.77,
        "Longitude (Decimal Degrees)": -53.62,
        "Latitude": 464600000,
        "Longitude": -533700000,
        "Elevation (m)": 17.4,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "ST LAWRENCE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403619",
        "Station ID": 45567,
        "WMO ID": 71110,
        "TC ID": "ADS",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -55.38,
        "Latitude": 465500000,
        "Longitude": -552300000,
        "Elevation (m)": 48.5,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALMONIER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403620",
        "Station ID": 6726,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.27,
        "Longitude (Decimal Degrees)": -53.33,
        "Latitude": 471600000,
        "Longitude": -532000000,
        "Elevation (m)": 121.9,
        "First Year": 1967,
        "Last Year": 1977,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1977,
        "MLY First Year": 1967,
        "MLY Last Year": 1977
      },
      {
        "Name": "SALMONIER NATURE PARK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403621",
        "Station ID": 6727,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.27,
        "Longitude (Decimal Degrees)": -53.28,
        "Latitude": 471600000,
        "Longitude": -531700000,
        "Elevation (m)": 135.8,
        "First Year": 1977,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1977,
        "DLY Last Year": 2006,
        "MLY First Year": 1978,
        "MLY Last Year": 2006
      },
      {
        "Name": "SALMONIER NATURE PARK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403622",
        "Station ID": 30766,
        "WMO ID": null,
        "TC ID": "XSA",
        "Latitude (Decimal Degrees)": 47.26,
        "Longitude (Decimal Degrees)": -53.29,
        "Latitude": 471549000,
        "Longitude": -531711000,
        "Elevation (m)": 135.8,
        "First Year": 2001,
        "Last Year": 2007,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 2001,
        "DLY Last Year": 2007,
        "MLY First Year": 2001,
        "MLY Last Year": 2007
      },
      {
        "Name": "SALT POND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403623",
        "Station ID": 6728,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.09,
        "Longitude (Decimal Degrees)": -55.2,
        "Latitude": 470537000,
        "Longitude": -551206000,
        "Elevation (m)": 30.3,
        "First Year": 1974,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2006,
        "MLY First Year": 1974,
        "MLY Last Year": 2006
      },
      {
        "Name": "SANDY POINT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403635",
        "Station ID": 6729,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.67,
        "Longitude (Decimal Degrees)": -58.45,
        "Latitude": 484000000,
        "Longitude": -582700000,
        "Elevation (m)": 8.2,
        "First Year": 1889,
        "Last Year": 1912,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 1912,
        "MLY First Year": 1889,
        "MLY Last Year": 1912
      },
      {
        "Name": "SEAL COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403650",
        "Station ID": 6730,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -53.07,
        "Latitude": 472700000,
        "Longitude": -530400000,
        "Elevation (m)": 22.7,
        "First Year": 1961,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1988,
        "MLY First Year": 1961,
        "MLY Last Year": 1988
      },
      {
        "Name": "SIBLEY'S COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403667",
        "Station ID": 6731,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.05,
        "Longitude (Decimal Degrees)": -53.1,
        "Latitude": 480300000,
        "Longitude": -530600000,
        "Elevation (m)": 12,
        "First Year": 1989,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1998,
        "MLY First Year": 1989,
        "MLY Last Year": 1998
      },
      {
        "Name": "SIGNAL HILL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403669",
        "Station ID": 6732,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.57,
        "Longitude (Decimal Degrees)": -52.68,
        "Latitude": 473400000,
        "Longitude": -524100000,
        "Elevation (m)": 96,
        "First Year": 1984,
        "Last Year": 2001,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2001,
        "MLY First Year": 1984,
        "MLY Last Year": 2001
      },
      {
        "Name": "SNOOKS ARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403680",
        "Station ID": 6733,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.87,
        "Longitude (Decimal Degrees)": -55.72,
        "Latitude": 495200000,
        "Longitude": -554300000,
        "Elevation (m)": 15.2,
        "First Year": 1958,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1963,
        "MLY First Year": 1958,
        "MLY Last Year": 1963
      },
      {
        "Name": "SOPS ARM WHITE BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403690",
        "Station ID": 6734,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.77,
        "Longitude (Decimal Degrees)": -56.88,
        "Latitude": 494600000,
        "Longitude": -565300000,
        "Elevation (m)": 16.6,
        "First Year": 1979,
        "Last Year": 2021,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1979,
        "DLY Last Year": 2021,
        "MLY First Year": 1980,
        "MLY Last Year": 2006
      },
      {
        "Name": "SOUTH BRANCH",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403691",
        "Station ID": 6735,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.95,
        "Longitude (Decimal Degrees)": -58.97,
        "Latitude": 475700000,
        "Longitude": -585800000,
        "Elevation (m)": 31,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "SOUTH BROOK PASADENA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403693",
        "Station ID": 6736,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.02,
        "Longitude (Decimal Degrees)": -57.62,
        "Latitude": 490100000,
        "Longitude": -573700000,
        "Elevation (m)": 38.1,
        "First Year": 1985,
        "Last Year": 2017,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1985,
        "DLY Last Year": 2017,
        "MLY First Year": 1985,
        "MLY Last Year": 2006
      },
      {
        "Name": "SPRINGDALE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403700",
        "Station ID": 6737,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -56.08,
        "Latitude": 493000000,
        "Longitude": -560500000,
        "Elevation (m)": 23,
        "First Year": 1955,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1993,
        "MLY First Year": 1955,
        "MLY Last Year": 1993
      },
      {
        "Name": "SPRINGDALE GB FARM",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403702",
        "Station ID": 6738,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.45,
        "Longitude (Decimal Degrees)": -56.17,
        "Latitude": 492700000,
        "Longitude": -561000000,
        "Elevation (m)": 42.7,
        "First Year": 1980,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1999,
        "MLY First Year": 1980,
        "MLY Last Year": 1999
      },
      {
        "Name": "STANHOPE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403775",
        "Station ID": 6739,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.27,
        "Longitude (Decimal Degrees)": -55.1,
        "Latitude": 491600000,
        "Longitude": -550600000,
        "Elevation (m)": 3.1,
        "First Year": 1988,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1988,
        "DLY Last Year": 1997,
        "MLY First Year": 1988,
        "MLY Last Year": 1997
      },
      {
        "Name": "STAR BROOK (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403781",
        "Station ID": 10861,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.58,
        "Longitude (Decimal Degrees)": -57.23,
        "Latitude": 483500000,
        "Longitude": -571400000,
        "Elevation (m)": 290,
        "First Year": 1994,
        "Last Year": 1997,
        "HLY First Year": 1994,
        "HLY Last Year": 1997,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STEPHENVILLE A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403800",
        "Station ID": 6740,
        "WMO ID": 71815,
        "TC ID": "YJT",
        "Latitude (Decimal Degrees)": 48.53,
        "Longitude (Decimal Degrees)": -58.55,
        "Latitude": 483200000,
        "Longitude": -583300000,
        "Elevation (m)": 24.7,
        "First Year": 1942,
        "Last Year": 2014,
        "HLY First Year": 1953,
        "HLY Last Year": 2014,
        "DLY First Year": 1942,
        "DLY Last Year": 2014,
        "MLY First Year": 1942,
        "MLY Last Year": 2014
      },
      {
        "Name": "STEPHENVILLE A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403801",
        "Station ID": 52759,
        "WMO ID": 71815,
        "TC ID": "YJT",
        "Latitude (Decimal Degrees)": 48.54,
        "Longitude (Decimal Degrees)": -58.55,
        "Latitude": 483229000,
        "Longitude": -583300000,
        "Elevation (m)": 24.7,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "STEPHENVILLE CROSSING",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403815",
        "Station ID": 6741,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.57,
        "Longitude (Decimal Degrees)": -58.43,
        "Latitude": 483400000,
        "Longitude": -582600000,
        "Elevation (m)": 12.2,
        "First Year": 1967,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1967,
        "MLY First Year": 1967,
        "MLY Last Year": 1967
      },
      {
        "Name": "SUNNYSIDE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403818",
        "Station ID": 6742,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.87,
        "Longitude (Decimal Degrees)": -53.93,
        "Latitude": 475200000,
        "Longitude": -535600000,
        "Elevation (m)": 15.2,
        "First Year": 1971,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1995,
        "MLY First Year": 1971,
        "MLY Last Year": 1995
      },
      {
        "Name": "STEPHENVILLE RCS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403820",
        "Station ID": 47607,
        "WMO ID": 71159,
        "TC ID": "AJT",
        "Latitude (Decimal Degrees)": 48.56,
        "Longitude (Decimal Degrees)": -58.57,
        "Latitude": 483341000,
        "Longitude": -583358000,
        "Elevation (m)": 58,
        "First Year": 2008,
        "Last Year": 2022,
        "HLY First Year": 2008,
        "HLY Last Year": 2022,
        "DLY First Year": 2008,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SWIFT CURRENT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403825",
        "Station ID": 6743,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.89,
        "Longitude (Decimal Degrees)": -54.21,
        "Latitude": 475307000,
        "Longitude": -541247000,
        "Elevation (m)": 18.2,
        "First Year": 1984,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 2019,
        "MLY First Year": 1984,
        "MLY Last Year": 2006
      },
      {
        "Name": "TERRA NOVA COMMUNITY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403850",
        "Station ID": 6744,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.5,
        "Longitude (Decimal Degrees)": -54.22,
        "Latitude": 483000000,
        "Longitude": -541300000,
        "Elevation (m)": 93,
        "First Year": 1957,
        "Last Year": 1989,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1989,
        "MLY First Year": 1957,
        "MLY Last Year": 1989
      },
      {
        "Name": "TERRA NOVA NAT PARK CS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403851",
        "Station ID": 27142,
        "WMO ID": 71589,
        "TC ID": "XTP",
        "Latitude (Decimal Degrees)": 48.56,
        "Longitude (Decimal Degrees)": -53.97,
        "Latitude": 483325000,
        "Longitude": -535828000,
        "Elevation (m)": 106.7,
        "First Year": 1996,
        "Last Year": 2022,
        "HLY First Year": 1999,
        "HLY Last Year": 2022,
        "DLY First Year": 1996,
        "DLY Last Year": 2022,
        "MLY First Year": 1996,
        "MLY Last Year": 2007
      },
      {
        "Name": "TERRA NOVA NAT PARK HQ",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403852",
        "Station ID": 6745,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.55,
        "Longitude (Decimal Degrees)": -53.98,
        "Latitude": 483300000,
        "Longitude": -535900000,
        "Elevation (m)": 83.8,
        "First Year": 1962,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1996,
        "MLY First Year": 1962,
        "MLY Last Year": 1996
      },
      {
        "Name": "TERRA NOVA NAT PARK S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403854",
        "Station ID": 6746,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.45,
        "Longitude (Decimal Degrees)": -54.02,
        "Latitude": 482700000,
        "Longitude": -540100000,
        "Elevation (m)": 106.7,
        "First Year": 1962,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1969,
        "MLY First Year": 1962,
        "MLY Last Year": 1969
      },
      {
        "Name": "TERRENCEVILLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403858",
        "Station ID": 6747,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.67,
        "Longitude (Decimal Degrees)": -54.72,
        "Latitude": 474000000,
        "Longitude": -544300000,
        "Elevation (m)": 15.2,
        "First Year": 1980,
        "Last Year": 1994,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1980,
        "DLY Last Year": 1994,
        "MLY First Year": 1980,
        "MLY Last Year": 1994
      },
      {
        "Name": "THORNLEA T.B.",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403860",
        "Station ID": 6748,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.62,
        "Longitude (Decimal Degrees)": -53.73,
        "Latitude": 473700000,
        "Longitude": -534400000,
        "Elevation (m)": 30.5,
        "First Year": 1987,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1997,
        "MLY First Year": 1988,
        "MLY Last Year": 1997
      },
      {
        "Name": "TILT COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403866",
        "Station ID": 6749,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.88,
        "Longitude (Decimal Degrees)": -55.63,
        "Latitude": 495300000,
        "Longitude": -553800000,
        "Elevation (m)": 61,
        "First Year": 1958,
        "Last Year": 1963,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1958,
        "DLY Last Year": 1963,
        "MLY First Year": 1958,
        "MLY Last Year": 1963
      },
      {
        "Name": "TOMPKINS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403870",
        "Station ID": 6750,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.78,
        "Longitude (Decimal Degrees)": -59.23,
        "Latitude": 474700000,
        "Longitude": -591400000,
        "Elevation (m)": 10.1,
        "First Year": 1981,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1997,
        "MLY First Year": 1981,
        "MLY Last Year": 1997
      },
      {
        "Name": "TOPSAIL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403875",
        "Station ID": 6751,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.53,
        "Longitude (Decimal Degrees)": -52.92,
        "Latitude": 473200000,
        "Longitude": -525500000,
        "Elevation (m)": 15.2,
        "First Year": 1961,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 1967,
        "MLY First Year": 1961,
        "MLY Last Year": 1967
      },
      {
        "Name": "TORS COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403950",
        "Station ID": 6752,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.22,
        "Longitude (Decimal Degrees)": -52.85,
        "Latitude": 471300000,
        "Longitude": -525100000,
        "Elevation (m)": 6.1,
        "First Year": 1955,
        "Last Year": 1993,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1993,
        "MLY First Year": 1955,
        "MLY Last Year": 1993
      },
      {
        "Name": "TREPASSEY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403970",
        "Station ID": 6753,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.73,
        "Longitude (Decimal Degrees)": -53.17,
        "Latitude": 464400000,
        "Longitude": -531000000,
        "Elevation (m)": 128.3,
        "First Year": 1966,
        "Last Year": 1966,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1966,
        "DLY Last Year": 1966,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TREPASSEY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403971",
        "Station ID": 6754,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.77,
        "Longitude (Decimal Degrees)": -53.37,
        "Latitude": 464600000,
        "Longitude": -532200000,
        "Elevation (m)": 15.2,
        "First Year": 1982,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 1990,
        "MLY First Year": 1982,
        "MLY Last Year": 1990
      },
      {
        "Name": "26 MILE DEPOT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403990",
        "Station ID": 6755,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.42,
        "Longitude (Decimal Degrees)": -57.07,
        "Latitude": 482500000,
        "Longitude": -570400000,
        "Elevation (m)": 266.7,
        "First Year": 1962,
        "Last Year": 1962,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1962,
        "DLY Last Year": 1962,
        "MLY First Year": 1962,
        "MLY Last Year": 1962
      },
      {
        "Name": "SEAL COVE CB",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8403FN0",
        "Station ID": 6694,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.45,
        "Longitude (Decimal Degrees)": -53.07,
        "Latitude": 472700000,
        "Longitude": -530400000,
        "Elevation (m)": 33,
        "First Year": 1989,
        "Last Year": 1991,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1991,
        "MLY First Year": 1989,
        "MLY Last Year": 1991
      },
      {
        "Name": "TWILLINGATE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404000",
        "Station ID": 6756,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -54.82,
        "Latitude": 494100000,
        "Longitude": -544900000,
        "Elevation (m)": 4.9,
        "First Year": 1950,
        "Last Year": 1967,
        "HLY First Year": 1953,
        "HLY Last Year": 1967,
        "DLY First Year": 1950,
        "DLY Last Year": 1967,
        "MLY First Year": 1950,
        "MLY Last Year": 1966
      },
      {
        "Name": "TWILLINGATE (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404025",
        "Station ID": 7083,
        "WMO ID": 71402,
        "TC ID": "WDO",
        "Latitude (Decimal Degrees)": 49.68,
        "Longitude (Decimal Degrees)": -54.8,
        "Latitude": 494100000,
        "Longitude": -544800000,
        "Elevation (m)": 92.3,
        "First Year": 1993,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 1993,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "UPPER SALMON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404080",
        "Station ID": 6757,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.18,
        "Longitude (Decimal Degrees)": -56.17,
        "Latitude": 481100000,
        "Longitude": -561000000,
        "Elevation (m)": 189.9,
        "First Year": 1981,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 1983,
        "MLY First Year": 1981,
        "MLY Last Year": 1983
      },
      {
        "Name": "VICTORIA",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404100",
        "Station ID": 6758,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.77,
        "Longitude (Decimal Degrees)": -53.22,
        "Latitude": 474606000,
        "Longitude": -531304000,
        "Elevation (m)": 42.7,
        "First Year": 1961,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1961,
        "DLY Last Year": 2002,
        "MLY First Year": 1961,
        "MLY Last Year": 2002
      },
      {
        "Name": "WESTBROOK ST LAWRENCE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404201",
        "Station ID": 6759,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.95,
        "Longitude (Decimal Degrees)": -55.38,
        "Latitude": 465700000,
        "Longitude": -552300000,
        "Elevation (m)": 30.5,
        "First Year": 1957,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1957,
        "DLY Last Year": 1995,
        "MLY First Year": 1957,
        "MLY Last Year": 1995
      },
      {
        "Name": "WESTERN ARM BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404210",
        "Station ID": 6760,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -56.77,
        "Latitude": 511100000,
        "Longitude": -564600000,
        "Elevation (m)": 15.2,
        "First Year": 1981,
        "Last Year": 2016,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2016,
        "MLY First Year": 1981,
        "MLY Last Year": 2005
      },
      {
        "Name": "WHALESBACK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404225",
        "Station ID": 6761,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.6,
        "Longitude (Decimal Degrees)": -56,
        "Latitude": 493600000,
        "Longitude": -560000000,
        "Elevation (m)": 157,
        "First Year": 1965,
        "Last Year": 1969,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1965,
        "DLY Last Year": 1969,
        "MLY First Year": 1965,
        "MLY Last Year": 1969
      },
      {
        "Name": "WHITBOURNE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404234",
        "Station ID": 6939,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.42,
        "Longitude (Decimal Degrees)": -53.54,
        "Latitude": 472500000,
        "Longitude": -533215000,
        "Elevation (m)": 58,
        "First Year": 1991,
        "Last Year": 2014,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 2014,
        "MLY First Year": 1991,
        "MLY Last Year": 2006
      },
      {
        "Name": "WHITBOURNE T.B.",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404235",
        "Station ID": 6762,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.32,
        "Longitude (Decimal Degrees)": -53.55,
        "Latitude": 471900000,
        "Longitude": -533300000,
        "Elevation (m)": 56,
        "First Year": 1987,
        "Last Year": 1990,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1990,
        "MLY First Year": 1988,
        "MLY Last Year": 1990
      },
      {
        "Name": "WILTONDALE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404237",
        "Station ID": 6763,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.4,
        "Longitude (Decimal Degrees)": -57.62,
        "Latitude": 492400000,
        "Longitude": -573700000,
        "Elevation (m)": 132,
        "First Year": 1989,
        "Last Year": 1997,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1989,
        "DLY Last Year": 1997,
        "MLY First Year": 1989,
        "MLY Last Year": 1997
      },
      {
        "Name": "WINTERLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404240",
        "Station ID": 6764,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.17,
        "Longitude (Decimal Degrees)": -55.3,
        "Latitude": 471000000,
        "Longitude": -551800000,
        "Elevation (m)": 51.5,
        "First Year": 1981,
        "Last Year": 2006,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1981,
        "DLY Last Year": 2006,
        "MLY First Year": 1981,
        "MLY Last Year": 2006
      },
      {
        "Name": "WINTERLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404241",
        "Station ID": 27712,
        "WMO ID": 71181,
        "TC ID": "XWT",
        "Latitude (Decimal Degrees)": 47.14,
        "Longitude (Decimal Degrees)": -55.33,
        "Latitude": 470811000,
        "Longitude": -551939000,
        "Elevation (m)": 29.26,
        "First Year": 1999,
        "Last Year": 2018,
        "HLY First Year": 1999,
        "HLY Last Year": 2018,
        "DLY First Year": 1999,
        "DLY Last Year": 2018,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      },
      {
        "Name": "WINTERLAND BRANCH HILL",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404242",
        "Station ID": 54898,
        "WMO ID": 73080,
        "TC ID": "ABH",
        "Latitude (Decimal Degrees)": 47.14,
        "Longitude (Decimal Degrees)": -55.33,
        "Latitude": 470810000,
        "Longitude": -551941000,
        "Elevation (m)": 29.26,
        "First Year": 2018,
        "Last Year": 2022,
        "HLY First Year": 2018,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WOODDALE BISHOP'S FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404310",
        "Station ID": 6765,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.03,
        "Longitude (Decimal Degrees)": -55.55,
        "Latitude": 490200000,
        "Longitude": -553300000,
        "Elevation (m)": 45.7,
        "First Year": 1974,
        "Last Year": 2011,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1974,
        "DLY Last Year": 2011,
        "MLY First Year": 1974,
        "MLY Last Year": 2006
      },
      {
        "Name": "WOODY POINT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404320",
        "Station ID": 6766,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.5,
        "Longitude (Decimal Degrees)": -57.92,
        "Latitude": 493000000,
        "Longitude": -575500000,
        "Elevation (m)": 23,
        "First Year": 1971,
        "Last Year": 1995,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1971,
        "DLY Last Year": 1995,
        "MLY First Year": 1971,
        "MLY Last Year": 1995
      },
      {
        "Name": "WRECKHOUSE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404340",
        "Station ID": 29471,
        "WMO ID": null,
        "TC ID": "XWR",
        "Latitude (Decimal Degrees)": 47.71,
        "Longitude (Decimal Degrees)": -59.31,
        "Latitude": 474242000,
        "Longitude": -591830000,
        "Elevation (m)": 31.7,
        "First Year": 2000,
        "Last Year": 2006,
        "HLY First Year": 2000,
        "HLY Last Year": 2006,
        "DLY First Year": 2000,
        "DLY Last Year": 2006,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WRECKHOUSE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8404343",
        "Station ID": 45307,
        "WMO ID": 71180,
        "TC ID": "AWR",
        "Latitude (Decimal Degrees)": 47.71,
        "Longitude (Decimal Degrees)": -59.31,
        "Latitude": 474242000,
        "Longitude": -591830000,
        "Elevation (m)": 31.7,
        "First Year": 2006,
        "Last Year": 2022,
        "HLY First Year": 2006,
        "HLY Last Year": 2022,
        "DLY First Year": 2006,
        "DLY Last Year": 2022,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "GRATES COVE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840B053",
        "Station ID": 10818,
        "WMO ID": 71336,
        "TC ID": "WVW",
        "Latitude (Decimal Degrees)": 48.17,
        "Longitude (Decimal Degrees)": -52.94,
        "Latitude": 481019000,
        "Longitude": -525621000,
        "Elevation (m)": 46.2,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2002,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2004
      },
      {
        "Name": "LOURDES",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840B5HH",
        "Station ID": 6551,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 48.65,
        "Longitude (Decimal Degrees)": -58.98,
        "Latitude": 483900000,
        "Longitude": -585900000,
        "Elevation (m)": 12.2,
        "First Year": 1990,
        "Last Year": 2015,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2015,
        "MLY First Year": 1990,
        "MLY Last Year": 2006
      },
      {
        "Name": "POOLS ISLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840BR7C",
        "Station ID": 10817,
        "WMO ID": 71931,
        "TC ID": "WYI",
        "Latitude (Decimal Degrees)": 49.11,
        "Longitude (Decimal Degrees)": -53.58,
        "Latitude": 490644000,
        "Longitude": -533452000,
        "Elevation (m)": 19.3,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ST ANTHONY A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840C401",
        "Station ID": 10228,
        "WMO ID": 71819,
        "TC ID": "YAY",
        "Latitude (Decimal Degrees)": 51.38,
        "Longitude (Decimal Degrees)": -56.1,
        "Latitude": 512300000,
        "Longitude": -560600000,
        "Elevation (m)": 32.9,
        "First Year": 1987,
        "Last Year": 2014,
        "HLY First Year": 1987,
        "HLY Last Year": 2009,
        "DLY First Year": 1993,
        "DLY Last Year": 2014,
        "MLY First Year": 2001,
        "MLY Last Year": 2008
      },
      {
        "Name": "ST MARY'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840C616",
        "Station ID": 6552,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 46.92,
        "Longitude (Decimal Degrees)": -53.57,
        "Latitude": 465500000,
        "Longitude": -533400000,
        "Elevation (m)": 15.5,
        "First Year": 1982,
        "Last Year": 2000,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1982,
        "DLY Last Year": 2000,
        "MLY First Year": 1982,
        "MLY Last Year": 2000
      },
      {
        "Name": "ST. ANTHONY LWIS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840D401",
        "Station ID": 30622,
        "WMO ID": 71830,
        "TC ID": "AAY",
        "Latitude (Decimal Degrees)": 51.39,
        "Longitude (Decimal Degrees)": -56.09,
        "Latitude": 512326000,
        "Longitude": -560508000,
        "Elevation (m)": 33,
        "First Year": 2000,
        "Last Year": 2010,
        "HLY First Year": 2009,
        "HLY Last Year": 2010,
        "DLY First Year": 2000,
        "DLY Last Year": 2009,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GREAT BARASWAY P.B.",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840K0NC",
        "Station ID": 6554,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 47.13,
        "Longitude (Decimal Degrees)": -54.07,
        "Latitude": 470800000,
        "Longitude": -540400000,
        "Elevation (m)": 7,
        "First Year": 1987,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1987,
        "DLY Last Year": 1996,
        "MLY First Year": 1987,
        "MLY Last Year": 1996
      },
      {
        "Name": "MAIN BROOK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840KE88",
        "Station ID": 6553,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.18,
        "Longitude (Decimal Degrees)": -56.02,
        "Latitude": 511100000,
        "Longitude": -560100000,
        "Elevation (m)": 14,
        "First Year": 1983,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 2019,
        "MLY First Year": 1983,
        "MLY Last Year": 2006
      },
      {
        "Name": "MARTICOT ISLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840KN90",
        "Station ID": 10909,
        "WMO ID": 71692,
        "TC ID": "WTM",
        "Latitude (Decimal Degrees)": 47.33,
        "Longitude (Decimal Degrees)": -54.59,
        "Latitude": 471942003,
        "Longitude": -543507001,
        "Elevation (m)": 21.8,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2005,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "RODDICKTON NORTH",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840L0R9",
        "Station ID": 6932,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 50.88,
        "Longitude (Decimal Degrees)": -56.12,
        "Latitude": 505300000,
        "Longitude": -560700000,
        "Elevation (m)": 12,
        "First Year": 1991,
        "Last Year": 1999,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1991,
        "DLY Last Year": 1999,
        "MLY First Year": 1991,
        "MLY Last Year": 1999
      },
      {
        "Name": "ST SHOTTS (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840L617",
        "Station ID": 10854,
        "WMO ID": null,
        "TC ID": "WFH",
        "Latitude (Decimal Degrees)": 46.71,
        "Longitude (Decimal Degrees)": -53.49,
        "Latitude": 464232000,
        "Longitude": -532924000,
        "Elevation (m)": 142.5,
        "First Year": 1994,
        "Last Year": 1999,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MCIVER'S",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840M001",
        "Station ID": 27228,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 49.07,
        "Longitude (Decimal Degrees)": -58.12,
        "Latitude": 490400000,
        "Longitude": -580700000,
        "Elevation (m)": 49.5,
        "First Year": 1996,
        "Last Year": 2019,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1996,
        "DLY Last Year": 2019,
        "MLY First Year": 1996,
        "MLY Last Year": 2006
      },
      {
        "Name": "LONG POND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "840N001",
        "Station ID": 27286,
        "WMO ID": null,
        "TC ID": "WWU",
        "Latitude (Decimal Degrees)": 47.52,
        "Longitude (Decimal Degrees)": -52.98,
        "Latitude": 473057000,
        "Longitude": -525849000,
        "Elevation (m)": 12.5,
        "First Year": 1997,
        "Last Year": 2015,
        "HLY First Year": 1997,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ALEXIS RIVER (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500036",
        "Station ID": 10729,
        "WMO ID": null,
        "TC ID": "WXW",
        "Latitude (Decimal Degrees)": 52.65,
        "Longitude (Decimal Degrees)": -56.87,
        "Latitude": 523900000,
        "Longitude": -565200000,
        "Elevation (m)": 48.3,
        "First Year": 1994,
        "Last Year": 1999,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "ASHUANIPI",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500200",
        "Station ID": 6767,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.53,
        "Longitude (Decimal Degrees)": -66.23,
        "Latitude": 523200000,
        "Longitude": -661400000,
        "Elevation (m)": 545.6,
        "First Year": 1948,
        "Last Year": 1950,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1950,
        "MLY First Year": 1948,
        "MLY Last Year": 1950
      },
      {
        "Name": "BATTLE HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500395",
        "Station ID": 6768,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.28,
        "Longitude (Decimal Degrees)": -55.58,
        "Latitude": 521700000,
        "Longitude": -553500000,
        "Elevation (m)": 15.2,
        "First Year": 1947,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1983,
        "MLY First Year": 1947,
        "MLY Last Year": 1983
      },
      {
        "Name": "BATTLE HARBOUR LOR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500398",
        "Station ID": 6769,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.25,
        "Longitude (Decimal Degrees)": -55.6,
        "Latitude": 521500000,
        "Longitude": -553600000,
        "Elevation (m)": 9.4,
        "First Year": 1957,
        "Last Year": 1983,
        "HLY First Year": 1957,
        "HLY Last Year": 1983,
        "DLY First Year": 1957,
        "DLY Last Year": 1983,
        "MLY First Year": 1957,
        "MLY Last Year": 1983
      },
      {
        "Name": "BATTLE HARBOUR MARYS R",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500400",
        "Station ID": 6770,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.32,
        "Longitude (Decimal Degrees)": -55.83,
        "Latitude": 521900000,
        "Longitude": -555000000,
        "Elevation (m)": 6.1,
        "First Year": 1956,
        "Last Year": 1983,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1956,
        "DLY Last Year": 1983,
        "MLY First Year": 1956,
        "MLY Last Year": 1983
      },
      {
        "Name": "BELLE ISLE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500500",
        "Station ID": 6771,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.88,
        "Longitude (Decimal Degrees)": -55.38,
        "Latitude": 515300000,
        "Longitude": -552300000,
        "Elevation (m)": 129.8,
        "First Year": 1871,
        "Last Year": 1969,
        "HLY First Year": 1953,
        "HLY Last Year": 1969,
        "DLY First Year": 1871,
        "DLY Last Year": 1969,
        "MLY First Year": 1871,
        "MLY Last Year": 1969
      },
      {
        "Name": "BIG BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500535",
        "Station ID": 10842,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.74,
        "Longitude (Decimal Degrees)": -60.43,
        "Latitude": 554420000,
        "Longitude": -602547000,
        "Elevation (m)": 279.8,
        "First Year": 1992,
        "Last Year": 1993,
        "HLY First Year": 1992,
        "HLY Last Year": 1993,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "BIG BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500536",
        "Station ID": 54780,
        "WMO ID": 73234,
        "TC ID": "LAB",
        "Latitude (Decimal Degrees)": 55.74,
        "Longitude (Decimal Degrees)": -60.43,
        "Latitude": 554420000,
        "Longitude": -602547000,
        "Elevation (m)": 249.5,
        "First Year": 2021,
        "Last Year": 2021,
        "HLY First Year": 2021,
        "HLY Last Year": 2021,
        "DLY First Year": 2021,
        "DLY Last Year": 2021,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE HARRISON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500900",
        "Station ID": 6772,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.77,
        "Longitude (Decimal Degrees)": -58.45,
        "Latitude": 544600000,
        "Longitude": -582700000,
        "Elevation (m)": 10.1,
        "First Year": 1943,
        "Last Year": 1961,
        "HLY First Year": 1953,
        "HLY Last Year": 1961,
        "DLY First Year": 1943,
        "DLY Last Year": 1961,
        "MLY First Year": 1943,
        "MLY Last Year": 1961
      },
      {
        "Name": "CAPE KAKKIVIAK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500920",
        "Station ID": 10836,
        "WMO ID": 71176,
        "TC ID": "WKW",
        "Latitude (Decimal Degrees)": 59.99,
        "Longitude (Decimal Degrees)": -64.17,
        "Latitude": 595906000,
        "Longitude": -640954000,
        "Elevation (m)": 551.1,
        "First Year": 1994,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2018,
        "DLY First Year": 1994,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAPE KIGLAPAIT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8500926",
        "Station ID": 10841,
        "WMO ID": 71177,
        "TC ID": "WYK",
        "Latitude (Decimal Degrees)": 57.14,
        "Longitude (Decimal Degrees)": -61.48,
        "Latitude": 570809000,
        "Longitude": -612832000,
        "Elevation (m)": 834.1,
        "First Year": 1994,
        "Last Year": 2017,
        "HLY First Year": 1994,
        "HLY Last Year": 2017,
        "DLY First Year": 1994,
        "DLY Last Year": 2017,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CAROL LAKE PROJECT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501080",
        "Station ID": 6595,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.95,
        "Longitude (Decimal Degrees)": -66.9,
        "Latitude": 525700000,
        "Longitude": -665400000,
        "Elevation (m)": null,
        "First Year": 1959,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1959,
        "DLY Last Year": 1960,
        "MLY First Year": 1959,
        "MLY Last Year": 1960
      },
      {
        "Name": "CARTWRIGHT",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501100",
        "Station ID": 6773,
        "WMO ID": 71818,
        "TC ID": "WCA",
        "Latitude (Decimal Degrees)": 53.71,
        "Longitude (Decimal Degrees)": -57.04,
        "Latitude": 534230000,
        "Longitude": -570206000,
        "Elevation (m)": 14.3,
        "First Year": 1934,
        "Last Year": 2015,
        "HLY First Year": 1953,
        "HLY Last Year": 2015,
        "DLY First Year": 1934,
        "DLY Last Year": 2015,
        "MLY First Year": 1934,
        "MLY Last Year": 2015
      },
      {
        "Name": "CARTWRIGHT A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501101",
        "Station ID": 53578,
        "WMO ID": null,
        "TC ID": "WCA",
        "Latitude (Decimal Degrees)": 53.68,
        "Longitude (Decimal Degrees)": -57.04,
        "Latitude": 534057000,
        "Longitude": -570231000,
        "Elevation (m)": 14.3,
        "First Year": 2015,
        "Last Year": 2018,
        "HLY First Year": 2015,
        "HLY Last Year": 2018,
        "DLY First Year": 2015,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CARTWRIGHT A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501106",
        "Station ID": 53958,
        "WMO ID": 71818,
        "TC ID": "YCA",
        "Latitude (Decimal Degrees)": 53.68,
        "Longitude (Decimal Degrees)": -57.04,
        "Latitude": 534057000,
        "Longitude": -570231000,
        "Elevation (m)": 14.3,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHURCHILL FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501130",
        "Station ID": 10887,
        "WMO ID": null,
        "TC ID": "AFL",
        "Latitude (Decimal Degrees)": 53.56,
        "Longitude (Decimal Degrees)": -64.09,
        "Latitude": 533328000,
        "Longitude": -640538000,
        "Elevation (m)": 439.5,
        "First Year": 1992,
        "Last Year": 2021,
        "HLY First Year": 1994,
        "HLY Last Year": 2021,
        "DLY First Year": 1992,
        "DLY Last Year": 2021,
        "MLY First Year": 2006,
        "MLY Last Year": 2007
      },
      {
        "Name": "CHURCHILL FALLS A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501131",
        "Station ID": 49629,
        "WMO ID": 71002,
        "TC ID": "ZUM",
        "Latitude (Decimal Degrees)": 53.56,
        "Longitude (Decimal Degrees)": -64.11,
        "Latitude": 533343000,
        "Longitude": -640623000,
        "Elevation (m)": 439.5,
        "First Year": 2011,
        "Last Year": 2022,
        "HLY First Year": 2011,
        "HLY Last Year": 2022,
        "DLY First Year": 2011,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "CHURCHILL FALLS A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501132",
        "Station ID": 6774,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.55,
        "Longitude (Decimal Degrees)": -64.1,
        "Latitude": 533300000,
        "Longitude": -640600000,
        "Elevation (m)": 439.5,
        "First Year": 1968,
        "Last Year": 1993,
        "HLY First Year": 1968,
        "HLY Last Year": 1993,
        "DLY First Year": 1968,
        "DLY Last Year": 1993,
        "MLY First Year": 1969,
        "MLY Last Year": 1993
      },
      {
        "Name": "ESKER 2",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501548",
        "Station ID": 6775,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.87,
        "Longitude (Decimal Degrees)": -66.42,
        "Latitude": 535200000,
        "Longitude": -662500000,
        "Elevation (m)": 487.7,
        "First Year": 1972,
        "Last Year": 1978,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1972,
        "DLY Last Year": 1978,
        "MLY First Year": 1972,
        "MLY Last Year": 1978
      },
      {
        "Name": "FORTEAU",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501615",
        "Station ID": 6776,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -56.97,
        "Latitude": 512800000,
        "Longitude": -565800000,
        "Elevation (m)": null,
        "First Year": 1871,
        "Last Year": 1878,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1871,
        "DLY Last Year": 1878,
        "MLY First Year": 1871,
        "MLY Last Year": 1878
      },
      {
        "Name": "GOOSE A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501900",
        "Station ID": 6777,
        "WMO ID": 71816,
        "TC ID": "YYR",
        "Latitude (Decimal Degrees)": 53.32,
        "Longitude (Decimal Degrees)": -60.42,
        "Latitude": 531900000,
        "Longitude": -602500000,
        "Elevation (m)": 48.8,
        "First Year": 1941,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1941,
        "DLY Last Year": 2022,
        "MLY First Year": 1941,
        "MLY Last Year": 2017
      },
      {
        "Name": "HAPPY VALLEY GOOSE BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8501915",
        "Station ID": 55078,
        "WMO ID": 73120,
        "TC ID": "AHV",
        "Latitude (Decimal Degrees)": 53.41,
        "Longitude (Decimal Degrees)": -60.56,
        "Latitude": 532423099,
        "Longitude": -603338099,
        "Elevation (m)": 38,
        "First Year": 2020,
        "Last Year": 2022,
        "HLY First Year": 2020,
        "HLY Last Year": 2022,
        "DLY First Year": 2020,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "GULL ISLAND",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502059",
        "Station ID": 6779,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53,
        "Longitude (Decimal Degrees)": -61.5,
        "Latitude": 530000000,
        "Longitude": -613000000,
        "Elevation (m)": 120.1,
        "First Year": 1975,
        "Last Year": 1976,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1975,
        "DLY Last Year": 1976,
        "MLY First Year": 1975,
        "MLY Last Year": 1976
      },
      {
        "Name": "HEBRON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502200",
        "Station ID": 6780,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.22,
        "Longitude (Decimal Degrees)": -62.58,
        "Latitude": 581300000,
        "Longitude": -623500000,
        "Elevation (m)": 7.6,
        "First Year": 1947,
        "Last Year": 1957,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1947,
        "DLY Last Year": 1957,
        "MLY First Year": 1947,
        "MLY Last Year": 1955
      },
      {
        "Name": "HOPEDALE (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502400",
        "Station ID": 6781,
        "WMO ID": 71900,
        "TC ID": "WHO",
        "Latitude (Decimal Degrees)": 55.45,
        "Longitude (Decimal Degrees)": -60.22,
        "Latitude": 552700000,
        "Longitude": -601300000,
        "Elevation (m)": 11.9,
        "First Year": 1942,
        "Last Year": 2022,
        "HLY First Year": 1953,
        "HLY Last Year": 2022,
        "DLY First Year": 1942,
        "DLY Last Year": 2022,
        "MLY First Year": 1942,
        "MLY Last Year": 2007
      },
      {
        "Name": "LITTLE MECATINA RIVER (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502561",
        "Station ID": 10862,
        "WMO ID": null,
        "TC ID": "WFU",
        "Latitude (Decimal Degrees)": 52.23,
        "Longitude (Decimal Degrees)": -61.32,
        "Latitude": 521400000,
        "Longitude": -611900000,
        "Elevation (m)": 321,
        "First Year": 1994,
        "Last Year": 1999,
        "HLY First Year": 1994,
        "HLY Last Year": 1999,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MAKKOVIK A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502580",
        "Station ID": 53506,
        "WMO ID": null,
        "TC ID": "YFT",
        "Latitude (Decimal Degrees)": 55.08,
        "Longitude (Decimal Degrees)": -59.19,
        "Latitude": 550438000,
        "Longitude": -591116000,
        "Elevation (m)": 70.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MAKKOVIK A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502588",
        "Station ID": 53505,
        "WMO ID": null,
        "TC ID": "YFT",
        "Latitude (Decimal Degrees)": 55.08,
        "Longitude (Decimal Degrees)": -59.19,
        "Latitude": 550438000,
        "Longitude": -591116000,
        "Elevation (m)": 70.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MAKKOVIK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502589",
        "Station ID": 6782,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.08,
        "Longitude (Decimal Degrees)": -59.18,
        "Latitude": 550500000,
        "Longitude": -591100000,
        "Elevation (m)": 13.1,
        "First Year": 1983,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1985,
        "MLY First Year": 1983,
        "MLY Last Year": 1985
      },
      {
        "Name": "MARY'S HARBOUR A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502590",
        "Station ID": 51999,
        "WMO ID": null,
        "TC ID": "YMH",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -55.85,
        "Latitude": 521810000,
        "Longitude": -555052000,
        "Elevation (m)": 10.7,
        "First Year": 2013,
        "Last Year": 2015,
        "HLY First Year": 2013,
        "HLY Last Year": 2015,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARY'S HARBOUR A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502591",
        "Station ID": 6783,
        "WMO ID": 71817,
        "TC ID": "YMH",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -55.83,
        "Latitude": 521813000,
        "Longitude": -555001000,
        "Elevation (m)": 10.6,
        "First Year": 1983,
        "Last Year": 2013,
        "HLY First Year": 1983,
        "HLY Last Year": 2013,
        "DLY First Year": 1983,
        "DLY Last Year": 2013,
        "MLY First Year": 1983,
        "MLY Last Year": 1998
      },
      {
        "Name": "MARY'S HARBOUR A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502592",
        "Station ID": 51918,
        "WMO ID": 71339,
        "TC ID": "YMH",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -55.85,
        "Latitude": 521810000,
        "Longitude": -555052000,
        "Elevation (m)": 10.6,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MENIHEK RAPIDS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502600",
        "Station ID": 6784,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.47,
        "Longitude (Decimal Degrees)": -66.62,
        "Latitude": 542800000,
        "Longitude": -663700000,
        "Elevation (m)": 489.2,
        "First Year": 1952,
        "Last Year": 1961,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1961,
        "MLY First Year": 1952,
        "MLY Last Year": 1961
      },
      {
        "Name": "MILE 163",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502700",
        "Station ID": 6785,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.27,
        "Longitude (Decimal Degrees)": -65.68,
        "Latitude": 521600000,
        "Longitude": -654100000,
        "Elevation (m)": 600.5,
        "First Year": 1952,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1952,
        "DLY Last Year": 1953,
        "MLY First Year": 1952,
        "MLY Last Year": 1953
      },
      {
        "Name": "MILE 224",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502750",
        "Station ID": 6786,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.93,
        "Longitude (Decimal Degrees)": -66.23,
        "Latitude": 525600000,
        "Longitude": -661400000,
        "Elevation (m)": 533.4,
        "First Year": 1955,
        "Last Year": 1955,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1955,
        "MLY First Year": 1955,
        "MLY Last Year": 1955
      },
      {
        "Name": "NAIN",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502799",
        "Station ID": 10813,
        "WMO ID": 71665,
        "TC ID": "WFP",
        "Latitude (Decimal Degrees)": 56.55,
        "Longitude (Decimal Degrees)": -61.68,
        "Latitude": 563300000,
        "Longitude": -614100000,
        "Elevation (m)": 7.6,
        "First Year": 1994,
        "Last Year": 2022,
        "HLY First Year": 1994,
        "HLY Last Year": 2022,
        "DLY First Year": 2004,
        "DLY Last Year": 2022,
        "MLY First Year": 2004,
        "MLY Last Year": 2007
      },
      {
        "Name": "NAIN A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502800",
        "Station ID": 6787,
        "WMO ID": 71902,
        "TC ID": "YDP",
        "Latitude (Decimal Degrees)": 56.55,
        "Longitude (Decimal Degrees)": -61.68,
        "Latitude": 563300000,
        "Longitude": -614100000,
        "Elevation (m)": 6.4,
        "First Year": 1926,
        "Last Year": 2015,
        "HLY First Year": 1984,
        "HLY Last Year": 2015,
        "DLY First Year": 1926,
        "DLY Last Year": 2015,
        "MLY First Year": 1927,
        "MLY Last Year": 2012
      },
      {
        "Name": "NAIN A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502801",
        "Station ID": 53507,
        "WMO ID": 71902,
        "TC ID": "YDP",
        "Latitude (Decimal Degrees)": 56.55,
        "Longitude (Decimal Degrees)": -61.68,
        "Latitude": 563302000,
        "Longitude": -614056000,
        "Elevation (m)": 6.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2015,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NAIN A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502810",
        "Station ID": 53502,
        "WMO ID": null,
        "TC ID": "YDP",
        "Latitude (Decimal Degrees)": 56.55,
        "Longitude (Decimal Degrees)": -61.68,
        "Latitude": 563302000,
        "Longitude": -614056000,
        "Elevation (m)": 6.4,
        "First Year": 2015,
        "Last Year": 2022,
        "HLY First Year": 2015,
        "HLY Last Year": 2022,
        "DLY First Year": 2018,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "NORTH WEST RIVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502875",
        "Station ID": 6788,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -60.15,
        "Latitude": 533200000,
        "Longitude": -600900000,
        "Elevation (m)": 61,
        "First Year": 1901,
        "Last Year": 1973,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1901,
        "DLY Last Year": 1973,
        "MLY First Year": 1906,
        "MLY Last Year": 1973
      },
      {
        "Name": "NORTH WEST RIVER",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502876",
        "Station ID": 6789,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.52,
        "Longitude (Decimal Degrees)": -60.15,
        "Latitude": 533100000,
        "Longitude": -600900000,
        "Elevation (m)": 4.3,
        "First Year": 1984,
        "Last Year": 1985,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1985,
        "MLY First Year": 1984,
        "MLY Last Year": 1985
      },
      {
        "Name": "NUTAK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502900",
        "Station ID": 6790,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 57.47,
        "Longitude (Decimal Degrees)": -61.83,
        "Latitude": 572800000,
        "Longitude": -615000000,
        "Elevation (m)": 1.5,
        "First Year": 1948,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1948,
        "DLY Last Year": 1953,
        "MLY First Year": 1948,
        "MLY Last Year": 1953
      },
      {
        "Name": "POINT AMOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502960",
        "Station ID": 6791,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.47,
        "Longitude (Decimal Degrees)": -56.85,
        "Latitude": 512800000,
        "Longitude": -565100000,
        "Elevation (m)": 47.2,
        "First Year": 1889,
        "Last Year": 1935,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1889,
        "DLY Last Year": 1935,
        "MLY First Year": 1889,
        "MLY Last Year": 1935
      },
      {
        "Name": "MAKKOVIK A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8502NHR",
        "Station ID": 6778,
        "WMO ID": null,
        "TC ID": "YFT",
        "Latitude (Decimal Degrees)": 55.08,
        "Longitude (Decimal Degrees)": -59.19,
        "Latitude": 550456000,
        "Longitude": -591119000,
        "Elevation (m)": 70.4,
        "First Year": 1985,
        "Last Year": 2015,
        "HLY First Year": 1985,
        "HLY Last Year": 2015,
        "DLY First Year": 1986,
        "DLY Last Year": 2015,
        "MLY First Year": 1986,
        "MLY Last Year": 2006
      },
      {
        "Name": "PORT HOPE SIMPSON",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503018",
        "Station ID": 6792,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.53,
        "Longitude (Decimal Degrees)": -56.3,
        "Latitude": 523200000,
        "Longitude": -561800000,
        "Elevation (m)": 10,
        "First Year": 1983,
        "Last Year": 1988,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1983,
        "DLY Last Year": 1988,
        "MLY First Year": 1983,
        "MLY Last Year": 1988
      },
      {
        "Name": "RED BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503081",
        "Station ID": 6793,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.73,
        "Longitude (Decimal Degrees)": -56.43,
        "Latitude": 514400000,
        "Longitude": -562600000,
        "Elevation (m)": 18.3,
        "First Year": 1990,
        "Last Year": 1996,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 1996,
        "MLY First Year": 1990,
        "MLY Last Year": 1996
      },
      {
        "Name": "RIGOLET",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503090",
        "Station ID": 6794,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 54.18,
        "Longitude (Decimal Degrees)": -58.43,
        "Latitude": 541100000,
        "Longitude": -582600000,
        "Elevation (m)": 30.5,
        "First Year": 1973,
        "Last Year": 1975,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1973,
        "DLY Last Year": 1975,
        "MLY First Year": 1973,
        "MLY Last Year": 1975
      },
      {
        "Name": "ROSS BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503100",
        "Station ID": 6795,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 52.98,
        "Longitude (Decimal Degrees)": -66.23,
        "Latitude": 525900000,
        "Longitude": -661400000,
        "Elevation (m)": 554.4,
        "First Year": 1953,
        "Last Year": 1953,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1953,
        "DLY Last Year": 1953,
        "MLY First Year": 1953,
        "MLY Last Year": 1953
      },
      {
        "Name": "ROSS BAY BNC",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503200",
        "Station ID": 6796,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.03,
        "Longitude (Decimal Degrees)": -66.23,
        "Latitude": 530200000,
        "Longitude": -661400000,
        "Elevation (m)": 548.6,
        "First Year": 1954,
        "Last Year": 1954,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1954,
        "DLY Last Year": 1954,
        "MLY First Year": 1954,
        "MLY Last Year": 1954
      },
      {
        "Name": "SAGLEK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503249",
        "Station ID": 6797,
        "WMO ID": 71335,
        "TC ID": "WZZ",
        "Latitude (Decimal Degrees)": 58.48,
        "Longitude (Decimal Degrees)": -62.59,
        "Latitude": 582900000,
        "Longitude": -623508000,
        "Elevation (m)": 488,
        "First Year": 1989,
        "Last Year": 2022,
        "HLY First Year": 1989,
        "HLY Last Year": 2022,
        "DLY First Year": 1989,
        "DLY Last Year": 2022,
        "MLY First Year": 1989,
        "MLY Last Year": 1993
      },
      {
        "Name": "SAGLEK",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503250",
        "Station ID": 6798,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.48,
        "Longitude (Decimal Degrees)": -62.65,
        "Latitude": 582900000,
        "Longitude": -623900000,
        "Elevation (m)": 21.3,
        "First Year": 1955,
        "Last Year": 1960,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1955,
        "DLY Last Year": 1960,
        "MLY First Year": 1955,
        "MLY Last Year": 1960
      },
      {
        "Name": "SAGLEK A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503252",
        "Station ID": 10230,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 58.47,
        "Longitude (Decimal Degrees)": -62.65,
        "Latitude": 582828000,
        "Longitude": -623917000,
        "Elevation (m)": 82.9,
        "First Year": 1991,
        "Last Year": 1994,
        "HLY First Year": 1991,
        "HLY Last Year": 1994,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "SANDGIRT LAKE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503630",
        "Station ID": 6799,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.83,
        "Longitude (Decimal Degrees)": -65.5,
        "Latitude": 535000000,
        "Longitude": -653000000,
        "Elevation (m)": 452.6,
        "First Year": 1939,
        "Last Year": 1948,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1939,
        "DLY Last Year": 1948,
        "MLY First Year": 1939,
        "MLY Last Year": 1948
      },
      {
        "Name": "TUKIALIK BAY",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8503992",
        "Station ID": 10837,
        "WMO ID": 71179,
        "TC ID": "WTU",
        "Latitude (Decimal Degrees)": 54.72,
        "Longitude (Decimal Degrees)": -58.36,
        "Latitude": 544257000,
        "Longitude": -582128000,
        "Elevation (m)": 682.6,
        "First Year": 1994,
        "Last Year": 2018,
        "HLY First Year": 1994,
        "HLY Last Year": 2018,
        "DLY First Year": 1994,
        "DLY Last Year": 2018,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "TWIN FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504050",
        "Station ID": 6800,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.5,
        "Longitude (Decimal Degrees)": -64.52,
        "Latitude": 533000000,
        "Longitude": -643100000,
        "Elevation (m)": 483.1,
        "First Year": 1960,
        "Last Year": 1967,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1960,
        "DLY Last Year": 1967,
        "MLY First Year": 1960,
        "MLY Last Year": 1967
      },
      {
        "Name": "TWIN FALLS A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504060",
        "Station ID": 6801,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.63,
        "Longitude (Decimal Degrees)": -64.48,
        "Latitude": 533800000,
        "Longitude": -642900000,
        "Elevation (m)": 456.9,
        "First Year": 1967,
        "Last Year": 1968,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1967,
        "DLY Last Year": 1968,
        "MLY First Year": 1967,
        "MLY Last Year": 1968
      },
      {
        "Name": "WABUSH LAKE A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504175",
        "Station ID": 6802,
        "WMO ID": 71825,
        "TC ID": "YWK",
        "Latitude (Decimal Degrees)": 52.93,
        "Longitude (Decimal Degrees)": -66.87,
        "Latitude": 525538000,
        "Longitude": -665227000,
        "Elevation (m)": 551.38,
        "First Year": 1960,
        "Last Year": 2013,
        "HLY First Year": 1961,
        "HLY Last Year": 2013,
        "DLY First Year": 1960,
        "DLY Last Year": 2013,
        "MLY First Year": 1960,
        "MLY Last Year": 2008
      },
      {
        "Name": "WABUSH A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504176",
        "Station ID": 51057,
        "WMO ID": null,
        "TC ID": "YWK",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -66.86,
        "Latitude": 525522000,
        "Longitude": -665153000,
        "Elevation (m)": 551.4,
        "First Year": 2013,
        "Last Year": 2022,
        "HLY First Year": 2013,
        "HLY Last Year": 2022,
        "DLY First Year": 2013,
        "DLY Last Year": 2014,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WABUSH A",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504177",
        "Station ID": 52541,
        "WMO ID": 71825,
        "TC ID": "YWK",
        "Latitude (Decimal Degrees)": 52.92,
        "Longitude (Decimal Degrees)": -66.86,
        "Latitude": 525522000,
        "Longitude": -665153000,
        "Elevation (m)": 551.4,
        "First Year": 2014,
        "Last Year": 2022,
        "HLY First Year": 2014,
        "HLY Last Year": 2022,
        "DLY First Year": 2014,
        "DLY Last Year": 2022,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "WEST ST MODESTE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504216",
        "Station ID": 6803,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.6,
        "Longitude (Decimal Degrees)": -56.7,
        "Latitude": 513600000,
        "Longitude": -564200000,
        "Elevation (m)": 12.2,
        "First Year": 1990,
        "Last Year": 2002,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1990,
        "DLY Last Year": 2002,
        "MLY First Year": 1990,
        "MLY Last Year": 2002
      },
      {
        "Name": "WEST ST MODESTE",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "8504217",
        "Station ID": 6804,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 51.58,
        "Longitude (Decimal Degrees)": -56.72,
        "Latitude": 513500000,
        "Longitude": -564300000,
        "Elevation (m)": 15.2,
        "First Year": 1984,
        "Last Year": 1987,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1984,
        "DLY Last Year": 1987,
        "MLY First Year": 1984,
        "MLY Last Year": 1987
      },
      {
        "Name": "CHURCHILL FALLS",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "850A131",
        "Station ID": 6940,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 53.53,
        "Longitude (Decimal Degrees)": -63.97,
        "Latitude": 533200000,
        "Longitude": -635800000,
        "Elevation (m)": 488.5,
        "First Year": 1993,
        "Last Year": 1998,
        "HLY First Year": null,
        "HLY Last Year": null,
        "DLY First Year": 1993,
        "DLY Last Year": 1998,
        "MLY First Year": 1993,
        "MLY Last Year": 1998
      },
      {
        "Name": "MAKKOVIK (AUT)",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "850B5HR",
        "Station ID": 9025,
        "WMO ID": null,
        "TC ID": "",
        "Latitude (Decimal Degrees)": 55.08,
        "Longitude (Decimal Degrees)": -59.17,
        "Latitude": 550500000,
        "Longitude": -591000000,
        "Elevation (m)": 71.3,
        "First Year": 1985,
        "Last Year": 1986,
        "HLY First Year": 1985,
        "HLY Last Year": 1986,
        "DLY First Year": null,
        "DLY Last Year": null,
        "MLY First Year": null,
        "MLY Last Year": null
      },
      {
        "Name": "MARY'S HARBOUR",
        "Province": "NEWFOUNDLAND",
        "Climate ID": "850B5R1",
        "Station ID": 10227,
        "WMO ID": 71339,
        "TC ID": "YMH",
        "Latitude (Decimal Degrees)": 52.3,
        "Longitude (Decimal Degrees)": -55.83,
        "Latitude": 521813000,
        "Longitude": -554957000,
        "Elevation (m)": 11.6,
        "First Year": 1992,
        "Last Year": 2014,
        "HLY First Year": 1994,
        "HLY Last Year": 2014,
        "DLY First Year": 1992,
        "DLY Last Year": 2013,
        "MLY First Year": 2005,
        "MLY Last Year": 2007
      }
];

export function buildStationList () {
   return stationList;
}

