import React from "react";

export default class Checkbox extends React.Component {
    render() {
        return (
            <div>
                    <input
                        type="checkbox"
                        name={this.props.label}
                        checked={this.props.isSelected}
                        onChange={this.props.onChange}
                        className={this.props.className}
                        disabled={this.props.disabled}
                    />
            </div>
        )
    }
}