import React, { useState } from "react";
import { Auth} from "aws-amplify";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    password: ""
  });

  function validateForm() {
    return fields.username.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    props.handleLogout();

    try {
      await Auth.signIn(fields.username, fields.password);
      props.userHasAuthenticated(true);
      //set user details here!!!   //moved since component leaves before set is finished, causing warning
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (

    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" >
          <FormLabel className="ldlabel">Username</FormLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.username}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" >
          <FormLabel className="ldlabel">Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          className="ldloaderbutton"
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}