import React, { useEffect } from "react";
import { Card, Figure, Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";

import earthImage from "../media/537521main_earth_pacific_full.jpg";
import measureImage from "../media/measure.jpg";
import modelImage from "../media/model.jpg";
import model104Image from "../media/104.jpg";
import roadmapImage from "../media/roadmap.jpg";
import bookImage from "../media/bookcover.jpg";
import ahrrtrImage from "../media/ahrrtr.jpg";
import tempImpactImage from "../media/tempimpact.jpg";
import aimforResultImage from "../media/aimforResult.jpg";
import ahrnowtco2eImage from "../media/AHRNow-tCO2e.png";
import { Helmet } from "react-helmet";

export default function News(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function renderApril32021() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    TakeBackTheHeat.org is now launched!
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={earthImage}
                                />
                                <Figure.Caption><Link to="/Etc"><sub>[4]</sub></Link></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>
                                TakeBackTheHeat.org is now available.
                            </p>
                            <p>
                                The planet is slowly heating up, causing 'global warming' and 'climate change' issues.
                                Energy received from the Sun was roughly balanced with what was outgoing, but that ratio is changing.
                                'Progress' and the increase in population has resulted in greenhouse gases (GHG) and other concerns that have changed the equation, we now have more heat retained in the atmosphere and increasing the surface temperature, which has undesired consequences.
                                At Take Back the Heat we are looking at addressing the immediate concern of the heat itself.
                            </p>
                            <p>
                                Let us know what you think, the forum is available for feedback.
                            </p>
                            <p>
                                Spread the word, let's get going on brainstorming, bringing forth solutions, and making a difference!
                            </p>
                            <p>
                                <i>Yesterday is not tomorrow</i>.  We <i>can</i> fix it.
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    April 3, 2021
                </Card.Footer>

            </Card>
        )
    }

    function renderJune212021() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Data Logging
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={measureImage}
                                />
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>
                                Along with revised content, data logging has been added for registered devices.
                                Logging the temperatures (cold and hot side), power generated,
                                and other details allows the tracking and comparisons of solutions.
                            </p>
                            <p>
                                After adding a device (Registry page), a read key and a write key will be generated, and only you have online access to the keys.
                                The datalogs will be visible publicly (after logging in), later to be summarized and presented as part of the bigger picture.
                                Next up will be to summarize this data into daily averages for public reporting and integration into the map (Where page).
                            </p>
                            <p>
                                Devices without data logging can also be added, where estimates are entered manually.
                            </p>
                            <p>
                                Contact us if you are interested in being an early adopter of this, further instructions will be provided.
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    June 21, 2021
                </Card.Footer>

            </Card>

        )
    }

    function renderAug052021() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Temperature Conversion Model
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={modelImage}
                                />
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>
                                Model to show impact of conversions.
                            </p>
                            <p>
                                The 'Program | Model' tab shows the projected conversions and totals for each class of solution through to 2100, and allows you to plug in your own estimates to determine the impact.
                                The ability to share these with others has also been added.
                            </p>
                            <p>
                                A category 'Program' is available in the Forum section for further information and discussions on this modeling effort.
                                While not a magic solution, we can see through this model that converting heat to other forms of energy worldwide will make a difference when we act together globally.
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Aug 5, 2021
                </Card.Footer>

            </Card>

        )
    }

    function renderAug152021() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Program Roadmap
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={roadmapImage}
                                />
                                <Figure.Caption><Link to="/Etc"><sub>[4]</sub></Link></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>
                                A roadmap of possible projects.
                            </p>
                            <p>
                                The 'Program | Roadmap' tab shows a high level perspective of the projects, with rough estimated costs.
                            </p>
                            <p>
                                A category 'Program' is available in the Forum section for further information and discussions on this program roadmap.
                                The projects include those delivered by takebacktheheat efforts (indicated as [int]) as well as probably external projects (indicated as [ext]).
                                The estimate amounts when included show minimal start funding, not entire project, as many factors such as timing and location are involved.
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Aug 15, 2021
                </Card.Footer>

            </Card>

        )
    }

    function renderOct272021() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Reducing global warming by reducing atmospheric heat directly.
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={model104Image}
                                />
                                <Figure.Caption><Link to="/model?sy=2021&ey=2100&t=68900000000000000&tDesc=Total days of average 1 watt, for 1 degree Celsius change&hslName=HeatScavengerLarge&hslNote=high heat large scale scavengers 8MW&hsl100=20&hslStart=2026&hslSites=10000&hslDaily=8000000&hslLoss=10&hsmName=HeatScavengerMedium&hsmNote=mid scale scavengers 75KW&hsm100=15&hsmStart=2024&hsmSites=15000&hsmDaily=75000&hsmLoss=5&o1Name=Other1&o1Note=Small solutions everywhere&o1100=20&o1Start=2025&o1Sites=1000000&o1Daily=1000000&o1Loss=10&o2Name=Other2&o2Note=New improved large solutions&o2100=20&o2Start=2035&o2Sites=10000&o2Daily=50000000&o2Loss=10&o3Name=Other3&o3Note=New improved large solutions&o3100=20&o3Start=2040&o3Sites=10000&o3Daily=100000000&o3Loss=10&pbmName=PVBackYardMedium&pbmNote=garage panels etc.  estimate 25 panels @ 400W each, half day of light&pbm100=10&pbmStart=2023&pbmSites=1000000&pbmDaily=5000&pbmLoss=20&pbsName=PVBackYardSmall&pbsNote=backyard panels&pbs100=10&pbsStart=2021&pbsSites=2000000&pbsDaily=100&pbsLoss=20&pflName=PVFarmLarge&pflNote=Photovoltaic farm large size  400MW&pfl100=20&pflStart=2025&pflSites=1000&pflDaily=400000000&pflLoss=20&pfmName=PVFarmMedium&pfmNote=medium scale panels deployed 75MW&pfm100=25&pfmStart=2023&pfmSites=10000&pfmDaily=75000000&pfmLoss=20&pfsName=PVFarmSmall&pfsNote=PV Farm 1MW&pfs100=20&pfsStart=2023&pfsSites=10000&pfsDaily=1000000&pfsLoss=20"><sub>model</sub></Link></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>

                            </p>
                            <p>
                                Our focus is not on carbon, it is on atmospheric heat.
                            </p>
                            <p>
                                Other organizations have climate change projects including carbon scrubbers, vehicle and building electrification, and afforestation efforts that will take decades to affect the carbon in the atmosphere enough to begin reducing heat.
                                TakeBackTheHeat.org projects work on removing heat from the environment directly, starting <i>now</i>.
                                Our goal is at least .5°C by 2050 and 1°C reduction by 2100, with faster results possible.
                                This will buy some much needed time for the other projects to have an impact.
                            </p>
                            <p>
                                Heat reduction projects address the 4 sources of heat: incoming solar radiation; earth radiation; existing atmospheric heat; and manmade waste heat.
                                External (to takebacktheheat team) projects can self register on this site to maintain a single tally of progress worldwide.
                                Shown is a simplified model of some basic projects, generalized for ease of understanding.
                                Reality will be many variations of the 4 project types, improving over the years and deployed globally.
                                We win when we work willingly worldwide.
                            </p>
                            <p>
                                Our efforts do not fit conventional thinking; the projects do not qualify for popular carbon funding competitions or considerations.
                                But they will work, with your support.
                                Please review the information provided at TakeBackTheHeat.org and contact us for further discussion on how you can get involved.
                            </p>
                            <p>
                                Progress is slow, due to a lack of funding.  But we will get there.
                            </p>
                            <p>
                                Thank you.
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Oct 27, 2021
                </Card.Footer>

            </Card>

        )
    }

    function renderFeb052022() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Now available in print and ebook!
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={bookImage}
                                />
                                <Figure.Caption><a href="https://www.amazon.ca/dp/B09R94L6N9/ref=cm_sw_em_r_mt_dp_F26N60EDDYF5C1XT884Y">amazon</a></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>

                            </p>
                            <p>
                                We are happy to announce that <b>TakeBackTheHeat</b> is now in print!
                            </p>
                            <p>
                                Available as a kindle ebook and in printed form, you may now read about <b>TakeBackTheHeat</b> concepts and background in whatever comfortable setting you prefer.
                            </p>
                            <p>
                                Great as gifts for friends and family, it is an easy way to spread the word.
                                Read how we can address global warming and atmospheric heat energy directly, beyond carbon.
                            </p>
                            <p>
                                Get your copy today! Use this <a href="https://www.amazon.ca/dp/B09R94L6N9/ref=cm_sw_em_r_mt_dp_F26N60EDDYF5C1XT884Y">amazon link</a> or search on amazon for danberger.
                            </p>
                            <p>
                                Thank you!
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Feb 5, 2022
                </Card.Footer>

            </Card>

        )
    }

    /*
    function renderJune102022() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Atmospheric Heat Reduction Right To Report (AHR RTR) now available for purchase!
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={ahrrtrImage}
                                />
                                <Figure.Caption><a href="https://shop.takebacktheheat.org/products/atmospheric-heat-reduction-right-to-report-ahr-rtr">AHR RTR</a></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>

                            </p>
                            <p>
                                We are happy to announce that <b>Atmospheric Heat Reduction Right To Report (AHR RTR)</b> are now available for purchase!
                            </p>
                            <p>
                                Available in single or multi packs, each AHR RTR represents your investment in the future of the planet.
                                One AHR RTR is equivalent to removing 2000 watt-days over 1 year, which is equivalent to the anticipated results of removing 1 metric ton of carbon dioxide or equivalent greenhouse gas (CO2E).</p>
                            <p>
                                As each AHR RTR is equivalent to carbon sequestered by 16.5 tree seedlings grown for 10 years (as per epa.gov), you can have the same impact as planting a forest!</p>
                            <p>
                                We implement and maintain the equipment that is removing the atmospheric heat energy, and an AHR RTR represents a portion of that effort for the year.
                            </p>
                            <p>
                                You can report that amount for the year on sustainability reports, web sites etc. to let others know you are actively helping cool the planet.
                            </p>
                            <p>
                                No more excuses, no more waiting on global committees.
                                You say you want to make a real difference?
                                Here is your opportunity.
                                Each AHR RTR contributes directly to the path to reduced global temperature.
                                It's a small step, but each step counts, and the world today and future generations thank you for leaning in.
                                See the big picture at <a href="https://takebacktheheat.org/model">https://takebacktheheat.org/model</a>.
                            </p>
                            <p>
                                Powered by Shopify, head over to <a href="https://shop.takebacktheheat.org">https://shop.takebacktheheat.org</a> today!
                            </p>
                            <p>
                                Thank you!
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    June 10, 2022
                </Card.Footer>

            </Card>

        )
    }
    */
//                          

    function renderJuly292022() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Comparing AHR impact with Electrification and others
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={tempImpactImage}
                                />
                                      <Figure.Caption><a href="https://nubium.com/publicdownload/tempimpact.jpg">image</a></Figure.Caption>
 
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>

                            </p>
                            <p>
                                Comparing the 80 year impact of multiple strategies, we see that <b>Atmospheric Heat Reduction (AHR)</b> can play a significant part in reducing global temperature.
                            </p>
                            <p>
                                Utilizing data from <a href="https://en-roads.climateinteractive.org/">https://en-roads.climateinteractive.org/</a> and adding our own <a href="https://takebacktheheat.org/model">https://takebacktheheat.org/model</a> data.
                            </p>
                            <Table striped bordered hover size="sm">
                                <thead>
                                <tr>
                                    <th>Strategy</th>
                                    <th>80 year temperature reduction (Celsius)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Transportation Electrification</td>
                                    <td>0.1</td>
                                </tr>
                                <tr>
                                    <td>Building and Industry Electrification</td>
                                    <td>0.3</td>
                                </tr>
                                <tr>
                                    <td>Reducing Deforestation</td>
                                    <td>0.1</td>
                                </tr>
                                <tr>
                                    <td>Carbon Removal</td>
                                    <td>0.4</td>

                                </tr>
                                <tr>
                                    <td>Highly tax Coal, Oil, Natural Gas</td>
                                    <td>0.5</td>

                                </tr>
                                <tr>
                                    <td>Atmospheric Heat Reduction (AHR)</td>
                                    <td>1.0</td>
                                </tr>
                                </tbody>
                            </Table>
                            <p>
                                Baseline data (blue) represents the anticipated temperature with no significant policy changes.  The AHR (dark red) line represents a significant drop as a result of AHR efforts.
                                The other data represents the individual impacts based on electrification of transportation industry, electrification of buildings and industry, deforestation, carbon removal, and highly taxing coal, oil, and natural gas.
                            </p>
                            <p>
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    July 29, 2022
                </Card.Footer>

            </Card>

        )
    }

    function renderNov192022() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Multiple targets can lead to drift from the most important outcome.
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={aimforResultImage}
                                />
                                <Figure.Caption><a href="https://nubium.com/publicdownload/aimforResult.jpg">image</a></Figure.Caption>
                             </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>

                            </p>
                            <p>
                                How can we fix <b>global warming</b> if we're not really trying?
                            </p>
                            <p>
                                Plenty to do, lots to focus on, very little, if any, impact on global warming.
                            </p>
                            <p>
                            </p>
                            <Table striped bordered hover size="sm">
                                <thead>
                                <tr>
                                    <th>Target</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Atmospheric Heat Reduction</td>
                                    <td>Direct reduction of global warming.  See <a href="https://takebacktheheat.org/model">https://takebacktheheat.org/model</a> <br />Aim for this!</td>
                                </tr>
                                <tr>
                                    <td>1.5C</td>
                                    <td>IPCC identified 1.5°C above pre-industrial levels as a critical threat.  Well, one of them.  The later action is delayed, the more drastic any action will need to be, if attempting to see results by 2030, 2050, 2100, or any other target date.</td>
                                </tr>
                                <tr>
                                    <td>Address Climate Impact</td>
                                    <td>The world is filled with changing conditions, every day we hear someone claiming it's due to climate change.  Some of them may be right.  Funds are needed to help countries re-adjust their surroundings to desired standards.</td>
                                </tr>
                                <tr>
                                    <td>Anti fossil fuel</td>
                                    <td>What keeps us alive, heats our homes in the winter, cools the home in the summer, cooks our food, transports goods globally, and everyone loves to hate?  Oh and releases emissions causing global warming directly (production of) and when consumers utilize it.</td>
                                </tr>
                                <tr>
                                    <td>Appease Activists</td>
                                    <td>The squeaky wheels get the grease, regardless of logic or fairness, or laws.  Beware their wrath, you could be their next target.</td>
                                </tr>
                                <tr>
                                    <td>Appease Investors</td>
                                    <td>Keeping investors happy means money continues to flow for building businesses; public, private and non-profit. </td>

                                </tr>
                                <tr>
                                    <td>Appease Public</td>
                                    <td>The general public are voters that need to be entertained and amused, and their wishes pursued, least they become activists.  And they have knowledge beyond that of many decision makers.</td>

                                </tr>
                                <tr>
                                    <td>Appease Shareholders</td>
                                    <td>What better way to influence corporate decision makers than from within?  Shareholders no longer just want profitable returns, some put other factors ahead of money.  You be the judge.</td>
                                </tr>
                                <tr>
                                    <td>Carbon Capture & Storage</td>
                                    <td>Putting carbon somewhere prevents it from entering the atmosphere causing the greenhouse effect.  Let the next generation figure out what to do with it, maybe they'll find a good use for it.</td>
                                </tr>
                                <tr>
                                    <td>Emissions Reduction</td>
                                    <td>Greenhouse gases stay in the atmosphere anywhere from decades to centuries or more (see gwp epa.gov).   Increasing the level of GHGs will increase the rate of trapping heat, when not utilizing the approaches advocated at takebacktheheat.org, and this effect will be around for many generations.</td>
                                </tr>
                                <tr>
                                    <td>Global Money Shuffle</td>
                                    <td>Those without want what those with have.  Those with want more.  Global warming is blamed on those with.  Those impacted by climate (that is... everyone) want financial help to adapt.  Let those with, pay.</td>
                                </tr>
                                <tr>
                                    <td>Greenwash</td>
                                    <td>Green, green, my heart is green.  Green is the color, of everything.  Unless you're not, but you want to appear to be motivated by making it a better world climate-wise, so make it look like whatever you are doing is for the benefit of the 8 billion people around you, not you.</td>
                                </tr>
                                <tr>
                                    <td>International Relations</td>
                                    <td>Climate doesn't recognize political boundaries, we all need to get along.  A common enemy (global warming and bad players) can make us friends.  Perhaps.</td>
                                </tr>
                                <tr>
                                    <td>Meetings, Bloody Meetings</td>
                                    <td>Meetings may be well intended but often go awry.  A lot of time and resources involving a lot of people can have good intentions but result in very little.  Are your ears burning IPCC, COP?  Prove me wrong.  Please.</td>
                                </tr>
                                <tr>
                                    <td>Net Zero</td>
                                    <td>Eliminating emissions does not reduce what is already released, for that we'll need to wait around for a few hundred years or more.  In the mean time global warming continues to increase.  Using artificial credits doesn't help.</td>
                                </tr>
                                <tr>
                                    <td>Obtain Funding</td>
                                    <td>Number one priority on every workers mind, is how to make money, how to pay their bills, maybe how to survive tomorrow.  Only a very small group of elite have the luxury of ignoring this.  Whatever project you are on needs funding, and if you annoy the funders you may be looking for your next project.  So raise funds, regardless of the outcome.</td>
                                </tr>
                                <tr>
                                    <td>Raise Awareness</td>
                                    <td>Do whatever you want, and call it raising awareness.  Hey there might still be someone left on the planet that hasn't heard about global warming or climate change.</td>
                                </tr>
                                </tbody>
                            </Table>
                            <p>
                                Are you ready to take action, today?  Contribute at our site and subscribe to Atmospheric Heat Reduction deployed gear (RTR), where we implement the equipment for you.  Start at 2,000 watt-days/year (1 RTR), which is equivalent to removing 1 metric ton of CO2E/year. Level up to 20 RTRs, raising you to equivalent to personal net zero.</p>
                            <p>
                                <a href="https://shop.takebacktheheat.org">https://shop.takebacktheheat.org</a>
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Nov 19, 2022
                </Card.Footer>

            </Card>

        )
    }

    function renderDec242022() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Atmospheric Heat Reduction RTR rebranded to Air Joules!
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={ahrrtrImage}
                                />
                                <Figure.Caption><a href="https://shop.takebacktheheat.org/products/air-joules-base">Air Joules</a></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>

                            </p>
                            <p>
                                We are happy to announce that <b>Atmospheric Heat Reduction Right To Report (AHR RTR)</b> have been rebranded as Air Joules!
                            </p>
                            <p>
                                The Air Joules base package provides 172 MJ of atmospheric energy conversion over 1 year, which is the same as the original RTR package.  
                                This is equivalent to removing 2000 watt-days over 1 year, which is equivalent to the anticipated results of removing 1 metric ton of carbon dioxide or equivalent greenhouse gas (CO2E).</p>
                            <p> Other amounts are also available.</p>
                            <p>
                                As each Air Joules base package is equivalent to carbon sequestered by 16.5 tree seedlings grown for 10 years (as per epa.gov), you can have the same impact as planting a forest!</p>
                            <p>
                                You can report that amount for the year on sustainability reports, web sites etc. to let others know you are actively helping cool the planet.
                            </p>
                            <p>
                                No more excuses, no more waiting on global committees.
                                You say you want to make a real difference?
                                Here is your opportunity.
                                Each Air Joules package contributes directly to the path to reduced global temperature.
                                It's a small step, but each step counts, and the world today and future generations thank you for leaning in.
                                See the big picture at <a href="https://takebacktheheat.org/model">https://takebacktheheat.org/model</a>.
                            </p>
                            <p>
                                Powered by Shopify, head over to <a href="https://shop.takebacktheheat.org">https://shop.takebacktheheat.org</a> today!
                            </p>
                            <p>
                                Thank you!
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Dec 24, 2022
                </Card.Footer>

            </Card>

        )
    }

    function renderOct232023() {
        return (
            <Card className="NewsCard">
                <Card.Header className="NewsCardTitle">
                    Simplified online tCO2e/y calculator
                </Card.Header>
                <Card.Body className="NewsCardBody">
                    <Row>
                        <Col md="auto" lg="4">
                            <Figure>
                                <Figure.Image
                                    width={"70%"}
                                    src={ahrnowtco2eImage}
                                />
                                <Figure.Caption><a href="https://ahrnow.com/calc/calctco2e.html">AHR Now tCO2e Calc</a></Figure.Caption>
                            </Figure>
                        </Col>
                        <Col xs lg="8" className="text-left">
                            <p>
                            New simplified online tCO2e/y calculator available for estimating global warming reduction through various Atmospheric Heat Reduction (AHR) strategies, and how they compare to other programs.
                            </p>
                            <p>
                            An inexpensive, scalable, pragmatic distributed approach to reducing global warming, looking beyond CO2e reduction, carbon credits, or the 'keep it in the ground' mindset.
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="NewsCardFooter">
                    Oct 23, 2023
                </Card.Footer>

            </Card>

        )
    }


    function renderNews() {
        return (<div className="news" >
            <p></p>
            <h1 > Take Back The Heat - News</h1>

            <Container>
                {renderOct232023()}
                {/*renderDec242022()*/}
                {renderNov192022()}
                {renderJuly292022()}
                {/*renderJune102022()*/}
                {renderFeb052022()}
                {renderOct272021()}
                {renderAug152021()}
                {renderAug052021()}
                {renderJune212021()}
                {renderApril32021()}

            </Container>
        </div>

        );
    }

    return (
        <div className="News" >
            <Helmet>
                <title>{"TakeBackTheHeat - News"}</title>
                <meta name="description" content="Changes and news related to takebacktheheat.org, a site focused on activities to reduce the heat energy to address climate change and global warming." />
            </Helmet>
            {renderNews()}
        </div>
    );
}