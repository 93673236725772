import React, { useState, useEffect } from "react";
import { Container, ListGroupItem, ListGroup, FormGroup, FormControl, Row, Button } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { API } from 'aws-amplify';
import { commentsOrderByCreatedAt } from '../graphql/queries';
import { createComment, deleteComment } from '../graphql/mutations';

//pass in commentType (R or S)
//        iD (resultId or SurveyId)

export default function Comments({ postId, isAuthenticated, username, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [commentText, setCommentText] = useState();

    const [commentList, setCommentList] = useState([]);

    useEffect(() => {
        fetchComments(postId);
        // eslint-disable-next-line  
    }, [postId]);

    async function fetchComments(postId) {
        setIsLoading(true);
        setIsLoaded(false);
        if (postId !== undefined) {
            try {
                const commentsOrderByCreatedAtData = await API.graphql({ query: commentsOrderByCreatedAt, variables: { commentPostId: postId }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
                if (commentsOrderByCreatedAtData.data.commentsOrderByCreatedAt.items.length > 0) {
                    const commentList = commentsOrderByCreatedAtData.data.commentsOrderByCreatedAt.items;
                    //                setPostList(postListA);
                    setCommentList(commentList);
                    //console.log("commentList:", commentList);
                } else {
                    setCommentList([]);
                }
            } catch (error) {
                //console.log("error on fetching postList", error);
            }
        }
        setIsLoading(false);
        setIsLoaded(true);
    }

    async function handleRemove(id) {
        let cResult = window.confirm("Delete comment?");
        if (cResult) {
            try {
                const delComment = {
                    id: id
                };
                await API.graphql({ query: deleteComment, variables: { input: delComment }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
                fetchComments(postId);
            } catch (e) {
                alert(e);
                //console.log("unable to delete comment ", e);
            }
        }
    }

    async function saveComment() {
        var ret = true;
        const newComment = {
            commentPostId: postId,
            content: commentText
        };
        if (isAuthenticated) {
            try {
                await API.graphql({ query: createComment, variables: { input: newComment }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
                ret = true;
            } catch (error) {
                alert("unable to save");
                //console.log("error on updatePost", error);
                ret = false;
            }
        }
        return ret;


    }

    async function handleSaveButton(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const ret = await saveComment();
           // console.log("comment save returned:", ret);
            if (ret === false) {
                alert("Hmm, unable to save. Please try again.");
            } else {
                //append newly created comment
                //setCommentList(commentList => [...commentList, listComments.data]);
                setCommentText("");
                setIsLoading(false);
                fetchComments(postId);
            }


        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    function toDate(dString) {
//        const dNum = parseInt(dString);
        return new Date(dString).toLocaleString();
    }

    function showDeleteButton(c) {
        if (c.owner === username) {
            return (

                <Button type="button" className="ldDeleteButtonR" onClick={() => handleRemove(c.id)}>
                    X
                </Button>
            )
        } else {
            return (<></>)
        }
    }

    function renderCommentList() {
        var ret;
        ret = commentList.map((comment, i) =>
            <ListGroupItem className="ldCommentListGroupItem" key={comment.id}>
                <Row className="ldCommentAuthor ldLockedField ">
                    <Container>
                        <div className="ldCommentItem">
                            {"\uD83D\uDDE3 "}{comment.owner}
                            {showDeleteButton(comment)}
                        </div>
                        </Container>
                </Row>
                <Row className="ldComment">
                    {comment.content && comment.content.split('\n').map((item, i) => <p key={"c" + i}>{item}</p>)}
                </Row>
                <Row>
                    <div className="ldmodifieddatetime ldCommentModifiedDateTime">
                        {"Created: " + toDate(comment.createdAt)}
                    </div>
                </Row>
            </ListGroupItem>
        );
        return ret;
    }

    function renderComments() {
        return (
            <div className="ldCommentList">
                <label className=" ldCommentListHeader">
                    Comments ({commentList.length})
            </label>
                <ListGroup>
                    {isLoaded && renderCommentList()}
                </ListGroup>
            </div>
        );
    }

    //    if (targetId && (oldTargetId !== targetId)) {
    //        if (!isLoading) {
    //            loadData(targetType, targetId); //("R","1");
    //            setOldTargetId(targetId);
    //        }
    //    }

    return (
        <div className="ldCommentPage">

            {renderComments()}

            <div className="ldCommentNewArea">
                <form onSubmit={handleSaveButton}>
                    <FormGroup >
                        <FormControl as="textarea"
                            rows={5}
                            placeholder="comment"
                            value={commentText || ''}
                            onChange={e => setCommentText(e.target.value)}
                            disabled={!isAuthenticated}
                        />
                    </FormGroup>
                    <LoaderButton className="ldloaderbutton"
                        type="submit"
                        isLoading={isLoading}
                        disabled={!isAuthenticated}
                    >
                        Save Comment
                    </LoaderButton>
                </form>
            </div>
        </div >
    );

}