import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import logo from "./flame2.jpg";   //"./tbthlogo4-80.gif";
import "./App.css";
//import { withAuthenticator } from "@aws-amplify/ui-react";   //, AmplifySignOut
import { Auth } from "aws-amplify";
//import config from './config';
//import config from './aws-exports';
import { Helmet } from "react-helmet";

// withAuthenticator will require signin for all pages, including about etc.  using withRouter allows logic to determine what needs to be logged in

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  //  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [sessionSettings, setSessionSettings] = useState([]);  //currently not persisted but available from any page
  const [isLoggingOut, setIsLoggingOut] = useState(false);  //when logging out, we no longer want to update survey, result etc on changes
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isPDropDownOpen, setIsPDropDownOpen] = useState(false);

  document.title = "Take Back the Heat";

  useEffect(() => {
    async function onLoad() {

      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      }
      catch (err) {
        if (err === 'No current user') {
        } else {
          alert("error:" + err.message);
        }
      }

      //      setIsAuthenticating(false);
    }
    onLoad();
  }, []);

  useEffect(() => {
    async function onIsAuthenticated() {
      if (isAuthenticated || (isAuthenticated && isUserChanged)) {
        try {
          const uDetails = await getUser();
          setIsLoggingOut(false);
          if (uDetails.status === true) {
            await populateUserDetails(uDetails.data); //seperate so we can call from other components
          } else {
            userHasAuthenticated(false);        //probably account exists in aws cognito but user item is not in dynamodb.  or just added and we're too quick for dynamodb
            //alert("Our apologies but there appears to be a problem with that account.  If this is a new account please try sign in again.");
            console.log("sorry there appears to be a problem with that account");

          }
        }
        catch (e) {
          userHasAuthenticated(false);        //probably account exists in aws cognito but user item is not in dynamodb.  or just added and we're too quick for dynamodb
          //     alert("Our apologies but there appears to be a problem with that account.  If this is a new account please try sign in again.");
          console.log("..appears to be a problem with that account");
        }
      }
    }
    onIsAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isUserChanged]);

  function updateSessionSettings(newObject) {
    let sArray = [...sessionSettings];
    const objIndex = sArray.findIndex(obj => obj.name === newObject.name);
    if (objIndex === -1) {
      sArray.push(newObject);
    } else {
      sArray[objIndex].value = newObject.value;
    }
    setSessionSettings(sArray);
  }

  async function populateUserDetails(uDetails) {
    await setUserDetails({ sub: uDetails.sub, id: uDetails.id, username: uDetails.username, email: uDetails.email, email_verified: uDetails.email_verified });
    setIsUserChanged(false);

  }

  //get details for the current signed in user
  async function getUser() {
    //if this is a new user, seems dynamodb takes longer to write out new item compared to us getting here

    var result;
    var error;
    for (var x = 0; x < 10; x++) {         //seems to be 2 to 4 retries before it's available
      console.log("getUser try:", x);
      try {
        result = await Auth.currentUserInfo(); // {} //SAL await API.get("tbth", "/user", {});
        return { status: true, data: result };
      }
      catch (e) {
        error = e;
        console.log(e);
      }
    }
    return { status: false, data: error }; //this will be the last error
  }

  async function handleNavSelect(e) {
    console.log("in handleNavSelect:", e);
    //setIsDropDownOpen(false);
  }

  async function handleNavClick(e) {
    console.log("in handleNavClick:", e);
    setIsExpanded(false);
    setIsDropDownOpen(true);
  }

  async function handlePNavClick(e) {
    console.log("in handlePNavClick:", e);
    setIsExpanded(false);
    setIsPDropDownOpen(true);
  }

  //  async function handleDropdownToggle(e) {
  //    console.log("in handleDropdownToggle:",e);
  //    console.log("--",isDropDownOpen);
  //    //setIsExpanded(false);
  //   setIsDropDownOpen(true);
  //  }

  async function handleLogout() {
    setIsLoggingOut(true);
    setIsExpanded(false);
    await Auth.signOut();
    setUserDetails({});
    userHasAuthenticated(false);


    //  props.history.push("/login");   //don't do this anymore.  we want silent logout for guest account

    // for now take out
    //    <LinkContainer onClick={handleNavClick} to="/who">
    //    <NavItem className="ldNavItem">Who</NavItem>
    //  </LinkContainer>

  }
//38 x 60


  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>TakeBackTheHeat Climate Action</title>
        <meta name="description" content="Address global warming and climate change crisis by reducing the heat energy. :)(:" />
      </Helmet>

      <div className="App">
        <Navbar expanded={isExpanded} expand="md" className="bg-light" onSelect={handleNavSelect} fixed="top">
          <Navbar.Brand >
            <LinkContainer tabIndex={0} to="/home">
              <img src={logo} alt="TakeBackTheHeat logo" height="38" width="38"></img>
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setIsExpanded(isExpanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="account-navbar-nav">
            <Nav fill defaultActiveKey="/about" className="container-fluid nav">
              <NavDropdown open={isDropDownOpen} title={"Background"} id="nav-dropdown2" className="ldNavDropdownBackground">
                <LinkContainer onClick={handleNavClick} to="/about">
                  <NavDropdown.Item className="ldNavItem">About</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer onClick={handleNavClick} to="/what">
                  <NavDropdown.Item className="ldNavItem">What</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer onClick={handleNavClick} to="/why">
                  <NavDropdown.Item className="ldNavItem">Why</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer onClick={handleNavClick} to="/how">
                  <NavDropdown.Item className="ldNavItem">How</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer onClick={handleNavClick} to="/context">
                  <NavDropdown.Item className="ldNavItem">Context</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer onClick={handleNavClick} to="/news">
                <NavItem className="ldNavItem">News</NavItem>
              </LinkContainer>
              <LinkContainer onClick={handleNavClick} to="/products">
                <NavItem className="ldNavItem">Products</NavItem>
              </LinkContainer>
              <LinkContainer onClick={handleNavClick} to="/where">
                <NavItem className="ldNavItem">Where</NavItem>
              </LinkContainer>
              <LinkContainer onClick={handleNavClick} to="/registry">
                <NavItem className="ldNavItem">Registry</NavItem>
              </LinkContainer>
              <NavDropdown open={isPDropDownOpen} title={"Program"} id="nav-pdropdown2" className="ldNavDropdownBackground">
                <LinkContainer onClick={handlePNavClick} to="/model">
                  <NavDropdown.Item className="ldNavItem">Model</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer onClick={handlePNavClick} to="/roadmap">
                  <NavDropdown.Item className="ldNavItem">Roadmap</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer onClick={handleNavClick} to="/forum">
                <NavItem className="ldNavItem">Forum</NavItem>
              </LinkContainer>
              <LinkContainer onClick={handleNavClick} to="/etc">
                <NavItem className="ldNavItem">Etc</NavItem>
              </LinkContainer>
              <LinkContainer onClick={handleNavClick} to="/getInvolved">
                <NavItem className="ldNavItem">Get Involved!</NavItem>
              </LinkContainer>
            </Nav>

          </Navbar.Collapse>

          <Navbar.Collapse id="account-navbar-nav" className="ldnavBarCollapse">

            <Nav className="ldnavEtc justify-content-end container-fluid">
              {isAuthenticated ? (
                <>
                  <NavDropdown title={isAuthenticated ? userDetails.username + "" : "Account"} id="nav-dropdown" className="ldNavDropdownAccount">
                    <LinkContainer onClick={handleNavClick} to="/changePassword" className="ldLinkContainerR">
                      <NavDropdown.Item>Change Password</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={handleLogout} className="ldLinkContainerR">Logout</NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <LinkContainer onClick={handleNavClick} to="/signup" className="ldsignup">
                    <Nav.Item>Signup</Nav.Item>
                  </LinkContainer>
                  <LinkContainer onClick={handleNavClick} to="/login" className="ldlogin">
                    <Nav.Item>Login</Nav.Item>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>

        </Navbar>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated, userDetails, populateUserDetails, setIsUserChanged, handleLogout, isLoggingOut, sessionSettings, updateSessionSettings }} />
      </div>
    </div>
  );
}

//export default withAuthenticator(App);
export default withRouter(App);
