//<a href="https://spaceplace.nasa.gov/greenhouse/en/" target="_blank" rel="noreferrer">NASA greenhouse gases web page</a> <a href=Etc"><sub>[2]</sub></a>

import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";

//import './video-react.css'; // import css
//import {Player } from 'video-react';
//import ReactPlayer from "react-player";
import {Helmet} from "react-helmet";

//import netRadiationVideo from "../media/CERES_NETFLUX_M.mp4";   //this doesn't work for safari ios wtf
// interacting between react environment and the canvas has been very difficult, not very successful here I have to move on.  
// note the pause and unpause seems to slow it down each time.
// I was getting multiple instances, for example the debug print objs.length, when restarting, would show multiple versions (1002 9  1012 14 etc.) one new one for each
// I believe this is now dealt with by having the return deactivate line, but watch out for it.
// tried to have toggles for heatback to show/disappear along with different calcs etc., and to be able to change rates via interface, but this proved unsuccessful at this time.
// canvas seems more appropriate for run it once type of animations (games etc. that have interaction), not simulations utilizing user input fields for values that can then be restarted, paused etc.
// occasionally random seemed to get stuck with just returning 1 or low numbers regardless of range, but couldn't reproduce this consistently.

export default function What(props) {

    //const [context, setContext] = useState(null);

    const width = window.innerWidth * 0.90;
    const height = window.innerHeight * 0.5;

    //https://game-icons.net/1x1/delapouite/power-generator.html

    //move to array/object
    const makerColor = '255,155,72';                       //FF9B48 factory
    const sparkerColor = '72,186,255';                       // 48BAFF       power generator
    const growerColor = '66, 245, 69';                       // 42F545         barn
    const moverColor = '66, 84, 245';                        // 4254F5         flatbed covered         
    const temperColor = '202,3,252';                      // ca03fc         thermometer cold
    const heatbackColor = "252,3,3";                               //  C0303       solar power edited
    const groundColor = "80, 28, 26";
    const waterColor = "1,1,255";
    const iceColor = "255, 255, 255";
    //const ghgColor = "52, 7, 71";
    //const radColor = "255,255,255";
    const sunColor = "254, 254, 6";                        // FEFE06
    //    const solarPanelColor = "245,116,66";                        // F57442 solar power
    const svgScaleX = window.innerWidth / 1500 * 0.1;
    const svgScaleY = window.innerHeight / 1500 * 0.1;
    //const makerSVG = 'xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height: 512px; width: 512px;"><path d="M0 0h512v512H0z" fill="#000" fill-opacity="1"></path><g class="" transform="translate(0,0)" style=""><path d="M384 64l.387 256H368l-96-128-16 128-96-128-16 128-96-128-16 128v160h448V64h-32v256h-32V64h-32zM64 352h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zM64 416h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32z" fill="#daf542" fill-opacity="1"></path>';
    //    const makerSVG = "M0 200 v-200 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z";
    const makerSVG = "M384 64l.387 256H368l-96-128-16 128-96-128-16 128-96-128-16 128v160h448V64h-32v256h-32V64h-32zM64 352h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zM64 416h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32z";
    const sparkerSVG = "M239.283 27.155l-29.615 59.229-12.412-37.23L153 71.281v20.125l33.742-16.87 19.59 58.767 34.387-68.77 47.998 80 35.308-70.613L359 91.407V71.282l-43.025-21.511-28.694 57.384zM89 70.845v46h46v-46zm288 0v46h46v-46zm-304 64v302h94v-112h178v112h94v-302h-79.973l-32 128H184.973l-32-128zm101.027 10l4.5 18h154.946l4.5-18zm8.5 34l4.5 18h137.946l4.5-18zm208.473 27c13.7 0 25 11.3 25 25s-11.3 25-25 25-25-11.3-25-25 11.3-25 25-25zm-336 .816l-30 20v146.184h30zm136.027 6.184l4.5 18h120.946l4.5-18zm199.973 57c13.7 0 25 11.3 25 25s-11.3 25-25 25-25-11.3-25-25 11.3-25 25-25zm66 57v46h30v-46zm-66 7c13.7 0 25 11.3 25 25s-11.3 25-25 25-25-11.3-25-25 11.3-25 25-25zm-206 9v46h17.438L224 345.72l34.467 68.931 9.804-9.806H327v-62zm39 43.127l-10.438 20.873H185v46h142v-30h-51.271l-22.194 22.193zm233 4.873v46h30v-46zm-390 64l-40 30h108v-30zm310 0v30h108l-40-30z";
    const growerSVG = "M256 23.38L89.844 89.845l-64.9 162.254 14.85 5.943c20.312-50.766 40.62-101.535 60.93-152.304l1.432-3.58L256 40.616l153.844 61.54 1.43 3.58 60.93 152.305 14.853-5.942-64.9-162.254C366.77 67.69 311.386 45.534 256 23.38zm0 36.624l-139.996 55.998L72.8 224h.2v263h78V329h-39v-18h297v176h30V224h.2c-14.402-36-28.802-72-43.204-107.998L256 60.004zM151 135h210v114H151V135zm23.563 18L199 201.873V153h-24.438zM313 153v48.873L337.438 153H313zm-144 29.127V231h24.438L169 182.127zm174 0L318.562 231H343v-48.873zm-98.73 18.69c-1.207-.02-2.31.02-3.288.128-2.823.31-10.76 3.708-16.86 7.3-2.796 1.645-5.23 3.22-7.122 4.484V231h78v-16.97c-4.193-1.675-10.334-4.02-17.578-6.368-11.206-3.63-24.71-6.71-33.152-6.846zM160 263h192v18H160v-18zm15.16 66L208 389.205 240.84 329h-65.68zm144 0L352 389.205 384.84 329h-65.68zM169 355.295v105.41L197.748 408 169 355.295zm78 0L218.252 408 247 460.705v-105.41zm66 0v105.41L341.748 408 313 355.295zm78 0L362.252 408 391 460.705v-105.41zm-183 71.5L175.16 487h65.68L208 426.795zm144 0L319.16 487h65.68L352 426.795z";
    const moverSVG = "M100.63 319l-59-5.93v-23.45h80.18A53.36 53.36 0 0 0 100.63 319zm385.87 14.56a36.16 36.16 0 1 1-36.17-36.16 36.16 36.16 0 0 1 36.17 36.16zm-6.31 0a29.86 29.86 0 1 0-29.86 29.86 29.89 29.89 0 0 0 29.87-29.86zm-29.86-12.85a12.85 12.85 0 1 0 12.85 12.85 12.85 12.85 0 0 0-12.84-12.85zm-262.41 12.85a36.16 36.16 0 1 1-36.17-36.16 36.16 36.16 0 0 1 36.17 36.16zm-36.17-29.86a29.86 29.86 0 1 0 29.86 29.86 29.89 29.89 0 0 0-29.86-29.86zm0 17a12.85 12.85 0 1 0 12.85 12.85 12.85 12.85 0 0 0-12.85-12.84zm116.44-178.42H25.5v131.37h242.69V142.28zM483.61 282v10.09a53.13 53.13 0 0 0-86.45 41.43H281.6a25.08 25.08 0 0 0-21.83-12.85h-56.42a53.33 53.33 0 0 0-21.68-31.08h102.69v-91.71a12.27 12.27 0 0 1 12.27-12.27h66.31a12.27 12.27 0 0 1 12.27 12.27v2.67h-7.77v43.84H446A37.65 37.65 0 0 1 483.61 282zm-123.34-81.44h-52V252h52v-51.44z";
    const temperSVG = "M160 36.5c-11.688 0-23 6.674-23 25.5v25h23v18h-23v14h7v18h-7v14h23v18h-23v14h7v18h-7v14h23v18h-23v14h7v18h-7v14h23v18h-23v14h7v18h-7v14h14v-23h18v81.313A32 32 0 0 1 192 432a32 32 0 0 1-32 32 32 32 0 0 1-32-32 32 32 0 0 1 23-30.688V361h-14v21h.01c-18.926 8.673-32.01 27.74-32.01 50 0 30.482 24.518 55 55 55s55-24.518 55-55c0-22.26-13.084-41.327-32.01-50h.01V62c0-18.826-11.313-25.5-23-25.5zm87 16.273v66.73l-46-23v20.124l46 23v50.246l-13.54 27.084-30.228-1.814-2.232-1.29v27.126l9.918 15.02L201 271.02v27.126l2.232-1.29 30.227-1.813L247 322.127v50.246l-29.51 14.754c3.703 4.73 6.834 9.922 9.293 15.478L247 392.498v66.73h18v-66.73l68.266 34.133 8.05-16.1L265 372.374v-50.246l13.54-27.084 30.228 1.814 43.513 25.123-5.11 85.172 17.97 1.078 4.57-76.187 57.79 33.365 9-15.588-57.79-33.365 63.694-42.053-9.918-15.02-71.205 47.01-43.514-25.124L301.082 256l16.684-25.268 43.515-25.125 71.206 47.012 9.918-15.022-63.693-42.053 57.79-33.365-9-15.588-57.79 33.365-4.57-76.187-17.97 1.078 5.11 85.172-43.512 25.123-30.227 1.814L265 189.873v-50.246l76.316-38.158-8.05-16.1L265 119.5v-66.73h-18zm-162.5 93.82l-9 15.587 43.5 25.115v-20.783l-34.5-19.92zm34.5 58.386l-49.404 32.618 9.918 15.02L119 226.55v-21.57zm-39.486 54.4l-9.918 15.022L119 307.022v-21.57l-39.486-26.07zM119 324.706L75.5 349.82l9 15.588 34.5-19.92v-20.783zm43.11 83.943c-25.186 0-25.186 26.678-25.186 26.678s7.05-10.4 11.31-14.904c4.195-4.435 13.877-11.774 13.877-11.774z";
    //    const solarSVG = "M219.615 401.84h16v88.06h-16v-88.06zm219.94-271.6l21.83-13.18-21.82-13.16 16-19.8-25-4.88 8.25-24.12-25.19 4-.46-25.49-22.28 12.29-9.18-23.8-16.7 19.24-16.78-19.24-9.22 23.8-22.3-12.31-.46 25.49-25.19-4 8.25 24.12-25 4.88 16 19.8-21.83 13.18 21.83 13.14h33.41l29.29 76.34 12-13.76 16.74 19.24 9.17-23.76 22.3 12.31.46-25.49 25.19 4-8.28-24.18 25-4.88zm-254.55 46.31h-91l31 80.85h91zm108.25 0h-91l30.94 80.85h91zm-70.81 97.42h-91l30.94 80.85h91zm108.25 0h-91l30.94 80.85h91zm73.89 111.87h-262.22L50.615 146.2h262.1zm-19.69-15l-43.31-112.87-37.28-97.42H70.785l80.47 210.27h233.68z";
    const heatbackSVG = "m 219.615,401.84 h 16 v 88.06 h -16 z m -34.61,-225.29 h -91 l 31,80.85 h 91 z m 108.25,0 h -91 l 30.94,80.85 h 91 z m -70.81,97.42 h -91 l 30.94,80.85 h 91 z m 108.25,0 h -91 l 30.94,80.85 h 91 z m 73.89,111.87 H 142.365 L 50.615,146.2 h 262.1 z m -19.69,-15 -43.31,-112.87 -37.28,-97.42 H 70.785 l 80.47,210.27 h 233.68 z";
    const makerWidth = window.innerWidth * 0.035;       //with svg these widths are estimated, not used like a box
    const sparkerWidth = window.innerWidth * 0.03;
    const growerWidth = window.innerWidth * 0.045;
    const moverWidth = window.innerWidth * 0.035;
    const temperWidth = window.innerWidth * 0.03;
    const heatbackWidth = window.innerWidth * 0.03;
    const sunWidth = window.innerWidth * 0.04;
    const makerHeight = window.innerHeight * 0.02;
    const sparkerHeight = window.innerHeight * 0.03;
    const growerHeight = window.innerHeight * 0.03;
    const moverHeight = window.innerHeight * 0.025;
    const temperHeight = window.innerHeight * 0.03;
    const heatbackHeight = window.innerHeight * 0.03;
    const sunHeight = window.innerHeight * 0.04;
    const radHeight = 1;
    const radWidth = 1;
    const ghgHeight = 2; //ll 2
    const ghgWidth = 2;

    const groundRow = height * 0.9;   //where does the ground start
    //    const sunRow = width * 0.05;       //where does sun start
    const spaceRow = height * 0.25;    //where does space start
    const zoneRow = height * 0.35;    //where does zone start (ghgs rise to here)
    const iceColumn = width * 0.85;      //where does ice start
    const waterColumn = width * 0.55;    //where does waterLevel = groundRow; start
    const cliffColumn = width * 0.45;    //where does the cliffs to the waterLevel = groundRow; start
    //const groundColumn3 = width * 0.38;
    //const groundColumn2 = width * 0.29;
    const groundColumn1 = width * 0.20;
    //ratio of occurrance.  ie for rads escaping the atmosphere is 1 in 7 so set to 7
    //const spaceRatio = 7;

    var waterLevel = groundRow + 30;
    var iceLevel = groundRow - 50;
    //var radCount = 0;
    //var ghgCount = 0;
    //    var zoneGhgCount = 0;
    //    var zoneRadCount = 0;

    //    const [objCount, setObjCount] = useState(null);
    //const [count, setCount] = useState(0);
    const [restart, setRestart] = useState(false);
    const [pause, setPause] = useState(false);

    const waterLevelRef = React.useRef(waterLevel);
    const iceLevelRef = React.useRef(iceLevel);
    const pauseRef = React.useRef(pause);

    const [objs, setObjs] = useState([]);  //let objs = [];
    //const objsRef = React.useRef(objs);

    const canvasRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useLayoutEffect(() => {
        if (pause) {
            return;
        }
        //let objs = [];
        var activeRads = 0;
        var activeGhgs = 0;
        var maxActiveRads = 3000; //3000;          //ld
        var maxActiveGhgs = 1000; //1000;          //ld

        //if (canvasRef.current) {
        //     const renderCtx = canvasRef.current.getContext('2d');
        //     if (renderCtx) {
        //         setContext(renderCtx);
        //     }

        //        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');            //removed options.context ||


        // function to generate random number

        function random(min, max) {
            const num = Math.floor(Math.random() * (max - min + 1)) + min;
            return num;
        }

        // define Obj constructor
        // type: 0=sun rad, 1=maker rad , 2=sparker rad, 3=grower rad, 4=mover rad, 5=temper rad
        //       10=sun , 11=maker , 12=sparker , 13=grower , 14=mover, 15=temper
        //       20=ghg
        function Obj(x, y, velX, velY, color, size, width, heatRate, ghgRate, type) {
            this.x = x;
            this.y = y;
            this.velX = velX;
            this.velY = velY;
            this.color = color;
            this.size = size;                                                     //for circles.  if rectangles is height
            this.width = width;                                                   //for rectangles only
            this.heatRate = heatRate;     //.xx  rate this class generates heat
            this.ghgRate = ghgRate;       //.xx  rate this class generates ghg's   note when including multiple of same class on model, divide accordingly
            this.type = type;
        }

        const createNextObj = function (x, y, velX, velY, color, size, width, heatRate, ghgRate, type) {
            //try to reuse a deactivated object first, otherwise create a new one.  speeds up processing
            var i2;
            for (i2 = 0; i2 < objs.length; i2++) {
                if (objs[i2].type >= 900) {
                    objs[i2].x = x;
                    objs[i2].y = y;
                    objs[i2].velX = velX;
                    objs[i2].velY = velY;
                    objs[i2].color = color;
                    objs[i2].size = size;
                    objs[i2].width = width;
                    objs[i2].heatRate = heatRate;
                    objs[i2].ghgRate = ghgRate;
                    objs[i2].type = type;
                    break;
                }
            }
            if (i2 >= objs.length) { //didn't find a space
                var newObj = new Obj(x, y, velX, velY, color, size, width, heatRate, ghgRate, type);
                objs.push(newObj);
            }
        }


        Obj.prototype.emitHeat = function () {
            if (random(1, 100) <= this.heatRate) {                                            // (false) only emit heat at the configured rate. probably only sun is 100% each cycle
                if (activeRads < maxActiveRads) {
                    let newxv = random(0, 12) * (random(100, 200) < 150 ? 1 : -1);
                    createNextObj(this.x + random(5, 17), this.y + random(-17, 5),
                        newxv,
                        this.type === 10 ? 10 : random(6 + newxv, 18 - newxv) * (random(100, 200) < 150 ? 1 : -1),   // gives us higher velocities in any direction
                        this.color, radHeight, radWidth, 0, 0, 0);
                    //objs.push(obj);
                    activeRads += 1;
                }
            }
        }

        Obj.prototype.emitGhg = function () {
            if (activeGhgs < maxActiveGhgs) {
                if (random(1, 100) < this.ghgRate) {                                           // only emit heat at the configured rate. probably only sun is 100% each cycle
                    if (this.y > (height * .5)) {                                            // if we're emitting from lower half of screen, offset away from buildings etc. so no immediate collision
                        createNextObj(this.x + random(-5, 5), this.y + random(-5, 5),
                            random(-7, 7),
                            random(-7, 0),
                            this.color, ghgHeight, ghgWidth, 0, 0, 9);
                        //objs.push(obj);
                        activeGhgs += 1;
                    } else {
                        createNextObj(this.x + random(-2, 2), this.y + random(-2, 2),       //not happening now since everything that emits ghg is in lower half, but keep for later
                            random(-5, 5),
                            random(-5, 5),
                            this.color, ghgHeight, ghgWidth, 0, 0, 9);
                        //objs.push(obj);
                        activeGhgs += 1;
                    }
                }
            }
        }


        Obj.prototype.deactivate = function () {
            if (this.type >= 900) {      //may have just been deactivated in update
            } else {
                if (this.type === 9) {
                    this.type = 909;
                    activeGhgs -= 1;
                } else {
                    if (this.type >= 0 && this.type < 9) {
                        this.type = 900 + this.type;
                        activeRads -= 1;
                    } else {
                        console.log("deactivating unknown type:", this.type);
                    }
                }
            }
        }
        // define Obj draw method
        Obj.prototype.draw = function () {
            if (context) {
                switch (this.type) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 9:
                        context.beginPath();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
                        context.fill();
                        context.closePath();
                        break;
                    case 10: //sun
                        context.beginPath();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
                        context.fill();
                        context.closePath();
                        break;
                    case 11:
                        context.beginPath();
                        context.save();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.scale(svgScaleX, svgScaleY);
                        context.translate(this.x / svgScaleX, this.y / svgScaleY);
                        context.fill(new Path2D(makerSVG));
                        context.restore();
                        context.closePath();
                        break;
                    case 12:
                        context.beginPath();
                        context.save();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.scale(svgScaleX, svgScaleY);
                        context.translate(this.x / svgScaleX, this.y / svgScaleY);
                        context.fill(new Path2D(sparkerSVG));
                        context.restore();
                        context.closePath();
                        break;
                    case 13:
                        context.beginPath();
                        context.save();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.scale(svgScaleX, svgScaleY);
                        context.translate(this.x / svgScaleX, this.y / svgScaleY);
                        context.fill(new Path2D(growerSVG));
                        context.restore();
                        context.closePath();
                        break;
                    case 14:
                        context.beginPath();
                        context.save();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.scale(svgScaleX, svgScaleY);
                        context.translate(this.x / svgScaleX, this.y / svgScaleY);
                        context.fill(new Path2D(moverSVG));
                        context.restore();
                        context.closePath();
                        break;
                    case 15:
                        context.beginPath();
                        context.save();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.scale(svgScaleX, svgScaleY);
                        context.translate(this.x / svgScaleX, this.y / svgScaleY);
                        context.fill(new Path2D(temperSVG));
                        context.restore();
                        context.closePath();
                        break;
                    case 16:
                        context.beginPath();
                        context.save();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.scale(svgScaleX, svgScaleY);
                        context.translate(this.x / svgScaleX, this.y / svgScaleY);
                        context.fill(new Path2D(heatbackSVG));
                        context.restore();
                        context.closePath();
                        break;
                    case 20:  //ghg 
                        context.beginPath();
                        context.fillStyle = "rgba(" + this.color + ",1)";
                        context.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
                        context.fill();
                        context.closePath();
                        break;
                    default:
                        break;
                }
            }
        };

        // define Obj update method

        Obj.prototype.update = function () {
            let newX = this.x + this.velX;
            let newY = this.y + this.velY;
            let isInSpace = (this.y <= spaceRow);
            let isNewInSpace = (newY <= spaceRow);
            let isInZone = (this.y > spaceRow) && (this.y < zoneRow);
            let isNewInZone = (newY > spaceRow) && (newY < zoneRow);
            let isBelowZone = (this.y >= zoneRow);
            let isNewBelowZone = (newY >= zoneRow);

            if (newX >= width) {                                  // at wall edge
                if (isInSpace) {                                  // above the zone just disappear
                    this.deactivate();
                } else {
                    this.x = 0 + this.size;                       // wrap
                }
            }

            if (newX <= 0) {                                     // at wall edge
                if (isInSpace) {                                 // above the zone just dissappear
                    this.deactivate();
                } else {
                    this.x = width - this.size;                  // wrap
                }
            }
            //
            // space ----
            //     ~~~
            // zone ----
            // scenario                                         action
            // case a: below zone into zone                     ghg goes in, rad maybe bounce back to earth
            // case b: in zone into space                       ghg maybe goes in, rad maybe goes in
            // case c: above space into zone                    ghg shouldn't happen, rad maybe goes in
            // case d: in zone to below zone                    ghg maybe goes back, rad maybe goes bac
            // case e: moving up off of screen                  ghg gone, rad gone
            // case f: stays in same zone                       no change

            if (newY <= 0) {                                                                              // case e           off screen, gone
                this.deactivate();
            } else {
                //if ((newY <= spaceRow && this.y <= spaceRow) || (newY > spaceRow && this.y > spaceRow)) {   //didn't cross line, do nothing
                if ((isInSpace && isNewInSpace) || (isInZone && isNewInZone) || (isBelowZone && isNewBelowZone)) {
                    if (newY >= height) {                                                                // floor bounce
                        if (random(1, 100) > 90) {                                                          //  are absorbed into ground
                            this.deactivate();                                                           // revisit..
                        } else {
                            this.velY = - (this.velY);
                            this.velX = this.velX - random(-2, 2);
                        }
                    }
                } else {
                    if (isInSpace && isNewInZone) {                                                     //   case c
                        if (random(1, 10) > 9) {
                            this.velY = -(this.velY);                                                        // might get bounced back to space
                        }
                    } else {
                        if (isInZone && isNewInSpace) {                                                     // case b
                            if (this.type === 9) {                                                          // ghg's don't escape to space
                                this.velY = -(this.velY);
                            } else {
                                if (random(1, 100) > 60) {    //2
                                    this.velY = -(this.velY);
                                }
                            }
                        } else {
                            if (isInZone && isNewBelowZone) {                                               //  case d
                                if (this.type < 9) {                                                        //  rads keep going down, ghgs stay up
                                } else {
                                    if (random(1, 100) < 95) {
                                        this.velY = -(this.velY);
                                    }
                                }
                            } else {
                                if (isBelowZone && isNewInZone) {                                           // case a
                                    if (this.type < 9) {
                                        if (random(1, 105) < (activeGhgs/maxActiveGhgs * 100) ) { //< 50) {       //activeGhgs < maxActiveGhgs perhaps let em go
                                            this.velY = -(this.velY);                                          // rads may bounce back, ghgs keep going
                                        } else {                         //make them bounce and stay (ghg effect)   
                                            this.velY = this.velY - 1;      //but a bit slower
                                        }
                                    } else {
                                    }
                                } else {
                                    console.log("unknown update: x,y,velx,vely:", this.x, this.y, this.velX, this.velY);
                                    this.deactivate();
                                }
                            }
                        }
                    }
                }

            }


            if (this.velX === 0) {
                if (this.velY === 0) {
                    //  console.log("stalled");                    
                    this.velY = random(-2, 2);
                    //                this.deactivate();
                } else {
                    this.velX = random(-2, 2);
                }
            } else {
                if (this.velY === 0) {
                    this.velY = random(-2, 2);
                }
            }


            this.x += this.velX; // + (this.type < 9)? 0: random(-1,1);                                      //rads are straight line, GHGs wiggle
            this.y += this.velY; // + (this.type < 9)? 0: random(-1,1);
        };

        // define Obj collision detection

        Obj.prototype.collisionDetect = function () {
            if (this.type < 9) {                                                                                  // if we're not a rad, don't worry about collisions
                for (let j = 0; j < objs.length; j++) {
                    if ((objs[j].type > 8) && (objs[j].type !== 900) && (objs[j].type !== 10)) {                     //rads don't collide with rads, and nothing collides with sun (suns rads couldn't get out)
                        if (!(this === objs[j])) {               //dont collide with itself
                            const dx = this.x - objs[j].x;
                            const dy = this.y - objs[j].y;
                            const distance = Math.sqrt(dx * dx + dy * dy);

                            if (distance < this.size + objs[j].size) {
                                if (objs[j].type === 16 && (random(1, 100) < 100)) {      //25           //if heatback panel and we've hit it, use default rate of conversion
                                    this.deactivate();
                                }
                                if (random(1, 50000) === 5) { //|| (objs[j].type === 10)) {   //1 in 5 are absorbed into building etc.   or if it's the sun then deactivatee
                                    this.deactivate();
                                } else {
                                    this.velX = this.velX * -1;
                                    this.velY = this.velY * -1;
                                }
                            }
                        }
                    }
                }
            }
        };

        //add stationary

        let obj = new Obj(width * 0.5, sunHeight * 0.5, 0, 0, sunColor, sunHeight, sunWidth, 100, 0, 10);
        objs.push(obj);

        obj = new Obj(makerWidth, groundRow - (makerHeight * 1.5), 0, 0, makerColor, makerHeight, makerWidth, 10, 31, 11);
        objs.push(obj);
        obj = new Obj(makerWidth + (makerWidth * 1.03), groundRow - sparkerHeight, 0, 0, sparkerColor, sparkerHeight, sparkerWidth, 10, 27, 12);
        objs.push(obj);
        obj = new Obj(makerWidth + (makerWidth * 1.03) + (sparkerWidth * 1.03), groundRow - growerHeight, 0, 0, growerColor, growerHeight, growerWidth, 10, 19, 13);
        objs.push(obj);
        obj = new Obj(makerWidth + (makerWidth * 1.03) + (sparkerWidth * 1.03) + (growerWidth * 1.04), groundRow - moverHeight, 0, 0, moverColor, moverHeight, moverWidth, 10, 16, 14);
        objs.push(obj);
        //*1.05
        obj = new Obj(groundColumn1 + 15 + (growerWidth * 1.05), (groundRow * 1.05) - temperHeight, 0, 0, temperColor, temperHeight, temperWidth, 10, 7, 15);
        objs.push(obj);

        obj = new Obj(groundColumn1 + ((heatbackWidth) * 0.5), (groundRow * 1.05) - heatbackHeight, 0, 0, heatbackColor, heatbackHeight, heatbackWidth, 0, 0, 16);
        objs.push(obj);

        // define loop that keeps drawing the scene constantly

        var myReq;

        function loop() {
            //     console.log("in loop...", objs.length, pause, restart);
            if (objs.length === 0) {
                console.log("0");
            }
            if (pause) return;

            if (iceLevelRef.current <= height) iceLevelRef.current += 0.01;
            if (waterLevelRef.current >= (groundRow * .99)) waterLevelRef.current -= 0.001;                                                  //   W A T E R   R A T E

            context.fillStyle = 'rgba(0,0,0,1)';                                     //change final ,1 here to get trails
            context.fillRect(0, 0, width, height);
            //identify space
            //  context.fillStyle = 'rgba(0,0,255,.05)';
            //  context.fillRect(0, 0, width, spaceRow);
            //identify zone
            //  context.fillStyle = 'rgba(0,255,0,.05)';
            //  context.fillRect(0, spaceRow, width, zoneRow - spaceRow);
            //waterLevel = groundRow;
            context.fillStyle = "rgba(" + waterColor + ",1)";
            context.fillRect(0, waterLevelRef.current, width, height - waterLevelRef.current);
            //ground
            context.fillStyle = "rgba(" + groundColor + ",1)";
            context.beginPath();
            context.moveTo(0, groundRow);
            context.lineTo(0, height);
            context.lineTo(waterColumn, height);
            context.lineTo(cliffColumn, groundRow * 1.05);
            context.lineTo(groundColumn1 + 5, groundRow * 1.05);
            context.lineTo(groundColumn1, groundRow);
            context.closePath();
            context.fill();


            //ice
            context.fillStyle = "rgba(" + iceColor + ",1)";
            //            context.fillRect(iceColumn, groundRow-10, width-iceColumn, height-(groundRow-10));
            context.beginPath();
            context.moveTo(iceColumn, height);
            context.lineTo(width, height);
            context.lineTo(width, iceLevelRef.current);
            context.closePath();
            context.fill();

            //context.fillStyle = temperColor;
            //context.fillRect(width/2,height-50, 50, 50);

            for (let i = 0; i < objs.length; i++) {
                if (objs[i].y < -100) {
                    //     console.log("- ", i, objs[i].y);
                }
                if (objs[i].type === 900) {             //if the rad etc went off screen, we're done with it
                }
                else {
                    //if (objs[i].y < 10 && objs[i].x < 10) {
                    //  console.log("y<10");
                    if (objs[i].type === 10) {
                        //console.log("sun");
                    }
                    if (context) {
                        if (objs[i].type < 10) {
                            objs[i].update();
                            if (objs[i].type !== 900) { //did update deactivate it?
                                objs[i].collisionDetect();
                            }
                        } else {
                            objs[i].emitHeat();
                            objs[i].emitGhg();
                        }
                        objs[i].draw();
                    }
                }
            }


            if (objs.length < 5000) {
                if (pauseRef === true) {
                    //     console.log("paused");
                } else {
                    if (!restart) {
                        myReq = requestAnimationFrame(loop);
                    }
                }
            } else {
                //console.log("reached max:", objs.length);
            }

            if (restart) {
                //console.log("in restart ...", myReq);
                //objsRef.current = [];
                //objs = [];
                setObjs([]);
                setRestart(false);
                cancelAnimationFrame(myReq);
                activeGhgs = 0;
                activeRads = 0;
                waterLevelRef.current = groundRow + 30;
                //iceLevel = groundRow - 50;
                iceLevelRef.current = groundRow - 50;
                //            zoneGhgCount = 0;
                //            zoneRadCount = 0;

            }

        }


        if (context) {
            myReq = loop();   //requestAnimationFrame(loop);  //loop();
            //console.log("================================");
        }

        return () => cancelAnimationFrame(myReq);   //requestAnimationFrame(loop);

        //},);


    }, [height, width, cliffColumn, groundColumn1, groundRow, iceColumn, spaceRow, waterColumn, zoneRow,
        makerWidth, sparkerWidth, growerWidth, moverWidth, temperWidth,
        makerHeight, sparkerHeight, growerHeight, moverHeight, temperHeight, heatbackHeight, heatbackWidth, sunHeight, sunWidth, radHeight, radWidth, ghgHeight, ghgWidth,
        objs, setObjs, restart, setRestart, pause, setPause, pauseRef, svgScaleX, svgScaleY]);

    function handleRestart(event) {
        setRestart(true);
    }

    function handlePause(event) {
        setPause(!pause);
    }

   // function handleVideoPlayerOnError(event) {
   //     //   console.log("video player error occurred:",event);
   // }

    function renderWhat() {
        return (<div className="What" >
            <p></p>
            <h1 > Take Back The Heat - What we are doing</h1>
            <p>Increased heat worldwide is part of 'climate change'.
                The  <a href="https://spaceplace.nasa.gov/greenhouse/en/" target="_blank" rel="noreferrer">NASA greenhouse gases web page</a> <Link to="/Etc"><sub>[2]</sub></Link> has some excellent descriptions of the various environment components partially animated here, to which we've added the basic categories as discussed in the excellent book <a href="https://www.amazon.ca/dp/0735280444/ref=cm_sw_em_r_mt_dp_9W0EMYYG01M23JPNVRDF">How to avoid a climate disaster by Bill Gates</a> <Link to="/Etc"><sub>[1]</sub></Link>
            </p>
            <p></p>
            <p>The immediate problem is the heat.</p>
            <p>
                Over time we can convert a small fraction of this low energy to something less harmful.
            </p>
            <p>In the animation below, small circles represent heat emitted from each earth source and from the sun.
                Larger circles represent GHG released, which gather at high altitudes and delay heat from leaving the atmosphere.
                As the GHG increase, the ability to escape the atmosphere decreases, which contributes to warming up the planet.
            </p>
            <p>
                <b>'Climate'</b> is the 30 year average of temperature, which is the measure of heat.
                The climate change occurs slowly, the green house gases build up over decades and will take decades to clear.
                Human impact on climate is being assessed, the science is not there yet; what is reported in media is often low probability scenarios, generalizations of generalizations, misleading information, or scare tactics.  Follow the money.
            </p>
            <p>
                <i><b>Take Back the Heat</b></i> projects aim at reducing the heat by converting a portion of it to other forms of energy.  Small immediate, concrete, measurable results that can be scaled.
            </p>
            <p>The animation is obviously over-simplified and not to scale.  Further below are the maps from NASA regarding the net radiation, showing the cycles and measured results.</p>
            <p></p>
            <button onClick={handleRestart}>
                Restart
            </button>
            <button onClick={handlePause}>
                {pause ? "Continue" : "Pause"}
            </button>
            <canvas
                id="canvas"
                ref={canvasRef}
                width={width}
                height={height}
                style={{
                    border: '2px solid #000',
                    marginTop: 10,
                }}
            ></canvas>
            <p></p>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th ></th>
                        <th width='35%'>Category</th>
                        <th width='25%'>GHG <sub>[1]</sub></th>
                        <th width='25%'>Heat <sub>[2]</sub></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <svg width="30" height="60" viewBox="60 300 550 100" fill={"rgba(" + makerColor + ",1)"} >
                                <path d={makerSVG} />
                            </svg>
                        </td>
                        <td>Making things</td>
                        <td>31%</td>
                        <td>tbd</td>
                    </tr>
                    <tr>
                        <td>
                            <svg width="30" height="60" viewBox="60 300 550 100" fill={"rgba(" + sparkerColor + ",1)"} >
                                <path d={sparkerSVG} />
                            </svg>
                        </td>
                        <td>Plugging in</td>
                        <td>27%</td>
                        <td>tbd</td>
                    </tr>
                    <tr>
                        <td>
                            <svg width="30" height="60" viewBox="60 300 550 100" fill={"rgba(" + growerColor + ",1)"} >
                                <path d={growerSVG} />
                            </svg>
                        </td>
                        <td>Growing things</td>
                        <td>19%</td>
                        <td>tbd</td>
                    </tr>
                    <tr>
                        <td>
                            <svg width="30" height="60" viewBox="60 300 550 100" fill={"rgba(" + moverColor + ",1)"} >
                                <path d={moverSVG} />
                            </svg>
                        </td>
                        <td>Getting around</td>
                        <td>16%</td>
                        <td>tbd</td>
                    </tr>
                    <tr>
                        <td>
                            <svg width="30" height="60" viewBox="60 300 550 100" fill={"rgba(" + temperColor + ",1)"} >
                                <path d={temperSVG} />
                            </svg>
                        </td>
                        <td>Keeping warm and cool</td>
                        <td>7%</td>
                        <td>tbd</td>
                    </tr>
                    <tr>
                        <td>
                            <svg width="30" height="60" viewBox="60 300 550 100" fill={"rgba(" + heatbackColor + ",1)"} >
                                <path d={heatbackSVG} />
                            </svg>
                        </td>
                        <td>Heatback device</td>
                        <td>0%</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </Table>
            <p>
            </p>
            
            <p className="PageFooter"><Link to="/About">{"<<"}--About</Link><Link to="/Why">Why--{">>"}</Link></p>

        </div>
        );
    }
    //ReactPlayer doesn't work from amazon
/*
<div style={{ display: 'flex', justifyContent: 'center' }}>
                <ReactPlayer playsInline muted url='https://tbth-publicfiles.s3.ca-central-1.amazonaws.com/CERES_NETFLUX_M.mp4' controls={true} type="video/mov" width="70%" onError={handleVideoPlayerOnError} />
            </div>
            <p>
                If the video above does not play, please select the 'NASA Net Radiation' link below to try from the NASA site.</p>
            <p>
                From <a href="https://earthobservatory.nasa.gov/global-maps/CERES_NETFLUX_M" target="_blank" rel="noreferrer">NASA Net Radation</a> <Link to="/Etc"><sub>[3]</sub></Link>.  Energy coming in - energy going out = net radiation.
                <i>'These maps show monthly net radiation in watts per square meter. Places where the amounts of incoming and outgoing energy were in balance are white. Places where more energy was coming in than going out (positive net radiation) are orange. Places where more energy was going out than coming in (negative net radiation) are purple.'</i>
            </p>

*/

    //https://eoimages.gsfc.nasa.gov/images/globalmaps/data/mov/CERES_NETFLUX_M.mov
 //was working...   <ReactPlayer playsInline muted url='CERES_NETFLUX_M.mov' controls={true} type="video/mov" width="70%" onError={handleVideoPlayerOnError} />
    //
    // work local!    <ReactPlayer url='trailer_hd.mp4' controls={true} width="70%"/>            file is in public folder

    //    <video controls autoPlay src="/trailer_hd.mp4" width="70%"/>    no joy but works with https source

    // try converting with ... https://cloudconvert.com/mov-to-mp4

    //    <Player width="75%">
    //    <source src={"/media/CERES_NETFULX_M.mov"} type="video/mp4"></source>
    //</Player>
    //      <source src="/media/CERES_NETFLUX_M.mp4" />

    //       <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
    // 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4'

    //       <source src="/video/CERES_NETFLUX_M.mp4" />

    //https://nubium.com/publicdownload/CERES_NETFLUX_M.mp4

    //        <source src={netRadiationVideo} type="video/mp4"></source>
    /*
        <video controls loop muted autoPlay playsInline preload="auto" width="75%">
            <source src={"https://takebacktheheat.org/video/CERES_NETFLUX_M.mp4"} type="video/mp4"></source>
            <source src={"/video/CERES_NETFLUX_M.mp4"} type="video/mp4"></source>
        </video>
    
    */
    return (
        <div className="What" > 
            <Helmet>
                <title>{"TakeBackTheHeat - What"}</title>
                <meta name="description" content="What takebacktheheat.org is doing to reduce the heat energy to address climate change and global warming." />
            </Helmet>
        {renderWhat()}
        </div>
    );
}