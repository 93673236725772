/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContribution = /* GraphQL */ `
  query GetContribution($id: ID!) {
    getContribution(id: $id) {
      id
      contributorName
      contributorId
      contributionDescription
      contributionAmount
      updatedAt
      createdAt
    }
  }
`;
export const listContributions = /* GraphQL */ `
  query ListContributions(
    $filter: ModelContributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContributions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contributorName
        contributorId
        contributionDescription
        contributionAmount
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getDataLog = /* GraphQL */ `
  query GetDataLog($id: ID!) {
    getDataLog(id: $id) {
      id
      owner
      dataLogRegistryId
      dataType
      note
      lat
      lng
      watts1
      watts2
      watts3
      coldTempC
      hotTempC
      cels1
      cels2
      cels3
      val1
      val2
      val3
      dataJSON
      eventDate
      createdAt
      updatedAt
      TTLExpiry
      ip
      registry {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listDataLogs = /* GraphQL */ `
  query ListDataLogs(
    $filter: ModelDataLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        dataLogRegistryId
        dataType
        note
        lat
        lng
        watts1
        watts2
        watts3
        coldTempC
        hotTempC
        cels1
        cels2
        cels3
        val1
        val2
        val3
        dataJSON
        eventDate
        createdAt
        updatedAt
        TTLExpiry
        ip
        registry {
          id
          owner
          rowState
          deviceType
          deviceName
          deviceDescription
          lat
          lng
          dailyAverageW
          includeInDashTotal
          updateFromDataLog
          dataLogReadKey
          dataLogWriteKey
          attributes
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user
      date
      total
      items
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        date
        total
        items
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const dataLogsOrderByDate = /* GraphQL */ `
  query DataLogsOrderByDate(
    $dataLogRegistryId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDataLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataLogsOrderByDate(
      dataLogRegistryId: $dataLogRegistryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        dataLogRegistryId
        dataType
        note
        lat
        lng
        watts1
        watts2
        watts3
        coldTempC
        hotTempC
        cels1
        cels2
        cels3
        val1
        val2
        val3
        dataJSON
        eventDate
        createdAt
        updatedAt
        TTLExpiry
        ip
        registry {
          id
          owner
          rowState
          deviceType
          deviceName
          deviceDescription
          lat
          lng
          dailyAverageW
          includeInDashTotal
          updateFromDataLog
          dataLogReadKey
          dataLogWriteKey
          attributes
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
      sortOrder
      posts {
        items {
          id
          title
          content
          contentHTML
          postBlogId
          topic
          createdAt
          updatedAt
          owner
          groupsAdmin
          imageNames
        }
        nextToken
      }
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        sortOrder
        posts {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const blogsOrderBySortOrder = /* GraphQL */ `
  query BlogsOrderBySortOrder(
    $owner: String
    $sortOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogsOrderBySortOrder(
      owner: $owner
      sortOrder: $sortOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        sortOrder
        posts {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      content
      contentHTML
      postBlogId
      topic
      createdAt
      updatedAt
      owner
      groupsAdmin
      imageNames
      blog {
        id
        name
        createdAt
        updatedAt
        owner
        sortOrder
        posts {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentPostId
          createdAt
          updatedAt
          owner
          groupsAdmin
        }
        nextToken
      }
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        contentHTML
        postBlogId
        topic
        createdAt
        updatedAt
        owner
        groupsAdmin
        imageNames
        blog {
          id
          name
          createdAt
          updatedAt
          owner
          sortOrder
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const postsOrderByCreatedAt = /* GraphQL */ `
  query PostsOrderByCreatedAt(
    $postBlogId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsOrderByCreatedAt(
      postBlogId: $postBlogId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        contentHTML
        postBlogId
        topic
        createdAt
        updatedAt
        owner
        groupsAdmin
        imageNames
        blog {
          id
          name
          createdAt
          updatedAt
          owner
          sortOrder
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      commentPostId
      createdAt
      updatedAt
      owner
      groupsAdmin
      post {
        id
        title
        content
        contentHTML
        postBlogId
        topic
        createdAt
        updatedAt
        owner
        groupsAdmin
        imageNames
        blog {
          id
          name
          createdAt
          updatedAt
          owner
          sortOrder
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        commentPostId
        createdAt
        updatedAt
        owner
        groupsAdmin
        post {
          id
          title
          content
          contentHTML
          postBlogId
          topic
          createdAt
          updatedAt
          owner
          groupsAdmin
          imageNames
        }
      }
      nextToken
    }
  }
`;
export const commentsOrderByCreatedAt = /* GraphQL */ `
  query CommentsOrderByCreatedAt(
    $commentPostId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsOrderByCreatedAt(
      commentPostId: $commentPostId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        commentPostId
        createdAt
        updatedAt
        owner
        groupsAdmin
        post {
          id
          title
          content
          contentHTML
          postBlogId
          topic
          createdAt
          updatedAt
          owner
          groupsAdmin
          imageNames
        }
      }
      nextToken
    }
  }
`;
export const getMapMarker = /* GraphQL */ `
  query GetMapMarker($id: ID!) {
    getMapMarker(id: $id) {
      id
      owner
      lat
      lng
      markerType
      markerRef
      createdAt
      updatedAt
    }
  }
`;
export const listMapMarkers = /* GraphQL */ `
  query ListMapMarkers(
    $filter: ModelMapMarkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMapMarkers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        lat
        lng
        markerType
        markerRef
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      owner
      configName
      configNote
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        configName
        configNote
        attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegistry = /* GraphQL */ `
  query GetRegistry($id: ID!) {
    getRegistry(id: $id) {
      id
      owner
      rowState
      deviceType
      deviceName
      deviceDescription
      lat
      lng
      dailyAverageW
      includeInDashTotal
      updateFromDataLog
      dataLogReadKey
      dataLogWriteKey
      attributes
      dataLogs {
        items {
          id
          owner
          dataLogRegistryId
          dataType
          note
          lat
          lng
          watts1
          watts2
          watts3
          coldTempC
          hotTempC
          cels1
          cels2
          cels3
          val1
          val2
          val3
          dataJSON
          eventDate
          createdAt
          updatedAt
          TTLExpiry
          ip
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRegistrys = /* GraphQL */ `
  query ListRegistrys(
    $filter: ModelRegistryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const registryByOwner = /* GraphQL */ `
  query RegistryByOwner(
    $owner: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registryByOwner(
      owner: $owner
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const registryOrderByName = /* GraphQL */ `
  query RegistryOrderByName(
    $rowState: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registryOrderByName(
      rowState: $rowState
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        rowState
        deviceType
        deviceName
        deviceDescription
        lat
        lng
        dailyAverageW
        includeInDashTotal
        updateFromDataLog
        dataLogReadKey
        dataLogWriteKey
        attributes
        dataLogs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStats = /* GraphQL */ `
  query GetStats($id: ID!) {
    getStats(id: $id) {
      id
      name
      title
      level
      sortOrder
      statsType
      statsDate
      description
      value
      createdAt
      updatedAt
      owner
      isDisabled
    }
  }
`;
export const listStatss = /* GraphQL */ `
  query ListStatss(
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        level
        sortOrder
        statsType
        statsDate
        description
        value
        createdAt
        updatedAt
        owner
        isDisabled
      }
      nextToken
    }
  }
`;
export const statsOrderBySortOrder = /* GraphQL */ `
  query StatsOrderBySortOrder(
    $statsType: Int
    $sortOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statsOrderBySortOrder(
      statsType: $statsType
      sortOrder: $sortOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        title
        level
        sortOrder
        statsType
        statsDate
        description
        value
        createdAt
        updatedAt
        owner
        isDisabled
      }
      nextToken
    }
  }
`;
export const statsLevelBySortOrder = /* GraphQL */ `
  query StatsLevelBySortOrder(
    $level: Int
    $sortOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statsLevelBySortOrder(
      level: $level
      sortOrder: $sortOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        title
        level
        sortOrder
        statsType
        statsDate
        description
        value
        createdAt
        updatedAt
        owner
        isDisabled
      }
      nextToken
    }
  }
`;
