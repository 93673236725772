import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import forceImage from "../media/ceres-poster-011-v2.jpg";
import { Helmet } from "react-helmet";

export default function About(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  function renderAbout() {
    return (<div className="About" >
      <p></p>
      <h1>About Take Back The Heat</h1>
      <p></p>
      <p>It's a stupid idea.</p>
      <p>And it just might work.</p>
      <p>
      </p>
      <p>
        The planet is slowly heating up, causing 'global warming' and 'climate change' issues.  A small portion of this is due to human activity, the rest is part of the natural cycle of the earth.
        A search through scientific papers, opinions, internet sites, UN resources, IPCC resources, and more will show conclusively that mankind is responsible for somewhere between 0% and 100% of the changing climate.</p>
      <p>
        One popular theory is that energy received from the Sun was roughly balanced with what was outgoing, until 'Progress' and the increase in population resulted in greenhouse gases (GHG) and other concerns that have changed the equation.
        We now have more heat, and it is popular to claim this has undesired consequences.
      </p>
      <p>Efforts are underway to address the greenhouse gases (GHG), carbon etc. that are reflecting heat back to the planet.
        Addressing the GHG will take a lot of time, money, and creative thinking.
        At <b>Take Back the Heat</b> we are looking not at addressing those causes, but at the immediate concern of the heat itself.
        Can we convert a small portion of this low energy to something less harmful, in a manner that can be scaled to make a difference?
      </p>
      <p>
        <b><i>Yes</i></b>.
      </p>
      <p></p>
      <p>
        <b>Let's reduce the heat</b>
      </p>
      <p></p>
      <p>
      </p>
      <h2>Earth's Energy Budget</h2>
      <p>The balance of energy is also known as 'Earth Budget', 'Energy Budget' or 'Energy Balance', identifying what is coming in and going out.
        The <a href="https://www.nasa.gov/feature/langley/what-is-earth-s-energy-budget-five-questions-with-a-guy-who-knows" target="_blank" rel="noreferrer">NASA Earth Energy Budget</a> <Link to="/Etc"><sub>[6]</sub></Link>  illustration below gives us an idea of what the budget is.
        'Carbon' and Greenhouse Gases projects of various types mainly look to address the greenhouse gases that are reflecting back 340.3 watts/meter<sup>2</sup> radiation. (all measures are averaged)</p>
      '<b>Take back the heat</b>' projects (Atmospheric Heat Reduction) mainly look to directly address the areas below identified as 'absorbed by surface 163.3' and 'reflected by surface 22.9' portions, while also affecting the 'emitted by surface 398.2' and 'back radiation 340.3' portions.
      <p>
        <img src={forceImage} alt="Earth Budget" height="60%" width="60%"></img>

      </p>
      <p className="PageFooter"><Link to="/Home">{"<<"}--Home</Link><Link to="/What">What--{">>"}</Link></p>
    </div>
    );
  }

  return <div className="About" >
    <Helmet>
      <title>{"TakeBackTheHeat - About"}</title>
      <meta name="description" content="takebacktheheat.org is a project focused on reducing the heat energy to address climate change and global warming crisis.  Pursuing existing and new innovative solutions, communications with education, and progress tracking are the three main streams." />
    </Helmet>
    {renderAbout()} </div>;
}