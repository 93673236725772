//https://developers.google.com/maps/documentation/javascript/markers
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import "./Map.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons'; //faCoffee

const LocationPin = ({ text }) => (
   <div className="pin" style={{ color: 'red' }} >
      <FontAwesomeIcon icon={faMapPin} className="fa-3x" />
      <p className="pin-text">{text}</p>
   </div>
)

const getMapOptions = (maps) => {
   return {
     disableDefaultUI: true,
     mapTypeControl: true,
     streetViewControl: false,
     clickableIcons:false,      //to disable the info windows
     styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
   };
};

//const AnyReactComponent = ({ text }) => <div>{text}</div>;


class SimpleMap extends Component {

   static defaultProps = {
      center: {
         lat: 51.0447,
         lng: -114.0719
      },
      zoom: 11
   };


//                     img_src={marker.img_src}
//'100vh', width: '100%' }}>
//                     text={marker.id}            from locationPin call

//  marker.addListener("click", () => {
//   infowindow.open(map, marker);
//});

render() {

   return (
      // Important! Always set the container height explicitly
      <div style={{ height: '70vh', width: '90%', marginLeft:'auto', marginRight:'auto'}}>
         <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyD8KBBHzV18fqAtMF9OjfydDKnoXX7IEFk' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            onClick={this.props.onMapClick}
            options={getMapOptions}
            
         >
         {/*   <AnyReactComponent
               lat={50}
               lng={-114}
               text="Site xxxxx"
            />
         */}
            {this.props.markers.map((marker, i) => {
               return (
                  <LocationPin 
                     key={i}
                     lat={marker.lat}
                     lng={marker.lng}
                  />

               )
            })}

         </GoogleMapReact>
      </div>
   );
}
}

export default SimpleMap;