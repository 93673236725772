import React, { useEffect } from "react";
import { Figure, Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

import teachImage from "../media/photo-1613211431746-aacbe481a84b.jpg";
import designerImage from "../media/nirmal-rajendharkumar-88s0zVMLSqc-unsplash.jpg";
import progressImage from "../media/mauro-paillex-lRQouvQf1-w-unsplash.jpg";
import {Helmet} from "react-helmet";
export default function How(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function renderHow() {


        return (<div className="how" >
            <p></p>
            <p>
                The atmosphere is big.
                It is really, really, big.
                Obviously we can't just turn down the heat quickly, and that's a good thing.
                If it was easy to manipulate, we'd have all sorts of folks mucking about with it.
                As it stands, this will take a distributed effort, by many people and governments, over many years, to accomplish a small positive impact.
                Ignoring the worse case scenario descriptions that have been used as scare tactics for attention and financial gain, we can in fact adapt to the predicted minimal increase, as we have been.
                However we do need to clean up our mess before it gets worse.
            </p>
            <p>
                The two broad streams of activities required are 1) Education, and 2) Solutions.
                Each will need to address two tracks: the existing heat, and reducing the heat deliberately released into the atmosphere.
            </p>
            <Container>
                <Row className="justify-content-center">
                    <h2>Education</h2>
                </Row>
                <Row>
                    <Col md="auto" lg="4">
                        <Figure>
                            <Figure.Image
                                width={"90%"}
                                src={teachImage}
                            />
                            <Figure.Caption><Link to="/Etc"><sub>[7]</sub></Link></Figure.Caption>
                        </Figure>
                    </Col>
                    <Col xs lg="8" className="text-left">
                        <p>
                            Getting others involved and learning from each other will be critical.
                            This web site is a unification point for these ongoing activities, complimenting efforts already underway.
                            Forums are provided for discussions and knowledge sharing.
                    </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <h2>Solutions</h2>
                </Row>
                <Row>
                    <Col md="auto" lg="4">
                        <Figure>
                            <Figure.Image
                                width={"90%"}
                                src={designerImage}
                            />
                            <Figure.Caption><Link to="/Etc"><sub>[8]</sub></Link></Figure.Caption>
                        </Figure>
                    </Col>
                    <Col xs lg="8" className="text-left">
                        <p>
                            Small solutions may be identified and implemented by interested persons worldwide, utilizing local resources.
                            Larger solutions will require funding and full project management for each initiative.
                            Solutions that address heat deliberately released by businesses will require careful discussions with all involved parties, as addressing this may be viewed as a competitive disadvantage and burden.
                            Heat scavenger components could provide additional benefits to the organization.
                            The forums provide an opportunity to discuss ideas and projects, and concerns.
                    </p>
                        <p>
                            Key to any solution, large or small, will be measuring the effectiveness of the solution towards the desired outcome.
                            This site will identify some broadly defined goals, and will provide functionality to track solutions being implemented along with the achieved results.
                            Where feasible, Internet of Things (IOT) type tracking devices will be utilized to consolidate results and activities here.
            </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <h2>Measuring Success</h2>
                </Row>
                <Row>
                    <Col md="auto" lg="4">
                        <Figure>
                            <Figure.Image
                                width={"90%"}
                                src={progressImage}
                            />
                            <Figure.Caption><Link to="/Etc"><sub>[9]</sub></Link></Figure.Caption>
                        </Figure>
                    </Col>
                    <Col xs lg="8" className="text-left">
                        <p>
                            While solutions implemented will vary greatly, each will need to identify the energy put into the system, the heat converted from the environment, and any heat or energy released into the environment.
                            The sum, over time, of the heat converted out of the environment must be greater than the energy put into the system + the residual heat or energy released into the environment.
                            Projects that merely move heat to other locations may be constructive but will not be considered successful, as the overall total of heat would not be reduced.
                            Methods will be identified to estimate, measure and record attributes of each solution or solution component such as net heat converted, time span, and cost.
                    </p>
                    </Col>
                </Row>
            </Container>
            <p className="PageFooter"><Link to="/Why">{"<<"}--Why</Link><Link to="/Context">Context--{">>"}</Link></p>

        </div>

        );
    }

    return (
        <div className="How" > 
                    <Helmet>
                <title>{"TakeBackTheHeat - How"}</title>
                <meta name="description" content="How takebacktheheat.org and related projects are reducing the heat energy to address climate change and global warming." />
            </Helmet>
            { renderHow()}
        </div>
    );
}