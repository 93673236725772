import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Button, Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { API, graphqlOperation } from 'aws-amplify';
import { listBlogs } from '../graphql/queries';
import { createPost } from '../graphql/mutations';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './suneditor.css';

import { buildTopicOptions } from '../data.js';
import DOMPurify from 'dompurify';
import { Helmet } from "react-helmet";

export default function PostNew(props) {

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [messageLine, setMessageLine] = useState();
    const [isEdited, setIsEdited] = useState(false);

    const editorRef = useRef();

    const topicOptions = buildTopicOptions();

    const [subjectText, setSubjectText] = useState();
    const [categoryType, setCategoryType] = useState([]);
    const [topicType, setTopicType] = useState([]);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted black',
            color: state.isSelected ? 'red' : 'blue',
            padding: 10,
        }),
    }

    useEffect(() => {
        // onload               pass in either 'new' or a post id
        // if not logged in, return
        if ((props.match.params.id === undefined) && (props.location.pathname !== "/post/new")) {
            return;
        }
        fetchBlogList();
        // eslint-disable-next-line
    }, []);



    function handleSubjectChange(e) {
        setSubjectText(e.target.value);
        setIsEdited(true);
    }

    function handleCategoryChange(event) {     //will become blog list
        //        setAccountType(event.value);
        setCategoryType({ value: event.value, label: event.label });
        setIsEdited(true);
    }

    function handleTopicChange(event) {
        setTopicType({ value: event.value, label: event.label });
        setIsEdited(true);
    }

    function compare(a, b) {
        if (a.sortOrder < b.sortOrder) {
            return -1;
        }
        if (a.sortOrder > b.sortOrder) {
            return 1;
        }
        return 0;
    }

    async function fetchBlogList() {
        try {
            const blogListDataA = await API.graphql(graphqlOperation(listBlogs));
            const blogListA = blogListDataA.data.listBlogs.items;
            //                setBlogList(blogListA);
            const blistA = blogListA.map((item) => [{ value: item.id, label: item.name, sortOrder: item.sortOrder }]);
            const ablistA = [].concat(...blistA).sort(compare).filter(item => item.label !== "Housekeeping");
            setCategoryOptions(ablistA);
        } catch (error) {
            console.log("error on fetching blogList", error);
        }
    };

    async function savePost() {
        var ret = { result: true };
        const newPost = {
            title: subjectText,
            content: DOMPurify.sanitize(editorRef.current.editor.core.getContents()), //editorRef.current.editor.core.getContents(),

            // content: createMarkup(convertedContent).__html,  //convertedContent,
            //x            contentHTML: createMarkup(convertedContent).__html,  //convertedContent,
            //x            content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            topic: topicType.value,
            postBlogId: categoryType.value
        };
        try {
            await API.graphql({ query: createPost, variables: { input: newPost }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
            setIsEdited(false);
        } catch (error) {
            ret = { result: false, message: error.errors[0].message };
        }
        return ret;
    };

    function validateForm() {
        let ret = true;
        let msg = "";
        if (categoryType.length === 0) {
            msg = "Please select Category\n";
            ret = false;
        };
        if (topicType.length === 0) {              //should be a number
            msg = msg + "Please select Topic\n";
            ret = false;
        }
        const edContent = editorRef.current.editor.core.getContents();
        if ((edContent === undefined) || (edContent && (edContent.length < 10))) {
            msg = msg + "Please enter content\n";
            ret = false;
        }
        if (subjectText === undefined) {
            msg = msg + "Please enter subject\n";
            ret = false;
        } else {
            if (subjectText.length < 3) {   //not working on one line above.
                msg = msg + "Please enter subject\n";
                ret = false;
            }
        }
        setMessageLine(msg);
        return ret;
    }

    async function handleCancel() {
        const edContent = editorRef.current.editor.core.getContents();

        if (isEdited) {
            if (edContent && (edContent.length > 10)) {
                if (window.confirm('Discard post?')) {
                    props.history.push("/forum");
                }
            } else {
                props.history.push("/forum");

            }
        } else {
            props.history.push("/forum");
        }
    }

    async function handleSave() {
        if (!validateForm()) {
            return;
        }
        const saveResult = await savePost();
        if (saveResult.result === true) {
            props.history.push("/forum/" + categoryType.value);
        } else {
            setMessageLine("Unable to save: " + saveResult.message);
        }
    }

    async function handleEditorChange(e) {
        if (editorRef.current.editor.core.history.stack.length > 2) {    //first two changes are initial state and post content from db, so these don't represent something to save
            setIsEdited(true);
        }
        //editorRef.current.editor.core.setContents( DOMPurify.sanitize(editorRef.current.editor.core.getContents())); //loops
        //setEditorContent(e);
    };

    function renderSaveButton() {
        return (
            <Button className="float-right ldButton" middle="xs" onClick={handleSave} disabled={(!isEdited)}
            >
                Save
            </Button>
        )
    }

    function renderCancelButton() {
        return (
            <>
                {isEdited ? (
                    <Button className="ldButton" middle="xs" onClick={handleCancel}>Cancel</Button>
                ) : (
                    <Button className="ldButton" middle="xs" onClick={handleCancel}>Close</Button>
                )}
            </>
        )
    }

    //{isLoading ? " loading" : ""}

    function renderPostNew() {
        return (<div className="PostNew" >
            <p></p>
            <Container className="ldPostContainer" fluid>
                <Row className="ldTopRow">
                    <div className="ldBackButton">
                        {renderCancelButton()}
                    </div>
                    <div className="ldMessageContainer" >
                        <div className="ldFormMessage">
                            {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                        </div>
                    </div>
                    <div className="ldSaveButton">
                        {renderSaveButton()}
                    </div>
                </Row>
                <Row className="ldBodyRowN">
                    <Col className="ldBodyColLeft">
                        <InputGroup size="lg" >
                            <InputGroup.Text>Category</InputGroup.Text>
                            <Select className="selectCategory"
                                value={categoryType || ''}
                                onChange={handleCategoryChange}
                                options={categoryOptions}
                                placeholder=""
                                styles={customStyles}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="ldBodyColRight">
                        <InputGroup size="lg">
                            <InputGroup.Text>Topic</InputGroup.Text>
                            <Select className="selectTopic"
                                value={topicType || ''}
                                onChange={handleTopicChange}
                                options={topicOptions}
                                placeholder=""
                                styles={customStyles}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="ldBodyRow">
                    <InputGroup size="lg">
                        <InputGroup.Text>Subject</InputGroup.Text>
                        <FormControl as="textarea"
                            className="formSubjectText"
                            rows={1}
                            placeholder="subject"
                            value={subjectText || ''}
                            name="subjectText"
                            onChange={handleSubjectChange}
                        />
                    </InputGroup>

                </Row>
                <div className="EditorContent">
                    <SunEditor ref={editorRef}
                        onChange={handleEditorChange}
                        autoFocus={true}
                        showToolbar={true}
                        width="100%"
                        height="100%"
                        setOptions={{
                            height: '100%',
                            buttonList: [['undo', 'redo'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['removeFormat'], ['outdent', 'indent'], ['fullScreen', 'showBlocks'],
                            ['font', 'fontSize', 'fontColor', 'hiliteColor', 'formatBlock', 'align'],
                            ['list', 'paragraphStyle', 'table', 'textStyle'],
                            ['link', 'image'],
                            ['preview']],
                            defaultStyle: 'sun-editor-editable',
                            placeholder: "content..."
                        }}
                    />
                </div>

            </Container>
        </div>
        );
    }
    //need to add katex for math
    function renderNeedAuth() {
        // works but with error   (the error was the space!)             <div className="ContentPreview" dangerouslySetInnerHTML={createMarkup(convertedContent)}> </div>
        //                       <div className="ContentPreview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>

        return (
            <div>
                Please sign in.
            </div>
        );
    }

    return (
        <div className="PostNew" >
            <Helmet>
                <title>{"TakeBackTheHeat - PostNew"}</title>
                <meta name="description" content="Posting new content on takebacktheheat.org, focused on reducing heat energy to address global warming and climate change." />
            </Helmet>

            {props.isAuthenticated ? renderPostNew() : renderNeedAuth()}
        </div>
    );
}