//https://developers.google.com/maps/documentation/javascript/markers
import React, { Component } from 'react';

import Chart from "react-google-charts";

//const AnyReactComponent = ({ text }) => <div>{text}</div>;


class SimpleChart extends Component {
/*
   static defaultProps = {
      center: {
         lat: 51.0447,
         lng: -114.0719
      },
      zoom: 11
   };
*/
//style={{ height: '70vh', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
   render() {

      return (
         // Important! Always set the container height explicitly
         <div >
            <Chart
               width={this.props.width}
               height={this.props.height}
               chartType={this.props.chartType}
               loader={<div>Loading Chart</div>}
               data={this.props.data}
               options={this.props.options}
               legendToggle
            />
         </div>
      );
   }
}

export default SimpleChart;