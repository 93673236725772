import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import { Accordion, Button, Card, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { createDataLog, updateDataLog, deleteDataLog } from '../graphql/mutations';
//import { getRegistry } from '../graphql/queries';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { Helmet } from "react-helmet";

export default function DataLog(props) {
    const [dataLogList, setDataLogList] = useState([]);

    const [selectedDataLog, setSelectedDataLog] = useState();

    //    const [registry, setRegistry] = useState();       //details retrieved for the registry id sent in

    //    const [formData, setFormData] = useState();       //using a generic onChange was updating the state but the visible form was delayed in getting updated, happens later after doing dropdown etc. not good

    const [formId, setFormId] = useState();
    const [formOwner, setFormOwner] = useState();
    //   const [formRegistry, setFormRegistry] = useState();
    const [formDataLogRegistryId, setFormDataLogRegistryId] = useState();
    const [formLat, setFormLat] = useState();
    const [formLng, setFormLng] = useState();
    const [formWatts1, setFormWatts1] = useState();
    const [formWatts2, setFormWatts2] = useState();
    const [formWatts3, setFormWatts3] = useState();
    const [formCels1, setFormCels1] = useState();
    const [formCels2, setFormCels2] = useState();
    const [formCels3, setFormCels3] = useState();
    const [formColdTempC, setFormColdTempC] = useState();
    const [formHotTempC, setFormHotTempC] = useState();
    const [formVal1, setFormVal1] = useState();
    const [formVal2, setFormVal2] = useState();
    const [formVal3, setFormVal3] = useState();
    const [formDataJSON, setFormDataJSON] = useState();
    const [formCreatedAt, setFormCreatedAt] = useState();
    const [formUpdatedAt, setFormUpdatedAt] = useState();
    const [formEventDate, setFormEventDate] = useState();
    const [formIp, setFormIp] = useState();
//    const [formTTLExpiry, setFormTTLExpiry] = useState();
    const [formNote, setFormNote] = useState();

    const [currentRegistry, setCurrentRegistry] = useState({});

    const [messageLine, setMessageLine] = useState();
    const [isEdited, setIsEdited] = useState(false);

    const [dataLogRegistryId, setDataLogRegistryId] = useState();
    const [itemCount, setItemCount] = useState(0);

    const [colsToShow, setColsToShow] = useState("");

    const [dLimit] = useState(100);         //limit count for downloading data log list
    const [dNextPageTokenList, setDNextPageTokenList] = useState([]);      //an array of next tokens we have collected so far.  if undefined, means no more pages.  so [0] may be undefined, or next group...
    const [dCurrentPage, setDCurrentPage] = useState(0);   //what page of data are we currently looking at?  will only be built up as we walk through data, only supporting prev/next  nothing fancy here

    //copied from queries, removed registry owner, read/write keys etc.
    const dataLogsOrderByDate = /* GraphQL */ `
  query DataLogsOrderByDate(
    $dataLogRegistryId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDataLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataLogsOrderByDate(
      dataLogRegistryId: $dataLogRegistryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        dataLogRegistryId
        lat
        lng
        watts1
        watts2
        watts3
        coldTempC
        hotTempC
        cels1
        cels2
        cels3
        val1
        val2
        val3
        dataJSON
        eventDate
        createdAt
        updatedAt
        ip
        note
        registry {
          deviceName
          deviceDescription
          deviceType
        }
      }
      nextToken
    }
  }
`;

    function hideCol(col) {
        // if col isIn(colsToShow)   return false else true

        if (colsToShow.includes(col)) {
            return false;
        }
        else {
            return true;
        }
    }

    function ActionFormatter(data) {
        return (
            <div className="ButtonColumn">
                {(props.userDetails.username === data.row.owner) ? (<>
                    <Button type="button" variant="outline-danger" className="ActionButton" size="sm" onClick={() => handleDeleteButton(data.row)}>delete</Button></>) : (<></>)}
            </div>
        );
    }
    const actionFormatter = (cell, row) => <ActionFormatter row={row} />;

    const dataLogColumns = [{
        dataField: 'action',
        isDummyField: true,
        csvExport: false,
        formatter: actionFormatter,
        formatExtraData: props.userDetails.username,
        headerStyle: (column, colIndex) => { return { width: '8rem' }; },
        text: ''
    }, {
        dataField: 'localDate',
        text: 'Datetime',
        headerStyle: (column, colIndex) => { return { width: '20rem' }; },
        sort: false
    }, {
        dataField: 'watts1',
        text: 'watts1',
        headerStyle: (column, colIndex) => { return { width: '15rem' }; },
        hidden: hideCol('watts1'),
        formatter: (cell) => {
            return (
                <> {(cell) == null?(cell):(cell).toFixed(2)} </>)
        },
        sort: true
    }, {
        dataField: 'coldTempC',
        text: 'Cold Temp C',
        headerStyle: (column, colIndex) => { return { width: '10rem' }; },
        hidden: hideCol('coldTempC'),
        formatter: (cell) => {
            return (
                <> {(cell) == null?(cell):(cell).toFixed(2)} </>)
        },
        sort: true
    }, {
        dataField: 'hotTempC',
        text: 'Hot Temp C',
        headerStyle: (column, colIndex) => { return { width: '10rem' }; },
        hidden: hideCol('hotTempC'),
        formatter: (cell) => {
            return (
                <> {(cell) == null?(cell):(cell).toFixed(2)} </>)
        },
        sort: true
    }, {
        dataField: 'jData.pm10',
        text: 'pm10',
        headerStyle: (column, colIndex) => { return { width: '10rem' }; },
        hidden: hideCol('pm10'),
        sort: true
    }, {
        dataField: 'jData.pm25',
        text: 'pm25',
        headerStyle: (column, colIndex) => { return { width: '10rem' }; },
        hidden: hideCol('pm25'),
        sort: true
        /*    }, {
                dataField: 'cels1',
                text: 'Cels1',
                headerStyle: (column, colIndex) => { return { width: '10rem' }; },
                sort: true
            }, {
                dataField: 'cels2',
                text: 'Cels2',
                headerStyle: (column, colIndex) => { return { width: '10rem' }; },
                sort: true
            }, {
                dataField: 'val1',
                text: 'val1',
                headerStyle: (column, colIndex) => { return { width: '10rem' }; },
                sort: true
            }, {
                dataField: 'lat',
                text: 'Lat',
                headerStyle: (column, colIndex) => { return { width: '6rem' }; },
                sort: true
            }, {
                dataField: 'lng',
                text: 'Lng',
                headerStyle: (column, colIndex) => { return { width: '6rem' }; },
                sort: true
        */
    }, {
        dataField: 'dataJSON',
        text: 'dataJSON',
        headerStyle: (column, colIndex) => { return { width: '80rem' }; },
        /*    }, {
                dataField: 'ip',
                text: 'ip',
                headerStyle: (column, colIndex) => { return { width: '10rem' }; },
                sort: true
        */
    }, {
        dataField: 'note',
        text: 'Note',
        headerStyle: (column, colIndex) => { return { width: '25rem' }; },
        sort: true
    }, {
        dataField: 'eventDate',
        text: 'UTC Datetime',
        headerStyle: (column, colIndex) => { return { width: '20rem' }; },
        sort: true
    }
    ];

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            // console.log("in openRow from doubleClick, selected row is:", row);
            alert(row.content);
            //props.history.push("/post/" + row.id);
        }
    }

    const selectRow = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            //            console.log("row.id:", row.id);
            //            console.log("isSelect:", isSelect);
            //            console.log("rowIndex:", rowIndex);
            //           console.log("e:", e);
            setSelectedDataLog(row);
            //   setFormData(row);
            setFormId(row.id);
            setFormOwner(row.owner);
            //            setFormRegistry(row.registry);
            setFormDataLogRegistryId(row.dataLogRegistryId);
            setFormLat(row.lat);
            setFormLng(row.lng);
            setFormWatts1(row.watts1);
            setFormWatts2(row.watts2);
            setFormWatts3(row.watts3);
            setFormCels1(row.cels1);
            setFormCels2(row.cels2);
            setFormCels3(row.cels3);
            setFormColdTempC(row.coldTempC);
            setFormHotTempC(row.hotTempC);
            setFormVal1(row.val1);
            setFormVal2(row.val2);
            setFormVal3(row.val3);
            setFormDataJSON(row.dataJSON);
            setFormCreatedAt(row.createdAt);
            setFormUpdatedAt(row.updatedAt);
            setFormEventDate(row.eventDate);
            setFormIp(row.ip);
  //          setFormTTLExpiry(new Date(row.TTLExpiry)/1000);
            setFormNote(row.note);
        }
    };

    function updateDNextPageTokenList(i, v) {
        var items = dNextPageTokenList;
        items[i] = v;
        setDNextPageTokenList(items);
    }

    function handleLatChange(e) {
        setIsEdited(true);
        setFormLat(e.target.value);
    }

    function handleLngChange(e) {
        setIsEdited(true);
        setFormLng(e.target.value);
    }

    function handleWatts1Change(e) {
        setIsEdited(true);
        setFormWatts1(e.target.value);
    }

    function handleWatts2Change(e) {
        setIsEdited(true);
        setFormWatts2(e.target.value);
    }
    function handleWatts3Change(e) {
        setIsEdited(true);
        setFormWatts3(e.target.value);
    }

    function handleCels1Change(e) {
        setIsEdited(true);
        setFormCels1(e.target.value);
    }

    function handleCels2Change(e) {
        setIsEdited(true);
        setFormCels2(e.target.value);
    }

    function handleCels3Change(e) {
        setIsEdited(true);
        setFormCels3(e.target.value);
    }

    function handleColdTempCChange(e) {
        setIsEdited(true);
        setFormCels3(e.target.value);

    } function handleHotTempCChange(e) {
        setIsEdited(true);
        setFormCels3(e.target.value);
    }

    function handleVal1Change(e) {
        setIsEdited(true);
        setFormVal1(e.target.value);
    }

    function handleVal2Change(e) {
        setIsEdited(true);
        setFormVal2(e.target.value);
    }

    function handleVal3Change(e) {
        setIsEdited(true);
        setFormVal3(e.target.value);
    }

    function handleNoteChange(e) {
        setIsEdited(true);
        setFormNote(e.target.value);
    }

    //************************************************************************************************************************** */
    useEffect(() => {
        setDataLogRegistryId(props.match.params.id);
        setDCurrentPage(0);
        //        fetchRegistryInfo(props.match.params.id);
        fetchDataLogList(0);
        //setDataLogRegistryId(props.registryId);
        // eslint-disable-next-line        
    }, []);

    /*
    async function fetchRegistryInfo(id) {
        try {
            const listDataA = await API.graphql({ query: getRegistry, variables: {id: id}, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
     
            const listA = listDataA.data.getRegistry.items;
    
            //const sList = listA.map(obj => ({ ...obj, attribute: JSON.parse(obj.attributes) }));
    
            //const cpList = sList.filter(obj => obj.attribute.Type === "calcpanel");
    
            setRegistry(listA);  //sList);
            //setCalcpanelList(cpList);    //first load what's in db.  we may need to overwrite with url parameter settings
            //setReloadModel(true);
    
            //return { status: true };
    
        } catch (error) {
            console.log("error on fetching registry details", error);
            //return { status: false, error: error };
        }
    
    }
    */

    async function fetchDataLogList(page) {      //page is the page we want, not current page
        try {

            var dToken;
            if (page === 0) {
                dToken = undefined;
            } else {
                dToken = dNextPageTokenList[page - 1];
            }

            setItemCount(0);
            if (true) { //props.isAuthenticated) {   //here
                //                const dataLogListDataA = await API.graphql(graphqlOperation(listDataLogs, {
                //                  const commentsOrderByCreatedAtData = await API.graphql({ query: commentsOrderByCreatedAt, variables: { commentPostId: postId }, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });

                const dataLogListDataA = await API.graphql({ query: dataLogsOrderByDate, variables: { dataLogRegistryId: props.match.params.id, sortDirection: 'DESC', limit: dLimit, nextToken: dToken } });
                const dataLogListA = dataLogListDataA.data.dataLogsOrderByDate.items.map(obj => ({ ...obj, jData: JSON.parse(obj.dataJSON), localDate: (new Date(obj.eventDate)).toLocaleString() })).sort((a, b) => a.eventDate < b.eventDate ? 1 : -1);
                updateDNextPageTokenList(page, dataLogListDataA.data.dataLogsOrderByDate.nextToken);
                //don't change current page here.  the calling routine does that
                //here

                setDataLogList(dataLogListA);
                setItemCount(dataLogListA.length);
                setMessageLine("");
                setCurrentRegistry(dataLogListA[0].registry);

                //todo later add rest of columns or make dynamic with table configuration
                switch (dataLogListA[0].registry.deviceType) {
                    case "heatback":
                        setColsToShow("watts1");
                        break;
                    case "heatback1":
                        setColsToShow("coldTempC,hotTempC,watts1");
                        break;
                    case "heatback2":
                        setColsToShow("watts1"); //coldTempC,hotTempC,watts1");
                        break;
                    case "heatback3":
                        setColsToShow("watts1,coldTempC");
                        break;
                    case "airqual1":
                        setColsToShow("pm10,pm25");
                        break;
                    case "temp1":
                        setColsToShow("coldTempC");
                        break;
                    case "temp2":
                        setColsToShow("coldTempC,hotTempC");
                        break;
                    default:
                        setColsToShow("coldTempC,hotTempC,watts1");
                        break;
                }

                return { status: true };
            } else {       //if not authenticated then they don't see anything
                alert("Please log in to review your devices");
            }
        } catch (error) {
            //console.log("error on fetching dataLogList", error);
            return { status: false, error: error };
        }
    };

    async function handleDeleteButton(e) {
        let cResult = window.confirm("Delete " + e.updatedAt + " ?");
        if (cResult) {
            try {
                const delDataLog = {
                    id: e.id
                };
                await API.graphql({ query: deleteDataLog, variables: { input: delDataLog }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
                await fetchDataLogList();
                setMessageLine("Deleted " + e.updatedAt);
            } catch (error) {
                alert(error);
            }
        }
    }

    async function saveDataLog() {
        var ret = { result: true };
        const newDataLog = {
            id: formId,
            lat: formLat,
            lng: formLng,
            watts1: formWatts1,
            watts2: formWatts2,
            watts3: formWatts3,
            cels1: formCels1,
            cels2: formCels2,
            cels3: formCels3,
            coldTempC: formColdTempC,
            hotTempC: formHotTempC,
            val1: formVal1,
            val2: formVal2,
            val3: formVal3,
            ip: formIp,
            note: formNote,
   //         TTLExpiry: formTTLExpiry,
            dataJSON: formDataJSON
        };
        try {
            await API.graphql({ query: updateDataLog, variables: { input: newDataLog }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
            setIsEdited(false);
            await fetchDataLogList();
        } catch (error) {
            ret = { result: false, message: error.errors[0].message };
            //console.log("error on saveDataLog", error);
            //console.log("..", error.errors[0].message);
        }

        return ret;
    };


    async function newDataLog() {
        var ret = { result: true };
        const newDataLog = {
            dataLogRegistryId: dataLogRegistryId
        };
        try {
            await API.graphql({ query: createDataLog, variables: { input: newDataLog }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
            setIsEdited(false);
            await fetchDataLogList();
        } catch (error) {
            ret = { result: false, message: error.errors[0].message };
            //console.log("error on createDataLog", error);
            //console.log("..", error.errors[0].message);
        }

        return ret;
    };


    function validateForm() {
        let ret = true;
        let msg = "";
        setMessageLine(msg);
        return ret;
    }

    async function handleBack(event) {
        props.history.goBack();
    }

    async function handleSave() {
        if (!validateForm()) {
            return;
        }
        const saveResult = await saveDataLog();
        if (saveResult.result === true) {
            setMessageLine("Saved");
        } else {
            setMessageLine("Unable to save: " + saveResult.message);
        }
    }

    async function handleNew() {
        const saveResult = await newDataLog();
        if (saveResult.result === true) {
            setMessageLine("New Device Created");
        } else {
            setMessageLine("Unable to save: " + saveResult.message);
        }
    }

    async function handleRefresh() {
        setItemCount("");
        fetchDataLogList(dCurrentPage);
    }

    async function handlePrev() {
        const fetchResult = await fetchDataLogList(dCurrentPage - 1);
        if (fetchResult.status === true) {
            setDCurrentPage(dCurrentPage - 1);
        } else {
            console.log("unable to get previous page:" + dCurrentPage - 1);
        }
    }

    async function handleNext() {
        const fetchResult = await fetchDataLogList(dCurrentPage + 1);
        if (fetchResult.status === true) {
            setDCurrentPage(dCurrentPage + 1);
        } else {
            console.log("unable to get next page:" + dCurrentPage + 1);
        }
    }


    function renderNewButton() {
        return (
            <Button className="float-right ldButton" middle="xs" onClick={handleNew} disabled={(isEdited)}
            >
                New
            </Button>
        )
    }

    function renderRefreshButton() {
        return (
            <Button className="float-right ldButton" middle="xs" onClick={handleRefresh} disabled={false}
            >
                Refresh
            </Button>
        )
    }

    function renderSaveButton() {
        return (
            <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleSave} disabled={(!isEdited)}
            >
                Save
            </Button>
        )
    }

    function renderBackButton() {
        return (
            <Button className="ldButton" middle="xs" onClick={handleBack} disabled={false}
            >
                Back
            </Button>
        )
    }

    function renderPrevButton() {
        return (
            <Button className="ldButton" middle="xs" onClick={handlePrev} disabled={dCurrentPage === 0}
            >
                Prev
            </Button>
        )
    }
    function renderNextButton() {
        return (
            <Button className="ldButton" middle="xs" onClick={handleNext} disabled={(dNextPageTokenList[dCurrentPage] === undefined || dNextPageTokenList[dCurrentPage] === null)}
            >
                Next
            </Button>
        )
    }

    function renderCardBody() {
        return (
            <Card.Body className="ldCardBody">
                {/*selectedPost.content*/}
                <Row className="ldBodyRow">
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Event Date</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="eventdate"
                                value={formEventDate || ''}
                                name="eventDate"
                                disabled={true}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={2} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>lat</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="lat"
                                value={formLat || ''}
                                name="lat"
                                onChange={handleLatChange}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={2} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>lng</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="lng"
                                value={formLng || ''}
                                name="lng"
                                onChange={handleLngChange}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColRight">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>IP</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="ip"
                                value={formIp || ''}
                                name="ip"
                                disabled={true}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="ldBodyRow">
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>coldTempC</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formColdTempC || ''}
                                name="coldTempC"
                                onChange={handleColdTempCChange}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>hotTempC</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formHotTempC || ''}
                                name="hotTempC"
                                onChange={handleHotTempCChange}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="ldBodyRow">
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>watts1</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formWatts1 || ''}
                                name="watts1"
                                onChange={handleWatts1Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>watts2</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formWatts2 || ''}
                                name="watts2"
                                onChange={handleWatts2Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>watts3</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formWatts3 || ''}
                                name="watts3"
                                onChange={handleWatts3Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>cels1</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formCels1 || ''}
                                name="cels1"
                                onChange={handleCels1Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>cels2</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formCels2 || ''}
                                name="cels2"
                                onChange={handleCels2Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>cels3</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formCels3 || ''}
                                name="cels3"
                                onChange={handleCels3Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>val1</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formVal1 || ''}
                                name="val1"
                                onChange={handleVal1Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>val2</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formVal2 || ''}
                                name="val2"
                                onChange={handleVal2Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>val3</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0.0"
                                value={formVal3 || ''}
                                name="val3"
                                onChange={handleVal3Change}
                                disabled={selectedDataLog === undefined}
                            />
                        </InputGroup>
                    </Col>
                    <InputGroup >
                        <InputGroup.Prepend>
                            <InputGroup.Text>note</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea"
                            rows={1}
                            placeholder="note"
                            value={formNote || ''}
                            name="dataJSON"
                            onChange={handleNoteChange}
                            disabled={selectedDataLog === undefined}
                        />
                    </InputGroup>
                    <InputGroup >
                        <InputGroup.Prepend>
                            <InputGroup.Text>data JSON</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea"
                            rows={2}
                            placeholder="data JSON"
                            value={formDataJSON || ''}
                            name="dataJSON"
                            disabled={true}
                        />
                    </InputGroup>
                </Row>
                <Row className="ldBodyRow">
                    <InputGroup>
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text >DataLogRegistryId</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="dataLogRegistryId"
                            value={formDataLogRegistryId || ''}
                            name="dataLogRegistryId"
                            disabled={true}
                        />
                    </InputGroup>
                </Row>
                <Row className="ldBodyRow">
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text >Created At</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="created at"
                                value={formCreatedAt || ''}
                                name="createdAt"
                                disabled={true}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColLeft">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text >Updated At</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="updated at"
                                value={formUpdatedAt || ''}
                                name="updatedAt"
                                disabled={true}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4} className="ldBodyColRight">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text >Owner</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="owner"
                                value={formOwner || ''}
                                name="owner"
                                disabled={true}
                            />
                        </InputGroup>
                    </Col>
                </Row>

            </Card.Body>
        )
    }
    function renderDataLog() {
        return (<div className="DataLog" >
            <p></p>
            <h1 > Take Back The Heat - DataLog - {currentRegistry.deviceName} : ({currentRegistry.deviceType})</h1>
            <Row className="ldTopRow">
                <div>
                    {renderBackButton()}
                </div>
                <div className="ldMessageContainer" >
                    <div className="ldFormMessage">
                        {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                    </div>
                </div>
                <div>
                    {renderRefreshButton()}
                </div>
            </Row>
            <Row className="ldTopRow">
                <div className="ldSmallButton">
                    {renderPrevButton()}
                    {renderNextButton()}
                </div>
                <div className="ldSmallButton">
                    <h5>({itemCount})</h5>
                </div>
                <div className="ldSmallButton">
                    {renderNewButton()}
                    {renderSaveButton()}
                </div>
            </Row>
            <Accordion>
                <Card className="DataLogCard">
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        {selectedDataLog === undefined ? '' : selectedDataLog.localDate}...
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <>
                            <Card.Body>
                                {renderCardBody()}
                            </Card.Body>
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <div className="DataLogList">
                <BootstrapTable
                    bootstrap4
                    keyField='id'
                    striped
                    condensed
                    data={dataLogList}
                    columns={dataLogColumns}
                    selectRow={selectRow}
                    rowEvents={rowEvents}
                >
                </BootstrapTable>
            </div>
        </div>

        );
    }

    return (
        <div className="DataLog" >
            <Helmet>
                <title>{"TakeBackTheHeat - Datalog"}</title>
                <meta name="description" content="takebacktheheat.org datalog of measurements for monitored sites involved in reducing the heat energy to address climate change and global warming." />
            </Helmet>
            {renderDataLog()}
        </div>
    );
}