import React, { useState, useEffect } from "react";
import { API,Auth, graphqlOperation } from 'aws-amplify';
import { Button, Card, Row, Col, InputGroup, FormControl, Accordion } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { createConfig, updateConfig, deleteConfig } from '../graphql/mutations';
import { listConfigs } from '../graphql/queries';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export default function Admin(props) {

  const [configList, setConfigList] = useState([]);

  const [selectedConfig, setSelectedConfig] = useState();

  /*
    id: ID!
  owner: String
  configName: String!
  configNote: String
  attributes: AWSJSON
  createdAt: AWSDateTime!
  updatedAt: AWSDateTime!
  */
  const [formId, setFormId] = useState();
  const [formName, setFormName] = useState();
  const [formNote, setFormNote] = useState();
  const [formAttributes, setFormAttributes] = useState();
  const [formCreatedAt, setFormCreatedAt] = useState();
  const [formUpdatedAt, setFormUpdatedAt] = useState();

  const [messageLine, setMessageLine] = useState();
  const [isEdited, setIsEdited] = useState(false);

  const [itemCount, setItemCount] = useState(0);

  function compare(a, b) {
    if (a.configName < b.configName) {
        return -1;
    }
    if (a.configName > b.configName) {
        return 1;
    }
    return 0;
}    

  function ActionFormatter(data) {
    return (
        <div className="ButtonColumn">
            {(props.userDetails.username === data.row.owner) ? (<>
                <Button type="button" variant="outline-danger" className="ActionButton" size="sm" onClick={() => handleDeleteButton(data.row)}>delete</Button></>) : (<></>)}
        </div>
    );
}

//function formatDate(cell, row, rowIndex, colIndex) {
//}

const actionFormatter = (cell, row) => <ActionFormatter row={row} />;

const configColumns = [{
    dataField: 'action',
    isDummyField: true,
    csvExport: false,
    formatter: actionFormatter,
    formatExtraData: props.userDetails.username,
    headerStyle: (column, colIndex) => { return { width: '16rem' }; },
    text: ''
}, {
    dataField: 'configName',
    text: 'Name',
    sort: true
    //    }, {
    //        dataField: 'createdAt',
    //        text: 'Created At'
}, {
    dataField: 'configNote',
    text: 'Note',
    sort: true
    //    }, {
    //        dataField: 'createdAt',
    //        text: 'Created At'
  }, {
    dataField: 'updatedAt',
    text: 'Date',
    sort: true,
    formatter: (cell) => {  return (
        <> {cell.substring(0,10)} </> ) },
    //    }, {
    //        dataField: 'createdAt',
    //        text: 'Created At'
}

];

const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
        alert(row.content);
        //props.history.push("/post/" + row.id);
    }
}

const selectRow = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
        setSelectedConfig(row);
        //   setFormData(row);
        setFormId(row.id);
        setFormName(row.configName);
        setFormNote(row.configNote);
        setFormAttributes(row.attributes);
        setFormCreatedAt(row.createdAt);
        setFormUpdatedAt(row.updatedAt);
        //setDdeviceType({label:row.deviceType,value:row.deviceType});

    }
};

  useEffect(() => {
    //    window.scrollTo(0, 0)
    fetchConfigList();
    getGroups();

    /* eslint-disable */

  }, [props.isAuthenticated])

  async function getGroups() {
    if (props.isAuthenticated) {
      const user = await Auth.currentAuthenticatedUser();
      console.log("user:", user);
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      if (groups.includes('Admin')) {
        console.log("is in Admin");
      } else {
        console.log("is not in Admin");
      }
    } else {
      console.log("not logged in");
    }
  }

function handleConfigNameChange(e) {
  setIsEdited(true);
  setFormName(e.target.value);
}

function handleConfigNoteChange(e) {
  setIsEdited(true);
  setFormNote(e.target.value);
}

function handleAttributesChange(e) {
  setIsEdited(true);
  setFormAttributes(e.target.value);
}

async function fetchConfigList() {
  if (props.isAuthenticated) {

      setItemCount(0);

      try {
          const configListDataA = await API.graphql(graphqlOperation(listConfigs));
          const configListA = configListDataA.data.listConfigs.items;
          const cList = [].concat(...configListA).sort(compare);

          setConfigList(cList); //configListA);

          setItemCount(cList.length);
          setMessageLine("");
          return { status: true };

      } catch (error) {
          console.log("error on fetching configList", error);
          return { status: false, error: error };
      }
  }
};

async function handleDeleteButton(e) {
  let cResult = window.confirm("Delete " + e.configName + " ?");
  if (cResult) {
      try {
          const delConfig = {
              id: e.id
          };
          await API.graphql({ query: deleteConfig, variables: { input: delConfig }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
          await fetchConfigList();
          setMessageLine("Deleted " + e.configName);
      } catch (error) {
          alert(error);
      }
  }
}


async function saveConfig() {
  var ret = { result: true };
  const newConfig = {
      id: formId,
      configName: formName,
      configNote: formNote,
      attributes: formAttributes
  };
  try {
      await API.graphql({ query: updateConfig, variables: { input: newConfig }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      setIsEdited(false);
      await fetchConfigList();
  } catch (error) {
      ret = { result: false, message: error.errors[0].message };
  }

  return ret;
};

async function newConfig() {
  var ret = { result: true };
  const newConfig = {
      configName: "New",
      configNote: "New"
  };
  try {
      await API.graphql({ query: createConfig, variables: { input: newConfig }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      setIsEdited(false);
      await fetchConfigList();
  } catch (error) {
      ret = { result: false, message: error.errors[0].message };
  }

  return ret;
};


function validateForm() {
  let ret = true;
  let msg = "";
  setMessageLine(msg);
  return ret;
}

async function handleSave() {
  if (!validateForm()) {
      return;
  }
  const saveResult = await saveConfig();
  if (saveResult.result === true) {
      setMessageLine("Saved");
  } else {
      setMessageLine("Unable to save: " + saveResult.message);
  }
  
}

async function handleNew() {
  const saveResult = await newConfig();
  if (saveResult.result === true) {
      setMessageLine("New Config Created");
  } else {
      setMessageLine("Unable to save: " + saveResult.message);
  }
}


function renderNewButton() {
  return props.isAuthenticated ? (
      <Button className="float-right ldButton" middle="xs" onClick={handleNew} disabled={(isEdited)}
      >
          New
      </Button>
  ) : (<></>
  )
}

function renderSaveButton() {
  if (selectedConfig === undefined) {
      return (<></>)
  } else {
      if (selectedConfig.owner && selectedConfig.owner === props.userDetails.username) {
          return (
              <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleSave} disabled={(!isEdited)}
              >
                  Save
              </Button>
          )
      } else {
          return (
              <Button className="float-right ldButton ldSaveButton" middle="xs" onClick={handleSave} disabled={true}
              >
                  Save
              </Button>
          )
      }
  }
}

function renderItemCount() {
  return (<div className="ldItemCount">
   ({itemCount})
   </div>
)
}


function renderCardBody() {
  return (
      <Card.Body className="ldCardBody">
          {/*selectedPost.content*/}
          <Row className="ldBodyRow">
              <Col sm={5} className="ldBodyColRight">
                  <InputGroup>
                      <InputGroup.Prepend>
                          <InputGroup.Text>Name</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="name"
                          value={formName || ''}
                          name="configName"
                          onChange={handleConfigNameChange}
                          disabled={selectedConfig === undefined}
                      />
                  </InputGroup>
              </Col>
          </Row>
          <Row className="ldBodyRow">
              <InputGroup >
                  <InputGroup.Prepend>
                      <InputGroup.Text>Note</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl as="textarea"
                      className="formNoteText"
                      rows={3}
                      placeholder="note"
                      value={formNote || ''}
                      name="configNote"
                      onChange={handleConfigNoteChange}
                      disabled={selectedConfig === undefined}
                  />
              </InputGroup>

          </Row>
          <Row className="ldBodyRow">
              <InputGroup >
                  <InputGroup.Prepend>
                      <InputGroup.Text>Attributes</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl as="textarea"
                      className="formAttributeText"
                      rows={3}
                      placeholder="attributes"
                      value={formAttributes || ''}
                      name="attributes"
                      onChange={handleAttributesChange}
                      disabled={selectedConfig === undefined}
                  />
              </InputGroup>

          </Row>
          <Row>
              <InputGroup >
                  <InputGroup.Prepend>
                      <InputGroup.Text>CreatedAt</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      placeholder=""
                      value={formCreatedAt || ''}
                      name="createdAt"
                      disabled={true}
                  />
              </InputGroup>

          </Row>

      </Card.Body>

  )

}





  function renderAdmin() {
    return (<div className="Admin" >
                <Row className="ldHeaderRow">
                    Take Back The Heat - Admin
                </Row>
                <Row className="ldTopRow">
                    <div className="ldSmallButton">
                           {renderItemCount()}
                    </div>
                    <div className="ldMessageContainer" >
                        <div className="ldFormMessage">
                            {messageLine && messageLine.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                        </div>
                    </div>
                    <div className="ldSmallButton">
                        {renderNewButton()}
                        {renderSaveButton()}
                    </div>
                </Row>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            {formName}...
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <>
                                <Card.Body>
                                    {renderCardBody()}
                                </Card.Body>
                                <Card.Footer className="ConfigCardFooter">
                                    {formUpdatedAt}
                                </Card.Footer>

                            </>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <div className="ConfigList">
                    <BootstrapTable
                        bootstrap4
                        keyField='id'
                        data={configList}
                        columns={configColumns}
                        selectRow={selectRow}
                        rowEvents={rowEvents}
                    >
                    </BootstrapTable>
                </div>

            </div>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Take Back The Heat</h1>
        <p>Please log in first.</p>
      </div>
    );
  }

  return <div className="Admin" >
    {props.isAuthenticated ? renderAdmin() : renderLander()}
  </div>;
}


