import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import contextImage from "../media/giveahoot.jpg";
import { Helmet } from "react-helmet";

export default function Context(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  function renderContext() {
    return (<div className="Context" >
      <p></p>
      <h1>Take Back The Heat - Context</h1>
      <p></p>
      <p>The UN Secretary-General indicated the following 10 priorities for 2021:<Link to="/Etc"><sub>[10]</sub></Link>
      </p>
      <li>Respond to COVID-19</li>
      <li>Start an inclusive and substainable economic recovery</li>
      <li>Make peace with nature</li>
      <li>Tackle poverty and inequality</li>
      <li>Reverse the assault on human rights</li>
      <li>Gender equality, the greatest human rights challenge</li>
      <li>Heal geopolitical rifts</li>
      <li>Reverse the erosion of the nuclear disarmament and non-proliferation regime</li>
      <li>Seize the opportunities of digital technologies while protecting against their growing dangers</li>
      <li>Launch a reset for the 21st century</li>
      <p></p>
      <div >
        <p>'Make peace with nature' is a cute rename that encompasses what has previously been described as 'climate change', 'global warming', 'global climate crisis', 'carbon initiatives', 'carbon neutrality', 'biodiversity', 'phase out fossil fuels', 'carbon-neutral', etc. Not the top priority, but still high on the list.  Forcing development banks to align with Paris Agreement is an interesting component.</p>
        <p>The earliest name/motto I can remember is 'give a hoot, don't pollute' from the '70s.  Too bad we didn't listen.  Air pollution, noise pollution, ground pollution, heat pollution, water pollution, they are all essentially the same issue: recklessly discarding into the environment and letting others (mainly future generations) clean it up. <i>'The solution to pollution is dilution'</i> doesn't work in the long run.</p>
        <img src={contextImage} alt="Give a hoot" height="20%" width="20%"></img>
        <p></p>
        <Link to="/Etc"><sub>[11]</sub></Link>
        <p></p>
        <p>At <b>Take Back the Heat</b> we are looking at one portion (heat conversion) of one aspect (climate) of one UN priority (make peace with nature).  The main priority here is technical solutions to reduce heat, in part due to there being no other visible projects currently looking at this aspect.  Current geo-engineering efforts seem to mainly focus on blocking the sun, or carbon filtering.</p>
        <p>We can start exploring what is possible in this micro geo-engineering space to identify what can be done immediately, albeit slowly and in very small increments.</p>
        <p> So, excuse the mess as we build as we go, and let's get started.</p>
      </div>
      <p className="PageFooter"><Link to="/How">{"<<"}--How</Link><Link to="/getInvolved">Get Involved--{">>"}</Link></p>
    </div>
    );
  }

  return <div className="About" >
    <Helmet>
      <title>{"TakeBackTheHeat - Context"}</title>
      <meta name="description" content="Where takebacktheheat.org fits in with UN priorities and other global efforts to reduce the heat energy to address climate change and global warming." />
    </Helmet>
    {renderContext()} </div>;
}