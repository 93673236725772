import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Button } from "react-bootstrap";
import config from '../config';
import { Helmet } from "react-helmet";
export default function GetInvolved(props) {

    // {props.userDetails.username}
    function renderGetInvolved() {
        return (<div className="GetInvolved" >
            <p></p>
            <h1 > Take Back The Heat - How you can get involved</h1>
            <p>
            </p>
            <p>
                - Drop a pin on the <Link to="/Where">Where Page</Link> to let us know where you are from.
            </p>
            <p>
                - Participate in forums (<Link to="/Forum">Forum Page</Link>), and let us know your thoughts!
            </p>
            <p>
                - Try out some of the backyard projects as they become available on the forums.  These are great for raising awareness and influencing considerations of future projects, while contributing to the total heat converted.
            </p>
            <p>
                - Register your atmospheric heat reduction solution(s) on the <Link to="/Registry">Registry Page</Link>.  This allows us to total up the world efforts, showing global progress and efforts.
            </p>
            <p>
                - Try out the atmospheric heat reduction model on the <Link to="/Model">Model Page</Link>.
                The <Link to="/post/bfc658b1-c883-413f-ba8a-f475292ff6e1">Forum</Link> has a category for Model, which includes details on using it and allows for feedback. See the difference to global temperature that will happen based on different combinations of solar photovoltaic panel farms, heat scavenger installations, and other heat reduction solutions.
            </p>
            <p>
                - Look around your worksite.  Are there opportunities to make improvements?  Is excessive waste heat going to the atmosphere?  Discuss some options internally or <Link to="/contact">contact</Link> us.
            </p>
            <p>
            </p>
            <p>
                Addressing climate change and global warming will be accomplished through multiple small initiatives and solutions, not one big one.
                Many of our solutions skip over the usual government red tape and committee gauntlets, making it possible for you to <b>make an impact today</b>, beyond yet more discussion.
            </p>
            <p>
                Drop by the online store and pick up a few Atmospheric Heat Reduction Right To Report (AHR RTR)!
                This moves you from the 'someoneotta' group to the 'immakingadifference' group!
                Each purchase allows us to build out more and gets us one step further in the journey to reducing global warming.
                </p>
                <p>
                <a href="https://shop.takebacktheheat.org">Store</a>
            </p>

            <p>
                Thank you for visiting TakeBackTheHeat.org, and for caring about the future of our planet.  Our children deserve better than what we're leaving them today.
            </p>
        </div>
        );
    }
    return (
        <div className="GetInvolved" >
            <Helmet>
                <title>{"TakeBackTheHeat - GetInvolved"}</title>
                <meta name="description" content="How you can get involved and take climate action in association with takebacktheheat.org, to reduce the heat energy to address climate change and global warming." />
            </Helmet>
            {renderGetInvolved()}
        </div>
    );
}