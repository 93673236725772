import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import { Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { listConfigs } from '../graphql/queries';
import Chart from "react-google-charts";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Helmet} from "react-helmet";

export default function Roadmap(props) {

    const [roadmapChart, setRoadmapChart] = useState([]);
    const [roadmapList, setRoadmapList] = useState([]);

    function compare(a, b) {
        if (a.displayOrder < b.displayOrder) {
            return -1;
        }
        if (a.displayOrder > b.displayOrder) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        function doOnLoad() {
            fetchConfigList();
        }
        doOnLoad();
/* eslint-disable */        
    }, [])

    const configColumns = [{
        dataField: 'name',
        text: 'Name',    //name
        sort: true,
        type: 'string'
    }, {
        dataField: 'description',
        text: 'Desc',
        sort: true,
        type: 'string'
    }, {
        dataField: 'startBudget',
        text: 'Start $',
        sort: true,
        headerStyle: (column, colIndex) => { return { width: '8rem' }; },
        type: 'string'
    }, {
        dataField: 'startDate',
        text: 'Start Year',
        sort: true,
        headerStyle: (column, colIndex) => { return { width: '8rem' }; },
        type: 'int'
    }, {
        dataField: 'endDate',
        text: 'End Year',
        sort: true,
        headerStyle: (column, colIndex) => { return { width: '8rem' }; },
        type: 'int'
    }
    ];


    async function fetchConfigList() {

        try {
            //todo   just get the roadmap record, if we're not going to integrate 
            const configListDataA = await API.graphql({ query: listConfigs, authMode: (props.isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY') });
            //const configListDataA = await API.graphql(graphqlOperation(listConfigs));
            const configListA = configListDataA.data.listConfigs.items;

            const configListB = configListA.map(obj => ({ ...obj, attribute: JSON.parse(obj.attributes) }));
            const cList = [].concat(...configListB).sort(compare);
            
            const tl = JSON.parse(cList.filter(obj => obj.attribute.Type === "roadmap")[0].attributes).Data;
            const tlList = [].concat(...tl).sort(compare);

            //stuff chart data
            var cData = [];
            var cR = [];

            cR.push({type: 'string', id: 'Name' });
            cR.push({type: 'string', id: 'startBudget'});
            cR.push({type: 'string', role: 'tooltip' });
            cR.push({type: 'date' , id: 'Start'});
            cR.push({type: 'date' , id: 'End'});

            cData.push(cR);
            tlList.forEach(function (tlRow) {
//             cData.push([tlRow.name, new Date(tlRow.startDate,1,1), new Date(tlRow.endDate,12,31)]);
               cData.push([tlRow.name, tlRow.startBudget, tlRow.description + ":" + tlRow.startBudget + " ",new Date(tlRow.startDate,1,1), new Date(tlRow.endDate,12,31)]);
            });

            setRoadmapList(tlList);
            setRoadmapChart(cData);

            //            if (urlData.t !== undefined) {
            //                setTarget(urlData.t);

            return { status: true };

        } catch (error) {
            console.log("error on fetching configList", error);
            return { status: false, error: error };
        }
    };

    function renderChart() {
        return ( <>
         {roadmapChart.length>0?(
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <Chart
                    width={"100%"}
                    height={window.innerWidth * 0.95}
                    chartType="Timeline"
                    loader={<div>Loading Chart</div>}
                    data={roadmapChart}
                />
            </div>
            ) : (
                <div>
                    loading data
                </div>
            )}
        </>);
    }

    function renderTable() {
        return ( 
                    <div className="RoadmapList">
                    <BootstrapTable
                        bootstrap4
                        keyField='id'
                        data={roadmapList}
                        columns={configColumns}
                    >
                    </BootstrapTable>
            </div> 
        );
    }

    function renderRoadmap() {
        return (
        <div className="Roadmap" >
            <Row className="ldHeaderRow">
                Take Back The Heat - Roadmap (Year scale)
            </Row>
            <Row className="ldBodyRow">
                <div className="ldSmallButton">
                    <Link to="/post/029d52e9-4353-434a-a2a8-559e6884cb68">Forum Discussion</Link>
                </div>
            </Row>
            {window.innerWidth < 900 ? renderTable() : renderChart()}
        </div>
        );
    }

    return <div >
            <Helmet>
                <title>{"TakeBackTheHeat - Roadmap"}</title>
                <meta name="description" content="The anticipated activities takebacktheheat.org will be involved in for reducing heat energy to address climate change and global warming." />
            </Helmet>

        {renderRoadmap()}
    </div>;
}
