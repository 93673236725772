import React, { useState } from "react";
import {
  FormGroup,
  FormControl,
  FormLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
//import { API } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import "./Signup.css";

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: ""
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      const newUser = await Auth.signUp({
        username: fields.username.toString().toLowerCase(), //fields.email,
        password: fields.password,
        attributes: {
           email: fields.email.toString().toLowerCase()
        }
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }
  
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    props.handleLogout();
    
    try {
/*      const emailLower = fields.email.toString().toLowerCase();
      await Auth.confirmSignUp(emailLower, fields.confirmationCode);  //fields.email, fields.confirmationCode);
      await Auth.signIn(emailLower, fields.password);  //fields.email, fields.password); 
*/  
      const usernameLower = fields.username.toString().toLowerCase();
      await Auth.confirmSignUp(usernameLower, fields.confirmationCode);  //fields.email, fields.confirmationCode);
      await Auth.signIn(usernameLower, fields.password);  //fields.email, fields.password); 

      props.userHasAuthenticated(true);
      props.history.push("/why");

    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <p>Please don't close this page!  You should receive an email within the next few minutes with the confirmation code.  Closing this page without the confirmation code means you'll need to contact support (contact@surveyalot.com) to remove the email address and start again.</p>
        <FormGroup controlId="confirmationCode" >
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" >
          <FormLabel>Username</FormLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.username}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="email" >
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" >
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" >
          <FormLabel>Confirm Password</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
        <label className="ldPasswordDescription"> Passwords: minimal length of 8 and have at least 1 each of number, special character, uppercase letter, and lowercase letter.</label>

      </form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}